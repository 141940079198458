import {
    copyShapes,
    cutShapes,
    pastShapes,
    openChangeAnnotationModal,
    deleteLinkedShapes,
    openOverlayedShapesModal,
    openSeverInfoModal,
    openViewCommentModal,
} from '../../redux/actions/redactor';
import {
    REDACTION_COMMON_VIEW_SEVER_INFO,
    REDACTION_COMMON_CONVERT_HIGHLIGHT_TO_SEVER,
} from '../permissions';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IState } from '../../redux/store';
import { AnyAction } from 'redux';

export const deleteCutCopyAnnotationMenus = [
    {
        id: 'delete',
        label: 'Delete',
        disabled: false,
        action: (): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
            deleteLinkedShapes(true),
    }, {
        id: 'cut',
        label: 'Cut',
        disabled: false,
        action: (): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => void =>
            cutShapes(true),
    }, {
        id: 'copy',
        label: 'Copy',
        disabled: false,
        action: (): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void>  =>
            copyShapes(true),
    },
];

const changeAnnotationTypeTo = {
    id: REDACTION_COMMON_CONVERT_HIGHLIGHT_TO_SEVER,
    label: 'Change annotation type to..',
    disabled: false,
    action: openChangeAnnotationModal,
};

const viewSeverInfo = {
    id: 'severInfo',
    label: 'View Sever Info',
    disabled: false,
    action: openSeverInfoModal,
};

export const highlightSpecificMenu = [
    changeAnnotationTypeTo,
];

export const severSpecificMenu = [
    changeAnnotationTypeTo,
    viewSeverInfo,
];

const overlayedShapes = {
    id: 'overlay',
    label: 'Toggle Selection',
    disabled: false,
    action: openOverlayedShapesModal,
};

export const toggleSelectionMenu = [
    overlayedShapes,
];

export const pasteMenu = {
    id: 'paste',
    label: 'Paste',
    disabled: false,
    action: (): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
        pastShapes(true),

};

export const viewCommentMenu = {
    id: 'viewComment',
    label: 'View Comment',
    disabled: false,
    action:  (): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => void =>
        openViewCommentModal(),
};

export const GET_DUPLICATES_CURRENT_GROUP_PENDING = 'reduction/GET_DUPLICATES_CURRENT_GROUP_PENDING';
export const GET_DUPLICATES_CURRENT_GROUP_SUCCESS = 'reduction/GET_DUPLICATES_CURRENT_GROUP_SUCCESS';
export const GET_DUPLICATES_CURRENT_GROUP_FAILURE = 'reduction/GET_DUPLICATES_CURRENT_GROUP_FAILURE';

export const GET_DUPLICATES_RESOURCE_PENDING = 'reduction/GET_DUPLICATES_RESOURCE_PENDING';
export const GET_DUPLICATES_RESOURCE_SUCCESS = 'reduction/GET_DUPLICATES_RESOURCE_SUCCESS';
export const GET_DUPLICATES_RESOURCE_FAILURE = 'reduction/GET_DUPLICATES_RESOURCE_FAILURE';

export const SET_DUPLICATES_MAX_COUNT = 'reduction/SET_DUPLICATES_MAX_COUNT';
export const CLOSE_SELECTED_DUPLICATE = 'reduction/CLOSE_SELECTED_DUPLICATE';

export const CLEAR_DUPLICATE_GROUP = 'reduction/CLEAR_DUPLICATE_GROUP';
export const UPDATE_DUPLICATE_RESOURCE = 'reduction/UPDATE_DUPLICATE_RESOURCE';
export const UPDATE_ALLOW_PAGES_NAVIGATION = 'reduction/UPDATE_ALLOW_PAGES_NAVIGATION';

export const CLEAR_DUPLICATES_RESOURCE = 'reduction/CLEAR_DUPLICATES_RESOURCE';

export const SET_DUPLICATE_SCALE = 'reduction/SET_DUPLICATE_SCALE';

export const SET_DUPLICATE_CLIENT_SIZE = 'reduction/SET_DUPLICATE_CLIENT_SIZE';
export const SET_DUPLICATE_SEARCH_PENDING = 'reduction/SET_DUPLICATE_SEARCH_PENDING';

export const SET_MASTER_DOCUMENTS = 'reduction/SET_MASTER_DOCUMENTS';
export const SET_REFERENCE_PAGES = 'reduction/SET_REFERENCE_PAGES';
export const SET_REFERENCE_DUPLICATES = 'reduction/SET_REFERENCE_DUPLICATES';
export const CLEAN_REFERENCE_MANAGEMENT_DATA = 'reduction/CLEAN_REFERENCE_MANAGEMENT_DATA';

export const GET_DUPLICATE_REFERENCES = 'reduction/GET_DUPLICATE_REFERENCES';
export const CLEAR_DUPLICATE_REFERENCES = 'reduction/CLEAR_DUPLICATE_REFERENCES';

export const GET_MODAL_DUPLICATE_REF_DATA_PENDING = 'reduction/GET_MODAL_DUPLICATE_REF_DATA_PENDING';
export const GET_MODAL_DUPLICATE_REF_DATA_SUCCESS = 'reduction/GET_MODAL_DUPLICATE_REF_DATA_SUCCESS';
export const GET_MODAL_DUPLICATE_REF_DATA_FAILURE = 'reduction/GET_MODAL_DUPLICATE_REF_DATA_FAILURE';

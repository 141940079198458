import { IHeader } from './leftSidebar.config';

export const REQUEST_SEARCH_MODAL = 'requestSearch';

export const stateOptions = [
    {
        value: 'All',
        label: 'All',
    },
    {
        value: 'Active',
        label: 'Active',
    },
    {
        value: 'Closed',
        label: 'Closed',
    },
];

export const tableColumns = (
    handleLanguageChange: (param: string) => string,
    ): IHeader[] => [
    {
        accessorKey: 'requestNumber',
        accessor: 'requestNumber',
        id: 'requestNumber',
        header: handleLanguageChange('Request Number'),
        minResizeWidth: 1,
        show: true,
        sortOrder:null
    },
    {
        accessorKey: 'summary',
        accessor: 'summary',
        id: 'summary',
        header: handleLanguageChange('Summary'),
        minResizeWidth: 1,
        show: true,
        sortOrder:null
    },
    {
        accessorKey: 'dueData',
        accessor: 'dueData',
        id: 'dueData',
        header: handleLanguageChange('Due Data'),
        minResizeWidth: 1,
        show: true,
        sortOrder:null
    },
    {
        accessorKey: 'officer',
        accessor: 'officer',
        id: 'officer',
        header: handleLanguageChange('Officer'),
        minResizeWidth: 1,
        show: true,
        sortOrder:null
    },
    {
        accessorKey: 'folderRsn',
        accessor: 'folderRsn',
        id: 'folderRsn',
        header: handleLanguageChange('FolderRSN'),
        minResizeWidth: 1,
        show: true,
        sortOrder:null
    },
];
import { ID } from '../constants/common.constants';

// TODO need to rewrite with generic
export const range = (arr: any[]): any[] => {
    const length = [...arr].pop() - arr[0] + 1;

    return Array.from({ length }, (_: number, i: number): any[] => arr[0] + i);
};

export const convertStringRangeToArray = (value: string): number[] => {
    const intervals = value.split(',');

    return intervals.reduce((acc: string[], interval: string) => {
        const rangedNumbers = [
            ...interval
                .split('-')
                .map((i: string) => Number(i)),
        ];

        return isNaN(Number(interval))
            ? [...acc, ...range(rangedNumbers)]
            : [...acc, Number(interval)];
    }
        , []);
};

export const rangeFormat = (array: number[]): string => {
    const lastIdx = array.length - 1;
    // [ '2,', '4-', '-', '6,', '9,', '11-', '-', '13,', '15,' ] and then join
    const numbersToStrings = array.map((elem: number, i: number, arr: number[]): string => {
        const isAdjacentNum = i < lastIdx && arr[i + 1] - elem === 1;
        const iPrevAdjacentNum = i > 0 && elem - arr[i - 1] === 1;

        return isAdjacentNum ? `${iPrevAdjacentNum ? '' : elem}-` : `${elem},`;
    }).join('');

    // replace multiple dashes by single dash
    return numbersToStrings.replace(/-+/g, '-').slice(0, -1);
};

export const compareNumbers = (a: number, b: number): number => b - a;

export const updateArrayWithArrayByKey = <T extends object, K extends keyof T>(
    oldArray: T[],
    newArray: T[],
    key: string,
): T[] => {
    const newArrayObj = newArray.reduce((obj: T, item: T) => {
        obj[item[key]] = item;

        return obj;
    }, {});

    return [...oldArray].map((page: T): T => {
        if (newArrayObj.hasOwnProperty(page[key])) {
            return {
                ...(page as object),
                ...newArrayObj[page[key]],
            };
        }

        return page;
    });
};

export const getWantedObjectByProp = <T, K extends keyof T>(data: any[], property: string, value: any): any =>
    data.find((element: T): boolean => element[property] === value);

export const deleteObjectById = <T, K extends keyof T>(data: any[], id: number): T[] =>
    data.filter((element: T): boolean => element[ID] !== id);

export const deleteSubarrayFromArray = <T, K extends keyof T>(array: any[], subArray: T[]): T[] =>
    array.filter((element: T): boolean => !subArray.find((subElement: T): boolean =>
        subElement[ID] === element[ID],
    ));

export const arrayMoveMutate = <T extends object>(array: T[], from: number, to: number): void => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

/* tslint:disable */
export const arrayMove = <T extends object>(array: T[], from: number, to: number): T[] => {
    array = array.slice();
    arrayMoveMutate(array, from, to);

    return array;
};
/* tslint:enable */

export const getFilteredArrayByObjectProp = <G extends object>(array: G[], wanted: G, property: string): G[] =>
    array.filter((entity: G): boolean => entity[property] !== wanted[property]);

export const getRequiredFilteredArrayByProp = <G extends object>
    (
        array: G[],
        wanted: string,
        property: string,
        toCheck: string,
    ): G[] =>
    array.filter((entity: G): boolean => {
        if (toCheck) {
            return entity[property][toCheck];
        } else if (!toCheck) {
            return entity[property];
        }
    }).map((value: G) =>
        value[wanted],
    );

import * as React from 'react';
import { omit, get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import {
    NAME,
    DESCRIPTION,
    emptyStack,
    EMPTY_STACK_ID,
    STACK_MANAGMENT_MODAL,
    STACK_PROPERTIES_MODAL,
    STACK_PROPERTIES_TITLE_MODAL,
    STACK_PROPERTY_ID, STACK_PROPERTY_LABEL,
} from '../../../constants/stack.contstants';
import { ID } from '../../../constants/common.constants';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { IDispatchProps, IProps, IStateProps } from './modalStackManagement.model';
import './modalStackManagement.style.scss';
import { IState as StoreState } from '../../../redux/store';
import { Spinner } from '../../../components/spinner/Spinner';
import {
    CONFIRMATION_DIALOG_MODAL,
    WARNING,
    CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
    DELETE_STACK_MESSAGE,
} from '../../../constants/messages.constants';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import LinearProgress from '@mui/material/LinearProgress';
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form';
import { validate } from '../../../utils/validate.util';
import { AnyObject, FormApi, FormState } from 'final-form';
import { CANCEL, CLOSE, SAVE } from '../../../constants/common.constants';
import { MAX_LENGTH, REQUIRED, UNIQUE } from '../../../constants/validate.constants';
import StackList from './StackList/StackList';
import {
    deleteStack,
    fetchStackList,
    postStack,
    putStack,
    changeStackOptions,
    clearStackOptions,
    setStackOptions, fetchStackPropertyList,
} from '../../../redux/actions/stackList';
import { IStackOptions, IStackPropertyOptions } from '../../../redux/reducers/stackList/stackList.model';
import {
    getStackList,
    getStackListLoading,
    getStackListUpdating,
    getStackOptions, getStackPropertyList,
} from '../../../redux/selectors/stackList';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import {
    emptySelectOption,
    renderSelectField,
    renderTextField,
} from '../../../components/materialUiForms/materialUiForms';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { initialLabel } from '../../../constants/localization.constants';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../localization/localizationData';
import { getStackLabelsByKey } from '../modalDocumentStack/modalDocumentStack';

const classNames = {
    stackModal: 'stack-modal',
    stackModalFooter: 'stack-modal-footer',
    stackModalBody: 'stack-modal-body',
    stackListWrap: 'stack-list-wrap',
    stackTabsWrap: 'stack-tabs-wrap',
    stackLoader: 'stack-modal-loader',
};

const validateFields = [
    {
        field: NAME,
        [REQUIRED]: true,
        [MAX_LENGTH]: 250,
        [UNIQUE]: true,
    },
    {
        field: DESCRIPTION,
        [REQUIRED]: true,
        [MAX_LENGTH]: 2000,
    },
];

export class ModalStackManagementComponent extends React.Component<IProps> {
    public componentWillUnmount(): void {
        this.props.clearStackOptions();
    }

    public componentDidMount(): void {
        const { redactionDocumentId } = this.props;

        this.props.fetchStackPropertyList();
        this.props.fetchStackList(redactionDocumentId);
    }

    public getUniqueNamesOfStack = (): string[] => {
        const { stackList, currentStack } = this.props;

        return stackList.map((item: IStackOptions) => {
            return currentStack.id === item.id ? '' : item[NAME];
        });
    }

    public render(): JSX.Element {
        const {
            stackListLoading,
            stackListUpdating,
            stackList,
            stackPropertyList,
            currentStack,
            handleDetectChanges,
            modifiedLabels,
            redactionLang,
        } = this.props;
        const labels = {
            cancelLabel: initialLabel,
            closeLabel: initialLabel,
            saveLabel: initialLabel,
            stackPropertiesTitle: initialLabel,
            addNewLabel: initialLabel,
            nameLabel: initialLabel,
            descriptionLabel: initialLabel,
            stackPropertyLabel: initialLabel,
            selectStackLabel: initialLabel,
            mustBeUniqueLabel: initialLabel,
            atLeastOneOfListLabel: initialLabel,
            mandatoryFieldLabel: initialLabel,
            maxLengthLabel: initialLabel,
            minLengthLabel: initialLabel,
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        const activeId = currentStack[ID];
        const uniqueFields = { name: this.getUniqueNamesOfStack() };
        const formatStackPropertyList =
            stackPropertyList.map((stackProperty: IStackPropertyOptions): ISelectOptions => ({
                value: `${stackProperty[ID]}`,
                label: stackProperty[NAME],
            }));
        const formattedStackPropertyList = [
            ...emptySelectOption,
            ...formatStackPropertyList,
        ];

        return (
            <Form
                onSubmit={this.handleSave}
                initialValues={currentStack}
                validate={(values: any): { [param: string]: boolean } => validate(values, validateFields, uniqueFields,
                    labels, langRule)}
                render={({
                                               handleSubmit,
                                               pristine,
                                               submitting,
                                               invalid,
                                               dirty,
                                               form,
                                           }: FormRenderProps): JSX.Element => (
                    <form
                        className={classNames.stackModal}
                        onSubmit={handleSubmit}
                    >
                        <div className={classNames.stackModalBody}>
                            <div className={classNames.stackListWrap}>
                                <Fab
                                    variant='extended'
                                    size='medium'
                                    color='primary'
                                    aria-label='Add'
                                    onClick={(): void => this.handleAddNew(form)}
                                    className='stack-add'
                                >
                                    <AddIcon fontSize={'small'} />
                                        {labels.addNewLabel[langRule]}
                                </Fab>
                                {
                                    stackListLoading ?
                                        <Spinner active={true} /> :
                                        <StackList
                                            list={stackList}
                                            activeId={activeId}
                                            handleClickStack={(item: IStackOptions): void =>
                                                this.handleClickStack(item, form)}
                                            handleDeleteStack={this.handleDeleteStack}
                                        />
                                }
                            </div>
                            <div className={classNames.stackTabsWrap}>
                                {
                                    currentStack[ID] !== null ?
                                        <div>
                                            <Field
                                                render={renderTextField}
                                                name={NAME}
                                                label={labels.nameLabel[langRule]}
                                                margin='normal'
                                                required={true}
                                                variant='outlined'
                                                fullWidth={true}
                                            />
                                            <Field
                                                render={renderTextField}
                                                name={DESCRIPTION}
                                                multiline={true}
                                                required={true}
                                                rows='2'
                                                label={labels.descriptionLabel[langRule]}
                                                margin='normal'
                                                variant='outlined'
                                                fullWidth={true}
                                            />
                                            <div className={'stack-select'}>
                                                <Field
                                                    render={renderSelectField}
                                                    name={STACK_PROPERTY_ID}
                                                    label={labels.stackPropertyLabel[langRule]}
                                                    margin='normal'
                                                    variant='outlined'
                                                    options={formattedStackPropertyList}
                                                    fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div className='stack-empty'>{labels.selectStackLabel[langRule]}</div>
                                }
                            </div>
                        </div>
                        <div className={classNames.stackModalFooter}>
                            <Tooltip title={labels.closeLabel[langRule]} placement='top'>
                                <Button
                                    variant='outlined'
                                    onClick={(): void => this.handleCloseModal(form)}
                                    className='modal-window__buttons outlined'
                                    size='small'
                                >
                                    {labels.cancelLabel[langRule]}
                                </Button>
                            </Tooltip>
                            <Tooltip title={labels.stackPropertiesTitle[langRule]} placement='top'>
                                <span>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        onClick={(): void => this.props.openModalWindow(
                                            STACK_PROPERTIES_MODAL, {id: STACK_PROPERTIES_MODAL,
                                                title: labels.stackPropertiesTitle[langRule]})
                                        }
                                        className='primary'
                                    >
                                        {labels.stackPropertiesTitle[langRule]}
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title={labels.saveLabel[langRule]} placement='top'>
                                <span>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        disabled={submitting || invalid || pristine}
                                        onClick={(): Promise<object | undefined> => handleSubmit()}
                                        className='primary'
                                    >
                                        {labels.saveLabel[langRule]}
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div className={classNames.stackLoader}>
                            {stackListUpdating && <LinearProgress />}
                        </div>
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={(): void => handleDetectChanges(this.isStackOptionsChanged(form))}
                        />
                    </form>
                )}
            />
        );
    }

    private handleAddNew = (form: FormApi): void => {
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (this.isStackOptionsChanged(form)) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => {
                    this.props.setStackOptions(emptyStack);
                    form.reset();
                },
            });
        } else {
            this.props.setStackOptions(emptyStack);
        }
    }

    private handleSave = (values: AnyObject): void => {
        const { redactionDocumentId, currentStack } = this.props;
        const currentId = currentStack[ID];
        const stackOptions = {
            [ID]: currentId,
            [NAME]: values[NAME],
            [DESCRIPTION]: values[DESCRIPTION],
            [STACK_PROPERTY_ID]: values[STACK_PROPERTY_ID] || null,
        };

        if (currentId === EMPTY_STACK_ID) {
            const options = omit(stackOptions, ID);

            this.props.postStack(redactionDocumentId, options);

            return;
        }

        this.props.putStack(redactionDocumentId, stackOptions);
    }

    private isStackOptionsChanged(form: FormApi): boolean {
        const { currentStack, stackList } = this.props;
        const currentId = currentStack[ID];

        if (currentId === null) { // not selected stack
            return false;
        }

        const formValues = form.getState().values;

        const stack = {
            ...formValues,
            [NAME]: get(formValues, NAME, ''),
            [DESCRIPTION]: get(formValues, DESCRIPTION, ''),
            [STACK_PROPERTY_ID]: `${get(formValues, STACK_PROPERTY_ID, '')}`,
        };

        if (currentId === EMPTY_STACK_ID) {
            const emptyStackProperty = {
                ...emptyStack,
                [STACK_PROPERTY_ID]: '',
            };

            return !isEqual(emptyStackProperty, stack);
        }

        const initialStackOptions = stackList
            .find((i: IStackOptions) => currentId === i.id);
        const initialInfo = {
            ...initialStackOptions,
            [NAME]: get(initialStackOptions, NAME, ''),
            [DESCRIPTION]: get(initialStackOptions, DESCRIPTION, ''),
            [STACK_PROPERTY_ID]: `${get(initialStackOptions, STACK_PROPERTY_ID, '')}`,
        };

        return !isEqual(initialInfo, stack);
    }

    private handleClickStack = (item: IStackOptions, form: FormApi): void => {
        const prevItemId = this.props.currentStack[ID];
        const currItemId = item.id;
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (prevItemId === currItemId) {
            return;
        }

        if (this.isStackOptionsChanged(form)) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => this.props.setStackOptions(item),
            });
        } else {
            this.props.setStackOptions(item);
        }
    }

    private handleDeleteStack = (id: number): void => {
        const { redactionDocumentId, currentStack, modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            pressOkLabel: initialLabel,
            deleteStackLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: `Delete-${id}`,
            title: labels.warningLabel[langRule],
            message: <div>{`${labels.deleteStackLabel[langRule]}? ${labels.pressOkLabel[langRule]}`}</div>,
            confirm: (): () => void => (): void => this.props.deleteStack(
                redactionDocumentId,
                id,
                currentStack[ID] === id,
            ),
        });
    }

    private handleCloseModal = (form: FormApi): void => {
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (this.isStackOptionsChanged(form)) {
            const values = { ...form.getState().values };
            const stackOptions = {
                [ID]: values[ID],
                [NAME]: values[NAME],
                [DESCRIPTION]: values[DESCRIPTION],
                [STACK_PROPERTY_ID]: values[STACK_PROPERTY_ID] || null,
            };

            this.props.setStackOptions(stackOptions);

            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => this.props.handleCloseModalWindow(STACK_MANAGMENT_MODAL),
            });
        } else {
            this.props.handleCloseModalWindow(STACK_MANAGMENT_MODAL);
        }
    }
}

const mapStateToProps = (state: StoreState): IStateProps => ({
    currentStack: getStackOptions(state),
    stackListLoading: getStackListLoading(state),
    stackListUpdating: getStackListUpdating(state),
    stackList: getStackList(state),
    stackPropertyList: getStackPropertyList(state),
    redactionDocumentId: getRedactionDocumentId(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, IDispatchProps, AnyAction>): IDispatchProps => ({
    setStackOptions: (data: IStackOptions): void => {
        dispatch(setStackOptions(data));
    },
    changeStackOptions: (data: IStackOptions): void => {
        dispatch(changeStackOptions(data));
    },
    clearStackOptions: (): void => {
        dispatch(clearStackOptions());
    },
    fetchStackList: (redactionDocumentId: number): void => {
        dispatch(fetchStackList(redactionDocumentId));
    },
    postStack: (redactionDocumentId: number, data: IStackOptions): void => {
        dispatch(postStack(redactionDocumentId, data));
    },
    putStack: (redactionDocumentId: number, data: IStackOptions): void => {
        dispatch(putStack(redactionDocumentId, data));
    },
    deleteStack: (redactionDocumentId: number, id: number, needsClearState: boolean): void => {
        dispatch(deleteStack(redactionDocumentId, id, needsClearState));
    },
    fetchStackPropertyList: (): void => {
        dispatch(fetchStackPropertyList());
    },
    handleCloseModalWindow: (id: string): void => {
        dispatch(handleCloseModalWindow(id));
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
});

export const ModalStackManagement = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalStackManagementComponent);

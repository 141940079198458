import {
    GET_REDACTION_DOCUMENT_METADATA_PENDING,
    GET_REDACTION_DOCUMENT_METADATA_SUCCESS,
    GET_REDACTION_DOCUMENT_METADATA_FAILURE,
    GET_USER_PERMISSIONS_FAILURE,
    GET_USER_PERMISSIONS_SUCCESS,
    GET_USER_PERMISSIONS_PENDING,
    GET_REDACTION_AUTH_FAILURE,
    GET_REDACTION_AUTH_PENDING,
    GET_AMANDA_PERMISSIONS_FAILURE,
    GET_AMANDA_PERMISSIONS_PENDING,
    GET_AMANDA_PERMISSIONS_SUCCESS,
    GET_ENTRY_DATA_PENDING,
    GET_ENTRY_DATA_SUCCESS,
    GET_ENTRY_DATA_FAILURE,
} from './constants';
import { IInitializationState } from './initialization.model';
import { AnyAction } from 'redux';

const initialState: IInitializationState = {
    redactionDocumentId: null,
    requestType: '',
    folderRsn: null,
    redactionDocumentLoading: false,
    redactionEntryDataLoading: false,
    redactionDocumentLoaded: false,
    error: null,
    userPermissions: [],
    userId: '',
    userSecurityRole: '',
    userIsAdmin: false,
    userPermissionsLoading: false,
    redactionAuthLoading: false,
    requestNumber: '',
    actCode: null,
};

const initialMetadataReducer = (
    state: IInitializationState = initialState,
    {type, payload}: AnyAction): IInitializationState => {
    switch (type) {
        case GET_REDACTION_DOCUMENT_METADATA_PENDING:
            return {
                ...state,
                redactionDocumentId: null,
                redactionDocumentLoading: true,
                error: null,
                requestNumber: '',
            };
        case GET_REDACTION_DOCUMENT_METADATA_SUCCESS:
            return{
                ...state,
                redactionDocumentId: payload.folderId,
                requestType: payload.requestType,
                folderRsn: payload.folderRsn,
                redactionDocumentLoading: false,
                redactionDocumentLoaded: true,
                requestNumber: payload.requestNumber,
                actCode: payload.actCode,
            };
        case GET_REDACTION_DOCUMENT_METADATA_FAILURE:
            return{
                ...state,
                redactionDocumentLoading: false,
                redactionDocumentLoaded: true,
                error: payload,
            };
        case GET_USER_PERMISSIONS_PENDING:
        case GET_AMANDA_PERMISSIONS_PENDING:
            return {
                ...state,
                userPermissionsLoading: true,
                error: null,
            };
        case GET_USER_PERMISSIONS_SUCCESS:
        case GET_AMANDA_PERMISSIONS_SUCCESS:

            const { permissions, securityRole, userId, hadAdminPermissions } = payload;

            return {
                ...state,
                userPermissionsLoading: false,
                userPermissions: permissions,
                userSecurityRole: securityRole,
                userIsAdmin: hadAdminPermissions,
                userId,
            };
        case GET_USER_PERMISSIONS_FAILURE:
        case GET_AMANDA_PERMISSIONS_FAILURE:
            return {
                ...state,
                userPermissionsLoading: false,
                error: payload,
            };
        case GET_REDACTION_AUTH_PENDING:
            return {
                ...state,
                redactionAuthLoading: true,
                error: null,
            };
        case GET_REDACTION_AUTH_FAILURE:
            return {
                ...state,
                redactionAuthLoading: false,
                error: payload,
            };
        case GET_ENTRY_DATA_PENDING:
            return {
                ...state,
                redactionEntryDataLoading: true,
            };
        case GET_ENTRY_DATA_SUCCESS:
            return {
                ...state,
                redactionEntryDataLoading: false,
            };
        case GET_ENTRY_DATA_FAILURE:
            return {
                ...state,
                redactionEntryDataLoading: false,
                error: payload,
            };
        default:
            return state;
    }
};

export default initialMetadataReducer;

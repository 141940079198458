import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCurrentPage, getCommentLoadingFlag } from '../../redux/selectors/pageList';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { IComment, ICommentContainerProps } from './comment.model';
import { CommentContent } from '../../components/comment/Comment';
import './comment.style.scss';
import { setSelectedCommentInCurrentPage, confirmDeleteComment, setCommentLoadingFlag } from '../../redux/actions/pageList';
import { Spinner } from '../../components/spinner/Spinner';

const Comment = (props: ICommentContainerProps): JSX.Element => {
    const currentPage = useSelector(getCurrentPage);
    const commentLoadingFlag = useSelector(getCommentLoadingFlag);

    const dispatch = useDispatch();

    const handleDelete = (e: React.MouseEvent<Element>, comment: IComment): void => {
        dispatch(confirmDeleteComment(comment));
    };

    useEffect(() => {
        if(commentLoadingFlag) {
            dispatch(setCommentLoadingFlag(false));
        }
    },[props.pdfComments]);

    const removeSelectComment = (): void => {
        if(currentPage.selectedComment) {
            dispatch(setSelectedCommentInCurrentPage(null));
        }
    };

    return (
        <React.Fragment>
            { commentLoadingFlag
                ? <Spinner active={commentLoadingFlag} />
                : currentPage && (props.pdfComments && props.pdfComments.length) > 0 ? props.pdfComments.map(
                        (comment: IComment, index: number): JSX.Element => {
                    return (
                        <div
                            key={index}
                            className='container'
                            onClick={(): void => { dispatch(setSelectedCommentInCurrentPage(comment)); }}
                            onMouseLeave={removeSelectComment}
                        >
                                <IconButton
                                    aria-label={'Delete'}
                                    className='delete-comment'
                                    onClick={(e: React.MouseEvent<Element>): void => { handleDelete(e, comment); }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            <div className='card-container'>
                                <CommentContent {...comment} />
                            </div>

                        </div>
                    );
                    }) : <div className='emptyrows'>{props.noCommentsMsg}</div>

            }
        </React.Fragment>
    );
};

export default Comment;

import { IState } from '../store';
import { createSelector } from 'reselect';
import { IDisclosureTypeState } from 'redux/reducers/disclosureTypes/disclosureType.model';
import { IDisclosureOptions } from '../reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { ID, NAME } from '../../constants/disclosure.contstants';

export const getDisclosureTypes = (state: IState): IDisclosureTypeState => state.disclosureTypes;

export const getAllDisclosureTypesLoading = createSelector(
    [getDisclosureTypes],
    (disclosure: IDisclosureTypeState): boolean => disclosure.disclosureTypePending,
);

export const getAllDisclosureTypesUpdating = createSelector(
    [getDisclosureTypes],
    (disclosure: IDisclosureTypeState): boolean => disclosure.disclosureTypeUpdatePending,
);

export const getAllDisclosureTypesList = createSelector(
    [getDisclosureTypes],
    (disclosure: IDisclosureTypeState): IDisclosureOptions[] => disclosure.disclosureType,
);

export const getCurrentDisclosureTypes = createSelector(
    [getDisclosureTypes],
    (disclosure: IDisclosureTypeState): IDisclosureOptions[] => disclosure.currentDisclosureTypes,
);

export const getCurrentDisclosureTypesLoading = createSelector(
    [getDisclosureTypes],
    (disclosure: IDisclosureTypeState): boolean => disclosure.currentDisclosureTypesPending,
);

export const getDuplicatedDisclosureTypes = createSelector(
    [getDisclosureTypes],
    (disclosure: IDisclosureTypeState): IDisclosureOptions[] => disclosure.duplicatedDisclosureTypes,
);

export const getDuplicatedDisclosureTypesLoading = createSelector(
    [getDisclosureTypes],
    (disclosure: IDisclosureTypeState): boolean => disclosure.duplicatedDisclosureTypesPending,
);

export const getDisclosureTypesAccordance = createSelector(
    [getDisclosureTypes],
    (disclosure: IDisclosureTypeState): Partial<IDisclosureTypeState> => {
        return disclosure.currentDisclosureTypes.reduce(
            (
                obj: Partial<IDisclosureTypeState>,
                item: IDisclosureOptions,
            ) => {
                obj[item[ID]] = item[NAME];

                return obj;
            }, {});
    },
);

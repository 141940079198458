export const WATERMARK_TITLE_MODAL = 'Watermark Management';
export const EMPTY_WATERMARK_ID = 0;

export const emptyWatermark = {
    id: EMPTY_WATERMARK_ID,
    image: null,
    text: '',
    name: '',
    requestType: [],
};

export const BASE64 = 'base64';

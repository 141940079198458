if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength: number, padString: string): string {
        /* tslint:disable: no-bitwise */
        let targetNewLength = targetLength >> 0;
        /* tslint:enable */
        let padToString = String(padString || ' ');

        if (this.length > targetNewLength) {

            return String(this);
        } else {
            targetNewLength = targetNewLength - this.length;

            if (targetNewLength > padToString.length) {
                padToString += padToString.repeat(targetNewLength/padString.length);
            }

            return padToString.slice(0, targetNewLength) + String(this);
        }
    };
}

import {
    FETCH_DOCUMENT_LIST_PENDING,
    FETCH_DOCUMENT_LIST_SUCCESS,
    FETCH_DOCUMENT_STACK_LIST_SUCCESS,
    FETCH_DOCUMENT_LIST_FAILURE,
    ADD_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_PAGE_COUNT,
    DELETE_DOCUMENT_PENDING,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAILURE,
    SET_DISCLOSURE_TYPE_FOR_DOCUMENT,
    SET_DOCUMENT_PAGINATION_PENDING,
    SET_DOCUMENT_PAGINATION_SUCCESS,
    SET_DOCUMENT_PAGINATION_FAILURE,
    SET_PAGINATION_PENDING,
    SET_PAGINATION_SUCCESS,
    SET_PAGINATION_FAILURE,
    UNDO_PAGINATION_PENDING,
    UNDO_PAGINATION_SUCCESS,
    UNDO_PAGINATION_FAILURE,
    UPDATE_DOCUMENT_PAGINATION,
    OCR_DOCUMENT_PENDING,
    OCR_DOCUMENT_SUCCESS,
    OCR_DOCUMENT_FAILURE,
    UPDATE_DOCUMENT_INFO,
    UPDATE_PAGINATION_PENDING,
    UPDATE_PAGINATION_SUCCESS,
    UPDATE_PAGINATION_FAILURE,
    UPDATE_DOCUMENTS_SUCCESS,
    SAVE_STACK_ID, UPDATE_DOCUMENTS_BY_LIST,
    SET_DOCUMENT_INITIAL_STATE,
    FETCH_DOCUMENT_EXPORT_LIST_SUCCESS,
    CHANGE_DOCUMENT_DISCLOSURE_PENDING,
    CHANGE_DOCUMENT_DISCLOSURE_COMPLETE,
    SET_DOCUMENT_LIST_COLUMN_HEADER_PENDING,
    SET_DOCUMENT_LIST_COLUMN_HEADER_LIST_SUCCESS,
    SET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE,
    MODIFY_DOCUMENT_LIST_COLUMN_HEADER_FAILURE,
    MODIFY_DOCUMENT_LIST_COLUMN_HEADER_PENDING,
    MODIFY_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS,
    SET_DOCUMENT_LIST_TABLE_COLUMN,
    RESET_DOCUMENT_LIST_COLUMN_HEADER_PENDING,
    RESET_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS,
    RESET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE,
} from './constant';
import { updateArrayWithArrayByKey } from '../../../utils/array.utils';
import { ID } from '../../../constants/disclosure.contstants';
import { DocumentListState, IFile } from './documentList.model';
import { AnyAction } from 'redux';
import { sortBy, difference } from 'lodash';
import { IStackOptions } from '../stackList/stackList.model';
import { UPDATE_ALL_DOCUMENTS_IN_LIST } from '../disclosureTypes/constants';

const initialState: DocumentListState = {
    files: [],
    exportFiles: [],
    currentDocument: null,
    currentPage: null,
    loading: false,
    loadingPagination: [],
    loaded: false,
    loadingCurrentDoc: false,
    loadedCurrentDoc: false,
    loadingDeleting: false,
    loadingOcr: [],
    stackId: null,
    error: null,
    fileDisclosureLoading: [],
    documentListColumnHeaderPending: false,
    documentListColumnHeader: [],
    modifyDocumentListTableColumnLoading: false,
    resetDocumentListColumnHeaderPending: false,
};

const documentListReducer = (state: DocumentListState = initialState,
                             { type, payload }: AnyAction): DocumentListState => {
    switch (type) {
        case FETCH_DOCUMENT_LIST_PENDING:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DOCUMENT_STACK_LIST_SUCCESS:
            return {
                ...state,
                files: payload.map((document: IFile) => {
                    const { stacks }: { stacks: IStackOptions[] } = document;

                    return { ...document, stacks: sortBy(stacks, 'id') };
                }),
                loading: false,
                loaded: true,
            };
        case FETCH_DOCUMENT_LIST_SUCCESS:
            return {
                ...state,
                files: payload,
                loading: false,
                loaded: true,
            };
        case FETCH_DOCUMENT_EXPORT_LIST_SUCCESS:
            return {
                ...state,
                exportFiles: payload,
                loading: false,
                loaded: true,
            };
        case FETCH_DOCUMENT_LIST_FAILURE:
            return {
                ...state,
                files: [],
                loading: false,
                loaded: false,
            };
        case DELETE_DOCUMENT_PENDING:
            return {
                ...state,
                loadingDeleting: true,
            };
        case DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                files: state.files.reduce((result: IFile[], file: IFile) => {
                    return payload.includes(file.id) ? [...result] : [
                        ...result,
                        file,
                    ];
                }, []),
                currentDocument: null,
                loadingDeleting: false,
            };
        case DELETE_DOCUMENT_FAILURE:
            return {
                ...state,
                loadingDeleting: false,
            };
        case OCR_DOCUMENT_PENDING:
            return {
                ...state,
                loadingOcr: [...payload],
            };
        case OCR_DOCUMENT_SUCCESS:
        case OCR_DOCUMENT_FAILURE:
            return {
                ...state,
                loadingOcr: [],
            };
        case UPDATE_DOCUMENT_PAGE_COUNT:
            return {
                ...state,
                files: state.files.map((file: IFile) =>
                    file.id !== payload.docId ? file : { ...file, pageCount: payload.pageCount }),
            };
        case ADD_DOCUMENT_SUCCESS:
            return {
                ...state,
                files: [...state.files, ...payload],
            };
        case SET_DISCLOSURE_TYPE_FOR_DOCUMENT:
            const { docId, disclosureTypeName } = payload;
            const newFiles = state.files.map((file: IFile) => {
                if (file.id === docId) {
                    file.disclosureType = disclosureTypeName;
                }

                return file;
            });

            return {
                ...state,
                files: newFiles,
            };
        case SET_PAGINATION_PENDING:
        case SET_DOCUMENT_PAGINATION_PENDING:
            return {
                ...state,
                loadingPagination: payload,
                error: null,
            };
        case UPDATE_DOCUMENTS_BY_LIST:
            return {
                ...state,
                files: updateArrayWithArrayByKey<IFile, keyof IFile>(state.files, payload, ID),
            };
        case SET_DOCUMENT_PAGINATION_SUCCESS:
            return {
                ...state,
                files: updateArrayWithArrayByKey<IFile, keyof IFile>(state.files, payload, ID),
                loadingPagination: [],
            };
        case UPDATE_DOCUMENT_PAGINATION:
            const files = state.files.map((file: IFile) => {
                if (file.id === payload.id) {
                    return {
                        ...file,
                        paginationRange: payload.paginationRange,
                    };
                }

                return file;
            });

            return {
                ...state,
                files,
                loadingPagination: [],
            };
        case UPDATE_ALL_DOCUMENTS_IN_LIST:
            return {
                ...state,
                files: state.files.map((file: IFile): any => {
                    const res = payload.find((updatedFile: IFile) => updatedFile.id === file.id);

                    return res ? { ...file, ...res } : file;
                }),
            };
        case UPDATE_DOCUMENT_INFO:
            return {
                ...state,
                files: state.files.map((file: IFile) => {
                    if (file.id === payload.id) {
                        return {
                            ...file,
                            ...payload,
                        };
                    }

                    return file;
                }),
                loadingPagination: [],
                fileDisclosureLoading: [],
            };
        case SET_PAGINATION_SUCCESS:
            return {
                ...state,
                files: payload,
                loadingPagination: [],
            };
        case SET_PAGINATION_FAILURE:
        case SET_DOCUMENT_PAGINATION_FAILURE:
            return {
                ...state,
                error: payload,
                loadingPagination: [],
            };
        case UNDO_PAGINATION_PENDING:
        case UPDATE_PAGINATION_PENDING:
            return {
                ...state,
                loadingPagination: payload,
            };
        case UNDO_PAGINATION_SUCCESS:
            return {
                ...state,
                files: state.files.map((file: IFile): IFile => payload.includes(file.id) ? {
                    ...file,
                    paginationRange: {
                        from: null,
                        to: null,
                    },
                } : file),
                loadingPagination: [],
            };
        case UPDATE_PAGINATION_FAILURE:
        case UNDO_PAGINATION_FAILURE:
            return {
                ...state,
                loadingPagination: [],
            };
        case UPDATE_PAGINATION_SUCCESS:
            return {
                ...state,
                files: state.files.map((file: IFile): IFile => file.id === payload.documentId ? {
                    ...file,
                    paginationRange: payload.paginationRange,
                } : file),
                loadingPagination: [],
            };
        case UPDATE_DOCUMENTS_SUCCESS:

            if (payload.currentSelectedStackId && payload.currentSelectedStackId !== 0 && payload.documents.stackIds
                && (!payload.documents.stackIds.length ||
                    !payload.documents.stackIds.includes(payload.currentSelectedStackId))) {
                return {
                    ...state,
                };
            }

            const currentIds = state.files.map((file: IFile): number => file.id);

            return {
                ...state,
                files: currentIds.includes(payload.documents.id) ?
                    state.files.map((file: IFile) =>
                        file.id === payload.documents.id ? { ...file, ...payload.documents } : file) :
                    [...state.files, payload.documents],

            };
        case SAVE_STACK_ID:
            return {
                ...state,
                stackId: payload,
            };
        case CHANGE_DOCUMENT_DISCLOSURE_PENDING:
            return {
                ...state,
                fileDisclosureLoading: [...payload],
            };
        case CHANGE_DOCUMENT_DISCLOSURE_COMPLETE:
            return {
                ...state,
                fileDisclosureLoading: difference(state.fileDisclosureLoading, payload),
            };
        case SET_DOCUMENT_INITIAL_STATE:
            return {
                ...initialState,
                ...(state.documentListColumnHeader.length &&
                    { documentListColumnHeader: state.documentListColumnHeader }),
            };
        case SET_DOCUMENT_LIST_TABLE_COLUMN:
            return {
                ...state,
                documentListColumnHeader: payload,
            };
        case SET_DOCUMENT_LIST_COLUMN_HEADER_PENDING:
            return {
                ...state,
                documentListColumnHeaderPending: true,
            };
        case SET_DOCUMENT_LIST_COLUMN_HEADER_LIST_SUCCESS:
            return {
                ...state,
                documentListColumnHeader: payload,
                documentListColumnHeaderPending: false,
            };
        case SET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                documentListColumnHeader: [],
                documentListColumnHeaderPending: false,
                error: payload,
            };
        case MODIFY_DOCUMENT_LIST_COLUMN_HEADER_PENDING:
            return {
                ...state,
                modifyDocumentListTableColumnLoading: true,
            };
        case MODIFY_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS:
            return {
                ...state,
                documentListColumnHeader: payload,
                modifyDocumentListTableColumnLoading: false,
            };
        case MODIFY_DOCUMENT_LIST_COLUMN_HEADER_FAILURE:
        case RESET_DOCUMENT_LIST_COLUMN_HEADER_PENDING:
            return {
                ...state,
                resetDocumentListColumnHeaderPending: true,
            };
        case RESET_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS:
            return {
                ...state,
                documentListColumnHeader: payload,
                resetDocumentListColumnHeaderPending: false,
            };
        case RESET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                resetDocumentListColumnHeaderPending: false,
                error: payload,
            };
        default:
            return state;
    }
};

export default documentListReducer;

export const SET_ADVANCE_SEARCH_PARAM = 'reduction/SET_ADVANCE_SEARCH_PARAM';
export const CLEAR_ADVANCE_SEARCH_PARAM = 'reduction/CLEAR_ADVANCE_SEARCH_PARAM';
export const UPDATE_ADVANCE_SEARCH_ERRORS = 'reduction/UPDATE_ADVANCE_SEARCH_ERRORS';
export const ADVANCE_SEARCH_VALUE_PENDING = 'reduction/ADVANCE_SEARCH_VALUE_PENDING';
export const ADVANCE_SEARCH_VALUE_FAILURE = 'reduction/ADVANCE_SEARCH_VALUE_FAILURE';
export const ADVANCE_SEARCH_VALUE_SUCCESS = 'reduction/ADVANCE_SEARCH_VALUE_SUCCESS';
export const SET_ACTS_LIST = 'reduction/SET_ACTS_LIST';
export const SET_EXEMPTIONS_LIST = 'reduction/SET_EXEMPTIONS_LIST';
export const CLEAR_ADVANCE_SEARCH_RESULT = 'redaction/CLEAR_ADVANCE_SEARCH_RESULT';

export const SET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING = 'redaction/SET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING';
export const SET_ADVANCE_SEARCH_COLUMN_HEADER_LIST_SUCCESS = 'redaction/SET_ADVANCE_SEARCH_COLUMN_HEADER_LIST_SUCCESS';
export const SET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE = 'redaction/SET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE';

export const SET_ADVANCE_SEARCH_TABLE_COLUMNS = 'reduction/SET_ADVANCE_SEARCH_TABLE_COLUMNS';

export const MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_PENDING = 'redaction/MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_PENDING';
export const MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS = 'redaction/MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS';
export const MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE = 'redaction/MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE';

export const RESET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING = 'redaction/RESET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING';
export const RESET_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS = 'redaction/RESET_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS';
export const RESET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE = 'redaction/RESET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE';

export const SET_SELECTED_ADVANCE_SEARCHED_DOCUMENT = 'redaction/SET_SELECTED_ADVANCE_SEARCHED_DOCUMENT';

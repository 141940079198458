import { createSelector } from 'reselect';
import { IState } from '../store';
import { IIndexMetadata, ILookupIndexMetadata } from '../reducers/indexMetadata/indexMetadata.model';
import { ISelectOptions } from '../../components/materialUiForms/marerialUiForms.model';
import { LANGUAGE, CLASSIFICATION, DOCUMENT_TYPE, SUBJECT } from '../../constants/advancedSearch.constants';
import { IHeader } from '../../constants/leftSidebar.config';
import { getRedactionLanguage } from './localStorage';

export const getIndexMetaDataPending = (state: IState): boolean => state.indexMetadata.pending;

export const getIndexMetadataData = (state: IState): IIndexMetadata[]  => state.indexMetadata.metadata;

export const getIndexMetadataDataLanguage = createSelector(
    [getIndexMetadataData],
    (metaData: IIndexMetadata[]): IIndexMetadata => metaData && metaData.length ?
        metaData.filter((meta: IIndexMetadata) => meta.description === LANGUAGE)[0] :
        null,
);

export const getLanguageMetaData = createSelector(
    [getIndexMetadataDataLanguage, getRedactionLanguage],
    (languageMetaData: IIndexMetadata, redactionLang: string): ISelectOptions[] =>
        languageMetaData && languageMetaData.lookups && languageMetaData.lookups.length ?
            languageMetaData.lookups.map((lookup: ILookupIndexMetadata): ISelectOptions => {
                if( redactionLang === 'en') {
                    return {
                        label: lookup.description,
                        value: `${lookup.id}`,
                    };
                } else {
                    return {
                        label: lookup.description2 ? lookup.description2 : lookup.description,
                        value: `${lookup.id}`,
                    };
                }
            }) : [],
);

export const getIndexMetadataDataClassification = createSelector(
    [getIndexMetadataData],
    (metaData: IIndexMetadata[]): IIndexMetadata => metaData && metaData.length ?
        metaData.filter((meta: IIndexMetadata) => meta.description === CLASSIFICATION)[0] :
        null,
);

export const getClassificationMetaData = createSelector(
    [getIndexMetadataDataClassification, getRedactionLanguage],
    (classificationMetaData: IIndexMetadata, redactionLang: string): ISelectOptions[] =>
        classificationMetaData && classificationMetaData.lookups && classificationMetaData.lookups.length ?
            classificationMetaData.lookups.map((lookup: ILookupIndexMetadata): ISelectOptions => {
                if( redactionLang === 'en') {
                    return {
                        label: lookup.description,
                        value: `${lookup.id}`,
                    };
                } else {
                    return {
                        label: lookup.description2 ? lookup.description2 : lookup.description,
                        value: `${lookup.id}`,
                    };
                }
            }) : [],
);

export const getIndexMetadataDataDocumentType = createSelector(
    [getIndexMetadataData],
    (metaData: IIndexMetadata[]): IIndexMetadata => metaData && metaData.length ?
        metaData.filter((meta: IIndexMetadata) => meta.description === DOCUMENT_TYPE)[0] :
        null,
);

export const getDocumentTypeMetaData = createSelector(
    [getIndexMetadataDataDocumentType, getRedactionLanguage],
    (documentTypeMetaData: IIndexMetadata, redactionLang: string): ISelectOptions[] =>
        documentTypeMetaData && documentTypeMetaData.lookups && documentTypeMetaData.lookups.length ?
            documentTypeMetaData.lookups.map((lookup: ILookupIndexMetadata): ISelectOptions => {
                if( redactionLang === 'en') {
                    return {
                        label: lookup.description,
                        value: `${lookup.id}`,
                    };
                } else {
                    return {
                        label: lookup.description2 ? lookup.description2 : lookup.description,
                        value: `${lookup.id}`,
                    };
                }
            }) : [],
);

export const getIndexMetadataDataSubject = createSelector(
    [getIndexMetadataData],
    (metaData: IIndexMetadata[]): IIndexMetadata => metaData && metaData.length ?
        metaData.filter((meta: IIndexMetadata) => meta.description === SUBJECT)[0] :
        null,
);

export const getSubjectMetaData = createSelector(
    [getIndexMetadataDataSubject],
    (subjectMetaData: IIndexMetadata): ISelectOptions[] =>
        subjectMetaData && subjectMetaData.lookups && subjectMetaData.lookups.length ?
            subjectMetaData.lookups.map((lookup: ILookupIndexMetadata): ISelectOptions => ({
                label: lookup.description,
                value: `${lookup.id}`,
            })) : [],
);

export const getCustomFieldsForTableHeaders = createSelector(
    [getIndexMetadataData],
    (metaData: IIndexMetadata[]): IHeader[] =>
        metaData.reduce((acc: IHeader[], meta: IIndexMetadata): IHeader[] => {
            return !meta.defaultIndexMetadata ? [
                ...acc,
                {
                    accessor: `customFields.${meta.name}`,
                    id: meta.name,
                    Header: meta.name,
                    minResizeWidth: 1,
                    show: true,
                },
            ] : acc;
        }, []));

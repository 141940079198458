import * as React from 'react';
import './scanner.styles.scss';
import { range, noop, first, last } from 'lodash';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CheckboxMaterialBuilder } from '../../../../components/materialUiForms/materialUiFormBuilder';
import { compose } from '../../../../utils/compose.util';
import Button from '@mui/material/Button';
import {
    WEB_TWAIN_ID,
} from './scanner.constants';
import {
    IDynamicWebTwainCapability,
    IDynamicWebTwainCapabilityType,
    IDynamicWebTwainImageType,
    IDynamicWebTwainPixelType, IDynamsoft,
    IScannerProps, IScannerState, IWebTwain,
} from './scanner.model';

export default class Scanner extends React.Component<IScannerProps, IScannerState> {
    public state: IScannerState  = {
        selectedScanner: null,
        selectedResolution: null,
        selectedColor: this.props.labels.balckAndWhiteLabel[this.props.langRule],
        selectedMode: this.props.labels.multiPageLabel[this.props.langRule],
        isCustomSettings: false,
        isFeeder: true,
        isDuplex: false,
        scannedFiles: [],
    };
    private webTwain: IWebTwain;
    private availableScanners: string[] = [];
    private availableResolutions: number[] = [];

    public async componentDidMount(): Promise<void> {
        if(this.dynamicSoft) {
            this.init();

            return;
        }

        const { configURL, initialURL } = this.props;

        await this.addScript(configURL);
        await this.addScript(initialURL);

        this.dynamicSoft.WebTwainEnv.RegisterEvent('OnWebTwainReady', () => {
            this.init();
        });
    }

    public render(): JSX.Element {
        const{ labels, langRule} = this.props;
        const colors = [labels.balckAndWhiteLabel[langRule], labels.colorGrayLabel[langRule],
            labels.scannerColorLabel[langRule]];
        const scanModes = [labels.multiPageLabel[langRule], labels.multiBatchLabel[langRule]];

        return (
            <section className='scanner'>
                <div id={WEB_TWAIN_ID} />
                { this.webTwain &&
                    <div className='scanner_wrapper'>
                        <div>
                            <div className='row'>
                                <div className='row_title'>{labels.selectScannerLabel[langRule]}</div>
                                <Select
                                    className='row_select'
                                    value={this.state.selectedScanner}
                                    renderValue={(): string => this.state.selectedScanner}
                                >
                                    {
                                        this.availableScanners.length && <div>
                                            {this.availableScanners.map((option: string) => (
                                                <MenuItem
                                                    key={option}
                                                    value={option}
                                                    onClick={(): void => {
                                                        this.setState({selectedScanner: option});
                                                        this.getScannerCapabilities(option);
                                                    }}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    }
                                </Select>
                            </div>
                            {
                                 this.creteCheckBoxRow(labels.customSettingsLabel[langRule], 'isCustomSettings')
                            }
                            {
                                this.state.isCustomSettings &&
                                <div className='custom_settings'>
                                    <div className='row'>
                                        <div className='row_title'>{labels.resolutionLabel[langRule]}</div>
                                        <Select
                                            className='row_select'
                                            value={this.state.selectedResolution}
                                            renderValue={(): string => String(this.state.selectedResolution)}
                                            onClick={(e: React.BaseSyntheticEvent): void => {
                                                if(e.target.value) {
                                                    this.setState({selectedResolution: e.target.value});
                                                }
                                            }}
                                        >
                                            {
                                                this.availableResolutions.map(this.createMenuItem)
                                            }
                                        </Select>
                                    </div>
                                    <div className='row'>
                                        <div className='row_title'>{labels.colorDepthLabel[langRule]}</div>
                                        <Select
                                            className='row_select'
                                            value={this.state.selectedColor}
                                            renderValue={(): string => this.state.selectedColor}
                                            onClick={(e: React.BaseSyntheticEvent): void => {
                                                if(e.target.value) {
                                                    this.setState({selectedColor: e.target.value});
                                                }
                                            }}
                                        >
                                            {
                                                colors.map(this.createMenuItem)
                                            }
                                        </Select>
                                    </div>
                                    {
                                        this.webTwain.Duplex
                                            ? this.creteCheckBoxRow(labels.duplexLabel[langRule], 'isDuplex') : null
                                    }
                                    {
                                        this.webTwain.IfAutoFeed
                                            ? this.creteCheckBoxRow(labels.autoFeederLabel[langRule], 'isFeeder')
                                            : null
                                    }
                                    {
                                        this.webTwain.IfAutoFeed && this.state.isFeeder && <div className='row'>
                                            <div className='row_title'>{labels.scannerModeLabel[langRule]}</div>
                                            <Select
                                                className='row_select'
                                                value={this.state.selectedMode}
                                                renderValue={(): string => this.state.selectedMode}
                                                onClick={(e: React.BaseSyntheticEvent): void => {
                                                    if(e.target.value) {
                                                       this.setState({selectedMode: e.target.value});
                                                    }
                                                }}
                                            >
                                                {
                                                    scanModes.map(this.createMenuItem)
                                                }
                                            </Select>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div>
                            <div className='row row-button'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='medium'
                                    className='button-import primary'
                                    onClick={this.scan}
                                >
                                    {labels.scanLabel[langRule]}
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </section>
        );
    }

    private init(): void {
        this.setWebTwain();
        this.setAvailableScanners();
        this.forceUpdate();
    }

    private completeScanWithFeeder = async (): Promise<void> => {
        if (this.state.selectedMode === this.props.labels.multiPageLabel[this.props.langRule]) {
            await this.convertBlob(range(this.webTwain.HowManyImagesInBuffer), false);
        }

        if (this.state.selectedMode === this.props.labels.multiBatchLabel[this.props.langRule]) {
            const pagesWithEmptyPage = [];

            for(let index = 0; index < this.webTwain.HowManyImagesInBuffer; index++) {
                if (this.webTwain.IsBlankImageExpress(index)) {
                    pagesWithEmptyPage.push(true);
                } else {
                    pagesWithEmptyPage.push(index);
                }
            }

            const stringedArray = pagesWithEmptyPage
                .join('')
                .split('true');
            const stringedRange = stringedArray.map((item: string) => item.split(','));
            const ranges = stringedRange
                .map((item: string[]) => first(item)
                    .split('')
                    .map((element: string) => Number(element)),
                )
                .filter((arr: number[]) => arr.length);
            const delayedConvert = async (item: number[]): Promise<void> => {
                await this.convertBlob(item, false);
            };

            const asyncProcessArray = async (arr: number[][]): Promise<void> => {
                const promises = arr.map(delayedConvert);

                await Promise.all(promises);
            };

            await asyncProcessArray(ranges);
        }

        this.completeScanning();
        this.webTwain.RemoveAllImages();
    }

    private scan = (): void => {
        this.webTwain.IfShowUI = !this.state.isCustomSettings;
        this.webTwain.IfDisableSourceAfterAcquire = true;

        // HIDE THE PROGRESS BAR WHICH BROKE OUR CSS
        this.webTwain.IfShowProgressBar = false;
        this.webTwain.SelectSourceByIndex(this.availableScanners.indexOf(this.state.selectedScanner));
        this.webTwain.OpenSource();

        if (this.state.isCustomSettings && !this.state.isFeeder) {
            this.setScannerSettings();
            this.webTwain.AcquireImage(this.convertBlob, noop);
        } else if (this.state.isCustomSettings && this.state.isFeeder && this.state.selectedMode) {
            this.setScannerSettings();
            this.webTwain.RegisterEvent('OnPostAllTransfers',() => {
               this.completeScanWithFeeder();
            });
            this.webTwain.AcquireImage();
        } else {
            this.webTwain.RegisterEvent('OnPostAllTransfers',() => {
                this.convertBlob(range(this.webTwain.HowManyImagesInBuffer), true);
            });
            this.webTwain.RegisterEvent('OnSourceUIClose',() => {
                this.getScannerCapabilities(this.state.selectedScanner);
            });
            this.webTwain.AcquireImage();
        }
    }

    private setAvailableScanners(): void {
        this.availableScanners = range(this.webTwain.SourceCount)
            .map((item: number) => this.webTwain.GetSourceNameItems(item));
        this.setState({selectedScanner: first(this.availableScanners)});
        this.getScannerCapabilities(first(this.availableScanners));
    }

    private completeScanning = (): void => {
        const customEvent: any = ({target: {files: {}}});

        this.state.scannedFiles.forEach((item: File, i: number): void => {
            customEvent.target.files = {...customEvent.target.files, [i]: item, length: i + 1};
        });
        this.props.openFile(customEvent);
    }

    private get dynamicSoft(): IDynamsoft {
        return window.Dynamsoft;
    }

    private get dynamicWebTwainCapability(): IDynamicWebTwainCapability {
        return window.EnumDWT_Cap;
    }

    private get dynamicWebTwainCapabilityType(): IDynamicWebTwainCapabilityType {
        return window.EnumDWT_CapType;
    }

    private get dynamicWebTwainPixelType(): IDynamicWebTwainPixelType {
        return window.EnumDWT_PixelType;
    }

    private get dynamicWebTwainImageType(): IDynamicWebTwainImageType {
        return window.EnumDWT_ImageType;
    }

    private setWebTwain(): void {
        this.webTwain = this.dynamicSoft.WebTwainEnv.GetWebTwain(WEB_TWAIN_ID);
    }

    private getScannerCapabilities = (selectedScanner: string): void => {
        this.webTwain.SelectSourceByIndex(this.availableScanners.indexOf(selectedScanner));
        this.webTwain.OpenSource();

        // get resolution
        this.webTwain.Capability = this.dynamicWebTwainCapability.ICAP_XRESOLUTION;
        this.webTwain.CapGet();

        if (this.webTwain.CapType === this.dynamicWebTwainCapabilityType.TWON_ENUMERATION) {
            this.availableResolutions = range(this.webTwain.CapNumItems)
                .map((item: number): number => this.webTwain.GetCapItems(item));
            this.state.selectedResolution =
                this.availableResolutions[Math.round(this.availableResolutions.length / 2)];
        }

        if (this.webTwain.CapType === this.dynamicWebTwainCapabilityType.TWON_RANGE) {
            this.availableResolutions = range(this.webTwain.CapMinValue, this.webTwain.CapMaxValue, 100);
            this.state.selectedResolution =
                this.availableResolutions[Math.round(this.availableResolutions.length / 2)];
        }
    }

    private setScannerSettings(): void {
        this.webTwain.Resolution = Number(this.state.selectedResolution);
        this.setScanColor();

        if (this.webTwain.Duplex) {
            this.webTwain.IfDuplexEnabled = this.state.isDuplex;
        }

        if (this.webTwain.IfAutoFeed) {
            this.webTwain.IfFeederEnabled = this.state.isFeeder;
        }
    }

    private setScanColor(): void {
        const color = this.state.selectedColor;

        if (color === this.props.labels.balckAndWhiteLabel[this.props.langRule]) {
            this.webTwain.PixelType = this.dynamicWebTwainPixelType.TWPT_BW;
        } else if (color === this.props.labels.colorGrayLabel[this.props.langRule]) {
            this.webTwain.PixelType = this.dynamicWebTwainPixelType.TWPT_GRAY;
        } else if (color === this.props.labels.scannerColorLabel[this.props.langRule]) {
            this.webTwain.PixelType = this.dynamicWebTwainPixelType.TWPT_RGB;
        }
    }

    private convertBlob = (itemsRange: number[] = [this.webTwain.CurrentImageIndexInBuffer],
                           shouldCompleteScanning: boolean = true): Promise<void> => {
        return new Promise((resolve: () => void): void => {
            this.webTwain.ConvertToBlob(
                itemsRange,
                this.dynamicWebTwainImageType.IT_PDF,
                compose(
                    this.blobToFile,
                    (file: File) => {
                        this.state.scannedFiles.push(file);

                        if(shouldCompleteScanning) {
                            this.forceUpdate();
                            this.completeScanning();
                            this.webTwain.RemoveAllImages();
                        }

                        resolve();
                    },
                ),
                noop,
            );
        });
    }

    private creteCheckBoxRow(title: string, control: string): JSX.Element {
        return (
            <div className='row'>
                <div className='row_title row_title-long'>{title}</div>
                <CheckboxMaterialBuilder
                    checked={this.state[control]}
                    handleCheckboxChange={(): void => {
                        const newState: any = {[control]: !this.state[control]};

                        this.setState(newState);
                    }}
                    paramName='title'
                />
            </div>
        );
    }

    private createMenuItem = (option: string | number): JSX.Element => {
        return (
            <MenuItem
                key={option}
                value={option}
            >
                {option}
            </MenuItem>
        );
    }

    private addScript(src: string): Promise<void> {
        const script = document.createElement( 'script' );

        script.setAttribute( 'src', src );
        document.body.appendChild(script);

        return new Promise((resolve: () => void): void => {
            script.onload = resolve;
        });
    }

    private blobToFile = (blob: Blob): File => {
        // Workaround for IE
        const copiedBlob: any = blob;

        copiedBlob.lastModifiedDate = new Date();
        copiedBlob.name = `Document_${this.state.scannedFiles.length + 1}.pdf`;

        return (blob as File);
    }
}

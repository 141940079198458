import { AnyAction } from 'redux';
import {
    GET_AVAILABLE_RANGE_FAIL,
    GET_AVAILABLE_RANGE_SUCCESS,
    GET_AVAILABLE_RANGE_PENDING,
    UPDATE_PAGINATION_INDEX_PENDING,
    UPDATE_PAGINATION_INDEX_SUCCESS,
    UPDATE_PAGINATION_INDEX_FAIL,
} from './constant';
import { IModalEditPaginationState } from './modalEditPagination.model';

const initialState: IModalEditPaginationState = {
    paginationAvailableRangeLoading: false,
    updatePagination: false,
    paginationAvailableRange: [],
};

const modalWindowExportReducer = (state: IModalEditPaginationState = initialState,
                                  {type, payload}: AnyAction): IModalEditPaginationState => {
  switch (type) {
      case GET_AVAILABLE_RANGE_PENDING:
          return {
              ...state,
              paginationAvailableRangeLoading: true,
          };
      case GET_AVAILABLE_RANGE_FAIL:
          return {
              ...state,
              paginationAvailableRangeLoading: false,
          };
      case GET_AVAILABLE_RANGE_SUCCESS:
          return {
              ...state,
              paginationAvailableRangeLoading: false,
              paginationAvailableRange: payload,
          };
      case UPDATE_PAGINATION_INDEX_PENDING:
          return {
              ...state,
              updatePagination: true,
          };
      case UPDATE_PAGINATION_INDEX_SUCCESS:
      case UPDATE_PAGINATION_INDEX_FAIL:
          return {
              ...state,
              updatePagination: false,
          };
      default:
          return state;
  }
};

export default modalWindowExportReducer;

import * as React from 'react';
import 'react-contexify/dist/ReactContexify.min.css';
import { Item } from 'react-contexify';
import { IContextListItemProps } from '../contextMenu.model';

const ListItem = ({
    elem,
    handleClick,
}: IContextListItemProps): JSX.Element => (
    <div className={'list-item__element'}>
        <div className={`react-contexify__item ${elem.disabled ? 'react-contexify__item--disabled' : ''}`}>
            <Item onClick={(): void => handleClick(elem)}>
                {elem.label}
            </Item>
        </div>
    </div>
);

export default ListItem;

import * as React from 'react';
import Submenus from './submenus/Submenus';
import { HeaderMainMenus, IMenu } from '../../constants/headerMenu.constants';
import { IState } from './header.model';
import { connect } from 'react-redux';
import { IProps, IStateProps, IDispatchProps } from './header.model';
import { IState as StoreState } from '../../redux/store';
import { getDocument } from '../../redux/selectors/redactor';
import { getPageInformation } from '../../redux/selectors/pageList';
import {
    getCurrentDocument,
    getCurrentPage,
    getSelectedDocuments,
    getSelectedPages,
} from '../../redux/selectors/pageList';
import { openModalWindow } from '../../redux/actions/modal';
import { getRedactionDocumentId } from '../../redux/selectors/initialization';
import { LEFT_ROTATE_PAGE, RIGHT_ROTATE_PAGE } from '../../constants/headerMenu.constants';
import { degrees } from '../../constants';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { Dispatch } from 'redux';
import { getHeaderActiveMenu, getHeaderMenu } from '../../redux/selectors/header';
import { clearHeaderActiveItem } from '../../redux/actions/header';
import { getRedactionRequestNumber } from '../../redux/selectors/initialization';
import './header.styles.scss';
import { getModifiedLabels } from '../../redux/selectors/localization';
import { getRedactionLanguage, getRedactionMode } from '../../redux/selectors/localStorage';
import { initialLabel } from '../../constants/localization.constants';
import resourceBundle from '../localization/localizationData';
import { REQUEST_SEARCH_MODAL } from '../../constants/modalRequestSearch.constants';
import { USER_PREFERENCE_MODAL } from '../../constants/modalUserPreference.constants';
import { ASSOCIATE_WATERMARK_TO_FOLDER, ANNOTATION_TYPE_MANAGEMENT, DISCLOSURE_MANAGEMENT, DOCUMENT_INDEX, PACKAGE_PRESETS, USER_DEFINED_STAMP_MANAGEMENT, ENCRYPTION,  REQUEST_SECURITY, WATERMARK_MANAGEMENT, REPLACE_DISCLOSURE, GLOBAL_SETTINGS } from '../../constants/modal.constants';
import { EXPORT_ID } from '../../constants/common.constants';
import { changeLang } from '../../redux/actions/localization';
import { PAGE_REVIEW_SUMMARY_MODAL, REQUESTER_REPORT_MODAL } from '../modalWindowContents';
import { MODE_CONSULT, MODE_FULL } from '../../redux/reducers/localStorage/constant';
import { EDIT_FILE_NAME_MODAL } from '../../constants/messages.constants';
import { STACK_MANAGMENT_MODAL } from '../../constants/stack.contstants';

export const getModalLabelsByKey = (key: string): string => {
    switch(key) {
        case 'SEARCH_REQUEST_MODAL_TITLE':
            return 'requestSearchLabel';
        case 'USER_PREFERENCES_MODAL_TITLE':
            return 'userPreferenceLabel';
        case 'INDEX_KEYWORD_MANAGEMENT_MODAL_TITLE':
            return 'indexManagementLabel';
        case 'MODAL_EXPORT_TITLE':
            return 'modalExportLabel';
        case 'MODAL_PACKAGE_PRESETS_TITLE':
            return 'packagePresetsLabel';
        case 'REPORTS_REQUESTER_REPORT_TITLE':
            return 'requesterReportTitle';
        case 'ACTION_HEADER_REPORTS_PAGE_REVIEW_SUMMARY':
            return 'pageReviewSummaryTitle';
        case 'ADMIN_HEADER_DISCLOSURE_MANAGEMENT':
            return 'disclosureHeaderTitle';
        case 'MODAL_EDIT_FILENAME_TITLE':
            return 'editFilenameModalTitle';
        case 'DOCUMENT_HEADER_STACK_MANAGEMENT':
            return 'stackManagementModalTitle';
        case 'USER_DEFINED_STAMP_MANAGEMENT_MODAL_TITLE':
            return 'userStampManagementModalTitle';
        case 'ANNOTATION_TYPE_MANAGEMENT_MODAL_TITLE':
            return 'annotationTypeManagementModalTitle';
        case 'REQUESTSECURITY_MODAL_TITLE':
            return 'requestSecurityTitle';
        case 'ENCRYPTION_MODAL_TITLE':
            return 'encryptionTitle';
        case 'WATERMARK_MANAGEMENT_MODAL_TITLE':
            return 'waterMarkManagementTitle';
        case 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_TITLE':
            return 'associateWaterMarkTitle';
        case 'DOCUMENT_LIST_TITLE':
            return 'documentListTitle';
        case 'PAGE_LIST_TITLE':
            return 'pageListTitle';
        case 'REPLACE_DISCLOSURE_MODAL_TITLE':
            return 'replaceDisclosureModalTitle';
        case 'UNDO_DISCLOSURE_MODAL_TITLE':
            return 'undoDisclosureModalTitle';
        case 'DUPLICATE_REFENRENCES_MODAL_TITLE':
            return 'duplicateReferencesModalTitle';
        case 'TITLE_GLOBAL_SETTINGS':
            return 'globalSettingsTitle';
        default: return '';
    }
};

export const getHeaderLabelsByKey= (key: string): string => {
    switch(key) {
        case 'FILE_HEADER_TITLE':
            return 'fileHeaderLabel';
        case 'FILE_HEADER_REQUEST_SEARCH':
            return 'requestSearchHeaderLabel';
        case 'FILE_HEADER_USER_PREFERENCES':
            return 'userPreferenceHeaderLabel';
        case 'ACTION_HEADER_TITLE':
            return 'actionHeaderLabel';
        case 'ACTION_HEADER_IMPORT_DOCUMENT':
            return 'importDocHeaderLabel';
        case 'ACTION_HEADER_EXPORT_DOCUMENT':
            return 'exportDocHeaderLabel';
        case 'ACTION_HEADER_MANAGE_DUPLICATES':
            return 'manageDuplicateHeaderLabel';
        case 'ACTION_HEADER_REPORTS':
            return 'reportsHeaderLabel';
        case 'ACTION_HEADER_REPORTS_INDEX_NOTES':
            return 'indexNotesHeaderLabel';
        case 'ACTION_HEADER_REPORTS_OFFICER_RESPORTS':
            return 'officerReportHeaderLabel';
        case 'ACTION_HEADER_REPORTS_REQUESTER_REPORT':
            return 'requesterReportHeaderLabel';
        case 'ACTION_HEADER_REPORTS_PAGE_REVIEW_SUMMARY':
            return 'pageReviewHeaderLabel';
        case 'DOCUMENT_HEADER_TITLE':
            return 'documentHeaderLabel';
        case 'DOCUMENT_HEADER_DELETE':
            return 'deleteHeaderLabel';
        case 'DOCUMENT_HEADER_EDIT_FILENAME':
            return 'editFilenameHeaderLabel';
        case 'DOCUMENT_HEADER_STACK':
            return 'stackHeaderLabel';
        case 'DOCUMENT_HEADER_PAGINATION':
            return 'paginationHeaderLabel';
        case 'DOCUMENT_HEADER_STACK_ADD_REMOVE':
            return 'addRemoveStackHeaderLabel';
        case 'DOCUMENT_HEADER_STACK_MANAGEMENT':
            return 'stackManageHeaderLabel';
        case 'DOCUMENT_HEADER_PAGINATION_SET':
            return 'setPaginationHeaderLabel';
        case 'DOCUMENT_HEADER_PAGINATION_EDIT':
            return 'editPaginationHeaderLabel';
        case 'DOCUMENT_HEADER_PAGINATION_UNDO':
            return 'undoPaginationHeaderLabel';
        case 'DOCUMENT_HEADER_SELECT_ALL':
            return 'selectAllHeaderLabel';
        case 'DOCUMENT_HEADER_SET_RELEASE_DATE':
            return 'setReleaseHeaderLabel';
        case 'DOCUMENT_HEADER_CLEAR_RELEASE_DATE':
            return 'clearReleaseHeaderLabel';
        case 'DOCUMENT_HEADER_APPLY_OCR':
            return 'applyOcrHeaderLabel';
        case 'DOCUMENT_HEADER_APPLY_AUTO_DESKEW':
            return 'autoDeskewHeaderLabel';
        case 'DOCUMENT_HEADER_AUTO_ALIGN_ARTICLE_STAMPS':
            return 'autoAlignHeaderLabel';
        case 'PAGE_HEADER_TITLE':
            return 'pageHeaderLabel';
        case 'PAGE_HEADER_ROTATE':
            return 'rotateHeaderLabel';
        case 'PAGE_HEADER_ROTATE_LEFT':
            return 'rotateLeftHeaderLabel';
        case 'PAGE_HEADER_ROTATE_RIGHT':
            return 'rotateRightHeaderLabel';
        case 'PAGE_HEADER_MOVE':
            return 'moveHeaderLabel';
        case 'PAGE_HEADER_MOVE_UP':
            return 'moveUpHeaderLabel';
        case 'PAGE_HEADER_MOVE_DOWN':
            return 'moveDownHeaderLabel';
        case 'PAGE_HEADER_REVERSE_ORDER':
            return 'revereseHeaderLabel';
        case 'PAGE_HEADER_INSERT':
            return 'insertHeaderLabel';
        case 'PAGE_HEADER_GOTO':
            return 'gotoHeaderLabel';
        case 'PAGE_HEADER_GOTO_FIRST':
            return 'gotoFirstHeaderLabel';
        case 'PAGE_HEADER_GOTO_LAST':
            return 'gotoLastHeaderLabel';
        case 'PAGE_HEADER_GOTO_PREVIOUS':
            return 'gotoPreviousHeaderLabel';
        case 'PAGE_HEADER_GOTO_NEXT':
            return 'gotoNextHeaderLabel';
        case 'ADMIN_HEADER_TITLE':
            return 'adminHeaderLabel';
        case 'ADMIN_HEADER_PACKAGE_PRESETS':
            return 'packagePresetsHeaderLabel';
        case 'ADMIN_HEADER_GLOBAL_SETTINGS':
            return 'globalSettingHeaderLabel';
        case 'ADMIN_HEADER_AUDIT_LOG':
            return 'auditLogHeaderLabel';
        case 'ADMIN_HEADER_LOCALIZATION':
            return 'localizationHeaderLabel';
        case 'ADMIN_HEADER_DISCLOSURE_MANAGEMENT':
            return 'disclosureHeaderLabel';
        case 'ADMIN_HEADER_ANNOTATION_TYPE_MANAGEMENT':
            return 'annotationHeaderLabel';
        case 'ADMIN_HEADER_USER_DEFINED_STAMP_MANAGEMENT':
            return 'userDefStampHeaderLabel';
        case 'ADMIN_HEADER_WATERMARK_MANAGEMENT':
            return 'watermarkHeaderLabel';
        case 'ADMIN_HEADER_ASSOCIATE_WATERMARK_TO_REQUEST_TYPE':
            return 'watermarktoRequestHeaderLabel';
        case 'ADMIN_HEADER_REQUEST_SECURITY':
            return 'reqSecurityHeaderLabel';
        case 'ADMIN_HEADER_ENCRYPTION':
            return 'encryptionHeaderLabel';
        case 'ADMIN_HEADER_INDEX_KEYWORD_MANAGEMENT':
            return 'indexKeywordHeaderLabel';
        case 'HELP_HEADER_TITLE':
            return 'helpHeaderLabel';
        case 'HELP_HEADER_ABOUT_REDACTION':
            return 'aboutHeaderLabel';
        case 'HELP_HEADER_KEYBOARD_SHORTCUTS':
            return 'keyboardShortHeaderLabel';
        case 'HELP_HEADER_REDACTION_USER_GUIDE':
            return 'redactionUserGuideHeaderLabel';
        default: return '';
    }
};

class Header extends React.Component<IProps, IState> {
    public state: IState = {
        modalContent: null,
    };
    public SubmenusComponent: React.RefObject<Submenus>;

    constructor(props: IProps) {
        super(props);
        this.SubmenusComponent = React.createRef();
    }

    public render(): JSX.Element {
        const labels = {
            fileHeaderLabel: initialLabel,
            requestSearchHeaderLabel: initialLabel,
            userPreferenceHeaderLabel: initialLabel,
            actionHeaderLabel: initialLabel,
            importDocHeaderLabel: initialLabel,
            exportDocHeaderLabel: initialLabel,
            manageDuplicateHeaderLabel: initialLabel,
            reportsHeaderLabel: initialLabel,
            indexNotesHeaderLabel: initialLabel,
            officerReportHeaderLabel: initialLabel,
            requesterReportHeaderLabel: initialLabel,
            pageReviewHeaderLabel: initialLabel,
            documentHeaderLabel: initialLabel,
            stackHeaderLabel: initialLabel,
            addRemoveStackHeaderLabel: initialLabel,
            stackManageHeaderLabel: initialLabel,
            paginationHeaderLabel: initialLabel,
            editPaginationHeaderLabel: initialLabel,
            setPaginationHeaderLabel: initialLabel,
            undoPaginationHeaderLabel: initialLabel,
            selectAllHeaderLabel: initialLabel,
            deleteHeaderLabel: initialLabel,
            editFilenameHeaderLabel: initialLabel,
            setReleaseHeaderLabel: initialLabel,
            clearReleaseHeaderLabel: initialLabel,
            applyOcrHeaderLabel: initialLabel,
            autoDeskewHeaderLabel: initialLabel,
            autoAlignHeaderLabel: initialLabel,
            pageHeaderLabel: initialLabel,
            rotateHeaderLabel: initialLabel,
            rotateLeftHeaderLabel: initialLabel,
            rotateRightHeaderLabel: initialLabel,
            moveHeaderLabel: initialLabel,
            moveUpHeaderLabel: initialLabel,
            moveDownHeaderLabel: initialLabel,
            revereseHeaderLabel: initialLabel,
            insertHeaderLabel: initialLabel,
            gotoHeaderLabel: initialLabel,
            gotoFirstHeaderLabel: initialLabel,
            gotoLastHeaderLabel: initialLabel,
            gotoPreviousHeaderLabel: initialLabel,
            gotoNextHeaderLabel: initialLabel,
            adminHeaderLabel: initialLabel,
            packagePresetsHeaderLabel: initialLabel,
            auditLogHeaderLabel: initialLabel,
            globalSettingHeaderLabel: initialLabel,
            localizationHeaderLabel: initialLabel,
            disclosureHeaderLabel: initialLabel,
            annotationHeaderLabel: initialLabel,
            userDefStampHeaderLabel: initialLabel,
            watermarkHeaderLabel: initialLabel,
            watermarktoRequestHeaderLabel: initialLabel,
            reqSecurityHeaderLabel: initialLabel,
            encryptionHeaderLabel: initialLabel,
            helpHeaderLabel: initialLabel,
            aboutHeaderLabel: initialLabel,
            keyboardShortHeaderLabel: initialLabel,
            indexKeywordHeaderLabel: initialLabel,
            redactionUserGuideHeaderLabel:initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if(getHeaderLabelsByKey(resource.resourceKey)) {
                labels[getHeaderLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.props.modifiedLabels.map((resource: any) => {
            if(getHeaderLabelsByKey(resource.resourceKey)) {
                labels[getHeaderLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        this.props.headerMenu.map((menu: IMenu) => {
            const isAudit = window.location.pathname.includes('audit');
            const isDuplicates = window.location.pathname.includes('duplicates');
            const isLocalization = window.location.pathname.includes('localization');

            if (menu.id === HeaderMainMenus.FILE && (this.props.redactionMode !== MODE_FULL)) {
                return menu.enable = false
            } else if (menu.id === HeaderMainMenus.ACTION && (this.props.redactionMode === MODE_CONSULT)) {
                return menu.enable = false
            } else if ((menu.id === HeaderMainMenus.PAGE || menu.id === HeaderMainMenus.DOCUMENT) &&
                (isAudit || isDuplicates || isLocalization)) {
                return menu.enable = false
            } else {
                return menu
            }
        });

        return (
            <div className='header'>
                <div className='header-menuList-composition'>
                    <div className='header_request-number'>{this.props.requestNumber}</div>
                    <Submenus
                        documentData={this.props.documentData}
                        submenu={this.props.headerMenu}
                        orientation='vertical'
                        activeMenu={this.props.activeMenu}
                        buttonClass={'header-button-file'}
                        placementOrient={'top'}
                        handleMenuActions={(submenu: IMenu): void => this.handleMenuActions(submenu)}
                        clearHeaderActiveItem={this.props.clearHeaderActiveItem}
                        folderRsn={this.props.folderRsn}
                        labels={labels}
                        redactionLang={this.props.redactionLang}
                    />
                </div>
                <div>
                    <h2 className='header-logo-h2'>amanda 7</h2>
                </div>
            </div>
        );
    }

    private getLabelsByKey = (key: string): string => {
        const { redactionLang, modifiedLabels } = this.props;
        const label = {
            requestSearchLabel: initialLabel,
            userPreferenceLabel: initialLabel,
            indexManagementLabel: initialLabel,
            modalExportLabel: initialLabel,
            packagePresetsLabel: initialLabel,
            requesterReportTitle: initialLabel,
            pageReviewSummaryTitle: initialLabel,
            disclosureHeaderTitle: initialLabel,
            editFilenameModalTitle: initialLabel,
            stackManagementModalTitle: initialLabel,
            userStampManagementModalTitle: initialLabel,
            annotationTypeManagementModalTitle: initialLabel,
            requestSecurityTitle: initialLabel,
            encryptionTitle: initialLabel,
            waterMarkManagementTitle: initialLabel,
            associateWaterMarkTitle: initialLabel,
            documentListTitle:initialLabel,
            pageListTitle: initialLabel,
            replaceDisclosureModalTitle: initialLabel,
            undoDisclosureModalTitle: initialLabel,
            globalSettingsTitle:initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if (getModalLabelsByKey(resource.resourceKey)) {
                label[getModalLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        modifiedLabels.map((resource: any) => {
            if (getModalLabelsByKey(resource.resourceKey)) {
                label[getModalLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        switch(key) {
            case REQUEST_SEARCH_MODAL :
                return label.requestSearchLabel[langRule];
            case USER_PREFERENCE_MODAL :
                return label.userPreferenceLabel[langRule];
            case DOCUMENT_INDEX :
                return label.indexManagementLabel[langRule];
            case EXPORT_ID :
                return label.modalExportLabel[langRule];
            case PACKAGE_PRESETS:
                return label.packagePresetsLabel[langRule];
            case REQUESTER_REPORT_MODAL:
                return label.requesterReportTitle[langRule];
            case PAGE_REVIEW_SUMMARY_MODAL:
                return label.pageReviewSummaryTitle[langRule];
            case DISCLOSURE_MANAGEMENT:
                return label.disclosureHeaderTitle[langRule];
            case EDIT_FILE_NAME_MODAL:
                return label.editFilenameModalTitle[langRule];
            case STACK_MANAGMENT_MODAL:
                return label.stackManagementModalTitle[langRule];
            case USER_DEFINED_STAMP_MANAGEMENT:
                return label.userStampManagementModalTitle[langRule];
            case ANNOTATION_TYPE_MANAGEMENT:
                return label.annotationTypeManagementModalTitle[langRule];
            case REQUEST_SECURITY:
                return label.requestSecurityTitle[langRule];
            case ENCRYPTION:
                return label.encryptionTitle[langRule];
            case WATERMARK_MANAGEMENT:
                return label.waterMarkManagementTitle[langRule];
            case ASSOCIATE_WATERMARK_TO_FOLDER:
                 return label.associateWaterMarkTitle[langRule];
            case REPLACE_DISCLOSURE:
                return label.replaceDisclosureModalTitle[langRule];
            case 'undoDisclosure':
                return label.undoDisclosureModalTitle[langRule];
            case GLOBAL_SETTINGS:
                return label.globalSettingsTitle[langRule];
            default:
                return '';
        }
    }

    private handleMenuActions = (submenu: any): void => {
        const {
            selectedDocuments,
            selectedPages,
            currentDocument,
            redactionDocumentId,
            currentPage,
        } = this.props;

        if (submenu.modal) {
            this.props.openModalWindow(submenu.modal, {
                id: submenu.modal,
                title: this.getLabelsByKey(submenu.modal),
                modalSpecificProps: {
                    documentIds: selectedDocuments,
                }});
        } else if (submenu.action) {
            if (submenu.id === LEFT_ROTATE_PAGE || submenu.id === RIGHT_ROTATE_PAGE) {

                const degree = submenu.id === LEFT_ROTATE_PAGE ? degrees.QUARTER : degrees.MINUS_QUARTER;

                this.props.dispatchAction(submenu.action, {
                    redactionDocumentId,
                    documentId: currentDocument && currentDocument.id,
                    pageId: currentPage && currentPage.id,
                    rotationState: degree,
                });
            } else {
                this.props.dispatchAction(submenu.action, submenu.actionProps || {
                    documentIds: selectedDocuments,
                    currentDocId: currentDocument && currentDocument.id,
                    pageIds: selectedPages,
                    redactionDocumentId,
                });
            }
        }
    }
}

const mapStateToProps = (state: StoreState): IStateProps => ({
    currentDocument: getCurrentDocument(state),
    selectedDocuments: getSelectedDocuments(state),
    currentPage: getCurrentPage(state),
    documentData: getDocument(state),
    pageInformation: getPageInformation(state),
    selectedPages: getSelectedPages(state),
    redactionDocumentId: getRedactionDocumentId(state),
    activeMenu: getHeaderActiveMenu(state),
    headerMenu: getHeaderMenu(state),
    requestNumber: getRedactionRequestNumber(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
    redactionMode: getRedactionMode(state)
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
    openModalWindow: (modalType: string, modalProps: IModalProps): void => {
        dispatch(openModalWindow(modalType, modalProps));
    },
    dispatchAction: (func: any, arg: any): any => dispatch(func(arg)),
    clearHeaderActiveItem: (): void => {
        dispatch(clearHeaderActiveItem());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);

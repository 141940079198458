import { createSelector } from 'reselect';
import { IState } from '../store';
import {
    ILayoutRedactorTypes,
    IValidExemptionDtoList,
} from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';

export const getLayoutRedactorTypes = (state: IState): any => state.layoutRedactorTypes;

export const getSelectedArticleStamps = (state: IState): IValidExemptionDtoList[] =>
    state.layoutRedactorTypes.selectedArticleStamps;

export const getRecentlySingleUsedStamp = createSelector(
    [getLayoutRedactorTypes],
    (layout: ILayoutRedactorTypes): IValidExemptionDtoList[] => layout.recentlyUsedStamp,
);

export const getInteractiveMode = (state: IState): boolean =>
    state.layoutRedactorTypes.interactMode;

import * as React from 'react';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'rc-scrollbars';
import Table from './duplicateTable/Table';
import { DuplicatesListHeaders } from './duplicates.config';
import './duplicatePanels.styles.scss';
import { Row } from 'react-table';
import { ITrProps, MyRowData } from '../../components/table/table.model';
import { IDuplicateDocument, IDuplicateMetadata } from '../../redux/reducers/duplicates/duplicates.model';
import { IHeader } from '../../constants/leftSidebar.config';

export interface IDuplicateTableProps {
    duplicateCustomFileds: IHeader[];
    duplicatesResources: IDuplicateDocument[];
    documents: IDuplicateMetadata[];
    rowAction: (documentId: number, pageId: number) => void;
    handleDuplicateReferences: (documentId: number) => void;
}

export const DuplicateTable = (
    {
        documents,
        rowAction,
        duplicatesResources,
        handleDuplicateReferences,
        duplicateCustomFileds,
    }: IDuplicateTableProps,
): JSX.Element => {

    const getTrProps = (row: Row<MyRowData>): React.HTMLAttributes<HTMLTableRowElement> => {
        const { original } = row;
        const { documentId, pageId } = original;
        rowAction(documentId, pageId);
        handleDuplicateReferences(documentId);
        return {
            id: original.documentId.toString(),
            onClick: () => {
                const { documentId, pageId } = original;
                const isResourceExist = duplicatesResources.find((resource: IDuplicateDocument): boolean =>
                    resource.documentId === documentId && resource.currentPageId === pageId
                );
                if (!isResourceExist) {
                    rowAction(documentId, pageId);
                    handleDuplicateReferences(documentId);
                }
            },
        };
    };

    return (
        <div className='duplicates-body_table'>
            <AutoSizer>
                {({width, height}: { width: number; height: number; }): JSX.Element => {
                    return (
                        <div style={{width, height}}>
                            <Scrollbars>
                                <Table
                                    data={documents||[]}
                                    columnsGroup={DuplicatesListHeaders(duplicateCustomFileds)}
                                    getTrProps={getTrProps}
                                    showPagination={false}
                                />
                            </Scrollbars>
                        </div>
                    );
                }}
            </AutoSizer>
        </div>
    );
    
};

import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import './localization.scss';
import { LOCALIZATION_MIN_CHAR, SCREEN_LABEL_MAX_CHAR } from '../../constants/localization.constants';

const ResourceValueInput = (
    {
        initialValue,
        id,
        param,
        type,
        data,
        isDisabled = false,
        pageCountError = null,
        handleChange,
    }: any,
    ): JSX.Element => {
        const [value, setValue] = useState(initialValue);
        const isError = value.length < LOCALIZATION_MIN_CHAR || value.length > SCREEN_LABEL_MAX_CHAR;

        useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);

        const helperText = (): string | null => {
            if(value.length < LOCALIZATION_MIN_CHAR) {
                return 'Value should not be empty';
            } else if(value.length > SCREEN_LABEL_MAX_CHAR) {
                return 'should be less than 255 characters';
            }
        };
        const onBlur = (): void => {
            handleChange(data, value, param);
        };

        return (
            <React.Fragment>
                {
                    <div className='error-msg' >
                        <TextField
                            className='form-body-field'
                            onChange={(e: React.ChangeEvent<Element>): void =>
                                setValue((e.target as HTMLInputElement).value)}
                            onBlur={(): void => onBlur()}
                            value={value}
                            fullWidth={true}
                            type='text'
                            variant='outlined'
                            error={isError}
                            helperText={helperText()}
                            disabled={isDisabled}
                        />
                    </div>
                }
            </React.Fragment>
        );
    }
;

export default ResourceValueInput;

import * as React from 'react';
import { Field } from 'react-final-form';
import { renderCheckbox } from '../../../../components/materialUiForms/materialUiForms';
import { connect } from 'react-redux';
import { IProps, IStateProps } from './contactTab.model';
import { IState as StoreState } from '../../../../redux/store';
import * as constants from '../../../../constants/disclosure.contstants';
import { getContactTypesList } from '../../../../redux/selectors/amandaContent';
import { IContactType } from '../../../../redux/reducers/amandaContent/amandaContent.model';
import { langFrench } from '../../../../constants/localization.constants';

export class ContactTab extends React.Component<IProps> {
    public render(): JSX.Element {
        const {contactTypes, language} = this.props;

        return (
          <div>
              {
                  contactTypes.map((item: IContactType): JSX.Element => (
                      <div key={item.peopleCode}>
                          <Field
                              render={renderCheckbox}
                              type='checkbox'
                              name={constants.CONTACTS}
                              label={ language ? language === langFrench ? item.peopleDesc2 ? item.peopleDesc2
                                    : item.peopleDesc : item.peopleDesc : item.peopleDesc}
                              value={item.peopleCode}
                              disabled={!this.props.hasEditPermissions}
                          />
                      </div>
                  ))
              }
          </div>
        );
    }
}

const mapStateToProps = (state: StoreState): IStateProps => ({
    contactTypes: getContactTypesList(state),
});

export default connect(mapStateToProps)(ContactTab);

// THIS id has hardcoded inside the webtwain lib
export const WEB_TWAIN_ID = 'dwtcontrolContainer';

export const BLACK_AND_WHITE = 'Black & White';
export const GRAY = 'Gray';
export const COLOR = 'Color';
export const colors = [
    BLACK_AND_WHITE,
    GRAY,
    COLOR,
];
export const MULTIPLE_PAGE_DOCUMENT = 'Multiple Page Document';
export const BATCH_MULTI_DOCUMENT = 'Batch Multi - Document';
export const scanModes = [MULTIPLE_PAGE_DOCUMENT, BATCH_MULTI_DOCUMENT];

export const SEARCH_SCOPE = 'Search Scope';
export const SEARCH_SCOPE_PARAM = 'searchScope';
export const CURRENT_SCOPE = 'Current Request';
export const CURRENT_SCOPE_PARAM = 'current_request';
export const ALL_SCOPE = 'All Requests';
export const ALL_SCOPE_PARAM = 'all_requests';
export const SPECIFIC_SCOPE = 'Specific Request';
export const SPECIFIC_SCOPE_PARAM = 'specific_requests';

export const LANGUAGE = 'Language';
export const LANGUAGE_PARAM = 'language';

export const CLASSIFICATION = 'Classification';
export const CLASSIFICATION_PARAM = 'classification';

export const REFERENCE_NUM = 'Reference Number';
export const REFERENCE_NUM_PARAM = 'referenceNumber';

export const DOCUMENT_TYPE = 'Document Type';
export const DOCUMENT_TYPE_PARAM = 'documentType';

export const RECEIVING_ORGANIZATION = 'To Org.';
export const RECEIVING_ORGANIZATION_PARAM = 'toOrganisation';

export const SENDING_ORGANIZATION = 'From Org.';
export const SENDING_ORGANIZATION_PARAM = 'fromOrganisation';

export const RECEIVING_INDIVIDUAL = 'To';
export const RECEIVING_INDIVIDUAL_PARAM = 'to';

export const SENDING_INDIVIDUAL = 'From';
export const SENDING_INDIVIDUAL_PARAM = 'from';

export const NOTES = 'Notes';
export const NOTES_PARAM ='notes';

export const SUBJECT = 'Subject';
export const SUBJECT_PARAM = 'subject';

export const FIELD1 = 'Field 1';
export const FIELD1_PARAM = 'field1';
export const ACT = 'act';

export const DOCUMENT_NAME = 'Doc. Name';
export const DOCUMENT_NAME_PARAM = 'documentName';

export const PAGINATION_NUM = 'Pagination Number';
export const PAGINATION_NUM_PARAM = 'paginationNumber';

export const FOLDER_RSN = 'Folder RSN';
export const FOLDER_RSN_PARAM = 'folderRSN';

export const SEARCH_REQUEST_TYPE = 'Request Type';
export const SEARCH_REQUEST_TYPE_PARAM = 'requestType';

export const REQUEST_NUMBER = 'Request Number';
export const REQUEST_NUMBER_PARAM = 'requestNumber';

export const EXEMPTION = 'Exemptions / Exclusions Applied';
export const EXEMPTION_PARAMS = 'exemptionIds';

export const ANNOTATION = 'Annotations Applied';
export const ANNOTATION_PARAMS = 'annotationIds';

export const USER_STAMP = 'User Defined Stamps Applied';
export const USER_STAMP_PARAMS = 'stampsIds';

export const DISCLOSURE = 'Disclosure Name';
export const DISCLOSURE_PARAMS = 'disclosureIds';

export const DOC_DATE = 'Doc. Date';
export const DOC_DATE_FROM = 'From';
export const DOC_DATE_FROM_PARAM = 'startDate';
export const DOC_DATE_TO = 'To';
export const DOC_DATE_TO_PARAM = 'endDate';

export const SPECIFIC_REQUEST_PARAM = 'specificRequest';

export const CLEAR = 'Clear';
export const SEARCH = 'Search';

export const MAX_LENGTH = 'Max length 255';

export const ADVANCED_SEARCH_RESULTS_LABEL = 'Advanced search results';

export const searchScope = [
    { value: CURRENT_SCOPE_PARAM, label: CURRENT_SCOPE },
    { value: ALL_SCOPE_PARAM, label: ALL_SCOPE },
    { value: SPECIFIC_SCOPE_PARAM, label: SPECIFIC_SCOPE },
];

export const FIELDS_NUMBER_TYPE = [
    CLASSIFICATION_PARAM,
    DOCUMENT_TYPE_PARAM,
    LANGUAGE_PARAM,
    FOLDER_RSN_PARAM,
];

export const SPECIFIC_REQUEST_FIELDS = [
    FOLDER_RSN_PARAM,
    SEARCH_REQUEST_TYPE_PARAM,
    REQUEST_NUMBER_PARAM,
];

import { AnyAction } from 'redux';
import {
    SET_ALL_DOCUMENT_PAGES_TO_EXPORT,
    CHANGE_PAGES_IN_EXPORT_LIST,
    CHANGE_DOCUMENT_IN_EXPORT_LIST,
    SET_ANNOTATION_TYPE,
    REMOVE_ANNOTATION_TYPE,
    CHANGE_OPTIONS,
    CHANGE_PAGINATION_OPTIONS,
    CLEAR_EXPORT_STATE,
    POST_EXPORT_DOCUMENT_PENDING,
    POST_EXPORT_DOCUMENT_SUCCESS,
    POST_EXPORT_DOCUMENT_FAILURE,
    CHANGE_EXPORT_DESTINATION_OPTIONS,
    GET_EXPORT_DOCUMENTS_PENDING,
    GET_EXPORT_DOCUMENTS_SUCCESS,
    GET_EXPORT_DOCUMENTS_FAILURE,
    SET_CONSULT_FILTERS,
    SET_PAGES_FOR_SINGLE_FILE, SET_EXPORT_OPTIONS_FROM_PRESET, CLEAR_PRESET_OPTIONS,
    SELECT_EXPORT_STACK,
    EXPORT_STACK_DOCS_PENDING,
    EXPORT_STACK_DOCS_SUCCESS,
    EXPORT_STACK_DOCS_FAILURE,
    SELECT_ALL_OR_DESELECT_ALL_DOCUMENTS_IN_EXPORT_LIST,
    CHANGE_DOCUMENT_ORIGINAL_NAME_IN_EXPORT_LIST,
    SET_EXPORT_FILE_NAME_ERROR_FLAG,
    CHANGE_ATTACHMENT_TYPE_OPTIONS,
} from './constant';
import * as constants from '../../../constants/export.contstants';
import {
    IModalWindowExportState,
    IExportPages,
    IExportDoc,
} from './modalWindowExport.model';
import { DEFAULT_ITEM_COLOR } from '../../../constants/annotationTypes.constants';
import {
    getCheckedAnnotations,
} from '../../../containers/modalWindowContents/modalPackagePresets/existingPresets/ExistingPresets';
import { ORIGINAL_SEVER_COLOR_PARAM, SELECT_PRESET, ALL_DOCUMENTS } from '../../../constants/export.contstants';
import { CUSTOM_SEVER_COLOR_PARAM } from '../../../constants/export.contstants';
import { removeExtension } from '../../../utils/documentList.util';

export const initialContentOptions = {
    [constants.PRESETS_PARAM]: SELECT_PRESET,
    [constants.SEVER_COLOR_TYPE_PARAM]: constants.ORIGINAL_SEVER_COLOR_PARAM,
    [constants.SEVER_COLOR_PARAM]: null,
    [constants.ORIGINALS_ONLY_PARAM]: false,
    [constants.DOCUMENT_ORDER_PARAM]: constants.BY_PAGINATION_PARAM,
    [constants.CREATE_PACKAGE_AS_SINGLE_FILE_PARAM]: false,
    [constants.NON_RELEASABLE_PAGES_PARAM]: constants.EXCLUDE_PARAM,
    [constants.LABEL_DOCUMENT_BY_PARAM]: constants.DOCUMENT_NAME_PARAM,
    [constants.SHOW_ARTICLE_STAMPS_PARAM]: false,
    [constants.SHOW_USER_DEFINED_STAMP_PARAM]: false,
    [constants.INCLUDE_PAGINATED_PAGES_ONLY_PARAM]: false,
    [constants.INCLUDE_PAGES_WITH_CONTACT_DISCLOSURES_PARAM]: false,
    [constants.INCLUDE_SEVER_TO_ARTICLES_LINKING_LINES_PARAM]: false,
    [constants.ARTICLE_COLOR_PARAM]: '#000000',
    [constants.SHOW_WATERMARKS_PARAM]: false,
    [constants.WATERMARK_LOCATION_PARAM]: constants.POSITION_MIDDLE_LEFT_PARAM,
    [constants.INCLUDE_NOTES_PARAM]: false,
    [constants.LANGUAGE_PARAM]: 'english',
};

export const initialPaginationOptions = {
    [constants.PAGINATION_MODE_PARAM]: constants.PAGINATION_TYPE_BASIC_PARAM,
    [constants.FONT_COLOR_PARAM]: constants.DEFAULT_COLOR_PARAM_HEX,
    [constants.LOCATION_PARAM]: constants.POSITION_BOTTOM_RIGHT_PARAM,
    [constants.STAMP_DOC_INDEX_PARAM]: false,
    [constants.STARTING_NUMBER_PARAM]: '',
    [constants.INCREMENT_BY_PARAM]: '',
    [constants.FONT_SIZE_PARAM]: 12,
};

const initialState: IModalWindowExportState = {
    exportedSuccess: false,
    exportDocumentPending: false,
    error: null,
    getExportDocumentsPending: null,
    initialDocumentsToExport: [],
    documentsToExport: [],
    annotationControlsId: [],
    contentOptions: initialContentOptions,
    paginationOptions: initialPaginationOptions,
    exportDocumentsList: {
        basicDocuments: [],
        disclosureTypes: [],
        people: [],
    },
    exportStackDocumentsList: [],
    exportDestinationOptions: {
        [constants.EXPORT_DESTINATION_PARAM]: constants.FILE_PARAM,
        [constants.FOLDER_PARAM]: '',
        [constants.PRINTER_CODE]: '',
        [constants.PRINTER_TRAY_PARAM]: '',
        [constants.EXPORT_FILE_NAME_PARAM]: '',
        [constants.ATTACHMENT_TYPE_CODE_PARAM]: 0,
        [constants.ATTACHMENT_DESCRIPTION]: '',
        [constants.ATTACHMENT_DETAIL]: '',
    },
    exportFilters: {
        contactFilters: [],
        disclosureFilters: [],
    },
    pagesForSingleFile: null,
    currentExportStackId: ALL_DOCUMENTS,
    exportFileNameErrorMessage: '',
    documentFileNameErrorMessage: '',
    attachmentTypeCode: {
        [constants.FOLDER_ATTACHMENT_TYPE_CODE_PARAM]: '',
        [constants.PROCESS_ATTACHMENT_TYPE_CODE_PARAM]: '',
    },
};

const modalWindowExportReducer =
    (state: IModalWindowExportState = initialState, { type, payload }: AnyAction):
        IModalWindowExportState => {
        switch (type) {
            case SET_ALL_DOCUMENT_PAGES_TO_EXPORT:
                return {
                    ...state,
                    initialDocumentsToExport: [...payload],
                    documentsToExport: payload ? [...payload.map((docs: IExportDoc): IExportDoc => {
                        return {
                            ...docs,
                            originalFileName: removeExtension(docs.originalFileName),
                        };
                    })] : [],
                };
            case CHANGE_DOCUMENT_IN_EXPORT_LIST: {
                return {
                    ...state,
                    documentsToExport: state.documentsToExport.map(
                        (document: IExportDoc): IExportDoc => document.id === payload.id ? ({
                            ...document,
                            isChecked: payload.isChecked,
                        }) : document),
                };
            }
            case CHANGE_DOCUMENT_ORIGINAL_NAME_IN_EXPORT_LIST: {
                return {
                    ...state,
                    documentsToExport: state.documentsToExport.map(
                        (document: IExportDoc): IExportDoc => document.id === payload.id ? ({
                            ...document,
                            originalFileName: payload.fileName,
                        }) : document),
                };
            }
            case SELECT_ALL_OR_DESELECT_ALL_DOCUMENTS_IN_EXPORT_LIST: {
                return {
                    ...state,
                    documentsToExport: state.documentsToExport.map(
                        (document: IExportDoc): IExportDoc => ({
                            ...document,
                            isChecked: payload,
                        })),
                };
            }
            case CHANGE_PAGES_IN_EXPORT_LIST: {
                return {
                    ...state,
                    documentsToExport: state.documentsToExport.map(
                        (document: IExportDoc): IExportDoc => document.id === payload.id ? ({
                            ...document,
                            pages: document.pages.map((page: IExportPages): IExportPages => ({
                                ...page,
                                isChecked: payload.selectedPageRange.includes(page.pageNumber),
                            })),
                        }) : document),
                };
            }
            case SET_EXPORT_OPTIONS_FROM_PRESET:
                const {
                    exportOptions,
                    paginationOptions,
                    annotationControlOptions: { highlightDisplayOptions, severTransparencyOptions },
                } = payload[0];

                const severColorOption = {
                    severColorOption: exportOptions.severColorOption ?
                        exportOptions.severColorOption : DEFAULT_ITEM_COLOR,
                };

                const severColorType = {
                    severColorType: exportOptions.severColorOption && exportOptions.presets !== SELECT_PRESET
                        ? CUSTOM_SEVER_COLOR_PARAM
                        : ORIGINAL_SEVER_COLOR_PARAM,
                };

                const incrementPagination = {
                    incrementBy: paginationOptions.incrementBy ? paginationOptions.incrementBy : null,
                };
                const startPagination = {
                    startingNumber: paginationOptions.startingNumber ? paginationOptions.startingNumber : null,
                };

                const highlightIds = getCheckedAnnotations(highlightDisplayOptions);
                const severIds = getCheckedAnnotations(severTransparencyOptions);

                return {
                    ...state,
                    contentOptions: {
                        ...state.contentOptions,
                        ...exportOptions,
                        ...severColorOption,
                        ...severColorType,
                    },
                    paginationOptions: {
                        ...state.paginationOptions,
                        ...paginationOptions,
                        ...incrementPagination,
                        ...startPagination,
                    },
                    annotationControlsId: [...state.annotationControlsId, ...highlightIds, ...severIds].slice(
                        state.annotationControlsId.length),
                };
            case CLEAR_PRESET_OPTIONS:
                return {
                    ...state,
                    contentOptions: {
                        ...initialContentOptions,
                        severColorOption: DEFAULT_ITEM_COLOR,
                        severColorType: ORIGINAL_SEVER_COLOR_PARAM,
                    },
                    paginationOptions: initialPaginationOptions,
                    annotationControlsId: [],
                };
            case CHANGE_OPTIONS:
                return {
                    ...state,
                    contentOptions: {
                        ...state.contentOptions,
                        ...payload,
                    },
                };
            case CHANGE_PAGINATION_OPTIONS:
                return {
                    ...state,
                    paginationOptions: {
                        ...state.paginationOptions,
                        ...payload,
                    },
                };
            case CHANGE_EXPORT_DESTINATION_OPTIONS:
                return {
                    ...state,
                    exportDestinationOptions: {
                        ...state.exportDestinationOptions,
                        ...payload,
                    },
                };
            case SET_ANNOTATION_TYPE:
                return {
                    ...state,
                    annotationControlsId: [...state.annotationControlsId, payload],
                };
            case REMOVE_ANNOTATION_TYPE:
                return {
                    ...state,
                    annotationControlsId: state.annotationControlsId.filter((id: number): boolean => id !== payload),
                };
            case CLEAR_EXPORT_STATE:
                return initialState;

            case GET_EXPORT_DOCUMENTS_PENDING:
            case EXPORT_STACK_DOCS_PENDING:
                return {
                    ...state,
                    getExportDocumentsPending: true,
                    error: null,
                };
            case GET_EXPORT_DOCUMENTS_SUCCESS:
                return {
                    ...state,
                    exportDocumentsList: payload,
                    getExportDocumentsPending: false,
                };
            case GET_EXPORT_DOCUMENTS_FAILURE:
            case EXPORT_STACK_DOCS_FAILURE:
                return {
                    ...state,
                    error: payload,
                    getExportDocumentsPending: false,
                };

            case POST_EXPORT_DOCUMENT_PENDING:
                return {
                    ...state,
                    exportedSuccess: false,
                    exportDocumentPending: true,
                    error: null,
                };
            case POST_EXPORT_DOCUMENT_SUCCESS:
                return {
                    ...state,
                    exportedSuccess: true,
                    exportDocumentPending: false,

                };
            case POST_EXPORT_DOCUMENT_FAILURE:
                return {
                    ...state,
                    error: payload,
                    exportedSuccess: false,
                    exportDocumentPending: false,
                };
            case SET_CONSULT_FILTERS:
                return {
                    ...state,
                    exportFilters: payload,
                };
            case SET_PAGES_FOR_SINGLE_FILE:
                return {
                    ...state,
                    pagesForSingleFile: payload,
                };
            case SELECT_EXPORT_STACK:
                return {
                    ...state,
                    currentExportStackId: payload,
                };
            case EXPORT_STACK_DOCS_SUCCESS:
                return {
                    ...state,
                    exportStackDocumentsList: payload,
                    getExportDocumentsPending: false,
                };
            case SET_EXPORT_FILE_NAME_ERROR_FLAG:
                return {
                    ...state,
                    exportFileNameErrorMessage: payload,
                };
            case CHANGE_ATTACHMENT_TYPE_OPTIONS:
                return {
                    ...state,
                    attachmentTypeCode: {
                        ...state.attachmentTypeCode,
                        ...payload,
                    },
                };
            default:
                return state;
        }
    };

export default modalWindowExportReducer;

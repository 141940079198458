import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import moment = require('moment');
import { Scrollbars } from 'rc-scrollbars';
import { useSelector, useDispatch } from '../../../node_modules/react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getIndexMetadataData } from '../../redux/selectors/indexMetadata';
import { setDocumentIndexValue, saveDocumentIndex } from '../../redux/actions/documentIndex';
import { setPageIndexValue, savePageIndex } from '../../redux/actions/pageIndex';
import { getPageIndexPending } from '../../redux/selectors/pageIndex';
import {
    IIndex,
    IIndexMetadata, IIndexValue, ILookupIndexMetadata,
} from '../../redux/reducers/indexMetadata/indexMetadata.model';
import { ISelectOptions } from '../../components/materialUiForms/marerialUiForms.model';
import { IIndexTabProps } from './indexTab.model';
import { AvailableTabs } from './../rightBottomBar/rightBottomBar';
import {
    MOMENT_DATE_FORMAT,
    DATAPICKER_DATE_FORMAT,
    KEYWORD_INDEX, SAVE,
} from './../../constants/common.constants';
import { DOCUMENT_INDEX, INDEX_METADATA } from '../../constants/modal.constants';
import { SelectMaterialBuilder } from '../../components/materialUiForms/materialUiFormBuilder';
import { getCurrentDocumentOriginalName } from '../../redux/selectors/pageList';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';
import { langEnglish, langFrench } from '../../constants/localization.constants';
import dayjs = require('dayjs');

enum FieldType {
    Index = 'Index',
    String = 'text',
    Double = 'number',
    Integer = 'number',
    Data = 'date',
}

export const IndexTabComponent = ({
    title,
    isValid,
    data,
    selectedPageId,
    openModalWindowIndex,
    editPermission,
    viewPermission,
    labels,
    language,
}: IIndexTabProps): JSX.Element => {
    const indexMetadata = useSelector(getIndexMetadataData);
    const pending = useSelector(getPageIndexPending);
    const currentDocumentOriginalName = useSelector(getCurrentDocumentOriginalName);
    const dispatch = useDispatch();
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const handleSetDocumentValue = (id: number, value: IIndexValue): void =>
        dispatch(setDocumentIndexValue(id, value));
    const handleSetPageValue = (id: number, value: IIndexValue): void =>
        dispatch(setPageIndexValue(id, value));
    const handleSaveDocumentIndex = (indexTabSave: boolean): void => dispatch(saveDocumentIndex(indexTabSave));
    const handleSavePageIndex = (indexTabSave: boolean): void => dispatch(savePageIndex(indexTabSave));
    const langRule = language ? language === langFrench ? 'resourceValue2' : 'resourceValue' : 'resourceValue';
    const INVALID_DATE = "Invalid Date Format";
    let validDate: boolean;

    language ? language === langFrench ? dayjs.locale(langFrench) : dayjs.locale(langEnglish)
        : dayjs.locale(langEnglish);

    const getCurrentIndexMetadata = (rowData: IIndex): IIndexMetadata =>
        indexMetadata.find((metaData: IIndexMetadata): boolean => metaData.id === rowData.metadataId);

    const getCurrentLookup = (metaData: IIndexMetadata, value: IIndexValue): ILookupIndexMetadata =>
        metaData.lookups.find((item: ILookupIndexMetadata): boolean =>
            item.id === (value as ILookupIndexMetadata).id);

    const setData = (id: number, value: IIndexValue): void => {
        if (title === labels.documentIndexTitle[langRule]) {
            handleSetDocumentValue(id, value);
        } else if (title === labels.pageIndexTitle[langRule]) {
            handleSetPageValue(id, value);
        }
    };

    const onSelectChange = (value: string, metadata: IIndexMetadata, id: number): void => {
        const valueToSet = metadata.lookups.find((item: ILookupIndexMetadata): boolean =>
            item.id === Number(value));

        setData(id, valueToSet);
    };

    const onTextFieldChange = (e: ChangeEvent<HTMLInputElement>, metaData: IIndexMetadata, id: number): void => {
        const { Integer } = FieldType;
        const value = FieldType[metaData.dataType] === Integer ? Number(e.target.value)
            : e.target.value;

        setData(id, value);
    };

    const save = (): void => {
        if (title === labels.documentIndexTitle[langRule]) {
            handleSaveDocumentIndex(true);
        } else if (title === labels.pageIndexTitle[langRule]) {
            handleSavePageIndex(true);
        }
    };

    const validateDate = (date, id: any): void => {
        if (date) {
            validDate = dayjs(date.$d).isValid();
            if (!validDate) {
                setDateErrorMessage(INVALID_DATE);
            }
            else {
                setDateErrorMessage('');
            }
        }
        setData(
            id, date ? date.format(MOMENT_DATE_FORMAT) : null,
        )
    };

    return (
        <div className='tab-content'>
            <div className='content-wrapper'>
                <Scrollbars>
                    {data && data.length > 0 &&
                        title === labels.documentIndexTitle[langRule] && <div className='bottom-bar_table row'>
                            <div className='row_name'>{labels.fileNameLabel[langRule]}</div>
                            <div className='row_value'><TextField
                                disabled={true}
                                type={FieldType.String}
                                value={currentDocumentOriginalName}
                                className='row_field border-bottom-solid'
                                onChange={(e: ChangeEvent<HTMLInputElement>): void => { return; }}
                            /></div></div>}
                    {
                        data.map((indexData: IIndex): JSX.Element => {
                            const currentIndexMetadata = getCurrentIndexMetadata(indexData);

                            if (currentIndexMetadata.name === 'Filename') {
                                return;
                            }

                            const isLookupsExist = currentIndexMetadata && currentIndexMetadata.lookups;
                            const currentLookup = isLookupsExist && indexData.value ?
                                getCurrentLookup(currentIndexMetadata, indexData.value) : null;
                            const selectValue = {
                                value: currentLookup ? currentLookup.id : '',
                                label: currentLookup ? currentLookup.description : '',
                            };
                            const key = `${currentIndexMetadata.id}${selectedPageId}${indexData.id}${pending}`;

                            return (<React.Fragment key={indexData.id}>
                                {
                                    currentIndexMetadata &&
                                    <div
                                        key={key}
                                        className='bottom-bar_table row'
                                    >
                                        <div className='row_name'>{language ? language === 'fr'
                                            ? currentIndexMetadata.name_fr ? currentIndexMetadata.name_fr
                                                : currentIndexMetadata.name : currentIndexMetadata.name
                                            : currentIndexMetadata.name}</div>
                                        <div className='row_value'>
                                            {
                                                currentIndexMetadata.dataType === FieldType.Index ?
                                                    <SelectMaterialBuilder
                                                        className='row_field'
                                                        value={selectValue.value.toString()}
                                                        paramName={INDEX_METADATA}
                                                        label={language ? language === 'fr'
                                                            ? currentIndexMetadata.name_fr ? currentIndexMetadata.name_fr
                                                                : currentIndexMetadata.name : currentIndexMetadata.name
                                                            : currentIndexMetadata.name}
                                                        handleSelectChange={
                                                            (param: string, value: string): void =>
                                                                onSelectChange(
                                                                    value,
                                                                    currentIndexMetadata,
                                                                    indexData.id,
                                                                )}
                                                        options={(currentIndexMetadata.lookups || []).map((
                                                            item: ILookupIndexMetadata): ISelectOptions => ({
                                                                value: String(item.id),
                                                                label: language ? language === 'fr' ? item.description2
                                                                    ? item.description2 : item.description
                                                                    : item.description : item.description,
                                                            }),
                                                        )}
                                                        disabled={!editPermission}
                                                    />
                                                    : (currentIndexMetadata.dataType === 'Data'
                                                        ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                defaultValue={indexData.value as string ?
                                                                    dayjs(indexData.value as string) : null}
                                                                    onChange={(date) => validateDate(date, indexData.id)}
                                                                    slotProps={{
                                                                        textField: {
                                                                            helperText: (indexData.value != null && dateErrorMessage) ? dateErrorMessage : '',
                                                                            InputProps: {
                                                                                style: { marginBottom: dateErrorMessage ? '0px' : '10px' }
                                                                            },
                                                                        },
                                                                    }}
                                                                className='row_field'
                                                                format={DATAPICKER_DATE_FORMAT}
                                                            />

                                                        </LocalizationProvider>
                                                        : <TextField
                                                            disabled={!editPermission}
                                                            type={FieldType[currentIndexMetadata.dataType]}
                                                            value={(indexData.value as string) || ''}
                                                            className='row_field'
                                                            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                                                                onTextFieldChange(
                                                                    e,
                                                                    currentIndexMetadata,
                                                                    indexData.id,
                                                                )}
                                                        />)
                                            }
                                        </div>
                                    </div>
                                }
                            </React.Fragment>);
                        })
                    }
                </Scrollbars>
            </div>
            <div className='bottom-bar_buttons'>
                <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    className='primary bottom-bar_buttons'
                    onClick={save}
                    disabled={!editPermission || !selectedPageId}
                >
                    {labels.saveLabel[langRule]}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    className='primary bottom-bar_buttons left_margin'
                    onClick={(): void => openModalWindowIndex(DOCUMENT_INDEX)}
                    disabled={!editPermission || !selectedPageId || !viewPermission}
                >
                    {labels.keyIndexLabel[langRule]}
                </Button>
            </div>
        </div>
    );
};

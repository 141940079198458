import * as React from 'react';
import Button from '@mui/material/Button';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { renderTextField, renderSelectField } from '../../../../components/materialUiForms/materialUiForms';
import { IDisclosureContacts } from '../../../../redux/reducers/disclosureContacts/disclosureContacts.model';
import { ISelectOptions } from '../../../../components/materialUiForms/marerialUiForms.model';
import { IPeopleSearchProps } from './searchTab.model';
import { validate } from '../../../../utils/validate.util';
import { MAX_LENGTH } from '../../../../constants/validate.constants';
import { FormState } from 'final-form';
import Select from 'react-select';
import { changeLang } from '../../../../redux/actions/localization';
import { langFrench } from '../../../../constants/localization.constants';

const validateFields = [
    {
        field: 'nameFirst',
        [MAX_LENGTH]: 50,
    },
    {
        field: 'nameLast',
        [MAX_LENGTH]: 50,
    },
];
const customStyles = {
    control: (base: any): any => ({
        ...base,
        height: 41,
    }),
    placeholder: (base: any): any => ({
        ...base,
        fontFamily : 'Roboto,Helvetica, Arial, sans-serif',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.015,
    }),
    indicatorSeparator: (): any => ({}),
};

const PeopleSearch = ({ peopleTypeByDisclosure, handleSave, labels, language }: IPeopleSearchProps): JSX.Element => {
    const formatPeopleType = peopleTypeByDisclosure.map((peopleType: IDisclosureContacts): ISelectOptions => ({
        value: `${peopleType.peopleCode}`,
        label: language ? language === langFrench ? peopleType.peopleDesc2 ? peopleType.peopleDesc2
            : peopleType.peopleDesc : peopleType.peopleDesc : peopleType.peopleDesc,
    }));
    const langRule = changeLang(language);

    const formatOrganizations = peopleTypeByDisclosure
        .filter((peopleType: IDisclosureContacts) => peopleType.organizationName)
        .map((peopleType: IDisclosureContacts): ISelectOptions => ({
            value: `${peopleType.organizationName}`,
            label: peopleType.organizationName,
        }));

    return (
        <Form
            onSubmit={handleSave}
            validate={(values: any): { [param: string]: boolean } => validate(values, validateFields)}
            render={<T extends object>({
                 handleSubmit,
                 pristine,
                 submitting,
                 invalid,
                 form,
            }: FormRenderProps & FormState<T>): JSX.Element => (
                <form onSubmit={handleSubmit}>
                    <div className='text-field-blocks'>
                        <div className='row-column'>
                            <Field
                                render={renderTextField}
                                name='nameFirst'
                                label={labels.firstNameHeaderLabel[langRule]}
                                margin='normal'
                                variant='outlined'
                            />
                            <Field
                                render={renderSelectField}
                                name='peopleCode'
                                label={labels.peopleTypeHeaderLabel[langRule]}
                                margin='normal'
                                variant='outlined'
                                options={formatPeopleType}
                            />
                        </div>
                        <div className='row-column'>
                            <Field
                                render={renderTextField}
                                name='nameLast'
                                label={labels.lastNameHeaderLabel[langRule]}
                                margin='normal'
                                variant='outlined'
                            />
                            <Field
                                render={({ input, label, meta: { touched, error }, ...custom }: any): JSX.Element => {
                                    return (
                                        <div className='free-field'>
                                            <Select
                                                placeholder={labels.organisationNameHeaderLabel[langRule]}
                                                styles={customStyles}
                                                {...input}
                                                {...custom}
                                            />
                                        </div>
                                    );
                                }}
                                name='organizationName'
                                label={labels.organisationNameHeaderLabel[langRule]}
                                margin='normal'
                                variant='outlined'
                                options={formatOrganizations}
                            />
                        </div>
                    </div>
                    <div className='row-item footer-actions'>
                        <Button
                            variant='outlined'
                            className='modal-window__buttons outlined'
                            size='small'
                            onClick={form.reset}
                        >
                            {labels.resetLabel[langRule]}
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            type='submit'
                            disabled={invalid}
                            className='primary'
                        >
                            {labels.searchLabel[langRule]}
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default PeopleSearch;

import { IState } from '../store';
import { createSelector } from 'reselect';
import { clone } from 'lodash';
import {
    IContextData,
    IContextMenu,
    IContextMenuStore,
} from '../reducers/contextMenu/contextMenu.model';
import { IDisclosureOptions } from '../reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import {
    CHANGE_PAGE_DISCLOSURE_LIST_MENU,
    PAGE_LIST_CONTEXT_MENU,
    DOCUMENT_LIST_CONTEXT_MENU,
} from '../../constants/contextmenu/context.menu.constants';
import {
    UNDO_DISCLOSURE_ID,
    DELETE_PAGE_ID,
    EXPORT_ID,
    STACK_ID,
    PAGINATION_ID,
    DISCLOSURE_ID,
    DELETE_DOCUMENT_ID,
    REPLACE_DISCLOSURE_ID,
    SET_DISCLOSURE_ID,
    SET_PAGINATION_ID,
    EDIT_PAGINATION_ID,
    UNDO_PAGINATION_ID,
    ADD_REMOVE_FROM_STACK_ID,
    STACK_MANAGEMENT_ID,
    EDIT_DOCUMENT_ORIGINAL_NAME,
} from '../../constants/common.constants';
import { getDocumentListPermissions, getPageListPermissions } from './initialization';
import { showAlertMessage, changeDisclosure } from '../actions/pageList';
import { getCurrentDisclosureTypes } from './disclosureTypes';
import { getCurrentDocument } from './pageList';
import { Dispatch } from 'redux';
import { IModalIdsData } from '../reducers/modal/modal.model';
import { IDocument } from '../reducers/pageList/pageList.model';
import { IModalElementPermissions } from '../reducers/initialization/initialization.model';
import { IStartCoordinate } from '../../containers/redactor/redactor.model';
import { getLocalStorageState, getRedactionLanguage } from './localStorage';
import { getModifiedLabels } from './localization';
import { ILabel } from '../../redux/reducers/localization/localization.model';
import { changeLang } from '../../redux/actions/localization';
import resourceBundle from '../../containers/localization/localizationData';
import { getRightClickMenuLabelsByKey, rightClickMenuLabels } from '../../constants/contextmenu/context.menu.config';

export const getContextMenuState = (state: IState): IContextMenuStore => state.contextMenu;

export const updateDocumentMenuWithPermissions = (
    contextMenu: IContextData,
    documentListPermissions: IModalElementPermissions,
): IContextData => {
    const hasPaginationPermission =
        !documentListPermissions.editPaginationPermission &&
        !documentListPermissions.setPaginationPermission &&
        !documentListPermissions.undoPaginationPermission &&
        contextMenu.id === PAGINATION_ID;

    const hasStackPermission =
        !documentListPermissions.addRemoveStackPermission &&
        !documentListPermissions.managementStackPermission &&
        contextMenu.id === STACK_ID;

    const hasDisclosurePermission =
        !documentListPermissions.setDisclosurePermission &&
        !documentListPermissions.replaceDisclosurePermission &&
        !documentListPermissions.undoPaginationPermission &&
        contextMenu.id === DISCLOSURE_ID;

    if (
        hasPaginationPermission ||
        hasStackPermission ||
        hasDisclosurePermission ||
        !documentListPermissions.undoPaginationPermission && contextMenu.id === UNDO_PAGINATION_ID ||
        !documentListPermissions.editPaginationPermission && contextMenu.id === EDIT_PAGINATION_ID ||
        !documentListPermissions.setPaginationPermission && contextMenu.id === SET_PAGINATION_ID ||
        !documentListPermissions.addRemoveStackPermission && contextMenu.id === ADD_REMOVE_FROM_STACK_ID ||
        !documentListPermissions.managementStackPermission && contextMenu.id === STACK_MANAGEMENT_ID ||
        !documentListPermissions.deleteDocumentPermission && contextMenu.id === DELETE_DOCUMENT_ID ||
        !documentListPermissions.setDisclosurePermission && contextMenu.id === SET_DISCLOSURE_ID ||
        !documentListPermissions.replaceDisclosurePermission && contextMenu.id === REPLACE_DISCLOSURE_ID ||
        !documentListPermissions.undoPaginationPermission && contextMenu.id === UNDO_DISCLOSURE_ID ||
        !documentListPermissions.editFileNamePermission && contextMenu.id === EDIT_DOCUMENT_ORIGINAL_NAME
    ) {
        return {
            ...contextMenu,
            disabled: true,
        };
    }

    return contextMenu.menu ? {
        ...contextMenu,
        menu: contextMenu.menu.map(
            (submenu: IContextData): IContextData =>
                updateDocumentMenuWithPermissions(submenu, documentListPermissions),
        ),
    } : contextMenu;
};

export const updatePageMenuWithPermissions = (
    contextMenu: IContextData,
    pageListPermissions: IModalElementPermissions,
): IContextData => {

    if (
        !pageListPermissions.deletePagePermission && contextMenu.id === DELETE_PAGE_ID ||
        !pageListPermissions.setDisclosurePermission && contextMenu.id === CHANGE_PAGE_DISCLOSURE_LIST_MENU ||
        !pageListPermissions.undoPaginationPermission && contextMenu.id === UNDO_DISCLOSURE_ID ||
        !pageListPermissions.exportPagePermission && contextMenu.id === EXPORT_ID
    ) {
        return {
            ...contextMenu,
            disabled: true,
        };
    }

    return contextMenu.menu ? {
        ...contextMenu,
        menu: contextMenu.menu.map(
            (submenu: IContextData): IContextData => updateDocumentMenuWithPermissions(submenu, pageListPermissions),
        ),
    } : contextMenu;

};

export const updateContextMenuWithPermissions = (
    contextMenu: IContextMenu,
    permissionsList: IModalElementPermissions,
    updateMenu: (submenu: IContextData, permissionsList: IModalElementPermissions) => IContextData,
): IContextMenu => ({
    ...contextMenu,
    menu: contextMenu.menu.map(
        (submenu: IContextData): IContextData => updateMenu(submenu, permissionsList),
    ),
});

export const getContextMenuWithPermissions = createSelector(
    [getContextMenuState, getDocumentListPermissions, getPageListPermissions],
    (
        popup: IContextMenuStore,
        documentListPermissions: IModalElementPermissions,
        pageListPermissions: IModalElementPermissions,
    ): IContextMenuStore => {
        const { contextMenuList } = popup;

        const contextMenuArray = contextMenuList.map((contextMenu: IContextMenu): IContextMenu => {
            if (contextMenu.id === DOCUMENT_LIST_CONTEXT_MENU) {

                return updateContextMenuWithPermissions(
                    contextMenu,
                    documentListPermissions,
                    updateDocumentMenuWithPermissions,
                );
            }

            if (contextMenu.id === PAGE_LIST_CONTEXT_MENU) {

                return updateContextMenuWithPermissions(
                    contextMenu,
                    pageListPermissions,
                    updatePageMenuWithPermissions,
                );
            }

            return contextMenu;
        });

        return {
            ...popup,
            contextMenuList: contextMenuArray,
        };
    });

// TODO need to add return type
export const getContextMenu = createSelector(
    [getContextMenuWithPermissions, getCurrentDisclosureTypes, getRedactionLanguage],
    (popup: IContextMenuStore, disclosureType: IDisclosureOptions[], language: string): IContextMenuStore => {
        const getContexDisclosurePageList = disclosureType.map((i: IDisclosureOptions): IContextData => ({
            id: `${i.id}`,
            label: (language.length && language === 'fr') ? (i.name1 && i.name1.length) ? i.name1 : i.name : i.name,
            disabled: false,
            action: (props: IModalIdsData): (dispatch: Dispatch<any>, getState: () => IState) => void =>
                changeDisclosure({
                    ...props,
                    disclosureTypeId: i.id,
                }),
        }));

        const connections = {
            [CHANGE_PAGE_DISCLOSURE_LIST_MENU]: getContexDisclosurePageList,
            [SET_DISCLOSURE_ID]: getContexDisclosurePageList,
            // id menu:  list of menu items
        };
        const contextMenu = clone(popup.contextMenuList);
        const iterate = (item: IContextMenu | IContextData): void => {
            if (item.hasOwnProperty('menu') && item.menu.length) {
                item.menu.forEach((i: IContextData): void => {
                    iterate(i);
                });
            }

            if (connections.hasOwnProperty(item.id)) {
                item.menu = connections[item.id];
            }
        };

        contextMenu.map((item: IContextMenu): void => iterate(item));

        return ({
            contextMenuList: contextMenu,
            contextMenuType: popup.contextMenuType,
            contextMenuProps: popup.contextMenuProps,
        });
    },
);

export const hasNotSetDefaultDisclosure = createSelector(
    [getContextMenu, getCurrentDocument],
    (contextMenu: IContextMenuStore, document: IDocument): boolean => {
        const { contextMenuProps: { pageIds } } = contextMenu;

        return document && document.pages && pageIds && !document.defaultDisclosureTypeId;
    },
);

export const getContextMenuAccordingToDisclosure = createSelector(
    [getContextMenu, hasNotSetDefaultDisclosure, getPageListPermissions, getRedactionLanguage, getModifiedLabels],
    (
        contextMenu: IContextMenuStore,
        isNotSetDefaultDisclosure: boolean,
        pageListPermission: IModalElementPermissions,
        language: string,
        modifiedLabels: ILabel[],
    ): IContextMenuStore => {
        const langRule = changeLang(language);
        const labels = rightClickMenuLabels;
        resourceBundle.map((resource: any) => {
            if (getRightClickMenuLabelsByKey(resource.resourceKey)) {
                labels[getRightClickMenuLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        modifiedLabels.map((resource: any) => {
            if (getRightClickMenuLabelsByKey(resource.resourceKey)) {
                labels[getRightClickMenuLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        const contextMenuList = contextMenu.contextMenuList.map((mainMenu: IContextMenu): IContextMenu => {
            if ((mainMenu.id === PAGE_LIST_CONTEXT_MENU || mainMenu.id === DOCUMENT_LIST_CONTEXT_MENU)
                && mainMenu.menu) {
                const updatedSubMenu = mainMenu.menu.map((submenu: IContextData): IContextData => {
                    submenu.label = labels[submenu.id] ? labels[submenu.id][langRule] : submenu.label;
                    if (submenu.hasOwnProperty('menu') && submenu.menu.length) {
                        submenu.menu.map((subSubmenu: IContextData): IContextData => {
                            subSubmenu.label = labels[subSubmenu.id] ? labels[subSubmenu.id][langRule] : subSubmenu.label;
                            return subSubmenu;
                        });
                    }
                    if (submenu.id === UNDO_DISCLOSURE_ID && pageListPermission.undoDisclosurePermission) {
                        if (isNotSetDefaultDisclosure) {
                            return { ...submenu, ...{ disabled: false, modal: null, action: showAlertMessage } };
                        } else {
                            return { ...submenu, ...{ disabled: false } };
                        }
                    }

                    return submenu;
                });

                return { ...mainMenu, ...{ menu: updatedSubMenu } };
            }

            return mainMenu;
        });

        return { ...contextMenu, ...{ contextMenuList } };
    },
);

export const getContextMenuStartPoint = createSelector( [getContextMenu],
    (contextMenu: IContextMenuStore): IStartCoordinate => {
    return contextMenu.contextMenuProps.startPoint;
});

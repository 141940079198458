export const GET_ALL_DISCLOSURE_TYPE_PENDING = 'reduction/GET_ALL_DISCLOSURE_TYPE_PENDING';
export const GET_ALL_DISCLOSURE_TYPE_SUCCESS = 'reduction/GET_ALL_DISCLOSURE_TYPE_SUCCESS';
export const GET_ALL_DISCLOSURE_TYPE_FAILURE = 'reduction/GET_ALL_DISCLOSURE_TYPE_FAILURE';

export const GET_CURRENT_DISCLOSURE_TYPE_PENDING = 'reduction/GET_CURRENT_DISCLOSURE_TYPE_PENDING';
export const GET_CURRENT_DISCLOSURE_TYPE_SUCCESS = 'reduction/GET_CURRENT_DISCLOSURE_TYPE_SUCCESS';
export const GET_CURRENT_DISCLOSURE_TYPE_FAILURE = 'reduction/GET_CURRENT_DISCLOSURE_TYPE_FAILURE';

export const GET_DUPLICATED_DISCLOSURE_TYPE_PENDING = 'reduction/GET_DUPLICATED_DISCLOSURE_TYPE_PENDING';
export const GET_DUPLICATED_DISCLOSURE_TYPE_SUCCESS = 'reduction/GET_DUPLICATED_DISCLOSURE_TYPE_SUCCESS';
export const GET_DUPLICATED_DISCLOSURE_TYPE_FAILURE = 'reduction/GET_DUPLICATED_DISCLOSURE_TYPE_FAILURE';

export const DELETE_DISCLOSURE_TYPE_PENDING = 'reduction/DELETE_DISCLOSURE_TYPE_PENDING';
export const DELETE_DISCLOSURE_TYPE_SUCCESS = 'reduction/DELETE_DISCLOSURE_TYPE_SUCCESS';
export const DELETE_DISCLOSURE_TYPE_FAILURE = 'reduction/DELETE_DISCLOSURE_TYPE_FAILURE';

export const POST_DISCLOSURE_TYPE_PENDING = 'reduction/POST_DISCLOSURE_TYPE_PENDING';
export const POST_DISCLOSURE_TYPE_SUCCESS = 'reduction/POST_DISCLOSURE_TYPE_SUCCESS';
export const POST_DISCLOSURE_TYPE_FAILURE = 'reduction/POST_DISCLOSURE_TYPE_FAILURE';

export const PUT_DISCLOSURE_TYPE_PENDING = 'reduction/PUT_DISCLOSURE_TYPE_PENDING';
export const PUT_DISCLOSURE_TYPE_SUCCESS = 'reduction/PUT_DISCLOSURE_TYPE_SUCCESS';
export const PUT_DISCLOSURE_TYPE_FAILURE = 'reduction/PUT_DISCLOSURE_TYPE_FAILURE';

export const UPDATE_ALL_DOCUMENTS_IN_LIST = 'reduction/UPDATE_ALL_DOCUMENTS_IN_LIST';

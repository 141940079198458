import { IState } from '../store';
import {
    IDuplicateDocument,
    IDuplicatesCurrentGroup,
    IDuplicatesState,
    IAllowPagesNavigation, IReferenceMaster, IReferencePage, IReferenceDuplicate,
} from '../reducers/duplicates/duplicates.model';
import { createSelector } from 'reselect';
import { IDuplicateReferences, IDuplicatesRefData } from '../../containers/duplicatePanels/duplicate.model';

export const getDuplicates = (state: IState): IDuplicatesState =>
    state.duplicatesReducer;

export const getDuplicatesResources = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): IDuplicateDocument[] =>  {
        return duplicatesReducer.duplicatesResources;
    }
);

export const getDuplicatesReferences = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): IDuplicateReferences[] => {
        return duplicatesReducer.duplicateReferences;
    }
);

export const getModalDuplicatesRefData = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): IDuplicatesRefData => {
        return duplicatesReducer.modalDuplicatesRefData;
    }
);

export const getModalDuplicatesRefPending = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): boolean => duplicatesReducer.modalDuplicatesRefPending,
);

export const getDuplicatesCurrentGroup = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): IDuplicatesCurrentGroup => duplicatesReducer.duplicatesCurrentGroup,
);
export const getAllowPagesNavigation = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): IAllowPagesNavigation => duplicatesReducer.allowPagesNavigation,
);

export const getSearchPending = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): boolean => duplicatesReducer.searchPending,
);

export const getAssignedModalButtonStatus = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): boolean => duplicatesReducer.duplicatesResources.length >= 2,
);

export const getMasterDocumentsReference = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): IReferenceMaster[] =>
        duplicatesReducer.referenceManagement.masterDocuments,
);

export const getMasterDocumentsPages = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): IReferencePage[] => duplicatesReducer.referenceManagement.pages,
);

export const getReferenceDuplicates = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): IReferenceDuplicate[] =>
        duplicatesReducer.referenceManagement.duplicates,
);

export const getCriteriaSearchSatus = createSelector(
    [getDuplicates],
    (duplicatesReducer: IDuplicatesState): boolean =>
        duplicatesReducer.isCriteriaSearch,
);

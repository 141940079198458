import * as React from 'react';
import { connect } from 'react-redux';
import './modalAboutRedaction.styles.scss';
import { IVersion } from './modalAboutRedaction.model';
import { getFEVersion, getBEVersion, getDBVersion } from '../../../redux/selectors/infoController';
import { IState as StoreState } from '../../../redux/store';

class ModalAboutRedaction extends React.Component<IVersion> {

    public render(): JSX.Element {
        const { FEVersion, BEVersion, DBVersion } = this.props;

        return (
            <div className='about-redaction'>
                <p>FE Version: {FEVersion}</p>
                <p>BE Version: {BEVersion}</p>
                <p>DB Version: {DBVersion}</p>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): IVersion => ({
    FEVersion: getFEVersion(state),
    BEVersion: getBEVersion(state),
    DBVersion: getDBVersion(state),
});

export default connect( mapStateToProps )(ModalAboutRedaction);

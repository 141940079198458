import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import MaterialCheckboxTableHead from './materialCheckboxTableComponents/materialCheckboxTableHead';
import MaterialCheckboxTableBody from './materialCheckboxTableComponents/materialCheckboxTableBody';
import { IMaterialCheckboxTableProps, IMaterialCheckboxTableState } from './materialCheckboxTable.model';
import './materialCheckboxTable.scss';
import { Scrollbars } from 'rc-scrollbars';

class MaterialCheckboxTable extends React.Component<IMaterialCheckboxTableProps, IMaterialCheckboxTableState> {
    public state: IMaterialCheckboxTableState = {
        selected: [],
    };

    public componentDidMount(): void {
        const { allChecked } = this.props;

        if (allChecked) {
            this.handleSelectAllClick(true);
        }
    }

    public render(): JSX.Element {
        const { rows, headers, id, model } = this.props;
        const { selected } = this.state;

        return (
            <Paper className='material-table__root'>
                <Scrollbars autoHide={true}>
                    <div className='material-table__table-wrapper'>
                        <Table className='material-table__table' aria-labelledby='tableTitle'>
                            <MaterialCheckboxTableHead
                                rows={rows}
                                headers={headers}
                                selected={selected}
                                handleSelectAll={this.handleSelectAllClick}
                            />
                            <TableBody>
                                <MaterialCheckboxTableBody
                                    id={id}
                                    rows={rows}
                                    handleRowClick={this.handleRowClick}
                                    handleSelected={this.isSelected}
                                    model={model}
                                />
                            </TableBody>
                        </Table>
                    </div>
                </Scrollbars>
            </Paper>
        );
    }

    private handleSelectedRows = (): void => {
        const { rows, id, getSelectedRows } = this.props;
        const { selected } = this.state;

        // rows prop can be whatever you pass
        const selectedRows = rows.filter((row: any): boolean => {
            return selected.some((item: number): boolean => row[id] === item);
        });

        getSelectedRows(selectedRows);
    }

    private handleSelectAllClick = (isChecked: boolean): void => {
        const { rows, id } = this.props;

        if (isChecked) {
            // rows prop can be whatever you pass
            this.setState({ selected: rows.map((row: any): any => row[id]) }, () => {
                this.handleSelectedRows();
            });
        } else {
            this.setState({ selected: [] }, () => {
                this.handleSelectedRows();
            });
        }
    }

    private handleRowClick = (id: number): void => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected }, () => {
            this.handleSelectedRows();
        });
    }

    private isSelected = (id: number): boolean => this.state.selected.indexOf(id) !== -1;
}

export default MaterialCheckboxTable;

import * as React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import { getAnnotationTypesList } from '../../../redux/selectors/annotationTypes';
import { isActiveProperty, isInActiveProperty } from '../../../utils/object.utils';
import { compose } from '../../../utils/compose.util';
import { IState as StoreState } from '../../../redux/store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { IAnnotationType } from '../../../redux/reducers/annotationTypes/annotationTypes.model';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Scrollbars } from 'rc-scrollbars';
import { SEVER_TEXT, HIGHLIGHT_TEXT } from '../../../constants/annotationTypes.constants';
import './changeAnnotationTypeTo.styles.scss';
import { RadioMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { IRadio } from '../../../components/materialUiForms/marerialUiForms.model';
import { changeAnnotationTypeTo } from '../../../redux/actions/annotations';
import {
    IChangeAnnotationTypeToDispatchProps,
    IChangeAnnotationTypeToProps,
    IChangeAnnotationTypeToState,
    IChangeAnnotationTypeToStateProps,
} from './changeAnnotationTypeTo.model';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { getSelectedAnnotationShapes, getSingleSelectedShape } from '../../../redux/selectors/redactor';
import { CONFIRMATION_DIALOG_MODAL, WARNING,
    CHANGE_LINKED_SEVER_TO_HIGHLIGHT } from '../../../constants/messages.constants';
import { getRequiredFilteredArrayByProp } from '../../../utils/array.utils';
import { CANCEL, ID, OK } from '../../../constants/common.constants';

class ChangeAnnotationTypeTo extends React.Component<IChangeAnnotationTypeToProps, IChangeAnnotationTypeToState> {

    public state: IChangeAnnotationTypeToState = {
        activeTab: SEVER_TEXT,
        selectedValue: '',
    };

    public render(): JSX.Element {
        const activeAnnotations = compose(
            (items: IAnnotationType[]) => items.filter(isActiveProperty<IAnnotationType>('isActive')),
        )(this.props.getAnnotationTypesList);

        const severs = compose(
            (items: IAnnotationType[]) => items.filter(isActiveProperty<IAnnotationType>('isSever')),
            (items: IAnnotationType[]) => items.map(this.transformToRadio),
        )(activeAnnotations);

        const annotations = compose(
            (items: IAnnotationType[]) => items.filter(isInActiveProperty<IAnnotationType>('isSever')),
            (items: IAnnotationType[]) => items.map(this.transformToRadio),
        )(activeAnnotations);

        const { activeTab, selectedValue } = this.state;

        return (
            <div className='change_type'>
                <Tabs
                    value={this.state.activeTab}
                    onChange={this.handleChange}
                    variant='fullWidth'
                >
                    <Tab value={SEVER_TEXT} label={SEVER_TEXT} />
                    <Tab value={HIGHLIGHT_TEXT} label={HIGHLIGHT_TEXT} />
                </Tabs>
                <div className='change_type-wrapper'>
                    <div className='change_type-title'>Convert Annotation to:</div>
                    <Scrollbars>
                        {
                            activeTab === SEVER_TEXT &&
                            <RadioMaterialBuilder
                                value={selectedValue}
                                handleRadioChange={this.handleSelectedValue}
                                paramName={''}
                                ariaLabel={''}
                                row={false}
                                radioList={severs}
                            />
                        }
                        {
                            activeTab === HIGHLIGHT_TEXT &&
                            <RadioMaterialBuilder
                                value={selectedValue}
                                handleRadioChange={this.handleSelectedValue}
                                paramName={''}
                                ariaLabel={''}
                                row={false}
                                radioList={annotations}
                            />
                        }
                    </Scrollbars>
                    <div className='form-footer__action'>
                        <Button
                            size='small'
                            variant='outlined'
                            className='modal-window__buttons outlined'
                            onClick={this.props.handleCloseModalWindow}
                        >
                            Cancel
                        </Button>
                        <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            className='modal-window__buttons primary'
                            disabled={!selectedValue}
                            onClick={this.save}
                        >
                            Ok
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    private save = (): void => {
        const {
            singleSelectedShape,
            selectedAnnotationShapes,
            openIsLinkedStampDeleteConfirmationModal,
            closeModalWindow,
            changeAnnotationsTypeTo,
        } = this.props;
        const annotationIds = getRequiredFilteredArrayByProp(selectedAnnotationShapes, ID, 'linkedArticles', 'length');

        if(this.state.activeTab === HIGHLIGHT_TEXT
            && ((singleSelectedShape && singleSelectedShape.isSever
            && singleSelectedShape.linkedArticles && !!singleSelectedShape.linkedArticles.length)
            || (annotationIds && !!annotationIds.length))) {

                openIsLinkedStampDeleteConfirmationModal(CONFIRMATION_DIALOG_MODAL, {
                    id: CONFIRMATION_DIALOG_MODAL,
                    title: WARNING,
                    okButtonTitle: OK,
                    cancelButtonTitle: CANCEL,
                    message: CHANGE_LINKED_SEVER_TO_HIGHLIGHT,

                    confirm: (): () => void => (): void => {
                        closeModalWindow(CONFIRMATION_DIALOG_MODAL);
                        changeAnnotationsTypeTo(
                            Number(this.state.selectedValue),
                            true,
                            this.state.activeTab !== HIGHLIGHT_TEXT ? true : false,
                        );

                        return;
                    },
                    reject: (): () => void => (): void => {
                        closeModalWindow(CONFIRMATION_DIALOG_MODAL);

                        return;
                    },
                });
        } else {
            changeAnnotationsTypeTo(
                Number(this.state.selectedValue),
                false,
                this.state.activeTab !== HIGHLIGHT_TEXT ? true : false,
            );

        }
    }

    private handleSelectedValue = (param: string, selectedValue: string): void => {
        this.setState({ selectedValue });
    }

    private handleChange = (event: React.SyntheticEvent, activeTab: string): void => {
        this.setState({ activeTab });
    }

    private transformToRadio =({name, id}: IAnnotationType): IRadio => {
        return {
            label: name,
            value: String(id),
        };
    }
}

const mapStateToProps = (state: StoreState): IChangeAnnotationTypeToStateProps => ({
    getAnnotationTypesList: getAnnotationTypesList(state),
    singleSelectedShape: getSingleSelectedShape(state),
    selectedAnnotationShapes: getSelectedAnnotationShapes(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IChangeAnnotationTypeToDispatchProps, AnyAction>,
): IChangeAnnotationTypeToDispatchProps => ({
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    changeAnnotationsTypeTo: (id: number, isSeverLinked?: boolean, isChangeToSever?: boolean): void => {
        dispatch(changeAnnotationTypeTo(id, isSeverLinked, isChangeToSever));
    },
    openIsLinkedStampDeleteConfirmationModal: (type: string, modalProps: IModalProps): void => {
        dispatch(openModalWindow(type, modalProps));
    },
    closeModalWindow: (type: string): void => {
        dispatch(handleCloseModalWindow(type));
    },
});

export default connect( mapStateToProps, mapDispatchToProps)(ChangeAnnotationTypeTo);

import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import { IState as StoreState } from '../../../redux/store';
import {
    IPageReviewSummaryDispatchProps,
    IPageReviewSummaryStateProps,
    IProps,
    IState,
} from './modalPageReviewSummary.model';
import './modalPageReviewSummary.styles.scss';
import Button from '@mui/material/Button';
import { getStackList } from '../../../redux/selectors/stackList';
import { IStackOptions } from '../../../redux/reducers/stackList/stackList.model';
import { fetchPagesReviewSummaryByStackId } from '../../../redux/actions/pagesReviewSummary';
import { pagesReviewSummaryPending } from '../../../redux/selectors/modalPageReviewSummary';
import { LinearProgress } from '@mui/material';
import { SelectMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { NAME } from '../../../constants/stack.contstants';
import { ID } from '../../../constants/common.constants';
import {
    ALL_DOCUMENTS_LABEL,
    allDocumentsOption,
    REPORT_CRITERIA_LABEL,
} from '../../../constants/modalPageReviewSummary.constants';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { initialLabel, langFrench } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { handleCloseAllModalWindows, openModalWindow } from '../../../redux/actions/modal';
import { getReportLanguageModalLabels, REPORT_LANG_SELECTION_MODAL, REPORT_LANG_SELECTION_TITLE } from '../../../constants/modalReportLangSelection.constants';
import { getItem } from '../../../utils/localStorage.util';

export const getReportsLabelsByKey = (key: string): string => {
    switch(key) {
        case 'MODAL_EXPORT_SELECT_ALL_DOCUMENTS':
            return 'allDocumentLabel';
        case 'COMMON_LABEL_GENERATE':
            return 'generateLabel';
        case 'REPORTS_PAGE_REVIEW_REPORT_CRITERIA':
            return 'reportCriteriaLabel';
        case 'REPORTS_REQUESTER_REPORT_SHOW_DATE_TIME':
            return 'showDateTimeLabel';
        case 'REPORTS_REQUESTER_REPORT_SELECTED_ITEMS_ONLY':
            return 'reportSelectedItemsLabel';
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        default: return '';
    }
};

class ModalPageReviewSummary extends React.Component<IProps, IState> {
    public state: IState = {
        selectedStack: ALL_DOCUMENTS_LABEL,
    };

    public render(): JSX.Element {
        const { stackList, modifiedLabels, redactionLang } = this.props;
        const { selectedStack } = this.state;
        const langRule = changeLang(redactionLang);
        const labels = {
            allDocumentLabel: initialLabel,
            generateLabel: initialLabel,
            reportCriteriaLabel: initialLabel,
        };
        const stackOptions = stackList.map((stack: IStackOptions) => ({
            label: stack[NAME],
            value: `${stack[ID]}`,
        }));

        resourceBundle.map((resource: any) => {
            if (getReportsLabelsByKey(resource.resourceKey)) {
                labels[getReportsLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getReportsLabelsByKey(resource.resourceKey)) {
                labels[getReportsLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        const fullStackOptions = [
            { label: labels.allDocumentLabel[langRule], value: ALL_DOCUMENTS_LABEL },
            ...stackOptions,
        ];

        return (
            <div className='review-summary'>
                <SelectMaterialBuilder
                    value={selectedStack}
                    label={labels.reportCriteriaLabel[langRule]}
                    paramName={ALL_DOCUMENTS_LABEL}
                    handleSelectChange={(param: string, val: string): void => this.handleSelectStack(val)}
                    options={fullStackOptions}
                />
                <div className='review-summary_button'>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        className='buttons-save primary'
                        onClick={this.onSelect}
                    >
                       {labels.generateLabel[langRule]}
                    </Button>
                </div>
                <div className='review-summary_loader'>
                    {this.props.pagesReviewSummaryPending && <LinearProgress />}
                </div>
            </div>
        );
    }

    private handleSelectStack = (value: string): void => {
        this.setState({ selectedStack: value });
    }

    private onSelect = (): void => {
        this.props.handleOpenModalWindow(REPORT_LANG_SELECTION_MODAL, {
            id: REPORT_LANG_SELECTION_MODAL,
            title: getReportLanguageModalLabels(REPORT_LANG_SELECTION_TITLE),
            okButtonTitle: getReportLanguageModalLabels('Ok'),
            cancelButtonTitle: getReportLanguageModalLabels('Cancel'),
            confirm: (): () => void => (): void => {
                const reportLang = getItem("reportLang");
                const stackId = this.state.selectedStack;
        
                this.props.fetchPagesReviewSummaryByStack(
                    this.props.reductionDocumentId,
                    stackId === ALL_DOCUMENTS_LABEL ? null : Number(this.state.selectedStack),
                    reportLang
                );
                this.props.handleCloseModalWindow();
            },
            reject: (): () => void => (): void => {
                this.props.handleCloseModalWindow();
            },
        });
    }
}

const mapStateToProps = (state: StoreState): IPageReviewSummaryStateProps => ({
    reductionDocumentId: getRedactionDocumentId(state),
    pagesReviewSummaryPending: pagesReviewSummaryPending(state),
    stackList: getStackList(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<StoreState, IPageReviewSummaryDispatchProps, AnyAction>):
        IPageReviewSummaryDispatchProps => ({
    fetchPagesReviewSummaryByStack: (redactionDocumentId: number, stackId: number, reportLang: string): void => {
        dispatch(fetchPagesReviewSummaryByStackId(redactionDocumentId, stackId, reportLang));
    },
    handleOpenModalWindow: (modalType: string, modalProps: IModalProps): void => {
        dispatch(openModalWindow(modalType, modalProps));
    },    
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
});

export default connect( mapStateToProps, mapDispatchToProps) (ModalPageReviewSummary);

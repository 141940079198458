import { AnyAction } from 'redux';
import { IWatermarksAssociateState,
         IWatermarkTypeAssociate,
         IWatermarkRequest } from './modalAssociateWatermarks.model';
import { GET_WATERMARKS_SETTINGS_PENDING,
         GET_WATERMARKS_SETTINGS_FAILURE,
         GET_WATERMARKS_SETTINGS_SUCCESS,
         PUT_WATERMARK_TYPE_SUCCESS,
         PUT_WATERMARK_TYPE_FAILURE,
         PUT_WATERMARK_TYPE_PENDING } from './constants';

const initialState: IWatermarksAssociateState = {
    requestTypesWithWatermarkTypes: [],
    watermarksSettings: [],
    watermarkTypes: [],
    selectedWatermarkTypes: [],
    watermarksPending: false,
    error: null,
};

const modalWatermarksAssociate = (
    state: IWatermarksAssociateState = initialState,
    {type, payload}: AnyAction,
): IWatermarksAssociateState => {

    switch (type) {
        case PUT_WATERMARK_TYPE_PENDING:
        case GET_WATERMARKS_SETTINGS_PENDING:
        return {
            ...state,
            error: payload,
            watermarksPending: true,
        };
        case GET_WATERMARKS_SETTINGS_SUCCESS:
        return {
            ...state,
            watermarksSettings: payload.requestTypesWithWatermarkTypes,
            watermarkTypes: payload.watermarkTypes,
            requestTypesWithWatermarkTypes: payload.requestTypesWithWatermarkTypes
            .map(( setting: IWatermarkRequest) => {
                return {defaultWatermarkTypeId: setting.defaultWatermarkTypeId,
                        redactionDocumentId: setting.redactionDocumentId,
                        requestType: setting.requestType,
                        requestDescription: setting.requestDescription};
            }),
            watermarksPending: false,
        };
        case GET_WATERMARKS_SETTINGS_FAILURE:
        case PUT_WATERMARK_TYPE_FAILURE:
        return {
            ...state,
            error: payload,
            watermarksPending: false,
        };
        case PUT_WATERMARK_TYPE_SUCCESS:
        return{
            ...state,
            requestTypesWithWatermarkTypes: payload,
            watermarksPending: false,
        };

        default:
            return state;
    }
};

export default modalWatermarksAssociate;

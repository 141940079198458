import { applyMiddleware, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { ThunkAction } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from './reducers/index';
import { DocumentListState } from './reducers/documentList/documentList.model';
import { IRedactorState } from './reducers/redactor/redactor.model';
import { IModalStore } from './reducers/modal/modal.model';
import { IContextMenuStore } from './reducers/contextMenu/contextMenu.model';
import { PageListState } from './reducers/pageList/pageList.model';
import { LocalStorageState } from './reducers/localStorage/localStorage.model';
import { ILayoutRedactorTypes } from './reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { IAnnotationTypesState } from './reducers/annotationTypes/annotationTypes.model';
import { IDisclosureTypeState } from './reducers/disclosureTypes/disclosureType.model';
import { IStackListState } from './reducers/stackList/stackList.model';
import { IModalWindowExportState } from './reducers/modalWindowExport/modalWindowExport.model';
import { IModalEditPaginationState } from './reducers/modalEditPagination/modalEditPagination.model';
import { IModalWindowDisclosureState } from './reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { IWatermarksManagementState } from './reducers/modalWatermarks/watermarksManagement.model';
import { IWatermarksAssociateState } from './reducers/modalAssociateWatermarks/modalAssociateWatermarks.model';
import { INotificationState } from './reducers/notificationHandling/reducer';
import { IAmandaContentState } from './reducers/amandaContent/amandaContent.model';
import { IVersionController } from './reducers/infoController/infoController.model';
import { IAnnotationState } from './reducers/annotation/annotation.model';
import { IGlobalSettingsState } from './reducers/modalGlobalSettings/globalSettings.model';
import { IStampsState } from './reducers/stamps/stamps.model';
import { IDisclosureContactsState } from './reducers/disclosureContacts/disclosureContacts.model';
import { IScannerState } from './reducers/scanner/scanner.model';
import { IModalSearchDocuments } from './reducers/modalSearchDocuments/searchDocuments.model';
import { IRequesterReportTypes } from './reducers/modalRequesterReport/requesterReport.model';
import { ITaskListReducer } from './reducers/taskQueue/taskQueue.model';
import { IHeaderState } from './reducers/header/header.model';
import { IPrinterState } from './reducers/printer/printer.model';
import { IOfficerReportTypes } from './reducers/reportsOfficer/reportsOffice.model';
import { IModalWindowImportState } from './reducers/modalWindowImport/modalWindowImport.model';
import { IPackagePresetState } from '../containers/modalWindowContents/modalPackagePresets/modalPackagePresets.model';
import { IDocumentIndexState } from './reducers/documentIndex/documentIndex.model';
import { IIndexMetadataState } from './reducers/indexMetadata/indexMetadata.model';
import { IIndexMetadataDescriptionsState } from './reducers/indexMetadataDescriptions/indexMetadataDescriptions.model';
import { IPageIndexState } from './reducers/pageIndex/pageIndex.model';
import { IInitializationState } from './reducers/initialization/initialization.model';
import { IRequestSearchState } from './reducers/modalRequestSearch/requestSearch.model';
import { IReduxStateSearch } from '../containers/globalSearch/globalSearch.model';
import { IModalEncryptionState } from './reducers/modalEncryption/modalEncryptionKeys.model';
import { IPageMetadataState } from './reducers/pageMetadata/notes.model';
import { IAdvanceSearchReducer } from './reducers/advanceSearch/advanceSearch.model';
import { IIndexNotes } from './reducers/reportsIndexNotes/reportsIndexNotes.model.';
import { IAuditLog } from './reducers/auditLog/auditLog.model';
import { IPagesReviewSummaryData } from './reducers/modalPageReviewSummary/pagesReviewSummary.model';
import { IDuplicatesState } from './reducers/duplicates/duplicates.model';
import { IAssignDuplicatesState } from './reducers/modalAssignDuplicates/modalAssignDuplicates.model';
import { ILocalizationState } from './reducers/localization/localization.model';
import { ITableState } from './reducers/common/common.model';

const logger = createLogger({
    collapsed: true,
});

export interface IState extends Store {
    reductionMetaData: IInitializationState;
    documentList: DocumentListState;
    pageList: PageListState;
    annotation: IAnnotationState;
    redactor: IRedactorState;
    modal: IModalStore;
    contextMenu: IContextMenuStore;
    localStorage: LocalStorageState;
    layoutRedactorTypes: ILayoutRedactorTypes;
    modalWindowEncryptionKeys: IModalEncryptionState;
    requesterReportTypes: IRequesterReportTypes;
    reportsOfficer: IOfficerReportTypes;
    indexNotesReports: IIndexNotes;
    annotationTypes: IAnnotationTypesState;
    modalWindowExport: IModalWindowExportState;
    modalWindowImport: IModalWindowImportState;
    modalWindowDisclosure: IModalWindowDisclosureState;
    modalEditPagination: IModalEditPaginationState;
    modalSearchDocuments: IModalSearchDocuments;
    modalPackagePresets: IPackagePresetState;
    modalWatermarksManagement: IWatermarksManagementState;
    modalWatermarksAssociate: IWatermarksAssociateState;
    modalRequestSearch: IRequestSearchState;
    modalAssignDuplicates: IAssignDuplicatesState;
    modalPageReviewSummary: IPagesReviewSummaryData;
    amandaContent: IAmandaContentState;
    disclosureTypes: IDisclosureTypeState;
    notifications: INotificationState;
    version: IVersionController;
    modalGlobalSettings: IGlobalSettingsState;
    modalDisclosureContacts: IDisclosureContactsState;
    stamps: IStampsState;
    stackList: IStackListState;
    scanner: IScannerState;
    taskQueueList: ITaskListReducer;
    header: IHeaderState;
    printer: IPrinterState;
    auditLog: IAuditLog;
    localization: ILocalizationState;
    documentIndex: IDocumentIndexState;
    indexMetadata: IIndexMetadataState;
    indexMetadataDescriptions: IIndexMetadataDescriptionsState;
    pageIndex: IPageIndexState;
    globalSearch: IReduxStateSearch;
    pageMetadata: IPageMetadataState;
    advanceSearch: IAdvanceSearchReducer;
    duplicatesReducer: IDuplicatesState;
    tableReducer : ITableState;
}

export type ThunkResult<R> = ThunkAction<R, IState, null, AnyAction>;

const isDevelopmentMode  = process.env.NODE_ENV === 'development';
const store: Store = createStore(
    rootReducer,
    isDevelopmentMode
        ? composeWithDevTools(applyMiddleware(thunk, logger))
        : applyMiddleware(thunk),
);

export default store;

import { action } from 'typesafe-actions';
import {
    GET_CURRENT_PAGE_STAMPS_FAILURE,
    GET_CURRENT_PAGE_STAMPS_SUCCESS,
    GET_CURRENT_PAGE_STAMPS_PENDING,
    UPDATE_STAMP_PENDING,
    UPDATE_STAMP_SUCCESS,
    UPDATE_STAMP_FAILURE,
    ADD_STAMPS_PENDING,
    ADD_STAMPS_SUCCESS,
    ADD_STAMPS_FAILURE,
    DELETE_STAMPS_FAILURE,
    DELETE_STAMPS_SUCCESS,
    DELETE_STAMPS_PENDING,
    REMOVE_STAMPS_PER_PAGE,
    SET_AUTO_STAMP,
    HIGHLIGHT_LINKED_ARTICLES,
    CLEAR_LINKED_ARTICLES,
    GET_STAMP_ALIGN_FAILURE,
    GET_STAMP_ALIGN_PENDING,
    GET_STAMP_ALIGN_SUCCESS,
    UPDATE_STAMP_ALIGN_FAILURE,
    UPDATE_STAMP_ALIGN_PENDING,
    UPDATE_STAMP_ALIGN_SUCCESS,
    CLEAR_LINKED_ARTICLES_FOR_ALL_ANNOTATIONS,
} from '../reducers/stamps/constants';
import api from '../../api/reductionApi';
import { addError } from './errorHandling';
import { AnyAction, Dispatch } from 'redux';
import { isDisclosureTypeDefaultOnPage, updatePageMetaDataInDocument, changePageExemptionSuccess } from './pageList';
import { IError } from '../common.model';
import { IStamp, IStampAlign } from '../reducers/stamps/stamps.model';
import { IPageActionParams } from '../reducers/annotation/annotation.model';
import { IState } from '../store';
import { IAnnotation, IDisclosureReset } from '../reducers/pageList/pageList.model';
import { first } from 'lodash';
import { START_POINT } from '../../constants/annotationTypes.constants';
import { refreshResultDisclosureForDocument } from './disclosureTypes';
import { setLinkedStampId } from './redactor';
import { setRecentUndoSnapAnnotation, snapAnnotationPending } from './annotations';

export const getPageStampsPending = (): AnyAction => action(GET_CURRENT_PAGE_STAMPS_PENDING);
export const getPageStampsSuccess = (data: IStamp[]): AnyAction => action(GET_CURRENT_PAGE_STAMPS_SUCCESS, data);
export const getPageStampsFailure = (error: IError): AnyAction => action(GET_CURRENT_PAGE_STAMPS_FAILURE, error);

export const updateStampPending = (): AnyAction => action(UPDATE_STAMP_PENDING);
export const updateStampSuccess = (data: IStamp[]): AnyAction => action(UPDATE_STAMP_SUCCESS, data);
export const updateStampFailure = (error: IError): AnyAction => action(UPDATE_STAMP_FAILURE, error);

export const addStampsPending = (data: IStamp[]): AnyAction => action(ADD_STAMPS_PENDING, data);
export const addStampsSuccess = (data: IStamp[]): AnyAction => action(ADD_STAMPS_SUCCESS, data);
export const addStampsFailure = (error: IError): AnyAction => action(ADD_STAMPS_FAILURE, error);

export const deleteStampsPending = (): AnyAction => action(DELETE_STAMPS_PENDING);
export const deleteStampsSuccess = (data: number[]): AnyAction => action(DELETE_STAMPS_SUCCESS, data);
export const deleteStampsFailure = (error: IError): AnyAction => action(DELETE_STAMPS_FAILURE, error);

export const removeStampsPerPage = (data: IDisclosureReset): AnyAction => action(REMOVE_STAMPS_PER_PAGE, data);

export const setAutoStamp = (autoStamp: boolean): AnyAction => action(SET_AUTO_STAMP, autoStamp);

export const clearLinkedArticles = (stampId: number): AnyAction => action(CLEAR_LINKED_ARTICLES, stampId);

export const clearLinkedArticlesForAllAnnotations = (): AnyAction => action(CLEAR_LINKED_ARTICLES_FOR_ALL_ANNOTATIONS);

export const getStampAlignPending = (): AnyAction => action(GET_STAMP_ALIGN_PENDING);
export const getStampAlignSuccess = (alignmentValue: string): AnyAction =>
    action(GET_STAMP_ALIGN_SUCCESS, alignmentValue);
export const getStampAlignFailure = (error: IError): AnyAction => action(GET_STAMP_ALIGN_FAILURE, error);

export const updateStampAlignPending = (): AnyAction => action(UPDATE_STAMP_ALIGN_PENDING);
export const updateStampAlignSuccess = (alingment: string): AnyAction => action(UPDATE_STAMP_ALIGN_SUCCESS, alingment);
export const updateStampAlignFailuer = (error: IError): AnyAction => action(UPDATE_STAMP_ALIGN_FAILURE, error);

export const highlightLinkedArticles = (linkedArticlesId: number[]): AnyAction =>
    action(HIGHLIGHT_LINKED_ARTICLES, linkedArticlesId);

export const getPageStamps = (
    {
        redactionDocumentId,
        documentId,
        pageId,
    }: IPageActionParams,
    annotations: IAnnotation[] = [],
    isAddAnnotationFromSearch: boolean = false,
): (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            annotation: {
                autoCorrectSever,
                snapAnnotationPending: snapAnnotationPendingState,
            },
        } = getState();

        dispatch(getPageStampsPending());

        try {
            const stamps = await api.stamps.getStampsPerPage(redactionDocumentId, documentId, pageId);

            dispatch(getPageStampsSuccess(stamps));

            if(autoCorrectSever && annotations.length &&
                annotations[0]['preCoordinate'] && !isAddAnnotationFromSearch) {
                dispatch(setRecentUndoSnapAnnotation(annotations[0].id));
            }

            if(snapAnnotationPendingState) {
                dispatch(snapAnnotationPending(false));
            }

        } catch (error) {
            dispatch(getPageStampsFailure(error));
            dispatch(addError(error));
            dispatch(snapAnnotationPending(false));

        }
    };

export const updateStamp = (
    {
        redactionDocumentId,
        documentId,
        pageId,
    }: IPageActionParams,
    data: IStamp[],
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(updateStampPending());

        try {
            const stamps = await api.stamps.updateStamp(redactionDocumentId, documentId, pageId, data);

            dispatch(updateStampSuccess(stamps));
        } catch (error) {
            dispatch(updateStampFailure(error));
            dispatch(addError(error));
        }
    };

export const addStamps = (
    {
        redactionDocumentId,
        documentId,
        pageId,
    }: IPageActionParams,
    data: IStamp[],
    actionCall?: () => AnyAction,
): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const {
            redactor: {
                linkedAnnotaionIdAndStampsId: {
                    linkedArticlesFlag,
                    annotaionId,
                    articlesId,
                },
            },
        } = getState();

        dispatch(addStampsPending(data));

        try {
            // Filter added in scope of https://csdcjira.atlassian.net/browse/RED-2862 bug.
            const dataWithoutNegativeCoords = data.filter(({ coordinate }: IStamp) =>
                first(coordinate)[START_POINT].x > 0 && first(coordinate)[START_POINT].y > 0);
            const stamps = await api.stamps
                .addStampsToPage(redactionDocumentId, documentId, pageId, dataWithoutNegativeCoords);
            const { pageList: { currentDocument } } = getState();
            const articleStamps = data.filter((stamp: IStamp): boolean => stamp.isExemption)
                .map((stamp: IStamp): number => stamp.stampTypeId);

            dispatch(addStampsSuccess(stamps));

            if (linkedArticlesFlag) {
                const linkedstampIds = stamps.map((stamp: IStamp): number => {
                    return stamp.id;
                });

                dispatch(setLinkedStampId(linkedstampIds));
            } else if (linkedArticlesFlag && annotaionId && articlesId && !articlesId.length) {
                const linkedstampIds = stamps.map((stamp: IStamp): number => {
                    return stamp.id;
                });

                dispatch(setLinkedStampId(linkedstampIds));
            }

            dispatch(changePageExemptionSuccess(pageId, articleStamps));

            if (data[0].isExemption) {
                dispatch(refreshResultDisclosureForDocument(documentId));
            }

            if (actionCall) {
                dispatch(actionCall());
            }

            // TODO: NEED REFACTOR isDisclosureTypeDefaultOnPage, make like annotations
            if (isDisclosureTypeDefaultOnPage(currentDocument, documentId, pageId)) {
                dispatch(updatePageMetaDataInDocument(redactionDocumentId, documentId, pageId));
            }
        } catch (error) {
            dispatch(addStampsFailure(error));
            dispatch(addError(error));
        }
    };

export const deleteStamps = (
    {
        redactionDocumentId,
        documentId,
        pageId,
    }: IPageActionParams,
    data: number[],
): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const { annotation: { annotations } } = getState();

        dispatch(deleteStampsPending());

        try {
            await api.stamps.deleteStamps(redactionDocumentId, documentId, pageId, data);

            dispatch(deleteStampsSuccess(data));
            dispatch(refreshResultDisclosureForDocument(documentId));

            if(data && data.length) {
                data.map((stampId: number) => {
                    if (annotations && annotations.length) {
                        annotations.map((annotation: IAnnotation) => {
                            if(annotation.linkedArticles &&
                                annotation.linkedArticles.length && annotation.linkedArticles.includes(stampId)) {
                                dispatch(clearLinkedArticles(stampId));
                            }
                        });
                    }
                });
            }
        } catch (error) {
            dispatch(deleteStampsFailure(error));
            dispatch(addError(error));
        }
    };

export const setStampAlingment = (): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                userId,
            },
        } = getState();

        dispatch(getStampAlignPending());
        try {
            const response = await api.stamps.getStampAlingment(userId);

            dispatch(getStampAlignSuccess(response.stampAlignment));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const updateStampAlingment = (stampAlignment: string):
    (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                userId,
            },
        } = getState();

        dispatch(updateStampAlignPending());
        try {
            const response = await api.stamps.updateStampAlingment({ stampAlignment, userId });

            dispatch(updateStampAlignSuccess(response.stampAlignment));
        } catch (error) {
            dispatch(addError(error));
        }
    };

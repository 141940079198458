if (!Object.assign) {
    // Disable this rule because we need arguments. Arrow function has not it.
    /* tslint:disable only-arrow-functions */
    Object.assign = function(target: object): object {
        /* tslint:enable only-arrow-functions */
        if (target === null) {
            throw new TypeError('Cannot convert undefined or null to object');
        }

        const newTarget = Object(target);

        for (let index = 1; index < arguments.length; index++) {
            const source = arguments[index];

            if (source !== null) {
                for (const key in source) {
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        newTarget[key] = source[key];
                    }
                }
            }
        }

        return newTarget;
    };
}

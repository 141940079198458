import { AnyAction } from 'redux';
import {
    ADD_PACKAGE_PRESET_SUCCESS,
    ADD_PACKAGE_PRESET_FAILURE,
    GET_ALL_PACKAGE_PRESETS_FAILURE,
    GET_ALL_PACKAGE_PRESETS_SUCCESS,
    REMOVE_PACKAGE_ANNOTATION_TYPE,
    SET_PACKAGE_ANNOTATION_TYPE,
    SET_PRESET_EXPORT_OPTIONS,
    SET_PRESET_PAGINATION_OPTIONS,
    SET_SELECTED_PRESET,
    DELETE_PACKAGE_PRESET_SUCCESS,
    DELETE_PACKAGE_PRESET_FAILURE,
    SET_DEFAULT_PRESET,
    SET_SELECTED_PRESET_ID, EDIT_PACKAGE_PRESET_SUCCESS, EDIT_PACKAGE_PRESET_FAILURE,
} from './constants';
import * as constants from '../../../constants/export.contstants';
import { initialPaginationOptions } from '../modalWindowExport/reducer';
import {
    IExportPreset,
    IPackagePresetReduxState,
} from 'containers/modalWindowContents/modalPackagePresets/modalPackagePresets.model';
import { DEFAULT_ITEM_COLOR } from '../../../constants/annotationTypes.constants';
import { CUSTOM_SEVER_COLOR_PARAM } from '../../../constants/export.contstants';
import { ORIGINAL_SEVER_COLOR_PARAM } from '../../../constants/export.contstants';

export const initialExportOptionsState = {
    [constants.SEVER_COLOR_PARAM]: null,
    [constants.ORIGINALS_ONLY_PARAM]: false,
    [constants.DOCUMENT_ORDER_PARAM]: constants.BY_PAGINATION_PARAM,
    [constants.CREATE_PACKAGE_AS_SINGLE_FILE_PARAM]: false,
    [constants.NON_RELEASABLE_PAGES_PARAM]: constants.EXCLUDE_PARAM,
    [constants.LABEL_DOCUMENT_BY_PARAM]: constants.DOCUMENT_NAME_PARAM,
    [constants.SHOW_ARTICLE_STAMPS_PARAM]: false,
    [constants.SHOW_USER_DEFINED_STAMP_PARAM]: false,
    [constants.INCLUDE_PAGINATED_PAGES_ONLY_PARAM]: false,
    [constants.INCLUDE_PAGES_WITH_CONTACT_DISCLOSURES_PARAM]: false,
    [constants.INCLUDE_SEVER_TO_ARTICLES_LINKING_LINES_PARAM]: false,
    [constants.ARTICLE_COLOR_PARAM]: '#000000',
    [constants.SHOW_WATERMARKS_PARAM]: false,
    [constants.WATERMARK_LOCATION_PARAM]: constants.POSITION_MIDDLE_LEFT_PARAM,
    [constants.INCLUDE_NOTES_PARAM]: false,
};

const initialState = {
    exportOptions: initialExportOptionsState,
    paginationOptions: initialPaginationOptions,
    annotationPresetIdList: [],
    presets: [],
    selectedPresetId: null,
};

const packagePresetsReducer = (state: IPackagePresetReduxState = initialState,
                               { type, payload }: AnyAction): IPackagePresetReduxState => {
    switch (type) {
        case SET_PRESET_EXPORT_OPTIONS:
            return {
                ...state,
                exportOptions: {
                    ...state.exportOptions,
                    ...payload,
                },
            };
        case SET_PRESET_PAGINATION_OPTIONS:
            return {
                ...state,
                paginationOptions: {
                    ...state.paginationOptions,
                    ...payload,
                },
            };
        case SET_PACKAGE_ANNOTATION_TYPE:
            return {
                ...state,
                annotationPresetIdList: [...state.annotationPresetIdList, payload],
            };
        case REMOVE_PACKAGE_ANNOTATION_TYPE:
            return {
                ...state,
                annotationPresetIdList: state.annotationPresetIdList.filter((id: number): boolean => id !== payload),
            };
        case SET_SELECTED_PRESET:
            const { exportOptions } = payload;

            const severColorOption = {
                severColorOption: exportOptions.severColorOption ? exportOptions.severColorOption : DEFAULT_ITEM_COLOR,
            };

            const severColorType = {
                severColorType: exportOptions.severColorOption ? CUSTOM_SEVER_COLOR_PARAM : ORIGINAL_SEVER_COLOR_PARAM,
            };

            return {
                ...state,
                exportOptions: {
                    ...payload.exportOptions,
                    ...severColorOption,
                    ...severColorType,
                },
                paginationOptions: { ...payload.paginationOptions },
                annotationPresetIdList: [...payload.annotationControlOptions],
            };
        case ADD_PACKAGE_PRESET_SUCCESS:
            return {
                ...state,
                presets: [
                    ...state.presets,
                    ...payload,
                ],
                error: null,
            };
        case ADD_PACKAGE_PRESET_FAILURE:
        case DELETE_PACKAGE_PRESET_FAILURE:
        case GET_ALL_PACKAGE_PRESETS_FAILURE:
        case EDIT_PACKAGE_PRESET_FAILURE:
            return {
                ...state,
                error: payload,
            };
        case GET_ALL_PACKAGE_PRESETS_SUCCESS:
            return {
                ...state,
                presets: payload,
                error: null,
            };
        case DELETE_PACKAGE_PRESET_SUCCESS:
            return {
                ...state,
                presets: state.presets.filter((preset: IExportPreset): boolean => preset.id !== payload),
                error: null,
            };
        case EDIT_PACKAGE_PRESET_SUCCESS:
            return {
                ...state,
                presets: state.presets.map((preset: IExportPreset): IExportPreset => {
                    return preset.id !== payload.id ? preset : { ...preset, ...payload };
                }),
                error: null,
            };
        case SET_SELECTED_PRESET_ID:
            return {
                ...state,
                selectedPresetId: payload,
            };
        case SET_DEFAULT_PRESET:
            return {
                ...state,
                exportOptions: {
                    ...initialExportOptionsState,
                    severColorOption: DEFAULT_ITEM_COLOR,
                    severColorType: ORIGINAL_SEVER_COLOR_PARAM,
                    name: '',
                },
                paginationOptions: initialPaginationOptions,
                annotationPresetIdList: [],
                selectedPresetId: null,
            };
        default:
            return state;
    }
};

export default packagePresetsReducer;

import * as React from 'react';
import { omit, get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import {
    NAME,
    DESCRIPTION,
    emptyStackProperty,
    EMPTY_STACK_ID,
    STACK_PROPERTIES_MODAL,
} from '../../../constants/stack.contstants';
import { ID } from '../../../constants/common.constants';
import { Button, Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IDispatchProps, IProps, IStateProps } from './modalStackProperties.model';
import './modalStackProperties.style.scss';
import { IState as StoreState } from '../../../redux/store';
import { Spinner } from '../../../components/spinner/Spinner';
import {
    CONFIRMATION_DIALOG_MODAL,
    WARNING,
    CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
    DELETE_STACK_PROPERTY_MESSAGE,
} from '../../../constants/messages.constants';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import LinearProgress from '@mui/material/LinearProgress';
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form';
import { validate } from '../../../utils/validate.util';
import { AnyObject, FormApi, FormState } from 'final-form';
import { CANCEL, CLOSE, SAVE } from '../../../constants/common.constants';
import { MAX_LENGTH, REQUIRED, UNIQUE } from '../../../constants/validate.constants';
import StackList from './StackList/StackList';
import {
    changeStackPropertyOptions,
    setStackPropertyOptions,
    deleteStackProperty,
    putStackProperty,
    postStackProperty, fetchStackPropertyList, clearStackPropertyOptions,
} from '../../../redux/actions/stackList';
import { IStackOptions, IStackPropertyOptions } from '../../../redux/reducers/stackList/stackList.model';
import {
    getStackPropertyList,
    getStackPropertyListLoading,
    getStackPropertyListUpdating,
    getStackPropertyOptions,
} from '../../../redux/selectors/stackList';
import { renderTextField } from '../../../components/materialUiForms/materialUiForms';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { changeLang } from '../../../redux/actions/localization';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { getStackLabelsByKey } from '../modalDocumentStack/modalDocumentStack';

const classNames = {
    stackModal: 'stack-modal',
    stackModalFooter: 'stack-modal-footer',
    stackModalBody: 'stack-modal-body',
    stackListWrap: 'stack-list-wrap',
    stackTabsWrap: 'stack-tabs-wrap',
    stackLoader: 'stack-modal-loader',
};

const validateFields = [
    {
        field: NAME,
        [REQUIRED]: true,
        [MAX_LENGTH]: 128,
        [UNIQUE]: true,
    },
    {
        field: DESCRIPTION,
        [REQUIRED]: true,
        [MAX_LENGTH]: 2000,
    },
];

export class ModalStackManagementComponent extends React.Component<IProps> {
    public componentWillUnmount(): void {
        this.props.clearStackPropertyOptions();
    }

    public getUniqueNamesOfStackProperty = (): string[] => {
        const { stackPropertyList, currentStackProperty } = this.props;

        return stackPropertyList.map((item: IStackOptions) => {
            return currentStackProperty.id === item.id ? '' : item[NAME];
        });
    }

    public render(): JSX.Element {
        const {
            stackPropertyListLoading,
            stackPropertyListUpdating,
            stackPropertyList,
            currentStackProperty,
            handleDetectChanges,
            modifiedLabels,
            redactionLang,
        } = this.props;
        const langRule = changeLang(redactionLang);
        const labels = {
            cancelLabel: initialLabel,
            closeLabel: initialLabel,
            saveLabel: initialLabel,
            addNewLabel: initialLabel,
            nameLabel: initialLabel,
            descriptionLabel: initialLabel,
            mustBeUniqueLabel: initialLabel,
            atLeastOneOfListLabel: initialLabel,
            mandatoryFieldLabel: initialLabel,
            maxLengthLabel: initialLabel,
            minLengthLabel: initialLabel,
            selectPropertyLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        const activeId = currentStackProperty[ID];
        const uniqueFields = { name: this.getUniqueNamesOfStackProperty() };

        return (
            <Form
                onSubmit={this.handleSave}
                initialValues={{...currentStackProperty}}
                validate={(values: any): { [param: string]: boolean } => validate(values, validateFields, uniqueFields,
                    labels, langRule)}
                render={({
                                               handleSubmit,
                                               pristine,
                                               submitting,
                                               invalid,
                                               dirty,
                                               form,
                                           }: FormRenderProps): JSX.Element => (
                    <form
                        className={classNames.stackModal}
                        onSubmit={handleSubmit}
                    >
                        <div className={classNames.stackModalBody}>
                            <div className={classNames.stackListWrap}>
                                <Fab
                                    variant='extended'
                                    size='medium'
                                    color='primary'
                                    aria-label='Add'
                                    onClick={(): void => this.handleAddNew(form)}
                                    className='stack-add'
                                >
                                    <AddIcon fontSize={'small'} />
                                    {labels.addNewLabel[langRule]}
                                </Fab>
                                {
                                    stackPropertyListLoading ?
                                        <Spinner active={true} /> :
                                        <StackList
                                            list={stackPropertyList}
                                            activeId={activeId}
                                            handleClickStack={(item: IStackPropertyOptions): void =>
                                                this.handleClickStack(item, form)}
                                            handleDeleteStack={this.handleDeleteStack}
                                        />
                                }
                            </div>
                            <div className={classNames.stackTabsWrap}>
                                {
                                    currentStackProperty[ID] !== null ?
                                        <div>
                                            <Field
                                                render={renderTextField}
                                                name={NAME}
                                                label={labels.nameLabel[langRule]}
                                                margin='normal'
                                                required={true}
                                                variant='outlined'
                                                fullWidth={true}
                                            />
                                            <Field
                                                render={renderTextField}
                                                name={DESCRIPTION}
                                                multiline={true}
                                                rows='2'
                                                required={true}
                                                label={labels.descriptionLabel[langRule]}
                                                margin='normal'
                                                variant='outlined'
                                                fullWidth={true}
                                            />
                                        </div>
                                        :
                                        <div className='stack-empty'>{labels.selectPropertyLabel[langRule]}</div>
                                }
                            </div>
                        </div>
                        <div className={classNames.stackModalFooter}>
                            <Tooltip title={labels.closeLabel[langRule]} placement='top'>
                                <Button
                                    variant='outlined'
                                    onClick={(): void => this.handleCloseModal(form)}
                                    className='modal-window__buttons outlined'
                                    size='small'
                                >
                                    {labels.cancelLabel[langRule]}
                                </Button>
                            </Tooltip>
                            <Tooltip title={labels.saveLabel[langRule]} placement='top'>
                                <span>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        disabled={submitting || invalid || pristine}
                                        onClick={(): Promise<object | undefined> => handleSubmit()}
                                        className='primary'
                                    >
                                        {labels.saveLabel[langRule]}
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div className={classNames.stackLoader}>
                            {stackPropertyListUpdating && <LinearProgress />}
                        </div>
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={(): void => handleDetectChanges(this.isStackOptionsChanged(form))}
                        />
                    </form>
                )}
            />
        );
    }

    private handleAddNew = (form: FormApi): void => {
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (this.isStackOptionsChanged(form)) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => {
                    this.props.setStackPropertyOptions(emptyStackProperty);
                    form.reset();
                },
            });
        } else {
            this.props.setStackPropertyOptions(emptyStackProperty);
        }
    }

    private handleSave = (values: AnyObject): void => {
        const { currentStackProperty } = this.props;
        const currentId = currentStackProperty[ID];
        const stackOptions = {
            [ID]: currentId,
            [NAME]: values[NAME],
            [DESCRIPTION]: values[DESCRIPTION],
        };

        if (currentId === EMPTY_STACK_ID) {
            const options = omit(stackOptions, ID);

            this.props.postStackProperty(options);

            return;
        }

        this.props.putStackProperty(stackOptions);
    }

    private isStackOptionsChanged(form: FormApi): boolean {
        const { currentStackProperty, stackPropertyList } = this.props;
        const currentId = currentStackProperty[ID];

        if (currentId === null) { // not selected stack
            return false;
        }

        const formValues = form.getState().values;

        const stack = {
            ...formValues,
            [NAME]: get(formValues, NAME, ''),
            [DESCRIPTION]: get(formValues, DESCRIPTION, ''),
        };

        if (currentId === EMPTY_STACK_ID) {
            return !isEqual(emptyStackProperty, stack);
        }

        const initialStackOptions = stackPropertyList
            .find((i: IStackOptions) => currentId === i.id);
        const initialInfo = {
            ...initialStackOptions,
            [NAME]: get(initialStackOptions, NAME, ''),
            [DESCRIPTION]: get(initialStackOptions, DESCRIPTION, ''),
        };

        return !isEqual(initialInfo, stack);
    }

    private handleClickStack = (item: IStackPropertyOptions, form: FormApi): void => {
        const prevItemId = this.props.currentStackProperty[ID];
        const currItemId = item.id;
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (prevItemId === currItemId) {
            return;
        }

        if (this.isStackOptionsChanged(form)) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => this.props.setStackPropertyOptions(item),
            });
        } else {
            this.props.setStackPropertyOptions(item);
        }
    }

    private handleDeleteStack = (id: number): void => {
        const { currentStackProperty, modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            pressOkLabel: initialLabel,
            deleteStackPropertyLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: `Delete-${id}`,
            title: labels.warningLabel[langRule],
            message: <div>{`${labels.deleteStackPropertyLabel[langRule]}? ${labels.pressOkLabel[langRule]}`}</div>,
            confirm: (): () => void => (): void => this.props.deleteStackProperty(
                id,
                currentStackProperty[ID] === id,
            ),
        });
    }

    private handleCloseModal = (form: FormApi): void => {
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (this.isStackOptionsChanged(form)) {
            const values = { ...form.getState().values };
            const stackOptions = {
                [ID]: values[ID],
                [NAME]: values[NAME],
                [DESCRIPTION]: values[DESCRIPTION],
            };

            this.props.setStackPropertyOptions(stackOptions);

            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => this.props.handleCloseModalWindow(STACK_PROPERTIES_MODAL),
            });
        } else {
            this.props.handleCloseModalWindow(STACK_PROPERTIES_MODAL);
        }
    }
}

const mapStateToProps = (state: StoreState): IStateProps => ({
    currentStackProperty: getStackPropertyOptions(state),
    stackPropertyListLoading: getStackPropertyListLoading(state),
    stackPropertyListUpdating: getStackPropertyListUpdating(state),
    stackPropertyList: getStackPropertyList(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, IDispatchProps, AnyAction>): IDispatchProps => ({
    setStackPropertyOptions: (data: IStackPropertyOptions): void => {
        dispatch(setStackPropertyOptions(data));
    },
    changeStackPropertyOptions: (data: IStackPropertyOptions): void => {
        dispatch(changeStackPropertyOptions(data));
    },
    clearStackPropertyOptions: (): void => {
        dispatch(clearStackPropertyOptions());
    },
    postStackProperty: (data: IStackPropertyOptions): void => {
        dispatch(postStackProperty(data));
    },
    putStackProperty: (data: IStackPropertyOptions): void => {
        dispatch(putStackProperty(data));
    },
    deleteStackProperty: (id: number, needsClearState: boolean): void => {
        dispatch(deleteStackProperty(id, needsClearState));
    },
    handleCloseModalWindow: (id: string): void => {
        dispatch(handleCloseModalWindow(id));
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
});

export const ModalStackProperties = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalStackManagementComponent);

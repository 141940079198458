import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import { DELETE } from '../../../../constants/common.constants';
import { Scrollbars } from 'rc-scrollbars';
import { IWatermark } from '../../../../redux/reducers/modalWatermarks/watermarksManagement.model';

interface IWatermarkList {
    list: IWatermark[];
    activeId: number;
    deletePermission: boolean;
    handleClickWatermark: (item: IWatermark) => void;
    handleDeleteWatermark: (item: IWatermark) => void;
}

const WatermarkList = ({
   list,
   activeId,
   handleDeleteWatermark,
   handleClickWatermark,
   deletePermission,
}: IWatermarkList): JSX.Element => {
    return (
        <Scrollbars>
            <List
                component='nav'
            >
                {
                    list
                        .map((item: IWatermark) =>
                            (<ListItem
                                key={item.id}
                                divider={true}
                                button={true}
                                selected={activeId === item.id}
                                onClick={(): void => handleClickWatermark(item)}
                            >
                                <ListItemText
                                    title={item.name}
                                    className='text-watermark'
                                    primary={item.name}
                                />
                                {
                                    deletePermission && (
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                aria-label={DELETE}
                                                onClick={(): void => handleDeleteWatermark(item)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    )
                                }
                            </ListItem>))
                }
            </List>
        </Scrollbars>
    );
};

export default WatermarkList;

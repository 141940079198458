import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    IFoiFolder,
    IModalSearchDocuments, ISearchDocuments,
} from '../reducers/modalSearchDocuments/searchDocuments.model';
import { IBasicDocuments } from '../reducers/modalWindowExport/modalWindowExport.model';
import {
    IAmandaAttachmentResponse, IEdmsFile, IValidEdms,
} from '../../containers/modalWindowContents/modalWindowImport/modalWindowImport.model';
import { ISelectOptions } from '../../components/materialUiForms/marerialUiForms.model';
import { getRedactionLanguage } from './localStorage';

export const getModalSearchDocuments = (state: IState): IModalSearchDocuments => state.modalSearchDocuments;

export const getSearchDocuments = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): ISearchDocuments[] => modalSearchDocuments.searchDocuments,
);

export const getSearchDocumentsLoading = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): boolean => modalSearchDocuments.searchDocumentsPending,
);

export const getFoiFolders = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): IFoiFolder[] => modalSearchDocuments.foiFolders,
);

export const getFoiFoldersOptions = createSelector(
    [getFoiFolders, getRedactionLanguage],
    (foiFolders: IFoiFolder[], language: string): ISelectOptions[] => {
        return foiFolders.map((item: IFoiFolder): ISelectOptions =>({
                label: (language ==='fr' && item.folderDesc2) ?item.folderDesc2:item.folderDesc,
                value: item.folderType,
            }),
        );
    });

export const getFoiFolderPending = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): boolean => modalSearchDocuments.foiFolderPending,
);

export const getSearchRedactionDocuments = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): IBasicDocuments[] => modalSearchDocuments.selectedDocuments,
);

export const getSelectedDocumentsRedactionId = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): number => modalSearchDocuments.selectedDocumentsRedactionId,
);

export const getSearchRedactionDocumentsPending = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): boolean => modalSearchDocuments.selectedDocumentsPending,
);

export const getAmandaAttachments = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments):
    IAmandaAttachmentResponse[] => modalSearchDocuments.amandaAttachments,
);

export const  getAmandaEDMSAdaptors = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): IValidEdms[] => modalSearchDocuments.edmsAdaptors,
);

export const  getEDMSFiles = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): IEdmsFile[] => modalSearchDocuments.edmsFiles,
);

export const getAmandaAttachmentsPending = createSelector(
    [getModalSearchDocuments],
    (modalSearchDocuments: IModalSearchDocuments): boolean => modalSearchDocuments.amandaAttachmentsPending,
);

import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import classnames from 'classnames';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { IProps, IState } from './submenus.model';
import { IHeaderMenu, IMenu } from 'constants/headerMenu.constants';
import { PopperPlacementType } from '@mui/material/Popper';
import { TransitionProps as MaterialTransitionProps } from '@mui/material/transitions/transition';
import { Ref } from 'react';
import { EDIT_FILE_NAME_TITLE } from '../../../constants/messages.constants';
import { GLOBAL_SETTINGS_TITLE } from '../../../constants/globalSettings.constants';
import { AUDIT_LOG_TITLE } from '../../../constants/auditLog.contstants';
import { DISCLOSURE_TITLE_MODAL } from '../../../constants/disclosure.contstants';
import { WATERMARK_TITLE_MODAL } from '../../../constants/watermark.contstants';
import { changeLang } from '../../../redux/actions/localization';

class Submenus extends React.Component<IProps, IState> {
    public state: IState = {
        open: {},
        menuOpenState: {},
    };

    constructor(props: IProps) {
        super(props);
    }

    public componentDidUpdate(): void {
        const shouldHidePageMenu = this.props.activeMenu === 'page' && !this.props.documentData.pdfSrc;
        const { activeMenu } = this.props;

        if (!!activeMenu && !shouldHidePageMenu) {
            this.setState({
                open: {
                    [activeMenu]: true,
                },
            });
            this.props.clearHeaderActiveItem();
        }
    }

    public render(): JSX.Element {
        const { submenu, placementOrient, orientation, buttonClass, folderRsn } = this.props;
        const { menuOpenState, open } = this.state;
        const headerClass = classnames('header-menuList', `${orientation}`);

        return (
            <div className={headerClass}>
                {submenu.map((menu: IMenu) => {
                    const HeaderElement = typeof (menu.Header) !== 'string' ? menu.Header.element : null;

                    return (<div key={`${menu.id}${menu.Header}`}>
                        {
                            menu.link ?
                            (
                                <Button
                                    className={buttonClass}
                                    disabled={!menu.enable}
                                    fullWidth={true}
                                    role='link'
                                    href={menu.link.slice(-3) === 'pdf' ? menu.link : `/${folderRsn}${menu.link}`}
                                    
                                    
                                >
                                    {this.getLabelsByKey(menu.Header) as React.ReactNode}
                                </Button>
                            ) : (
                                <Button
                                    className={buttonClass}
                                    ref={(node: HTMLElement): void => {
                                        menuOpenState[menu.id] = node;
                                    }}
                                    aria-label={typeof (menu.Header) === 'string' ? menu.Header : menu.id}
                                    role='button'
                                    aria-owns={open[menu.id] ? `menu-list-grow${menu.id}` : null}
                                    aria-haspopup='true'
                                    onClick={(): void => {
                                        this.handleToggle(menu);
                                    }}
                                    size='small'
                                    disabled={!menu.enable}
                                >
                                    {HeaderElement ? <HeaderElement /> : this.getLabelsByKey(menu.Header) as React.ReactNode}
                                </Button>
                            )
                        }
                        <Popper
                            open={open[menu.id] || false}
                            anchorEl={menuOpenState[menu.id]}
                            transition={true}
                            placement={placementOrient}
                            disablePortal={true}
                        >
                            {({ TransitionProps, placement }: {
                                placement: PopperPlacementType,
                                TransitionProps?: MaterialTransitionProps,
                            }): JSX.Element => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener
                                            onClickAway={(): void => {
                                                this.handleClose(menu.id);
                                            }}
                                        >
                                            <MenuList autoFocus={true}>
                                                {menu.submenus && (
                                                    <Submenus
                                                        documentData={null}
                                                        submenu={menu.submenus}
                                                        buttonClass={'header-sub-button'}
                                                        orientation='horizontal'
                                                        placementOrient={'right-start'}
                                                        folderRsn={folderRsn}
                                                        handleMenuActions={(itemMenu: IMenu): void => {
                                                            this.props.handleMenuActions(itemMenu);
                                                            this.handleClose(menu.id);
                                                        }

                                                        }
                                                        labels={this.props.labels}
                                                        redactionLang={this.props.redactionLang}
                                                    />
                                                )}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>);
                })}
            </div>
        );
    }

    private handleToggle(menu: IMenu): void {
        if (menu.submenus) {
            this.setState((state: IState) => ({
                ...state,
                open: {
                    ...state.open,
                    [menu.id]: !state[menu.id],
                },
            }));
        } else {
            this.props.handleMenuActions(menu);
        }
    }

    private getLabelsByKey = (key: string | IHeaderMenu): string | IHeaderMenu => {
        const { labels, redactionLang } = this.props;
        const langRule = changeLang(redactionLang);

        if(labels) {
            switch(key) {
                case 'File':
                    return labels.fileHeaderLabel[langRule];
                case 'Request Search':
                    return labels.requestSearchHeaderLabel[langRule];
                case 'User Preferences':
                    return labels.userPreferenceHeaderLabel[langRule];
                case 'Action':
                    return labels.actionHeaderLabel[langRule];
                case 'Import Document':
                    return labels.importDocHeaderLabel[langRule];
                case 'Export Document':
                    return labels.exportDocHeaderLabel[langRule];
                case 'Manage Duplicates':
                    return labels.manageDuplicateHeaderLabel[langRule];
                case 'Reports':
                    return labels.reportsHeaderLabel[langRule];
                case 'Index Notes':
                    return labels.indexNotesHeaderLabel[langRule];
                case 'Officer Report':
                    return labels.officerReportHeaderLabel[langRule];
                case 'Requester Report':
                    return labels.requesterReportHeaderLabel[langRule];
                case 'Page Review Summary':
                    return labels.pageReviewHeaderLabel[langRule];
                case 'Document':
                    return labels.documentHeaderLabel[langRule];
                case 'Delete':
                    return labels.deleteHeaderLabel[langRule];
                case EDIT_FILE_NAME_TITLE:
                    return labels.editFilenameHeaderLabel[langRule];
                case 'Stack':
                    return labels.stackHeaderLabel[langRule];
                case 'Pagination':
                    return labels.paginationHeaderLabel[langRule];
                case 'Add/Remove from Stack':
                    return labels.addRemoveStackHeaderLabel[langRule];
                case 'Stack Management':
                    return labels.stackManageHeaderLabel[langRule];
                case 'Set Pagination':
                    return labels.setPaginationHeaderLabel[langRule];
                case 'Edit Pagination':
                    return labels.editPaginationHeaderLabel[langRule];
                case 'Undo Pagination':
                    return labels.undoPaginationHeaderLabel[langRule];
                case 'Select All':
                    return labels.selectAllHeaderLabel[langRule];
                case 'Set Release Date':
                    return labels.setReleaseHeaderLabel[langRule];
                case 'Clear Release Date':
                    return labels.clearReleaseHeaderLabel[langRule];
                case 'Apply OCR':
                    return labels.applyOcrHeaderLabel[langRule];
                case 'Apply Auto Deskew':
                    return labels.autoDeskewHeaderLabel[langRule];
                case 'Auto Align Article Stamps':
                    return labels.autoAlignHeaderLabel[langRule];
                case 'Page':
                    return labels.pageHeaderLabel[langRule];
                case 'Rotate':
                    return labels.rotateHeaderLabel[langRule];
                case 'Left':
                    return labels.rotateLeftHeaderLabel[langRule];
                case 'Right':
                    return labels.rotateRightHeaderLabel[langRule];
                case 'Move':
                    return labels.moveHeaderLabel[langRule];
                case 'Up':
                    return labels.moveUpHeaderLabel[langRule];
                case 'Down':
                    return labels.moveDownHeaderLabel[langRule];
                case 'Reverse Order':
                    return labels.revereseHeaderLabel[langRule];
                case 'Insert':
                    return labels.insertHeaderLabel[langRule];
                case 'Go to':
                    return labels.gotoHeaderLabel[langRule];
                case 'First':
                    return labels.gotoFirstHeaderLabel[langRule];
                case 'Last':
                    return labels.gotoLastHeaderLabel[langRule];
                case 'Previous':
                    return labels.gotoPreviousHeaderLabel[langRule];
                case 'Next':
                    return labels.gotoNextHeaderLabel[langRule];
                case 'Admin':
                    return labels.adminHeaderLabel[langRule];
                case 'Package Presets':
                    return labels.packagePresetsHeaderLabel[langRule];
                case GLOBAL_SETTINGS_TITLE:
                    return labels.globalSettingHeaderLabel[langRule];
                case AUDIT_LOG_TITLE:
                    return labels.auditLogHeaderLabel[langRule];
                case 'Redaction Localization':
                    return labels.localizationHeaderLabel[langRule];
                case DISCLOSURE_TITLE_MODAL:
                    return labels.disclosureHeaderLabel[langRule];
                case 'Annotation Type Management':
                    return labels.annotationHeaderLabel[langRule];
                case 'User Defined Stamp Management':
                    return labels.userDefStampHeaderLabel[langRule];
                case WATERMARK_TITLE_MODAL:
                    return labels.watermarkHeaderLabel[langRule];
                case 'Associate Watermark to Request Type':
                    return labels.watermarktoRequestHeaderLabel[langRule];
                case 'Request Security':
                    return labels.reqSecurityHeaderLabel[langRule];
                case 'Encryption':
                    return labels.encryptionHeaderLabel[langRule];
                case 'Index Keyword Management':
                    return labels.indexKeywordHeaderLabel[langRule];
                case 'Help':
                    return labels.helpHeaderLabel[langRule];
                case 'About Redaction':
                    return labels.aboutHeaderLabel[langRule];
                case 'Keyboard Shortcuts':
                    return labels.keyboardShortHeaderLabel[langRule];
                case 'Redaction User Guide':
                    return labels.redactionUserGuideHeaderLabel[langRule];
                default: return key;
            }
        }

        return key;

    }

    private handleClose = (id: string): void => {
        if (this.props.clearHeaderActiveItem) {
            this.props.clearHeaderActiveItem();
        }

        this.setState((state: IState) => ({
            ...state,
            open: {
                ...state.open,
                [id]: false,
            },
        }));
    }
}
export default Submenus;

import { END_POINT, START_POINT } from './annotationTypes.constants';

export const BOTTOM_RIGHT = 'bottom-right';
export const MIDDLE_RIGHT = 'middle-right';
export const BOTTOM_CENTER = 'bottom-center';
export const TOP_LEFT = 'top-left';
export const MIDDLE_LEFT = 'middle-left';
export const TOP_CENTER = 'top-center';
export const TOP_RIGHT = 'top-right';
export const BOTTOM_LEFT = 'bottom-left';

export const SEARCH_HIGHLIGHT_COLOR = '#f1c40f';
export const SEARCH_HIGHLIGHT_OPACITY = 0.5;

export const DEFAULT_DRAW_RECTANGLE = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    fill: '',
    opacity: 0,
};

export const DEFAULT_COORDINATES =  {
    [START_POINT]: {
        x: 0,
        y: 0,
    },
    [END_POINT]: {
        x: 0,
        y: 0,
    },
};

export const MIN_SHAPE_SIZE = 1;
export const DEFAULT_FONT = 12;
export const STAMP_LINE_HEIGHT = 1.6;
export const STAMP_FONT_FAMILY = 'Helvetica';
export const PADDING_OFFSET = 10;

export const DEFAULT_OPACITY = 0.5;
export const DEFAULT_STROKE_WIDTH = 1;

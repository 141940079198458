import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../redux/store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import ModalEditPaginationForm from './ModalEditPaginationForm';
import {
    IProps, IEditPaginationState, IStateProps, IDispatchProps, IEditPaginationProps,
} from './ModalEditPagination.model';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { getPagesCount, getSelectedDocuments } from '../../../redux/selectors/pageList';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import { editPaginationIndex, fetchAvailablePagination } from '../../../redux/actions/modalEditPagination';
import {
    getEditPaginationRange,
    getEditPaginationRangeLoading,
    getUpdatingPagination,
} from '../../../redux/selectors/modalEditPagination';
import { Spinner } from '../../../components/spinner/Spinner';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { initialLabel } from '../../../constants/localization.constants';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../localization/localizationData';

export const getEditPaginationLabelsByKey = (key: string): string => {
    switch(key) {
        case 'DOCUMENT_HEADER_PAGINATION_EDIT':
            return 'editPaginationTitleLabel';
        case 'COMMON_LABEL_NO_DOCUMENT_WARNING':
            return 'noDocumentWarningLabel';
        case 'MODAL_PAGINATION_MULTIPLE_PAGE_WARNING':
            return 'multiplePageWarningLabel';
        case 'COMMON_LABEL_YES':
            return 'yesLabel';
        case 'COMMON_LABEL_NO':
            return 'noLabel';
        case 'MODAL_PAGINATION_CONFIRMATION':
            return 'paginationConfirmationTitleLabel';
        case 'MODAL_PAGINATION_CONFIRMATION_MESSAGE':
            return 'paginationConfirmationMessageLabel';
        case 'DOCUMENT_HEADER_PAGINATION_UNDO':
            return 'undoPaginationTitleLabel';
        case 'DOCUMENT_LIST_DELETE_DOCUMENT':
            return 'deleteDocumentTitleLabel';
        case 'COMMON_LABEL_PRESS_OK_TO_CONTINUE':
            return 'pressOkContinueLabel';
        case 'DOCUMENT_HEADER_DELETE_MESSAGE':
            return 'deleteMessageLabel';
        case 'COMMON_LABEL_WARNING':
            return 'warningLabel';
        case 'MODAL_AUTO_ALIGN_ARTICLE_ALERT_MESSAGE':
            return 'autoAlignAlertMessageLabel';
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'COMMON_LABEL_OK':
            return 'okLabel';
        case 'MODAL_PAGINATION_AVAILABLE_PAGINATION':
            return 'availablePaginationNumberLabel';
        case 'MODAL_PAGINATION_START_FIELD':
            return 'paginationStartLabel';
        case 'MODAL_PAGINATION_END_FIELD':
            return 'paginationEndLabel';
        case 'DOCUMENT_HEADER_AUTO_ALIGN_ARTICLE_STAMPS':
            return 'autoAlignArticleStampsTitleLabel';
        case 'DOCUMENT_HEADER_PAGINATION':
            return 'paginationTitleLabel';
        default: return '';
    }
};

class ModalEditPagination extends React.Component<IEditPaginationProps, IEditPaginationState> {

    public componentDidMount(): void {

        const {
            fetchAvailablePagination: fetchPagination,
            redactionDocumentId,
            selectedDocuments,
        } = this.props;

        fetchPagination(redactionDocumentId, selectedDocuments[0]);
    }

    public render(): JSX.Element {
        const {
            handleCloseModalWindow,
            paginationAvailableRange,
            updatingPagination,
            selectedPagesLength,
            paginationAvailableRangeLoading,
            modifiedLabels,
            redactionLanguage,
        } = this.props;
        const labels = {
            okLabel: initialLabel,
            cancelLabel: initialLabel,
            availablePaginationNumberLabel: initialLabel,
            paginationStartLabel: initialLabel,
        };
        const langRule = changeLang(redactionLanguage);

        resourceBundle.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        return paginationAvailableRangeLoading || !paginationAvailableRange.length ? <Spinner active={true}/> : (
                <ModalEditPaginationForm
                    onSubmit={this.onSubmit}
                    selectedPagesLength={selectedPagesLength}
                    paginationAvailable={paginationAvailableRange}
                    updatingPagination={updatingPagination}
                    paginationAvailableRangeLoading={paginationAvailableRangeLoading}
                    handleCloseModalWindow={handleCloseModalWindow}
                    labels={labels}
                    langRule={langRule}
                />
            );
    }

    private onSubmit = (startRange: number): void => {
        const { redactionDocumentId, selectedDocuments } = this.props;

        this.props.editPaginationIndex(selectedDocuments[0], startRange, redactionDocumentId);
    }
}

const mapStateToProps = (state: IState): IStateProps => ({
    selectedDocuments: getSelectedDocuments(state),
    selectedPagesLength: getPagesCount(state),
    paginationAvailableRangeLoading: getEditPaginationRangeLoading(state),
    updatingPagination: getUpdatingPagination(state),
    paginationAvailableRange: getEditPaginationRange(state),
    redactionDocumentId: getRedactionDocumentId(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLanguage: getRedactionLanguage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IState, IDispatchProps, AnyAction>): IDispatchProps => ({
    fetchAvailablePagination: (redactionDocumentId: number, documentId: number): void => {
        dispatch(fetchAvailablePagination(redactionDocumentId, documentId));
    },
    editPaginationIndex: (
        documentId: number,
        paginationIndex: number,
        redactionDocumentId: number,
    ): void => {
        dispatch(editPaginationIndex(documentId, paginationIndex, redactionDocumentId));
    },
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
});

export default connect( mapStateToProps, mapDispatchToProps)(ModalEditPagination);

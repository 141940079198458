import * as React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { CheckboxMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import { undoPageDisclosure, undoDocumentDisclosure, hasLinkedArticles } from '../../../redux/actions/pageList';
import { getContextMenu } from '../../../redux/selectors/contextMenu';
import { IAnnotation, IUndoDisclosure, IUndoDocDisclosure } from '../../../redux/reducers/pageList/pageList.model';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import {
    IUndoDisclosureState,
    IUndoDisclosureItems,
    IUndoDisclosureProps,
    IUndoDisclosureOwnProps,
    IUndoDisclosureDispatch,
} from './modalUndoDisclosure.model';
import './modalUndoDisclosure.style.scss';
import { IState as StoreState } from '../../../redux/store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import LinearProgress from '@mui/material/LinearProgress';
import { getHasLinkedArticles, getPageListUpdateDisclosurePending } from '../../../redux/selectors/pageList';
import { getAnnotationLayouts } from '../../../redux/selectors/annotation';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { CONFIRMATION_DIALOG_MODAL, DELETE_LINKED_ARTICLE_OR_EXEMPTION_MESSAGE, NO_BUTTON, WARNING, YES_BUTTON } from '../../../constants/messages.constants';
import { setRecentUndoSnapAnnotation } from '../../../redux/actions/annotations';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { changeLang, } from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';
import { initialLabel } from '../../../constants/localization.constants';

const getUserDefinedStampsLabelsByKey = (key: string): string => {
    switch (key) {
        case 'UNDO_DISCLOSURE_MODAL_REMOVE_HIGHLIGHTS':
            return 'removeHighlightsLabel';
        case 'UNDO_DISCLOSURE_MODAL_REMOVE_SERVERS':
            return 'removeServersLabel';
        case 'UNDO_DISCLOSURE_MODAL_REMOVE_EXEMPTIONS':
            return 'removeExemptionsLabel';
        case 'UNDO_DISCLOSURE_MODAL_REMOVE_USER_DEFINED_STAMPS':
            return 'removeUserDefinedStampsLabel';
        case 'UNDO_DISCLOSURE_MODAL_REMOVE_CONTACTS':
            return 'removeContactsLabel';           
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'COMMON_LABEL_OK':
            return 'okLabel';
        default: return '';
    }
};
const labels = {
    removeHighlightsLabel: initialLabel,
    removeServersLabel: initialLabel,
    removeExemptionsLabel: initialLabel,
    removeUserDefinedStampsLabel: initialLabel,
    removeContactsLabel: initialLabel,
    cancelLabel: initialLabel,
    okLabel: initialLabel,
};

class ModalUndoDisclosure extends React.Component<IUndoDisclosureProps, IUndoDisclosureState> {
    public state: IUndoDisclosureState = {
        highlights: true,
        severs: true,
        exemptions: true,
        userDefinedStamps: true,
        contacts: true,
    };

    public componentDidMount(): void {
        this.props.hasLinkedArticle();
    }

    public render(): JSX.Element {
        const { pending, redactionLang, modifiedLabels } = this.props;
        const langRule = changeLang(redactionLang);        

        resourceBundle.map((resource: any) => {
            if (getUserDefinedStampsLabelsByKey(resource.resourceKey)) {
                labels[getUserDefinedStampsLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getUserDefinedStampsLabelsByKey(resource.resourceKey)) {
                labels[getUserDefinedStampsLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        const undoDisclosureItems = [
            {
                label: labels.removeHighlightsLabel[langRule],
                name: 'highlights',
            }, {
                label: labels.removeServersLabel[langRule],
                name: 'severs',
            }, {
                label: labels.removeExemptionsLabel[langRule],
                name: 'exemptions',
            }, {
                label: labels.removeUserDefinedStampsLabel[langRule],
                name: 'userDefinedStamps',
            }, {
                label: labels.removeContactsLabel[langRule],
                name: 'contacts',
            },
        ];

        return (
            <div className='undo-disclosure-module'>
                {
                    undoDisclosureItems.map((item: IUndoDisclosureItems): JSX.Element => (
                        <div className='undo-disclosure-module_item' key={item.name}>
                            <label>
                                <span>{item.label}</span>
                                <CheckboxMaterialBuilder
                                    checked={this.state[item.name]}
                                    handleCheckboxChange={(param: string, checked: boolean): void =>
                                        this.handleCheckboxChange(param, checked)}
                                    paramName={item.name}
                                />
                            </label>
                        </div>
                    ))
                }
                <div className='form-footer__action'>
                    <Button
                        size='small'
                        variant='outlined'
                        className='modal-window__buttons outlined'
                        onClick={this.props.handleCloseModalWindow}
                    >
                        {labels.cancelLabel[langRule]}
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        className='modal-window__buttons primary'
                        disabled={pending}
                        onClick={(): void => this.onSave()}
                    >
                        {labels.okLabel[langRule]}
                    </Button>
                </div>
                <div className='linear-progress'>
                    {pending && <LinearProgress />}
                </div>
            </div>
        );
    }

    private handleCheckboxChange(param: string, checked: boolean): void {
        this.setState({ [param]: checked });
    }

    private onSave(): void {
        const {
            redactionDocumentId,
            contextMenuData: { contextMenuProps: { pageIds, currentDocId } },
            contextMenuData: { contextMenuProps: { documentIds } },
            hasLinkedArticleStamps,
            closeModalWindow,
            openIsLinkedStampDeleteConfirmationModal,
        } = this.props;
        const { highlights, severs, exemptions, userDefinedStamps, contacts } = this.state;

        if (pageIds) {
            const undoData = {
                pageIds,
                disclosureReset: {
                    highlights,
                    severs,
                    exemptions,
                    userDefinedStamps,
                    contacts,
                },
            };

            if(undoData.disclosureReset.severs) {
                this.props.setRecentSnappedAnnotationId(null);
            }

            if (undoData.disclosureReset.severs
                && !undoData.disclosureReset.exemptions
                && hasLinkedArticleStamps) {
                openIsLinkedStampDeleteConfirmationModal(CONFIRMATION_DIALOG_MODAL, {
                    id: CONFIRMATION_DIALOG_MODAL,
                    title: WARNING,
                    okButtonTitle: YES_BUTTON,
                    cancelButtonTitle: NO_BUTTON,
                    message: DELETE_LINKED_ARTICLE_OR_EXEMPTION_MESSAGE,
                    confirm: (): () => void => (): void => {
                        this.props.undoPageDisclosure(redactionDocumentId, currentDocId, undoData, true);
                        closeModalWindow(CONFIRMATION_DIALOG_MODAL);

                        return;
                    },
                    reject: (): () => void => (): void => {
                        this.props.undoPageDisclosure(redactionDocumentId, currentDocId, undoData, false);
                        closeModalWindow(CONFIRMATION_DIALOG_MODAL);

                        return;
                    },
                });
            } else {
                this.props.undoPageDisclosure(redactionDocumentId, currentDocId, undoData, false);
            }
        } else {
            const undoData = {
                documentIds,
                disclosureReset: {
                    highlights,
                    severs,
                    exemptions,
                    userDefinedStamps,
                    contacts,
                },
            };

            if(undoData.disclosureReset.severs) {
                this.props.setRecentSnappedAnnotationId(null);
            }

            if (undoData.disclosureReset.severs
                && !undoData.disclosureReset.exemptions
                && hasLinkedArticleStamps) {
                openIsLinkedStampDeleteConfirmationModal(CONFIRMATION_DIALOG_MODAL, {
                    id: CONFIRMATION_DIALOG_MODAL,
                    title: WARNING,
                    okButtonTitle: YES_BUTTON,
                    cancelButtonTitle: NO_BUTTON,
                    message: DELETE_LINKED_ARTICLE_OR_EXEMPTION_MESSAGE,
                    confirm: (): () => void => (): void => {
                        this.props.undoDocumentDisclosure(redactionDocumentId, undoData, true);
                        closeModalWindow(CONFIRMATION_DIALOG_MODAL);

                        return;
                    },
                    reject: (): () => void => (): void => {
                        this.props.undoDocumentDisclosure(redactionDocumentId, undoData, false);
                        closeModalWindow(CONFIRMATION_DIALOG_MODAL);

                        return;
                    },
                });
            } else {
                this.props.undoDocumentDisclosure(redactionDocumentId, undoData, false);
            }

        }

    }
}

const mapStateToProps = (state: StoreState): IUndoDisclosureOwnProps => ({
    contextMenuData: getContextMenu(state),
    redactionDocumentId: getRedactionDocumentId(state),
    pending: getPageListUpdateDisclosurePending(state),
    annotations: getAnnotationLayouts(state),
    hasLinkedArticleStamps: getHasLinkedArticles(state),
    redactionLang: getRedactionLanguage(state),
    modifiedLabels: getModifiedLabels(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IUndoDisclosureDispatch, AnyAction>,
): IUndoDisclosureDispatch => ({
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    undoPageDisclosure:
        (redactionDocumentId: number, documentId: number, data: IUndoDisclosure, isDeleteStamp?: boolean): void => {
        dispatch(undoPageDisclosure(redactionDocumentId, documentId, data, isDeleteStamp));
    },
    undoDocumentDisclosure: (redactionDocumentId: number, data: IUndoDocDisclosure, isDeleteStamp?: boolean): void => {
        dispatch(undoDocumentDisclosure(redactionDocumentId, data, isDeleteStamp));
    },
    openIsLinkedStampDeleteConfirmationModal: (type: string, modalProps: IModalProps): void => {
        dispatch(openModalWindow(type, modalProps));
    },
    closeModalWindow: (type: string): void => {
        dispatch(handleCloseModalWindow(type));
    },
    hasLinkedArticle: (): void => {
        dispatch(hasLinkedArticles());
    },
    setRecentSnappedAnnotationId: (id: number): void => {
        dispatch(setRecentUndoSnapAnnotation(id));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUndoDisclosure);

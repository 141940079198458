import * as React from 'react';
import Button from '@mui/material/Button';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from '../../../../node_modules/react-redux';
import { Grid, MenuItem, TextField } from '@mui/material';
import {
    renderTextField,
    renderCheckbox,
} from '../../../components/materialUiForms/materialUiForms';
import { validate } from '../../../utils/validate.util';
import {
    FOLDER_TYPE,
    FOLDER_TYPE_LABEL,
    REQUEST_NUMBER,
    REQUEST_NUMBER_LABEL,
    REQUEST_YEAR,
    REQUEST_YEAR_LABEL,
} from '../../../constants/searchDocuments.contstants';
import { EXACT_MATCH, EXACT_MATCH_PARAM } from '../../../constants/common.constants';
import { ISearchDocumentFormProps } from './modalSearchDocument.model';
import { IMaterialUIField, ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import { IFoiFolder } from '../../../redux/reducers/modalSearchDocuments/searchDocuments.model';
import { MAX_LENGTH, MIN_LENGTH } from '../../../constants/validate.constants';
import { FormState } from 'final-form';
import { getModalType } from '../../../redux/selectors/modal';
import { langFrench } from '../../../constants/localization.constants';
import { changeLang } from '../../../redux/actions/localization';

const validateFields = [
    {
        field: REQUEST_NUMBER,
        [MIN_LENGTH]: 4,
    },
];

const SearchDocument = ({ loading, onSearch, foiFolders, requestNumber, labels, language }: ISearchDocumentFormProps)
    : JSX.Element => {
    const formatFolderType = foiFolders.map((foiFolder: IFoiFolder): ISelectOptions => ({
        value: `${foiFolder.folderType}`,
        label: language ? language === langFrench ? foiFolder.folderDesc2 ? foiFolder.folderDesc2
            : foiFolder.folderDesc : foiFolder.folderDesc : foiFolder.folderDesc,
    }));

    const renderSelectField = ({ input, label, meta: { touched, error }, options, ...custom }:
        IMaterialUIField): React.ReactNode => (
        <TextField
            select={true}
            label={label}
            {...input}
            {...custom}
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        sx: {
                            left: language === langFrench ? '164px !important' : '194px',
                        },
                    },
                },
            }}
        >
            {
                options.map((option: ISelectOptions): JSX.Element => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))
            }
        </TextField>
    );

    const modalType: boolean = useSelector(getModalType);
    const langRule = changeLang(language);

    return (
        <Form
            onSubmit={onSearch}
            validate={(values: any): { [param: string]: boolean } => validate(values, validateFields)}
            render={<T extends object>({
                handleSubmit,
                invalid,
                form,
            }: FormRenderProps & FormState<T>): JSX.Element => (
                    <form onSubmit={handleSubmit}>
                        <div className='select-document_fields'>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} >
                                    <Field
                                        render={renderSelectField}
                                        name={FOLDER_TYPE}
                                        label={labels.folderTypeLabel[langRule]}
                                        margin='normal'
                                        variant='outlined'
                                        options={formatFolderType}
                                        fullWidth={true}
                                        className='folder-type'
                                    />
                                </Grid>
                                <Grid item={true}>
                                    <Field
                                        render={renderTextField}
                                        name={REQUEST_NUMBER}
                                        label={labels.requestNumberLabel[langRule]}
                                        margin='normal'
                                        variant='outlined'
                                        className='folder-type'
                                        defaultValue={modalType ? requestNumber : undefined}
                                    />
                                </Grid>
                                <Grid item={true}>
                                    <Field
                                        render={renderCheckbox}
                                        name={EXACT_MATCH_PARAM}
                                        label={labels.exactMatchLabel[langRule]}
                                        type='checkbox'
                                        className='folder-type exact-match-checkbox'
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='footer-actions'>
                            <Button
                                variant='outlined'
                                size='small'
                                onClick={form.reset}
                            >
                                {labels.resetlabel[langRule]}
                            </Button>
                            <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                type='submit'
                                disabled={invalid || loading}
                            >
                                {labels.searchLabel[langRule]}
                            </Button>
                        </div>
                    </form>
                )}
        />
    );
};

export default SearchDocument;

import * as React from 'react';
import moment = require('moment');
import { createSelector } from 'reselect';
import { Spinner } from '../../components/spinner/Spinner';
import {
    IFile,
    DocumentListState,
    IDocumentListMetadata,
    IPaginationData,
} from 'redux/reducers/documentList/documentList.model';
import { NOT_APPLICABLE, YES, NO } from '../../constants/common.constants';
import { IState } from '../store';
import { IStackOptions } from '../reducers/stackList/stackList.model';
import { NAME } from '../../constants/stack.contstants';
import { IDefaultIndex } from '../reducers/indexMetadata/indexMetadata.model';
import { getUserPermissionsLoading } from './initialization';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { dateSorting } from '../../utils/documentList.util';

export const getDocumentState = (state: IState): DocumentListState => state.documentList;
export const getDocumentList = createSelector(
    [getDocumentState],
    (documentsState: DocumentListState): IFile[] => documentsState.files,
);
export const getDocumentListLoading = createSelector(
    [getDocumentState, getUserPermissionsLoading],
    (documentsState: DocumentListState, userPermissionsLoading: boolean): boolean =>
        documentsState.loading || userPermissionsLoading,
);

export const getRedactionDocumentPaginationLoading = createSelector(
    [getDocumentState],
    (documentsState: DocumentListState): number[] => documentsState.loadingPagination,
);

export const getFileDisclosureLoading = createSelector(
    [getDocumentState],
    (documentsState: DocumentListState): number[] => documentsState.fileDisclosureLoading,
);

export const getOcrLoadingDocumentsLoading = createSelector(
    [getDocumentState],
    (documentsState: DocumentListState): number[] => documentsState.loadingOcr,
);

export const getStackId = createSelector(
    [getDocumentState],
    (documentsState: DocumentListState): number => documentsState.stackId,
);

//  TODO fix 'any'(if a can:) in next PR - Tamara K)
export const setValue = (isLoading: boolean, value: string | number): any =>
    isLoading ? (<Spinner active={true} size={20} />) : value;

export const getDocumentListMetadata = createSelector(
    [getDocumentList, getRedactionDocumentPaginationLoading, getOcrLoadingDocumentsLoading, getFileDisclosureLoading],
    (documents: IFile[], paginationLoading: number[], ocrLoading: number[], fileDisclosureLoading: number[]) =>
        documents ?
            documents.map((file: IFile): IDocumentListMetadata => {
                let ocr = file.isOcred ? YES : NO;

                if (file.ocrState) {
                    ocr = file.ocrState;
                }

                if (!Array.isArray(paginationLoading)) {
                    paginationLoading = [paginationLoading];
                }

                const getIndexValueDoc = (param: IDefaultIndex): string => !!param && param.value ? param.value : '';
                const preReleaseDate = file.preReleaseDate && moment(file.preReleaseDate).isValid()
                    ? moment(file.preReleaseDate).format('MM/DD/YYYY')
                    : '';

                const releaseDate = file.releaseDate && moment(file.releaseDate).isValid()
                    ? moment(file.releaseDate).format('MM/DD/YYYY')
                    : '';

                return {
                    id: file.id,
                    fileIcon: file.id,
                    name: file.name,
                    pages: file.pageCount,
                    disclosureType: setValue(fileDisclosureLoading.includes(file.id), file.disclosureType),
                    ocr: setValue(ocrLoading.includes(file.id), ocr),
                    start: setValue(paginationLoading.includes(file.id), file.paginationRange.from || NOT_APPLICABLE),
                    end: setValue(paginationLoading.includes(file.id), file.paginationRange.to || NOT_APPLICABLE),
                    stacks: file.stacks ? file.stacks.map((stack: IStackOptions) => stack[NAME]).join(', ') : null,
                    to: getIndexValueDoc(file.defaultIndexMetadata.to),
                    toOrg: getIndexValueDoc(file.defaultIndexMetadata.toOrg),
                    from: getIndexValueDoc(file.defaultIndexMetadata.from),
                    fromOrg: getIndexValueDoc(file.defaultIndexMetadata.fromOrg),
                    subject: getIndexValueDoc(file.defaultIndexMetadata.subject),
                    preReleaseDate,
                    releaseDate,
                    documentDate: file.documentDate || '',
                    fileName: file.originalName,
                };
            }) : [],
);

export const getDocumentListColumnHeaderLoading = createSelector(
    [getDocumentState],
    (documentsState: DocumentListState): boolean => documentsState.documentListColumnHeaderPending,
);

export const getDocumnetListColumnHeader = createSelector(
    [getDocumentState],
    (documentsState: DocumentListState): IHeader[] => {
        const documentListHeader = documentsState.documentListColumnHeader.map((header: IHeader): IHeader => {
            if (header.accessorKey === 'documentDate') {
                return {
                    ...header,
                    sortMethod: dateSorting,
                };
            }else{
                return {
                    ...header,
                    accessorKey: header.accessor,
                    header: header.Header,
                };
            }
        });
        return documentListHeader;
    },
);

export const getResetedDocumentListColumnHeaderLoading = createSelector(
    [getDocumentState],
    (documentsState: DocumentListState): boolean => documentsState.resetDocumentListColumnHeaderPending,
);

export const SET = 'Set';
export const OF_ALL_SELECTED_DOCS_TO = 'of all selected documents to';
export const OF_ALL_SELECTED_DOCS = 'of all selected documents';
export const PRE_RELEASE_DATE_PARAM = 'preReleaseDate';
export const PRE_RELEASE_DATE_LABEL = 'Pre-Release Date';
export const RELEASE_DATE_PARAM = 'releaseDate';
export const RELEASE_DATE_LABEL = 'Release Date';

export const SELECT_RELEASE_DATE_OPTIONS = [
    {
        value: PRE_RELEASE_DATE_PARAM,
        label: PRE_RELEASE_DATE_LABEL,
    },
    {
        value: RELEASE_DATE_PARAM,
        label: RELEASE_DATE_LABEL,
    },
];

import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import {
    GET_STAMP_PENDING,
    GET_STAMP_SUCCESS,
    GET_STAMP_FAILURE,
    GET_ARTICLE_STAMP_PENDING,
    GET_ARTICLE_STAMP_SUCCESS,
    GET_ARTICLE_STAMP_FAILURE,
    POST_STAMP_PENDING,
    POST_STAMP_SUCCESS,
    POST_STAMP_FAILURE,
    PUT_STAMP_PENDING,
    PUT_STAMP_SUCCESS,
    PUT_STAMP_FAILURE,
    DELETE_STAMP_PENDING,
    DELETE_STAMP_SUCCESS,
    DELETE_STAMP_FAILURE,
    CHANGE_LOCAL_STAMPS,
    SET_SELECTED_CUSTOM_STAMPS,
    SET_SELECTED_ARTICLE_STAMPS,
    SET_SINGLE_RECENTLY_USED_STAMP,
    SET_INTERACTIVE_MODE,
} from '../reducers/layoutRedactorTypes/constants';
import { action } from 'typesafe-actions';
import {
    IStampType,
    IArticleStamp,
    IValidExemptionDtoList,
} from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from './modal';
import { SET_SELECTED_ANNOTATIONS_TYPES } from '../reducers/annotationTypes/constants';
import { IError } from '../common.model';
import { addError } from './errorHandling';
import { IState } from '../store';
import {
    ALERT_DIALOG_MODAL,
    CONFIRMATION_DIALOG_MODAL,
    DELETE_STAMP_TYPE_MESSAGE,
    DELETE_STAMP_TYPE_TITLE, WARNING,
} from '../../constants/messages.constants';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { getState } from 'redux/selectors/pageList';
import { changeLang, commonLabels, getCommonLabelsByKey } from './localization';
import resourceBundle from '../../containers/localization/localizationData';

export const fetchStampListPending = (): AnyAction => action(GET_STAMP_PENDING);
export const fetchStampListSuccess = (dataList: IStampType[]): AnyAction => action(GET_STAMP_SUCCESS, dataList);
export const fetchStampListFailure = (error: IError): AnyAction => action(GET_STAMP_FAILURE, error);
export const fetchArticleStampListPending = (): AnyAction => action(GET_ARTICLE_STAMP_PENDING);
export const fetchArticleStampListSuccess = (dataList: IArticleStamp[]): AnyAction =>
    action(GET_ARTICLE_STAMP_SUCCESS, dataList);
export const fetchArticleStampListFailure = (error: IError): AnyAction => action(GET_ARTICLE_STAMP_FAILURE, error);
export const postStampListPending = (): AnyAction => action(POST_STAMP_PENDING);
export const postStampListSuccess = (data: IStampType[]): AnyAction => action(POST_STAMP_SUCCESS, data);
export const postStampListFailure = (error: IError): AnyAction => action(POST_STAMP_FAILURE, error);
export const putStampListPending = (): AnyAction => action(PUT_STAMP_PENDING);
export const putStampListSuccess = (data: IStampType[]): AnyAction => action(PUT_STAMP_SUCCESS, data);
export const putStampListFailure = (error: IError): AnyAction => action(PUT_STAMP_FAILURE, error);
export const deleteStampListPending = (): AnyAction => action(DELETE_STAMP_PENDING);
export const deleteStampListSuccess = (id: number): AnyAction => action(DELETE_STAMP_SUCCESS, id);
export const deleteStampListFailure = (error: IError): AnyAction => action(DELETE_STAMP_FAILURE, error);

export const changeLocalStampList = (dataList: IStampType[]): AnyAction => action(CHANGE_LOCAL_STAMPS, dataList);

export const setRecentlyUsed = (stamp: IValidExemptionDtoList[]): AnyAction =>
    action(SET_SINGLE_RECENTLY_USED_STAMP, stamp);

export const setInteractiveMode = (flag: boolean): (dispatch: Dispatch) => void =>
    (dispatch: Dispatch): void => {
    dispatch(action(SET_INTERACTIVE_MODE, flag));

    if(flag) {
        dispatch(action(SET_SELECTED_ANNOTATIONS_TYPES, null));
        dispatch(action(SET_SELECTED_ARTICLE_STAMPS, []));
        dispatch(action(SET_SELECTED_CUSTOM_STAMPS, []));
        dispatch(action(SET_SINGLE_RECENTLY_USED_STAMP, []));
    }
};

export const setSelectedCustomStamps = (data: IStampType[]): (dispatch: Dispatch) => void =>
    (dispatch: Dispatch): void => {
    dispatch(action(SET_SELECTED_ANNOTATIONS_TYPES, null));
    dispatch(action(SET_SELECTED_ARTICLE_STAMPS, []));
    dispatch(action(SET_SELECTED_CUSTOM_STAMPS, data));
    dispatch(action(SET_SINGLE_RECENTLY_USED_STAMP, []));
    dispatch(action(SET_INTERACTIVE_MODE, false));
    dispatch(handleCloseAllModalWindows());
};

export const setSelectedArticleStamps = (data: IValidExemptionDtoList[]): (dispatch: Dispatch) =>
    void => (dispatch: Dispatch): void => {
    dispatch(action(SET_SELECTED_ANNOTATIONS_TYPES, null));
    dispatch(action(SET_SELECTED_ARTICLE_STAMPS, data));
    dispatch(action(SET_SELECTED_CUSTOM_STAMPS, []));
    dispatch(action(SET_SINGLE_RECENTLY_USED_STAMP, []));
    dispatch(action(SET_INTERACTIVE_MODE, false));
    dispatch(handleCloseAllModalWindows());
};

export const fetchArticleStampList = (): (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {

    const { reductionMetaData: { redactionDocumentId } } = getState();

    dispatch(fetchArticleStampListPending());

    try {
        const response = await api.amandaExemptions.getArticleStampTypes(redactionDocumentId);

        dispatch(fetchArticleStampListSuccess(response));
    } catch (error) {
        dispatch(fetchArticleStampListFailure(error));
    }
};

export const fetchStampList = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

    dispatch(fetchStampListPending());

    try {
        const response = await api.stampTypes.getStampTypes();

        dispatch(fetchStampListSuccess(response));
    } catch (error) {
        dispatch(fetchStampListFailure(error));
        dispatch(addError(error));
    }
};

export const postStamp = (data: IStampType[]): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

    dispatch(postStampListPending());

    try {
        const response = await api.stampTypes.addStampType(data);

        dispatch(postStampListSuccess(response));
    } catch (error) {
        dispatch(postStampListFailure(error));
        dispatch(addError(error));
    }
};

export const putStamp = (data: IStampType[]):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
    const {
        layoutRedactorTypes: {
            selectedCustomStamps,
        },
    } = getState();

    dispatch(putStampListPending());

    try {
        const response = await api.stampTypes.updateStampType(data);
        const filterSelectedStampTypes = selectedCustomStamps.reduce((acc: IStampType[], stampType: IStampType) => {
            const changedStampType = response.find((el: IStampType): boolean => el.id === stampType.id);

            if (changedStampType && changedStampType.isActive) {
                return [...acc, changedStampType];
            } else if (!changedStampType) {
                return [...acc, stampType];
            }

            return acc;
        }, []);

        dispatch(action(SET_SELECTED_CUSTOM_STAMPS, filterSelectedStampTypes ));

        dispatch(putStampListSuccess(response));
    } catch (error) {
        dispatch(putStampListFailure(error));
        dispatch(addError(error));
    }
};

export const confirmDeleteStampType = (id: number): (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const {
            localStorage: {
                language,
            },
            localization:{
                modifiedLabels
            }
        } = getState();
        const labels = commonLabels;
        const langRule = changeLang(language);
        resourceBundle.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: DELETE_STAMP_TYPE_TITLE,
            title: labels.warningLabel[langRule],
            message: labels.changesNotSavedLabel[langRule],
            confirm: (): (dispatch: Dispatch, getState: () => IState) => void => deleteStampList(id),
        }));
    };

export const deleteStampList = (id: number): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

    dispatch(deleteStampListPending());

    try {
        await api.stampTypes.deleteStampType(id);

        dispatch(deleteStampListSuccess(id));
    } catch (error) {
        dispatch(deleteStampListFailure(error));
        dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
            id: ALERT_DIALOG_MODAL,
            title: WARNING,
            message: error.message,
            confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
        }));
    }
};

import { AnyAction } from 'redux';
import { IAssignDuplicatesState } from './modalAssignDuplicates.model';
import {
  ASSIGN_DUPLICATES_FAILURE,
  ASSIGN_DUPLICATES_SUCCESS,
  ASSIGN_DUPLICATES_PENDING,
} from './constants';

const initialState: IAssignDuplicatesState = {
  assignDuplicatesPending: false,
  error: null,
};

const modalWatermarksAssociate = (
  state: IAssignDuplicatesState = initialState,
  {type, payload}: AnyAction,
): IAssignDuplicatesState => {

  switch (type) {
    case ASSIGN_DUPLICATES_PENDING:
      return {
        ...state,
        error: payload,
        assignDuplicatesPending: true,
      };
    case ASSIGN_DUPLICATES_FAILURE:
      return {
        ...state,
        error: payload,
        assignDuplicatesPending: false,
      };
    case ASSIGN_DUPLICATES_SUCCESS:
      return {
        ...state,
        assignDuplicatesPending: false,
      };

    default:
      return state;
  }
};

export default modalWatermarksAssociate;

import * as React from 'react';
import { Link } from 'react-router-dom';
import { getRedactionDocumentId, getRedactionFolderRsn } from '../../redux/selectors/initialization';
import { useSelector, useDispatch } from '../../../node_modules/react-redux';
import './auditLog.scss';
import { CheckboxMaterialBuilder, SelectMaterialBuilder } from '../../components/materialUiForms/materialUiFormBuilder';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import Table from '../../components/table/Table';
import dayjs = require('dayjs');
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';
import { langEnglish, langFrench } from '../../constants/localization.constants';
import {
    AUDIT_LOG_TITLE,
    SPECIFIC_REQUESTS_LABEL,
    tableColumns,
    YEAR_LABEL,
    END_DATE_LABEL,
    START_DATE_LABEL,
    NUMBER_LABEL,
    TYPE_LABEL,
    DOCUMENT_NAME_LABEL,
    USER_LABEL,
    MAX_DATE_MESSAGE,
    MIN_DATE_MESSAGE,
    SPECIFIC_REQUESTS,
    ALL_REQUESTS,
    EXPORT_ALL_LOG,
    auditExportOptions, EXPORT_CURRENT_LOG, getAuditLogLabelsByKey,
    INVALID_DATE,
} from '../../constants/auditLog.contstants';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Collapse, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import { IRequestType, IUser } from '../../redux/reducers/amandaContent/amandaContent.model';
import { ISelectOptions } from '../../components/materialUiForms/marerialUiForms.model';
import { getFoiFolders } from '../../redux/selectors/modalSearchDocument';
import Checkbox from '@mui/material/Checkbox';
import { getAmandaUsers } from '../../redux/selectors/amandaContent';
import { getAuditLogPermission } from '../../redux/selectors/initialization';
import { fetchAmandaUsers } from '../../redux/actions/amandaContent';
import { fetchFoiFolderTypes } from '../../redux/actions/modalSearchDocuments';
import {
    getAuditActionsOptions,
    getAuditActionsPending,
    getAuditLogs, getAuditLogsPending, getAuditLogHeaders, getResetColumnPendingFlag,
} from '../../redux/selectors/auditLog';
import {
    fetchAuditActions,
    fetchAuditLogs,
    fetchAuditLogsClear,
    exportAuditLogCSV,
    eraseAuditLogs,
    confirmSaveBeforeErase,
    changeAuditLogColumnHeaders,
    resetAuditLogColumnHeaders,
    setAuditLogLoadingFlag,
} from '../../redux/actions/auditLog';
import { IAuditActionState, IAuditHeader, IAuditSearchDto } from '../../redux/reducers/auditLog/auditLog.model';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@mui/material/Popover';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Grid from '@mui/material/Grid';
import SplitButton from '../../components/splitButton/SplitButton';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SEARCH } from '../../constants/advancedSearch.constants';
import { CLEAR } from '../../constants/globalSearch.constants';
import { b64EncodeUnicode } from '../../utils/encode.util';
import {
    AUDIT_LOG_ERASE_CONFIRM,
    AUDIT_LOG_ERASE_CONFIRM_MESSAGE,
    AUDIT_LOG_ERASE_WITHOUT_SAVE,
    AUDIT_LOG_ERASE_WITHOUT_SAVE_MESSAGE,
    CONFIRMATION_DIALOG_MODAL,
} from '../../constants/messages.constants';
import { NO, YES } from '../../constants/common.constants';
import { handleCloseModalWindow, openModalWindow } from '../../redux/actions/modal';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { Spinner } from '../../components/spinner/Spinner';
import { getRedactionLanguage } from '../../redux/selectors/localStorage';

const DEFAULT_LOADING_DELAY = 100;

export const AuditLog = (): JSX.Element => {
    const folderRsn = useSelector(getRedactionFolderRsn);
    const auditActionsOptionsStore = useSelector(getAuditActionsOptions);
    const auditLogsPending = useSelector(getAuditLogsPending);
    const auditLogsList = useSelector(getAuditLogs);
    const permissions = useSelector(getAuditLogPermission);
    const redactionDocumentId = useSelector(getRedactionDocumentId);
    const auditLogHeaders = useSelector(getAuditLogHeaders);
    const columnPendingFlag = useSelector(getResetColumnPendingFlag);
    const redactionLang = useSelector(getRedactionLanguage);

    const dispatch = useDispatch();
    const getUsers = (): void => dispatch(fetchAmandaUsers());
    const getFoiFolderTypes = (): void => dispatch(fetchFoiFolderTypes());
    const fetchAuditActionsList = (): void => dispatch(fetchAuditActions());
    const exportAuditLog = (data?: IAuditSearchDto): void => dispatch(exportAuditLogCSV(data));
    const eraseAuditLog = (): void => dispatch(eraseAuditLogs());
    const confirmSave = (): void => dispatch(confirmSaveBeforeErase());
    const clearAuditLogsList = (): void => dispatch(fetchAuditLogsClear());
    const fetchAuditLogsList = (auditSearchDto: IAuditSearchDto): void => dispatch(fetchAuditLogs(auditSearchDto));
    const handleOpenModalWindow = (modalType: string, modalProps: IModalProps): void =>
        dispatch(openModalWindow(modalType, modalProps));
    const changeAuditHeaders = (columns: IAuditHeader[]): void => dispatch(changeAuditLogColumnHeaders(columns));
    const resetAuditLogColumnHeader = (auditSearchDto: IAuditSearchDto): void =>
        dispatch(resetAuditLogColumnHeaders(auditSearchDto));
    const setLoadingFlag = (flag: boolean): void => dispatch(setAuditLogLoadingFlag(flag));

    const [startDate, setStartDate] = useState(null);
    const [error, setError] = useState({});
    const [endDateErrorMessage,setEndDateErrorMessage] = useState('');
    const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
    const [endDate, setEndDate] = useState(null);
    const [documentName, handleChangeDocumentName] = useState('');
    const [specificRequest, handleSpecificRequestChange] = useState(false);
    // special request start
    const requestTypeOptions = useSelector(getFoiFolders);
    const [year, handleYearChange] = useState(null);
    const [number, handleNumberChange] = useState('');
    const [checkedTypes, handleCheckType] = useState([]);
    // special request end

    const [lastSearchParams, setLastSearchParams] = useState(null);
    const [amandaUser, setAmandaUser] = useState(null);
    const [page, setPage] = React.useState(0);
    const amandaUsers = useSelector(getAmandaUsers);
    const amandaUsersOptions = amandaUsers.map((user: IUser): ISelectOptions => ({
        label: user.userName,
        value: user.userId,
    }));

    const [auditActionsOptions, setAuditActionsOptions] = useState([]);
    let validDate: boolean;
    let language: string;
    language ? redactionLang === langFrench ? dayjs.locale(langFrench) : dayjs.locale(langEnglish)
        : dayjs.locale(langEnglish);

    useEffect(() => {
        getUsers();
        getFoiFolderTypes();
        fetchAuditActionsList();
        setAuditActionsOptions(auditActionsOptionsStore);

        return (): void => {
            clearAuditLogsList();
          };
    }, [auditActionsOptionsStore.length]);

    const handleCheckActionGroup = (label: string, checked: boolean): void => {
        const newOptions = auditActionsOptions.map((item: IAuditActionState) => {
            const list = item.list.map((childItem: IAuditActionState) => {
                return (
                    {
                        ...childItem,
                        isChecked: checked,
                    }
                );
            });

            return item.label !== label ? item :
                {
                    ...item,
                    isChecked: checked,
                    isNotCompleted: false,
                    list,
                };
        });

        setAuditActionsOptions(newOptions);
    };

    const handleCheckActionItem = (parentlabel: string, checked: boolean, childLabel?: string): void => {
        const newOptions = auditActionsOptions.map((item: IAuditActionState) => {
            let countCheckedItemsInGroup = 1;
            const list = item.list.map((listItem: IAuditActionState) => {
                const isCurrentChild = listItem.label === childLabel;
                const newChild = isCurrentChild ?
                    {
                        ...listItem,
                        isChecked: checked,
                    } : listItem;

                if (newChild.isChecked) {
                    countCheckedItemsInGroup++;
                }

                return newChild;
            });
            const isChecked = countCheckedItemsInGroup === item.list.length + 1;

            return item.label !== parentlabel ? item :
                {
                    ...item,
                    list,
                    isChecked,
                    isNotCompleted: countCheckedItemsInGroup > 1 && !isChecked,
                };
        });

        setAuditActionsOptions(newOptions);
    };

    const prepareDataForSearch = (): IAuditSearchDto => {
        const auditActionTypes = auditActionsOptions.reduce((acc: string[], types: IAuditActionState) => {
            if (types.isChecked || types.isNotCompleted) {
                return [...acc, types.label];
            }

            return acc;
        }, []);
        const auditActions = auditActionsOptions.reduce((acc: string[], types: IAuditActionState) => {
            if (types.isChecked || types.isNotCompleted) {
                const actions = types.list.reduce((accChild: string[], typesChild: IAuditActionState) => {
                    return typesChild.isChecked ? [...accChild, typesChild.label] : accChild;
                }, []);

                return [...acc, ...actions];
            }

            return acc;
        }, []);
        const auditTypes: Partial<IAuditSearchDto> = {};

        if (auditActionTypes.length) {
            auditTypes.auditActionTypes = auditActionTypes;
            auditTypes.auditActions = auditActions;
        }

        return ({
            ...auditTypes,
            documentName,
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            searchScope: specificRequest ? SPECIFIC_REQUESTS : ALL_REQUESTS,
            specificRequest: {
                customFolderNumber: number,
                folderType: checkedTypes,
                folderYear: year ? dayjs(year).format('YYYY') : null,
            },
            userId: amandaUser,
        });
    };

    const handleSearch = (): void => {
        const auditSearchDto = prepareDataForSearch();

        setLastSearchParams(auditSearchDto);
        fetchAuditLogsList(auditSearchDto);
    };

    const resetAuditListColumnheaders = (): void => {
        if (!auditLogsList.length) {
            setLoadingFlag(true);
        }

        const resetHeaders = tableColumns.map((header: IAuditHeader) => ({...header, ...{sortOrder: null}}));

        changeAuditHeaders(resetHeaders);

        if(!auditLogsList.length) {
            setTimeout(() => {
                setLoadingFlag(false);
            }, DEFAULT_LOADING_DELAY);
        }

        if(auditLogsList.length) {
            resetAuditLogColumnHeader(lastSearchParams);
        }
    };

    const changeColumnHeaders = (columns: IAuditHeader[]): void => {
        changeAuditHeaders(columns);
    };

    const handleClear = (): void => {
        setStartDate(null);
        setEndDate(null);
        handleChangeDocumentName('');
        handleSpecificRequestChange(false);
        handleYearChange(null);
        handleNumberChange('');
        handleCheckType([]);
        setAmandaUser(null);
        clearAuditLogsList();
        setLastSearchParams(null);
        setAuditActionsOptions(auditActionsOptionsStore);
    };

    const handleErase = (): void => {
        if (permissions.exportButton) {
            handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: getAuditLogLabelsByKey(AUDIT_LOG_ERASE_CONFIRM),
                okButtonTitle: YES,
                cancelButtonTitle: NO,
                message: getAuditLogLabelsByKey(AUDIT_LOG_ERASE_CONFIRM_MESSAGE),
                confirm: (): () => void => (): void => {
                    confirmSave();
                },
                reject: (): () => void => (): void => {
                    eraseAuditLog();
                },
            });
        } else {
            handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: getAuditLogLabelsByKey(AUDIT_LOG_ERASE_WITHOUT_SAVE),
                okButtonTitle: YES,
                cancelButtonTitle: NO,
                message: getAuditLogLabelsByKey(AUDIT_LOG_ERASE_WITHOUT_SAVE_MESSAGE),
                confirm: (): () => void => (): void => {
                    eraseAuditLog();
                },
            });
        }
    };

    const isSearchDisabled = (): boolean => {
        const errorArray = Object.keys(error).reduce((acc: string[], key: string) => {
            return error[key] ? [...acc, error[key]] : acc;
        }, []);

        return !!errorArray.length || !startDate || !endDate;
    };

    const handleChooseOption = (value: string): void => {
        if (value === EXPORT_ALL_LOG) {
            exportAuditLog();
        } else {
            exportAuditLog(lastSearchParams);
        }
    };

    const auditExportOptionsList = auditExportOptions.map((option: ISelectOptions): ISelectOptions =>
        !auditLogsList.length && option.value === EXPORT_CURRENT_LOG ? { ...option, disabled: true } : option);

    const handleStartDateChange = (date) => {
        setStartDate(date);
        validDate = dayjs(date.$d).isValid();
        if (!validDate) {
            setStartDateErrorMessage(INVALID_DATE);
            setEndDateErrorMessage('');
            setError({ startDate: true, endDate: false });
        } else {
            validateDates(date, endDate);
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        validDate = dayjs(date.$d).isValid();
        if (!validDate) {
            setEndDateErrorMessage(INVALID_DATE);
            setStartDateErrorMessage('');
            setError({ startDate: false, endDate: true });
        } else {
            validateDates(startDate, date);
        }
    };

    const validateDates = (start, end) => {
        if (dayjs(start).isValid() && dayjs(end).isValid()) {
            if (dayjs(start).isAfter(dayjs(end))) {
                setStartDateErrorMessage(MAX_DATE_MESSAGE);
                setError({ startDate: true, endDate: false });
            } if (dayjs(end).isBefore(dayjs(start))) {
                setEndDateErrorMessage(MIN_DATE_MESSAGE);
                setError({ startDate: false, endDate: true });
            } else {
                setStartDateErrorMessage('');
                setEndDateErrorMessage('');
                setError({ startDate: false, endDate: false });
            }
        }
    };
    const paginatedData = auditLogsList;

    return (
        <div className='auditLog'>
            {
                folderRsn && redactionDocumentId ?
                    <Link
                        to={`/${b64EncodeUnicode(folderRsn)}`}
                        className='link_button auditLog_back_button'
                        onClick={(): void => {
                            const resetHeaders = tableColumns.map((header: IAuditHeader) =>
                                ({...header, ...{sortOrder: null}}));

                            changeAuditHeaders(resetHeaders);
                        }}
                    >
                        {getAuditLogLabelsByKey('Back Home')}
                    </Link>
                    : <Link
                        to={`/${b64EncodeUnicode(folderRsn)}`}
                        onClick={(e: React.MouseEvent<Element>): void => {
                            e.preventDefault();

                            const resetHeaders = tableColumns.map((header: IAuditHeader) =>
                                ({...header, ...{sortOrder: null}}));

                            changeAuditHeaders(resetHeaders);
                        }}
                        className='link_button link_button_disabled  auditLog_back_button'
                    >
                        {getAuditLogLabelsByKey('Back Home')}
                    </Link>
            }
            <div className='auditLog_header'>
                <h2>{getAuditLogLabelsByKey(AUDIT_LOG_TITLE)}</h2>
            </div>
            <div className='auditLog_form'>
                <div className='auditLog_scrollWrap'>
                    <Scrollbars>
                        <div className='auditLog_formInner'>
                            <CheckboxMaterialBuilder
                                type={'switch'}
                                checked={specificRequest}
                                label={getAuditLogLabelsByKey(SPECIFIC_REQUESTS_LABEL)}
                                handleCheckboxChange={
                                    (param: string, checked: boolean): void => handleSpecificRequestChange(checked)}
                                paramName={SPECIFIC_REQUESTS_LABEL}
                            />
                            <Collapse in={specificRequest}>
                                <Grid container={true} spacing={4}>
                                    <Grid item={true} xs={6}>
                                        <FormLabel component='legend'>{getAuditLogLabelsByKey(TYPE_LABEL)}</FormLabel>
                                        <div className={'auditLog_typeBox'}>
                                            <Scrollbars>
                                                <FormControl component='fieldset'>
                                                <FormGroup>
                                                    {
                                                        requestTypeOptions.map((item: IRequestType): JSX.Element => {
                                                            const checked = checkedTypes.includes(item.folderType);
                                                            const array = !checked ?
                                                                [...checkedTypes, item.folderType] :
                                                                checkedTypes.filter((i: string) =>
                                                                    i !== item.folderType);

                                                            return (
                                                                <div key={item.folderType}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                color='primary'
                                                                                checked={checked}
                                                                                onChange={(): void =>
                                                                                    handleCheckType(array)}
                                                                                value='gilad'
                                                                            />
                                                                        }
                                                                            label={(redactionLang && redactionLang === 'fr' && item.folderDesc2) ? item.folderDesc2 : item.folderDesc}
                                                                        key={item.folderType}
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </FormGroup>
                                                </FormControl>
                                            </Scrollbars>
                                        </div>
                                    </Grid>
                                    <Grid item={true} xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            views={['year']}
                                            label={getAuditLogLabelsByKey(YEAR_LABEL)}
                                            value={year}
                                            minDate={dayjs('2000-01-01')}
                                            onChange={(e: any): void => {
                                                setError({...error, year: e && !dayjs(e.$d).isValid()});
                                                handleYearChange(e);
                                            }}
                                        />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item={true} xs={3}>
                                        <TextField
                                            value={number}
                                            label={getAuditLogLabelsByKey(NUMBER_LABEL)}
                                            fullWidth={true}
                                            type='text'
                                            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                                handleNumberChange(e.target.value.slice(0, 255));
                                            }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>

                            <Grid container={true} spacing={4}>
                                <Grid item={true} xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={startDate}
                                        maxDate={dayjs(endDate) ? dayjs(endDate) : undefined}
                                        onChange={(date) => handleStartDateChange(date)}
                                        slotProps={{
                                            textField: {
                                                helperText: startDateErrorMessage,
                                                InputProps:{
                                                    style: {marginBottom: startDateErrorMessage ? '0px' : '20px'}
                                                }
                                            },
                                        }}
                                        label={getAuditLogLabelsByKey(START_DATE_LABEL)}
                                        format='YYYY/MM/DD'
                                    />
                                    </LocalizationProvider>
                                    <TextField
                                        value={documentName}
                                        label={getAuditLogLabelsByKey(DOCUMENT_NAME_LABEL)}
                                        fullWidth={true}
                                        type='text'
                                        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                                            handleChangeDocumentName(e.target.value)}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item={true} xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={endDate}
                                        minDate={dayjs(startDate) ? dayjs(startDate) : undefined}
                                        onChange={(date) => handleEndDateChange(date)}
                                        slotProps={{
                                            textField: {
                                                helperText: endDateErrorMessage,
                                                InputProps:{
                                                    style: {marginBottom: endDateErrorMessage ? '0px' : '20px'}
                                                }
                                            },
                                        }}
                                        label={getAuditLogLabelsByKey(END_DATE_LABEL)}
                                        format='YYYY/MM/DD'
                                    />
                                    </LocalizationProvider>
                                    <SelectMaterialBuilder
                                        value={amandaUser}
                                        label={getAuditLogLabelsByKey(USER_LABEL)}
                                        paramName={USER_LABEL}
                                        handleSelectChange={(param: string, val: string): void => setAmandaUser(val)}
                                        options={amandaUsersOptions}
                                    />
                                </Grid>
                            </Grid>
                            <div className={'auditLog_comboboxList'}>
                                {
                                    auditActionsOptions.map((auditAction: IAuditActionState) =>
                                        <div
                                            key={auditAction.label}
                                            className={'auditLog_comboboxItem'}
                                        >
                                            <Checkbox
                                                color={'primary'}
                                                indeterminate={auditAction.isNotCompleted}
                                                checked={auditAction.isChecked}
                                                value={auditAction.label}
                                                onChange={(): void =>
                                                    handleCheckActionGroup(auditAction.label, !auditAction.isChecked)}
                                            />
                                            <PopupState variant='popover' popupId='demo-popup-popover'>
                                                {(popupState: any): JSX.Element => (
                                                    <div>
                                                        <Button {...bindTrigger(popupState)}>
                                                            {getAuditLogLabelsByKey(auditAction.label)}

                                                            <KeyboardArrowDown classes={{root: 'arrow-down'}}/>
                                                        </Button>
                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            }}
                                                        >
                                                            {
                                                                auditAction.list.map((item: IAuditActionState) =>
                                                                    <div
                                                                        key={item.label}
                                                                        className={'auditLog_comboboxPopover'}
                                                                    >
                                                                        <Typography
                                                                            variant='subtitle1'
                                                                            gutterBottom={true}
                                                                        >
                                                                            <Checkbox
                                                                                color='primary'
                                                                                checked={item.isChecked}
                                                                                value={item.label}
                                                                                onChange={(): void =>
                                                                                    handleCheckActionItem(
                                                                                        auditAction.label,
                                                                                        !item.isChecked,
                                                                                        item.label,
                                                                                    )}
                                                                            />

                                                                            {getAuditLogLabelsByKey(item.label)}
                                                                        </Typography>
                                                                    </div>,
                                                                )
                                                            }
                                                        </Popover>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </div>)
                                }
                            </div>
                        </div>

                    </Scrollbars>
                </div>
                <div className={'auditLog_formFooter'}>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        className='buttons-style primary'
                        onClick={handleSearch}
                        disabled={isSearchDisabled()}
                    >
                        {getAuditLogLabelsByKey(SEARCH)}
                    </Button>
                    <Button
                        size='small'
                        variant='outlined'
                        onClick={handleClear}
                        className='buttons-style outlined'
                    >
                        {getAuditLogLabelsByKey(CLEAR)}
                    </Button>
                    {permissions.eraseButton &&
                        <Button
                            size='small'
                            variant='outlined'
                            onClick={handleErase}
                            className='buttons-style outlined'
                            disabled={!auditLogsList.length}
                        >
                            {getAuditLogLabelsByKey('Erase Log')}
                        </Button>
                    }
                </div>
            </div>
            <div className='auditLog_result'>
                {columnPendingFlag ? <Spinner active={true} /> :
                    <div className='auditLog_result_table'>
                        <Table
                            title={getAuditLogLabelsByKey('Search Result')}
                            columns={auditLogHeaders.map((header => {
                                header.Header = getAuditLogLabelsByKey(header.id);
                                return header;
                            }))}
                            defaultPageSize={15}
                            data={paginatedData}
                            showPagination={!!auditLogsList.length}
                            pagesCount={auditLogsList.length}
                            page={page}
                            pageSize={15}
                            onFetchData={(page: number): void => setPage(page)}
                            allowSettings={true}
                            loading={auditLogsPending}
                            userPreferenceTable={true}
                            enableShowHideColumn={true}
                            resetColumnHeader={resetAuditListColumnheaders}
                            changeColumns={changeColumnHeaders}
                        />
                    </div>}
                <div className='auditLog_result_button'>
                    <SplitButton
                        options={auditExportOptionsList.map((exportOption => {
                            exportOption.label = getAuditLogLabelsByKey(exportOption.value);
                            return exportOption;
                        }))}
                        disabled={!permissions.exportButton}
                        handleChooseOption={handleChooseOption}
                    />
                </div>
            </div>
        </div>
    );
};
export const GET_ENCRYPTION_KEYS_PENDING = 'reduction/GET_ENCRYPTION_KEYS_PENDING';
export const GET_ENCRYPTION_KEYS_SUCCESS = 'reduction/GET_ENCRYPTION_KEYS_SUCCESS';
export const GET_ENCRYPTION_KEYS_FAIL = 'reduction/GET_ENCRYPTION_KEYS_FAIL';

export const SET_OPTIONS_ENCRYPTION = 'reduction/SET_OPTIONS_ENCRYPTION';
export const PUT_ENCRYPTION_PENDING = 'reduction/PUT_ENCRYPTION_PENDING';
export const PUT_ENCRYPTION_SUCCESS = 'reduction/PUT_ENCRYPTION_SUCCESS';
export const PUT_ENCRYPTION_FAILURE = 'reduction/PUT_ENCRYPTION_FAILURE';

export const POST_ENCRYPTION_PENDING = 'reduction/POST_ENCRYPTION_PENDING';
export const POST_ENCRYPTION_SUCCESS = 'reduction/POST_ENCRYPTION_SUCCESS';
export const POST_ENCRYPTION_FAILURE = 'reduction/POST_ENCRYPTION_FAILURE';

export const DELETE_ENCRYPTION_KEY_PENDING = 'reduction/DELETE_ENCRYPTION_KEY_PENDING';
export const DELETE_ENCRYPTION_KEY_SUCCESS = 'reduction/DELETE_ENCRYPTION_KEY_SUCCESS';
export const DELETE_ENCRYPTION_KEY_FAILURE = 'reduction/DELETE_ENCRYPTION_KEY_FAILURE';

import {
    GET_REQUEST_TYPE_PENDING,
    GET_REQUEST_TYPE_SUCCESS,
    GET_REQUEST_TYPE_FAILURE,
    GET_CONTACT_TYPE_PENDING,
    GET_CONTACT_TYPE_SUCCESS,
    GET_CONTACT_TYPE_FAILURE,
    GET_REQUEST_FOLDERS_FAILURE,
    GET_REQUEST_FOLDERS_PENDING,
    GET_REQUEST_FOLDERS_SUCCESS,
    GET_REQUEST_SECURITY_FAILURE,
    GET_REQUEST_SECURITY_PENDING,
    GET_REQUEST_SECURITY_SUCCESS,
    SET_SELECTED_REQUEST_TYPE,
    GET_AVAILABLE_SECURITY_FAILURE,
    GET_AVAILABLE_SECURITY_PENDING,
    GET_AVAILABLE_SECURITY_SUCCESS,
    UPDATE_REQUEST_SECURITY_FAILURE,
    UPDATE_REQUEST_SECURITY_PENDING,
    UPDATE_REQUEST_SECURITY_SUCCESS,
    GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE,
    GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING,
    GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS,
    UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE,
    UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING,
    UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS,
    SET_REQUEST_SECURITIES,
    SET_REQUEST_SECURITY_GROUPS_BY_FOLDER,
    GET_FOLDER_PROCESSES_PENDING,
    GET_FOLDER_PROCESSES_SUCCESS,
    GET_FOLDER_PROCESSES_FAILURE,
    CLEAN_REQUEST_SECURITIES,
    GET_AMANDA_USERS_PENDING,
    GET_AMANDA_USERS_FAILURE,
    GET_AMANDA_USERS_SUCCESS,
    GET_FOLDER_ATTACHMENT_TYPES_PENDING,
    GET_FOLDER_ATTACHMENT_TYPES_SUCCESS,
    GET_FOLDER_ATTACHMENT_TYPES_FAILURE,
    GET_PROCESS_ATTACHMENT_TYPES_PENDING,
    GET_PROCESS_ATTACHMENT_TYPES_FAILURE,
    GET_PROCESS_ATTACHMENT_TYPES_SUCCESS,
} from './constants';
import { IAmandaContentState } from './amandaContent.model';
import { AnyAction } from 'redux';

const initialState: IAmandaContentState = {
    requestType: [],
    requestTypePending: false,
    contactsTypes: [],
    contactsTypesPending: false,
    error: null,
    requestFolders: [],
    requestFoldersPending: false,
    requestSecurities: null,
    requestSecuritiesPending: false,
    selectedRequestType: null,
    availableSecurity: null,
    availableSecurityPending: false,
    requestSecurityGroupsByFolder: null,
    requestSecurityGroupsByFolderPending: false,
    folderProcesses: [],
    folderProcessesLoading: false,
    amandaUsers: [],
    amandaUsersPending: false,
    folderAttachmentTypes: [],
    folderAttachmentTypesLoading: false,
    processAttachmentTypes: [],
    processAttachmentTypesLoading: false,
};

const amandaContentReducer = (
    state: IAmandaContentState = initialState,
    {type, payload}: AnyAction,
): IAmandaContentState => {
    switch (type) {
        case GET_REQUEST_TYPE_PENDING:
            return {
                ...state,
                requestTypePending: true,
                error: null,
            };
        case GET_REQUEST_TYPE_SUCCESS:
            return {
                ...state,
                requestType: payload,
                requestTypePending: false,
            };
        case GET_REQUEST_TYPE_FAILURE:
            return {
                ...state,
                error: payload,
                requestTypePending: false,
            };
        case GET_AMANDA_USERS_PENDING:
            return {
                ...state,
                amandaUsersPending: true,
                error: null,
            };
        case GET_AMANDA_USERS_SUCCESS:
            return {
                ...state,
                amandaUsers: payload,
                amandaUsersPending: false,
            };
        case GET_AMANDA_USERS_FAILURE:
            return {
                ...state,
                error: payload,
                amandaUsersPending: false,
            };
        case GET_CONTACT_TYPE_PENDING:
            return {
                ...state,
                contactsTypesPending: true,
                error: null,
            };
        case GET_CONTACT_TYPE_SUCCESS:
            return {
                ...state,
                contactsTypes: payload,
                contactsTypesPending: false,
            };
        case GET_CONTACT_TYPE_FAILURE:
            return {
                ...state,
                error: payload,
                contactsTypesPending: false,
            };
        case GET_REQUEST_FOLDERS_PENDING:
            return {
                ...state,
                requestFoldersPending: true,
                error: null,
            };
        case GET_REQUEST_FOLDERS_SUCCESS:
            return {
                ...state,
                requestFoldersPending: false,
                requestFolders: payload,
            };
        case GET_REQUEST_FOLDERS_FAILURE:
            return {
                ...state,
                requestFoldersPending: false,
                error: payload,
            };
        case GET_REQUEST_SECURITY_PENDING:
        case UPDATE_REQUEST_SECURITY_PENDING:
            return {
                ...state,
                requestSecuritiesPending: true,
                error: null,
            };
        case GET_REQUEST_SECURITY_SUCCESS:
        case UPDATE_REQUEST_SECURITY_SUCCESS:
        case GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS:
        case UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS:
            return {
                ...state,
                requestSecurityGroupsByFolder: null,
                requestSecuritiesPending: false,
                requestSecurities: payload,
            };
        case GET_REQUEST_SECURITY_FAILURE:
        case UPDATE_REQUEST_SECURITY_FAILURE:
            return {
                ...state,
                requestSecuritiesPending: false,
                error: payload,
            };
        case GET_FOLDER_PROCESSES_PENDING:
            return {
                ...state,
                folderProcessesLoading: true,
                error: null,
            };
        case GET_FOLDER_PROCESSES_SUCCESS:
            return {
                ...state,
                folderProcessesLoading: false,
                folderProcesses: payload,
            };
        case GET_FOLDER_PROCESSES_FAILURE:
            return {
                ...state,
                folderProcessesLoading: false,
                error: payload,
            };
        case SET_SELECTED_REQUEST_TYPE:
            return {
                ...state,
                selectedRequestType: payload,
            };
        case GET_AVAILABLE_SECURITY_PENDING:
            return {
                ...state,
                availableSecurityPending: true,
            };
        case GET_AVAILABLE_SECURITY_SUCCESS:
            return {
                ...state,
                availableSecurityPending: false,
                availableSecurity: payload,
            };
        case GET_AVAILABLE_SECURITY_FAILURE:
            return {
                ...state,
                availableSecurityPending: false,
                error: payload,
            };
        case GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING:
        case UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING:
            return {
                ...state,
                requestSecurityGroupsByFolderPending: true,
            };
        case GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE:
        case UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE:
            return {
                ...state,
                requestSecurityGroupsByFolderPending: false,
                error: payload,
            };
        case SET_REQUEST_SECURITIES:
            return {
                ...state,
                requestSecurities: payload,
            };
        case SET_REQUEST_SECURITY_GROUPS_BY_FOLDER:
            return {
                ...state,
                requestSecurityGroupsByFolder: payload,
            };
        case GET_FOLDER_ATTACHMENT_TYPES_PENDING:
            return {
                ...state,
                folderAttachmentTypesLoading: true,
                error: null,
            };
        case GET_FOLDER_ATTACHMENT_TYPES_SUCCESS:
            return {
                ...state,
                folderAttachmentTypesLoading: false,
                folderAttachmentTypes: payload,
            };
        case GET_FOLDER_ATTACHMENT_TYPES_FAILURE:
            return {
                ...state,
                folderAttachmentTypesLoading: false,
                error: payload,
            };
        case GET_PROCESS_ATTACHMENT_TYPES_PENDING:
            return {
                ...state,
                processAttachmentTypesLoading: true,
                error: null,
            };
        case GET_PROCESS_ATTACHMENT_TYPES_SUCCESS:
            return {
                ...state,
                processAttachmentTypesLoading: false,
                processAttachmentTypes: payload,
            };
        case GET_PROCESS_ATTACHMENT_TYPES_FAILURE:
            return {
                ...state,
                processAttachmentTypesLoading: false,
                error: payload,
            };
        case CLEAN_REQUEST_SECURITIES:
            return {
                ...state,
                requestFolders: [],
                requestType: [],
                availableSecurity: null,
                requestSecurities: null,
                selectedRequestType: null,
                requestSecurityGroupsByFolder: null,
            };
        default:
            return state;
    }
};

export default amandaContentReducer;

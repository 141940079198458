export const CANCELED_STATUS = 'CANCELED';
export const ERROR_STATUS = 'ERROR';
export const COMPLETED_STATUS = 'COMPLETED';

export const QUEUE_MANAGEMENT = 'Document Queue';
export const SEARCH = 'Search';
export const ADVANCED_SEARCH = 'Advanced search';

export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_DELAY = 5 * 1000;

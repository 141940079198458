import { AnyAction, Dispatch } from 'redux';
import {
    GET_REQUEST_TYPE_PENDING,
    GET_REQUEST_TYPE_SUCCESS,
    GET_REQUEST_TYPE_FAILURE,
    GET_CONTACT_TYPE_PENDING,
    GET_CONTACT_TYPE_SUCCESS,
    GET_CONTACT_TYPE_FAILURE,
    GET_REQUEST_SECURITY_FAILURE,
    GET_REQUEST_SECURITY_SUCCESS,
    GET_REQUEST_SECURITY_PENDING,
    GET_REQUEST_FOLDERS_FAILURE,
    GET_REQUEST_FOLDERS_SUCCESS,
    GET_REQUEST_FOLDERS_PENDING,
    GET_FOLDER_PROCESSES_PENDING,
    GET_FOLDER_PROCESSES_SUCCESS,
    GET_FOLDER_PROCESSES_FAILURE,
    SET_SELECTED_REQUEST_TYPE,
    GET_AVAILABLE_SECURITY_FAILURE,
    GET_AVAILABLE_SECURITY_SUCCESS,
    GET_AVAILABLE_SECURITY_PENDING,
    UPDATE_REQUEST_SECURITY_FAILURE,
    UPDATE_REQUEST_SECURITY_SUCCESS,
    UPDATE_REQUEST_SECURITY_PENDING,
    GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE,
    GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS,
    GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING,
    UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE,
    UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS,
    UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING,
    SET_REQUEST_SECURITY_GROUPS_BY_FOLDER,
    SET_REQUEST_SECURITIES,
    CLEAN_REQUEST_SECURITIES,
    GET_AMANDA_USERS_PENDING,
    GET_AMANDA_USERS_FAILURE,
    GET_AMANDA_USERS_SUCCESS,
    GET_FOLDER_ATTACHMENT_TYPES_PENDING,
    GET_FOLDER_ATTACHMENT_TYPES_SUCCESS,
    GET_FOLDER_ATTACHMENT_TYPES_FAILURE,
    GET_PROCESS_ATTACHMENT_TYPES_PENDING,
    GET_PROCESS_ATTACHMENT_TYPES_SUCCESS,
    GET_PROCESS_ATTACHMENT_TYPES_FAILURE,
} from '../reducers/amandaContent/constants';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import {
    IRequestType,
    IContactType,
    IRequestFolder,
    IRequestSecurities,
    IRequestSecurityParams,
    IAvailableSecurity,
    ISecurityGroupByFolderRsn,
    IRequestFolderSecurityParams,
    IFolderProcesses, IUser, IAttachmentTypes,
} from '../reducers/amandaContent/amandaContent.model';
import { IError } from '../common.model';
import { IState } from '../store';
import { handleCloseAllModalWindows } from './modal';
import { FOLDER, PROCESS } from '../../constants/export.contstants';

export const fetchRequestTypeListPending = (): AnyAction => action(GET_REQUEST_TYPE_PENDING);
export const fetchRequestTypeListSuccess = (dataList: IRequestType[]): AnyAction =>
    action(GET_REQUEST_TYPE_SUCCESS, dataList);
export const fetchRequestTypeListFailure = (error: IError): AnyAction => action(GET_REQUEST_TYPE_FAILURE, error);
export const fetchContactTypeListPending = (): AnyAction => action(GET_CONTACT_TYPE_PENDING);
export const fetchContactTypeListSuccess = (dataList: IContactType[]): AnyAction =>
    action(GET_CONTACT_TYPE_SUCCESS, dataList);
export const fetchContactTypeListFailure = (error: IError): AnyAction => action(GET_CONTACT_TYPE_FAILURE, error);
export const fetchRequestFoldersPending = (): AnyAction => action(GET_REQUEST_FOLDERS_PENDING);
export const fetchRequestFoldersSuccess = (folders: IRequestFolder[]): AnyAction =>
    action(GET_REQUEST_FOLDERS_SUCCESS, folders);
export const fetchRequestFoldersFailure = (error: IError): AnyAction => action(GET_REQUEST_FOLDERS_FAILURE, error);
export const fetchRequestSecuritiesPending = (): AnyAction => action(GET_REQUEST_SECURITY_PENDING);
export const fetchRequestSecuritiesSuccess = (securities: IRequestSecurities): AnyAction =>
    action(GET_REQUEST_SECURITY_SUCCESS, securities);
export const fetchRequestSecuritiesFailure = (error: IError): AnyAction =>
    action(GET_REQUEST_SECURITY_FAILURE, error);
export const fetchFolderProcessesPending = (): AnyAction => action(GET_FOLDER_PROCESSES_PENDING);
export const fetchFolderProcessesSuccess = (processes: IFolderProcesses[]): AnyAction =>
    action(GET_FOLDER_PROCESSES_SUCCESS, processes);
export const fetchFolderProcessesFailure = (error: IError): AnyAction => action(GET_FOLDER_PROCESSES_FAILURE, error);
export const fetchAmandaUsersPending = (): AnyAction => action(GET_AMANDA_USERS_PENDING);
export const fetchAmandaUsersSuccess = (processes: IUser[]): AnyAction =>
    action(GET_AMANDA_USERS_SUCCESS, processes);
export const fetchAmandaUsersFailure = (error: IError): AnyAction => action(GET_AMANDA_USERS_FAILURE, error);
export const setSelectedRequestType = (requestType: string): AnyAction =>
    action(SET_SELECTED_REQUEST_TYPE, requestType);
export const fetchAvailableSecurityPending = (): AnyAction => action(GET_AVAILABLE_SECURITY_PENDING);
export const fetchAvailableSecuritySuccess = (availableSecurity: IAvailableSecurity): AnyAction =>
    action(GET_AVAILABLE_SECURITY_SUCCESS, availableSecurity);
export const fetchAvailableSecurityFailure = (error: IError): AnyAction =>
    action(GET_AVAILABLE_SECURITY_FAILURE, error);
export const updateRequestSecurityPending = (): AnyAction =>
    action(UPDATE_REQUEST_SECURITY_PENDING);
export const updateRequestSecuritySuccess = (requestSecurity: IRequestSecurities): AnyAction =>
    action(UPDATE_REQUEST_SECURITY_SUCCESS, requestSecurity);
export const updateRequestSecurityFailure = (error: IError): AnyAction =>
    action(UPDATE_REQUEST_SECURITY_FAILURE, error);
export const fetchRequestSecurityGroupsByFolderPending = (): AnyAction =>
    action(GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING);
export const fetchRequestSecurityGroupsByFolderSuccess = (group: ISecurityGroupByFolderRsn): AnyAction =>
    action(GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS, group);
export const fetchRequestSecurityGroupsByFolderFailure = (error: IError): AnyAction =>
    action(GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE, error);
export const updateRequestSecurityGroupsByFolderPending = (): AnyAction =>
    action(UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING);
export const updateRequestSecurityGroupsByFolderSuccess = (group: ISecurityGroupByFolderRsn): AnyAction =>
    action(UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS, group);
export const updateRequestSecurityGroupsByFolderFailure = (error: IError): AnyAction =>
    action(UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE, error);
export const setRequestSecurities = (data: IRequestSecurities): AnyAction =>
    action(SET_REQUEST_SECURITIES, data);
export const setRequestSecurityGroupsByFolder = (data: ISecurityGroupByFolderRsn): AnyAction =>
    action(SET_REQUEST_SECURITY_GROUPS_BY_FOLDER, data);
export const cleanRequestSecurities = (): AnyAction => action(CLEAN_REQUEST_SECURITIES);
export const fetchFolderAttachmentTypesPending = (): AnyAction => action(GET_FOLDER_ATTACHMENT_TYPES_PENDING);
export const fetchFolderAttachmentTypesSuccess = (processes: IAttachmentTypes[]): AnyAction =>
    action(GET_FOLDER_ATTACHMENT_TYPES_SUCCESS, processes);
export const fetchFolderAttachmentTypesFailure = (error: IError): AnyAction =>
    action(GET_FOLDER_ATTACHMENT_TYPES_FAILURE, error);
export const fetchProcessAttachmentTypesPending = (): AnyAction =>
    action(GET_PROCESS_ATTACHMENT_TYPES_PENDING);
export const fetchProcessAttachmentTypesSuccess = (processes: IAttachmentTypes[]): AnyAction =>
    action(GET_PROCESS_ATTACHMENT_TYPES_SUCCESS, processes);
export const fetchProcessAttachmentTypesFailure = (error: IError): AnyAction =>
    action(GET_PROCESS_ATTACHMENT_TYPES_FAILURE, error);
export const fetchRequestTypeList = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchRequestTypeListPending());

        try {

            const response = await api.amandaRequests.getRequestTypes();
            // TODO need to remove map, when backend will return only this
            const result = response.map((resp: IRequestType): IRequestType => ({
                folderType: resp.folderType,
                folderDesc: resp.folderDesc,
                folderDesc2: resp.folderDesc2 ? resp.folderDesc2 : '',
            }));

            dispatch(fetchRequestTypeListSuccess(result));
        } catch (error) {
            dispatch(fetchRequestTypeListFailure(error));
        }
    };

export const fetchContactTypeList = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchContactTypeListPending());

        try {
            const response = await api.amandaRequests.getPeopleTypes();
            // TODO need to remove map, when backend will return only this
            const result = response.map((resp: IContactType): IContactType => ({
                peopleCode: resp.peopleCode,
                peopleDesc: resp.peopleDesc,
                peopleDesc2: resp.peopleDesc2 ? resp.peopleDesc2 : '',
            }));

            dispatch(fetchContactTypeListSuccess(result));
        } catch (error) {
            dispatch(fetchContactTypeListFailure(error));
        }
    };

export const fetchRequestFolders = (
    params: IRequestSecurityParams): (dispatch: Dispatch, getState: () => IState,
) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        dispatch(fetchRequestFoldersPending());

        try {
            const response = await api.requestSecurityController.getRequestsFolders(
                params.folderType === 'All' ?  {} : params,
            );

            dispatch(fetchRequestFoldersSuccess(response));
        } catch (error) {
            dispatch(fetchRequestFoldersFailure(error));
        }
    };

export const fetchRequestSecurities = (
    params: IRequestSecurityParams): (dispatch: Dispatch, getState: () => IState,
) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        dispatch(fetchRequestSecuritiesPending());

        try {
            const response = await api.requestSecurityController.getRequestSecurity(params);

            dispatch(fetchRequestSecuritiesSuccess(response));
        } catch (error) {
            dispatch(fetchRequestSecuritiesFailure(error));
        }
    };

export const fetchAvailableSecurity = (
    params: IRequestSecurityParams): (dispatch: Dispatch, getState: () => IState,
) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        dispatch(fetchAvailableSecurityPending());

        try {
            const response = await api.requestSecurityController.getAvailableSecurity(params);

            dispatch(fetchAvailableSecuritySuccess(response));
        } catch (error) {
            dispatch(fetchAvailableSecurityFailure(error));
        }
    };

export const updateRequestSecurity = (
    params: IRequestSecurityParams,
    data: IRequestSecurities,
): (dispatch: Dispatch, getState: () => IState,
) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        dispatch(updateRequestSecurityPending());

        try {
            const response = await api.requestSecurityController.updateRequestSecurity(data, params);

            dispatch(updateRequestSecuritySuccess(response));
            dispatch(handleCloseAllModalWindows());
        } catch (error) {
            dispatch(updateRequestSecurityFailure(error));
        }
    };

export const fetchRequestSecurityGroupsByFolder = (
    params: IRequestFolderSecurityParams): (dispatch: Dispatch, getState: () => IState,
) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        dispatch(fetchRequestSecurityGroupsByFolderPending());

        try {
            const response = await api.folderSecurityController.getRequestSecurityGroupsByFolderRsn(params);

            dispatch(fetchRequestSecurityGroupsByFolderSuccess(response));
        } catch (error) {
            dispatch(fetchRequestSecurityGroupsByFolderFailure(error));
        }
    };

export const updateRequestSecurityGroupsByFolder = (
    params: IRequestFolderSecurityParams,
    data: ISecurityGroupByFolderRsn,
): (dispatch: Dispatch, getState: () => IState,
) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        dispatch(updateRequestSecurityGroupsByFolderPending());

        try {
            const response = await api.folderSecurityController.updateRequestSecurityGroupsByFolderRsn(data, params);

            dispatch(updateRequestSecurityGroupsByFolderSuccess(response));
            dispatch(handleCloseAllModalWindows());
        } catch (error) {
            dispatch(updateRequestSecurityGroupsByFolderFailure(error));
        }
    };

export const fetchFolderProcesses = (redactionDocumentId: number): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchFolderProcessesPending());

        try {
            const response = await api.amandaRequests.getFolderProcesses(redactionDocumentId);

            dispatch(fetchFolderProcessesSuccess(response));
        } catch (error) {
            dispatch(fetchFolderProcessesFailure(error));
        }
    };

export const fetchAmandaUsers = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchAmandaUsersPending());

        try {
            const response = await api.amandaRequests.getUsers();

            dispatch(fetchAmandaUsersSuccess(response));
        } catch (error) {
            dispatch(fetchAmandaUsersFailure(error));
        }
    };

export const fetchFolderAttachmentTypes = (redactionDocumentId: number, folderRsn: number):
    (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchFolderAttachmentTypesPending());

        try {
            const response = await api.amandaRequests.getAttachmentTypes(redactionDocumentId, FOLDER, folderRsn);

            dispatch(fetchFolderAttachmentTypesSuccess(response));
        } catch (error) {
            dispatch(fetchFolderAttachmentTypesFailure(error));
        }
    };

export const fetchProcessAttachmentTypes = (redactionDocumentId: number, processRsn: number):
    (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchProcessAttachmentTypesPending());

        try {
            const response = await api.amandaRequests.getAttachmentTypes(redactionDocumentId, PROCESS, processRsn);

            dispatch(fetchProcessAttachmentTypesSuccess(response));
        } catch (error) {
            dispatch(fetchProcessAttachmentTypesFailure(error));
        }
    };

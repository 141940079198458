import * as React from 'react';
import './duplicatesReference.styles.scss';
import Button from '@mui/material/Button';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { connect } from 'react-redux';
import { IState } from '../../../redux/store';
import Typography from '@mui/material/Typography';
import { SelectMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { ITableColumn, ITrProps, MyRowData } from '../../../components/table/table.model';
import { Row } from 'react-table';
import {
    fetchDocuments,
    fetchReferencePagesById,
    setReferenceDuplicates,
    cleanReferenceManagementData,
    removeDuplicateReference,
    removePageReference,
    removeMasterReference,
    fetchReferencePageNumber,
} from '../../../redux/actions/duplicates';
import {
    getMasterDocumentsReference,
    getMasterDocumentsPages,
    getReferenceDuplicates,
} from '../../../redux/selectors/duplicates';
import { first, uniq, curry } from 'lodash';
import Table from '../../../components/table/ServerSidePagingTable';
import { IDuplicatesReferenceState, IProps, IReferenceDispatchProps, IReferenceStateProps } from './reference.modal';
import {
    IReferenceDuplicate,
    IReferenceMaster,
    IReferencePage,
} from '../../../redux/reducers/duplicates/duplicates.model';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import * as constants from '../../../constants/duplicates.constants';

export const pagesColumns: ITableColumn[] = [
    {
        id: 'actualPageNumber',
        accessorKey: 'actualPageNumber',
        header: constants.getManageDuplicatesLabelsByKey('Page #'),
        minResizeWidth: 1,
    },
    {
        id: 'pageDisclosureTypeName',
        accessorKey: 'pageDisclosureTypeName',
        header: constants.getManageDuplicatesLabelsByKey('Reference Modal Disclosure'),
        minResizeWidth: 1,
    },
    {
        id: 'deleteReference',
        accessorKey: 'deleteReference',
        header: '',
        minResizeWidth: 1,
        minWidth: 25,
    },
];

export const duplicatesColumns: ITableColumn[] = [
    {
        id: 'documentName',
        accessorKey: 'documentName',
        header: constants.getManageDuplicatesLabelsByKey('Reference Modal Document'),
        minResizeWidth: 1,
    },
    {
        id: 'documentDisclosure',
        accessorKey: 'documentDisclosure',
        header: constants.getManageDuplicatesLabelsByKey('Document Disclosure'),
        minResizeWidth: 1,
    },
    {
        id: 'actualPageNumber',
        accessorKey: 'actualPageNumber',
        header: constants.getManageDuplicatesLabelsByKey('Page #'),
        minResizeWidth: 1,
    },
    {
        id: 'pageDisclosure',
        accessorKey: 'pageDisclosure',
        header: constants.getManageDuplicatesLabelsByKey('Page Disclosure'),
        minResizeWidth: 1,
    },
    {
        id: 'deleteReference',
        accessorKey: 'deleteReference',
        header: '',
        minResizeWidth: 1,
        minWidth: 25,
    },
];

export const MAX_PAGE_ITEMS = 25;

export class Reference extends React.Component <IProps, IDuplicatesReferenceState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedDocumentId: '0',
            selectedPageId: 0,
            page: 0,
        };
    }

    public componentDidMount(): void {
        this.props.fetchDocuments();
    }

    public componentDidUpdate({masterDocuments}: IProps): void {
        if (!masterDocuments.length && this.props.masterDocuments.length) {
            const id = first(this.props.masterDocuments).id;

            this.props.fetchReferencePagesById(id);
        }
    }

    public componentWillUnmount(): void {
        this.props.cleanData();
    }

    public render(): JSX.Element {
        const { selectedDocumentId } = this.state;
        const { masterDocuments, pages, duplicates } = this.props;

        const options = masterDocuments.map((item: IReferenceMaster, i: number) => {
            return {
                label: `${item.documentName} (${item.pageCount} pages, ${item.disclosureTypeName})`,
                value: `${i}`,
            };
        });

        const data = pages.map((item: IReferencePage, i: number) => {
            return {
                ...item,
                deleteReference: (item.id ? <span onClick={(): void => this.removePageReference(i)}>x</span> : ''),
            };
        });
        const duplicatesData = duplicates.map((item: IReferenceDuplicate, i: number) => {
            return {
                ...item,
                deleteReference: (<span onClick={(): void => this.removeDuplicateReference(i)}>x</span>),
            };
        });
        const duplicatesName = String(uniq(duplicates.map((item: IReferenceDuplicate) => item.documentName)));
        const getPaginationPages = (): ((pageNumber: number) => void) => {            
            if (masterDocuments.length && selectedDocumentId !== null) {
                const masterDocumentId = masterDocuments[selectedDocumentId]?.id;
                return curry(this.props.fetchReferencePageNumber)(masterDocumentId);
            }
            return () => {};
        }

        const paginatedData = (() => {
            const start = this.state.page * MAX_PAGE_ITEMS;
            const end = start + MAX_PAGE_ITEMS;
            return data.slice(start, end);
        })();

        return (
            <div className='reference'>
                <div className='reference_main'>
                    <div className='reference_master'>
                        <Typography className='heading' variant='h5'>{constants.getManageDuplicatesLabelsByKey('References Modal Master Document')}</Typography>
                        <div className='reference_master_select'>
                            <SelectMaterialBuilder
                                value={selectedDocumentId}
                                paramName={'name'}
                                label={''}
                                handleSelectChange={this.handleDocumentChange}
                                options={options}
                                variant='standard'
                            />
                        </div>
                        <Table
                            data={paginatedData}
                            columns={pagesColumns}
                            pageSize={MAX_PAGE_ITEMS}
                            showPagination={data.length > MAX_PAGE_ITEMS}
                            defaultPageSize={MAX_PAGE_ITEMS}
                            pagesCount={data.length}
                            page={this.state.page}
                            onFetchData={(page: number): void => {
                                const fetchPageNumber = getPaginationPages();
                                fetchPageNumber(page);
                                this.setState({ page });
                            }}
                            onPageChange={(page: number): void => {
                                const fetchPageNumber = getPaginationPages();
                                fetchPageNumber(page);
                                this.setState({ page });
                            }}
                            allowSettings={false}
                            title={''}
                            isDraggable={false}
                            sortable={false}
                            getTrProps={this.getTrProps}
                        />
                    </div>
                    <div className='reference_duplicates'>
                        <Typography className='heading' variant='h5'>{constants.getManageDuplicatesLabelsByKey('References Duplicates')}</Typography>
                        <Typography className='subtitle1 reference_duplicates_documents'>
                            {duplicatesName || <div>&nbsp;</div>}
                            </Typography>
                        <Table
                            data={duplicatesData}
                            columns={duplicatesColumns}
                            allowSettings={false}
                            title={''}
                            isDraggable={false}
                            sortable={false}
                        />
                    </div>
                </div>
                <div className='reference_footer'>
                    <Button
                        variant='outlined'
                        className='modal-window__buttons outlined'
                        size='small'
                        onClick={this.close}
                    >
                        {constants.getManageDuplicatesLabelsByKey('Reference Modal Close')}
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        onClick={this.removeMasterReference}
                        className='primary delete_all'
                    >
                        {constants.getManageDuplicatesLabelsByKey('Reference Modal DeleteAll')}
                    </Button>
                </div>
            </div>
        );
    }

    private handleDocumentChange = (name: string, value: string): void => {
        this.setState({selectedDocumentId: value, selectedPageId: 0});

        this.props.fetchReferencePagesById(this.props.masterDocuments[value].id);
    }

    private getTrProps = (rowInfo: Row<MyRowData>): ITrProps => {
        const { id } = rowInfo.original;
        this.setState({ selectedPageId: Number(id) });
        const duplicates = this.props.pages
            .find((item: IReferencePage) => item.id === id).duplicatePages;
        this.props.setDuplicates(duplicates);

        return {
            id,
            onClick: (): void => {
                this.setState({ selectedPageId: Number(id) });
                const duplicates = this.props.pages
                    .find((item: IReferencePage) => item.id === id).duplicatePages;
                this.props.setDuplicates(duplicates);
            },
        };
    };

    private removeMasterReference = (): void => {
        const selectedDocumentId = Number(this.state.selectedDocumentId);
        const document = this.props.masterDocuments[selectedDocumentId];
        const nextIndex = selectedDocumentId > 1? `${selectedDocumentId - 1}` : `0`;
        const nextDocument = this.props.masterDocuments[nextIndex];

        this.props.removeMasterReference(document.id);
        this.setState({selectedDocumentId: nextIndex});

        if (nextDocument && nextDocument.id && this.props.masterDocuments.length > 1) {
            this.props.fetchReferencePagesById(nextDocument.id);
        }
    }

    private removePageReference = (index: number): void => {
        const page = this.props.pages[index];
        const nextIndex = index > 1 ? index - 1 : 0;

        this.props.removePageReference(page.masterDocumentId, page.id);

        this.setState({selectedPageId: nextIndex});
    }

    private removeDuplicateReference = (index: number): void => {
        const duplicate = this.props.duplicates[index];
        const { masterDocumentId } = this.props.pages
            .find((item: IReferencePage) => item.id === this.state.selectedPageId);

        this.props.removeDuplicateReference(masterDocumentId, duplicate.masterPageId, duplicate.id);
    }

    private close = (): void => {
        this.props.handleCloseModalWindow();
    }
}

const mapStateToProps = (state: IState): IReferenceStateProps => ({
    masterDocuments: getMasterDocumentsReference(state),
    pages: getMasterDocumentsPages(state),
    duplicates: getReferenceDuplicates(state),
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<IState, IReferenceDispatchProps, AnyAction>): IReferenceDispatchProps => ({
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    fetchDocuments: (): void => {
        dispatch(fetchDocuments());
    },
    fetchReferencePagesById: (id: number): void => {
        dispatch(fetchReferencePagesById(id));
    },
    setDuplicates: (duplicates: IReferenceDuplicate[]): void => {
        dispatch(setReferenceDuplicates(duplicates));
    },
    cleanData: (): void => {
        dispatch(cleanReferenceManagementData());
    },
    removeDuplicateReference: (masterDocumentId: number, masterPageId: number, duplicateId: number): void => {
        dispatch(removeDuplicateReference(masterDocumentId, masterPageId, duplicateId));
    },
    removePageReference: (masterDocumentId: number, masterPageId: number): void => {
        dispatch(removePageReference(masterDocumentId, masterPageId));
    },
    removeMasterReference: (masterDocumentId: number): void => {
        dispatch(removeMasterReference(masterDocumentId));
    },
    fetchReferencePageNumber: (masterDocumentId: number, pageNumber: number): void => {
        dispatch(fetchReferencePageNumber(masterDocumentId, pageNumber));
    },
});

export default connect( mapStateToProps, mapDispatchToProps )(Reference);

import * as React from 'react';

export const GLOBAL_SETTINGS_TITLE = 'Global Settings';

export const BASIC_SETTINGS = 'Basic Settings';
export const DISCLOSURE_SETTINGS = 'Disclosure Settings';
export const EXEMPTION_SETTINGS = 'Exemption Settings';

export const PREFIX = 'prefix';
export const SUFFIX= 'suffix';

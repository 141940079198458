import * as React from 'react';
import { curry, isEqual } from 'lodash';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { IAppDispatchProps, IAppProps, IAppStateProps, IVariant } from './app.model';
import { connect } from 'react-redux';
import { getAnyError, getAnyNotification } from './redux/selectors/notificationHandler';
import { getExportedSuccess } from './redux/selectors/modalWindowExport';
import { removeError, removeSuccessNotification } from './redux/actions/errorHandling';
import { IState } from './redux/store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import MomentUtils from '@date-io/moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MainPanelsContainer, DuplicatePanelsContainer, AuditPanelsContainer, LocalizationPanelsContainer } from './routesContainer';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { openModalWindow } from './redux/actions/modal';
import { ALERT_DIALOG_MODAL } from './constants/messages.constants';
import { expiredSessionAlert } from './redux/actions/initialization/initialization';
import { getCursorLoading } from './redux/selectors/cursorLoading';

const UNAUTHORIZED_STATUS = 401;

class App extends React.Component<IAppProps> {

    public componentDidMount(): void {
        const { location } = window;

        if (location.href === `${location.origin}/`) {
            this.props.openSessionExpiredAlert();
        }
    }

    public componentDidUpdate(prevProps: IAppProps): void {
        if (!isEqual(prevProps.successMessage, this.props.anyNotification)) {
            this.showAnyNotification(this.props.anyNotification, this.props.removeNotification, { variant: 'success' });
        }

        if (!isEqual(prevProps.anyError, this.props.anyError)) {
            this.showAnyNotification(this.props.anyError, this.props.removeError);
        }
    }

    public render(): JSX.Element  {
        return (
            <div className={this.props.cursorLoading ? 'cursor-loading' : ''}>
                <LocalizationProvider >
                    <BrowserRouter>
                        <Switch>
                            <Route exact={true} path='/' component={MainPanelsContainer} />
                            <Route exact={true} path='/:folderRsn' component={MainPanelsContainer} />
                            <Route path='/:folderRsn/duplicates' component={DuplicatePanelsContainer}/>
                            <Route path='/:folderRsn/audit' component={AuditPanelsContainer}/>
                            <Route path='/:folderRsn/localization' component={LocalizationPanelsContainer}/>
                        </Switch>
                    </BrowserRouter>
                </LocalizationProvider>
            </div>
        );
    }

    private showAnyNotification = <T extends object>(
        someNotification: T[],
        removeAction: (id: string) => void,
        variant: { variant?: IVariant } = {},
    ): void => {
        someNotification
            .filter((notification: T) => !!notification)
            .forEach((notification: T) => {
                /* tslint:disable:no-string-literal */
                if ((notification as any).status === UNAUTHORIZED_STATUS) {
                    this.props.openSessionExpiredAlert();

                    return;
                }

                this.props.enqueueSnackbar(notification['message'], variant);
                removeAction(notification['id']);
                /* tslint:enable:no-string-literal */
            });
    }
}

const mapStateToProps = (state: IState): IAppStateProps => ({
    anyError: getAnyError(state),
    anyNotification: getAnyNotification(state),
    successMessage: getExportedSuccess(state),
    cursorLoading: getCursorLoading(state),
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<IState, IAppDispatchProps, AnyAction>): IAppDispatchProps  => ({
        removeError: (id: string): void => {
            dispatch(removeError(id));
        },
        removeNotification: (id: string): void => {
            dispatch(removeSuccessNotification(id));
        },
        openSessionExpiredAlert: (): void => {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, expiredSessionAlert));
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(App);

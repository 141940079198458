import { REDACTION_ADMIN, ADD, EDIT, DELETE, permissionsProperty } from './common.constants';
import { formatPermArrayToObject } from '../utils/app.util';

export interface IModalPermissionElement {
    [name: string]: string;
}

export const ANNOTATION_TYPE_MANAGEMENT = 'ANNOTATION_TYPE_MANAGEMENT';
export const ASSOCIATE_WATERMARK_TO_FOLDER = 'ASSOCIATE_WATERMARK_TO_FOLDER_TYPE';
export const DISCLOSURE_MANAGEMENT = 'DISCLOSURE_MANAGEMENT';
export const GLOBAL_SETTINGS = 'GLOBAL_SETTINGS';
export const PACKAGE_PRESETS = 'PACKAGE_PRESETS';
export const USER_DEFINED_STAMP_MANAGEMENT = 'USER_DEFINED_STAMP_MANAGEMENT';
export const WATERMARK_MANAGEMENT = 'WATERMARK_MANAGEMENT';
export const DOCUMENT_INDEX = 'DOCUMENT_INDEX';
export const ENCRYPTION = 'ENCRYPTION';
export const REQUEST_SECURITY = 'REQUEST_SECURITY';
export const REPLACE_DISCLOSURE = 'REPLACE_DISCLOSURE';
export const INDEX_METADATA = 'index_metadata';
export const DUPLICATE_REFERENCES = 'duplicate_references';
export const REFERENCES = 'References';
export const RELEASE_DATE = 'Release Date';
export const SELECT_ANNOTATION_OBJECTS = 'Select Annotation Objects';
export const OVERLAYED_SHAPES = 'Overlayed Shapes';
export const IS_SELECT_OVERLAYED = 'isSelectOverlayed';
export const SEVER_INFORMATION = 'Sever Information';
export const SEVER_TEXT = 'Sever Text';
export const RECOMMENDATIONS = 'Recommendations';
export const STAMP_ASSOCIATIONS = 'Stamp Associations';
export const COMMENT = 'Comments';

export default {
    [ANNOTATION_TYPE_MANAGEMENT]: formatPermArrayToObject(
        permissionsProperty,
        REDACTION_ADMIN,
        ANNOTATION_TYPE_MANAGEMENT,
    ),
    [DISCLOSURE_MANAGEMENT]: formatPermArrayToObject(permissionsProperty, REDACTION_ADMIN, DISCLOSURE_MANAGEMENT),
    [PACKAGE_PRESETS]: formatPermArrayToObject(permissionsProperty, REDACTION_ADMIN, PACKAGE_PRESETS),
    [USER_DEFINED_STAMP_MANAGEMENT]: formatPermArrayToObject(
        permissionsProperty,
        REDACTION_ADMIN,
        USER_DEFINED_STAMP_MANAGEMENT,
    ),
    [WATERMARK_MANAGEMENT]: formatPermArrayToObject(permissionsProperty, REDACTION_ADMIN, WATERMARK_MANAGEMENT),
    // not standard permission from Amanda
    [GLOBAL_SETTINGS]: {
        [ADD]: `${REDACTION_ADMIN}_${GLOBAL_SETTINGS}_${EDIT}`,
        [EDIT]: `${REDACTION_ADMIN}_${GLOBAL_SETTINGS}_${EDIT}`,
        [DELETE]: `${REDACTION_ADMIN}_${GLOBAL_SETTINGS}_${EDIT}`,
    },
    [DOCUMENT_INDEX]: {
        [ADD]: `${REDACTION_ADMIN}_${DOCUMENT_INDEX}_${EDIT}`,
        [EDIT]: `${REDACTION_ADMIN}_${DOCUMENT_INDEX}_${EDIT}`,
        [DELETE]: `${REDACTION_ADMIN}_${DOCUMENT_INDEX}_${EDIT}`,
    },

};

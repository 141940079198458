import * as React from 'react';
import './fileImportErrors.styles.scss';
import { first } from 'lodash';
import { IItem } from './fileImportErrors.model';

const FileImportErrors = (errors: IItem[]): JSX.Element => (
  <React.Fragment>
    {
      errors.map((item: IItem) => {
        if (!item.filesNames.length) {
          return null;
        }

        return <div key={item.title}>
          <strong>{item.title}</strong>
          <ul className={'fileErrors-list'}>
            {item.filesNames.map((i: string): JSX.Element => (
              <li
                key={i}
                className={'fileErrors-list-item'}
              >
                {i}
              </li>
            ))}
          </ul>
        </div>;
      })
    }
  </React.Fragment>
);

export default FileImportErrors;

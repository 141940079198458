import { AnyAction } from 'redux';
import { uniq, difference } from 'lodash';
import {
    GET_CURRENT_DOCUMENT_PENDING,
    GET_CURRENT_DOCUMENT_SUCCESS,
    GET_CURRENT_DOCUMENT_FAILURE,
    SET_DOCUMENT_TO_SELECT_LIST,
    SET_PAGE_TO_SELECT_LIST,
    GET_CURRENT_PAGE_SUCCESS,
    CHANGE_PAGE_ORDER_PENDING,
    CHANGE_PAGE_ORDER_SUCCESS,
    CHANGE_PAGE_ORDER_FAILURE,
    DELETE_PAGE_PENDING,
    DELETE_PAGE_SUCCESS,
    DELETE_PAGE_FAILURE,
    UNDO_PAGE_DISCLOSURE_PENDING,
    UNDO_PAGE_DISCLOSURE_SUCCESS,
    UNDO_PAGE_DISCLOSURE_FAILURE,
    GET_PAGE_IN_DOCUMENT_PENDING,
    GET_PAGE_IN_DOCUMENT_SUCCESS,
    GET_PAGE_IN_DOCUMENT_FAILURE,
    CLEAR_CURRENT_DOCUMENT_STATE,
    CHANGE_PAGE_EXEMPTIONS_SUCCESS,
    REMOVE_PAGE_EXEMPTIONS_SUCCESS,
    UNDO_PAGINATION_PAGE,
    UPDATE_PAGINATION,
    SET_DISCLOSURE_PAGE,
    OCR_PAGES_PENDING,
    OCR_PAGES_SUCCESS,
    OCR_PAGES_FAILURE,
    SET_ROTATE_IN_DOCUMENT_LIST,
    UNSET_CURRENT_DOCUMENT,
    SET_ALL_DOCUMENTS_TO_SELECT_LIST,
    SET_ALL_PAGES_TO_SELECT_LIST,
    CLEAR_CURRENT_PAGE_STATE,
    GET_EXEMPTION_CURRENT_DOCUMENT_SUCCESS,
    GET_EXEMPTION_CURRENT_DOCUMENT_PENDING,
    GET_EXEMPTION_CURRENT_DOCUMENT_FAILURE,
    UPDATE_DISCLOSURES,
    SET_PAGE_LIST_SUCCESS,
    SET_PAGE_LIST_PENDING, SET_PAGE_NUMBER, SET_SORT_PARAM,
    SET_PAGE_INITIAL_STATE,
    CHANGE_PAGES_DISCLOSURE_PENDING,
    SET_PAGE_LIST_COLUMN_HEADER_PENDING,
    SET_PAGE_LIST_COLUMN_HEADER_LIST_SUCCESS,
    SET_PAGE_LIST_COLUMN_HEADER_FAILURE,
    MODIFY_PAGE_LIST_COLUMN_HEADER_PENDING,
    MODIFY_PAGE_LIST_COLUMN_HEADER_SUCCESS,
    MODIFY_PAGE_LIST_COLUMN_HEADER_FAILURE,
    RESET_PAGE_LIST_COLUMN_HEADER_FAILURE,
    RESET_PAGE_LIST_COLUMN_HEADER_SUCCESS,
    RESET_PAGE_LIST_COLUMN_HEADER_PENDING,
    SET_PAGE_LIST_TABLE_COLUMN,
    EDIT_DOCUMENT_FILE_NAME_PENDING,
    EDIT_DOCUMENT_FILE_NAME_SUCCESS,
    EDIT_DOCUMENT_FILE_NAME_FAILURE,
    UPDATE_CURRENT_DOCUMENT_EDITED_FILE_NAME,
    HAS_LINKED_ARTICLES_SUCCESS,
    SET_FIRST_PAGE,
    GET_CURRENT_PAGE_COMMENTS_SUCCESS,
    SET_PAGE_COUNT_AFTER_DELETION,
    GET_CURRENT_PAGE_HYPER_LINKS_SUCCESS,
    SET_SELECTED_COMMENT,
    SET_COMMENT_LOADING_FLAG,
    SET_GOTO_PAGE_PENDING,
    SET_CURRENT_FIRST_PAGE_ID,
    SET_CURRENT_LAST_PAGE_ID,
    GET_CURRENT_PAGE_COMMENTS_FAILURE,
} from './constant';
import { PageListState, IPages } from './pageList.model';
import { updateArrayWithArrayByKey } from '../../../utils/array.utils';
import { ID } from '../../../constants/disclosure.contstants';
import { getUniqSelectedItems } from '../../../utils/documentList.util';
import { IAnnotationUpdate } from '../annotationTypes/annotationTypes.model';

export const initialState: PageListState = {
    currentDocument: null,
    selectedDocuments: [],
    currentPage: null,
    selectedPages: [],
    loading: false,
    loaded: false,
    loadingCurrentDoc: false,
    loadedCurrentDoc: false,
    loadingDeleting: false,
    loadingUpdateDisclosure: false,
    loadingPageOrdering: false,
    loadingOcr: [],
    error: null,
    exemptionsCurrentDocument: [],
    currentPageNumber: 0,
    currentPageSort: null,
    pagesDisclosureLoading: [],
    pageListColumnHeaderPending: false,
    pageListColumnHeader: [],
    modifyPageListTableColumnLoading: false,
    resetPageListColumnHeaderPending: false,
    hasLinkedArticles: null,
    firstPageId: null,
    currentPageComments: null,
    currentPageHyperLinks: null,
    commentLoadingFlag:false,
    gotoPagePending: false,
    currentFirstPageId: null,
    currentLastPageId: null,
};

export const documentListReducer = (state: PageListState = initialState, { type, payload }: AnyAction):
    PageListState => {
    switch (type) {
        case GET_CURRENT_DOCUMENT_PENDING:
            return {
                ...state,
                loadingCurrentDoc: true,
                loadedCurrentDoc: false,
            };
        case GET_CURRENT_DOCUMENT_SUCCESS:
            return {
                ...state,
                currentDocument: payload,
                selectedDocuments: [payload.id],
                loadingCurrentDoc: false,
                loadedCurrentDoc: true,
            };
        case GET_CURRENT_DOCUMENT_FAILURE:
            return {
                ...state,
                loadingCurrentDoc: false,
                loadedCurrentDoc: false,
            };
        case UNSET_CURRENT_DOCUMENT:
            return {
                ...state,
                currentDocument: null,
                selectedDocuments: [],
                currentPage: null,
                selectedPages: [],
                currentPageNumber: 0,
            };
        case SET_DOCUMENT_TO_SELECT_LIST:
            return {
                ...state,
                selectedDocuments: getUniqSelectedItems(payload.id, payload.isMultiple, state.selectedDocuments),
            };
        case SET_ALL_DOCUMENTS_TO_SELECT_LIST:
            return {
                ...state,
                selectedDocuments: payload,
            };
        case SET_PAGE_TO_SELECT_LIST:
            return {
                ...state,
                selectedPages: getUniqSelectedItems(payload.id, payload.isMultiple, state.selectedPages),
            };
        case SET_ALL_PAGES_TO_SELECT_LIST:
            return {
                ...state,
                selectedPages: payload,
            };
        case SET_PAGE_LIST_SUCCESS: {
            return {
                ...state,
                loadingPageOrdering: false,
                currentDocument: {
                    ...state.currentDocument,
                    pages: payload,
                },
                pagesDisclosureLoading: [],
            };
        }
        case CHANGE_PAGE_EXEMPTIONS_SUCCESS: {
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages: state.currentDocument.pages.map((page: IPages): IPages => page.id === payload.id ? {
                        ...page,
                        exemptionIds: uniq([...page.exemptionIds, ...payload.stampsIds]),
                    } : page,
                    ),
                },
            };
        }
        case REMOVE_PAGE_EXEMPTIONS_SUCCESS: {
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages: state.currentDocument.pages.map((page: IPages): IPages => page.id === payload.id ? {
                        ...page,
                        exemptionIds: difference(page.exemptionIds, payload.stampsIds),
                    } : page,
                    ),
                },
            };
        }
        case UPDATE_PAGINATION: {
            const newCurrentPage = payload.pages.find(({ id }: IPages): boolean => id === state.currentPage.id);

            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages:
                        updateArrayWithArrayByKey<IPages, keyof IPages>(state.currentDocument.pages, payload.pages, ID),
                    paginationRange: payload.paginationRange,
                },
                currentPage: {
                    ...state.currentPage,
                    paginationIndex: newCurrentPage.paginationIndex,
                },
            };
        }
        case CLEAR_CURRENT_DOCUMENT_STATE:
            return {
                ...state,
                currentDocument: null,
                selectedDocuments: [],
            };
        case CLEAR_CURRENT_PAGE_STATE:
            return {
                ...state,
                currentPage: null,
            };
        case GET_CURRENT_PAGE_SUCCESS:
            return {
                ...state,
                currentPage: { ...state.currentDocument.pages.filter((page: IPages) => page.id === payload)[0] },
                selectedPages: [payload],
            };
        case CHANGE_PAGE_ORDER_PENDING:
        case SET_PAGE_LIST_PENDING:
        case DELETE_PAGE_PENDING:
            return {
                ...state,
                loadingPageOrdering: true,
            };
        case CHANGE_PAGE_ORDER_SUCCESS:
            const { currentPage, pages } = payload;
            const changedCurrentPage = pages.length && pages.find((page: IPages) => page.id === currentPage.id);

            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages,
                },
                currentPage: changedCurrentPage ? changedCurrentPage : currentPage,
                loadingPageOrdering: false,
            };
        case DELETE_PAGE_FAILURE:
        case CHANGE_PAGE_ORDER_FAILURE:
            return {
                ...state,
                loadingCurrentDoc: false,
                loadingPageOrdering: false,
            };
        case DELETE_PAGE_SUCCESS:
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages: state.currentDocument.pages.filter(
                        (page: IPages) => !payload.includes(page.id),
                    ),
                },
                loadingCurrentDoc: false,
                loadingPageOrdering: false,
            };
        case UNDO_PAGE_DISCLOSURE_PENDING:
        case GET_PAGE_IN_DOCUMENT_PENDING: {
            return {
                ...state,
                loadingUpdateDisclosure: true,
                error: null,
            };
        }
        case UNDO_PAGE_DISCLOSURE_SUCCESS: {
            return {
                ...state,
                loadingUpdateDisclosure: false,
            };
        }
        case SET_DISCLOSURE_PAGE: {
            const { pageId, disclosureId } = payload;

            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages: state.currentDocument && state.currentDocument.pages &&
                        state.currentDocument.pages.map((page: IPages) => ({
                        ...page,
                        disclosureTypeId: pageId === page.id ? disclosureId : page.disclosureTypeId,
                    }
                    )),
                },
            };
        }
        case UNDO_PAGE_DISCLOSURE_FAILURE:
        case GET_PAGE_IN_DOCUMENT_FAILURE:
            return {
                ...state,
                loadingUpdateDisclosure: false,
                error: payload,
            };
        case GET_PAGE_IN_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loadingUpdateDisclosure: false,
                currentDocument: {
                    ...state.currentDocument,
                    pages: updateArrayWithArrayByKey<IPages, keyof IPages>(state.currentDocument.pages, [payload], ID),
                },
                currentPage: state.currentPage.id === payload.id ? payload : { ...state.currentPage },
            };
        }
        case UNDO_PAGINATION_PAGE:
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages: state.currentDocument.pages.map((page: IPages): IPages => {
                        return {
                            ...page,
                            paginationIndex: 0,
                        };
                    }),
                },
            };
        case OCR_PAGES_PENDING:
            return {
                ...state,
                loadingOcr: [...payload],
            };
        case OCR_PAGES_SUCCESS:
        case OCR_PAGES_FAILURE:
            return {
                ...state,
                loadingOcr: [],
            };
        case SET_ROTATE_IN_DOCUMENT_LIST:
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages: state.currentDocument.pages.map((page: IPages): IPages => page.id === payload.id ? ({
                        ...page,
                        rotation: payload.rotation,
                    }) : page),
                },
            };
        case GET_EXEMPTION_CURRENT_DOCUMENT_SUCCESS:
            return {
                ...state,
                exemptionsCurrentDocument: payload,
            };
        case GET_EXEMPTION_CURRENT_DOCUMENT_PENDING:
        case GET_EXEMPTION_CURRENT_DOCUMENT_FAILURE:
            return {
                ...state,
                exemptionsCurrentDocument: [],
            };
        case UPDATE_DISCLOSURES:
            const { expandedAnnotations } = payload;

            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pages: state.currentDocument.pages.map((page: IPages) => {
                        const disclosureId = expandedAnnotations.find((
                            foundPage: IAnnotationUpdate) => foundPage.pageId === page.id,
                        );

                        return {
                            ...page,
                            disclosureTypeId: disclosureId && disclosureId.pageDisclosureTypeId,
                        };
                    }),
                },
            };
        case SET_PAGE_NUMBER:
            return {
                ...state,
                currentPageNumber: payload,
            };
        case SET_SORT_PARAM:
            return {
                ...state,
                currentPageSort: payload,
            };
        case CHANGE_PAGES_DISCLOSURE_PENDING:
            return {
                ...state,
                pagesDisclosureLoading: [...payload],
            };
        case SET_PAGE_INITIAL_STATE:
            return {
                ...initialState,
                ...(state.pageListColumnHeader.length && { pageListColumnHeader: state.pageListColumnHeader,
                    currentPageSort: state.currentPageSort }),
            };
        case SET_PAGE_LIST_TABLE_COLUMN:
            return {
                ...state,
                pageListColumnHeader: payload,
            };
        case SET_PAGE_LIST_COLUMN_HEADER_PENDING:
            return {
                ...state,
                pageListColumnHeaderPending: true,
            };
        case SET_PAGE_LIST_COLUMN_HEADER_LIST_SUCCESS:
            return {
                ...state,
                pageListColumnHeader: payload,
                pageListColumnHeaderPending: false,
            };
        case SET_PAGE_LIST_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                pageListColumnHeader: [],
                pageListColumnHeaderPending: false,
                error: payload,
            };
        case MODIFY_PAGE_LIST_COLUMN_HEADER_PENDING:
            return {
                ...state,
                modifyPageListTableColumnLoading: true,
            };
        case MODIFY_PAGE_LIST_COLUMN_HEADER_SUCCESS:
            return {
                ...state,
                pageListColumnHeader: payload,
                resetPageListColumnHeaderPending: false,
            };
        case MODIFY_PAGE_LIST_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                resetPageListColumnHeaderPending: false,
                error: payload,
            };
        case RESET_PAGE_LIST_COLUMN_HEADER_PENDING:
            return {
                ...state,
                resetPageListColumnHeaderPending: true,
            };
        case RESET_PAGE_LIST_COLUMN_HEADER_SUCCESS:
            return {
                ...state,
                pageListColumnHeader: payload,
                resetPageListColumnHeaderPending: false,
            };
        case RESET_PAGE_LIST_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                resetPageListColumnHeaderPending: false,
                error: payload,
            };
        case EDIT_DOCUMENT_FILE_NAME_PENDING:
        case EDIT_DOCUMENT_FILE_NAME_SUCCESS:
            return {
                ...state,
            };
        case EDIT_DOCUMENT_FILE_NAME_FAILURE:
            return {
                ...state,
            };
        case UPDATE_CURRENT_DOCUMENT_EDITED_FILE_NAME:
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    originalName: payload,
                },
            };
        case HAS_LINKED_ARTICLES_SUCCESS:
            return {
                ...state,
                hasLinkedArticles: payload,
            };
        case SET_FIRST_PAGE:
            return {
                ...state,
                firstPageId: payload,
            };
        case GET_CURRENT_PAGE_COMMENTS_SUCCESS:
            return {
                ...state,
                currentPageComments: payload,
            };
        case GET_CURRENT_PAGE_COMMENTS_FAILURE:
            return {
                ...state,
                currentPageComments: [],
            };
        case SET_PAGE_COUNT_AFTER_DELETION:
            return {
                ...state,
                currentDocument: {
                    ...state.currentDocument,
                    pageCount: state && state.currentDocument && state.currentDocument.pageCount - payload,
                },
            };
        case GET_CURRENT_PAGE_HYPER_LINKS_SUCCESS:
            return {
                ...state,
                currentPageHyperLinks: payload,
            };
        case SET_SELECTED_COMMENT:
            return {
                ...state,
                currentPage: {
                    ...state.currentPage,
                    selectedComment: payload,
                },
            };
        case SET_COMMENT_LOADING_FLAG:
            return {
                ...state,
                commentLoadingFlag: payload,
            };
        case SET_GOTO_PAGE_PENDING:
            return{
                ...state,
                gotoPagePending: payload,
            };
        case SET_CURRENT_FIRST_PAGE_ID:
            return{
                ...state,
                currentFirstPageId: payload,
            };
        case SET_CURRENT_LAST_PAGE_ID:
            return{
                ...state,
                currentLastPageId: payload,
            };
        default:
            return state;
    }
};

export default documentListReducer;

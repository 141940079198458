if (!String.prototype.repeat) {
    String.prototype.repeat = function(count: number): string {
        if (this == null) {
            throw new TypeError(`can\'t convert ${this} to object`);
        }

        const str = '' + this;

        /* tslint:disable: no-parameter-reassignment */
        count = +count;
        /* tslint:enable */

        if (count !== count) {
            /* tslint:disable: no-parameter-reassignment */
            count = 0;
            /* tslint:enable */
        }

        if (count < 0) {

            throw new RangeError('repeat count must be non-negative');
        }

        if (count === Infinity) {

            throw new RangeError('repeat count must be less than infinity');
        }

        /* tslint:disable: no-parameter-reassignment */
        count = Math.floor(count);
        /* tslint:enable */

        if (str.length === 0 || count === 0) {

            return '';
        }

        /* tslint:disable: no-bitwise */
        if (str.length * count >= 1 << 28) {
        /* tslint:enable */

            throw new RangeError('repeat count must not overflow maximum string size');
        }

        let rpt = '';

        for (let i = 0; i < count; i++) {
            rpt += str;
        }

        return rpt;
    };
}

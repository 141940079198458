export const EXPORT_OPTIONS = 'Export Options';
export const PACKAGE_PRESETS_MODAL = 'packagePresets';

export const EXISTING_PRESET = 'Existing Presets';
export const PRESET_NAME = 'Name';
export const PRESET_NAME_2 = 'Name fr';
export const PRESET_NAME_PARAM = 'name';
export const PRESET_NAME_2_PARAM = 'name2';

export const ADD = 'Add';
export const REMOVE = 'Remove';

export const NO_UNIQUE_ERROR = 'Package name is already in use.';
export const MAX_LENGTH_ERROR ='Max length 255';

import { createSelector } from 'reselect';
import { IState } from '../store';
import {
    ITaskListReducer,
    ITaskList,
    ITaskTableList,
    ITaskListMetaData,
} from '../reducers/taskQueue/taskQueue.model';

export const getTaskQueue = (state: IState): ITaskListReducer => state.taskQueueList;

export const getTaskQueueLoading = createSelector(
    [getTaskQueue],
    (taskQueue: ITaskListReducer): boolean => taskQueue.loading,
);

export const getTaskQueueLoaded = createSelector(
    [getTaskQueue],
    (taskQueue: ITaskListReducer): boolean => taskQueue.loadedDocumentsTask,
);

export const getTaskQueueList = createSelector(
    [getTaskQueue],
    (taskQueue: ITaskListReducer): ITaskList[] => taskQueue.taskList,
);

export const getLastProcessedDocumentsList = createSelector(
    [getTaskQueue],
    (taskQueue: ITaskListReducer): ITaskList[] => taskQueue.lastProcessedDocuments,
);

export const getTaskQueueTableList = createSelector(
    [getTaskQueueList],
    (taskQueueList: ITaskList[]): ITaskTableList[] => taskQueueList && taskQueueList.map((taskQueue: ITaskList) => ({
        name: taskQueue.name,
        status: taskQueue.status,
        documentId: taskQueue.documentId,
        id: taskQueue.id,
        isFinished: taskQueue.isFinished,
        isCompleted: taskQueue.isCompleted,
        errorMessage: taskQueue.errorMessage,
    })),
);

export const getTaskQueueMetaData = createSelector(
    [getTaskQueue],
    (taskQueue: ITaskListReducer): ITaskListMetaData => ({
        totalPages: taskQueue.totalPages,
        currentPage: taskQueue.currentPage,
        totalTaskCount: taskQueue.totalTaskCount,
        showFinishedTasks: taskQueue.showFinishedTasks,
    }),
);

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { AUTH } from '../redux/reducers/localStorage/constant';
import { getItem } from '../utils/localStorage.util';
import { X_AUTH, CACHE_CONTROL, X_AUTH_LOWER_CASE } from '../constants/api-headers.constants';

export interface IResponseHeaders {
    [CACHE_CONTROL]: string;
    [CACHE_CONTROL]: string;
    expires: string;
    pragma: string;
    [X_AUTH_LOWER_CASE]?: string;
}

export interface IRequestLoginResponse {
    data: null;
    headers: IResponseHeaders;
}

const REQUEST_POST = 'POST';
const REQUEST_GET = 'GET';
const REQUEST_PUT = 'PUT';
const REQUEST_DELETE = 'DELETE';
const REQUEST_PATCH = 'PATCH';
const RESPONSE_JSON = 'json';
const RESPONSE_BLOB = 'blob';
const RESPONSE_ARRAY_BUFFER = 'arraybuffer';

interface ISimpleError {
    status: number;
}

class BaseAPI {
    constructor(private readonly axiosInstance: AxiosInstance) {}

    public get<T, D>(
        url: string,
        data?: D,
        isFormData?: boolean,
        isFormDataResponse?: boolean,
        params?: any,
        sendHeaders?: any,
        isArrayBuffer?: boolean,
    ): Promise<T> {
        return this.request<T, D>(
            REQUEST_GET,
            url,
            data,
            isFormData,
            isFormDataResponse,
            params,
            false,
            sendHeaders, false,
            isArrayBuffer,
        );
    }

    public post<T, D>(url: string,
                      data: D,
                      isFormData?: boolean,
                      isFormDataResponse?: boolean,
                      params?: any,
                      getResponseHeaders?: boolean,
                      sendHeaders?: any,
                      noAuth?: boolean,
    ): Promise<T> {
        return this.request<T, D>(
            REQUEST_POST,
            url,
            data,
            isFormData,
            isFormDataResponse,
            params,
            getResponseHeaders,
            sendHeaders,
            noAuth,
        );
    }

    public put<T, D>(url: string,
                     data?: D,
                     params?: any,
                     isFormData?: boolean,
                     isFormDataResponse?: boolean,
    ): Promise<T> {
        return this.request<T, D>(REQUEST_PUT, url, data, isFormData, isFormDataResponse, params);
    }

    public patch<T, D>(url: string, params?: any, data?: D): Promise<T> {
        return this.request<T, D>(REQUEST_PATCH, url, data, null, false, params);
    }

    public delete<T, D>(url: string, data?: D): Promise<T> {
        return this.request<T, D>(REQUEST_DELETE, url, data);
    }

    private request<T, D>(
        method: any,
        url: string,
        sendData?: D,
        isFormData?: boolean,
        isFormDataResponse?: boolean,
        params?: any,
        getResponseHeaders?: boolean,
        sendHeaders?: any,
        noAuth?: boolean,
        isArrayBuffer?: boolean,
    ): Promise<T> {

        const options: AxiosRequestConfig = {
            method,
            url,
            responseType: isFormData ? RESPONSE_BLOB : RESPONSE_JSON,
        };

        if(isArrayBuffer) {
            options.responseType = RESPONSE_ARRAY_BUFFER;
        }

        if (sendData) {
            if (method === REQUEST_GET) {
                if (!(sendData as {token?: string}).token) {
                    options.params = sendData;
                }

                if (params) {
                    options.headers = params;
                }
            } else if (isFormData) {
                options.data = sendData;
            } else {
                if (isFormDataResponse) {
                    options.data = sendData;
                } else {
                    options.data = JSON.stringify(sendData);
                }

                options.headers = {
                    'Content-Type': 'application/json',
                };
            }
        }

        if (params) {
            options.params = params;
        }

        if(!noAuth && getItem(AUTH)) {
            options.headers = { ...options.headers, [X_AUTH]: getItem(AUTH) };
        }

        options.headers = { ...sendHeaders, ...options.headers };

        return new Promise<T>(async (resolve: (response: T) => void,
                                     reject: (error: AxiosError | ISimpleError) => void): Promise<void> => {
            try {
                const response = await this.axiosInstance(options);
                const { headers, data } = response;

                resolve(getResponseHeaders ? { data, headers } : data);
            } catch (error) {
                // Made only for RESPONSE_BLOB, other way we get blob object as error
                if (error.request.responseType === RESPONSE_BLOB) {
                    const resText = await new Promise((onResolve: (response: string | ArrayBuffer) => void,
                                                       onReject: () => void): void => {
                        const reader = new FileReader();

                        reader.addEventListener('abort', onReject);
                        reader.addEventListener('error', onReject);
                        reader.addEventListener('loadend', () => {
                            onResolve(reader.result);
                        });
                        reader.readAsText(error.response.data);
                    });

                    reject(JSON.parse(resText as string));
                }

                if (error.response.data) {
                    reject(error.response.data);
                } else {
                    reject({status: error.response.status});
                }
            }
        });

    }
}

export default BaseAPI;

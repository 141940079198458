import * as React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { IDuplicateTableHeader, ITableProps, ITableState } from './table.model';
import { SettingsTable } from './SettingsTable/SettingsTable';
import './table.styles.scss';
import * as constants from '../../../constants/duplicates.constants';
import { REDACTION_LANG, MODIFIED_LABELS } from '../../../redux/reducers/localStorage/constant';
import { getSessionItem, getItem } from '../../../utils/localStorage.util';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

class ResizibleSortableTable<D extends object> extends React.Component<Partial<ITableProps<D>>, ITableState<D>> {
    constructor(props: ITableProps<D>) {
        super(props);
        this.state = {
            columnsGroup: props.columnsGroup,
            columns: this.getColumnsFromColumnsGroup(props.columnsGroup)
        };
    }

    componentDidMount(): void {
        const columns = this.getColumnsFromColumnsGroup(this.props.columnsGroup);
        this.setState({ columns });
    }

    public getColumnsFromColumnsGroup = (columnsGroup: IDuplicateTableHeader[]): any => {
        return columnsGroup.reduce((acc: any, headerColumn: IDuplicateTableHeader) =>
            [...acc, ...headerColumn.list.filter(item => item.show).map(item => ({
                Header: item.header,
                header: item.header,
                accessor: item.accessorKey as keyof D,
                id: item.id,
                accessorKey : item.accessorKey
            }))]
        , []);
    }

    public setColumns = (columns:any): void => {
        this.setState({ columns });
    }

    public setColumnsGroup = (columnsGroup: IDuplicateTableHeader[]): void => {
        const columns = this.getColumnsFromColumnsGroup(columnsGroup);
        this.setState({ columnsGroup, columns });
    }

    public resetColumns = (): void => {
        const columns = this.getColumnsFromColumnsGroup(this.props.columnsGroup);
        this.setState({ columnsGroup: this.props.columnsGroup, columns });
    }

    public handleShowAll = (): void => {
        const columnsGroup = this.props.columnsGroup.map(group => ({
            ...group,
            list: group.list.map(column => ({
                ...column,
                show : true
            }))
        }));
        const columns = this.getColumnsFromColumnsGroup(columnsGroup);
        this.setState({ columnsGroup, columns });
    };
    
    public handleHideAll = (): void => {
        const columnsGroup = this.props.columnsGroup.map(group => ({
            ...group,
            list: group.list.map(column => ({
                ...column,
                show: column.alwaysShow ? true : false
            }))
        }));
        const columns = this.getColumnsFromColumnsGroup(columnsGroup);
        this.setState({ columnsGroup, columns });
    };

    public render(): JSX.Element {
        const {
            data,
            getTrProps,
        } = this.props;

        const { columnsGroup, columns } = this.state;
        const redactionLang = getSessionItem(REDACTION_LANG);

        const handleRowClick = (row: any) => {
            getTrProps(row);
        };

        return (
            <div className={'react_tableDuplicate'}>
                <div className='material-react-table'>
                    <MaterialReactTable
                        data={data}
                        columns={columns}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () => handleRowClick(row),
                        })}
                        enableStickyHeader={true}
                        enableStickyFooter={true}
                        enablePagination={false}
                        enableColumnActions={false}
                        enableGlobalFilter={false}
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableColumnResizing={true}
                        enableFullScreenToggle={false}
                        enableSortingRemoval={false}
                        sortDescFirst={false}
                        columnResizeMode='onEnd'
                        layoutMode='grid'
                        muiFilterTextFieldProps={{sx:{minWidth: "80px"}}}
                        muiTableContainerProps={{sx:{height:'100%'}}}
                        localization={redactionLang == 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                        muiTablePaperProps= {{sx:{display: 'flex', flexDirection: 'column', height: '100%'}}}
                        defaultColumn={{
                            minSize: 20,
                            maxSize: 9001,
                            size: 100,
                        }}
                        renderTopToolbarCustomActions={() => (
                            <div className='react_table-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <div>
                                    <h3>{constants.getManageDuplicatesLabelsByKey('Duplicates')}</h3>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <SettingsTable
                                        columnsGroup={columnsGroup}
                                        setColumns={this.setColumns}
                                        resetColumns={this.resetColumns}
                                        setColumnsGroup={this.setColumnsGroup}
                                        handleShowAll={this.handleShowAll}
                                        handleHideAll={this.handleHideAll}
                                    />

                                </div>
                            </div>
                        )}
                        muiTopToolbarProps={() => {
                            return {
                                sx: {
                                    "& .MuiSvgIcon-root": {
                                        fontSize: "1.2rem !important"
                                    },
                                }
                            }
                        }}
                        muiTableHeadCellProps={(props) => {
                            const sortDir = props.column.getIsSorted();
                            if (typeof sortDir === 'string') {
                                return {
                                    sx: {
                                        backgroundColor: "#f2f2f2",
                                        color: "#8492a6",
                                        boxShadow: sortDir == 'asc' ? 'inset 0 3px 0 0 rgba(0, 0, 0, 0.6);' : 'inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);',
                                        "& .MuiBadge-root": {
                                            display: "none !important",
                                        },
                                    },
                                };
                            }
                            return {
                                sx: {
                                    backgroundColor: "#f2f2f2",
                                    color: "#8492a6",
                                    "& .MuiBadge-root": {
                                        display: "none !important",
                                    },
                                }
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default ResizibleSortableTable;
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from '../../../../node_modules/react-redux';
import { SelectMaterialBuilder, RadioMaterialBuilder, CheckboxMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import TextField from '@mui/material/TextField';
import './modalRequestSearch.scss';
import Table from '../../../components/table/Table';
import { fetchRequestSearch, clearSearchResult } from '../../../redux/actions/modalRequestSearch';
import { getRequestSearchFolders } from '../../../redux/actions/initialization/initialization';
import {
    getRequestResultSearchLoading,
    getTableRequestResultSearch,
    getOfficers,
} from '../../../redux/selectors/modalRequestSearch';
import { ITrProps, MyRowData } from '../../../components/table/table.model';
import { get } from 'lodash';
import Button from '@mui/material/Button';
import { CLOSE, NO_OFFICER_SELECTED, EXACT_MATCH } from '../../../constants/common.constants';
import { tableColumns } from '../../../constants/modalRequestSearch.constants';
import { ChangeEvent } from 'react';
import { getFoiFoldersOptions } from '../../../redux/selectors/modalSearchDocument';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { IOfficerRequest, IRequestSearchParams } from '../../../redux/reducers/modalRequestSearch/requestSearch.model';
import { ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import './modalRequestSearch.scss';
import { b64EncodeUnicode } from '../../../utils/encode.util';
import { setPageInitialState, clearCurrentDocumentState } from '../../../redux/actions/pageList';
import { setDocumentInitialState } from '../../../redux/actions/documentList';
import * as constants from '../../../constants/export.contstants';
import { getRedactionFolderRsn } from '../../../redux/selectors/initialization';
import { clearDocumentIndex } from '../../../redux/actions/documentIndex';
import { clearPageIndex } from '../../../redux/actions/pageIndex';
import { clearPageMetaData, clearPageConsultees } from '../../../redux/actions/pageMetadata';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { getModifiedLabels, getModifiedLabelsFlag } from '../../../redux/selectors/localization';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { Row } from 'react-table';
import { PageData } from '../../../redux/reducers/common/common.model';


const ModalRequestSearch = (): JSX.Element => {
    const modifiedLabels = useSelector(getModifiedLabels);
    const modifiedLabelsFlag = useSelector(getModifiedLabelsFlag);
    const [requestType, setRequestType] = useState('');
    const [folderRsn, setFolderRsn] = useState<any>();
    const [requestNumber, setRequestNumber] = useState('');
    const [searchState, setSearchState] = useState('All');
    const [officer, setOfficer] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState<any>();
    const [exactMatch, setExactMatch] = useState(false);
    const [page, setPage] = React.useState(0);
    const [sortParams, setSortParams] = useState<PageData>();

    const dispatch = useDispatch();
    const getRequestSearch = (params: IRequestSearchParams): void => dispatch(fetchRequestSearch(params));
    const closeAllModalWindows = (): void => dispatch(handleCloseAllModalWindows());
    const clearModalSearch = (): void => dispatch(clearSearchResult());
    const getFolders = (): void => dispatch(getRequestSearchFolders());
    const setTableSort= (param: PageData) => dispatch(setTableSort(param));

    const clearUnusedState = (): void => {
        if(selectedRowId !== folderRSN) {
            dispatch(setPageInitialState());
            dispatch(setDocumentInitialState());
            dispatch(clearCurrentDocumentState());
            dispatch(clearDocumentIndex());
            dispatch(clearPageIndex());
            dispatch(clearPageMetaData());
            dispatch(clearPageConsultees());
        }
    };

    const labels = {
        requestTypeLabel: initialLabel,
        folderRSNLabel: initialLabel,
        officerLabel: initialLabel,
        requestNumberLabel: initialLabel,
        exactMatchLabel: initialLabel,
        allLabel: initialLabel,
        activeLabel: initialLabel,
        closedLabel: initialLabel,
        clearLabel: initialLabel,
        searchLabel: initialLabel,
        resultTitleLabel: initialLabel,
        requestNumberHeaderLabel: initialLabel,
        summaryHeaderLabel: initialLabel,
        dueDataHeaderLabel: initialLabel,
        officerHeaderLabel: initialLabel,
        FolderRSNHeaderLabel: initialLabel,
        closeLabel: initialLabel,
        openLabel: initialLabel,
        openNewTabLabel: initialLabel,
    };

    resourceBundle.map((resource: any) => {
        switch(resource.resourceKey) {
            case 'SEARCH_REQUEST_MODAL_REQUEST_TYPE':
                labels.requestTypeLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_FOLDER_RSN':
                labels.folderRSNLabel= resource;
                break;
            case 'COMMON_LABEL_EXACT_MATCH':
                labels.exactMatchLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_REQUEST_NUMBER':
                labels.requestNumberLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_OFFICER':
                labels.officerLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_SEARCH_RESULT':
                labels.resultTitleLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_STATE_ALL':
                labels.allLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_STATE_ACTIVE':
                labels.activeLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_STATE_CLOSED':
                labels.closedLabel= resource;
                break;
            case 'COMMON_LABEL_CLEAR':
                labels.clearLabel= resource;
                break;
            case 'COMMON_LABEL_SEARCH':
                labels.searchLabel= resource;
                break;
            case 'COMMON_LABEL_CLOSE':
                labels.closeLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_OPEN':
                labels.openLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_OPEN_NEW_TAB':
                labels.openNewTabLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_COLUMNHEADER_SUMMARY':
                labels.summaryHeaderLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_COLUMNHEADER_DUE_DATA':
                labels.dueDataHeaderLabel= resource;
                break;
        }

        return resource;
    });

    modifiedLabels.map((resource: any) => {
        switch(resource.resourceKey) {
            case 'SEARCH_REQUEST_MODAL_REQUEST_TYPE':
                labels.requestTypeLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_FOLDER_RSN':
                labels.folderRSNLabel= resource;
                break;
            case 'COMMON_LABEL_EXACT_MATCH':
                labels.exactMatchLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_REQUEST_NUMBER':
                labels.requestNumberLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_OFFICER':
                labels.officerLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_SEARCH_RESULT':
                labels.resultTitleLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_STATE_ALL':
                labels.allLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_STATE_ACTIVE':
                labels.activeLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_STATE_CLOSED':
                labels.closedLabel= resource;
                break;
            case 'COMMON_LABEL_CLEAR':
                labels.clearLabel= resource;
                break;
            case 'COMMON_LABEL_SEARCH':
                labels.searchLabel= resource;
                break;
            case 'COMMON_LABEL_CLOSE':
                labels.closeLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_OPEN':
                labels.openLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_OPEN_NEW_TAB':
                labels.openNewTabLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_COLUMNHEADER_SUMMARY':
                labels.summaryHeaderLabel= resource;
                break;
            case 'SEARCH_REQUEST_MODAL_COLUMNHEADER_DUE_DATA':
                labels.dueDataHeaderLabel= resource;
                break;
        }

        return resource;
    });

    const requestTypeOptions = useSelector(getFoiFoldersOptions);
    const resultLoading = useSelector(getRequestResultSearchLoading);
    const resultSearch = useSelector(getTableRequestResultSearch);
    const redactionLang = useSelector(getRedactionLanguage);
    const noOfficerSelectedOption = {
        nameFirst: NO_OFFICER_SELECTED,
        nameLast: '',
        peopleRsn: NO_OFFICER_SELECTED,
    };
    const officersList = useSelector(getOfficers);
    const officersListOptions = [...officersList, noOfficerSelectedOption];
    const folderRSN = useSelector(getRedactionFolderRsn);

    const langRule = redactionLang ? redactionLang === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';
    const stateOptions = [
        {
            value: 'All',
            label: labels.allLabel[langRule],
        },
        {
            value: 'Active',
            label: labels.activeLabel[langRule],
        },
        {
            value: 'Closed',
            label: labels.closedLabel[langRule],
        },
    ];

    const handleLanguageChange = (param: string): string => {
        if(param === 'Request Number') {
            return labels.requestNumberLabel[langRule];
        } else if(param === 'Summary') {
            return labels.summaryHeaderLabel[langRule];
        } else if(param === 'Due Data') {
            return labels.dueDataHeaderLabel[langRule];
        } else if(param === 'Officer') {
            return labels.officerLabel[langRule];
        } else if(param === 'FolderRSN') {
            return labels.folderRSNLabel[langRule];
        } else {
            return param;
        }
    };

    useEffect(() => {
        getFolders();

        return (): void => clearModalSearch();
    }, []);

    useEffect(() => {

        if (!resultLoading) {
            return (): void => clearModalSearch();
        }

    }, [resultLoading]);

    const officersOptions = officersListOptions.map((officerItem: IOfficerRequest): ISelectOptions => ({
        value: `${officerItem.peopleRsn}`,
        label: `${officerItem.nameFirst} ${officerItem.nameLast}`,
    }));

    const clearSearchForm = (): void => {
        setRequestType('');
        setFolderRsn('');
        setRequestNumber('');
        setExactMatch(false);
        setSearchState('All');
        setOfficer('');
    };

    const searchResult = (): void => {
        setSelectedRowId('');

        const params = {
            ...(requestType && { requestType }),
            ...(folderRsn && { folderRSN: folderRsn }),
            ...(officer && { peopleRSN: officer === NO_OFFICER_SELECTED ? '' : officer }),
            ...(requestNumber && { requestNumber }),
            exactMatch,
            state: searchState,
            noOfficerSelected: officer === NO_OFFICER_SELECTED,
        };

        getRequestSearch(params);
    };

    const getTrProps = (rowInfo: Row): ITrProps => {
        const id = get(rowInfo, 'original.id', null);
        setSelectedRowId(id);

        return {
            id,
            onClick: (): void => {
                setSelectedRowId(id);
            },
        };
    };
    const handleSortTable = (param: string, val: string, pageName: string): void => {
        const pageData: PageData = {
            pageName: pageName,
            columnName: param,
            sortOrder: val,
        };
        setSortParams(pageData);
    }

    const paginatedData = resultSearch;

    return (
        <div className='request-search'>
            <div className='request-search_header'>
                <div className='request-search_row'>
                    <div className='request-search_item-large'>
                        <SelectMaterialBuilder
                            value={requestType}
                            label={labels.requestTypeLabel[langRule]}
                            paramName='requestType'
                            handleSelectChange={(param: string, val: string): void => setRequestType(val)}
                            options={requestTypeOptions}
                        />
                    </div>
                    <div className='request-search_item-small'>
                        <TextField
                            value={folderRsn}
                            label={labels.folderRSNLabel[langRule]}
                            fullWidth={true}
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => setFolderRsn(e.target.value)}
                            type='number'
                            variant='outlined'
                        />
                    </div>
                </div>
                <div className='request-search_row'>
                    <div className='request-search_item-small request-search_item-twolines'>
                        <TextField
                            value={requestNumber}
                            label={labels.requestNumberLabel[langRule]}
                            fullWidth={true}
                            type='text'
                            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                                setRequestNumber(e.target.value)}
                            variant='outlined'
                            inputProps={{ maxLength: 40 }}
                        />
                        <div className='request-search_item-stick-with-control'>
                            <CheckboxMaterialBuilder
                                type={constants.CHECKBOX_TYPE_DEFAULT}
                                label={labels.exactMatchLabel[langRule]}
                                checked={exactMatch}
                                handleCheckboxChange={(param: string, checked: boolean): void =>
                                    setExactMatch(checked)}
                                paramName={'exactMatch'}
                                disabled={false}
                            />
                        </div>
                    </div>
                    <div className='request-search_item-medium request-search_item-center'>
                        <RadioMaterialBuilder
                            value={searchState}
                            handleRadioChange={(param: string, val: string): void => setSearchState(val)}
                            paramName={'state'}
                            ariaLabel={'State'}
                            radioList={stateOptions}
                        />
                    </div>
                    <div className='request-search_item-small'>
                        <SelectMaterialBuilder
                            value={officer}
                            label={labels.officerLabel[langRule]}
                            paramName='officer'
                            handleSelectChange={(param: string, val: string): void => setOfficer(val)}
                            options={officersOptions}
                        />
                    </div>
                </div>
            </div>
            <div className='request-search_body'>
                <div className='request-search_body_header'>
                    <div className='request-search_body_header_title'>{labels.resultTitleLabel[langRule]}</div>
                    <div>
                        <Button
                            variant='outlined'
                            size='small'
                            className='button_indent-left'
                            onClick={clearSearchForm}
                        >
                            {labels.clearLabel[langRule]}
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            className='primary button_indent-left'
                            onClick={searchResult}
                        >
                            {labels.searchLabel[langRule]}
                        </Button>
                    </div>
                </div>
                <div className='request-search_body_table'>
                    {!modifiedLabelsFlag &&
                        <Table
                            columns={tableColumns(handleLanguageChange)}
                            data={paginatedData}
                            showPagination={!!resultSearch.length}
                            pagesCount={resultSearch.length}
                            defaultPageSize={100}
                            page={page}
                            pageName={'RequestSearch'}
                            handleSortSession = {handleSortTable}
                            sortParams={sortParams}
                            getTrProps={(row: any) => getTrProps(row)}
                            selectedIds={[selectedRowId]}
                            allowSettings={false}
                            loading={resultLoading}
                            userPreferenceTable={true} 
                            onFetchData={(page: number): void => setPage(page)}
                        />
                    }
                </div>
            </div>
            <div className='request-search_footer'>
                <Button
                    variant='outlined'
                    size='small'
                    className='button_indent-left'
                    onClick={closeAllModalWindows}
                >
                    {labels.closeLabel[langRule]}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    disabled={!selectedRowId}
                    className='primary button_indent-left'
                    onClick={(): void => {
                        clearUnusedState();
                        closeAllModalWindows();
                    }}
                    role='link'
                    href={`/${b64EncodeUnicode(selectedRowId)}`}
                >
                    {labels.openLabel[langRule]}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    disabled={!selectedRowId}
                    className='primary button_indent-left'
                    target='_blank'
                    role='link'
                    href={`/${b64EncodeUnicode(selectedRowId)}${redactionLang && '?lang='+redactionLang || ''}`}
                >
                    {labels.openNewTabLabel[langRule]}
                </Button>
            </div>
        </div >
    );
};

export default ModalRequestSearch;
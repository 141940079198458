import {
    OPEN_MODAL_WINDOW,
    CLOSE_MODAL_WINDOW,
    CLOSE_ALL_MODAL_WINDOWS, CLOSE_2_LAST_MODAL_WINDOWS,
} from './constants';
import { AnyAction } from 'redux';
import { findIndex } from 'lodash';
import { IModal, IModalStore } from './modal.model';
import { getRightClickModalLabelsByKey } from '../../../constants/contextmenu/context.menu.config';

const initialState: IModalStore = {
    modal: [],
};

const modalReducer = (state: IModalStore = initialState, { type, payload }: AnyAction): IModalStore => {
    switch (type) {
        case OPEN_MODAL_WINDOW:
            const modals = [...state.modal];
            const index = findIndex(modals, ({ modalProps }: IModal) => modalProps.id === payload.modalProps.id);
            if (getRightClickModalLabelsByKey(payload.modalProps.id) != '') {
                payload.modalProps.title = getRightClickModalLabelsByKey(payload.modalProps.id);
            }
            const newModal = {
                modalType: payload.modalType,
                modalProps: payload.modalProps,
            };

            index === -1 ? modals.push(newModal) : modals[index] = newModal;

            return {
                ...state,
                modal: [...modals],
            };
        case CLOSE_MODAL_WINDOW:
            const stateModals = [...state.modal];
            const modal = stateModals.filter(({ modalProps }: IModal) => modalProps.id !== payload.id);

            return {
                ...state,
                modal,
            };
        case CLOSE_ALL_MODAL_WINDOWS:
            return {
                ...state,
                modal: [],
            };
        case CLOSE_2_LAST_MODAL_WINDOWS:
            const restModals = state.modal.slice(0, state.modal.length-2);

            return {
                ...state,
                modal: restModals,
            };
        default:
            return state;
    }
};

export default modalReducer;

import { LOW_WINDOW_HEIGHT, LOW_WINDOW_WIDTH } from '../redux/reducers/localStorage/constant';
import { IModalPermissionElement } from '../constants/modal.constants';

export const isLowResolution = (): boolean => {
    const { innerWidth, innerHeight } = window;

    return innerWidth <= LOW_WINDOW_WIDTH && innerHeight <= LOW_WINDOW_HEIGHT;
};

export const formatPermArrayToObject = (
    permissionsProperty: string[],
    type: string,
    name: string,
): IModalPermissionElement => permissionsProperty.reduce((result: IModalPermissionElement, property: string) => ({
    ...result,
    [property]: `${type}_${name}_${property}`,
}), {});

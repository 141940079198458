import * as React from 'react';
import { ISvgProps } from './svgIcons.model';

export const SEVER_ICON_ID = 'severIcon';
export const HIGHLIGHT_ICON_ID = 'highlightIcon';
export const AUTO_CORRECT_SEVER_ICON_ID = 'autoCorrectSeverIcon';
export const autoCorrectSeverIconStyle = {
    fontSize: '6px',
    paddingLeft: '1px',
    paddingBottom: '12px',
    fontFamily: 'Arial',
};

/* tslint:disable */
export const SvgIcon = (props: ISvgProps): JSX.Element => {
    switch (props.iconname) {
        case 'SeverIcon':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' {...props} id={SEVER_ICON_ID}>
                    <path fill='none' fillRule='evenodd' stroke='#798698'
                        d={'M13.5 2.5h1v11h-1l-11-11v-1h11v1zm2 ' +
                            '0v-2h-2v1h-11v-1h-2v2h1v11h-1v2h2v-1h11v1h2v-2h-1v-11h1zm-13 11h-1v-11h1l11 11v1h-11v-1z'} />
                </svg>
            );
        case 'HighlightIcon':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' {...props} id={HIGHLIGHT_ICON_ID}>
                    <path fill='none' fillRule='evenodd' stroke='#798698'
                        d={'M2.5.5h-2v2h1v11h-1v2h2v-1h11v1h2v-2h-1v-11h1v-2h-2v1h-11v-1zm11 ' +
                            '2h1v11h-1v1h-11v-1h-1v-11h1v-1h11v1z'} />
                </svg>
            );
        case 'AutoCorrectSeverIcon':
            return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props} id={AUTO_CORRECT_SEVER_ICON_ID}>
                        <g transform="translate(-12812 -1711)">
                            <path d="M1,1V2H2V1H1M0,0H3V3H0Z" transform="translate(12812 1711)" fill="#707070" />
                            <g transform="translate(12826.5 1713.636)">
                                <path d="M.5,10.615h-1V10h1ZM.5,9h-1V8h1Zm0-2h-1V6h1Zm0-2h-1V4h1Zm0-2h-1V2h1Zm0-2h-1V0h1Z" fill="#798698" />
                            </g>
                            <g transform="translate(12813.5 1713.636)">
                                <path d="M.5,10.615h-1V10h1ZM.5,9h-1V8h1Zm0-2h-1V6h1Zm0-2h-1V4h1Zm0-2h-1V2h1Zm0-2h-1V0h1Z" fill="#798698" />
                            </g>
                            <g transform="translate(12825.615 1712.5) rotate(90)">
                                <path d="M.5,10.615h-1V10h1ZM.5,9h-1V8h1Zm0-2h-1V6h1Zm0-2h-1V4h1Zm0-2h-1V2h1Zm0-2h-1V0h1Z" fill="#798698" />
                            </g>
                            <g transform="translate(12825.615 1725.5) rotate(90)">
                                <path d="M.5,10.615h-1V10h1ZM.5,9h-1V8h1Zm0-2h-1V6h1Zm0-2h-1V4h1Zm0-2h-1V2h1Zm0-2h-1V0h1Z" fill="#798698" />
                            </g>
                            <path d="M1,1V2H2V1H1M0,0H3V3H0Z" transform="translate(12825 1711)" fill="#798698" />
                            <path d="M1,1V2H2V1H1M0,0H3V3H0Z" transform="translate(12825 1724)" fill="#798698" />
                            <path d="M1,1V2H2V1H1M0,0H3V3H0Z" transform="translate(12812 1711)" fill="#798698" />
                            <path d="M1,1V2H2V1H1M0,0H3V3H0Z" transform="translate(12812 1724)" fill="#798698" />
                            <path d="M3.155-9.1l.119,2.981H3.048a9.217,9.217,0,0,0-.926-2.156A1.334,1.334,0,0,0,.957-8.851H.815v7.815h1.01v.249H-2.511v-.249H-1.5V-8.851h-.154a1.347,1.347,0,0,0-1.176.6,8.941,8.941,0,0,0-.915,2.132h-.226L-3.853-9.1Z" transform="translate(12820.656 1723.887)" fill="#424e5f" />
                        </g>
                    </svg>
                </>

            );
        case 'ArticleStampIcon':
            return (
                <svg xmlns='ttp://www.w3.org/2000/svg' width='17' height='14' viewBox='0 0 17 14' {...props}>
                    <g fill='none' fillRule='evenodd' stroke='#798698'>
                        <path d={'M12 1.714L13.243 3 16 0M.5 11.5h12v2H.5zM1.964 ' +
                            '11.5h8.93a3.5 3.5 0 0 0-3.465-3h-2a3.5 3.5 0 0 0-3.465 3z'} />
                        <path d='M4.748 8.5H8.11l.451-5.691a2.14 2.14 0 1 0-4.265 0L4.748 8.5z' />
                    </g>
                </svg>
            );
        case 'AutoStampIcon':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14'  {...props} >
                    <g fill='none' fillRule='evenodd' stroke='#798698'>
                        <path d='M.5 11.5h12v2H.5zM1.964 11.5h8.93a3.5 3.5 0 0 0-3.465-3h-2a3.5 3.5 0 0 0-3.465 3z' />
                        <path d='M4.748 8.5H8.11l.451-5.691a2.14 2.14 0 1 0-4.265 0L4.748 8.5zM12 0h0M00 000' />
                        <text x="11" y="4" fontSize='0.4rem' strokeWidth="0.57" fontFamily="Arial">A</text>
                    </g>
                </svg>
            );
        case 'CustomStampIcon':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' {...props}>
                    <g fill='none' fillRule='evenodd' stroke='#798698'>
                        <path d='M.5 11.5h12v2H.5zM1.964 11.5h8.93a3.5 3.5 0 0 0-3.465-3h-2a3.5 3.5 0 0 0-3.465 3z' />
                        <path d='M4.748 8.5H8.11l.451-5.691a2.14 2.14 0 1 0-4.265 0L4.748 8.5zM12 2h4M14 4V0' />
                    </g>
                </svg>
            );
        case 'ZoomPlusIcon':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' {...props}>
                    <g fill='none' fillRule='evenodd'>
                        <circle cx='8' cy='8' r='8' fill='#F8F9FB' />
                        <g stroke='#798698'>
                            <path d='M4 8h8M8 12V4' />
                        </g>
                    </g>
                </svg>
            );
        case 'ZoomMinusIcon':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' {...props}>
                    <g fill='none' fillRule='evenodd'>
                        <circle cx='8' cy='8' r='8' fill='#F8F9FB' />
                        <path stroke='#798698' d='M4 8h8' />
                    </g>
                </svg>
            );
        case 'RotateIconLeft':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' {...props}>
                    <g fill='none' fillRule='evenodd' stroke='#798698'>
                        <path d='M1.464 6.707L5 3.171l3.535 3.536L5 10.243z' />
                        <path d='M6.653 11.415a5 5 0 1 0 2.584-9.41C9.167 2 8.07 2 8 2' />
                        <path fill='#798698' d={'M8.5 2.793V4l.854-.354-.854-.853zM7.707 ' +
                            '2l-.353-.354v.708L7.707 2zm.793-.793l.854-.853L8.5 0v1.207z'} />
                    </g>
                </svg>
            );
        case 'RotateIconRight':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' {...props}>
                    <g fill='none' fillRule='evenodd' stroke='#798698'>
                        <path d='M13.536 6.707L10 3.171 6.465 6.707 10 10.243z' />
                        <path d='M8.347 11.415a5 5 0 1 1-2.584-9.41C5.833 2 6.93 2 7 2' />
                        <path fill='#798698' d={'M6.5 2.793V4l-.854-.354.854-.853zM7.293 2l.353-.354v.708L7.293 ' +
                            '2zM6.5 1.207L5.646.354 6.5 0v1.207z'} />
                    </g>
                </svg>
            );
        case 'SelectObjectIcon':
            return (
                <svg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' {...props}>
                    <g fill='none' fillRule='evenodd' stroke='#798698'>
                        <path d={'M5 3.75 L5 8.5 L3.5 7.25 C2.75 7 1.75 7.25 1.5 8.25 C3.5 10.5 6 13 6.5 11.75' +
                        'C6 12.75 10 12.95 11 12.5 C13 12 14 10 14 9 L14 7 S13 5 12 7 L12 8.3 M12 6.5' +
                        'S10.95 4 9.75 6.25 L9.75 8 M9.75 6 S8.75 3.25 7.5 6 L7.6 7.75 L7.6 3.75' +
                        'S6.5 1.75 4.75 3.75'} />
                        <path d='M0.5 4 L3.5 4 M4.5 2.5 L2 1 M6 0 L6 2 M7.75 2.25 L9.5 0' />
                    </g>
                </svg>
            );
    }
};
/* tslint:enable */

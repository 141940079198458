export const GET_PEOPLE_TYPE_BY_DISCLOSURE_PENDING = 'reduction/GET_PEOPLE_TYPE_BY_DISCLOSURE_PENDING';
export const GET_PEOPLE_TYPE_BY_DISCLOSURE_SUCCESS = 'reduction/GET_PEOPLE_TYPE_BY_DISCLOSURE_SUCCESS';
export const GET_PEOPLE_TYPE_BY_DISCLOSURE_FAILURE = 'reduction/GET_PEOPLE_TYPE_BY_DISCLOSURE_FAILURE';

export const GET_SEARCH_PEOPLE_RECORDS_PENDING = 'reduction/GET_SEARCH_PEOPLE_RECORDS_PENDING';
export const GET_SEARCH_PEOPLE_RECORDS_SUCCESS = 'reduction/GET_SEARCH_PEOPLE_RECORDS_SUCCESS';
export const GET_SEARCH_PEOPLE_RECORDS_FAILURE = 'reduction/GET_SEARCH_PEOPLE_RECORDS_FAILURE';

export const GET_ASSIGNED_PEOPLE_RECORDS_PENDING = 'reduction/GET_ASSIGNED_PEOPLE_RECORDS_PENDING';
export const GET_ASSIGNED_PEOPLE_RECORDS_SUCCESS = 'reduction/GET_ASSIGNED_PEOPLE_RECORDS_SUCCESS';
export const GET_ASSIGNED_PEOPLE_RECORDS_FAILURE = 'reduction/GET_ASSIGNED_PEOPLE_RECORDS_FAILURE';

export const CLEAR_SEARCH_RESULT = 'reduction/CLEAR_SEARCH_RESULT';

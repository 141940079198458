import * as React from 'react';
import { isNull, isEqual } from 'lodash';
import { IBasicSettingsTabProps, IBasicSettingsState } from './BasicSettingsTab.model';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import {
     DISABLE_AUDIT_LOGGING,
     MAXIMUM_NUMBER_OF_DAYS,
     DISABLE_AUDIT_LOGGING_PARAM,
     MAXIMUM_NUMBER_OF_DAYS_PARAM,
     NUMBER_ERROR,
     NUMBER_REG_EXP,
} from './BasicSettings.constants';
import {
    CheckboxMaterialBuilder,
} from '../../../../components/materialUiForms/materialUiFormBuilder';
import './basicSettings.style.scss';
import { initialLabel } from '../../../../constants/localization.constants';
import resourceBundle from '../../../../containers/localization/localizationData';
import { changeLang } from '../../../../redux/actions/localization';

const initialBasicSettings = {
    disableAuditLogging: false,
    numberOfDays: null,
};

const getBasicLabelsByKey = (key: string): string => {
    switch (key) {
        case 'GLOBALSETTINGS_DISABLEAUDITLOG':
            return 'disableAuditLabel';
        case 'GLOBALSETTINGS_MAXIMUM':
            return 'maximumLabel';
        default:  return '';
    }
}

const labels = {
    disableAuditLabel: initialLabel,
    maximumLabel: initialLabel,
};
export class BasicSettingsTab extends React.Component<IBasicSettingsTabProps, IBasicSettingsState> {

    constructor(props: IBasicSettingsTabProps) {
        super(props);

        const settings = !isNull(this.props.basicSettings) ?
        this.props.basicSettings : initialBasicSettings;

        this.state = {
            basicSettingsDto: settings,
            isError: false,
        };

    }
    public componentDidUpdate(prevProps: IBasicSettingsTabProps): void {
        if (!isEqual(prevProps.basicSettings, this.props.basicSettings)) {
            this.setState({ basicSettingsDto: this.props.basicSettings });
        }
    }
    public render(): JSX.Element {
        const error = this.state.isError;
        const numberOfDays = this.state.basicSettingsDto && this.state.basicSettingsDto.numberOfDays;
        const disableAuditLogging = this.state.basicSettingsDto && this.state.basicSettingsDto.disableAuditLogging;
        const { editPermission,redactionLang,modifiedLabels } = this.props;
        const langRule = changeLang(redactionLang);
        resourceBundle.map((resource: any) => {
            if (getBasicLabelsByKey(resource.resourceKey)) {
                labels[getBasicLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getBasicLabelsByKey(resource.resourceKey)) {
                labels[getBasicLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        return (
                <div className='basic-settings-container'>
                    <div className='select-wrapper'>
                    <FormLabel> {labels.disableAuditLabel[langRule]} </FormLabel>
                    <div className='text'>
                        <CheckboxMaterialBuilder
                                type={'switch'}
                                handleCheckboxChange={this.handleCheckboxChange}
                                label=''
                                checked={disableAuditLogging}
                                paramName={DISABLE_AUDIT_LOGGING_PARAM}
                                disabled={!editPermission}
                        />
                        </div>
                    </div>
                    <div className='select-wrapper '>
                        <FormLabel> {labels.maximumLabel[langRule]} </FormLabel>
                        <div className='text'>
                        <TextField
                                onChange={(e: React.ChangeEvent<Element>): void => {
                                    const value = (e.target as HTMLSelectElement).value;

                                    if (value.match(NUMBER_REG_EXP)) {
                                        this.handleNumberChange(MAXIMUM_NUMBER_OF_DAYS_PARAM, Number(value));
                                    } else if (!value) {
                                        this.handleNumberChange(MAXIMUM_NUMBER_OF_DAYS_PARAM, null);
                                    }
                                }}
                                type='text'
                                multiline={false}
                                value={numberOfDays}
                                disabled={!editPermission || disableAuditLogging}
                                error={error}
                                helperText={error &&
                                <span className='error'>{NUMBER_ERROR}</span>}
                        />
                        </div>
                    </div>
                </div>
            );
    }
    private handleNumberChange = ( param: string, value: number): void => {
        const basicSettingsDto = {...this.state.basicSettingsDto, [param]: value};

        this.setState((prevState: IBasicSettingsState): IBasicSettingsState => ({
            ...prevState,
            basicSettingsDto,
        }), this.handleFormChange);

        this.setState({
            isError: value <= 0,
        });
    }

    private handleCheckboxChange = (param: string, value: boolean): void => {
        const basicSettingsDto = {...this.state.basicSettingsDto, [param]: value};

        this.setState((prevState: IBasicSettingsState): IBasicSettingsState => ({
            ...prevState,
            basicSettingsDto,
        }), this.handleFormChange);
    }

    private handleFormChange(): void {
        this.props.form.batch(() => {
            this.props.form.change('basicSettingsDto', this.state.basicSettingsDto);
        });
    }
}

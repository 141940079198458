import * as React from 'react';
import { AnyAction } from 'redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { first } from 'lodash';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import classnames from 'classnames';
import {
    IControlsDispatchProps,
    IControlsState,
    IControlsStateProps,
    INavigationProps,
    ISizeSelectOptionsObject,
    IRotationData,
} from './control.model';
import {
    PAGE_WIDTH_NAME,
    sizeSelectOptions,
    degrees,
    scaleDirection,
    ITEM_CLASS,
    LEFT,
    RIGHT,
    MIN_SCALE,
} from '../../constants';
import toggleClassName from '../../utils/toggleClassNames';
import StampControls from './StampControls';
import { AnnotationTypesControls } from './AnnotationTypesControls';
import { getPageListPermissions, getRedactionDocumentId } from '../../redux/selectors/initialization';
import { clientSize, getDocument, getSelectedCustomStamps } from '../../redux/selectors/redactor';
import { pageScale, pageSize, pageRotate } from '../../redux/actions/redactor';
import { size as pdfSizeUtil, zoom } from '../../utils/pdfControl.util';
import {
    getActiveHighlightTypesList,
    getActiveSeverTypesList,
    getSelectedAnnotationType,
} from '../../redux/selectors/annotationTypes';
import { getControlsPermissions } from '../../redux/selectors/initialization';
import { IState } from '../../redux/store';
import { IAnnotationType } from '../../redux/reducers/annotationTypes/annotationTypes.model';
import { setSelectedAnnotationTypes } from '../../redux/actions/annotationTypes';
import { openModalWindow } from '../../redux/actions/modal';
import { isLowResolution } from '../../utils/app.util';
import { getPageInformation } from '../../redux/selectors/pageList';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { SvgIcon } from '../svgIcons/svgIcons';
import './controls.styles.scss';
import { getSelectedArticleStamps, getInteractiveMode } from '../../redux/selectors/layoutRedactorTypes';
import { setAutoCorrectSever } from '../../redux/actions/annotations';
import { getAutoCorrectSever } from '../../redux/selectors/annotation';
import { setInteractiveMode } from '../../redux/actions/layoutRedactorTypes';
import { MenuItem } from '@mui/material';

export enum ElementType {
    TEXT = 'text',
    SVG = 'svg',
}

export const setActiveToolStyles = (elementType: ElementType, condition: boolean): string => {
    if (elementType === ElementType.TEXT) {
        return `text ${condition ? 'active-text' : ''}`;
    } else if (elementType === ElementType.SVG) {
        return `svg ${condition ? 'active-svg' : ''}`;
    }
};

class Controls extends React.Component<INavigationProps, IControlsState> {
    public state: IControlsState = {
        sizeSelect: PAGE_WIDTH_NAME,
        activeControl: null,
        lowResolution: isLowResolution(),
    };

    public componentDidMount(): void {
        window.addEventListener('resize', this.onDefaultEventResize);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.onDefaultEventResize);
    }

    public render(): JSX.Element {
        const { lowResolution, sizeSelect } = this.state;
        const {
            openModal,
            severTypes,
            highlightTypes,
            selectedAnnotationType,
            selectedCustomStamps,
            openSever,
            openHighlight,
            handleChangeSever,
            handleChangeHighLight,
            handleCloseSelects,
            permissions,
            permissionsPages,
            articleStamps,
        } = this.props;
        const { severPermission, highlightPermission, exemptionsPermission,
          customStampsPermission, autoStampPermission } = permissions;
        const { rotatePagePermission } = permissionsPages;
        const horizontalScrollStyles = {
            height: '6px',
            bottom: '0',
            left: '2px',
            borderRadius: '3px',
            width: '100%',
            zIndex: 5,
        };

        return (
            <div className={'controls_wrapper'} style={{overflowX:'auto', overflowY:'hidden', paddingLeft: '20px', cursor: 'pointer'}}>
                <div
                  className={toggleClassName(
                      !this.props.data.pdfSrc,
                      'disabled',
                      `${lowResolution ? 'low-res-controls' : ''} controls`)}
                >
                  <div className={classnames(ITEM_CLASS)}>
                    <div className='icon'>
                      <div
                        className={setActiveToolStyles(ElementType.SVG, this.props.interactMode)}
                        onClick={(): void => this.selectHandler()}
                        onKeyPress={(e: React.KeyboardEvent<Element>): void => e.key === 'Enter' ?
                        this.selectHandler() :
                        null}
                        tabIndex={1}
                      >
                        <SvgIcon
                          iconname={'SelectObjectIcon'}
                          width={18}
                          height={16}
                        />
                      </div>
                      <div className='text'>Select</div>
                    </div>
                  </div>
                  <AnnotationTypesControls
                    activeControl={this.state.activeControl}
                    buttonToggle={this.buttonToggle}
                    highlightTypes={highlightTypes}
                    severTypes={severTypes}
                    pdfSrc={!!this.props.data.pdfSrc}
                    setSelectedAnnotations={this.props.setSelectedAnnotations}
                    selectedAnnotationType={selectedAnnotationType}
                    openSever={openSever}
                    openHighlight={openHighlight}
                    handleChangeSever={handleChangeSever}
                    handleChangeHighLight={handleChangeHighLight}
                    handleCloseSelects={handleCloseSelects}
                    severPermission={severPermission}
                    highlightPermission={highlightPermission}
                    setAutoCorrectSever={this.props.setAutoCorrectSever}
                    autoCorrectSever={this.props.autoCorrectSever}
                  />
                  <StampControls
                    openModalWindow={openModal}
                    activeItem={this.state.activeControl}
                    pdfSrc={!!this.props.data.pdfSrc}
                    exemptionsPermission={exemptionsPermission}
                    customStampsPermission={customStampsPermission}
                    selectedAnnotationType={selectedAnnotationType}
                    selectedCustomStamps={selectedCustomStamps}
                    articleStamps={articleStamps}
                    autoStampPermission={autoStampPermission}
                  />
                  <div className='wrapper-zoom'>
                    <div className='zoom'>
                      <div className='zoom-item' onClick={(): void => this.zoomHandler(scaleDirection.DOWN)}>
                        <SvgIcon iconname={'ZoomMinusIcon'} width={18} height={18} />
                      </div>
                      <div className='zoom-item'>
                          <FormControl className='zoom-item-size'{...(!this.props.data.pdfSrc && { disabled: true })}
                            sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                borderWidth: '0px !important',
                              }
                            }}
                          >
                          <Select
                            value={sizeSelect}
                            onChange={this.handleChange}
                            renderValue={(): string => sizeSelect}
                            className='button-before'
                          >
                              {sizeSelectOptions.map((option: ISizeSelectOptionsObject) => (
                                  <MenuItem
                                      key={option.val}
                                      value={option.val}
                                      className={`zoom-option ${sizeSelect === option.name ? 'zoom-active' : ''}`}
                                  >
                                      {option.name}
                                  </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='zoom-item' onClick={(): void => this.zoomHandler(scaleDirection.UP)}>
                        <SvgIcon iconname={'ZoomPlusIcon'} width={18} height={18} />
                      </div>
                    </div>
                    <div className='text'>Zoom</div>
                  </div>

                  <div
                    className={classnames(ITEM_CLASS, { disabled: !rotatePagePermission })}
                    onClick={(): void => this.rotatePage(degrees.QUARTER)}
                  >
                    <div className='icon'>
                      <div className='svg'>
                        <SvgIcon
                          iconname={'RotateIconLeft'}
                          width={18}
                          height={16}
                        />
                      </div>
                      <div className='text'>{LEFT}</div>
                    </div>
                  </div>
                  <div
                    className={classnames(ITEM_CLASS, { disabled: !rotatePagePermission })}
                    onClick={(): void => this.rotatePage(degrees.MINUS_QUARTER)}
                  >
                    <div className='icon'>
                      <div className='svg'>
                        <SvgIcon
                          iconname={'RotateIconRight'}
                          width={18}
                          height={16}
                        />
                      </div>
                      <div className='text'>{RIGHT}</div>
                    </div>
                  </div>
                </div>
            </div>
        );
    }

    private onDefaultEventResize = (): void => {
        this.setState({ lowResolution: isLowResolution() });
    }

    private rotatePage = (degree: number): void => {
        const { pageInformation, redactionDocumentId } = this.props;

        this.props.pageRotate({
            redactionDocumentId,
            documentId: pageInformation.documentId,
            pageId: pageInformation.pageId,
            rotationState: Number(degree),
        });
    }

    /* tslint:disable:no-string-literal */
    private handleChange = (e: SelectChangeEvent<unknown>): void => {
      const filteredOptions = sizeSelectOptions
            .filter((item: ISizeSelectOptionsObject) => item.val === e.target['value']);
        const size: string = first(filteredOptions).name;
      this.setState({
          sizeSelect: size,
      });
      this.sizeHandler(size);
  }
    /* tslint:enable:no-string-literal */

    private zoomHandler = (direction: string): void => {
        const scale = zoom(direction, this.props.data.scale);

        if (scale > MIN_SCALE) {
            this.props.pageScale(scale);
            this.props.pageSize(null);
        }

        this.setState({
            sizeSelect: `${Math.round(scale*100)}%`,
        });
    }

    private sizeHandler = (nextSize: string): void => {
        const { clientSize: size, data: { pdfWidth, pdfHeight, rotate } } = this.props;
        const isRotated = !(rotate === degrees.ZERO || rotate === degrees.MINUS_HALF);
        const { pdfSize, pdfScale } = pdfSizeUtil(nextSize, size, pdfWidth, pdfHeight, isRotated);

        this.props.pageSize(pdfSize);
        this.props.pageScale(pdfScale);
    }

    private selectHandler = (): void => {
      this.props.setInteractiveMode(true);
    }

    private buttonToggle = (activeControl: string): void => {
        this.setState({ activeControl });
    }
}

const mapStateToProps = (state: IState): IControlsStateProps => ({
    data: getDocument(state),
    severTypes: getActiveSeverTypesList(state),
    highlightTypes: getActiveHighlightTypesList(state),
    pageInformation: getPageInformation(state),
    selectedAnnotationType: getSelectedAnnotationType(state),
    selectedCustomStamps: getSelectedCustomStamps(state),
    clientSize: clientSize(state),
    redactionDocumentId: getRedactionDocumentId(state),
    permissions: getControlsPermissions(state),
    permissionsPages: getPageListPermissions(state),
    articleStamps: getSelectedArticleStamps(state),
    autoCorrectSever: getAutoCorrectSever(state),
    interactMode: getInteractiveMode(state),
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<IState, IControlsDispatchProps, AnyAction>): IControlsDispatchProps => ({
        setSelectedAnnotations: (data: IAnnotationType): void => {
            dispatch(setSelectedAnnotationTypes(data));
        },
        openModal: (type: string, modalProps: IModalProps): void => {
            dispatch(openModalWindow(type, modalProps));
        },
        pageScale: (scale: number): void => {
            dispatch(pageScale(scale));
        },
        pageSize: (size: number): void => {
            dispatch(pageSize(size));
        },
        pageRotate: (params: IRotationData): void => {
            dispatch(pageRotate(params));
        },
        setAutoCorrectSever : (autoCorrect: boolean): void => {
            dispatch(setAutoCorrectSever(autoCorrect));
        },
        setInteractiveMode : (flag: boolean): void => {
          dispatch(setInteractiveMode(flag));
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(Controls);

import * as React from 'react';
import classnames from 'classnames';
import { Menu } from 'react-contexify';
import { IProps } from './contextMenu.model';
import 'react-contexify/dist/ReactContexify.min.css';
import { IContextData, IContextMenu } from '../../redux/reducers/contextMenu/contextMenu.model';
import SubMenu from './contexMenuComponents/SubMenu';


const ContextDynamicMenu = ({
    contextMenuType,
    contextMenuList,
    contextMenuProps,
    openModalWindow,
    handleAction,
    redactionDocumentId,
}: IProps): JSX.Element => {
    const contextClass = classnames({hidden: !contextMenuType});

    return (
        <div className='context-menu-wrapper'>
            {
                contextMenuList.map((contextMenuItem: IContextMenu) => (
                    <Menu
                        id={contextMenuItem.id}
                        key={contextMenuItem.id}
                        className={contextClass}
                    >
                        <div className='hidden'/>
                        {
                            contextMenuItem.menu.map((elem: IContextData) => (
                                <SubMenu
                                    elem={elem}
                                    key={elem.id}
                                    contextMenuProps={contextMenuProps}
                                    openModalWindowHandler={openModalWindow}
                                    handleAction={handleAction}
                                    redactionDocumentId={redactionDocumentId}
                                />
                            ))
                        }
                    </Menu>
                ))
            }
        </div>
    );
};

export default ContextDynamicMenu;

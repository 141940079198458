import { action } from 'typesafe-actions';
import { AnyAction, Dispatch } from 'redux';
import {
    ADD_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_PENDING,
    DELETE_DOCUMENT_SUCCESS,
    FETCH_DOCUMENT_LIST_FAILURE,
    FETCH_DOCUMENT_LIST_PENDING,
    FETCH_DOCUMENT_LIST_SUCCESS,
    FETCH_DOCUMENT_STACK_LIST_SUCCESS,
    OCR_DOCUMENT_FAILURE,
    OCR_DOCUMENT_PENDING,
    OCR_DOCUMENT_SUCCESS,
    SAVE_STACK_ID,
    SET_DISCLOSURE_TYPE_FOR_DOCUMENT,
    SET_DOCUMENT_PAGINATION_FAILURE,
    SET_DOCUMENT_PAGINATION_PENDING,
    SET_DOCUMENT_PAGINATION_SUCCESS,
    SET_PAGINATION_FAILURE,
    SET_PAGINATION_PENDING,
    SET_PAGINATION_SUCCESS,
    UNDO_PAGINATION_FAILURE,
    UNDO_PAGINATION_PENDING,
    UNDO_PAGINATION_SUCCESS,
    UPDATE_DOCUMENT_PAGE_COUNT,
    UPDATE_DOCUMENT_INFO,
    UPDATE_DOCUMENT_PAGINATION,
    UPDATE_DOCUMENTS_FAILURE,
    UPDATE_DOCUMENTS_PENDING,
    UPDATE_DOCUMENTS_SUCCESS,
    UPDATE_PAGINATION_FAILURE,
    UPDATE_PAGINATION_PENDING,
    UPDATE_PAGINATION_SUCCESS, UPDATE_DOCUMENTS_BY_LIST,
    SET_DOCUMENT_INITIAL_STATE,
    FETCH_DOCUMENT_EXPORT_LIST_SUCCESS,
    CHANGE_DOCUMENT_DISCLOSURE_PENDING,
    CHANGE_DOCUMENT_DISCLOSURE_COMPLETE,
    SET_DOCUMENT_LIST_COLUMN_HEADER_PENDING,
    SET_DOCUMENT_LIST_COLUMN_HEADER_LIST_SUCCESS,
    SET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE,
    SET_DOCUMENT_LIST_TABLE_COLUMN,
    MODIFY_DOCUMENT_LIST_COLUMN_HEADER_FAILURE,
    MODIFY_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS,
    MODIFY_DOCUMENT_LIST_COLUMN_HEADER_PENDING,
    RESET_DOCUMENT_LIST_COLUMN_HEADER_PENDING,
    RESET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE,
    RESET_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS,
    APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_PENDING,
    APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_SUCCESS,
    APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_FAILURE,
} from '../reducers/documentList/constant';
import {
    ALERT_DIALOG_MODAL,
    CONFIRMATION_DIALOG_MODAL,
    DELETE_DOCUMENT_MESSAGE,
    DELETE_DOCUMENT_TITLE,
    EDIT_PAGINATION_MODAL,
    UNDO_PAGINATION_MODAL,
    NO_BUTTON,
    PAGINATION_CONFIRMATION_MESSAGE,
    PAGINATION_CONFIRMATION_TITLE,
    PAGINATION_MULTI_PAGE_WARNING,
    PAGINATION_NO_DOCUMENT_WARNING,
    PAGINATION_TITLE,
    WARNING,
    YES_BUTTON,
    NOT_SELECT_DOCUMENT, AUTO_DESKEW_DOCUMENT_MESSAGE,
    RELEASE_MANAGEMENT_MODAL, AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL,
    AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MESSAGE,
    AUTO_ALIGN_ARTICLE_STAMPS_DOCUMENT_WARNING_MESSAGE,
    AUTO_ALIGN_ARTICLE_STAMP_TITLE,
} from '../../constants/messages.constants';
import api from '../../api/reductionApi';
import {
    clearCurrentDocumentState,
    fetchDocumentMetadata,
    getCurrentDocumentSuccess,
    undoPaginationPage,
    clearCurrentPageState,
    fetchPaginatedPages,
} from './pageList';
import { handleCloseModalWindow, openModalWindow } from './modal';
import { addError } from './errorHandling';
import { IModalIdsData } from '../reducers/modal/modal.model';
import { IFile, IOcrDocPage, IPaginationRange } from '../reducers/documentList/documentList.model';
import { IState, ThunkResult } from '../store';
import { IDocument } from '../reducers/pageList/pageList.model';
import { IError } from '../common.model';
import {
    SET_ALL_DOCUMENTS_TO_SELECT_LIST,
    SET_DISCLOSURE_PAGE,
    UNSET_CURRENT_DOCUMENT,
} from '../reducers/pageList/constant';
import { clearOptionsState } from './redactor';
import { restartDocumentTasksQueue } from './taskQueue';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IStackOptions } from '../reducers/stackList/stackList.model';
import { clearDocumentIndex } from './documentIndex';
import { clearPageIndex } from './pageIndex';
import { updatePageMetaDataSuccess, clearPageConsultees, clearPageMetaData } from './pageMetadata';
import {
    DOCUMENT_STACK_MODAL,
    DOCUMENT_STACK_TITLE_MODAL,
    DOCUMENT_STACK_WARNING,
} from '../../constants/stack.contstants';
import { IUpdateModalReleaseData } from '../../containers/modalWindowContents/modalReleaseDate/modalReleaseData.model';
import { RELEASE_DATE_PARAM } from '../../constants/releaseDate.constants';
import { updateExportFilters } from './modalWindowExport';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { getAllAnnotationsPerPage } from './annotations';
import { getPageStamps } from './stamps';
import { applySearchPending } from './globalSearch';
import { initialLabel } from '../../constants/localization.constants';
import resourceBundle from '../../containers/localization/localizationData';
import {
    getEditPaginationLabelsByKey } from '../../containers/modalWindowContents/modalEditPagination/ModalEditPagination';
import { changeLang, getCommonLabelsByKey } from './localization';
import { getStackLabelsByKey } from '../../containers/modalWindowContents/modalDocumentStack/modalDocumentStack';
import { getReleaseDateModalLabelsByKey } from '../../containers/modalWindowContents/modalReleaseDate/ModalReleseDate';

export const setDocumentInitialState = (): AnyAction => action(SET_DOCUMENT_INITIAL_STATE);
export const fetchDocumentListPending = (): AnyAction => action(FETCH_DOCUMENT_LIST_PENDING);
export const fetchDocumentListSuccess = (files: IFile[]): AnyAction => action(FETCH_DOCUMENT_LIST_SUCCESS, files);
export const fetchDocumentExportListSuccess = (files: IFile[]): AnyAction =>
    action(FETCH_DOCUMENT_EXPORT_LIST_SUCCESS, files);
export const fetchDocumentStackListSuccess = (files: IFile[]): AnyAction =>
    action(FETCH_DOCUMENT_STACK_LIST_SUCCESS, files);
export const fetchDocumentListFailure = (): AnyAction => action(FETCH_DOCUMENT_LIST_FAILURE);
export const deleteDocumentPending = (): AnyAction => action(DELETE_DOCUMENT_PENDING);
export const deleteDocumentSuccess = (ids: number[]): AnyAction => action(DELETE_DOCUMENT_SUCCESS, ids);

export const unsetCurrentDocument = (): AnyAction => action(UNSET_CURRENT_DOCUMENT);

export const undoPaginationPending = (documentIds: number[]): AnyAction => action(UNDO_PAGINATION_PENDING, documentIds);
export const undoPaginationSuccess = (ids: number[]): AnyAction => action(UNDO_PAGINATION_SUCCESS, ids);
export const undoPaginationFailure = (): AnyAction => action(UNDO_PAGINATION_FAILURE);

export const updatePaginationPending = (documentId: number): AnyAction =>
    action(UPDATE_PAGINATION_PENDING, [documentId]);
export const updatePaginationSuccess = (documentId: number, paginationRange: IPaginationRange): AnyAction =>
    action(UPDATE_PAGINATION_SUCCESS, { documentId, paginationRange });
export const updatePaginationFailure = (): AnyAction => action(UPDATE_PAGINATION_FAILURE);

export const updateDocumentsListPending = (): AnyAction => action(UPDATE_DOCUMENTS_PENDING);
export const updateDocumentsListSuccess = (documents: IDocument, currentSelectedStackId: number): AnyAction =>
    action(UPDATE_DOCUMENTS_SUCCESS, {documents, currentSelectedStackId});
export const updateDocumentsListFailure = (error: IError): AnyAction => action(UPDATE_DOCUMENTS_FAILURE, error);

export const updateDocumentsListWithList = (files: IFile[]): AnyAction =>
    action(UPDATE_DOCUMENTS_BY_LIST, files);

export const setPageDisclosureType = (pageId: number, disclosureId: number): AnyAction =>
    action(SET_DISCLOSURE_PAGE, { pageId, disclosureId });
export const setDocumentDisclosureType = (docId: number, disclosureTypeName: string): AnyAction =>
    action(SET_DISCLOSURE_TYPE_FOR_DOCUMENT, { docId, disclosureTypeName });
export const deleteDocumentFailure = (): AnyAction => action(DELETE_DOCUMENT_FAILURE);
export const updateDocumentPageCount = (docId: number, pageCount: number): AnyAction =>
    action(UPDATE_DOCUMENT_PAGE_COUNT, { docId, pageCount });
export const updatePaginationDocumentList = (file: IDocument): AnyAction => action(UPDATE_DOCUMENT_PAGINATION, file);
// TODO: IDocument & IFile looks the same, maybe better to combine it?
export const updateInfoDocumentInDocumentList =
    (file: IDocument | IFile): AnyAction => action(UPDATE_DOCUMENT_INFO, file);

export const setPaginationDocumentListPending = (documentId: number[]): AnyAction =>
    action(SET_PAGINATION_PENDING, documentId);
export const setPaginationDocumentListSuccess = (files: IFile[]): AnyAction => action(SET_PAGINATION_SUCCESS, files);
export const setPaginationDocumentListFailure = (error: IError): AnyAction => action(SET_PAGINATION_FAILURE, error);
export const setPaginationDocumentsPending = (documentIds: number[]): AnyAction =>
    action(SET_DOCUMENT_PAGINATION_PENDING, documentIds);
export const setPaginationDocumentsSuccess = (files: IFile[]): AnyAction =>
    action(SET_DOCUMENT_PAGINATION_SUCCESS, files);
export const setPaginationDocumentsFailure = (error: IError): AnyAction =>
    action(SET_DOCUMENT_PAGINATION_FAILURE, error);

export const ocrDocumentPending = (documentIds: number[]): AnyAction => action(OCR_DOCUMENT_PENDING, documentIds);
export const ocrDocumentSuccess = (): AnyAction => action(OCR_DOCUMENT_SUCCESS);
export const ocrDocumentFailure = (): AnyAction => action(OCR_DOCUMENT_FAILURE);

export const changeDocumentDisclosurePending = (documentIds: number[]): AnyAction =>
    action(CHANGE_DOCUMENT_DISCLOSURE_PENDING, documentIds);
export const changeDocumentDisclosureComplete = (documentIds: number[]): AnyAction =>
    action(CHANGE_DOCUMENT_DISCLOSURE_COMPLETE, documentIds);

export const saveStackId = (id: number): AnyAction => action(SAVE_STACK_ID, id);

export const setDocumentListColumnHeaderPending = (): AnyAction =>
    action(SET_DOCUMENT_LIST_COLUMN_HEADER_PENDING);
export const setDocumentListColumnHeaderSuccess = (documentListColumnsHeaders: IHeader[]): AnyAction =>
    action(SET_DOCUMENT_LIST_COLUMN_HEADER_LIST_SUCCESS, documentListColumnsHeaders);
export const setDocumentListColumnHeaderFailure = (error: IError): AnyAction =>
    action(SET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE, error);

export const modifyDocumentListColumnHeaderPending = (): AnyAction =>
    action(MODIFY_DOCUMENT_LIST_COLUMN_HEADER_PENDING);
export const modifyDocumentListColumnHeaderSuccess = (columns: IHeader[]): AnyAction =>
    action(MODIFY_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS, columns);
export const modifyDocumentListColumnHeaderFailure = (error: IError): AnyAction =>
    action(MODIFY_DOCUMENT_LIST_COLUMN_HEADER_FAILURE, error);

export const setDocumentListTableColumns = (columns: IHeader[]): AnyAction =>
    action(SET_DOCUMENT_LIST_TABLE_COLUMN, columns);

export const resetDocumentListColumnHeaderPending = (): AnyAction =>
    action(RESET_DOCUMENT_LIST_COLUMN_HEADER_PENDING);
export const resetDocumentListColumnHeaderSuccess = (documentListColumnsHeaders: IHeader[]): AnyAction =>
    action(RESET_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS, documentListColumnsHeaders);
export const resetDocumentListColumnHeaderFailure = (error: IError): AnyAction =>
    action(RESET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE, error);

export const applyDocumentAutoAlignArticleStampsPending = (): AnyAction =>
    action(APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_PENDING);
export const applyDocumentAutoAlignArticleStampsSuccess = (): AnyAction =>
    action(APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_SUCCESS);
export const applyDocumentAutoAlignArticleStampsFailure = (error: IError): AnyAction =>
    action(APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_FAILURE, error);

export const selectAllDocuments = (): (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const { documentList: { files } } = getState();
        const ids = files.map((file: IFile) => file.id);

        dispatch({ type: SET_ALL_DOCUMENTS_TO_SELECT_LIST, payload: ids });
    };

export const fetchDocumentList = (
    redactionDocumentId: number,
    shouldNotPending?: boolean,
    fetchForExport?: boolean,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {

        if (!shouldNotPending) {
            dispatch(fetchDocumentListPending());
        }

        try {
            if (fetchForExport) {
                const response = await api.redactionDocuments.getDocumentsMetadata(redactionDocumentId);

                dispatch(fetchDocumentExportListSuccess(response));
                dispatch(updateExportFilters());
            } else {
                const response = await api.redactionDocuments.getDocumentsSimpleMetadata(redactionDocumentId);

                dispatch(fetchDocumentListSuccess(response));
            }
        } catch (error) {
            dispatch(fetchDocumentListFailure());
            dispatch(addError(error));
        }
    };

export const confirmDeleteDocument = ({ documentIds }: IModalIdsData):
    (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const { localization: { modifiedLabels }, localStorage: { language } } = getState();
        const labels = {
            deleteDocumentTitleLabel: initialLabel,
            noDocumentWarningLabel: initialLabel,
            pressOkContinueLabel: initialLabel,
            deleteMessageLabel: initialLabel,
        };
        const langRule = changeLang(language);

        resourceBundle.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        if (!Array.isArray(documentIds)) {
            documentIds = [documentIds];
        }

        if (!documentIds.length) {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: labels.deleteDocumentTitleLabel[langRule],
                message: labels.noDocumentWarningLabel[langRule],
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        } else {
            dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: DELETE_DOCUMENT_TITLE,
                title: labels.deleteDocumentTitleLabel[langRule],
                message: `${labels.deleteMessageLabel[langRule]} ${labels.pressOkContinueLabel[langRule]}`,
                confirm: (): (dispatch: Dispatch, getState: () => IState) => void => deleteDocument(documentIds),
            }));
        }
    };

const getNextDocument = (files: number[], currentDeletedId: number, deletedIds: number[]): number => {
    const currentIdx = files.indexOf(currentDeletedId);
    const restOfFileList = files.slice(currentIdx).filter((id: number) => !deletedIds.includes(id));

    return restOfFileList.length ? restOfFileList[0] : files.filter((id: number) => !deletedIds.includes(id))[0];
};

export const openErrorModalWindowPagination = (error: IError, dispatch: Dispatch, warning: string): void => {
    dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
        id: ALERT_DIALOG_MODAL,
        title: warning,
        message: error.message,
        confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
    }));
};

export const deleteDocument = (documentIds: number[]): (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {

        const { reductionMetaData: { redactionDocumentId } } = getState();

        dispatch(deleteDocumentPending());

        try {
            await api.redactionDocuments.deleteDocument(redactionDocumentId, documentIds);

            const { documentList: { files }, pageList: { currentDocument } } = getState();

            const currentDocumentId = currentDocument ? currentDocument.id : null;

            if (currentDocumentId) {
                const nextActiveId = getNextDocument(files.map((file: IFile): number => file.id),
                    currentDocumentId, documentIds);

                if (nextActiveId) {
                    dispatch(fetchDocumentMetadata(redactionDocumentId, nextActiveId));
                }
            }

            dispatch(deleteDocumentSuccess(documentIds));
            dispatch(clearCurrentPageState());
            dispatch(clearCurrentDocumentState());
            dispatch(clearDocumentIndex());
            dispatch(clearPageIndex());
            dispatch(updatePageMetaDataSuccess(null));
            dispatch(clearPageConsultees());
        } catch (error) {
            dispatch(deleteDocumentFailure());
            dispatch(addError(error));
        }
    };

export const fetchStackDocumentsList = (
    redactionDocumentId: number,
    id: number,
    shouldNotPending?: boolean,
): (dispatch: Dispatch, getState: () => IState) =>
        Promise<void> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
            dispatch(saveStackId(id));

            if (!shouldNotPending) {
                dispatch(fetchDocumentListPending());
            }

            try {
                const response = await api.stackController.getStackDocuments(redactionDocumentId, id);

                const {
                    pageList: {
                        currentDocument,
                    },
                } = getState();

                if (currentDocument && response.every(
                    ({ id: fileId }: { id: number }) =>
                        currentDocument.id !== fileId)) {
                    dispatch(unsetCurrentDocument());
                    dispatch(clearCurrentDocumentState());
                    dispatch(clearDocumentIndex());
                    dispatch(clearPageIndex());
                    dispatch(clearPageMetaData());
                    dispatch(clearPageConsultees());
                }

                dispatch(fetchDocumentStackListSuccess(response));
            } catch (error) {
                dispatch(fetchDocumentListFailure());
            }
        };

export const setPaginationDocument = ({
    documentIds,
    redactionDocumentId,
}: IModalIdsData): (dispatch: Dispatch, getState: () => IState) =>
    void => (dispatch: Dispatch, getState: () => IState): void => {
    const { localization: { modifiedLabels }, localStorage: { language } } = getState();
    const labels = {
        yesLabel: initialLabel,
        noLabel: initialLabel,
        paginationConfirmationTitleLabel: initialLabel,
        paginationConfirmationMessageLabel: initialLabel,
    };
    const langRule = changeLang(language);

    resourceBundle.map((resource: any) => {
        if(getEditPaginationLabelsByKey(resource.resourceKey)) {
            labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
        }

        return;
    });
    modifiedLabels.map((resource: any) => {
        if(getEditPaginationLabelsByKey(resource.resourceKey)) {
            labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
        }

        return;
    });

    dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
        id: PAGINATION_CONFIRMATION_TITLE,
        title: labels.paginationConfirmationTitleLabel[langRule],
        message: labels.paginationConfirmationMessageLabel[langRule],
        okButtonTitle: labels.yesLabel[langRule],
        cancelButtonTitle: labels.noLabel[langRule],
        confirm: (): ThunkResult<Promise<void>> =>
            setPaginationForAllDocuments(redactionDocumentId),
        reject: (): ThunkResult<Promise<void>> =>
            setPaginationForExectDocuments(redactionDocumentId, documentIds),
    }));
};

export const undoPagination = ({
    documentIds,
    redactionDocumentId,
}: IModalIdsData): (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            pageList: { selectedDocuments },
            localization: {modifiedLabels},
            localStorage: {language},
        } = getState();
        const labels = {
            undoPaginationTitleLabel: initialLabel,
            noDocumentWarningLabel: initialLabel,
        };
        const langRule = changeLang(language);

        resourceBundle.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        if (selectedDocuments.length === 0) {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: UNDO_PAGINATION_MODAL,
                title: labels.undoPaginationTitleLabel[langRule],
                message: labels.noDocumentWarningLabel[langRule],
            }));

            return;
        }

        dispatch(undoPaginationPending(documentIds));

        try {
            if (!Array.isArray(documentIds)) {
                documentIds = [documentIds];
            }
            await api.paginationController.deletePaginationIndex(redactionDocumentId, documentIds);

            dispatch(undoPaginationSuccess(documentIds));
            dispatch(undoPaginationPage());

        } catch (error) {
            dispatch(undoPaginationFailure());
            dispatch(addError(error));
        }
    };

export const setPaginationForAllDocuments = (
    redactionDocumentId: number,
): ThunkResult<Promise<void>> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const {
        documentList: { files },
        pageList: { currentDocument, currentPage },
        localization: {modifiedLabels},
        localStorage: {language},
    } = getState();
    const labels = { warningLabel: initialLabel};

    resourceBundle.map((resource: any) => {
        if(getCommonLabelsByKey(resource.resourceKey)) {
            labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }

        return;
    });
    modifiedLabels.map((resource: any) => {
        if(getCommonLabelsByKey(resource.resourceKey)) {
            labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }

        return;
    });

    dispatch(setPaginationDocumentListPending(files.map((file: IFile): number => file.id)));

    try {
        const updatedDocumentList = await api.paginationController.resetPagination(redactionDocumentId);

        dispatch(setPaginationDocumentListSuccess(updatedDocumentList));

        if (currentDocument && currentPage) {
            dispatch(fetchDocumentMetadata(redactionDocumentId, currentDocument.id));
        }

    } catch (error) {
        dispatch(setPaginationDocumentListFailure(error));
        openErrorModalWindowPagination(error, dispatch, labels.warningLabel[changeLang(language)]);
    }
};

export const setPaginationForExectDocuments = (
    redactionDocumentId: number,
    documentIds: number[],
): ThunkResult<Promise<void>> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const { localization: {modifiedLabels}, localStorage: {language} } = getState();
    const labels = { warningLabel: initialLabel};

    resourceBundle.map((resource: any) => {
        if(getCommonLabelsByKey(resource.resourceKey)) {
            labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }

        return;
    });
    modifiedLabels.map((resource: any) => {
        if(getCommonLabelsByKey(resource.resourceKey)) {
            labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }

        return;
    });

    dispatch(setPaginationDocumentsPending(documentIds));

    try {
        const updatedDocuments = await api.paginationController.resetDocumentsPagination(
            redactionDocumentId,
            documentIds,
        );
        const { pageList: { currentDocument, currentPage } } = getState();

        dispatch(setPaginationDocumentsSuccess(updatedDocuments));

        if (currentDocument && currentPage) {
            dispatch(fetchDocumentMetadata(redactionDocumentId, currentDocument.id));
        }
    } catch (error) {

        dispatch(setPaginationDocumentsFailure(error));
        openErrorModalWindowPagination(error, dispatch, labels.warningLabel[changeLang(language)]);
    }
};

export const editPaginationDocuments = (): (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const {pageList: { selectedDocuments }, localization: {modifiedLabels}, localStorage: {language}} = getState();
        const labels = {
            editPaginationTitleLabel: initialLabel,
            noDocumentWarningLabel: initialLabel,
            multiplePageWarningLabel: initialLabel,
            paginationTitleLabel: initialLabel,
        };
        const langRule = changeLang(language);

        resourceBundle.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        if (selectedDocuments.length === 0) {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: EDIT_PAGINATION_MODAL,
                title: labels.editPaginationTitleLabel[langRule],
                message: labels.noDocumentWarningLabel[langRule],
            }));
        } else if (selectedDocuments.length !== 1) {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: EDIT_PAGINATION_MODAL,
                title: labels.editPaginationTitleLabel[langRule],
                message: labels.multiplePageWarningLabel[langRule],
            }));
        } else {
            dispatch(openModalWindow(EDIT_PAGINATION_MODAL, {
                id: PAGINATION_TITLE,
                title: labels.paginationTitleLabel[langRule],
            }));
        }
    };

export const ocrExistingDocuments = ({ documentIds, redactionDocumentId }: IModalIdsData):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        const ocrDocumentsData = documentIds.map((documentId: number): IOcrDocPage => ({
            documentId,
            autoDeskew: false,
            ocrDocumentCompletely: true, // for ignore pageIds
        }));

        dispatch(ocrDocumentPending(documentIds));

        try {
            await api.ocrExistingDocumentController.ocrExistingDocuments(redactionDocumentId, ocrDocumentsData);
            dispatch(ocrDocumentSuccess());

        } catch (error) {
            dispatch(ocrDocumentFailure());
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

export const checkPagesAndAutoDeskewDocuments = ({ documentIds, redactionDocumentId }: IModalIdsData):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            documentList: { files },
            localStorage: { language },
            localization: { modifiedLabels },
        } = getState();
        if (!Array.isArray(documentIds)) {
            documentIds = [documentIds];
        }
        const hasNotOcredDocuments = files.some((file: IFile) =>
            !file.isOcred && documentIds.includes(file.id));
        const ocrDocumentsData = documentIds.map((documentId: number): IOcrDocPage => ({
            documentId,
            autoDeskew: true,
            ocrDocumentCompletely: true, // for ignore pageIds
        }));
        const labels = {
            autoDeskewDocMessageLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if(getReleaseDateModalLabelsByKey(resource.resourceKey)) {
                labels[getReleaseDateModalLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getReleaseDateModalLabelsByKey(resource.resourceKey)) {
                labels[getReleaseDateModalLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        hasNotOcredDocuments ?
            dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: AUTO_DESKEW_DOCUMENT_MESSAGE,
                title: '',
                message: labels.autoDeskewDocMessageLabel[changeLang(language)],
                confirm: (): (dispatch: Dispatch, getState: () => IState) => void =>
                    autoDeskewDocuments(redactionDocumentId, ocrDocumentsData, documentIds),
            })) :
            dispatch(autoDeskewDocuments(redactionDocumentId, ocrDocumentsData, documentIds));
    };
export const autoDeskewDocuments = (
    redactionDocumentId: number,
    ocrDocumentsData: IOcrDocPage[],
    documentIds: number[],
):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(ocrDocumentPending(documentIds));

        try {
            await api.ocrExistingDocumentController.ocrExistingDocuments(redactionDocumentId, ocrDocumentsData);
            dispatch(ocrDocumentSuccess());

        } catch (error) {
            dispatch(ocrDocumentFailure());
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

export enum GoToDocumentDirections {
    NEXT = 'next',
    PREV = 'prev',
    FIRST = 'first',
    LAST = 'last',
}

export const goToDocument = (act: string): ThunkResult<void> => (dispatch: Dispatch, getState: () => IState): void => {
    const { redactor: { pdfSrc } } = getState();

    if (!pdfSrc) {
        return;
    }

    const {
        documentList: { files },
        reductionMetaData: { redactionDocumentId },
        pageList: { currentDocument: { id } },
    } = getState();
    const orderedFilesIds = files.map((file: IFile) => file.id);
    const idxCurrentFile = orderedFilesIds.indexOf(id);
    const handler = {
        [GoToDocumentDirections.NEXT]: orderedFilesIds[idxCurrentFile] === orderedFilesIds[orderedFilesIds.length - 1]
            ? orderedFilesIds[0]
            : orderedFilesIds[idxCurrentFile + 1],
        [GoToDocumentDirections.PREV]: orderedFilesIds[idxCurrentFile] === orderedFilesIds[0]
            ? orderedFilesIds[orderedFilesIds.length - 1]
            : orderedFilesIds[idxCurrentFile - 1],
        [GoToDocumentDirections.FIRST]: orderedFilesIds[0],
        [GoToDocumentDirections.LAST]: orderedFilesIds[orderedFilesIds.length - 1],
    };

    dispatch(clearOptionsState());
    dispatch(fetchDocumentMetadata(redactionDocumentId, handler[act]));
};

export const updatePaginationDocument = (redactionDocumentId: number, documentId: number): ThunkResult<Promise<void>> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(updatePaginationPending(documentId));

        try {
            const response = await api.redactionDocuments.getDocumentMetadata(redactionDocumentId, documentId);
            const { paginationRange } = response;

            dispatch(updatePaginationSuccess(documentId, paginationRange));
        } catch (error) {
            dispatch(updatePaginationFailure());
        }
    };

export const updateDocumentList = (redactionDocumentId: number, documentId: number):
    (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { pageList: { currentDocument, currentPage },
            stackList: { stackList }, documentList: { stackId: currentSelectedStackId } } = getState();

        dispatch(updateDocumentsListPending());

        try {
            const response = await api.redactionDocuments.getDocumentMetadata(redactionDocumentId, documentId);
            const { id } = response;

            response.stacks = stackList.filter((stack: IStackOptions): boolean => {
                return response.stackIds.some((stackId: number): boolean => stackId === stack.id);
            });

            if (!response.documentDate) {
                response.documentDate = '';
            }

            dispatch(updateDocumentsListSuccess(response, currentSelectedStackId));

            if (currentDocument.id === id) {
                dispatch(getCurrentDocumentSuccess(response));
                dispatch(fetchPaginatedPages(0));
                dispatch(getAllAnnotationsPerPage(
                    redactionDocumentId,
                    documentId,
                    currentPage.id,
                    currentPage.layers[0].id,
                ));
            }
        } catch (error) {
            dispatch(updateDocumentsListFailure(error));
        }
    };

export const updateDocumentsList = (redactionDocumentId: number): ThunkResult<Promise<void>> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const { documentList: { stackId } } = getState();

        if (stackId) {
            dispatch(fetchStackDocumentsList(redactionDocumentId, stackId, true));
        } else {
            dispatch(fetchDocumentList(redactionDocumentId, true));
        }
    };

export const deletePrereleaseDate = (redactionDocumentId: number, data: number[]):
    ThunkResult<Promise<void>> => async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        try {
            await api.releaseDateController.deletePrereleaseDate(redactionDocumentId, data);
            const { documentList: { stackId: currentSelectedStackId } } = getState();
            if (currentSelectedStackId !== 0 && currentSelectedStackId != null) {
                dispatch(fetchStackDocumentsList(redactionDocumentId, currentSelectedStackId));
            } else {
                dispatch(fetchDocumentList(redactionDocumentId));
            }
            dispatch(handleCloseModalWindow(RELEASE_MANAGEMENT_MODAL));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const deleteReleaseDate = (redactionDocumentId: number, data: number[]):
    ThunkResult<Promise<void>> => async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        try {
            await api.releaseDateController.deleteReleaseDate(redactionDocumentId, data);
            const { documentList: { stackId: currentSelectedStackId } } = getState();
            if (currentSelectedStackId !== 0 && currentSelectedStackId != null) {
                dispatch(fetchStackDocumentsList(redactionDocumentId, currentSelectedStackId));
            } else {
                dispatch(fetchDocumentList(redactionDocumentId));
            }
            dispatch(handleCloseModalWindow(RELEASE_MANAGEMENT_MODAL));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const updateReleaseDate = (redactionDocumentId: number, data: IUpdateModalReleaseData):
    ThunkResult<Promise<void>> => async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        try {
            await api.releaseDateController.updateReleaseDate(redactionDocumentId, data);
            const { documentList: { stackId: currentSelectedStackId } } = getState();
            if (currentSelectedStackId !== 0 && currentSelectedStackId != null) {
                dispatch(fetchStackDocumentsList(redactionDocumentId, currentSelectedStackId));
            } else {
                dispatch(fetchDocumentList(redactionDocumentId));
            }
            dispatch(handleCloseModalWindow(RELEASE_MANAGEMENT_MODAL));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const setReleaseDate = ({ documentIds, redactionDocumentId }: IModalIdsData): ThunkResult<Promise<void>> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const { localization: {modifiedLabels}, localStorage: {language} } = getState();
        const labels = {
            stackTitleLabel: initialLabel,
            selectDocWarningLabel: initialLabel,
            releaseManagementTitleLabel: initialLabel,
        };
        const langRule = changeLang(language);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (!documentIds.length) {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: DOCUMENT_STACK_MODAL,
                title: labels.stackTitleLabel[langRule],
                message: labels.selectDocWarningLabel[langRule],
            }));
        } else {
            dispatch(openModalWindow(RELEASE_DATE_PARAM, {
                id: RELEASE_MANAGEMENT_MODAL,
                title: labels.releaseManagementTitleLabel[langRule],
                message: labels.releaseManagementTitleLabel[langRule],
                modalSpecificProps: {
                    documentIds,
                    redactionDocumentId,
                    setRelease: true,
                },
            }));
        }
    };

export const clearReleaseDate = ({ documentIds, redactionDocumentId }: IModalIdsData): ThunkResult<Promise<void>> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const { localization: {modifiedLabels}, localStorage: {language} } = getState();
        const labels = {
            stackTitleLabel: initialLabel,
            selectDocWarningLabel: initialLabel,
            releaseManagementTitleLabel: initialLabel,
        };
        const langRule = changeLang(language);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (!documentIds.length) {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: DOCUMENT_STACK_MODAL,
                title: labels.stackTitleLabel[langRule],
                message: labels.selectDocWarningLabel[langRule],
            }));
        } else {
            dispatch(openModalWindow(RELEASE_DATE_PARAM, {
                id: RELEASE_MANAGEMENT_MODAL,
                title: labels.releaseManagementTitleLabel[langRule],
                message: labels.releaseManagementTitleLabel[langRule],
                modalSpecificProps: {
                    documentIds,
                    redactionDocumentId,
                    setRelease: false,
                },
            }));
        }
    };

export const getDocumentListColumnHeader = (lid: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(setDocumentListColumnHeaderPending());
        try {
            const response = await api.redactionDocuments.getDocumentListColumnHeader(lid);

            dispatch(setDocumentListColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const modifyDocumentListColumnHeader = (documentListColumnHeader: IHeader[], userId: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(modifyDocumentListColumnHeaderPending());

        const columns = documentListColumnHeader.map((columnsHeader: IHeader, index: number) => {
            return {
                ...columnsHeader,
                displayOrder: index + 1,
            };
        });

        try {
            const response = await api.redactionDocuments.modifyDocumentListColumnHeader(columns, userId);
            dispatch(modifyDocumentListColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const getResetedDocumentListColumnHeader = (userId: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(resetDocumentListColumnHeaderPending());
        try {
            const response = await api.redactionDocuments.resetDocumentListColumnHeader(userId);

            dispatch(resetDocumentListColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const openAutoAlignArticleStampsConfirmModal = ():
    (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            pageList: {
                currentDocument,
                selectedDocuments,
                currentPage,
            },
            localStorage: { language },
            localization: { modifiedLabels },
        } = getState();
        const labels = {
            warningLabel: initialLabel,
            yesLabel: initialLabel,
            noLabel: initialLabel,
            autoAlignAlertMessageLabel: initialLabel,
            noDocumentWarningLabel: initialLabel,
            autoAlignArticleStampsTitleLabel: initialLabel,
        };
        const langRule = changeLang(language);

        resourceBundle.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getEditPaginationLabelsByKey(resource.resourceKey)) {
                labels[getEditPaginationLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        if(selectedDocuments && selectedDocuments.length) {
            dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL,
                title: labels.warningLabel[langRule],
                message: labels.autoAlignAlertMessageLabel[langRule],
                okButtonTitle: labels.yesLabel[langRule],
                cancelButtonTitle: labels.noLabel[langRule],
                confirm: (): () => void => (): void => {
                    dispatch(applyDocumentAutoAlignArticleStamps());
                    handleCloseModalWindow(AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL);
                },
                reject: (): AnyAction => handleCloseModalWindow(AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL),
            }));
        } else {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                message: labels.noDocumentWarningLabel[langRule],
                title: labels.autoAlignArticleStampsTitleLabel[langRule],
            }));
        }

    };

export const applyDocumentAutoAlignArticleStamps = ():
    (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                userId,
                redactionDocumentId,
            },
            pageList: {
                currentDocument,
                selectedDocuments,
                currentPage,
            },
        } = getState();
        const isCurrentDocumentActive = currentDocument && selectedDocuments.includes(currentDocument.id);

        dispatch(applyDocumentAutoAlignArticleStampsPending());
        try {
            if(selectedDocuments.length || currentDocument) {
                dispatch(applySearchPending(true));

                await api.redactionDocuments.autoAlignDocumentStamps(redactionDocumentId,
                    userId, selectedDocuments);

                dispatch(applyDocumentAutoAlignArticleStampsSuccess());

                if(isCurrentDocumentActive) {
                    dispatch(getPageStamps({redactionDocumentId,
                        documentId: currentDocument.id, pageId: currentPage.id}));

                }

                dispatch(applySearchPending(false));

            }

        } catch (error) {
            dispatch(applySearchPending(false));
            dispatch(addError(error));
        }
    };

import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    IWatermarksManagementState,
    IWatermark,
} from '../reducers/modalWatermarks/watermarksManagement.model';

export const getWatermarks = (state: IState): IWatermarksManagementState => state.modalWatermarksManagement;

export const getWatermarksList = createSelector(
    [getWatermarks],
    (modalWatermarksManagement: IWatermarksManagementState): IWatermark[] =>
        modalWatermarksManagement.watermarksList,
);

export const getCurrentWatermark = createSelector(
    [getWatermarks],
    (modalWatermarksManagement: IWatermarksManagementState): IWatermark =>
        modalWatermarksManagement.currentWatermarkOptions,
);

export const getWatermarksPending = createSelector(
    [getWatermarks],
    (modalWatermarksManagement: IWatermarksManagementState): boolean =>
        modalWatermarksManagement.watermarksPending,
);

import * as React from 'react';
import Icon from '@mui/material/Icon';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { IComment, IReply } from '../../containers/comment/comment.model';
import './comment.style.scss';

export const CommentContent = ({ comment, author, date, icon, replies, markupType }: IComment): JSX.Element => {

    return (
        <div className='comment-wrapper'>
            {
                Comment({
                    author,
                    date,
                    comment,
                    icon: true,
                    markupType,
                })
            }
            {
                replies && replies.map((reply: IReply): JSX.Element => {
                    return (
                        <>
                            {Comment({
                                author: reply.author,
                                date: reply.date,
                                comment: reply.reply,
                                icon: false,
                            })}
                        </>
                    );
                })
            }

        </div>
    );
};

export const Comment = ({ comment, author, date, icon, markupType }: IComment): JSX.Element => {
    return (
        <>
            <div className={icon ? 'comment-info' : 'reply-info'}>
                {
                    icon &&
                    <div className='icon'>
                        <Icon>
                            <QuestionAnswerIcon />
                        </Icon>
                    </div>
                }
                <div className='comment-info-font-style author'>
                    {author}{markupType && (<span className='markup'>({markupType})</span>)}
                </div>
                <div className='comment-info-font-style'>
                    {date}
                </div>
            </div>
            <div className={icon ? 'comment' : 'reply'}>
                {comment}
                <div className='line' />
            </div>
        </>
    );
};

import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { addError } from './errorHandling';
import api from '../../api/reductionApi';
import {
  ASSIGN_DUPLICATES_PENDING,
  ASSIGN_DUPLICATES_SUCCESS,
  ASSIGN_DUPLICATES_FAILURE,
} from '../reducers/modalAssignDuplicates/constants';
import { IError } from '../common.model';
import { handleCloseAllModalWindows } from './modal';
import {
    IMasterDocumentDto,
} from '../../containers/modalWindowContents/modalAssignDuplicate/modalAssignDuplicate.model';
import { IState } from '../store';
import { fetchDuplicateReferences, clearDuplicateReferences } from './duplicates';
import { IDuplicateReferences } from '../../containers/duplicatePanels/duplicate.model';
import { ThunkDispatch } from 'redux-thunk/es/types';

export const assignDuplicatesPending = (): AnyAction =>
 action(ASSIGN_DUPLICATES_PENDING);
export const assignDuplicatesSuccess = (data: any): AnyAction =>
    action(ASSIGN_DUPLICATES_SUCCESS, data);
export const assignDuplicatesFailure = (error: IError): AnyAction =>
    action(ASSIGN_DUPLICATES_FAILURE, error);

export const addMasterDocument = (
    redactionDocumentId: number,
    data: IMasterDocumentDto,
): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { duplicatesReducer: { duplicateReferences } } = getState();

        dispatch(assignDuplicatesPending());

        try {
            const response = await api.masterDocumentController.addMasterDocument(redactionDocumentId, data);

            dispatch(assignDuplicatesSuccess(response));
            dispatch(clearDuplicateReferences());
            dispatch(handleCloseAllModalWindows());
            duplicateReferences.forEach((duplicate: IDuplicateReferences) => {
                dispatch(fetchDuplicateReferences(duplicate.documentId));
            });
        } catch (error) {
            dispatch(assignDuplicatesFailure(error));
            dispatch(addError(error));
        }
    };

import * as React from 'react';
import { sortBy, omit, get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import {
    emptyDisclosure,
    ID,
    NEW_DISCLOSURE_ID,
    REQUEST_TYPE,
    NAME,
    CONTACTS,
    RELEASE_SETTINGS,
    CONTACT_SETTINGS,
    DESCRIPTION,
    PAGE_OUT_NOTICE,
    REQUEST_TYPES_LIST,
    NAME_1,
    DESCRIPTION_1,
} from '../../../constants/disclosure.contstants';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { IDispatchProps, IProps, IStateProps } from './modalDisclosureManagement.model';
import './modalDisclosureManagement.style.scss';
import { IState as StoreState } from '../../../redux/store';
import SimpleTabs from '../../../components/simpleTabs/SimpleTabs';
import { fetchContactTypeList, fetchRequestTypeList } from '../../../redux/actions/amandaContent';
import { getRequestTypesListValues } from '../../../redux/selectors/amandaContent';
import {
    getAllDisclosureTypesList,
    getAllDisclosureTypesLoading,
    getAllDisclosureTypesUpdating,
} from '../../../redux/selectors/disclosureTypes';
import { Spinner } from '../../../components/spinner/Spinner';
import DisclosureList from './DisclosureList/DisclosureList';
import {
    CONFIRMATION_DIALOG_MODAL,
    DELETE_DISCLOSURE_MESSAGE,
    WARNING,
    CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
} from '../../../constants/messages.constants';
import { DISCLOSURE_MANAGEMENT } from '../../../constants/modal.constants';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import {
    deleteDisclosureType,
    postDisclosureType,
    putDisclosureType,
    fetchAllDisclosureTypeList,
} from '../../../redux/actions/disclosureTypes';
import { getDisclosureOptions } from '../../../redux/selectors/modalWindowDisclosure';
import {
    changeDisclosureOptions,
    clearDisclosureOptions,
    setDisclosureOptions,
} from '../../../redux/actions/modalWindowDisclosure';
import { IDisclosureOptions } from '../../../redux/reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import LinearProgress from '@mui/material/LinearProgress';
import BasicTab from './basicTab/BasicTab';
import ReleaseTab from './ReleaseTab/ReleaseTab';
import ContactTab from './ContactTab/ContactTab';
import { BASIC_SETTINGS } from '../../../constants/globalSettings.constants';
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import { validate } from '../../../utils/validate.util';
import { AnyObject, FormApi, FormState } from 'final-form';
import { CANCEL, CLOSE, SAVE } from '../../../constants/common.constants';
import { AT_LEAST_ONE_OF, MAX_LENGTH, REQUIRED, UNIQUE } from '../../../constants/validate.constants';
import { fetchGlobalSettings } from '../../../redux/actions/modalGlobalSettings';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import { initialLabel } from '../../../constants/localization.constants';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../localization/localizationData';

export const disclosureLabels = {
    selectDisclosureLabel: initialLabel,
    addNewLabel: initialLabel,
    basicSettingsLabel: initialLabel,
    releaseSettingsLabel: initialLabel,
    contactSettingsLabel: initialLabel,
    cancelLabel: initialLabel,
    saveLabel: initialLabel,
    activeLabel: initialLabel,
    nameLabel: initialLabel,
    nameFrenchLabel: initialLabel,
    descriptionLabel: initialLabel,
    descriptionFrenchLabel: initialLabel,
    requestTypeLabel: initialLabel,
    atleastOneRequiredLabel: initialLabel,
    selectAllLabel: initialLabel,
    includeInPaginationLabel: initialLabel,
    duplicateReferenceLabel: initialLabel,
    releasableLabel: initialLabel,
    exemptionRequiredLabel: initialLabel,
    pageOutNoticeLabel: initialLabel,
    countPageAsLabel: initialLabel,
    countDuplicateLabel: initialLabel,
    notRelevantLabel: initialLabel,
    receivedLabel: initialLabel,
    releasedLabel: initialLabel,
    reviewedLabel: initialLabel,
};

export const getDisclosureLabelsByKey = (key: string): string => {
    switch(key) {
        case 'MODAL_DISCLOSURE_SELECT_DISCLOSURE':
            return 'selectDisclosureLabel';
        case 'COMMON_LABEL_ADD_NEW':
            return 'addNewLabel';
        case 'MODAL_DISCLOSURE_BASIC_SETTINGS':
            return 'basicSettingsLabel';
        case 'MODAL_DISCLOSURE_RELEASE_SETTINGS':
            return 'releaseSettingsLabel';
        case 'MODAL_DISCLOSURE_CONTACT_SETTINGS':
            return 'contactSettingsLabel';
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'COMMON_LABEL_SAVE':
            return 'saveLabel';
        case 'COMMON_LABEL_WARNING':
            return 'warningLabel';
        case 'COMMON_LABEL_CHANGES_NOT_SAVED':
            return 'changesNotSavedLabel';
        case 'MODAL_DISCLOSURE_DELETE_WARNING':
            return 'deleteDisclosureLabel';
        case 'COMMON_LABEL_PRESS_OK_TO_CONTINUE':
            return 'pressOkLabel';
        case 'COMMON_LABEL_ACTIVE':
            return 'activeLabel';
        case 'COMMON_LABEL_NAME':
            return 'nameLabel';
        case 'COMMON_LABEL_NAME_FRENCH':
            return 'nameFrenchLabel';
        case 'COMMON_LABEL_DESCRIPTION':
            return 'descriptionLabel';
        case 'COMMON_LABEL_DESCRIPTION_FRENCH':
            return 'descriptionFrenchLabel';
        case 'COMMON_LABEL_REQUEST_TYPE':
            return 'requestTypeLabel';
        case 'COMMON_LABEL_AT_LEAST_ONE_REQUIRED':
            return 'atleastOneRequiredLabel';
        case 'COMMON_LABEL_SELECT_ALL':
            return 'selectAllLabel';
        case 'MODAL_DISCLOSURE_INCLUDE_IN_PAGINATION':
            return 'includeInPaginationLabel';
        case 'MODAL_DISCLOSURE_DUPLICATE_REFERENCE':
            return 'duplicateReferenceLabel';
        case 'MODAL_DISCLOSURE_RELEASABLE_FIELD':
            return 'releasableLabel';
        case 'MODAL_DISCLOSURE_EXEMPTION_REQUIRED':
            return 'exemptionRequiredLabel';
        case 'MODAL_DISCLOSURE_PAGE_OUT_NOTICE':
            return 'pageOutNoticeLabel';
        case 'MODAL_DISCLOSURE_COUNT_PAGE_AS':
            return 'countPageAsLabel';
        case 'MODAL_DISCLOSURE_COUNT_AS_DUPLICATE':
            return 'countDuplicateLabel';
        case 'MODAL_DISCLOSURE_COUNT_AS_NOT_RELEVANT':
            return 'notRelevantLabel';
        case 'MODAL_DISCLOSURE_COUNT_AS_RECEIVED':
            return 'receivedLabel';
        case 'MODAL_DISCLOSURE_COUNT_AS_RELEASED':
            return 'releasedLabel';
        case 'MODAL_DISCLOSURE_COUNT_AS_REVIEWED':
            return 'reviewedLabel';
        case 'COMMON_LABEL_MUST_BE_UNIQUE':
            return 'mustBeUniqueLabel';
        case 'COMMON_LABEL_AT_LEAST_ONE_OF_LIST':
            return 'atLeastOneOfListLabel';
        case 'COMMON_LABEL_FIELD_IS_MANDATORY':
            return 'mandatoryFieldLabel';
        case 'COMMON_LABEL_MAX_LENGTH':
            return 'maxLengthLabel';
        case 'COMMON_LABEL_MIN_LENGTH':
            return 'minLengthLabel';
        default: return '';
    }
};

const classNames = {
    disclosureModal: 'disclosure-modal',
    disclosureModalFooter: 'disclosure-modal-footer',
    disclosureModalBody: 'disclosure-modal-body',
    disclosureListWrap: 'disclosure-list-wrap',
    disclosureTabsWrap: 'disclosure-tabs-wrap',
    disclosureLoader: 'disclosure-modal-loader',
};

const validateFields = [
    {
        field: NAME,
        [REQUIRED]: true,
        [MAX_LENGTH]: 250,
        [UNIQUE]: true,
    },
    {
        field: DESCRIPTION,
        [MAX_LENGTH]: 2000,
    },
    {
        field: NAME_1,
        [MAX_LENGTH]: 250,
    },
    {
        field: DESCRIPTION_1,
        [MAX_LENGTH]: 2000,
    },
    {
        field: REQUEST_TYPE,
        [AT_LEAST_ONE_OF]: true,
    },
    {
        field: PAGE_OUT_NOTICE,
        [MAX_LENGTH]: 2000,
    },
];

export class ModalDisclosureManagementComponent extends React.Component<IProps> {
    public componentWillUnmount(): void {
        this.props.clearDisclosureOptions();
        this.props.handleDetectChanges(false);
    }

    public componentDidMount(): void {
        if (!this.props.redactionDocumentId) {
            this.props.fetchAllDisclosureTypeList();
        }

        this.props.fetchRequestTypeList();
        this.props.fetchContactTypeList();
        this.props.fetchGlobalSettings();
    }

    public getUniqueNamesOfDisclosure = (): string[] => {
        const { disclosureTypesList, disclosureOptions } = this.props;

        return disclosureTypesList.map((item: IDisclosureOptions) => {
            return disclosureOptions.id === item.id ? '' : item[NAME];
        });
    }

    public render(): JSX.Element {
        const {
            disclosureTypesLoading,
            disclosureTypesList,
            disclosureOptions,
            disclosureTypesUpdating,
            requestTypesListValues,
            handleDetectChanges,
            permissions: {
                ADD: addPermission,
                DELETE: deletePermission,
                EDIT: editPermission,
            },
            modifiedLabels,
            redactionLang,
        } = this.props;
        const langRule = changeLang(redactionLang);
        const labels = disclosureLabels;

        resourceBundle.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        const initialValues = {
            ...disclosureOptions,
            [REQUEST_TYPES_LIST]: requestTypesListValues,
        };

        const activeId = disclosureOptions[ID];
        const uniqueFields = { name: this.getUniqueNamesOfDisclosure() };
        const hasEditPermissions = editPermission || addPermission && !disclosureOptions[ID];

        return (
            <Form
                onSubmit={this.handleSave}
                initialValues={initialValues}
                validate={(values: any): { [param: string]: boolean } => validate(values, validateFields, uniqueFields,
                    labels, langRule)}
                render={({
                                               handleSubmit,
                                               pristine,
                                               submitting,
                                               invalid,
                                               dirty,
                                               form,
                                           }: FormRenderProps): JSX.Element => (
                    <form
                        className={classNames.disclosureModal}
                        onSubmit={handleSubmit}
                    >
                        <div className={classNames.disclosureModalBody}>
                            <div className={classNames.disclosureListWrap}>
                                {
                                    addPermission && (
                                        <Fab
                                            variant='extended'
                                            size='medium'
                                            color='primary'
                                            aria-label='Add'
                                            onClick={(): void => this.handleAddNew(form)}
                                            className='disclosure-add'
                                        >
                                            <AddIcon fontSize={'small'} />
                                            {labels ? labels.addNewLabel[langRule] : ''}
                                        </Fab>
                                    )
                                }
                                {
                                    disclosureTypesLoading ?
                                        <Spinner active={true} /> :
                                        <DisclosureList
                                            list={disclosureTypesList}
                                            activeId={activeId}
                                            handleClickDisclosure={(item: IDisclosureOptions): void =>
                                                this.handleClickDisclosure(item, form)}
                                            handleDeleteDisclosure={this.handleDeleteDisclosure}
                                            deletePermission={deletePermission}
                                            language={redactionLang}
                                        />
                                }
                            </div>
                            <div className={classNames.disclosureTabsWrap}>
                                {
                                    disclosureOptions[ID] !== null ?
                                        <SimpleTabs>
                                            <BasicTab
                                                disclosureId={disclosureOptions.id}
                                                title={labels.basicSettingsLabel[langRule]}
                                                isValid={true}
                                                form={form}
                                                formState={form.getState()}
                                                hasEditPermissions={hasEditPermissions}
                                                labels={labels}
                                                language={redactionLang}
                                            />
                                            <ReleaseTab
                                                title={labels.releaseSettingsLabel[langRule]}
                                                isValid={true}
                                                hasEditPermissions={hasEditPermissions}
                                                labels={labels}
                                                langRule={langRule}
                                            />
                                            <ContactTab
                                                title={labels.contactSettingsLabel[langRule]}
                                                isValid={true}
                                                hasEditPermissions={hasEditPermissions}
                                                language={redactionLang}
                                            />
                                        </SimpleTabs>
                                        :
                                        <div className='disclosure-empty'>
                                            {labels.selectDisclosureLabel[langRule]}
                                        </div>
                                }
                            </div>
                        </div>
                        <div className={classNames.disclosureModalFooter}>
                            <Tooltip title={labels.cancelLabel[langRule]} placement='top'>
                                <Button
                                    variant='outlined'
                                    onClick={(): void => this.handleCloseModal(form)}
                                    className='modal-window__buttons outlined'
                                    size='small'
                                >
                                    {labels.cancelLabel[langRule]}
                                </Button>
                            </Tooltip>
                            <Tooltip title={labels.saveLabel[langRule]} placement='top'>
                                <span>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        disabled={submitting || invalid || pristine}
                                        onClick={(): Promise<object | undefined> => handleSubmit()}
                                        className='primary'
                                    >
                                        {labels.saveLabel[langRule]}
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div className={classNames.disclosureLoader}>
                            {disclosureTypesUpdating && <LinearProgress />}
                        </div>
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={(): void => handleDetectChanges(this.isDisclosureOptionsChanged(form))}
                        />
                    </form>
                )}
            />
        );
    }

    private handleAddNew = (form: FormApi): void => {
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);
        const setDisclosure = (): void => {
            this.props.setDisclosureOptions(emptyDisclosure);
            form.reset();
        };

        resourceBundle.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (this.isDisclosureOptionsChanged(form)) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => setDisclosure(),
            });
        } else {
            setDisclosure();
        }
    }

    private handleSave = (values: AnyObject): void => {
        const disclosureOptions = { ...values };
        const currentId = this.props.disclosureOptions[ID];

        disclosureOptions.id = currentId;

        this.props.setDisclosureOptions(disclosureOptions);

        if (currentId === NEW_DISCLOSURE_ID) {
            const options = omit(disclosureOptions, ID);

            this.props.postDisclosureType(options);

            return;
        }

        this.props.putDisclosureType(disclosureOptions);
    }

    private isDisclosureOptionsChanged(form: FormApi): boolean {
        const { disclosureOptions, disclosureTypesList } = this.props;
        const currentId = disclosureOptions[ID];

        if (currentId === null) { // not selected disclosure
            return false;
        }

        const formValues = omit(form.getState().values, REQUEST_TYPES_LIST);

        const currentInfo = {
            ...formValues,
            [NAME]: get(formValues, NAME, ''),
            [DESCRIPTION]: get(formValues, DESCRIPTION, ''),
            [PAGE_OUT_NOTICE]: get(formValues, PAGE_OUT_NOTICE, ''),
            [REQUEST_TYPE]: sortBy(formValues[REQUEST_TYPE]),
            [CONTACTS]: sortBy(formValues[CONTACTS]),
        };

        if (currentId === NEW_DISCLOSURE_ID) {
            return !isEqual(emptyDisclosure, currentInfo);
        }

        const initialDisclosureOptions = disclosureTypesList
            .find((i: IDisclosureOptions) => currentId === i.id);
        const initialInfo = {
            ...initialDisclosureOptions,
            [NAME]: get(initialDisclosureOptions, NAME, ''),
            [DESCRIPTION]: get(initialDisclosureOptions, DESCRIPTION, ''),
            [PAGE_OUT_NOTICE]: get(formValues, PAGE_OUT_NOTICE, ''),
            [REQUEST_TYPE]: sortBy(initialDisclosureOptions[REQUEST_TYPE]),
            [CONTACTS]: sortBy(initialDisclosureOptions[CONTACTS]),
        };

        return !isEqual(initialInfo, currentInfo);
    }

    private handleClickDisclosure = (item: IDisclosureOptions, form: FormApi): void => {
        const prevItemId = this.props.disclosureOptions[ID];
        const currItemId = item.id;
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (prevItemId === currItemId) {
            return;
        }

        const setDisclosure = (): void => {
            this.props.setDisclosureOptions(item);
        };

        if (this.isDisclosureOptionsChanged(form)) {
            this.props.setDisclosureOptions(form.getState().values);
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => setDisclosure(),
            });
        } else {
            setDisclosure();
        }
    }

    private handleDeleteDisclosure = (id: number): void => {
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            pressOkLabel: initialLabel,
            deleteDisclosureLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: `Delete-${id}`,
            title: labels.warningLabel[langRule],
            message: <div>{`${labels.deleteDisclosureLabel[langRule]}? ${labels.pressOkLabel[langRule]}`}</div>,
            confirm: (): () => void => (): void => this.props.deleteDisclosureType(
                id,
                this.props.disclosureOptions[ID] === id,
            ),
        });
    }

    private handleCloseModal = (form: FormApi): void => {
        const { modifiedLabels, redactionLang } = this.props;
        const labels = {
            warningLabel: initialLabel,
            changesNotSavedLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (this.isDisclosureOptionsChanged(form)) {
            this.props.setDisclosureOptions(form.getState().values);
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => this.props.handleCloseModalWindow(DISCLOSURE_MANAGEMENT),
            });
        } else {
            this.props.handleCloseModalWindow(DISCLOSURE_MANAGEMENT);
        }
    }
}

const mapStateToProps = (state: StoreState): IStateProps => ({
    disclosureOptions: getDisclosureOptions(state),
    disclosureTypesList: getAllDisclosureTypesList(state),
    disclosureTypesLoading: getAllDisclosureTypesLoading(state),
    disclosureTypesUpdating: getAllDisclosureTypesUpdating(state),
    requestTypesListValues: getRequestTypesListValues(state),
    redactionDocumentId: getRedactionDocumentId(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    postDisclosureType: (data: IDisclosureOptions): void => {
        dispatch(postDisclosureType(data));
    },
    putDisclosureType: (data: IDisclosureOptions): void => {
        dispatch(putDisclosureType(data));
    },
    deleteDisclosureType: (id: number, needsClearState: boolean): void => {
        dispatch(deleteDisclosureType(id, needsClearState));
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
    handleCloseModalWindow: (id: string): void => {
        dispatch(handleCloseModalWindow(id));
    },
    fetchRequestTypeList: (): void => {
        dispatch(fetchRequestTypeList());
    },
    fetchContactTypeList: (): void => {
        dispatch(fetchContactTypeList());
    },
    setDisclosureOptions: (data: IDisclosureOptions): void => {
        dispatch(setDisclosureOptions(data));
    },
    changeDisclosureOptions: (data: IDisclosureOptions): void => {
        dispatch(changeDisclosureOptions(data));
    },
    clearDisclosureOptions: (): void => {
        dispatch(clearDisclosureOptions());
    },
    fetchGlobalSettings: (): void => {
        dispatch(fetchGlobalSettings());
    },
    fetchAllDisclosureTypeList: (): void => {
        dispatch(fetchAllDisclosureTypeList());
    },
});

export const ModalDisclosureManagement = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalDisclosureManagementComponent);

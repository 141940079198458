import { createSelector } from 'reselect';
import {
    IDisclosureContactsState,
    IPeopleRecords,
    IDisclosureContacts,
    IAssignedContacts,
} from '../reducers/disclosureContacts/disclosureContacts.model';
import { IState } from '../store';

export const getModalDisclosureContacts = (state: IState): IDisclosureContactsState => state.modalDisclosureContacts;

export const getPeopleTypeByDisclosure = createSelector(
    [getModalDisclosureContacts],
    (modalDisclosureContacts: IDisclosureContactsState): IDisclosureContacts[] =>
        modalDisclosureContacts.peopleTypeByDisclosure,
);

export const getModalDisclosureContactsLoading = createSelector(
    [getModalDisclosureContacts],
    (modalDisclosureContacts: IDisclosureContactsState): boolean =>
        modalDisclosureContacts.peopleTypeByDisclosurePending,
);

export const getSearchPeopleRecordsLoading = createSelector(
    [getModalDisclosureContacts],
    (modalDisclosureContacts: IDisclosureContactsState): boolean =>
        modalDisclosureContacts.searchPeopleRecordsPending,
);

export const getSearchPeopleRecords = createSelector(
    [getModalDisclosureContacts],
    (modalDisclosureContacts: IDisclosureContactsState): IPeopleRecords[] =>
        modalDisclosureContacts.searchPeopleRecords,
);

export const getAssignedPeopleRecords = createSelector(
    [getModalDisclosureContacts],
    (modalDisclosureContacts: IDisclosureContactsState): IAssignedContacts[] =>
        modalDisclosureContacts.assignedContacts,
);

export const getAssignedPeopleRecordsPending = createSelector(
    [getModalDisclosureContacts],
    (modalDisclosureContacts: IDisclosureContactsState): boolean =>
        modalDisclosureContacts.assignedContactsPending,
);

import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import {
    DELETE_DISCLOSURE_TYPE_FAILURE,
    DELETE_DISCLOSURE_TYPE_PENDING,
    DELETE_DISCLOSURE_TYPE_SUCCESS,
    GET_ALL_DISCLOSURE_TYPE_FAILURE,
    GET_ALL_DISCLOSURE_TYPE_PENDING,
    GET_ALL_DISCLOSURE_TYPE_SUCCESS,
    GET_CURRENT_DISCLOSURE_TYPE_FAILURE,
    GET_CURRENT_DISCLOSURE_TYPE_PENDING,
    GET_CURRENT_DISCLOSURE_TYPE_SUCCESS,
    GET_DUPLICATED_DISCLOSURE_TYPE_PENDING,
    GET_DUPLICATED_DISCLOSURE_TYPE_FAILURE,
    GET_DUPLICATED_DISCLOSURE_TYPE_SUCCESS,
    POST_DISCLOSURE_TYPE_FAILURE,
    POST_DISCLOSURE_TYPE_PENDING,
    POST_DISCLOSURE_TYPE_SUCCESS,
    PUT_DISCLOSURE_TYPE_FAILURE,
    PUT_DISCLOSURE_TYPE_PENDING,
    PUT_DISCLOSURE_TYPE_SUCCESS,
    UPDATE_ALL_DOCUMENTS_IN_LIST,
} from '../reducers/disclosureTypes/constants';
import { action } from 'typesafe-actions';
import { IError } from '../common.model';
import * as constants from '../../constants/disclosure.contstants';
import { setDisclosureOptions, clearDisclosureOptions } from './modalWindowDisclosure';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from './modal';
import { ALERT_DIALOG_MODAL, WARNING } from '../../constants/messages.constants';
import {
    IDisclosureOptions, IFetchDisclosurOption,
    IPostDisclosureOptions,
} from '../reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import {
    disclosureCountPage,
    DUPLICATE,
    IS_DUPLICATE,
    IS_NOT_RELEVANT,
    IS_RECEIVED,
    IS_RELEASED, IS_REVIEWED, NOT_RELEVANT, RECEIVED, RELEASED, REVIEWED,
} from '../../constants/disclosure.contstants';
import { addError } from './errorHandling';
import { compact } from 'lodash';
import { IState } from '../store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IFile } from '../reducers/documentList/documentList.model';
import {
    updateInfoDocumentInDocumentList,
    updateDocumentsListWithList,
    changeDocumentDisclosurePending,
    changeDocumentDisclosureComplete,
} from './documentList';
import { changePagesDisclosurePending, fetchPaginatedPagesSilent, getCurrentDocumentSuccess } from './pageList';
import { ARTICLE_STAMPS_MODAL, SELECT_CONSTANT_MODAL } from '../../containers/modalWindowContents';
import { flatten } from 'lodash';
import { STAMP } from '../../constants/common.constants';
import { getPageConsultee } from './pageMetadata';
import { IDocument, IPages } from '../reducers/pageList/pageList.model';
import { IAssignedContactsDto } from '../reducers/disclosureContacts/disclosureContacts.model';
import { changeLang, getCommonLabelsByKey } from './localization';
import { initialLabel } from '../../constants/localization.constants';
import { getConsulteesLabelsByKey } from '../../containers/IndexMetadataConsultees/IndexMetadataConsultees';
import resourceBundle from '../../containers/localization/localizationData';

const modifyData = (data: IDisclosureOptions): IPostDisclosureOptions => {

    const disclosureCountPages = compact(Object.keys(disclosureCountPage).map((key: string) =>
        data[disclosureCountPage[key]] ? key : null,
    ));

    return {
        disclosureTypeContact: {
            contacts: data[constants.CONTACTS],
        },
        disclosureTypeBasic: {
            description: data[constants.DESCRIPTION],
            isActive: data[constants.IS_ACTIVE],
            name: data[constants.NAME],
            requestType: data[constants.REQUEST_TYPE],
            name1: data[constants.NAME_1],
            description1: data[constants.DESCRIPTION_1],
        },
        disclosureTypeRelease: {
            disclosureCountPages,
            borderColor: data[constants.BORDER_COLOR],
            isDuplicateRefRequired: data[constants.IS_DUPLICATE_REF_REQUIRED],
            isExemptionRequired: data[constants.IS_EXEMPTION_REQUIRED],
            isInPagination: data[constants.IS_IN_PAGINATION],
            pageOutNotice: data[constants.PAGE_OUT_NOTICE],
            isReleasableState: data[constants.IS_RELEASABLE_STATE],
        },
        id: data[constants.ID],
    };
};

export const fetchAllDisclosureListPending = (): AnyAction => action(GET_ALL_DISCLOSURE_TYPE_PENDING);
export const fetchAllDisclosureListSuccess = (dataList: IDisclosureOptions[]): AnyAction =>
    action(GET_ALL_DISCLOSURE_TYPE_SUCCESS, dataList);
export const fetchAllDisclosureListFailure = (error: IError): AnyAction =>
    action(GET_ALL_DISCLOSURE_TYPE_FAILURE, error);

export const fetchCurrentDisclosureListPending = (): AnyAction => action(GET_CURRENT_DISCLOSURE_TYPE_PENDING);
export const fetchCurrentDisclosureListSuccess = (dataList: IDisclosureOptions[]): AnyAction =>
    action(GET_CURRENT_DISCLOSURE_TYPE_SUCCESS, dataList);
export const fetchCurrentDisclosureListFailure = (error: IError): AnyAction =>
    action(GET_CURRENT_DISCLOSURE_TYPE_FAILURE, error);

export const fetchDuplicatedDisclosureListPending = (): AnyAction => action(GET_DUPLICATED_DISCLOSURE_TYPE_PENDING);
export const fetchDuplicatedDisclosureListSuccess = (dataList: IDisclosureOptions[]): AnyAction =>
    action(GET_DUPLICATED_DISCLOSURE_TYPE_SUCCESS, dataList);
export const fetchDuplicatedDisclosureListFailure = (error: IError): AnyAction =>
    action(GET_DUPLICATED_DISCLOSURE_TYPE_FAILURE, error);

export const postDisclosureTypePending = (): AnyAction =>
    action(POST_DISCLOSURE_TYPE_PENDING);
export const postDisclosureTypeSuccess = (data: IDisclosureOptions): AnyAction =>
    action(POST_DISCLOSURE_TYPE_SUCCESS, data);
export const postDisclosureTypeFailure = (error: IError): AnyAction =>
    action(POST_DISCLOSURE_TYPE_FAILURE, error);

export const putDisclosureTypePending = (): AnyAction =>
    action(PUT_DISCLOSURE_TYPE_PENDING);
export const putDisclosureTypeSuccess = (data: IDisclosureOptions): AnyAction =>
    action(PUT_DISCLOSURE_TYPE_SUCCESS, data);
export const putDisclosureTypeFailure = (error: IError): AnyAction =>
    action(PUT_DISCLOSURE_TYPE_FAILURE, error);

export const deleteDisclosureTypePending = (): AnyAction =>
    action(DELETE_DISCLOSURE_TYPE_PENDING);
export const deleteDisclosureTypeSuccess = (id: number): AnyAction =>
    action(DELETE_DISCLOSURE_TYPE_SUCCESS, id);
export const deleteDisclosureTypeFailure = (error: IError): AnyAction =>
    action(DELETE_DISCLOSURE_TYPE_FAILURE, error);

export const updateAllDocumentsInList = (docs: IDocument[]): AnyAction => action(UPDATE_ALL_DOCUMENTS_IN_LIST, docs);

// TODO we have different cases, so need any, but if we refactoring own code we can fix it
export const countPages = (disclosure: any): IDisclosureOptions => {
    return {
        ...disclosure,
        [IS_DUPLICATE]: disclosure.disclosureCountPages.includes(DUPLICATE),
        [IS_NOT_RELEVANT]: disclosure.disclosureCountPages.includes(NOT_RELEVANT),
        [IS_RECEIVED]: disclosure.disclosureCountPages.includes(RECEIVED),
        [IS_RELEASED]: disclosure.disclosureCountPages.includes(RELEASED),
        [IS_REVIEWED]: disclosure.disclosureCountPages.includes(REVIEWED),
    };
};

export const fetchAllDisclosureTypeList = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchAllDisclosureListPending());
        try {
            const response = await api.disclosureTypes.getAllDisclosureTypes();
            if (!response) {
                throw new Error('Empty response');
            }
            const data = response.map((disclosure: IFetchDisclosurOption) => countPages(disclosure));

            dispatch(fetchAllDisclosureListSuccess(data));
        } catch (error) {
            dispatch(fetchAllDisclosureListFailure(error));
        }
    };

export const fetchCurrentDisclosureTypeList = (redactionDocumentId: number): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchCurrentDisclosureListPending());
        try {
            const response = await api.disclosureTypes.getCurrentDisclosureTypes(redactionDocumentId);
            const data = response.map((disclosure: IFetchDisclosurOption) => countPages(disclosure));

            dispatch(fetchCurrentDisclosureListSuccess(data));
        } catch (error) {
            dispatch(fetchCurrentDisclosureListFailure(error));
            dispatch(addError(error));
        }
    };

export const fetchDuplicatedDisclosureTypeList = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchDuplicatedDisclosureListPending());
        try {
            const response = await api.disclosureTypes.getAllDisclosureTypes(true);

            dispatch(fetchDuplicatedDisclosureListSuccess(response));
        } catch (error) {
            dispatch(fetchDuplicatedDisclosureListFailure(error));
            dispatch(addError(error));
        }
    };

export const postDisclosureType = (data: IDisclosureOptions):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        dispatch(postDisclosureTypePending());

        const { reductionMetaData: { redactionDocumentId } } = getState();

        try {
            const response = await api.disclosureTypes.postDisclosureType(modifyData(data));
            const resultDisclosure = countPages(response);

            dispatch(postDisclosureTypeSuccess(resultDisclosure));
            dispatch(setDisclosureOptions(resultDisclosure));

            if (redactionDocumentId) {
                dispatch(fetchCurrentDisclosureTypeList(redactionDocumentId));
            }
        } catch (error) {
            dispatch(postDisclosureTypeFailure(error));
            dispatch(addError(error));
        }
    };

export const putDisclosureType = (data: IDisclosureOptions):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        dispatch(putDisclosureTypePending());

        const { reductionMetaData: { redactionDocumentId } } = getState();

        try {
            const response = await api.disclosureTypes.updateDisclosureType(data.id, modifyData(data));
            const resultDisclosure = countPages(response);

            dispatch(putDisclosureTypeSuccess(resultDisclosure));
            dispatch(setDisclosureOptions(resultDisclosure));
            dispatch(fetchCurrentDisclosureTypeList(redactionDocumentId));
        } catch (error) {
            dispatch(putDisclosureTypeFailure(error));
            dispatch(addError(error));
        }
    };

export const deleteDisclosureType = (id: number, needsClearState: boolean):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        dispatch(deleteDisclosureTypePending());

        const {
            reductionMetaData: { redactionDocumentId },
            localization: {modifiedLabels},
            localStorage: {language},
        } = getState();
        const labels = { warningLabel: initialLabel};

        resourceBundle.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        try {
            await api.disclosureTypes.deleteDisclosureType(id);

            if (needsClearState) {
                dispatch(clearDisclosureOptions());
            }

            dispatch(deleteDisclosureTypeSuccess(id));

            if (redactionDocumentId) {
                dispatch(fetchCurrentDisclosureTypeList(redactionDocumentId));
            }
        } catch (error) {
            dispatch(deleteDisclosureTypeFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: labels.warningLabel[changeLang(language)],
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

const replaceSimpleDisclosureForDocuments = async (
    selectedDocuments: number[],
    files: IFile[],
    toId: number,
    fromId: number,
    redactionDocumentId: number,
    dispatch: ThunkDispatch<IState, null, AnyAction>,
    currentDocument: IDocument,
): Promise<void> => {
        const pageDisclosureDto = {
            documentIds: selectedDocuments,
            pageContacts: [],
            exemptionIds: [],
            previousDisclosureId: fromId,
        };

        const response = await api.disclosureController.updateDocumentDisclosure(
            redactionDocumentId, toId, pageDisclosureDto);
        const currentDocId = currentDocument && currentDocument.id;
        const isChangesInCurrentDoc = selectedDocuments.some((docId: number) => docId === currentDocId);

        if (isChangesInCurrentDoc) {
            dispatch(fetchPaginatedPagesSilent());
        }

        dispatch(updateDocumentsListWithList((response as any[])));
        dispatch(handleCloseAllModalWindows());
    };

export const refreshResultDisclosureForDocument = (documentId: number):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            documentList: {
                files,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
        } = getState();

        const disclosure = await api.disclosureTypes.calculateDocumentDisclosureType(
            redactionDocumentId, documentId);
        const file = files.find(({id}: IFile) => id === documentId);
        const newFile = {
            ...file,
            disclosureType: disclosure.disclosureTypeName,
        };

        dispatch(updateInfoDocumentInDocumentList(newFile));
    };

export const replaceDocumentsDisclosure = (fromId: number, toId: number):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            disclosureTypes: {
                disclosureType,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                selectedDocuments,
                currentDocument,
            },
            documentList: {
                files,
            },
            localStorage: { language },
            localization: { modifiedLabels },
        } = getState();
        const currentDisclosure = disclosureType
            .find((disclosure: IDisclosureOptions) => disclosure.id === toId);
        const langRule = changeLang(language);
        const labels = { consultessModalTitleLabel: initialLabel };

        resourceBundle.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        dispatch(changeDocumentDisclosurePending(selectedDocuments));

        if (currentDocument) {
            dispatch(changePagesDisclosurePending(currentDocument.pages.map((item: IPages) => item.id)));
        }

        if (!currentDisclosure) {
            return;
        }

        const { contacts, isExemptionRequired } = currentDisclosure;
        const isContactsRequired = !!contacts && !!contacts.length;

        if (!isContactsRequired && !isExemptionRequired) {
            await replaceSimpleDisclosureForDocuments(selectedDocuments, files, toId, fromId, redactionDocumentId,
                dispatch, currentDocument);

            dispatch(changeDocumentDisclosureComplete(selectedDocuments));

            return;
        }

        if (isContactsRequired && !isExemptionRequired) {
            dispatch(openModalWindow(SELECT_CONSTANT_MODAL, {
                id: SELECT_CONSTANT_MODAL,
                title: labels.consultessModalTitleLabel[langRule],
                modalSpecificProps: {
                    redactionDocumentId,
                    documentId: selectedDocuments,
                    disclosureTypeId: toId,
                    needReplace: true,
                    fromId,
                },
            }));

            return;
        }

        if (isExemptionRequired) {
            const articleModalSpecificData = {
                redactionDocumentId,
                documentId: selectedDocuments,
                disclosureTypeId: toId,
                fromId,
            };

            dispatch(openModalWindow(ARTICLE_STAMPS_MODAL, {
                id: STAMP,
                modalSpecificProps: {
                    setRequiredExemptions: true,
                    articleModalSpecificData,
                    isContactsRequired,
                    needReplace: true,
                },
            }));
        }
    };

export const replaceDocumentsDisclosureWithAdditionalData =
    (fromId: number, toId: number, pageContacts: IAssignedContactsDto[], exemptionIds: number[] = []):
        (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
        async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
            const {
                reductionMetaData: {
                    redactionDocumentId,
                },
                pageList: {
                    selectedDocuments,
                    currentDocument,
                    currentPage,
                },
            } = getState();
            const pageDisclosureDto = {
                documentIds: selectedDocuments,
                pageContacts,
                exemptionIds,
                previousDisclosureId: fromId,
            };
            const response = await api.disclosureController
                .updateDocumentDisclosure(redactionDocumentId, toId, pageDisclosureDto);
            const currentDocId = currentDocument && currentDocument.id;
            const isChangesInCurrentDoc = selectedDocuments.some((docId: number) => docId === currentDocId);

            if (isChangesInCurrentDoc) {
                if(currentPage) {
                    dispatch(getPageConsultee(redactionDocumentId, currentDocId, currentPage.id));
                }

                dispatch(fetchPaginatedPagesSilent());
            }

            dispatch(updateDocumentsListWithList((response as any[])));
            dispatch(handleCloseAllModalWindows());
            dispatch(changeDocumentDisclosureComplete(selectedDocuments));
        };

import * as React from 'react';
import { decode } from 'base-64';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { curry, get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from '../../../node_modules/react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { IHandlers } from '../../app.model';
import { HotKeys } from 'react-hotkeys';
import {
    DocumentListKeys,
    HeaderMenuKeys,
    PageListKeys,
    RedactorKeys,
    RedactorPageKeys,
    hotKeys,
} from '../../hotKeys';
import { getModalList } from '../../redux/selectors/modal';
import { copyShapes, pastShapes, cutShapes, pageScale, pageSize, deleteLinkedShapes, setContextMenuStartPoint } from '../../redux/actions/redactor';
import { setHeaderActiveItem } from '../../redux/actions/header';
import { goToPage } from '../../redux/actions/pageList';
import { goToDocument } from '../../redux/actions/documentList';
import { getDocument } from '../../redux/selectors/redactor';
import { openModalWindow, handleCloseModalWindow, handleCloseAllModalWindows } from '../../redux/actions/modal';
import { fetchScannerSettings } from '../../redux/actions/scanner';
import { getContextMenuAccordingToDisclosure } from '../../redux/selectors/contextMenu';
import { getRedactionDocumentId, getModalWindowPermissions } from '../../redux/selectors/initialization';
import { HeaderMainMenus } from '../../constants/headerMenu.constants';
import { scaleDirection } from '../../constants';
import ModalWindow from './../../components/modalWindow/ModalWindow';
import ContextDynamicMenu from './../../components/contextMenu/ContextMenu';
import { HIGHLIGHT_ICON_ID, SEVER_ICON_ID } from '../../components/svgIcons/svgIcons';
import { clickEvent, contextMenuEvent, mouseUpEvent } from '../../utils/event.utils';
import { zoom } from '../../utils/pdfControl.util';
import { ARTICLE_STAMPS_MODAL, CUSTOM_STAMPS_MODAL } from '../modalWindowContents';
import {
    ACTIVE_PAGE_PATH,
    STAMP,
    FOLDER_RSN,
    SSO_PARAM,
    SSO_LID,
    FOLDER_RSN_PARAM,
    LANG_PARAM,
    MODE_PARAM,
} from '../../constants/common.constants';
import { GoToPage } from '../../components/navigation/Navigation';
import Header from './../header/Header';
import { GoToDocumentDirections } from '../../redux/actions/documentList';
import theme from './../../styles/MuiThemes';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { IMainContainerProps } from './mainContainer.model';
import {
    createRedactionDocumentFirstLogin,
} from '../../redux/actions/initialization/initialization';
import { setRedactionLang, setRedactionMode, setReductionLid } from '../../redux/actions/localStorage';
import {
    getInitialDataLoaded,
    getMainModulesPermissions,
} from '../../redux/selectors/initialization';
import { getRedactionLanguage, getRedactionMode, getReductionLid } from '../../redux/selectors/localStorage';
import { REQUEST_SEARCH_MODAL } from '../../constants/modalRequestSearch.constants';
import { fetchInfoController } from '../../redux/actions/infoController';
import { getQueryStringParams } from '../../utils/queryString.util';
import { b64DecodeUnicode, b64EncodeUnicode } from '../../utils/encode.util';
import { setSelectedAdvanceSearchedDocument } from '../../redux/actions/advanceSearch';
import { ISelectedDocument } from '../../redux/reducers/advanceSearch/advanceSearch.model';
import { undoShapes } from '../../redux/actions/annotations';
import { initialLabel } from '../../constants/localization.constants';
import resourceBundle from '../../containers/localization/localizationData';
import { getModifiedLabels } from '../../redux/selectors/localization';

export const MainContainer = ({ children, location, routeParams }: IMainContainerProps): JSX.Element => {
    const modal = useSelector(getModalList);
    const contextMenu = useSelector(getContextMenuAccordingToDisclosure);
    const isInitialDataLoaded = useSelector(getInitialDataLoaded);
    const {contextMenuList, contextMenuProps, contextMenuType} = contextMenu;
    const redactionDocumentId = useSelector(getRedactionDocumentId);
    const documentData = useSelector(getDocument);
    const reductionLid = useSelector(getReductionLid);    
    const reductionMode = useSelector(getRedactionMode);
    const modalWindowPermissions = useSelector(getModalWindowPermissions);
    const { isDenyAccess, showHeader } = useSelector(getMainModulesPermissions);
    const redactionLang = useSelector(getRedactionLanguage);
    const modifiedLabels = useSelector(getModifiedLabels);

    const dispatch = useDispatch();
    const handleOpenModalWindow = (type: string, message: IModalProps): void =>
        dispatch(openModalWindow(type, message));
    const handleKeysOpenModalWindow = (type: string, modalProps: IModalProps, e: Event): void => {
        e.preventDefault();
        handleOpenModalWindow(type, modalProps);
    };
    const closeModalWindow = (id: string): void => dispatch(handleCloseModalWindow(id));
    const handleFetchInfoController = (): void => dispatch(fetchInfoController());
    const setLid = (lid: string): void => dispatch(setReductionLid(lid));
    const setLang = (lang: string): void => dispatch(setRedactionLang(lang));
    const setMode = (mode: string): void => dispatch(setRedactionMode(mode));
    const closeAllModalWindows = (): void => dispatch(handleCloseAllModalWindows());
    const handleAction = (action: any, formData?: any): void => dispatch(action(formData));
    const createReductionMeta = (folderRsn: string): void =>
        dispatch(createRedactionDocumentFirstLogin(folderRsn));
    const handleFetchScannerSettings = (): void => dispatch(fetchScannerSettings());
    const handleDeleteShapes = (): void => {
        document.dispatchEvent(mouseUpEvent);
        dispatch(deleteLinkedShapes(false));
    };
    const setCoordinatesForContextMenu = (coordinate: {x: number, y: number}): void =>
        dispatch(setContextMenuStartPoint(coordinate));

    const handleCopyShapes = (): void => dispatch(copyShapes());
    const handlePastShapes = (): void => dispatch(pastShapes());
    const handleCutShapes = (): void => dispatch(cutShapes());
    const handleUndoShapes = (): void => dispatch(undoShapes());
    const openHeaderMenu = (name: string, e: Event): void => {
        e.preventDefault();
        dispatch(setHeaderActiveItem(name));
    };
    const changeZoom  = (direction: string, e: Event): void => {
        e.preventDefault();

        const scale = zoom(direction, documentData.scale);

        dispatch(pageScale(scale));
        dispatch(pageSize(null));
    };

    const handleGoToPage = (act: string): void => dispatch(goToPage(act));
    const handleGoToDocument = (act: string): void => dispatch(goToDocument(act));
    const triggerSetDisclosureEvent = (selector: string, customEvent: MouseEvent, e: Event): void => {
        const element = document.querySelector(selector);

        if(element && customEvent.type === 'contextmenu') {
          setCoordinatesForContextMenu({
              x: element.getBoundingClientRect().left + 20,
              y: element.getBoundingClientRect().top + 5,
            });
        }

        triggerEvent(selector, customEvent, e);
    };
    const triggerEvent = (selector: string, customEvent: MouseEvent, e: Event): void => {
      e.preventDefault();

      const element = document.querySelector(selector);

      if(element) {
        element.dispatchEvent(customEvent);
      }

    };

    const setSelectedDocumentDetails = (selectedDetails: ISelectedDocument): void =>
    dispatch(setSelectedAdvanceSearchedDocument(selectedDetails));

    const handlers: IHandlers = {
        [RedactorKeys.DELETE_SHAPES]: handleDeleteShapes,
        [RedactorKeys.COPY_SHAPES]: handleCopyShapes,
        [RedactorKeys.PAST_SHAPES]: handlePastShapes,
        [RedactorKeys.CUT_SHAPES]: handleCutShapes,
        [RedactorKeys.UNDO_SHAPES]: handleUndoShapes,
        [HeaderMenuKeys.MENU_FILE]: curry(openHeaderMenu)(HeaderMainMenus.FILE),
        [HeaderMenuKeys.MENU_ACTION]: curry(openHeaderMenu)(HeaderMainMenus.ACTION),
        [HeaderMenuKeys.MENU_DOCUMENT]: curry(openHeaderMenu)(HeaderMainMenus.DOCUMENT),
        [HeaderMenuKeys.MENU_PAGE]: curry(openHeaderMenu)(HeaderMainMenus.PAGE),
        [HeaderMenuKeys.MENU_ADMIN]: curry(openHeaderMenu)(HeaderMainMenus.ADMIN),
        [HeaderMenuKeys.MENU_HELP]: curry(openHeaderMenu)(HeaderMainMenus.HELP),
        [RedactorPageKeys.ZOOM_IN]: curry(changeZoom)(scaleDirection.UP),
        [RedactorPageKeys.ZOOM_OUT]: curry(changeZoom)(scaleDirection.DOWN),
        [RedactorPageKeys.ADD_SEVER]: curry(triggerEvent)(`#${SEVER_ICON_ID}`, clickEvent),
        [RedactorPageKeys.ADD_HIGHLIGHT]: curry(triggerEvent)(`#${HIGHLIGHT_ICON_ID}`, clickEvent),
        [RedactorPageKeys.EXEMPTION_STAMP]: curry(handleKeysOpenModalWindow)(ARTICLE_STAMPS_MODAL ,{id: STAMP}),
        [RedactorPageKeys.USER_DEFINED_STAMP]: curry(handleKeysOpenModalWindow)(CUSTOM_STAMPS_MODAL ,{id: STAMP}),
        [RedactorPageKeys.CHANGE_DISCLOSURE]: curry(triggerSetDisclosureEvent)(ACTIVE_PAGE_PATH, contextMenuEvent),
        [PageListKeys.NEXT_PAGE]: (): void => handleGoToPage(GoToPage.NEXT),
        [PageListKeys.PREV_PAGE]: (): void => handleGoToPage(GoToPage.PREV),
        [PageListKeys.FIRST_PAGE]: (): void => handleGoToPage(GoToPage.FIRST),
        [PageListKeys.LAST_PAGE]: (): void => handleGoToPage(GoToPage.LAST),
        [DocumentListKeys.NEXT]: (): void => handleGoToDocument(GoToDocumentDirections.NEXT),
        [DocumentListKeys.PREV]: (): void => handleGoToDocument(GoToDocumentDirections.PREV),
        [DocumentListKeys.FIRST]: (): void => handleGoToDocument(GoToDocumentDirections.FIRST),
        [DocumentListKeys.LAST]: (): void => handleGoToDocument(GoToDocumentDirections.LAST),
    };

    const getFolderRsn = (params: { [param: string]: string }): string | null => {
        const amandaSso = params && params[SSO_PARAM] && decode(params[SSO_PARAM]);
        const amandaSsoParm = !!amandaSso && JSON.parse(amandaSso);
        const history = createBrowserHistory();
        const folderRsn = amandaSsoParm && amandaSsoParm[FOLDER_RSN_PARAM];

        if (folderRsn) {
            history.push(`/${b64EncodeUnicode(folderRsn)}`);
        }

        return folderRsn || null;
    };

    useEffect(()=> {
        const params = getQueryStringParams(location.search);
        const folderRsn = !isEmpty(routeParams.params) && routeParams.params[FOLDER_RSN] || getFolderRsn(params);
        const lid = params[SSO_LID] || reductionLid;
        const lang = params[LANG_PARAM] || redactionLang;
        const mode = params[MODE_PARAM] || reductionMode;
        const label = {
            requestSearchLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if(resource.resourceKey === 'SEARCH_REQUEST_MODAL_TITLE') {
                label.requestSearchLabel = resource;
            }

            return resource;
        });

        modifiedLabels.map((resource: any) => {
            if(resource.resourceKey === 'SEARCH_REQUEST_MODAL_TITLE') {
                label.requestSearchLabel = resource;
            }

            return resource;
        });

        if (!folderRsn && lid && !isInitialDataLoaded) {
            setLid(lid);
            setLang(lang);
            setMode(mode);
            handleOpenModalWindow(REQUEST_SEARCH_MODAL, {id: REQUEST_SEARCH_MODAL,
                title: label.requestSearchLabel[lang
                    ? lang === 'fr' ? 'resourceValue2': 'resourceValue' : 'resourceValue']});
        }

        if (folderRsn && !isInitialDataLoaded && lid) {
            setLid(lid);
            setLang(lang);
            setMode(mode);
            createReductionMeta(folderRsn);

            return;
        }

        if (folderRsn) {
            if (isInitialDataLoaded) {
                handleFetchScannerSettings();
                handleFetchInfoController();
            }

            if(params && !params.from) {
                createReductionMeta(folderRsn);
            }

            if(params && params.from) {

                const selectedDocumentDetails = {
                    documentId: +b64DecodeUnicode(params.document),
                    page: +b64DecodeUnicode(params.page),
                    redactionDocumentId: +b64DecodeUnicode(params.redactionDocument),
                };

                setSelectedDocumentDetails(selectedDocumentDetails);
            }

        }
    }, [isInitialDataLoaded, get(routeParams, 'params.folderRsn', null), modifiedLabels.length]);

    return (
        <React.Fragment>
            <HotKeys handlers={handlers} keyMap={hotKeys}>
                <ThemeProvider theme={theme}>
                    <React.Fragment>
                        {
                            showHeader &&
                            <Header
                                folderRsn={!isEmpty(routeParams.params) &&
                                routeParams.params[FOLDER_RSN] ||
                                b64EncodeUnicode(getFolderRsn(getQueryStringParams(location.search)))}
                            />
                        }
                        {!isDenyAccess && {...children}}
                    </React.Fragment>
                    <ModalWindow
                        modal={modal}
                        openModalWindow={
                            (data: string, message: IModalProps): void => handleOpenModalWindow(data, message)
                        }
                        handleCloseModalWindow={closeModalWindow}
                        handleCloseAllModalWindows={closeAllModalWindows}
                        handleAction={handleAction}
                        modalWindowPermissions={modalWindowPermissions}
                        redactionLang={redactionLang}
                        modifiedLabels={modifiedLabels}
                    />
                    <ContextDynamicMenu
                        contextMenuList={contextMenuList}
                        contextMenuType={contextMenuType}
                        contextMenuProps={contextMenuProps}
                        openModalWindow={handleOpenModalWindow}
                        handleAction={handleAction}
                        redactionDocumentId={redactionDocumentId}
                    />
                </ThemeProvider>
            </HotKeys>
        </React.Fragment>
    );
};

import * as React from 'react';
import {
    ANNOTATION_TYPE_MANAGEMENT,
    DISCLOSURE_MANAGEMENT,
    GLOBAL_SETTINGS,
    PACKAGE_PRESETS,
    USER_DEFINED_STAMP_MANAGEMENT,
    WATERMARK_MANAGEMENT,
    ASSOCIATE_WATERMARK_TO_FOLDER,
    ENCRYPTION,
    REQUEST_SECURITY,
    DOCUMENT_INDEX,
} from './modal.constants';
import { pageRotate } from '../redux/actions/redactor';
import {
    confirmDeletePage,
    goToPage,
    movePages,
    ocrExistingPages,
    selectAllPages,
    reverseOrder,
    checkPagesAndAutoDeskewCurrentPages,
    openPageAutoAlignArticleStampsConfirmModal,
} from '../redux/actions/pageList';
import {
    checkPagesAndAutoDeskewDocuments,
    confirmDeleteDocument,
    editPaginationDocuments,
    ocrExistingDocuments,
    selectAllDocuments,
    setPaginationDocument, setReleaseDate,
    undoPagination,
    clearReleaseDate,
    openAutoAlignArticleStampsConfirmModal,
} from '../redux/actions/documentList';
import { fetchReportOfficer } from '../redux/actions/reportsOfficer';
import { DISCLOSURE_TITLE_MODAL } from './disclosure.contstants';
import { GLOBAL_SETTINGS_TITLE } from './globalSettings.constants';
import { callModalImport, callModalInsertPage } from '../redux/actions/modalWindowImport';
import { STACK_MANAGMENT_MODAL } from './stack.contstants';
import { GoToPage } from '../components/navigation/Navigation';
import { addToStackDocuments } from '../redux/actions/stackList';
import { WATERMARK_TITLE_MODAL } from './watermark.contstants';
import { AUDIT_LOG_TITLE } from './auditLog.contstants';
import { fetchReportIndexNotes } from '../redux/actions/reportsIndexNotes';
import {
    REDACTION_DOCUMENT_SELECT_ALL,
    REDACTION_DOCUMENT_APPLY_OCR,
    REDACTION_PAGE_SELECT_ALL,
    REDACTION_PAGE_APPLY_OCR,
    REDACTION_HELP_ABOUT_REDACTION,
    REDACTION_HELP_KEYBOARD_SHORTCUTS,
    REDACTION_HELP_USER_MANUAL,
    REDACTION_ACTION_EXPORT_PRINT_PACKAGE,
    REDACTION_DOCUMENT_STACK_MANAGEMENT,
    REDACTION_DOCUMENT_PAGINATION_SET,
    REDACTION_DOCUMENT_DELETE,
    REDACTION_PAGE_DELETE,
    REDACTION_DOCUMENT_VIEW,
    REDACTION_PAGE_VIEW,
    REDACTION_HELP_VIEW,
    REDACTION_FILE_MY_SETTINGS,
    REDACTION_FILE_REFRESH_REQUEST,
    REDACTION_FILE_REQUEST_SEARCH,
    REDACTION_FILE_CLOSE,
    REDACTION_ACTION_VIEW,
    REDACTION_ACTION_DUPLICATE_SEARCH,
    REDACTION_ACTION_REPORTS,
    REDACTION_DOCUMENT_DETAILS,
    REDACTION_DOCUMENT_SET_RELEASE_DATE,
    REDACTION_DOCUMENT_CLEAR_RELEASE_DATE,
    REDACTION_PAGE_ROTATE,
    REDACTION_PAGE_MOVE,
    REDACTION_PAGE_REORDER_PAGES,
    REDACTION_PAGE_INSERT,
    REDACTION_PAGE_GO_TO,
    REDACTION_PAGE_GO_TO_FIRST,
    REDACTION_PAGE_GO_TO_LAST,
    REDACTION_PAGE_GO_TO_PREV,
    REDACTION_PAGE_GO_TO_NEXT,
    REDACTION_ADMIN_PACKAGE_PRESETS_VIEW,
    REDACTION_ADMIN_GLOBAL_SETTINGS_VIEW,
    REDACTION_ADMIN_AUDIT_LOGS_VIEW,
    REDACTION_ADMIN_DISCLOSURE_MANAGEMENT_VIEW,
    REDACTION_ADMIN_ANNOTATION_TYPE_MANAGEMENT_VIEW,
    REDACTION_ADMIN_USER_DEFINED_STAMP_MANAGEMENT_VIEW,
    REDACTION_ADMIN_WATERMARK_MANAGEMENT_VIEW,
    REDACTION_ADMIN_ASSOCIATE_WATERMARK_TO_FOLDER_TYPE,
    REDACTION_ADMIN_REQUEST_SECURITY,
    REDACTION_ADMIN_ENCRYPTION,
    REDACTION_ADMIN_DOCUMENT_INDEX_VIEW,
    REDACTION_DOCUMENT_PAGINATION_EDIT,
    REDACTION_DOCUMENT_PAGINATION_UNDO,
    REDACTION_DOCUMENT_PAGINATION_VIEW,
    REDACTION_DOCUMENT_STACK_ADD_REMOVE,
    REDACTION_DOCUMENT_STACK_VIEW,
    REDACTION_PAGE_MOVE_PREV,
    REDACTION_PAGE_MOVE_NEXT,
    REDACTION_ACTION_IMPORT_DOCUMENT_REDACTION,
    REDACTION_DOCUMENT_EDIT_FILE_NAME,
    REDACTION_STAMP_ALIGNMENT,
    REDACTION_DOCUMENT_APPLY_AUTO_ALIGN_ARTICLE_STAMPS,
    REDACTION_PAGE_APPLY_AUTO_ALIGN_ARTICLE_STAMPS,
    REDACTION_ADMIN_LOCALIZATION_VIEW,
    REDACTION_DOCUMENT_APPLY_AUTO_DESKEW,
    REDACTION_PAGE_APPLY_AUTO_DESKEW,
    REDACTION_HELP_REDACTION_USER_GUIDE,
} from './permissions';
import { EDIT_FILE_NAME_TITLE, EDIT_FILE_NAME_MODAL } from './messages.constants';
import { USER_PREFERENCE_MODAL } from './modalUserPreference.constants';
import { PAGE_REVIEW_SUMMARY_MODAL, REQUESTER_REPORT_MODAL } from '../containers/modalWindowContents';
import { REPORT_LANG_SELECTION_MODAL } from './modalReportLangSelection.constants';

export const LEFT_ROTATE_PAGE = 'left_rotate_page';
export const RIGHT_ROTATE_PAGE = 'right_rotate_page';
export const REPORTS_ACTION = 'reports_action';
export const OFFICER_REPORTS_ACTION = 'Officer_reports_action';
export const INDEX_NOTES_REPORTS_ACTION = 'Index_Not_reports_action';
export const REQUESTER_REPORTS_ACTION = 'Requester reports action';
export const PAGE_REVIEW_SUMMARY_REPORTS_ACTION = 'page_review_summary_reports_action';

export interface IHeaderMenu {
    type: string;
    // TODO  investigate and set type ConnectedComponentClass
    element: any;
}

export interface IMenu {
    id: string;
    Header: string | IHeaderMenu;
    submenusClass?: string;
    submenus?: IMenu[];
    action?: (act?: any) => void;
    modal?: string;
    actionProps?: any;
    link?: string;
    enable?: boolean;
}

export enum HeaderMainMenus {
    FILE = 'file',
    ACTION = 'action',
    DOCUMENT = 'document',
    PAGE = 'page',
    ADMIN = 'admin',
    HELP = 'help',
}

export const showAnywayHeaderMenus = [
    REDACTION_DOCUMENT_SELECT_ALL,
    REDACTION_DOCUMENT_VIEW,
    REDACTION_PAGE_VIEW,
    REDACTION_PAGE_SELECT_ALL,
    REDACTION_HELP_VIEW,
    REDACTION_HELP_USER_MANUAL,
    REDACTION_HELP_ABOUT_REDACTION,
    REDACTION_HELP_KEYBOARD_SHORTCUTS,
    REDACTION_HELP_REDACTION_USER_GUIDE,
    REDACTION_FILE_REQUEST_SEARCH,
    REDACTION_STAMP_ALIGNMENT,
    REDACTION_PAGE_GO_TO,
    REDACTION_PAGE_GO_TO_LAST,
    REDACTION_PAGE_GO_TO_FIRST,
    REDACTION_PAGE_GO_TO_PREV,
    REDACTION_PAGE_GO_TO_NEXT,
    LEFT_ROTATE_PAGE,
    RIGHT_ROTATE_PAGE,
    REDACTION_PAGE_MOVE_PREV,
    REDACTION_PAGE_MOVE_NEXT,
    INDEX_NOTES_REPORTS_ACTION,
    OFFICER_REPORTS_ACTION,
    REQUESTER_REPORTS_ACTION,
    PAGE_REVIEW_SUMMARY_REPORTS_ACTION,
];

export const headerMenu: IMenu[] = [
    {
        id: HeaderMainMenus.FILE,
        Header: 'File',
        enable: true,
        submenus: [
            {
                id: REDACTION_FILE_REQUEST_SEARCH,
                enable: true,
                Header: 'Request Search',
                modal: 'requestSearch',
            },
            {
                id: REDACTION_STAMP_ALIGNMENT,
                enable: true,
                Header: 'User Preferences',
                modal: USER_PREFERENCE_MODAL,
            },
        ],
    },
    {
        id: HeaderMainMenus.ACTION,
        Header: 'Action',
        enable: true,
        submenus: [
            {
                id: REDACTION_ACTION_IMPORT_DOCUMENT_REDACTION,
                enable: true,
                Header: 'Import Document',
                action: callModalImport,
            },
            {
                id: REDACTION_ACTION_EXPORT_PRINT_PACKAGE,
                enable: true,
                Header: 'Export Document',
                modal: 'export',
            },
            {
                id: REDACTION_ACTION_DUPLICATE_SEARCH,
                enable: false,
                Header: 'Manage Duplicates',
                link: '/duplicates',
            },
            {
                id: REDACTION_ACTION_REPORTS,
                enable: true,
                Header: 'Reports',
                submenus: [
                    {
                        id: INDEX_NOTES_REPORTS_ACTION,
                        enable: true,
                        Header: 'Index Notes',
                        action: fetchReportIndexNotes,
                    },
                    {
                        id: OFFICER_REPORTS_ACTION,
                        enable: true,
                        Header: 'Officer Report',
                        action : fetchReportOfficer,
                    },
                    {
                        id: REQUESTER_REPORTS_ACTION,
                        enable: true,
                        Header: 'Requester Report',
                        modal: REQUESTER_REPORT_MODAL,

                    },
                    {
                        id: PAGE_REVIEW_SUMMARY_REPORTS_ACTION,
                        enable: true,
                        Header: 'Page Review Summary',
                        modal: PAGE_REVIEW_SUMMARY_MODAL,
                    },
                ],

            },
        ],
    },
    {
        id: HeaderMainMenus.DOCUMENT,
        Header: 'Document',
        enable: true,
        submenus: [
            {
                id: REDACTION_DOCUMENT_STACK_VIEW,
                Header: 'Stack',
                enable: true,
                submenus: [
                    {
                        id: REDACTION_DOCUMENT_STACK_ADD_REMOVE,
                        enable: true,
                        Header: 'Add/Remove from Stack',
                        action: addToStackDocuments,
                    },
                    {
                        id: REDACTION_DOCUMENT_STACK_MANAGEMENT,
                        enable: true,
                        Header: 'Stack Management',
                        modal: STACK_MANAGMENT_MODAL,
                    },
                ],

            },
            {
                id: REDACTION_DOCUMENT_PAGINATION_VIEW,
                enable: true,
                Header: 'Pagination',
                submenus: [
                    {
                        id: REDACTION_DOCUMENT_PAGINATION_EDIT,
                        enable: true,
                        Header: 'Edit Pagination',
                        action: editPaginationDocuments,
                    },
                    {
                        id: REDACTION_DOCUMENT_PAGINATION_SET,
                        enable: true,
                        Header: 'Set Pagination',
                        action: setPaginationDocument,
                    },
                    {
                        id: REDACTION_DOCUMENT_PAGINATION_UNDO,
                        enable: true,
                        Header: 'Undo Pagination',
                        action: undoPagination,
                    },
                ],

            },
            {
                id: REDACTION_DOCUMENT_SELECT_ALL,
                enable: true,
                Header: 'Select All',
                action: selectAllDocuments,
            },
            {
                id: REDACTION_DOCUMENT_DELETE,
                enable: true,
                Header: 'Delete',
                action: confirmDeleteDocument,
            },
            {
                id: REDACTION_DOCUMENT_EDIT_FILE_NAME,
                enable: true,
                Header: EDIT_FILE_NAME_TITLE,
                modal: EDIT_FILE_NAME_MODAL,
            },
            {
                id: REDACTION_DOCUMENT_SET_RELEASE_DATE,
                enable: true,
                Header: 'Set Release Date',
                action: setReleaseDate,
            },
            {
                id: REDACTION_DOCUMENT_CLEAR_RELEASE_DATE,
                enable: true,
                Header: 'Clear Release Date',
                action: clearReleaseDate,
            },
            {
                id: REDACTION_DOCUMENT_APPLY_OCR,
                enable: true,
                Header: 'Apply OCR',
                action: ocrExistingDocuments,
            },
            {
                id: REDACTION_DOCUMENT_APPLY_AUTO_DESKEW,
                enable: true,
                Header: 'Apply Auto Deskew',
                action: checkPagesAndAutoDeskewDocuments,
            },
            {
                id: REDACTION_DOCUMENT_APPLY_AUTO_ALIGN_ARTICLE_STAMPS,
                enable: true,
                Header: 'Auto Align Article Stamps',
                action: openAutoAlignArticleStampsConfirmModal,
            },

        ],
    },
    {
        id: HeaderMainMenus.PAGE,
        Header: 'Page',
        enable: true,
        submenus: [
            {
                id: REDACTION_PAGE_ROTATE,
                enable: true,
                Header: 'Rotate',
                submenus: [
                    {
                        id: LEFT_ROTATE_PAGE,
                        enable: true,
                        Header: 'Left',
                        action: pageRotate,
                    },
                    {
                        id: RIGHT_ROTATE_PAGE,
                        enable: true,
                        Header: 'Right',
                        action: pageRotate,
                    },
                ],

            },
            {
                id: REDACTION_PAGE_MOVE,
                enable: true,
                Header: 'Move',
                submenus: [
                    {
                        id: REDACTION_PAGE_MOVE_PREV,
                        enable: true,
                        Header: 'Up',
                        action: movePages,
                        actionProps: 'up',
                    },
                    {
                        id: REDACTION_PAGE_MOVE_NEXT,
                        enable: true,
                        Header: 'Down',
                        action: movePages,
                        actionProps: 'down',
                    },
                ],

            },
            {
                id: REDACTION_PAGE_REORDER_PAGES,
                enable: true,
                Header: 'Reverse Order',
                action: reverseOrder,
            },
            {
                id: REDACTION_PAGE_INSERT,
                enable: true,
                Header: 'Insert',
                action: callModalInsertPage,
            },
            {
                id: REDACTION_PAGE_DELETE,
                enable: true,
                Header: 'Delete',
                action: confirmDeletePage,
            },
            {
                id: REDACTION_PAGE_GO_TO,
                enable: true,
                Header: 'Go to',
                submenus: [
                    {
                        id: REDACTION_PAGE_GO_TO_FIRST,
                        enable: true,
                        Header: 'First',
                        action: goToPage,
                        actionProps: GoToPage.FIRST,
                    },
                    {
                        id: REDACTION_PAGE_GO_TO_LAST,
                        enable: true,
                        Header: 'Last',
                        action: goToPage,
                        actionProps: GoToPage.LAST,

                    },
                    {
                        id: REDACTION_PAGE_GO_TO_PREV,
                        enable: true,
                        Header: 'Previous',
                        action: goToPage,
                        actionProps: GoToPage.PREV,
                    },
                    {
                        id: REDACTION_PAGE_GO_TO_NEXT,
                        enable: true,
                        Header: 'Next',
                        action: goToPage,
                        actionProps: GoToPage.NEXT,
                    },
                ],
            },
            {
                id: REDACTION_PAGE_SELECT_ALL,
                enable: false,
                Header: 'Select All',
                action: selectAllPages,
            },
            {
                id: REDACTION_PAGE_APPLY_OCR,
                enable: true,
                Header: 'Apply OCR',
                action: ocrExistingPages,
            },
            {
                id: REDACTION_PAGE_APPLY_AUTO_DESKEW,
                enable: true,
                Header: 'Apply Auto Deskew',
                action: checkPagesAndAutoDeskewCurrentPages,
            },
            {
                id: REDACTION_PAGE_APPLY_AUTO_ALIGN_ARTICLE_STAMPS,
                enable: true,
                Header: 'Auto Align Article Stamps',
                action: openPageAutoAlignArticleStampsConfirmModal,
            },
        ],
    },
    {
        id: HeaderMainMenus.ADMIN,
        Header: 'Admin',
        enable: true,
        submenus: [
            {
                id: REDACTION_ADMIN_PACKAGE_PRESETS_VIEW,
                enable: true,
                Header: 'Package Presets',
                modal: PACKAGE_PRESETS,
            },
            {
                id: REDACTION_ADMIN_GLOBAL_SETTINGS_VIEW,
                enable: true,
                Header: GLOBAL_SETTINGS_TITLE,
                modal: GLOBAL_SETTINGS,
            },
            {
                id: REDACTION_ADMIN_AUDIT_LOGS_VIEW,
                enable: true,
                Header: AUDIT_LOG_TITLE,
                link: '/audit',
            },
            {
                id: REDACTION_ADMIN_LOCALIZATION_VIEW,
                enable: true,
                Header: 'Redaction Localization',
                link: '/localization',
            },
            {
                id: REDACTION_ADMIN_DISCLOSURE_MANAGEMENT_VIEW,
                enable: true,
                Header: DISCLOSURE_TITLE_MODAL,
                modal: DISCLOSURE_MANAGEMENT,
            },
            {
                id: REDACTION_ADMIN_ANNOTATION_TYPE_MANAGEMENT_VIEW,
                enable: true,
                Header: 'Annotation Type Management',
                modal: ANNOTATION_TYPE_MANAGEMENT,
            },
            {
                id: REDACTION_ADMIN_USER_DEFINED_STAMP_MANAGEMENT_VIEW,
                enable: true,
                Header: 'User Defined Stamp Management',
                modal: USER_DEFINED_STAMP_MANAGEMENT,
            },
            {
                id: REDACTION_ADMIN_WATERMARK_MANAGEMENT_VIEW,
                enable: true,
                Header: WATERMARK_TITLE_MODAL,
                modal: WATERMARK_MANAGEMENT,
            },
            {
                id: REDACTION_ADMIN_ASSOCIATE_WATERMARK_TO_FOLDER_TYPE,
                enable: true,
                Header: 'Associate Watermark to Request Type',
                modal: ASSOCIATE_WATERMARK_TO_FOLDER,
            },
            {
                id: REDACTION_ADMIN_REQUEST_SECURITY,
                enable: true,
                Header: 'Request Security',
                modal: REQUEST_SECURITY,
            },
            {
                id: REDACTION_ADMIN_ENCRYPTION,
                enable: true,
                Header: 'Encryption',
                modal: ENCRYPTION,
            },
            {
                id: REDACTION_ADMIN_DOCUMENT_INDEX_VIEW,
                enable: true,
                Header: 'Index Keyword Management',
                modal: DOCUMENT_INDEX,
            },
        ],
    },
    {
        id: HeaderMainMenus.HELP,
        Header: 'Help',
        enable: true,
        submenus: [
            {
                id: REDACTION_HELP_ABOUT_REDACTION,
                enable: true,
                Header: 'About Redaction',
                modal: 'aboutRedaction',
            },
            {
                id: REDACTION_HELP_KEYBOARD_SHORTCUTS,
                enable: true,
                Header: 'Keyboard Shortcuts',
                modal: 'keyList',
            },
            {
                id: REDACTION_HELP_REDACTION_USER_GUIDE,
                enable: true,
                Header: 'Redaction User Guide',
                link:'/src/redactionManual/RedactionUserReference.pdf'
            },
        ],
    },
];

import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import {
    GET_REQUEST_RESULTS_PENDING,
    GET_REQUEST_RESULTS_SUCCESS,
    GET_REQUEST_RESULTS_FAILURE,
    CLEAR_SEARCH_RESULT,
    GET_OFFICERS_PENDING,
    GET_OFFICERS_SUCCESS,
    GET_OFFICERS_FAILURE,
} from '../reducers/modalRequestSearch/constants';
import { IError } from '../common.model';
import {
    IResultRequestSearch,
    IRequestSearchParams,
    IOfficerRequest,
} from '../reducers/modalRequestSearch/requestSearch.model';
import { addError } from './errorHandling';

export const fetchRequestSearchPending = (): AnyAction => action(GET_REQUEST_RESULTS_PENDING);
export const fetchRequestSearchSuccess = (globalSettings: IResultRequestSearch[]): AnyAction =>
    action(GET_REQUEST_RESULTS_SUCCESS, globalSettings);
export const fetchRequestSearchFailure = (error: IError): AnyAction => action(GET_REQUEST_RESULTS_FAILURE, error);

export const fetchOfficerPending = (): AnyAction => action(GET_OFFICERS_PENDING);
export const fetchOfficerSuccess = (officers: IOfficerRequest[]): AnyAction => action(GET_OFFICERS_SUCCESS, officers);
export const fetchOfficerFailure = (error: IError): AnyAction => action(GET_OFFICERS_FAILURE, error);

export const clearSearchResult = (): AnyAction => action(CLEAR_SEARCH_RESULT);

export const fetchRequestSearch = (params: IRequestSearchParams): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

    dispatch(fetchRequestSearchPending());

    try {
        const response = await api.requestController.searchForRequest(params);

        dispatch(fetchRequestSearchSuccess(response));
    } catch (error) {
        dispatch(fetchRequestSearchFailure(error));
        dispatch(addError(error));
    }
};

export const fetchOfficers = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchOfficerPending());

        try {
            const response = await api.peopleController.getOfficers();

            dispatch(fetchOfficerSuccess(response));
        } catch (error) {
            dispatch(fetchOfficerFailure(error));
            dispatch(addError(error));
        }
    };

import {
    GET_OFFICER_PENDING,
    GET_OFFICER_SUCCESS,
    GET_OFFICER_FAILURE,
    POST_OFFICER_PENDING,
    POST_OFFICER_SUCCESS,
    POST_OFFICER_FAILURE,
} from './constants';

import { IOfficerReportTypes } from './reportsOffice.model';
import { AnyAction } from 'redux';

const initialState: IOfficerReportTypes = {
    officerReportPending: false,
    error: null,
};

const officerReport = (state: IOfficerReportTypes = initialState, {type, payload}: AnyAction): IOfficerReportTypes => {
    switch (type) {
        case GET_OFFICER_PENDING:
        case POST_OFFICER_PENDING:
            return {
                ...state,
                officerReportPending: true,
                error: null,
            };
        case GET_OFFICER_SUCCESS:
        case POST_OFFICER_SUCCESS:
            return{
                ...state,
                officerReportPending: false,
            };
        case GET_OFFICER_FAILURE:
        case POST_OFFICER_FAILURE:
            return{
                ...state,
                error: payload,
                officerReportPending: false,
            };
        default:
            return state;
    }
};

export default officerReport;

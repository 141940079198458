import { AnyAction } from 'redux';
import {
    GET_ENCRYPTION_KEYS_PENDING,
    GET_ENCRYPTION_KEYS_SUCCESS,
    GET_ENCRYPTION_KEYS_FAIL,
    PUT_ENCRYPTION_SUCCESS,
    PUT_ENCRYPTION_FAILURE,
    PUT_ENCRYPTION_PENDING,
    SET_OPTIONS_ENCRYPTION,
    POST_ENCRYPTION_PENDING,
    POST_ENCRYPTION_SUCCESS,
    POST_ENCRYPTION_FAILURE,
    DELETE_ENCRYPTION_KEY_PENDING,
    DELETE_ENCRYPTION_KEY_SUCCESS,
    DELETE_ENCRYPTION_KEY_FAILURE,
} from './constant';
import { IModalEncryptionState ,IEncryptionType } from './modalEncryptionKeys.model';
import { NAME } from '../../../constants/stack.contstants';
import { ID } from '../../../constants/common.constants';

export const initialEncryptionOptions: IEncryptionType =  {
    [ID]: null,
    [NAME]: '',
    isActive: false,
};

const initialState: IModalEncryptionState = {
    encryptionKeysType: [],
    encryptionTypePending: false,
    encryptionUpdatePending: false,
    encryptionOptions: initialEncryptionOptions,
    error: null,
};

const modalWindowExportReducer = (state: IModalEncryptionState = initialState,
                                  {type, payload}: AnyAction): IModalEncryptionState => {
    switch (type) {
        case PUT_ENCRYPTION_PENDING:
        case POST_ENCRYPTION_PENDING:
        case GET_ENCRYPTION_KEYS_PENDING:
        case DELETE_ENCRYPTION_KEY_PENDING:
            return {
                ...state,
                encryptionTypePending: true,
                error: null,
            };
        case GET_ENCRYPTION_KEYS_SUCCESS:
            return {
                ...state,
                encryptionKeysType: payload,
                encryptionTypePending: false,
            };
        case PUT_ENCRYPTION_FAILURE:
        case POST_ENCRYPTION_FAILURE:
        case DELETE_ENCRYPTION_KEY_FAILURE:
        case GET_ENCRYPTION_KEYS_FAIL:
            return{
                ...state,
                encryptionTypePending: false,
                error: payload,
            };
        case POST_ENCRYPTION_SUCCESS:
            const prevEncryptionKeysType = payload.isActive ?
                state.encryptionKeysType.map((encryption: IEncryptionType): IEncryptionType => ({
                    ...encryption,
                    isActive: false,
                })) : state.encryptionKeysType;

            return {
                ...state,
                encryptionKeysType: [...prevEncryptionKeysType, payload],
                encryptionTypePending: false,
            };
        case PUT_ENCRYPTION_SUCCESS:
            const activeKeys = state.encryptionKeysType
                .filter((encryption: IEncryptionType): boolean => encryption.isActive);

            return {
                ...state,
                encryptionKeysType: state.encryptionKeysType.map((encryption: IEncryptionType): IEncryptionType => {
                    const activeElementId = activeKeys.length && activeKeys[0].id;

                    if (
                        payload.isActive === true &&
                        activeElementId === encryption.id &&
                        activeElementId !== payload.id
                    ) {
                        return { ...encryption, isActive: false };
                    }

                    return encryption.id === payload.id ? payload : encryption;
                }),
                encryptionTypePending: false,
                error: null,
            };
        case SET_OPTIONS_ENCRYPTION:
            return {
                ...state,
                encryptionOptions: {...payload},
            };
        case DELETE_ENCRYPTION_KEY_SUCCESS:
            return{
                ...state,
                encryptionKeysType: [...state.encryptionKeysType.filter(
                    (encrypt: IEncryptionType ): boolean => encrypt.id !== payload,
                )],
                encryptionTypePending: false,
                error: null,
            };
        default:
            return state;
    }
};

export default modalWindowExportReducer;

import * as React from 'react';
import { useEffect, useState } from 'react';
import SplitterLayout from 'react-splitter-layout-react-v18';
import { useSelector, useDispatch } from '../../../node_modules/react-redux';
import { getDuplicatePanelSizes } from '../../redux/selectors/localStorage';
import { getRedactionFolderRsn } from '../../redux/selectors/initialization';
import {
    getCustomFieldsForTableHeaders,
    getIndexMetadataData,
    getIndexMetaDataPending,
} from '../../redux/selectors/indexMetadata';
import { setDuplicatesBottomPanelSize } from '../../redux/actions/localStorage';
import { setPdfWidth, setPdfHeight } from '../../redux/actions/redactor';
import { fetchIndexMetadata } from '../../redux/actions/indexMetadata';
import {
    fetchDuplicatesCurrentGroup,
    fetchDuplicatesResource,
    setMaxDuplicatesCount,
    closeSelectedDuplicate,
    clearDuplicateGroup,
    setDuplicateScale,
    setDuplicateClientSize,
    handleDuplicatesSearch,
    goToPageDuplicates,
    goToDocumentPage,
    clearDuplicatesResource,
    fetchDuplicateReferences, fetchPageDuplicateReferences,
} from '../../redux/actions/duplicates';
import { DuplicateControls } from './DuplicateControls';
import { DuplicateTable } from './DupilcateTable';
import {
    getDuplicatesCurrentGroup,
    getDuplicatesResources,
    getSearchPending,
    getAllowPagesNavigation, getDuplicatesReferences, getCriteriaSearchSatus,
} from '../../redux/selectors/duplicates';
import { IClientSize } from '../redactor/redactor.model';
import { SplitterWrapper } from './SplitterWrapper';
import { IDuplicatesSearchIds } from '../../redux/reducers/duplicates/duplicates.model';
import { maxDuplicatesCount } from '../../index';
import { Spinner } from '../../components/spinner/Spinner';
import './duplicatePanels.styles.scss';
import { openModalWindow } from '../../redux/actions/modal';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { DUPLICATE_REFERENCES, REFERENCES } from '../../constants/modal.constants';
import { getDocument } from '../../redux/selectors/redactor';
import { getRedactionDocumentId } from '../../redux/selectors/initialization';
import * as constants from '../../constants/duplicates.constants';

export const DuplicatePanels = (): JSX.Element => {
    const [bottomPanelSize, setBottomPanelSize] = useState(0);
    const panelSizes = useSelector(getDuplicatePanelSizes);
    const folderRsn = useSelector(getRedactionFolderRsn);
    const duplicates = useSelector(getDuplicatesResources);
    const duplicatesFilters = useSelector(getIndexMetadataData);
    const duplicatesFiltersPending = useSelector(getIndexMetaDataPending);
    const duplicatesCurrentGroup = useSelector(getDuplicatesCurrentGroup);
    const searchPending = useSelector(getSearchPending);
    const duplicateCustomFileds = useSelector(getCustomFieldsForTableHeaders);
    const allowPagesNavigation = useSelector(getAllowPagesNavigation);
    const duplicateReferences = useSelector(getDuplicatesReferences);
    const isCriteriaSearch = useSelector(getCriteriaSearchSatus);
    const redactorDocument = useSelector(getDocument);
    const redactionDocumentId = useSelector(getRedactionDocumentId);
    const dispatch = useDispatch();
    const handleSetBottomPanelSize = (size: number): void =>
        dispatch(setDuplicatesBottomPanelSize(size));
    const handleFetchIndexMetaData = (): void => dispatch(fetchIndexMetadata());
    const handleSetWidth = (width: number): void => dispatch(setPdfWidth(width));
    const handleSetHeight = (height: number): void => dispatch(setPdfHeight(height));
    const handleFetchDuplicatesCurrentGroup = (ids: IDuplicatesSearchIds, pageNumber?: number): void =>
        dispatch(fetchDuplicatesCurrentGroup(ids, pageNumber));
    const handleFetchDuplicatesResource = (documentId: number, pageId: number): void =>
        dispatch(fetchDuplicatesResource(documentId, pageId, duplicates));
    const handleCloseSelectedDocument = (index: number): void => dispatch(closeSelectedDuplicate(index));
    const handleClearDuplicateGroup = (): void => dispatch(clearDuplicateGroup());
    const handleClearDuplicatesResource = (): void => dispatch(clearDuplicatesResource());
    const handleSetMaxDuplicateCount = (): void => dispatch(setMaxDuplicatesCount(maxDuplicatesCount));
    const handleSetDuplicateScale = (documentId: number, pageId: number, scale: number): void =>
        dispatch(setDuplicateScale(documentId, pageId, scale));
    const handleSetDuplicateClientSize = (documentId: number, pageId: number, clientSize: IClientSize): void =>
        dispatch(setDuplicateClientSize(documentId, pageId, clientSize));
    const handleSearch = (pageNumber?: number): void => dispatch(handleDuplicatesSearch(pageNumber));
    const handleGoToPage = (page: string): void => dispatch(goToPageDuplicates(page));
    const handleGoToDocumentPage = (documentId: number, page: string): void =>
        dispatch(goToDocumentPage(documentId, page));
    const fetchDuplicateRefData = (currentDocId: number, pageId: number, actualPageNumber: number): void =>
        dispatch(fetchPageDuplicateReferences(currentDocId, pageId, actualPageNumber));
    const handleOpenModalDuplicates = (): void =>
        dispatch(openModalWindow(DUPLICATE_REFERENCES, { id: DUPLICATE_REFERENCES }));
    const handleDuplicateReferences = (documentId: number): void => dispatch(fetchDuplicateReferences(documentId));
    const handleOpenModalWindow = (modalType: string, modalProps: IModalProps): void =>
        dispatch(openModalWindow(modalType, modalProps));

    useEffect((): void => {
        handleSetMaxDuplicateCount();
    }, []);

    return (
        <div className='duplicates-body'>
            {searchPending && <div className='duplicates-body_spinner'><Spinner active={true} /></div>}
            {panelSizes && <SplitterLayout
              primaryIndex={1}
              secondaryInitialSize={panelSizes.bottom}
              onSecondaryPaneSizeChange={setBottomPanelSize}
              onDragEnd={(): void => handleSetBottomPanelSize(bottomPanelSize)}
              vertical={true}
            >
              <div className='duplicates-body_main_wrapper'>
                <DuplicateControls
                  controlsHeight={panelSizes.bottom}
                  filtersPending={duplicatesFiltersPending}
                  filters={duplicatesFilters}
                  folderRsn={folderRsn}
                  duplicatesCurrentGroup={duplicatesCurrentGroup}
                  isCriteriaSearch={isCriteriaSearch}
                  allowPagesNavigation={allowPagesNavigation}
                  fetchFilters={handleFetchIndexMetaData}
                  fetchDuplicateCurrentGroup={handleFetchDuplicatesCurrentGroup}
                  handleClearDuplicateGroup={handleClearDuplicateGroup}
                  handleClearDuplicatesResource={handleClearDuplicatesResource}
                  handleGoToPage={handleGoToPage}
                  handleSearch={handleSearch}
                  redactionDocumentId={redactionDocumentId}
                  openReference={(): void => handleOpenModalWindow(REFERENCES, { id: REFERENCES, title: constants.getManageDuplicatesLabelsByKey('References Modal') })}
                />
                  {duplicates && <SplitterWrapper
                    documents={duplicates}
                    redactorDocument={redactorDocument}
                    duplicateReferences={duplicateReferences}
                    setWidth={handleSetWidth}
                    setHeight={handleSetHeight}
                    index={0}
                    handleClose={handleCloseSelectedDocument}
                    setDuplicateScale={handleSetDuplicateScale}
                    setDuplicateClientSize={handleSetDuplicateClientSize}
                    handleGoToDocumentPage={handleGoToDocumentPage}
                    handleOpenModalDuplicates={handleOpenModalDuplicates}
                    fetchDuplicateRefData={fetchDuplicateRefData}
                  />}
              </div>
              <div>
                <SplitterLayout>
                    {duplicatesCurrentGroup && <DuplicateTable
                      duplicatesResources={duplicates}
                      duplicateCustomFileds={duplicateCustomFileds}
                      rowAction={handleFetchDuplicatesResource}
                      documents={duplicatesCurrentGroup.duplicatesGroup}
                      handleDuplicateReferences={handleDuplicateReferences}
                    />}
                </SplitterLayout>
              </div>
            </SplitterLayout>}
        </div>
    );
};

import { action } from 'typesafe-actions';
import { get } from 'lodash';
import {
    getCurrentPageSuccess,
    updatePageMetaDataInDocument,
    setPageRotateInDocumentInfo,
    setPageListSuccess,
    setPageNumber,
    setFirstPage,
    getCurrentPageComments,
    getCurrentPageHyperLinks,
    setCommentLoadingFlag,
    setGotoPagePendingFlag,
    setCurrentFirstOrLastPage,
    getCurrentPageCommentsFailure,
} from './pageList';
import api from '../../api/reductionApi';
import { addError } from './errorHandling';
import { addAnnotation, getAllAnnotationsPerPage, highlightLinkedSever } from './annotations';
import { addStamps, getPageStamps, highlightLinkedArticles } from './stamps';
import { AnyAction, Dispatch } from 'redux';
import { formatGroupCoordinates } from '../../utils/redactor.utils';
import { IError } from '../common.model';
import { IClientSize, ILayerShape } from '../../containers/redactor/redactor.model';
import {
    FETCH_DOCUMENT_PENDING,
    FETCH_DOCUMENT_SUCCESS,
    FETCH_DOCUMENT_FAILURE,
    PAGE_ROTATE_PENDING,
    PAGE_ROTATE_SUCCESS,
    PAGE_ROTATE_FAILURE,
    SET_PAGE_ROTATE,
    PAGE_SCALE,
    PAGE_SIZE,
    CLEAR_OPTIONS_STATE,
    SET_PDF_HEIGHT,
    SET_PDF_WIDTH,
    ADD_SELECTED_SHAPES,
    GET_CLIENT_SIZE,
    CLEAR_SELECTED_SHAPES,
    COPY_SELECTED_SHAPES,
    PAST_SELECTED_SHAPES,
    ADD_SINGLE_SELECTED_SHAPE,
    GET_OVERLAYED_SHAPES_PENDING,
    GET_OVERLAYED_SHAPES_SUCCESS,
    GET_OVERLAYED_SHAPES_FAILURE,
    SET_LINKED_STAMPS_ID,
    SET_LINKED_ANNOTATION_ID,
    SET_LINKED_ARTICLE_FLAG,
    CLEAR_LINKED_ANNOTATION_AND_STAMP_ID,
    GET_PAGE_NUMBER_BY_PAGEID_PENDING,
    GET_PAGE_NUMBER_BY_PAGEID_SUCCESS,
    GET_PAGE_NUMBER_BY_PAGEID_FAILURE,
    SET_CONTEXT_MENU_COORDINATES,
} from '../reducers/redactor/constants';
import {
    ANNOTATION_TYPE_ID,
    STAMP_TYPE_ID,
    ANNOTATIONS,
    STAMPS,
} from '../../constants/annotationTypes.constants';
import { IState } from '../store';
import { IAnnotation, ICoordinate, IPages } from '../reducers/pageList/pageList.model';
import { IStamp } from '../reducers/stamps/stamps.model';
import { groupBy } from 'lodash';
import { removeAnnotation, getCurrentPageAnnotationsSuccess } from '../actions/annotations';
import { deleteStamps } from './stamps';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { CHANGE_ANNOTATION_TYPE_TO, OVERLAYED_SHAPES, VIEW_COMMENT, VIEW_SEVER_INFO } from '../../constants/contextmenu/context.menu.constants';
import { handleCloseModalWindow, openModalWindow } from './modal';
import { IModalIdsData } from '../reducers/modal/modal.model';
import { getPageStampsSuccess } from './stamps';
import { fetchPageIndexPending, fetchPageIndexFailure, fetchPageIndexSuccess } from '../actions/pageIndex';
import { mergeIndexArrayWithMetadata } from '../../utils/indexMetadata';
import { restrictOnFetchDataPageList, setSearchCurrentPageId } from './globalSearch';
import { IPageActionParams } from '../../redux/reducers/annotation/annotation.model';
import { ISelectedShapeData, IOverlayedShapesResponse } from '../../redux/reducers/redactor/redactor.model';

import {
    CONFIRMATION_DIALOG_MODAL,
    CUTTING_LINKED_SEVER,
    DELETE_LINKED_ARTICLE_OR_EXEMPTION_MESSAGE,
    NO_BUTTON, WARNING,
    YES_BUTTON,
} from '../../constants/messages.constants';
import { ID, OK } from '../../constants/common.constants';
import { getRequiredFilteredArrayByProp } from '../../utils/array.utils';
import { clearPageConsultees, getPageConsultee } from './pageMetadata';
import { sortKeys } from '../../constants/leftSidebar.config';
import { setSelectedAdvanceSearchedDocument } from './advanceSearch';
import {
    SET_CURRENT_FIRST_PAGE_ID,
    SET_CURRENT_LAST_PAGE_ID,
} from '../../redux/reducers/pageList/constant';

const MIN_ZOOM = 0.2;

export const fetchDocumentPending = (): AnyAction => action(FETCH_DOCUMENT_PENDING);
export const fetchDocumentSuccess = (pdfSrc: string): AnyAction => action(FETCH_DOCUMENT_SUCCESS, pdfSrc);
export const fetchDocumentFailure = (error: string): AnyAction => action(FETCH_DOCUMENT_FAILURE, error);

export const pageRotatePending = (): AnyAction => action(PAGE_ROTATE_PENDING);
export const pageRotateSuccess = (degree: number): AnyAction => action(PAGE_ROTATE_SUCCESS, degree);
export const pageRotateFailure = (error: IError): AnyAction => action(PAGE_ROTATE_FAILURE, error);

export const setPageRotate = (degree: number): AnyAction => action(SET_PAGE_ROTATE, degree);

export const getOverlayedShapesPending = (): AnyAction => action(GET_OVERLAYED_SHAPES_PENDING);
export const getOverlayedShapesSuccess = (response: IOverlayedShapesResponse): AnyAction => action(
    GET_OVERLAYED_SHAPES_SUCCESS,
    response,
);

export const getOverlayedShapesFailure = (error: IError): AnyAction => action(
    GET_OVERLAYED_SHAPES_FAILURE,
    error,
);
export const setLinkedArticlesFlag = (linkedArticleFlag: boolean): AnyAction =>
    action(SET_LINKED_ARTICLE_FLAG, linkedArticleFlag);
export const setLinkedAnnotationId = (annotationId: number): AnyAction =>
    action(SET_LINKED_ANNOTATION_ID, annotationId);
export const setLinkedStampId = (stampId: number[]): AnyAction => action(SET_LINKED_STAMPS_ID, stampId);
export const clearLinkedAnnotationIdAndArticleId = (): AnyAction => action(CLEAR_LINKED_ANNOTATION_AND_STAMP_ID);

export const getPageNumberByPageIdPending = (): AnyAction => action(GET_PAGE_NUMBER_BY_PAGEID_PENDING);
export const getPageNumberByPageIdSuccess = (response: IOverlayedShapesResponse): AnyAction => action(
    GET_PAGE_NUMBER_BY_PAGEID_SUCCESS,
    response,
);

export const getPageNumberByPageIdFailure = (error: IError): AnyAction => action(
    GET_PAGE_NUMBER_BY_PAGEID_FAILURE,
    error,
);

export const setContextMenuCoordinate = (coordinate: {x: number,y: number}): AnyAction =>action(
    SET_CONTEXT_MENU_COORDINATES,
    coordinate,
);

export const setFocusOnRedactor = (isContextMenuEvent: boolean): void => {
    if (isContextMenuEvent) {
        const element = document.querySelector('.main') as HTMLDivElement;

        if(element) {
            element.focus();
        }
    }
};

export const getPageResource = (
    redactionDocumentId: number,
    id: number,
    pageId: number,
    rotation?: number,
    layerId?: number,
): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        const {
            advanceSearch: {
                selectedAdvanceSearchedDocument,
            },
            globalSearch: {
                restrictFlagForPageList,
            },
            pageList: {
                commentLoadingFlag,
                currentDocument: { pages, pageCount },
                currentPageNumber,
            },
        } = getState();

        dispatch(fetchDocumentPending());
        dispatch(fetchPageIndexPending());

        if(!commentLoadingFlag) {
            dispatch(setCommentLoadingFlag(true));
        }

        try {
            dispatch(getCurrentPageAnnotationsSuccess([]));
            dispatch(getPageStampsSuccess([]));

            const request = await api.pages.getPageResource(redactionDocumentId, id, pageId);

            dispatch(fetchDocumentSuccess(request));
            dispatch(getCurrentPageSuccess(pageId));
            dispatch(setSearchCurrentPageId(pageId));
            dispatch(setPageRotate(rotation));
            dispatch(setFirstPage(null));
            dispatch(getCurrentPageComments());
            dispatch(getCurrentPageHyperLinks());

            if(layerId) {
                dispatch(getAllAnnotationsPerPage(redactionDocumentId, id, pageId, layerId));
                dispatch(getPageStamps({ redactionDocumentId, documentId: id, pageId }));
            }
        } catch (error) {
            dispatch(fetchDocumentFailure('error'));
            dispatch(getCurrentPageSuccess(pageId));
            dispatch(setCommentLoadingFlag(false));
            dispatch(getCurrentPageCommentsFailure());
            dispatch(addError(error));
        }

        if(pages && pages.length && pageCount) {
            const currentPageIndex = pages.findIndex( (page: IPages): boolean => page.id === pageId );

            dispatch(setCurrentFirstOrLastPage(SET_CURRENT_FIRST_PAGE_ID,
                currentPageNumber === 0 && currentPageIndex === 0 ? pageId : null));
            dispatch(setCurrentFirstOrLastPage(SET_CURRENT_LAST_PAGE_ID,
                currentPageNumber === Math.floor(pageCount/50) && currentPageIndex === pages.length - 1 ?
                pageId : null));
        }

        try {
            const response = await api.pageIndex.getPageIndex(redactionDocumentId, id, pageId);
            const {
                indexMetadata: {
                    metadata,
                },
            } = getState();

            dispatch(fetchPageIndexSuccess(mergeIndexArrayWithMetadata(response, metadata)));

            if(selectedAdvanceSearchedDocument) {
                dispatch(setSelectedAdvanceSearchedDocument(null));

            }

            if(restrictFlagForPageList) {
                dispatch(restrictOnFetchDataPageList(false));
            }

        } catch (error) {
            dispatch(fetchPageIndexFailure(error));
            dispatch(addError(error));
        }
        dispatch(setGotoPagePendingFlag(false));
    };

export const getPageNumberByPageId = ({
    redactionDocumentId,
    documentId,
    selectedPageId,
    getAnnotationStamps,
    goToPage = false,
    openDocInNewTab = false,
}: {
    redactionDocumentId: number;
    documentId: number;
    selectedPageId: number;
    getAnnotationStamps: boolean;
    goToPage?: boolean;
    openDocInNewTab?: boolean;
}): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
    const {
        pageList: {
            currentPageSort,
            currentDocument: {
                pages,
            },
        },
        globalSearch: {
            restrictFlagForPageList,
        },
    } = getState();

    const sort = currentPageSort &&
        currentPageSort.id ? `?sort=${sortKeys[currentPageSort.id]},${currentPageSort.desc ? 'desc' : 'asc'}` : '';

    const currentPaginationPage = pages.length
        && pages.find((page: IPages) => page.actualPageNumber === selectedPageId);

    if(currentPaginationPage && !openDocInNewTab) {
        const layerId = currentPaginationPage.layers[0].id;

        getAnnotationStamps ? dispatch(getPageResource(
            redactionDocumentId,
            documentId,
            currentPaginationPage.id,
            currentPaginationPage.rotation,
            layerId,
        )) : dispatch(getPageResource(
            redactionDocumentId,
            documentId,
            currentPaginationPage.id,
            currentPaginationPage.rotation,
        ));
        dispatch(setFirstPage(null));
        dispatch(clearOptionsState());
        dispatch(clearPageConsultees());
        dispatch(getPageConsultee(redactionDocumentId, documentId, currentPaginationPage.id));

        if(restrictFlagForPageList) {
            dispatch(setPageNumber(0));
        }

        return;
    }

    dispatch(getPageNumberByPageIdPending());

    try {
        const response = await api.pages.getPageNumberByPageId(redactionDocumentId, documentId, selectedPageId, sort);

        dispatch(getPageNumberByPageIdSuccess(response));
        dispatch(setPageListSuccess(response.pages));
        dispatch(setPageNumber(response.pageNumber));

        const newSelectedPage = response.pages.length &&
            response.pages.find((page: IPages) => page.actualPageNumber === selectedPageId);

            // additional check if getPageResource action call is required or not.
        if(newSelectedPage && !goToPage) {
            const pageId = newSelectedPage.id;

            dispatch(getPageResource(
                redactionDocumentId,
                documentId,
                newSelectedPage.id,
                newSelectedPage.rotation,
            ));
            dispatch(setFirstPage(null));
            dispatch(clearOptionsState());
            dispatch(clearPageConsultees());
            dispatch(getPageConsultee(redactionDocumentId, documentId, newSelectedPage.id));

            if(getAnnotationStamps) {
                const layerId = newSelectedPage.layers[0].id;

                dispatch(getAllAnnotationsPerPage(redactionDocumentId, documentId, pageId, layerId));
                dispatch(getPageStamps({redactionDocumentId, documentId, pageId}));
            }
        }
    } catch(error) {
        dispatch(getPageNumberByPageIdFailure(error));
        dispatch(addError(error));
    }
};

export const pageRotate = ({
    redactionDocumentId,
    documentId,
    pageId,
    rotationState,
}: {
    redactionDocumentId: number;
    documentId: number;
    pageId: number;
    rotationState: number;
}): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> =>  {

        const { redactor: { rotate } } = getState();
        const resultRotate = rotate + rotationState;
        const degreeRotate = resultRotate >= 360 ? resultRotate - 360 : resultRotate;

        dispatch(pageRotatePending());

        try {
            await api.pages.rotatePage(redactionDocumentId, documentId, pageId, degreeRotate);
            dispatch(getCurrentPageComments());
            dispatch(getCurrentPageHyperLinks());
            dispatch(pageRotateSuccess(degreeRotate));
            dispatch(setPageRotateInDocumentInfo(pageId, degreeRotate));
        } catch (error) {
            dispatch(pageRotateFailure(error));
            dispatch(addError(error));
        }
    };

export const pageScale = (scale: number): AnyAction => action(PAGE_SCALE, scale < MIN_ZOOM ? MIN_ZOOM : scale);
export const pageSize = (size: number): AnyAction => action(PAGE_SIZE, size);
export const setPdfWidth = (width: number): AnyAction => action(SET_PDF_WIDTH, width);
export const setPdfHeight = (height: number): AnyAction => action(SET_PDF_HEIGHT, height);
export const clearOptionsState = (): AnyAction => action(CLEAR_OPTIONS_STATE);
export const setClientSize = (size: IClientSize): AnyAction => action(GET_CLIENT_SIZE, size);

export const setSelectedShapes = <T extends object>(shapes: T[], scale: number): AnyAction => {
    const payload = {
        [ANNOTATIONS]: shapes.filter((shape: T): boolean =>
            shape[ANNOTATION_TYPE_ID]),
        [STAMPS]: shapes.filter((shape: T): boolean =>
            shape[STAMP_TYPE_ID]),
        scale,
    };

    return action(ADD_SELECTED_SHAPES, payload);
};

export const setSingleSelectedShape = <T extends object>(shape: T, scale: number): AnyAction =>
    action(ADD_SINGLE_SELECTED_SHAPE, { shape, scale });
export const cleanSelectedShapes = (): AnyAction => action(CLEAR_SELECTED_SHAPES);
export const copyShapesAction = (stamps: IStamp[]): AnyAction => action(COPY_SELECTED_SHAPES, stamps);
export const pastLayerShapes = (): AnyAction => action(PAST_SELECTED_SHAPES);

export const copyShapes = (isContextMenuEvent?: boolean):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        setFocusOnRedactor(isContextMenuEvent);

        const {
            stamps: {
                stamps,
            },
        } = getState();

        dispatch(copyShapesAction(stamps));
    };

export const deleteShapes = (
    isContextMenuEvent?: boolean,
    isDeleteLinkedStamp?: boolean,
    selectedAnnotationShapeslinkedArticlesId?: number[]):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        const {
            redactor:
            {
                selectedAnnotationShapes,
                selectedStampShapes,
                selectedSingleShape,
            },
            pageList: {
                selectedDocuments: [selectedDocuments],
                selectedPages: [selectedPages],
            },
            reductionMetaData: {
                redactionDocumentId,
            },
            annotation: {
                annotations,
            },
            stamps: {
                stamps,
            },
        } = getState();

        const singleIsAnnotation = selectedSingleShape && selectedSingleShape.annotationTypeId;
        const annotationShapes = selectedSingleShape && singleIsAnnotation ?
            [...selectedAnnotationShapes, selectedSingleShape] : selectedAnnotationShapes;
        const  linkedArticleStamps = [];

        if(selectedAnnotationShapes && selectedAnnotationShapes.length)  {
            selectedAnnotationShapes.map((annotation: IAnnotation) => {

                if(annotation.linkedArticles && annotation.linkedArticles.length) {
                    annotation.linkedArticles.map((articleId: number) => {
                        linkedArticleStamps.push(stamps.find((stamp: IStamp) => stamp.id === articleId).stampTypeId);
                    });
                }
            });
        } else if(
            selectedSingleShape && !!selectedSingleShape.linkedArticles && selectedSingleShape.linkedArticles.length
        ) {
            selectedSingleShape.linkedArticles.map((articleId: number) => {
                linkedArticleStamps.push(stamps.find((stamp: IStamp) => stamp.id === articleId).stampTypeId);
            });
        }

        const multiSelectStampShapes = (selectedAnnotationShapes && selectedAnnotationShapes.length) &&
            (selectedStampShapes && selectedStampShapes.length) ? selectedStampShapes : [];

        const stampsShapes = selectedSingleShape && !singleIsAnnotation ?
            [...selectedStampShapes, selectedSingleShape] :
            (((selectedAnnotationShapes && selectedAnnotationShapes.length)
            || selectedSingleShape) && linkedArticleStamps.length ?
            linkedArticleStamps : selectedStampShapes);

        const selectedAnnotationsWithLayerId = annotationShapes.map((item: IAnnotation) => {
            const layerId = annotations.find((annotation: IAnnotation) => annotation.id === item.id).layerId;

            return {
                id: item.id,
                layerId,
            };
        });
        const groupedAnnotations = groupBy(selectedAnnotationsWithLayerId, 'layerId');
        const selectedStamps = stampsShapes.map((item: IStamp) => item.id);
        const multiSelectSeverStamps = multiSelectStampShapes.length &&
            multiSelectStampShapes.map((item: IStamp) => item.id);
        const data = {
            redactionDocumentId,
            documentId: selectedDocuments,
            pageId: selectedPages,
        };

        for (const key of Object.keys(groupedAnnotations)) {
            const annotationsIds = groupedAnnotations[key].map((item: IAnnotation) => item.id);

            await dispatch(
                removeAnnotation(
                    { ...data, layerId: Number(key) },
                    annotationsIds,
                    isDeleteLinkedStamp,
                    selectedAnnotationShapeslinkedArticlesId,
                ),
            );
        }

        setFocusOnRedactor(isContextMenuEvent);

        if (!!selectedStamps.length) {
            if(!linkedArticleStamps.length) {
                await dispatch(deleteStamps(data, selectedStamps));
            }

            const { stamps: { stamps: articleStamps } } = getState();

            const articlesShouldBeCleanedIds = stampsShapes
                .filter((item: IStamp) => !articleStamps
                    .filter((stamp: IStamp) => stamp.stampTypeId === item.stampTypeId).length);

            if (!!articlesShouldBeCleanedIds.length) {
                dispatch(updatePageMetaDataInDocument(redactionDocumentId, selectedDocuments, selectedPages));
            }
        }

        if(multiSelectSeverStamps && multiSelectSeverStamps.length && !(multiSelectStampShapes === stampsShapes)) {

            const { stamps: { stamps: articleStamps } } = getState();

            const remainingStamps = [];

            articleStamps.map((stamp: IStamp)=> {
                multiSelectSeverStamps.map((stampId: number) => {
                    if(stamp && (stamp.id === stampId) && !stampsShapes.includes(stampId)) {
                        remainingStamps.push(stampId);
                    }
                });
            });

            if(remainingStamps.length) {
                await dispatch(deleteStamps(data, remainingStamps));
            }

            const articlesShouldBeCleanedIds = multiSelectStampShapes
                .filter((item: IStamp) => !articleStamps
                    .filter((stamp: IStamp) => stamp.stampTypeId === item.stampTypeId).length);

            if (!!articlesShouldBeCleanedIds.length) {
                dispatch(updatePageMetaDataInDocument(redactionDocumentId, selectedDocuments, selectedPages));
            }
        }

        dispatch(cleanSelectedShapes());
        dispatch(highlightLinkedArticles([]));
        dispatch(highlightLinkedSever(null));
        dispatch(setLinkedAnnotationId(null));
        dispatch(setLinkedArticlesFlag(false));
    };

export const updateGroupCoordinates = (
    shapesLayers: Array<IAnnotation | IStamp>,
    pdfWidth: number,
    pdfHeight: number,
    contextMenuParam: IModalIdsData,
    rotate: number,
    currentScale: number,
): ILayerShape[] => {
    const allCoordinates = shapesLayers.map((shapesLayer: ILayerShape): ICoordinate =>
        Array.isArray(shapesLayer.coordinate) ? shapesLayer.coordinate[0] : shapesLayer.coordinate);
    const startPoint = contextMenuParam && contextMenuParam.startPoint;

    if (currentScale !== 1 && startPoint) {
        startPoint.x = startPoint.x / currentScale;
        startPoint.y = startPoint.y / currentScale;
    }

    const updatedCoordinates = formatGroupCoordinates(
        allCoordinates,
        pdfWidth,
        pdfHeight,
        startPoint,
        rotate,
    );

    return shapesLayers.map((shapesLayer: ILayerShape, idx: number) => ({
        ...shapesLayer,
        coordinate: [updatedCoordinates[idx]],
    }));
};

export const pastShapes = (
    isContextMenuEvent?: boolean,
): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        const {
            redactor: {
                buffer: {
                    annotationShapes,
                    stampShapes,
                },
                pdfWidth,
                pdfHeight,
                rotate,
                scale: currentScale,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument,
                currentPage,
            },
            contextMenu: {
                contextMenuProps,
            },
        } = getState();

        if (!annotationShapes && !stampShapes || !currentDocument) {
            return;
        }

        const documentId = get(currentDocument, 'id');
        const pageId = get(currentPage, 'id');
        const layerId = get(currentPage, 'layers[0].id');

        const allShapes = [...annotationShapes, ...stampShapes];
        const allShapesWithCoordinates =
            updateGroupCoordinates(allShapes, pdfWidth, pdfHeight, contextMenuProps, rotate, currentScale);
        /* tslint:disable:no-string-literal */
        const annotations = allShapesWithCoordinates.filter(
            <G extends object>(annotation: G): boolean => !!annotation['annotationTypeId'],
        );
        const stamps = allShapesWithCoordinates.filter(
            <G extends object>(annotation: G): boolean => !annotation['annotationTypeId'],
        );
        /* tslint:enable:no-string-literal */
        const param = { redactionDocumentId, documentId, pageId, layerId };

        setFocusOnRedactor(isContextMenuEvent);

        const mainComponentClassName = 'main';
        const isRedactorActive = document.activeElement.className === mainComponentClassName;

        if (annotationShapes.length && isRedactorActive) {
            const copiedAnnotationShapes = annotations.map((annotation: IAnnotation): IAnnotation => ({
                accessCode: annotation.accessCode,
                annotationTypeId: annotation.annotationTypeId,
                borderStyle: annotation.borderStyle,
                borderWidth: annotation.borderWidth,
                color: annotation.color,
                coordinate: annotation.coordinate,
                description: annotation.description,
                isActive: annotation.isActive,
                lastModified: new Date().toISOString(),
                layerId: annotation.layerId,
                opacity: annotation.opacity,
                text: annotation.text,
                isSever: annotation.isSever,
                name: annotation.name,
                isBorderOnly: annotation.isBorderOnly,
            }));

            dispatch(addAnnotation(param, copiedAnnotationShapes, pastLayerShapes, false, true));
        }

        if (stampShapes.length) {

            const copiedStampShapes = stamps.map((stamp: IStamp): IStamp => ({
                coordinate: stamp.coordinate,
                isExemption: stamp.isExemption,
                lastModified: new Date().toISOString(),
                pageId: stamp.pageId,
                stampTypeId: stamp.isExemption ? stamp.exemptionCode : stamp.stampTypeId,
                text: stamp.text,
                fontSize: stamp.fontSize,
                rotation: stamp.rotation || stamp.rotate,
            }));

            dispatch(addStamps(param, copiedStampShapes, pastLayerShapes));
        }
    };

export const cutShapes = (isContextMenuEvent?: boolean):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => void =>
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): void => {

        const {
            redactor: {
                selectedSingleShape,
                selectedAnnotationShapes,
            },
        } = getState();
        const annotationIds = getRequiredFilteredArrayByProp(
            selectedAnnotationShapes,
            ID,
            'linkedArticles',
            'length',
        );

        if (selectedSingleShape
            && selectedSingleShape.isSever
            && !!selectedSingleShape.linkedArticles
            && !!selectedSingleShape.linkedArticles.length
            || (annotationIds && !!annotationIds.length)) {
            dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: WARNING,
                okButtonTitle: OK,
                message: CUTTING_LINKED_SEVER,
                confirm: (): () => void => (): void => {
                    setFocusOnRedactor(isContextMenuEvent);
                    dispatch(copyShapes());
                    dispatch(deleteShapes());
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    return;
                },
            }));
        } else {
            setFocusOnRedactor(isContextMenuEvent);
            dispatch(copyShapes());
            dispatch(deleteShapes());
        }

    };

export const setOverlayedShapes = (params: IPageActionParams, data: ISelectedShapeData): (
    dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(getOverlayedShapesPending());

        try {
            const response = await api.overlayedShapes.getOverlayedShapes(
                params.redactionDocumentId, params.documentId, params.pageId, data);

            dispatch(getOverlayedShapesSuccess(response));

        } catch (error) {
            dispatch(getOverlayedShapesFailure(error));
        }
    };

export const openChangeAnnotationModal = (): (dispatch: Dispatch) => void => (dispatch: Dispatch): void => {
    dispatch(openModalWindow(CHANGE_ANNOTATION_TYPE_TO, {
        id: CHANGE_ANNOTATION_TYPE_TO,
    }));
};

export const openOverlayedShapesModal = (): (dispatch: Dispatch) => void => (dispatch: Dispatch): void => {
    dispatch(openModalWindow(OVERLAYED_SHAPES, {
        id: OVERLAYED_SHAPES,
    }));
};
export const deleteLinkedShapes = (isContextMenuEvent?: boolean):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            redactor: {
                selectedSingleShape,
                selectedAnnotationShapes,
            },
        } = getState();

        const isAnnotaionLinkedArticles = selectedSingleShape && selectedSingleShape.isSever
            && selectedSingleShape.linkedArticles && selectedSingleShape.linkedArticles.length;

        const multiSelectedlinkedArticlesId = selectedAnnotationShapes && !!selectedAnnotationShapes.length
            && selectedAnnotationShapes.find((item: IAnnotation) =>
                item.linkedArticles && item.linkedArticles.length > 0,
            );
        const selectedAnnotationShapeslinkedArticlesId = multiSelectedlinkedArticlesId
            ? multiSelectedlinkedArticlesId.linkedArticles : [];

        if (!!isAnnotaionLinkedArticles) {
            dispatch(openIsDeleteLinkedStamps());

            return;
        } else if (selectedAnnotationShapeslinkedArticlesId && selectedAnnotationShapeslinkedArticlesId.length) {
            dispatch(openIsDeleteLinkedStamps(selectedAnnotationShapeslinkedArticlesId));

            return;
        } else {
            return dispatch(deleteShapes(isContextMenuEvent, false));
        }
    };

export const openIsDeleteLinkedStamps = (selectedAnnotationShapeslinkedArticlesId?: number[]):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: CONFIRMATION_DIALOG_MODAL,
            title: WARNING,
            okButtonTitle: YES_BUTTON,
            cancelButtonTitle: NO_BUTTON,
            message: DELETE_LINKED_ARTICLE_OR_EXEMPTION_MESSAGE,
            confirm: (): () => void => (): void => {
                dispatch(deleteShapes(true, true, selectedAnnotationShapeslinkedArticlesId));
                dispatch(handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL));
            },
            reject: (): () => void => (): void => {
                dispatch(deleteShapes(true, false));
                dispatch(handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL));
            },
        }));

        return;

    };

export const openSeverInfoModal = (): (dispatch: Dispatch) => void => (dispatch: Dispatch): void => {
    dispatch(openModalWindow(VIEW_SEVER_INFO, {
        id: VIEW_SEVER_INFO,
    }));
};

export const openViewCommentModal = (): (dispatch: Dispatch) => void => (dispatch: Dispatch): void => {
    dispatch(openModalWindow(VIEW_COMMENT, {
        id: VIEW_COMMENT,
    }));
};

export const setContextMenuStartPoint = (coordinate: {x: number,y: number}):
    (dispatch: Dispatch) => void => (dispatch: Dispatch): void => {
        dispatch(setContextMenuCoordinate(coordinate));
};

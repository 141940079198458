export const GET_ANNOTATION_TYPE_PENDING = 'reduction/GET_ANNOTATION_TYPE_PENDING';
export const GET_ANNOTATION_TYPE_SUCCESS = 'reduction/GET_ANNOTATION_TYPE_SUCCESS';
export const GET_ANNOTATION_TYPE_FAILURE = 'reduction/GET_ANNOTATION_TYPE_FAILURE';

export const POST_ANNOTATION_TYPE_PENDING = 'reduction/POST_ANNOTATION_TYPE_PENDING';
export const POST_ANNOTATION_TYPE_SUCCESS = 'reduction/POST_ANNOTATION_TYPE_SUCCESS';
export const POST_ANNOTATION_TYPE_FAILURE = 'reduction/POST_ANNOTATION_TYPE_FAILURE';

export const PUT_ANNOTATION_TYPE_PENDING = 'reduction/PUT_ANNOTATION_TYPE_PENDING';
export const PUT_ANNOTATION_TYPE_SUCCESS = 'reduction/PUT_ANNOTATION_TYPE_SUCCESS';
export const PUT_ANNOTATION_TYPE_FAILURE = 'reduction/PUT_ANNOTATION_TYPE_FAILURE';

export const DELETE_ANNOTATION_TYPE_PENDING = 'reduction/DELETE_ANNOTATION_TYPE_PENDING';
export const DELETE_ANNOTATION_TYPE_SUCCESS = 'reduction/DELETE_ANNOTATION_TYPE_SUCCESS';
export const DELETE_ANNOTATION_TYPE_FAILURE = 'reduction/DELETE_ANNOTATION_TYPE_FAILURE';

export const SET_SELECTED_ANNOTATIONS_TYPES = 'reduction/SET_SELECTED_ANNOTATIONS_TYPES';
export const LOCAL_CHANGE_ANNOTATIONS_TYPES = 'reduction/LOCAL_CHANGE_ANNOTATIONS_TYPES';

export const ANNOTATION_COLOR = 'annotationColor';

export const CLEAR_ERRORS = 'reduction/CLEAR_ERRORS';

import { omit } from 'lodash';
import { IUpdatedPages } from '../containers/leftTopBar/leftTopBar.model';
import {
    IStampType,
    IArticleStamp,
    IAdditionalArticleStamp, IValidExemptionDtoList,
} from '../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import {
    IAnnotation,
    IDocument,
    IPages,
    IPageDisclosureDto,
    IDocDisclosureDto,
    IHyperLink,
} from '../redux/reducers/pageList/pageList.model';
import { IAnnotationType, IAnnotationUpdate } from '../redux/reducers/annotationTypes/annotationTypes.model';
import { BlobPart } from 'file-saver';
import {
    IRequestType,
    IContactType,
    IRequestFolder,
    IRequestSecurityParams,
    IRequestSecurities,
    IAvailableSecurity,
    IRequestFolderSecurityParams,
    ISecurityGroupByFolderRsn,
    IFolderProcesses,
    IUser,
    IAttachmentTypes,
} from '../redux/reducers/amandaContent/amandaContent.model';
import { IFile, IOcrDocPage } from '../redux/reducers/documentList/documentList.model';
import {
    IDisclosureOptions,
    IFetchDisclosurOption, IGetDocumentDisclosureType, IGetDocumentsDisclosureOption,
} from '../redux/reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { IVersion } from '../redux/reducers/infoController/infoController.model';
import { IUndoDisclosure, IUndoDocDisclosure } from '../redux/reducers/pageList/pageList.model';
import { IGlobalSettings, IDisclosureSettingsUpdate } from '../redux/reducers/modalGlobalSettings/globalSettings.model';
import {
    IDisclosureContacts,
    IPeopleRecords,
    IAssignedContacts,
    IAssignedContactsByDocIdsResponse,
} from '../redux/reducers/disclosureContacts/disclosureContacts.model';
import {
    IPageActionParams,
    IDocumentAnnotations,
    IDocumentAnnotationUpdate,
} from '../redux/reducers/annotation/annotation.model';
import { IStamp, IStampAlign } from '../redux/reducers/stamps/stamps.model';
import {
    IExportController,
    IExportData,
} from '../redux/reducers/modalWindowExport/modalWindowExport.model';
import { ISearchParam } from '../containers/modalWindowContents/modalSelectContacts/searchTab/searchTab.model';
import { IRange } from '../redux/reducers/modalEditPagination/modalEditPagination.model';
import { IScannerState } from '../redux/reducers/scanner/scanner.model';
import {
    IEdmsFileDto,
    ISearchRedactionDocumentsParams, IUploadRedactionDocument, IUploadRedactionDocumentsDto,
} from '../containers/modalWindowContents/modalSearchDocument/modalSearchDocument.model';
import { IFoiFolder, ISearchDocuments } from '../redux/reducers/modalSearchDocuments/searchDocuments.model';
import { IExportPreset } from '../containers/modalWindowContents/modalPackagePresets/modalPackagePresets.model';
import {
    IWatermark,
} from '../redux/reducers/modalWatermarks/watermarksManagement.model';
import { IPaginationParams, ITaskListObject, ITaskList } from '../redux/reducers/taskQueue/taskQueue.model';
import { IRequesterReport } from '../redux/reducers/modalRequesterReport/requesterReport.model';
import { IPrinterState } from '../redux/reducers/printer/printer.model';
import {
    IInsertPagesResponse,
    IInsertPageOptionsFromRedaction,
    IImportRedactionDocumentsResponse,
    IAmandaAttachmentResponse,
    IUploadAttachmentDto,
    IValidEdms,
} from '../containers/modalWindowContents/modalWindowImport/modalWindowImport.model';
import { IStackDocumentsDto, IStackOptions, IStackPropertyOptions } from '../redux/reducers/stackList/stackList.model';
import { IFileImportObject } from '../redux/reducers/modalWindowImport/modalWindowImport.model';
import { IIndex, IIndexMetadata } from '../redux/reducers/indexMetadata/indexMetadata.model';
import { IPostSearchValue, ISearchResponseData } from '../containers/globalSearch/globalSearch.model';
import {
    IReductionDocumentsParam,
    IReductionDocumentsPostParam,
    IReductionDocumentsMetadata,
    IReductionInitialData,
    IReductionDocumentsResponseParam,
    IUserPermissions,
} from '../redux/reducers/initialization/initialization.model';
import {
    IResultRequestSearch,
    IRequestSearchParams,
    IOfficerRequest,
} from '../redux/reducers/modalRequestSearch/requestSearch.model';
import { ID } from '../constants/common.constants';
import { IEncryptionType } from '../redux/reducers/modalEncryption/modalEncryptionKeys.model';
import { IConsultees, IPageMetadataType } from '../redux/reducers/pageMetadata/notes.model';
import { reductionApi } from '../index';
import { IDescription } from '../redux/reducers/indexMetadataDescriptions/indexMetadataDescriptions.model';
import {
    IReplaceData,
} from '../containers/modalWindowContents/modalIndexKeywordManagement/modalIndexKeywordManagement.model';
import { IIndexMetadataDescriptionsDto } from '../redux/actions/indexMetadataDescriptions';
import {
    IWatermarkTypeAssociate,
} from '../redux/reducers/modalAssociateWatermarks/modalAssociateWatermarks.model';
import { IRequestLoginResponse } from './baseApi';
import { IAdvanceSearchParams } from '../containers/advancedSearch/advancedSearch.model';
import { IAuditAction, IAuditSearchDto, IAuditSearchResponse } from '../redux/reducers/auditLog/auditLog.model';
import { IAct, IAdvanceSearchResponseData } from '../redux/reducers/advanceSearch/advanceSearch.model';
import { IStackId } from '../containers/modalWindowContents/modalPageReviewSummary/modalPageReviewSummary.model';
import {
    IDuplicatesCurrentGroup,
    IDuplicatesSearchIds,
    IReferenceMaster,
    IReferencePage,
} from '../redux/reducers/duplicates/duplicates.model';
import { IMasterDocumentDto } from '../containers/modalWindowContents/modalAssignDuplicate/modalAssignDuplicate.model';
import { IDuplicateReferences, IDuplicatesRefData } from '../containers/duplicatePanels/duplicate.model';
import { IUpdateModalReleaseData } from '../containers/modalWindowContents/modalReleaseDate/modalReleaseData.model';
import { IHeader } from '../containers/leftTopBar/leftTopBar.model';
import { ISelectedShapeData } from '../containers/shapesLayer/shapesLayer.model';
import { IDocAndPageIds } from '../containers/modalWindowContents/modalUndoDisclosure/modalUndoDisclosure.model';
import { IComment } from '../containers/comment/comment.model';
import { ILabel, ISearchData } from 'redux/reducers/localization/localization.model';

export default {
    redactionDocuments: {
        // heavy
        getDocumentsMetadata(redactionDocumentId: number): Promise<IFile[]> {
            return reductionApi.get<IFile[], null>(`/redaction-documents/${redactionDocumentId}/documents`);
        },
        getDocumentsMetadataByIds(documentIds: number[], redactionDocumentId: number): Promise<IDocument[]> {
            // tslint:disable-next-line:max-line-length
            return reductionApi.post<IDocument[], { documentIds: number[] }>(`/redaction-documents/${redactionDocumentId}/documents`, { documentIds });
        },
        // light (without pages)
        getDocumentsSimpleMetadata(redactionDocumentId: number): Promise<IFile[]> { //
            return reductionApi.get<IFile[], null>(`/redaction-documents/${redactionDocumentId}/documents/metadata`);
        },
        getDocumentMetadata(redactionDocumentId: number, documentId: number): Promise<IDocument> {
            return reductionApi
                .get<IDocument, null>(`/redaction-documents/${redactionDocumentId}/documents/${documentId}`);
        },
        deleteDocument(redactionDocumentId: number, documentIds: number[]): Promise<void> {
            return reductionApi
                .delete<void, number[]>(`/redaction-documents/${redactionDocumentId}/documents`, documentIds);
        },
        uploadDocuments(redactionDocumentId: number, data: FormData): Promise<IFileImportObject> {
            return reductionApi
                .post<IFileImportObject, FormData>(
                    `/redaction-documents/${redactionDocumentId}/documents`,
                    data,
                    false,
                    true,
                );
        },
        uploadAttachmentDocuments(
            redactionDocumentId: number,
            ocrRequired: boolean,
            autoDeskew: boolean,
            stackId: number,
            uploadAttachmentDocumentsDto: IUploadAttachmentDto,
        ): Promise<IUploadRedactionDocument> {
            return reductionApi
                .put<IUploadRedactionDocument, IUploadAttachmentDto>(
                    `/redaction-documents/${redactionDocumentId}/documents/attachment?ocrRequired=${ocrRequired}
                    &autoDeskew=${autoDeskew}&stackId=${stackId}`, uploadAttachmentDocumentsDto);
        },
        uploadRedactionDocuments(
            redactionDocumentId: number,
            ocrRequired: boolean,
            autoDeskew: boolean,
            stackId: number,
            uploadRedactionDocumentsDto: IUploadRedactionDocumentsDto,
        ): Promise<IUploadRedactionDocument> {
            return reductionApi
                .put<IUploadRedactionDocument, IUploadRedactionDocumentsDto>(
                    `/redaction-documents/${redactionDocumentId}/documents/redaction?ocrRequired=${ocrRequired}
                    &autoDeskew=${autoDeskew}&stackId=${stackId}`, uploadRedactionDocumentsDto);
        },
        getDocumentListColumnHeader(lid: string): Promise<IHeader[]> {
            return reductionApi
                .get<IHeader[], null>(`/user-column-preferences/${lid}/document-list`);
        },
        modifyDocumentListColumnHeader(documentListColumnHeader: IHeader[], userId: string): Promise<IHeader[]> {
            return reductionApi.post<IHeader[], IHeader[]>
                (`/user-column-preferences/${userId}/document-list`, documentListColumnHeader);
        },
        resetDocumentListColumnHeader(userId: string): Promise<IHeader[]> {
            return reductionApi.get<IHeader[], null>(`/user-column-preferences/${userId}/document-list/resetter`);
        },
        editFileName(filename: string, redactionDocumentId: number, documentId: number): Promise<void> {
            return reductionApi.post<void, {}>
                (`/redaction-documents/${redactionDocumentId}/documents/${documentId}
            /updateDocumentFileName`, { filename });
        },
        autoAlignDocumentStamps(redactionDocumentId: number, userId: string, data: number[]): Promise<void> {
            return reductionApi.post<void, number[]>(
                `/redaction-documents/${redactionDocumentId}/documents/${userId}/autoAlignDocumentStamps`,data,
            );
        },
    },
    ocrExistingDocumentController: {
        ocrExistingDocuments(redactionDocumentId: number, data: IOcrDocPage[]): Promise<void> {
            return reductionApi
                .post<void, IOcrDocPage[]>(`/redaction-documents/${redactionDocumentId}/documents/ocr`, data);
        },
    },
    exportController: {
        exportDocument(redactionDocumentId: number, data: IExportController): Promise<IExportController> {
            return reductionApi.post<IExportController, IExportController>(
                `/redaction-documents/${redactionDocumentId}/documents/export`, data, true);
        },

        getExportDocuments(redactionDocumentId: number): Promise<IExportData> {
            return reductionApi.get<IExportData, null>(
                `/redaction-documents/${redactionDocumentId}/documents/export`);
        },
    },
    overlayedShapes: {
        getOverlayedShapes(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            data: ISelectedShapeData,
        ): Promise<any> {
            return reductionApi.post(
                `redaction-documents/${redactionDocumentId}/documents/${documentId}
                /pages/${pageId}/toggle-selection`, data,
            );
        },
    },
    annotation: {
        getAllAnnotationsPerPage(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            layerId: number,
        ): Promise<IAnnotation[]> {
            return reductionApi.get<IAnnotation[], null>(
                `redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/
                ${pageId}/layers/${layerId}/annotations`,
            );
        },

        getRedactionAnnotation(
            documentId: number,
            pageId: number,
            layerId: number,
            annotationId: number,
        ): Promise<any> {
            return reductionApi.get(
                `/documents/${documentId}/pages/${pageId}/layers/${layerId}/annotations/${annotationId}`,
            );
        },
        addRedactionAnnotation(
            {
                redactionDocumentId,
                documentId,
                pageId,
                layerId,
            }: IPageActionParams,
            data: IAnnotation[],
            isSnapTextEnabled: boolean = false,
        ): Promise<IAnnotationUpdate> {
            return reductionApi.post(
                `redaction-documents/${redactionDocumentId}/documents/
                ${documentId}/pages/${pageId}/layers/${layerId}/annotations/${isSnapTextEnabled}`,
                data,
            );
        },
        updateRedactionAnnotation(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            layerId: number,
            data: IAnnotation[],
            isSnapTextEnabled: boolean = false,
        ): Promise<IAnnotation[]> {
            return reductionApi.put<IAnnotation[], IAnnotation[]>(
                `redaction-documents/${redactionDocumentId}/documents/
                ${documentId}/pages/${pageId}/layers/${layerId}/annotations/${isSnapTextEnabled}`,
                data,
            );
        },
        deleteRedactionAnnotation(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            layerId: number,
            data: number[],
            isDeleteStamps: boolean = false,
        ): Promise<void> {
            return reductionApi.delete<void, number[]>(
                `redaction-documents/${redactionDocumentId}/documents/
                ${documentId}/pages/${pageId}/layers/${layerId}/annotations?isDeleteStamps=${isDeleteStamps}`,
                data,
            );
        },
        addRedactionAnnotationsToDocuments(
            redactionDocumentId: number,
            data: IDocumentAnnotations[],
            userId?: string,
            isSnapTextEnabled: boolean = false,
        ): Promise<IDocumentAnnotationUpdate[]> {
            return reductionApi.post(
                `redaction-documents/${redactionDocumentId}/documents/${userId}/annotations/${isSnapTextEnabled}`,
                data,
            );
        },
        updateLinkedArticles(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            layerId: number,
            annotationId: number,
            data: number[],
        ): Promise<IAnnotation[]> {
            return reductionApi.put<IAnnotation[], number[]>(
                `redaction-documents/${redactionDocumentId}/documents/
                ${documentId}/pages/${pageId}/layers/${layerId}/annotations/
                ${annotationId}/updateLinkedArticles`,
                data,
            );
        },
        undoSnapTextAnnotation(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            layerId: number,
            annotationId: number,
        ): Promise<IAnnotation[]> {
            return reductionApi.put<IAnnotation[], null>(`redaction-documents/${redactionDocumentId}/documents/
                ${documentId}/pages/${pageId}/layers/${layerId}/annotations/
                ${annotationId}/undoSnapTextAnnotation`,
            );
        },
    },
    pages: {
        fetchPaginatedPages(
            redactionDocumentId: number,
            documentId: number,
            page: number,
            sort: string): Promise<IPages[]> {
            return reductionApi.get<IPages[], null>
                (`/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages?page=${page}${sort}`);
        },
        getPageInDocument(redactionDocumentId: number, documentId: number, pageId: number): Promise<IPages> {
            return reductionApi.get<IPages, null>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}`,
                null,
            );
        },
        insertPagesFromRedaction(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            insertPageOptions: IInsertPageOptionsFromRedaction,
        ): Promise<IInsertPagesResponse> {
            return reductionApi.post<IInsertPagesResponse, IInsertPageOptionsFromRedaction>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}`,
                insertPageOptions,
                null,
                null,
            );
        },
        insertPagesFromFiles(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            data: FormData,
        ): Promise<IInsertPagesResponse> {
            return reductionApi.post<IInsertPagesResponse, FormData>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}`,
                data,
                false,
                true,
            );
        },
        getPageResource(redactionDocumentId: number, documentId: number, pageId: number): Promise<string> {
            return reductionApi.get<string, null>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/resource`,
                null,
                true,
            );
        },
        getPageResourceByActualNumber(
            redactionDocumentId: number,
            documentId: number,
            actualPageNumber: number): Promise<string> {
            return reductionApi.get<string, { actualPageNumber: number }>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/resource`,
                { actualPageNumber },
                true,
            );
        },
        getPageNumberByPageId(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            sort: string,
        ): Promise<any> {
            return reductionApi.get(
                `redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/getPageNumberByPageId${sort}`,
            );
        },
        movePages(
            redactionDocumentId: number,
            documentId: number,
            data: IUpdatedPages[],
            pageNumber: number,
        ): Promise<IPages[]> {
            return reductionApi.put<IPages[], IUpdatedPages[]>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages?page=${pageNumber}`, data);
        },
        movePagesInDocument(redactionDocumentId: number, documentId: number, data: IUpdatedPages[]): Promise<IPages[]> {
            return reductionApi.put<IPages[], IUpdatedPages[]>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages`, data,
            );
        },
        deletePageFromDocument(redactionDocumentId: number, documentId: number, pageId: number[]): Promise<void> {
            return reductionApi.delete<void, number[]>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages`,
                pageId,
            );
        },
        updatePageDisclosure(
            redactionDocumentId: number,
            documentId: number,
            pageDisclosureDto: IPageDisclosureDto,
        ): Promise<IDocument> {
            return reductionApi.put<IDocument, IPageDisclosureDto>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/disclosures`,
                pageDisclosureDto,
            );
        },
        rotatePage(redactionDocumentId: number, documentId: number, pageId: number, data: number): Promise<IPages> {
            return reductionApi
                .patch<IPages, null>(
                    `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/rotate`,
                    { angle: data },
                );
        },
        reversePages(redactionDocumentId: number, documentId: number): Promise<IPages[]> {
            return reductionApi
                .put<IPages[], null>(
                    `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/reverse`,
                );
        },
        getPageListColumnHeader(lid: string): Promise<IHeader[]> {
            return reductionApi.get<IHeader[], null>
                (`/user-column-preferences/${lid}/page-list`);
        },
        modifyPageListColumnHeader(pageListColumnHeader: IHeader[], userId: string): Promise<IHeader[]> {
            return reductionApi.post<IHeader[], IHeader[]>
                (`/user-column-preferences/${userId}/page-list`, pageListColumnHeader);
        },
        resetPageListColumnHeader(userId: string): Promise<IHeader[]> {
            return reductionApi.get<IHeader[], null>(`/user-column-preferences/${userId}/page-list/resetter`);
        },
        autoAlignPageStamps(
            redactionDocumentId: number,
            documentId: number,
            userId: string,
            data: number[]): Promise<void> {
            return reductionApi.post<void, number []>(
                `/redaction-documents/${redactionDocumentId}
                /documents/${documentId}/${userId}/autoAlignPageStamps`,data,
            );
        },
        getCurrentPageComments(redactionDocumentId: number, documentId: number, pageId: number): Promise<IComment> {
            return reductionApi.get<IComment, null>(`redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/page-comments`);
        },
        getCurrentPageHyperLinks(redactionDocumentId: number, documentId: number, pageId: number): Promise<IHyperLink> {
            return reductionApi.get<IHyperLink, null>(`redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/page-hyperlinks`);
        },
        deletePageComment(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            comment: IComment,
            ): Promise<boolean> {
            return reductionApi.delete<boolean, IComment>(
                `redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/page-comments`,
                comment);
        },
    },
    stampTypes: {
        getStampTypes(): Promise<IStampType[]> {
            return reductionApi.get<IStampType[], null>('/stamp-types');
        },
        addStampType(data: IStampType[]): Promise<IStampType[]> {
            return reductionApi.post<IStampType[], IStampType[]>('/stamp-types', data);
        },
        updateStampType(data: IStampType[]): Promise<IStampType[]> {
            return reductionApi.put<IStampType[], IStampType[]>(`/stamp-types`, data);
        },
        deleteStampType(stampTypeId: number): Promise<void> {
            return reductionApi.delete<void, null>(`/stamp-types/${stampTypeId}`);
        },
    },
    reportController: {
        getRequesterReport(redactionDocumentId: number, reportLang: string, param?: IRequesterReport[]): Promise<IRequesterReport[]> {
            return reductionApi.get<IRequesterReport[], IRequesterReport[]>
                (`/redaction-documents/${redactionDocumentId}/reports/requester`,
                    param,
                    true,
                    null,
                    {reportLang}
                );
        },
        // TODO We don't know what back give, so must fix any
        postRequesterReport(
            redactionDocumentId: number,
            reportLang: string,
            param?: IRequesterReport[],
            documentIds?: number[],
        ): Promise<any> {
            return reductionApi.post<any, number[]>(
                `/redaction-documents/${redactionDocumentId}/reports/requester`,
                documentIds,
                true,
                null,
                {param, reportLang},
            );
        },
        getReportOfficer(redactionDocumentId: number, reportLang:string): Promise<number> {
            return reductionApi.get<number, null>(
                `/redaction-documents/${redactionDocumentId}/reports/officer`,
                null,
                true,
                null,
                {reportLang}
            );
        },
        postReportOfficer(redactionDocumentId: number, dataRequesterReport: number[], reportLang: string): Promise<number> {
            return reductionApi.post<number, number[]>(
                `/redaction-documents/${redactionDocumentId}/reports/officer`,
                dataRequesterReport,
                true,
                false,
                {reportLang},
                false,
                false,
                false,
            );
        },
        getReportIndexNotes(redactionDocumentId: number, reportLang: string): Promise<number> {
            return reductionApi.get<number, null>(
                `/redaction-documents/${redactionDocumentId}/reports/index-notes`,
                null,
                true,
                null,
                {reportLang}
            );
        },
        postReportIndexNotes(redactionDocumentId: number, dataRequesterReport: number[], reportLang: string): Promise<number> {
            return reductionApi.post<number, number[]>(
                `/redaction-documents/${redactionDocumentId}/reports/index-notes`,
                dataRequesterReport,
                true,
                null,
                {reportLang},
            );
        },
        getPagesReviewSummary(redactionDocumentId: number, stackId: IStackId, reportLang: string): Promise<File> {
            return reductionApi.get<File, IStackId>(
                `/redaction-documents/${redactionDocumentId}/reports/pages-review`,
                stackId,
                true,
                null,
                {reportLang}
            );
        },

    },
    stamps: {
        getStampsPerPage(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
        ): Promise<IStamp[]> {
            return reductionApi.get<IStamp[], null>(
                `redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/
                ${pageId}/stamps`,
            );
        },
        addStampsToPage(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            data: IStamp[],
        ): Promise<IStamp[]> {
            return reductionApi.post<IStamp[], IStamp[]>(`redaction-documents/${redactionDocumentId}/documents/
            ${documentId}/pages/${pageId}/stamps`, data);
        },
        updateStamp(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            data: IStamp[],
        ): Promise<IStamp[]> {
            return reductionApi.put<IStamp[], IStamp[]>(`redaction-documents/${redactionDocumentId}/documents/
            ${documentId}/pages/${pageId}/stamps/`, data);
        },
        deleteStamps(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            data: number[],
        ): Promise<void> {
            return reductionApi.delete<void, number[]>(`redaction-documents/${redactionDocumentId}/documents/
            ${documentId}/pages/${pageId}/stamps/`, data);
        },
        getStampAlingment(userId: string): Promise<IStampAlign> {
            return reductionApi.get<IStampAlign, null>(`/user-preferences/${userId}/getUserPreference`);

        },
        updateStampAlingment(data: IStampAlign): Promise<IStampAlign> {
            return reductionApi.post<IStampAlign, IStampAlign>(`/user-preferences/updateUserPreference`, data);
        },
    },
    amandaExemptions: {
        getArticleStampTypes(redactionDocumentId: number): Promise<IArticleStamp[]> {
            // TODO backend authorization isn't ready and string token is hardcoded. Need be changed
            return reductionApi
                .get<IArticleStamp[], { token: 'token' }>(
                    `/redaction-documents/${redactionDocumentId}/amanda/exemptions`,
                    { token: 'token' },
                );
        },

        getArticleStampTypesByExemptionsId(data: number[]): Promise<IAdditionalArticleStamp[]> {
            return reductionApi.post<IAdditionalArticleStamp[], number[]>
                ('/redaction-documents/exemptions/stamp-types', data);
        },
    },
    encryptionController: {
        getEncryptionKeys(): Promise<IEncryptionType[]> {
            return reductionApi.get<IEncryptionType[], null>(`/encryption/keys`);
        },
        postEncryption(data: IEncryptionType): Promise<IEncryptionType> {
            return reductionApi.post<IEncryptionType, IEncryptionType>(`/encryption/keys`, data);
        },
        putEncryption(id: number, data: IEncryptionType): Promise<IEncryptionType> {
            return reductionApi.put<IEncryptionType, IEncryptionType>(`/encryption/keys/${id}`, data);
        },
        deleteEncrypt(id: number): Promise<void> {
            return reductionApi.delete<void, null>(`/encryption/keys/${id}`);
        },
    },
    annotationType: {
        getAllAnnotationType(): Promise<IAnnotationType[]> {
            return reductionApi.get<IAnnotationType[], null>('/annotation-types');
        },
        postAnnotationType(data: IAnnotationType[]): Promise<IAnnotationType> {
            return reductionApi.post<IAnnotationType, IAnnotationType[]>('/annotation-types', data);
        },
        updateAnnotationType(data: IAnnotationType[]): Promise<IAnnotationType> {
            return reductionApi.put<IAnnotationType, IAnnotationType[]>(`/annotation-types`, data);
        },
        deleteAnnotationType(id: number): Promise<void> {
            return reductionApi.delete<void, null>(`/annotation-types/${id}`);
        },
    },
    disclosureTypes: {
        getAllDisclosureTypes(isDuplicateRequiredOnly?: boolean): Promise<IFetchDisclosurOption[]> {
            return reductionApi.get<IFetchDisclosurOption[], null>(
                '/disclosure-types', null, false, false, { isDuplicateRequiredOnly });
        },
        getCurrentDisclosureTypes(redactionDocumentId: number): Promise<IFetchDisclosurOption[]> {
            return reductionApi.get<IFetchDisclosurOption[], null>(
                `/disclosure-types/redaction-document/${redactionDocumentId}`,
            );
        },
        // TODO need refactoring or to leave any, because we have two different case
        postDisclosureType(data: IDisclosureOptions): Promise<any> {
            return reductionApi.post<IDisclosureOptions, any>(`/disclosure-types`, data);
        },
        // TODO need refactoring or to leave any, because we have two different cases
        updateDisclosureType(id: number, data: IDisclosureOptions): Promise<any> {
            return reductionApi.put<IDisclosureOptions, any>(`/disclosure-types/${id}`, data);
        },
        deleteDisclosureType(id: number): Promise<void> {
            return reductionApi.delete<void, null>(`/disclosure-types/${id}`);
        },
        getDocumentsDisclosureTypes(
            redactionDocumentId: number,
            documentIds: number[],
        ): Promise<IGetDocumentsDisclosureOption[]> {
            return reductionApi.post<IGetDocumentsDisclosureOption[], number[]>(
                `/disclosure-types/redaction-document/${redactionDocumentId}/documents`,
                documentIds,
            );
        },
        calculateDocumentDisclosureType(
            redactionDocumentId: number,
            documentId: number,
        ): Promise<IGetDocumentDisclosureType> {
            return reductionApi.get<IGetDocumentDisclosureType, null>(
                `/disclosure-types/redaction-document/${redactionDocumentId}/documents/${documentId}`,
            );
        },
    },
    stackController: {
        getStackList(redactionDocumentId: number): Promise<IStackOptions[]> {
            return reductionApi.get<IStackOptions[], null>(`/redaction-documents/${redactionDocumentId}/stacks`);
        },
        postStack(redactionDocumentId: number, data: IStackOptions): Promise<IStackOptions> {
            return reductionApi
                .post<IStackOptions, IStackOptions>(`/redaction-documents/${redactionDocumentId}/stacks`, data);
        },
        updateStack(redactionDocumentId: number, data: IStackOptions[]): Promise<IStackOptions[]> {
            return reductionApi
                .put<IStackOptions[], IStackOptions[]>(`/redaction-documents/${redactionDocumentId}/stacks`, data);
        },
        deleteStack(redactionDocumentId: number, id: number): Promise<void> {
            return reductionApi.delete<void, null>(`/redaction-documents/${redactionDocumentId}/stacks/${id}`);
        },
        getStackDocuments(redactionDocumentId: number, id: number): Promise<IFile[]> {
            return reductionApi
                .get<IFile[], null>(`/redaction-documents/${redactionDocumentId}/stacks/${id}/documents`);
        },
        updateStackDocumentRelation(redactionDocumentId: number, stackDto: IStackDocumentsDto): Promise<IDocument[]> {
            return reductionApi.put<IDocument[], IStackDocumentsDto>(
                `/redaction-documents/${redactionDocumentId}/stacks/documents`, stackDto,
            );
        },
    },
    stackPropertyController: {
        getStackPropertyList(): Promise<IStackPropertyOptions[]> {
            return reductionApi.get<IStackPropertyOptions[], null>('stack-properties/');
        },
        postStackProperty(data: IStackPropertyOptions): Promise<IStackPropertyOptions> {
            return reductionApi.post<IStackPropertyOptions, IStackPropertyOptions>('stack-properties/', data);
        },
        updateStackProperty(data: IStackPropertyOptions): Promise<IStackPropertyOptions> {
            return reductionApi.put<IStackPropertyOptions, IStackPropertyOptions>(
                `stack-properties/${data[ID]}`, data,
            );
        },
        deleteStackProperty(id: number): Promise<void> {
            return reductionApi.delete<void, null>(`stack-properties/${id}`);
        },
    },
    amandaRequests: {
        getRequestTypes(): Promise<IRequestType[]> {
            // TODO backend authorization isn't ready and string token is hardcoded. Need be changed
            return reductionApi
                .get<IRequestType[], { token: 'token' }>(
                    `redaction-documents/amanda/request-types`,
                    { token: 'token' },
                );
        },
        getPeopleTypes(): Promise<IContactType[]> {
            // TODO backend authorization isn't ready and string token is hardcoded. Need be changed
            return reductionApi
                .get<IContactType[], { token: 'token' }>(
                    `redaction-documents/amanda/people-types`,
                    { token: 'token' },
                );
        },
        getPeopleTypesByDisclosureType(
            redactionDocumentId: number,
            disclosureTypeId: number,
        ): Promise<IDisclosureContacts[]> {
            // TODO backend authorization isn't ready and string token is hardcoded. Need be changed
            return reductionApi.get<IDisclosureContacts[], { token: 'token' }>(
                `redaction-documents/amanda/people-types/disclosures/${disclosureTypeId}`,
                { token: 'token' },
            );
        },
        searchPeopleRecords(
            disclosureTypeId: number,
            data: ISearchParam,
        ): Promise<IPeopleRecords[]> {
            return reductionApi.get<IPeopleRecords[], ISearchParam>(
                `redaction-documents/amanda/people/disclosures/${disclosureTypeId}`,
                data,
            );
        },
        getAssignedContacts(redactionDocumentId: number, data: { pageIds: string }): Promise<IAssignedContacts[]> {
            return reductionApi.get<IAssignedContacts[], { pageIds: string }>(
                `redaction-documents/${redactionDocumentId}/amanda/people/pages`,
                data,
            );
        },
        /// TODO backend authorization isn't ready and string token is hardcoded. Need be changed
        getFolderProcesses(redactionDocumentId: number, token: number = 1): Promise<IFolderProcesses[]> {
            return reductionApi.get<IFolderProcesses[], { token: number }>(
                `redaction-documents/${redactionDocumentId}/amanda/folder-processes`,
                { token },
            );
        },
        getUsers(): Promise<IUser[]> {
            return reductionApi.get<IUser[], { token: 'token' }>(
                `redaction-documents/amanda/users`,
            );
        },
        getAttachmentTypes(redactionDocumentId: number, type: string, rsn: number): Promise<IAttachmentTypes[]> {
            return reductionApi.get<IAttachmentTypes[], { rsn: number}>(
                `redaction-documents/${redactionDocumentId}/documents/export/attachment-type/${type}/${rsn}`,
            );
        },
    },
    edmsController: {
        getEdmsAdaptors(): Promise<IValidEdms[]> {
            return reductionApi.get<IValidEdms[], null>(
                `redaction-documents/edms/adaptors`,
            );
        },

        getEdmsFiles(inputURLData: string, edmsAdaptor: string): Promise<IEdmsFileDto[]> {
            return reductionApi.get<IEdmsFileDto[], null>(
                `redaction-documents/edms/files`, null, false, false,
                { inputURLData, edmsAdaptor },
                false, false
            );
        },
        uploadEdmsDocuments(
            redactionDocumentId: number,
            ocrRequired: boolean,
            autoDeskew: boolean,
            stackId: number,
            fromScanner: boolean,
            edmsFileDtos: IEdmsFileDto[],
        ): Promise<IFileImportObject> {
            return reductionApi
                .post<IFileImportObject, IEdmsFileDto[]>(
                    `/redaction-documents/edms/upload?redactionDocumentId=${redactionDocumentId}&ocrRequired=${ocrRequired}
                    &autoDeskew=${autoDeskew}&stackId=${stackId}&fromScanner=${fromScanner}`, edmsFileDtos);
        },
    },
    auditController: {
        getAuditActions(): Promise<IAuditAction[]> {
            return reductionApi.get<IAuditAction[], null>(
                `/audit/actions`,
            );
        },
        getSearchAuditLogs(data: IAuditSearchDto): Promise<IAuditSearchResponse[]> {
            return reductionApi.post<IAuditSearchResponse[], IAuditSearchDto>(
                `/audit/search`,
                data,
            );
        },
        exportAllAuditLogs(): Promise<BlobPart> {
            return reductionApi.get<BlobPart, null>('/audit/report-csv', null, true);
        },
        exportAuditLogs(data: IAuditSearchDto): Promise<BlobPart> {
            return reductionApi.post<BlobPart, IAuditSearchDto>('/audit/report-csv', data, true);
        },
        eraseAuditLogs(): Promise<void> {
            return reductionApi.delete<void, null>('/audit/erase');
        },
    },
    requestController: {
        getImportRedactionDocuments(folderRsn: number): Promise<IImportRedactionDocumentsResponse> {

            return reductionApi.get<IImportRedactionDocumentsResponse, { folderRsn: number }>(
                `/requests/import-documents`,
                { folderRsn },
            );
        },
        getImportAmandaAttachments(folderRsn: number): Promise<IAmandaAttachmentResponse[]> {
            return reductionApi.get<IAmandaAttachmentResponse[], { folderRsn: number }>(
                `/requests/attachments`,
                { folderRsn },
            );
        },
        getSearchRequestsFolders(data: ISearchRedactionDocumentsParams): Promise<ISearchDocuments[]> {

            return reductionApi.get<ISearchDocuments[], IRequestSecurityParams>(
                `/requests/request-folders`,
                data,
            );
        },
        getFoiRequestTypes(): Promise<IFoiFolder[]> {
            return reductionApi.get<IFoiFolder[], {}>(
                `/requests/foi-types`,
                {},
                false,
                false,
            );
        },
        searchForRequest(params: IRequestSearchParams): Promise<IResultRequestSearch[]> {
            return reductionApi.get<IResultRequestSearch[], IRequestSearchParams>(
                '/requests/search',
                params,
            );
        },
    },
    peopleController: {
        getOfficers(): Promise<IOfficerRequest[]> {
            return reductionApi.get<IOfficerRequest[], null>(
                `/redaction-documents/amanda/people/officers`,
            );
        },
    },
    requestSecurityController: {
        getRequestsFolders({ folderType }: IRequestSecurityParams): Promise<IRequestFolder[]> {

            return reductionApi.get<IRequestFolder[], IRequestSecurityParams>(
                `/amanda/request-security/request-folders`,
                { folderType },
                false,
                false,
            );
        },
        getRequestSecurity({ folderType }: IRequestSecurityParams): Promise<IRequestSecurities> {
            return reductionApi.get<IRequestSecurities, IRequestSecurityParams>(
                `amanda/request-security`,
                { folderType },
                false,
                false,
            );
        },
        updateRequestSecurity(
            data: IRequestSecurities,
            { folderType }: IRequestSecurityParams,
        ): Promise<IRequestSecurities> {
            return reductionApi.put<IRequestSecurities, IRequestSecurityParams>(
                `amanda/request-security`,
                data,
            );
        },
        getAvailableSecurity({ folderType }: IRequestSecurityParams): Promise<IAvailableSecurity> {
            return reductionApi.get<IAvailableSecurity, IRequestSecurityParams>(
                `amanda/request-security/available-security`,
                { folderType },
                false,
                false,
            );
        },
    },
    redactionDocumentController: {
        getRedactionDocuments<T>(data: IReductionDocumentsParam<T>): Promise<IReductionDocumentsMetadata[]> {
            return reductionApi.get<IReductionDocumentsMetadata[], IReductionDocumentsParam<T>>(
                `redaction-documents`,
                data,
            );
        },
        createRedactionDocument<T>(folderId: string): Promise<IReductionDocumentsResponseParam> {
            return reductionApi.post<IReductionDocumentsResponseParam, IReductionDocumentsPostParam<T>>(
                `redaction-documents`,
                null,
                null,
                null,
                { folderId },
                true,
                null,
            );
        },
    },
    folderSecurityController: {
        getRequestSecurityGroupsByFolderRsn(
            { folderRsn }: IRequestFolderSecurityParams,
        ): Promise<ISecurityGroupByFolderRsn> {
            return reductionApi.get<ISecurityGroupByFolderRsn, IRequestFolderSecurityParams>(
                `amanda/folder-security`,
                { folderRsn },
                false,
                false,
            );
        },
        updateRequestSecurityGroupsByFolderRsn(
            data: ISecurityGroupByFolderRsn,
            { folderRsn }: IRequestFolderSecurityParams,
        ): Promise<ISecurityGroupByFolderRsn> {
            return reductionApi.put<ISecurityGroupByFolderRsn, IRequestFolderSecurityParams>(
                `amanda/folder-security`,
                data,
            );
        },
    },
    infoController: {
        getRedactionVersions(): Promise<IVersion> {
            return reductionApi.get<IVersion, null>('info/version');
        },
    },
    globalSettings: {
        getGlobalSettings(): Promise<IGlobalSettings> {
            return reductionApi.get<IGlobalSettings, null>('global-settings');
        },
        updateGlobalSettings(data: IDisclosureSettingsUpdate): Promise<IDisclosureSettingsUpdate> {
            return reductionApi.put<IDisclosureSettingsUpdate, IDisclosureSettingsUpdate>('global-settings', data);
        },
    },
    disclosureController: {
        resetPageDisclosure(
            redactionDocumentId: number,
            documentId: number,
            data: IUndoDisclosure,
            isDeleteStamps: boolean = false,
        ): Promise<IDocument> {
            return reductionApi.put<IDocument, IUndoDisclosure>(
                `redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/disclosures/resetter?isDeleteStamps=${isDeleteStamps}`,
                data,
            );
        },
        resetDocDisclosure(
            redactionDocumentId: number,
            data: IUndoDocDisclosure,
            isDeleteStamps: boolean = false,
        ): Promise<IDocument[]> {
            return reductionApi.put<IDocument[], IUndoDocDisclosure>(
                `redaction-documents/${redactionDocumentId}/disclosures/resetter?isDeleteStamps=${isDeleteStamps}`,
                data,
            );
        },
        updateDocumentDisclosure(
            redactionDocumentId: number,
            disclosureTypeId: number,
            data: IDocDisclosureDto,
        ): Promise<IDocument[]> {
            return reductionApi.put<IDocument[], IDocDisclosureDto>(
                `redaction-documents/${redactionDocumentId}/disclosures/${disclosureTypeId}`,
                data,
            );
        },
        hasLinkedArticles(
            redactionDocumentId: number,
            data: IDocAndPageIds,
        ): Promise<boolean> {
            return reductionApi.post<boolean, IDocAndPageIds>(
                `redaction-documents/${redactionDocumentId}/documents/hasLinkedArticles`,
                data,
            );
        },
    },
    paginationController: {
        resetPagination(redactionDocumentId: number): Promise<IFile[]> {
            return reductionApi.put<IFile[], null>(`redaction-documents/${redactionDocumentId}/repagination`);
        },
        resetDocumentsPagination(redactionDocumentId: number, documentIds: number[]): Promise<IFile[]> {
            return reductionApi.put<IFile[], number[]>(
                `redaction-documents/${redactionDocumentId}/documents/pagination`,
                documentIds,
            );
        },
        deletePaginationIndex(redactionDocumentId: number, documentsId: number[]): Promise<IFile[]> {
            return reductionApi.delete<IFile[], number[]>(
                `redaction-documents/${redactionDocumentId}/documents/pagination`,
                documentsId,
            );
        },
        getAvailablePaginationRange(
            redactionDocumentId: number,
            documentId: number,
        ): Promise<IRange[]> {
            return reductionApi.get<IRange[], null>(
                `redaction-documents/${redactionDocumentId}/documents/${documentId}/repagination/range`,
            );
        },
        updatePaginationIndex(
            documentId: number,
            paginationIndex: number,
            redactionDocumentId: number,
        ): Promise<IDocument> {
            return reductionApi.put<IDocument, number>(
                `redaction-documents/${redactionDocumentId}/documents/${documentId}/pagination/${paginationIndex}`,
            );
        },
    },
    scanner: {
        getScannerSettings(): Promise<IScannerState> {
            return reductionApi.get<IScannerState, IRequestSecurityParams>(`scanner`, {}, false, false);
        },
    },
    taskQueue: {
        getDocumentTasks(redactionDocumentId: number, params?: IPaginationParams): Promise<ITaskListObject> {
            return reductionApi.get<ITaskListObject, IPaginationParams>(
                `redaction-documents/${redactionDocumentId}/document-tasks`,
                params,
            );
        },
        deleteTask(redactionDocumentId: number, taskId: number): Promise<void> {
            return reductionApi.delete<void, null>(
                `redaction-documents/${redactionDocumentId}/document-tasks/${taskId}`,
            );
        },
        updateTask(redactionDocumentId: number, taskId: number, status: string): Promise<ITaskList> {
            return reductionApi.put<ITaskList, null>(
                `redaction-documents/${redactionDocumentId}/document-tasks/${taskId}`,
                null,
                { status },
            );
        },
    },
    printers: {
        getPrinters(): Promise<IPrinterState> {
            return reductionApi.get<IPrinterState, IRequestSecurityParams>(
                `/redaction-documents/amanda/printers`,
                {},
                false,
                false,
            );
        },
    },
    packagePresets: {
        addPackagePreset(data: IExportPreset): Promise<IExportPreset> {
            return reductionApi.post<IExportPreset, IExportPreset>('/package-presets', data);
        },
        getAllPackagePresets(): Promise<IExportPreset[]> {
            return reductionApi.get<IExportPreset[], IExportPreset[]>('/package-presets');
        },
        editPackagePreset(data: IExportPreset): Promise<IExportPreset> {
            return reductionApi.put<IExportPreset, IExportPreset>(`/package-presets/${data.id}`, data);
        },
        deletePackagePreset(presetId: number): Promise<number> {
            return reductionApi.delete<number, number>(`/package-presets/${presetId}`);
        },
    },
    indexMetadata: {
        getIndexMetadata(): Promise<IIndexMetadata[]> {
            return reductionApi.get<IIndexMetadata[], null>('/index-metadata');
        },
        deletable(metadataId: number): Promise<boolean> {
            return reductionApi.get<boolean, null>(`/index-metadata/${metadataId}/deletable`);
        },
        getIndexMetadataDescriptions(redactionDocumentId: number): Promise<IDescription[]> {
            return reductionApi.get<IDescription[], null>(
                `/index-metadata/redaction-documents/${redactionDocumentId}/descriptions`);
        },
        saveIndexMetadataDescriptions(
            redactionDocumentId: number,
            data: IIndexMetadataDescriptionsDto[],
        ): Promise<IDescription[]> {
            return reductionApi.post<IDescription[], IIndexMetadataDescriptionsDto[]>(
                `/index-metadata/redaction-documents/${redactionDocumentId}/lookup`,
                data,
            );
        },
        replaceStringDescription(
            redactionDocId: number,
            metadataId: number,
            data: IReplaceData,
        ): Promise<IReplaceData> {
            return reductionApi.post<IReplaceData, IReplaceData>(
                `/index-metadata/redaction-documents/${redactionDocId}/${metadataId}/replace`, data);
        },
        replaceIndexDescription(redactionDocId: number, data: IReplaceData): Promise<IReplaceData> {
            return reductionApi.post<IReplaceData, IReplaceData>(
                `/index-metadata/redaction-documents/${redactionDocId}/lookup/replace`, data);
        },
        exportIndexMetadataOccurrences(redactionDocumentId: number, metadataId: number): Promise<BlobPart> {
            return reductionApi
                .post<BlobPart, null>(`/index-metadata/redaction-documents/${redactionDocumentId}/export/${metadataId}`,
                    null,
                    true,
                );
        },
    },
    documentIndex: {
        getDocumentIndex(redactionDocumentId: number, documentId: number): Promise<IIndex[]> {
            return reductionApi
                .get<IIndex[], null>(`/redaction-documents/${redactionDocumentId}
                /documents/${documentId}/index-metadata`);
        },
        saveDocumentIndex(redactionDocumentId: number, documentId: number, data: IIndex[]): Promise<IIndex[]> {
            return reductionApi.post<IIndex[], IIndex[]>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/index-metadata`,
                data,
            );
        },
    },
    pageIndex: {
        getPageIndex(redactionDocumentId: number, documentId: number, pageId: number): Promise<IIndex[]> {
            return reductionApi
                .get<IIndex[], null>(`/redaction-documents/${redactionDocumentId}
                /documents/${documentId}/pages/${pageId}/index-metadata`);
        },
        savePageIndex(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            data: IIndex[]): Promise<IIndex[]> {
            return reductionApi.post<IIndex[], IIndex[]>(
                `/redaction-documents/${redactionDocumentId}
                /documents/${documentId}/pages/${pageId}/index-metadata`,
                data,
            );
        },
    },
    watermarksController: {
        getAllWatermarks(): Promise<IWatermark[]> {
            return reductionApi.get<IWatermark[], IWatermark[]>('/watermark-types');
        },
        postWatermark(watermark: IWatermark, file: File): Promise<IWatermark> {
            const data = new FormData();

            data.append('watermarkType', JSON.stringify(watermark));

            if (file) {
                data.append('image', file, file.name);
            }

            return reductionApi.post<IWatermark, any>(
                '/watermark-types',
                data,
                false,
                true,
            );
        },
        putWatermark(watermark: IWatermark, file: File): Promise<IWatermark> {
            const watermarkObj = omit(watermark, ID);
            const watermarkType = JSON.stringify(watermarkObj);
            const data = new FormData();

            data.append('watermarkType', watermarkType);

            if (file) {
                data.append('image', file, file.name);
            }

            return reductionApi.put<IWatermark, any>(
                `/watermark-types/${watermark.id}`,
                data,
                null,
                false,
                true,
            );
        },
        deleteWatermark(watermarkId: number): Promise<void> {
            return reductionApi.delete<void, null>(`/watermark-types/${watermarkId}`);
        },
        getWaterMarkImage(watermarkTypeId: number): Promise<string> {
            return reductionApi.get<string, null>(
                `/watermark/image/${watermarkTypeId}`,
                null,
                false,
                false,
                null,
                false,
                true,
            );
        },
    },
    watermarksTypeAssociateController: {
        getWatermarkTypeAssociateSettings(): Promise<any[]> {
            return reductionApi.get<IWatermark[], IWatermark[]>('/watermark-settings');
        },
        updateWatermarkTypeAssociateSettings(data: IWatermarkTypeAssociate[]): Promise<IWatermarkTypeAssociate[]> {
            return reductionApi.put<IWatermarkTypeAssociate[], IWatermarkTypeAssociate[]>('/watermark-settings', data);
        },

    },
    searchController: {
        postSearchQuery(data: IPostSearchValue, redactionDocumentId: number): Promise<ISearchResponseData[]> {
            return reductionApi
                .post<ISearchResponseData[], IPostSearchValue>(`/redaction-documents/${redactionDocumentId}/search`,
                    data);
        },
        postAdvanceSearchQuery(data: IAdvanceSearchParams, redactionDocumentId: number):
            Promise<IAdvanceSearchResponseData[]> {
            return reductionApi
                .post<IAdvanceSearchResponseData[], IAdvanceSearchParams>(
                    `/redaction-documents/${redactionDocumentId}/search/advance`,
                    data);
        },
        getGlobalSearchListColumnHeader(lid: string): Promise<IHeader[]> {
            return reductionApi
                .get<IHeader[], null>(`/user-column-preferences/${lid}/search-list`);
        },
        modifyGlobalSearchColumnHeader(globalSearchListColumnHeader: IHeader[], userId: string): Promise<IHeader[]> {
            return reductionApi.post<IHeader[], IHeader[]>
                (`/user-column-preferences/${userId}/search-list`, globalSearchListColumnHeader);
        },
        resetGlobalSearchColumnHeader(userId: string): Promise<IHeader[]> {
            return reductionApi.get<IHeader[], null>(`/user-column-preferences/${userId}/search-list/resetter`);
        },

    },
    loginController: {
        loginEntryRequest(redactionDocumentId: number): Promise<IReductionInitialData> {
            return reductionApi
                .get<IReductionInitialData, { redactionDocumentId: number }>(`/redaction-documents/`,
                    {
                        redactionDocumentId,
                    });
        },
        loginRequest(lid: string, isFirstLogin: boolean, language: string, mode: string): Promise<IRequestLoginResponse> {
            return reductionApi.post<IRequestLoginResponse, null>(
                `/redaction-documents/login`,
                null,
                false,
                false,
                { lid, isFirstLogin, language, mode },
                true,
                false,
                true,
            );
        },
    },
    permissionController: {
        getUserPermissions(redactionDocumentId: number): Promise<IUserPermissions> {
            return reductionApi
                .get<IUserPermissions, null>(
                    `/redaction-documents/${redactionDocumentId}/permissions`,
                    null,
                    false,
                    false,
                    null,
                );
        },
        getAdminPermissions(lid: string): Promise<IUserPermissions> {
            return reductionApi.get<IUserPermissions, null>(
                `/admin-permissions`,
                null,
                false,
                false,
                { lid },
            );
        },
    },
    consultee: {
        getPageConsultee(redactionDocId: number, documentId: number, pageId: number):
            Promise<IConsultees[]> {
            return reductionApi.get<IConsultees[], null>(
                `/redaction-documents/${redactionDocId}/documents/${documentId}/pages/${pageId}/consultees`);
        },
        deletePageConsultee(redactionDocId: number, documentId: number, pageId: number, consulteeId: number):
            Promise<void> {
            return reductionApi.delete<void, null>(
                `/redaction-documents/${redactionDocId}/documents/${documentId}/pages/${pageId}/consultees/${consulteeId}`);
        },
        getDocumentsConsultees(
            redactionDocumentId: number,
            documentIds: number[],
        ): Promise<IAssignedContactsByDocIdsResponse[]> {
            return reductionApi.post<IAssignedContactsByDocIdsResponse[], number[]>(
                `/redaction-documents/${redactionDocumentId}/documents/consultees`,
                documentIds,
            );
        },
    },
    pageMetadataController: {
        getPageMetadata(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            dataType: string,
        ): Promise<IPageMetadataType[]> {
            return reductionApi.get<IPageMetadataType[], null>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/metadata`,
                null,
                false,
                false,
                dataType ? { dataType } : null,
            );
        },
        addPageMetadata(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            data: IPageMetadataType,
        ): Promise<IPageMetadataType> {
            return reductionApi.post<IPageMetadataType, IPageMetadataType>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/metadata`,
                data,
            );
        },
        updatePageMetadata(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            data: IPageMetadataType,
        ): Promise<IPageMetadataType> {
            return reductionApi.put<IPageMetadataType, IPageMetadataType>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/metadata`,
                data,
            );
        },
    },
    duplicatesController: {
        searchDuplicates(
            redactionDocumentId: number,
            data: IDuplicatesSearchIds,
            pageNumber: number,
        ): Promise<IDuplicatesCurrentGroup> {
            const page = pageNumber == null ? '' : `?page=${pageNumber}`;

            return reductionApi.post<IDuplicatesCurrentGroup, IDuplicatesSearchIds>(
                `/redaction-documents/${redactionDocumentId}/duplicate-search${page}`,
                data,
            );
        },
        search(
            redactionDocumentId: number,
            pageNumber?: number,
        ): Promise<IDuplicatesCurrentGroup> {
            const page = pageNumber == null ? '' : `?page=${pageNumber}`;

            return reductionApi.get<IDuplicatesCurrentGroup, null>(
                `/redaction-documents/${redactionDocumentId}/duplicate-search${page}`);
        },
        removeDuplicate(
            redactionDocumentId: number,
            masterDocumentId: number,
            masterPageId: number,
            duplicateId: number): Promise<void> {
            return reductionApi.delete<void, null>(`/redaction-documents/${redactionDocumentId}/master-documents/
                     ${masterDocumentId}/master-pages/${masterPageId}/duplicates/${duplicateId}`);
        },
    },
    duplicateReferenceController: {
        getDuplicateReferences(redactionDocumentId: number, documentId: number): Promise<IDuplicateReferences> {
            return reductionApi.get<IDuplicateReferences, null>(
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/duplicate-references`,
            );
        },
        getPageDuplicateReferences(
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            actualPageNumber: number): Promise<IDuplicatesRefData> {
            return reductionApi.get<IDuplicatesRefData, null>(
                // tslint:disable-next-line:max-line-length
                `/redaction-documents/${redactionDocumentId}/documents/${documentId}/pages/${pageId}/duplicate-references?actualPageNumber=${actualPageNumber}`,
            );
        },
    },
    masterDocumentController: {
        addMasterDocument(
            redactionDocumentId: number,
            data: IMasterDocumentDto,
        ): Promise<IMasterDocumentDto> {
            return reductionApi.post<IMasterDocumentDto, IMasterDocumentDto>(
                `/redaction-documents/${redactionDocumentId}/master-documents`,
                data,
            );
        },
        getMasterDocuments(redactionDocumentId: number): Promise<IReferenceMaster[]> {
            return reductionApi
                .get<IReferenceMaster[], null>(`/redaction-documents/${redactionDocumentId}/master-documents`);
        },
        removeMasterDocument(redactionDocumentId: number, masterDocumentId: number): Promise<void> {
            return reductionApi
                .delete<void, null>(`/redaction-documents/${redactionDocumentId}/master-documents/${masterDocumentId}`);
        },
    },
    masterPageController: {
        getMasterPages(redactionDocumentId: number, id: number, pageNumber: number = 0):
            Promise<{ masterPageList: IReferencePage[], totalElements: number }> {
            return reductionApi
                .get<{ masterPageList: IReferencePage[], totalElements: number }, null>(`/redaction-documents/
            ${redactionDocumentId}/master-documents/${id}/master-pages?page=${pageNumber}`);
        },
        removeMasterPage(redactionDocumentId: number, masterDocumentId: number, masterPageId: number): Promise<void> {
            return reductionApi.delete<void, null>(`/redaction-documents/${redactionDocumentId}/master-documents/
                ${masterDocumentId}/master-pages/${masterPageId}`);
        },
    },
    releaseDateController: {
        deletePrereleaseDate(redactionDocumentId: number, data: number[]): Promise<void> {
            // tslint:disable-next-line:max-line-length
            return reductionApi.delete<void, number[]>(`/redaction-documents/${redactionDocumentId}/documents/prerelease-date`, data);
        },
        deleteReleaseDate(redactionDocumentId: number, data: number[]): Promise<void> {
            // tslint:disable-next-line:max-line-length
            return reductionApi.delete<void, number[]>(`/redaction-documents/${redactionDocumentId}/documents/release-date`, data);
        },
        updateReleaseDate(
            redactionDocumentId: number,
            data: IUpdateModalReleaseData,
        ): Promise<IUpdateModalReleaseData> {
            return reductionApi.post<IUpdateModalReleaseData, IUpdateModalReleaseData>(`/redaction-documents/
            ${redactionDocumentId}/documents/release-date`, data);
        },
    },
    actController: {
        getActs(): Promise<IAct[]> {
            return reductionApi.get(`/amanda/acts`);
        },
        getAct(actId: number): Promise<IAct> {
            return reductionApi.get(`/amanda/acts/${actId}`);
        },
        getActExemption(actId: number): Promise<IValidExemptionDtoList[]> {
            return reductionApi.get(`/amanda/acts/${actId}/exemptions`);
        },
    },
    advanceSearch: {
        getAdvanceSearchListColumnHeader(lid: string): Promise<IHeader[]> {
            return reductionApi
                .get<IHeader[], null>(`/user-column-preferences/${lid}/advance-search-list`);
        },
        modifyAdvanceSearchColumnHeader(advanceSearchListColumnHeader: IHeader[], userId: string)
            : Promise<IHeader[]> {
                return reductionApi.post<IHeader[], IHeader[]>
                    (`/user-column-preferences/${userId}/advance-search-list`, advanceSearchListColumnHeader);
        },
        resetAdvanceSearchColumnHeader(userId: string): Promise<IHeader[]> {
            return reductionApi
            .get<IHeader[], null>(`/user-column-preferences/${userId}/advance-search-list/resetter`);
        },
    },
    localization: {
        getModifiedScreenLabels(): Promise<ILabel[]> {
            return reductionApi
                .get<ILabel[], null>(`/localization/getResourceBundle`);
        },
        updateScreenLabels(data: ILabel[]): Promise<ILabel[]> {
            return reductionApi.post<ILabel[], ILabel[]>(`/localization/updateResourceBundle`, data);
        },
        searchSystemMessages(data: ISearchData): Promise<ILabel[]> {
            return reductionApi.get<ILabel[], ISearchData>(`/system-message/searchSystemMessage`, data);
        },
        updateSystemMessages(data: ILabel[]): Promise<ILabel[]> {
            return reductionApi.post<ILabel[], ILabel[]>(`/system-message/updateSystemMessages`, data);
        },
    },
};

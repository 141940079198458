import * as React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { IMaterialCheckboxTableHeadProps } from '../materialCheckboxTable.model';

const MaterialCheckboxTableHead = (props: IMaterialCheckboxTableHeadProps): JSX.Element => {
    const numSelected = props.selected.length;
    const rowCount = props.rows.length;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        color='primary'
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount && numSelected > 0 && rowCount > 0}
                        onChange={(e: React.ChangeEvent<Element>): void =>
                            props.handleSelectAll((e.target as HTMLInputElement).checked)
                        }
                    />
                </TableCell>
                {
                    // rows prop can be whatever you pass
                    props.headers.map((row: any): JSX.Element => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={row.disablePadding ? 'none' : 'normal'}
                            >
                                {row.label}
                            </TableCell>
                        ),
                    )
                }
            </TableRow>
        </TableHead>
    );
};

export default MaterialCheckboxTableHead;

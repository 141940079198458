import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../redux/store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { uniq } from 'lodash';
import ModalCustomStampsForm from './ModalCustomStampsForm';
import { IProps, IModalStampsState, IStateProps, IDispatchProps, IModalStampsProps } from './ModalStamps.model';
import { fetchStampList, setSelectedCustomStamps } from '../../../redux/actions/layoutRedactorTypes';
import { setSelectedAnnotationTypes } from '../../../redux/actions/annotationTypes';
import { getActiveStampsList, getSelectedCustomStamps } from '../../../redux/selectors/redactor';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { IAnnotationType } from '../../../redux/reducers/annotationTypes/annotationTypes.model';
import { IStampType } from '../../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';

class ModalCustomStamps extends React.Component<IModalStampsProps, IModalStampsState> {

    public state: IModalStampsState = {
        selectedStamps: [],
    };

    public componentDidMount(): void {
        this.props.fetchStampList();
    }

    public render(): JSX.Element {
        const { customStampsList, handleCloseModalWindow } = this.props;

        return (
                <ModalCustomStampsForm
                    onSubmit={this.onSubmit}
                    customStampsList={customStampsList}
                    selectedStamps={this.state.selectedStamps}
                    handleChange={this.handleChange}
                    handleCloseModalWindow={handleCloseModalWindow}
                />
            );
    }

    private handleChange = (paramId: string, checked: boolean): void => {
        const { customStampsList } = this.props;

        this.setState((prevState: IModalStampsState): IModalStampsState => {
            const selected = customStampsList.find((stamp: IStampType): boolean => (
                stamp.id === Number(paramId)
            ));
            const newState = checked ?
                [...prevState.selectedStamps, selected] :
                prevState.selectedStamps.filter((stamp: IStampType): boolean => stamp.id !== selected.id);

            return {
                ...prevState,
                selectedStamps: uniq(newState),
            };
        });
    }

    private onSubmit = (): void => {
        this.props.setSelectedCustomStamps(this.state.selectedStamps);
        this.props.setSelectedAnnotationTypes(null);
    }
}

const mapStateToProps = (state: IState): IStateProps => ({
    customStampsList: getActiveStampsList(state),
    selectedCustomStamps: getSelectedCustomStamps(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IProps, IDispatchProps, AnyAction>): IDispatchProps => ({
    fetchStampList: (): void => {
        dispatch(fetchStampList());
    },
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    setSelectedCustomStamps: (selectedStamps: IStampType[]): void => {
        dispatch(setSelectedCustomStamps(selectedStamps));
    },
    setSelectedAnnotationTypes: (data: IAnnotationType): void => {
        dispatch(setSelectedAnnotationTypes(data));
    },
});

export default connect( mapStateToProps, mapDispatchToProps)(ModalCustomStamps);

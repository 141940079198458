import { NAME } from '../constants/common.constants';
import { BASE64 } from '../constants/watermark.contstants';

export const sortByName = <T extends object>(arr: T[]): T[] => {
    return arr.sort((a: T, b: T) => a[NAME].localeCompare(b[NAME]));
};

export const getImagePath = (data: any): string => {
    const buffer = new Buffer(data);

    return buffer.byteLength ? `data:image/bmp;${BASE64},${buffer.toString(BASE64)}` : null;
};

import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import {
    GET_ALL_PACKAGE_PRESETS_SUCCESS,
    GET_ALL_PACKAGE_PRESETS_FAILURE,
    DELETE_PACKAGE_PRESET_SUCCESS,
    DELETE_PACKAGE_PRESET_FAILURE,
    SET_PRESET_EXPORT_OPTIONS,
    SET_PRESET_PAGINATION_OPTIONS,
    SET_PACKAGE_ANNOTATION_TYPE,
    REMOVE_PACKAGE_ANNOTATION_TYPE,
    ADD_PACKAGE_PRESET_SUCCESS, ADD_PACKAGE_PRESET_FAILURE,
    SET_SELECTED_PRESET,
    SET_DEFAULT_PRESET,
    SET_SELECTED_PRESET_ID,
    EDIT_PACKAGE_PRESET_SUCCESS,
    EDIT_PACKAGE_PRESET_FAILURE,
} from '../reducers/modalPackagePresets/constants';
import {
    IContentOptionChange,
} from '../../containers/modalWindowContents/modalWindowExport/contentOption/contentOption.model';
import {
    IPaginationOptionChange,
} from '../../containers/modalWindowContents/modalWindowExport/paginationOptions/PaginationOptions';
import {
    IExportPreset, ISelectedPreset,
} from '../../containers/modalWindowContents/modalPackagePresets/modalPackagePresets.model';
import { formatAnnotations } from './modalWindowExport';
import { addError } from './errorHandling';
import {
    IContentOptions,
    IPaginationOptions,
} from '../reducers/modalWindowExport/modalWindowExport.model';
import { IAnnotationType } from '../reducers/annotationTypes/annotationTypes.model';
import api from '../../api/reductionApi';
import { IError } from '../common.model';

export const addPackagePresetSuccess = (preset: IExportPreset):
    AnyAction => action(ADD_PACKAGE_PRESET_SUCCESS, preset);
export const addPackagePresetFailure = (error: IError):
    AnyAction => action(ADD_PACKAGE_PRESET_FAILURE, error);

export const getAllPackagePresetsSuccess = (presets: IExportPreset[]):
    AnyAction => action(GET_ALL_PACKAGE_PRESETS_SUCCESS, presets);
export const getAllPackagePresetsFailure = (error: IError):
    AnyAction => action(GET_ALL_PACKAGE_PRESETS_FAILURE, error);

export const editPackagePresetSuccess = (preset: IExportPreset):
    AnyAction => action(EDIT_PACKAGE_PRESET_SUCCESS, preset);
export const editPackagePresetFailure = (preset: IExportPreset):
    AnyAction => action(EDIT_PACKAGE_PRESET_FAILURE, preset);

export const deletePackagePresetSuccess = (id: number):
    AnyAction => action(DELETE_PACKAGE_PRESET_SUCCESS, id);
export const deletePackagePresetFailure = (error: IError):
    AnyAction => action(DELETE_PACKAGE_PRESET_FAILURE, error);

export const setPackageExportOptions = (data: IContentOptionChange): AnyAction =>
    action(SET_PRESET_EXPORT_OPTIONS, data);
export const setPackagePaginationOptions = (data: IPaginationOptionChange): AnyAction =>
    action(SET_PRESET_PAGINATION_OPTIONS, data);
export const createPackageAnnotationList = (id: number): AnyAction => action(SET_PACKAGE_ANNOTATION_TYPE, id);
export const removeAnnotationOptionFromList = (id: number): AnyAction => action(REMOVE_PACKAGE_ANNOTATION_TYPE, id);

export const setDefaultPreset = (): AnyAction => action(SET_DEFAULT_PRESET);
export const setSelectedPreset = (preset: ISelectedPreset): AnyAction => action(SET_SELECTED_PRESET, preset);
export const setSelectedPresetId = (id: number): AnyAction => action(SET_SELECTED_PRESET_ID, id);

export const postPackagePreset = (
    exportOptions: IContentOptions,
    paginationOptions: IPaginationOptions,
    annotationPresetIdList: number[],
    highLightList: IAnnotationType[],
    severList: IAnnotationType[],
): (dispatch: Dispatch) => void => async (dispatch: Dispatch): Promise<void> => {
    const annotationControlOptions = {
        highlightDisplayOptions:
            formatAnnotations(highLightList, annotationPresetIdList, 'showHighlight'),
        severTransparencyOptions: formatAnnotations(severList, annotationPresetIdList, 'showText'),
    };

    const newPreset = {
        annotationControlOptions,
        exportOptions,
        paginationOptions,
    };

    try {
        const response = await api.packagePresets.addPackagePreset(newPreset);
        const responseAllPrsets = await api.packagePresets.getAllPackagePresets();
        dispatch(setSelectedPresetId(response.id));
        dispatch(addPackagePresetSuccess(response));
        dispatch(getAllPackagePresetsSuccess(responseAllPrsets));
       
    } catch (error) {
        dispatch(addPackagePresetFailure(error));
        dispatch(addError(error));
    }
};

export const fetchAllPresets = (): (dispatch: Dispatch) => void =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            const response = await api.packagePresets.getAllPackagePresets();
            dispatch(getAllPackagePresetsSuccess(response));
        } catch (error) {
            dispatch(getAllPackagePresetsFailure(error));
            dispatch(addError(error));
        }
    };

export const editPackagePreset = (
    selectedPresetId: number,
    exportOptions: IContentOptions,
    paginationOptions: IPaginationOptions,
    annotationPresetIdList: number[],
    highLightList: IAnnotationType[],
    severList: IAnnotationType[],
): (dispatch: Dispatch<any>) => void =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        const annotationControlOptions = {
            highlightDisplayOptions:
                formatAnnotations(highLightList, annotationPresetIdList, 'showHighlight'),
            severTransparencyOptions: formatAnnotations(severList, annotationPresetIdList, 'showText'),
        };

        const newPreset = {
            id: selectedPresetId,
            annotationControlOptions,
            exportOptions,
            paginationOptions,
        };

        try {
            const response = await api.packagePresets.editPackagePreset(newPreset);
            const responseAllPrsets = await api.packagePresets.getAllPackagePresets();
            dispatch(editPackagePresetSuccess(response));
            dispatch(getAllPackagePresetsSuccess(responseAllPrsets));
        } catch (error) {
            dispatch(editPackagePresetFailure(error));
            dispatch(addError(error));
        }
    };

export const deletePackagePreset = (id: number): (dispatch: Dispatch) => void =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            await api.packagePresets.deletePackagePreset(id);

            dispatch(deletePackagePresetSuccess(id));
        } catch (error) {
            dispatch(deletePackagePresetFailure(error));
        }
    };
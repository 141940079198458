import resourceBundle from '../containers/localization/localizationData';
import { changeLang } from '../redux/actions/localization';
import { MODIFIED_LABELS, REDACTION_LANG } from '../redux/reducers/localStorage/constant';
import { getItem, getSessionItem } from '../utils/localStorage.util';
import { dateSorting } from '../utils/documentList.util';
import { SEARCH } from './advancedSearch.constants';
import { CLEAR } from './globalSearch.constants';
import { initialLabel } from './localization.constants';
import { IAuditHeader } from 'redux/reducers/auditLog/auditLog.model';
import { AUDIT_LOG_ERASE_CONFIRM, AUDIT_LOG_ERASE_CONFIRM_MESSAGE, AUDIT_LOG_ERASE_WITHOUT_SAVE, AUDIT_LOG_ERASE_WITHOUT_SAVE_MESSAGE } from './messages.constants';

export const AUDIT_LOG_TITLE = 'Audit Log';
export const SPECIFIC_REQUESTS_LABEL = 'Specific Requests';

export const YEAR_LABEL = 'Year';
export const NUMBER_LABEL = 'Number';
export const TYPE_LABEL = 'Type';
export const DOCUMENT_NAME_LABEL = 'Document Name';
export const USER_LABEL = 'User';
export const START_DATE_LABEL = 'Start Date *';
export const END_DATE_LABEL = 'End Date *';
export const MAX_DATE_MESSAGE = 'The Start Date should be less than the End Date';
export const MIN_DATE_MESSAGE = 'The End Date cannot be less than the Start Date';
export const INVALID_DATE = 'Invalid Date Format';
export const START_DATE = '2000-01-01';
export const ALL_REQUESTS = 'all_requests';
export const SPECIFIC_REQUESTS = 'specific_requests';
export const EXPORT_ALL_LOG = 'export_all';
export const EXPORT_ALL_LOG_LABEL = 'Export all log';
export const EXPORT_CURRENT_LOG = 'export_current';
export const EXPORT_CURRENT_LOG_LABEL = 'Export current search result';

const getAuditlogByKey = (key: string): string => {
    switch (key) {
        case 'AUDIT_LOG_SCREEN_TITLE':
            return 'auditLogLabel';
        case 'AUDIT_LOG_SCREEN_SPECIFIC_REQUESTS':
            return 'specificRequestsLabel';
        case 'AUDIT_LOG_SCREEN_TYPE_FIELD':
            return 'auditLogTypeLabel';
        case 'AUDIT_LOG_SCREEN_YEAR_FIELD':
            return 'auditLogYearLabel';
        case 'AUDIT_LOG_SCREEN_NUMBER_FIELD':
            return 'auditLogNumberLabel';
        case 'AUDIT_LOG_SCREEN_START_DATE_FIELD':
            return 'auditLogStartDateLabel';
        case 'AUDIT_LOG_SCREEN_END_DATE_FIELD':
            return 'auditLogEndDateLabel';
        case 'AUDIT_LOG_SCREEN_DOCUMENT_NAME_FIELD':
            return 'auditLogDocumentNameLabel';
        case 'AUDIT_LOG_SCREEN_USER_FIELD':
            return 'auditLogUserLabel';
        case 'AUDIT_LOG_SCREEN_SEARCH_BUTTON':
            return 'auditLogSearchLabel';
        case 'AUDIT_LOG_SCREEN_CLEAR_BUTTON':
            return 'auditLogClearLabel';
        case 'AUDIT_LOG_SCREEN_ERASE_LOG':
            return 'auditLogEraseLogLabel';
        case 'AUDIT_LOG_SCREEN_BACK_HOME':
            return 'auditLogBackHomeLabel';
        case 'AUDIT_LOG_SCREACH_RESULT_TITLE':
            return 'auditLogSearchResultTitle';
        case 'AUDIT_LOG_SCREACH_RESULT_FOLDER':
            return 'auditLogSearchResultFolder';
        case 'AUDIT_LOG_SCREACH_RESULT_ACTION':
            return 'auditLogSearchResultAction';
        case 'AUDIT_LOG_SCREACH_RESULT_ACTION_TYPE':
            return 'auditLogSearchResultActionType';
        case 'AUDIT_LOG_SCREACH_RESULT_DATE':
            return 'auditLogSearchResultDate';
        case 'AUDIT_LOG_SCREACH_RESULT_DOCUMENT_NAME':
            return 'auditLogSearchResultDocumentName';
        case 'AUDIT_LOG_SCREACH_RESULT_PAGE':
            return 'auditLogSearchResultPage';
        case 'AUDIT_LOG_SCREACH_RESULT_USER':
            return 'auditLogSearchResultUser';
        case 'AUDIT_LOG_SCREACH_RESULT_EXPORT_ALL_LOG':
            return 'exportAllLogLabel';
        case 'AUDIT_LOG_SCREACH_RESULT_EXPORT_CURRENT_RESULT':
            return 'exportCurrentLogLabel';
        case 'AUDIT_LOG_SCREEN_SYSTEM_LABEL':
            return 'systemLabel';
        case 'AUDIT_LOG_SCREEN_SYSTEM_SESSION_STARTED':
            return 'systemSessionStartedLabel';
        case 'AUDIT_LOG_SCREEN_SYSTEM_SESSION_ENDED':
            return 'systemSessionEndedLabel';
        case 'AUDIT_LOG_SCREEN_SYSTEM_SESSION_TIMEOUT':
            return 'systemSessionTimeoutLabel';
        case 'AUDIT_LOG_SCREEN_SYSTEM_REQUEST_LOADED':
            return 'systemRequestLoadedLabel';
        case 'AUDIT_LOG_SCREEN_SYSTEM_REQUEST_ACCESS_DENIED':
            return 'systemRequestAccessDeniedLabel';
        case 'AUDIT_LOG_SCREEN_STACK_LABEL':
            return 'stackLabel';
        case 'AUDIT_LOG_SCREEN_CREATE_STACK_LABEL':
            return 'createStackLabel';
        case 'AUDIT_LOG_SCREEN_ADD_TO_STACK_LABEL':
            return 'addToStackLabel';
        case 'AUDIT_LOG_SCREEN_REMOVE_FROM_STACK_LABEL':
            return 'removeFromStackLabel';
        case 'AUDIT_LOG_SCREEN_DELETE_STACK_LABEL':
            return 'deleteStackLabel';
        case 'AUDIT_LOG_SCREEN_INDEX_LABEL':
            return 'indexLabel';
        case 'AUDIT_LOG_SCREEN_DOCUMENT_INDEX_SAVED_LABEL':
            return 'documentIndexSavedLabel';
        case 'AUDIT_LOG_SCREEN_PAGE_INDEX_SAVED_LABEL':
            return 'pageIndexSavedLabel';
        case 'AUDIT_LOG_SCREEN_ANNOTATION_LABEL':
            return 'indexAnnotationLabel';
        case 'AUDIT_LOG_SCREEN_ADD_ANNOTATION_LABEL':
            return 'addAnnotationLabel';
        case 'AUDIT_LOG_SCREEN_DELETE_ANNOTATION_LABEL':
            return 'deleteAnnotationLabel';
        case 'AUDIT_LOG_SCREEN_RESIZE_MOVE_ANNOTATION_LABEL':
            return 'resizeMoveAnnotationLabel';
        case 'AUDIT_LOG_SCREEN_DISCLOSURE_LABEL':
            return 'disclosureLabel';
        case 'AUDIT_LOG_SCREEN_CHANGE_DISCLOSURE_LABEL':
            return 'changeDisclosureLabel';
        case 'AUDIT_LOG_SCREEN_UNDO_DISCLOSURE_LABEL':
            return 'undoDisclosureLabel';
        case 'AUDIT_LOG_SCREEN_DOCUMENT_LABEL':
            return 'documentLabel';
        case 'AUDIT_LOG_SCREEN_DELETE_DOCUMENT_LABEL':
            return 'deleteDocumentLabel';
        case 'AUDIT_LOG_SCREEN_DOCUMENT_SET_PAGINATION_LABEL':
            return 'documentSetPaginationLabel';
        case 'AUDIT_LOG_SCREEN_DOCUMENT_UNDO_PAGINATION_LABEL':
            return 'documentUndoPaginationLabel';
        case 'AUDIT_LOG_SCREEN_DOCUMENT_EDIT_PAGINATION_LABEL':
            return 'documentEditPaginationLabel';
        case 'AUDIT_LOG_SCREEN_AUTO_DESKEW_DOCUMENT_LABEL':
            return 'autoDeskewDocumentLabel';
        case 'AUDIT_LOG_SCREEN_STAMP_LABEL':
            return 'stampLabel';
        case 'AUDIT_LOG_SCREEN_ADD_STAMP_LABEL':
            return 'addStampLabel';
        case 'AUDIT_LOG_SCREEN_MOVE_STAMP_LABEL':
            return 'moveStampLabel';
        case 'AUDIT_LOG_SCREEN_DELETE_STAMP_LABEL':
            return 'deletStampLabel';
        case 'AUDIT_LOG_SCREEN_PAGE_LABEL':
            return 'pageLabel';
        case 'AUDIT_LOG_SCREEN_DELETE_PAGE_LABEL':
            return 'deletePageLabel';
        case 'AUDIT_LOG_SCREEN_MOVE_PAGE_LABEL':
            return 'movePageLabel';
        case 'AUDIT_LOG_SCREEN_ROTATE_PAGE_LABEL':
            return 'rotatePageLabel';
        case 'AUDIT_LOG_SCREEN_FLIP_PAGE_LABEL':
            return 'flipPageLabel';
        case 'AUDIT_LOG_SCREEN_AUTO_DESKEW_PAGE_LABEL':
            return 'autoDeskewPageLabel';
        case 'AUDIT_LOG_SCREEN_IMPORT_LABEL':
            return 'importLabel';
        case 'AUDIT_LOG_SCREEN_IMPORT_PAGE_FROM_SCANNER_LABEL':
            return 'importPageScannerLabel';
        case 'AUDIT_LOG_SCREEN_IMPORT_PAGE_FROM_REQUEST_LABEL':
            return 'importPageRequestLabel';
        case 'AUDIT_LOG_SCREEN_IMPORT_PAGE_FROM_FILE_LABEL':
            return 'importPageFileLabel';
        case 'AUDIT_LOG_SCREEN_IMPORT_DOCUMENT_FROM_SCANNER_LABEL':
            return 'importDocumentScannerLabel';
        case 'AUDIT_LOG_SCREEN_IMPORT_DOCUMENT_FROM_REQUEST_LABEL':
            return 'importDocumentRequestLabel';
        case 'AUDIT_LOG_SCREEN_IMPORT_DOCUMENT_FROM_FILE_LABEL':
            return 'importDocumentFileLabel';
        case 'AUDIT_LOG_SCREEN_EXPORT_LABEL':
            return 'exportLabel';
        case 'AUDIT_LOG_SCREEN_EXPORT_CREATE_PACKAGE_LABEL':
            return 'exportCreatePackageLabel';
        case 'AUDIT_LOG_SCREEN_EXPORT_ORIGINAL_ONLY_LABEL':
            return 'exportOriginalOnlyLabel';
        case 'AUDIT_LOG_SCREEN_ERASE_CONFIRM':
            return 'eraseConfirm';
        case 'AUDIT_LOG_SCREEN_ERASE_CONFIRM_MESSAGE':
            return 'eraseConfirmMessage';
        case 'AUDIT_LOG_SCREEN_ERASE_WITHOUT_SAVE':
            return 'eraseWithoutSave';
        case 'AUDIT_LOG_SCREEN_ERASE_WITHOUT_SAVE_MESSAGE':
            return 'eraseWithoutSaveMessage';
        default: return '';
    }
}

export const getAuditLogLabelsByKey = (key: string): string => {
    const redactionLang = getSessionItem(REDACTION_LANG);
    const modifiedLabels = getItem(MODIFIED_LABELS);
    const label = {
        auditLogLabel: initialLabel,
        specificRequestsLabel: initialLabel,
        auditLogTypeLabel: initialLabel,
        auditLogYearLabel: initialLabel,
        auditLogNumberLabel: initialLabel,
        auditLogStartDateLabel: initialLabel,
        auditLogEndDateLabel: initialLabel,
        auditLogDocumentNameLabel: initialLabel,
        auditLogUserLabel: initialLabel,
        auditLogSearchLabel: initialLabel,
        auditLogClearLabel: initialLabel,
        auditLogEraseLogLabel: initialLabel,
        auditLogBackHomeLabel: initialLabel,
        auditLogSearchResultTitle: initialLabel,
        auditLogSearchResultFolder: initialLabel,
        auditLogSearchResultAction: initialLabel,
        auditLogSearchResultActionType: initialLabel,
        auditLogSearchResultDate: initialLabel,
        auditLogSearchResultDocumentName: initialLabel,
        auditLogSearchResultPage: initialLabel,
        auditLogSearchResultUser: initialLabel,
        exportAllLogLabel: initialLabel,
        exportCurrentLogLabel: initialLabel,
        systemLabel: initialLabel,
        systemSessionStartedLabel: initialLabel,
        systemSessionEndedLabel: initialLabel,
        systemSessionTimeoutLabel: initialLabel,
        systemRequestLoadedLabel: initialLabel,
        systemRequestAccessDeniedLabel: initialLabel,
        stackLabel: initialLabel,
        createStackLabel: initialLabel,
        addToStackLabel: initialLabel,
        removeFromStackLabel: initialLabel,
        deleteStackLabel: initialLabel,
        indexLabel: initialLabel,
        documentIndexSavedLabel: initialLabel,
        pageIndexSavedLabel: initialLabel,
        indexAnnotationLabel: initialLabel,
        addAnnotationLabel: initialLabel,
        deleteAnnotationLabel: initialLabel,
        resizeMoveAnnotationLabel: initialLabel,
        disclosureLabel: initialLabel,
        changeDisclosureLabel: initialLabel,
        undoDisclosureLabel: initialLabel,
        documentLabel: initialLabel,
        deleteDocumentLabel: initialLabel,
        documentSetPaginationLabel: initialLabel,
        documentUndoPaginationLabel: initialLabel,
        documentEditPaginationLabel: initialLabel,
        autoDeskewDocumentLabel: initialLabel,
        stampLabel: initialLabel,
        addStampLabel: initialLabel,
        moveStampLabel: initialLabel,
        deletStampLabel: initialLabel,
        pageLabel: initialLabel,
        deletePageLabel: initialLabel,
        movePageLabel: initialLabel,
        rotatePageLabel: initialLabel,
        flipPageLabel: initialLabel,
        autoDeskewPageLabel: initialLabel,
        importLabel: initialLabel,
        importPageScannerLabel: initialLabel,
        importPageRequestLabel: initialLabel,
        importPageFileLabel: initialLabel,
        importDocumentScannerLabel: initialLabel,
        importDocumentRequestLabel: initialLabel,
        importDocumentFileLabel: initialLabel,
        exportLabel: initialLabel,
        exportCreatePackageLabel: initialLabel,
        exportOriginalOnlyLabel: initialLabel,
        eraseConfirm: initialLabel,
        eraseConfirmMessage: initialLabel,
        eraseWithoutSave: initialLabel,
        eraseWithoutSaveMessage: initialLabel,
    };
    const langRule = changeLang(redactionLang);

    resourceBundle.map((resource: any) => {
        if (getAuditlogByKey(resource.resourceKey)) {
            label[getAuditlogByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    (modifiedLabels && modifiedLabels.length) ? modifiedLabels.map((resource: any) => {
        if (getAuditlogByKey(resource.resourceKey)) {
            label[getAuditlogByKey(resource.resourceKey)] = resource;
        }

        return resource;
    }) : '';

    switch (key) {
        case AUDIT_LOG_TITLE:
            return label.auditLogLabel[langRule];
        case SPECIFIC_REQUESTS_LABEL:
            return label.specificRequestsLabel[langRule];
        case TYPE_LABEL:
            return label.auditLogTypeLabel[langRule];
        case YEAR_LABEL:
            return label.auditLogYearLabel[langRule];
        case NUMBER_LABEL:
            return label.auditLogNumberLabel[langRule];
        case START_DATE_LABEL:
            return label.auditLogStartDateLabel[langRule];
        case END_DATE_LABEL:
            return label.auditLogEndDateLabel[langRule];
        case DOCUMENT_NAME_LABEL:
            return label.auditLogDocumentNameLabel[langRule];
        case USER_LABEL:
            return label.auditLogUserLabel[langRule];
        case SEARCH:
            return label.auditLogSearchLabel[langRule];
        case CLEAR:
            return label.auditLogClearLabel[langRule];
        case 'Erase Log':
            return label.auditLogEraseLogLabel[langRule];
        case 'Back Home':
            return label.auditLogBackHomeLabel[langRule];
        case 'Search Result':
            return label.auditLogSearchResultTitle[langRule];
        case 'folderId':
            return label.auditLogSearchResultFolder[langRule];
        case 'actionDetails':
            return label.auditLogSearchResultAction[langRule];
        case 'auditAction':
            return label.auditLogSearchResultActionType[langRule];
        case 'stampDate':
            return label.auditLogSearchResultDate[langRule];
        case 'documentName':
            return label.auditLogSearchResultDocumentName[langRule];
        case 'pageId':
            return label.auditLogSearchResultPage[langRule];
        case 'stampUser':
            return label.auditLogSearchResultUser[langRule];
        case EXPORT_ALL_LOG:
            return label.exportAllLogLabel[langRule];
        case EXPORT_CURRENT_LOG:
            return label.exportCurrentLogLabel[langRule];
        case 'System':
            return label.systemLabel[langRule];
        case 'Session Started':
            return label.systemSessionStartedLabel[langRule];
        case 'Session Ended':
            return label.systemSessionEndedLabel[langRule];
        case 'Session Timeout':
            return label.systemSessionTimeoutLabel[langRule];
        case 'Request Loaded':
            return label.systemRequestLoadedLabel[langRule];
        case 'Request Access Denied':
            return label.systemRequestAccessDeniedLabel[langRule];
        case 'Stack':
            return label.stackLabel[langRule];
        case 'Create Stack':
            return label.createStackLabel[langRule];
        case 'Add to Stack':
            return label.addToStackLabel[langRule];
        case 'Removed from Stack':
            return label.removeFromStackLabel[langRule];
        case 'Delete Stack':
            return label.deleteStackLabel[langRule];
        case 'Index':
            return label.indexLabel[langRule];
        case 'Document Index Saved':
            return label.documentIndexSavedLabel[langRule];
        case 'Page Index Saved':
            return label.pageIndexSavedLabel[langRule];
        case 'Annotation':
            return label.indexAnnotationLabel[langRule];
        case 'Add Annotation':
            return label.addAnnotationLabel[langRule];
        case 'Delete Annotation':
            return label.deleteAnnotationLabel[langRule];
        case 'Resize/Move Annotation':
            return label.resizeMoveAnnotationLabel[langRule];
        case 'Disclosure':
            return label.disclosureLabel[langRule];
        case 'Change Disclosure':
            return label.changeDisclosureLabel[langRule];
        case 'Undo Disclosure':
            return label.undoDisclosureLabel[langRule];
        case 'Document':
            return label.documentLabel[langRule];
        case 'Delete (Document)':
            return label.deleteDocumentLabel[langRule];
        case 'Set Pagination':
            return label.documentSetPaginationLabel[langRule];
        case 'Undo Pagination':
            return label.documentUndoPaginationLabel[langRule];
        case 'Edit Pagination':
            return label.documentEditPaginationLabel[langRule];
        case 'Auto Deskew (Document)':
            return label.autoDeskewDocumentLabel[langRule];
        case 'Stamp':
            return label.stampLabel[langRule];
        case 'Add Stamp':
            return label.addStampLabel[langRule];
        case 'Move Stamp':
            return label.moveStampLabel[langRule];
        case 'Delete Stamp':
            return label.deletStampLabel[langRule];
        case 'Page':
            return label.pageLabel[langRule];
        case 'Delete (Page)':
            return label.deletePageLabel[langRule];
        case 'Move (Page)':
            return label.movePageLabel[langRule];
        case 'Rotate (Page)':
            return label.rotatePageLabel[langRule];
        case 'Flip (Page)':
            return label.flipPageLabel[langRule];
        case 'Auto Deskew (Page)':
            return label.autoDeskewPageLabel[langRule];
        case 'Import':
            return label.importLabel[langRule];
        case 'Page from Scanner':
            return label.importPageScannerLabel[langRule];
        case 'Page from Request':
            return label.importPageRequestLabel[langRule];
        case 'Page from File':
            return label.importPageFileLabel[langRule];
        case 'Document from Scanner':
            return label.importDocumentScannerLabel[langRule];
        case 'Document from Request':
            return label.importDocumentRequestLabel[langRule];
        case 'Document from File':
            return label.importDocumentFileLabel[langRule];
        case 'Export':
            return label.exportLabel[langRule];
        case 'Create Package':
            return label.exportCreatePackageLabel[langRule];
        case 'Export Original Only':
            return label.exportOriginalOnlyLabel[langRule];
        case AUDIT_LOG_ERASE_CONFIRM:
            return label.eraseConfirm[langRule];
        case AUDIT_LOG_ERASE_CONFIRM_MESSAGE:
            return label.eraseConfirmMessage[langRule];
        case AUDIT_LOG_ERASE_WITHOUT_SAVE:
            return label.eraseWithoutSave[langRule];
        case AUDIT_LOG_ERASE_WITHOUT_SAVE_MESSAGE:
            return label.eraseWithoutSaveMessage[langRule];
        default:
            return '';
    }
}

export const tableColumns = [
    {
        accessorKey: 'folderId',
        id: 'folderId',
        header: 'Folder',
        minResizeWidth: 1,
        show: true,
        sortOrder: null,
    },
    {
        accessorKey: 'actionDetails',
        id: 'actionDetails',
        header: 'Action',
        minResizeWidth: 1,
        show: true,
        sortOrder: null,
    },
    {
        accessorKey: 'auditAction',
        id: 'auditAction',
        header: 'Action Type',
        minResizeWidth: 1,
        show: true,
        sortOrder: null,
    },
    {
        accessorKey: 'stampDate',
        id: 'stampDate',
        header: 'Date',
        minResizeWidth: 1,
        show: true,
        sortMethod: dateSorting,
        sortOrder: null,
    },
    {
        accessorKey: 'documentName',
        id: 'documentName',
        header: 'Document Name',
        minResizeWidth: 1,
        show: true,
        sortOrder: null,
    },
    {
        accessorKey: 'pageId',
        id: 'pageId',
        header: 'Page',
        minResizeWidth: 1,
        show: true,
        sortOrder: null,
    },
    {
        accessorKey: 'stampUser',
        id: 'stampUser',
        header: 'User',
        minResizeWidth: 1,
        show: true,
        sortOrder: null,
    },
];

export const auditExportOptions = [
    {
        label: EXPORT_ALL_LOG_LABEL,
        value: EXPORT_ALL_LOG,
        disabled: false,
    },
    {
        label: EXPORT_CURRENT_LOG_LABEL,
        value: EXPORT_CURRENT_LOG,
        disabled: false,
    },
];

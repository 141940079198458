import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import LinearProgress from '@mui/material/LinearProgress';
import DynamicForm from './DynamicFormWrapper';
import {
    getAnnotationTypesList,
    getAnnotationTypesUpdatingLoading,
    getSelectedAnnotationType,
    getLocalAnnotationTypesList,
} from '../../../redux/selectors/annotationTypes';
import {
    postAnnotationType,
    putAnnotationType,
    confirmDeleteAnnotationType,
    fetchAnnotationTypeList,
    setSelectedAnnotation,
    changeLocallyAnnotation,
} from '../../../redux/actions/annotationTypes';
import { DEFAULT_ITEM_COLOR } from '../../../constants/annotationTypes.constants';
import { IAnnotationType } from '../../../redux/reducers/annotationTypes/annotationTypes.model';
import {
    ICommonAnnotationTypeModalProps,
    IAnnotationTypeModalProps,
    IAnnotationTypeModalStateProps,
    IAnnotationTypeModalDispatchProps,
} from './modalLayerTypes.model';
import { IState as StoreState } from '../../../redux/store';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { initialLabel } from '../../../constants/localization.constants';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';
import { ILabel } from 'redux/reducers/localization/localization.model';
import { isEqual } from 'lodash';

const getAnnotationTypeLabelsByKey = (key: string): string => {
    switch (key) {
        case 'ANNOTATION_TYPE_MANAGEMENT_MODAL_NAME':
            return 'nameLabel';
        case 'ANNOTATION_TYPE_MANAGEMENT_MODAL_DESCRIPTION':
            return 'descriptionLabel';
        case 'ANNOTATION_TYPE_MANAGEMENT_MODAL_ACTIVE':
            return 'activeLabel';
        case 'ANNOTATION_TYPE_MANAGEMENT_MODAL_IS_SEVER':
            return 'isSeverLabel';
        case 'ANNOTATION_TYPE_MANAGEMENT_MODAL_SAVE_SEVERED_TEXT':
            return 'saveSeveredTextLabel';
        case 'ANNOTATION_TYPE_MANAGEMENT_MODAL_BORDER_ONLY':
            return 'borderOnlyLabel';
        case 'ANNOTATION_TYPE_MANAGEMENT_MODAL_COLOR':
            return 'colorLabel'
        default: return '';
    }
};
const labels = {
    nameLabel: initialLabel,
    descriptionLabel: initialLabel,
    activeLabel: initialLabel,
    isSeverLabel: initialLabel,
    saveSeveredTextLabel: initialLabel,
    borderOnlyLabel: initialLabel,
    colorLabel: initialLabel,
};

class ModalAnnotationTypes extends React.Component<ICommonAnnotationTypeModalProps> {

    public componentDidMount(): void {
        this.props.fetchAnnotationType();
    }

    public componentWillUnmount(): void {
        this.props.handleDetectChanges(false);
    }

    public render(): JSX.Element {
        const { annotationsList, localAnnotationsList, onCloseModal, permissions, modifiedLabels, redactionLang } = this.props;

        const langRule = changeLang(redactionLang);
        const formFields = [
            {
                field: 'name',
                label: labels.nameLabel[langRule],
                className: 'grow-2',
                type: 'textfield',
                isRequired: true,
                maxLength: 60,
                isUniqueText: true,
            }, {
                field: 'name2',
                label: 'Name Fr',
                className: 'grow-2',
                type: 'textfield',
                maxLength: 60,
            }, {
                field: 'description',
                label: labels.descriptionLabel[langRule],
                className: 'grow-3',
                type: 'textfield',
                maxLength: 200,
            }, {
                field: 'description2',
                label: 'Description Fr',
                className: 'grow-3',
                type: 'textfield',
                maxLength: 200,
            }, {
                field: 'isActive',
                label: labels.activeLabel[langRule],
                type: 'checkbox',
            }, {
                field: 'isSever',
                label: labels.isSeverLabel[langRule],
                type: 'checkbox',
            }, {
                field: 'saveSeveredText',
                label: labels.saveSeveredTextLabel[langRule],
                className: 'checkbox-field',
                type: 'checkbox',
            }, {
                field: 'isBorderOnly',
                label: labels.borderOnlyLabel[langRule],
                className: 'checkbox-field',
                type: 'checkbox',
            }, {
                field: 'color',
                label: labels.colorLabel[langRule],
                type: 'colorPicker',
                className: 'custom-field',
                isUniqueText: true,
            }, {
                field: '',
                label: '',
                type: 'icon',
                className: 'custom-field',
            },
        ];

        resourceBundle.map((resource: any) => {
            if (getAnnotationTypeLabelsByKey(resource.resourceKey)) {
                labels[getAnnotationTypeLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getAnnotationTypeLabelsByKey(resource.resourceKey)) {
                labels[getAnnotationTypeLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        const defaultData = {
            color: DEFAULT_ITEM_COLOR,
            isSever: false,
            isActive: false,
            saveSeveredText: false,
            isBorderOnly: false,
        };

        const uniqueField = {
            color: localAnnotationsList.map((annotation: IAnnotationType): string => annotation.color),
            name: localAnnotationsList.map((annotation: IAnnotationType): string => annotation.name),
        };

        return (
            <div className='annotation-types-modal'>
                <DynamicForm
                    layerItems={localAnnotationsList}
                    formFields={formFields}
                    onSubmit={this.onSubmit}
                    onDelete={this.props.deleteAnnotation}
                    handleDetectChanges={this.handleDetectChanges}
                    defaultData={defaultData}
                    uniqueField={uniqueField}
                    onCloseModal={onCloseModal}
                    isPristine={isEqual(annotationsList, localAnnotationsList)}
                    permissions={permissions}
                    redactionLang={redactionLang}
                    modifiedLabels={modifiedLabels}
                />
                <div className='linear-progress'> {this.props.updateLoading && <LinearProgress/>} </div>
            </div>
        );
    }
    private onSubmit = ({ layerItems }: { layerItems: IAnnotationType[] }): void => {
        this.createNewAnnotations(layerItems);
        this.editAnnotations(layerItems);
        this.resetDeactivatedAnnotation(layerItems);
    }

    private resetDeactivatedAnnotation = (annotations: IAnnotationType[]): void => {
        const { selectedAnnotationType } = this.props;

        for (const item of annotations) {
            if (selectedAnnotationType && selectedAnnotationType.id === item.id && !item.isActive) {
                this.props.setSelectedAnnotation(null);
            }
        }
    }

    private createNewAnnotations = (annotations: IAnnotationType[]): void => {
        const newCreatedAnnotations = annotations.filter((annotation: IAnnotationType): boolean =>
            !annotation.id);

        if (newCreatedAnnotations.length) {
            this.props.createAnnotation(newCreatedAnnotations);
        }
    }

    private editAnnotations = (annotations: IAnnotationType[]): void => {
        const editedAnnotations = annotations.filter((annotation: IAnnotationType): boolean => {
            if (!annotation.id) {
                return;
            }

            const savedAnnotations = this.props.annotationsList.filter(
                (savedAnnotation: IAnnotationType): boolean =>
                    savedAnnotation.id === annotation.id)[0];

            return !isEqual(savedAnnotations, annotation);
        });

        if (editedAnnotations.length) {
            const editedAnnotationsWithoutDisabledFields = editedAnnotations.map(
                (annotation: IAnnotationType): IAnnotationType =>
                    annotation,
                );

            this.props.editAnnotation(editedAnnotationsWithoutDisabledFields);
            this.props.handleDetectChanges(false);
        }

    }

    private handleDetectChanges = (values: IAnnotationType[], isDirty: boolean): void => {
        const wasChanged = isDirty || !isEqual(this.props.annotationsList, this.props.localAnnotationsList);

        values.map((value: IAnnotationType) => {
            if(!value.isSever) {
                value.saveSeveredText = value.isSever;
            }
        });
        this.props.changeLocallyAnnotation(values);
        this.props.handleDetectChanges(wasChanged);
    }
}

const mapStateToProps = (state: StoreState): IAnnotationTypeModalStateProps => ({
    annotationsList: getAnnotationTypesList(state),
    localAnnotationsList: getLocalAnnotationTypesList(state),
    updateLoading: getAnnotationTypesUpdatingLoading(state),
    selectedAnnotationType: getSelectedAnnotationType(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IAnnotationTypeModalDispatchProps, AnyAction>,
): IAnnotationTypeModalDispatchProps => ({
    createAnnotation: (data: IAnnotationType[]): void => {
        dispatch(postAnnotationType(data));
    },
    editAnnotation: (data: IAnnotationType[]): void => {
        dispatch(putAnnotationType(data));
    },
    deleteAnnotation: (id: number): void => {
        dispatch(confirmDeleteAnnotationType(id));
    },
    fetchAnnotationType: (): void => {
        dispatch(fetchAnnotationTypeList());
    },
    setSelectedAnnotation: (data: IAnnotationType): void => {
        dispatch(setSelectedAnnotation(data));
    },
    changeLocallyAnnotation: (data: IAnnotationType[]): void => {
        dispatch(changeLocallyAnnotation(data));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalAnnotationTypes);

import {
    FETCH_INDEX_METADATA_DESCRIPTIONS_SUCCESS,
    FETCH_INDEX_METADATA_DESCRIPTIONS_PENDING,
    FETCH_INDEX_METADATA_DESCRIPTIONS_FAIL,
    CLEAR_INDEX_METADATA_DESCRIPTIONS,
    ADD_INDEX_METADATA_DESCRIPTIONS,
    REMOVE_INDEX_METADATA_DESCRIPTIONS,
    EDIT_INDEX_METADATA_DESCRIPTIONS,
    EXPORT_INDEX_METADATA_SUCCESS,
    EXPORT_INDEX_METADATA_FAIL,
    EXPORT_INDEX_METADATA_PENDING,
} from '../reducers/indexMetadataDescriptions/constant';
import { EXPORT_PDF_KEYWORD_OCCURENCY, EXPORT_PDF_TYPE } from '../../constants';
import {
    EXPORTED_SUCCESSFULLY,
} from '../../constants/export.contstants';
import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import { addError, addSuccessNotification } from './errorHandling';
import {
    IDescription, IDescriptionItem,
    ILookup,
} from '../reducers/indexMetadataDescriptions/indexMetadataDescriptions.model';
import { IError } from '../common.model';
import { IState } from '../store';
import {
    IKeywordManagementDispatchToProps,
    IReplaceData,
} from '../../containers/modalWindowContents/modalIndexKeywordManagement/modalIndexKeywordManagement.model';
import { ThunkDispatch } from 'redux-thunk/es/types';
import {
    FieldTypes,
} from '../../containers/modalWindowContents/modalIndexKeywordManagement/ModalIndexKeywordManagement';
import { EXPORT } from '../../constants/common.constants';
import { saveAs, BlobPart } from 'file-saver';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from './modal';
import { REQUEST_SEARCH_MODAL } from '../../constants/modalRequestSearch.constants';
import { ALERT_DIALOG_MODAL, WARNING } from '../../constants/messages.constants';
import { saveDocumentIndex } from './documentIndex';
import { savePageIndex } from './pageIndex';
import resourceBundle from '../../containers/localization/localizationData';
import { ILabel } from '../reducers/localization/localization.model';
import { initialLabel } from '../../constants/localization.constants';
import { changeLang } from './localization';

export interface IIndexMetadataDescriptionsDto {
    metadataId: number;
    indexMetadataLookups: any;
}

export const fetchIndexMetadataDescriptionsPending = (): AnyAction => action(FETCH_INDEX_METADATA_DESCRIPTIONS_PENDING);
export const fetchIndexMetadataDescriptionsSuccess = (indexMetadata: IDescription[]): AnyAction =>
    action(FETCH_INDEX_METADATA_DESCRIPTIONS_SUCCESS, indexMetadata);
export const fetchIndexMetadataDescriptionsFailure = (error: IError): AnyAction =>
    action(FETCH_INDEX_METADATA_DESCRIPTIONS_FAIL, error);
export const clearIndexMetadataDescriptions = (): AnyAction => action(CLEAR_INDEX_METADATA_DESCRIPTIONS);
export const addIndexMetadataDescriptions = (id: number): AnyAction => action(ADD_INDEX_METADATA_DESCRIPTIONS, id);
export const removeIndexMetadataDescriptionsSuccess = (descriptionId: number, dataIndex: number): AnyAction =>
    action(REMOVE_INDEX_METADATA_DESCRIPTIONS, {descriptionId, dataIndex});
export const editIndexMetadataDescriptions = (value: string, descriptionId: number, dataIndex: number, param: string)
    : AnyAction => action(EDIT_INDEX_METADATA_DESCRIPTIONS, {value, descriptionId , dataIndex, param});
export const exportStringMetadataDescriptionsSuccess = (data: BlobPart): AnyAction =>
    action(EXPORT_INDEX_METADATA_SUCCESS, data);
export const exportStringMetadataDescriptionsFailure = (error: IError): AnyAction =>
    action(EXPORT_INDEX_METADATA_FAIL, error);
export const exportStringMetadataDescriptionsPending = (): AnyAction =>
    action(EXPORT_INDEX_METADATA_PENDING);

export const removeIndexMetadataDescriptions = (descriptionId: number, dataIndex: number, id: number)
    : (dispatch: Dispatch) => Promise<void> => async (dispatch: Dispatch): Promise<void> => {

    try {
        if(!id) {
            dispatch(removeIndexMetadataDescriptionsSuccess(descriptionId, dataIndex));

            return;
        }

        const isDeletable = await api.indexMetadata.deletable(id);

        if (isDeletable) {
            dispatch(removeIndexMetadataDescriptionsSuccess(descriptionId, dataIndex));
        } else {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: WARNING,
                message: 'Cannot delete keyword because it is referenced to another document type.',
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    } catch (error) {
        dispatch(addError(error));
    }
};

export const fetchIndexMetadataDescriptions = (): (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: { redactionDocumentId },
            localStorage: { language },
            localization: {modifiedLabels},
        } = getState();

        if (!redactionDocumentId) {
            let requestSearchLabel: ILabel = initialLabel;

            resourceBundle.map((resource: any) => {
                if(resource.resourceKey === 'SEARCH_REQUEST_MODAL_TITLE') {
                    requestSearchLabel = resource;
                }

                return resource;
            });

            modifiedLabels.map((resource: any) => {
                if(resource.resourceKey === 'SEARCH_REQUEST_MODAL_TITLE') {
                    requestSearchLabel = resource;
                }

                return resource;
            });

            dispatch(handleCloseAllModalWindows());
            dispatch(openModalWindow(REQUEST_SEARCH_MODAL, {id: REQUEST_SEARCH_MODAL,
                title: requestSearchLabel[language ? language === 'fr'
                    ? 'resourceValue2' : 'resourceValue': 'resourceValue']}));

            return;
        }

        dispatch(fetchIndexMetadataDescriptionsPending());

        try {
            const response = await api.indexMetadata.getIndexMetadataDescriptions(redactionDocumentId);

            dispatch(fetchIndexMetadataDescriptionsSuccess(response));
        } catch (error) {
            dispatch(fetchIndexMetadataDescriptionsFailure(error));
            dispatch(addError(error));
        }
    };

type IDispatch = ThunkDispatch<IState,IKeywordManagementDispatchToProps, AnyAction>;

export const saveIndexMetadataDescriptions = (): (dispatch: IDispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: IDispatch, getState: () => IState): Promise<void> => {
        const {
            indexMetadataDescriptions: {
                metadataDescriptions,
            } ,
            reductionMetaData: { redactionDocumentId },
        } = getState();
        const data = metadataDescriptions
            .filter((description: IDescription) => description.dataType === FieldTypes.INDEX)
            .map((description: IDescription) => {
                return {
                    metadataId: description.id,
                    indexMetadataLookups: description.data.map((field: IDescriptionItem) => field.value),
                };
            });

        try {
            const response = await api.indexMetadata.saveIndexMetadataDescriptions(redactionDocumentId, data);

            dispatch(fetchIndexMetadataDescriptionsSuccess(response));
        } catch (error) {
            dispatch(fetchIndexMetadataDescriptionsFailure(error));
            dispatch(addError(error));
        }
    };

export const replaceIndexMetadataDescriptions =
    (replaceData: IReplaceData): (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: IDispatch, getState: () => IState): Promise<void> => {
        const { reductionMetaData: { redactionDocumentId } } = getState();

        try {
            await api.indexMetadata.replaceIndexDescription(redactionDocumentId, replaceData);

            dispatch(fetchIndexMetadataDescriptions());
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const replaceStringMetadataDescriptions =
    (metadataId: number, replaceData: IReplaceData): (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: IDispatch, getState: () => IState): Promise<void> => {
        const { reductionMetaData: { redactionDocumentId } } = getState();

        try {
            await api.indexMetadata.replaceStringDescription(redactionDocumentId, metadataId, replaceData);

            dispatch(fetchIndexMetadataDescriptions());
            dispatch(saveDocumentIndex());
            dispatch(savePageIndex());
        } catch (error) {
            dispatch(addError(error));
        }
    };
export const exportStringMetadataDescriptions =
    (metadataId: number): (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: IDispatch, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: { redactionDocumentId },
            localStorage: {language},
            localization: {modifiedLabels},
        } = getState();
        const label = {
            exportMessage: initialLabel,
        };
        const langRule = changeLang(language);

        resourceBundle.map((resource: any) => {
            if(getExportSuccessLabelByKey(resource.resourceKey)) {
                label[getExportSuccessLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getExportSuccessLabelByKey(resource.resourceKey)) {
                label[getExportSuccessLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        dispatch(exportStringMetadataDescriptionsPending());

        try {
            const response = await api.indexMetadata.exportIndexMetadataOccurrences(redactionDocumentId, metadataId);

            dispatch(exportStringMetadataDescriptionsSuccess(response));
            dispatch(addSuccessNotification({
                message: label.exportMessage[langRule],
                id: EXPORT,
            }));

            const exportDoc = new Blob([response], {type: EXPORT_PDF_TYPE});

            saveAs(exportDoc, EXPORT_PDF_KEYWORD_OCCURENCY);

        } catch (error) {
            dispatch(exportStringMetadataDescriptionsFailure(error));
            dispatch(addError(error));
        }
    };

export const getExportSuccessLabelByKey = (key: string): string => {

    if(key === 'COMMON_LABEL_EXPORT_SUCCESS') {
        return 'exportMessage';
    } else if(key === 'COMMON_LABEL_EXPORT_ABORT') {
        return 'exportAbortMessage';
    }
};

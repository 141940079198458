export const UNDEFINED = 'undefined';

export const getItem = (name: string): any => {
    const item = localStorage.getItem(name);

    return item !== UNDEFINED ? JSON.parse(item) : null;
};

export const setItem = (name: string, value: any): void => {
    localStorage.setItem(name, JSON.stringify(value));
};

export const getSessionItem = (name: string): any => {
    const item = sessionStorage.getItem(name);

    return item !== UNDEFINED ? JSON.parse(item) : null;
};

export const setSessionItem = (name: string, value: any): void => {
    sessionStorage.setItem(name, JSON.stringify(value));
};

// To fetch any partially know keys
export const fetchKeys = (requiredKey: string): string => {
    for (const key in localStorage) {

        if (key.includes(requiredKey)) {

            return key;
        }

    }
};

export const clearItemValue = (requiredKey: string): void => {
    for (const key in localStorage) {

        if (key.includes(requiredKey)) {

            localStorage.removeItem(key);
        }
    }
};

import { REPORT_LANG_SELECTION_MODAL, REPORT_LANG_SELECTION_TITLE, getReportLanguageModalLabels } from '../../constants/modalReportLangSelection.constants';
import { EXPORT, OK } from '../../constants/common.constants';
import { CHANGE_ANNOTATION_TYPE_TO, OVERLAYED_SHAPES, VIEW_SEVER_INFO } from '../../constants/contextmenu/context.menu.constants';
import { DISCLOSURE_TITLE_MODAL } from '../../constants/disclosure.contstants';
import { ASSIGNED_DUPLICATES_MODAL, ASSIGNED_DUPLICATES_TITLE_MODAL } from '../../constants/duplicates.constants';
import { GLOBAL_SETTINGS_TITLE } from '../../constants/globalSettings.constants';
import { EDIT_SYSTEM_MESSAGE_MODAL } from '../../constants/localization.constants';
import {
    ALERT_DIALOG_MODAL, CONFIRMATION_DIALOG_MODAL, EDIT_FILE_NAME_MODAL,
    EDIT_FILE_NAME_TITLE, EDIT_PAGINATION_MODAL,
    IMPORT, PAGINATION_TITLE
} from '../../constants/messages.constants';
import {
    ANNOTATION_TYPE_MANAGEMENT,
    ASSOCIATE_WATERMARK_TO_FOLDER,
    DISCLOSURE_MANAGEMENT, DOCUMENT_INDEX, DUPLICATE_REFERENCES, ENCRYPTION, GLOBAL_SETTINGS,
    PACKAGE_PRESETS, REFERENCES, REPLACE_DISCLOSURE, REQUEST_SECURITY, SELECT_ANNOTATION_OBJECTS,
    SEVER_INFORMATION, USER_DEFINED_STAMP_MANAGEMENT, WATERMARK_MANAGEMENT
} from '../../constants/modal.constants';
import { REQUEST_SEARCH_MODAL } from '../../constants/modalRequestSearch.constants';
import { USER_PREFERENCE_MODAL, USER_PREFERENCE_TITLE } from '../../constants/modalUserPreference.constants';
import { RELEASE_DATE_PARAM } from '../../constants/releaseDate.constants';
import { SEARCH_REDACTION_DOCUMENTS } from '../../constants/searchDocuments.contstants';
import {
    DOCUMENT_STACK_MODAL,
    DOCUMENT_STACK_TITLE_MODAL,
    STACK_MANAGMENT_MODAL,
    STACK_MANAGMENT_TITLE_MODAL,
    STACK_PROPERTIES_MODAL,
    STACK_PROPERTIES_TITLE_MODAL
} from '../../constants/stack.contstants';
import { WATERMARK_TITLE_MODAL } from '../../constants/watermark.contstants';
import ChangeAnnotationTypeTo from './changeAnnotationTypeTo/changeAnnotationTypeTo';
import ConfirmationDialog from './confirmationDialog/ConfirmationDialog';
import ModalAboutRedaction from './modalAboutRedaction/ModalAboutRedaction';
import ModalArticleStamps from './modalArticleStamps/ModalArticleStamps';
import ModalAssignDuplicate from './modalAssignDuplicate/ModalAssignDuplicate';
import { ModalAssociateWatermarks } from './modalAssociateWatermark/ModalAssociateWatermark';
import { ModalDisclosureManagement } from './modalDisclosureManagement/modalDisclosureManagement';
import { ModalDocumentStack } from './modalDocumentStack/modalDocumentStack';
import ModalDuplicateReferences from './modalDuplicateReferences/ModalDuplicateReferences';
import { EditFilenameDialog } from './modalEditFilename/ModalEditFilename';
import ModalEditPagination from './modalEditPagination/ModalEditPagination';
import ModalEncryption from './modalEncryption/modalEncryption';
import GlobalSettingsModal from './modalGlobalSettings/GlobalSettingsModal';
import ModalIndexKeywordManagement from './modalIndexKeywordManagement/ModalIndexKeywordManagement';
import { keyboardShortcuts } from './modalKeyboardShortcusts/keyboardShortcuts';
import ModalAnnotationTypes from './modalLayerTypes/ModalAnnotationTypes';
import ModalUserDefinedStamp from './modalLayerTypes/UserDefinedStamp';
import ModalOverlayedShapes from './modalOverlayedShapes/modalOverlayedShapes';
import ModalPackagePresets from './modalPackagePresets/ModalPackagePresets';
import ModalPageReviewSummary from './modalPageReviewSummary/ModalPageReviewSummary';
import Reference from './modalReference/Reference';
import ModalReleaseDate from './modalReleaseDate/ModalReleseDate';
import { ReplaceDisclosure } from './modalReplaceDisclosure/ReplaceDisclosure';
import ModalWindowRequester from './modalRequesterReport/ModalWindowRequester';
import ModalRequestSearch from './modalRequestSearch/ModalRequestSearch';
import { ModalRequestSecurity } from './modalRequestSecurity/modalRequestSecurity';
import ModalSearchDocument from './modalSearchDocument/ModalSearchDocument';
import ModalSelectContacts from './modalSelectContacts/ModalSelectContacts';
import ModalSeverInfo from './modalSeverInfo/modalSeverInfo';
import ModalCustomStamps from './modalsStamps/ModalCustomStamps';
import { ModalStackManagement } from './modalStackManagement/modalStackManagement';
import { ModalStackProperties } from './modalStackProperties/modalStackProperties';
import ModalSystemMessageEdit from './modalSystemMessageEdit/ModalSystemMessageEdit';
import ModalUndoDisclosure from './modalUndoDisclosure/ModalUndoDisclosure';
import { ModalUserPreference } from './modalUserPreference/ModalUserPreference';
import ModalWatermarkManagement from './modalWatermarkManagment/modalWatermarkManagement';
import ModalWindowExport from './modalWindowExport/ModalWindowExport';
import ModalWindowImport from './modalWindowImport/ModalWindowImport';
import { ModalReportLangSelection } from './modalReportLangSelection/ModalReportLangSelection';
import { IMPORT_FROM_EDMS, ModalImportFromEDMS } from './modalImportFromEDMS/ModalImportFromEDMS';

export const MIN_WIDTH_MORE_HUGE = 900;
export const MIN_WIDTH_ETRA_HUGE = 1000;
export const MIN_WIDTH_HUGE = 720;
export const MIN_WIDTH_LARGE = 500;
export const MIN_WIDTH_MEDIUM_L = 330;
export const MIN_WIDTH_MEDIUM = 300;
export const MIN_HEIGHT_LARGE = 500;
export const MIN_HEIGHT_MEDIUM = 350;
export const MIN_HEIGHT_MEDIUM_SMALL = 300;
export const MIN_HEIGHT_SMALL = 200;
export const MIN_WIDTH_SMALL = 400;
export const MIN_MEDIUM_HEIGHT_SMALL = 400;
export const GLOBAL_SETTINGS_MIN_HEIGHT = 350;
export const DEFAULT_MEDIUM_HEIGHT_IN_PERCENT = '55%';
export const DEFAULT_LARGE_HEIGHT_IN_PERCENT = '75%';
export const DEFAULT_LARGE_HEIGHT = 760;
export const DEFAULT_HEIGHT_SMALL = 200;
export const DEFAULT_HEIGHT_MEDIUM_SMALL = 300;
export const DEFAULT_HEIGHT_MEDIUM = 450;
export const DEFAULT_HEIGHT_LARGE = 550;
export const DEFAULT_LARGE_WIDTH = 700;
export const DEFAULT_MEDIUM_WIDTH = 500;
export const DEFAULT_MEDIUM_SMALL = 250;
export const DEFAULT_WIDTH_HUGE = 890;
export const DEFAULT_WIDTH_SMALL_IN_PERCENT = '25%';
export const DEFAULT_WIDTH_BETWEEN_SMALL_AND_MEDIUM_IN_PERCENT = '42%';
export const DEFAULT_WIDTH_MEDIUM_IN_PERCENT = '65%';
export const DEFAULT_WIDTH_HUGE_IN_PERCENT = '70%';
export const DEFAULT_EXPORT_MODAL_WIDTH_HUGE_IN_PERCENT = '95%';
export const ARTICLE_STAMPS_MODAL = 'articleStamps';
export const SELECT_CONSTANT_MODAL = 'selectContacts';
export const CUSTOM_STAMPS_MODAL = 'customStamps';
export const INDEX_KEYWORD_MANAGEMENT = 'indexKeywordManagement';
export const INDEX_KEYWORD_MANAGEMENT_TITLE = 'Index Keyword Management';
export const SIZE_AUTO = 'auto';
export const REQUESTER_REPORT_MODAL = 'requester';
export const PAGE_REVIEW_SUMMARY_MODAL = 'page_review_summary';

export default {
    [CONFIRMATION_DIALOG_MODAL]: {
        content: ConfirmationDialog,
        okButton: OK,
        detectChanges: false,
        cancelButton: true,
        modalDimensions: {
            disableResize: true,
            disableDrag: true,
        },
    },
    [ALERT_DIALOG_MODAL]: {
        content: ConfirmationDialog,
        okButton: OK,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            disableResize: true,
            disableDrag: true,
        },
    },
    [IMPORT]: {
        content: ModalWindowImport,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_SMALL,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_HEIGHT_LARGE,
            defaultHeight: DEFAULT_MEDIUM_SMALL,
        },
    },
    [REQUESTER_REPORT_MODAL]: {
        title: 'Requester',
        content: ModalWindowRequester,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_SMALL,
            disableResize: true,
            disableDrag: false,
            defaultWidth: DEFAULT_MEDIUM_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_SMALL,
        },
    },
    [PAGE_REVIEW_SUMMARY_MODAL]: {
        title: 'Page review summary',
        content: ModalPageReviewSummary,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_SMALL,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_MEDIUM_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_SMALL,
        },
    },
    export: {
        title: EXPORT,
        content: ModalWindowExport,
        okButton: false,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_MORE_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_EXPORT_MODAL_WIDTH_HUGE_IN_PERCENT,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    [EDIT_PAGINATION_MODAL]: {
        title: PAGINATION_TITLE,
        content: ModalEditPagination,
        okButton: false,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: 400,
            modalMinHeight: MIN_HEIGHT_MEDIUM_SMALL,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_MEDIUM_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_MEDIUM_SMALL,
        },
    },
    [USER_DEFINED_STAMP_MANAGEMENT]: {
        title: 'Manage User Defined Stamps',
        content: ModalUserDefinedStamp,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_MEDIUM_IN_PERCENT,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    keyList: {
        title: 'Keyboard Shortcuts',
        content: keyboardShortcuts,
        okButton: false,
        detectChanges: false,
        cancelButton: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_MEDIUM_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_MEDIUM,
        },
    },
    customStamps: {
        title: 'Apply user defined stamps',
        content: ModalCustomStamps,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_MEDIUM,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_MEDIUM_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_MEDIUM,
        },
    },
    articleStamps: {
        title: 'Apply page level exemptions',
        content: ModalArticleStamps,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_MEDIUM,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_MEDIUM_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_LARGE,
        },
    },
    [DOCUMENT_INDEX]: {
        title: INDEX_KEYWORD_MANAGEMENT_TITLE,
        content: ModalIndexKeywordManagement,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_LARGE_WIDTH,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    selectContacts: {
        title: 'Select Contacts',
        content: ModalSelectContacts,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_HUGE,
            modalMinHeight: DEFAULT_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: MIN_WIDTH_ETRA_HUGE + 278,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    [SEARCH_REDACTION_DOCUMENTS]: {
        title: 'Search Redaction Documents',
        content: ModalSearchDocument,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_MEDIUM_IN_PERCENT,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    aboutRedaction: {
        title: 'About Redaction',
        content: ModalAboutRedaction,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: DEFAULT_MEDIUM_SMALL,
            modalMinHeight: DEFAULT_HEIGHT_SMALL,
            disableResize: true,
            disableDrag: true,
            defaultWidth: DEFAULT_MEDIUM_SMALL,
            defaultHeight: DEFAULT_HEIGHT_SMALL,
        },
    },
    [ANNOTATION_TYPE_MANAGEMENT]: {
        title: 'Manage Annotation Types',
        content: ModalAnnotationTypes,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_MORE_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_MEDIUM_IN_PERCENT,
            defaultHeight: DEFAULT_MEDIUM_HEIGHT_IN_PERCENT,
        },
    },
    [PACKAGE_PRESETS]: {
        title: 'Package Presets',
        content: ModalPackagePresets,
        okButton: false,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_HUGE_IN_PERCENT,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    [DISCLOSURE_MANAGEMENT]: {
        title: DISCLOSURE_TITLE_MODAL,
        content: ModalDisclosureManagement,
        okButton: false,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_MEDIUM_IN_PERCENT,
            defaultHeight: DEFAULT_MEDIUM_HEIGHT_IN_PERCENT,
        },
    },
    [ENCRYPTION]: {
        title: 'Encryption',
        content: ModalEncryption,
        okButton: false,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_LARGE_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_MEDIUM,
        },
    },
    [DOCUMENT_STACK_MODAL]: {
        title: DOCUMENT_STACK_TITLE_MODAL,
        content: ModalDocumentStack,
        okButton: false,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: MIN_WIDTH_LARGE,
            defaultHeight: MIN_HEIGHT_MEDIUM,
        },
    },
    [STACK_MANAGMENT_MODAL]: {
        title: STACK_MANAGMENT_TITLE_MODAL,
        content: ModalStackManagement,
        okButton: false,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_LARGE_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_MEDIUM,
        },
    },
    [STACK_PROPERTIES_MODAL]: {
        title: STACK_PROPERTIES_TITLE_MODAL,
        content: ModalStackProperties,
        okButton: false,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_LARGE_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_MEDIUM,
        },
    },
    [GLOBAL_SETTINGS]: {
        title: GLOBAL_SETTINGS_TITLE,
        content: GlobalSettingsModal,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
            modalMinHeight: GLOBAL_SETTINGS_MIN_HEIGHT,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_HUGE_IN_PERCENT,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    undoDisclosure: {
        title: 'Undo Disclosure',
        content: ModalUndoDisclosure,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: DEFAULT_WIDTH_MEDIUM_IN_PERCENT,
            modalMinHeight: MIN_HEIGHT_SMALL,
            disableResize: true,
            disableDrag: true,
            defaultWidth: MIN_WIDTH_MEDIUM,
            defaultHeight: MIN_HEIGHT_SMALL,
        },
    },
    [CHANGE_ANNOTATION_TYPE_TO]: {
        title: 'Convert Annotations',
        content: ChangeAnnotationTypeTo,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: DEFAULT_WIDTH_SMALL_IN_PERCENT,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: MIN_WIDTH_MEDIUM,
            defaultHeight: MIN_HEIGHT_LARGE,
        },
    },
    [REQUEST_SECURITY]: {
        title:'Request Security',
        content: ModalRequestSecurity,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_MORE_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_HUGE_IN_PERCENT,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    [WATERMARK_MANAGEMENT]: {
        title: WATERMARK_TITLE_MODAL,
        content: ModalWatermarkManagement,
        okButton: false,
        detectChanges: true,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_MEDIUM_IN_PERCENT,
            defaultHeight: DEFAULT_MEDIUM_HEIGHT_IN_PERCENT,
        },
    },
    [ASSOCIATE_WATERMARK_TO_FOLDER]: {
        title: 'Associate Watermark to Request Type',
        content: ModalAssociateWatermarks,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_LARGE,
            modalMinHeight: MIN_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_LARGE_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_MEDIUM,
        },
    },
    [REQUEST_SEARCH_MODAL]: {
        title: 'Search For Request',
        content: ModalRequestSearch,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_ETRA_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_HUGE_IN_PERCENT,
            defaultHeight: DEFAULT_LARGE_HEIGHT_IN_PERCENT,
        },
    },
    [EDIT_SYSTEM_MESSAGE_MODAL]: {
        title: 'Edit System Message',
        content: ModalSystemMessageEdit,
        okButton: false,
        detectChanges: true,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_HUGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_BETWEEN_SMALL_AND_MEDIUM_IN_PERCENT,
        },
    },
    [REPLACE_DISCLOSURE]: {
        title: 'Replace Disclosure',
        content: ReplaceDisclosure,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_MEDIUM,
            modalMinHeight: MIN_HEIGHT_SMALL,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_HUGE,
            defaultHeight: DEFAULT_HEIGHT_SMALL,
        },
    },
    [ASSIGNED_DUPLICATES_MODAL]: {
        title: ASSIGNED_DUPLICATES_TITLE_MODAL,
        content: ModalAssignDuplicate,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: DEFAULT_MEDIUM_WIDTH,
            modalMinHeight: DEFAULT_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_MEDIUM_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_LARGE,
        },
    },
    [DUPLICATE_REFERENCES]: {
        title: 'Duplicate References',
        content: ModalDuplicateReferences,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: DEFAULT_MEDIUM_WIDTH,
            modalMinHeight: DEFAULT_HEIGHT_MEDIUM,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_MEDIUM_WIDTH,
            defaultHeight: DEFAULT_HEIGHT_MEDIUM,
        },
    },
    [REFERENCES]: {
        title: 'References',
        content: Reference,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_HEIGHT_LARGE,
            modalMinHeight: MIN_HEIGHT_LARGE,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_MEDIUM_IN_PERCENT,
            defaultHeight: DEFAULT_LARGE_HEIGHT,
        },
    },
    [RELEASE_DATE_PARAM]: {
        title: 'Release Management',
        content: ModalReleaseDate,
        okButton: false,
        detectChanges: false,
        cancelButton: false,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_HUGE,
            modalMinHeight: MIN_HEIGHT_SMALL,
            disableResize: false,
            disableDrag: false,
            defaultWidth: DEFAULT_WIDTH_MEDIUM_IN_PERCENT,
            defaultHeight: DEFAULT_HEIGHT_SMALL,
        },
    },
    [OVERLAYED_SHAPES]: {
        title: SELECT_ANNOTATION_OBJECTS,
        content: ModalOverlayedShapes,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: DEFAULT_WIDTH_SMALL_IN_PERCENT,
            modalMinHeight: SIZE_AUTO,
            disableResize: false,
            disableDrag: false,
            defaultWidth: SIZE_AUTO,
            defaultHeight: SIZE_AUTO,
        },
    },
    [EDIT_FILE_NAME_MODAL]: {
        title: EDIT_FILE_NAME_TITLE,
        content: EditFilenameDialog,
        okButton: false,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: 400,
            modalMinHeight: SIZE_AUTO,
            disableResize: false,
            disableDrag: false,
            defaultWidth: SIZE_AUTO,
            defaultHeight: SIZE_AUTO,
        },
    },
    [VIEW_SEVER_INFO]: {
        title: SEVER_INFORMATION,
        content: ModalSeverInfo,
        okButton: false,
        cancelButton: false,
        detectChanges: true,
        modalDimensions: {
            modalMinWidth: MIN_WIDTH_MORE_HUGE,
            modalMinHeight: SIZE_AUTO,
            disableResize: false,
            disableDrag: false,
            defaultWidth: SIZE_AUTO,
            defaultHeight: SIZE_AUTO,
        },
    },
    [USER_PREFERENCE_MODAL]: {
        title: USER_PREFERENCE_TITLE,
        content: ModalUserPreference,
        okButton: false,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: 516,
            modalMinHeight: SIZE_AUTO,
            disableResize: false,
            disableDrag: false,
            defaultWidth: SIZE_AUTO,
            defaultHeight: SIZE_AUTO,
        },
    },
    [REPORT_LANG_SELECTION_MODAL]: {
        title: getReportLanguageModalLabels(REPORT_LANG_SELECTION_TITLE),
        content: ModalReportLangSelection,
        okButton: false,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: 516,
            modalMinHeight: SIZE_AUTO,
            disableResize: false,
            disableDrag: false,
            defaultWidth: SIZE_AUTO,
            defaultHeight: SIZE_AUTO,
        },
    },
    [IMPORT_FROM_EDMS]: {
        title: 'Import from EDMS',
        content: ModalImportFromEDMS,
        okButton: false,
        cancelButton: false,
        detectChanges: false,
        modalDimensions: {
            modalMinWidth: 516,
            modalMinHeight: SIZE_AUTO,
            disableResize: false,
            disableDrag: false,
            defaultWidth: SIZE_AUTO,
            defaultHeight: 400,
        },
    },
};

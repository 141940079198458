import { AnyAction } from 'redux';
import { IIndexMetadataState } from './indexMetadata.model';
import { FETCH_INDEX_METADATA_PENDING, FETCH_INDEX_METADATA_SUCCESS, FETCH_INDEX_METADATA_FAIL } from './constant';

export const initialState: IIndexMetadataState = {
    metadata: [],
    pending: false,
    error: null,
};

export const indexMetadataReducer =
    (state: IIndexMetadataState = initialState, { type, payload }: AnyAction): IIndexMetadataState => {
    switch (type) {
        case FETCH_INDEX_METADATA_PENDING:
            return {
                ...state,
                pending: true,
            };
        case FETCH_INDEX_METADATA_FAIL:
            return {
                ...state,
                pending: false,
                error: payload,
            };
        case FETCH_INDEX_METADATA_SUCCESS:

            return {
                ...state,
                pending: false,
                error: null,
                metadata: [
                    ...payload,
                    {
                        id: 11,
                        name: 'Filename',
                        description: 'Filename',
                        dataType: 'String',
                        defaultIndexMetadata: 'Filename',
                        lookups: null,
                    },
                ],
            };
        default:
            return state;
    }
};

export default indexMetadataReducer;

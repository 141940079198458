import { AnyAction, Dispatch } from 'redux';
import { cloneDeep, intersectionBy, isUndefined, isEmpty } from 'lodash';
import { action } from 'typesafe-actions';
import { saveAs } from 'file-saver';
import api from '../../api/reductionApi';
import {
    CHANGE_DOCUMENT_IN_EXPORT_LIST,
    CHANGE_EXPORT_DESTINATION_OPTIONS,
    CHANGE_OPTIONS,
    CHANGE_PAGES_IN_EXPORT_LIST,
    CHANGE_PAGINATION_OPTIONS,
    CLEAR_EXPORT_STATE,
    CLEAR_PRESET_OPTIONS,
    EXPORT_STACK_DOCS_FAILURE,
    EXPORT_STACK_DOCS_PENDING,
    EXPORT_STACK_DOCS_SUCCESS,
    GET_EXPORT_DOCUMENTS_FAILURE,
    GET_EXPORT_DOCUMENTS_PENDING,
    GET_EXPORT_DOCUMENTS_SUCCESS,
    POST_EXPORT_DOCUMENT_FAILURE,
    POST_EXPORT_DOCUMENT_PENDING,
    POST_EXPORT_DOCUMENT_SUCCESS,
    REMOVE_ANNOTATION_TYPE,
    SELECT_EXPORT_STACK,
    SELECT_ALL_OR_DESELECT_ALL_DOCUMENTS_IN_EXPORT_LIST,
    SET_ALL_DOCUMENT_PAGES_TO_EXPORT,
    SET_ANNOTATION_TYPE,
    SET_CONSULT_FILTERS,
    SET_EXPORT_OPTIONS_FROM_PRESET,
    SET_PAGES_FOR_SINGLE_FILE,
    CHANGE_DOCUMENT_ORIGINAL_NAME_IN_EXPORT_LIST,
    SET_EXPORT_FILE_NAME_ERROR_FLAG,
    CHANGE_ATTACHMENT_TYPE_OPTIONS,
} from '../reducers/modalWindowExport/constant';
import { EXPORT_PDF_NAME, EXPORT_PDF_TYPE, EXPORT_ZIP_NAME, EXPORT_ZIP_TYPE } from '../../constants';
import * as constants from '../../constants/export.contstants';
import {
    ALL_DOCUMENTS, AS_PAGE_OUT_NOTICE_PARAM,
    EXCLUDE_PARAM,
    EXPORTED_SUCCESSFULLY, INCLUDE_PARAM,
    NON_RELEASABLE_PAGES_PARAM,
    ORIGINAL_SEVER_COLOR_PARAM,
    ORIGINALS_ONLY_PARAM,
    SEVER_COLOR_PARAM,
} from '../../constants/export.contstants';
import { ID, NOT_APPLICABLE } from '../../constants/common.constants';
import { addError, addSuccessNotification } from './errorHandling';
import {
    IBasicDocuments,
    IContentOptions,
    IExportController,
    IExportCustomPages,
    IExportData,
    IExportDoc,
    IExportPages,
    IExportCustomDocumentName,
} from '../reducers/modalWindowExport/modalWindowExport.model';
import {
    IContentOptionChange,
} from '../../containers/modalWindowContents/modalWindowExport/contentOption/contentOption.model';
import { IError } from '../common.model';
import {
    IPaginationOptionChange,
} from '../../containers/modalWindowContents/modalWindowExport/paginationOptions/PaginationOptions';
import { IState } from '../store';
import { IAnnotationType } from '../reducers/annotationTypes/annotationTypes.model';
import {
    IAttachmentOptionChange,
    IDestinationOptionChange,
} from '../../containers/modalWindowContents/modalWindowExport/exportDestination/ExportDestination';
import {
    IExportFilters,
    ISelectedPagesData,
} from '../../containers/modalWindowContents/modalWindowExport/modalWindowExport.model';
import { IFile } from '../reducers/documentList/documentList.model';
import { IDisclosureTypes } from '../reducers/modalGlobalSettings/globalSettings.model';
import { IPeople } from '../../containers/modalWindowContents/modalWindowExport/consult/consult.model';
import { IDisclosureOptions } from '../reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { setLastUsedPrinter } from './localStorage';
import { IExportPreset } from '../../containers/modalWindowContents/modalPackagePresets/modalPackagePresets.model';
import { IPages } from '../reducers/pageList/pageList.model';
import { ALERT_DIALOG_MODAL, EXPORT_ABORT } from '../../constants/messages.constants';
import { openModalWindow } from './modal';
import { changeLang } from './localization';
import resourceBundle from '../../containers/localization/localizationData';
import { initialLabel } from '../../constants/localization.constants';
import { getExportSuccessLabelByKey } from './indexMetadataDescriptions';

export const clearExportState = (): AnyAction => action(CLEAR_EXPORT_STATE);
export const clearPresetOptions = (): AnyAction => action(CLEAR_PRESET_OPTIONS);
export const addOrRemoveDocument = (isChecked: boolean, id: number): AnyAction =>
    action(CHANGE_DOCUMENT_IN_EXPORT_LIST, { id, isChecked });
export const addOrRemoveAllDocuments = (isChecked: boolean): AnyAction =>
    action(SELECT_ALL_OR_DESELECT_ALL_DOCUMENTS_IN_EXPORT_LIST, isChecked);
export const setPagesToExport = (id: number, selectedPageRange: number[]): AnyAction =>
    action(CHANGE_PAGES_IN_EXPORT_LIST, { id, selectedPageRange });

export const changeDocumentOriginalNameInExportDocumentList = (fileName: string, id: number):
    AnyAction => action(CHANGE_DOCUMENT_ORIGINAL_NAME_IN_EXPORT_LIST, { id, fileName });
export const setExportFileNameErrorMessage = (errorMessage: string): AnyAction =>
    action(SET_EXPORT_FILE_NAME_ERROR_FLAG, errorMessage);
export const setExportOptionsFromPreset = (data: IExportPreset[]): AnyAction =>
    action(SET_EXPORT_OPTIONS_FROM_PRESET, data);

export const handleChangeOption = (data: IContentOptionChange): AnyAction => action(CHANGE_OPTIONS, data);
export const handlePaginationOptions = (data: IPaginationOptionChange): AnyAction =>
    action(CHANGE_PAGINATION_OPTIONS, data);
export const handleExportDestinationOptions = (data: IDestinationOptionChange): AnyAction =>
    action(CHANGE_EXPORT_DESTINATION_OPTIONS, data);
export const handleAttachmentTypeOptions = (data: IAttachmentOptionChange): AnyAction =>
    action(CHANGE_ATTACHMENT_TYPE_OPTIONS, data);

export const setAnnotationOption = (id: number): AnyAction => action(SET_ANNOTATION_TYPE, id);
export const removeAnnotationOption = (id: number): AnyAction => action(REMOVE_ANNOTATION_TYPE, id);
export const getExportDocumentsPending = (): AnyAction => action(GET_EXPORT_DOCUMENTS_PENDING);
export const getExportDocumentsSuccess = (data: IExportData): AnyAction =>
    action(GET_EXPORT_DOCUMENTS_SUCCESS, data);
export const getExportDocumentsFailure = (error: IError): AnyAction => action(GET_EXPORT_DOCUMENTS_FAILURE, error);
export const postExportDocumentPending = (): AnyAction => action(POST_EXPORT_DOCUMENT_PENDING);
export const postExportDocumentSuccess = (data: IExportController): AnyAction =>
    action(POST_EXPORT_DOCUMENT_SUCCESS, data);
export const postExportDocumentFailure = (error: IError): AnyAction => action(POST_EXPORT_DOCUMENT_FAILURE, error);
export const setExportPages = (formattedDocs: IExportDoc[]): AnyAction =>
    action(SET_ALL_DOCUMENT_PAGES_TO_EXPORT, formattedDocs);
export const setExportFilters = (filters: IExportFilters): AnyAction => action(SET_CONSULT_FILTERS, filters);

export const setPagesForSingleFile = (pages: ISelectedPagesData): AnyAction =>
    action(SET_PAGES_FOR_SINGLE_FILE, pages);

export const selectExportStack = (stackId: string): AnyAction => action(SELECT_EXPORT_STACK, stackId);

export const getExportStackDocsPending = (): AnyAction => action(EXPORT_STACK_DOCS_PENDING);
export const getExportStackDocsSuccess = (files: IFile[]): AnyAction => action(EXPORT_STACK_DOCS_SUCCESS, files);
export const getExportStackDocsFailure = (error: IError): AnyAction => action(EXPORT_STACK_DOCS_FAILURE, error);

export const formatFiles = (files: IFile[], selectedPages?: number[]): IExportDoc[] =>
    files.map((file: IFile): IExportDoc => ({
        id: file.id,
        name: file.name,
        isChecked: true,
        pages: [...file.pages]
            .sort((a: IPages, b: IPages): number => a.actualPageNumber - b.actualPageNumber)
            .map((page: IPages): IExportPages => ({
                id: page.id,
                pageNumber: page.actualPageNumber,
                isChecked: selectedPages ? selectedPages.includes(page.id) : true,
            })),
        originalFileName: file.originalName,
    }));

export const filterPages = (
    docs: IFile[],
    contactFilters: IPeople[],
    disclosureFilters: IDisclosureOptions[],
    AllDisclosureTypes: IDisclosureOptions[],
    includePagesWithContact: boolean,
    includePaginatedPagesOnly: boolean,
    nonReleasablePagesOption: string,
): IFile[] => {
    const clonedDocs = cloneDeep(docs);

    if (includePaginatedPagesOnly) {
        clonedDocs.forEach((doc: IFile) => {
            doc.pages = filterPaginatedPages(doc, AllDisclosureTypes);
        });
    }

    if (contactFilters.length && includePagesWithContact) {
        clonedDocs.forEach((doc: IFile) => {
            return doc.pages = getDocsFilteredByContactIds(doc, contactFilters);
        });
    }

    if (disclosureFilters.length && includePagesWithContact) {
        clonedDocs.forEach((doc: IFile) => {
            return doc.pages = filterPagesByDisclosures(doc, disclosureFilters);
        });
    }

    if (nonReleasablePagesOption === EXCLUDE_PARAM) {
        clonedDocs.forEach((doc: IFile) => {
            return doc.pages = filterNonReleasablePages(doc, AllDisclosureTypes);
        });
    }

    return clonedDocs.filter((doc: IFile) => {
        return doc.pages.length;
    });
};

// Include Paginated Pages Only
export const filterPaginatedPages = (doc: IFile, disclosureFilters: IDisclosureOptions[]): IPages[] => {
    return doc.pages.filter((page: IPages) => {
        const isDisclosurePaginated = disclosureFilters.some((item: IDisclosureOptions): boolean => {
            return item.id === page.disclosureTypeId && item.isInPagination;
        });

        return isDisclosurePaginated && !isUndefined(page.paginationIndex);
    });
};

export const filterDocsWithPaginatedPages = (
    files: IFile[],
    includePaginatedPagesOnly: boolean,
): IFile[] => {
    return files.filter((file: IFile): boolean => {
        if (!file.pages.length) {
            return;
        }

        if (includePaginatedPagesOnly) {
            return !isEmpty(file.paginationRange);
        }
    });
};
// --------------------------------------------

// Include Pages with Contact Disclosures Only --> Disclosures Table
const filterPagesByDisclosures = (file: IFile, disclosureFilters: IDisclosureOptions[]): IPages[] => {
    return file.pages.filter((page: IPages): boolean => {
        return disclosureFilters.some(
            (item: IDisclosureTypes): boolean => item.id === page.disclosureTypeId);
    });
};

const filterDocsByDisclosures = (documentList: IFile[], disclosureFilters: IDisclosureOptions[]): IExportDoc[] => {
    const filtered = documentList.filter((doc: IFile): boolean => {
        const filteredPages = filterPagesByDisclosures(doc, disclosureFilters);

        return !!filteredPages.length;
    });

    return formatFiles(filtered, null);
};
// --------------------------------------------

// Include Pages with Contact Disclosures Only --> Contacts Table
const getDocsFilteredByContactIds = (curr: IFile, contactFilters: IPeople[]): IPages[] => {
    return curr.pages.filter((page: IPages): boolean => {
        if (page.contactIds.length > 0) {
            return loopPageContactIds(curr, page, contactFilters);
        }
    });
};

const loopPageContactIds = (doc: IFile, page: IPages, contactFilters: IPeople[]): boolean => {
    const filteredIds = page.contactIds.filter((id: number): boolean => {
        return contactFilters.some((item: IPeople): boolean => item.peopleRsn === id);
    });

    return filteredIds.length > 0;
};

const filterDocsByContacts = (documentList: IFile[], contactFilters: IPeople[]): IExportDoc[] => {
    const filteredDocs = documentList.filter((doc: IFile): boolean => {
        const filteredPages = getDocsFilteredByContactIds(doc, contactFilters);

        return !!filteredPages.length;
    });

    return formatFiles(filteredDocs, null);
};
// --------------------------------------------

// Non Releasable Pages
const filterNonReleasablePages = (doc: IFile, disclosureFilters: IDisclosureOptions[]): IPages[] => {
    return doc.pages.filter((page: IPages): boolean => {
        return disclosureFilters.some(
            (item: IDisclosureTypes): boolean => item.id === page.disclosureTypeId && item.isReleasableState);
    });
};

const filterNonReleasableDocs = (documentList: IFile[], disclosureFilters: IDisclosureOptions[]): IFile[] => {
    return documentList.filter((doc: IFile): boolean => {
        const filteredPages = filterNonReleasablePages(doc, disclosureFilters);

        return !!filteredPages.length;
    });
};
// --------------------------------------------

// This function is called when user exports pages from context menu
const filterSelectedPages = (
    documentsList: IFile[],
    selectedPagesData: ISelectedPagesData,
): IFile[] => {
    const { currentDocId, pageIds } = selectedPagesData;

    const filteredByDocId = documentsList.filter((file: IFile): boolean => {
        return file.id === currentDocId;
    });

    const clonedFilteredDoc = cloneDeep(filteredByDocId);

    if (clonedFilteredDoc.length) {
        clonedFilteredDoc[0].pages = filteredByDocId[0].pages.filter((page: IPages): boolean => {
            return pageIds.includes(page.id);
        });

        return clonedFilteredDoc;
    }

    return [];
};

export const createCustomDocumentNamePairs = (exportDocs: IExportDoc[]): IExportCustomDocumentName[] => {
    return exportDocs.filter((exportDocsList: IExportDoc) => {
        return !!exportDocsList.isChecked;
    }).map((exportDoc: IExportDoc): IExportCustomDocumentName => {
        return {
            documentName: exportDoc.name,
            fileName: exportDoc.originalFileName,
        };
    });
};

export const updateExportFilters = ():
    (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const {
            documentList: { exportFiles },
            modalWindowExport: {
                contentOptions,
                exportFilters,
                pagesForSingleFile,
                currentExportStackId,
                exportStackDocumentsList,
            },
            disclosureTypes: { currentDisclosureTypes },
        } = getState();

        const {
            includePagesWithContact,
            includePaginatedPagesOnly,
            nonReleasablePagesOption,
            originalsOnly,
        } = contentOptions;

        const deepClonedDocList: any = cloneDeep(currentExportStackId === ALL_DOCUMENTS
            ? exportFiles
            : exportStackDocumentsList,
        );

        const defaultFiles = pagesForSingleFile && pagesForSingleFile.currentDocId && pagesForSingleFile.pageIds
            ? filterSelectedPages(deepClonedDocList, pagesForSingleFile)
            : deepClonedDocList;

        const { contactFilters, disclosureFilters } = exportFilters;

        const paginatedOnlyFilter = includePaginatedPagesOnly
            ? filterDocsWithPaginatedPages(defaultFiles, includePaginatedPagesOnly)
            : defaultFiles;

        const nonReleasableFilter = nonReleasablePagesOption === EXCLUDE_PARAM
            ? filterNonReleasableDocs(defaultFiles, currentDisclosureTypes)
            : defaultFiles;

        const contactTableFilters = contactFilters.length && includePagesWithContact
            ? filterDocsByContacts(defaultFiles, contactFilters)
            : defaultFiles;

        const disclosuresFilter = disclosureFilters.length && includePagesWithContact
            ? filterDocsByDisclosures(defaultFiles, disclosureFilters)
            : defaultFiles;

        // Apply filters to filter documents
        const filteredDocs: any = intersectionBy(
            nonReleasableFilter,
            paginatedOnlyFilter,
            contactTableFilters,
            disclosuresFilter,
            ID,
        );

        // Apply filters to filter pages
        const filteredPages = filterPages(
            filteredDocs,
            contactFilters,
            disclosureFilters,
            currentDisclosureTypes,
            includePagesWithContact,
            includePaginatedPagesOnly,
            nonReleasablePagesOption,
        );

        const isOnlyOriginal = originalsOnly ? defaultFiles : filteredPages;

        const formattedDocs = formatFiles(isOnlyOriginal, null);

        dispatch(setExportPages(formattedDocs));
};

export const setAllPages = (
    documentsList: IBasicDocuments[],
    selectedPagesData: ISelectedPagesData,
): (dispatch: Dispatch<any>, getState: () => IState) => void => (dispatch: Dispatch<any>): void => {

    dispatch(setPagesForSingleFile(selectedPagesData));

    dispatch(updateExportFilters());
};

export const handleOptions = (
    data: IContentOptionChange,
    contentOptionsData: IContentOptions,
    selectedPagesData: ISelectedPagesData,
    shouldUpdateDocList: boolean,
): (dispatch: Dispatch<any>, getState: () => IState) => void =>
    (dispatch: Dispatch<any>, getState: () => IState): void => {
        const { modalWindowExport: { exportDocumentsList: { basicDocuments } } } = getState();

        if (shouldUpdateDocList) {
            dispatch(setAllPages(basicDocuments, selectedPagesData));
        }

        dispatch(handleChangeOption(data));
    };

// any as it could accept different format
 export const formatAnnotations = (list: IAnnotationType[], annotationControlsId: number[], param: string): any =>
    list.map((annotation: IAnnotationType) => ({
        annotationTypeId: annotation.id,
        [param]: annotationControlsId.includes(annotation.id),
    }));

export const exportCustomPages = (
    exportDocumentsList: IExportDoc[],
): IExportCustomPages[] => exportDocumentsList.reduce(
    (result: IExportCustomPages[], current: IExportDoc) => {
        if (current.isChecked) {
            const checkedPages = current.pages
                .filter((page: IExportPages) => page.isChecked)
                .map((page: IExportPages) => page.id);

            return [...result, {
                documentId: current.id,
                pageIds: checkedPages,
            }];
        }

        return result;
    },
    [],
);

export const getExportDocuments = (
    redactionDocumentId: number,
    selectedPages: ISelectedPagesData,
): (dispatch: Dispatch<any>) => Promise<void> => async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(getExportDocumentsPending());

    try {
        const response = await api.exportController.getExportDocuments(redactionDocumentId);

        dispatch(getExportDocumentsSuccess(response));
        dispatch(setAllPages(response.basicDocuments, selectedPages));

    } catch (error) {
        dispatch(getExportDocumentsFailure(error));
    }
};

export const fetchExportStackDocList = (redactionDocumentId: number, stackId: number): (dispatch: Dispatch<any>) =>
    Promise<void> => async (dispatch: Dispatch<any>): Promise<void> => {

        dispatch(getExportStackDocsPending());

        try {
            const response = await api.stackController.getStackDocuments(redactionDocumentId, stackId);

            dispatch(getExportStackDocsSuccess(response));
            dispatch(updateExportFilters());
        } catch (error) {
            dispatch(getExportStackDocsFailure(error));
        }
    };

export const postExportDocument = (
    redactionDocumentId: number,
    highLightList: IAnnotationType[],
    severList: IAnnotationType[],
): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        dispatch(postExportDocumentPending());

        const {
            modalWindowExport: {
                documentsToExport,
                contentOptions,
                paginationOptions,
                annotationControlsId,
                exportDestinationOptions,
                attachmentTypeCode,
            },
            localStorage: {language},
            localization: {modifiedLabels},
        } = getState();

        const langRule = changeLang(language);

        const {
            name,
            severColorType,
            presets,
            showWatermarks,
            nonReleasablePagesOption,
            ...restContentOptions
        } = contentOptions;

        if(exportDestinationOptions.exportDestinationOption === constants.FOLDER_PROCESS_PARAM &&
            attachmentTypeCode[constants.PROCESS_ATTACHMENT_TYPE_CODE_PARAM]) {
            exportDestinationOptions.attachmentTypeCode =
                +attachmentTypeCode[constants.PROCESS_ATTACHMENT_TYPE_CODE_PARAM];
        } else if(exportDestinationOptions.exportDestinationOption === constants.REQUEST_FOLDER_PARAM &&
            attachmentTypeCode[constants.FOLDER_ATTACHMENT_TYPE_CODE_PARAM]) {
            exportDestinationOptions.attachmentTypeCode =
                +attachmentTypeCode[constants.FOLDER_ATTACHMENT_TYPE_CODE_PARAM];
        } else {
            exportDestinationOptions.attachmentTypeCode = 0;
        }

        const exportData = {
            exportCustomDocumentName: createCustomDocumentNamePairs(documentsToExport),
            exportCustomPages: exportCustomPages(documentsToExport),
            exportOptions: {
                annotationControlOptions: {
                    highlightDisplayOptions: formatAnnotations(highLightList, annotationControlsId, 'showHighlight'),
                    severTransparencyOptions: formatAnnotations(severList, annotationControlsId, 'showText'),
                },
                contentOptions: {
                    ...restContentOptions,
                    showWatermarks,
                    severColorOption: severColorType === ORIGINAL_SEVER_COLOR_PARAM
                        ? null
                        : contentOptions[SEVER_COLOR_PARAM],
                    nonReleasablePagesOption: contentOptions[ORIGINALS_ONLY_PARAM]
                        ? INCLUDE_PARAM
                        : contentOptions[NON_RELEASABLE_PAGES_PARAM],
                },
                paginationOptions,
                exportDestinationOptions,
            },
        };
        

        const label = {
            exportMessage: initialLabel,
            exportAbortMessage: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if(getExportSuccessLabelByKey(resource.resourceKey)) {
                label[getExportSuccessLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getExportSuccessLabelByKey(resource.resourceKey)) {
                label[getExportSuccessLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (exportDestinationOptions.exportDestinationOption === constants.PRINTER_PARAM) {
            dispatch(setLastUsedPrinter({
                printerCode: exportDestinationOptions.printerCode,
                trayCode: exportDestinationOptions.trayCode,
            }));
        }

        try {
            const response = await api.exportController.exportDocument(redactionDocumentId, exportData) as any;

            dispatch(postExportDocumentSuccess(response));
            dispatch(addSuccessNotification({
                message: label.exportMessage[langRule],
                id: 'export',
            }));

            const exportFileName =  exportDestinationOptions[constants.EXPORT_FILE_NAME_PARAM];

            if (exportDestinationOptions[constants.EXPORT_DESTINATION_PARAM] === constants.FILE_PARAM) {
                const { exportOptions: { contentOptions: { packageAsASingleFile } } } = exportData;

                const fileType = packageAsASingleFile ?
                    EXPORT_PDF_TYPE : EXPORT_ZIP_TYPE;
                const fileName = packageAsASingleFile ?
                    exportFileName ? exportFileName.concat('.pdf') : EXPORT_PDF_NAME:
                    exportFileName ? exportFileName.concat('.zip') : EXPORT_ZIP_NAME;

                const exportDoc = new Blob([response], { type: fileType });

                saveAs(exportDoc, fileName);
            }
        } catch (error) {
            dispatch(postExportDocumentFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: NOT_APPLICABLE,
                title: label.exportAbortMessage[langRule],
                message: error.message,
            }));
        }
    };

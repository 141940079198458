export const GET_REDACTION_DOCUMENT_METADATA_PENDING = 'redaction/GET_REDACTION_DOCUMENT_METADATA_PENDING';
export const GET_REDACTION_DOCUMENT_METADATA_SUCCESS = 'redaction/GET_REDACTION_DOCUMENT_METADATA_SUCCESS';
export const GET_REDACTION_DOCUMENT_METADATA_FAILURE = 'redaction/GET_REDACTION_DOCUMENT_METADATA_FAILURE';

export const GET_USER_PERMISSIONS_PENDING = 'redaction/GET_USER_PERMISSIONS_PENDING';
export const GET_USER_PERMISSIONS_SUCCESS = 'redaction/GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_FAILURE = 'redaction/GET_USER_PERMISSIONS_FAILURE';

export const GET_REDACTION_AUTH_PENDING = 'redaction/GET_REDACTION_AUTH_PENDING';
export const GET_REDACTION_AUTH_FAILURE = 'redaction/GET_REDACTION_AUTH_FAILURE';

export const GET_AMANDA_PERMISSIONS_PENDING = 'redaction/GET_AMANDA_PERMISSIONS_PENDING';
export const GET_AMANDA_PERMISSIONS_SUCCESS = 'redaction/GET_AMANDA_PERMISSIONS_SUCCESS';
export const GET_AMANDA_PERMISSIONS_FAILURE = 'redaction/GET_AMANDA_PERMISSIONS_FAILURE';

export const GET_ENTRY_DATA_PENDING = 'redaction/GET_ENTRY_DATA_PENDING';
export const GET_ENTRY_DATA_SUCCESS = 'redaction/GET_ENTRY_DATA_SUCCESS';
export const GET_ENTRY_DATA_FAILURE = 'redaction/GET_ENTRY_DATA_FAILURE';

import api from '../../api/reductionApi';
import { action } from 'typesafe-actions';
import { SET_PRINTERS } from '../reducers/printer/constant';
import { AnyAction, Dispatch } from 'redux';
import { addError } from '../../redux/actions/errorHandling';

const setPrinters = (data: any): AnyAction => action(SET_PRINTERS, data);

export const fetchPrinters = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            const response = await api.printers.getPrinters();
            dispatch(setPrinters(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };


export enum permissionValues {
    FULL_ACCESS = 'FULL_ACCESS',
    READ_ONLY_ALL = 'READ_ONLY_ALL',
    READ_ONLY_RELEASABLE = 'READ_ONLY_RELEASABLE',
    DENY_ACCESS = 'DENY_ACCESS',
}
 
export const DEFAULT_PERMISSION = permissionValues.FULL_ACCESS;

export const REDACTION_DOCUMENT_DELETE = 'REDACTION_DOCUMENT_DELETE';
export const REDACTION_DOCUMENT_EDIT_FILE_NAME = 'REDACTION_DOCUMENT_EDIT_FILE_NAME';
export const REDACTION_PAGE_DELETE = 'REDACTION_PAGE_DELETE';
export const REDACTION_DOCUMENT_PAGINATION_UNDO = 'REDACTION_DOCUMENT_PAGINATION_UNDO';
export const REDACTION_DOCUMENT_PAGINATION_EDIT = 'REDACTION_DOCUMENT_PAGINATION_EDIT';
export const REDACTION_DOCUMENT_PAGINATION_SET = 'REDACTION_DOCUMENT_PAGINATION_SET';
export const REDACTION_DOCUMENT_STACK_ADD_REMOVE = 'REDACTION_DOCUMENT_STACK_ADD_REMOVE';
export const REDACTION_DOCUMENT_STACK_MANAGEMENT = 'REDACTION_DOCUMENT_STACK_MANAGEMENT';
export const REDACTION_DOCUMENT_STACK_VIEW = 'REDACTION_DOCUMENT_STACK_VIEW';
export const REDACTION_COMMON_SET_DISCLOSURE = 'REDACTION_COMMON_SET_DISCLOSURE';
export const REDACTION_COMMON_REPLACE_DISCLOSURE = 'REDACTION_COMMON_REPLACE_DISCLOSURE';
export const REDACTION_COMMON_UNDO_DISCLOSURE = 'REDACTION_COMMON_UNDO_DISCLOSURE';
export const REDACTION_ACTION_EXPORT_PRINT_PACKAGE = 'REDACTION_ACTION_EXPORT_PRINT_PACKAGE';
export const REDACTION_ACTION_ADD_ATTACHMENT_FROM_EDMS = 'REDACTION_ACTION_ADD_ATTACHMENT_FROM_EDMS';
export const REDACTION_ACTION_DOCUMENT_SEARCH = 'REDACTION_ACTION_DOCUMENT_SEARCH';
export const REDACTION_ACTION_IMPORT_DOCUMENT_FOLDER_PROCESS = 'REDACTION_ACTION_IMPORT_DOCUMENT_FOLDER_PROCESS';
export const REDACTION_ACTION_IMPORT_DOCUMENT_LOCAL_FILE_SYSTEM = 'REDACTION_ACTION_IMPORT_DOCUMENT_LOCAL_FILE_SYSTEM';
export const REDACTION_ACTION_IMPORT_DOCUMENT_REDACTION = 'REDACTION_ACTION_IMPORT_DOCUMENT_REDACTION';
export const REDACTION_ACTION_IMPORT_DOCUMENT_SCANNER = 'REDACTION_ACTION_IMPORT_DOCUMENT_SCANNER';
export const REDACTION_DOCUMENT_SELECT_ALL = 'REDACTION_DOCUMENT_SELECT_ALL';
export const REDACTION_DOCUMENT_APPLY_OCR = 'REDACTION_DOCUMENT_APPLY_OCR';
export const REDACTION_DOCUMENT_APPLY_AUTO_DESKEW = 'REDACTION_DOCUMENT_APPLY_AUTO_DESKEW';
export const REDACTION_DOCUMENT_APPLY_AUTO_ALIGN_ARTICLE_STAMPS = 'REDACTION_DOCUMENT_APPLY_AUTO_ALIGN_ARTICLE_STAMPS';
export const REDACTION_PAGE_APPLY_AUTO_ALIGN_ARTICLE_STAMPS = 'REDACTION_PAGE_APPLY_AUTO_ALIGN_ARTICLE_STAMPS';
export const REDACTION_PAGE_SELECT_ALL = 'REDACTION_PAGE_SELECT_ALL';
export const REDACTION_PAGE_APPLY_OCR = 'REDACTION_PAGE_APPLY_OCR';
export const REDACTION_PAGE_APPLY_AUTO_DESKEW = 'REDACTION_PAGE_APPLY_AUTO_DESKEW';
export const REDACTION_HELP_USER_MANUAL = 'REDACTION_HELP_USER_MANUAL';
export const REDACTION_HELP_ABOUT_REDACTION = 'REDACTION_HELP_ABOUT_REDACTION';
export const REDACTION_HELP_KEYBOARD_SHORTCUTS = 'REDACTION_HELP_KEYBOARD_SHORTCUTS';
export const REDACTION_HELP_REDACTION_USER_GUIDE= 'REDACTION_HELP_REDACTION_USER_GUIDE';
export const REDACTION_DOCUMENT_VIEW = 'REDACTION_DOCUMENT_VIEW';
export const REDACTION_PAGE_VIEW = 'REDACTION_PAGE_VIEW';
export const REDACTION_HELP_VIEW = 'REDACTION_HELP_VIEW';
export const REDACTION_FILE_MY_SETTINGS = 'REDACTION_FILE_MY_SETTINGS';
export const REDACTION_FILE_REFRESH_REQUEST = 'REDACTION_FILE_REFRESH_REQUEST';
export const REDACTION_FILE_REQUEST_SEARCH = 'REDACTION_FILE_REQUEST_SEARCH';
export const REDACTION_STAMP_ALIGNMENT = 'REDACTION_STAMP_ALIGNMENT';
export const REDACTION_FILE_CLOSE = 'REDACTION_FILE_CLOSE';
export const REDACTION_ACTION_VIEW = 'REDACTION_ACTION_VIEW';
export const REDACTION_ACTION_DUPLICATE_SEARCH = 'REDACTION_ACTION_DUPLICATE_SEARCH';
export const REDACTION_ACTION_REPORTS = 'REDACTION_ACTION_REPORTS';
export const REDACTION_DOCUMENT_DETAILS = 'REDACTION_DOCUMENT_DETAILS';
export const REDACTION_DOCUMENT_SET_RELEASE_DATE = 'REDACTION_DOCUMENT_SET_RELEASE_DATE';
export const REDACTION_DOCUMENT_CLEAR_RELEASE_DATE = 'REDACTION_DOCUMENT_CLEAR_RELEASE_DATE';
export const REDACTION_PAGE_ROTATE = 'REDACTION_PAGE_ROTATE';
export const REDACTION_PAGE_MOVE = 'REDACTION_PAGE_MOVE';
export const REDACTION_PAGE_MOVE_PREV = 'REDACTION_PAGE_MOVE_PREV';
export const REDACTION_PAGE_MOVE_NEXT = 'REDACTION_PAGE_MOVE_NEXT';
export const REDACTION_PAGE_REORDER_PAGES = 'REDACTION_PAGE_REORDER_PAGES';
export const REDACTION_PAGE_INSERT = 'REDACTION_PAGE_INSERT';
export const REDACTION_PAGE_GO_TO = 'REDACTION_PAGE_GO_TO';
export const REDACTION_PAGE_GO_TO_FIRST = 'REDACTION_PAGE_GO_TO_FIRST';
export const REDACTION_PAGE_GO_TO_LAST = 'REDACTION_PAGE_GO_TO_LAST';
export const REDACTION_PAGE_GO_TO_PREV = 'REDACTION_PAGE_GO_TO_PREV';
export const REDACTION_PAGE_GO_TO_NEXT = 'REDACTION_PAGE_GO_TO_NEXT';
export const REDACTION_ADMIN_PACKAGE_PRESETS_VIEW = 'REDACTION_ADMIN_PACKAGE_PRESETS_VIEW';
export const REDACTION_ADMIN_GLOBAL_SETTINGS_VIEW = 'REDACTION_ADMIN_GLOBAL_SETTINGS_VIEW';
export const REDACTION_ADMIN_AUDIT_LOGS_VIEW = 'REDACTION_ADMIN_AUDIT_LOGS_VIEW';
export const REDACTION_ADMIN_LOCALIZATION_VIEW = 'REDACTION_ADMIN_LOCALIZATION_VIEW';
export const REDACTION_ADMIN_DISCLOSURE_MANAGEMENT_VIEW = 'REDACTION_ADMIN_DISCLOSURE_MANAGEMENT_VIEW';
export const REDACTION_ADMIN_ANNOTATION_TYPE_MANAGEMENT_VIEW = 'REDACTION_ADMIN_ANNOTATION_TYPE_MANAGEMENT_VIEW';
export const REDACTION_ADMIN_USER_DEFINED_STAMP_MANAGEMENT_VIEW = 'REDACTION_ADMIN_USER_DEFINED_STAMP_MANAGEMENT_VIEW';
export const REDACTION_ADMIN_WATERMARK_MANAGEMENT_VIEW = 'REDACTION_ADMIN_WATERMARK_MANAGEMENT_VIEW';
export const REDACTION_ADMIN_ASSOCIATE_WATERMARK_TO_FOLDER_TYPE = 'REDACTION_ADMIN_ASSOCIATE_WATERMARK_TO_FOLDER_TYPE';
export const REDACTION_ADMIN_REQUEST_SECURITY = 'REDACTION_ADMIN_REQUEST_SECURITY';
export const REDACTION_ADMIN_ENCRYPTION = 'REDACTION_ADMIN_ENCRYPTION';
export const REDACTION_ADMIN_DOCUMENT_INDEX_VIEW = 'REDACTION_ADMIN_DOCUMENT_INDEX_VIEW';
export const REDACTION_ADMIN_AUDIT_LOGS_EXPORT_RESULTS = 'REDACTION_ADMIN_AUDIT_LOGS_EXPORT_RESULTS';
export const REDACTION_DOCUMENT_PAGINATION_VIEW = 'REDACTION_DOCUMENT_PAGINATION_VIEW';

export const REDACTION_COMMON_APPLY_CUSTOM_STAMPS = 'REDACTION_COMMON_APPLY_CUSTOM_STAMPS';
export const REDACTION_COMMON_APPLY_EXEMPTION_STAMPS = 'REDACTION_COMMON_APPLY_EXEMPTION_STAMPS';
export const REDACTION_COMMON_APPLY_AUTO_STAMPS = 'REDACTION_COMMON_APPLY_AUTO_STAMPS';
export const REDACTION_COMMON_APPLY_HIGHLIGHTS = 'REDACTION_COMMON_APPLY_HIGHLIGHTS';
export const REDACTION_COMMON_APPLY_SEVERS  = 'REDACTION_COMMON_APPLY_SEVERS';
export const REDACTION_COMMON_ASSIGN_DUPLICATES = 'REDACTION_COMMON_ASSIGN_DUPLICATES';
export const REDACTION_COMMON_CONSULT_TAB_VIEW = 'REDACTION_COMMON_CONSULT_TAB_VIEW';
export const REDACTION_COMMON_CONVERT_HIGHLIGHT_TO_SEVER = 'REDACTION_COMMON_CONVERT_HIGHLIGHT_TO_SEVER';
export const REDACTION_COMMON_INDEX_EDIT = 'REDACTION_COMMON_INDEX_EDIT';
export const REDACTION_COMMON_INDEX_VIEW = 'REDACTION_COMMON_INDEX_VIEW';
export const REDACTION_COMMON_NOTES_TAB_VIEW = 'REDACTION_COMMON_NOTES_TAB_VIEW';
export const REDACTION_COMMON_NOTES_TAB_EDIT = 'REDACTION_COMMON_NOTES_TAB_EDIT';
export const REDACTION_COMMON_RECCOMENDATIONS_TAB_EDIT = 'REDACTION_COMMON_RECCOMENDATIONS_TAB_EDIT';
export const REDACTION_COMMON_RECCOMENDATIONS_TAB_VIEW = 'REDACTION_COMMON_RECCOMENDATIONS_TAB_VIEW';
export const REDACTION_COMMON_VIEW_SEVER_INFO = 'REDACTION_COMMON_VIEW_SEVER_INFO';
export const REDACTION_ADMIN_ERASE_LOGS = 'REDACTION_ADMIN_ERASE_LOGS';

export const ADMIN = 'admin';
export const REDACTION = 'REDACTION';
export const VIEW = 'VIEW';

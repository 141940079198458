import * as React from 'react';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { IProps } from './confirmationDialog.model';
import './confirmationDialog.styles.scss';

const ConfirmationDialog = ({ message }: IProps): JSX.Element => (
    <div className='confirmation-dialog'>
        <div className='confirmation-dialog__icon'>
            <WarningIcon color='secondary' fontSize='large'/>
        </div>
        <div className='confirmation-dialog__message'>
          {
            typeof message === 'string' ?
              <Typography variant='body1' gutterBottom={true}>
                {message}
              </Typography>
              :
              <div>{message}</div>
          }
        </div>
    </div>
);

export default ConfirmationDialog;

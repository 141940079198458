import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
    INavigationProps,
    INavigationState,
    INavigationDispatchProps,
    INavigationStateProps,
} from './navigation.model';
import Tooltip from '@mui/material/Tooltip';
import {
  getCurrentDocument,
  getCurrentPage,
  getCurrentFirstPageId,
  getCurrentLastPageId,
  getCurrentPageNumber,
  getFirstPageId } from '../../redux/selectors/pageList';
import { goToPage, setPageToSelectedList } from '../../redux/actions/pageList';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IState } from '../../redux/store';
import './navigation.styles.scss';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { getPageNumberByPageId } from '../../redux/actions/redactor';
import { getRedactionDocumentId } from '../../redux/selectors/initialization';
import { ALERT_DIALOG_MODAL, PAGENUMBER_INVALID, WARNING } from '../../constants/messages.constants';
import { NOT_APPLICABLE } from '../../constants/common.constants';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { openModalWindow } from '../../redux/actions/modal';
import { setElementSearchDirection } from '../../redux/actions/globalSearch';
import { getSearchElementDirection } from '../../redux/selectors/globalSearch';
import { IPages } from 'redux/reducers/pageList/pageList.model';

const classNames = {
  navigation: 'navigation',
  icon: 'icon',
};

export enum GoToPage {
    NEXT = 'next',
    PREV = 'prev',
    FIRST = 'first',
    LAST = 'last',
}

class Navigation extends React.Component<INavigationProps, INavigationState> {

    constructor(props: INavigationProps) {
        super(props);

        this.state = {
            nextPageButtonActive: false,
            previousPageButtonActive: false,
            firstPageButtonActive: false,
            lastPageButtonActive: false,
            selectedPage: null,
        };
    }

    public componentDidUpdate(prevProps: INavigationProps, prevState: INavigationState): void {

      if(this.props.currentPage && prevProps.currentPage !== this.props.currentPage) {
        const { currentPage: { actualPageNumber }} = this.props;

        this.setState({
          selectedPage: actualPageNumber,
        });
      }
    }

    public render(): JSX.Element {
      if (this.props.currentDocument && this.props.currentPage) {
        const {
          currentDocument: { pages, pageCount },
          currentPage: { id: currentPageId },
          currentFirstPageId,
          currentLastPageId,
        } = this.props;
        const firstPageId = pages.length && pages[0].id;
        const lastPageId = pages.length && pages[pages.length - 1].id;

        return (
          <div className={classNames.navigation}>
            <Tooltip title='Go to First Page' placement='top'>
              <span>
                <IconButton
                  className={classNames.icon}
                  disabled={this.checkButtonsHandler(currentPageId, firstPageId)}
                  aria-label='First Page'
                  onClick={(): void => this.goToPageHandler(GoToPage.FIRST)}
                >
                  <FirstPageIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Go to Previous Page' placement='top'>
              <span>
                <IconButton
                  className={classNames.icon}
                  disabled={this.checkButtonDisable(0, currentFirstPageId)}
                  aria-label='Previous Page'
                  onClick={(): void => this.goToPageHandler(GoToPage.PREV)}
                >
                  <KeyboardArrowLeft />
                </IconButton>
              </span>
            </Tooltip>
            <span className='pageNumber-position'>
              <input
                type='text'
                className='pageNumber-input'
                defaultValue={this.props.currentPage.actualPageNumber ? this.props.currentPage.actualPageNumber : null}
                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>): void => {
                  if (event.key === 'Enter') {
                    this.getCurrentPage();
                  }

                }}
                onBlur={this.getCurrentPage}
                onChange={this.setSelectedValue}
                value={this.state.selectedPage}
              />
            </span>
            <Tooltip title='Go to Next Page' placement='top'>
              <span>
                <IconButton
                  className={classNames.icon}
                  disabled={this.checkButtonDisable(pageCount-1, currentLastPageId)}
                  aria-label='Next Page'
                  onClick={(): void => this.goToPageHandler(GoToPage.NEXT)}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Go to Last Page' placement='top'>
              <span>
                <IconButton
                  className={classNames.icon}
                  disabled={this.checkButtonsHandler(currentPageId, lastPageId)}
                  aria-label='Last Page'
                  onClick={(): void => this.goToPageHandler(GoToPage.LAST)}
                >
                  <LastPageIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        );
      }

      return null;
    }

    private checkButtonsHandler = (pageId: number, currentPageId: number): boolean => {

        return pageId === currentPageId;
    }

    private checkButtonDisable = (checkValue: number, currentPageCheck: number): boolean => {
      const { currentDocument: { pages },
        currentPage: { id: currentPageId },
        currentPageNumber,
      } = this.props;

      if(pages && pages.length) {
        const currentPageIndex = pages.findIndex( (page: IPages): boolean => page.id === currentPageId );

        if((currentPageIndex + (currentPageNumber * 50)) === checkValue) {
          return true;
        } else if ( currentPageCheck && currentPageCheck === currentPageId) {
          return true;
        }

        return false;
      } else {
        return true;
      }
    }

    private setSelectedValue = (e: any): void => {
      if(!isNaN(e.target.value)) {
        this.setState({
          selectedPage: Number(e.target.value),
        });
      }
    }

    private getCurrentPage = (): void => {
      const { currentDocument: { id: currentDocumentId, pageCount }, currentPage: {actualPageNumber} } = this.props;

      if(this.state.selectedPage === actualPageNumber) {
        return;
      }

      if (this.props.currentSearchElementDirection) {
        this.props.setCurrentSearchElementDirection(null);
      }

      if(!this.state.selectedPage) {
        this.setState({
          selectedPage: actualPageNumber,
        });

        return;
      }

      if(this.state.selectedPage > pageCount || this.state.selectedPage < 1) {
        this.props.openModalWindow(ALERT_DIALOG_MODAL, {
          id: NOT_APPLICABLE,
          title: WARNING,
          message: `${PAGENUMBER_INVALID} ${pageCount}`,
        });
        this.setState({
          selectedPage: actualPageNumber,
        });
      } else {
        this.props.getPageNumberByPageId(this.props.redactionDocumentId, currentDocumentId, this.state.selectedPage);
      }
    }

    private goToPageHandler = (direction: string): void => {

      if (this.props.currentSearchElementDirection) {
        this.props.setCurrentSearchElementDirection(null);
      }

      this.props.goToPage(direction);
    }
}

const mapStateToProps = (state: IState): INavigationStateProps => ({
    currentDocument: getCurrentDocument(state),
    currentPage: getCurrentPage(state),
    redactionDocumentId: getRedactionDocumentId(state),
    firstPageId: getFirstPageId(state),
    currentSearchElementDirection: getSearchElementDirection(state),
    currentPageNumber: getCurrentPageNumber(state),
    currentFirstPageId: getCurrentFirstPageId(state),
    currentLastPageId: getCurrentLastPageId(state),
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<IState, INavigationDispatchProps, AnyAction>): INavigationDispatchProps => ({
    goToPage: (act: string): void => {
        dispatch(goToPage(act));
    },
    getPageNumberByPageId: (redactionDocumentId: number, documentId: number, selectedPageId: number,
                            getAnnotationStamps: boolean = false): void => {
      dispatch(getPageNumberByPageId({redactionDocumentId, documentId, selectedPageId, getAnnotationStamps}));
    },
    setPageToSelectedList: (id: number, isMultiple: boolean = false): void => {
      dispatch(setPageToSelectedList(id, isMultiple));
    },
    openModalWindow: (data: string, message: IModalProps): void => {
      dispatch(openModalWindow(data, message));
  },
  setCurrentSearchElementDirection: (direction: string): void => {
    dispatch(setElementSearchDirection(direction));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

import * as React from 'react';
import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Button } from '@mui/material';
import { CANCEL, SAVE, TIMESTAMP, MAX_NOTE_VALUE_LENGTH } from '../../constants/common.constants';
import { METADATA_ERROR } from '../../constants/rightSidebar.constants';
import { usePrevious } from '../../hooks/usePrevious';
import { IMetaDataTabProps } from '../../redux/reducers/pageMetadata/notes.model';
import TextField from '@mui/material/TextField';
import './metaDataTab.styles.scss';
import { useSelector } from 'react-redux';
import { getModifiedLabels } from '../../redux/selectors/localization';
import { getRedactionLanguage } from '../../redux/selectors/localStorage';
import resourceBundle from '../localization/localizationData';
import { initialLabel } from '../../constants/localization.constants';

export const MetaDataTab = (
    {
        data,
        editPermission,
        updateMetaData,
        addMetaData,
        getQueryParam,
        show,
        currentDocument,
        currentPage,
        userMetadata,
    }: IMetaDataTabProps,
): JSX.Element => {
    const [metaDataValue, setMetaDataValue] = useState('');
    const prevMetaData = usePrevious(data);
    const modifiedLabels = useSelector(getModifiedLabels);
    const redactionLang = useSelector(getRedactionLanguage);
    const labels = {
        saveLabel: initialLabel,
        cancelLabel: initialLabel,
        timeStampLabel: initialLabel,
        metaDataTabErrorLabel: initialLabel,
    };
    const langRule = redactionLang ? redactionLang === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';

    resourceBundle.map((resource: any) => {
        switch(resource.resourceKey) {
            case 'COMMON_LABEL_SAVE':
                labels.saveLabel = resource;
                break;
            case 'COMMON_LABEL_CANCEL':
                labels.cancelLabel = resource;
                break;
            case 'METADATA_TAB_TIMESTAMP':
                labels.timeStampLabel = resource;
                break;
            case 'METADATA_TAB_ERROR':
                labels.metaDataTabErrorLabel = resource;
                break;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        switch(resource.resourceKey) {
            case 'COMMON_LABEL_SAVE':
                labels.saveLabel = resource;
                break;
            case 'COMMON_LABEL_CANCEL':
                labels.cancelLabel = resource;
                break;
            case 'METADATA_TAB_TIMESTAMP':
                labels.timeStampLabel = resource;
                break;
            case 'METADATA_TAB_ERROR':
                labels.metaDataTabErrorLabel = resource;
                break;
        }

        return resource;
    });

    useEffect(() => {
        if (!isEqual(prevMetaData, data)) {
            setMetaDataValue(data && data.dataType === getQueryParam ? data.text : '');
        } else if (!data && !metaDataValue) {
            setMetaDataValue('');
        }
    });

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setMetaDataValue(e.target.value);
    };

    const addTimestamp = (): void => {
        const date = new Date();
        const currentDate = date.toLocaleDateString('en-ZA').replace(/-/g, '/');
        const currentTime = date.toLocaleTimeString('eu-ES');

        const timestamp = ` [ ${userMetadata.userId}, ${currentDate}, ${currentTime} ] `;

        setMetaDataValue(metaDataValue + timestamp);
    };

    const saveMetaData = (): void => {
        const dataToSave = {
            dataType: getQueryParam,
            text: metaDataValue,
        };

        if (metaDataValue && !data) {
            addMetaData(dataToSave);
        } else if(data) {
            updateMetaData({ id: data.id, ...dataToSave });
        }
    };

    const cancel = (): void => {
        setMetaDataValue(data && data.text || '');
    };

    const isDisabled = !(currentDocument && currentPage && currentDocument.id && currentPage.id) || !editPermission;

    return (
        <React.Fragment>
            { show && <div className='metaData-tab tab-content'>
                 <div className='content-wrapper'>
                     <TextField
                         onChange={handleChange}
                         value={metaDataValue}
                         disabled={isDisabled}
                         id='outlined-dense-multiline'
                         variant='outlined'
                         fullWidth={true}
                         multiline={true}
                         sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '100%',
                            },
                            '& textarea': {
                                height: '100% !important',
                                overflowY: 'auto !important',
                            },
                        }}
        
                     />
                     {metaDataValue && metaDataValue.length > MAX_NOTE_VALUE_LENGTH &&
                     <span className='metaData-tab_error'>{labels.metaDataTabErrorLabel[langRule]}</span>}
                 </div>
                 <div className='metaData-tab_buttons'>
                     <Button
                         variant='contained'
                         className='primary bottom-bar_buttons'
                         color='primary'
                         size='small'
                         onClick={saveMetaData}
                         disabled={isDisabled}
                     >
                         {labels.saveLabel[langRule]}
                     </Button>
                     <Button
                         variant='outlined'
                         className='modal-window__buttons outlined left_margin'
                         size='small'
                         onClick={cancel}
                         disabled={isDisabled}
                     >
                         {labels.cancelLabel[langRule]}
                     </Button>
                     <Button
                         variant='contained'
                         className='primary bottom-bar_buttons left_margin'
                         color='primary'
                         size='small'
                         onClick={addTimestamp}
                         disabled={isDisabled}
                     >
                         {labels.timeStampLabel[langRule]}
                     </Button>
                 </div>
            </div> }
        </React.Fragment>
    );
};

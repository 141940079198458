import {
    FETCH_DOCUMENT_INDEX_PENDING,
    FETCH_DOCUMENT_INDEX_FAIL,
    FETCH_DOCUMENT_INDEX_SUCCESS,
    SET_DOCUMENT_INDEX_VALUE,
    CLEAR_DOCUMENT_INDEX,
} from '../reducers/documentIndex/constant';
import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import { IState } from '../store';
import { IIndex, IIndexValue } from '../reducers/indexMetadata/indexMetadata.model';
import { mergeIndexArrayWithMetadata, prepareIndexDataToSave } from '../../utils/indexMetadata';
import { addError } from './errorHandling';
import { IError } from '../common.model';
import { updateDocumentList } from './documentList';
import { ThunkDispatch } from 'redux-thunk/es/types';

export const fetchDocumentIndexPending = (): AnyAction => action(FETCH_DOCUMENT_INDEX_PENDING);
export const fetchDocumentIndexSuccess = (documentIndex: IIndex[]): AnyAction =>
    action(FETCH_DOCUMENT_INDEX_SUCCESS, documentIndex);
export const fetchDocumentIndexFailure = (error: IError): AnyAction => action(FETCH_DOCUMENT_INDEX_FAIL, error);
export const setDocumentIndexValue = (id: number, value: IIndexValue): AnyAction =>
    action(SET_DOCUMENT_INDEX_VALUE, { id, value });
export const clearDocumentIndex = (): AnyAction => action(CLEAR_DOCUMENT_INDEX);

export const saveDocumentIndex = (indexTabSave?: boolean): (
    dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            documentIndex: {
                documentIndex,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument: {
                    id: documentId,
                },
            },
            indexMetadata: {
                metadata,
            },
        } = getState();

        try {
            const data = prepareIndexDataToSave(documentIndex);

            const response = indexTabSave
                ? await api.documentIndex.saveDocumentIndex(redactionDocumentId, documentId, data)
                : await api.documentIndex.getDocumentIndex(redactionDocumentId, documentId);

            dispatch(fetchDocumentIndexSuccess(mergeIndexArrayWithMetadata(response, metadata)));
            dispatch(updateDocumentList(redactionDocumentId, documentId));
        } catch (error) {
            dispatch(addError(error));
        }
    };

import * as React from 'react';
import { connect } from 'react-redux';
import { uniq, isEqual, pull, difference, compact } from 'lodash';
import { getArticleStamps } from '../../../redux/selectors/redactor';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'rc-scrollbars';
import './modalArticleStamps.styles.scss';
import {
    IModalArticleState,
    IModalArticleProps,
    IModalArticleStateProps,
    IModalArticleDispatchProps,
    IArticleModalSpecificData,
} from './modalArticle.model';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
    ARTICLE,
    DESCRIPTION,
} from './modalArticle.constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import {
    IValidExemptionDtoList,
    IArticleStamp,
} from '../../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import Button from '@mui/material/Button';
import { setSelectedArticleStamps } from '../../../redux/actions/layoutRedactorTypes';
import { handleCloseAllModalWindows, openModalWindow } from '../../../redux/actions/modal';
import { getSelectedArticleStamps } from '../../../redux/selectors/layoutRedactorTypes';
import { ModalArticleStampsSelect } from './modalArticleStampsSelect';
import * as constants from './modalArticle.constants';
import { compareNumbers } from '../../../utils/array.utils';
import { changePageDisclosureWithAdditionalData } from '../../../redux/actions/pageList';
import { IState } from '../../../redux/store';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { SELECT_CONSTANT_MODAL } from '../index';
import { setRecentlyUsedExemptions, setRecentlyUsedStamps } from '../../../redux/actions/localStorage';
import { replaceDocumentsDisclosureWithAdditionalData } from '../../../redux/actions/disclosureTypes';
import { IAssignedContactsDto } from '../../../redux/reducers/disclosureContacts/disclosureContacts.model';
import { getRecentlyUsedExemptions } from '../../../redux/selectors/localStorage';
import { getRedactionRequestNumber } from '../../../redux/selectors/initialization';
import { SelectChangeEvent } from '@mui/material';

const className = {
    COLOR_CHECKBOX: 'color-checkbox',
};

export class ModalArticleStamps extends React.Component<IModalArticleProps, IModalArticleState> {
    constructor(props: IModalArticleProps) {
        super(props);
        this.state = {
            selectedStamps: [],
            stampsFilter: constants.RESET_VALUE,
        };
    }

    public componentDidMount(): void {
        if (!this.isExemptionRequired()) {
            this.setState({ selectedStamps: this.props.lastSelectedExemptions });
        }

    }
    public componentDidUpdate(prevProps: IModalArticleProps): void {
        if (!isEqual(prevProps.selectedArticleStamps, this.props.selectedArticleStamps)) {
            this.setState({ selectedStamps: this.props.selectedArticleStamps });
        }

    }

    public render(): JSX.Element {
        const { articleStampsList, handleCloseModalWindow, requestNumber } = this.props;
        const { stampsFilter } = this.state;
        const isAnyStampsSelected = !!this.state.selectedStamps.length;

        return (
            <div className='modal-article-stamps' >
                <ModalArticleStampsSelect
                    label={constants.FILTER_BY_TYPE}
                    value={stampsFilter}
                    options={[constants.RESET_VALUE, ...this.filterTypes]}
                    fullWidth={true}
                    onChangeHandler={(e: SelectChangeEvent<string>): void => this.filterByTypeHandler(e)}
                />
                <AutoSizer>
                    {({ width, height }: { width: number; height: number; }): JSX.Element => (
                        <div className='full-width indents-article' style={{ width, height }}>
                            <div className='table-article-stamps'>
                                <div className='header-list'>
                                    <div className='article-stamps'>
                                        <Checkbox
                                            onClick={(event: React.MouseEvent): void => {
                                                this.handlerAllCheck(event);
                                            }}
                                            checked={
                                                stampsFilter === constants.RESET_VALUE ?
                                                    this.isMainChecked() :
                                                    this.isCategoryChecked()
                                            }
                                            className='main-check'
                                        />
                                        <div className='article'>{ARTICLE}</div>
                                    </div>
                                    <div className='description'>{DESCRIPTION}</div>
                                </div>
                                <Scrollbars
                                    style={{ width, height }}
                                >
                                    <div className='stamp-list'>
                                        {
                                            this.getFilteredStampsList(
                                                articleStampsList, stampsFilter).map((stamp: IArticleStamp) => (
                                                    !!stamp.validExemptionDtoList.length &&
                                                    <Accordion
                                                        className='collapsed'
                                                        key={stamp.validExemptionGroupDto.exemptionGroupCode + 1}
                                                        defaultExpanded={!!stamp.validExemptionDtoList.length}
                                                    >
                                                        <AccordionSummary
                                                            className='name-groups'
                                                            expandIcon={<ExpandMoreIcon />}
                                                        >
                                                            <div className='desc-group'>
                                                                {stamp.validExemptionGroupDto.exemptionGroupDesc}</div>
                                                        </AccordionSummary>
                                                        <AccordionDetails className='panel-del'>
                                                            {
                                                                stamp.validExemptionDtoList.map(
                                                                    (exemption: IValidExemptionDtoList) => (
                                                                        <div
                                                                            key={exemption.exemptionCode}
                                                                            className='item'
                                                                        >
                                                                            <div className='checkBox-name'>
                                                                                <Checkbox
                                                                                    checked={this.isChecked(exemption)}
                                                                                    onChange={(): void => {
                                                                                        this.onChange(exemption);
                                                                                    }}
                                                                                    name={`${exemption.exemptionCode}`}
                                                                                    className={className.COLOR_CHECKBOX}
                                                                                />
                                                                                <div className='name'>
                                                                                    {exemption.exemptionLabel}</div>
                                                                            </div>
                                                                            <div className='item-description'>
                                                                                {exemption.exemptionDesc}</div>
                                                                        </div>
                                                                    ))
                                                            }
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ),
                                                )}
                                    </div>
                                </Scrollbars>
                                {/*TODO need refactoring*/}
                                <div className='form-article-stamps__action'>
                                    <div>
                                        <Button
                                            size='small'
                                            variant='outlined'
                                            onClick={handleCloseModalWindow}
                                            className='buttons-style outlined'
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size='small'
                                            variant='contained'
                                            color='primary'
                                            onClick={this.onSubmit}
                                            className='buttons-style primary'
                                            disabled={this.isExemptionRequired() && !isAnyStampsSelected}
                                        >
                                            Ok
                                        </Button>
                                    </div>
                                    {
                                        !this.isExemptionRequired() &&
                                        <div>
                                            <Button
                                                size='small'
                                                variant='contained'
                                                color='primary'
                                                onClick={(): void => {
                                                    this.setState({ selectedStamps: [] });
                                                }}
                                                className='primary'
                                                disabled={!isAnyStampsSelected}
                                            >
                                             Clear
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    )}
                </AutoSizer>
            </div>
        );
    }

    private isChecked(exemption: IValidExemptionDtoList): boolean {
        return this.state.selectedStamps.some(
            (selectedStamp: IValidExemptionDtoList): boolean =>
                selectedStamp.exemptionCode === exemption.exemptionCode,
        );
    }

    private get filterTypes(): string[] {
        return this.props.articleStampsList.reduce((result: string[], current: IArticleStamp) => {
            if (current.validExemptionDtoList.length) {
                return [...result, current.validExemptionGroupDto.exemptionGroupDesc];
            }

            return result;
        }, []);
    }

    private get allIds(): IValidExemptionDtoList[] {
        const { articleStampsList } = this.props;
        const { stampsFilter } = this.state;
        const stampsList = this.getFilteredStampsList(articleStampsList, stampsFilter);

        return stampsList.reduce((result: IValidExemptionDtoList[], current: IArticleStamp): IValidExemptionDtoList[] =>
            [...result, ...current.validExemptionDtoList], [])
            .sort(
                (a: IValidExemptionDtoList, b: IValidExemptionDtoList): number => b.exemptionCode - a.exemptionCode,
            );
    }

    private isMainChecked = (): boolean => isEqual(
        this.allIds,
        [...this.state.selectedStamps].sort(
            (a: IValidExemptionDtoList, b: IValidExemptionDtoList): number => b.exemptionCode - a.exemptionCode,
        ),
    )

    private isCategoryChecked = (): boolean => {
        const { selectedStamps } = this.state;

        return !difference(this.allIds, [...selectedStamps]).sort(
            (a: IValidExemptionDtoList, b: IValidExemptionDtoList): number => b.exemptionCode - a.exemptionCode,
        ).length;
    }

    private handlerAllCheck = (e: React.MouseEvent): void => {
        const checked = (e.target as HTMLInputElement).checked;
        const { selectedStamps, stampsFilter } = this.state;
        let newSelectedStamps;

        if (stampsFilter === constants.RESET_VALUE) {
            newSelectedStamps = checked ? [...this.allIds] : [];
        } else {
            newSelectedStamps = checked ?
                uniq([...selectedStamps, ...this.allIds]).sort(compareNumbers)
                :
                pull(selectedStamps, ...this.allIds);
        }

        this.setState({
            selectedStamps: newSelectedStamps,
        });
    }

    private filterByTypeHandler = (e: SelectChangeEvent<string>): void => {
        const { value } = e.target;
    
        this.setState({ stampsFilter: value });
    }

    private getFilteredStampsList = (stampsList: IArticleStamp[], selectedFilter: string): IArticleStamp[] => {
        if (selectedFilter === constants.RESET_VALUE) {
            return stampsList;
        }

        return stampsList.filter(
            (stamps: IArticleStamp) =>
                stamps.validExemptionGroupDto.exemptionGroupDesc === this.state.stampsFilter && stamps);
    }

    private onChange = (exemption: IValidExemptionDtoList): void => {
        const exists = this.state.selectedStamps.some(
            (selectedStamp: IValidExemptionDtoList): boolean => exemption.exemptionCode === selectedStamp.exemptionCode,
        );

        this.setState((prev: IModalArticleState): IModalArticleState => {
            return {
                ...prev,
                selectedStamps: exists ?
                [...prev.selectedStamps.filter(
                    (el: IValidExemptionDtoList) => el.exemptionCode !== exemption.exemptionCode,
                )] :
                [...prev.selectedStamps, exemption],
            };
        });

    }
    private isExemptionRequired = (): boolean => {
        const isExemptionRequired = this.props.modalSpecificProps && !!Object.keys(this.props.modalSpecificProps).length
            ? this.props.modalSpecificProps.setRequiredExemptions
            : false;

        return isExemptionRequired;

    }

    private onSubmit = (): void => {
        const {
            modalSpecificProps,
            handleCloseModalWindow,
            openModalWindow: openModal,
            changePageDisclosureWithAdditionalData: changePageDisclosure,
            setSelectedArticleStamps: setArticles,
            replacesDisclosure,
            setRecentlyUsedExemptions: setExemptions,
            requestNumber,
            setRecentlyUsedStamps: setStamps,
        } = this.props;

        if (modalSpecificProps && modalSpecificProps.isContactsRequired && modalSpecificProps.setRequiredExemptions
            && modalSpecificProps.needReplace) {
            // document level
            const { articleModalSpecificData: {
                disclosureTypeId,
                documentId,
                fromId,
                redactionDocumentId,
                pageIds,
            },
            } = modalSpecificProps;

            handleCloseModalWindow();
            openModal(SELECT_CONSTANT_MODAL, {
                id: SELECT_CONSTANT_MODAL,
                modalSpecificProps: {
                    redactionDocumentId,
                    documentId,
                    disclosureTypeId,
                    pageIds,
                    needReplace: true,
                    fromId,
                    exemptionIds: this.state.selectedStamps
                        .map((selectedStamp: IValidExemptionDtoList): number => selectedStamp.exemptionCode),
                },
            });

            return;
        }

        if (modalSpecificProps && modalSpecificProps.setRequiredExemptions && modalSpecificProps.needReplace) {
            // document level
            const exemptionIds = this.state.selectedStamps
                .map((selectedStamp: IValidExemptionDtoList): number => selectedStamp.exemptionCode);
            const { fromId, disclosureTypeId } = modalSpecificProps.articleModalSpecificData;

            replacesDisclosure(fromId, disclosureTypeId, [], exemptionIds);

            return;
        }

        if (modalSpecificProps && modalSpecificProps.isContactsRequired && modalSpecificProps.setRequiredExemptions) {
            // document and page level
            const { articleModalSpecificData: {
                redactionDocumentId,
                documentId,
                disclosureTypeId,
                pageIds,
            },
            } = modalSpecificProps;

            handleCloseModalWindow();
            openModal(SELECT_CONSTANT_MODAL, {
                id: SELECT_CONSTANT_MODAL,
                modalSpecificProps: {
                    redactionDocumentId,
                    documentId,
                    disclosureTypeId,
                    pageIds,
                    selectedStamps: this.state.selectedStamps
                        .map((selectedStamp: IValidExemptionDtoList): number => selectedStamp.exemptionCode),
                },
            });

            return;
        }

        if (modalSpecificProps && modalSpecificProps.setRequiredExemptions) {
            // document and page level
            changePageDisclosure(
                this.state.selectedStamps.map((selectedStamp: IValidExemptionDtoList): number =>
                    selectedStamp.exemptionCode),
                modalSpecificProps.articleModalSpecificData,
            );

            return;
        }

        setArticles(this.state.selectedStamps);
        setStamps(requestNumber, this.state.selectedStamps);
        setExemptions(requestNumber, this.state.selectedStamps);
    }
}

const mapStateToProps = (state: IState): IModalArticleStateProps => ({
    articleStampsList: getArticleStamps(state),
    selectedArticleStamps: getSelectedArticleStamps(state),
    lastSelectedExemptions: getRecentlyUsedExemptions(state),
    requestNumber: getRedactionRequestNumber(state),
});

const mapDispatchToProps = (dispatch: any): IModalArticleDispatchProps => ({
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    setSelectedArticleStamps: (selectedStamps: IValidExemptionDtoList[]): void => {
        dispatch(setSelectedArticleStamps(selectedStamps));
    },
    setRecentlyUsedStamps: (requestNumber: string, selectedStamps: IValidExemptionDtoList[]): void => {
        dispatch(setRecentlyUsedStamps(requestNumber, selectedStamps));
    },
    changePageDisclosureWithAdditionalData:
        (selectedStamps: number[], articleModalSpecificData: IArticleModalSpecificData): void => {
            dispatch(changePageDisclosureWithAdditionalData(selectedStamps, articleModalSpecificData, []));
        },
    openModalWindow: (type: string, modalProps: IModalProps): void => {
        dispatch(openModalWindow(type, modalProps));
    },
    replacesDisclosure: (
        fromId: number, toId: number, pageContacts: IAssignedContactsDto[], exemptionIds: number[]): void => {
        dispatch(replaceDocumentsDisclosureWithAdditionalData(fromId, toId, pageContacts, exemptionIds));
    },
    setRecentlyUsedExemptions: (requestNumber: string, exemptions: IValidExemptionDtoList[]): void => {
        dispatch(setRecentlyUsedExemptions(requestNumber, exemptions));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalArticleStamps);

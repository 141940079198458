import { AnyAction, Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { DOCUMENT_IMPORT_TITLE, IMPORT, PAGE_INSERT_TITLE } from '../../constants/messages.constants';
import { handleCloseAllModalWindows, openModalWindow } from './modal';
import {
    IAdditionalImportParam,
    IInsertPageOptionsFromFile,
    IInsertPageOptionsFromRedaction,
    IUploadAttachmentDto,
} from '../../containers/modalWindowContents/modalWindowImport/modalWindowImport.model';
import api from '../../api/reductionApi';
import { fetchDocumentMetadata,changeDisclosure } from './pageList';
import { addError } from './errorHandling';
import { action } from 'typesafe-actions';


import {
    UPLOAD_FILES_PENDING,
    UPLOAD_FILES_FAILURE,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_PENDING,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_REDACTION_DOCUMENTS_PENDING,
    UPLOAD_REDACTION_DOCUMENTS_SUCCESS,
    UPLOAD_REDACTION_DOCUMENTS_FAILURE,
    UPLOAD_FILES_SUCCESS,
} from '../reducers/modalWindowImport/constant';
import { IError } from '../common.model';
import { restartDocumentTasksQueue } from './taskQueue';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IState } from '../store';
import {
    IEdmsFileDto,
    IUploadRedactionDocumentsDto,
} from '../../containers/modalWindowContents/modalSearchDocument/modalSearchDocument.model';
import { initialLabel } from '../../constants/localization.constants';
import resourceBundle from '../../containers/localization/localizationData';
import { changeLang } from './localization';

export const uploadDocumentPending = (): AnyAction => action(UPLOAD_DOCUMENT_PENDING);
export const uploadDocumentSuccess = (): AnyAction => action(UPLOAD_DOCUMENT_SUCCESS);
export const uploadDocumentFailure = (): AnyAction => action(UPLOAD_DOCUMENT_FAILURE);

export const uploadFilesPending = (): AnyAction => action(UPLOAD_FILES_PENDING);
export const uploadFilesSuccess = (): AnyAction => action(UPLOAD_FILES_SUCCESS);
export const uploadFilesFailure = (error: IError): AnyAction =>
    action(UPLOAD_FILES_FAILURE, error);
export const uploadRedactionDocumentPending = (): AnyAction => action(UPLOAD_REDACTION_DOCUMENTS_PENDING);
export const uploadRedactionDocumentSuccess = (): AnyAction => action(UPLOAD_REDACTION_DOCUMENTS_SUCCESS);
export const uploadRedactionDocumentFailure = (error: IError): AnyAction =>
    action(UPLOAD_REDACTION_DOCUMENTS_FAILURE, error);

export const callModalImport = (): (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const { localization: { modifiedLabels }, localStorage: {language} } = getState();
        const labels = { docImportTitle: initialLabel };

        resourceBundle.map((resource: any) => {
            if (getImportTitleByKey(resource.resourceKey)) {
                labels[getImportTitleByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getImportTitleByKey(resource.resourceKey)) {
                labels[getImportTitleByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        dispatch(openModalWindow(IMPORT, {
            id: IMPORT,
            title: labels.docImportTitle[changeLang(language)],
            modalSpecificProps: {
                isInsertPage: false,
            },
        }));
    };

export const callModalInsertPage = (): (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const { localization: { modifiedLabels }, localStorage: {language} } = getState();
        const labels = { pageInsertTitle: initialLabel };

        resourceBundle.map((resource: any) => {
            if (getImportTitleByKey(resource.resourceKey)) {
                labels[getImportTitleByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getImportTitleByKey(resource.resourceKey)) {
                labels[getImportTitleByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        dispatch(openModalWindow(IMPORT, {
            id: IMPORT,
            title: labels.pageInsertTitle[changeLang(language)],
            modalSpecificProps: {
                isInsertPage: true,
            },
        }));
    };

export const fetchFiles = (
    files: File[],
    param: IAdditionalImportParam,
    redactionDocumentId: number,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {

        dispatch(uploadDocumentPending());

        const data = new FormData();
        const { ocrRequired, autoDeskew, stackId, fromScanner } = param;

        data.append('ocrRequired', JSON.stringify(ocrRequired));
        data.append('autoDeskew', JSON.stringify(autoDeskew));
        data.append('stackId', JSON.stringify(stackId));
        data.append('fromScanner', JSON.stringify(fromScanner));

        files.forEach((file: File) => {
            data.append('files', file, file.name);
        });
        try {
            const response = await api.redactionDocuments.uploadDocuments(redactionDocumentId, data);
            const { processingDocumentList, errorMessage } = response;

            if (errorMessage) {
                dispatch(addError({
                    id: uuidv4(),
                    message: errorMessage,
                }));
            }

            dispatch(uploadDocumentSuccess());
        } catch (error) {
            dispatch(uploadDocumentFailure());
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };


export const fetchEDMSFiles = (
    edmsFiles: IEdmsFileDto[],
    param: IAdditionalImportParam,
    redactionDocumentId: number,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {

        dispatch(uploadDocumentPending());

        const data = new FormData();
        const { ocrRequired, autoDeskew, stackId, fromScanner } = param;

        try {

            const response = await api.edmsController.uploadEdmsDocuments(redactionDocumentId,
                ocrRequired, autoDeskew, stackId, false, edmsFiles);
            const { processingDocumentList, errorMessage } = response;

            if (errorMessage) {
                dispatch(addError({
                    id: uuidv4(),
                    message: errorMessage,
                }));
            }

            dispatch(uploadDocumentSuccess());
        } catch (error) {
            dispatch(uploadDocumentFailure());
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

export const fetchRedactionDocuments = (
    redactionDocumentId: number,
    ocrRequired: boolean,
    autoDeskew: boolean,
    stackId: number,
    uploadRedactionDocumentsDto: IUploadRedactionDocumentsDto,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {

        dispatch(uploadDocumentPending());

        try {
            const response = await api.redactionDocuments.uploadRedactionDocuments(
                redactionDocumentId,
                ocrRequired,
                autoDeskew,
                stackId,
                uploadRedactionDocumentsDto,
            );
            const { errorMessage } = response;

            if (errorMessage) {
                dispatch(addError({
                    id: uuidv4(),
                    message: errorMessage,
                }));
            }

            dispatch(uploadDocumentSuccess());
        } catch (error) {
            dispatch(uploadDocumentFailure());
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

export const importDocumentFromAttachments = (
    redactionDocumentId: number,
    ocrRequired: boolean,
    autoDeskew: boolean,
    stackId: number,
    uploadAttachmentDocumentsDto: IUploadAttachmentDto,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {

        dispatch(uploadDocumentPending());

        try {
            const response = await api.redactionDocuments.uploadAttachmentDocuments(
                redactionDocumentId,
                ocrRequired,
                autoDeskew,
                stackId,
                uploadAttachmentDocumentsDto,
            );
            const { errorMessage } = response;

            if (errorMessage) {
                dispatch(addError({
                    id: uuidv4(),
                    message: errorMessage,
                }));
            }

            dispatch(uploadDocumentSuccess());
            dispatch(handleCloseAllModalWindows());
        } catch (error) {
            dispatch(uploadDocumentFailure());
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

export const insertRedactionDocuments = (
    redactionDocumentId: number,
    documentId: number,
    pageId: number,
    insertPageOptions: IInsertPageOptionsFromRedaction,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {

        dispatch(uploadRedactionDocumentPending());

        try {
            const response = await api.pages.insertPagesFromRedaction(
                redactionDocumentId,
                documentId,
                pageId,
                insertPageOptions,
            );

            const {errorMessage} = response;

            if (errorMessage) {
                dispatch(addError({
                    id: uuidv4(),
                    message: errorMessage,
                }));
            }

            dispatch(fetchDocumentMetadata(redactionDocumentId, documentId));
            dispatch(uploadRedactionDocumentSuccess());
            dispatch(handleCloseAllModalWindows());
        } catch (error) {
            dispatch(uploadRedactionDocumentFailure(error));
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

export const insertFiles = (
    redactionDocumentId: number,
    documentId: number,
    pageId: number,
    files: File[],
    insertPageOptions: IInsertPageOptionsFromFile,
    assignToDisclosureTypeId: number,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {

        dispatch(uploadFilesPending());

        const data = new FormData();

        data.append('insertPageOptions', JSON.stringify(insertPageOptions));
        files.forEach((file: File) => {
            data.append('files', file, file.name);
        });

        try {
            const response = await api.pages.insertPagesFromFiles(
                redactionDocumentId,
                documentId,
                pageId,
                data,
            );

            const {errorMessage} = response;

            if (errorMessage) {
                dispatch(addError({
                    id: uuidv4(),
                    message: errorMessage,
                }));
            }

            if (!!assignToDisclosureTypeId) {
                dispatch(changeDisclosure({
                    pageIds:[response.pages[0].id],
                    documentIds:[documentId],
                    redactionDocumentId,
                    disclosureTypeId: assignToDisclosureTypeId,
                }));
            }

            dispatch(fetchDocumentMetadata(redactionDocumentId, documentId));
            dispatch(uploadFilesSuccess());
        } catch (error) {
            dispatch(uploadFilesFailure(error));
            dispatch(addError(error));
        }
        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

export const getImportTitleByKey = (key: string): string => {
    switch(key) {
        case 'MODAL_IMPORT_PAGE_TITLE':
            return 'pageInsertTitle';
        case 'MODAL_IMPORT_DOCUMENT_TITLE':
            return 'docImportTitle';
        default: return '';
    }
};

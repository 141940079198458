import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import {
    GET_OFFICER_PENDING,
    GET_OFFICER_SUCCESS,
    GET_OFFICER_FAILURE,
    POST_OFFICER_PENDING,
    POST_OFFICER_SUCCESS,
    POST_OFFICER_FAILURE,
} from '../reducers/reportsOfficer/constants';
import {
    OFFICER_ALL_DOCUMENTS,
    OFFICER,
} from '../../constants/officer.constants';
import { action } from 'typesafe-actions';
import { fileSaver } from '../../utils/fileSaver';
import { IError } from '../common.model';
import { IModalIdsData } from '../reducers/modal/modal.model';
import { getItem } from '../../utils/localStorage.util';
import { handleCloseModalWindow, openModalWindow } from './modal';
import { getReportLanguageModalLabels, REPORT_LANG_SELECTION_MODAL, REPORT_LANG_SELECTION_TITLE } from '../../constants/modalReportLangSelection.constants';

export const fetchOfficerPending = (): AnyAction => action(GET_OFFICER_PENDING);
export const fetchOfficerSuccess = (dataList: number): AnyAction =>
    action(GET_OFFICER_SUCCESS, dataList);
export const fetchOfficerFailure = (error: IError): AnyAction => action(GET_OFFICER_FAILURE, error);

export const postOfficerPending = (): AnyAction => action(POST_OFFICER_PENDING);
export const postOfficerSuccess = (dataList: number): AnyAction =>
    action(POST_OFFICER_SUCCESS, dataList);
export const postOfficerFailure = (error: IError): AnyAction => action(POST_OFFICER_FAILURE, error);

// TODO investigate how to remove any from Dispatch type

export const fetchReportOfficer = ({documentIds, redactionDocumentId }: IModalIdsData): (dispatch: Dispatch<any>) =>
    void => (dispatch: Dispatch<any>): void  => {
        dispatch(openModalWindow(REPORT_LANG_SELECTION_MODAL, {
            id: REPORT_LANG_SELECTION_MODAL,
            title: getReportLanguageModalLabels(REPORT_LANG_SELECTION_TITLE),
            okButtonTitle: getReportLanguageModalLabels('Ok'),
            cancelButtonTitle: getReportLanguageModalLabels('Cancel'),
            confirm: (): () => void => (): void => {
                const reportLang = getItem("reportLang");
                if (redactionDocumentId && !documentIds.length) {
                    dispatch(fetchReportOfficerAllDocuments( redactionDocumentId, reportLang ));
                } else {
                    dispatch(fetchReportOfficerById( redactionDocumentId, documentIds, reportLang));
                }
                dispatch(handleCloseModalWindow(REPORT_LANG_SELECTION_MODAL))
            },
            reject: (): () => void => (): void => {
                dispatch(handleCloseModalWindow(REPORT_LANG_SELECTION_MODAL))
            },
        }));
    };

export const fetchReportOfficerAllDocuments = (redactionDocumentId: number, reportLang: string):
    (dispatch: Dispatch) => Promise<void> => async (dispatch: Dispatch): Promise<void> => {

    dispatch(fetchOfficerPending());

    try {
        const dataReportOfficer = await api.reportController.
        getReportOfficer(redactionDocumentId, reportLang);

        dispatch(fetchOfficerSuccess(dataReportOfficer));

        fileSaver(dataReportOfficer, OFFICER_ALL_DOCUMENTS);

    } catch (error) {
        dispatch(fetchOfficerFailure(error));
    }
};

export const fetchReportOfficerById = (
    redactionDocumentId: number,
    documentIds: number[], reportLang: string
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(postOfficerPending());

        try {
            const dataRequesterReport = await api.reportController.postReportOfficer(
                redactionDocumentId,
                documentIds,
                reportLang
            );

            dispatch(postOfficerSuccess(dataRequesterReport));

            fileSaver(dataRequesterReport, OFFICER);

        } catch (error) {
            dispatch(postOfficerFailure(error));
        }
    };

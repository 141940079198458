import { IState } from '../store';
import { createSelector } from 'reselect';
import { IPagesReviewSummaryData } from 'redux/reducers/modalPageReviewSummary/pagesReviewSummary.model';

export const pagesReviewSummary = (state: IState): IPagesReviewSummaryData => state.modalPageReviewSummary;

export const pagesReviewSummaryPending = createSelector(
    [ pagesReviewSummary ],
    (layout: IPagesReviewSummaryData): boolean => layout.pagesReviewSummaryPending,
);

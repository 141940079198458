import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { IError } from '../common.model';
import { handleCloseModalWindow, openModalWindow } from './modal';
import {
    ALERT_DIALOG_MODAL,
    WARNING,
} from '../../constants/messages.constants';
import {
    DELETE_STACK_FAILURE,
    DELETE_STACK_PENDING,
    DELETE_STACK_SUCCESS,
    GET_STACK_LIST_FAILURE,
    GET_STACK_LIST_PENDING,
    GET_STACK_LIST_SUCCESS,
    POST_STACK_FAILURE,
    POST_STACK_PENDING,
    POST_STACK_SUCCESS,
    PUT_STACK_PENDING,
    PUT_STACK_SUCCESS,
    PUT_STACK_FAILURE,
    CHANGE_OPTIONS_STACK,
    CLEAR_OPTIONS_STACK,
    SET_OPTIONS_STACK,
    GET_STACK_PROPERTY_LIST_PENDING,
    GET_STACK_PROPERTY_LIST_SUCCESS,
    GET_STACK_PROPERTY_LIST_FAILURE,
    DELETE_STACK_PROPERTY_PENDING,
    DELETE_STACK_PROPERTY_SUCCESS,
    DELETE_STACK_PROPERTY_FAILURE,
    POST_STACK_PROPERTY_PENDING,
    POST_STACK_PROPERTY_SUCCESS,
    POST_STACK_PROPERTY_FAILURE,
    PUT_STACK_PROPERTY_PENDING,
    PUT_STACK_PROPERTY_SUCCESS,
    PUT_STACK_PROPERTY_FAILURE,
    CHANGE_OPTIONS_STACK_PROPERTY,
    SET_OPTIONS_STACK_PROPERTY,
    CLEAR_OPTIONS_STACK_PROPERTY,
    UPDATE_STACK_DOCUMENT_RELATION_PENDING,
    UPDATE_STACK_DOCUMENT_RELATION_SUCCESS,
    UPDATE_STACK_DOCUMENT_RELATION_FAILURE,
} from '../reducers/stackList/constants';
import { IStackDocumentsDto, IStackOptions, IStackPropertyOptions } from '../reducers/stackList/stackList.model';
import { IState } from '../store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import {
    DOCUMENT_STACK_MODAL,
    DOCUMENT_STACK_TITLE_MODAL,
    DOCUMENT_STACK_WARNING,
} from '../../constants/stack.contstants';
import { fetchDocumentList, updateInfoDocumentInDocumentList, fetchStackDocumentsList } from './documentList';
import { IDocument } from '../reducers/pageList/pageList.model';
import { initialLabel } from '../../constants/localization.constants';
import { changeLang, getCommonLabelsByKey } from './localization';
import resourceBundle from '../../containers/localization/localizationData';
import { getStackLabelsByKey } from '../../containers/modalWindowContents/modalDocumentStack/modalDocumentStack';

export const setStackOptions = (data: IStackOptions): AnyAction => action(SET_OPTIONS_STACK, data);
export const changeStackOptions = (data: IStackOptions): AnyAction => action(CHANGE_OPTIONS_STACK, data);
export const clearStackOptions = (): AnyAction => action(CLEAR_OPTIONS_STACK);

export const fetchStackListPending = (): AnyAction => action(GET_STACK_LIST_PENDING);
export const fetchStackListSuccess = (dataList: IStackOptions[]): AnyAction =>
    action(GET_STACK_LIST_SUCCESS, dataList);
export const fetchStackListFailure = (error: IError): AnyAction => action(GET_STACK_LIST_FAILURE, error);

export const postStackPending = (): AnyAction =>
    action(POST_STACK_PENDING);
export const postStackSuccess = (data: IStackOptions): AnyAction =>
    action(POST_STACK_SUCCESS, data);
export const postStackFailure = (error: IError): AnyAction =>
    action(POST_STACK_FAILURE, error);

export const putStackPending = (): AnyAction =>
    action(PUT_STACK_PENDING);
export const putStackSuccess = (data: IStackOptions): AnyAction =>
    action(PUT_STACK_SUCCESS, data);
export const putStackFailure = (error: IError): AnyAction =>
    action(PUT_STACK_FAILURE, error);

export const deleteStackPending = (): AnyAction =>
    action(DELETE_STACK_PENDING);
export const deleteStackSuccess = (id: number): AnyAction =>
    action(DELETE_STACK_SUCCESS, id);
export const deleteStackFailure = (error: IError): AnyAction =>
    action(DELETE_STACK_FAILURE, error);

export const setStackPropertyOptions = (data: IStackPropertyOptions): AnyAction =>
    action(SET_OPTIONS_STACK_PROPERTY, data);
export const changeStackPropertyOptions = (data: IStackPropertyOptions): AnyAction =>
    action(CHANGE_OPTIONS_STACK_PROPERTY, data);
export const clearStackPropertyOptions = (): AnyAction =>
    action(CLEAR_OPTIONS_STACK_PROPERTY);

export const fetchStackPropertyListPending = (): AnyAction =>
    action(GET_STACK_PROPERTY_LIST_PENDING);
export const fetchStackPropertyListSuccess = (dataList: IStackPropertyOptions[]): AnyAction =>
    action(GET_STACK_PROPERTY_LIST_SUCCESS, dataList);
export const fetchStackPropertyListFailure = (error: IError): AnyAction =>
    action(GET_STACK_PROPERTY_LIST_FAILURE, error);

export const postStackPropertyPending = (): AnyAction =>
    action(POST_STACK_PROPERTY_PENDING);
export const postStackPropertySuccess = (data: IStackPropertyOptions): AnyAction =>
    action(POST_STACK_PROPERTY_SUCCESS, data);
export const postStackPropertyFailure = (error: IError): AnyAction =>
    action(POST_STACK_PROPERTY_FAILURE, error);

export const putStackPropertyPending = (): AnyAction =>
    action(PUT_STACK_PROPERTY_PENDING);
export const putStackPropertySuccess = (data: IStackPropertyOptions): AnyAction =>
    action(PUT_STACK_PROPERTY_SUCCESS, data);
export const putStackPropertyFailure = (error: IError): AnyAction =>
    action(PUT_STACK_PROPERTY_FAILURE, error);

export const deleteStackPropertyPending = (): AnyAction =>
    action(DELETE_STACK_PROPERTY_PENDING);
export const deleteStackPropertySuccess = (id: number): AnyAction =>
    action(DELETE_STACK_PROPERTY_SUCCESS, id);
export const deleteStackPropertyFailure = (error: IError): AnyAction =>
    action(DELETE_STACK_PROPERTY_FAILURE, error);

export const updateStackDocumentRelationPending = (): AnyAction =>
    action(UPDATE_STACK_DOCUMENT_RELATION_PENDING);
export const updateStackDocumentRelationSuccess = (): AnyAction =>
    action(UPDATE_STACK_DOCUMENT_RELATION_SUCCESS);
export const updateStackDocumentRelationFailure = (error: IError): AnyAction =>
    action(UPDATE_STACK_DOCUMENT_RELATION_FAILURE, error);

export const fetchStackList = (redactionDocumentId: number): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchStackListPending());
        try {
            const response = await api.stackController.getStackList(redactionDocumentId);

            dispatch(fetchStackListSuccess(response));
        } catch (error) {
            dispatch(fetchStackListFailure(error));
        }
    };

export const postStack = (redactionDocumentId: number, data: IStackOptions)
    : (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { localization: {modifiedLabels}, localStorage: {language} } = getState();
        const labels = { warningLabel: initialLabel};

        resourceBundle.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        dispatch(postStackPending());
        try {
            const response = await api.stackController.postStack(redactionDocumentId, data);

            dispatch(postStackSuccess(response));
            dispatch(setStackOptions(response));
        } catch (error) {
            dispatch(postStackFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: labels.warningLabel[changeLang(language)],
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

export const putStack = (redactionDocumentId: number, data: IStackOptions): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(putStackPending());
        try {
            const response = await api.stackController.updateStack(redactionDocumentId, [data]);

            dispatch(putStackSuccess(response[0]));
            dispatch(setStackOptions(response[0]));
        } catch (error) {
            dispatch(putStackFailure(error));
        }
    };

export const deleteStack = (
    redactionDocumentId: number,
    id: number,
    needsClearState: boolean)
    : (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { localization: {modifiedLabels}, localStorage: {language} } = getState();
        const labels = { warningLabel: initialLabel};

        resourceBundle.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        dispatch(deleteStackPending());
        try {
            await api.stackController.deleteStack(redactionDocumentId, id);

            if (needsClearState) {
                dispatch(clearStackOptions());
            }

            dispatch(deleteStackSuccess(id));
            dispatch(fetchDocumentList(redactionDocumentId));
        } catch (error) {
            dispatch(deleteStackFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: labels.warningLabel[changeLang(language)],
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

export const fetchStackPropertyList = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchStackPropertyListPending());
        try {
            const response = await api.stackPropertyController.getStackPropertyList();

            dispatch(fetchStackPropertyListSuccess(response));
        } catch (error) {
            dispatch(fetchStackPropertyListFailure(error));
        }
    };

export const postStackProperty = (data: IStackPropertyOptions): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(postStackPropertyPending());
        try {
            const response = await api.stackPropertyController.postStackProperty(data);

            dispatch(postStackPropertySuccess(response));
            dispatch(setStackPropertyOptions(response));
        } catch (error) {
            dispatch(postStackPropertyFailure(error));
        }
    };

export const putStackProperty = (data: IStackPropertyOptions): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(putStackPropertyPending());
        try {
            const response = await api.stackPropertyController.updateStackProperty(data);

            dispatch(putStackPropertySuccess(response));
            dispatch(setStackPropertyOptions(response));
        } catch (error) {
            dispatch(putStackPropertyFailure(error));
        }
    };

export const deleteStackProperty = (
    id: number,
    needsClearState: boolean,
): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { localization: {modifiedLabels}, localStorage: {language} } = getState();
        const labels = { warningLabel: initialLabel};

        resourceBundle.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        dispatch(deleteStackPropertyPending());
        try {
            await api.stackPropertyController.deleteStackProperty(id);

            const { reductionMetaData: { redactionDocumentId } } = getState();

            if (needsClearState) {
                dispatch(clearStackPropertyOptions());
            }

            dispatch(deleteStackPropertySuccess(id));

            dispatch(clearStackOptions());
            dispatch(fetchStackList(redactionDocumentId));
        } catch (error) {
            dispatch(deleteStackPropertyFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: labels.warningLabel[changeLang(language)],
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

export const addToStackDocuments = (): (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const {
            pageList: { selectedDocuments },
            localStorage: { language },
            localization: { modifiedLabels },
        } = getState();
        const labels = {
            stackTitleLabel: initialLabel,
            selectDocWarningLabel: initialLabel,
        };
        const langRule = changeLang(language);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (selectedDocuments.length < 1) {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: DOCUMENT_STACK_MODAL,
                title: labels.stackTitleLabel[langRule],
                message: labels.selectDocWarningLabel[langRule],
            }));
        } else {
            dispatch(openModalWindow(DOCUMENT_STACK_MODAL, {
                id: DOCUMENT_STACK_MODAL,
                title: labels.stackTitleLabel[langRule],
            }));
        }
    };

export const updateStackDocumentRelation = (
    redactionDocumentId: number,
    stackDto: IStackDocumentsDto,
    ): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
        async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
            const { documentList: { stackId }, localization: {modifiedLabels}, localStorage: {language} } = getState();
            const labels = { warningLabel: initialLabel};

            resourceBundle.map((resource: any) => {
                if(getCommonLabelsByKey(resource.resourceKey)) {
                    labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
                }

                return;
            });
            modifiedLabels.map((resource: any) => {
                if(getCommonLabelsByKey(resource.resourceKey)) {
                    labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
                }

                return;
            });

            dispatch(updateStackDocumentRelationPending());
            try {
                const response = await api.stackController.updateStackDocumentRelation(redactionDocumentId, stackDto);

                dispatch(updateStackDocumentRelationSuccess());
                response.forEach((document: IDocument) => {
                    dispatch(updateInfoDocumentInDocumentList(document));
                });

                if (stackId) {
                    dispatch(fetchStackDocumentsList(redactionDocumentId, stackId, true));
                }

                dispatch(handleCloseModalWindow(DOCUMENT_STACK_MODAL));
            } catch (error) {
                dispatch(updateStackDocumentRelationFailure(error));
                dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                    id: ALERT_DIALOG_MODAL,
                    title: labels.warningLabel[changeLang(language)],
                    message: error.message,
                    confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
                }));
            }
        }
;

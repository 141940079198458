import {
    GET_ANNOTATION_TYPE_PENDING,
    GET_ANNOTATION_TYPE_SUCCESS,
    GET_ANNOTATION_TYPE_FAILURE,
    POST_ANNOTATION_TYPE_PENDING,
    POST_ANNOTATION_TYPE_SUCCESS,
    POST_ANNOTATION_TYPE_FAILURE,
    PUT_ANNOTATION_TYPE_PENDING,
    PUT_ANNOTATION_TYPE_SUCCESS,
    PUT_ANNOTATION_TYPE_FAILURE,
    DELETE_ANNOTATION_TYPE_PENDING,
    DELETE_ANNOTATION_TYPE_SUCCESS,
    DELETE_ANNOTATION_TYPE_FAILURE,
    SET_SELECTED_ANNOTATIONS_TYPES, LOCAL_CHANGE_ANNOTATIONS_TYPES,
} from './constants';
import { IAnnotationType, IAnnotationTypesState } from './annotationTypes.model';
import { AnyAction } from 'redux';

const initialState: IAnnotationTypesState = {
    annotationType: [],
    localAnnotationType: [],
    annotationTypePending: false,
    annotationTypeUpdatePending: false,
    error: null,
    selectedAnnotation: null,
};

const popupReducer = (
    state: IAnnotationTypesState = initialState,
    {type, payload}: AnyAction,
): IAnnotationTypesState => {
    switch (type) {
        case GET_ANNOTATION_TYPE_PENDING:
        return {
            ...state,
            annotationTypePending: true,
        };
        case GET_ANNOTATION_TYPE_SUCCESS:
            return{
                ...state,
                annotationType: payload,
                localAnnotationType: payload,
                annotationTypePending: false,
            };
        case GET_ANNOTATION_TYPE_FAILURE:
            return{
                ...state,
                error: payload,
                annotationTypePending: false,
            };
        case POST_ANNOTATION_TYPE_SUCCESS:
            return{
                ...state,
                annotationType: [...state.annotationType, ...payload],
                localAnnotationType: [...state.annotationType, ...payload],
                annotationTypeUpdatePending: false,
                error: null,
            };
        case POST_ANNOTATION_TYPE_FAILURE:
        case PUT_ANNOTATION_TYPE_FAILURE:
        case DELETE_ANNOTATION_TYPE_FAILURE:
            return{
                ...state,
                error: payload,
                annotationTypeUpdatePending: false,
            };
        case POST_ANNOTATION_TYPE_PENDING:
        case PUT_ANNOTATION_TYPE_PENDING:
        case DELETE_ANNOTATION_TYPE_PENDING:
            return {
                ...state,
                annotationTypeUpdatePending: true,
            };
        case PUT_ANNOTATION_TYPE_SUCCESS:
            return{
                ...state,
                annotationType: state.annotationType.map((annotation: IAnnotationType) => {
                    const updatedElements = payload.filter((savedAnnotation: IAnnotationType): boolean =>
                        savedAnnotation.id === annotation.id);

                    return updatedElements.length ? updatedElements[0] : annotation;
                }),
                annotationTypeUpdatePending: false,
            };
        case DELETE_ANNOTATION_TYPE_SUCCESS:
            return{
                ...state,
                annotationType: [...state.annotationType.filter(
                    (annotation: IAnnotationType): boolean => annotation.id !== payload,
                )],
                localAnnotationType: [...state.localAnnotationType.filter(
                    (annotation: IAnnotationType): boolean => annotation.id !== payload,
                )],
                annotationTypeUpdatePending: false,
            };
        case SET_SELECTED_ANNOTATIONS_TYPES:
            return {
                ...state,
                selectedAnnotation: payload,
            };
        case LOCAL_CHANGE_ANNOTATIONS_TYPES:
            return {
                ...state,
                localAnnotationType: payload,
            };
        default:
            return state;
    }
};

export default popupReducer;

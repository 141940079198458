import * as React from 'react';
import { Scrollbars } from 'rc-scrollbars';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { TransitionProps as MaterialTransitionProps } from '@mui/material/transitions/transition';
import { ISelectOptions } from '../materialUiForms/marerialUiForms.model';
import { IScrollableProps, IScrollableState } from './scrollableSelect.model';
import './ScrollableSelect.scss';

class ScrollableSelect extends React.Component<IScrollableProps, IScrollableState> {
    constructor(props: IScrollableProps) {
        super(props);

        this.state = {
            isOpen: false,
            selectValue: '',
        };
    }

    public componentDidMount(): void {
        this.setState({
            selectValue: this.getLabelFromValue(),
        });
    }

    public componentDidUpdate(prevProps: IScrollableProps): void {
        if (prevProps.value !== this.props.value) {
            this.setState({
                selectValue: this.getLabelFromValue(),
            });
        }
    }

    public render(): JSX.Element {
        const { isOpen, selectValue } = this.state;
        const { options } = this.props;

        return (
            <div className='scrollable-select'>
                <div className='selected-item' onClick={this.openSelect}>
                    <div className='selected-item_text'>
                        {selectValue}
                    </div>

                    <div className='selected-item_icon'>
                        <ArrowDropDown classes={{ root: 'arrow-down' }} />
                    </div>
                </div>
                <Popper open={isOpen} transition={true} disablePortal={true} style={{ zIndex: 2, top: 160, left: 20 }}>
                    <Grow in={isOpen}>
                        <Paper className='scrollable-select_paper'>
                            <ClickAwayListener onClickAway={this.handleClickAway}>
                                <Scrollbars autoHeight={true} autoHeightMax={200}>
                                    <MenuList>
                                        {options.map((item: ISelectOptions) => (
                                            <MenuItem
                                                onClick={(): void => this.handleClickItem(item.value)}
                                                key={item.value}
                                            >
                                                {item.label}
                                        </MenuItem>
                                        ))}
                                    </MenuList>
                                </Scrollbars>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                </Popper>
            </div>
        );
    }

    private openSelect = (): void => {
        this.setState({ isOpen: true });
    }

    private handleClickItem = (value: string): void => {
        this.setState({ isOpen: false });
        this.props.onChange(value);
    }

    private getLabelFromValue = (): string => {
        const currentOption = this.props.options.filter((item: ISelectOptions) => {
            return item.value === this.props.value;
        });

        return currentOption.length ? currentOption[0].label : '';
    }

    private handleClickAway = (): void => {
        this.setState({ isOpen: false });
    }
}

export default ScrollableSelect;

import { ISearchPage, ISearchResponseData } from '../containers/globalSearch/globalSearch.model';

export const getPageSearchData = (
    data: ISearchResponseData[],
    currentDocumentName: string,
    currentPageId: number): ISearchPage => {

    const documentSearchData = data.find((responseData: ISearchResponseData): boolean =>
        responseData.documentName === currentDocumentName);

    return documentSearchData && documentSearchData.pages.find((page: ISearchPage): boolean =>
        page.page.id === currentPageId);
};

export const FETCH_DOCUMENT_LIST_PENDING = 'reduction/FETCH_DOCUMENT_LIST_PENDING';
export const FETCH_DOCUMENT_LIST_FAILURE = 'reduction/FETCH_DOCUMENT_LIST_FAILURE';
export const FETCH_DOCUMENT_LIST_SUCCESS = 'reduction/FETCH_DOCUMENT_LIST_SUCCESS';
export const FETCH_DOCUMENT_EXPORT_LIST_SUCCESS = 'reduction/FETCH_DOCUMENT_EXPORT_LIST_SUCCESS';
export const FETCH_DOCUMENT_STACK_LIST_SUCCESS = 'redaction/FETCH_DOCUMENT_STACK_LIST_SUCCESS';

export const DELETE_DOCUMENT_PENDING = 'reduction/DELETE_DOCUMENT_PENDING';
export const DELETE_DOCUMENT_SUCCESS = 'reduction/DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'reduction/DELETE_DOCUMENT_FAILURE';

export const ADD_DOCUMENT_SUCCESS = 'reduction/ADD_DOCUMENT_SUCCESS';

export const UPDATE_DOCUMENT_PAGE_COUNT = 'reduction/UPDATE_DOCUMENT_PAGE_COUNT';

export const SET_DISCLOSURE_TYPE_FOR_DOCUMENT = 'reduction/SET_DISCLOSURE_TYPE_FOR_DOCUMENT';

export const SET_PAGINATION_PENDING = 'reduction/SET_PAGINATION_PENDING';
export const SET_PAGINATION_SUCCESS = 'reduction/SET_PAGINATION_SUCCESS';
export const SET_PAGINATION_FAILURE = 'reduction/SET_PAGINATION_FAILURE';

export const UPDATE_PAGINATION_PENDING = 'reduction/UPDATE_PAGINATION_PENDING';
export const UPDATE_PAGINATION_SUCCESS = 'reduction/UPDATE_PAGINATION_SUCCESS';
export const UPDATE_PAGINATION_FAILURE = 'reduction/UPDATE_PAGINATION_FAILURE';

export const SET_DOCUMENT_PAGINATION_PENDING = 'reduction/SET_DOCUMENT_PAGINATION_PENDING';
export const SET_DOCUMENT_PAGINATION_SUCCESS = 'reduction/SET_DOCUMENT_PAGINATION_SUCCESS';
export const SET_DOCUMENT_PAGINATION_FAILURE = 'reduction/SET_DOCUMENT_PAGINATION_FAILURE';

export const UNDO_PAGINATION_PENDING = 'reduction/UNDO_PAGINATION_PENDING';
export const UNDO_PAGINATION_SUCCESS = 'reduction/UNDO_PAGINATION_SUCCESS';
export const UNDO_PAGINATION_FAILURE = 'reduction/UNDO_PAGINATION_FAILURE';

export const UPDATE_DOCUMENT_PAGINATION = 'reduction/UPDATE_DOCUMENT_PAGINATION';
export const UPDATE_DOCUMENT_INFO = 'reduction/UPDATE_DOCUMENT_INFO';
export const UPDATE_DOCUMENT_DISCLOSURE = 'reduction/UPDATE_DOCUMENT_DISCLOSURE';

export const OCR_DOCUMENT_PENDING = 'reduction/OCR_DOCUMENT_PENDING';
export const OCR_DOCUMENT_SUCCESS = 'reduction/OCR_DOCUMENT_SUCCESS';
export const OCR_DOCUMENT_FAILURE = 'reduction/OCR_DOCUMENT_FAILURE';

export const UPDATE_DOCUMENTS_PENDING = 'reduction/UPDATE_DOCUMENTS_PENDING';
export const UPDATE_DOCUMENTS_SUCCESS = 'reduction/UPDATE_DOCUMENTS_SUCCESS';
export const UPDATE_DOCUMENTS_FAILURE = 'reduction/UPDATE_DOCUMENTS_FAILURE';

export const UPDATE_DOCUMENTS_BY_LIST = 'reduction/UPDATE_DOCUMENTS_BY_LIST';

export const SAVE_STACK_ID = 'reduction/SAVE_STACK_ID';

export const SET_DOCUMENT_INITIAL_STATE = 'reduction/SET_DOCUMENT_INITIAL_STATE';
export const CHANGE_DOCUMENT_DISCLOSURE_PENDING = 'reduction/CHANGE_DOCUMENT_DISCLOSURE_PENDING';
export const CHANGE_DOCUMENT_DISCLOSURE_COMPLETE = 'reduction/CHANGE_DOCUMENT_DISCLOSURE_COMPLETE';

export const SET_DOCUMENT_LIST_COLUMN_HEADER_PENDING = 'redaction/SET_DOCUMENT_LIST_COLUMN_HEADER_PENDING';
export const SET_DOCUMENT_LIST_COLUMN_HEADER_LIST_SUCCESS = 'redaction/SET_DOCUMENT_LIST_COLUMN_HEADER_LIST_SUCCESS';
export const SET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE = 'redaction/SET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE';

export const SET_DOCUMENT_LIST_TABLE_COLUMN = 'redaction/SET_DOCUMENT_LIST_TABLE_COLUMN';

export const MODIFY_DOCUMENT_LIST_COLUMN_HEADER_PENDING = 'redaction/MODIFY_DOCUMENT_LIST_COLUMN_HEADER_PENDING';
export const MODIFY_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS = 'redaction/MODIFY_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS';
export const MODIFY_DOCUMENT_LIST_COLUMN_HEADER_FAILURE = 'redaction/MODIFY_DOCUMENT_LIST_COLUMN_HEADER_FAILURE';

export const RESET_DOCUMENT_LIST_COLUMN_HEADER_PENDING = 'redaction/RESET_DOCUMENT_LIST_COLUMN_HEADER_PENDING';
export const RESET_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS = 'redaction/RESET_DOCUMENT_LIST_COLUMN_HEADER_SUCCESS';
export const RESET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE = 'redaction/RESET_DOCUMENT_LIST_COLUMN_HEADER_FAILURE';

export const APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_PENDING = 'redaction/APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_PENDING';
export const APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_SUCCESS = 'redaction/APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_SUCCESS';
export const APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_FAILURE = 'redaction/APPLY_DOCUMENT_AUTO_ALIGN_ARTICLE_STAMPS_FAILURE';

export const GET_CURRENT_PAGE_STAMPS_PENDING = 'reduction/ADD_STAMP_PENDING';
export const GET_CURRENT_PAGE_STAMPS_SUCCESS = 'reduction/ADD_STAMP_SUCCESS';
export const GET_CURRENT_PAGE_STAMPS_FAILURE = 'reduction/ADD_STAMP_FAILURE';

export const UPDATE_STAMP_PENDING = 'reduction/UPDATE_STAMP_PENDING';
export const UPDATE_STAMP_SUCCESS = 'reduction/UPDATE_STAMP_SUCCESS';
export const UPDATE_STAMP_FAILURE = 'reduction/UPDATE_STAMP_FAILURE';

export const ADD_STAMPS_PENDING = 'reduction/ADD_STAMPS_PENDING';
export const ADD_STAMPS_SUCCESS = 'reduction/ADD_STAMPS_SUCCESS';
export const ADD_STAMPS_FAILURE = 'reduction/ADD_STAMPS_FAILURE';

export const DELETE_STAMPS_PENDING = 'reduction/DELETE_STAMPS_PENDING';
export const DELETE_STAMPS_SUCCESS = 'reduction/DELETE_STAMPS_SUCCESS';
export const DELETE_STAMPS_FAILURE = 'reduction/DELETE_STAMPS_FAILURE';

export const GET_STAMP_ALIGN_PENDING = 'redaction/GET_STAMP_ALIGN_PENDING';
export const GET_STAMP_ALIGN_SUCCESS = 'redaction/GET_STAMP_ALIGN_SUCCESS';
export const GET_STAMP_ALIGN_FAILURE = 'redaction/GET_STAMP_ALIGN_FAILURE';

export const UPDATE_STAMP_ALIGN_PENDING = 'redaction/UPDATE_STAMP_ALIGN_PENDING';
export const UPDATE_STAMP_ALIGN_SUCCESS = 'redaction/UPDATE_STAMP_ALIGN_SUCCESS';
export const UPDATE_STAMP_ALIGN_FAILURE = 'redaction/UPDATE_STAMP_ALIGN_FAILURE';

export const REMOVE_STAMPS_PER_PAGE = 'reduction/REMOVE_STAMPS_PER_PAGE';

export const SET_AUTO_STAMP = 'redaction/SET_AUTO_STAMP';

export const HIGHLIGHT_LINKED_ARTICLES = 'redaction/HIGHLIGHT_LINKED_ARTICLES';
export const CLEAR_LINKED_ARTICLES = 'redaction/CLEAR_LINKED_ARTICLES';
export const CLEAR_LINKED_ARTICLES_FOR_ALL_ANNOTATIONS = 'redaction/CLEAR_LINKED_ARTICLES_FOR_ALL_ANNOTATIONS';

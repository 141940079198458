import * as React from 'react';
import { useState, useEffect } from 'react';
import { usePrevious } from '../../hooks/usePrevious';
import SplitterLayout from 'react-splitter-layout-react-v18';
import { DuplicateRedactor } from './DupilcateRedactor';
import { IDuplicateDocument } from '../../redux/reducers/duplicates/duplicates.model';
import { IClientSize } from '../redactor/redactor.model';
import { IDuplicateReferences } from './duplicate.model';
import { IRedactorDocument } from '../../redux/reducers/redactor/redactor.model';

export interface ISplitterWrapperProps {
    documents: IDuplicateDocument[];
    redactorDocument: IRedactorDocument;
    setWidth: (width: number) => void;
    setHeight: (height: number) => void;
    index: number;
    handleClose: (index: number) => void;
    setDuplicateScale: (documentId: number, pageId: number, scale: number) => void;
    setDuplicateClientSize: (documentId: number, pageId: number, clientSize: IClientSize) => void;
    handleGoToDocumentPage: (documentId: number, page: string) => void;
    handleOpenModalDuplicates: () => void;
    fetchDuplicateRefData: (documentId: number, pageId: number, actualPageNumber: number) => void;
    duplicateReferences: IDuplicateReferences[];
}

export const SplitterWrapper = (
    {
        documents,
        redactorDocument,
        setWidth,
        setHeight,
        index,
        handleClose,
        setDuplicateScale,
        setDuplicateClientSize,
        handleGoToDocumentPage,
        duplicateReferences,
        handleOpenModalDuplicates,
        fetchDuplicateRefData,
    }: ISplitterWrapperProps,
): JSX.Element => {
    const columnWidth = documents ? 100 / documents.length : 0;
    const [secondColumnSize, useSecondColumnSize] = useState(columnWidth);
    const previousDocuments = usePrevious(documents);

    useEffect(() => {
        if (previousDocuments && documents && previousDocuments.length !== documents.length) {
            useSecondColumnSize(columnWidth);
        }
    }, [documents]);

    return (
        <SplitterLayout
            key={secondColumnSize}
            percentage={true}
            primaryIndex={1}
            secondaryInitialSize={secondColumnSize}
        >
            {
                documents[0] && <DuplicateRedactor
                  document={documents[0]}
                  redactorDocument={redactorDocument}
                  setWidth={setWidth}
                  setHeight={setHeight}
                  handleClose={(): void => handleClose(index)}
                  setDuplicateScale={setDuplicateScale}
                  setDuplicateClientSize={setDuplicateClientSize}
                  handleGoToDocumentPage={handleGoToDocumentPage}
                  duplicateReferences={duplicateReferences}
                  fetchDuplicateRefData={fetchDuplicateRefData}
                />
            }
            {documents[1] && <div>
              <SplitterWrapper
                documents={documents.filter(
                    (
                        document: IDuplicateDocument,
                        i: number,
                    ): boolean => i !== 0)}
                redactorDocument={redactorDocument}
                setWidth={setWidth}
                setHeight={setHeight}
                index={index + 1}
                handleClose={handleClose}
                setDuplicateScale={setDuplicateScale}
                setDuplicateClientSize={setDuplicateClientSize}
                handleGoToDocumentPage={handleGoToDocumentPage}
                duplicateReferences={duplicateReferences}
                handleOpenModalDuplicates={handleOpenModalDuplicates}
                fetchDuplicateRefData={fetchDuplicateRefData}
              />
            </div>}
        </SplitterLayout>
    );
};

import * as React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Spinner } from '../../components/spinner/Spinner';
import { Scrollbars } from 'rc-scrollbars';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import { ITEM_CLASS } from '../../constants';
import { CANCEL, COMPARE } from '../../constants/common.constants';
import { IIndexMetadata } from '../../redux/reducers/indexMetadata/indexMetadata.model';
import {
    IAllowPagesNavigation,
    IDuplicatesCurrentGroup,
    IDuplicatesSearchIds,
} from '../../redux/reducers/duplicates/duplicates.model';
import { SEARCH } from '../../constants/advancedSearch.constants';
import * as constants from '../../constants/duplicates.constants';
import { GoToPage } from '../../components/navigation/Navigation';
import './duplicatePanels.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { openModalWindow } from '../../redux/actions/modal';
import { ASSIGNED_DUPLICATES_MODAL } from '../../constants/duplicates.constants';
import toggleClassName from '../../utils/toggleClassNames';
import { getAssignedModalButtonStatus } from '../../redux/selectors/duplicates';
import { b64EncodeUnicode } from '../../utils/encode.util';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@mui/material/Popover';

export interface IDuplicateControlsProps {
    folderRsn: { folderRsn: number };
    fetchFilters: () => void;
    filters: IIndexMetadata[];
    filtersPending: boolean;
    controlsHeight: number;
    duplicatesCurrentGroup: IDuplicatesCurrentGroup;
    fetchDuplicateCurrentGroup: (ids: IDuplicatesSearchIds, pageNumber?: number) => void;
    handleClearDuplicateGroup: () => void;
    handleGoToPage: (page: string) => void;
    allowPagesNavigation: IAllowPagesNavigation;
    handleClearDuplicatesResource: () => void;
    handleSearch: (pageNumber?: number) => void;
    openReference: () => void;
    isCriteriaSearch: boolean;
    redactionDocumentId: number;
}

export const DuplicateControls = (
    {
        folderRsn,
        fetchFilters,
        filters,
        filtersPending,
        controlsHeight,
        fetchDuplicateCurrentGroup,
        handleClearDuplicateGroup,
        handleClearDuplicatesResource,
        duplicatesCurrentGroup,
        handleSearch,
        handleGoToPage,
        allowPagesNavigation,
        openReference,
        isCriteriaSearch,
        redactionDocumentId,
    }: IDuplicateControlsProps): JSX.Element => {
    const [duplicateSearchFiltersValue, useDuplicateSearchFiltersValue] = useState([]);
    const outsideRef = useRef(null);

    const currentDocumentSet = duplicatesCurrentGroup && duplicatesCurrentGroup.currentPage + 1;

    const getFilters = (): void => {
        if (!filters.length) {
            fetchFilters();
        }
    };

    const setDuplicateFilters = (e: React.SyntheticEvent): void => {
        e.persist();

        const checkedValue = Number(e.target['value']);

        useDuplicateSearchFiltersValue(duplicateSearchFiltersValue.includes(checkedValue) ?
            duplicateSearchFiltersValue.filter((value: number): boolean => value !== checkedValue) :
            [...duplicateSearchFiltersValue, checkedValue]);
    };

    const fetchDuplicatesGroup = (): void => {
        fetchDuplicateCurrentGroup({searchCriteriaIdList: duplicateSearchFiltersValue});
    };
    const dispatch = useDispatch();
    const showAssignDuplicatesModal = (): void => {
        dispatch(openModalWindow(ASSIGNED_DUPLICATES_MODAL,
            {id: ASSIGNED_DUPLICATES_MODAL, title: constants.getManageDuplicatesLabelsByKey(constants.ASSIGNED_DUPLICATES_TITLE_MODAL)}));
    };

    const handleCancel = (): void => {
        useDuplicateSearchFiltersValue([]);
        handleClearDuplicateGroup();
    };

    const showDocSets = (): string => {
        const emptyResult = '0/0';

        if (duplicatesCurrentGroup) {
            const {totalPages, currentPage} = duplicatesCurrentGroup;

            return totalPages === 0 && currentPage === 0 ? emptyResult : `${currentDocumentSet}/${totalPages}`;
        }

        return emptyResult;
    };
    const assignedModalButtonStatus = useSelector(getAssignedModalButtonStatus);

    const validatePrevButtons = (): boolean => {
        return duplicatesCurrentGroup && duplicatesCurrentGroup.currentPage !== 0;
    };

    const validateNextButtons = (): boolean => {
        if (duplicatesCurrentGroup) {
            const {totalPages, currentPage} = duplicatesCurrentGroup;

            return totalPages !== currentDocumentSet && totalPages !== currentPage;
        } else {
            return false;
        }
    };

    const handleDocNavType = (navType: string): number => {
        const {currentPage, totalPages} = duplicatesCurrentGroup;

        switch (navType) {
            case GoToPage.FIRST:
                return 0;
            case GoToPage.PREV:
                return currentPage - 1;
            case GoToPage.NEXT:
                return currentPage + 1;
            case GoToPage.LAST:
                return totalPages - 1;
        }
    };

    const handleSetNavigation = (navType: string, validation: boolean): void => {
        if (validation) {
            handleClearDuplicatesResource();

            if (isCriteriaSearch) {
                fetchDuplicateCurrentGroup(
                    { searchCriteriaIdList: duplicateSearchFiltersValue },
                    handleDocNavType(navType),
                );
            } else {
                handleSearch(handleDocNavType(navType));
            }
        }
    };

    return (
    <React.Fragment>
        {
        folderRsn ?
            <div className='duplicates-body_controls controls'>
                <div>
                    <div className={ITEM_CLASS} ref={outsideRef}>
                        <PopupState
                            variant='popover'
                            popupId='demo-popup-menu'
                        >
                            {(popupState: any): JSX.Element => (
                                <React.Fragment>
                                    <div
                                        className='icon'
                                        onClick={(): void => getFilters()}
                                        {...bindTrigger(popupState)}
                                    >
                                        <div className='svg'>
                                            <img alt='compare' src='/icons/compare.png'/>
                                        </div>
                                        <div className='text'>{constants.getManageDuplicatesLabelsByKey(COMPARE)}</div>
                                    </div>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div className={'duplicates-body_filters-wrapper'}>
                                            {filtersPending ? <Spinner active={true}/> :
                                                <FormControl component='fieldset'>
                                                    <FormGroup aria-label='position' onChange={setDuplicateFilters}>
                                                        <span className='compare_attributes_hint'>
                                                            {constants.getManageDuplicatesLabelsByKey(constants.SELECT_ONE)}
                                                        </span>
                                                        {filters.map((filter: IIndexMetadata): JSX.Element => (
                                                            <FormControlLabel
                                                                key={filter.id}
                                                                control={
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={
                                                                        duplicateSearchFiltersValue.includes(filter.id)
                                                                        }
                                                                        value={filter.id}
                                                                    />}
                                                                label= {constants.getManageDuplicatesLabelsByKey(filter.name)}
                                                            />))}
                                                        <div className='duplicates-body_filters-wrapper_buttons'>
                                                            <Button
                                                                variant='outlined'
                                                                className='primary bottom-bar_buttons'
                                                                size='small'
                                                                onClick={handleCancel}
                                                            >
                                                                {constants.getManageDuplicatesLabelsByKey(CANCEL)}
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                className='primary bottom-bar_buttons'
                                                                color='primary'
                                                                size='small'
                                                                onClick={fetchDuplicatesGroup}
                                                                disabled={!duplicateSearchFiltersValue.length}
                                                            >
                                                                {constants.getManageDuplicatesLabelsByKey(COMPARE)}
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </FormControl>}
                                        </div>
                                    </Popover>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </div>
                    <div className={ITEM_CLASS}>
                        <div className='icon' onClick={(): void => handleSearch()}>
                            <div className='svg'>
                                <img alt='compare' src='/icons/search.png'/>
                            </div>
                            <div className='text'>{constants.getManageDuplicatesLabelsByKey(SEARCH)}</div>
                        </div>
                    </div>
                    <div
                        className={toggleClassName(!assignedModalButtonStatus, 'disable', ITEM_CLASS)}
                        onClick={assignedModalButtonStatus ? showAssignDuplicatesModal : undefined}
                    >
                        <div className='icon'>
                            <div className='svg'>
                                <img alt='compare' src='/icons/assign.png'/>
                            </div>
                            <div className='text'>{constants.getManageDuplicatesLabelsByKey(constants.ASSIGNMENT)}</div>
                        </div>
                    </div>
                    <div className={ITEM_CLASS}>
                        <div className='icon'>
                            <div className='svg' onClick={openReference}>
                                <img alt='compare' src='/icons/references.png'/>
                            </div>
                            <div className='text'>{constants.getManageDuplicatesLabelsByKey(constants.REFERENCES)}</div>
                        </div>
                    </div>
                    <div className={`${ITEM_CLASS} page-nav`}>
                        <div className='page-nav-buttons'>
                            <div
                                className={`icon ${!allowPagesNavigation.back ? 'page-nav-buttons-disabled' : ''}`}
                                onClick={(): void => {
                                    if (allowPagesNavigation.back) {
                                        handleGoToPage(GoToPage.FIRST);
                                    }
                                }}
                            >
                                <div className='svg'>
                                    <FirstPage/>
                                </div>
                            </div>
                            <div
                                className={`icon ${!allowPagesNavigation.back ? 'page-nav-buttons-disabled' : ''}`}
                                onClick={(): void => {
                                    if (allowPagesNavigation.back) {
                                        handleGoToPage(GoToPage.PREV);
                                    }
                                }}
                            >
                                <div className='svg'>
                                    <ChevronLeft/>
                                </div>
                            </div>
                            <div
                                className={`icon ${!allowPagesNavigation.forward ? 'page-nav-buttons-disabled' : ''}`}
                                onClick={(): void => {
                                    if (allowPagesNavigation.forward) {
                                        handleGoToPage(GoToPage.NEXT);
                                    }
                                }}
                            >
                                <div className='svg'>
                                    <ChevronRight/>
                                </div>
                            </div>
                            <div
                                className={`icon ${!allowPagesNavigation.forward ? 'page-nav-buttons-disabled' : ''}`}
                                onClick={(): void => {
                                    if (allowPagesNavigation.forward) {
                                        handleGoToPage(GoToPage.LAST);
                                    }
                                }}
                            >
                                <div className='svg'>
                                    <LastPage/>
                                </div>
                            </div>
                        </div>
                        <div className='text'>{constants.getManageDuplicatesLabelsByKey(constants.PAGE_NAVIGATION)}</div>
                    </div>

                    <div className={`${ITEM_CLASS} doc-nav`}>
                        <div className='doc-nav-buttons'>
                            <div
                                className={classnames('icon document', {disabled: !validatePrevButtons()})}
                                onClick={(): void => handleSetNavigation(GoToPage.FIRST, validatePrevButtons())}
                            >
                                <div className='svg'>
                                    <FirstPage/>
                                    <img alt='compare' src='/icons/pages.png'/>
                                </div>
                            </div>
                            <div
                                className={classnames('icon document', {disabled: !validatePrevButtons()})}
                                onClick={(): void => handleSetNavigation(GoToPage.PREV, validatePrevButtons())}
                            >
                                <div className='svg'>
                                    <ChevronLeft/>
                                    <img alt='compare' src='/icons/pages.png'/>
                                </div>
                            </div>
                            <div className='doc-nav-number'>
                                {showDocSets()}
                            </div>
                            <div
                                className={classnames('icon document', {disabled: !validateNextButtons()})}
                                onClick={(): void => handleSetNavigation(GoToPage.NEXT, validateNextButtons())}
                            >
                                <div className='svg'>
                                    <ChevronRight/>
                                    <img alt='compare' src='/icons/pages.png'/>
                                </div>
                            </div>
                            <div
                                className={classnames('icon document', {disabled: !validateNextButtons()})}
                                onClick={(): void => handleSetNavigation(GoToPage.LAST, validateNextButtons())}
                            >
                                <div className='svg'>
                                    <LastPage/>
                                    <img alt='compare' src='/icons/pages.png'/>
                                </div>
                            </div>
                        </div>
                        <div className='text'>{constants.getManageDuplicatesLabelsByKey(constants.DOCUMENT_SET_NAVIGATION)}</div>
                    </div>
                </div>
                {
                    redactionDocumentId &&
                    <Link
                        to={`/${b64EncodeUnicode(folderRsn)}`}
                        className='link_button duplicates-body_controls_back_button'
                    >
                        {constants.getManageDuplicatesLabelsByKey(constants.BACK_HOME)}
                    </Link>
                }
                {
                    !redactionDocumentId &&
                    <Link
                        to={`/${b64EncodeUnicode(folderRsn)}`}
                        onClick={(e: React.MouseEvent<Element>): void => e.preventDefault()}
                        className='link_button link_button_disabled duplicates-body_controls_back_button'
                    >
                        {constants.getManageDuplicatesLabelsByKey(constants.BACK_HOME)}
                    </Link>
                }

            </div> : null
        }
    </React.Fragment>
    );
};

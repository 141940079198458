import * as React from 'react';
import Modal from '@mui/material/Modal';
import BaseModalWindow from './BaseModalWindow';
import ResizableDraggableModal from './ResizableDraggableModalWindow';
import contentMapper from '../../containers/modalWindowContents/index';
import { IProps, IState } from './modalWindow.model';
import { IModal } from '../../redux/reducers/modal/modal.model';
import { handleClose2LastModalWindows } from '../../redux/actions/modal';
import {
    CONFIRMATION_DIALOG_MODAL,
    CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
    WARNING,
    CANCEL_BUTTON,
    ALERT_DIALOG_MODAL,
} from '../../constants/messages.constants';
import './modalWindow.styles.scss';
import { changeLang, commonLabels, getCommonLabelsByKey } from '../../redux/actions/localization';
import resourceBundle from '../../containers/localization/localizationData';

class ModalWindow extends React.Component<IProps, IState> {
    public state: IState = {
        isChangedForm: false,
        isConfirmOpen: false,
    };

    public handleDetectChanges = (isChanged: boolean): void => {
        this.setState({ isChangedForm: isChanged });
    }

    public handleClose = (id: string, modalType: string): void => {
        const {handleCloseModalWindow, redactionLang, modifiedLabels } = this.props;
        const labels = commonLabels;
        const langRule = changeLang(redactionLang);
        resourceBundle.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });

        if (
            this.state.isChangedForm &&
            !this.state.isConfirmOpen &&
            (modalType !== CONFIRMATION_DIALOG_MODAL && modalType !== ALERT_DIALOG_MODAL)
        ) {
            this.setState({ isConfirmOpen: true });
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: `${WARNING}-${id}`,
                title: `${labels.warningLabel[langRule]}`,
                message: `${labels.changesNotSavedLabel[langRule]}`,
                confirm: handleClose2LastModalWindows,
            });
        } else {
            handleCloseModalWindow(id);
            this.setState({ isConfirmOpen: false });
        }
    }

    public render(): JSX.Element {
        const { modal, handleCloseModalWindow, handleAction } = this.props;
        const { isConfirmOpen, isChangedForm } = this.state;

        if (modal.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                {modal.map(
                    ({ modalType, modalProps }: IModal): JSX.Element => {
                        const {
                            content: SpecificModal,
                            title,
                            okButton,
                            cancelButton,
                            formId,
                            modalDimensions,
                            detectChanges,
                        } = contentMapper[modalType];
                        const modalPermissions = this.props.modalWindowPermissions[modalType];

                        const modalTitle = (modalProps && modalProps.title) || title;
                        const okButtonTitle = modalProps && modalProps.okButtonTitle || okButton;
                        const cancelButtonTitle = modalProps && modalProps.cancelButtonTitle ||
                            cancelButton && CANCEL_BUTTON;
                        const id = modalProps && modalProps.id;
                        const noControls = !!(modalProps && modalProps.noControls);

                        let childModalProps: any = {
                            ...modalProps,
                            permissions: modalPermissions,
                        };

                        if (detectChanges) {
                            childModalProps = {
                                ...childModalProps,
                                isChangedForm,
                                isConfirmOpen,
                                handleDetectChanges: (isChanged: boolean): void => this.handleDetectChanges(isChanged),
                                onCloseModal: (): void => this.handleClose(id, modalType),
                            };
                        }

                        if (okButton) {
                            childModalProps = {
                                ...childModalProps,
                                formId,
                                onSubmit: (form: any): void => onSubmit(form),
                            };
                        }

                        const onAccept = (): void => {
                            if (formId) {
                                onSaveButton(formId);
                            } else if (modalProps.confirm) {
                                handleAction(modalProps.confirm);
                                this.setState({ isConfirmOpen: false });
                            }

                            handleCloseModalWindow(id);
                        };
                        const onCancel = (modalId: string, modalTypeName: string, needActionsCall: boolean): void => {
                            if (needActionsCall && modalProps.reject) {
                                handleAction(modalProps.reject);
                                this.setState({ isConfirmOpen: false });
                            }

                            this.handleClose(modalId, modalTypeName);
                        };
                        const onSubmit = (formData: any): void => {
                            const {
                                okButton: { action },
                            } = contentMapper[modalType];

                            handleAction(action, formData);
                        };
                        const onSaveButton = (idForm: string): void => {
                            document.getElementById(idForm).dispatchEvent(new Event('submit', { cancelable: true }));
                        };

                        return (
                            <Modal
                                key={id}
                                aria-labelledby='simple-modal-title'
                                aria-describedby='simple-modal-description'
                                open={true}
                                onClose={(reason) => {
                                        if (reason !== 'backdropClick') {
                                          this.handleClose(id, modalType);
                                        }
                                    }}
                                className='modal-wrapper'
                            >
                                <div>
                                    {!modalDimensions.disableDrag || !modalDimensions.disableResize ? (
                                        <ResizableDraggableModal
                                            noControls={noControls}
                                            SpecificModal={SpecificModal}
                                            id={id}
                                            modalTitle={modalTitle}
                                            okButton={okButtonTitle}
                                            cancelButton={cancelButtonTitle}
                                            onAccept={onAccept}
                                            onCancel={(modalId: string, needActionsCall?: boolean) =>
                                                onCancel(modalId, modalType, needActionsCall)}
                                            childModalProps={childModalProps}
                                            isDisabledDrag={false}
                                            modalDimensions={modalDimensions}
                                        />
                                    ) : (
                                        <BaseModalWindow
                                            noControls={noControls}
                                            SpecificModal={SpecificModal}
                                            id={id}
                                            modalTitle={modalTitle}
                                            okButton={okButtonTitle}
                                            cancelButton={cancelButtonTitle}
                                            onAccept={onAccept}
                                            onCancel={(modalId: string, needActionsCall?: boolean) =>
                                                onCancel(modalId, modalType, needActionsCall)}
                                            childModalProps={childModalProps}
                                            isDisabledDrag={true}
                                            modalDimensions={modalDimensions}
                                        />
                                    )}
                                </div>
                            </Modal>
                        );                        
                    },
                )}
            </React.Fragment>
        );
    }
}

export default ModalWindow;

import * as React from 'react';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';
import { isEmpty } from 'lodash';
import { Scrollbars } from 'rc-scrollbars';
import Navigation from '../../components/navigation/Navigation';
import {
    setPdfHeight,
    setPdfWidth,
    setSelectedShapes,
    cleanSelectedShapes,
    setClientSize, pageScale,
} from '../../redux/actions/redactor';
import { getRedactionDocumentId, getUserMetaData } from '../../redux/selectors/initialization';
import { getDocument, getDocumentLoading, getBuffer } from '../../redux/selectors/redactor';
import { getPageInformation } from '../../redux/selectors/pageList';
import { getPageStamps, setStampAlingment } from '../../redux/actions/stamps';
import { getAllAnnotationsPerPage } from '../../redux/actions/annotations';
import { Spinner } from '../../components/spinner/Spinner';
import { ShapesLayer } from '../shapesLayer/shapesLayer';
import {
    IRedactorDispatchProps,
    IRedactorProps,
    IRedactorStateProps,
    IRedactorThunkDispatch,
    IClientSize,
    IStartCoordinate,
    ILoadedPage,
    IRedactorState,
    IPositionValues,
} from './redactor.model';
import { IState } from '../../redux/store';
import { IPageActionParams } from '../../redux/reducers/annotation/annotation.model';
import { useContextMenu } from 'react-contexify';
import {
    deleteCutCopyAnnotationMenus,
    highlightSpecificMenu,
    severSpecificMenu,
    pasteMenu,
    toggleSelectionMenu,
    viewCommentMenu,
} from '../../constants/contextmenu/redactor.menu';
import { degrees, PAGE_WIDTH_VAL } from '../../constants';
import { IContextData } from '../../redux/reducers/contextMenu/contextMenu.model';
import { openContextMenu, setRedactorContextMenu } from '../../redux/actions/contextMenu';
import { setPageParamsOnLoaded } from '../../utils/redactor.utils';
import { REDACTOR_CONTEXT_MENU } from '../../constants/contextmenu/context.menu.constants';
import { Shapes } from '../../constants/konva.constants';
import { IModalIdsData } from '../../redux/reducers/modal/modal.model';
import './redactor.styles.scss';
import { getReductionAuthHeader } from '../../redux/selectors/localStorage';
import { getPageWidthSize } from '../../utils/pdfControl.util';
import { getAutoAlignArticleStampsUserPreference } from '../../redux/selectors/stamps';
import { ERROR_PAGE_NOT_ENCRYPTED_OR_NOT_LOADED, NO_DOCUMENT_SELECTED, SELECT_A_DOCUMENT } from '../../constants/messages.constants';

class Redactor<T extends object> extends React.Component<IRedactorProps<T>, IRedactorState> {
    public documentComponent: React.RefObject<HTMLDivElement>;
    public documentPage: React.RefObject<HTMLDivElement>;
    public scrollbars: React.RefObject<Scrollbars>;
    public resizingWindow: React.RefObject<HTMLDivElement>;

    constructor(props: IRedactorProps<T>) {
        super(props);
        this.documentComponent = React.createRef();
        this.documentPage = React.createRef();
        this.scrollbars = React.createRef();
        this.resizingWindow = React.createRef();

        this.state = {
            bottomPage: false,
        };
    }

    public componentDidUpdate(prevProps: Readonly<IRedactorProps<T>>): void {
        const { pageInformation, redactionDocumentId, data } = this.props;
        const isPageExist = prevProps.pageInformation && pageInformation;

        if (this.props.data.pdfSrc !== prevProps.data.pdfSrc) {
            const isScrollExist = this.scrollbars && this.scrollbars.current;

            if (isScrollExist) {
                const { clientHeight, clientWidth } = this.scrollbars.current.getValues();

                this.props.setClientSize({
                    clientHeight,
                    clientWidth,
                });
            }
        }

        if (isPageExist && prevProps.pageInformation.pageId !== pageInformation.pageId) {
            const pageParams = {
                redactionDocumentId,
                documentId: pageInformation.documentId,
                pageId: pageInformation.pageId,
            };

            this.props.setSelectedShapes([], data.scale);

            // TODO backend said to use pageInformation.pageId if  pageInformation.layers doesn't exist
            const layerId = !!pageInformation.layers &&
                !!pageInformation.layers.length &&
                pageInformation.layers[0].id ||
                pageInformation.pageId;

            this.props.getPageAnnotations({
                ...pageParams,
                layerId,
            });

            this.props.getPageStamps(pageParams);

        }

        if (this.props.userMetaData.userId !== prevProps.userMetaData.userId) {
            this.props.getStampAlingment();

        }
    }

    public render(): JSX.Element {
        const { loading, data, redactionDocumentId, pageInformation, handleCloseSelects } = this.props;
        const scaledWidth = data.pdfWidth * data.scale;
        const scaledHeight = data.pdfHeight * data.scale;
        const rotatedWidth = data.rotate === degrees.ZERO || data.rotate === degrees.HALF ? scaledWidth : scaledHeight;
        const rotatedHeight = data.rotate === degrees.ZERO || data.rotate === degrees.HALF ? scaledHeight : scaledWidth;
        const documentWrapper = {
            minHeight: rotatedHeight ? rotatedHeight : 1000,
            minWidth: rotatedWidth ? rotatedWidth : 1000,
        };

        return (
            <div className='redactor-body' ref={this.resizingWindow}>
                {
                    loading ?
                        <Spinner active={loading} /> :
                        <Scrollbars

                            onUpdate={(values: IPositionValues): void => {

                                if ((values.clientHeight !== values.scrollHeight) && !this.state.bottomPage) {
                                    this.setState({ bottomPage: true });
                                } else if ((values.clientHeight === values.scrollHeight) && this.state.bottomPage) {
                                    this.setState({ bottomPage: false });
                                }

                                if ((values.clientHeight === values.scrollHeight) && this.state.bottomPage
                                    && (data.rotate === degrees.QUARTER || data.rotate === degrees.MINUS_QUARTER)) {
                                    this.setState({ bottomPage: false });
                                }

                            }}
                            ref={this.scrollbars}
                        >
                            {
                                this.props.data.pdfSrc
                                    ? this.props.data.pdfSrc === 'error'
                                        ? <div className='title'>
                                            <div>{ERROR_PAGE_NOT_ENCRYPTED_OR_NOT_LOADED}</div>
                                        </div>
                                        : (<div className='redactor_container'>
                                            <div
                                                className='redactor_wrapper'
                                                ref={this.documentComponent}
                                            >
                                                <div style={documentWrapper} onContextMenu={this.handleContextMenu}>
                                                    <ShapesLayer
                                                        handleCloseSelects={handleCloseSelects}
                                                        width={scaledWidth}
                                                        height={scaledHeight}
                                                        actionParams={{ redactionDocumentId, ...pageInformation }}
                                                        onContextMenu={this.handleContext}
                                                        scale={data.scale}
                                                        pageRotation={data.rotate}
                                                    >
                                                        <Document
                                                            file={data.pdfSrc}
                                                            loading={<Spinner active={loading} />}
                                                        >
                                                            <Page
                                                                ref={this.documentPage}
                                                                pageNumber={data.selectedPage}
                                                                onLoadSuccess={(page: ILoadedPage): void => {
                                                                    setPageParamsOnLoaded(
                                                                        page,
                                                                        this.props.setWidth,
                                                                        this.props.setHeight,
                                                                    );

                                                                    if (data.size === PAGE_WIDTH_VAL) {
                                                                        const scale = getPageWidthSize(
                                                                            data.rotate === 0 || data.rotate === 180
                                                                                ? page.view[2]
                                                                                : page.view[3]).pdfScale;

                                                                        this.props.pageScale(scale);
                                                                    }
                                                                }}
                                                                width={rotatedWidth / data.scale}
                                                                height={rotatedHeight / data.scale}
                                                                renderTextLayer={data.textLayer}
                                                                scale={data.scale}
                                                                rotate={data.rotate}

                                                            />
                                                        </Document>
                                                    </ShapesLayer>
                                                </div>

                                                {this.state.bottomPage && <div className='navigation-extra-space' />}
                                            </div>
                                        </div>)
                                    : <div className='title'>
                                        <div>{NO_DOCUMENT_SELECTED}</div>
                                        <div>{SELECT_A_DOCUMENT}</div>
                                    </div>
                            }

                        </Scrollbars>
                }
                <Navigation />
            </div>
        );
    }

    private handleContext = (elementType: Shapes, startPoint: IStartCoordinate, isCommentAvailable?: boolean): void => {
        const isBuffered = !isEmpty(this.props.buffer);

        if (elementType === Shapes.Stage) {
            if (!isBuffered) {
                this.props.setContextMenuItems([]);

                return;
            }

            this.openMenuWithParams([], isBuffered, startPoint);
        }

        if (elementType === Shapes.Text) {
            this.openMenuWithParams(
                [...deleteCutCopyAnnotationMenus, ...toggleSelectionMenu],
                isBuffered,
                startPoint,
            );
        }

        if (elementType === Shapes.Highlight) {
            this.openMenuWithParams(
                [...deleteCutCopyAnnotationMenus, ...highlightSpecificMenu, ...toggleSelectionMenu],
                isBuffered,
                startPoint,
            );
        }

        if (elementType === Shapes.Sever) {
            this.openMenuWithParams(
                [...deleteCutCopyAnnotationMenus, ...severSpecificMenu, ...toggleSelectionMenu],
                isBuffered,
                startPoint,
            );
        }

    }

    private openMenuWithParams = (params: IContextData[], isBuffered: boolean, startPoint: IStartCoordinate): void => {
        if (isBuffered) {
            this.props.setContextMenuItems([...params, pasteMenu]);
        } else {
            this.props.setContextMenuItems(params);
        }

        this.props.openContextMenu(
            REDACTOR_CONTEXT_MENU,
            { startPoint },
        );
    }

    private handleContextMenu = (event: React.MouseEvent): void => {
        event.preventDefault();

        const { show } = useContextMenu({ id: REDACTOR_CONTEXT_MENU });

        show(event);
    }

}

const mapStateToProps = (state: IState): IRedactorStateProps => ({
    data: getDocument(state),
    loading: getDocumentLoading(state),
    pageInformation: getPageInformation(state),
    redactionDocumentId: getRedactionDocumentId(state),
    buffer: getBuffer(state),
    auth: getReductionAuthHeader(state),
    stampAlingment: getAutoAlignArticleStampsUserPreference(state),
    userMetaData: getUserMetaData(state),
});

const mapDispatchToProps = <T extends object, K extends keyof T>
    (dispatch: IRedactorThunkDispatch<T>): IRedactorDispatchProps<T> => ({
        setHeight: (height: number): void => {
            dispatch(setPdfHeight(height));
        },
        setWidth: (width: number): void => {
            dispatch(setPdfWidth(width));
        },
        getPageAnnotations: (
            {
                redactionDocumentId,
                documentId,
                pageId,
                layerId,
            }: IPageActionParams): void => {
            dispatch(getAllAnnotationsPerPage(redactionDocumentId, documentId, pageId, layerId));
        },
        getPageStamps: (params: IPageActionParams): void => {
            dispatch(getPageStamps(params));
        },
        setContextMenuItems: (items: IContextData[]): void => {
            dispatch(setRedactorContextMenu(items));
        },
        openContextMenu: (type: string, contextProps: IModalIdsData): void => {
            dispatch(openContextMenu(type, contextProps));
        },
        setSelectedShapes: (data: T[], scale: number): void => {
            dispatch(setSelectedShapes(data, scale));
        },
        cleanSelectedShapes: (): void => {
            dispatch(cleanSelectedShapes());
        },
        setClientSize: (data: IClientSize): void => {
            dispatch(setClientSize(data));
        },
        pageScale: (scale: number): void => {
            dispatch(pageScale(scale));
        },
        getStampAlingment: (): void => {
            dispatch(setStampAlingment());
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(Redactor);

import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ResourceValueInput from '../containers/localization/ResourceValueInput';
import { ILabel, ILocalizationHeader } from 'redux/reducers/localization/localization.model';
import { IconButton } from '@mui/material';

export const RESOURCE_BUNDLE_LABEL = 'Resource Name';
export const LOCALIZATION_TITLE = 'Redaction Localization';
export const CANCEL = 'Cancel';
export const SAVE = 'Save';
export const RESOURCE_TYPE_PARAM = 'resourceType';
export const RESOURCE_TYPE_LABEL = 'ResourceType';
export const RESOURCE_KEY_PARAM = 'resourceKey';
export const RESOURCE_KEY_LABEL = 'ResourceKey';
export const RESOURCE_VALUE1_PARAM = 'resourceValue';
export const RESOURCE_VALUE1_LABEL = 'ResourceValue';
export const RESOURCE_VALUE2_PARAM = 'resourceValue2';
export const RESOURCE_VALUE2_LABEL = 'ResourceValue(French)';
export const FILTER_LABEL = 'Filters';
export const SYSTEM_MESSAGES_LABEL = 'System Messages';
export const SYSTEM_MESSAGES_PARAM = 'systemMessages';
export const SCREEN_LABELS_LABEL = 'Screen Labels';
export const SCREEN_LABELS_PARAM = 'screenLabels';
export const MESSAGE_KEY_LABEL = 'MessageKey';
export const MESSAGE_KEY_PARAM = 'messageKey';
export const MESSAGE_TEXT_LABEL = 'MessageText';
export const MESSAGE_TEXT_PARAM = 'messageText';
export const MESSAGE_TEXT2_LABEL = 'MessageText(French)';
export const MESSAGE_TEXT2_PARAM = 'messageText2';
export const MESSAGE_EDIT_PARAM = 'edit';
export const MESSAGE_COMMENTS_LABEL = 'MessageComments';
export const MESSAGE_COMMENTS_PARAM = 'messageComments';
export const SAVE_SUCCESS = 'Saved successfully';
export const SYSTEM_MESSAGE_MAX_CHAR_ERROR = 'should be less than 1000 characters';
export const SYSTEM_MESSAGE_MIN_CHAR_ERROR = 'Value should not be empty';
export const LOCALIZATION_MIN_CHAR = 1;
export const SYSTEM_MESSAGE_MAX_CHAR = 1000;
export const SCREEN_LABEL_MAX_CHAR = 255;
export const EDIT_SYSTEM_MESSAGE_MODAL = 'systemMessageEdit';
export const langFrench = 'fr';
export const langEnglish = 'en-gb';

export const getTableColumns = (
    handleChange: (key: ILabel, value: string, param: string) => void,
    ): ILocalizationHeader[]  => [
    {
        accessorKey: RESOURCE_TYPE_PARAM,
        id: RESOURCE_TYPE_PARAM,
        header: RESOURCE_TYPE_LABEL,
        minResizeWidth: 1,
        show: true,
        sortable: false,
    },
    {
        accessorKey: RESOURCE_KEY_PARAM,
        id: RESOURCE_KEY_PARAM,
        header: RESOURCE_KEY_LABEL,
        minResizeWidth: 1,
        show: true,
        sortable: false,
    },
    {
        accessorKey: RESOURCE_VALUE1_PARAM,
        id: RESOURCE_VALUE1_PARAM,
        header: RESOURCE_VALUE1_LABEL,
        minResizeWidth: 1,
        show: true,
        Cell: (cellInfo: any): JSX.Element => {
            return (
                <ResourceValueInput
                    initialValue={cellInfo.cell.row.original.resourceValue}
                    id={cellInfo.cell.row.original.resourceKey}
                    param={'resourceValue'}
                    type={'screenLabels'}
                    isDisabled={false}
                    handleChange={handleChange}
                    data={cellInfo.cell.row.original}
                />
            );
        },
        sortable: false,
    },
    {
        accessorKey: RESOURCE_VALUE2_PARAM,
        id: RESOURCE_VALUE2_PARAM,
        header: RESOURCE_VALUE2_LABEL,
        minResizeWidth: 1,
        show: true,
        Cell: (cellInfo: any): JSX.Element => (
            <ResourceValueInput
                initialValue={cellInfo.cell.row.original.resourceValue2}
                id={cellInfo.cell.row.original.resourceKey}
                param={'resourceValue2'}
                type={'screenLabels'}
                isDisabled={false}
                handleChange={handleChange}
                data={cellInfo.cell.row.original}
            />
        ),
        sortable: false,
    },
];

export const getMessagesTableColumns = (
    handleEdit: (date: ILabel) => void,
): ILocalizationHeader[] =>
    [
        {
            accessorKey: MESSAGE_KEY_PARAM,
            id: MESSAGE_KEY_PARAM,
            header: MESSAGE_KEY_LABEL,
            minResizeWidth: 1,
            show: true,
            sortable: false,
        },
        {
            accessorKey: MESSAGE_TEXT_PARAM,
            id: MESSAGE_TEXT_PARAM,
            header: MESSAGE_TEXT_LABEL,
            minResizeWidth: 1,
            show: true,
            sortable: false,
        },
        {
            accessorKey: MESSAGE_TEXT2_PARAM,
            id: MESSAGE_TEXT2_PARAM,
            header: MESSAGE_TEXT2_LABEL,
            minResizeWidth: 1,
            show: true,
            sortable: false,
        },
        {
            id: "MESSAGE_EDIT_PARAM",
            show: true,
            sortable: false,
            Cell: (cellInfo: any): any => (
                <IconButton onClick={(): void => handleEdit(cellInfo.cell.row.original)}>
                    <EditIcon />
                </IconButton>
            ),
            width: 80,
        },
    ];

export const FILTER_OPTIONS = [
    { value: RESOURCE_KEY_PARAM, label: RESOURCE_KEY_LABEL },
    { value: RESOURCE_VALUE1_PARAM, label: RESOURCE_VALUE1_LABEL },
    { value: RESOURCE_VALUE2_PARAM, label: RESOURCE_VALUE2_LABEL },
];

export const MESSAGES_FILTER_OPTIONS = [
    { value: MESSAGE_KEY_PARAM, label: MESSAGE_KEY_LABEL },
    { value: MESSAGE_TEXT_PARAM, label: MESSAGE_TEXT_LABEL },
    { value: MESSAGE_TEXT2_PARAM, label: MESSAGE_TEXT2_LABEL },
];

export const RESOURCE_TYPE_OPTIONS = [
    { value: 'DocumentQueue', label: 'DocumentQueue' },
    { value: 'DocumentList', label: 'DocumentList' },
    { value: 'AdvancedSearch', label: 'AdvancedSearch' },
    { value: 'CommonLabels', label: 'CommonLabels' },
    { value: 'SimpleSearch', label: 'SimpleSearch' },
    { value: 'MetaDataTab', label: 'MetaDataTab' },
    { value: 'ModalExport', label: 'ModalExport'},
    { value: 'ModalPackagePresets', label: 'ModalPackagePresets'},
    { value: 'ModalDisclosureManagement', label: 'ModalDisclosureManagement' },
    { value: 'ModalStackManagement', label: 'ModalStackManagement' },
    { value: 'ModalPagination', label: 'ModalPagination' },
    { value: 'ModalEditFilename', label: 'ModalEditFilename'},
    { value: 'ExportContentOptions', label: 'ExportContentOptions'},
    { value: 'IndexKeywordManagementModal', label: 'IndexKeywordManagementModal'},
    { value: 'PageList', label: 'PageList' },
    { value: 'Controls', label: 'Controls' },
    { value: 'FileHeader', label: 'FileHeader' },
    { value: 'ActionHeader', label: 'ActionHeader' },
    { value: 'DocumentHeader', label: 'DocumentHeader' },
    { value: 'PageHeader', label: 'PageHeader' },
    { value: 'AdminHeader', label: 'AdminHeader' },
    { value: 'HelpHeader', label: 'HelpHeader' },
    { value: 'SearchRequestModal', label: 'SearchRequestModal' },
    { value: 'UserPreferencesModal', label: 'UserPreferencesModal' },
    { value: 'ModalConsultees', label: 'ModalConsultees' },
    { value: 'AboutRedactionModal', label: 'AboutRedactionModal' },
    { value: 'KeyboardShortcutsModal', label: 'KeyboardShortcutsModal' },
    { value: 'EncryptionModal', label: 'EncryptionModal' },
    { value: 'AssociateWatermarkToRequestModal', label: 'AssociateWatermarkToRequestModal' },
    { value: 'WatermarkManagementModal', label: 'WatermarkManagementModal' },
    { value: 'ModalImport', label: 'ModalImport'},
    { value: 'ModalImportFromEDMS', label: 'ModalImportFromEDMS'},
    { value: 'UserDefinedStampManagementModal', label: 'UserDefinedStampManagementModal'},
    { value: 'AnnotationTypeManagementModal', label: 'AnnotationTypeManagementModal'},
    { value: 'RequestSecurityModal', label: 'RequestSecurityModal'},
    { value: 'UndoDisclosureModal', label: 'UndoDisclosureModal'},
    { value: 'DuplicateReferencesModal', label: 'DuplicateReferencesModal'},
    { value: 'GlobalSettingsModal', label: 'GlobalSettingsModal'},
    { value: 'ManageDuplicatesScreen', label: 'ManageDuplicatesScreen'},
    { value: 'AssignDuplicatesModal', label: 'AssignDuplicatesModal'},
    { value: 'ReferenceModal', label: 'ReferenceModal'},
    { value: 'AuditLogScreen', label: 'AuditLogScreen'},
    { value: 'ReportLanguageSelectionModal', label: 'ReportLanguageSelectionModal'},
    { value: 'IndexNotesReportLables', label: 'IndexNotesReportLables'},
    { value: 'OfficerReportLables', label: 'OfficerReportLables'},
    { value: 'RequesterReportLabels', label: 'RequesterReportLabels'},
    { value: 'PageReviewSummaryReportLables', label: 'PageReviewSummaryReportLables'},
];

export const LOCALIZATION_TYPE_OPTIONS = [
    { value: SCREEN_LABELS_PARAM, label: SCREEN_LABELS_LABEL },
    { value: SYSTEM_MESSAGES_PARAM, label: SYSTEM_MESSAGES_LABEL },
];

export const initialLabel = {
    resourceKey: '',
    resourceType: '',
    resourceValue: '',
    resourceValue2: '',
};

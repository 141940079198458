import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import { saveAs } from 'file-saver';
import {
    GET_AUDIT_ACTIONS_PENDING,
    GET_AUDIT_ACTIONS_SUCCESS,
    GET_AUDIT_ACTIONS_FAILURE,
    GET_AUDIT_LOGS_PENDING,
    GET_AUDIT_LOGS_SUCCESS,
    GET_AUDIT_LOGS_FAILURE,
    EXPORT_AUDIT_LOGS_PENDING,
    EXPORT_AUDIT_LOGS_SUCCESS,
    EXPORT_AUDIT_LOGS_FAILURE,
    GET_AUDIT_LOGS_CLEAR,
    MODIFY_AUDIT_LOG_COLUMN_HEADERS,
    SET_AUDIT_LOG_COLUMN_LOADING_FLAG,
} from '../reducers/auditLog/constants';
import { action } from 'typesafe-actions';
import { IError } from '../common.model';
import { IAuditAction, IAuditHeader, IAuditSearchDto, IAuditSearchResponse } from '../reducers/auditLog/auditLog.model';
import { EXPORT_CSV_NAME, EXPORT_CSV_TYPE } from '../../constants';
import { addError } from './errorHandling';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IState } from '../store';

export const fetchAuditActionsPending = (): AnyAction => action(GET_AUDIT_ACTIONS_PENDING);
export const fetchAuditActionsSuccess = (data: IAuditAction[]): AnyAction =>
    action(GET_AUDIT_ACTIONS_SUCCESS, data);
export const fetchAuditActionsFailure = (error: IError): AnyAction => action(GET_AUDIT_ACTIONS_FAILURE, error);

export const changeAuditLogColumnHeaders = (headers: IAuditHeader[]): AnyAction =>
    action(MODIFY_AUDIT_LOG_COLUMN_HEADERS, headers);
export const setAuditLogLoadingFlag = (flag: boolean): AnyAction =>
    action(SET_AUDIT_LOG_COLUMN_LOADING_FLAG, flag);

export const fetchAuditLogsPending = (): AnyAction => action(GET_AUDIT_LOGS_PENDING);
export const fetchAuditLogsSuccess = (data: IAuditSearchResponse[]): AnyAction =>
    action(GET_AUDIT_LOGS_SUCCESS, data);
export const fetchAuditLogsFailure = (error: IError): AnyAction => action(GET_AUDIT_LOGS_FAILURE, error);
export const fetchAuditLogsClear = (): AnyAction => action(GET_AUDIT_LOGS_CLEAR);

export const exportAuditActionsPending = (): AnyAction => action(EXPORT_AUDIT_LOGS_PENDING);
export const exportAuditActionsSuccess = (): AnyAction => action(EXPORT_AUDIT_LOGS_SUCCESS);
export const exportAuditActionsFailure = (error: IError): AnyAction => action(EXPORT_AUDIT_LOGS_FAILURE, error);

export const fetchAuditActions = (): (dispatch: Dispatch<AnyAction>) =>
    Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchAuditActionsPending());
    try {
        const data = await api.auditController.getAuditActions();

        dispatch(fetchAuditActionsSuccess(data));
    } catch (error) {
        dispatch(fetchAuditActionsFailure(error));
    }
};

export const fetchAuditLogs = (auditSearchDto: IAuditSearchDto): (dispatch: Dispatch<AnyAction>) =>
    Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchAuditLogsPending());
    try {
        const data = await api.auditController.getSearchAuditLogs(auditSearchDto);

        dispatch(fetchAuditLogsSuccess(data));
    } catch (error) {
        dispatch(fetchAuditLogsFailure(error));
        dispatch(addError(error));
    }
};

export const resetAuditLogColumnHeaders = (auditSearchDto: IAuditSearchDto): (dispatch: Dispatch<AnyAction>) =>
    Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchAuditLogsPending());
    dispatch(setAuditLogLoadingFlag(true));
    try {
        const data = await api.auditController.getSearchAuditLogs(auditSearchDto);

        dispatch(fetchAuditLogsSuccess(data));
        dispatch(setAuditLogLoadingFlag(false));
    } catch (error) {
        dispatch(fetchAuditLogsFailure(error));
        dispatch(setAuditLogLoadingFlag(false));
        dispatch(addError(error));
    }
};

export const exportAuditLogCSV = (data?: IAuditSearchDto): (dispatch: Dispatch<AnyAction>) =>
    Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(exportAuditActionsPending());
    try {
        let response;

        if (data) {
            response = await api.auditController.exportAuditLogs(data);
        } else {
            response = await api.auditController.exportAllAuditLogs();
        }

        dispatch(exportAuditActionsSuccess());

        const exportDoc = new Blob([response], { type: EXPORT_CSV_TYPE });

        saveAs(exportDoc, EXPORT_CSV_NAME);
    } catch (error) {
        dispatch(exportAuditActionsFailure(error));
        dispatch(addError(error));
    }
};

export const confirmSaveBeforeErase = (): (dispatch: ThunkDispatch<IState, null, AnyAction>) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
    dispatch(exportAuditActionsPending());
    try {
        const response = await api.auditController.exportAllAuditLogs();

        dispatch(exportAuditActionsSuccess());

        const exportDoc = new Blob([response], { type: EXPORT_CSV_TYPE });

        saveAs(exportDoc, EXPORT_CSV_NAME);
        dispatch(eraseAuditLogs());
    } catch (error) {
        dispatch(exportAuditActionsFailure(error));
        dispatch(addError(error));
    }
};

export const eraseAuditLogs = (): (dispatch: Dispatch<AnyAction>) =>
    Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchAuditLogsPending());
    try {
        const data = await api.auditController.eraseAuditLogs();

        dispatch(fetchAuditLogsSuccess([]));
    } catch (error) {
        dispatch(addError(error));
    }
};

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { REDACTION_LANG } from '../../redux/reducers/localStorage/constant';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import {
    MODIFY_SEARCH_RESULT,
    SET_AUTH_FLAG,
    SET_MODIFIED_LABELS_FAILURE,
    SET_MODIFIED_LABELS_PENDING,
    SET_MODIFIED_LABELS_SUCCESS,
    SET_ORIGINAL_SEARCH_RESULT,
    SET_SEARCH_RESULTS_FAILURE,
    SET_SEARCH_RESULTS_PENDING,
    SET_SEARCH_RESULTS_SUCCESS,
    SET_SELECTED_SYSTEM_MESSAGE,
    UPDATE_SYSTEM_MESSAGES_FAILURE,
    UPDATE_SYSTEM_MESSAGES_PENDING,
    UPDATE_SYSTEM_MESSAGES_SUCCESS
} from '../reducers/localization/constants';
import { IState } from '../store';
import { addError, addSuccessNotification } from './errorHandling';
import { ILabel, ISearchData } from 'redux/reducers/localization/localization.model';
import { initialLabel, SAVE_SUCCESS } from '../../constants/localization.constants';

export const setSearchResult = (results: ILabel[]): AnyAction => action(SET_SEARCH_RESULTS_SUCCESS, results);
export const setSearchResultsPending = (): AnyAction => action(SET_SEARCH_RESULTS_PENDING);
export const setSearchResultsFailure = (): AnyAction => action(SET_SEARCH_RESULTS_FAILURE);

export const updateSystemMessageSuccess = (modifiedMessage: ILabel): AnyAction =>
    action(UPDATE_SYSTEM_MESSAGES_SUCCESS, modifiedMessage);
export const updateSystemMessagePending = (): AnyAction => action(UPDATE_SYSTEM_MESSAGES_PENDING);
export const updateSystemMessageFailure = (): AnyAction => action(UPDATE_SYSTEM_MESSAGES_FAILURE);

export const setOriginalSearchResult = (results: ILabel[]): AnyAction => action(SET_ORIGINAL_SEARCH_RESULT, results);

export const modifySearchResult = (result: ILabel): AnyAction => action(MODIFY_SEARCH_RESULT, result);

export const selectSystemMessageForEdit = (message: ILabel | null): AnyAction =>
    action(SET_SELECTED_SYSTEM_MESSAGE, message);

export const setModifiedScreenLabelsPending = (): AnyAction =>
    action(SET_MODIFIED_LABELS_PENDING);
export const setModifiedScreenLabelsSuccess = (labels: ILabel[]): AnyAction =>
    action(SET_MODIFIED_LABELS_SUCCESS, labels);
export const setModifiedScreenLabelsFailure = (): AnyAction =>
    action(SET_MODIFIED_LABELS_FAILURE);

export const setAuthFlag = (flag: boolean): AnyAction => action(SET_AUTH_FLAG, flag);

export const getModifiedScreenLabels = ():
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(setModifiedScreenLabelsPending());
        try {
            const response = await api.localization.getModifiedScreenLabels();

            dispatch(setModifiedScreenLabelsSuccess(response));
        } catch(error) {
            dispatch(setModifiedScreenLabelsFailure());
            dispatch(addError(error));
        }
};

export const updateScreenLabels = (labels: ILabel[], searchResults: ILabel[]):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(setModifiedScreenLabelsPending());
        try {
            const response = await api.localization.updateScreenLabels(labels);

            dispatch(setModifiedScreenLabelsSuccess(response));
            dispatch(setOriginalSearchResult(searchResults));
            dispatch(addSuccessNotification({message: SAVE_SUCCESS,
                id: 'Label Save',
            }));
        } catch(error) {
            dispatch(setModifiedScreenLabelsFailure());
            dispatch(addError(error));
        }
};

export const searchSystemMessages = (data: ISearchData):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(setSearchResultsPending());
        try {
            const response = await api.localization.searchSystemMessages(data);

            dispatch(setSearchResult(response));
        } catch(error) {
            dispatch(setSearchResultsFailure());
            dispatch(addError(error));
        }
};

export const updateSystemMessages = (data: ILabel):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(updateSystemMessagePending());
        try {
            const response = await api.localization.updateSystemMessages([data]);

            dispatch(updateSystemMessageSuccess(response[0]));
            dispatch(addSuccessNotification({message: SAVE_SUCCESS,
                id: 'Message save',
            }));
            dispatch(selectSystemMessageForEdit(null));
        } catch(error) {
            dispatch(addError(error));
            dispatch(updateSystemMessageFailure());
        }
};

export const changeLang = (language: string | null): string => {
    return language ? language === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';
};

export const getCommonLabelsByKey = (key: string): string => {
    switch(key) {
        case 'COMMON_LABEL_WARNING':
            return 'warningLabel';
        case 'COMMON_LABEL_ADD_NEW':
            return 'addNewLabel';
        case 'COMMON_LABEL_SAVE':
            return 'saveLabel';
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'COMMON_LABEL_OK':
            return 'okLabel';
        case 'COMMON_LABEL_FIELD_IS_MANDATORY':
            return 'mandatoryFieldLabel';
        case 'COMMON_LABEL_CHANGES_NOT_SAVED':
            return 'changesNotSavedLabel';
        case 'COMMON_LABEL_MUST_BE_UNIQUE':
            return 'mustBeUniqueLabel';
        case 'COMMON_LABEL_NO_ROWS_FOUND':
            return 'noRowsFoundLabel';
        case 'COMMON_LABEL_RESET':
            return 'resetLabel';
        case 'COMMON_LABEL_SHOW_ALL':
            return 'showAllLabel';
        case 'COMMON_LABEL_HIDE_ALL':
            return 'hideAllLabel';
        default: return '';
    }
};

export const commonLabels = {
    warningLabel: initialLabel,
    addNewLabel: initialLabel,
    saveLabel: initialLabel,
    cancelLabel: initialLabel,
    okLabel: initialLabel,
    mandatoryFieldLabel: initialLabel,
    changesNotSavedLabel: initialLabel,
    mustBeUniqueLabel: initialLabel,
    noRowsFoundLabel: initialLabel,
    resetLabel: initialLabel,
    showAllLabel: initialLabel,
    hideAllLabel: initialLabel,
};

import { createSelector } from 'reselect';
import { IState } from '../store';
import { getFoiFolderPending } from './modalSearchDocument';
import {
    IRequestSearchState,
    IResultRequestSearch,
    IOfficerRequest,
} from '../reducers/modalRequestSearch/requestSearch.model';

const getModalRequestSearch = (state: IState): IRequestSearchState => state.modalRequestSearch;

export const getRequestResultSearch = createSelector(
    [getModalRequestSearch],
    (modalRequestSearch: IRequestSearchState): IResultRequestSearch[] => modalRequestSearch.requestResultSearch,
);

export const getOfficers = createSelector(
    [getModalRequestSearch],
    (modalRequestSearch: IRequestSearchState): IOfficerRequest[] => modalRequestSearch.officers,
);

export const getModalLoading = createSelector(
    [getModalRequestSearch, getFoiFolderPending],
    (modalRequestSearch: IRequestSearchState, foiFolderPending: boolean): boolean =>
        modalRequestSearch.officersPending || foiFolderPending,
);

export const getRequestResultSearchLoading = createSelector(
    [getModalRequestSearch],
    (modalRequestSearch: IRequestSearchState): boolean => modalRequestSearch.requestResultSearchPending,
);

export const getTableRequestResultSearch = createSelector(
    [getRequestResultSearch],
    (requestResultSearch: IResultRequestSearch[]): any[] =>
        requestResultSearch.map((result: IResultRequestSearch): any => ({
            id: result.folderRSN,
            requestNumber: result.requestNumber,
            summary: result.summary,
            dueData: result.dueDate,
            officer: result.officer,
            folderRsn: result.folderRSN,
            accessorKey: result.accessor ,
            header: result.Header,
            sortOrder: null
        }),
    ),
);
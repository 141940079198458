export const GET_WATERMARKS_SUCCESS = 'reduction/GET_WATERMARKS_SUCCESS';
export const GET_WATERMARKS_FAILURE = 'reduction/GET_WATERMARKS_FAILURE';
export const GET_WATERMARKS_PENDING = 'reduction/GET_WATERMARKS_PENDING';

export const PUT_WATERMARK_SUCCESS = 'reduction/PUT_WATERMARK_SUCCESS';
export const PUT_WATERMARK_FAILURE = 'reduction/PUT_WATERMARK_FAILURE';
export const PUT_WATERMARK_PENDING = 'reduction/PUT_WATERMARK_PENDING';

export const POST_WATERMARK_SUCCESS = 'reduction/POST_WATERMARK_SUCCESS';
export const POST_WATERMARK_FAILURE = 'reduction/POST_WATERMARK_FAILURE';
export const POST_WATERMARK_PENDING = 'reduction/POST_WATERMARK_PENDING';

export const DELETE_WATERMARK_SUCCESS = 'reduction/DELETE_WATERMARK_SUCCESS';
export const DELETE_WATERMARK_FAILURE = 'reduction/DELETE_WATERMARK_FAILURE';
export const DELETE_WATERMARK_PENDING = 'reduction/DELETE_WATERMARK_PENDING';

export const SET_OPTIONS_WATERMARK = 'reduction/SET_OPTIONS_WATERMARK';
export const CHANGE_OPTIONS_WATERMARK = 'reduction/CHANGE_OPTIONS_WATERMARK';
export const CLEAR_OPTIONS_WATERMARK = 'reduction/CLEAR_OPTIONS_WATERMARK';

export const GET_WATERMARK_IMAGE_FAILURE = 'reduction/GET_WATERMARK_IMAGE_FAILURE';
export const GET_WATERMARK_IMAGE_PENDING = 'reduction/GET_WATERMARK_IMAGE_PENDING';

import { MAX_LENGTH, REQUIRED, UNIQUE } from '../../../constants/validate.constants';

export const ACTIVE = 'Active';
export const IS_ACTIVE = 'isActive';
export const DATABASE = 'Database';
export const DESCRIPTION = 'Description: ';
export const ENCRYPTION_METHOD_TITLE = 'Method:';
export const ENCRYPTION_METHOD = 'AES-256';
export const KEYSTORE = 'Keystore:';
export const NAME = 'name';
export const MUST_BE_ACTIVE = 'At least one encryption must be active';
export const EMPTY_ENCRYPT_ID = 0;
export const validateFields = [
    {
        field: NAME,
        [REQUIRED]: true,
        [MAX_LENGTH]: 250,
        [UNIQUE]: true,
    },
];
export const emptyEncryption = {
    id: EMPTY_ENCRYPT_ID,
    name: '',
    isActive: false,
};

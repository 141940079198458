import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Scrollbars } from 'rc-scrollbars';
import {
    CheckboxMaterialBuilder,
    RadioMaterialBuilder,
    SelectMaterialBuilder,
} from '../../../../components/materialUiForms/materialUiFormBuilder';
import * as constants from '../../../../constants/export.contstants';
import { INCREMENT_BY_PARAM, STARTING_NUMBER_PARAM } from '../../../../constants/export.contstants';
import { RenderColorPickerInput } from '../../../../components/materialUiForms/materialUiForms';
import { IPaginationOptions } from '../../../../redux/reducers/modalWindowExport/modalWindowExport.model';
import { usePrevious } from '../../../../hooks/usePrevious';
import { useSelector } from 'react-redux';
import { getModifiedLabels } from '../../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../../redux/selectors/localStorage';
import { initialLabel } from '../../../../constants/localization.constants';
import resourceBundle from '../../../localization/localizationData';
import { changeLang } from '../../../../redux/actions/localization';

export type IPaginationInputType = number | string;

export interface IPaginationOptionChange {
    [name: string]: string | number | boolean;
}

const getPaginationLabelsByKey = (key: string): string => {
    switch(key) {
        case 'COMMON_LABEL_POSITION_BOTTOM_LEFT':
            return 'bottomLeftLabel';
        case 'COMMON_LABEL_POSITION_BOTTOM_CENTER':
            return 'bottomCenterLabel';
        case 'COMMON_LABEL_POSITION_BOTTOM_RIGHT':
            return 'bottomRightLabel';
        case 'COMMON_LABEL_POSITION_TOP_LEFT':
            return 'topLeftLabel';
        case 'COMMON_LABEL_POSITION_TOP_CENTER':
            return 'topCenterLabel';
        case 'COMMON_LABEL_POSITION_TOP_RIGHT':
            return 'topRightLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_PAGINATION_MODE':
            return 'paginationModeLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_BASIC':
            return 'basicLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_ADVANCED':
            return 'advancedLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_OFF':
            return 'offLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_FONT_SIZE':
            return 'fontSizeLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_FONT_COLOR':
            return 'fontColorLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_LOCATION':
            return 'locationLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_STAMP_DOC_NAME_PAGE_INDEX':
            return 'stampDocNamePageIndexLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_STARTING_NUMBER':
            return 'startingNumberLabel';
        case 'MODAL_EXPORT_PAGINATION_TAB_INCREMENT_BY':
            return 'incrementByLabel';
        case '':
            return '';
        default: return '';
    }
};

const FONT_SIZE_PARAMS = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
const MIN_NUMBER = 1;
const MAX_START_NUMBER = 9999999;
const MAX_INC_NUMBER = 10000;

const PaginationOptions = ({
    paginationOptions,
    isExportModal,
    handlePaginationOptionsChange,
    selectedPresetId,
    hasEditPermission = true,
}: {
    handlePaginationOptionsChange: (data: IPaginationOptionChange) => void;
    paginationOptions: IPaginationOptions;
    title?: string;
    isValid?: boolean;
    isExportModal?: boolean;
    selectedPresetId?: number;
    hasEditPermission?: boolean;
}): JSX.Element => {
    const [isOpen, usePopoverState] = useState(false);
    const [fontSizeValue, useFontSizeValue] =
        useState<IPaginationInputType>(paginationOptions[constants.FONT_SIZE_PARAM]);
    const [startNumber, useStartNumber] =
        useState<IPaginationInputType>(paginationOptions[constants.STARTING_NUMBER_PARAM]);
    const [incNumber, useIncNumber] =
        useState<IPaginationInputType>(paginationOptions[constants.INCREMENT_BY_PARAM]);

    const prevId = usePrevious(selectedPresetId);
    const prevIncNumber = usePrevious(paginationOptions[INCREMENT_BY_PARAM]);
    const prevStartNumber = usePrevious(paginationOptions[STARTING_NUMBER_PARAM]);
    const modifiedLabels = useSelector(getModifiedLabels);
    const redactionLang = useSelector(getRedactionLanguage);
    const langRule = changeLang(redactionLang);
    const labels = {
        bottomLeftLabel: initialLabel,
        bottomCenterLabel: initialLabel,
        bottomRightLabel: initialLabel,
        topLeftLabel: initialLabel,
        topCenterLabel: initialLabel,
        topRightLabel: initialLabel,
        paginationModeLabel: initialLabel,
        basicLabel: initialLabel,
        advancedLabel: initialLabel,
        offLabel: initialLabel,
        fontSizeLabel: initialLabel,
        fontColorLabel: initialLabel,
        locationLabel: initialLabel,
        stampDocNamePageIndexLabel: initialLabel,
        startingNumberLabel: initialLabel,
        incrementByLabel: initialLabel,
    };

    resourceBundle.map((resource: any) => {
        if (getPaginationLabelsByKey(resource.resourceKey)) {
            labels[getPaginationLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getPaginationLabelsByKey(resource.resourceKey)) {
            labels[getPaginationLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    useEffect(() => {
        if (!isExportModal && prevId !== selectedPresetId) {
            useStartNumber(paginationOptions[constants.STARTING_NUMBER_PARAM]);
            useIncNumber(paginationOptions[constants.INCREMENT_BY_PARAM]);
            useFontSizeValue(paginationOptions[constants.FONT_SIZE_PARAM]);
        }

        if (isExportModal && prevIncNumber !== paginationOptions[INCREMENT_BY_PARAM] ||
            prevStartNumber !== paginationOptions[STARTING_NUMBER_PARAM]
        ) {
            useStartNumber(paginationOptions[constants.STARTING_NUMBER_PARAM]);
            useIncNumber(paginationOptions[constants.INCREMENT_BY_PARAM]);
            useFontSizeValue(paginationOptions[constants.FONT_SIZE_PARAM]);
        }
    });

    const isFontParamDisabled =
        paginationOptions[constants.PAGINATION_MODE_PARAM] === constants.PAGINATION_TYPE_OFF_PARAM;
    const isPaginationParamDisabled =
        paginationOptions[constants.PAGINATION_MODE_PARAM] !== constants.PAGINATION_TYPE_ADVANCED_PARAM;
    const isPaginationOffModeStampDocumentNameOrPageIndexParamEnabled =
        paginationOptions[constants.PAGINATION_MODE_PARAM] === constants.PAGINATION_TYPE_OFF_PARAM;

    const validateOnlyNumber = (value: IPaginationInputType): boolean => {
        return /^\d+$/.test(value as string);
    };

    const validateNumberRange = (min: number, max: number, value: IPaginationInputType): any => {
        return Number(value) <= max && Number(value) >= min;
    };

    const handleStartNumber = (param: string, value: IPaginationInputType): void => {
        if (validateNumberRange(MIN_NUMBER, MAX_START_NUMBER, value) || value === '') {
            useStartNumber(value);
        }
    };

    const handleIncNumber = (param: string, value: IPaginationInputType): void => {
        if (validateNumberRange(MIN_NUMBER, MAX_INC_NUMBER, value) || value === '') {
            useIncNumber(value);
        }
    };

    const handleRangeNumbersKeyUp = (): void => {
        handleRangeNumbersChange(startNumber, incNumber);
    };

    const handleRangeNumbersChange = (startNumberParam?: number | string, incNumberParam?: number | string): void => {
        handleChange(constants.STARTING_NUMBER_PARAM, Number(startNumberParam) || '');
        handleChange(constants.INCREMENT_BY_PARAM, Number(incNumberParam) || '');
    };

    const handleFontSizeOptions = (value: IPaginationInputType): void => {
        if (validateOnlyNumber(value) || value === '') {
            useFontSizeValue(value);
        }
    };

    const handleFontSizeBlur = (): void => {
        const defaultFontSize = 12;
        const minFontSize = FONT_SIZE_PARAMS[0];
        const maxFontSize = FONT_SIZE_PARAMS[FONT_SIZE_PARAMS.length - 1];
        const range = validateNumberRange(minFontSize, maxFontSize, fontSizeValue);

        if (!range) {
            handleChange(constants.FONT_SIZE_PARAM, defaultFontSize);
            useFontSizeValue(defaultFontSize);
        } else {
            handleChange(constants.FONT_SIZE_PARAM, Number(fontSizeValue));
        }
    };

    const updateParamOption = (): void => {
        handleStartNumber(constants.STARTING_NUMBER_PARAM, '');
        handleIncNumber(constants.INCREMENT_BY_PARAM, '');
        handleRangeNumbersChange();
    };

    const handleChange = (param: string, value: string | number | boolean): void => {

        handlePaginationOptionsChange({ [param]: value });

        if (param === constants.PAGINATION_MODE_PARAM && value === constants.PAGINATION_TYPE_OFF_PARAM) {
            updateParamOption();
        }

        if (param === constants.PAGINATION_MODE_PARAM && value === constants.PAGINATION_TYPE_BASIC_PARAM) {
            updateParamOption();
        }
    };

    const handleToggle = (): void => {
        usePopoverState(!isOpen);
    };

    const handleClose = (event: React.ChangeEvent<{}>, item: number): void => {
        if (item) {
            useFontSizeValue(item);
            handleChange(constants.FONT_SIZE_PARAM, item);
        }

        usePopoverState(false);
    };

    const handleClickAway = (): void => {
        usePopoverState(false);
    };

    const disabledComboboxClass = classnames('combobox-icon', { disabled: isFontParamDisabled });
    const disabledColorpickerClass = classnames('color-picker-label', {
        disabled: isPaginationOffModeStampDocumentNameOrPageIndexParamEnabled
            ? !paginationOptions.stampDocumentNameIndex : isFontParamDisabled,
    });

    return (
        <div className='pagination-tab tab'>
            <div className='radio-wrapper'>
                <FormLabel>{labels.paginationModeLabel[langRule]}: </FormLabel>
                <RadioMaterialBuilder
                    value={paginationOptions[constants.PAGINATION_MODE_PARAM]}
                    handleRadioChange={handleChange}
                    paramName={constants.PAGINATION_MODE_PARAM}
                    ariaLabel={constants.PAGINATION_MODE_PARAM}
                    radioList={constants.paginationModeOptions(labels, langRule)}
                    disabled={!hasEditPermission}
                />
            </div>
            <div className='select-group'>
                <div className='select-group'>
                    <div className='combobox-wrapper'>
                        <TextField
                            multiline={false}
                            minRows='4'
                            label={labels.fontSizeLabel[langRule]}
                            variant='outlined'
                            value={fontSizeValue || ''}
                            onChange={
                                (e: React.ChangeEvent<Element>): void =>
                                    handleFontSizeOptions((e.target as HTMLInputElement).value)
                            }
                            onBlur={handleFontSizeBlur}
                            margin='dense'
                            fullWidth={true}
                            error={false}
                            disabled={isPaginationOffModeStampDocumentNameOrPageIndexParamEnabled
                                ? !paginationOptions.stampDocumentNameIndex :
                                isFontParamDisabled || !hasEditPermission}
                        />
                        <div className={disabledComboboxClass} onClick={handleToggle}>
                            <ArrowDropDown classes={{ root: 'arrow-down' }} />
                        </div>
                    </div>
                    <Popper
                        open={isOpen}
                        anchorEl={null}
                        transition={true}
                        disablePortal={true}
                        style={{ zIndex: 2 }}
                    >
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper
                                    style={{
                                        position: 'absolute',
                                        width: 250,
                                        height: 270,
                                        marginTop: 250,
                                        marginLeft: isExportModal ? 340: 490,
                                        zIndex: 100,
                                    }}
                                >
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <Scrollbars>
                                            <MenuList>
                                                {FONT_SIZE_PARAMS.map((item: number) => (
                                                    <MenuItem
                                                        onClick={(e: React.ChangeEvent<{}>): void => handleClose(e, item)}
                                                        key={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </Scrollbars>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </div>
            <div className='content-option-tab__color-picker'>
                <RenderColorPickerInput
                    color={paginationOptions.fontColor}
                    handleChange={(colorHex: string): void => handleChange(constants.FONT_COLOR_PARAM, colorHex)}
                    error=''
                    isDisabled={isPaginationOffModeStampDocumentNameOrPageIndexParamEnabled
                        ? !paginationOptions.stampDocumentNameIndex :
                        isFontParamDisabled || !hasEditPermission}
                />
                <span className={disabledColorpickerClass}>{labels.fontColorLabel[langRule]}</span>
            </div>
            <div className='select-group'>
                <SelectMaterialBuilder
                    value={paginationOptions[constants.LOCATION_PARAM]}
                    label={labels.locationLabel[langRule]}
                    paramName={constants.LOCATION_PARAM}
                    handleSelectChange={handleChange}
                    options={constants.paginationLocation(labels, langRule)}
                    disabled={isPaginationOffModeStampDocumentNameOrPageIndexParamEnabled
                        ? !paginationOptions.stampDocumentNameIndex :
                        isFontParamDisabled || !hasEditPermission}
                />
            </div>
            <CheckboxMaterialBuilder
                type={constants.CHECKBOX_TYPE_SWITCH}
                label={labels.stampDocNamePageIndexLabel[langRule]}
                checked={paginationOptions[constants.STAMP_DOC_INDEX_PARAM]}
                handleCheckboxChange={handleChange}
                paramName={constants.STAMP_DOC_INDEX_PARAM}
                disabled={!hasEditPermission}
            />
            <div className='select-group'>
                <TextField
                    multiline={false}
                    minRows='4'
                    label={labels.startingNumberLabel[langRule]}
                    variant='outlined'
                    value={startNumber || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                        handleStartNumber(constants.STARTING_NUMBER_PARAM, (e.target as HTMLInputElement).value)
                    }
                    onKeyUp={handleRangeNumbersKeyUp}
                    fullWidth={true}
                    error={!isPaginationParamDisabled && !!paginationOptions[constants.INCREMENT_BY_PARAM]
                        && !startNumber}
                    disabled={isPaginationParamDisabled || !hasEditPermission}
                />
            </div>
            <div className='select-group'>
                <TextField
                    multiline={false}
                    minRows='4'
                    label={labels.incrementByLabel[langRule]}
                    variant='outlined'
                    value={incNumber || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                        handleIncNumber(constants.INCREMENT_BY_PARAM, (e.target as HTMLInputElement).value)
                    }
                    onKeyUp={handleRangeNumbersKeyUp}
                    fullWidth={true}
                    error={!isPaginationParamDisabled && !!paginationOptions[constants.STARTING_NUMBER_PARAM]
                        && !incNumber}
                    disabled={isPaginationParamDisabled || !hasEditPermission}
                />
            </div>
        </div>
    );
};

export default PaginationOptions;

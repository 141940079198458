import { SET_TABLE_SORT } from '../../actions/commonTypes';
import { ITableState, PageData } from './common.model';

const initialState: ITableState = {
    sortParams : []
};

const tableReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_TABLE_SORT:
            const sortParams = state.sortParams.slice();
            const index = sortParams.findIndex(data => data.pageName === action.payload.pageName);

            if (index !== -1) {
                sortParams[index] = {
                    ...sortParams[index],
                    columnName: action.payload.columnName,
                    sortOrder: action.payload.sortOrder
                };
            } else {
                sortParams.push({
                    pageName: action.payload.pageName,
                    columnName: action.payload.columnName,
                    sortOrder: action.payload.sortOrder
                });
            }
            return {
                ...state,
                sortParams
            };

        default:
            return state;
    }
};

export default tableReducer;

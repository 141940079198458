if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, 'findIndex', {
        // Used any. Because element of Array can be any value.
        value(predicate: any): number {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            const o = Object(this);
            const len = o.length;

            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            const thisArg = arguments[1];
            let k = 0;

            while (k < len) {
                const kValue = o[k];

                if (predicate.call(thisArg, kValue, k, o)) {
                    return k;
                }

                k++;
            }

            return -1;
        },
    });
}

import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    IAssignDuplicatesState,
} from '../reducers/modalAssignDuplicates/modalAssignDuplicates.model';

export const getAssignDuplicates = (state: IState): IAssignDuplicatesState => state.modalAssignDuplicates;

export const getAssignDuplicatesPending = createSelector(
    [getAssignDuplicates],
    (modalAssignDuplicates: IAssignDuplicatesState): boolean => modalAssignDuplicates.assignDuplicatesPending,
);

import * as React from 'react';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import DynamicForm from './DynamicFormWrapper';
import {
    confirmDeleteStampType,
    postStamp,
    putStamp,
    changeLocalStampList,
} from '../../../redux/actions/layoutRedactorTypes';
import { fetchStampList } from '../../../redux/actions/layoutRedactorTypes';
import { Spinner } from '../../../components/spinner/Spinner';
import {
    getStampsList,
    getStampsListLoading,
    getUpdateStampsListLoading,
    getLocalStampsList,
} from '../../../redux/selectors/redactor';
import {
    IUserDefinedModalProps,
    IUserDefinedModalStateProps,
    IUserDefinedModalDispatchProps,
} from './modalLayerTypes.model';
import { IState as StoreState } from '../../../redux/store';
import { IStampType } from '../../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { changeLang } from '../../../redux/actions/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { isEqual } from 'lodash';

const getUserDefinedStampsLabelsByKey = (key: string): string => {
    switch (key) {
        case 'USER_DEFINED_STAMP_MANAGEMENT_MODAL_TEXT':
            return 'textLabel';
        case 'USER_DEFINED_STAMP_MANAGEMENT_MODAL_DESCRIPTION':
            return 'descriptionLabel';
        case 'USER_DEFINED_STAMP_MANAGEMENT_MODAL_ACTIVE':
            return 'activeLabel';
        default: return '';
    }
};
const labels = {
    textLabel: initialLabel,
    descriptionLabel: initialLabel,
    activeLabel: initialLabel,
};

class UserDefinedStamps extends React.Component<IUserDefinedModalProps> {

    public componentDidMount(): void {
        this.props.fetchStamps();
    }

    public componentWillUnmount(): void {
        this.props.handleDetectChanges(false);
    }

    public render(): JSX.Element {
        const { stampsTypeList, onCloseModal, loading, localStampsTypeList, permissions, modifiedLabels, redactionLang } = this.props;
        const uniqueField = {
            text: localStampsTypeList.map((stamp: IStampType): string => stamp && stamp.text),
        };
        const defaultData = {
            isActive: false,
        };
        const langRule = changeLang(redactionLang);
        const formFields = [
            {
                field: 'text',
                label: labels.textLabel[langRule],
                className: 'grow-2',
                type: 'textarea',
                isRequired: true,
                maxLength: 250,
                isUniqueText: true,
            }, {
                field: 'description',
                label: labels.descriptionLabel[langRule],
                className: 'grow-3',
                type: 'textarea',
                maxLength: 1000,
            }, {
                field: 'isActive',
                label: labels.activeLabel[langRule],
                type: 'checkbox',
            }, {
                field: '',
                label: '',
                type: 'icon',
                className: 'custom-field',
            },
        ];
        

        resourceBundle.map((resource: any) => {
            if (getUserDefinedStampsLabelsByKey(resource.resourceKey)) {
                labels[getUserDefinedStampsLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getUserDefinedStampsLabelsByKey(resource.resourceKey)) {
                labels[getUserDefinedStampsLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        if (loading) {
            return (<Spinner active={true}/>);
        }

        return (
            <div className='user-defined-stamps-modal' style={{ width: '1650px' }}>
                <DynamicForm
                    layerItems={localStampsTypeList}
                    formFields={formFields}
                    uniqueField={uniqueField}
                    onSubmit={this.onSubmit}
                    onDelete={this.props.deleteStamp}
                    handleDetectChanges={this.handleDetectChanges}
                    onCloseModal={onCloseModal}
                    isPristine={isEqual(stampsTypeList, localStampsTypeList)}
                    permissions={permissions}
                    defaultData={defaultData} 
                    redactionLang={redactionLang}
                    modifiedLabels={modifiedLabels}
                />
                <div className='linear-progress'> {this.props.updateLoading && <LinearProgress/>} </div>
            </div>
        );
    }

    private onSubmit = ({ layerItems }: { layerItems: IStampType[] }): void => {
        this.createNewStamp(layerItems);
        this.editStamp(layerItems);
    }

    private createNewStamp = (layerItems: IStampType[]): void => {
        const newStamp = layerItems.filter((stamp: IStampType): boolean => !stamp.id);

        if (newStamp.length) {
            this.props.createStamp(newStamp);
        }
    }

    private editStamp = (layerItems: IStampType[]): void => {
        const editedStamps = layerItems.filter((stamp: IStampType): boolean => {
            if (!stamp.id) {
                return;
            }

            const savedStamps = this.props.stampsTypeList.filter(
                (saveStamp: IStampType): boolean =>
                    saveStamp.id === stamp.id)[0];

            return !isEqual(savedStamps, stamp);
        });

        if (editedStamps.length) {
            const editedStampsWithoutDisabledFields = editedStamps.map((stamp: IStampType): IStampType =>
                stamp.isApplied ? {
                    id: stamp.id,
                    isActive: stamp.isActive,
                } : stamp,
            );

            this.props.updateStamp(editedStampsWithoutDisabledFields);
            this.props.handleDetectChanges(false);
        }
    }

    private handleDetectChanges = (values: IStampType[], isDirty: boolean): void => {
        this.props.changeLocalStampList(values);
        this.props.handleDetectChanges(isDirty || !isEqual(this.props.stampsTypeList, this.props.localStampsTypeList));
    }
}

const mapStateToProps = (state: StoreState): IUserDefinedModalStateProps => ({
    stampsTypeList: getStampsList(state),
    localStampsTypeList: getLocalStampsList(state),
    loading: getStampsListLoading(state),
    updateLoading: getUpdateStampsListLoading(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state)
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IUserDefinedModalDispatchProps, AnyAction>,
): IUserDefinedModalDispatchProps => ({
    fetchStamps: (): void => {
        dispatch(fetchStampList());
    },
    updateStamp: (data: IStampType[]): void => {
        dispatch(putStamp(data));
    },
    deleteStamp: (id: number): void => {
        dispatch(confirmDeleteStampType(id));
    },
    createStamp: (data: IStampType[]): void => {
        dispatch(postStamp(data));
    },
    changeLocalStampList: (data: IStampType[]): void => {
        dispatch(changeLocalStampList(data));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserDefinedStamps);

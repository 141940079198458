export const GET_AUDIT_ACTIONS_PENDING = 'reduction/GET_AUDIT_ACTIONS_PENDING';
export const GET_AUDIT_ACTIONS_SUCCESS = 'reduction/GET_AUDIT_ACTIONS_SUCCESS';
export const GET_AUDIT_ACTIONS_FAILURE = 'reduction/GET_AUDIT_ACTIONS_FAILURE';

export const GET_AUDIT_LOGS_PENDING = 'reduction/GET_AUDIT_LOGS_PENDING';
export const GET_AUDIT_LOGS_SUCCESS = 'reduction/GET_AUDIT_LOGS_SUCCESS';
export const GET_AUDIT_LOGS_FAILURE = 'reduction/GET_AUDIT_LOGS_FAILURE';

export const EXPORT_AUDIT_LOGS_PENDING = 'reduction/EXPORT_AUDIT_LOGS_PENDING';
export const EXPORT_AUDIT_LOGS_SUCCESS = 'reduction/EXPORT_AUDIT_LOGS_SUCCESS';
export const EXPORT_AUDIT_LOGS_FAILURE = 'reduction/EXPORT_AUDIT_LOGS_FAILURE';

export const GET_AUDIT_LOGS_CLEAR = 'reduction/GET_AUDIT_LOGS_CLEAR';

export const MODIFY_AUDIT_LOG_COLUMN_HEADERS = 'reduction/MODIFY_AUDIT_LOG_COLUMN_HEADERS';
export const SET_AUDIT_LOG_COLUMN_LOADING_FLAG = 'reduction/SET_AUDIT_LOG_COLUMN_LOADING_FLAG';

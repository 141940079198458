export const DOCUMENT_LIST_CONTEXT_MENU = 'documentListContextMenu';
export const PAGE_LIST_CONTEXT_MENU = 'pageListContextMenu';
export const REDACTOR_CONTEXT_MENU = 'redactorContextMenu';

export const CHANGE_PAGE_DISCLOSURE_LIST_MENU = 'changePageDisclosureListMenu';
export const CHANGE_DOCUMENT_DISCLOSURE_LIST_MENU = 'Set Disclosure';

export const CHANGE_ANNOTATION_TYPE_TO = 'ChangeAnnotationTypeTo';

export const OVERLAYED_SHAPES = 'overlayedShapes';

export const VIEW_SEVER_INFO = 'viewSeverInfo';

export const VIEW_COMMENT = 'viewComment';

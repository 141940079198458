import * as React from 'react';
import classnames from 'classnames';
import SplitterLayout from 'react-splitter-layout-react-v18';
import IconButton from '@mui/material/IconButton';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Reorder from '@mui/icons-material/Reorder';

import './resizablePanels.styles.scss';
import { IProps, IState } from './resizablePanels.model';
import {
    MIN_LEFT_PANEL_SIZE,
    MIN_PDF_PANEL_SIZE,
    MIN_RIGHT_PANEL_SIZE,
} from '../../redux/reducers/localStorage/constant';
import { isLowResolution } from '../../utils/app.util';

const PRIMARY_BAR_INDEX = 1;

class ResizablePanels extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isDragging: false,
            lowResolution: isLowResolution(),
            temporaryLeft: props.panelSizes.leftPanel.barWidth,
            temporaryRight: props.panelSizes.rightPanel.barWidth,
            temporaryTopRight: props.panelSizes.topRightPanel.barWidth,
            temporaryTopLeft: props.panelSizes.topLeftPanel.barWidth,
        };
    }

    public componentDidMount(): void {
        window.addEventListener('resize', this.onDefaultEventResize);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.onDefaultEventResize);
    }

    public render(): JSX.Element {
        const { center, leftTop, leftBottom, rightTop, rightBottom } = this.props.children;
        const { leftPanel, rightPanel, topLeftPanel, topRightPanel } = this.props.panelSizes;
        const { lowResolution, isDragging, temporaryRight} = this.state;
        const draggClass = classnames('panel', {
            'is-not-dragging': !isDragging,
            'is-expanded-left': !leftPanel.isCollapsed,
            'is-expanded-right': !rightPanel.isCollapsed,
        });
        const setLeftPanelMinSize = {
            primaryMinSize: !rightPanel.isCollapsed ?
                MIN_PDF_PANEL_SIZE + temporaryRight : MIN_PDF_PANEL_SIZE,
            secondaryMinSize: lowResolution ? null : MIN_LEFT_PANEL_SIZE,
        };
        const setRightPanelMinSize = {
            primaryMinSize: MIN_PDF_PANEL_SIZE,
            secondaryMinSize: lowResolution ? null : MIN_RIGHT_PANEL_SIZE,
        };

        return (
            <div className='panel-container'>
                <SplitterLayout
                    {...setLeftPanelMinSize}
                    primaryIndex={PRIMARY_BAR_INDEX}
                    customClassName={draggClass}
                    secondaryInitialSize={leftPanel.barWidth}
                    onDragStart={(): void => this.onDragStart()}
                    onDragEnd={this.onDragEnd.bind(this, 'left')}
                    onSecondaryPaneSizeChange={this.onPaneSizeChange.bind(this, 'left')}
                >
                    <div className='panel'>
                        <IconButton
                            color='inherit'
                            aria-label='Open drawer'
                            className='panel-icon-button-left'
                            onClick={this.toggleSidebar.bind(this, 'left')}
                        >
                            {leftPanel.isCollapsed ? <Reorder fontSize='small' /> : <ChevronLeft fontSize='small' />}
                        </IconButton>
                        <div
                            className={classnames('sidebar_main', {
                                'collapsed-top': topLeftPanel.isCollapsed,
                                'collapsed': leftPanel.isCollapsed,
                            })}
                        >
                            <SplitterLayout
                                vertical={true}
                                secondaryInitialSize={topLeftPanel.barWidth}
                                onSecondaryPaneSizeChange={this.onPaneSizeChange.bind(this, 'topLeft')}
                                onDragEnd={this.onDragEnd.bind(this, 'topLeft')}
                                onDragStart={(): void => this.onDragStart()}
                            >
                                {leftTop}
                                <div className='bottom'>
                                    <IconButton
                                        color='inherit'
                                        aria-label='Open drawer'
                                        className='panel-icon-button'
                                        onClick={this.toggleSidebar.bind(this, 'topLeft')}
                                    >
                                        {topLeftPanel.isCollapsed ? <Add fontSize='small' /> :
                                            <Remove fontSize='small' />}
                                    </IconButton>
                                    {leftBottom}
                                </div>
                            </SplitterLayout>
                        </div>
                    </div>
                    <div
                        className={classnames('sidebar_right_main', {
                            'collapsed-top': topRightPanel.isCollapsed,
                            'collapsed': rightPanel.isCollapsed,
                        })}
                    >
                        <SplitterLayout
                            {...setRightPanelMinSize}
                            secondaryInitialSize={rightPanel.barWidth}
                            onSecondaryPaneSizeChange={this.onPaneSizeChange.bind(this, 'right')}
                            onDragEnd={this.onDragEnd.bind(this, 'right')}
                            onDragStart={(): void => this.onDragStart()}
                            customClassName='main-right-panel'
                        >
                            <div className='panel'>
                                {center}
                            </div>
                            <div className={`${draggClass} sas`} style={{ zIndex: 3 }}>
                                <IconButton
                                    color='inherit'
                                    aria-label='Open drawer'
                                    className='panel-icon-button-right'
                                    onClick={this.toggleSidebar.bind(this, 'right')}
                                >
                                    {
                                        rightPanel.isCollapsed ?
                                            <Reorder fontSize='small' /> :
                                            <ChevronRight fontSize='small' />
                                    }
                                </IconButton>
                                {!rightPanel.isCollapsed && <SplitterLayout
                                  vertical={true}
                                  primaryIndex={PRIMARY_BAR_INDEX}
                                  secondaryInitialSize={topRightPanel.barWidth}
                                  onSecondaryPaneSizeChange={this.onPaneSizeChange.bind(this, 'topRight')}
                                  onDragEnd={this.onDragEnd.bind(this, 'topRight')}
                                  onDragStart={(): void => this.onDragStart()}
                                  customClassName='top-right-panel'
                                >
                                    {rightTop}
                                  <div className='bottom'>
                                    <IconButton
                                      color='inherit'
                                      aria-label='Open drawer'
                                      className='panel-icon-button'
                                      onClick={this.toggleSidebar.bind(this, 'topRight')}
                                    >
                                        {topRightPanel.isCollapsed ? <Add fontSize='small' /> :
                                            <Remove fontSize='small' />}
                                    </IconButton>
                                      {!topRightPanel.isCollapsed && rightBottom}
                                  </div>
                                </SplitterLayout>}
                            </div>
                        </SplitterLayout>
                    </div>
                </SplitterLayout>
            </div>
         );
    }

    private onDefaultEventResize = (): void => {
        this.setState({ lowResolution: isLowResolution() });
    }

    private capitalizeFirstLetter(string: string): string {
        // TODO we have css property text-transform: capitalize. Better get rid of it
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    private onDragStart(): void {
        this.setState({ isDragging: true });
    }

    private onDragEnd(param: string): void {

        this.setState({ isDragging: false });
        this.props.setPanel({
            ...this.props.panelSizes,
            [`${param}Panel`]: {
                ...this.props.panelSizes[`${param}Panel`],
                barWidth: this.state[`temporary${this.capitalizeFirstLetter(param)}`],
            },
        });
    }

    private onPaneSizeChange(param: string, size: number): void {
        this.setState((prevState: IState) => {
            return {
                ...prevState,
                [`temporary${this.capitalizeFirstLetter(param)}`]: size,
            };
        });
    }

    private toggleSidebar = (param: string): void => {
        this.props.setPanel({
            ...this.props.panelSizes,
            [`${param}Panel`]: {
                ...this.props.panelSizes[`${param}Panel`],
                isCollapsed: !this.props.panelSizes[`${param}Panel`].isCollapsed,
            },
        });
    }
}

export default ResizablePanels;

import * as React from 'react';
import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { ISelectOptions } from '../materialUiForms/marerialUiForms.model';
import { TransitionProps as MaterialTransitionProps } from '@mui/material/transitions/transition';

const SplitButton = ({
    options,
    handleChooseOption,
    disabled,
}: {
    options: ISelectOptions[],
    handleChooseOption: (value: string) => void,
    disabled: boolean,
}): JSX.Element => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = (): void => {
        if (options[selectedIndex] && options[selectedIndex].value) {
            handleChooseOption(options[selectedIndex].value);
        }
    };

    const handleMenuItemClick = (index: number): void => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = (): void => {
        setOpen((prevOpen: boolean): boolean => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
            return;
        }
    
        setOpen(false);
    };

    return (
        <div className='split-button'>
            <ButtonGroup variant='contained' color='primary' ref={anchorRef} aria-label='split button'>
                <Button
                    disabled={options[selectedIndex].disabled}
                    onClick={handleClick}
                >
                    {options[selectedIndex].label}
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    size='small'
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup='true'
                    disabled={disabled}
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} transition={true} disablePortal={true}>
                {({ TransitionProps, placement }: {
                    placement: PopperPlacementType,
                    TransitionProps?: MaterialTransitionProps,
                }): JSX.Element => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {
                                        options.map((option: ISelectOptions, index: number): JSX.Element => (
                                            <MenuItem
                                                key={option.value}
                                                selected={index === selectedIndex}
                                                disabled={option.disabled}
                                                onClick={(): void => handleMenuItemClick(index)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default SplitButton;

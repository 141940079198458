import {
    SET_PANEL_SIZE,
    SET_LAST_USED_PRINTER,
    SET_DUPLICATES_BOTTOM_PANEL_SIZE,
    DUPLICATES_BOTTOM_PANEL,
    SET_REDUCTION_LID,
    SET_RECENTLY_USED_STAMPS,
    SET_REDUCTION_AUTH,
    RECENTLY_USED_STAMPS_KEY,
    SET_RECENTLY_USED_EXEMPTIONS,
    SET_REDACTION_LANGUAGE,
    SET_REDACTION_MODE,
} from '../reducers/localStorage/constant';
import { action } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { isEqual } from 'lodash';
import { getItem, setItem } from '../../utils/localStorage.util';
import { IPanelSizes } from '../../components/resizablePanels/resizablePanels.model';
import {
    IArticleStamp,
    IAvailableExemptionIds,
    IValidExemptionDtoList,
} from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';

export const setPanelSize = (panels: IPanelSizes): AnyAction => {
    Object.keys(panels).forEach((key: string) => {
        setItem(key, panels[key]);
    });

    return action(SET_PANEL_SIZE, panels);
};

export const filterCurrentRecentlyUsedStamps = (requestNumber: string, exemptions: IArticleStamp[]): AnyAction => {
    // for remove stamps witch user can`t apply (if previous user has more access)
    const usedStamps = getItem(RECENTLY_USED_STAMPS_KEY);

    if(!usedStamps.length) {
        return action(SET_RECENTLY_USED_STAMPS, {requestNumber, recentlyUsedStamp: []});
    }

    const flatExemptionsIdsObject = exemptions && exemptions.length && exemptions.reduce(  // {'1':1, '45':45}
        (acc: IAvailableExemptionIds, stampGroup: IArticleStamp): IAvailableExemptionIds => {
            const ids = stampGroup.validExemptionDtoList
                .reduce((ac: IAvailableExemptionIds, {exemptionCode}: IValidExemptionDtoList) => ({
                    ...ac,
                    [exemptionCode]: exemptionCode,
                }), {});

            return {...acc, ...ids};
        }, {});

    const filteredUsedStamps = usedStamps && usedStamps.length && usedStamps.reduce(
        (acc: IValidExemptionDtoList[][], stamps: IValidExemptionDtoList[]) => {
            const filteredStamps = stamps.reduce((ac: IValidExemptionDtoList[], stamp: IValidExemptionDtoList) => {
                if (flatExemptionsIdsObject.hasOwnProperty(`${stamp.exemptionCode}`)) {
                    return [...ac, stamp];
                }

                return [...ac];
            }, []);

            return filteredStamps.length ? [
                ...acc,
                filteredStamps,
            ] : [...acc];
        }, []);

    return action(SET_RECENTLY_USED_STAMPS, {requestNumber, recentlyUsedStamp: filteredUsedStamps});
};

export const setRecentlyUsedStamps = (requestNumber: string, selectedStamps: IValidExemptionDtoList[]): AnyAction => {
    const usedStamps = getItem(RECENTLY_USED_STAMPS_KEY + requestNumber);

    if(!usedStamps) {
        return action(SET_RECENTLY_USED_STAMPS, {requestNumber, recentlyUsedStamp: [selectedStamps]});
    }

    const isDuplicateChoice = usedStamps.some((listExemptions: IValidExemptionDtoList[]) => {

        return isEqual(listExemptions, selectedStamps);
    });
    const last19UsedStamps = usedStamps.slice(0,19);
    const newStamps = [selectedStamps, ...last19UsedStamps];
    const usedStampsSorted = [selectedStamps, ...usedStamps.filter((listExemptions: IValidExemptionDtoList[]) =>
        !isEqual(listExemptions, selectedStamps))];

    return action(SET_RECENTLY_USED_STAMPS,
        {
            requestNumber,
            recentlyUsedStamp: isDuplicateChoice ? usedStampsSorted : newStamps,
        },
    );
};

export const setLastUsedPrinter = (lastUsedPrinter: any): AnyAction => action(SET_LAST_USED_PRINTER, lastUsedPrinter);

export const setDuplicatesBottomPanelSize = (size: number): AnyAction => {
    setItem(DUPLICATES_BOTTOM_PANEL, size);

    return action(SET_DUPLICATES_BOTTOM_PANEL_SIZE, size);
};

export const setReductionLid = (lid: string): AnyAction => action(SET_REDUCTION_LID, lid);

export const setRedactionLang = (lang: string): AnyAction => action(SET_REDACTION_LANGUAGE, lang);

export const setRedactionMode = (mode: string): AnyAction => action(SET_REDACTION_MODE, mode);

export const setReductionAuth = (auth: string): AnyAction => action(SET_REDUCTION_AUTH, auth);

export const setRecentlyUsedExemptions = (requestNumber: string, exemptions: IValidExemptionDtoList[]):
     AnyAction => action(SET_RECENTLY_USED_EXEMPTIONS, {requestNumber, exemptions});

export const setRecentlyUsedStampsOnReferesh = (requestNumber: string, selectedStamps: IValidExemptionDtoList[]):
    AnyAction => action(SET_RECENTLY_USED_STAMPS, {requestNumber, recentlyUsedStamp: selectedStamps});

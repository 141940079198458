import { AnyAction } from 'redux';
import { IPrinterState } from './printer.model';
import { SET_PRINTERS } from './constant';

export const initialState: IPrinterState = {
    availablePrinters: [],
};

export const printerReducer = (state: IPrinterState = initialState, { type, payload }: AnyAction): IPrinterState => {
    if (type === SET_PRINTERS) {
            return {
                ...state,
                availablePrinters: payload,
            };
    } else {
        return state;
    }
};

export default printerReducer;

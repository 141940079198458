import { IState } from '../store';
import { createSelector } from 'reselect';
import { IModalEditPaginationState, IRange } from '../reducers/modalEditPagination/modalEditPagination.model';

export const getModalEditPagination = (state: IState): IModalEditPaginationState => state.modalEditPagination;

export const getEditPaginationRange = createSelector(
    [getModalEditPagination],
    (modalWindowDisclosure: IModalEditPaginationState): IRange[] =>
        modalWindowDisclosure.paginationAvailableRange,
);

export const getEditPaginationRangeLoading = createSelector(
    [getModalEditPagination],
    (modalWindowDisclosure: IModalEditPaginationState): boolean =>
        modalWindowDisclosure.paginationAvailableRangeLoading,
);

export const getUpdatingPagination = createSelector(
    [getModalEditPagination],
    (modalWindowDisclosure: IModalEditPaginationState): boolean =>
        modalWindowDisclosure.updatePagination,
);

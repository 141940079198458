import { ADD_ERROR, RESET_ERROR_MESSAGE, RESET_SUCCESS_MESSAGE, ADD_SUCCESS_MESSAGE } from './constants';
import { AnyAction } from 'redux';
import { IError, ISuccessNotification } from '../../common.model';

export interface INotificationState {
    error: IError[];
    successNotifications: ISuccessNotification[];
}

const initialState: INotificationState = {
    error: [],
    successNotifications: [],
};

const errorReducer = (state: INotificationState = initialState, {type, payload}: AnyAction): INotificationState => {
    switch (type) {

        case ADD_ERROR:
            return {
                ...state,
                error: [...state.error, payload],
            };

        case RESET_ERROR_MESSAGE:
            return {
                ...state,
                error: state.error.filter((error: ISuccessNotification): boolean => error.id !== payload),
            };

        case ADD_SUCCESS_MESSAGE:
            return {
                ...state,
                successNotifications: [...state.successNotifications, payload],
            };

        case RESET_SUCCESS_MESSAGE:
            return {
                ...state,
                successNotifications: state.successNotifications.filter(
                    (successNotification: ISuccessNotification): boolean => successNotification.id !== payload,
                ),
            };

        default:
            return state;
    }
};

export default errorReducer;

export const CONFIRMATION_DIALOG_MODAL = 'confirmationDialog';
export const ALERT_DIALOG_MODAL = 'alertDialog';
export const IMPORT = 'import';
export const DOCUMENT_IMPORT_TITLE = 'Document import';
export const PAGE_INSERT_TITLE = 'Page Insert';
export const DELETE_DOCUMENT_TITLE = 'Delete Document';
export const NOT_SELECT_DOCUMENT = 'You cannot perform this action. Please select a document';
export const DELETE_PAGE_TITLE = 'Delete Page';
export const DELETE_COMMENT_TITLE = 'Delete Comment';
export const WARNING = 'Warning';
export const EXPORT_ABORT = 'Export Aborted';
export const YES_BUTTON = 'Yes';
export const NO_BUTTON = 'No';
export const CANCEL_BUTTON = 'Cancel';
export const PRESS_CONTINUE = 'Press OK to continue.';
export const DELETE_DOCUMENT_MESSAGE = `This operation will permanently delete the document and all associated data? \
    ${PRESS_CONTINUE}`;
export const DELETE_DOCUMENT_PER_PAGE_MESSAGE =
    `This document no longer contains any pages. Do you wish to delete the document? ${PRESS_CONTINUE}`;
export const DELETE_COMMENT_MESSAGE = `Are you sure want to permanently remove this comment and its replies ?
    ${PRESS_CONTINUE}`;
export const DELETE_PAGE_MESSAGE =
    `This operation will permanently delete the page and all associated data. ${PRESS_CONTINUE}`;
export const DELETE_DISCLOSURE_MESSAGE = `Delete disclosure? ${PRESS_CONTINUE}`;
export const DELETE_STACK_MESSAGE = `Delete stack? ${PRESS_CONTINUE}`;
export const DELETE_STACK_PROPERTY_MESSAGE = `Delete stack property? ${PRESS_CONTINUE}`;
export const DELETE_WATERMARK_MESSAGE = `Delete watermark? ${PRESS_CONTINUE}`;
export const ALERT_DELETE_WATERMARK_MANAGEMENT = `Watermark is in use. Are you sure you want to remove the watermark?`;
export const DELETE_PRESET_MESSAGE = `Delete preset? ${PRESS_CONTINUE}`;
export const DELETE_CONTACT_TITLE = 'Remove contact';
export const DELETE_CONTACT_MESSAGE = `Remove contact? ${PRESS_CONTINUE}`;
export const CHANGES_MAY_NOT_BE_SAVED_MESSAGE = `Changes you made may not be saved. Click "Ok" - leave the window \
without data saved, "Cancel" - return user to the window.`;
export const DELETE_ANNOTATION_TYPE_TITLE = 'Delete Annotation Type';
export const DELETE_ANNOTATION_TYPE_MESSAGE = `Delete Annotation? ${PRESS_CONTINUE}`;
export const DELETE_STAMP_TYPE_TITLE = 'Delete Stamp Type';
export const DELETE_STAMP_TYPE_MESSAGE = `Delete Stamp Type? ${PRESS_CONTINUE}`;
export const DISCLOSURE_WARNING =
    'Default disclosure is not set for this request type. Please contact your administrator.';
export const CHANGE_DISCLOSURE_WARNING =
    `Selected documents already have disclosure set for all pages. Please use Replace Disclosure or
        Undo Disclosure first to reset specific pages back the default disclosure`;

export const AVAILABLE_PAGINATION = 'Available Pagination Numbers:';
export const PAGINATION_BIGGER = 'Must be bigger than ';
export const PAGINATION_LESS = 'Must be less than ';
export const PAGINATION_TITLE = 'Pagination';
export const EDIT_PAGINATION_MODAL = 'Edit Pagination';
export const UNDO_PAGINATION_MODAL = 'Undo Pagination';
export const PAGINATION_MULTI_PAGE_WARNING =
    'You cannot edit pagination when multiple documents are selected. Please select only one document.';
export const PAGINATION_NO_DOCUMENT_WARNING = 'You cannot perform this action. Please select a document.';
export const PAGINATION_CONFIRMATION_TITLE = 'Pagination confirmation';
export const PAGINATION_CONFIRMATION_MESSAGE = `Would you like to re-paginate the entire request? (Select No to \
    re-paginate currently selected documents only).`;
export const PAGINATION_RECOMMENDED_TITLE = 'Pagination Recommended';
export const PAGINATION_RECOMMENDED_MESSAGE = `This document was previously paginated. \
    It is recommended to repaginate to avoid pagination gaps. Do you wish to paginate now?`;
export const UNSUPPORTED_IMAGE_MESSAGE =
    `The following file selected is not permitted for import and will
   be automatically removed from the Watermark management.`;
export const SEVERAL_PAGES_SELECTED_WARNING = 'Please select only one page for this operation';
export const AUTO_DESKEW_PAGE_MESSAGE = `Page(s) have to be OCRed in order to apply Page Auto Descew. \
 Proceed with OCR operation?`;
export const AUTO_DESKEW_DOCUMENT_MESSAGE = `Document(s) have to be OCRed in order to apply Document Auto Descew. \
Proceed with OCR operation?`;
export const RELEASE_MANAGEMENT_MODAL = 'Release Management';
export const EMPTY_OVERLAYED_OBJECTS_MESSAGE = 'No overlaying annotation objects are available under the selected object.';
export const SORTING_NOT_APPLICABLE_WARNING = 'Please set the sort order to Page # before using drag and drop to move pages.';
export const SORTING_NOT_APPLICABLE_ON_MOVE_WARNING = 'Please set the sort order to Page # before using move up/down pages.';
export const DRAGGING_NONE_SELECTED_PAGE_WARNING = 'Please drag the selected pages to drop.';
export const DRAGGING_PAGE_IN_PRESENT_BLOCK_PAGE_WARNING = 'Please select the pages within the pagelist block to drag and drop.';
export const OVERLAYED_SHAPES_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const EDIT_FILE_NAME_MODAL = 'Edit Filename';
export const EDIT_FILE_NAME_TITLE = 'Edit Filename';
export const EDIT_FILE_NAME_NO_DOCUMENT_MESSAGE = 'You cannot perform this action. Please select a document.';
export const EDIT_FILE_NAME_MULTI_DOCUMENT_WARNING_MESSAGE = 'Please select only one document to edit the filename.';
export const NO_LINKED_STAMPS_MESSAGE = 'There are no linked stamps for this sever.';
export const DELETE_LINKED_ARTICLE_OR_EXEMPTION_MESSAGE = 'Article/exemption stamps are currently linked to this sever. Do you wish to delete the article stamps?';
export const CHANGE_LINKED_SEVER_TO_HIGHLIGHT = 'This action will delete the Links between the articles and sever,Do you want to continue?';
export const CUTTING_LINKED_SEVER = 'Cut and paste do not copy the links and exemptions associated with the sever.';
export const MANUAL_LINKING_MULTIPLE_SEVERS_TO_ARTICLES = 'Please select only a single sever to link the articles.';
export const PAGENUMBER_INVALID = 'Please enter a value between 1 - ';
export const AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL = 'autoAlignArticleStampsAlertModal';
export const AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MESSAGE = 'Re-align all article stamp positions based on current preference setting. \
    This may take several minutes for multiple or large documents. Continue?';
export const AUTO_ALIGN_ARTICLE_STAMPS_PAGE_ALERT_MESSAGE = 'Re-align all article stamp positions based on current preference setting. \
    This may take several minutes for large number of pages. Continue?';
export const AUTO_ALIGN_ARTICLE_STAMPS_DOCUMENT_WARNING_MESSAGE = 'You cannot perform this action. Please select a document.';
export const AUTO_ALIGN_ARTICLE_STAMP_TITLE = 'Auto Align Article Stamps';
export const ERROR_PAGE_NOT_ENCRYPTED_OR_NOT_LOADED = 'This page cannot be decrypted or loaded.';
export const NO_DOCUMENT_SELECTED = 'No document selected.';
export const SELECT_A_DOCUMENT = 'Please select a document to display';
export const NO_MATCHING_RESULTS = 'No matching results';
export const NO_MATCHING_RESULT_TO_APPLY = 'No matching results to apply annotation to. Please refine your Search page text and click the search button again.';
export const NO_MATCHING_RESULT_TO_APPLY_ALL = 'Please select any document from your search results before clicking Apply All.';
export const SELECTED_DOC_NOT_IN_CURRENT_STACK = 'This Document/Page is not visible in the current stack.Would you like to switch to All Documents View ?';
export const NO_NEXT_SEARCH_RESULT = 'There are no additional matching search results.';
export const NO_PREVIOUS_SEARCH_RESULT = 'There are no previous matching search results.';
export const OPEN_RESULT_IN_NEW_TAB = 'Your current selection of search result is inside another request. This will open a new tab of your browser. Do you like to continue ?';
export const UNDO_SEVER = 'Undo Sever';
export const UNDO_SEVER_MESSAGE = 'Would you like to undo the auto corrected sever';
export const AUDIT_LOG_ERASE_CONFIRM = 'Confirm Erase Log';
export const AUDIT_LOG_ERASE_CONFIRM_MESSAGE = 'This will completely erase the audit log. Do you wish to save the audit log before erasing?';
export const AUDIT_LOG_ERASE_WITHOUT_SAVE = 'Unable to Save';
export const AUDIT_LOG_ERASE_WITHOUT_SAVE_MESSAGE = 'You do not have permission to save logs, do you wish to erase entire audit log without saving?';

import * as React from 'react';
import { Spinner } from '../../../../components/spinner/Spinner';
import { IExemptionSettingsTabProps } from './ExemptionSettingsTab.model';
import { Field } from 'react-final-form';
import { renderTextField } from '../../../../components/materialUiForms/materialUiForms';
import { uppercaseFirstLetter } from '../../../../utils/string.utils';
import { AutoSizer } from 'react-virtualized';
import { PREFIX, SUFFIX } from '../../../../constants/globalSettings.constants';
import { initialLabel } from '../../../../constants/localization.constants';
import { changeLang } from '../../../../redux/actions/localization';
import resourceBundle from '../../../../containers/localization/localizationData';

const getExemptionLabelsByKey = (key: string): string => {
    switch (key) {
        case 'GLOBALSETTINGS_PREFIX':
            return 'prefixLabel';
        case 'GLOBALSETTINGS_SUFFIX':
            return 'suffixLabel';
        default:  return '';
    }
}

const labels = {
    prefixLabel: initialLabel,
    suffixLabel: initialLabel,
};


export class ExemptionSettingsTab extends React.Component<IExemptionSettingsTabProps> {
    public render(): JSX.Element {
        const {loadingSettings,redactionLang,modifiedLabels} = this.props;
        const langRule = changeLang(redactionLang);
            resourceBundle.map((resource: any) => {
                if (getExemptionLabelsByKey(resource.resourceKey)) {
                    labels[getExemptionLabelsByKey(resource.resourceKey)] = resource;
                }
        
                return resource;
            });
            modifiedLabels.map((resource: any) => {
                if (getExemptionLabelsByKey(resource.resourceKey)) {
                    labels[getExemptionLabelsByKey(resource.resourceKey)] = resource;
                }
        
                return resource;
            });
        return (
            <div style={{height: '100%'}}>
                {
                    loadingSettings ?
                        <Spinner active={true}/> :
                        <div className='exemption-settings-tab'>
                            <AutoSizer>
                                {({width, height}: { width: number; height: number; }): JSX.Element => (
                                    <div style={{width, height}}>
                                        <div className='exemption-settings-tab-form'>
                                            <Field
                                                render={renderTextField}
                                                label={uppercaseFirstLetter(labels.prefixLabel[langRule])}
                                                name={PREFIX}
                                                margin='normal'
                                                required={false}
                                                variant='outlined'
                                                fullWidth={false}
                                            />
                                            <Field
                                                render={renderTextField}
                                                label={uppercaseFirstLetter(labels.suffixLabel[langRule])}
                                                name={SUFFIX}
                                                margin='normal'
                                                required={false}
                                                variant='outlined'
                                                fullWidth={false}
                                            />
                                        </div>
                                    </div>
                                )}
                            </AutoSizer>
                        </div>
                }
            </div>
        );
    }
}

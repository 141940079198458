import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Scrollbars } from 'rc-scrollbars';
import { connect } from 'react-redux';
import DocumentField from '../documentField/DocumentField';
import { Spinner } from '../../../../components/spinner/Spinner';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import {
    IExportDoc,
    IExportPages,
} from '../../../../redux/reducers/modalWindowExport/modalWindowExport.model';
import {
    IProps,
    IState,
    IExportDocumentListStateProps,
    IExportDocumentListProps,
} from './documentList.model';
import {
    addOrRemoveDocument,
    addOrRemoveAllDocuments,
    selectExportStack,
    setPagesToExport,
    fetchExportStackDocList, updateExportFilters,
} from '../../../../redux/actions/modalWindowExport';
import { IState as StoreState } from '../../../../redux/store';
import {
    getExportDocumentList,
    getInitialExportDocumentList,
    getExportDocumentsLoading,
    getExportStackId,
} from '../../../../redux/selectors/modalWindowExport';
import { getStackList } from '../../../../redux/selectors/stackList';
import { IStackOptions } from '../../../../redux/reducers/stackList/stackList.model';
import { ISelectOptions } from '../../../../components/materialUiForms/marerialUiForms.model';
import { ALL_DOCUMENTS } from '../../../../constants/export.contstants';
import { getRedactionDocumentId } from '../../../../redux/selectors/initialization';
import ScrollableSelect from '../../../../components/ScrollableSelect/ScrollableSelect';
import { fetchDocumentList } from '../../../../redux/actions/documentList';
import { CheckboxMaterialBuilder } from '../../../../components/materialUiForms/materialUiFormBuilder';
import { Tooltip } from '@mui/material';
import { openModalWindow } from '../../../../redux/actions/modal';
import { IModalProps } from '../../../../redux/reducers/modal/modal.model';

class DocumentList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isPagesValid: {},
        };
    }

    public componentDidMount(): void {
        this.props.fetchDocumentList(this.props.redactionDocumentId, true, true);
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState): void {
        const isPagesValid = this.isAllFormValid(this.state.isPagesValid);

        if (!!isPagesValid) {
            this.props.onChangeValidationStatus(isPagesValid);
        } else {
            this.props.onChangeValidationStatus(isPagesValid);
        }

    }

    public render(): JSX.Element {
        const {parentLabels, langRule} = this.props;

        return (
            <div className='documents'>
                <div className='documents-heading'>
                    <Typography className='heading' variant='h6'>
                        {parentLabels.documentListTitleLabel[langRule]}
                    </Typography>
                    <div className='selectall-wrap'>
                        <Tooltip
                            title={this.isCheckedAll() ? parentLabels.deselectAllLabel[langRule]
                            : parentLabels.selectAllLabel[langRule]}
                            placement={'left'}
                        >
                            <span>
                                <CheckboxMaterialBuilder
                                    checked={this.props.exportDocumentsList.length && this.isCheckedAll()}
                                    handleCheckboxChange={
                                        (param: string, checked: boolean): void => {
                                            this.props.addOrRemoveAllDocuments(checked);
                                        }}
                                    paramName={'isCheckedAll'}
                                    disabled={!this.props.exportDocumentsList.length}
                                />
                            </span>
                        </Tooltip>
                        <ScrollableSelect
                            value={this.props.exportStackId}
                            onChange={this.handleSelectChange}
                            options={this.getDocListOptions()}
                        />
                    </div>
                </div>
                <Scrollbars>
                    <div className='documents-wrapper'>
                        {
                            this.props.loadingDocumentList ?
                                <Spinner active={true} /> :
                                this.props.exportDocumentsList.map(
                                    (document: IExportDoc): JSX.Element => (
                                        <DocumentField
                                            key={document.id}
                                            document={document}
                                            pages={this.getSelectedPages(document.pages)}
                                            initialPages={this.getInitialPages(document.id)}
                                            onChangeValidationStatus={(isValid: boolean): void =>
                                                this.setValidationStatus(document.id, isValid)
                                            }
                                            onChangeCheckbox={(isChecked: boolean): void =>
                                                this.checkboxHandler(document.id, isChecked)
                                            }
                                            inputChangeHandler={(documentId: number, val: number[]): void =>
                                                this.inputHandler(documentId, val)
                                            }
                                            openModalWindow={this.props.openEditFileNameModal}
                                            parentLabel={parentLabels}
                                            langRule={langRule}
                                        />
                                    ))
                        }
                    </div>
                </Scrollbars>
            </div>
        );
    }
    private isCheckedAll = (): boolean => {
        const { exportDocumentsList } = this.props;

        return exportDocumentsList.every((document: IExportDoc): boolean => {

            return document.isChecked === true;
        });
    }
    private getDocListOptions = (): ISelectOptions[] => {
        const { stackList,langRule, parentLabels } = this.props;
        const allDocs = { value: ALL_DOCUMENTS, label: parentLabels.allDocumentLabel[langRule] };

        const stacks = stackList.map((stack: IStackOptions): ISelectOptions => {
            return {
                value: stack.id.toString(),
                label: stack.name,
            };
        });

        return [allDocs, ...stacks];
    }

    private getSelectedPages = (pages: IExportPages[]): number[] =>
        pages.filter((page: IExportPages): boolean => page.isChecked)
            .map((page: IExportPages): number => page.pageNumber)

    private getInitialPages = (id: number): number[] => {
        const currentDoc = this.props.initialDocumentList
            .filter((doc: IExportDoc): boolean => doc.id === id)[0];

        return this.getSelectedPages(currentDoc.pages);
    }

    private handleSelectChange = (value: string): void => {
        const { redactionDocumentId } = this.props;

        this.props.selectExportStack(value);

        if (value === ALL_DOCUMENTS) {
            this.props.updateExportFilterParams();
        } else {
            this.props.fetchExportStackDocList(redactionDocumentId, Number(value));
        }
    }

    private checkboxHandler = (docId: number, isChecked: boolean): void => {
        this.props.addOrRemoveDocument(isChecked, docId);
    }

    private inputHandler = (docId: number, selectedPages: number[]): void => {
        this.props.setPagesToExport(docId, selectedPages);
    }

    private setValidationStatus = (id: number, isFormValid: boolean): void => {
        this.setState((prevState: IState): IState => ({
            ...prevState,
            isPagesValid: {
                ...prevState.isPagesValid,
                [`${id}`]: isFormValid,
            },
        }));
    }

    private isAllUnchecked = (): boolean => this.props.exportDocumentsList.every(
        (doc: IExportDoc): boolean => !doc.isChecked)

    private isAllFormValid = (isPagesValid: { [id: string]: boolean; }): boolean => {

        const { exportDocumentsList } = this.props;

        if (this.isAllUnchecked()) {
            return;
        }

        const formValidArr = Object.keys(isPagesValid).map((id: string): {
            id: string;
            isChecked: boolean;
            isValid: boolean;
        } => {
            const currentDocument = exportDocumentsList.filter((doc: IExportDoc): boolean => `${doc.id}` === id);

            return {
                id,
                isChecked: currentDocument.length && currentDocument[0].isChecked,
                isValid: isPagesValid[`${id}`],
            };
        });

        return formValidArr.every((elem: { id: string; isChecked: boolean; isValid: boolean; }): boolean =>
            elem.isChecked ? elem.isValid : true);
    }

}

const mapStateToProps = (state: StoreState): IExportDocumentListStateProps => ({
    initialDocumentList: getInitialExportDocumentList(state),
    exportDocumentsList: getExportDocumentList(state),
    loadingDocumentList: getExportDocumentsLoading(state),
    stackList: getStackList(state),
    exportStackId: getExportStackId(state),
    redactionDocumentId: getRedactionDocumentId(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IExportDocumentListProps, AnyAction>,
): IExportDocumentListProps => ({
    fetchDocumentList: (redactionDocumentId: number, pending: boolean, fetchExport: boolean): void => {
        dispatch(fetchDocumentList(redactionDocumentId, pending, fetchExport));
    },
    setPagesToExport: (docId: number, selectedPageRange: number[]): void => {
        dispatch(setPagesToExport(docId, selectedPageRange));
    },
    addOrRemoveDocument: (isChecked: boolean, docId: number): void => {
        dispatch(addOrRemoveDocument(isChecked, docId));
    },
    addOrRemoveAllDocuments: (isChecked: boolean): void => {
        dispatch(addOrRemoveAllDocuments(isChecked));
    },
    selectExportStack: (stackId: string): void => {
        dispatch(selectExportStack(stackId));
    },
    updateExportFilterParams: (): void => {
        dispatch(updateExportFilters());
    },
    fetchExportStackDocList: (redactionDocumentId: number, id: number): void => {
        dispatch(fetchExportStackDocList(redactionDocumentId, id));
    },
    openEditFileNameModal: (type: string, modalProps: IModalProps): void => {
        dispatch(openModalWindow(type, modalProps));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);

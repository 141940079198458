import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import {
    GET_INFO_CONTROLLER_PENDING,
    GET_INFO_CONTROLLER_SUCCESS,
    GET_INFO_CONTROLLER_FAILURE,
} from '../reducers/infoController/constants';
import { IVersion } from '../reducers/infoController/infoController.model';
import { IError } from '../common.model';

export const fetchInfoControllerPending = (): AnyAction => action(GET_INFO_CONTROLLER_PENDING);

export const fetchInfoControllerSuccess = (dataList: IVersion): AnyAction =>
    action(GET_INFO_CONTROLLER_SUCCESS, dataList);

export const fetchInfoControllerFailure = (error: IError): AnyAction => action(GET_INFO_CONTROLLER_FAILURE, error);

export const fetchInfoController = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

    dispatch(fetchInfoControllerPending());

    try {
        const response = await api.infoController.getRedactionVersions();

        dispatch(fetchInfoControllerSuccess(response));
    } catch (error) {
        dispatch(fetchInfoControllerFailure(error));
    }
};

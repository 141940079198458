import * as React from 'react';
import { MouseEvent } from 'react';
import { Submenu } from 'react-contexify';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { IContextMenuProps } from '../contextMenu.model';
import 'react-contexify/dist/ReactContexify.min.css';
import { IContextData } from '../../../redux/reducers/contextMenu/contextMenu.model';
import ListItem from './ListItem';
import { Scrollbars } from 'react-custom-scrollbars-2';

const ROW_HEIGHT = 31;
const PADDING = 5;
const MIN_SUBMENU_HEIGHT = 180;

const SubMenu = ({
    elem,
    contextMenuProps,
    openModalWindowHandler,
    handleAction,
    redactionDocumentId,
}: IContextMenuProps): JSX.Element => {
    const subMenuComponent = <ChevronRight />;
    const handleClick = ({ modal, action }: IContextData): void => {
      if (modal) {
        openModalWindowHandler(elem.modal, { ...contextMenuProps, id: elem.modal });
      } else if (action) {
          handleAction(action, {
              redactionDocumentId,
              ...contextMenuProps,
          });
      }
    };

    const height = elem.menu && elem.menu.length * ROW_HEIGHT + 2 * PADDING;
    const subMenuMap = (menuElem: any): JSX.Element[] => {
        return (
            menuElem.menu && menuElem.menu.map((el: IContextData) => (
                <SubMenu
                    elem={el}
                    key={el.id}
                    contextMenuProps={contextMenuProps}
                    openModalWindowHandler={openModalWindowHandler}
                    handleAction={handleAction}
                    redactionDocumentId={redactionDocumentId}
                />
            ))
        );
    };
    const subMenuScrollbars = (scrollNeeded: boolean): JSX.Element | JSX.Element[] => {
        return scrollNeeded ? (
        <Scrollbars
            style={{width: 250}}
            onClick={(e: React.MouseEvent<Scrollbars>): void => {
                if ((e.target as HTMLDivElement).className !== 'react-contexify__item__content') {
                    e.stopPropagation();
                }
            }}
        >
           {subMenuMap(elem)}
        </Scrollbars>) : (
            subMenuMap(elem)
        );

    };

    return (
        <div className='list-item__element' key={elem.id}>
            {
                elem.menu ?
                    <Submenu
                        label={elem.label}
                        arrow={subMenuComponent}
                        disabled={elem.disabled}
                        className='list-item__submenu'
                        style={{
                            height: `${elem.menu.length * ROW_HEIGHT + 2 * PADDING}px`,
                        }}
                    >
                        {subMenuScrollbars(height && height > MIN_SUBMENU_HEIGHT)}
                    </Submenu>
                    :
                    <ListItem
                        elem={elem}
                        handleClick={handleClick}
                    />
            }
        </div>
    );
};

export default SubMenu;

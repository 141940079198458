import * as React from 'react';
import { isEqual, find } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from '../../../node_modules/react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Table from '../../components/table/ServerSidePagingTable';
import { usePrevious } from '../../hooks/usePrevious';
import { CheckboxMaterialBuilder } from '../../components/materialUiForms/materialUiFormBuilder';
import { QueueListHeaders, DISPLAY_FINISHED } from '../../constants/rightSidebar.constants';
import { ITaskList, IPaginationParams } from '../../redux/reducers/taskQueue/taskQueue.model';
import { DELETE } from '../../constants/common.constants';
import {
    CANCELED_STATUS,
    ERROR_STATUS,
    DEFAULT_PAGE_SIZE,
    DEFAULT_DELAY,
    COMPLETED_STATUS,
} from '../../constants/taskQueue.constants';
import './queueManagement.styles.scss';
import { getRedactionLanguage } from '../../redux/selectors/localStorage';
import { getModifiedLabels, getModifiedLabelsFlag } from '../../redux/selectors/localization';
import resourceBundle from '../../containers/localization/localizationData';
import { initialLabel } from '../../constants/localization.constants';

const QueueManagement = ({
     totalPages,
     lastProcessedDocuments,
     currentPage,
     totalTaskCount,
     showFinishedTasks,
     taskQueueList,
     redactionDocumentId,
     loading,
     fetchDocumentTasksQueueList,
     deleteTasksQueue,
     updateTasksQueue,
     loadedTaskList,
     updateDocuments,
     updateDocumentsList,
}: {
    totalPages: number;
    currentPage: number;
    totalTaskCount: number;
    showFinishedTasks: boolean;
    taskQueueList: ITaskList[];
    lastProcessedDocuments: ITaskList[];
    redactionDocumentId: number;
    loading: boolean;
    loadedTaskList: boolean;
    fetchDocumentTasksQueueList: (redactionId: number, params?: IPaginationParams, needPending?: boolean) => void;
    deleteTasksQueue: (redactionId: number, id: number) => void;
    updateDocuments: (redactionId: number, id: number) => void;
    updateTasksQueue: (redactionId: number, id: number, status: string) => void;
    updateDocumentsList: (redactionId: number) => void;
}): JSX.Element => {
    const prevTaskQueueList = usePrevious(taskQueueList);
    const prevShowFinishedTasks = usePrevious(showFinishedTasks);
    const redactionLang = useSelector(getRedactionLanguage);
    const modifiedLabels = useSelector(getModifiedLabels);
    const modifiedLabelsFlag = useSelector(getModifiedLabelsFlag);
    const labels = {
        fileHeader: initialLabel,
        detailHeader: initialLabel,
        statusHeader: initialLabel,
        showFinished: initialLabel,
    };
    const langChangerule = redactionLang === 'en' ? 'resourceValue' : 'resourceValue2';

    resourceBundle.map( (resource: any) => {
        switch(resource.resourceKey) {
            case 'DOCUMENTQUEUE_COLUMN_HEADER_FILENAME':
                labels.fileHeader = resource;
                break;
            case 'DOCUMENTQUEUE_COLUMN_HEADER_STATUS':
                labels.statusHeader = resource;
                break;
            case 'DOCUMENTQUEUE_COLUMN_HEADER_DETAIL':
                labels.detailHeader = resource;
                break;
            case 'DOCUMENTQUEUE_SHOW_FINISHED_TASKS':
                labels.showFinished = resource;
                break;
        }

        return resource;
    });

    modifiedLabels.map( (resource: any) => {
        switch(resource.resourceKey) {
            case 'DOCUMENTQUEUE_COLUMN_HEADER_FILENAME':
                labels.fileHeader = resource;
                break;
            case 'DOCUMENTQUEUE_COLUMN_HEADER_STATUS':
                labels.statusHeader = resource;
                break;
            case 'DOCUMENTQUEUE_COLUMN_HEADER_DETAIL':
                labels.detailHeader = resource;
                break;
            case 'DOCUMENTQUEUE_SHOW_FINISHED_TASKS':
                labels.showFinished = resource;
                break;
        }

        return resource;
    });

    useEffect(() => {
        if (taskQueueList && !isEqual(prevTaskQueueList, taskQueueList)) {
            updateDocumentList();
        }

        const isTaskListInProgress = taskQueueList &&
            taskQueueList.some((taskQueue: ITaskList) => !taskQueue.isCompleted);

        if (isTaskListInProgress && loadedTaskList) {
            const interval = setTimeout(() => {
                const params = {
                    page: currentPage,
                    size: DEFAULT_PAGE_SIZE,
                    showFinishedTasks,
                };

                fetchDocumentTasksQueueList(redactionDocumentId, params, false);
            }, DEFAULT_DELAY);

            return (): void => clearTimeout(interval);
        }
    }, [showFinishedTasks, taskQueueList, loadedTaskList]);

    const updateDocumentListWithUnactiveCheckbox = (): void => {
        const completedTask = prevTaskQueueList && prevTaskQueueList.filter(
            (taskList: ITaskList) => !find(taskQueueList, { id: taskList.id }),
        );

        if (completedTask && completedTask.length) {
            lastProcessedDocuments.forEach((task: ITaskList): void => {
                if (find(completedTask, { id: task.id }) && task.documentId) {
                    updateDocuments(redactionDocumentId, task.documentId);
                }
            });

        }
    };

    const updateDocumentListWitActiveCheckbox = (): void => {
        taskQueueList.forEach((taskQueue: ITaskList): void => {
            const prevTaskElement = find(prevTaskQueueList, { id: taskQueue.id });
            const isStatusChanged = prevTaskElement &&
                prevTaskElement.status !==  taskQueue.status &&
                taskQueue.status === COMPLETED_STATUS;

            if (isStatusChanged &&  taskQueue.documentId) {
                updateDocuments(redactionDocumentId, taskQueue.documentId);
            }
        });
    };

    const updateDocumentList  = (): void => {

        if(prevShowFinishedTasks !== showFinishedTasks) {
            updateDocumentsList(redactionDocumentId);
        }

        if (prevShowFinishedTasks === showFinishedTasks && !showFinishedTasks) {
            updateDocumentListWithUnactiveCheckbox();
        }

        if (prevShowFinishedTasks === showFinishedTasks && showFinishedTasks) {
            updateDocumentListWitActiveCheckbox();
        }

    };

    const data = taskQueueList ? taskQueueList.map((taskQueue: ITaskList): any => ({
        ...taskQueue,
        status: <span>{taskQueue.status}</span>,
        details: (
            <div className='tooltip-action'>
                <Tooltip
                    aria-label={taskQueue.errorMessage}
                    title={(
                        <div style={{ width: 150, whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {taskQueue.errorMessage}
                        </div>
                    )}
                >
                <span>{taskQueue.errorMessage}</span>
                </Tooltip>
            </div>
        ),
        action: (
            <IconButton
                aria-label={DELETE}
                onClick={(): void => {
                    taskQueue.isFinished ?
                        deleteTasksQueue(redactionDocumentId, taskQueue.id) :
                        updateTasksQueue(redactionDocumentId, taskQueue.id, CANCELED_STATUS);
                }}
            >
                {
                    taskQueue.isFinished ? <DeleteIcon /> : <CancelIcon />
                }
            </IconButton>
        ),
    })) : [];

    const handleCheckboxChange = (checked: boolean): void => {
        const params = {
            page: currentPage,
            size: DEFAULT_PAGE_SIZE,
            showFinishedTasks: checked,
        };

        updateDataList(params);
    };

    const updateDataList = (params: IPaginationParams): void => {
        if (redactionDocumentId) {
            fetchDocumentTasksQueueList(redactionDocumentId, params);
        }
    };

    const handelLanguageChange = (param: string): string => {
        if(param === 'Filename') {
            return labels.fileHeader[langChangerule];
        } else if(param === 'Status') {
            return labels.statusHeader[langChangerule];
        } else if(param === 'Details') {
            return labels.detailHeader[langChangerule];
        } else {
            return param;
        }
    };

    return (
        <div className='queue-management'>
            { !modifiedLabelsFlag &&
                <Table
                    columns={QueueListHeaders(handelLanguageChange)}
                    data={data}
                    sortable={false}
                    showPagination={true}
                    allowSettings={true}
                    serverPagination={true}
                    defaultPageSize={DEFAULT_PAGE_SIZE}
                    pageSize={DEFAULT_PAGE_SIZE}
                    pagesCount={totalTaskCount}
                    page={currentPage}
                    loading={loading}
                    useDefaultTable={true}
                    // any is in library
                    onPageChange={(page: any): void => {
                        const params = {
                            page: page,
                            size: DEFAULT_PAGE_SIZE,
                            showFinishedTasks,
                        };
                        updateDataList(params);
                    }}
                />
            }
            <div className='queue-management-checkbox'>
                <CheckboxMaterialBuilder
                    checked={showFinishedTasks}
                    handleCheckboxChange={(param: string, checked: boolean): void => {
                        handleCheckboxChange(checked);
                    }}
                    label={labels.showFinished[langChangerule]}
                    paramName='isFinished'
                />
            </div>
        </div>
    );
};

export default QueueManagement;


import { deleteShapes, copyShapes, pastShapes, cutShapes, pageScale, pageSize } from './redux/actions/redactor';
import { setHeaderActiveItem } from './redux/actions/header';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { IAppDispatchProps } from './app.model';
import { IState } from './redux/store';
import { IModalProps } from './redux/reducers/modal/modal.model';
import { openModalWindow } from './redux/actions/modal';
import { goToPage } from './redux/actions/pageList';
import { goToDocument } from './redux/actions/documentList';
import { zoom } from './utils/pdfControl.util';

export interface IHotKeysProps {
    deleteSelectedShapes: () => void;
    copySelectedShapes: () => void;
    pastSelectedShapes: () => void;
    cutSelectedShapes: () => void;
    openHeaderMenu: (name: string, e: Event) => void;
    openModal: (type: string, modalProps: IModalProps, e: Event) => void;
    changeZoom: (direction: string, e: Event) => void;
    goToPage: (act: string) => void;
    goToDocument: (act: string) => void;
    triggerEvent: (selector: string, customEvent: MouseEvent, e: Event) => void;
}

export enum RedactorKeys {
    DELETE_SHAPES = 'Delete Shapes',
    COPY_SHAPES = 'Copy Shapes',
    PAST_SHAPES = 'Past Shapes',
    CUT_SHAPES = 'Cut Shapes',
    UNDO_SHAPES = 'Undo Shapes',
}

export enum HeaderMenuKeys {
    MENU_FILE = 'Main Menu - File',
    MENU_ACTION = 'Main Menu - Action',
    MENU_DOCUMENT = 'Main Menu- Document',
    MENU_PAGE = 'Main Menu - Page',
    MENU_ADMIN = 'Main Menu - Admin',
    MENU_HELP = 'Main Menu - Help',
}

export enum RedactorPageKeys {
    ZOOM_IN = 'Zoom In',
    ZOOM_OUT = 'Zoom Out',
    ADD_SEVER = 'Select "Add Sever"',
    ADD_HIGHLIGHT = 'Select "Add Add Highlight"',
    EXEMPTION_STAMP = 'Select ‘Exemption Stamp’ tool',
    USER_DEFINED_STAMP = 'Select ‘User Defined Stamp’ tool',
    CHANGE_DISCLOSURE = ' Set Disclosure',
}

export enum PageListKeys {
    NEXT_PAGE = 'Move to Next Page',
    PREV_PAGE = 'Move to Prev Page',
    FIRST_PAGE = 'Move to First Page in Document',
    LAST_PAGE = 'Move to Last Page in Document',
}

export enum DocumentListKeys {
    NEXT = 'Move to Next Document',
    PREV = 'Move to Prev Document',
    FIRST = 'Move to first Document in Document List',
    LAST = 'Move to last Document in Document List',
}

export enum NavigateKeys {
     UP_ARROW = 'Up Arrow',
     DOWN_ARROW = 'Down Arrow',
     LEFT_ARROW = 'Left Arrow',
     RIGHT_ARROW = 'Right Arrow',
}

export const ZOOM_IN_HOT_KEY = 'alt++';
export const ZOOM_OUT_HOT_KEY = 'alt+-';

export enum SubmitKeys {
    ENTER = 'Enter',
}

export const hotKeys = {
    [RedactorKeys.DELETE_SHAPES]: 'del',
    [RedactorKeys.COPY_SHAPES]: 'ctrl+c',
    [RedactorKeys.PAST_SHAPES]: 'ctrl+v',
    [RedactorKeys.CUT_SHAPES]: 'ctrl+x',
    [RedactorKeys.UNDO_SHAPES]: 'ctrl+z',
    [HeaderMenuKeys.MENU_FILE]: 'alt+f',
    [HeaderMenuKeys.MENU_ACTION]: 'alt+a',
    [HeaderMenuKeys.MENU_DOCUMENT]: 'alt+d',
    [HeaderMenuKeys.MENU_PAGE]: 'alt+p',
    [HeaderMenuKeys.MENU_ADMIN]: 'alt+x',
    [HeaderMenuKeys.MENU_HELP]: 'alt+h',
    [RedactorPageKeys.ZOOM_IN]: ZOOM_IN_HOT_KEY,
    [RedactorPageKeys.ZOOM_OUT]: ZOOM_OUT_HOT_KEY,
    [RedactorPageKeys.ADD_SEVER]: 'ctrl+r',
    [RedactorPageKeys.ADD_HIGHLIGHT]: 'ctrl+h',
    [RedactorPageKeys.EXEMPTION_STAMP]: 'ctrl+e',
    [RedactorPageKeys.USER_DEFINED_STAMP]: 'ctrl+i',
    [RedactorPageKeys.CHANGE_DISCLOSURE]: 'ctrl+d',
    [PageListKeys.NEXT_PAGE]: 'ctrl+down',
    [PageListKeys.PREV_PAGE]: 'ctrl+up',
    [PageListKeys.FIRST_PAGE]: 'ctrl+home',
    [PageListKeys.LAST_PAGE]: 'ctrl+end',
    [DocumentListKeys.NEXT]: 'shift+down',
    [DocumentListKeys.PREV]: 'shift+up',
    [DocumentListKeys.FIRST]: 'shift+home',
    [DocumentListKeys.LAST]: 'shift+end',
    [NavigateKeys.UP_ARROW]: 'up',
    [NavigateKeys.DOWN_ARROW]: 'down',
    [NavigateKeys.RIGHT_ARROW]: 'right',
    [NavigateKeys.LEFT_ARROW]: 'left',
    [SubmitKeys.ENTER]: 'enter',
};

export const hotKeysProps = (dispatch: ThunkDispatch<IState, IAppDispatchProps, AnyAction>): IHotKeysProps => {
    return {
        deleteSelectedShapes: (): void => {
            dispatch(deleteShapes());
        },
        copySelectedShapes: (): void => {
            dispatch(copyShapes());
        },
        pastSelectedShapes: (): void => {
            dispatch(pastShapes());
        },
        cutSelectedShapes: (): void => {
            dispatch(cutShapes());
        },
        openHeaderMenu: (name: string, e: Event): void => {
            e.preventDefault();
            dispatch(setHeaderActiveItem(name));
        },
        openModal: (type: string, modalProps: IModalProps, e: Event): void => {
            e.preventDefault();
            dispatch(openModalWindow(type, modalProps));
        },
        changeZoom: (direction: string, e: Event): void => {
            e.preventDefault();

            // const scale = zoom(direction, this.props.redactorData.scale);

            // dispatch(pageScale(scale));
            dispatch(pageSize(null));
        },
        goToPage: (act: string): void => {
            dispatch(goToPage(act));
        },
        goToDocument: (act: string): void => {
            dispatch(goToDocument(act));
        },
        triggerEvent: (selector: string, customEvent: MouseEvent, e: Event): void => {
            e.preventDefault();

            const element = document.querySelector(selector);

            if (element) {
                element.dispatchEvent(customEvent);
            }
        },
    };
};

import * as React from 'react';
import { omit, get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import {
    ID, IMAGE,
    NAME, regexImages,
    TEXT, IMAGE_PATH,
} from '../../../constants/common.constants';
import { Button, Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IDispatchProps, IProps, IStateProps, IState } from './modalWatermarkManagement.model';
import './modalWatermarkManagement.style.scss';
import { IState as StoreState } from '../../../redux/store';
import {
    CONFIRMATION_DIALOG_MODAL
} from '../../../constants/messages.constants';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import LinearProgress from '@mui/material/LinearProgress';
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form';
import { validate } from '../../../utils/validate.util';
import { AnyObject, FormApi, FormState } from 'final-form';
import { MAX_LENGTH, REQUIRED, UNIQUE } from '../../../constants/validate.constants';
import WatermarkList from './WatermarkList/WatermarkList';
import { renderTextField } from '../../../components/materialUiForms/materialUiForms';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import {
    clearWatermarkOptions,
    fetchWatermarks, postWatermark,
    removeWatermark, fetchWaterMarkOptions, updateWatermark, setWatermarkOptions,
} from '../../../redux/actions/modalWatermarksManagement';
import { IWatermark } from '../../../redux/reducers/modalWatermarks/watermarksManagement.model';
import {
    getCurrentWatermark,
    getWatermarksList,
    getWatermarksPending,
} from '../../../redux/selectors/modalWatermarksManagement';
import { EMPTY_WATERMARK_ID, emptyWatermark } from '../../../constants/watermark.contstants';
import { RadioMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import FormLabel from '@mui/material/FormLabel';
import { Scrollbars } from 'rc-scrollbars';
import { IMPORT_ERROR_TITLE_MESSAGE } from '../modalWindowImport/fileImportErrors/constants';
import { WATERMARK_MANAGEMENT } from '../../../constants/modal.constants';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { changeLang } from '../../../redux/actions/localization';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../../containers/localization/localizationData';
import { getModifiedLabels } from '../../../redux/selectors/localization';

const classNames = {
    watermarkModal: 'watermark-modal',
    watermarkModalFooter: 'watermark-modal-footer',
    watermarkModalBody: 'watermark-modal-body',
    watermarkListWrap: 'watermark-list-wrap',
    watermarkFormWrap: 'watermark-form-wrap',
    watermarkAdd: 'watermark-add',
    watermarkAddImage: 'watermark-add-image',
    watermarkImage: 'watermark-image',
    watermarkImageWrap: 'watermark-image-wrap',
    watermarkLoader: 'watermark-modal-loader',
};

const getWaterMarkLabelsByKey = (key: string): string => {
    switch(key) {
        case 'WATERMARK_MANAGEMENT_MODAL_LABEL_ADD_NEW':
            return 'addNewLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_SAVE':
            return 'saveLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_CANCEL':
            return 'cancelLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_WATERMARK_TEXT':
            return 'selectWaterMarkLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_TYPE':
            return 'typeWaterMarkLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_NAME':
            return 'nameLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_CLEAR_IMAGE':
            return 'clearLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_SELECT_WATERMARK':
            return 'selectWaterLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_IMAGE':
            return 'imageLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_SELECT_IMAGE':
            return 'selectImageLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_TEXT':
            return 'textLabel';
        case 'COMMON_LABEL_MUST_BE_UNIQUE':
            return 'mustBeUniqueLabel';
        case 'COMMON_LABEL_AT_LEAST_ONE_OF_LIST':
            return 'atLeastOneOfListLabel';
        case 'COMMON_LABEL_FIELD_IS_MANDATORY':
            return 'mandatoryFieldLabel'; 
        case 'COMMON_LABEL_CHANGES_NOT_SAVED':
            return 'changesNotSavedLabel';   
        case 'COMMON_LABEL_WARNING':
            return 'warningFieldLabel';   
        case 'WATERMARK_MANAGEMENT_MODAL_DELETE_WATERMARK_MESSAGE':
            return 'deleteFieldLabel';   
        case 'WATERMARK_MANAGEMENT_MODAL_UNSUPPORTED_IMAGE_MESSAGE':
            return 'unsupportedImageLabel';   
        case 'WATERMARK_MANAGEMENT_MODAL_ALERT_DELETE_WATERMARK_MANAGEMENT':
            return 'alertdeleteLabel';   
        case 'COMMON_LABEL_MAX_LENGTH':
            return 'maxLengthLabel';     
        default: return '';
    }
};

const labels = {
    addNewLabel: initialLabel,
    saveLabel: initialLabel,
    cancelLabel: initialLabel,
    selectWaterMarkLabel: initialLabel,
    typeWaterMarkLabel: initialLabel,
    nameLabel: initialLabel,
    clearLabel: initialLabel,
    selectWaterLabel: initialLabel,
    imageLabel: initialLabel,
    textLabel: initialLabel,
    mustBeUniqueLabel:initialLabel,
    atLeastOneOfListLabel:initialLabel,
    mandatoryFieldLabel:initialLabel,
    warningFieldLabel:initialLabel,
    deleteFieldLabel:initialLabel,
    unsupportedImageLabel: initialLabel,
    alertdeleteLabel: initialLabel,
    changesNotSavedLabel: initialLabel,
    selectImageLabel:initialLabel,
    maxLengthLabel:initialLabel,
};

export class ModalWatermarkManagementComponent extends React.Component<IProps, IState> {

    public static getDerivedStateFromProps(nextProps: IProps, prevState: IState): Partial<IState> {
        if (nextProps.currentWatermark.id !== prevState.initialCurrentWatermarkId) {
            return {
                type: nextProps.currentWatermark.imagePath ? IMAGE : TEXT,
                initialCurrentWatermarkId: nextProps.currentWatermark.id,
            };
        } else {
            return null;
        }
    }

    private readonly input: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();

        const { currentWatermark } = this.props;

        this.state = {
            imagePreviewUrl: currentWatermark.imagePath || null,
            file: null,
            type: TEXT,
            initialCurrentWatermarkId: null,
        };
    }

    public componentDidUpdate(prevProps: Readonly<IProps>): void {
        if(prevProps.currentWatermark.imagePath !== this.props.currentWatermark.imagePath) {
            this.setState({ imagePreviewUrl: null });
        }
    }

    public componentDidMount(): void {
        this.props.fetchAllWatermarks();
    }

    public componentWillUnmount(): void {
        this.props.clearWatermarkOptions();
        this.props.handleDetectChanges(false);
    }

    public getUniqueNamesOfWatermark = (): string[] => {
        const {watermarks, currentWatermark} = this.props;

        return watermarks.map((item: IWatermark) => {
            return currentWatermark.id === item.id ? '' : item.name;
        });
    }

    public render(): JSX.Element {
        const {
            currentWatermark,
            handleDetectChanges,
            watermarks,
            pending,
            permissions: {
                ADD: addPermission,
                DELETE: deletePermission,
                EDIT: editPermission,
            },
            redactionLang,
            modifiedLabels,
        } = this.props;
        const {
            imagePreviewUrl,
            type,
            file,
        } = this.state;

        const activeId = currentWatermark[ID];
        const hasEditPermission = editPermission || addPermission && !activeId;
        const uniqueFields = {name: this.getUniqueNamesOfWatermark()};
        const validateFields = [
            {
                field: NAME,
                [REQUIRED]: true,
                [MAX_LENGTH]: 128,
                [UNIQUE]: true,
            },
            {
                field: TEXT,
                [REQUIRED]: this.state.type === TEXT,
                [MAX_LENGTH]: 2000,
            },
        ];

        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if (getWaterMarkLabelsByKey(resource.resourceKey)) {
                labels[getWaterMarkLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getWaterMarkLabelsByKey(resource.resourceKey)) {
                labels[getWaterMarkLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        const typeOptions = [
            {
                value: IMAGE,
                label: labels.imageLabel[langRule],
            },
            {
                value: TEXT,
                label: labels.textLabel[langRule],
            },
        ];
        return (
            <Form
                onSubmit={this.handleSave}
                initialValues={{ ...currentWatermark }}
                validate={(values: any): { [param: string]: boolean } => validate(values, validateFields, uniqueFields, labels, langRule)}
                render={({
                    handleSubmit,
                    pristine,
                    submitting,
                    invalid,
                    form,
                }: FormRenderProps): JSX.Element => (
                    <form
                        className={classNames.watermarkModal}
                        onSubmit={handleSubmit}
                    >
                        <div className={classNames.watermarkModalBody}>
                            <div className={classNames.watermarkListWrap}>
                                {
                                    addPermission && (
                                        <Fab
                                            variant='extended'
                                            size='medium'
                                            color='primary'
                                            aria-label='Add'
                                            disabled={pending}
                                            onClick={(): void => this.handleAddNew(form)}
                                            className={classNames.watermarkAdd}
                                        >
                                            <AddIcon fontSize={'small'} />
                                            {labels.addNewLabel[langRule]}
                                        </Fab>
                                    )
                                }
                                <WatermarkList
                                    list={watermarks}
                                    activeId={activeId}
                                    deletePermission={deletePermission}
                                    handleClickWatermark={(item: IWatermark): void =>
                                        this.handleClickWatermark(item, form)}
                                    handleDeleteWatermark={this.handleDeleteWatermark}
                                />
                            </div>
                            <div className={classNames.watermarkFormWrap}>
                                {
                                    currentWatermark[ID] !== null ?
                                        <div>
                                            <Field
                                                render={renderTextField}
                                                name={NAME}
                                                label={labels.nameLabel[langRule]}
                                                margin='normal'
                                                required={true}
                                                variant='outlined'
                                                fullWidth={true}
                                                disabled={!hasEditPermission}
                                            />
                                            <div>
                                                <FormLabel>{labels.typeWaterMarkLabel[langRule]}:</FormLabel>
                                                <RadioMaterialBuilder
                                                    value={type}
                                                    handleRadioChange={
                                                        (param: string, val: string): void =>
                                                            this.handleType(val)
                                                    }
                                                    paramName={'type'}
                                                    ariaLabel={'Type watermark'}
                                                    radioList={typeOptions}
                                                    disabled={!hasEditPermission}
                                                />
                                            </div>
                                            {
                                                type === TEXT ?
                                                    <div>
                                                        <Field
                                                            render={renderTextField}
                                                            name={TEXT}
                                                            multiline={true}
                                                            rows='2'
                                                            label={labels.selectWaterMarkLabel[langRule]}
                                                            margin='normal'
                                                            variant='outlined'
                                                            fullWidth={true}
                                                            required={true}
                                                            disabled={!hasEditPermission}
                                                        />
                                                    </div> :
                                                    <div>
                                                        <input
                                                            className='hidden'
                                                            type='file'
                                                            multiple={false}
                                                            ref={this.input}
                                                            disabled={!hasEditPermission}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                                this.handleImageChange(e, form)
                                                            }
                                                        />
                                                        {
                                                            file ?
                                                                <Fab
                                                                    variant='extended'
                                                                    size='small'
                                                                    color='secondary'
                                                                    aria-label='Clear image'
                                                                    disabled={!hasEditPermission}
                                                                    onClick={(): void => this.handleClearImage(form)}
                                                                    className={classNames.watermarkAddImage}
                                                                >
                                                                    <RemoveIcon fontSize={'small'} />
                                                                    {labels.clearLabel[langRule]}
                                                                </Fab> :
                                                                <Fab
                                                                    variant='extended'
                                                                    size='small'
                                                                    color='primary'
                                                                    aria-label='Choose image'
                                                                    disabled={!hasEditPermission}
                                                                    onClick={(): void => this.input.current.click()}
                                                                    className={classNames.watermarkAddImage}
                                                                >
                                                                    <AddIcon fontSize={'small'} />
                                                                    {labels.selectImageLabel[langRule]}
                                                                </Fab>
                                                        }

                                                        <div className={classNames.watermarkImageWrap}>
                                                            <Scrollbars>
                                                                {imagePreviewUrl || currentWatermark.imagePath ? <img
                                                                    alt={'watermark'}
                                                                    src={imagePreviewUrl || currentWatermark.imagePath}
                                                                    className={classNames.watermarkImage}
                                                                /> : null}
                                                            </Scrollbars>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                        :
                                        <div className='watermark-empty'>{labels.selectWaterLabel[langRule]}</div>
                                }
                            </div>
                        </div>
                        <div className={classNames.watermarkModalFooter}>
                            <Tooltip title={labels.cancelLabel[langRule]} placement='top'>
                                <Button
                                    variant='outlined'
                                    onClick={(): void => this.handleCloseModal(form)}
                                    className='modal-window__buttons outlined'
                                    size='small'
                                >
                                    {labels.cancelLabel[langRule]}
                                </Button>
                            </Tooltip>
                            <Tooltip title={labels.saveLabel[langRule]} placement='top'>
                                <span>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        disabled={this.calcEnableSave(submitting, invalid, pristine)}
                                        onClick={(): Promise<object | undefined> => handleSubmit()}
                                        className='primary'
                                    >
                                        {labels.saveLabel[langRule]}
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div className={classNames.watermarkLoader}>
                            {pending && <LinearProgress />}
                        </div>
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={(): void => handleDetectChanges(this.isWatermarkOptionsChanged(form))}
                        />
                    </form>
                )}
            />
        );
    }

    private calcEnableSave = (submitting: boolean, invalid: boolean, pristine: boolean): boolean => {
        if (this.state.type === IMAGE && this.state.file && !submitting && !invalid) {
            return false;
        }

        return submitting || invalid || pristine;
    }

    private handleType = (type: string): void => {
        this.setState({type});
    }

    private handleClearImage = (form?: FormApi): void => {
        if (form) {
            form.change('file', null);
            this.input.current.value = '';
        }

        this.setState({
            file: null,
            imagePreviewUrl: null,
        });
    }
    private handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, form: FormApi): void => {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];

        if (regexImages.test(file.name.toLowerCase())) {
            form.batch(() => {
                form.change('file', file);
            });

            reader.readAsDataURL(file);
            reader.onloadend = (): void => {
                this.setState({
                    file,
                    imagePreviewUrl: reader.result,
                });
            };
        } else {
            const langRule = changeLang(this.props.redactionLang)
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: IMPORT_ERROR_TITLE_MESSAGE,
                title: labels.warningFieldLabel[langRule],
                message: labels.unsupportedImageLabel[langRule],
            });
        }

    }

    private handleAddNew = (form: FormApi): void => {
        const langRule = changeLang(this.props.redactionLang)
        if (this.isWatermarkOptionsChanged(form)) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningFieldLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => {
                    this.handleClearImage();
                    this.props.setWatermarkOptions(emptyWatermark);
                },
            });
        } else {
            this.handleClearImage();
            this.props.setWatermarkOptions(emptyWatermark);
        }
    }

    private handleSave = (values: AnyObject): void => {
        const {currentWatermark} = this.props;
        const {type} = this.state;
        let file = this.state.file;
        const currentId = currentWatermark[ID];
        const watermarkOptions = {
            [ID]: currentId,
            [NAME]: values[NAME],
        };

        if (type === TEXT) {
            watermarkOptions[TEXT] = values[TEXT];
            file = null;
        }

        if (currentId === EMPTY_WATERMARK_ID) {
            const options = omit(watermarkOptions, ID);

            this.props.postWatermark(options, file);
            this.setState({file: null});

            return;
        }

        this.props.updateWatermark(watermarkOptions, file);
        this.setState({file: null});
    }

    private isWatermarkOptionsChanged(form: FormApi): boolean {
        const {currentWatermark, watermarks} = this.props;
        const currentId = currentWatermark[ID];

        if (currentId === null) { // not selected watermark
            return false;
        }

        const formValues = form.getState().values;

        const watermark = {
            ...formValues,
            [NAME]: get(formValues, NAME, ''),
            [TEXT]: get(formValues, TEXT, ''),
            [IMAGE_PATH]: null,
        };

        if (currentId === EMPTY_WATERMARK_ID) {
            return !isEqual(emptyWatermark, watermark);
        }

        const initialWatermarkOptions = watermarks
            .find((i: IWatermark) => currentId === i.id);

        const initialInfo = {
            ...initialWatermarkOptions,
            [NAME]: get(initialWatermarkOptions, NAME, ''),
            [TEXT]: get(initialWatermarkOptions, TEXT, ''),
            [IMAGE_PATH]: null,
        };

        return !isEqual(initialInfo, watermark) || !!this.state.imagePreviewUrl;
    }

    private handleClickWatermark = (item: IWatermark, form: FormApi): void => {
        const prevItemId = this.props.currentWatermark.id;
        const currItemId = item.id;

        if (prevItemId === currItemId) {
            return;
        }

        if (this.isWatermarkOptionsChanged(form)) {
            const langRule = changeLang(this.props.redactionLang)
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningFieldLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => {
                    this.props.fetchWatermarkOptions(item);
                    this.props.handleDetectChanges(false);
                    this.setState({
                        file: null,
                        imagePreviewUrl: null,
                    });
                },
            });
        } else {
            this.props.fetchWatermarkOptions(item);
        }
    }

    private handleDeleteWatermark = (watermark: IWatermark): void => {
        const {currentWatermark} = this.props;
        const isRequestType = !!watermark.requestType.length;
        const langRule = changeLang(this.props.redactionLang)
        this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: `Delete-${watermark.name}`,
            title: labels.warningFieldLabel[langRule],
            message:  <div>{isRequestType ? labels.alertdeleteLabel[langRule]: labels.deleteFieldLabel[langRule]}</div>,
            confirm: (): () => void => (): void => this.props.deleteWatermark(
                watermark,
                currentWatermark[ID] === watermark.id,
            ),
        });
    }

    private handleCloseModal = (form: FormApi): void => {

        if (this.isWatermarkOptionsChanged(form)) {
            const values = {...form.getState().values};
            const watermarkOptions = {
                [ID]: values[ID],
                [NAME]: values[NAME],
                [TEXT]: values[TEXT],
                [IMAGE_PATH]: values[IMAGE_PATH],
            };

            this.props.setWatermarkOptions(watermarkOptions);
            const langRule = changeLang(this.props.redactionLang)
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningFieldLabel[langRule],
                message: labels.changesNotSavedLabel[langRule],
                confirm: (): () => void => (): void => this.props.handleCloseModalWindow(WATERMARK_MANAGEMENT),
            });
        } else {
            this.props.handleCloseModalWindow(WATERMARK_MANAGEMENT);
        }
    }
}

const mapStateToProps = (state: StoreState): IStateProps => ({
    currentWatermark: getCurrentWatermark(state),
    watermarks: getWatermarksList(state),
    pending: getWatermarksPending(state),
    redactionLang: getRedactionLanguage(state),
    modifiedLabels: getModifiedLabels(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, IDispatchProps, AnyAction>): IDispatchProps => ({
    fetchAllWatermarks: (): void => {
        dispatch(fetchWatermarks());
    },
    setWatermarkOptions: (data: IWatermark): void => {
        dispatch(setWatermarkOptions(data));
    },
    fetchWatermarkOptions: (data: IWatermark): void => {
      dispatch(fetchWaterMarkOptions(data));
    },
    clearWatermarkOptions: (): void => {
        dispatch(clearWatermarkOptions());
    },
    handleCloseModalWindow: (id: string): void => {
        dispatch(handleCloseModalWindow(id));
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
    postWatermark: (data: IWatermark, file: File): void => {
        dispatch(postWatermark(data, file));
    },
    updateWatermark: (data: IWatermark, file: File): void => {
        dispatch(updateWatermark(data, file));
    },
    deleteWatermark: (data: IWatermark, needsClearState: boolean): void => {
        dispatch(removeWatermark(data, needsClearState));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalWatermarkManagementComponent);

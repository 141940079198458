import * as React from 'react';
import './assignedContacts.styles.scss';
import { IProps } from './assignedContacts.model';
import ResizibleSortableTable from '../../../../components/table/Table';
import {
    AssignedContactHeaders, EMPTY_ASSIGNED_CONTACTS_MESSAGE,
    REMOVE,
} from '../../../../constants/selectContacts.contstants';
import {
    IPeopleRecords,
    IPeopleSearchRecordsTable,
} from '../../../../redux/reducers/disclosureContacts/disclosureContacts.model';
import Button from '@mui/material/Button';
import { LOADING_MESSAGE } from '../../../../constants/common.constants';
import { IStateSearchTab } from '../modalSelectContacts.model';
import { PageData } from 'redux/reducers/common/common.model';

export class AssignedContacts extends  React.Component<IProps, IStateSearchTab> {
    constructor(props: IProps) {
        super(props);
        this.state = {
           sortParams : null
        };
    }

    private handleSortTable = (param: string, val: string, pageName: string): void => {
        const pageData: PageData = {
            pageName: pageName,
            columnName: param,
            sortOrder: val,
        };
       this.setState({
        sortParams : pageData
       })
    }
    public render(): JSX.Element {
        const {
            assignContacts,
            assignedPeopleRecordsPending,
            removeAssignedContact,
            removeAllAssignedContact,
            labels,
            langRule,
        } = this.props;

        const assignContactsList = assignContacts.map((item: IPeopleRecords): IPeopleSearchRecordsTable => {
            return {
                nameFirst: item.nameFirst,
                nameLast: item.nameLast,
                nameTitle: item.nameTitle,
                peopleRsn: item.peopleRsn,
                action: (
                    <Button
                        color='primary'
                        onClick={(): void => removeAssignedContact(item.peopleRsn)}
                        size='small'
                    >
                        {labels.removeLabel[langRule]}
                    </Button>
                ),
            };
        });

        return (
            <div className='assigned-contact'>
                <div className='assigned-contact-head'>
                    <h3>{labels.assignedContactsTitleLabel[langRule]}</h3>
                    {
                        assignContactsList.length ?
                            <Button
                                className='outlined'
                                variant='outlined'
                                onClick={(): void => removeAllAssignedContact()}
                                size='small'
                            >
                                {labels.removeAllLabel[langRule]}
                            </Button> : null
                    }
                </div>

                {
                    assignContactsList.length ?
                        <ResizibleSortableTable
                            data={assignContactsList}
                            columns={AssignedContactHeaders(labels, langRule)}
                            userPreferenceTable={true}
                            pageName={'AssignedContacts'}
                            handleSortSession={this.handleSortTable}
                            sortParams={this.state.sortParams ? this.state.sortParams : null}
                            />
                        :
                        <p>{labels.noPersonaddedLabel[langRule]}</p>
                }
            </div>
        );
    }

}

export default AssignedContacts;
import { AnyAction, Dispatch } from 'redux';
import api from '../../api/reductionApi';
import {
    GET_ANNOTATION_TYPE_PENDING,
    GET_ANNOTATION_TYPE_SUCCESS,
    GET_ANNOTATION_TYPE_FAILURE,
    POST_ANNOTATION_TYPE_PENDING,
    POST_ANNOTATION_TYPE_SUCCESS,
    POST_ANNOTATION_TYPE_FAILURE,
    PUT_ANNOTATION_TYPE_PENDING,
    PUT_ANNOTATION_TYPE_SUCCESS,
    PUT_ANNOTATION_TYPE_FAILURE,
    DELETE_ANNOTATION_TYPE_PENDING,
    DELETE_ANNOTATION_TYPE_SUCCESS,
    DELETE_ANNOTATION_TYPE_FAILURE,
    SET_SELECTED_ANNOTATIONS_TYPES,
    LOCAL_CHANGE_ANNOTATIONS_TYPES,
} from '../reducers/annotationTypes/constants';
import { action } from 'typesafe-actions';
import { IAnnotationType } from '../reducers/annotationTypes/annotationTypes.model';
import { IError } from '../common.model';
import { handleCloseAllModalWindows, openModalWindow, handleCloseModalWindow } from './modal';
import { addError } from './errorHandling';
import {
    WARNING,
    CONFIRMATION_DIALOG_MODAL,
    DELETE_ANNOTATION_TYPE_MESSAGE,
    DELETE_ANNOTATION_TYPE_TITLE,
    ALERT_DIALOG_MODAL,
} from '../../constants/messages.constants';
import { IState } from '../store';
import { setInteractiveMode } from './layoutRedactorTypes';
import { SET_INTERACTIVE_MODE } from '../reducers/layoutRedactorTypes/constants';
import { changeLang, commonLabels, getCommonLabelsByKey } from './localization';
import resourceBundle from '../../containers/localization/localizationData';

export const fetchAnnotationListPending = (): AnyAction => action(GET_ANNOTATION_TYPE_PENDING);
export const fetchAnnotationListSuccess = (dataList: IAnnotationType[]): AnyAction =>
    action(GET_ANNOTATION_TYPE_SUCCESS, dataList);
export const fetchAnnotationListFailure = (error: IError): AnyAction => action(GET_ANNOTATION_TYPE_FAILURE, error);
export const postAnnotationTypePending = (): AnyAction => action(POST_ANNOTATION_TYPE_PENDING);
export const postAnnotationTypeSuccess = (data: IAnnotationType): AnyAction =>
    action(POST_ANNOTATION_TYPE_SUCCESS, data);
export const postAnnotationTypeFailure = (error: IError): AnyAction => action(POST_ANNOTATION_TYPE_FAILURE, error);
export const putAnnotationTypePending = (): AnyAction => action(PUT_ANNOTATION_TYPE_PENDING);
export const putAnnotationTypeSuccess = (data: IAnnotationType): AnyAction => action(PUT_ANNOTATION_TYPE_SUCCESS, data);
export const putAnnotationTypeFailure = (error: IError): AnyAction => action(PUT_ANNOTATION_TYPE_FAILURE, error);
export const deleteAnnotationTypePending = (): AnyAction => action(DELETE_ANNOTATION_TYPE_PENDING);
export const deleteAnnotationTypeSuccess = (id: number): AnyAction => action(DELETE_ANNOTATION_TYPE_SUCCESS, id);
export const deleteAnnotationTypeFailure = (error: IError): AnyAction => action(DELETE_ANNOTATION_TYPE_FAILURE, error);

export const setSelectedAnnotation = (data: IAnnotationType): AnyAction => action(SET_SELECTED_ANNOTATIONS_TYPES, data);
export const changeLocallyAnnotation = (data: IAnnotationType[]): AnyAction =>
    action(LOCAL_CHANGE_ANNOTATIONS_TYPES, data);

export const fetchAnnotationTypeList = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchAnnotationListPending());
        try {
            const response = await api.annotationType.getAllAnnotationType();

            dispatch(fetchAnnotationListSuccess(response));
        } catch (error) {
            dispatch(fetchAnnotationListFailure(error));
            dispatch(addError(error));
        }
    };

export const postAnnotationType = (data: IAnnotationType[]): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(postAnnotationTypePending());
        try {
            const response = await api.annotationType.postAnnotationType(data);

            dispatch(postAnnotationTypeSuccess(response));
        } catch (error) {
            dispatch(postAnnotationTypeFailure(error));
            dispatch(addError(error));
        }
    };

export const putAnnotationType = (data: IAnnotationType[]): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(putAnnotationTypePending());
        try {
            const response = await api.annotationType.updateAnnotationType(data);

            dispatch(putAnnotationTypeSuccess(response));
        } catch (error) {
            dispatch(putAnnotationTypeFailure(error));
            dispatch(addError(error));
        }
    };

export const confirmDeleteAnnotationType = (id: number): (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const {
            localStorage: {
                language,
            },
            localization:{
                modifiedLabels
            }
        } = getState();
        const labels = commonLabels;
        const langRule = changeLang(language);
        resourceBundle.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: DELETE_ANNOTATION_TYPE_TITLE,
            title: labels.warningLabel[langRule],
            message: labels.changesNotSavedLabel[langRule],
            confirm: (): (dispatch: Dispatch, getState: () => IState) => void => deleteAnnotationType(id),
        }));
    };

export const deleteAnnotationType = (id: number): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(deleteAnnotationTypePending());
        try {
            await api.annotationType.deleteAnnotationType(id);
            dispatch(deleteAnnotationTypeSuccess(id));
            dispatch(setSelectedAnnotation(null));
            dispatch(handleCloseModalWindow(DELETE_ANNOTATION_TYPE_TITLE));
        } catch (error) {
            dispatch(deleteAnnotationTypeFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: WARNING,
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

export const setSelectedAnnotationTypes =
    (data: IAnnotationType): (dispatch: Dispatch) => void => async (dispatch: Dispatch): Promise<void> => {
        dispatch(setSelectedAnnotation(data));
        dispatch(handleCloseAllModalWindows());
        dispatch(action(SET_INTERACTIVE_MODE, false));
    };

import * as React from 'react';
import { connect } from 'react-redux';
import { Button, TextField, Tooltip } from '@mui/material';
import { RadioMaterialBuilder, SelectMaterialBuilder, CheckboxMaterialBuilder } from '../../components/materialUiForms/materialUiFormBuilder';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { findIndex } from 'lodash';
import { IState as StoreState, IState } from '../../redux/store';
import './advanceSearch.style.scss';
import Table from '../../components/table/Table';
import FormLabel from '@mui/material/FormLabel';
import { pickBy, identity } from 'lodash';
import * as constants from '../../constants/advancedSearch.constants';
import * as commonConstants from '../../constants/export.contstants';
import {
    IActionAdvanceSearch,
    IAdvanceSearchParamChange,
    IAdvanceSearchParams,
    ICommonAdvanceSearchProps,
    IStoreAdvanceSearch,
    IStates,
} from './advancedSearch.model';
import {
    getAdvanceSearchErrors,
    getAdvanceSearchParams,
    getAdvanceSearchData,
    getActList,
    getExemptionsList,
    getAdvanceSearchResultLoading,
    getAdvanceSearchColumnHeaderLoading,
    getAdvanceSearchTableColumnHeader,
    getResetedAdvanceSearchColumnHeaderLoading,
} from '../../redux/selectors/advanceSearch';
import {
    getLanguageMetaData,
    getClassificationMetaData,
    getDocumentTypeMetaData,
    getSubjectMetaData,
} from '../../redux/selectors/indexMetadata';
import {
    setAdvanceSearchParams,
    clearAdvanceSearchParams,
    updateAdvanceSearchErrors,
    postAdvanceSearchValue,
    getAct,
    getAllAct,
    getExemptions,
    setExemptionsList,
    clearAdvanceSearchResult,
    setAdvanceSearchTableColumns,
    modifyAdvanceSearchColumnHeader,
    getResetedAdvanceSearchColumnHeader,
} from '../../redux/actions/advanceSearch';
import { getArticleStamps, getStampsList } from '../../redux/selectors/redactor';
import {
    IArticleStamp, IStampType,
    IValidExemptionDtoList,
} from '../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import Checkbox from '@mui/material/Checkbox';
import AccordionDetails from '@mui/material/AccordionDetails';
import { getCurrentDisclosureTypes } from '../../redux/selectors/disclosureTypes';
import { IDisclosureOptions } from '../../redux/reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { ID, TEXT, NAME, OK, NO, YES } from '../../constants/common.constants';
import { getAnnotationTypesList } from '../../redux/selectors/annotationTypes';
import { IAnnotation, IPages } from '../../redux/reducers/pageList/pageList.model';
import { fetchStampList } from '../../redux/actions/layoutRedactorTypes';
import moment = require('moment');
import CheckboxContainer from './CheckboxContainer';
import { emptySelectOption } from '../../components/materialUiForms/materialUiForms';
import {
    ADVANCED_SEARCH_RESULTS_LABEL,
    FIELDS_NUMBER_TYPE,
    SPECIFIC_REQUEST_FIELDS,
    SPECIFIC_SCOPE_PARAM,
} from '../../constants/advancedSearch.constants';
import { getRedactionDocumentId, getActCode, getUserMetaData, getRedactionFolderRsn } from '../../redux/selectors/initialization';
import {
    GO_NEXT_PAGE_RESULT,
    GO_PREV_PAGE_RESULT,
} from '../../constants/globalSearch.constants';
import IconButton from '@mui/material/IconButton';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Row } from 'react-table';
import { ITrProps } from '../../components/table/table.model';
import { ICurrentIds, ICurrentSelectedDoc } from '../globalSearch/globalSearch.model';
import { IFile } from '../../redux/reducers/documentList/documentList.model';
import { getDocumentList, getStackId } from '../../redux/selectors/documentList';
import { fetchDocumentMetadata, setDocumentToSelectedList, setPageToSelectedList } from '../../redux/actions/pageList';
import { getSearchDataFromNextDoc, restrictOnFetchDataPageList, setSearchCurrentPageId } from '../../redux/actions/globalSearch';
import { getCurrentPageId, getRestrictFlagForPageListFetchData } from '../../redux/selectors/globalSearch';
import { IAct, IAdvanceSearchTableData } from '../../redux/reducers/advanceSearch/advanceSearch.model';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { b64EncodeUnicode } from '../../utils/encode.util';
import { getFoiFoldersOptions } from '../../redux/selectors/modalSearchDocument';
import { getRequestSearchFolders } from '../../redux/actions/initialization/initialization';
import { ISelectOptions } from '../../components/materialUiForms/marerialUiForms.model';
import { EXACT_MATCH, EXACT_MATCH_PARAM } from '../../constants/common.constants';
import { ANY_ONE_FIELD_REQUIRED } from '../../constants/validate.constants';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { Spinner } from '../../components/spinner/Spinner';
import { fetchFoiFolderTypes } from '../../redux/actions/modalSearchDocuments';
import { getCurrentDocument, getPageCurrentDocLoading } from '../../redux/selectors/pageList';
import { getPageNumberByPageId, getPageResource, pageSize } from '../../redux/actions/redactor';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { handleCloseModalWindow, openModalWindow } from '../../redux/actions/modal';
import { CONFIRMATION_DIALOG_MODAL, NO_MATCHING_RESULTS,
    OPEN_RESULT_IN_NEW_TAB, SELECTED_DOC_NOT_IN_CURRENT_STACK } from '../../constants/messages.constants';
import { fetchDocumentList, saveStackId } from '../../redux/actions/documentList';
import { getRedactionLanguage, getRedactionMode } from '../../redux/selectors/localStorage';
import { getAuthFlag, getModifiedLabels } from '../../redux/selectors/localization';
import { initialLabel, langEnglish, langFrench } from '../../constants/localization.constants';
import resourceBundle from '../localization/localizationData';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';
import { MODE_CONTRIBUTE } from '../../redux/reducers/localStorage/constant';
import dayjs = require('dayjs');
import { PageData } from '../../redux/reducers/common/common.model';
import { setTableSort } from '../../redux/actions/CommonTypesAction';
import { getSortParams } from '../../redux/selectors/commonSelector';
import { Dayjs } from 'dayjs';
const className = {
    COLOR_CHECKBOX: 'color-checkbox',
};

class AdvancedSearch extends React.Component<ICommonAdvanceSearchProps, IStates> {
    constructor(props: ICommonAdvanceSearchProps) {
        super(props);
        this.state = {
            exactMatch: false,
            mandatoryFields: false,
            dateError: false,
            page: 0,
        };
    }

    public componentDidMount(): void {

        if (this.props.requestTypesListData && !this.props.requestTypesListData.length && this.props.auth) {
            this.props.fetchFoiFolderTypes();
        }

    }

    public componentDidUpdate(prevProps: ICommonAdvanceSearchProps): void {
        if (!prevProps.currentActCode && this.props.currentActCode) {
            this.props.getAct(this.props.currentActCode);
        }

        if (prevProps.redactionDocumentId !== this.props.redactionDocumentId && this.props.redactionDocumentId) {
            this.props.getRequestSearchFolders();
        }

        if (this.props.currentActCode && prevProps.acts.length !== this.props.acts.length
            && this.props.acts.length === 1) {
            this.props.getExemptions(this.props.currentActCode);
            this.props.setAdvanceSearchParams({ [constants.ACT]: this.props.currentActCode });
            this.props.advanceSearchParams[constants.EXEMPTION_PARAMS] = [];
        }

        if (prevProps.advanceSearchParams[constants.SEARCH_SCOPE_PARAM]
            !== this.props.advanceSearchParams[constants.SEARCH_SCOPE_PARAM]) {
            if (this.props.advanceSearchParams[constants.SEARCH_SCOPE_PARAM] === constants.CURRENT_SCOPE_PARAM) {
                this.props.getExemptions(this.props.currentActCode);
                this.props.setAdvanceSearchParams({ [constants.ACT]: this.props.currentActCode });
            } else {
                this.props.cleanExemption();
                this.props.setAdvanceSearchParams({ [constants.ACT]: '' });
                this.props.getAllAct();
            }

            this.props.advanceSearchParams[constants.EXEMPTION_PARAMS] = [];
        }

        if (this.state.mandatoryFields &&
            this.props.advanceSearchParams.searchScope === SPECIFIC_SCOPE_PARAM) {
            if (this.props.advanceSearchParams.specificRequest.folderRSN ||
                (this.props.advanceSearchParams.specificRequest.requestNumber === null ?
                    this.props.advanceSearchParams.specificRequest.requestNumber :
                    this.props.advanceSearchParams.specificRequest.requestNumber.trim()) ||
                this.props.advanceSearchParams.specificRequest.requestType) {
                this.setState({ mandatoryFields: false });
            }
        }
    }

    public render(): JSX.Element {
        const {
            advanceSearchParams,
            languageOptions,
            classificationOptions,
            documentTypeOptions,
            annotationTypesList,
            stampsTypeList,
            requestTypesListData,
            clearSearchParams,
            currentPageId,
            acts,
            resultLoading,
            advanceSearchData,
            currentDocLoading,
            modifiedLabels,
            redactionLanguage,
            redactionMode,
            page,
        } = this.props;

        const isSpecificRequest = advanceSearchParams[constants.SEARCH_SCOPE_PARAM] === constants.SPECIFIC_SCOPE_PARAM;
        const isCurrentRequest = advanceSearchParams[constants.SEARCH_SCOPE_PARAM] === constants.CURRENT_SCOPE_PARAM;
        const languageOptionsWithEmpty = [...languageOptions, ...emptySelectOption];
        const classificationOptionsWithEmpty = [...classificationOptions, ...emptySelectOption];
        const documentTypeOptionsWithEmpty = [...documentTypeOptions, ...emptySelectOption];
        const requestTypesListDataWithEmpty = [...requestTypesListData, ...emptySelectOption];
        const specificRequestValues = advanceSearchParams[constants.SPECIFIC_REQUEST_PARAM];
        const INVALID_FORMAT = "Invalid Date Format";
        const actsOptions = acts.map((item: IAct): ISelectOptions => {
             if (redactionLanguage === 'en') {
                return {
                    label: item.actDesc,
                    value: String(item.actCode),
                };
             } else {
                return {
                    label: item.actDesc2 ? item.actDesc2 : item.actDesc,
                    value: String(item.actCode),
                };
             }
        });
        const changeLangRule = redactionLanguage === 'en' ? 'resourceValue' : 'resourceValue2';
        const labels = {
            scopeLabel: initialLabel,
            allReqLabel: initialLabel,
            curReqLabel: initialLabel,
            specReqLabel: initialLabel,
            folderRsnLabel: initialLabel,
            reqTypeLabel: initialLabel,
            reqNumberLabel: initialLabel,
            exactMatchLabel: initialLabel,
            languageLabel: initialLabel,
            classificationLabel: initialLabel,
            refNumberLabel: initialLabel,
            docTypeLabel: initialLabel,
            toOrgLabel: initialLabel,
            toLabel: initialLabel,
            toDateLabel: initialLabel,
            fromOrgLabel: initialLabel,
            fromLabel: initialLabel,
            fromDateLabel: initialLabel,
            notesLabel: initialLabel,
            subjectLabel: initialLabel,
            field1Label: initialLabel,
            docDateLabel: initialLabel,
            docNameLabel: initialLabel,
            filenameLabel: initialLabel,
            paginationNumberLabel: initialLabel,
            actLabel: initialLabel,
            exemExclusionLabel: initialLabel,
            userDefinedStampLabel: initialLabel,
            annotationLabel: initialLabel,
            disclosureLabel: initialLabel,
            clearLabel: initialLabel,
            searchLabel: initialLabel,
            goToPreviousLabel: initialLabel,
            goToNextLabel: initialLabel,
            resultsTitleLabel: initialLabel,
            maxLengthLabel: initialLabel,
        };

        redactionLanguage ? redactionLanguage === langFrench ? dayjs.locale(langFrench) : dayjs.locale(langEnglish)
            : dayjs.locale(langEnglish);

        resourceBundle.map( (resource: any) => {
            switch(resource.resourceKey) {
                case 'ADVANCEDSEARCH_SEARCH_SCOPE':
                    labels.scopeLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_CURRENT_REQUEST':
                    labels.curReqLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_ALL_REQUESTS':
                    labels.allReqLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_SPECIFIC_REQUEST':
                    labels.specReqLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FOLDER_RSN':
                    labels.folderRsnLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_REQUEST_TYPE':
                    labels.reqTypeLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_REQUEST_NUMBER':
                    labels.reqNumberLabel = resource;
                    break;
                case 'COMMON_LABEL_EXACT_MATCH':
                    labels.exactMatchLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_LANGUAGE':
                    labels.languageLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_CLASSIFICATION':
                    labels.classificationLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_REFERENCE_NUMBER':
                    labels.refNumberLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DOCUMENT_TYPE':
                    labels.docTypeLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_TO_ORG':
                    labels.toOrgLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FROM_ORG':
                    labels.fromOrgLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_TO':
                    labels.toLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FROM':
                    labels.fromLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_NOTES':
                    labels.notesLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_SUBJECT':
                    labels.subjectLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FIELD1':
                    labels.field1Label = resource;
                    break;
                case 'ADVANCEDSEARCH_DOC_DATE':
                    labels.docDateLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DATE_FROM':
                    labels.fromDateLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DATE_TO':
                    labels.toDateLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DOC_NAME':
                    labels.docNameLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FILENAME':
                    labels.filenameLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_PAGINATION_NUMBER':
                    labels.paginationNumberLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_ACT':
                    labels.actLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_EXEMPTIONS_EXCLUSIONS':
                    labels.exemExclusionLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_USER_DEFINED_STAMPS':
                    labels.userDefinedStampLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_ANNOTATIONS':
                    labels.annotationLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DISCLOSURE_NAME':
                    labels.disclosureLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_CLEAR':
                    labels.clearLabel = resource;
                    break;
                case 'COMMON_LABEL_SEARCH':
                    labels.searchLabel = resource;
                    break;
                case 'COMMONSEARCH_GO_TO_PREVIOUS':
                    labels.goToPreviousLabel = resource;
                    break;
                case 'COMMONSEARCH_GO_TO_NEXT':
                    labels.goToNextLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_RESULTS_TITLE':
                    labels.resultsTitleLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_MAX_LENGTH_ERROR':
                    labels.maxLengthLabel = resource;
                    break;

            }

            return resource;
        });

        modifiedLabels.map( (resource: any) => {
            switch(resource.resourceKey) {
                case 'ADVANCEDSEARCH_SEARCH_SCOPE':
                    labels.scopeLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_CURRENT_REQUEST':
                    labels.curReqLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_ALL_REQUESTS':
                    labels.allReqLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_SPECIFIC_REQUEST':
                    labels.specReqLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FOLDER_RSN':
                    labels.folderRsnLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_REQUEST_TYPE':
                    labels.reqTypeLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_REQUEST_NUMBER':
                    labels.reqNumberLabel = resource;
                    break;
                case 'COMMON_LABEL_EXACT_MATCH':
                    labels.exactMatchLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_LANGUAGE':
                    labels.languageLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_CLASSIFICATION':
                    labels.classificationLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_REFERENCE_NUMBER':
                    labels.refNumberLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DOCUMENT_TYPE':
                    labels.docTypeLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_TO_ORG':
                    labels.toOrgLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FROM_ORG':
                    labels.fromOrgLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_TO':
                    labels.toLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FROM':
                    labels.fromLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_NOTES':
                    labels.notesLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_SUBJECT':
                    labels.subjectLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FIELD1':
                    labels.field1Label = resource;
                    break;
                case 'ADVANCEDSEARCH_DOC_DATE':
                    labels.docDateLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DATE_FROM':
                    labels.fromDateLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DATE_TO':
                    labels.toDateLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DOC_NAME':
                    labels.docNameLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_FILENAME':
                    labels.filenameLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_PAGINATION_NUMBER':
                    labels.paginationNumberLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_ACT':
                    labels.actLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_EXEMPTIONS_EXCLUSIONS':
                    labels.exemExclusionLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_USER_DEFINED_STAMPS':
                    labels.userDefinedStampLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_ANNOTATIONS':
                    labels.annotationLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_DISCLOSURE_NAME':
                    labels.disclosureLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_CLEAR':
                    labels.clearLabel = resource;
                    break;
                case 'COMMON_LABEL_SEARCH':
                    labels.searchLabel = resource;
                    break;
                case 'COMMONSEARCH_GO_TO_PREVIOUS':
                    labels.goToPreviousLabel = resource;
                    break;
                case 'COMMONSEARCH_GO_TO_NEXT':
                    labels.goToNextLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_RESULTS_TITLE':
                    labels.resultsTitleLabel = resource;
                    break;
                case 'ADVANCEDSEARCH_MAX_LENGTH_ERROR':
                    labels.maxLengthLabel = resource;
                    break;

            }

            return resource;
        });


        const paginatedData = this.props.advanceSearchData;
        const errLengthMsg = <span className='error-message'>{labels.maxLengthLabel[changeLangRule]}</span>;
        const searchScopeOptions = redactionMode === MODE_CONTRIBUTE ?
            [
                { value: constants.CURRENT_SCOPE_PARAM, label: labels.curReqLabel[changeLangRule] }]
            : [
                { value: constants.CURRENT_SCOPE_PARAM, label: labels.curReqLabel[changeLangRule] },
                { value: constants.ALL_SCOPE_PARAM, label: labels.allReqLabel[changeLangRule] },
                { value: constants.SPECIFIC_SCOPE_PARAM, label: labels.specReqLabel[changeLangRule] },
            ];    

        return (
            <div className='advance-search'>
                <div>
                    <FormLabel>{labels.scopeLabel[changeLangRule]}:</FormLabel>
                    <RadioMaterialBuilder
                        value={advanceSearchParams[constants.SEARCH_SCOPE_PARAM] ?
                            advanceSearchParams[constants.SEARCH_SCOPE_PARAM] : ''}
                        handleRadioChange={this.handleChange}
                        paramName={constants.SEARCH_SCOPE_PARAM}
                        ariaLabel={constants.SEARCH_SCOPE}
                        radioList={searchScopeOptions}
                    />
                </div>
                {isSpecificRequest &&
                    <div className='specific-search-block'>
                        <TextField
                            type='number'
                            label={labels.folderRsnLabel[changeLangRule]}
                            variant='outlined'
                            value={specificRequestValues[constants.FOLDER_RSN_PARAM] ?
                                specificRequestValues[constants.FOLDER_RSN_PARAM] : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(constants.FOLDER_RSN_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            fullWidth={true}
                            error={this.existError(constants.FOLDER_RSN_PARAM)}
                        />

                        {this.existError(constants.FOLDER_RSN_PARAM) && errLengthMsg}

                        <SelectMaterialBuilder
                            value={specificRequestValues[constants.SEARCH_REQUEST_TYPE_PARAM] ?
                                specificRequestValues[constants.SEARCH_REQUEST_TYPE_PARAM] : ''}
                            label={labels.reqTypeLabel[changeLangRule]}
                            paramName={constants.SEARCH_REQUEST_TYPE_PARAM}
                            handleSelectChange={this.handleChange}
                            options={requestTypesListDataWithEmpty}
                        />

                        <div>
                            {/* <FormLabel>{constants.REQUEST_NUMBER}:</FormLabel> */}

                            <div className={'request-number-inline'}>
                                <TextField
                                    value={specificRequestValues[constants.REQUEST_NUMBER_PARAM] ?
                                        specificRequestValues[constants.REQUEST_NUMBER_PARAM] : ''}
                                    label={labels.reqNumberLabel[changeLangRule]}
                                    fullWidth={true}
                                    type='text'
                                    variant='outlined'
                                    inputProps={{ maxLength: 40 }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                        this.handleChange(constants.REQUEST_NUMBER_PARAM,
                                            (e.target as HTMLInputElement).value)
                                    }
                                />
                                <CheckboxMaterialBuilder
                                    type={commonConstants.CHECKBOX_TYPE_DEFAULT}
                                    label={labels.exactMatchLabel[changeLangRule]}
                                    checked={this.state.exactMatch}
                                    handleCheckboxChange={(param: string, checked: boolean):
                                        void => this.setState({ exactMatch: checked })
                                    }
                                    paramName={'exactMatch'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className='adv-search-options'>
                    <SelectMaterialBuilder
                        value={advanceSearchParams[constants.LANGUAGE_PARAM] ?
                            advanceSearchParams[constants.LANGUAGE_PARAM] : ''}
                        label={labels.languageLabel[changeLangRule]}
                        paramName={constants.LANGUAGE_PARAM}
                        handleSelectChange={this.handleChange}
                        options={languageOptionsWithEmpty}
                    />
                    <SelectMaterialBuilder
                        value={advanceSearchParams[constants.CLASSIFICATION_PARAM] ?
                            advanceSearchParams[constants.CLASSIFICATION_PARAM] : ''}
                        label={labels.classificationLabel[changeLangRule]}
                        paramName={constants.CLASSIFICATION_PARAM}
                        handleSelectChange={this.handleChange}
                        options={classificationOptionsWithEmpty}
                    />
                </div>
                <div className='adv-search-options'>
                    <div>
                        <TextField
                            multiline={false}
                            minRows='4'
                            label={labels.refNumberLabel[changeLangRule]}
                            variant='outlined'
                            value={advanceSearchParams[constants.REFERENCE_NUM_PARAM] ?
                                advanceSearchParams[constants.REFERENCE_NUM_PARAM] : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(constants.REFERENCE_NUM_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            fullWidth={true}
                            error={this.existError(constants.REFERENCE_NUM_PARAM)}
                        />

                        {this.existError(constants.REFERENCE_NUM_PARAM) && errLengthMsg}

                    </div>
                    <div>
                        <SelectMaterialBuilder
                            value={advanceSearchParams[constants.DOCUMENT_TYPE_PARAM] ?
                                advanceSearchParams[constants.DOCUMENT_TYPE_PARAM] : ''}
                            label={labels.docTypeLabel[changeLangRule]}
                            paramName={constants.DOCUMENT_TYPE_PARAM}
                            handleSelectChange={this.handleChange}
                            options={documentTypeOptionsWithEmpty}
                        />
                    </div>
                </div>
                <div className='adv-search-options'>
                    <div>
                        <TextField
                            multiline={false}
                            minRows='4'
                            label={labels.toOrgLabel[changeLangRule]}
                            variant='outlined'
                            value={advanceSearchParams[constants.RECEIVING_ORGANIZATION_PARAM] ?
                                advanceSearchParams[constants.RECEIVING_ORGANIZATION_PARAM] : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(constants.RECEIVING_ORGANIZATION_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            fullWidth={true}
                            error={this.existError(constants.RECEIVING_ORGANIZATION_PARAM)}
                        />

                        {this.existError(constants.RECEIVING_ORGANIZATION_PARAM) && errLengthMsg}

                    </div>

                    <div>
                        <TextField
                            multiline={false}
                            minRows='4'
                            label={labels.fromOrgLabel[changeLangRule]}
                            variant='outlined'
                            value={advanceSearchParams[constants.SENDING_ORGANIZATION_PARAM] ?
                                advanceSearchParams[constants.SENDING_ORGANIZATION_PARAM] : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(constants.SENDING_ORGANIZATION_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            fullWidth={true}
                            error={this.existError(constants.SENDING_ORGANIZATION_PARAM)}
                        />

                        {this.existError(constants.SENDING_ORGANIZATION_PARAM) && errLengthMsg}

                    </div>

                </div>
                <div className='adv-search-options'>
                    <div>
                        <TextField
                            multiline={false}
                            minRows='4'
                            label={labels.toLabel[changeLangRule]}
                            variant='outlined'
                            value={advanceSearchParams[constants.RECEIVING_INDIVIDUAL_PARAM] ?
                                advanceSearchParams[constants.RECEIVING_INDIVIDUAL_PARAM] : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(constants.RECEIVING_INDIVIDUAL_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            fullWidth={true}
                            error={this.existError(constants.RECEIVING_INDIVIDUAL_PARAM)}
                        />

                        {this.existError(constants.RECEIVING_INDIVIDUAL_PARAM) && errLengthMsg}

                    </div>
                    <div>
                        <TextField
                            multiline={false}
                            minRows='4'
                            label={labels.fromLabel[changeLangRule]}
                            variant='outlined'
                            value={advanceSearchParams[constants.SENDING_INDIVIDUAL_PARAM] ?
                                advanceSearchParams[constants.SENDING_INDIVIDUAL_PARAM] : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(constants.SENDING_INDIVIDUAL_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            fullWidth={true}
                            error={this.existError(constants.SENDING_INDIVIDUAL_PARAM)}
                        />

                        {this.existError(constants.SENDING_INDIVIDUAL_PARAM) && errLengthMsg}

                    </div>
                </div>
                <div>
                    <TextField
                        multiline={false}
                        minRows='4'
                        label={labels.notesLabel[changeLangRule]}
                        variant='outlined'
                        value={advanceSearchParams[constants.NOTES_PARAM] ?
                            advanceSearchParams[constants.NOTES_PARAM] : ''}
                        className='search-input'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            this.handleChange(constants.NOTES_PARAM,
                                (e.target as HTMLInputElement).value)
                        }
                        fullWidth={true}
                        error={this.existError(constants.NOTES_PARAM)}
                    />

                    {this.existError(constants.NOTES_PARAM) && errLengthMsg}

                </div>
                <div>
                    <TextField
                        multiline={false}
                        minRows='4'
                        label={labels.subjectLabel[changeLangRule]}
                        variant='outlined'
                        value={advanceSearchParams[constants.SUBJECT_PARAM] ?
                            advanceSearchParams[constants.SUBJECT_PARAM] : ''}
                        className='search-input'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            this.handleChange(constants.SUBJECT_PARAM,
                                (e.target as HTMLInputElement).value)
                        }
                        fullWidth={true}
                        error={this.existError(constants.SUBJECT_PARAM)}
                    />

                    {this.existError(constants.SUBJECT_PARAM) && errLengthMsg}

                </div>
                <div>
                    <TextField
                        multiline={false}
                        minRows='4'
                        label={labels.field1Label[changeLangRule]}
                        variant='outlined'
                        value={advanceSearchParams[constants.FIELD1_PARAM] ?
                            advanceSearchParams[constants.FIELD1_PARAM] : ''}
                        className='search-input'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            this.handleChange(constants.FIELD1_PARAM,
                                (e.target as HTMLInputElement).value)
                        }
                        fullWidth={true}
                        error={this.existError(constants.FIELD1_PARAM)}
                    />

                    {this.existError(constants.FIELD1_PARAM) && errLengthMsg}

                </div>
                <div>
                    <FormLabel>{labels.docDateLabel[changeLangRule]}:</FormLabel>
                    <div className='adv-search-options date-block'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                defaultValue={advanceSearchParams[constants.DOC_DATE_FROM_PARAM] ?
                                    dayjs(advanceSearchParams[constants.DOC_DATE_FROM_PARAM]) : null}
                                label={labels.fromDateLabel[changeLangRule]}
                                onChange={(date: any): void => this.validateDateMethod(constants.DOC_DATE_FROM_PARAM, date)}
                                className='search-input'
                                format='MM/DD/YYYY'
                                slotProps={{
                                    textField: {
                                        helperText: (advanceSearchParams[constants.DOC_DATE_FROM_PARAM] != null && this.state.dateError) ? INVALID_FORMAT : '',
                                        InputProps: {
                                            style: { marginBottom: this.state.dateError ? '0px' : '10px' }
                                        },
                                    },
                                }}
                            />
                            <DatePicker
                                defaultValue={advanceSearchParams[constants.DOC_DATE_TO_PARAM] ?
                                    dayjs(advanceSearchParams[constants.DOC_DATE_TO_PARAM]) : null}
                                label={labels.toDateLabel[changeLangRule]}
                                onChange={(date: any): void => this.validateDateMethod(constants.DOC_DATE_TO_PARAM, date)}
                                className='search-input'
                                format='MM/DD/YYYY'
                                slotProps={{
                                    textField: {
                                        helperText: (advanceSearchParams[constants.DOC_DATE_TO_PARAM] != null && this.state.dateError) ? INVALID_FORMAT : '',
                                        InputProps: {
                                            style: { marginBottom: this.state.dateError ? '0px' : '10px' }
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className='adv-search-options'>
                    <div>
                        <TextField
                            multiline={false}
                            minRows='4'
                            label={labels.docNameLabel[changeLangRule]}
                            variant='outlined'
                            value={advanceSearchParams[constants.DOCUMENT_NAME_PARAM] ?
                                advanceSearchParams[constants.DOCUMENT_NAME_PARAM] : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(constants.DOCUMENT_NAME_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            fullWidth={true}
                            error={this.existError(constants.DOCUMENT_NAME_PARAM)}
                        />

                        {this.existError(constants.DOCUMENT_NAME_PARAM) && errLengthMsg}

                    </div>
                    <div>
                        <TextField
                            multiline={false}
                            minRows='4'
                            label={labels.filenameLabel[changeLangRule]}
                            variant='outlined'
                            value={advanceSearchParams[commonConstants.FILE_NAME_PARAM] ?
                                advanceSearchParams[commonConstants.FILE_NAME_PARAM] : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(commonConstants.FILE_NAME_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            fullWidth={true}
                            error={this.existError(commonConstants.FILE_NAME_PARAM)}
                        />

                        {this.existError(commonConstants.FILE_NAME_PARAM) && errLengthMsg}

                    </div>
                </div>

                <div className='adv-search-options'>
                    <div>
                        <TextField
                            multiline={false}
                            minRows='4'
                            type='number'
                            label={labels.paginationNumberLabel[changeLangRule]}
                            variant='outlined'
                            value={advanceSearchParams[constants.PAGINATION_NUM_PARAM] ?
                                advanceSearchParams[constants.PAGINATION_NUM_PARAM].toString() : ''}
                            className='search-input'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                this.handleChange(constants.PAGINATION_NUM_PARAM,
                                    (e.target as HTMLInputElement).value)
                            }
                            onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                                this.trimStartZero(e.target.value);
                            }}
                            fullWidth={true}
                            error={this.existError(constants.PAGINATION_NUM_PARAM)}
                        />

                        {this.existError(constants.PAGINATION_NUM_PARAM) && errLengthMsg}

                    </div>
                    <div>
                        <SelectMaterialBuilder
                            value={advanceSearchParams[constants.ACT] ?
                                advanceSearchParams[constants.ACT] : ''}
                            label={labels.actLabel[changeLangRule]}
                            paramName={constants.ACT}
                            handleSelectChange={(param: string, value: string): void => {
                                this.props.advanceSearchParams[constants.EXEMPTION_PARAMS] = [];
                                this.props.getExemptions(Number(value));
                                this.handleChange(param, value);
                            }}
                            options={actsOptions}
                            disabled={isCurrentRequest}
                        />
                    </div>
                </div>

                <div className='scrolling-block'>
                    <div className='adv-search-option-block'>
                        <FormLabel>{labels.exemExclusionLabel[changeLangRule]}:</FormLabel>
                        <div className='scrolling-box'>
                            <AccordionDetails
                                className='panel-del'
                            >
                                {this.props.articleStampsList.map(
                                    (exemption: IValidExemptionDtoList) => (
                                        <div
                                            key={exemption.exemptionCode}
                                            className='item'
                                        >
                                            <div className='checkBox-name'>
                                                <Checkbox
                                                    checked={this.isChecked(constants.EXEMPTION_PARAMS,
                                                        exemption.exemptionCode)}
                                                    onChange={(): void => {
                                                        this.handleCheckbox(constants.EXEMPTION_PARAMS,
                                                            exemption.exemptionCode);
                                                    }}
                                                    name={`${exemption.exemptionCode}`}
                                                    className={className.COLOR_CHECKBOX}
                                                />
                                                <div className='name'>
                                                    {exemption.exemptionLabel}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </AccordionDetails>
                        </div>
                    </div>
                    <div className='adv-search-option-block'>
                        <FormLabel>{labels.userDefinedStampLabel[changeLangRule]}:</FormLabel>
                        <div className='scrolling-box'>
                            {
                                stampsTypeList.map((stamp: IStampType) => (
                                    <AccordionDetails
                                        className='panel-del'
                                        key={stamp[ID]}
                                    >
                                        <CheckboxContainer
                                            type={constants.USER_STAMP_PARAMS}
                                            element={stamp[ID]}
                                            label={stamp[TEXT]}
                                            checked={this.isChecked(constants.USER_STAMP_PARAMS,
                                                stamp[ID])}
                                            handleCheckbox={this.handleCheckbox}
                                        />
                                    </AccordionDetails>
                                ))
                            }
                        </div>
                    </div>

                    <div className='adv-search-option-block'>
                        <FormLabel>{labels.annotationLabel[changeLangRule]}:</FormLabel>
                        <div className='scrolling-box'>
                            {
                                annotationTypesList.map((annotation: IAnnotation) => (
                                    <AccordionDetails
                                        className='panel-del'
                                        key={annotation[ID]}
                                    >
                                        <CheckboxContainer
                                            type={constants.ANNOTATION_PARAMS}
                                            element={annotation[ID]}
                                            label={redactionLanguage === 'en' ? annotation.name
                                                : annotation.name2 ? annotation.name2 : annotation.name }
                                            checked={this.isChecked(constants.ANNOTATION_PARAMS,
                                                annotation[ID])}
                                            handleCheckbox={this.handleCheckbox}
                                        />
                                    </AccordionDetails>
                                ))
                            }
                        </div>
                    </div>

                    <div className='adv-search-option-block'>
                        <FormLabel>{labels.disclosureLabel[changeLangRule]}:</FormLabel>
                        <div className='scrolling-box'>
                            {
                                this.props.disclosureTypesList.map((disclosure: IDisclosureOptions) => (
                                    <AccordionDetails
                                        className='panel-del'
                                        key={disclosure[ID]}
                                    >
                                        <CheckboxContainer
                                            type={constants.DISCLOSURE_PARAMS}
                                            element={disclosure[ID]}
                                            label={redactionLanguage === 'en' ? disclosure.name
                                            : disclosure.name1 ? disclosure.name1 : disclosure.name}
                                            checked={this.isChecked(constants.DISCLOSURE_PARAMS,
                                                disclosure[ID])}
                                            handleCheckbox={this.handleCheckbox}
                                        />
                                    </AccordionDetails>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='search-options-buttons'>
                    <Button
                        variant='contained'
                        onClick={(): void => {
                            this.setState({ exactMatch: false, mandatoryFields: false });
                            clearSearchParams();
                        }}
                        disabled={resultLoading}
                    >
                        {labels.clearLabel[changeLangRule]}
                    </Button>
                    <Button
                        variant='contained'
                        onClick={this.search}
                        disabled={resultLoading}
                    >
                        {labels.searchLabel[changeLangRule]}
                    </Button>
                    <Tooltip title={labels.goToPreviousLabel[changeLangRule]} placement='top'>
                        <IconButton
                            aria-label='Back'
                            onClick={(): void => this.navigateResultPage(false)}
                            disabled={
                                resultLoading || (advanceSearchData && advanceSearchData.length && currentDocLoading)
                            }
                        >
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={labels.goToNextLabel[changeLangRule]} placement='top'>
                        <IconButton
                            aria-label='Forward'
                            onClick={(): void => this.navigateResultPage(true)}
                            disabled={
                                resultLoading || (advanceSearchData && advanceSearchData.length && currentDocLoading)
                                }
                        >
                            <ArrowForward />
                        </IconButton>
                    </Tooltip>
                </div>
                {this.state.mandatoryFields ?
                    <span className='error-message'>{ANY_ONE_FIELD_REQUIRED}</span> : ''}
                <div className='adv-search-table-block'>
                    {this.props.resetedAdvanceSearchColumnLoading || this.props.advanceSearchColumnHeaderLoading ?
                        <Spinner active={true} /> :
                        <Table
                            data={paginatedData}
                            selectedIds={[currentPageId]}
                            allowSettings={true}
                            title={labels.resultsTitleLabel[changeLangRule]}
                            columns={this.props.advanceSearchTableColumnHeader}
                            showPagination={this.props.advanceSearchData.length > 100}
                            page={this.state.page}
                            onFetchData={(page: number): void => this.setState({page})}
                            pagesCount={this.props.advanceSearchData.length}
                            defaultPageSize={100}
                            dynamicColumns={this.props.advanceSearchTableColumnHeader}
                            changeColumns={this.props.setAdvanceSearchTableColumns}
                            getTrProps={(rowInfo: any): ITrProps => this.getTrProps(rowInfo)}
                            loading={resultLoading}
                            userPreferenceTable={true}
                            enableShowHideColumn={true}
                            isSearch={true}
                            modifyColumns={this.props.modifyAdvanceSearchColumnHeader}
                            userId={this.props.userMetaData.userId}
                            resetColumnHeader={this.props.getResetedAdvanceSearchColumnHeader}
                        />
                    }
                </div>

            </div>
        );
    }

    private handleChange = (param: string, value: string | number[] | number): void => {
        const { errorsList, advanceSearchParams: { [constants.SPECIFIC_REQUEST_PARAM]: specificRequest } } = this.props;

        if (typeof (value) === 'number'
            || typeof (value) === 'string' && value.length < 256
            || typeof (value) === 'object') {
            if (this.existError(param)) {
                const filteredErrorsList = errorsList.filter((error: string) => error !== param);

                this.props.updateAdvanceSearchErrors(filteredErrorsList);
            }
        } else {
            if (!this.existError(param)) {
                const newErrorList = [...errorsList, param];

                this.props.updateAdvanceSearchErrors(newErrorList);
            }
        }

        const parsedValue = FIELDS_NUMBER_TYPE.includes(param) ? Number(value) : value;

        if (SPECIFIC_REQUEST_FIELDS.includes(param)) {
            this.props.setAdvanceSearchParams({
                [constants.SPECIFIC_REQUEST_PARAM]: {
                    ...specificRequest,
                    [param]: parsedValue,
                },
            });
        } else {
            this.props.setAdvanceSearchParams({ [param]: parsedValue });
        }
    }

    private validateDateMethod = (param: string, value: Dayjs): void => {
        const { errorsList, advanceSearchParams: { [constants.SPECIFIC_REQUEST_PARAM]: specificRequest } } = this.props;
        let validDate: boolean;
        if (value) {
            validDate = value && value.isValid();
            if (!validDate) {
                const newErrorList = [...errorsList, param];
                this.props.updateAdvanceSearchErrors(newErrorList);
                this.setState({ dateError: true });
            }
            else {
                this.setState({ dateError: false});
            }
        }
        const parsedValue = FIELDS_NUMBER_TYPE.includes(param) ? Number(value) : value?.format();

        if (SPECIFIC_REQUEST_FIELDS.includes(param)) {
            this.props.setAdvanceSearchParams({
                [constants.SPECIFIC_REQUEST_PARAM]: {
                    ...specificRequest,
                    [param]: parsedValue,
                },
            });
        } else {
            this.props.setAdvanceSearchParams({ [param]: parsedValue });
        }
    }

    private trimStartZero = (value: string): void => {
        this.props.setAdvanceSearchParams({ [constants.PAGINATION_NUM_PARAM]: Number(value) });
    }

    private isChecked(type: string, id: number): boolean {
        const { advanceSearchParams } = this.props;

        return advanceSearchParams[type] ? advanceSearchParams[type].includes(id) : false;
    }

    private search = (): void => {
        const { postAdvanceSearch, redactionDocumentId, advanceSearchParams } = this.props;
        const advSearchParamsWithoutNullKeys = pickBy(advanceSearchParams, identity) as any;

        if (advSearchParamsWithoutNullKeys[constants.SEARCH_SCOPE_PARAM] !== constants.SPECIFIC_SCOPE_PARAM) {
            delete advSearchParamsWithoutNullKeys[constants.SPECIFIC_REQUEST_PARAM];
        } else {
            advSearchParamsWithoutNullKeys[constants.SPECIFIC_REQUEST_PARAM] = pickBy(
                advSearchParamsWithoutNullKeys[constants.SPECIFIC_REQUEST_PARAM],
                identity);
        }

        if (advSearchParamsWithoutNullKeys.searchScope === SPECIFIC_SCOPE_PARAM &&
            advSearchParamsWithoutNullKeys.specificRequest.requestNumber) {
            advSearchParamsWithoutNullKeys.specificRequest = {
                ...advSearchParamsWithoutNullKeys.specificRequest,
                [EXACT_MATCH_PARAM]: this.state.exactMatch,
            };
        }

        if (advSearchParamsWithoutNullKeys.searchScope === SPECIFIC_SCOPE_PARAM &&
            !(advanceSearchParams.specificRequest.requestNumber === null ?
                advanceSearchParams.specificRequest.requestNumber :
                advanceSearchParams.specificRequest.requestNumber.trim()) &&
            !advanceSearchParams.specificRequest.folderRSN &&
            !advanceSearchParams.specificRequest.requestType
        ) {
            this.setState({ mandatoryFields: true });
        } else {
            postAdvanceSearch(redactionDocumentId, advSearchParamsWithoutNullKeys);
        }

        this.props.clearAdvanceSearchResult();
    }

    private handleCheckbox = (type: string, id: number): void => {
        let checkedIds = this.props.advanceSearchParams[type];

        if (checkedIds) {
            const checked = checkedIds.includes(id);

            if (checked) {
                checkedIds = checkedIds.filter((checkedId: number) => checkedId !== id);
            } else {
                checkedIds = [...checkedIds, id];
            }
        } else {
            checkedIds = [id];
        }

        this.handleChange(type, checkedIds);
    }

    private existError = (field: string): boolean => {
        return (this.props.errorsList.includes(field));
    }

    private getTrProps = (rowInfo: Row): ITrProps => {
        this.handleRowClick(rowInfo);
        return {
            id: parseInt(rowInfo.id, 10),
            onClick: (): void => this.handleRowClick(rowInfo),
        };
    }

    private handleRowClick = (rowInfo: any): void => {
        const {
            advanceSearchData,
        } = this.props;
        const { original: { id, documentName, page: actualPageNumber, documentId } } = rowInfo;
        const pickedDocIndex = advanceSearchData.findIndex((searchedData: IAdvanceSearchTableData) =>
            searchedData.id === id);
        const selectedDoc = this.getCurrentDocId(pickedDocIndex);

        this.checkSelectedDocAvailability({
            currentSelectedDoc: documentId,
            pageId: id,
            actualPageNumber,
            nextResultIndex: pickedDocIndex,
            selectedDoc,
            docName: documentName,
        });

    }

    private getSearchedPages = (selectedDoc: number, id: number, actualPageNumber: number):
        void => {
        const {
            getPageResources,
            redactionDocumentId,
            fetchPageNumberByPageId,
            currentDocument,
            fetchSearchDataFromNextDoc,
            restrictOnFetchData,
            setRestrictOnFetchDataPageList,
        } = this.props;

        const isPageInCurrentPagination = currentDocument && currentDocument.pages &&
            currentDocument.pages.find((page: IPages) => page.id === id);

        const isCurrentDocument = currentDocument && currentDocument.id === selectedDoc;

        if (isCurrentDocument) {
            if (isPageInCurrentPagination) {
                getPageResources(redactionDocumentId, currentDocument.id, id, isPageInCurrentPagination.rotation,
                    isPageInCurrentPagination.layers[0].id);

            } else {
                fetchPageNumberByPageId(redactionDocumentId, selectedDoc, actualPageNumber, true);
            }
        } else {
            fetchSearchDataFromNextDoc(redactionDocumentId, selectedDoc, actualPageNumber);
        }

        if(!restrictOnFetchData) {
            setRestrictOnFetchDataPageList(true);
        }

    }

    private checkSelectedDocAvailability = (docDetails: ICurrentIds): void => {
        const {
            advanceSearchData,
            redactionDocumentId,
            setCurrentPageIdParam,
            onSetPageToSelectedList,
            onSetDocumentToSelectedList,
            setStackId,
            fetchDocumentsListValue,
            redactionLanguage,
        } = this.props;
        const { selectedDoc, pageId, nextResultIndex, actualPageNumber } = docDetails;
        const langParam = redactionLanguage && `&lang=${redactionLanguage}` || '';

        if (selectedDoc.inDifferentReq) {
            this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: ADVANCED_SEARCH_RESULTS_LABEL,
                okButtonTitle: YES,
                cancelButtonTitle: NO,
                message: OPEN_RESULT_IN_NEW_TAB,
                confirm: (): () => void => (): void => {
                    const selectedPage = advanceSearchData[nextResultIndex];

                    window.open(`/${b64EncodeUnicode(selectedPage.folderRSN)}?redactionDocument=${b64EncodeUnicode(selectedPage.redactionDocumentId)}&document=${b64EncodeUnicode(selectedPage.documentId)}&page=${b64EncodeUnicode(selectedPage.page)}&from=${b64EncodeUnicode('advanceSearch')}${langParam}`, '_blank');
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    return;
                },
                reject: (): () => void => (): void => {
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    return;
                },
        });

            return;

        }

        if(selectedDoc.inDifferentStack) {
            this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: NO_MATCHING_RESULTS,
                okButtonTitle: YES,
                cancelButtonTitle: NO,
                message: SELECTED_DOC_NOT_IN_CURRENT_STACK,
                confirm: (): () => void => (): void => {
                    fetchDocumentsListValue(redactionDocumentId);
                    setStackId(0);
                    this.getSearchedPages(selectedDoc.id, pageId, actualPageNumber);
                    setCurrentPageIdParam(pageId);
                    onSetPageToSelectedList(pageId);
                    onSetDocumentToSelectedList(selectedDoc.id);
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    return;
                },
                reject: (): () => void => (): void => {
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    return;
                },

            });

            return;
        }

        this.getSearchedPages(selectedDoc.id, pageId, actualPageNumber);
        setCurrentPageIdParam(pageId);
        onSetPageToSelectedList(pageId);
        onSetDocumentToSelectedList(selectedDoc.id);
    }

    private navigateResultPage = (forward: boolean): void => {
        const {
            advanceSearchData,
        } = this.props;

        if (advanceSearchData.length) {

                this.checkSelectedDocAvailability(this.getCurrentIds(forward));

        }
    }

    private getCurrentIds = (forward?: boolean): ICurrentIds => {
        const { advanceSearchData, currentPageId } = this.props;
        const isCurrentSelectedPageInSearchResult = currentPageId &&
            Boolean(advanceSearchData.filter((result: IAdvanceSearchTableData) => currentPageId === result.id )[0]);

        if(!currentPageId || !isCurrentSelectedPageInSearchResult) {

            return forward ? {
                selectedDoc: this.getCurrentDocId(0),
                pageId: advanceSearchData[0].id,
                nextResultIndex: 0,
                actualPageNumber: advanceSearchData[0].page,
            } : {
                selectedDoc: this.getCurrentDocId(advanceSearchData.length - 1),
                pageId: advanceSearchData[advanceSearchData.length - 1].id,
                nextResultIndex: advanceSearchData.length - 1,
                actualPageNumber: advanceSearchData[advanceSearchData.length - 1].page,

            };
        }

        const indexOfCurrentPage = findIndex(
            advanceSearchData,
            (item: IAdvanceSearchTableData): boolean => item.id === currentPageId,
        );

        if (advanceSearchData[indexOfCurrentPage]) {
            const pageIndex = this.getPageIndex(indexOfCurrentPage, forward);

            const pageId = advanceSearchData[pageIndex].id;

            return {
                selectedDoc: this.getCurrentDocId(pageIndex),
                pageId,
                nextResultIndex: pageIndex,
                actualPageNumber: advanceSearchData[pageIndex].page,
            };
        }
    }

    private getCurrentDocId = (pageIndex: number): ICurrentSelectedDoc => {
        const { advanceSearchData, documentList, currentReqFolderRsn } = this.props;

        if(advanceSearchData[pageIndex].folderRSN !== currentReqFolderRsn) {
            return {
                id: null,
                inDifferentReq: true,
                inDifferentStack: false,
            };
        }

        const nextSelectedDoc = documentList.filter((doc: IFile) => {
            return doc.name === advanceSearchData[pageIndex].documentName;
        })[0];

        return nextSelectedDoc ? {
            id: nextSelectedDoc.id,
            inDifferentReq: false,
            inDifferentStack: false,
        } : {
            id: advanceSearchData[pageIndex].documentId,
            inDifferentReq: false,
            inDifferentStack: true,
        };
    }

    private getPageIndex = (pageIndex: number, forward: boolean): number => {
        const { advanceSearchData } = this.props;

        const currentPageIndex = forward ? pageIndex + 1 : pageIndex - 1;

        if (currentPageIndex > advanceSearchData.length - 1) {
            return 0;
        } else if (currentPageIndex < 0) {
            return advanceSearchData.length - 1;
        }

        return currentPageIndex;
    }

}

const mapStateToProps = (state: StoreState): IStoreAdvanceSearch => ({
    redactionDocumentId: getRedactionDocumentId(state),
    advanceSearchParams: getAdvanceSearchParams(state),
    articleStampsList: getExemptionsList(state),
    disclosureTypesList: getCurrentDisclosureTypes(state),
    annotationTypesList: getAnnotationTypesList(state),
    stampsTypeList: getStampsList(state),
    languageOptions: getLanguageMetaData(state),
    classificationOptions: getClassificationMetaData(state),
    documentTypeOptions: getDocumentTypeMetaData(state),
    requestTypesListData: getFoiFoldersOptions(state),
    errorsList: getAdvanceSearchErrors(state),
    advanceSearchData: getAdvanceSearchData(state),
    documentList: getDocumentList(state),
    currentPageId: getCurrentPageId(state),
    currentActCode: getActCode(state),
    acts: getActList(state),
    resultLoading: getAdvanceSearchResultLoading(state),
    advanceSearchTableColumnHeader: getAdvanceSearchTableColumnHeader(state),
    userMetaData: getUserMetaData(state),
    resetedAdvanceSearchColumnLoading: getResetedAdvanceSearchColumnHeaderLoading(state),
    currentDocument: getCurrentDocument(state),
    currentReqFolderRsn: getRedactionFolderRsn(state),
    currentDocLoading: getPageCurrentDocLoading(state),
    restrictOnFetchData: getRestrictFlagForPageListFetchData(state),
    redactionLanguage: getRedactionLanguage(state),
    redactionMode:getRedactionMode(state),
    modifiedLabels: getModifiedLabels(state),
    advanceSearchColumnHeaderLoading: getAdvanceSearchColumnHeaderLoading(state),
    auth: getAuthFlag(state),
    sortParams: getSortParams(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IState, IActionAdvanceSearch, AnyAction>): IActionAdvanceSearch => (
    {
        setAdvanceSearchParams: (param: IAdvanceSearchParamChange): void => {
            dispatch(setAdvanceSearchParams(param));
        },
        postAdvanceSearch: (redactionDocId: number, searchValue: IAdvanceSearchParams): void => {
            dispatch(postAdvanceSearchValue(redactionDocId, searchValue));
        },
        setTableSort: (param: PageData) => dispatch(setTableSort(param)),
        fetchStamps: (): void => {
            dispatch(fetchStampList());
        },
        clearSearchParams: (): void => {
            dispatch(clearAdvanceSearchParams());
        },
        getRequestSearchFolders: (): void => {
            dispatch(getRequestSearchFolders());
        },
        updateAdvanceSearchErrors: (param: string[]): void => {
            dispatch(updateAdvanceSearchErrors(param));
        },
        fetchDocumentMeta: (redactionDocumentId: number, id: number, pageId: number,
                            searchUpdate?: boolean, fromSearch?: boolean): void => {
            dispatch(fetchDocumentMetadata(redactionDocumentId, id, pageId, searchUpdate, fromSearch));
        },
        setCurrentPageIdParam: (id: number): void => {
            dispatch(setSearchCurrentPageId(id));
        },
        onSetPageToSelectedList: (id: number, isMultiple: boolean = false): void => {
            dispatch(setPageToSelectedList(id, isMultiple));
        },
        onSetDocumentToSelectedList: (id: number, isMultiple: boolean = false): void => {
            dispatch(setDocumentToSelectedList(id, isMultiple));
        },
        getAct: (actId: number): void => {
            dispatch(getAct(actId));
        },
        getAllAct: (): void => {
            dispatch(getAllAct());
        },
        getExemptions: (actId: number): void => {
            dispatch(getExemptions(actId));
        },
        cleanExemption: (): void => {
            dispatch(setExemptionsList([]));
        },
        clearAdvanceSearchResult: (): void => {
            dispatch(clearAdvanceSearchResult());
        },
        setAdvanceSearchTableColumns: (columns: IHeader[]): void => {
            dispatch(setAdvanceSearchTableColumns(columns));
        },
        modifyAdvanceSearchColumnHeader: (columns: IHeader[], userId: string): void => {
            dispatch(modifyAdvanceSearchColumnHeader(columns, userId));
        },
        getResetedAdvanceSearchColumnHeader: (userId: string): void => {
            dispatch(getResetedAdvanceSearchColumnHeader(userId));
        },
        fetchFoiFolderTypes: (): void => {
            dispatch(fetchFoiFolderTypes());
        },
        fetchPageNumberByPageId: (redactionDocumentId: number, documentId: number,
                                  selectedPageId: number, getAnnotationStamps: boolean = false): void => {
            dispatch(getPageNumberByPageId({ redactionDocumentId, documentId, selectedPageId, getAnnotationStamps }));
        },
        getPageResources: (redactionDocumentId: number, id: number, pageId: number,
                           rotation: number, layerId: number): void => {
            dispatch(getPageResource(redactionDocumentId, id, pageId, rotation, layerId));
        },
        fetchSearchDataFromNextDoc: (redactionDocumentId: number, selectedDoc: number, actualPageNumber: number):
            void => { dispatch(getSearchDataFromNextDoc(redactionDocumentId, selectedDoc, actualPageNumber));
        },
        handleOpenModalWindow: (modalType: string, modalProps: IModalProps): void => {
            dispatch(openModalWindow(modalType, modalProps));
        },
        setStackId: (id: number): void => {
            dispatch(saveStackId(id));
        },
        fetchDocumentsListValue: (redactionDocumentId: number): void => {
            dispatch(fetchDocumentList(redactionDocumentId));
        },
        setRestrictOnFetchDataPageList: (restricFlag: boolean): void => {
            dispatch(restrictOnFetchDataPageList(restricFlag));
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);

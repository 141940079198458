import * as React from 'react';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { sortBy, intersectionBy } from 'lodash';
import { connect } from 'react-redux';
import { Scrollbars } from 'rc-scrollbars';
import { IState } from '../../redux/store';
import { SELECT_CONSTANT_MODAL } from '../modalWindowContents';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction, Dispatch } from 'redux';
import Table from '../../components/table/Table';
import { getSelectedPagesNames, getCurrentDocument } from '../../redux/selectors/pageList';
import { openModalWindow } from '../../redux/actions/modal';
import { getRedactionDocumentId } from '../../redux/selectors/initialization';
import {
    CONFIRMATION_DIALOG_MODAL,
    DELETE_CONTACT_MESSAGE,
    DELETE_CONTACT_TITLE,
} from '../../constants/messages.constants';
import { ConsalteesResult, REMOVE } from '../../constants/selectContacts.contstants';
import {
    IProps,
    IStateProps,
    IPropsActions,
    IConsulteeTable,
    IConsulteesPagesWithPage,
} from './indexMetadataConsultees.model';
import { IPageMetadataInfo, IPages } from '../../redux/reducers/pageList/pageList.model';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { getConsultees } from '../../redux/selectors/pageMetadata';
import './indexMetadataConsultees.styles.scss';
import { IConsultees, IConsulteesPages } from '../../redux/reducers/pageMetadata/notes.model';
import { deletePageConsultee } from '../../redux/actions/pageMetadata';
import { getCurrentDisclosureTypes } from '../../redux/selectors/disclosureTypes';
import { EDIT_LABEL } from '../../constants/common.constants';
import { IDisclosureOptions } from '../../redux/reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { getModifiedLabels } from '../../redux/selectors/localization';
import { getRedactionLanguage } from '../../redux/selectors/localStorage';
import { initialLabel, langFrench } from '../../constants/localization.constants';
import resourceBundle from '../localization/localizationData';
import { changeLang } from '../../redux/actions/localization';
import { PageData } from '../../redux/reducers/common/common.model';

const DEFAULT_ROW_HEIGHT = 50;
const EMPTY_TABLE_ROWS = 3;
const DEFAULT_ROW_HEIGHT_WITH_PADDING = 70;
const MAX_TABLE_HEIGHT = 300;
const EDIT_BUTTON_HEIGHT = 50;

export const getConsulteesLabelsByKey = (key: string): string => {
    switch(key) {
        case 'COMMON_LABEL_FIRST_NAME':
            return 'firstNameHeaderLabel';
        case 'COMMON_LABEL_LAST_NAME':
            return 'lastNameHeaderLabel';
        case 'MODAL_CONSULTEES_PEOPLE_TYPE_HEADER':
            return 'peopleTypeHeaderLabel';
        case 'COMMON_LABEL_ACTION':
            return 'actionHeaderLabel';
        case 'COMMON_LABEL_REMOVE':
            return 'removeLabel';
        case 'MODAL_CONSULTEES_REMOVE_CONTACTS':
            return 'removeContactsTitleLabel';
        case 'COMMON_LABEL_PRESS_OK_TO_CONTINUE':
            return 'pressOkContinueLabel';
        case 'MODAL_CONSULTEES_TITLE':
            return 'consultessModalTitleLabel';
        case 'COMMON_LABEL_EDIT':
            return 'editLabel';
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'COMMON_LABEL_SAVE':
            return 'saveLabel';
        case 'MODAL_CONSULTEES_ASSIGN':
            return 'assignLabel';
        case 'MODAL_CONSULTEES_SEARCH_RESULTS_TITLE':
            return 'searchResultsTitleLabel';
        case 'MODAL_CONSULTEES_ORGANISATION_NAME_HEADER':
            return 'organisationNameHeaderLabel';
        case 'MODAL_CONSULTEES_PEOPLE_RSN_HEADER':
            return 'pesopleRSNHeaderLabel';
        case 'COMMON_LABEL_RESET':
            return 'resetLabel';
        case 'COMMON_LABEL_SEARCH':
            return 'searchLabel';
        case 'COMMON_LABEL_REMOVE_ALL':
            return 'removeAllLabel';
        case 'MODAL_CONSULTEES_ASSIGNED_CONTACTS_TITLE':
            return 'assignedContactsTitleLabel';
        case 'MODAL_CONSULTEES_NO_PERSONS_ADDED_MESSAGE':
            return 'noPersonaddedLabel';
        case 'COMMON_LABEL_LOADING':
            return 'loadingLabel';
        case 'COMMON_LABEL_NO_RESULTS':
            return 'emptyContactsLabel';
        case 'COMMON_LABEL_WARNING':
            return 'warningLabel';
        case 'COMMON_LABEL_CHANGE_DISCLOSURE_WARNING':
            return 'changeDisclosureWarning';
        case 'COMMON_LABEL_PAGINATION_RECOMMENDED':
            return 'paginationLabel';
        case 'COMMON_LABEL_PAGINATION_RECOMMENDED_MESSAGE':
            return 'paginationMessageLabel';
        case 'COMMON_LABEL_SEVERAL_PAGES_SELECTED_WARNING':
            return 'severalPagesSelectedWarning';
        case 'COMMON_LABEL_SORTING_NOT_APPLICABLE_ON_MOVE_WARNING':
            return 'sortingNotApplicableOnMoveLabel';
        case 'COMMON_LABEL_DISCLOSURE_WARNING':
            return 'disclosureWarningLabel';
        case 'COMMON_LABEL_AUTO_DESKEW_PAGE_MESSAGE':
            return 'autoDeskewPageLabel';
        case 'COMMON_LABEL_AUTO_ALIGN_ARTICLE_STAMPS_PAGE_ALERT':
            return 'autoAlignArticleStampsLabel';
        case 'COMMON_LABEL_DELETE_COMMENT_TITLE':
            return 'deleteCommentTitleLabel';
        case 'COMMON_LABEL_DELETE_COMMENT_MESSAGE':
            return 'deleteCommentMessageLabel';
        default: return '';
    }
};

const createTable = (data: IConsulteeTable[], labels: any, langRule: string, handleSortTable : any, sortParams: any): JSX.Element => (
    <Table
        columns={ConsalteesResult(labels,langRule)}
        userPreferenceTable={true}
        data={data}
        allowSettings={false}
        handleSortSession = {handleSortTable}
        sortParams={sortParams ? sortParams : null}
    />
);

const IndexMetadataConsultees = ({
    selectedPagesNames,
    openModalWindowAction,
    redactionDocumentId,
    currentDocument,
    consuteesPages,
    disclosureTypes,
    editPermission,
    modifiedLabels,
    redactionLanguage,
 }: IProps): JSX.Element => {
    const selectedPages = intersectionBy(consuteesPages, selectedPagesNames, 'id');
    const labels = {
        firstNameHeaderLabel: initialLabel,
        lastNameHeaderLabel: initialLabel,
        peopleTypeHeaderLabel: initialLabel,
        actionHeaderLabel: initialLabel,
        removeLabel: initialLabel,
        removeContactsTitleLabel: initialLabel,
        pressOkContinueLabel: initialLabel,
        consultessModalTitleLabel: initialLabel,
        editLabel: initialLabel,
    };
    const [sortParams, setSortParams] = React.useState<PageData>();

    const langRule = changeLang(redactionLanguage);

    resourceBundle.map((resource: any) => {
        if(getConsulteesLabelsByKey(resource.resourceKey)) {
            labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if(getConsulteesLabelsByKey(resource.resourceKey)) {
            labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    const selectedPagesWithName = selectedPages.map((item: IConsulteesPages) => {
        return {
            ...item,
            page: selectedPagesNames.find((page: IPageMetadataInfo) => page && (page.id === item.id)).page || '',
            isEditable: currentDocument.pages
                .find((page: IPages) => page && (page.id === item.id)).isConsulteesEditable,
        };
    });

    const handleSortTable = (param: string, val: string , pageName : string): void => {
        const pageData: PageData = {
            pageName: pageName,
            columnName: param,
            sortOrder: val,
        
        };
        setSortParams(pageData);
    }
    
    const consuteesPagesSorted = sortBy(selectedPagesWithName, ({page}: IConsulteesPagesWithPage) => page);

    const formatContactsList = (data: IConsultees[], pageId: number): IConsulteeTable[] =>
        data.map((item: IConsultees): IConsulteeTable => ({
            nameFirst: item.nameFirst,
            nameLast: item.nameLast,
            peopleDesc: redactionLanguage ? redactionLanguage === langFrench ? item.peopleDesc2 ? item.peopleDesc2
                : item.peopleDesc : item.peopleDesc : item.peopleDesc,
            id: item.peopleRsn,
            action: (
                <Button
                    color='primary'
                    onClick={(): void =>  onDelete(item.peopleRsn, pageId)}
                    size='small'
                    disabled={!editPermission}
                >
                    {labels.removeLabel[langRule]}
                </Button>
            ),
        }));

    const onDelete = (id: number, pageId: number): void => {
        openModalWindowAction(CONFIRMATION_DIALOG_MODAL, {
            id: DELETE_CONTACT_TITLE,
            title: labels.removeContactsTitleLabel[langRule],
            message: `${labels.removeContactsTitleLabel[langRule]} ? ${labels.pressOkContinueLabel[langRule]}`,
            confirm: (): (dispatch: Dispatch, getState: () => IState) => void =>
                deletePageConsultee(redactionDocumentId, currentDocument.id, pageId, id),
        });
    };

    const getDisclosureIdForSingleRow = (): number => {
        const disclosure = disclosureTypes
            .find((item: IDisclosureOptions) => item.name === selectedPagesNames[0].disclosureType);

        return disclosure && disclosure.id;
    };

    const onEdit = (disclosureTypeId: number, pageIds: number[]): void => {
        openModalWindowAction(SELECT_CONSTANT_MODAL, {
            id: SELECT_CONSTANT_MODAL,
            title: labels.consultessModalTitleLabel[langRule],
            modalSpecificProps: {
                redactionDocumentId,
                documentId: [currentDocument.id],
                disclosureTypeId,
                pageIds,
            },
        });
    };

    const getTableHeight = (countOfRows: number): number => {
        const tableHeight = countOfRows * DEFAULT_ROW_HEIGHT + DEFAULT_ROW_HEIGHT_WITH_PADDING;

        return tableHeight > MAX_TABLE_HEIGHT ? MAX_TABLE_HEIGHT : tableHeight;
    };

    const consulteeClass = classnames('сonsultees-tab-content content-wrapper', {
        'no-scroll': consuteesPagesSorted.length === 1,
    });

    return (
        <div className='сonsultees-tab tab-content'>
            <div className={consulteeClass}>
                {
                    consuteesPagesSorted.length === 1 ?
                        createTable(formatContactsList(consuteesPagesSorted[0].consultees, consuteesPagesSorted[0].id),
                            labels, langRule, handleSortTable, sortParams):
                        (
                            <Scrollbars>
                                {
                                    consuteesPagesSorted.map((page: IConsulteesPagesWithPage): JSX.Element => {
                                        const tableHeight = page ?
                                            getTableHeight(page.consultees.length) :
                                            DEFAULT_ROW_HEIGHT * EMPTY_TABLE_ROWS;
                                        const tableData = page ? formatContactsList(page.consultees, page.id) : [];

                                        return (
                                            <Accordion key={page.id}>
                                                <AccordionSummary>
                                                    <Typography>{`Page ${page.page}`}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ minHeight: '150px'}}>
                                                    <div
                                                        className='expansion-details'
                                                        style={{ height: `${tableHeight + EDIT_BUTTON_HEIGHT}px`}}
                                                    >
                                                        {
                                                            createTable(tableData, labels, langRule, handleSortTable, sortParams)
                                                        }
                                                        <Button
                                                            variant='contained'
                                                            size='small'
                                                            disabled={
                                                                !page.consultees.length ||
                                                                !editPermission ||
                                                                !page.isEditable
                                                            }
                                                            color='primary'
                                                            className='primary bottom-bar_buttons сonsultees-tab_button'
                                                            onClick={(): void => {
                                                                const currentPage = selectedPagesNames
                                                                    .find((item: IPageMetadataInfo) =>
                                                                        item.id === page.id);
                                                                const disclosure = disclosureTypes
                                                                    .find((item: IDisclosureOptions) =>
                                                                        item.name === currentPage.disclosureType);

                                                                onEdit(disclosure.id, [page.id]);
                                                            }}
                                                        >
                                                            {labels.editLabel[langRule]}
                                                        </Button>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })
                                }
                            </Scrollbars>
                        )
                }
            </div>
                <div className='сonsultees-tab-action'>
                    {
                        consuteesPagesSorted.length === 1 &&
                        !!consuteesPagesSorted[0].consultees.length &&
                        consuteesPagesSorted[0].isEditable &&
                        <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            className='primary bottom-bar_buttons'
                            onClick={(): void => onEdit(
                                getDisclosureIdForSingleRow(),
                                consuteesPagesSorted.map((page: IConsulteesPagesWithPage) => page.id))
                            }
                            disabled={!editPermission}
                        >
                            {labels.editLabel[langRule]}
                        </Button>
                    }
                </div>

        </div>
    );
};

const mapStateToProps = (state: IState): IStateProps => ({
    selectedPagesNames: getSelectedPagesNames(state),
    redactionDocumentId: getRedactionDocumentId(state),
    currentDocument: getCurrentDocument(state),
    consuteesPages: getConsultees(state),
    disclosureTypes: getCurrentDisclosureTypes(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLanguage: getRedactionLanguage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IState, IProps, AnyAction>): IPropsActions => ({
    openModalWindowAction: (modalType: string, modalProps: IModalProps): void => {
        dispatch(openModalWindow(modalType, modalProps));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexMetadataConsultees);
import * as React from 'react';
import { ISelectComponent, IItem } from './select.model';
import { Button, ClickAwayListener } from '@mui/material';
import { useState } from 'react';
import './select.styles.scss';
import { getSessionItem } from '../../utils/localStorage.util';
import { REDACTION_LANG } from '../../redux/reducers/localStorage/constant';

export const SelectComponent = ({
    items,
    disabledCondition,
    handleItemChange,
    icon,
}: ISelectComponent): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const redactionLang = getSessionItem(REDACTION_LANG);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (item: IItem) => {
        setSelectedItem(item);
        handleItemChange(item.id);
        setIsOpen(false);
    };

    const handleClickAway = () => {
        setIsOpen(false);
    };

    return (
        <div className="select-wrapper">
            <Button
                {...(disabledCondition && {disabled: true})}
                className='style-icon button-icon'
                onClick={toggleDropdown}
                role='button'
                size='small'
                aria-haspopup='true'
            >
                {icon}
            </Button>
            {isOpen && (
                <div className="dropdown-menu-container" style={{ position: 'fixed' }}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <ul className="dropdown-menu">
                            {items.map((item: IItem) => (
                                <li
                                    key={item.id}
                                    className="dropdown-item"
                                    onClick={() => handleItemClick(item)}
                                >
                                    {redactionLang === 'fr'
                                        ? (item.name2 ? item.name2 : (item.name ? item.name : item.text))
                                        : (item.name ? item.name : item.text)}
                                </li>
                            ))}
                        </ul>
                    </ClickAwayListener>
                </div>
            )}
        </div>
    );
};

import * as React from 'react';
import { useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'rc-scrollbars';
import Fab from '@mui/material/Fab';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RowWithCheckbox } from './RowWithCheckBox';
import { isExistValidUser, isExistRowLevelSecurity } from '../../../utils/requestSecurity.utils';
import { getFilteredArrayByObjectProp } from '../../../utils/array.utils';
import {
    ISelectedValidUserDto,
    IValidUser,
    IValidRowLevelSecurity,
} from '../../../redux/reducers/amandaContent/amandaContent.model';
import { ROW_LEVEL_SECURITY_CODE, USER_ID } from './ModalRequestSecurityConstants';
import './modalRequestSecurity.style.scss';
import { differenceBy, uniqBy, unionBy } from 'lodash';
import Typography from '@mui/material/Typography';
import { ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import { IValidRowLevelSecurityWithPermission, IValidUserWithPermission } from './ModalRequestSecurity.model';
import { DEFAULT_PERMISSION, permissionValues } from '../../../constants/permissions';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';
import { initialLabel } from '../../../constants/localization.constants';
import { useSelector } from 'react-redux';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';

const getSecurityLabelsByKey = (key: string): string => {
    switch (key) {
        case 'REQUESTSECURITY_MODAL_NAME':
            return 'nameLabel';     
        case 'REQUESTSECURITY_PERMISSIONS':
            return 'permissionLabel';            
        case 'REQUESTSECURITY_FULL_ACCESS':
            return 'fullAccessLabel'; 
        case  'REQUESTSECURITY_READ_ONLY_ALL': 
            return 'readOnlyAllLabel';
        case 'REQUESTSECURITY_DENY_ACCESS':
            return 'denyAccessLabel'; 
        case  'REQUESTSECURITY_READ_ONLY_RELEASABLE': 
            return 'readOnlyReleasableLabel';  
        default:  return '';
    }
}
const mapValidUserDto = (validUserDto: any): IValidUserWithPermission => {
    return {
        permission: DEFAULT_PERMISSION,
        validUser: validUserDto,
    };
};

const mapRowLevelSecurityDtos = (rowLevelSecurityDto: any): IValidRowLevelSecurityWithPermission => {
    return {
        permission: DEFAULT_PERMISSION,
        validRowLevelSecurity: rowLevelSecurityDto,
    };
};

export const SecuritiesList = (
    {
        securities,
        securityGroupsByFolder,
        setRequestSecurities,
        setRequestSecurityGroupsByFolder,
        selectedAvailableSecurities,
        setSelectedAvailableSecurities,
        requestSecurities,
    }: any,
): JSX.Element => {
    const [toMoveRowLevelSecurities, setToMoveRowLevelSecurities] = useState([]);
    const [toMoveValidUsers, setToMoveValidUsers] = useState([]);
    const rowLevelSecurities = securities['selectedRowLevelSecurityDtos'] ||
        securityGroupsByFolder['selectedRowLevelSecurityDtos'];
    const validUsers = securities['selectedValidUserDtos'] ||
        securityGroupsByFolder['selectedValidUserDtos'];

    const addSecurities = (): void => {
        const { selectedRowLevelSecurityDtos, selectedValidUserDtos } = selectedAvailableSecurities;
        const selectedValidUserDtosWithPermission = selectedValidUserDtos.map(mapValidUserDto);
        const selectedRowLevelSecurityDtosWithPermission = selectedRowLevelSecurityDtos.map(mapRowLevelSecurityDtos);

        setRequestSecurities({
            ...requestSecurities,
            selectedRowLevelSecurityDtos: uniqBy([...requestSecurities.selectedRowLevelSecurityDtos,
            ...selectedRowLevelSecurityDtosWithPermission], 'validRowLevelSecurity.rowLevelSecurityCode'),
            selectedValidUserDtos: uniqBy([...requestSecurities.selectedValidUserDtos,
                ...selectedValidUserDtosWithPermission], 'validUser.userId'),
        });
    };
    const removeSecurities = (): void => {
        const requestUsers = requestSecurities.selectedValidUserDtos
            .map((item: IValidUserWithPermission): ISelectedValidUserDto => item.validUser);

        if (toMoveValidUsers.length && requestUsers.length) {
            const differenceSelectedUsers = differenceBy(requestUsers, toMoveValidUsers, USER_ID);

            setToMoveValidUsers([]);
            setRequestSecurities({
                ...requestSecurities,
                selectedValidUserDtos: differenceSelectedUsers.map(mapValidUserDto),
            });
            setSelectedAvailableSecurities({
                ...selectedAvailableSecurities,
                selectedValidUserDtos: differenceSelectedUsers,
            });

            return;
        }

        const requestRowLevels = requestSecurities
            .selectedValidUserDtos
            .map((item: IValidRowLevelSecurityWithPermission): IValidRowLevelSecurity =>
                item.validRowLevelSecurity);

        if (toMoveRowLevelSecurities.length && requestRowLevels.length) {
             const differenceunionSelectedRows =
                 differenceBy(requestRowLevels, toMoveRowLevelSecurities, ROW_LEVEL_SECURITY_CODE);

             setToMoveRowLevelSecurities([]);
             setRequestSecurities({
                 ...requestSecurities,
                 selectedRowLevelSecurityDtos: differenceunionSelectedRows.map(mapRowLevelSecurityDtos),
             });
             setSelectedAvailableSecurities({
                 ...selectedAvailableSecurities,
                 selectedRowLevelSecurityDtos: differenceunionSelectedRows,
             });

             return;
        }

        if (toMoveRowLevelSecurities.length) {
            const selectedRows = requestSecurities.selectedRowLevelSecurityDtos
                .map((item: IValidRowLevelSecurityWithPermission) => item.validRowLevelSecurity);
            const differenceSelectedRows =
                differenceBy(selectedRows, toMoveRowLevelSecurities, ROW_LEVEL_SECURITY_CODE);

            setToMoveRowLevelSecurities([]);
            setRequestSecurities({
                ...requestSecurities,
                selectedRowLevelSecurityDtos: differenceSelectedRows.map(mapRowLevelSecurityDtos),
            });
            setSelectedAvailableSecurities({
                ...selectedAvailableSecurities,
                selectedRowLevelSecurityDtos: differenceSelectedRows,
            });

            return;
        }
    };

    const handleSetToMoveValidUsers = (userToMove: IValidUser): void => {
        setToMoveValidUsers(isExistValidUser(
            toMoveValidUsers,
            userToMove,
    ) ?
            toMoveValidUsers.filter((user: IValidUser): boolean=>
                user.userId !== userToMove.userId) : [...toMoveValidUsers, userToMove]);
    };

    const handleSetToMoveRowLevelSecurities = (securityToMove: IValidRowLevelSecurity ): void => {
        const isExist = isExistRowLevelSecurity(toMoveRowLevelSecurities, securityToMove);

        setToMoveRowLevelSecurities(isExist ? getFilteredArrayByObjectProp(
            toMoveRowLevelSecurities,
            securityToMove,
            ROW_LEVEL_SECURITY_CODE,
        ) : [...toMoveRowLevelSecurities, securityToMove]);
    };

    /* tslint:disable*/
    const handleChangePermission = (e: React.ChangeEvent, value: string): void => {
        const usersToChange = toMoveValidUsers.map((item: IValidUser) => item.userId);
        const rowsToChange = toMoveRowLevelSecurities
            .map((item: IValidRowLevelSecurity) => item.rowLevelSecurityCode);
        const { selectedValidUserDtos, selectedRowLevelSecurityDtos } = requestSecurities;
        const usersToChangeWithAccess = selectedValidUserDtos.map((item: ISelectedValidUserDto) => usersToChange
            .includes(item.validUser.userId) ? { ...item, permission: value} : item);
        const rowsToChangeWithAccess = selectedRowLevelSecurityDtos
            .map((item: IValidRowLevelSecurityWithPermission) =>
                rowsToChange.includes(item.validRowLevelSecurity.rowLevelSecurityCode)
                    ? { ...item, permission: value}
                    : item);

        setRequestSecurities({
            ...requestSecurities,
            selectedRowLevelSecurityDtos: rowsToChangeWithAccess,
            selectedValidUserDtos: usersToChangeWithAccess,
        });
    };

    
    const labels = {
        nameLabel: initialLabel,
        permissionLabel: initialLabel,        
        fullAccessLabel: initialLabel,
        readOnlyAllLabel: initialLabel,
        denyAccessLabel: initialLabel,
        readOnlyReleasableLabel: initialLabel,
    };
    const modifiedLabels=useSelector(getModifiedLabels);
    const redactionLanguage =useSelector(getRedactionLanguage);
    const langRule = changeLang(redactionLanguage);
    resourceBundle.map((resource: any) => {
        if (getSecurityLabelsByKey(resource.resourceKey)) {
            labels[getSecurityLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getSecurityLabelsByKey(resource.resourceKey)) {
            labels[getSecurityLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    /* tslint:enable*/

     const permissions: ISelectOptions[] = [
        {
            value: permissionValues.FULL_ACCESS,
            label:labels.fullAccessLabel[langRule] ,
        },
        {
            value: permissionValues.READ_ONLY_ALL,
            label:labels.readOnlyAllLabel[langRule],
        },
        {
            value: permissionValues.READ_ONLY_RELEASABLE,
            label: labels.readOnlyReleasableLabel[langRule],
        },
        {
            value: permissionValues.DENY_ACCESS,
            label:labels.denyAccessLabel[langRule],
        },
    ];
    const getPermitionLabel = (value: string): string => {
        const permission = permissions
            .find((selectedPermission: ISelectOptions): boolean => selectedPermission.value === value);

        return permission.label;
    };
    return (
        <div>
            <div className='modal_request_security_securities_list_buttons'>
                <Fab
                    size='small'
                    color='primary'
                    aria-label='Add'
                    onClick={addSecurities}
                >
                    <Add />
                </Fab>
                <Fab
                    size='small'
                    color='primary'
                    aria-label='Remove'
                    onClick={removeSecurities}
                    disabled={!(rowLevelSecurities && rowLevelSecurities.length) && !(validUsers && validUsers.length)}
                >
                    <Remove />
                </Fab>
            </div>
            <div className='modal_request_security_securities_list'>
                <div className='modal_request_security_securities_list_header'>
                    <Typography className='heading' variant='h6'>
                        {labels.nameLabel[langRule]}
                    </Typography>
                    <Typography className='heading' variant='h6'>
                        {labels.permissionLabel[langRule]}
                    </Typography>
                </div>
                <AutoSizer>
                    {({width, height}: { width: number; height: number; }): JSX.Element => (
                        <div style={{width, height}}>
                            <Scrollbars>
                                {
                                    rowLevelSecurities && rowLevelSecurities
                                        .map((security: IValidRowLevelSecurityWithPermission, i: number): JSX.Element =>
                                        <div
                                            key={`row_${security['validRowLevelSecurity'].rowLevelSecurityCode}_${i}`}
                                            className='modal_request_security_securities_list_row'
                                        >
                                            <RowWithCheckbox
                                                key={`row_cehckbox_
                                                    ${security['validRowLevelSecurity'].rowLevelSecurityCode}_${i}`}
                                                showCheckbox={true}
                                                security={security['validRowLevelSecurity']}
                                                checked={!!isExistRowLevelSecurity(
                                                    toMoveRowLevelSecurities,
                                                    security['validRowLevelSecurity'],
                                                )}
                                                handleChange={(): void => handleSetToMoveRowLevelSecurities(
                                                    security['validRowLevelSecurity'],
                                                )}
                                                isGroup={true}
                                            />
                                            {getPermitionLabel(security.permission)}
                                        </div>)
                                }
                                {
                                    validUsers && validUsers
                                        .map((security: ISelectedValidUserDto, i: number): JSX.Element => <div
                                            key={`validUser_${security.validUser.accessCode}_${i}`}
                                            className='modal_request_security_securities_list_row'
                                        >
                                            <RowWithCheckbox
                                                key={`validUser_checkbox_${security.validUser.accessCode}_${i}`}
                                                showCheckbox={true}
                                                security={security.validUser}
                                                checked={!!isExistValidUser(toMoveValidUsers, security.validUser)}
                                                handleChange={(): void => handleSetToMoveValidUsers(security.validUser)}
                                                isGroup={false}
                                            />
                                            {getPermitionLabel(security.permission)}
                                        </div>)
                                }
                            </Scrollbars>
                        </div>
                    )}
                </AutoSizer>
            </div>
            <RadioGroup aria-label='position' name='position' onChange={handleChangePermission} row={true}>
                {permissions.map((permission: ISelectOptions, i: number): JSX.Element => (
                    <FormControlLabel
                        key={i}
                        value={permission.value}
                        control={<Radio color='primary' />}
                        label={permission.label}
                        labelPlacement='end'
                    />
                ))}
            </RadioGroup>
        </div>
    );
};

import { AnyAction } from 'redux';
import {
    GET_PAGES_REVIEW_SUMMARY_REPORT_FAILURE,
    GET_PAGES_REVIEW_SUMMARY_REPORT_PENDING,
    GET_PAGES_REVIEW_SUMMARY_REPORT_SUCCESS,
} from './constants';
import { IPagesReviewSummaryData } from './pagesReviewSummary.model';

const initialState: IPagesReviewSummaryData = {
    pagesReviewSummaryPending: false,
    error: null,
};

const pagesReviewSummaryReportTypes = (
    state: IPagesReviewSummaryData = initialState,
    {type, payload}: AnyAction,
): IPagesReviewSummaryData => {
    switch (type) {
        case GET_PAGES_REVIEW_SUMMARY_REPORT_PENDING:
            return {
                ...state,
                pagesReviewSummaryPending: true,
                error: null,
            };
        case GET_PAGES_REVIEW_SUMMARY_REPORT_SUCCESS:
            return{
                ...state,
                pagesReviewSummaryPending: false,
            };
        case GET_PAGES_REVIEW_SUMMARY_REPORT_FAILURE:
            return{
                ...state,
                error: payload,
                pagesReviewSummaryPending: false,
            };
        default:
            return state;
    }
};

export default pagesReviewSummaryReportTypes;

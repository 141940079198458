import { AnyAction } from 'redux';
import { IDuplicatesState, IDuplicateDocument } from './duplicates.model';
import {
    CLEAR_DUPLICATE_GROUP,
    GET_DUPLICATES_CURRENT_GROUP_FAILURE,
    GET_DUPLICATES_CURRENT_GROUP_PENDING,
    GET_DUPLICATES_CURRENT_GROUP_SUCCESS,
    GET_DUPLICATES_RESOURCE_FAILURE,
    GET_DUPLICATES_RESOURCE_PENDING,
    GET_DUPLICATES_RESOURCE_SUCCESS,
    CLEAR_DUPLICATES_RESOURCE,
    SET_DUPLICATES_MAX_COUNT,
    CLOSE_SELECTED_DUPLICATE,
    UPDATE_DUPLICATE_RESOURCE,
    UPDATE_ALLOW_PAGES_NAVIGATION,
    SET_DUPLICATE_SCALE,
    SET_DUPLICATE_CLIENT_SIZE,
    SET_DUPLICATE_SEARCH_PENDING,
    SET_MASTER_DOCUMENTS,
    SET_REFERENCE_PAGES,
    SET_REFERENCE_DUPLICATES,
    CLEAN_REFERENCE_MANAGEMENT_DATA,
    GET_DUPLICATE_REFERENCES,
    GET_MODAL_DUPLICATE_REF_DATA_PENDING,
    GET_MODAL_DUPLICATE_REF_DATA_SUCCESS,
    GET_MODAL_DUPLICATE_REF_DATA_FAILURE,
    CLEAR_DUPLICATE_REFERENCES,
} from './constants';

export const initialState: IDuplicatesState = {
    error: null,
    duplicatesCurrentGroup: null,
    duplicatesResources: [],
    pending: false,
    getResourcePending: false,
    maxDuplicatesCount: null,
    searchPending: false,
    duplicateReferences: [],
    modalDuplicatesRefData: null,
    modalDuplicatesRefPending: false,
    allowPagesNavigation: {
        back: false,
        forward: false,
    },
    referenceManagement: {
        masterDocuments: [],
        pages: [],
        duplicates: [],
    },
    isCriteriaSearch: false,
};

const duplicatesReducer = (state: IDuplicatesState = initialState, { type, payload }: AnyAction): IDuplicatesState => {
    switch (type) {
        case GET_DUPLICATES_CURRENT_GROUP_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
            };
        case GET_DUPLICATES_CURRENT_GROUP_SUCCESS:
            return {
                ...state,
                duplicatesCurrentGroup: payload.data,
                pending: false,
                isCriteriaSearch: payload.isCriteriaSearch,
            };
        case GET_DUPLICATES_CURRENT_GROUP_FAILURE:
            return {
                ...state,
                error: payload,
                pending: false,
            };
        case GET_DUPLICATES_RESOURCE_PENDING:
            return {
                ...state,
                getResourcePending: true,
                error: null,
            };
        case GET_DUPLICATES_RESOURCE_SUCCESS:
            return {
                ...state,
                getResourcePending: false,
                duplicatesResources: [...state.duplicatesResources, payload],
            };
        case UPDATE_DUPLICATE_RESOURCE:
            const resources = state.duplicatesResources.map((item: IDuplicateDocument) => {
                return item.documentId !== payload.documentId
                    ? item
                    : {
                        ...item,
                        currentPageId: payload.currentPageId,
                        currentPageNumber: payload.currentPageNumber,
                        pdfSrc: payload.pdfSrc,
                    };
            });
            const allowBack = resources.some((item: IDuplicateDocument) => item.currentPageNumber !== 1);
            const allowForward = resources
                .some((item: IDuplicateDocument) => item.currentPageNumber !== item.pagesAmount);

            return {
                ...state,
                duplicatesResources: resources,
                allowPagesNavigation: { back: allowBack, forward: allowForward },
            };
        case GET_DUPLICATES_RESOURCE_FAILURE:
            return {
                ...state,
                getResourcePending: false,
                error: payload,
            };
        case CLEAR_DUPLICATES_RESOURCE:
            return {
                ...state,
                duplicatesResources: [],
                allowPagesNavigation: {
                    back: false,
                    forward: false,
                },
            };
        case SET_DUPLICATES_MAX_COUNT:
            return {
                ...state,
                maxDuplicatesCount: payload,
            };
        case CLOSE_SELECTED_DUPLICATE:
            const duplicatesResources = state.duplicatesResources
                .filter((item: IDuplicateDocument, index: number) => index !== payload);
            const back = duplicatesResources.some((item: IDuplicateDocument) => item.currentPageNumber !== 1);
            const forward = duplicatesResources
                .some((item: IDuplicateDocument) => item.currentPageNumber !== item.pagesAmount);

            return {
                ...state,
                duplicatesResources,
                allowPagesNavigation: { back, forward },
            };
        case CLEAR_DUPLICATE_GROUP:
            return {
                ...state,
                duplicatesCurrentGroup: null,
                duplicatesResources: [],
            };
        case SET_DUPLICATE_SCALE:
            const { documentId, pageId, scale } = payload;

            return {
                ...state,
                duplicatesResources: state.duplicatesResources.map((
                    resource: IDuplicateDocument,
                ): IDuplicateDocument => ({
                    ...resource,
                    scale: resource.documentId === documentId && resource.currentPageId === pageId ? scale :
                        resource.scale,
                })),
            };
        case SET_DUPLICATE_CLIENT_SIZE:
            return {
                ...state,
                duplicatesResources: state.duplicatesResources.map((
                    resource: IDuplicateDocument,
                ): IDuplicateDocument => ({
                    ...resource,
                    clientSize: resource.documentId === payload.documentId &&
                    resource.currentPageId === payload.pageId ? payload.clientSize : resource.clientSize,
                })),
            };
        case SET_DUPLICATE_SEARCH_PENDING:
            return {
                ...state,
                searchPending: payload,
            };
        case UPDATE_ALLOW_PAGES_NAVIGATION:
            return {
                ...state,
                allowPagesNavigation: {
                    back: payload.back,
                    forward: payload.forward,
                },
            };
        case SET_MASTER_DOCUMENTS:
            return {
                ...state,
                referenceManagement: {
                    ...state.referenceManagement,
                    masterDocuments: payload,
                    pages: [],
                    duplicates: [],
                },
            };
        case SET_REFERENCE_PAGES:
            return {
                ...state,
                referenceManagement: {
                    ...state.referenceManagement,
                    pages: payload,
                    duplicates: [],
                },
            };
        case SET_REFERENCE_DUPLICATES:
            return {
                ...state,
                referenceManagement: {
                    ...state.referenceManagement,
                    duplicates: payload,
                },
            };
        case CLEAN_REFERENCE_MANAGEMENT_DATA:
            return {
                ...state,
                referenceManagement: {
                    masterDocuments: [],
                    pages: [],
                    duplicates: [],
                },
            };
        case GET_DUPLICATE_REFERENCES:
            return {
                ...state,
                duplicateReferences: [...state.duplicateReferences, ...payload],
            };
        case CLEAR_DUPLICATE_REFERENCES:
            return {
                ...state,
                duplicateReferences: [],
            };
        case GET_MODAL_DUPLICATE_REF_DATA_PENDING: {
            return {
                ...state,
                modalDuplicatesRefPending: true,
                error: null,
            };
        }
        case GET_MODAL_DUPLICATE_REF_DATA_SUCCESS: {
            return {
                ...state,
                modalDuplicatesRefData: payload,
                modalDuplicatesRefPending: false,
                error: null,
            };
        }
        case GET_MODAL_DUPLICATE_REF_DATA_FAILURE: {
            return {
                ...state,
                modalDuplicatesRefPending: false,
                error: payload,
            };
        }
        default:
            return state;
    }
};

export default duplicatesReducer;

export const IMPORT_ERROR_TITLE_MESSAGE =
  'Warning';
export const UNSUPPORTED_FILE_MESSAGE =
  `The following files selected are not permitted for import and will
   be automatically removed from the Document Import list.`;
export const BIG_FILE_MESSAGE =
  `The following files selected exceed the maximum import file size of
   2GB and will be automatically removed from the Document Import list.`;
export const ERROR_MESSAGE_NOT_DEFAULT_DISCLOSURE =
    'Default disclosure isn\'t set for this folder type. Please contact your administrator';
export const NO_ATTACHMENT_FOUND_TITLE =
`Alert`;
export const NO_ATTACHMENT_FOUND_MESSAGE =
`No Attachments Found`;
export const NO_AMANDA_ATTACHMENT_FOUND =
`No AmandaAttachments Found`;
export const NO_REDACTION_DOCUMENT_FOUND =
`No Redaction Document Found`;
export const NO_DOCUMENT_FOUND_MESSAGE =
`No Documents Found`;

export const UPLOAD_FILES_PENDING = 'reduction/UPLOAD_FILES_PENDING';
export const UPLOAD_FILES_SUCCESS = 'reduction/UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILURE = 'reduction/UPLOAD_FILES_FAILURE';

export const UPLOAD_REDACTION_DOCUMENTS_PENDING = 'reduction/UPLOAD_REDACTION_DOCUMENTS_PENDING';
export const UPLOAD_REDACTION_DOCUMENTS_SUCCESS = 'reduction/UPLOAD_REDACTION_DOCUMENTS_SUCCESS';
export const UPLOAD_REDACTION_DOCUMENTS_FAILURE = 'reduction/UPLOAD_REDACTION_DOCUMENTS_FAILURE';

export const UPLOAD_DOCUMENT_PENDING = 'reduction/UPLOAD_DOCUMENT_PENDING';
export const UPLOAD_DOCUMENT_SUCCESS = 'reduction/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'reduction/UPLOAD_DOCUMENT_FAILURE';

import * as React from 'react';
import Draggable from 'react-draggable';
import Resizable, { ResizableDirection, NumberSize } from 're-resizable';
import './modalWindow.styles.scss';
import { IBaseModalWindow, IDraggableModalState } from './modalWindow.model';
import BaseModalWindow from './BaseModalWindow';

const ResizableDraggableModalWindow = <P extends object>(
    WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P> => {
    return class extends React.Component<P & IBaseModalWindow, IDraggableModalState> {
        public state: IDraggableModalState = {
            defWidth: this.props.modalDimensions.defaultWidth,
            defHeight: this.props.modalDimensions.defaultHeight,
            defMinWidth: this.props.modalDimensions.modalMinWidth,
            defMinHeight: this.props.modalDimensions.modalMinHeight,
        };

        public handleResize = (w: number = 0, h: number = 0, defMinW: number = 0, defMinH: number = 0): void => {
            this.setState({ defWidth: w, defHeight: h, defMinWidth: defMinW, defMinHeight: defMinH });
        }

        public render(): JSX.Element {
            const { modalDimensions } = this.props;
            const resizeBounds = {
                top: !modalDimensions.disableResize,
                right: !modalDimensions.disableResize,
                bottom: !modalDimensions.disableResize,
                left: !modalDimensions.disableResize,
                topRight: !modalDimensions.disableResize,
                bottomRight: !modalDimensions.disableResize,
                bottomLeft: !modalDimensions.disableResize,
                topLeft: !modalDimensions.disableResize,
            };
            const childProps = {
                ...(this.props.childModalProps as object),
                handleResize: (
                    w: number,
                    h: number,
                    defMinW: number,
                    defMinH: number,
                ): void => this.handleResize(w, h, defMinW, defMinH),
            };

            // TODO remove as object
            const wrapperProps = {
                ...(this.props as object),
                childModalProps: childProps,
            };

            return (
                <Draggable
                    handle='.modal-window-header'
                    defaultClassName='drag-resize-wrapper'
                    disabled={modalDimensions.disableDrag}
                >
                    <Resizable
                        tabIndex={1}
                        size={!modalDimensions.disableResize && {
                            width: this.state.defWidth,
                            height: this.state.defHeight,
                        }}
                        minWidth={this.state.defMinWidth}
                        minHeight={this.state.defMinHeight}
                        enable={resizeBounds}
                        onResizeStop={(
                            e: MouseEvent | TouchEvent,
                            direction: ResizableDirection,
                            ref: HTMLDivElement,
                            d: NumberSize,
                        ): void => {
                            this.setState({
                                defWidth: this.state.defWidth + d.width,
                                defHeight: this.state.defHeight + d.height,
                            });
                        }}
                    >
                        <WrappedComponent
                            {...(wrapperProps as P)}
                        />
                    </Resizable>
                </Draggable>
            );
        }
    };
};

export default ResizableDraggableModalWindow(BaseModalWindow);

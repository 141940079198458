import { combineReducers } from 'redux';
import documentList from './documentList/index';
import stackList from './stackList/index';
import layoutRedactorTypes from './layoutRedactorTypes/index';
import redactor from './redactor/index';
import pageList from './pageList/index';
import modal from './modal/index';
import contextMenu from './contextMenu/index';
import localStorage from './localStorage/index';
import annotationTypes from './annotationTypes';
import amandaContent from './amandaContent';
import disclosureTypes from './disclosureTypes';
import modalWindowImport from './modalWindowImport';
import modalWindowExport from './modalWindowExport';
import modalWindowDisclosure from './modalWindowDisclosure';
import modalGlobalSettings from './modalGlobalSettings';
import modalEditPagination from './modalEditPagination';
import modalSearchDocuments from './modalSearchDocuments';
import modalDisclosureContacts from './disclosureContacts';
import modalPackagePresets from './modalPackagePresets';
import modalWatermarksManagement from './modalWatermarks';
import modalWatermarksAssociate from './modalAssociateWatermarks/index';
import modalAssignDuplicates from './modalAssignDuplicates/index';
import notifications from './notificationHandling/reducer';
import version from './infoController/index';
import annotation from './annotation/index';
import stamps from './stamps/index';
import scanner from './scanner/index';
import taskQueueList from './taskQueue';
import header from './header';
import printer from './printer/index';
import documentIndex from './documentIndex';
import indexMetadata from './indexMetadata';
import indexMetadataDescriptions from './indexMetadataDescriptions';
import pageIndex from './pageIndex';
import reductionMetaData from './initialization';
import modalRequestSearch from './modalRequestSearch';
import globalSearch from './globalSearch';
import modalWindowEncryptionKeys from './modalEncryption/index';
import pageMetadata from './pageMetadata';
import advanceSearch from './advanceSearch/index';
import reportsOfficer from './reportsOfficer';
import modalPageReviewSummary from './modalPageReviewSummary';
import indexNotesReports from './reportsIndexNotes/index';
import auditLog from './auditLog';
import duplicatesReducer from './duplicates/index';
import localization from './localization';
import tableReducer from './common/CommonActionsReducer';

const rootReducer = combineReducers({
    reductionMetaData,
    documentList,
    pageList,
    redactor,
    modal,
    auditLog,
    localization,
    contextMenu,
    localStorage,
    layoutRedactorTypes,
    modalWindowEncryptionKeys,
    modalWindowImport,
    modalWindowExport,
    modalWindowDisclosure,
    modalDisclosureContacts,
    modalGlobalSettings,
    modalSearchDocuments,
    modalEditPagination,
    modalPackagePresets,
    modalWatermarksManagement,
    modalWatermarksAssociate,
    modalRequestSearch,
    modalPageReviewSummary,
    modalAssignDuplicates,
    annotationTypes,
    amandaContent,
    disclosureTypes,
    notifications,
    version,
    annotation,
    taskQueueList,
    stackList,
    stamps,
    scanner,
    header,
    printer,
    documentIndex,
    indexMetadata,
    pageIndex,
    globalSearch,
    pageMetadata,
    advanceSearch,
    reportsOfficer,
    indexMetadataDescriptions,
    indexNotesReports,
    duplicatesReducer,
    tableReducer
});

export default rootReducer;

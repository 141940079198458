import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Box, Tooltip } from '@mui/material';
import SortableList from './SettingsTableList';
import { ISettingsTableColumn } from './settingsTable.model';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { getSessionItem } from '../../../utils/localStorage.util';
import {REDACTION_LANG } from '../../../redux/reducers/localStorage/constant';

interface SettingsTableProps {
    columns: ISettingsTableColumn[];
    setColumns?: (columns: ISettingsTableColumn[]) => void;
    resetColumns: () => void;
    handleClickCheckbox: (id: string, previousState: boolean) => void;
    handleDrop: (dragIndex: number, hoverIndex: number) => void;
    columnOrder: string[];
    handleShowAll?: () => void;
    handleHideAll?:() => void;
}

const SettingsTable: React.FC<SettingsTableProps> = ({ columns, setColumns, resetColumns, handleClickCheckbox, handleDrop, columnOrder, handleShowAll, handleHideAll }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const redactionLang = getSessionItem(REDACTION_LANG);

    return (
        <div>
            <Tooltip title = {redactionLang === 'en' ? "Show/Hide columns" : "Afficher/Masquer les colonnes"}>
                <IconButton onClick={handleOpenMenu}>
                    <ViewColumnIcon />
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <SortableList
                    items={columns}
                    handleClickCheckbox={handleClickCheckbox}
                    resetColumns={resetColumns}
                    handleDrop={handleDrop}
                    columnOrder={columnOrder}
                    handleShowAll={handleShowAll}
                    handleHideAll={handleHideAll}
                />
            </Menu>
        </div>
    );
};

export default SettingsTable;
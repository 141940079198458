import * as React from 'react';
import { Group } from 'react-konva';
import { useEffect, useState } from 'react';
import Konva from 'konva';
import { TransformerComponent } from './Transformer';
import { ShapesGroup } from './ShapesGroup';
import { getWantedObjectByProp } from '../../utils/array.utils';
import { IStamp } from '../../redux/reducers/stamps/stamps.model';
import { IAnnotation } from '../../redux/reducers/pageList/pageList.model';
import { IShapeComponentProps } from './shapesLayer.model';
import { getGroupMinX, getGroupMaxX, getGroupMinY, getGroupMaxY } from '../../utils/konva.utils';
import { END_POINT, START_POINT } from '../../constants/annotationTypes.constants';
import { ANNOTATION, STAMP } from '../../constants/common.constants';
import {
    BOTTOM_CENTER,
    BOTTOM_LEFT,
    BOTTOM_RIGHT,
    MIDDLE_LEFT,
    MIDDLE_RIGHT,
    TOP_CENTER,
    TOP_LEFT,
    TOP_RIGHT,
} from '../../constants/annotation.constants';
import { degrees } from '../../constants';

export const ShapeComponent = <T extends object, G extends object>({
    moveLayerToTop,
    annotations,
    stamps,
    selectedAnnotations,
    selectedStamps,
    container,
    handleDraggable,
    showTransform,
    selectedShape,
    updateShape,
    cleanSelectedShapes,
    pageRotation,
    scale,
}: IShapeComponentProps<T, G>): JSX.Element => {
    const [showGroupResize, setShowGroupResize] = useState(true);
    const allSelectedShapes = [...selectedAnnotations, ...selectedStamps];

    useEffect(() => {
        setShowGroupResize(true);
    }, [allSelectedShapes.length]);

    const startChangeGroup = (): void => {
        setShowGroupResize(false);
    };

    const getGroupElements = (e: Konva.KonvaEventObject<DragEvent>, name: string): Konva.Node[] =>
        /* tslint:disable:no-string-literal */
        e.target.children['filter']((child: Konva.Node) =>
            child.attrs.name.includes(name));

    const updateGroupElements = <D extends object>(
        elements: Konva.Node[],
        selectedElements: D[],
        x: number,
        y: number,
    ): void => {
        elements.forEach((element: Konva.Node) => {
            const selected = getWantedObjectByProp(selectedElements, 'name', element.attrs.name);
            const newCoordinates = {
                [START_POINT]: {
                    x: element.attrs.x + x,
                    y: element.attrs.y + y,
                },
                [END_POINT]: {
                    x: selected['coordinate'][END_POINT].x + x,
                    y: selected['coordinate'][END_POINT].y + y,
                },
            };

            updateShape(newCoordinates, selected);
        });
    };

    const changeGroup = (e: Konva.KonvaEventObject<DragEvent>): void => {
        const { x, y } = e.target.attrs;
        const groupAnnotations = getGroupElements(e, ANNOTATION);
        const groupStamps = getGroupElements(e, STAMP);

        updateGroupElements<IAnnotation>(groupAnnotations, selectedAnnotations, x, y);
        updateGroupElements<IStamp>(groupStamps, selectedStamps, x, y);
        cleanSelectedShapes();
    };

    // TODO: to fix any (children, movingResizer don't exist in konva event target)
    const resizeHandler = (e: any): void => {
        if (selectedShape.name) {
            const { children, _movingAnchorName } = e.currentTarget;
            const { width, height } = children[0].attrs;
            const newCoordinates = {...selectedShape.coordinate};

            if (_movingAnchorName === BOTTOM_RIGHT || _movingAnchorName === MIDDLE_RIGHT || _movingAnchorName === BOTTOM_CENTER) {
                newCoordinates[END_POINT].x = selectedShape.coordinate[START_POINT].x + width;
                newCoordinates[END_POINT].y = selectedShape.coordinate[START_POINT].y + height;
            } else if (_movingAnchorName === TOP_LEFT || _movingAnchorName === MIDDLE_LEFT || _movingAnchorName === TOP_CENTER) {
                newCoordinates[START_POINT].x = selectedShape.coordinate[END_POINT].x - width;
                newCoordinates[START_POINT].y = selectedShape.coordinate[END_POINT].y - height;
            } else if (_movingAnchorName === TOP_RIGHT) {
                newCoordinates[START_POINT].y = selectedShape.coordinate[END_POINT].y - height;
                newCoordinates[END_POINT].x = selectedShape.coordinate[START_POINT].x + width;
            } else if (_movingAnchorName === BOTTOM_LEFT) {
                newCoordinates[START_POINT].x = selectedShape.coordinate[END_POINT].x - width;
                newCoordinates[END_POINT].y = selectedShape.coordinate[START_POINT].y + height;
            }

            updateShape(newCoordinates, selectedShape, true);
        }
    };

    const groupBoundHandler = (e: any): void => {
        const { x, y } = e.target.getStage().children[0].children[0].getAbsolutePosition();
        const allSelectedShapesNormalized = allSelectedShapes.map((item: any) => {
            if (item.width < 0) {
                return {
                    ...item,
                    coordinate: {
                        [START_POINT]: {
                            ...item.coordinate[START_POINT],
                            x: item.coordinate[END_POINT].x,
                        },
                        [END_POINT]: {
                            ...item.coordinate[END_POINT],
                            x: item.coordinate[START_POINT].x,
                        },
                    },
                };
            }

            return item;
        });

        if (pageRotation === degrees.ZERO) {
            const minX = getGroupMinX(allSelectedShapesNormalized);
            const maxX = getGroupMaxX(allSelectedShapesNormalized);
            const minY = getGroupMinY(allSelectedShapesNormalized);
            const maxY = getGroupMaxY(allSelectedShapesNormalized);

            const top = -y > minY;
            const bottom = y + maxY > container.height;
            const right = x > container.width - maxX;
            const left = x < -minX;

            if (top && right) {
                e.target.position( {x: container.width - maxX, y: -minY} );

                return;
            }

            if (top && left) {
                e.target.position( {x: -minX, y: -minY} );

                return;
            }

            if (bottom && right) {
                e.target.position( {x: container.width - maxX, y: container.height - maxY});

                return;
            }

            if (bottom && left) {
                e.target.position( {x: -minX, y: container.height - maxY} );

                return;
            }

            if (top) {
                e.target.position( {x, y: -minY} );

                return;
            }

            if (bottom) {
                e.target.position( {x, y: container.height - maxY} );

                return;
            }

            if (right) {
                e.target.position( {x: container.width - maxX, y} );

                return;
            }

            if (left) {
                e.target.position( {x: -minX, y} );

                return;
            }

            e.target.position( { x, y });

            return;
        }

        if (pageRotation === degrees.MINUS_QUARTER) {
            const minX = getGroupMinX(allSelectedShapesNormalized);
            const maxX = getGroupMaxX(allSelectedShapesNormalized);
            const minY = getGroupMinY(allSelectedShapesNormalized);
            const maxY = getGroupMaxY(allSelectedShapesNormalized);
            const top = -y > minX;
            const bottom = y > container.height - maxX;
            const right  = x > minY + container.width;
            const left = x < maxY;

            if (top && right) {
                e.target.position( { x: -minX, y: -minY } );

                return;
            }

            if (top && left) {
                e.target.position( { x: -minX, y: container.width - maxY } );

                return;
            }

            if (bottom && right) {
                e.target.position( { x: container.height - maxX, y: -minY } );

                return;
            }

            if (bottom && left) {
                e.target.position( { x: container.height - maxX, y: container.width - maxY} );

                return;
            }

            if (top) {
                e.target.position( {x: -minX, y: container.width - x } );

                return;
            }

            if (bottom) {
                e.target.position( {x: container.height - maxX, y: container.width - x } );

                return;
            }

            if (right) {
                e.target.position( {x: y, y: -minY} );

                return;
            }

            if (left) {
                e.target.position( {x: y, y: container.width - maxY} );

                return;
            }

            e.target.position( { x: y, y: container.width - x } );

            return;
        }

        if (pageRotation === degrees.MINUS_HALF) {
            const minX = getGroupMinX(allSelectedShapesNormalized);
            const maxX = getGroupMaxX(allSelectedShapesNormalized);
            const minY = getGroupMinY(allSelectedShapesNormalized);
            const maxY = getGroupMaxY(allSelectedShapesNormalized);
            const top = y < maxY;
            const bottom = y > container.height + minY;
            const right = x > container.width + minX;
            const left = x < maxX;

            if (top && right) {
                e.target.position( { x: -minX, y: container.height - maxY});

                return;
            }

            if (top && left) {
                e.target.position( { x: container.width - maxX, y: container.height - maxY});

                return;
            }

            if (bottom && right) {
                e.target.position( { x: -minX, y: -minY });

                return;
            }

            if (bottom && left) {
                e.target.position( { x: container.width - maxX, y: -minY });

                return;
            }

            if (top) {
                e.target.position( {x: container.width - x, y: container.height - maxY});

                return;
            }

            if (bottom) {
                e.target.position( {x: container.width - x, y: -minY});

                return;
            }

            if (right) {
                e.target.position( {x: -minX, y: container.height - y});

                return;
            }

            if (left) {
                e.target.position( {x: container.width - maxX, y: container.height - y });

                return;
            }

            e.target.position( { x: container.width - x, y: container.height - y });

            return;
        }

        if (pageRotation === degrees.THREE_QUARTERS) {
            const minX = getGroupMinX(allSelectedShapesNormalized);
            const maxX = getGroupMaxX(allSelectedShapesNormalized);
            const minY = getGroupMinY(allSelectedShapesNormalized);
            const maxY = getGroupMaxY(allSelectedShapesNormalized);
            const right = x > container.width - maxY;
            const left = -x > minY;
            const top = y < maxX;
            const bottom = y > container.height + minX;

            if (top && right) {
                e.target.position( { x: container.height - maxX, y: container.width - maxY});

                return;
            }

            if (top && left) {
                e.target.position( { x: container.height - maxX, y: -minY});

                return;
            }

            if (bottom && right) {
                e.target.position( { x: container.height - (container.height + minX), y: container.width - maxY});

                return;
            }

            if (bottom && left) {
                e.target.position( { x: container.height - (container.height + minX), y: -minY});

                return;
            }

            if (top) {
                e.target.position( { x: container.height - maxX, y: x});

                return;
            }

            if (bottom) {
                e.target.position( { x: container.height - (container.height + minX), y: x});

                return;
            }

            if (right) {
                e.target.position( { x: container.height - y, y: container.width - maxY});

                return;
            }

            if (left) {
                e.target.position( { x: container.height - y, y: -minY});

                return;
            }

            e.target.position( { x: container.height - y, y: x});

            return;
        }
    };

/* tslint:enable:no-string-literal */

    return (
        // TODO: to fix key
        <React.Fragment key={selectedAnnotations.length + selectedStamps.length}>
            <Group
                draggable={true}
                onDragStart={startChangeGroup}
                onDragEnd={changeGroup}
                onDragMove={groupBoundHandler}
                pageRotation={pageRotation}
                container={container}
            >
                <ShapesGroup
                    moveLayerToTop={moveLayerToTop}
                    selectedShape={selectedShape}
                    pageRotation={pageRotation}
                    group={[...selectedAnnotations, ...selectedStamps]}
                    container={container}
                    handleDraggable={handleDraggable}
                    isDraggable={false}
                />
            </Group>
            <ShapesGroup
                moveLayerToTop={moveLayerToTop}
                selectedShape={selectedShape}
                pageRotation={pageRotation}
                group={[...annotations, ...stamps]}
                container={container}
                handleDraggable={handleDraggable}
                isDraggable={true}
            />
            {
                showGroupResize && allSelectedShapes.map((shape: IStamp | IAnnotation) => (
                    <TransformerComponent
                        key={shape.name}
                        selectedShapeName={shape.name}
                        isRotatable={false}
                        isResizable={false}
                        pageRotation={pageRotation}
                        scale={scale}
                    />
                ))
            }
            {
                showTransform && selectedShape  && !allSelectedShapes.length ?  <TransformerComponent
                    transformerHandler={resizeHandler}
                    selectedShapeName={selectedShape.name}
                    isResizable={true}
                    isRotatable={!selectedShape.annotationTypeId}
                    container={container}
                    pageRotation={pageRotation}
                    scale={scale}
                /> : null
            }
        </React.Fragment>
    );
};

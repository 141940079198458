import { ISelectOptions } from '../components/materialUiForms/marerialUiForms.model';

export const EXPORT_DOCUMENTS = 'exportDocuments';
export const CONTENT_OPTIONS = 'Content Options';
export const ANNOTATION_CONTROL = 'Annotation Control';
export const CONSULT = 'Consultees';
export const CONSULT_TITLE = 'Disclosures to Export Consultee Contacts';
export const PAGINATION_OPTIONS = 'Pagination Options';
export const PAGINATION_MODE = 'Pagination Mode';
export const PAGINATION_MODE_PARAM = 'paginationMode';
export const PAGINATION_TYPE_BASIC = 'Basic';
export const PAGINATION_TYPE_BASIC_PARAM = 'basic';
export const PAGINATION_TYPE_ADVANCED = 'Advanced';
export const PAGINATION_TYPE_ADVANCED_PARAM = 'advanced';
export const PAGINATION_TYPE_OFF = 'Off';
export const PAGINATION_TYPE_OFF_PARAM = 'off';
export const FONT_SIZE = 'Font Size';
export const FONT_SIZE_PARAM = 'fontSize';
export const FONT_COLOR = 'Font color';
export const FONT_COLOR_PARAM = 'fontColor';
export const LOCATION = 'Location';
export const LOCATION_PARAM = 'location';
export const STAMP_DOC_INDEX = 'Stamp Document Name/Page Index';
export const STAMP_DOC_INDEX_PARAM = 'stampDocumentNameIndex';
export const STARTING_NUMBER = 'Starting Number';
export const STARTING_NUMBER_PARAM = 'startingNumber';
export const INCREMENT_BY = 'Increment By';
export const INCREMENT_BY_PARAM = 'incrementBy';
export const EXPORT_DESTINATION = 'Export Destination';
export const EXPORT_DESTINATION_PARAM = 'exportDestinationOption';
export const FOLDER_PARAM = 'processId';
export const EXPORT_FILE_NAME = 'Export Filename';
export const EXPORT_FILE_NAME_PARAM = 'exportFileName';
export const EXPORT_AS = 'Export As';
export const SEVER_TRANSPARENCY = 'Sever Transparency';
export const SEVER_NAME = 'Sever Name';
export const HIDE_SHOW_TEXT = 'Hide/Show Text';
export const SHOW_TEXT = 'Show Text';
export const HIDE_TEXT = 'Hide Text';
export const SHOW_PARAM = 'show';
export const HIDE_PARAM = 'hide';
export const HIGHLIGHT_DISPLAY = 'Highlight Display';
export const HIGHLIGHT_NAME = 'HighLight Name';
export const OFF_SHOW = 'Off/Show';
export const SHOW = 'Show';
export const OFF = 'Off';
export const PRESETS_PARAM = 'presets';
export const PRESET_PARAM = 'Preset Settings';
export const SELECT_PRESET = 'Select preset';
export const ORIGINALS_ONLY = 'Originals Only';
export const ORIGINALS_ONLY_PARAM = 'originalsOnly';
export const CREATE_PACKAGE_AS_SINGLE_FILE = 'Create Package as Single File';
export const CREATE_PACKAGE_AS_SINGLE_FILE_PARAM = 'packageAsASingleFile';
export const SHOW_ARTICLE_STAMPS = 'Show Article Stamps';
export const SHOW_ARTICLE_STAMPS_PARAM = 'showArticleStamps';
export const SHOW_USER_DEFINED_STAMP = 'Show User Defined Stamps';
export const SHOW_USER_DEFINED_STAMP_PARAM = 'showUserDefinedStamps';
export const INCLUDE_NOTES = 'Include Notes';
export const INCLUDE_NOTES_PARAM = 'includeNotes';
export const INCLUDE_PAGINATED_PAGES_ONLY = 'Include Paginated Pages Only';
export const INCLUDE_PAGINATED_PAGES_ONLY_PARAM = 'includePaginatedPagesOnly';
export const INCLUDE_PAGES_WITH_CONTACT_DISCLOSURES = 'Include Pages with Contact Disclosures Only';
export const INCLUDE_PAGES_WITH_CONTACT_DISCLOSURES_PARAM = 'includePagesWithContact';
export const INCLUDE_SEVER_TO_ARTICLES_LINKING_LINES = 'Show Article Links';
export const INCLUDE_SEVER_TO_ARTICLES_LINKING_LINES_PARAM = 'showSeverArticleLinkLines';
export const LABEL_DOCUMENT_BY = 'Label Document By';
export const LABEL_DOCUMENT_BY_PARAM = 'labelDocumentBy';
export const SHOW_WATERMARKS = 'Show Watermarks';
export const SHOW_WATERMARKS_PARAM = 'showWatermarks';
export const DOCUMENT_ORDER = 'Document Order';
export const DOCUMENT_ORDER_PARAM = 'documentOrderOption';
export const ORIGINAL_SEVER_COLOR_LABEL = 'Original';
export const CUSTOM_SEVER_COLOR_LABEL = 'Custom';
export const ORIGINAL_SEVER_COLOR_PARAM = 'original';
export const CUSTOM_SEVER_COLOR_PARAM = 'custom';
export const SEVER_COLOR = 'Sever Color';
export const SEVER_COLOR_TYPE_PARAM = 'severColorType';
export const SEVER_COLOR_PARAM = 'severColorOption';
export const ARTICLE_COLOR = 'Article Color';
export const ARTICLE_COLOR_PARAM ='articleColorOption';

export const DEFAULT_COLOR_PARAM_HEX = '#000000';
export const BY_PAGINATION = 'By Pagination';
export const BY_PAGINATION_PARAM = 'by_pagination';
export const BY_DOCUMENT_NAME = 'By Document Name';
export const BY_DOCUMENT_NAME_PARAM = 'by_document_name';
export const BY_DOCUMENT_DATE = 'By Document Date';
export const BY_DOCUMENT_DATE_PARAM = 'by_document_date';
export const WATERMARK_LOCATION = 'Watermark Location';
export const WATERMARK_LOCATION_PARAM = 'watermarkLocation';
export const POSITION_MIDDLE_LEFT_PARAM = 'middle_left';
export const POSITION_MIDDLE_CENTER_PARAM = 'middle_center';
export const POSITION_MIDDLE_RIGHT_PARAM = 'middle_right';
export const POSITION_BOTTOM_LEFT_PARAM = 'bottom_left';
export const POSITION_BOTTOM_CENTER_PARAM = 'bottom_center';
export const POSITION_BOTTOM_RIGHT_PARAM = 'bottom_right';
export const POSITION_TOP_LEFT_PARAM = 'top_left';
export const POSITION_TOP_CENTER_PARAM = 'top_center';
export const POSITION_TOP_RIGHT_PARAM = 'top_right';
export const POSITION_MIDDLE_LEFT = 'Middle-Left';
export const POSITION_MIDDLE_CENTER = 'Middle-Center';
export const POSITION_MIDDLE_RIGHT = 'Middle-Right';
export const POSITION_BOTTOM_LEFT = 'Bottom Left';
export const POSITION_BOTTOM_CENTER = 'Bottom Center';
export const POSITION_BOTTOM_RIGHT = 'Bottom Right';
export const POSITION_TOP_LEFT = 'Top Left';
export const POSITION_TOP_CENTER = 'Top Center';
export const POSITION_TOP_RIGHT = 'Top Right';
export const NON_RELEASABLE_PAGES = 'Non Releasable Pages';
export const NON_RELEASABLE_PAGES_PARAM = 'nonReleasablePagesOption';
export const EXCLUDE = 'Exclude';
export const EXCLUDE_PARAM = 'exclude';
export const INCLUDE = 'Include';
export const INCLUDE_PARAM = 'include';
export const AS_PAGE_OUT_NOTICE = 'As Page Out Notice';
export const AS_PAGE_OUT_NOTICE_PARAM = 'page_out_notice';
export const ALL_DOCUMENTS = 'allDocuments';
export const DOCUMENT_NAME = 'Document Name';
export const DOCUMENT_NAME_PARAM = 'documentName';
export const FILE_NAME = 'Filename';
export const FILE_NAME_PARAM = 'fileName';
export const LANGUAGE_DEFAULT= 'English';
export const BILINGUAL = 'Bilingual';
export const LANGUAGE_DEFAULT_PARAM= 'english';
export const BILINGUAL_PARAM = 'bilingual';
export const LANGUAGE = 'Language';
export const LANGUAGE_PARAM = 'isBilingual';

export const FILE = 'File';
export const FILE_PARAM = 'file';
export const REQUEST_FOLDER = 'Folder Attachment';
export const REQUEST_FOLDER_PARAM = 'request_folder';
export const FOLDER_PROCESS = 'Process Attachment';
export const FOLDER_PROCESS_PARAM = 'folder_process';
export const PRINTER = 'Printer';
export const PRINTER_PARAM = 'printer';
export const PRINTER_CODE = 'printerCode';
export const PRINTER_TRAY_PARAM = 'trayCode';

export const ERROR_WATERMARKS = 'There are no watermarks associated with the Request Folder Type';
export const ERROR_TOOLTIP = 'Please adjust your Export options';
export const ERROR_ALL_UNCHECKED = 'No pages to export';
export const SAVE_TOOLTIP = 'Save';
export const NO_PAGES = `No pages to export based on current selections. \
Please adjust your Export options, or select one or more documents to include in the export package.`;
export const ERROR_FORMAT = `Please enter a list of PDF page numbers separated by commas and/or page ranges \
by separated by a dash.  Example: 1-5,8-10,14,20-30`;
export const EXPORTED_SUCCESSFULLY = 'Exported successfully';
export const FILE_LENGTH_EXCEED = 'File length exceeded';

export const CHECKBOX_TYPE_DEFAULT = 'checkbox';
export const CHECKBOX_TYPE_SWITCH = 'switch';

export const FILE_NAME_REGEX = /^(con|prn|aux|nul|com[1-9]|lpt[1-9])$|([<>:"\/\\|?*])|(\.|\s)$/;
export const INVALID_FILE_NAME = 'Please enter the valid filename.';
export const FILE_NAME_EXCEED_MAX_LENGTH = 'Filename exceed maximum length';

export const severShowHideOptions = (labels: any, langRule: string): ISelectOptions[] => [
    { value: HIDE_PARAM, label: labels.hideTextLabel[langRule] },
    { value: SHOW_PARAM, label: labels.showTextLabel[langRule] },
];

export const hightLightShowHideOptions = (labels: any, langRule: string): ISelectOptions[] => [
    { value: HIDE_PARAM, label: labels.offLabel[langRule] },
    { value: SHOW_PARAM, label: labels.showLabel[langRule] },
];

export const documentOrderOptions = (labels: any, langRule: string): ISelectOptions[] => [
    { value: BY_PAGINATION_PARAM, label: labels.byPaginationLabel[langRule] },
    { value: BY_DOCUMENT_NAME_PARAM, label: labels.byDocNameLabel[langRule] },
    { value: BY_DOCUMENT_DATE_PARAM, label: labels.byDocDateLabel[langRule] },
];

export const colorOptions = (labels: any, langRule: string): ISelectOptions[] => [
    { value: ORIGINAL_SEVER_COLOR_PARAM, label: labels.severOriginalLabel[langRule] },
    { value: CUSTOM_SEVER_COLOR_PARAM, label: labels.severCustomLabel[langRule] },
];

export const nonReleasablePageOptions = (labels: any, langRule: string): ISelectOptions[] => [
    { value: EXCLUDE_PARAM, label: labels.excludeLabel[langRule] },
    { value: INCLUDE_PARAM, label: labels.includeLabel[langRule] },
    { value: AS_PAGE_OUT_NOTICE_PARAM, label: labels.asPageOutNoticeLabel[langRule] },
];

export const languageOptions = (labels: any, langRule: string): ISelectOptions[] => [
    { value: LANGUAGE_DEFAULT_PARAM, label: labels.langDefaultLabel[langRule] },
    { value: BILINGUAL_PARAM, label: labels.bilingualLabel[langRule] },
];

export const labelDocumentBy = (labels: any, langRule: string): ISelectOptions[] => [
    { value: DOCUMENT_NAME_PARAM, label: labels.docNameLabel[langRule] },
    { value: FILE_NAME_PARAM, label: labels.fileNameLabel[langRule] },
];

export const firstListOfCheckbox = (labels: any, langRule: string): any => [{
    param: CREATE_PACKAGE_AS_SINGLE_FILE_PARAM,
    label: labels.createSingleFileLabel[langRule],
}, {
    param: SHOW_ARTICLE_STAMPS_PARAM,
    label: labels.showArticlesLabel[langRule],
}, {
    param: SHOW_USER_DEFINED_STAMP_PARAM,
    label: labels.showUserDefinedLabel[langRule],
}, {
    param: INCLUDE_NOTES_PARAM,
    label: labels.includeNotesLabel[langRule],
}];

export const secondListOfCheckbox = (labels: any, langRule: string): any => [{
    param: INCLUDE_PAGINATED_PAGES_ONLY_PARAM,
    label: labels.includePaginatedPagesLabel[langRule],
}, {
    param: INCLUDE_SEVER_TO_ARTICLES_LINKING_LINES_PARAM,
    label: labels.showArticleLinksLabel[langRule],
}, {
    param: INCLUDE_PAGES_WITH_CONTACT_DISCLOSURES_PARAM,
    label: labels.includeContactDisclosureLabel[langRule],
},
];

export const watermarkLocation = (labels: any, langRule: string): ISelectOptions[] => [
    { value: POSITION_MIDDLE_LEFT_PARAM, label: labels.middleLeftLabel[langRule] },
    { value: POSITION_MIDDLE_CENTER_PARAM, label: labels.middleCenterLabel[langRule] },
    { value: POSITION_MIDDLE_RIGHT_PARAM, label: labels.middleRightLabel[langRule] },
    { value: POSITION_BOTTOM_LEFT_PARAM, label: labels.bottomLeftLabel[langRule] },
    { value: POSITION_BOTTOM_CENTER_PARAM, label: labels.bottomCenterLabel[langRule] },
    { value: POSITION_BOTTOM_RIGHT_PARAM, label: labels.bottomRightLabel[langRule] },
    { value: POSITION_TOP_LEFT_PARAM, label: labels.topLeftLabel[langRule] },
    { value: POSITION_TOP_CENTER_PARAM, label: labels.topCenterLabel[langRule] },
    { value: POSITION_TOP_RIGHT_PARAM, label: labels.topRightLabel[langRule] },
];

export const paginationLocation = (labels: any, langRule: string): ISelectOptions[] => [
    { value: POSITION_BOTTOM_LEFT_PARAM, label: labels.bottomLeftLabel[langRule] },
    { value: POSITION_BOTTOM_CENTER_PARAM, label: labels.bottomCenterLabel[langRule] },
    { value: POSITION_BOTTOM_RIGHT_PARAM, label: labels.bottomRightLabel[langRule] },
    { value: POSITION_TOP_LEFT_PARAM, label: labels.topLeftLabel[langRule] },
    { value: POSITION_TOP_CENTER_PARAM, label: labels.topCenterLabel[langRule] },
    { value: POSITION_TOP_RIGHT_PARAM, label: labels.topRightLabel[langRule] },
];

export const paginationModeOptions = (labels: any, langRule: string): ISelectOptions[] => [
    { value: PAGINATION_TYPE_BASIC_PARAM, label: labels.basicLabel[langRule] },
    { value: PAGINATION_TYPE_ADVANCED_PARAM, label: labels.advancedLabel[langRule] },
    { value: PAGINATION_TYPE_OFF_PARAM, label: labels.offLabel[langRule] },
];

export const exportDestinationOptions = (labels: any, langRule: string): ISelectOptions[] => [
    { value: FILE_PARAM, label: labels.fileLabel[langRule] },
    { value: REQUEST_FOLDER_PARAM, label: labels.folderAttachmentLabel[langRule] },
    { value: FOLDER_PROCESS_PARAM, label: labels.processAttachmentLabel[langRule] },
    { value: PRINTER_PARAM, label: labels.printerLabel[langRule] },
];

export const FOLDER = 'FOLDER';
export const PROCESS = 'PROCESS';
export const ATTACHMENT_TYPE_CODE_PARAM = 'attachmentTypeCode';
export const FOLDER_ATTACHMENT_TYPE_CODE_PARAM = 'folderAttachmentTypeCode';
export const PROCESS_ATTACHMENT_TYPE_CODE_PARAM = 'processAttachmentTypeCode';
export const ATTACHMENT_DESCRIPTION = 'attachmentDescription';
export const ATTACHMENT_DETAIL = 'attachmentDetail';

import * as React from 'react';
import classnames from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import Group from '@mui/icons-material/Group';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Person from '@mui/icons-material/Person';
import { IValidRowLevelSecurity } from '../../../redux/reducers/amandaContent/amandaContent.model';
import { IRowWithCheckboxParams } from './ModalRequestSecurity.model';
import './modalRequestSecurity.style.scss';

export const RowWithCheckbox = (
    {
        security,
        checked,
        handleChange,
        isGroup,
        securityChildrenToShow,
        setSecurityChildrenToShow,
        showCheckbox,
    }: IRowWithCheckboxParams,
): JSX.Element => {

    const toggleSetSecurityChildrenToShow = (e: React.SyntheticEvent, children: IValidRowLevelSecurity[]): void => {
        e.persist();

        // TODO: refactor use reduce method
        let codes = [...securityChildrenToShow];

        children.forEach((child: IValidRowLevelSecurity): void => {
            if (codes.includes(child.rowLevelSecurityCode)) {
                codes = codes.filter((code: number): boolean =>
                    code !== child.rowLevelSecurityCode);
            } else {
                codes = [...codes, child.rowLevelSecurityCode];
            }
        });

        setSecurityChildrenToShow(codes);
    };

    return (
        <div className={classnames({ modal_request_security_missed_checkbox: !showCheckbox })}>
            {showCheckbox && <Checkbox
                checked={checked}
                value={`${security.rowLevelSecurityCode}`}
                onChange={handleChange}
                color='primary'
            />}
            {isGroup && security.validRowLevelSecurityChildren && !!security.validRowLevelSecurityChildren.length
                && securityChildrenToShow &&
                <KeyboardArrowDown
                    onClick={(e: React.SyntheticEvent): void =>
                        toggleSetSecurityChildrenToShow(e, security.validRowLevelSecurityChildren)}
                />
            }
            {isGroup ? <Group /> : <Person />}
            {isGroup ?  security.rowLevelSecurityDesc : security.userName}
        </div>
    );
};

import * as React from 'react';
import { AnyAction } from 'redux';
import { get } from 'lodash';
import { ThunkDispatch } from 'redux-thunk/es/types';
import {
    IBottomBarProps,
    IBottomBarStateProps,
    IBottomBarDispatchProps,
} from './rightBottomBar.model';
import { IState } from '../../redux/store';
import { connect } from 'react-redux';
import './rightBottomBar.styles.scss';
import { getDocumentIndex } from '../../redux/selectors/documentIndex';
import { getPageIndex, getPageIndexPending } from '../../redux/selectors/pageIndex';
import { getPageMetaDataState } from '../../redux/selectors/pageMetadata';
import { getUserMetaData } from '../../redux/selectors/initialization';
import { fetchIndexMetadata } from '../../redux/actions/indexMetadata';
import { addPageMetaData, getPageMetaData, updatePageMetaData } from '../../redux/actions/pageMetadata';
import { openModalWindow } from '../../redux/actions/modal';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { INDEX_KEYWORD_MANAGEMENT } from '../modalWindowContents';
import {
    NOTES_QUERY_PARAM,
    COMMENTS_QUERY_PARAM,
    RECOMMENDATIONS_QUERY_PARAM,
} from '../../constants/common.constants';
import { MetaDataTab } from '../metaDataTab/MetaDataTab';
import IndexMetadataConsultees from '../IndexMetadataConsultees/IndexMetadataConsultees';
import { IndexTabComponent } from '../IndexTab/indexTab';
import { IPageMetadataType } from '../../redux/reducers/pageMetadata/notes.model';
import { getSelectedPage, getCurrentDocument, getCurrentPage, getCurrentPageComments } from '../../redux/selectors/pageList';
import { getRightBottomTabsPermissions } from '../../redux/selectors/initialization';
import { getPanelSizes, getRedactionLanguage, getRedactionMode } from '../../redux/selectors/localStorage';
import SimpleTabs from '../../components/simpleTabs/SimpleTabs';
import Comment from '../comment/Comment';
import { getModifiedLabels } from '../../redux/selectors/localization';
import resourceBundle from '../localization/localizationData';
import { initialLabel } from '../../constants/localization.constants';
import { changeLang } from '../../redux/actions/localization';
import { MODE_CONSULT, MODE_CONTRIBUTE, MODE_FULL } from '../../redux/reducers/localStorage/constant';

export const AvailableTabs = {
    DOCUMENT_INDEX: 'Document Index',
    PAGE_INDEX: 'Page Index',
    NOTES: 'Notes',
    CONSULTEES:'Consultees',
    COMMENTS: 'Comments',
    RECOMMENDATIONS:'Recommendations',
    PDF_COMMENTS: 'Pdf Comments',
};

export const getRightbottomBarLabelsByKey = (key: string): string => {
    switch(key) {
        case 'METADATA_TAB_NOTES_TITLE':
            return 'notesTitle';
        case 'METADATA_TAB_COMMENTS_TITLE':
            return 'commentsTitle';
        case 'METADATA_TAB_RECOMMENDATIONS_TITLE':
            return 'recommendationsTitle';
        case 'METADATA_TAB_DOCUMENT_INDEX_TITLE':
            return 'documentIndexTitle';
        case 'METADATA_TAB_PAGE_INDEX_TITLE':
            return 'pageIndexTitle';
        case 'COMMON_LABEL_SAVE':
            return 'saveLabel';
        case 'METADATA_TAB_Filename':
            return 'fileNameLabel';
        case 'METADATA_TAB_KEYWORD_INDEX':
            return 'keyIndexLabel';
        case 'INDEX_KEYWORD_MANAGEMENT_MODAL_TITLE':
            return 'keywordIndexTitle';
        case 'METADATA_TAB_CONSULTEES_TITLE':
            return 'consulteesTitle';
        case 'METADATA_TAB_PDF_COMMENTS_TITLE':
            return 'pdfCommentsTitle';
        case 'METADATA_TAB_NO_COMMENTS_MSG' :
            return 'noCommentsMsg';
        default: return '';
    }
};

export class RightBottomBar extends React.Component<IBottomBarProps> {
    public componentDidUpdate(prevProps: IBottomBarProps): void {
        const { selectedPageId, currentDocument } = this.props;

        if(get(prevProps, 'currentDocument.id') !== get(currentDocument, 'id')) {
            this.props.fetchMetadata();
        }

        if(prevProps.selectedPageId !== selectedPageId) {
            this.props.getPageMetadata();
        }
    }

    public render(): JSX.Element {
        const {
            documentIndex,
            pageIndex,
            openModal,
            selectedPageId,
            pageMetaData,
            currentDocument,
            currentPage,
            userMetaData,
            panelSizes,
            currentPageComments,
            modifiedLabels,
            redactionLang,
            redactionMode,
        } = this.props;
        const { comments, recommendations, notes } = pageMetaData;
        const isActive = !!documentIndex.length;
        const {
            consultTubPermission,
            notesTabPermissionView,
            notesTabPermissionEdit,
            indexPermissionView,
            indexPermissionEdit,
            recommendationsTabPermissionView,
            recommendationsTabPermissionEdit,
            indexDocumentPermissionView,
        } = this.props.permissions;
        const width = panelSizes.rightPanel.barWidth;
        const labels = {
            notesTitle: initialLabel,
            commentsTitle: initialLabel,
            recommendationsTitle: initialLabel,
            documentIndexTitle: initialLabel,
            pageIndexTitle: initialLabel,
            saveLabel: initialLabel,
            keyIndexLabel: initialLabel,
            fileNameLabel: initialLabel,
            keywordIndexTitle: initialLabel,
            consulteesTitle: initialLabel,
            pdfCommentsTitle: initialLabel,
            noCommentsMsg: initialLabel,
        };
        const langRule = changeLang(redactionLang);
        const isConsult = redactionMode === MODE_CONSULT;
        const isFullMode = redactionMode === MODE_FULL;

        resourceBundle.map((resource: any) => {
            if (getRightbottomBarLabelsByKey(resource.resourceKey)) {
                labels[getRightbottomBarLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getRightbottomBarLabelsByKey(resource.resourceKey)) {
                labels[getRightbottomBarLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        const openModalWindowIndex = (type: string): void => {
            openModal(type, { id: INDEX_KEYWORD_MANAGEMENT, title: labels.keywordIndexTitle[langRule]});
        };

        return (
            <div className='bottom-bar'>
                {
                    !isActive && <div className='bottom-bar-hidden'/>
                }
                <section className='bottom-bar_table'>
                    <SimpleTabs
                        defaultIndex={0}
                        widthTabs={width}
                    >
                            {!isConsult &&  <IndexTabComponent
                            title={labels.documentIndexTitle[langRule]}
                            isValid={true}
                            hidden={!indexPermissionView}
                            data={documentIndex}
                            selectedPageId={selectedPageId}
                            openModalWindowIndex={openModalWindowIndex}
                            editPermission={indexPermissionEdit}
                            viewPermission={indexDocumentPermissionView}
                            labels={labels}
                            language={redactionLang}
                        /> }
                        {!isConsult &&  <IndexTabComponent
                            title={labels.pageIndexTitle[langRule]}
                            isValid={true}
                            hidden={!indexPermissionView}
                            data={pageIndex}
                            selectedPageId={selectedPageId}
                            openModalWindowIndex={openModalWindowIndex}
                            editPermission={indexPermissionEdit}
                            viewPermission={indexDocumentPermissionView}
                            labels={labels}
                            language={redactionLang}
                        /> }
                        {!isConsult &&  <MetaDataTab
                            title={labels.notesTitle[langRule]}
                            isValid={true}
                            hidden={!notesTabPermissionView}
                            data={notes}
                            editPermission={notesTabPermissionEdit}
                            updateMetaData={this.props.updatePageMetadata}
                            addMetaData={this.props.addPageMetadata}
                            getQueryParam={NOTES_QUERY_PARAM}
                            show={true}
                            currentDocument={currentDocument}
                            currentPage={currentPage}
                            userMetadata={userMetaData}
                        /> }
                        {isFullMode && <IndexMetadataConsultees
                            title={labels.consulteesTitle[langRule]}
                            isValid={true}
                            hidden={!consultTubPermission}
                            editPermission={notesTabPermissionEdit}
                        /> } 
                        <MetaDataTab
                            title={labels.commentsTitle[langRule]}
                            isValid={true}
                            data={comments}
                            hidden={!recommendationsTabPermissionView}
                            editPermission={recommendationsTabPermissionEdit}
                            updateMetaData={this.props.updatePageMetadata}
                            addMetaData={this.props.addPageMetadata}
                            getQueryParam={COMMENTS_QUERY_PARAM}
                            show={true}
                            currentDocument={currentDocument}
                            currentPage={currentPage}
                            userMetadata={userMetaData}
                        />
                        <MetaDataTab
                            title={labels.recommendationsTitle[langRule]}
                            isValid={true}
                            hidden={!recommendationsTabPermissionView}
                            data={recommendations}
                            editPermission={recommendationsTabPermissionEdit}
                            updateMetaData={this.props.updatePageMetadata}
                            addMetaData={this.props.addPageMetadata}
                            getQueryParam={RECOMMENDATIONS_QUERY_PARAM}
                            show={true}
                            currentDocument={currentDocument}
                            currentPage={currentPage}
                            userMetadata={userMetaData}
                        />
                    <Comment
                        title={labels.pdfCommentsTitle[langRule]}
                        isValid={true}
                        hidden={!recommendationsTabPermissionView}
                        pdfComments={currentPageComments}
                        noCommentsMsg={labels.noCommentsMsg[langRule]}
                    />
                    </SimpleTabs>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state: IState): IBottomBarStateProps => ({
    documentIndex: getDocumentIndex(state),
    pageIndex: getPageIndex(state),
    pageIndexPending: getPageIndexPending(state),
    selectedPageId: getSelectedPage(state),
    permissions: getRightBottomTabsPermissions(state),
    pageMetaData: getPageMetaDataState(state),
    currentDocument: getCurrentDocument(state),
    currentPage: getCurrentPage(state),
    userMetaData: getUserMetaData(state),
    panelSizes: getPanelSizes(state),
    currentPageComments: getCurrentPageComments(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
    redactionMode: getRedactionMode(state),
});
const mapDispatchToProps =
    (dispatch: ThunkDispatch<IState, IBottomBarDispatchProps, AnyAction>): IBottomBarDispatchProps => ({
        openModal: (type: string, modalProps: IModalProps): void => {
            dispatch(openModalWindow(type, modalProps));
        },
        fetchMetadata: (): void => {
            dispatch(fetchIndexMetadata());
        },
        updatePageMetadata: (data: IPageMetadataType): void => {
          dispatch(updatePageMetaData(data));
        },
        addPageMetadata: (data: IPageMetadataType): void => {
            dispatch(addPageMetaData(data));
        },
        getPageMetadata: (dataType?: string): void => {
            dispatch(getPageMetaData(dataType));
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(RightBottomBar);

import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { IState } from '../store';
import {
    GET_AVAILABLE_RANGE_PENDING,
    GET_AVAILABLE_RANGE_SUCCESS,
    GET_AVAILABLE_RANGE_FAIL,
    UPDATE_PAGINATION_INDEX_PENDING,
    UPDATE_PAGINATION_INDEX_FAIL, UPDATE_PAGINATION_INDEX_SUCCESS,
} from '../reducers/modalEditPagination/constant';
import api from '../../api/reductionApi';
import { IRange } from '../reducers/modalEditPagination/modalEditPagination.model';
import { handleCloseAllModalWindows } from './modal';
import { fetchPaginatedPagesSilent, updatePaginationPageList } from './pageList';
import { updatePaginationDocumentList } from './documentList';
import { openModalWindow } from './modal';
import {
    ALERT_DIALOG_MODAL,
    WARNING,
} from '../../constants/messages.constants';
import { addError } from './errorHandling';
import { ThunkDispatch } from 'redux-thunk/es/types';
import resourceBundle from '../../containers/localization/localizationData';
import { changeLang, getCommonLabelsByKey } from './localization';
import { initialLabel } from '../../constants/localization.constants';

export const fetchAvailablePaginationPending = (): AnyAction => action(GET_AVAILABLE_RANGE_PENDING);
export const fetchAvailablePaginationSuccess = (range: IRange[]): AnyAction =>
    action(GET_AVAILABLE_RANGE_SUCCESS, range);
export const fetchAvailablePaginationFailure = (): AnyAction => action(GET_AVAILABLE_RANGE_FAIL);

export const fetchAvailablePagination = (
    redactionDocumentId: number,
    documentId: number,
): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            localization: {modifiedLabels},
            localStorage: {language},
        } = getState();
        const labels = { warningLabel: initialLabel};

        resourceBundle.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });
        modifiedLabels.map((resource: any) => {
            if(getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }

            return;
        });

        dispatch(fetchAvailablePaginationPending());

        try {
            const response = await api.paginationController.getAvailablePaginationRange(
                redactionDocumentId,
                documentId,
            );

            dispatch(fetchAvailablePaginationSuccess(response));
        } catch (error) {
            dispatch(fetchAvailablePaginationFailure());
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: labels.warningLabel[changeLang(language)],
                message: error.message,
                confirm: (): AnyAction => handleCloseAllModalWindows(),
            }));
        }
    };

export const editPaginationIndexPending = (): AnyAction => action(UPDATE_PAGINATION_INDEX_PENDING);
export const editPaginationIndexSuccess = (): AnyAction => action(UPDATE_PAGINATION_INDEX_SUCCESS);
export const editPaginationIndexFailure = (): AnyAction => action(UPDATE_PAGINATION_INDEX_FAIL);

export const editPaginationIndex = (
    documentId: number,
    paginationIndex: number,
    redactionDocumentId: number,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {

        dispatch(editPaginationIndexPending());

        try {
            const response = await api.paginationController.updatePaginationIndex(
                documentId,
                paginationIndex,
                redactionDocumentId,
            );

            dispatch(updatePaginationPageList(response));
            dispatch(updatePaginationDocumentList(response));
            dispatch(editPaginationIndexSuccess());
            dispatch(handleCloseAllModalWindows());
            dispatch(fetchPaginatedPagesSilent());
        } catch (error) {
            dispatch(editPaginationIndexFailure());
            dispatch(addError(error));
        }
    };

import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import {
    CLEAR_OPTIONS_DISCLOSURE,
    CHANGE_OPTIONS_DISCLOSURE,
    SET_OPTIONS_DISCLOSURE,
} from '../reducers/modalWindowDisclosure/constant';
import { IDisclosureOptions } from '../reducers/modalWindowDisclosure/modalWindowDisclosure.model';

export const setDisclosureOptions = (data: IDisclosureOptions): AnyAction => action(SET_OPTIONS_DISCLOSURE, data);
export const changeDisclosureOptions = (data: IDisclosureOptions): AnyAction => action(CHANGE_OPTIONS_DISCLOSURE, data);
export const clearDisclosureOptions = (): AnyAction => action(CLEAR_OPTIONS_DISCLOSURE);

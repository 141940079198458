import * as React from 'react';
import classnames from 'classnames';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import arrayMutators from 'final-form-arrays';
import { FormState } from 'final-form';
import { Form, Field, FormRenderProps, FormSpy } from 'react-final-form';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'rc-scrollbars';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import Button from '@mui/material/Button';
import {
    renderCheckbox,
    renderColorPicker,
    renderTextField,
} from '../../../components/materialUiForms/materialUiForms';
import { validateArrayType } from '../../../utils/validate.util';
import { DELETE } from '../../../constants/common.constants';
import { IDynamicForm, IFormFields, ILayerItems, IValidationError } from './modalLayerTypes.model';
import './dynamicForm.style.scss';
import { IAnnotationType } from '../../../redux/reducers/annotationTypes/annotationTypes.model';
import { IStampType } from '../../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { changeLang, commonLabels, getCommonLabelsByKey } from '../../../redux/actions/localization';
import resourceBundle from '../../localization/localizationData';

const FIELD_NAMES = [
    'isActive',
    'saveSeveredText',
    'isBorderOnly',
    'color',
    'description',
    'name',
];

const isDisable = (isDisabled: boolean, fieldName: string, isSever?: boolean, isExemption?: string): boolean => {
    if (isDisabled && FIELD_NAMES.includes(fieldName)) {

        if (!isSever && fieldName === 'saveSeveredText') {
            return true;
        }

        if (!!isExemption && fieldName === 'description') {
            return true;
        }

        return false;
    } else if (!isDisabled) {
        if (!isSever && fieldName === 'saveSeveredText') {

            return true;
        }

        return false;
    } else {

        return isDisabled;
    }

};

const createField = (
    type: string,
    fieldName: string,
    name: string,
    isDisabled: boolean = false,
    editPermission: boolean = false,
    isSever: boolean,
    isExemption: string,
    label?: string,
): JSX.Element => {
    switch (type) {
        case 'checkbox':
            return (
                <Field
                    render={renderCheckbox}
                    type='checkbox'
                    name={name}
                    disabled={isDisable(isDisabled, fieldName, isSever) || !editPermission}
                />
            );
        case 'textarea':
            return (
                <Field
                    render={renderTextField}
                    name={name}
                    multiline={true}
                    rows='2'
                    label=''
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    disabled={isDisable(isDisabled, fieldName, null, isExemption) || !editPermission}
                />
            );
        case 'textfield':
            return (
                <Field
                    render={renderTextField}
                    className='input-text-label'
                    name={name}
                    label={label || ''}
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    disabled={isDisable(isDisabled, fieldName, null, isExemption) || !editPermission}
                />
            );
        case 'colorPicker':
            return (
                <Field
                    render={renderColorPicker}
                    name={name}
                    multiline={true}
                    rows='2'
                    label=''
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    disabled={isDisable(isDisabled, fieldName) || !editPermission}
                />
            );
    }
};

// fields can have different type of data
export const renderFormRows = (
    name: string,
    index: number,
    fields: any,
    deletePermission: boolean,
    editPermission: boolean,
    addPermission: boolean,
    formFields: IFormFields[],
    onDelete: (id: number) => void,
    activeField: string,
    setActiveField: (name: string) => void,
    redactionLang: string
): JSX.Element => {

    const formRowClass = classnames('form-row', { active: activeField === name });

    // Used to check whether the form is for AnnotationTypes or UserDefinedStamps
    const isAnnotationTypes = formFields && formFields.find((field: IFormFields) => field.field === 'color');

    return (
        <div key={name} className={formRowClass} onClick={(): void => setActiveField(name)}>
            {
                formFields.map((field: IFormFields): JSX.Element => {
                    if (field.field === 'name2' || field.field === 'description2') {
                        return null;
                    }

                    const fieldValue = fields.value && fields.value[index];
                    const isSever = fieldValue && fieldValue.hasOwnProperty('isSever') && fieldValue.isSever;
                    const isExemption = fieldValue && fieldValue.hasOwnProperty('isExemption') ? 'isExemption' : '';
                    const hasEditPermissions = editPermission || fieldValue && !fieldValue.id && addPermission;
                    const isFrench = redactionLang ? redactionLang === 'fr' : false;

                    return (
                        <div className={field.className} key={field.field}>
                            {
                                deletePermission && field.type && field.type === 'icon' ? (
                                    <div>
                                        <IconButton
                                            aria-label={DELETE}
                                            onClick={(): void => {
                                                if (fieldValue && fieldValue.id) {
                                                    onDelete(fields.value[index].id);
                                                } else {
                                                    fields.remove(index);
                                                }
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ) : createField(
                                    field.type,
                                    field.field,
                                    `${name}.[${field.field}]`,
                                    fieldValue && fieldValue.isApplied,
                                    hasEditPermissions,
                                    isSever,
                                    isExemption,
                                    isAnnotationTypes && isFrench ? `${field.label} en` : ''
                                )
                            }
                            {   // It only renders if the DynamicForm is rendered for ModalAnnotationTypes
                                field.field
                                && (field.field === 'name' || field.field === 'description')
                                && isAnnotationTypes
                                && createField(
                                    field.type,
                                    `${field.field}2`,
                                    `${name}.[${field.field}2]`,
                                    false,
                                    hasEditPermissions,
                                    isSever,
                                    isExemption,
                                    isFrench ? '' : `${field.label} fr`,
                                )
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};

const DynamicForm = ({
    layerItems,
    formFields,
    onSubmit,
    onDelete,
    defaultData,
    uniqueField,
    handleDetectChanges,
    activeField,
    setActiveField,
    onCloseModal,
    isPristine,
    permissions: {
        ADD: addPermission,
        DELETE: deletePermission,
        EDIT: editPermission,
    },
    redactionLang,
    modifiedLabels,
}: IDynamicForm): JSX.Element => {
    const initialValues = {
        layerItems,
    };
    const langRule = changeLang(redactionLang);
    const labels = commonLabels;

    resourceBundle.map((resource: any) => {
        if (getCommonLabelsByKey(resource.resourceKey)) {
            labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getCommonLabelsByKey(resource.resourceKey)) {
            labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    const scrollAndSetFocusToLastElement = (): void => {
        const el = document.querySelector('.form-row:last-of-type');
        // Used to check whether the form is for AnnotationTypes or UserDefinedStamps
        const isAnnotationTypes = formFields && formFields.find((field: IFormFields) => field.field === 'color');
        const textarea: HTMLElement = el.querySelector(`${isAnnotationTypes ? 'input' : 'textarea'}:first-of-type`);

        el.scrollIntoView();

        if (textarea) {
            textarea.focus();
        }
    };

    const footerClass = classnames('dynamic-form-form-footer', { 'left-side': !addPermission });

    return (
        <div className='dynamic-form-container' style={{ height: '500px'}}>
            <AutoSizer>
                {({ width, height }: { width: number; height: number; }): JSX.Element => (
                    <div style={{ width, height }}>
                        <div className={'dynamic-form-container_header'}>
                            {
                                formFields.map((field: IFormFields): JSX.Element =>
                                    field.field === 'name2' || field.field === 'description2' ? null : (
                                        <div title={field.label} className={field.className} key={field.field}>{field.label}</div>
                                    ))
                            }
                        </div>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={initialValues}
                            mutators={{
                                ...arrayMutators,
                            }}
                            validate={(values: ILayerItems): IValidationError[] =>
                                validateArrayType(values.layerItems, formFields, uniqueField)}
                            render={<T extends object>({
                                handleSubmit,
                                form: {
                                    mutators: { push, pop, remove },
                                },
                                form,
                                pristine,
                                submitting,
                                errors,
                            }: FormRenderProps & FormState<T>): JSX.Element => {

                                const hasErrors = errors.layerItems && errors.layerItems
                                    .some((error: IValidationError): boolean => {
                                        if (error) {
                                            const hasAnyProperties = Object.keys(error).length > 0;
                                            return !!hasAnyProperties;
                                        }
                                        return false;
                                    });

                                const onAddNew = (fieldsLength: number): void => {
                                    push('layerItems', defaultData);
                                    // TODO it is a big pf library react-final-form-arrays
                                    // https://github.com/final-form/react-final-form-arrays/issues/70
                                    // need to be fixed after their updates
                                    setTimeout((): void => {
                                        scrollAndSetFocusToLastElement();
                                        setActiveField(
                                            `layerItems[${fieldsLength}]`,
                                        );
                                    }, 0);
                                };

                                return (
                                    <form onSubmit={handleSubmit} className='form-container'>
                                        <FieldArray
                                            name='layerItems'
                                            validate={(values: IAnnotationType[] | IStampType[]): IValidationError[] =>
                                                validateArrayType(values, formFields, uniqueField, labels, langRule)
                                            }
                                        >
                                            {({ fields }: FieldArrayRenderProps<IAnnotationType | IStampType, HTMLElement>): JSX.Element => (
                                                <div className='form-container_content'>
                                                    <Scrollbars>
                                                        {fields.map((name: string, index: number): JSX.Element =>
                                                            renderFormRows(
                                                                name,
                                                                index,
                                                                fields,
                                                                deletePermission,
                                                                editPermission,
                                                                addPermission,
                                                                formFields,
                                                                onDelete,
                                                                activeField,
                                                                setActiveField,
                                                                redactionLang
                                                            ),
                                                        )}
                                                    </Scrollbars>
                                                    <div className={footerClass}>
                                                        {addPermission && (
                                                            <div className='dynamic-form-form-add'>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    color='primary'
                                                                    aria-label='Add new stamp'
                                                                    className='styles primary'
                                                                    onClick={(): void => onAddNew(fields.length)}
                                                                >
                                                                    <AddIcon />
                                                                    {labels.addNewLabel[langRule]}
                                                                </Button>
                                                            </div>
                                                        )}
                                                        <div className='dynamic-form-form-action'>
                                                            <Button
                                                                variant='outlined'
                                                                className='modal-window__buttons outlined'
                                                                size='small'
                                                                onClick={onCloseModal}
                                                            >
                                                                {labels.cancelLabel[langRule]}
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                size='small'
                                                                color='primary'
                                                                type='submit'
                                                                className='primary'
                                                                disabled={submitting || isPristine || hasErrors}
                                                            >
                                                                {labels.saveLabel[langRule]}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </FieldArray>

                                        <FormSpy
                                            subscription={{ values: true, dirty: true }}
                                            onChange={<K extends object>({ values, dirty }: FormState<K>): void => {
                                                handleDetectChanges(values['layerItems'], dirty);
                                            }}
                                        />
                                    </form>
                                );
                            }}
                        />

                    </div>
                )}
            </AutoSizer>
        </div>
    );
};

export default DynamicForm;

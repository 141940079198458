import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { get } from 'lodash';
import {
    SET_ADVANCE_SEARCH_PARAM,
    CLEAR_ADVANCE_SEARCH_PARAM,
    UPDATE_ADVANCE_SEARCH_ERRORS,
    ADVANCE_SEARCH_VALUE_PENDING,
    ADVANCE_SEARCH_VALUE_FAILURE,
    ADVANCE_SEARCH_VALUE_SUCCESS,
    SET_ADVANCE_SEARCH_TABLE_COLUMNS,
    SET_ACTS_LIST, SET_EXEMPTIONS_LIST,
    CLEAR_ADVANCE_SEARCH_RESULT,
    SET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING,
    SET_ADVANCE_SEARCH_COLUMN_HEADER_LIST_SUCCESS,
    SET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE,
    MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_PENDING,
    MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS,
    MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE,
    RESET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING,
    RESET_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS,
    RESET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE,
    SET_SELECTED_ADVANCE_SEARCHED_DOCUMENT,
} from '../reducers/advanceSearch/constant';
import { IAdvanceSearchParamChange, IAdvanceSearchParams } from '../../containers/advancedSearch/advancedSearch.model';
import api from '../../api/reductionApi';
import { IState } from '../store';
import { IError } from '../common.model';
import { fetchDocumentMetadata, setDocumentToSelectedList, setPageToSelectedList } from './pageList';
import { restrictOnFetchDataPageList, setSearchCurrentPageId } from './globalSearch';
import { IAct, IAdvanceSearchResponseData, ISelectedDocument } from '../reducers/advanceSearch/advanceSearch.model';
import { addError } from './errorHandling';
import { IValidExemptionDtoList } from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { getPageNumberByPageId, getPageResource } from './redactor';
import { ICurrentSelectedDoc, ISearchPage,
    ISearchResponseData } from '../../containers/globalSearch/globalSearch.model';
import { IDocument, IPages } from '../../redux/reducers/pageList/pageList.model';
import { ADVANCED_SEARCH_RESULTS_LABEL, CURRENT_SCOPE_PARAM } from '../../constants/advancedSearch.constants';
import { handleCloseModalWindow, openModalWindow } from './modal';
import { CONFIRMATION_DIALOG_MODAL, NO_MATCHING_RESULTS, OPEN_RESULT_IN_NEW_TAB,
    SELECTED_DOC_NOT_IN_CURRENT_STACK } from '../../constants/messages.constants';
import { b64EncodeUnicode } from '../../utils/encode.util';
import { NO, YES } from '../../constants/common.constants';
import { IFile } from 'redux/reducers/documentList/documentList.model';
import { fetchDocumentList, saveStackId } from './documentList';

export const setAdvanceSearchParams = (param: IAdvanceSearchParamChange): AnyAction =>
    action(SET_ADVANCE_SEARCH_PARAM, param);

export const clearAdvanceSearchParams = (): AnyAction =>
    action(CLEAR_ADVANCE_SEARCH_PARAM);

export const updateAdvanceSearchErrors = (param: string[]): AnyAction =>
    action(UPDATE_ADVANCE_SEARCH_ERRORS, param);

export const advanceSearchValuePending = (): AnyAction => action(ADVANCE_SEARCH_VALUE_PENDING);

export const advanceSearchValueSuccess = (data: IAdvanceSearchResponseData[]): AnyAction =>
    action(ADVANCE_SEARCH_VALUE_SUCCESS, data);
export const advanceSearchValueFailure = (error: IError): AnyAction => action(ADVANCE_SEARCH_VALUE_FAILURE, error);

export const setActsList = (acts: IAct[]): AnyAction =>
    action(SET_ACTS_LIST, acts);

export const setExemptionsList = (acts: IValidExemptionDtoList[]): AnyAction =>
    action(SET_EXEMPTIONS_LIST, acts);

export const clearAdvanceSearchResult = (): AnyAction => action(CLEAR_ADVANCE_SEARCH_RESULT);

export const setAdvanceSearchColumnHeaderPending = (): AnyAction =>
    action(SET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING);
export const setAdvanceSearchColumnHeaderSuccess = (advanceSearchColumnsHeaders: IHeader[]): AnyAction =>
    action(SET_ADVANCE_SEARCH_COLUMN_HEADER_LIST_SUCCESS, advanceSearchColumnsHeaders);
export const setAdvanceSearchColumnHeaderFailure = (error: IError): AnyAction =>
    action(SET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE, error);

export const modifyAdvanceSearchColumnHeaderPending = (): AnyAction =>
    action(MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_PENDING);
export const modifyAdvanceSearchColumnHeaderSuccess = (columns: IHeader[]): AnyAction =>
    action(MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS, columns);
export const modifyAdvanceSearchColumnHeaderFailure = (error: IError): AnyAction =>
    action(MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE, error);

export const setAdvanceSearchTableColumns = (columns: IHeader[]): AnyAction =>
    action(SET_ADVANCE_SEARCH_TABLE_COLUMNS, columns);

export const resetAdvanceSearchColumnHeaderPending = (): AnyAction =>
    action(RESET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING);
export const resetAdvanceSearchColumnHeaderSuccess = (advanceSearchColumnsHeaders: IHeader[]): AnyAction =>
    action(RESET_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS, advanceSearchColumnsHeaders);
export const resetAdvanceSearchColumnHeaderFailure = (error: IError): AnyAction =>
    action(RESET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE, error);

export const setSelectedAdvanceSearchedDocument = (selectedDocument: ISelectedDocument): AnyAction =>
    action(SET_SELECTED_ADVANCE_SEARCHED_DOCUMENT, selectedDocument);

export const postAdvanceSearchValue = (redactionDocId: number, searchValue: IAdvanceSearchParams):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                folderRsn: currentReqFolderRsn,
            },
            pageList: {
                currentDocument,
            },
            globalSearch: {
                currentPageId,
                restrictFlagForPageList,
            },
            documentList: {
                files,
            },
            localStorage:{ language },
        } = getState();

        dispatch(advanceSearchValuePending());

        try {
            const response = await api.searchController.postAdvanceSearchQuery(searchValue, redactionDocId);

            dispatch(advanceSearchValueSuccess(response));

            if (response.length) {
                const { documentId, pages, folderRSN, redactionDocumentId } = response[0];
                const { page: { actualPageNumber } } = pages[0];
                const selectedDoc = getCurrentDocId(0, response, files, currentReqFolderRsn);
                const langParam = language &&  `&lang=${language}` || '';

                if (selectedDoc.inDifferentReq) {
                    dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                        id: CONFIRMATION_DIALOG_MODAL,
                        title: ADVANCED_SEARCH_RESULTS_LABEL,
                        okButtonTitle: YES,
                        cancelButtonTitle: NO,
                        message: OPEN_RESULT_IN_NEW_TAB,
                        confirm: (): () => void => (): void => {
                            window.open(`/${b64EncodeUnicode(folderRSN)}?redactionDocument=${b64EncodeUnicode(redactionDocumentId)}&document=${b64EncodeUnicode(documentId)}&page=${b64EncodeUnicode(actualPageNumber)}&from=${b64EncodeUnicode('advanceSearch')}${langParam}`, '_blank');
                            handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                            return;
                        },
                        reject: (): () => void => (): void => {
                            handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                            return;
                        },
                    }));

                    return;
                } else if (selectedDoc.inDifferentStack) {
                    dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                        id: CONFIRMATION_DIALOG_MODAL,
                        title: NO_MATCHING_RESULTS,
                        okButtonTitle: YES,
                        cancelButtonTitle: NO,
                        message: SELECTED_DOC_NOT_IN_CURRENT_STACK,
                        confirm: (): () => void => (): void => {
                            dispatch(fetchDocumentList(redactionDocumentId));
                            dispatch(saveStackId(0));
                            getSearchedPage(response, currentPageId, currentDocument,
                                            restrictFlagForPageList, redactionDocId, dispatch);
                            handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                            return;
                        },
                        reject: (): () => void => (): void => {
                            handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                            return;
                        },

                    }));

                    return;
                }

                getSearchedPage(response, currentPageId, currentDocument,
                    restrictFlagForPageList, redactionDocId, dispatch);

            }

        } catch (error) {
            dispatch(advanceSearchValueFailure(error));
            dispatch(addError(error));
        }
    };
const getSearchedPage = async (response: IAdvanceSearchResponseData[],
                               currentPageId: number, currentDocument: null | IDocument,
                               restrictOnFetchDataPageListFlag: boolean,
                               redactionDocId: number,
                               dispatch: ThunkDispatch<IState, null, AnyAction> ): Promise<void> => {
    const { documentId, pages } = response[0];
    const { page: { id } } = pages[0];
    const responsePage = get(response, '[0].pages[0].page', null);
    const responseDocId = get(response, '[0].documentId', 0);
    let isPageExists = false;

    response.forEach((item: ISearchResponseData) => {
        isPageExists = item.pages.some((page: ISearchPage) => page.page.id === currentPageId);
    });

    const pageId = (!currentPageId || !isPageExists) && responsePage ? responsePage.id : currentPageId;
    const isPageInCurrentPagination = currentDocument &&
        currentDocument.pages.find((page: IPages) => page.id === pageId);
    const isCurrentDocument = currentDocument && currentDocument.id === responseDocId;

    dispatch(setPageToSelectedList(pageId, false));

    if(!restrictOnFetchDataPageListFlag) {
        dispatch(restrictOnFetchDataPageList(true));
    }

    if (isCurrentDocument) {
        if (isPageInCurrentPagination) {
            dispatch(getPageResource(
                redactionDocId, responseDocId, isPageInCurrentPagination.id,
                isPageInCurrentPagination.rotation, isPageInCurrentPagination.layers[0].id));
        } else {
            dispatch(getPageNumberByPageId({
                redactionDocumentId: redactionDocId,
                documentId: responseDocId,
                selectedPageId: responsePage.actualPageNumber,
                getAnnotationStamps: true,
            }));
        }
    } else if (response.length) {
        try {
            await dispatch(fetchDocumentMetadata(redactionDocId, responseDocId, null, false, true));
            dispatch(getPageNumberByPageId({
                redactionDocumentId: redactionDocId,
                documentId: responseDocId,
                selectedPageId: responsePage.actualPageNumber,
                getAnnotationStamps: true,
            }));
        } catch (error) {
            dispatch(advanceSearchValueFailure(error));
            dispatch(addError(error));
        }

    }

    if(!restrictOnFetchDataPageListFlag) {
        dispatch(restrictOnFetchDataPageList(true));
    }

    dispatch(setSearchCurrentPageId(id));
    dispatch(setPageToSelectedList(id, false));
    dispatch(setDocumentToSelectedList(documentId, false));
};

const getCurrentDocId = (pageIndex: number, response: IAdvanceSearchResponseData[],
                         files: IFile[], currentReqFolderRsn: number): ICurrentSelectedDoc => {

    if (response[pageIndex].folderRSN !== currentReqFolderRsn) {
        return {
            id: null,
            inDifferentReq: true,
            inDifferentStack: false,
        };
    }

    const nextSelectedDoc = files.filter((doc: IFile) => {
        return doc.name === response[pageIndex].documentName;
    })[0];

    return nextSelectedDoc ? {
        id: nextSelectedDoc.id,
        inDifferentReq: false,
        inDifferentStack: false,
    } : {
        id: response[pageIndex].documentId,
        inDifferentReq: false,
        inDifferentStack: true,
    };
};

export const getAct = (actId: number): (
    dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            const response = await api.actController.getAct(actId);

            dispatch(setActsList([response]));
        } catch (error) {
            dispatch(addError(error));
            dispatch(setActsList([]));
        }
    };

export const getAllAct = (): (
    dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            const response = await api.actController.getActs();

            dispatch(setActsList(response));
        } catch (error) {
            dispatch(addError(error));
            dispatch(setActsList([]));
        }
    };

export const getExemptions = (actId: number): (
    dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            const response = await api.actController.getActExemption(actId);

            dispatch(setExemptionsList(response));
        } catch (error) {
            dispatch(addError(error));
            dispatch(setExemptionsList([]));
        }
    };

export const getAdvanceSearchColumnHeader = (lid: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(setAdvanceSearchColumnHeaderPending());
        try {
            const response = await api.advanceSearch.getAdvanceSearchListColumnHeader(lid);

            dispatch(setAdvanceSearchColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
            dispatch(setAdvanceSearchColumnHeaderFailure(error));
        }
    };

export const modifyAdvanceSearchColumnHeader = (advanceSearchColumnHeader: IHeader[], userId: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        modifyAdvanceSearchColumnHeaderPending();

        const columns = advanceSearchColumnHeader.map((columnsHeader: IHeader, index: number) => {
            return {
                ...columnsHeader,
                displayOrder: index + 1,
            };

        });

        try {
            const response = await api.advanceSearch.modifyAdvanceSearchColumnHeader(columns, userId);
            dispatch(modifyAdvanceSearchColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const getResetedAdvanceSearchColumnHeader = (userId: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(resetAdvanceSearchColumnHeaderPending());
        try {
            const response = await api.advanceSearch.resetAdvanceSearchColumnHeader(userId);

            dispatch(resetAdvanceSearchColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const setSelectedAdvanceSearchDocumentDetails = (documentDetails: ISelectedDocument):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>

    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            redactionDocumentId,
            documentId,
            page: actualPageNumber,
        } = documentDetails;

        try {
            const response = await dispatch(fetchDocumentMetadata(
                redactionDocumentId,
                documentId, null, false, true));

            dispatch(getPageNumberByPageId({
                redactionDocumentId,
                documentId,
                selectedPageId: actualPageNumber,
                getAnnotationStamps: true,
                openDocInNewTab: true,
            }));
        } catch (error) {
            dispatch(addError(error));

        }

    };

import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    IPageMetadataState,
    IPageMetadataType,
    IConsultees,
    IConsulteesPages,
} from '../reducers/pageMetadata/notes.model';

export const getPageMetaDataState = (state: IState): IPageMetadataState => state.pageMetadata;

export const getNote = createSelector(
    [getPageMetaDataState],
    (pageMetadata: IPageMetadataState): IPageMetadataType => pageMetadata.notes,
);

export const getConsultees = createSelector(
    [getPageMetaDataState],
    (pageMetadata: IPageMetadataState): IConsulteesPages[] => pageMetadata.pagesConsultees,
);

import { AnyAction, Dispatch } from 'redux';
import api from '../../api/reductionApi';
import { action } from 'typesafe-actions';
import {
    GET_DOCUMENT_TASKS_PENDING,
    GET_DOCUMENT_TASKS_SUCCESS,
    GET_DOCUMENT_TASKS_FAILURE,
    DELETE_DOCUMENT_TASKS_PENDING,
    DELETE_DOCUMENT_TASKS_SUCCESS,
    DELETE_DOCUMENT_TASKS_FAILURE,
    UPDATE_DOCUMENT_TASKS_PENDING,
    UPDATE_DOCUMENT_TASKS_SUCCESS,
    UPDATE_DOCUMENT_TASKS_FAILURE,
} from '../reducers/taskQueue/constants';
import { ITaskList, IPaginationParams } from '../reducers/taskQueue/taskQueue.model';
import { IError } from '../common.model';
import { DEFAULT_PAGE_SIZE } from '../../constants/taskQueue.constants';
import { IState } from '../store';
import { ThunkDispatch } from 'redux-thunk/es/types';

export const setDocumentTasksQueuePending = (): AnyAction => action(GET_DOCUMENT_TASKS_PENDING);
export const setDocumentTasksQueueSuccess = (
    taskList: ITaskList[],
    lastProcessedDocuments: ITaskList[],
    totalPages: number,
    currentPage: number,
    totalTaskCount: number,
    showFinishedTasks: boolean,
): AnyAction => action(
    GET_DOCUMENT_TASKS_SUCCESS,
    { taskList, totalPages, currentPage, totalTaskCount, showFinishedTasks, lastProcessedDocuments },
);
export const setDocumentTasksQueueFailure = (error: IError): AnyAction => action(GET_DOCUMENT_TASKS_FAILURE, error);

export const deleteDocumentTasksQueuePending = (): AnyAction => action(DELETE_DOCUMENT_TASKS_PENDING);
export const deleteDocumentTasksQueueSuccess = (id: number): AnyAction => action(DELETE_DOCUMENT_TASKS_SUCCESS, id);
export const deleteDocumentTasksQueueFailure = (error: IError): AnyAction =>
    action(DELETE_DOCUMENT_TASKS_FAILURE, error);

export const updateDocumentTasksQueuePending = (): AnyAction => action(UPDATE_DOCUMENT_TASKS_PENDING);
export const updateDocumentTasksQueueSuccess = (id: number, status: ITaskList): AnyAction =>
    action(UPDATE_DOCUMENT_TASKS_SUCCESS, { id, status });
export const updateDocumentTasksQueueFailure = (error: IError): AnyAction =>
    action(UPDATE_DOCUMENT_TASKS_FAILURE, error);

export const restartDocumentTasksQueue = (redactionDocumentId: number):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { taskQueueList: { taskList, currentPage: page, showFinishedTasks } } = getState();
        const isAllTaskCompleted = taskList ? taskList.every((taskQueue: ITaskList) => taskQueue.isFinished) : true;

        if (isAllTaskCompleted && redactionDocumentId) {
            const paginationParams = {
                page,
                showFinishedTasks,
                size: DEFAULT_PAGE_SIZE,
            };

            dispatch(fetchDocumentTasksQueue(redactionDocumentId, paginationParams, false));
        }
    };

export const fetchDocumentTasksQueue = (
    redactionDocumentId: number,
    params?: IPaginationParams,
    needPending?: boolean,
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        if (needPending) {
            dispatch(setDocumentTasksQueuePending());
        }

        try {
            const paginationParams = params ? {
                ...params,
                // no sorting in the story, change if it is needed
                sort: 'createdDate,desc',
            } : { sort: 'createdDate,desc' };
            const response = await api.taskQueue.getDocumentTasks(redactionDocumentId, paginationParams);
            const { processingDocuments, totalPages, currentPage, totalTaskCount, lastProcessedDocuments } = response;
            const { showFinishedTasks } = params;

            dispatch(setDocumentTasksQueueSuccess(
                processingDocuments,
                lastProcessedDocuments,
                totalPages,
                currentPage,
                totalTaskCount,
                showFinishedTasks,
            ));
        } catch (error) {
            dispatch(setDocumentTasksQueueFailure(error));
        }
    };

export const deleteDocumentTasksQueue = (redactionDocumentId: number, id: number):
    (dispatch: Dispatch) => Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteDocumentTasksQueuePending());

    try {
        await api.taskQueue.deleteTask(redactionDocumentId, id);

        dispatch(deleteDocumentTasksQueueSuccess(id));
    } catch (error) {
        dispatch(deleteDocumentTasksQueueFailure(error));
    }
};

export const updateDocumentTasksQueue = (redactionDocumentId: number, id: number, status: string):
    (dispatch: Dispatch) => Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateDocumentTasksQueuePending());

    try {
        const response = await api.taskQueue.updateTask(redactionDocumentId, id, status);

        dispatch(updateDocumentTasksQueueSuccess(id, response));
    } catch (error) {
        dispatch(updateDocumentTasksQueueFailure(error));
    }
};

import * as React from 'react';
import MaterialCheckboxTable from '../../../../components/materialCheckboxTable/materialCheckboxTable';
import * as constants from '../../../../constants/export.contstants';
import { Spinner } from '../../../../components/spinner/Spinner';
import { IPeople, IState, IProps } from './consult.model';

import './consult.scss';
import { IDisclosureOptions } from '../../../../redux/reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { changeLang } from '../../../../redux/actions/localization';

const PEOPLE_HEADERS = (labels: any, langRule: string): any => [
    { id: 'lastName', numeric: false, disablePadding: false, label: labels.lastNameLabel[langRule] },
    { id: 'firstName', numeric: false, disablePadding: false, label: labels.firstNameLabel[langRule] },
    { id: 'organizationName', numeric: false, disablePadding: false, label: labels.organizationNameLabel[langRule] },
    { id: 'department', numeric: false, disablePadding: false, label: labels.departmentLabel[langRule] },
    { id: 'userTeam', numeric: false, disablePadding: false, label: labels.userTeamLabel[langRule] },
    { id: 'peopleRsn', numeric: false, disablePadding: false, label: labels.peopleRSNLabel[langRule] },
];
const DISCLOSURES_HEADERS = (labels: any, langRule: string): any => [
    { id: 'disclosures', numeric: false, disablePadding: false, label: labels.disclosuresLabel[langRule] },
];

const PEOPLE_MODEL = (language: string): string[] => ['nameLast', 'nameFirst', 'organizationName',
    language ? language === 'fr' ? 'departmentFr' : 'department' : 'department', 'userTeam', 'peopleRsn'];
const DISCLOSURES_MODEL = ['name'];

const PEOPLE_ID = 'peopleRsn';
const DISCLOSURE_ID = 'id';

class Consult extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            disclosureFilters: [],
            contactFilters: [],
        };
    }

    public render(): JSX.Element {
        const { isLoading, consultData, parentLabel, language } = this.props;
        const lang = changeLang(language);

        return (
            <div className='consult-tab tab'>
                <span className='consult-tab__title'>{parentLabel.consulteeTabDisclosureLabel[lang]}</span>
                <div className='consult-inner-wrapper'>
                    {
                        isLoading
                            ? <Spinner active={true} />
                            : <React.Fragment>
                                <div className='consult-disclosures'>
                                    <MaterialCheckboxTable
                                        id={DISCLOSURE_ID}
                                        headers={DISCLOSURES_HEADERS(parentLabel, lang)}
                                        rows={consultData.disclosureTypes}
                                        allChecked={true}
                                        model={[language ? language === 'fr' ? 'name1' : 'name' : 'name']}
                                        getSelectedRows={(value: IDisclosureOptions[]): void =>
                                            this.handleSelectedDisclosures(value)}
                                    />
                                </div>
                                <MaterialCheckboxTable
                                    id={PEOPLE_ID}
                                    headers={PEOPLE_HEADERS(parentLabel, lang)}
                                    rows={consultData.people}
                                    model={PEOPLE_MODEL(language)}
                                    getSelectedRows={(value: IPeople[]): void =>
                                        this.handleSelectedContacts(value)}
                                />
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }

    private handleUpdateFilters = (): void => {
        const filter = {
            contactFilters: this.state.contactFilters,
            disclosureFilters: this.state.disclosureFilters,
        };

        this.props.setExportFilters(filter);
        this.props.updateExportFilters();
    }

    private handleSelectedDisclosures = (value: IDisclosureOptions[]): void => {
        this.setState({ disclosureFilters: value }, () => {
            this.handleUpdateFilters();
        });
    }

    private handleSelectedContacts = (value: IPeople[]): void => {
        this.setState({ contactFilters: value }, () => {
            this.handleUpdateFilters();
        });
    }
}

export default Consult;

export const GET_CURRENT_DOCUMENT_PENDING = 'reduction/GET_CURRENT_DOCUMENT_PENDING';
export const GET_CURRENT_DOCUMENT_SUCCESS = 'reduction/GET_CURRENT_DOCUMENT_SUCCESS';
export const GET_CURRENT_DOCUMENT_FAILURE = 'reduction/GET_CURRENT_DOCUMENT_FAILURE';
export const UNSET_CURRENT_DOCUMENT = 'reduction/UNSET_CURRENT_DOCUMENT';

export const GET_PAGE_IN_DOCUMENT_PENDING = 'reduction/GET_PAGE_IN_DOCUMENT_PENDING';
export const GET_PAGE_IN_DOCUMENT_SUCCESS = 'reduction/GET_PAGE_IN_DOCUMENT_SUCCESS';
export const GET_PAGE_IN_DOCUMENT_FAILURE = 'reduction/GET_PAGE_IN_DOCUMENT_FAILURE';

export const SET_DOCUMENT_TO_SELECT_LIST = 'reduction/SET_DOCUMENT_TO_SELECT_LIST';
export const SET_PAGE_TO_SELECT_LIST = 'reduction/SET_PAGE_TO_SELECT_LIST';
export const SET_ALL_PAGES_TO_SELECT_LIST = 'reduction/SET_ALL_PAGES_TO_SELECT_LIST';
export const SET_ALL_DOCUMENTS_TO_SELECT_LIST = 'reduction/SET_ALL_DOCUMENTS_TO_SELECT_LIST';

export const GET_CURRENT_PAGE_SUCCESS = 'reduction/GET_CURRENT_PAGE_SUCCESS';

export const CHANGE_PAGE_ORDER_PENDING = 'reduction/CHANGE_PAGE_ORDER_PENDING';
export const CHANGE_PAGE_ORDER_SUCCESS = 'reduction/CHANGE_PAGE_ORDER_SUCCESS';
export const CHANGE_PAGE_ORDER_FAILURE = 'reduction/CHANGE_PAGE_ORDER_FAILURE';

export const UNDO_PAGE_DISCLOSURE_PENDING = 'reduction/UNDO_PAGE_DISCLOSURE_PENDING';
export const UNDO_PAGE_DISCLOSURE_SUCCESS = 'reduction/UNDO_PAGE_DISCLOSURE_SUCCESS';
export const UNDO_PAGE_DISCLOSURE_FAILURE = 'reduction/UNDO_PAGE_DISCLOSURE_FAILURE';

export const DELETE_PAGE_PENDING = 'reduction/DELETE_PAGE_PENDING';
export const DELETE_PAGE_SUCCESS = 'reduction/DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILURE = 'reduction/DELETE_PAGE_FAILURE';

export const UNDO_PAGINATION_PAGE = 'reduction/UNDO_PAGINATION_PAGE';

export const SET_DISCLOSURE_PAGE = 'reduction/SET_DISCLOSURE_PAGE';

export const SET_PAGE_LIST_PENDING = 'reduction/SET_PAGE_LIST_PENDING';
export const SET_PAGE_LIST_SUCCESS = 'reduction/SET_PAGE_LIST_SUCCESS';
export const SET_PAGE_LIST_FAILURE = 'reduction/SET_PAGE_LIST_FAILURE';
export const UPDATE_DOCUMENT_LIST = 'reduction/UPDATE_DOCUMENT_LIST';

export const CHANGE_PAGE_EXEMPTIONS_SUCCESS = 'reduction/CHANGE_PAGE_EXEMPTIONS_SUCCESS';
export const REMOVE_PAGE_EXEMPTIONS_SUCCESS = 'reduction/REMOVE_PAGE_EXEMPTIONS_SUCCESS';

export const GET_CURRENT_DOCUMENT_PAGINATION = 'reduction/GET_CURRENT_DOCUMENT_PAGINATION';
export const UPDATE_PAGINATION = 'reduction/UPDATE_PAGINATION';

export const CLEAR_CURRENT_DOCUMENT_STATE = 'reduction/CLEAR_CURRENT_DOCUMENT_STATE';
export const CLEAR_CURRENT_PAGE_STATE = 'reduction/CLEAR_CURRENT_PAGE_STATE';

export const SET_PAGE_NUMBER = 'reduction/SET_PAGE_NUMBER';
export const SET_SORT_PARAM = 'reduction/SET_SORT_PARAM';

export const OCR_PAGES_PENDING = 'reduction/OCR_PAGES_PENDING';
export const OCR_PAGES_SUCCESS = 'reduction/OCR_PAGES_SUCCESS';
export const OCR_PAGES_FAILURE = 'reduction/OCR_PAGES_FAILURE';

export const SET_ROTATE_IN_DOCUMENT_LIST = 'reduction/SET_ROTATE_IN_DOCUMENT_LIST';

export const GET_EXEMPTION_CURRENT_DOCUMENT_PENDING = 'reduction/GET_EXEMPTION_CURRENT_DOCUMENT_PENDING';
export const GET_EXEMPTION_CURRENT_DOCUMENT_SUCCESS = 'reduction/GET_EXEMPTION_CURRENT_DOCUMENT_SUCCESS';
export const GET_EXEMPTION_CURRENT_DOCUMENT_FAILURE = 'reduction/GET_EXEMPTION_CURRENT_DOCUMENT_FAILURE';

export const UPDATE_DISCLOSURES = 'reduction/UPDATE_DISCLOSURES';
export const SET_PAGE_INITIAL_STATE = 'reduction/SET_PAGE_INITIAL_STATE';

export const CHANGE_PAGES_DISCLOSURE_PENDING = 'reduction/CHANGE_PAGES_DISCLOSURE_PENDING';

export const SET_PAGE_LIST_COLUMN_HEADER_PENDING = 'redaction/SET_PAGE_LIST_COLUMN_HEADER_PENDING';
export const SET_PAGE_LIST_COLUMN_HEADER_LIST_SUCCESS = 'redaction/SET_PAGE_LIST_COLUMN_HEADER_LIST_SUCCESS';
export const SET_PAGE_LIST_COLUMN_HEADER_FAILURE = 'redaction/SET_PAGE_LIST_COLUMN_HEADER_FAILURE';

export const SET_PAGE_LIST_TABLE_COLUMN = 'redaction/SET_PAGE_LIST_TABLE_COLUMN';

export const MODIFY_PAGE_LIST_COLUMN_HEADER_PENDING = 'redaction/MODIFY_PAGE_LIST_COLUMN_HEADER_PENDING';
export const MODIFY_PAGE_LIST_COLUMN_HEADER_SUCCESS = 'redaction/MODIFY_PAGE_LIST_COLUMN_HEADER_SUCCESS';
export const MODIFY_PAGE_LIST_COLUMN_HEADER_FAILURE = 'redaction/MODIFY_PAGE_LIST_COLUMN_HEADER_FAILURE';

export const RESET_PAGE_LIST_COLUMN_HEADER_PENDING = 'redaction/RESET_PAGE_LIST_COLUMN_HEADER_PENDING';
export const RESET_PAGE_LIST_COLUMN_HEADER_SUCCESS = 'redaction/RESET_PAGE_LIST_COLUMN_HEADER_SUCCESS';
export const RESET_PAGE_LIST_COLUMN_HEADER_FAILURE = 'redaction/RESET_PAGE_LIST_COLUMN_HEADER_FAILURE';

export const EDIT_DOCUMENT_FILE_NAME_PENDING = 'reduction/EDIT_DOCUMENT_FILE_NAME_PENDING';
export const EDIT_DOCUMENT_FILE_NAME_SUCCESS = 'reduction/EDIT_DOCUMENT_FILE_NAME_SUCCESS';
export const EDIT_DOCUMENT_FILE_NAME_FAILURE = 'reduction/EDIT_DOCUMENT_FILE_NAME_FAILURE';
export const UPDATE_CURRENT_DOCUMENT_EDITED_FILE_NAME = 'redaction/UPDATE_CURRENT_DOCUMENT_EDITED_FILE_NAME';

export const HAS_LINKED_ARTICLES_PENDING = 'redaction/HAS_LINKED_ARTICLES_PENDING';
export const HAS_LINKED_ARTICLES_SUCCESS = 'redaction/HAS_LINKED_ARTICLES_SUCCESS';
export const HAS_LINKED_ARTICLES_FAILURE = 'redaction/HAS_LINKED_ARTICLES_FAILURE';

export const APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_PENDING = 'redaction/APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_PENDING';
export const APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_SUCCESS = 'redaction/APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_SUCCESS';
export const APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_FAILURE = 'redaction/APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_FAILURE';
export const SET_FIRST_PAGE = 'redaction/SET_FIRST_PAGE';

export const GET_CURRENT_PAGE_COMMENTS_PENDING = 'redaction/GET_CURRENT_PAGE_COMMENTS_PENDING';
export const GET_CURRENT_PAGE_COMMENTS_SUCCESS = 'redaction/GET_CURRENT_PAGE_COMMENTS_SUCCESS';
export const GET_CURRENT_PAGE_COMMENTS_FAILURE = 'redaction/GET_CURRENT_PAGE_COMMENTS_FAILURE';

export const GET_CURRENT_PAGE_HYPER_LINKS_PENDING = 'redaction/GET_CURRENT_PAGE_HYPER_LINKS_PENDING';
export const GET_CURRENT_PAGE_HYPER_LINKS_SUCCESS = 'redaction/GET_CURRENT_PAGE_HYPER_LINKS_SUCCESS';
export const GET_CURRENT_PAGE_HYPER_LINKS_FAILURE = 'redaction/GET_CURRENT_PAGE_HYPER_LINKS_FAILURE';

export const SET_PAGE_COUNT_AFTER_DELETION = 'redaction/SET_PAGE_COUNT_AFTER_DELETION';

export const SET_SELECTED_COMMENT = 'redaction/SET_SELECTED_COMMENT';

export const SET_COMMENT_LOADING_FLAG = 'redaction/SET_COMMENT_LOADING_FLAG';

export const SET_GOTO_PAGE_PENDING = 'redaction/SET_GOTO_PAGE_PENDING';

export const SET_CURRENT_FIRST_PAGE_ID = 'redaction/SET_CURRENT_FIRST_PAGE_ID';
export const SET_CURRENT_LAST_PAGE_ID = 'redaction/SET_CURRENT_LAST_PAGE_ID';

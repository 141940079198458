import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { IFormProps } from './ModalEditPagination.model';
import { Scrollbars } from 'rc-scrollbars';
import './ModalEditPaginationForm.styles.scss';
import TextField from '@mui/material/TextField';
import { AVAILABLE_PAGINATION } from '../../../constants/messages.constants';

import { IRadio } from '../../../components/materialUiForms/marerialUiForms.model';

import { RadioMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { NUMBER_REG_EXP } from '../modalGlobalSettings/basicSettings/BasicSettings.constants';

const ModalEditPaginationForm = ({
    onSubmit,
    paginationAvailable,
    handleCloseModalWindow,
    updatingPagination,
    selectedPagesLength,
    labels,
    langRule,
}: IFormProps): JSX.Element => {

    const [selectedRange, setSelectedRange] = useState('0');

    const [startRange, setStartRange] = useState(paginationAvailable.length && paginationAvailable[0].from);

    const radioPaginationList = paginationAvailable.map((pagination: any, i: number): IRadio => ({
        value: `${i}`,
        label: `${pagination.from} - ${pagination.to}`,
    }));

    const endRange = startRange + selectedPagesLength - 1;

    const paginationFrom = paginationAvailable[Number(selectedRange)].from;

    const paginationTo = paginationAvailable[Number(selectedRange)].to;

    const isError = startRange < paginationFrom || startRange > (paginationTo - selectedPagesLength + 1);

    return (
        <React.Fragment>
                    <div className='edit-pagination-wrapper'>
                        <div className='form-body'>
                            <h5>{labels.availablePaginationNumberLabel[langRule]}:</h5>

                            <div className='form-body-form'>
                                <div className='radio-button'>
                                    <Scrollbars>
                                        <RadioMaterialBuilder
                                            value={selectedRange}
                                            row={false}
                                            handleRadioChange={(param: string, value: string): void => {
                                                setSelectedRange(value);
                                                setStartRange(paginationAvailable[Number(value)].from);
                                            }}
                                            paramName='paginationRange'
                                            ariaLabel={''}
                                            radioList={radioPaginationList}

                                        />
                                    </Scrollbars>
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        className='form-body-field'
                                        onChange={(e: React.ChangeEvent<Element>): void => {
                                            const { value } = (e.target as HTMLInputElement);

                                            if (value.match(NUMBER_REG_EXP)) {
                                                setStartRange(Number((e.target as HTMLInputElement).value));
                                            } else if (!value) {
                                                setStartRange(0);
                                            }
                                        }}
                                        label={labels.paginationStartLabel[langRule]}
                                        value={startRange.toString()}
                                        fullWidth={true}
                                        type='number'
                                        variant='outlined'
                                        error={isError}
                                        InputProps={{
                                            inputProps: {
                                                min: paginationFrom,
                                                max: paginationTo,
                                            },
                                        }}
                                    />
                                    <TextField
                                        className='form-body-field'
                                        label={labels.paginationEndLabel[langRule]}
                                        value={endRange.toString()}
                                        fullWidth={true}
                                        variant='outlined'
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-footer__action'>
                            <Button
                                size='small'
                                variant='outlined'
                                onClick={handleCloseModalWindow}
                                className='modal-window-buttons-style outlined'
                            >
                                {labels.cancelLabel[langRule]}
                            </Button>
                            <Button
                                size='small'
                                variant='contained'
                                color='primary'
                                onClick={(): void => onSubmit(startRange)}
                                className='modal-window-buttons-style primary'
                                disabled={isError || updatingPagination}
                            >
                                {labels.okLabel[langRule]}
                            </Button>
                        </div>
                    </div>
        </React.Fragment>
    );
};

export default ModalEditPaginationForm;

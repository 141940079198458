const resourceBundle = [
    {
        resourceType: 'UserPreferencesModal',
        resourceKey: 'USER_PREFERENCES_MODAL_TITLE',
        resourceValue: 'User Preferences',
        resourceValue2: 'Préférences de l\'utilisateur',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_TITLE',
        resourceValue: 'Document List',
        resourceValue2: 'Liste de documents',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_ALL_DOCUMENTS',
        resourceValue: 'All Documents',
        resourceValue2: 'Tous les documents',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_DOCUMENT_DETAIL',
        resourceValue: 'Document Detail',
        resourceValue2: 'Détail du document',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_DELETE_DOCUMENT',
        resourceValue: 'Delete Document',
        resourceValue2: 'Supprimer le document',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_EDIT_FILENAME',
        resourceValue: 'Edit Filename',
        resourceValue2: 'Modifier le nom du fichier',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_STACK',
        resourceValue: 'Stack',
        resourceValue2: 'Empiler',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_PAGINATION',
        resourceValue: 'Pagination',
        resourceValue2: 'Pagination',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_DISCLOSURE',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_STACK_ADD_REMOVE',
        resourceValue: 'Add/Remove from Stack',
        resourceValue2: 'Ajouter/Supprimer de la pile',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_STACK_MANAGEMENT',
        resourceValue: 'Stack Management',
        resourceValue2: 'Gestion de la pile',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_PAGINATION_SET',
        resourceValue: 'Set Pagination',
        resourceValue2: 'Définir la pagination',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_PAGINATION_EDIT',
        resourceValue: 'Edit Pagination',
        resourceValue2: 'Modifier la pagination',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_PAGINATION_UNDO',
        resourceValue: 'Undo Pagination',
        resourceValue2: 'Annuler la pagination',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_DISCLOSURE_SET',
        resourceValue: 'Set Disclosure',
        resourceValue2: 'Définir la divulgation',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_DISCLOSURE_REPLACE',
        resourceValue: 'Replace Disclosure',
        resourceValue2: 'Remplacer la divulgation',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_DISCLOSURE_UNDO',
        resourceValue: 'Undo Disclosure',
        resourceValue2: 'Annuler la divulgation',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_DELETE_MESSAGE',
        resourceValue: 'This document no longer contains any pages. Do you wish to delete the document? Press OK to continue. ',
        resourceValue2: 'Ce document ne contient plus de pages. Souhaitez-vous supprimer le document ? Appuyez sur OK pour continuer.',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_NAME_TITLE',
        resourceValue: 'Document List',
        resourceValue2: 'Liste de documents',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_NAME',
        resourceValue: 'Name',
        resourceValue2: 'Nom',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_FILE_NAME',
        resourceValue: 'File Name',
        resourceValue2: 'Nom de fichier',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_DISCLOSURE',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_OCR',
        resourceValue: 'OCR',
        resourceValue2: 'OCR',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_PAGES',
        resourceValue: 'Pages',
        resourceValue2: 'pages',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_STACKS',
        resourceValue: 'Stack',
        resourceValue2: 'Empiler',
    },    
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_START',
        resourceValue: 'Start',
        resourceValue2: 'Commencer',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_END',
        resourceValue: 'End',
        resourceValue2: 'Fin',
    }, 
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_DOCUMENT_DATE',
        resourceValue: 'Doc. Date',
        resourceValue2: 'Doc. Date',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_PRE_RELEASE_DATE',
        resourceValue: 'Pre-release Date',
        resourceValue2: 'Date de pré-sortie',
    }, 
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_RELEASE_DATE',
        resourceValue: 'Release Date',
        resourceValue2: 'Date de sortie',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_TO',
        resourceValue: 'To',
        resourceValue2: 'Pour',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_TO_ORG',
        resourceValue: 'To Org.',
        resourceValue2: 'À l\'Org.',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_FROM',
        resourceValue: 'From',
        resourceValue2: 'Depuis',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_FROM_ORG',
        resourceValue: 'From Org.',
        resourceValue2: 'De l\'Org.',
    },
    {
        resourceType: 'DocumentList',
        resourceKey: 'DOCUMENT_LIST_COLUMN_HEADER_SUBJECT',
        resourceValue: 'Subject',
        resourceValue2: 'Sujette',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_TITLE',
        resourceValue: 'Page List',
        resourceValue2: 'Liste des pages',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_RESET',
        resourceValue: 'Reset',
        resourceValue2: 'Réinitialiser',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_DELETE_PAGE',
        resourceValue: 'Delete Page',
        resourceValue2: 'Supprimer la page',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_DISCLOSURE_SET',
        resourceValue: 'Set Disclosure',
        resourceValue2: 'Définir la divulgation',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_DISCLOSURE_UNDO',
        resourceValue: 'Undo Disclosure',
        resourceValue2: 'Annuler la divulgation',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_EXPORT',
        resourceValue: 'Export',
        resourceValue2: 'Exporter',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_DUPLICATE_REFERENCES',
        resourceValue: 'Duplicate References',
        resourceValue2: 'Références en double',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_DELETE_PAGE_MESSAGE',
        resourceValue: 'This operation will permanently delete the page and all associated data. Press OK to continue.',
        resourceValue2: 'Cette opération supprimera définitivement la page et toutes les données associées. Appuyez sur OK pour continuer.',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_PAGE',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_PAGINATION',
        resourceValue: 'Pagination',
        resourceValue2: 'Pagination',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_OCR',
        resourceValue: 'OCR',
        resourceValue2: 'OCR',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_DISCLOSURE',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_ARTICLE',
        resourceValue: 'Article',
        resourceValue2: 'Article',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_DOC_DATE',
        resourceValue: 'Doc. Date',
        resourceValue2: 'Doc. Date',
    },
    
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_SUBJECT',
        resourceValue: 'Subject',
        resourceValue2: 'Sujette',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_TO',
        resourceValue: 'To',
        resourceValue2: 'Pour',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_TO_ORG',
        resourceValue: 'To Org.',
        resourceValue2: 'À l\'Org.',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_FROM',
        resourceValue: 'From',
        resourceValue2: 'Depuis',
    },
    {
        resourceType: 'PageList',
        resourceKey: 'PAGE_LIST_COLUMN_HEADER_FROM_ORG',
        resourceValue: 'From Org.',
        resourceValue2: 'De l\'Org.',
    },
    {
        resourceType: 'DuplicateReferencesModal',
        resourceKey: 'DUPLICATE_REFENRENCES_MODAL_TITLE',
        resourceValue: 'Duplicate References',
        resourceValue2: 'Références en double',
    },
    {
        resourceType: 'DuplicateReferencesModal',
        resourceKey: 'DUPLICATE_REFENRENCES_MODAL_DOCUMENT_DISCLOSURE',
        resourceValue: 'Document Disclosure:',
        resourceValue2: 'Divulgation de documents:',
    },
    {
        resourceType: 'DuplicateReferencesModal',
        resourceKey: 'DUPLICATE_REFENRENCES_MODAL_PAGE_DISCLOSURE',
        resourceValue: 'Page Disclosure:',
        resourceValue2: 'Divulgation des pages :',
    },
    {
        resourceType: 'DuplicateReferencesModal',
        resourceKey: 'DUPLICATE_REFENRENCES_MODAL_PAGES',
        resourceValue: 'Pages',
        resourceValue2: 'pages',
    },
    {
        resourceType: 'DuplicateReferencesModal',
        resourceKey: 'DUPLICATE_REFENRENCES_MODAL_DOCUMENT',
        resourceValue: 'Document',
        resourceValue2: 'Document',
    },
    {
        resourceType: 'DuplicateReferencesModal',
        resourceKey: 'DUPLICATE_REFENRENCES_MODAL_PAGE_NUMBER',
        resourceValue: 'Page Number',
        resourceValue2: 'Numéro de page',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_SNAP',
        resourceValue: 'Snap',
        resourceValue2: 'Instantané',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_SEVER',
        resourceValue: 'Sever',
        resourceValue2: 'Rompre',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_HIGHLIGHT',
        resourceValue: 'Highlight',
        resourceValue2: 'Souligner',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_USER',
        resourceValue: 'User',
        resourceValue2: 'utilisateur',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_ARTICLE',
        resourceValue: 'Article',
        resourceValue2: 'Article',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_AUTO',
        resourceValue: 'Auto',
        resourceValue2: 'Auto',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_RECENTLY_USED',
        resourceValue: 'Recently Used',
        resourceValue2: 'Utilisé récemment',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_ZOOM',
        resourceValue: 'Zoom',
        resourceValue2: 'Zoom',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_PAGE_WIDTH',
        resourceValue: 'Page Width',
        resourceValue2: 'Largeur de page',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_ACTUAL_PAGE_SIZE',
        resourceValue: 'Actual Page Size',
        resourceValue2: 'Taille réelle de la page',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_FULL_PAGE',
        resourceValue: 'Full Page',
        resourceValue2: 'Page entière',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_LEFT',
        resourceValue: 'Left',
        resourceValue2: 'La gauche',
    },
    {
        resourceType: 'Controls',
        resourceKey: 'CONTROLS_RIGHT',
        resourceValue: 'Right',
        resourceValue2: 'droite',
    },
    {
        resourceType: 'FileHeader',
        resourceKey: 'FILE_HEADER_TITLE',
        resourceValue: 'File',
        resourceValue2: 'Dossier',
    },
    {
        resourceType: 'FileHeader',
        resourceKey: 'FILE_HEADER_REQUEST_SEARCH',
        resourceValue: 'Request Search',
        resourceValue2: 'Demande de recherche',
    },
    {
        resourceType: 'FileHeader',
        resourceKey: 'FILE_HEADER_USER_PREFERENCES',
        resourceValue: 'User Preferences',
        resourceValue2: 'Préférences de l\'utilisateur',
    },
    {
        resourceType: 'ReplaceDisclosureModal',
        resourceKey: 'REPLACE_DISCLOSURE_MODAL_TITLE',
        resourceValue: 'Replace Disclosure',
        resourceValue2: 'Remplacer la divulgation',
    },
    {
        resourceType: 'ReplaceDisclosureModal',
        resourceKey: 'REPLACE_DISCLOSURE_MODAL_TO',
        resourceValue: 'To',
        resourceValue2: 'À',
    },
    {
        resourceType: 'ReplaceDisclosureModal',
        resourceKey: 'REPLACE_DISCLOSURE_MODAL_FROM',
        resourceValue: 'From',
        resourceValue2: 'De',
    },
    {
        resourceType: 'ReplaceDisclosureModal',
        resourceKey: 'REPLACE_DISCLOSURE_MODAL_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'ReplaceDisclosureModal',
        resourceKey: 'REPLACE_DISCLOSURE_MODAL_REPLACE',
        resourceValue: 'Replace',
        resourceValue2: 'Remplacer',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_TITLE',
        resourceValue: 'Action',
        resourceValue2: 'Action',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_IMPORT_DOCUMENT',
        resourceValue: 'Import Document',
        resourceValue2: 'Importer un document',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_EXPORT_DOCUMENT',
        resourceValue: 'Export Document',
        resourceValue2: 'Exporter le document',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_MANAGE_DUPLICATES',
        resourceValue: 'Manage Duplicates',
        resourceValue2: 'Gérer les doublons',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_REPORTS',
        resourceValue: 'Reports',
        resourceValue2: 'Rapports',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_REPORTS_INDEX_NOTES',
        resourceValue: 'Index Notes',
        resourceValue2: 'Notes d\'index',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_REPORTS_OFFICER_RESPORTS',
        resourceValue: 'Officer Report',
        resourceValue2: 'Rapport d\'officier',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_REPORTS_REQUESTER_REPORT',
        resourceValue: 'Requester Report',
        resourceValue2: 'Rapport du demandeur',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'ACTION_HEADER_REPORTS_PAGE_REVIEW_SUMMARY',
        resourceValue: 'Page Review Summary',
        resourceValue2: 'Résumé de l\'examen de la page',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_TITLE',
        resourceValue: 'Document',
        resourceValue2: 'Document',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_DELETE',
        resourceValue: 'Delete',
        resourceValue2: 'Effacer',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_EDIT_FILENAME',
        resourceValue: 'Edit Filename',
        resourceValue2: 'Modifier le nom du fichier',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_STACK',
        resourceValue: 'Stack',
        resourceValue2: 'Empiler',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_PAGINATION',
        resourceValue: 'Pagination',
        resourceValue2: 'Pagination',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_STACK_ADD_REMOVE',
        resourceValue: 'Add/Remove from Stack',
        resourceValue2: 'Ajouter/Supprimer de la pile',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_STACK_MANAGEMENT',
        resourceValue: 'Stack Management',
        resourceValue2: 'Gestion de la pile',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_PAGINATION_SET',
        resourceValue: 'Set Pagination',
        resourceValue2: 'Définir la pagination',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_PAGINATION_EDIT',
        resourceValue: 'Edit Pagination',
        resourceValue2: 'Modifier la pagination',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_PAGINATION_UNDO',
        resourceValue: 'Undo Pagination',
        resourceValue2: 'Annuler la pagination',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_DELETE_MESSAGE',
        resourceValue: 'This operation will permanently delete the document and all associated data?',
        resourceValue2: 'Cette opération supprimera définitivement le document et toutes les données associées?',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'MODAL_RELEASE_MANAGEMENT_TITLE',
        resourceValue: 'Release Management',
        resourceValue2: 'Gestion des versions',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'MODAL_RELEASE_MANAGEMENT_SELECTED_DOCS_TO',
        resourceValue: 'of all selected documents to',
        resourceValue2: 'de tous les documents sélectionnés à',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'MODAL_RELEASE_MANAGEMENT_ALL_SELECTED_DOCS',
        resourceValue: 'of all selected documents',
        resourceValue2: 'de tous les documents sélectionnés',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'MODAL_RELEASE_MANAGEMENT_PRE_RELEASE_DATE',
        resourceValue: 'Pre-Release Date',
        resourceValue2: 'Date de pré-sortie',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'MODAL_RELEASE_MANAGEMENT_RELEASE_DATE',
        resourceValue: 'Release Date',
        resourceValue2: 'Date de sortie',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'MODAL_AUTODESKEW_DOCUMENT_MESSAGE',
        resourceValue:
            'Document(s) have to be OCRed in order to apply Document Auto Descew. Proceed with OCR operation?',
        resourceValue2: 'Le(s) document(s) doivent être OCRed afin d\'appliquer Document Auto Descew. \
            Continuer avec l\'opération OCR?',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'MODAL_AUTO_ALIGN_ARTICLE_ALERT_MESSAGE',
        resourceValue: 'Re-align all article stamp positions based on current preference setting. This may take \
            several minutes for multiple or large documents. Continue?',
        resourceValue2: 'Réalignez toutes les positions des tampons d\'article en fonction du paramètre de préférence \
            actuel. Cela peut prendre plusieurs minutes pour des documents multiples ou volumineux. Continuez?',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_SELECT_ALL',
        resourceValue: 'Select All',
        resourceValue2: 'Tout sélectionner',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_SET_RELEASE_DATE',
        resourceValue: 'Set Release Date',
        resourceValue2: 'Définir la date de sortie',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_CLEAR_RELEASE_DATE',
        resourceValue: 'Clear Release Date',
        resourceValue2: 'Effacer la date de sortie',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_APPLY_OCR',
        resourceValue: 'Apply OCR',
        resourceValue2: 'Appliquer la ROC',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_APPLY_AUTO_DESKEW',
        resourceValue: 'Apply Auto Deskew',
        resourceValue2: 'Appliquer le désalignement automatique',
    },
    {
        resourceType: 'DocumentHeader',
        resourceKey: 'DOCUMENT_HEADER_AUTO_ALIGN_ARTICLE_STAMPS',
        resourceValue: 'Auto Align Article Stamps',
        resourceValue2: 'Alignement automatique des tampons d\'article',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_TITLE',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_ROTATE',
        resourceValue: 'Rotate',
        resourceValue2: 'Tourner',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_ROTATE_LEFT',
        resourceValue: 'Left',
        resourceValue2: 'La gauche',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_ROTATE_RIGHT',
        resourceValue: 'Right',
        resourceValue2: 'droite',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_MOVE',
        resourceValue: 'Move',
        resourceValue2: 'Déplacer',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_MOVE_UP',
        resourceValue: 'Up',
        resourceValue2: 'En haut',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_MOVE_DOWN',
        resourceValue: 'Down',
        resourceValue2: 'Vers le bas',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_REVERSE_ORDER',
        resourceValue: 'Reverse Order',
        resourceValue2: 'Ordre inverse',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_INSERT',
        resourceValue: 'Insert',
        resourceValue2: 'Insérer',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_GOTO',
        resourceValue: 'Go to',
        resourceValue2: 'Aller à',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_GOTO_FIRST',
        resourceValue: 'First',
        resourceValue2: 'Première',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_GOTO_LAST',
        resourceValue: 'Last',
        resourceValue2: 'Dernier',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_GOTO_PREVIOUS',
        resourceValue: 'Previous',
        resourceValue2: 'Précédent',
    },
    {
        resourceType: 'PageHeader',
        resourceKey: 'PAGE_HEADER_GOTO_NEXT',
        resourceValue: 'Next',
        resourceValue2: 'Suivant',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_TITLE',
        resourceValue: 'Admin',
        resourceValue2: 'Administrateur',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_PACKAGE_PRESETS',
        resourceValue: 'Package Presets',
        resourceValue2: 'Préréglages de package',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_GLOBAL_SETTINGS',
        resourceValue: 'Global Settings',
        resourceValue2: 'Paramètres globaux',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_AUDIT_LOG',
        resourceValue: 'Audit Log',
        resourceValue2: 'Journal d\'audit',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_LOCALIZATION',
        resourceValue: 'Redaction Localization',
        resourceValue2: 'Localisation de la rédaction',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_DISCLOSURE_MANAGEMENT',
        resourceValue: 'Disclosure Management',
        resourceValue2: 'Gestion de la divulgation',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_ANNOTATION_TYPE_MANAGEMENT',
        resourceValue: 'Annotation Type Management',
        resourceValue2: 'Gestion des types d\'annotations',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_USER_DEFINED_STAMP_MANAGEMENT',
        resourceValue: 'User Defined Stamp Management',
        resourceValue2: 'Gestion des tampons définis par l\'utilisateur',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_WATERMARK_MANAGEMENT',
        resourceValue: 'Watermark Management',
        resourceValue2: 'Gestion des filigranes',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_ASSOCIATE_WATERMARK_TO_REQUEST_TYPE',
        resourceValue: 'Associate Watermark to Request Type',
        resourceValue2: 'Associer le filigrane au type de demande',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_REQUEST_SECURITY',
        resourceValue: 'Request Security',
        resourceValue2: 'Demander la sécurité',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_ENCRYPTION',
        resourceValue: 'Encryption',
        resourceValue2: 'Chiffrement',
    },
    {
        resourceType: 'AdminHeader',
        resourceKey: 'ADMIN_HEADER_INDEX_KEYWORD_MANAGEMENT',
        resourceValue: 'Index Keyword Management',
        resourceValue2: 'Gestion des mots clés de l\'index',
    },
    {
        resourceType: 'UndoDisclosureModal',
        resourceKey: 'UNDO_DISCLOSURE_MODAL_TITLE',
        resourceValue: 'Undo Disclosure',
        resourceValue2: 'Gestion des mots clés de l\'index',
    },
    {
        resourceType: 'UndoDisclosureModal',
        resourceKey: 'UNDO_DISCLOSURE_MODAL_REMOVE_HIGHLIGHTS',
        resourceValue: 'Remove Highlights:',
        resourceValue2: 'Supprimer les surlignages:',
    },
    {
        resourceType: 'UndoDisclosureModal',
        resourceKey: 'UNDO_DISCLOSURE_MODAL_REMOVE_SERVERS',
        resourceValue: 'Remove Severs:',
        resourceValue2: 'Supprimer les serveurs :',
    },
    {
        resourceType: 'UndoDisclosureModal',
        resourceKey: 'UNDO_DISCLOSURE_MODAL_REMOVE_EXEMPTIONS',
        resourceValue: 'Remove Exemptions:',
        resourceValue2: 'Supprimer les exemptions :',
    },
    {
        resourceType: 'UndoDisclosureModal',
        resourceKey: 'UNDO_DISCLOSURE_MODAL_REMOVE_USER_DEFINED_STAMPS',
        resourceValue: 'Remove User Defined Stamps:',
        resourceValue2: 'Supprimer les tampons définis par l\'utilisateur :',
    },
    {
        resourceType: 'UndoDisclosureModal',
        resourceKey: 'UNDO_DISCLOSURE_MODAL_REMOVE_CONTACTS',
        resourceValue: 'Remove Contacts:',
        resourceValue2: 'Supprimer des contacts :',
    },
    {
        resourceType: 'HelpHeader',
        resourceKey: 'HELP_HEADER_TITLE',
        resourceValue: 'Help',
        resourceValue2: 'Aider',
    },
    {
        resourceType: 'HelpHeader',
        resourceKey: 'HELP_HEADER_ABOUT_REDACTION',
        resourceValue: 'About Redaction',
        resourceValue2: 'À propos de la rédaction',
    },
    {
        resourceType: 'HelpHeader',
        resourceKey: 'HELP_HEADER_KEYBOARD_SHORTCUTS',
        resourceValue: 'Keyboard Shortcuts',
        resourceValue2: 'Raccourcis clavier',
    },
    {
        resourceType: 'HelpHeader',
        resourceKey: 'HELP_HEADER_REDACTION_USER_GUIDE',
        resourceValue: 'Redaction User Guide',
        resourceValue2: 'Guide de l’utilisateur de caviardage',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_TITLE',
        resourceValue: 'Search For Request',
        resourceValue2: 'Rechercher une demande',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_REQUEST_TYPE',
        resourceValue: 'Request Type',
        resourceValue2: 'Type de demande',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_REQUEST_NUMBER',
        resourceValue: 'Request Number',
        resourceValue2: 'Numéro de demande',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_FOLDER_RSN',
        resourceValue: 'FolderRSN',
        resourceValue2: 'DossierRSN',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_OFFICER',
        resourceValue: 'Officer',
        resourceValue2: 'Officier',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_EXACT_MATCH',
        resourceValue: 'Exact Match',
        resourceValue2: 'Correspondance exacte',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DELETE_DOCUMENT_PER_PAGE_MESSAGE',
        resourceValue: 'This document no longer contains any pages. Do you wish to delete the document? Press OK to continue.',
        resourceValue2: 'Ce document ne contient plus aucune page. Souhaitez-vous supprimer le document? Appuyez sur OK pour continuer.',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DELETE_PAGE_MESSAGE',
        resourceValue: 'This operation will permanently delete the page and all associated data. Press OK to continue.',
        resourceValue2: 'Cette opération supprimera définitivement la page et toutes les données associées. Appuyez sur OK pour continuer.',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DELETE_PAGE_TITLE',
        resourceValue: 'Delete Page',
        resourceValue2: 'Supprimer la page',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_CHANGE_DISCLOSURE_WARNING',
        resourceValue: 'Selected documents already have disclosure set for all pages. Please use Replace Disclosure or Undo Disclosure first to reset specific pages back the default disclosure',
        resourceValue2: 'Les documents sélectionnés ont déjà une divulgation définie pour toutes les pages. Veuillez utiliser Remplacer la divulgation ou Annuler la divulgation d’abord pour réinitialiser des pages spécifiques à la divulgation par défaut',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_PAGINATION_RECOMMENDED',
        resourceValue: 'Pagination Recommended',
        resourceValue2: 'Pagination recommandée',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_PAGINATION_RECOMMENDED_MESSAGE',
        resourceValue: 'This document was previously paginated. It is recommended to repaginate to avoid pagination gaps. Do you wish to paginate now?',
        resourceValue2: 'Ce document a été précédemment paginé. \
        Il est recommandé de repaginer pour éviter les lacunes de pagination. Voulez-vous paginer maintenant?',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SEVERAL_PAGES_SELECTED_WARNING',
        resourceValue: 'Please select only one page for this operation',
        resourceValue2: 'Veuillez sélectionner une seule page pour cette opération',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SORTING_NOT_APPLICABLE_ON_MOVE_WARNING',
        resourceValue: 'Please set the sort order to Page # before using move up/down pages.',
        resourceValue2: 'Veuillez définir l’ordre de tri sur Page # avant d’utiliser déplacer les pages vers le haut / vers le bas.',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DISCLOSURE_WARNING',
        resourceValue: 'Default disclosure is not set for this request type. Please contact your administrator.',
        resourceValue2: 'La divulgation par défaut n’est pas définie pour ce type de demande. Veuillez contacter votre administrateur.',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_AUTO_DESKEW_PAGE_MESSAGE',
        resourceValue: 'Page(s) have to be OCRed in order to apply Page Auto Descew. Proceed with OCR operation?',
        resourceValue2: 'Les pages doivent être OCRed afin d’appliquer la page Auto Descew. Procéder à l’opération OCR?',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_AUTO_ALIGN_ARTICLE_STAMPS_PAGE_ALERT',
        resourceValue: 'Re-align all article stamp positions based on current preference setting. \
        This may take several minutes for large number of pages. Continue?',
        resourceValue2: 'Réaligner toutes les positions de tampon d’article en fonction du paramètre de préférence actuel. Cela peut prendre plusieurs minutes pour un grand nombre de pages. Continuer?',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DELETE_COMMENT_TITLE',
        resourceValue: 'Delete Comment',
        resourceValue2: 'Supprimer le commentaire',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DELETE_COMMENT_MESSAGE',
        resourceValue: 'Are you sure want to permanently remove this comment and its replies ? Press OK to continue.',
        resourceValue2: 'Êtes-vous sûr de vouloir supprimer définitivement ce commentaire et ses réponses ? Appuyez sur OK pour continuer.',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_STATE_ALL',
        resourceValue: 'All',
        resourceValue2: 'Tout',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_STATE_ACTIVE',
        resourceValue: 'Active',
        resourceValue2: 'Actif',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_STATE_CLOSED',
        resourceValue: 'Closed',
        resourceValue2: 'Fermé',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_CLEAR',
        resourceValue: 'Clear',
        resourceValue2: 'Dégager',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SEARCH',
        resourceValue: 'Search',
        resourceValue2: 'Chercher',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_SEARCH_RESULT',
        resourceValue: 'Search Result',
        resourceValue2: 'Résultat de la recherche',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_COLUMNHEADER_SUMMARY',
        resourceValue: 'Summary',
        resourceValue2: 'Résumé',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_COLUMNHEADER_DUE_DATA',
        resourceValue: 'Due Date',
        resourceValue2: 'Date d\'échéance',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_CLOSE',
        resourceValue: 'Close',
        resourceValue2: 'Fermer',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_OPEN',
        resourceValue: 'Open',
        resourceValue2: 'Ouvrir',
    },
    {
        resourceType: 'SearchRequestModal',
        resourceKey: 'SEARCH_REQUEST_MODAL_OPEN_NEW_TAB',
        resourceValue: 'Open New Tab',
        resourceValue2: 'Ouvrir un nouvel onglet',
    },
    {
        resourceType: 'UserPreferencesModal',
        resourceKey: 'USER_PREFERENCES_MODAL_ARTICLE_STAMP_AUTO_ALIGN',
        resourceValue: 'Article Stamp Auto Align',
        resourceValue2: 'Alignement automatique du timbre d\'article',
    },
    {
        resourceType: 'UserPreferencesModal',
        resourceKey: 'USER_PREFERENCES_MODAL_LEFT_ALIGN',
        resourceValue: 'Left Align',
        resourceValue2: 'Aligner à gauche',
    },
    {
        resourceType: 'UserPreferencesModal',
        resourceKey: 'USER_PREFERENCES_MODAL_RIGHT_ALIGN',
        resourceValue: 'Right Align',
        resourceValue2: 'Aligner à droite',
    },
    {
        resourceType: 'UserPreferencesModal',
        resourceKey: 'USER_PREFERENCES_MODAL_NONE',
        resourceValue: 'None',
        resourceValue2: 'Aucun',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_LABEL_APPLY',
        resourceValue: 'Apply',
        resourceValue2: 'Présenter une demande',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_FULL_ACCESS',
        resourceValue: 'Full Access',
        resourceValue2: 'Accès complet',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_READ_ONLY_ALL',
        resourceValue: 'Read Only(All)',
        resourceValue2: 'Lecture seule(tous)',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_DENY_ACCESS',
        resourceValue: 'Deny Access',
        resourceValue2: 'Refuser l’accès',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_READ_ONLY_RELEASABLE',
        resourceValue: 'Read Only(Releasable)',
        resourceValue2: 'Lecture seule(libérable)',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_LABEL_REQUEST',
        resourceValue: 'Request',
        resourceValue2: 'Demande',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_LABEL_CLOSE',
        resourceValue: 'Close',
        resourceValue2: 'Fermer',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_PERMISSIONS',
        resourceValue: 'Permissions',
        resourceValue2: 'Autorisations',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_MODAL_NAME',
        resourceValue: 'Name',
        resourceValue2: 'Nom',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_MODAL_REQUEST_TYPE',
        resourceValue: 'Request Type',
        resourceValue2: 'Type de demande',
    },
    {
        resourceType: 'RequestSecurityModal',
        resourceKey: 'REQUESTSECURITY_MODAL_TITLE',
        resourceValue: 'Request Security',
        resourceValue2: 'Demander la sécurité',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'TITLE_GLOBAL_SETTINGS',
        resourceValue: 'Global Settings',
        resourceValue2: 'Paramètres globaux',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'BASIC_SETTINGS_LABEL',
        resourceValue: 'Basic Settings',
        resourceValue2: 'Paramètres de base',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'DISCLOSURE_SETTINGS_LABEL',
        resourceValue: 'Disclosure Settings',
        resourceValue2: 'Paramètres de divulgation',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'EXEMPTION_SETTINGS_LABEL',
        resourceValue: 'Exemption Settings',
        resourceValue2: 'Paramètres d’exemption',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_SAVE',
        resourceValue: 'Save',
        resourceValue2: 'sauvegarder',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_FOLDER_TYPE',
        resourceValue: 'Folder Type',
        resourceValue2: 'Type de dossier',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_DEFAULT_DISCLOSURE',
        resourceValue: 'Default Disclosure',
        resourceValue2: 'Divulgation par défaut',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_SEVER_APPLIED',
        resourceValue: 'Sever Applied',
        resourceValue2: 'Sever appliqué',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_HIGHLIGHT_APPLIED',
        resourceValue: 'Highlight Applied',
        resourceValue2: 'Mettre en surbrillance appliquée',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_EXEMPTION_APPLIED',
        resourceValue: 'Exemption Applied',
        resourceValue2: 'Exemption appliquée',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_USERSTAMPAPPLIED_APPLIED',
        resourceValue: 'User Stamp Applied',
        resourceValue2: 'Cachet d’utilisateur appliqué',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_DISABLEAUDITLOG',
        resourceValue: 'Disable Audit Logging',
        resourceValue2: 'Désactiver la journalisation d’audit',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_MAXIMUM',
        resourceValue: 'Maximum Number of Days of Audit Log History',
        resourceValue2: 'Nombre maximal de jours d’historique du journal d’audit',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_PREFIX',
        resourceValue: 'Prefix',
        resourceValue2: 'Préfixe',
    },
    {
        resourceType: 'GlobalSettingsModal',
        resourceKey: 'GLOBALSETTINGS_SUFFIX',
        resourceValue: 'Suffix',
        resourceValue2: 'Suffixe',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_OK',
        resourceValue: 'Ok',
        resourceValue2: 'Ok',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_YES',
        resourceValue: 'Yes',
        resourceValue2: 'Oui',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_NO',
        resourceValue: 'No',
        resourceValue2: 'Non',
    },
    {
        resourceType: 'AboutRedactionModal',
        resourceKey: 'ABOUT_REDACTION_MODAL_TITLE',
        resourceValue: 'About Redaction',
        resourceValue2: 'À propos de la rédaction',
    },
    {
        resourceType: 'KeyboardShortcutsModal',
        resourceKey: 'KEYBOARD_SHORTCUTS_MODAL_TITLE',
        resourceValue: 'Keyboard Shortcuts',
        resourceValue2: 'Raccourcis clavier',
    },
    {
        resourceType: 'KeyboardShortcutsModal',
        resourceKey: 'KEYBOARD_SHORTCUTS_MODAL_KEYS',
        resourceValue: 'Keys',
        resourceValue2: 'Clés',
    },
    {
        resourceType: 'KeyboardShortcutsModal',
        resourceKey: 'KEYBOARD_SHORTCUTS_MODAL_DESCRIPTION',
        resourceValue: 'Description',
        resourceValue2: 'La description',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_TITLE',
        resourceValue: 'Encryption',
        resourceValue2: 'Chiffrement',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_ACTIVE',
        resourceValue: 'Active',
        resourceValue2: 'Actif',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_NAME',
        resourceValue: 'Name',
        resourceValue2: 'Nom',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_METHOD',
        resourceValue: 'Method',
        resourceValue2: 'Méthode',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_KEYSTORE',
        resourceValue: 'Keystore',
        resourceValue2: 'Magasin de clés',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_KEYSTORE_VALUE',
        resourceValue: 'Database',
        resourceValue2: 'Base de données',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_SAVE',
        resourceValue: 'Save',
        resourceValue2: 'sauvegarder',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'COMMON_LABEL_SELECT_ENCRYPTION',
        resourceValue: 'Select Encryption',
        resourceValue2: 'Sélectionnez Chiffrement',
    },
    {
        resourceType: 'EncryptionModal',
        resourceKey: 'ENCRYPTION_MODAL_MUST_BE_ACTIVE',
        resourceValue: 'At least one encryption must be active',
        resourceValue2: 'Au moins un chiffrement doit être actif',
    },
    {
        resourceType: 'AssociateWatermarkToRequestModal',
        resourceKey: 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_TITLE',
        resourceValue: 'Associate Watermark to Request Type',
        resourceValue2: 'Associer le filigrane au type de demande',
    },
    {
        resourceType: 'AssociateWatermarkToRequestModal',
        resourceKey: 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_REQUEST_TYPE',
        resourceValue: 'Request Type',
        resourceValue2: 'type de demande',
    },
    {
        resourceType: 'AssociateWatermarkToRequestModal',
        resourceKey: 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_WATERMARK',
        resourceValue: 'Watermark',
        resourceValue2: 'Filigrane',
    },
    {
        resourceType: 'AssociateWatermarkToRequestModal',
        resourceKey: 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'AssociateWatermarkToRequestModal',
        resourceKey: 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_OK',
        resourceValue: 'Ok',
        resourceValue2: 'D\'accord',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_TITLE',
        resourceValue: 'Watermark Management',
        resourceValue2: 'Gestion des filigranes',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_SELECT_WATERMARK',
        resourceValue: 'Select Watermark',
        resourceValue2: 'Sélectionnez le filigrane',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_SELECT_IMAGE',
        resourceValue: 'Select Image',
        resourceValue2: 'Sélectionner l’image',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_NAME',
        resourceValue: 'Name',
        resourceValue2: 'Nom',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_TYPE',
        resourceValue: 'Type of watermark',
        resourceValue2: 'Type de filigrane',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_TEXT',
        resourceValue: 'Text',
        resourceValue2: 'Texte',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_IMAGE',
        resourceValue: 'Image',
        resourceValue2: 'Image',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_TEXT',
        resourceValue: 'Text',
        resourceValue2: 'Texte',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_WATERMARK_TEXT',
        resourceValue: 'Watermark Text',
        resourceValue2: 'Texte du filigrane',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_SELECT_IMAGE',
        resourceValue: 'Select image',
        resourceValue2: 'Sélectionner une image',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_CLEAR_IMAGE',
        resourceValue: 'Clear image',
        resourceValue2: 'Image claire',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_SAVE',
        resourceValue: 'Save',
        resourceValue2: 'sauvegarder',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_LABEL_ADD_NEW',
        resourceValue: 'Add New',
        resourceValue2: 'Ajouter nouveau',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_DELETE_WATERMARK_MESSAGE',
        resourceValue: 'Delete watermark? Press OK to continue.',
        resourceValue2: 'Supprimer le filigrane? Appuyez sur OK pour continuer.',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_UNSUPPORTED_IMAGE_MESSAGE',
        resourceValue: 'The following file selected is not permitted for import and will be automatically removed from the Watermark management.',
        resourceValue2: 'Le fichier suivant sélectionné n’est pas autorisé pour l’importation et seraêtre automatiquement supprimé de la gestion du filigrane.',
    },
    {
        resourceType: 'WatermarkManagementModal',
        resourceKey: 'WATERMARK_MANAGEMENT_MODAL_ALERT_DELETE_WATERMARK_MANAGEMENT',
        resourceValue: 'Watermark is in use. Are you sure you want to remove the watermark?',
        resourceValue2: 'Le filigrane est utilisé. Êtes-vous sûr de vouloir supprimer le filigrane?',
    },
    {
        resourceType: 'UserDefinedStampManagementModal',
        resourceKey: 'USER_DEFINED_STAMP_MANAGEMENT_MODAL_TITLE',
        resourceValue: 'Manage User Defined Stamps',
        resourceValue2: 'Gérer les tampons définis par l\'utilisateur',
    },
    {
        resourceType: 'UserDefinedStampManagementModal',
        resourceKey: 'USER_DEFINED_STAMP_MANAGEMENT_MODAL_TEXT',
        resourceValue: 'Text',
        resourceValue2: 'Texte',
    },
    {
        resourceType: 'UserDefinedStampManagementModal',
        resourceKey: 'USER_DEFINED_STAMP_MANAGEMENT_MODAL_DESCRIPTION',
        resourceValue: 'Description',
        resourceValue2: 'La description',
    },
    {
        resourceType: 'UserDefinedStampManagementModal',
        resourceKey: 'USER_DEFINED_STAMP_MANAGEMENT_MODAL_ACTIVE',
        resourceValue: 'Active',
        resourceValue2: 'Actif',
    },
    {
        resourceType: 'AnnotationTypeManagementModal',
        resourceKey: 'ANNOTATION_TYPE_MANAGEMENT_MODAL_TITLE',
        resourceValue: 'Manage Annotation Types',
        resourceValue2: 'Gérer les types d\'annotations',
    },
    {
        resourceType: 'AnnotationTypeManagementModal',
        resourceKey: 'ANNOTATION_TYPE_MANAGEMENT_MODAL_NAME',
        resourceValue: 'Name',
        resourceValue2: 'Texte',
    },
    {
        resourceType: 'AnnotationTypeManagementModal',
        resourceKey: 'ANNOTATION_TYPE_MANAGEMENT_MODAL_DESCRIPTION',
        resourceValue: 'Description',
        resourceValue2: 'La description',
    },
    {
        resourceType: 'AnnotationTypeManagementModal',
        resourceKey: 'ANNOTATION_TYPE_MANAGEMENT_MODAL_ACTIVE',
        resourceValue: 'Active',
        resourceValue2: 'Actif',
    },
    {
        resourceType: 'AnnotationTypeManagementModal',
        resourceKey: 'ANNOTATION_TYPE_MANAGEMENT_MODAL_IS_SEVER',
        resourceValue: 'Is Sever',
        resourceValue2: 'est sévère',
    },
    {
        resourceType: 'AnnotationTypeManagementModal',
        resourceKey: 'ANNOTATION_TYPE_MANAGEMENT_MODAL_SAVE_SEVERED_TEXT',
        resourceValue: 'Save Severed Text',
        resourceValue2: 'Enregistrer le texte coupé',
    },
    {
        resourceType: 'AnnotationTypeManagementModal',
        resourceKey: 'ANNOTATION_TYPE_MANAGEMENT_MODAL_BORDER_ONLY',
        resourceValue: 'Border Only',
        resourceValue2: 'Bordure seulement',
    },
    {
        resourceType: 'AnnotationTypeManagementModal',
        resourceKey: 'ANNOTATION_TYPE_MANAGEMENT_MODAL_COLOR',
        resourceValue: 'Color',
        resourceValue2: 'Couleur',
    },
    {
        resourceType: 'DocumentQueue',
        resourceKey: 'DOCUMENTQUEUE_TITLE',
        resourceValue: 'DOCUMENT QUEUE',
        resourceValue2: 'FILE D\'ATTENTE DE DOCUMENTS',
    },
    {
        resourceType: 'DocumentQueue',
        resourceKey: 'DOCUMENTQUEUE_COLUMN_HEADER_FILENAME',
        resourceValue: 'Filename',
        resourceValue2: 'Nom de fichier',
    },
    {
        resourceType: 'DocumentQueue',
        resourceKey: 'DOCUMENTQUEUE_COLUMN_HEADER_STATUS',
        resourceValue: 'Status',
        resourceValue2: 'Statut',
    },
    {
        resourceType: 'DocumentQueue',
        resourceKey: 'DOCUMENTQUEUE_COLUMN_HEADER_DETAIL',
        resourceValue: 'Details',
        resourceValue2: 'Détails',
    },
    {
        resourceType: 'DocumentQueue',
        resourceKey: 'DOCUMENTQUEUE_SHOW_FINISHED_TASKS',
        resourceValue: 'Display finished tasks',
        resourceValue2: 'Afficher les tâches terminées',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_TITLE',
        resourceValue: 'ADVANCED SEARCH',
        resourceValue2: 'RECHERCHE AVANCÉE',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_SEARCH_SCOPE',
        resourceValue: 'Search Scope',
        resourceValue2: 'Portée de la recherche',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_CURRENT_REQUEST',
        resourceValue: 'Current Request',
        resourceValue2: 'Demande courante',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_ALL_REQUESTS',
        resourceValue: 'All Requests',
        resourceValue2: 'Toutes les demandes',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_SPECIFIC_REQUEST',
        resourceValue: 'Specific Request',
        resourceValue2: 'Demande spécifique',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_FOLDER_RSN',
        resourceValue: 'Folder RSN',
        resourceValue2: 'Dossier RSN',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_REQUEST_TYPE',
        resourceValue: 'Request Type',
        resourceValue2: 'Type de demande',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_REQUEST_NUMBER',
        resourceValue: 'Request Number',
        resourceValue2: 'Numéro de demande',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_LANGUAGE',
        resourceValue: 'Language',
        resourceValue2: 'Langue',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_CLASSIFICATION',
        resourceValue: 'Classification',
        resourceValue2: 'Classification',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_REFERENCE_NUMBER',
        resourceValue: 'Reference Number',
        resourceValue2: 'Numéro de réference',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_DOCUMENT_TYPE',
        resourceValue: 'Document Type',
        resourceValue2: 'Type de document',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_TO_ORG',
        resourceValue: 'To Org.',
        resourceValue2: 'À l\'Org.',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_FROM_ORG',
        resourceValue: 'From Org.',
        resourceValue2: 'De l\'Org.',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_TO',
        resourceValue: 'To',
        resourceValue2: 'À',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_FROM',
        resourceValue: 'From',
        resourceValue2: 'De',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_NOTES',
        resourceValue: 'Notes',
        resourceValue2: 'Notes',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_SUBJECT',
        resourceValue: 'Subject',
        resourceValue2: 'Sujet',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_FIELD1',
        resourceValue: 'Field 1',
        resourceValue2: 'Champ 1',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_DOC_DATE',
        resourceValue: 'Doc. Date',
        resourceValue2: 'Doc. Date',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_DATE_FROM',
        resourceValue: 'From',
        resourceValue2: 'De',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_DATE_TO',
        resourceValue: 'To',
        resourceValue2: 'À',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_DOC_NAME',
        resourceValue: 'Doc. Name',
        resourceValue2: 'Doc. Nom',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_FILENAME',
        resourceValue: 'Filename',
        resourceValue2: 'Nom de fichier',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_PAGINATION_NUMBER',
        resourceValue: 'Pagination Number',
        resourceValue2: 'Numéro de pagination',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_ACT',
        resourceValue: 'Act',
        resourceValue2: 'Loi',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_EXEMPTIONS_EXCLUSIONS',
        resourceValue: 'Exemptions / Exclusions Applied',
        resourceValue2: 'Exemptions / Exclusions appliquées',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_USER_DEFINED_STAMPS',
        resourceValue: 'User Defined Stamps Applied',
        resourceValue2: 'Timbres définis par l\'utilisateur appliqués',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_ANNOTATIONS',
        resourceValue: 'Annotations Applied',
        resourceValue2: 'Annotations appliquées',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_DISCLOSURE_NAME',
        resourceValue: 'Disclosure Name',
        resourceValue2: 'Nom de divulgation',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_CLEAR',
        resourceValue: 'Clear',
        resourceValue2: 'Dégager',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMONSEARCH_GO_TO_PREVIOUS',
        resourceValue: 'Go to previous page result',
        resourceValue2: 'Aller au résultat de la page précédente',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMONSEARCH_GO_TO_NEXT',
        resourceValue: 'Go to next page result',
        resourceValue2: 'Aller au résultat de la page suivante',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_RESULTS_TITLE',
        resourceValue: 'Advanced search results',
        resourceValue2: 'Résultats de la recherche avancée',
    },
    {
        resourceType: 'AdvancedSearch',
        resourceKey: 'ADVANCEDSEARCH_MAX_LENGTH_ERROR',
        resourceValue: 'Max length 255',
        resourceValue2: 'Longueur maxi 255',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_NOTES_TITLE',
        resourceValue: 'NOTES',
        resourceValue2: 'REMARQUES',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_CONSULTEES_TITLE',
        resourceValue: 'CONSULTEES',
        resourceValue2: 'Consultés',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_COMMENTS_TITLE',
        resourceValue: 'COMMENTS',
        resourceValue2: 'COMMENTAIRES',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_RECOMMENDATIONS_TITLE',
        resourceValue: 'RECOMMENDATIONS',
        resourceValue2: 'RECOMMENDATIONS',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_PDF_COMMENTS_TITLE',
        resourceValue: 'Pdf Comments',
        resourceValue2: 'Commentaires PDF',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_NO_COMMENTS_MSG',
        resourceValue: 'No comments found',
        resourceValue2: 'Aucun commentaire trouvé',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_TIMESTAMP',
        resourceValue: 'Timestamp',
        resourceValue2: 'Horodatage',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_DOCUMENT_INDEX_TITLE',
        resourceValue: 'DOCUMENT INDEX',
        resourceValue2: 'INDEX DES DOCUMENTS',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_PAGE_INDEX_TITLE',
        resourceValue: 'PAGE INDEX',
        resourceValue2: 'INDEX DES PAGES',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_Filename',
        resourceValue: 'Filename',
        resourceValue2: 'Nom de fichier',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_KEYWORD_INDEX',
        resourceValue: 'Keyword Index',
        resourceValue2: 'Index des mots-clés',
    },
    {
        resourceType: 'MetaDataTab',
        resourceKey: 'METADATA_TAB_ERROR',
        resourceValue: 'The number of symbols exceeds 1000. The text will be truncated upon export.',
        resourceValue2: 'Le nombre de symboles dépasse 1000. Le texte sera tronqué à l\'exportation.',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SAVE',
        resourceValue: 'Save',
        resourceValue2: 'Sauvegarder',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_TITLE',
        resourceValue: 'Search',
        resourceValue2: 'Chercher',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_SEARCH_TEXT',
        resourceValue: 'Search page text',
        resourceValue2: 'Rechercher texte de la page',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_ALL_DOCUMENTS_CURRENT_STACK',
        resourceValue: 'Search All Documents under the current stack',
        resourceValue2: 'Rechercher tous les documents sous la pile actuelle',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_PREVIOUS',
        resourceValue: 'Previous',
        resourceValue2: 'Précédent',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_NEXT',
        resourceValue: 'Next',
        resourceValue2: 'Suivant',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_SEVER_TYPE',
        resourceValue: 'Sever Type',
        resourceValue2: 'Type de prélèvement',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_HIGHLIGHT_TYPE',
        resourceValue: 'Highlight Type',
        resourceValue2: 'Type de surlignage',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_APPLY_ONCE',
        resourceValue: 'Apply Once',
        resourceValue2: 'Appliquer une fois',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_APPLY_ALL',
        resourceValue: 'Apply All',
        resourceValue2: 'Appliquer tout',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_RESULT_TITLE',
        resourceValue: 'Search Results',
        resourceValue2: 'Résultats de recherche',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_SELECT_DOCUMENT_ERROR',
        resourceValue: 'Select which document to search or enable ‘Search All Documents under the current stack’.',
        resourceValue2: 'Sélectionnez le document à rechercher ou activez ‘Rechercher tous les documents sous la pile actuelle’.',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_EMPTY_SEARCH_ERROR',
        resourceValue: 'Please enter document text to search for',
        resourceValue2: 'Veuillez saisir le texte du document à rechercher',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE',
        resourceValue: 'No matching results',
        resourceValue2: 'Aucun résultat correspondant',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_NO_MATCH_RESULTS_TO_APPLY',
        resourceValue: 'No matching results to apply annotation to. Please refine your Search \
            page text and click the search button again.',
        resourceValue2: 'Aucun résultat correspondant pour appliquer l\'annotation à. Veuillez \
            affiner le texte de votre page de recherche et cliquer à nouveau sur le bouton de recherche.',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_NO_NEXT_RESULT',
        resourceValue: 'There are no additional matching search results.',
        resourceValue2: 'Il n\'y a pas de résultats de recherche correspondants supplémentaires.',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_NO_PREVIOUS_RESULT',
        resourceValue: 'There are no previous matching search results.',
        resourceValue2: 'Il n\'y a pas de résultats de recherche correspondants précédents.',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_NO_MATCH_RESULTS_TO_APPLY_ALL',
        resourceValue: 'Please select any document from your search results before clicking Apply All.',
        resourceValue2: 'Veuillez sélectionner un document dans les résultats de votre recherche avant \
            de cliquer sur Appliquer tout.',
    },
    {
        resourceType: 'SimpleSearch',
        resourceKey: 'SIMPLESEARCH_DOCUMENT_NOT_IN_CURRENT_STACK',
        resourceValue: 'This Document/Page is not visible in the current stack.Would you like to \
            switch to All Documents View?',
        resourceValue2: 'Ce document/page n\'est pas visible dans la pile actuelle. Souhaitez-vous \
            basculer vers la vue Tous les Documents?',
    },
    {
        resourceType: 'IndexKeywordManagementModal',
        resourceKey: 'INDEX_KEYWORD_MANAGEMENT_MODAL_TITLE',
        resourceValue: 'Index Keyword Management',
        resourceValue2: 'Gestion des mots clés de l\'index',
    },
    {
        resourceType: 'IndexKeywordManagementModal',
        resourceKey: 'INDEX_KEYWORD_MANAGEMENT_MODAL_DESCRIPTION',
        resourceValue: 'Description',
        resourceValue2: 'La description',
    },
    {
        resourceType: 'IndexKeywordManagementModal',
        resourceKey: 'INDEX_KEYWORD_MANAGEMENT_MODAL_DESCRIPTION_FR',
        resourceValue: 'Description fr',
        resourceValue2: 'La description en',
    },
    {
        resourceType: 'IndexKeywordManagementModal',
        resourceKey: 'INDEX_KEYWORD_MANAGEMENT_MODAL_OCCURENCES',
        resourceValue: 'Occurences',
        resourceValue2: 'Occurences',
    },
    {
        resourceType: 'IndexKeywordManagementModal',
        resourceKey: 'INDEX_KEYWORD_MANAGEMENT_MODAL_REPLACE',
        resourceValue: 'Replace',
        resourceValue2: 'Remplacer',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DELETE',
        resourceValue: 'Delete',
        resourceValue2: 'Effacer',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_ADD_NEW',
        resourceValue: 'Add New',
        resourceValue2: 'Ajouter nouveau',
    },
    {
        resourceType: 'IndexKeywordManagementModal',
        resourceKey: 'INDEX_KEYWORD_MANAGEMENT_MODAL_REFRESH',
        resourceValue: 'Refresh',
        resourceValue2: 'Rafraîchir',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_EXPORT',
        resourceValue: 'Export',
        resourceValue2: 'Exporter',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_BACK',
        resourceValue: 'Back',
        resourceValue2: 'Retour',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_EXPORT_SUCCESS',
        resourceValue: 'Exported successfully',
        resourceValue2: 'Exporté avec succès',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_EXPORT_ABORT',
        resourceValue: 'Export Aborted',
        resourceValue2: 'Exportation abandonnée',
    },
    {
        resourceType: 'IndexKeywordManagementModal',
        resourceKey: 'INDEX_KEYWORD_MANAGEMENT_MODAL_CHOOSE_REPLACE',
        resourceValue: 'Choose the Replacing Keyword',
        resourceValue2: 'Choisissez le mot-clé de remplacement',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_TITLE',
        resourceValue: 'CONTENT OPTIONS',
        resourceValue2: 'OPTIONS DE CONTENU',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_EXPORT_AS',
        resourceValue: 'Export As',
        resourceValue2: 'Exporter sous',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_ORIGINALS_ONLY',
        resourceValue: 'Originals Only',
        resourceValue2: 'Originaux uniquement',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_PRESET_SETTINGS',
        resourceValue: 'Preset Settings',
        resourceValue2: 'Paramètres prédéfinis',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_SELECT_PRESET',
        resourceValue: 'Select Preset',
        resourceValue2: 'Sélectionnez le préréglage',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_DOCUMENT_ORDER',
        resourceValue: 'Document Order',
        resourceValue2: 'Commande de documents',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_ORDER_BY_PAGINATION',
        resourceValue: 'By Pagination',
        resourceValue2: 'Par pagination',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_ORDER_BY_DOC_NAME',
        resourceValue: 'By Document Name',
        resourceValue2: 'Par nom de document',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_ORDER_BY_DOC_DATE',
        resourceValue: 'By Document Date',
        resourceValue2: 'Par date de document',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_SEVER_COLOR',
        resourceValue: 'Sever Color',
        resourceValue2: 'Couleur de coupe',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_SEVER_COLOR_ORIGINAL',
        resourceValue: 'Original',
        resourceValue2: 'Original',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_SEVER_COLOR_CUSTOM',
        resourceValue: 'Custom',
        resourceValue2: 'Personnalisé',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_ARTICLE_COLOR',
        resourceValue: 'Article Color',
        resourceValue2: 'Couleur de l\'article',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_PRESET_NAME',
        resourceValue: 'Name',
        resourceValue2: 'Nom',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_PRESET_NAME_FR',
        resourceValue: 'Name fr',
        resourceValue2: 'Nom en',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_NON_RELEASABLE_PAGES',
        resourceValue: 'Non Releasable Pages',
        resourceValue2: 'Pages non publiables',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_EXCLUDE',
        resourceValue: 'Exclude',
        resourceValue2: 'Exclure',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_INCLUDE',
        resourceValue: 'Include',
        resourceValue2: 'Comprendre',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_AS_PAGE_OUT_NOTICE',
        resourceValue: 'As Page Out Notice',
        resourceValue2: 'Avis de sortie de page',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_LANGUAGE_DEFAULT',
        resourceValue: 'English',
        resourceValue2: 'Anglais',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_LANGUAGE',
        resourceValue: 'Language',
        resourceValue2: 'Langue',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_BILINGUAL',
        resourceValue: 'Bilingual',
        resourceValue2: 'Bilingue',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_LABEL_DOC_BY',
        resourceValue: 'Label Document By',
        resourceValue2: 'Étiqueter le document par',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_DOCUMENT_NAME',
        resourceValue: 'Document Name',
        resourceValue2: 'Nom du document',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_FILENAME',
        resourceValue: 'Filename',
        resourceValue2: 'Nom de fichier',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_CREATE_AS_SINGLE_FILE',
        resourceValue: 'Create Package as Single File',
        resourceValue2: 'Créer un package en tant que fichier unique',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_SHOW_ARTICLE_STAMPS',
        resourceValue: 'Show Article Stamps',
        resourceValue2: 'Afficher les tampons d\'articles',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_SHOW_USER_DEFINED_STAMPS',
        resourceValue: 'Show User Defined Stamps',
        resourceValue2: 'Afficher les tampons définis par l\'utilisateur',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_INCLUDE_NOTES',
        resourceValue: 'Include Notes',
        resourceValue2: 'Inclure des remarques',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_INCLUDE_PAGINATED_PAGES_ONLY',
        resourceValue: 'Include Paginated Pages Only',
        resourceValue2: 'Inclure uniquement les pages paginées',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_NO_WATERMARK_ERROR',
        resourceValue: 'There are no watermarks associated with the Request Folder Type',
        resourceValue2: 'Il n\'y a pas de filigrane associé au type de dossier de demande',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_SHOW_ARTICLE_LINKS',
        resourceValue: 'Show Article Links',
        resourceValue2: 'Afficher les liens des articles',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_INCLUDE_CONTACT_DISCLOSURE_PAGES',
        resourceValue: 'Include Pages with Contact Disclosures Only',
        resourceValue2: 'Inclure les pages avec les informations de contact uniquement',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_SHOW_WATERMARKS',
        resourceValue: 'Show Watermarks',
        resourceValue2: 'Afficher les filigranes',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_WATERMARK_LOCATION',
        resourceValue: 'Watermark Location',
        resourceValue2: 'Emplacement du filigrane',
    },
    {
        resourceType: 'ExportContentOptions',
        resourceKey: 'EXPORT_CONTENT_OPTIONS_EXPORT_OPTIONS_TITLE',
        resourceValue: 'Export Options',
        resourceValue2: 'Options d\'exportation',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_MIDDLE_LEFT',
        resourceValue: 'Middle-Left',
        resourceValue2: 'Milieu-gauche',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_MIDDLE_CENTER',
        resourceValue: 'Middle-Center',
        resourceValue2: 'Milieu-Centre',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_MIDDLE_RIGHT',
        resourceValue: 'Middle-Right',
        resourceValue2: 'Milieu-droit',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_BOTTOM_LEFT',
        resourceValue: 'Bottom Left',
        resourceValue2: 'En bas à gauche',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_BOTTOM_CENTER',
        resourceValue: 'Bottom Center',
        resourceValue2: 'En bas au centre',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_BOTTOM_RIGHT',
        resourceValue: 'Bottom Right',
        resourceValue2: 'En bas à droite',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_TOP_LEFT',
        resourceValue: 'Top Left',
        resourceValue2: 'En haut à gauche',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_TOP_CENTER',
        resourceValue: 'Top Center',
        resourceValue2: 'Centre supérieur',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_POSITION_TOP_RIGHT',
        resourceValue: 'Top Right',
        resourceValue2: 'En haut à droite',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_CONTROL_TITLE',
        resourceValue: 'ANNOTATION CONTROL',
        resourceValue2: 'CONTRÔLE DES ANNOTATIONS',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_CONTROL_TITLE',
        resourceValue: 'PAGINATION OPTIONS',
        resourceValue2: 'OPTIONS DE PAGINATION',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEES_TITLE',
        resourceValue: 'Consultees',
        resourceValue2: 'Consultés',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_EXPORT_DESTINATION_TITLE',
        resourceValue: 'EXPORT DESTINATION',
        resourceValue2: 'DESTINATION D\'EXPORTATION',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DOCUMENTS_LIST_TITLE',
        resourceValue: 'Documents',
        resourceValue2: 'Documents',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_SELECT_ALL_DOCUMENTS',
        resourceValue: 'All Documents',
        resourceValue2: 'Tous les documents',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_FILENAME_FIELD',
        resourceValue: 'Filename',
        resourceValue2: 'Nom de fichier',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGES_FIELD',
        resourceValue: 'pages',
        resourceValue2: 'pages',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_NO_PAGES_TO_EXPORT_ERROR',
        resourceValue: 'No pages to export based on current selections.Please adjust your Export options, or select \
            one or more documents to include in the export package.',
        resourceValue2: 'Aucune page à exporter en fonction des sélections actuelles.Veuillez ajuster vos options \
            d\'exportation ou sélectionner un ou plusieurs documents à inclure dans le package d\'exportation.',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ERROR_FORMAT',
        resourceValue: 'Please enter a list of PDF page numbers separated by commas and/or page ranges by separated \
            by a dash.  Example: 1-5,8-10,14,20-30',
        resourceValue2: 'Veuillez saisir une liste de numéros de page PDF séparés par des virgules et/ou des plages \
            de pages séparées par un tiret. Exemple : 1-5,8-10,14,20-30',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_OUT_OF_RANGE_ERROR_PART_ONE',
        resourceValue: 'Out of range PDF page number entered for document',
        resourceValue2: 'Numéro de page PDF hors plage saisi pour le document',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_OUT_OF_RANGE_ERROR_PART_TWO',
        resourceValue: 'Please enter page numbers between',
        resourceValue2: 'Veuillez entrer des numéros de page entre',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_OUT_OF_RANGE_ERROR_PART_THREE',
        resourceValue: 'in the Documents list filter',
        resourceValue2: 'dans le filtre de liste de documents',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_FILE_LENGTH_EXCEED',
        resourceValue: 'File length exceeded',
        resourceValue2: 'Longueur de fichier dépassée',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_SELECT_ALL',
        resourceValue: 'Select All',
        resourceValue2: 'Tout sélectionner',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESELECT_ALL',
        resourceValue: 'Deselect All',
        resourceValue2: 'Tout déselectionner',
    },
    {
        resourceType: 'ModalEditFilename',
        resourceKey: 'MODAL_EDIT_FILENAME_TITLE',
        resourceValue: 'Edit Filename',
        resourceValue2: 'Modifier le nom du fichier',
    },
    {
        resourceType: 'ModalEditFilename',
        resourceKey: 'MODAL_EDIT_FILENAME_FIELD_FILENAME',
        resourceValue: 'Filename',
        resourceValue2: 'Nom de fichier',
    },
    {
        resourceType: 'ModalEditFilename',
        resourceKey: 'MODAL_EDIT_FILENAME_CONFIRM',
        resourceValue: 'Confirm',
        resourceValue2: 'Confirmer',
    },
    {
        resourceType: 'ModalEditFilename',
        resourceKey: 'MODAL_EDIT_FILENAME_MULTI_DOC_WARNING',
        resourceValue: 'Please select only one document to edit the filename.',
        resourceValue2: 'Veuillez sélectionner un seul document pour modifier le nom du fichier.',
    },
    {
        resourceType: 'ModalEditFilename',
        resourceKey: 'MODAL_EDIT_FILENAME_INVALID_FILE_NAME_ERROR',
        resourceValue: 'Please enter the valid filename.',
        resourceValue2: 'Veuillez saisir un nom de fichier valide.',
    },
    {
        resourceType: 'ModalEditFilename',
        resourceKey: 'MODAL_EDIT_FILENAME_FILE_NAME_EXCEED_ERROR',
        resourceValue: 'Filename exceed maximum length',
        resourceValue2: 'Le nom de fichier dépasse la longueur maximale',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_SEVER_TRANSPARENCY',
        resourceValue: 'Sever Transparency',
        resourceValue2: 'Séparer la transparence',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_HIGHLIGHT_DISPLAY',
        resourceValue: 'Highlight Display',
        resourceValue2: 'Affichage en surbrillance',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_SEVER_NAME',
        resourceValue: 'Sever Name',
        resourceValue2: 'Nom du serveur',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_HIGHLIGHT_NAME',
        resourceValue: 'HighLight Name',
        resourceValue2: 'Nom de la surbrillance',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_HIDE_OR_SHOW_TEXT',
        resourceValue: 'Hide/Show Text',
        resourceValue2: 'Masquer/Afficher le texte',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_HIDE_TEXT',
        resourceValue: 'Hide Text',
        resourceValue2: 'Masquer le texte',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_SHOW_TEXT',
        resourceValue: 'Show Text',
        resourceValue2: 'Afficher le texte',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_OFF_OR_SHOW',
        resourceValue: 'Off/Show',
        resourceValue2: 'À l\'arrêt/Spectacle',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_OFF',
        resourceValue: 'Off',
        resourceValue2: 'À l\'arrêt',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_ANNOTATION_TAB_SHOW',
        resourceValue: 'Show',
        resourceValue2: 'Spectacle',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_PAGINATION_MODE',
        resourceValue: 'Pagination Mode',
        resourceValue2: 'Mode de pagination',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_BASIC',
        resourceValue: 'Basic',
        resourceValue2: 'De base',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_ADVANCED',
        resourceValue: 'Advanced',
        resourceValue2: 'Avancé',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_FONT_SIZE',
        resourceValue: 'Font Size',
        resourceValue2: 'Taille de police',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_FONT_COLOR',
        resourceValue: 'Font color',
        resourceValue2: 'Couleur de la police',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_LOCATION',
        resourceValue: 'Location',
        resourceValue2: 'Emplacement',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_STAMP_DOC_NAME_PAGE_INDEX',
        resourceValue: 'Stamp Document Name/Page Index',
        resourceValue2: 'Cachet Nom du document/Index des pages',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_STARTING_NUMBER',
        resourceValue: 'Starting Number',
        resourceValue2: 'Numéro de départ',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_PAGINATION_TAB_INCREMENT_BY',
        resourceValue: 'Increment By',
        resourceValue2: 'Incrémenter de',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_DESTINATION_LABEL',
        resourceValue: 'Export Destination',
        resourceValue2: 'Destination d\'exportation',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_FILE',
        resourceValue: 'File',
        resourceValue2: 'Dossier',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_FOLDER_ATTACHMENT',
        resourceValue: 'Folder Attachment',
        resourceValue2: 'Pièce jointe au dossier',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_PROCESS_ATTACHMENT',
        resourceValue: 'Process Attachment',
        resourceValue2: 'Processus de pièce jointe',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_PRINTER',
        resourceValue: 'Printer',
        resourceValue2: 'Imprimante',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_EXPORT_FILENAME',
        resourceValue: 'Export Filename',
        resourceValue2: 'Exporter le nom du fichier',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_ATTACHMENT_TYPE',
        resourceValue: 'Attachment Type',
        resourceValue2: 'Type de pièce jointe',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_ATTACHEMENT_DESCRIPTION',
        resourceValue: 'Attachment Description',
        resourceValue2: 'Description de la pièce jointe',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_ATTACHMENT_DETAIL',
        resourceValue: 'Attachment Detail',
        resourceValue2: 'Détail de la pièce jointe',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_ATTACH_TO_PROCESS',
        resourceValue: 'Attach to Process',
        resourceValue2: 'Attacher au processus',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_SELECT_PRINTER',
        resourceValue: 'Select printer',
        resourceValue2: 'Sélectionnez l\'imprimante',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_DESTINATION_TAB_SELECT_TRAY',
        resourceValue: 'Select Tray',
        resourceValue2: 'Sélectionnez le bac',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEE_TAB_DISCLOSURE_TO_EXPORT',
        resourceValue: 'Disclosures to Export Consultee Contacts',
        resourceValue2: 'Divulgations aux contacts des consultants à l\'exportation',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEE_TAB_DISCLOSURES',
        resourceValue: 'Disclosures',
        resourceValue2: 'Divulgations',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEE_TAB_LAST_NAME',
        resourceValue: 'Last Name',
        resourceValue2: 'Nom de famille',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEE_TAB_FIRST_NAME',
        resourceValue: 'First Name',
        resourceValue2: 'Prénom',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEE_TAB_ORGANIZATION_NAME',
        resourceValue: 'Organization Name',
        resourceValue2: 'Nom de l\'organisation',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEE_TAB_DEPARTMENT',
        resourceValue: 'Department',
        resourceValue2: 'Département',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEE_TAB_USER_TEAM',
        resourceValue: 'User Team',
        resourceValue2: 'Équipe d\'utilisateurs',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_CONSULTEE_TAB_PEOPLE_RSN',
        resourceValue: 'People RSN',
        resourceValue2: 'Personnes RSN',
    },
    {
        resourceType: 'ModalPackagePresets',
        resourceKey: 'MODAL_PACKAGE_PRESETS_TITLE',
        resourceValue: 'Package Presets',
        resourceValue2: 'Préréglages de package',
    },
    {
        resourceType: 'ModalPackagePresets',
        resourceKey: 'MODAL_PACKAGE_PRESETS_EXISTING_PRESETS',
        resourceValue: 'Existing Presets',
        resourceValue2: 'Préréglages existants',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_WARNING',
        resourceValue: 'Warning',
        resourceValue2: 'Avertissement',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_PRESS_OK_TO_CONTINUE',
        resourceValue: 'Press OK to continue.',
        resourceValue2: 'Appuyez sur OK pour continuer.',
    },
    {
        resourceType: 'ModalPackagePresets',
        resourceKey: 'MODAL_PACKAGE_PRESETS_DELETE_PRESET',
        resourceValue: 'Delete preset? ',
        resourceValue2: 'Supprimer le préréglage ?',
    },
    {
        resourceType: 'ModalExport',
        resourceKey: 'MODAL_EXPORT_TITLE',
        resourceValue: 'Export',
        resourceValue2: 'Exporter',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'REPORTS_PAGE_REVIEW_REPORT_CRITERIA',
        resourceValue: 'Report Criteria',
        resourceValue2: 'Critères de rapport',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_GENERATE',
        resourceValue: 'Generate',
        resourceValue2: 'Générer',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'REPORTS_REQUESTER_REPORT_TITLE',
        resourceValue: 'Requester',
        resourceValue2: 'Demandeur',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'REPORTS_REQUESTER_REPORT_SHOW_DATE_TIME',
        resourceValue: 'Show date and Time',
        resourceValue2: 'Afficher la date et l\'heure',
    },
    {
        resourceType: 'ActionHeader',
        resourceKey: 'REPORTS_REQUESTER_REPORT_SELECTED_ITEMS_ONLY',
        resourceValue: 'Report on Selected Item Only',
        resourceValue2: 'Rapport sur l\'élément sélectionné uniquement',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_DOCUMENT_TITLE',
        resourceValue: 'Document import',
        resourceValue2: 'Importation de documents',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_PAGE_TITLE',
        resourceValue: 'Page Insert',
        resourceValue2: 'Insertion de page',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SELECT_SOURCE',
        resourceValue: 'Import Source',
        resourceValue2: 'Source d\'importation',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SELECT_LOCAL_FILE',
        resourceValue: 'Local file',
        resourceValue2: 'Fichier local',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SELECT_REDACTION_DOCUMENTS',
        resourceValue: 'Redaction Document(s)',
        resourceValue2: 'Document(s) de rédaction',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SELECT_FROM_EDMS',
        resourceValue: 'Import from EDMS',
        resourceValue2: 'Importer depuis EDMS',
    },    
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SELECT_AMANDA_ATTACHMENTS',
        resourceValue: 'AMANDA Attachment(s)',
        resourceValue2: 'Pièce(s) jointe(s) AMANDA',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SELECT_FROM_SCANNER',
        resourceValue: 'Import From Scanner',
        resourceValue2: 'Importer depuis le scanneur',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_DOCUMENT_TYPE_HEADER',
        resourceValue: 'Type',
        resourceValue2: 'Le type',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_INVALID_FILE_EXTENSION_ERROR',
        resourceValue: 'Invalid file extension',
        resourceValue2: 'Extension de fichier invalide',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_END_PAGE_HEADER',
        resourceValue: 'End Page',
        resourceValue2: 'Page de fin',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_START_PAGE_HEADER',
        resourceValue: 'Start Page',
        resourceValue2: 'Page de démarrage',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SIZE_HEADER',
        resourceValue: 'Size',
        resourceValue2: 'Taille',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_CONVERSION_OPTIONS',
        resourceValue: 'Conversion Options',
        resourceValue2: 'Options de conversion',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_REDACTION_OPTIONS',
        resourceValue: 'Redaction Options',
        resourceValue2: 'Options de rédaction',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_ASSIGN_TO_STACK',
        resourceValue: 'Assign to Stack',
        resourceValue2: 'Affecter à la pile',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_INCLUDE_ANNOTATIONS',
        resourceValue: 'Include annotations',
        resourceValue2: 'Inclure des annotations',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_INCLUDE_DISCLOSURES',
        resourceValue: 'Include disclosures',
        resourceValue2: 'Inclure les divulgations',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_OFFICER_ASSIGNED_HEADER',
        resourceValue: 'Officer Assigned',
        resourceValue2: 'Officier affecté',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_REQUEST_SUMMARY_HEADER',
        resourceValue: 'Request Summary',
        resourceValue2: 'Résumé de la demande',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SEARCH_FOLDER_ATTACHMENTS_TITLE',
        resourceValue: 'Search Folder Attachments',
        resourceValue2: 'Fichiers joints du dossier de recherche',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SEARCH_REDACTION_DOCUMENTS_TITLE',
        resourceValue: 'Search Redaction Documents',
        resourceValue2: 'Rechercher des documents de rédaction',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_HAVE_NO_DEFAULT_DISCLOSURE',
        resourceValue: 'Default disclosure isn\'t set for this folder type. Please contact your administrator',
        resourceValue2: 'La divulgation par défaut n\'est pas définie pour ce type de dossier. Veuillez\
             contacter votre administrateur',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_DOCUMENT_NAME_HEADER',
        resourceValue: 'Document Name',
        resourceValue2: 'Nom du document',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_PAGES_HEADER',
        resourceValue: 'Page(s)',
        resourceValue2: 'Page(s)',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_PAGE_COUNT_ERROR',
        resourceValue: 'Must be equal or larger than start page',
        resourceValue2: 'Doit être égal ou supérieur à la page de démarrage',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_UNSUPPORTED_FILE_MESSAGE',
        resourceValue: 'The following files selected are not permitted for import and will be automatically removed\
             from the Document Import list.',
        resourceValue2: 'Les fichiers suivants sélectionnés ne peuvent pas être importés et seront automatiquement\
             supprimés de la liste d\'importation de documents.',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_BIG_FILE_MESSAGE',
        resourceValue: 'The following files selected exceed the maximum import file size of 2GB and will be\
             automatically removed from the Document Import list.',
        resourceValue2: 'Les fichiers suivants sélectionnés dépassent la taille de fichier d\'importation maximale\
             de 2 GB et seront automatiquement supprimés de la liste d\'importation de documents.',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_NO_ATTACHMENT_FOUND',
        resourceValue: 'No Attachments Found',
        resourceValue2: 'Aucune pièce jointe trouvée',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_NO_DOCUMENT_FOUND',
        resourceValue: 'No Documents Found',
        resourceValue2: 'Aucun document trouvé',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_TOTAL_PAGES',
        resourceValue: 'total pages',
        resourceValue2: 'pages totales',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_FOLDER_TYPE',
        resourceValue: 'Folder Type',
        resourceValue2: 'Type de dossier',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_TITLE',
        resourceValue: 'Import from EDMS',
        resourceValue2: 'Importer depuis EDMS',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_SELECT_ADAPTOR',
        resourceValue: 'Select EDMS Adaptor',
        resourceValue2: 'Sélectionnez l\'adaptateur EDMS',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_INPUT_URL_LABEL',
        resourceValue: 'Enter Input EDMS URL',
        resourceValue2: 'Entrez l\'URL EDMS d\'entrée',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_ADD_LABEL',
        resourceValue: 'Add',
        resourceValue2: 'Ajouter',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_CANCEL_LABEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_OK_LABEL',
        resourceValue: 'OK',
        resourceValue2: 'D\'ACCORD',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_MANDATORY_ALERT_TITLE',
        resourceValue: 'Mandatory Alert!!',
        resourceValue2: 'Alerte obligatoire !!',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_URL_ALERT_MESSAGE',
        resourceValue: 'Atleast one EDMS url is needed',
        resourceValue2: 'Au moins une URL EDMS est nécessaire',
    },
    {
        resourceType: 'ModalImportFromEDMS',
        resourceKey: 'MODAL_IMPORT_EDMS_ADAPTOR_SELECTION_ALERT_MESSAGE',
        resourceValue: 'Select EDMS Adaptor is Empty',
        resourceValue2: 'Sélectionner l\'adaptateur EDMS est vide',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_NO_RESULTS',
        resourceValue: 'Your search did not return any results. Please review the search criteria.',
        resourceValue2: 'Votre recherche n\'a donné aucun résultat. Veuillez revoir les critères de recherche.',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SELECT_SCANNER',
        resourceValue: 'Select Scanner',
        resourceValue2: 'Sélectionnez Scanner',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_RESOLUTION',
        resourceValue: 'Resolution',
        resourceValue2: 'Résolution',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_COLOR_DEPTH',
        resourceValue: 'Colour Depth',
        resourceValue2: 'Profondeur de couleur',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_COLOR_Black_AND_WHITE',
        resourceValue: 'Black & White',
        resourceValue2: 'Noir & Blanc',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_COLOR_GRAY',
        resourceValue: 'Gray',
        resourceValue2: 'Gris',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_COLOR',
        resourceValue: 'Color',
        resourceValue2: 'La couleur',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_MODE',
        resourceValue: 'Scan Mode',
        resourceValue2: 'Mode de balayage',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_MODE_MULTI_PAGE',
        resourceValue: 'Multiple Page Document',
        resourceValue2: 'Document de plusieurs pages',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_MODE_BATCH_MULTI',
        resourceValue: 'Batch Multi - Document',
        resourceValue2: 'Lot Multi - Document',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SEARCH_RESULTS',
        resourceValue: 'Search Result',
        resourceValue2: 'Résultat de la recherche',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_REQUEST_NUMBER',
        resourceValue: 'Request Number',
        resourceValue2: 'Numéro de demande',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_CUSTOM_SETTINGS',
        resourceValue: 'Custom Settings',
        resourceValue2: 'Paramètres personnalisés',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_DUPLEX',
        resourceValue: 'Duplex',
        resourceValue2: 'Duplex',
    },
    {
        resourceType: 'ModalImport',
        resourceKey: 'MODAL_IMPORT_SCANNER_AUTOMATIC_FEEDER',
        resourceValue: 'Use Automatic Document Feeder',
        resourceValue2: 'Utiliser le chargeur automatique de documents',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_AUTO_DESKEW',
        resourceValue: 'Auto Deskew',
        resourceValue2: 'Désalignement automatique',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_IMPORT',
        resourceValue: 'Import',
        resourceValue2: 'Importer',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_RESET',
        resourceValue: 'Reset',
        resourceValue2: 'Réinitialiser',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SHOW_ALL',
        resourceValue: 'Show All',
        resourceValue2: 'Tout afficher',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_HIDE_ALL',
        resourceValue: 'Hide All',
        resourceValue2: 'Tout cacher',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_NEXT',
        resourceValue: 'Next',
        resourceValue2: 'Suivant',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DESELECT_ALL',
        resourceValue: 'Deselect All',
        resourceValue2: 'Tout déselectionner',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SELECT_ALL',
        resourceValue: 'Select All',
        resourceValue2: 'Tout sélectionner',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SELECT',
        resourceValue: 'Select',
        resourceValue2: 'Sélectionner',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DESCRIPTION',
        resourceValue: 'Description',
        resourceValue2: 'La description',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_APPLY_OCR',
        resourceValue: 'Apply OCR',
        resourceValue2: 'Appliquer la ROC',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_AUTO',
        resourceValue: 'Alert',
        resourceValue2: 'Alerte',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_EQUAL_OR_LARGER',
        resourceValue: 'Must be equal or larger than',
        resourceValue2: 'Doit être égal ou supérieur à',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_EQUAL_OR_LESS',
        resourceValue: 'Must be equal or less than',
        resourceValue2: 'Doit être égal ou inférieur à',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_MUST_BE_INTEGER',
        resourceValue: 'Must be integer',
        resourceValue2: 'Doit être un entier',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SCAN',
        resourceValue: 'Scan',
        resourceValue2: 'Le balayage',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_REMOVE_CONTACTS',
        resourceValue: 'Remove contact',
        resourceValue2: 'Supprimer contact',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_TITLE',
        resourceValue: 'Select Contacts',
        resourceValue2: 'Sélectionnez Contacts',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_ASSIGN',
        resourceValue: 'ASSIGN',
        resourceValue2: 'ATTRIBUER',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_SEARCH_RESULTS_TITLE',
        resourceValue: 'Search Result',
        resourceValue2: 'Résultat de la recherche',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_PEOPLE_TYPE_HEADER',
        resourceValue: 'People Type',
        resourceValue2: 'Type de personnes',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_ORGANISATION_NAME_HEADER',
        resourceValue: 'Organization Name',
        resourceValue2: 'Nom de l\'organisation',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_PEOPLE_RSN_HEADER',
        resourceValue: 'People RSN',
        resourceValue2: 'Personnes RSN',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_ASSIGNED_CONTACTS_TITLE',
        resourceValue: 'Assigned Contact',
        resourceValue2: 'Personne-ressource assignée',
    },
    {
        resourceType: 'ModalConsultees',
        resourceKey: 'MODAL_CONSULTEES_NO_PERSONS_ADDED_MESSAGE',
        resourceValue: 'No persons have been added yet.',
        resourceValue2: 'Aucune personne n\'a encore été ajoutée.',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_FIRST_NAME',
        resourceValue: 'First Name',
        resourceValue2: 'Prénom',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_LAST_NAME',
        resourceValue: 'Last Name',
        resourceValue2: 'Nom de famille',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_ACTION',
        resourceValue: 'Action',
        resourceValue2: 'Action',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_REMOVE',
        resourceValue: 'REMOVE',
        resourceValue2: 'RETIRER',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_EDIT',
        resourceValue: 'Edit',
        resourceValue2: 'Éditer',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_REMOVE_ALL',
        resourceValue: 'Remove All',
        resourceValue2: 'Enlever tout',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_LOADING',
        resourceValue: 'Loading...',
        resourceValue2: 'Chargement...',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_SELECT_DISCLOSURE',
        resourceValue: 'Select Disclosure',
        resourceValue2: 'Sélectionnez Divulgation',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_BASIC_SETTINGS',
        resourceValue: 'Basic Settings',
        resourceValue2: 'Paramètres de base',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_RELEASE_SETTINGS',
        resourceValue: 'Release Settings',
        resourceValue2: 'Paramètres de libération',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_CONTACT_SETTINGS',
        resourceValue: 'Contact Settings',
        resourceValue2: 'Paramètres des contacts',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_DELETE_WARNING',
        resourceValue: 'Delete disclosure',
        resourceValue2: 'Supprimer la divulgation',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_INCLUDE_IN_PAGINATION',
        resourceValue: 'Include in pagination',
        resourceValue2: 'Inclure dans la pagination',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_DUPLICATE_REFERENCE',
        resourceValue: 'Duplicate reference required',
        resourceValue2: 'Référence en double requise',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_RELEASABLE_FIELD',
        resourceValue: 'Releasable',
        resourceValue2: 'Libérable',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_EXEMPTION_REQUIRED',
        resourceValue: 'Exemption required',
        resourceValue2: 'Exemption requise',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_PAGE_OUT_NOTICE',
        resourceValue: 'Page Out Notice',
        resourceValue2: 'Avis de sortie de page',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_COUNT_PAGE_AS',
        resourceValue: 'Count page As',
        resourceValue2: 'Compter la page comme',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_COUNT_AS_DUPLICATE',
        resourceValue: 'Duplicate',
        resourceValue2: 'Dupliquer',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_COUNT_AS_NOT_RELEVANT',
        resourceValue: 'Not Relevant',
        resourceValue2: 'Non pertinent',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_COUNT_AS_RECEIVED',
        resourceValue: 'Received',
        resourceValue2: 'A reçu',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_COUNT_AS_RELEASED',
        resourceValue: 'Released',
        resourceValue2: 'Libéré',
    },
    {
        resourceType: 'ModalDisclosureManagement',
        resourceKey: 'MODAL_DISCLOSURE_COUNT_AS_REVIEWED',
        resourceValue: 'Reviewed',
        resourceValue2: 'Révisé',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_CHANGES_NOT_SAVED',
        resourceValue: 'Changes you made may not be saved. Click "Ok" - leave the window without data saved,\
             "Cancel" - return user to the window.',
        resourceValue2: 'Les modifications que vous avez apportées peuvent ne pas être enregistrées.\
             Cliquez sur "Ok" - quittez la fenêtre sans données enregistrées, "Annuler" - retournez l\'utilisateur\
             à la fenêtre.',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_ACTIVE',
        resourceValue: 'Active',
        resourceValue2: 'Actif',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_NAME',
        resourceValue: 'Name',
        resourceValue2: 'Nom',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'LABEL_PERMISSIONS',
        resourceValue: 'Permissions',
        resourceValue2: 'Autorisations',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_NAME_FRENCH',
        resourceValue: 'Name fr',
        resourceValue2: 'Nom fr',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_DESCRIPTION_FRENCH',
        resourceValue: 'Description fr',
        resourceValue2: 'La description fr',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_REQUEST_TYPE',
        resourceValue: 'Request type',
        resourceValue2: 'Type de demande',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_AT_LEAST_ONE_REQUIRED',
        resourceValue: 'at least one required',
        resourceValue2: 'au moins un requis',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_MUST_BE_UNIQUE',
        resourceValue: 'Must be unique',
        resourceValue2: 'Doit être unique',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_AT_LEAST_ONE_OF_LIST',
        resourceValue: 'At least one of list',
        resourceValue2: 'Au moins une de la liste',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_FIELD_IS_MANDATORY',
        resourceValue: 'Field is mandatory to fill in',
        resourceValue2: 'Le champ est obligatoire à remplir',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_MAX_LENGTH',
        resourceValue: 'Max length',
        resourceValue2: 'Longueur maximale',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_MIN_LENGTH',
        resourceValue: 'Min length',
        resourceValue2: 'Longueur minimale',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_NO_ROWS_FOUND',
        resourceValue: 'No rows found',
        resourceValue2: 'Aucune ligne trouvée',
    },
    {
        resourceType: 'ModalStackManagement',
        resourceKey: 'MODAL_STACK_TITLE',
        resourceValue: 'Document Stack',
        resourceValue2: 'Pile de documents',
    },
    {
        resourceType: 'ModalStackManagement',
        resourceKey: 'MODAL_STACK_SELECT_DOCUMENT_WARNING',
        resourceValue: 'You cannot perform this action. Please select at least one document.',
        resourceValue2: 'Vous ne pouvez pas effectuer cette action. Veuillez sélectionner au moins un document.',
    },
    {
        resourceType: 'ModalStackManagement',
        resourceKey: 'MODAL_STACK_PROPERTIES_TITLE',
        resourceValue: 'Stack Properties',
        resourceValue2: 'Propriétés de la pile',
    },
    {
        resourceType: 'ModalStackManagement',
        resourceKey: 'MODAL_STACK_MANAGEMENT_STACK_PROPERTY',
        resourceValue: 'Stack Property',
        resourceValue2: 'Propriété de la pile',
    },
    {
        resourceType: 'ModalStackManagement',
        resourceKey: 'MODAL_STACK_MANAGEMENT_SELECT_STACK',
        resourceValue: 'Select Stack',
        resourceValue2: 'Sélectionnez la pile',
    },
    {
        resourceType: 'ModalStackManagement',
        resourceKey: 'MODAL_STACK_PROPERTIES_SELECT_PROPERTY',
        resourceValue: 'Select Stack Property',
        resourceValue2: 'Sélectionnez la propriété de la pile',
    },
    {
        resourceType: 'ModalStackManagement',
        resourceKey: 'MODAL_STACK_MANAGEMENT_DELETE_STACK',
        resourceValue: 'Delete stack',
        resourceValue2: 'Supprimer la pile',
    },
    {
        resourceType: 'ModalStackManagement',
        resourceKey: 'MODAL_STACK_PROPERTIES_DELETE_PROPERTY',
        resourceValue: 'Delete stack property',
        resourceValue2: 'Supprimer la propriété de la pile',
    },
    {
        resourceType: 'ModalPagination',
        resourceKey: 'MODAL_PAGINATION_TITLE',
        resourceValue: 'Pagination',
        resourceValue2: 'Pagination',
    },
    {
        resourceType: 'ModalPagination',
        resourceKey: 'MODAL_PAGINATION_MULTIPLE_PAGE_WARNING',
        resourceValue:
            'You cannot edit pagination when multiple documents are selected. Please select only one document.',
        resourceValue2: 'Vous ne pouvez pas modifier la pagination lorsque plusieurs documents sont sélectionnés. \
            Veuillez sélectionner un seul document.',
    },
    {
        resourceType: 'ModalPagination',
        resourceKey: 'MODAL_PAGINATION_CONFIRMATION',
        resourceValue: 'Pagination confirmation',
        resourceValue2: 'Confirmation de pagination',
    },
    {
        resourceType: 'ModalPagination',
        resourceKey: 'MODAL_PAGINATION_AVAILABLE_PAGINATION',
        resourceValue: 'Available Pagination Numbers',
        resourceValue2: 'Numéros de pagination disponibles',
    },
    {
        resourceType: 'ModalPagination',
        resourceKey: 'MODAL_PAGINATION_START_FIELD',
        resourceValue: 'Pagination Start',
        resourceValue2: 'Début de la pagination',
    },
    {
        resourceType: 'ModalPagination',
        resourceKey: 'MODAL_PAGINATION_END_FIELD',
        resourceValue: 'Pagination End',
        resourceValue2: 'Fin de la pagination',
    },
    {
        resourceType: 'ModalPagination',
        resourceKey: 'MODAL_PAGINATION_CONFIRMATION_MESSAGE',
        resourceValue: 'Would you like to re-paginate the entire request? (Select No to \
            re-paginate currently selected documents only).',
        resourceValue2: 'Souhaitez-vous repaginer l\'intégralité de la demande? (Sélectionnez Non pour repaginer \
            uniquement les documents actuellement sélectionnés).',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_NO_DOCUMENT_WARNING',
        resourceValue: 'You cannot perform this action. Please select a document.',
        resourceValue2: 'Vous ne pouvez pas effectuer cette action. Veuillez sélectionner un document.',
    },
    {
        resourceType: 'CommonLabels',
        resourceKey: 'COMMON_LABEL_SET',
        resourceValue: 'Set',
        resourceValue2: 'Le set',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_TITLE',
        resourceValue: 'Audit Log',
        resourceValue2: 'Journal d\'audit',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_SPECIFIC_REQUESTS',
        resourceValue: 'Specific Requests',
        resourceValue2: 'Demandes spécifiques',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_TYPE_FIELD',
        resourceValue: 'Type',
        resourceValue2: 'Taper',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_YEAR_FIELD',
        resourceValue: 'Year',
        resourceValue2: 'Année',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_NUMBER_FIELD',
        resourceValue: 'Number',
        resourceValue2: 'Nombre',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_START_DATE_FIELD',
        resourceValue: 'Start Date *',
        resourceValue2: 'Date de début *',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_END_DATE_FIELD',
        resourceValue: 'End Date *',
        resourceValue2: 'Date de fin *',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DOCUMENT_NAME_FIELD',
        resourceValue: 'Document Name',
        resourceValue2: 'Nom du document',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_USER_FIELD',
        resourceValue: 'User',
        resourceValue2: 'Utilisatrice',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_SEARCH_BUTTON',
        resourceValue: 'Search',
        resourceValue2: 'Recherche',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_CLEAR_BUTTON',
        resourceValue: 'Clear',
        resourceValue2: 'Claire',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_ERASE_LOG',
        resourceValue: 'Erase Log',
        resourceValue2: 'Effacer le journal',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_BACK_HOME',
        resourceValue: 'Back Home',
        resourceValue2: 'Retour à la maison',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_TITLE',
        resourceValue: 'Search Result',
        resourceValue2: 'Résultat de la recherche',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_FOLDER',
        resourceValue: 'Folder',
        resourceValue2: 'Dossier',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_ACTION',
        resourceValue: 'Action',
        resourceValue2: 'Action',
    },    
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_ACTION_TYPE',
        resourceValue: 'Action Type',
        resourceValue2: 'type d\'action',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_DATE',
        resourceValue: 'Date',
        resourceValue2: 'Date',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_DOCUMENT_NAME',
        resourceValue: 'Document Name',
        resourceValue2: 'Nom du document',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_PAGE',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_USER',
        resourceValue: 'User',
        resourceValue2: 'Utilisatrice',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_EXPORT_ALL_LOG',
        resourceValue: 'Export all log',
        resourceValue2: 'Exporter tous les journaux',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREACH_RESULT_EXPORT_CURRENT_RESULT',
        resourceValue: 'Export current search result',
        resourceValue2: 'Exporter le résultat de la recherche actuelle',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_SYSTEM_LABEL',
        resourceValue: 'System',
        resourceValue2: 'Système',
    },    
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_SYSTEM_SESSION_STARTED',
        resourceValue: 'Session Started',
        resourceValue2: 'Session commencée',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_SYSTEM_SESSION_ENDED',
        resourceValue: 'Session Ended',
        resourceValue2: 'Session terminée',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_SYSTEM_SESSION_TIMEOUT',
        resourceValue: 'Session Timeout',
        resourceValue2: 'Expiration de la session',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_SYSTEM_REQUEST_LOADED',
        resourceValue: 'Request Loaded',
        resourceValue2: 'Demande chargée',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_SYSTEM_REQUEST_ACCESS_DENIED',
        resourceValue: 'Request Access Denied',
        resourceValue2: 'Demande d\'accès refusée',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_STACK_LABEL',
        resourceValue: 'Stack',
        resourceValue2: 'Empiler',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_CREATE_STACK_LABEL',
        resourceValue: 'Create Stack',
        resourceValue2: 'Créer une pile',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_ADD_TO_STACK_LABEL',
        resourceValue: 'Add to Stack',
        resourceValue2: 'Ajouter à la pile',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_REMOVE_FROM_STACK_LABEL',
        resourceValue: 'Removed from Stack',
        resourceValue2: 'Supprimé de la pile',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DELETE_STACK_LABEL',
        resourceValue: 'Delete Stack',
        resourceValue2: 'Supprimer la pile',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_INDEX_LABEL',
        resourceValue: 'Index',
        resourceValue2: 'Indice',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DOCUMENT_INDEX_SAVED_LABEL',
        resourceValue: 'Document Index Saved',
        resourceValue2: 'Index du document enregistré',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_PAGE_INDEX_SAVED_LABEL',
        resourceValue: 'Page Index Saved',
        resourceValue2: 'Index des pages enregistré',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_ANNOTATION_LABEL',
        resourceValue: 'Annotation',
        resourceValue2: 'Annotation',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_ADD_ANNOTATION_LABEL',
        resourceValue: 'Add Annotation',
        resourceValue2: 'Ajouter une annotation',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DELETE_ANNOTATION_LABEL',
        resourceValue: 'Delete Annotation',
        resourceValue2: 'Supprimer l\'annotation',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_RESIZE_MOVE_ANNOTATION_LABEL',
        resourceValue: 'Resize/Move Annotation',
        resourceValue2: 'Redimensionner/Déplacer l\'annotation',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DISCLOSURE_LABEL',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_CHANGE_DISCLOSURE_LABEL',
        resourceValue: 'Change Disclosure',
        resourceValue2: 'Modification de la divulgation',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_UNDO_DISCLOSURE_LABEL',
        resourceValue: 'Undo Disclosure',
        resourceValue2: 'Annuler la divulgation',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DOCUMENT_LABEL',
        resourceValue: 'Document',
        resourceValue2: 'Document',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DELETE_DOCUMENT_LABEL',
        resourceValue: 'Delete (Document)',
        resourceValue2: 'Supprimer (Document)',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DOCUMENT_SET_PAGINATION_LABEL',
        resourceValue: 'Set Pagination',
        resourceValue2: 'Définir la pagination',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DOCUMENT_UNDO_PAGINATION_LABEL',
        resourceValue: 'Undo Pagination',
        resourceValue2: 'Annuler la pagination',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DOCUMENT_EDIT_PAGINATION_LABEL',
        resourceValue: 'Edit Pagination',
        resourceValue2: 'Modifier la pagination',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_AUTO_DESKEW_DOCUMENT_LABEL',
        resourceValue: 'Auto Deskew (Document)',
        resourceValue2: 'Désalignement automatique (document)',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_STAMP_LABEL',
        resourceValue: 'Stamp',
        resourceValue2: 'Timbre',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_ADD_STAMP_LABEL',
        resourceValue: 'Add Stamp',
        resourceValue2: 'Ajouter un tampon',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_MOVE_STAMP_LABEL',
        resourceValue: 'Move Stamp',
        resourceValue2: 'Déplacer le tampon',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DELETE_STAMP_LABEL',
        resourceValue: 'Delete Stamp',
        resourceValue2: 'Supprimer le tampon',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_PAGE_LABEL',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_DELETE_PAGE_LABEL',
        resourceValue: 'Delete (Page)',
        resourceValue2: 'Supprimer (page)',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_MOVE_PAGE_LABEL',
        resourceValue: 'Move (Page)',
        resourceValue2: 'Déplacer (page)',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_ROTATE_PAGE_LABEL',
        resourceValue: 'Rotate (Page)',
        resourceValue2: 'Rotation (page)',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_FLIP_PAGE_LABEL',
        resourceValue: 'Flip (Page)',
        resourceValue2: 'Retourner (Page)',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_AUTO_DESKEW_PAGE_LABEL',
        resourceValue: 'Auto Deskew (Page)',
        resourceValue2: 'Désalignement automatique (Page)',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_IMPORT_LABEL',
        resourceValue: 'Import',
        resourceValue2: 'Importer',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_IMPORT_PAGE_FROM_SCANNER_LABEL',
        resourceValue: 'Page from Scanner',
        resourceValue2: 'Page du scanner',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_IMPORT_PAGE_FROM_REQUEST_LABEL',
        resourceValue: 'Page from Request',
        resourceValue2: 'Page de la demande',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_IMPORT_PAGE_FROM_FILE_LABEL',
        resourceValue: 'Page from File',
        resourceValue2: 'Page du fichier',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_IMPORT_DOCUMENT_FROM_SCANNER_LABEL',
        resourceValue: 'Document from Scanner',
        resourceValue2: 'Document du scanner',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_IMPORT_DOCUMENT_FROM_REQUEST_LABEL',
        resourceValue: 'Document from Request',
        resourceValue2: 'Document de la demande',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_IMPORT_DOCUMENT_FROM_FILE_LABEL',
        resourceValue: 'Document from File',
        resourceValue2: 'Document à partir du fichier',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_EXPORT_LABEL',
        resourceValue: 'Export',
        resourceValue2: 'Exporter',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_EXPORT_CREATE_PACKAGE_LABEL',
        resourceValue: 'Create Package',
        resourceValue2: 'Créer un package',
    },
    {
        resourceType: 'AuditLogScreen',
        resourceKey: 'AUDIT_LOG_SCREEN_EXPORT_ORIGINAL_ONLY_LABEL',
        resourceValue: 'Export Original Only',
        resourceValue2: 'Exporter l\'original uniquement',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_COMPARE',
        resourceValue: 'Compare',
        resourceValue2: 'Comparer',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_SUBJECT',
        resourceValue: 'Subject',
        resourceValue2: 'Sujette',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_TO',
        resourceValue: 'To',
        resourceValue2: 'Pour',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_FROM',
        resourceValue: 'From',
        resourceValue2: 'Depuis',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_DATE',
        resourceValue: 'Date',
        resourceValue2: 'Date',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_CLASSIFICATION',
        resourceValue: 'Classification',
        resourceValue2: 'Classification',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_LANGUAGE',
        resourceValue: 'Language',
        resourceValue2: 'Langue',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_TO_ORGANIZATION',
        resourceValue: 'To Organization',
        resourceValue2: 'À l\'organisation',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_FROM_ORGANIZATION',
        resourceValue: 'From Organization',
        resourceValue2: 'De l\'organisation',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_DOCUMENTTYPE',
        resourceValue: 'Document Type',
        resourceValue2: 'Type de document',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_REFERENCE_NUMBER',
        resourceValue: 'Reference Number',
        resourceValue2: 'Numéro de réference',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_FILENAME',
        resourceValue: 'Filename',
        resourceValue2: 'Nom de fichier',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_FIELD1',
        resourceValue: 'Field 1',
        resourceValue2: 'Champ 1',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_SEARCH',
        resourceValue: 'Search',
        resourceValue2: 'Recherche',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_ASSIGNMENT',
        resourceValue: 'Assignment',
        resourceValue2: 'Mission',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_REFERENCES',
        resourceValue: 'References',
        resourceValue2: 'Les références',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_SELECT_ATLEAST_ONE',
        resourceValue: 'Select at least one:',
        resourceValue2: 'Sélectionnez au moins un :',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_PAGE_NAVIGATION',
        resourceValue: 'Page Navigation',
        resourceValue2: 'Navigation dans les pages',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_DOCUMENT_SET_NAVIGATION',
        resourceValue: 'Document Set Navigation',
        resourceValue2: 'Navigation dans l\'ensemble de documents',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_GENERAL_ATTRIBUTES',
        resourceValue: 'General Attributes',
        resourceValue2: 'Attributs généraux',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_DOCUMENT_NAME',
        resourceValue: 'Document Name',
        resourceValue2: 'Nom du document',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_DISCLOSURE',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_START',
        resourceValue: 'Start',
        resourceValue2: 'Commencer',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_INDEX_ATTRIBUTES',
        resourceValue: 'Index Attributes',
        resourceValue2: 'Attributs d\'index',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_TO_ORG',
        resourceValue: 'To Org.',
        resourceValue2: 'À l\'Org.',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_FROM_ORG',
        resourceValue: 'From Org.',
        resourceValue2: 'De l\'Org.',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_DOCUMENT_ATTRIBUTES',
        resourceValue: 'Document Attributes',
        resourceValue2: 'Attributs de document',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_AUTHOR',
        resourceValue: 'Author',
        resourceValue2: 'Auteure',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_PAGES',
        resourceValue: 'Pages',
        resourceValue2: 'pages',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_FILE_SIZE',
        resourceValue: 'File Size',
        resourceValue2: 'Taille du fichier',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_DUPLICATES_TITLE',
        resourceValue: 'Duplicates',
        resourceValue2: 'Doublons',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_RESET',
        resourceValue: 'Reset',
        resourceValue2: 'Réinitialiser',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_SHOW_ALL',
        resourceValue: 'Show All',
        resourceValue2: 'Tout afficher',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_HIDE_ALL',
        resourceValue: 'Hide All',
        resourceValue2: 'Tout cacher',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_BACK_HOME_LABEL',
        resourceValue: 'Back Home',
        resourceValue2: 'Retour à la maison',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_PAGE_WIDTH',
        resourceValue: 'Page Width',
        resourceValue2: 'Largeur de page',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_ACTUAL_PAGE_SIZE',
        resourceValue: 'Actual Page Size',
        resourceValue2: 'Taille réelle de la page',
    },
    {
        resourceType: 'ManageDuplicatesScreen',
        resourceKey: 'MANAGE_DUPLICATES_SCREEN_FULL_PAGE',
        resourceValue: 'Full Page',
        resourceValue2: 'Page entière',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'ASSIGNED_DUPLICATES_MODAL_TITLE',
        resourceValue: 'Master Document',
        resourceValue2: 'Document maître',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'ASSIGNED_DUPLICATES_MASTER_DOCUMENT',
        resourceValue: 'Duplicates',
        resourceValue2: 'Doublons',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'ASSIGNED_DUPLICATES_DUPLICATE_FIELD',
        resourceValue: 'Duplicates',
        resourceValue2: 'Doublons',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'ASSIGNED_DUPLICATES_PAGE_AMOUNT_FIELD',
        resourceValue: 'Page Amount',
        resourceValue2: 'Nombre de pages',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'ASSIGNED_DUPLICATES_DISCLOSURE',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'ASSIGNED_DUPLICATES_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'ASSIGNED_DUPLICATES_ASSIGN',
        resourceValue: 'Assign',
        resourceValue2: 'Attribuer',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'ASSIGNED_DUPLICATES_ATLEAST_ONE_REUIRED',
        resourceValue: 'at least one required',
        resourceValue2: 'au moins un requis',
    },
    {
        resourceType: 'AssignDuplicatesModal',
        resourceKey: 'MANAGE_DUPLICATES_ALERT_MESSAGE',
        resourceValue: 'You can select only one page to see duplicate references!',
        resourceValue2: 'Vous ne pouvez sélectionner qu\'une seule page pour voir les références en double !',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_TITLE',
        resourceValue: 'References',
        resourceValue2: 'Les références',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_MASTER_DOCUMENT',
        resourceValue: 'Master Document',
        resourceValue2: 'Document maître',
    },    
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_MASTER_DUPLICATES',
        resourceValue: 'Duplicates',
        resourceValue2: 'Doublons',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_PAGE',
        resourceValue: 'Page #',
        resourceValue2: 'Numéro de page',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_DISCLOSURE',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_DOCUMENT',
        resourceValue: 'Document',
        resourceValue2: 'Document',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_DOCUMENT_DISCLOSURE',
        resourceValue: 'Document Disclosure',
        resourceValue2: 'Divulgation de documents',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_PAGE_DISCLOSURE',
        resourceValue: 'Page Disclosure',
        resourceValue2: 'Divulgation des pages',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_CLOSE',
        resourceValue: 'Close',
        resourceValue2: 'Fermer',
    },
    {
        resourceType: 'ReferenceModal',
        resourceKey: 'REFERENCE_MODAL_DELETEALL',
        resourceValue: 'Delete All References',
        resourceValue2: 'Supprimer toutes les références',
    },
    {
        resourceType: 'ReportLanguageSelectionModal',
        resourceKey: 'REPORT_LANGUAGE_SELECTION_TITLE',
        resourceValue: 'Report Language Selection',
        resourceValue2: 'Sélection de la langue du rapport',
    },
    {
        resourceType: 'ReportLanguageSelectionModal',
        resourceKey: 'REPORT_LANGUAGE_SELECTION_SELECT_LABEL',
        resourceValue: 'Select language for report',
        resourceValue2: 'Sélectionnez la langue pour le rapport',
    },
    {
        resourceType: 'ReportLanguageSelectionModal',
        resourceKey: 'REPORT_LANGUAGE_SELECTION_CANCEL',
        resourceValue: 'Cancel',
        resourceValue2: 'Annuler',
    },
    {
        resourceType: 'ReportLanguageSelectionModal',
        resourceKey: 'REPORT_LANGUAGE_SELECTION_OK',
        resourceValue: 'Ok',
        resourceValue2: 'D\'accord',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_TITLE',
        resourceValue: 'Officer Report',
        resourceValue2: 'Rapport d\'officier',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_PROTECTED',
        resourceValue: 'Protected',
        resourceValue2: 'Protégée',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_TOTAL_DOCUMENTS',
        resourceValue: 'Total Documents',
        resourceValue2: 'Documents totaux',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_TOTAL_PAGES',
        resourceValue: 'Total Pages',
        resourceValue2: 'Pages totales',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_REQUEST_NUMBER',
        resourceValue: 'Request Number',
        resourceValue2: 'Numéro de demande',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_DOCUMENT_PAGE',
        resourceValue: 'Document - Page',
        resourceValue2: 'Document - Page',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_PAGINATION',
        resourceValue: 'Pagination',
        resourceValue2: 'Pagination',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_TO',
        resourceValue: 'To',
        resourceValue2: 'Pour',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_FROM',
        resourceValue: 'From',
        resourceValue2: 'Depuis',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_SUBJECT',
        resourceValue: 'Subject',
        resourceValue2: 'Sujette',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_DISCLOSURE',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_EXEMPTIONS',
        resourceValue: 'Exemptions',
        resourceValue2: 'Exonérations',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_DATE',
        resourceValue: 'Date',
        resourceValue2: 'Date',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_PAGE',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'OfficerReportLables',
        resourceKey: 'OFFICER_REPORT_REDACTION',
        resourceValue: 'Redaction',
        resourceValue2: 'Rédaction',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_TITLE',
        resourceValue: 'Requester Report',
        resourceValue2: 'Rapport du demandeur',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_REQUEST_NUMBER',
        resourceValue: 'Request Number',
        resourceValue2: 'Numéro de demande',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_TOTAL_DOCUMENTS',
        resourceValue: 'Total Documents',
        resourceValue2: 'Documents totaux',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_TOTAL_PAGES',
        resourceValue: 'Total Pages',
        resourceValue2: 'Pages totales',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_PAGES',
        resourceValue: 'Pages',
        resourceValue2: 'pages',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_DISCLOSURE',
        resourceValue: 'Disclosure',
        resourceValue2: 'Divulgation',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_EXEMPTIONS_EXCLUSIONS',
        resourceValue: 'Exemptions - Exclusions',
        resourceValue2: 'Exonérations - Exclusions',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_PAGE',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'RequesterReportLabels',
        resourceKey: 'REQUESTER_REPORT_REDACTION',
        resourceValue: 'Redaction',
        resourceValue2: 'Rédaction',
    },
    
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_TITLE',
        resourceValue: 'Pages Reviewed Summary',
        resourceValue2: 'Résumé des pages examinées',
    },
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_REQUEST_NUMBER',
        resourceValue: 'Request Number',
        resourceValue2: 'Numéro de demande',
    },
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_STACK',
        resourceValue: 'Stack',
        resourceValue2: 'Empiler',
    },
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_TOTAL_PAGES',
        resourceValue: 'Total Pages',
        resourceValue2: 'Pages totales',
    },
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_INDEXED',
        resourceValue: 'Indexed',
        resourceValue2: 'Indexée',
    },
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_REVIEWED',
        resourceValue: 'Reviewed',
        resourceValue2: 'Révisé',
    },
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_PAGE',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_REDACTION',
        resourceValue: 'Redaction',
        resourceValue2: 'Rédaction',
    },
    {
        resourceType: 'PageReviewSummaryReportLables',
        resourceKey: 'PAGES_REVIEWED_SUMMARY_PAGE',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'IndexNotesReportLables',
        resourceKey: 'INDEX_NOTES_TITLE',
        resourceValue: 'INDEX NOTES',
        resourceValue2: 'NOTES D’INDEX',
    },
    {
        resourceType: 'IndexNotesReportLables',
        resourceKey: 'INDEX_NOTES_PROTECTED',
        resourceValue: 'PROTECTED',
        resourceValue2: 'PROTÉGÉ',
    },
    {
        resourceType: 'IndexNotesReportLables',
        resourceKey: 'INDEX_NOTES_REQUEST_NUMBER',
        resourceValue: 'Request Number',
        resourceValue2: 'Numéro de la demande',
    },
    {
        resourceType: 'IndexNotesReportLables',
        resourceKey: 'INDEX_NOTES_DOCUMENT_PAGE',
        resourceValue: 'Document - Page',
        resourceValue2: 'Document - Page',
    },
    {
        resourceType: 'IndexNotesReportLables',
        resourceKey: 'INDEX_NOTES_PAGINATION',
        resourceValue: 'Pagination',
        resourceValue2: 'Pagination',
    },
    {
        resourceType: 'IndexNotesReportLables',
        resourceKey: 'INDEX_NOTES_NOTES_LABEL',
        resourceValue: 'Notes',
        resourceValue2: 'Notes',
    },
    {
        resourceType: 'IndexNotesReportLables',
        resourceKey: 'INDEX_NOTES_PAGE',
        resourceValue: 'Page',
        resourceValue2: 'Page',
    },
    {
        resourceType: 'IndexNotesReportLables',
        resourceKey: 'INDEX_NOTES_REDACTION',
        resourceValue: 'Redaction',
        resourceValue2: 'Rédaction',
    },
];

export default resourceBundle;

export const SET_SEARCH_PARAM = 'reduction/SET_SEARCH_PARAM';
export const SEARCH_VALUE_SUCCESS = 'reduction/SEARCH_VALUE_SUCCESS';
export const SEARCH_VALUE_PENDING = 'reduction/SEARCH_VALUE_PENDING';
export const SEARCH_VALUE_FAILURE = 'reduction/SEARCH_VALUE_FAILURE';
export const SET_CURRENT_SEARCH_ELEMENT = 'reduction/SET_CURRENT_SEARCH_ELEMENT';
export const SET_SEARCH_CURRENT_PAGE_ID = 'reduction/SET_SEARCH_CURRENT_PAGE_ID';
export const CLEAR_SEARCH_ACTION = 'reduction/CLEAR_SEARCH_ACTION';
export const APPLY_SEARCH_PENDING =  'reduction/APPLY_SEARCH_PENDING';

export const SET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING = 'redaction/SET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING';
export const SET_GLOBAL_SEARCH_COLUMN_HEADER_LIST_SUCCESS = 'redaction/SET_GLOBAL_SEARCH_COLUMN_HEADER_LIST_SUCCESS';
export const SET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE = 'redaction/SET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE';

export const SET_GLOBAL_SEARCH_TABLE_COLUMNS = 'reduction/SET_GLOBAL_SEARCH_TABLE_COLUMNS';

export const MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_PENDING = 'redaction/MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_PENDING';
export const MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS = 'redaction/MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS';
export const MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE = 'redaction/MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE';

export const RESET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING = 'redaction/RESET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING';
export const RESET_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS = 'redaction/RESET_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS';
export const RESET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE = 'redaction/RESET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE';

export const RESTRICT_ON_FETCH_DATA_FOR_PAGE_LIST = 'redaction/RESTRICT_ON_FETCH_DATA_FOR_PAGE_LIST';
export const SET_ELEMENT_SEARCH_DIRECTION = 'redaction/SET_ELEMENT_SEARCH_DIRECTION';
export const SET_PREVIOUS_SEARCH_ELEMENT = 'redaction/SET_PREVIOUS_SEARCH_ELEMENT';

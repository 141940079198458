import {
    LOAD_GLOBAL_SETTINGS_PENDING,
    LOAD_GLOBAL_SETTINGS_SUCCESS,
    LOAD_GLOBAL_SETTINGS_FAILURE,
    UPDATE_GLOBAL_SETTINGS_PENDING,
    UPDATE_GLOBAL_SETTINGS_SUCCESS,
    UPDATE_GLOBAL_SETTINGS_FAILURE,
} from './constants';
import { AnyAction } from 'redux';
import { IGlobalSettingsState, IRequestTypeDefaultDisclosureList, IRequestTypes } from './globalSettings.model';

const initialState: IGlobalSettingsState = {
    disclosureSettings: {
        requestTypesWithDisclosureTypes: null,
    },
    basicSettings: null,
    exemptionSettings: null,
    globalSettingsPending: false,
    updateGlobalSettingsPending: false,
    error: null,
};

const globalSettingsReducer = (
    state: IGlobalSettingsState = initialState,
    { type, payload }: AnyAction,
): IGlobalSettingsState => {
    switch (type) {
        case LOAD_GLOBAL_SETTINGS_PENDING:
            return {
                ...state,
                globalSettingsPending: true,
                error: null,
            };
        case LOAD_GLOBAL_SETTINGS_SUCCESS:
            return{
                ...state,
                disclosureSettings: payload.disclosureSettings,
                exemptionSettings: payload.exemptionSettings,
                basicSettings: payload.basicSettingsDto,
                globalSettingsPending: false,
            };
        case LOAD_GLOBAL_SETTINGS_FAILURE:
            return{
                ...state,
                error: payload,
                globalSettingsPending: false,
            };
        case UPDATE_GLOBAL_SETTINGS_PENDING:
            return {
                ...state,
                updateGlobalSettingsPending: true,
                error: null,
            };
        case UPDATE_GLOBAL_SETTINGS_SUCCESS:
            return{
                ...state,
                disclosureSettings: {
                    requestTypesWithDisclosureTypes: state.disclosureSettings.requestTypesWithDisclosureTypes.map(
                        (disclosure: IRequestTypes): IRequestTypes => {
                            const currentDisclosure = payload.disclosureSettings.requestTypesWithDisclosureTypes.filter(
                                (updatedDisclosure: IRequestTypeDefaultDisclosureList): boolean =>
                                    updatedDisclosure.requestType === disclosure.requestType);

                            return currentDisclosure.length ? {
                                ...disclosure,
                                ...currentDisclosure[0],
                            } : disclosure;
                        }),
                },
                exemptionSettings: payload.exemptionSettings,
                basicSettings: payload.basicSettingsDto,
                updateGlobalSettingsPending: false,
            };
        case UPDATE_GLOBAL_SETTINGS_FAILURE:
            return{
                ...state,
                error: payload,
                updateGlobalSettingsPending: false,
            };
        default:
            return state;
    }
};

export default globalSettingsReducer;

import { Vector2d } from 'konva/types/types';
import { ISize } from '../containers/shapesLayer/shapesLayer.model';
import { ICoordinate } from '../redux/reducers/pageList/pageList.model';
import { END_POINT, START_POINT, COORDINATE } from '../constants/annotationTypes.constants';
import { MIN_SHAPE_SIZE } from '../containers/shapesLayer/Transformer';
import { degrees } from '../constants';

export const DEFAULT_POSITION = 0;

export const setPointLimits =
    (coordinate: number, limit: number, defaultPosition: number = DEFAULT_POSITION): number => {
    let pointCoordinate = coordinate;

    if (pointCoordinate < defaultPosition) {
        pointCoordinate = defaultPosition;
    } else if (pointCoordinate > limit) {
        pointCoordinate = limit;
    }

    return pointCoordinate;
};

export const dragBoundHandler = (container: ISize, width: number, height: number, position: Vector2d,
                                 pageRotation: number, invert: boolean = false, rotate: number = 0): Vector2d => {
    if (!invert) {
        if (pageRotation === degrees.ZERO) {
            return {
                x: setPointLimits(position.x,
                    rotate === 0 ? container.width - (width > 0 ? width : 0) : container.width,
                    rotate === 0 ? (width < 0 ? -width : 0) : width),
                y: setPointLimits(position.y,
                    rotate === 0 ? container.height - height: container.height, rotate === 0 ? 0 : height),
            };
        }

        if (pageRotation === degrees.MINUS_QUARTER) {
            return {
                x: setPointLimits(position.x,
                    rotate === 0 ? container.width : container.width - height, rotate === 0 ? height : 0),
                y: setPointLimits(position.y,
                    rotate === 0 ? container.height - (width < 0 ? 0 : width) : container.height ,
                    rotate === 0 ? (width > 0 ? 0 : -width) : width),
            };

        }

        if (pageRotation === degrees.HALF) {
            return {
                x: setPointLimits(position.x,
                    rotate === 0 ? container.width - (width < 0 ? -width : 0) : container.width - width,
                    rotate === 0 ? (width < 0 ? 0 : width) : 0),
                y: setPointLimits(position.y,
                    rotate === 0 ? container.height : container.height - height, rotate === 0 ? height : 0),
            };
        }

        if (pageRotation === degrees.THREE_QUARTERS) {
            return  {
                x: setPointLimits(position.x,
                    rotate === 0 ? container.width - height : container.width, rotate === 0 ? 0 : height),
                y: setPointLimits(position.y,
                    rotate === 0 ? container.height - (width < 0 ? -width : 0) : container.height - width,
                    rotate === 0 ? (width < 0 ? 0 : width) : 0),
            };
        }
    }

    if (pageRotation === degrees.ZERO) {
        return {
            x: setPointLimits(position.x,
                rotate === 90 ? container.width: container.width - height, rotate === 90 ? height : 0),
            y: setPointLimits(position.y,
                rotate === 90 ? container.height - width : container.height, rotate === 90 ? 0 : width),
        };
    }

    if (pageRotation === degrees.MINUS_QUARTER) {
        return {
            x: setPointLimits(position.x,
                rotate === 90 ? container.width : container.width - width, rotate === 90 ? width : 0),
            y: setPointLimits(position.y,
                rotate === 90 ? container.height : container.height - height, rotate === 90 ? height : 0),
        };

    }

    if (pageRotation === degrees.HALF) {
        return {
            x: setPointLimits(position.x,
                rotate === 90 ? container.width - height : container.width, rotate === 90 ? 0 : height),
            y: setPointLimits(position.y,
                rotate === 90 ? container.height : container.height - width, rotate === 90 ? width : 0),
        };
    }

    if (pageRotation === degrees.THREE_QUARTERS) {
        return  {
            x: setPointLimits(position.x,
                rotate === 90 ? container.width - width : container.width, rotate === 90 ? 0 : width),
            y: setPointLimits(position.y,
                rotate === 90 ? container.height - height: container.height, rotate === 90 ? 0 : height),
        };
    }

};

// any as generic and union doesn't works correct with spread operator
export const recalculateShape = (data: any, scale: number, invert: boolean = false): any => {
    return {
        ...(data as object),
        ...(data.fontSize && { fontSize: data.fontSize / scale }),
        coordinate: data.coordinate.map((item: ICoordinate) => {
            const isHandledBottomUp = item[END_POINT].y < item[START_POINT].y;
            const scaledStartX = item[START_POINT].x / scale;
            const scaledStartY = isHandledBottomUp ? item[END_POINT].y /scale : item[START_POINT].y / scale;
            const scaledEndX = item[END_POINT].x / scale;
            const scaledEndY = isHandledBottomUp ? item[START_POINT].y / scale : item[END_POINT].y / scale;
            const width = invert ? scaledStartX  - scaledEndX : scaledEndX - scaledStartX;
            const height = scaledEndY - scaledStartY;

            return {
                [START_POINT]: {
                    x: item[START_POINT].x / scale,
                    y: isHandledBottomUp ? item[END_POINT].y / scale : item[START_POINT].y / scale,
                },
                [END_POINT]: {
                    x: width > MIN_SHAPE_SIZE ? scaledEndX : scaledEndX + MIN_SHAPE_SIZE,
                    y: height > MIN_SHAPE_SIZE ? scaledEndY : scaledEndY + MIN_SHAPE_SIZE,
                },
            };
        }),
    };
};
/* tslint:disable:no-string-literal */
export const getGroupMinX = <G extends object>(group: G[]): number => group.reduce((prev: G, curr: G): G => (
    prev[COORDINATE][START_POINT].x < curr[COORDINATE][START_POINT].x ? prev : curr))[COORDINATE][START_POINT].x;

export const getGroupMaxX = <G extends object>(group: G[]): number => group.reduce((prev: G, curr: G): G => (
    prev[COORDINATE][END_POINT].x > curr[COORDINATE][END_POINT].x ? prev : curr))[COORDINATE][END_POINT].x;

export const getGroupMinY = <G extends object>(group: G[]): number => group.reduce((prev: G, curr: G): G => (
    prev[COORDINATE][START_POINT].y < curr[COORDINATE][START_POINT].y ? prev : curr))[COORDINATE][START_POINT].y;

export const getGroupMaxY = <G extends object>(group: G[]): number => group.reduce((prev: G, curr: G): G => (
    prev[COORDINATE][END_POINT].y > curr[COORDINATE][END_POINT].y ? prev : curr))[COORDINATE][END_POINT].y;
/* tslint:enable:no-string-literal */

import { IDuplicateTableHeader } from './duplicateTable/table.model';
import { IHeader } from '../../constants/leftSidebar.config';
import * as constants from '../../constants/duplicates.constants';

export const DuplicatesListHeaders = (customFields: IHeader[]): IDuplicateTableHeader[]  => {
    return [
        {
            title: constants.getManageDuplicatesLabelsByKey('General Attributes'),
            list: [
                {
                    accessorKey: 'documentName',
                    id: 'name',
                    header: constants.getManageDuplicatesLabelsByKey('Document Name'),
                    minResizeWidth: 1,
                    show: true,
                    alwaysShow: true,
                    enableHiding : false,
                }, {
                    accessorKey: 'disclosure',
                    id: 'disclosureType',
                    header: constants.getManageDuplicatesLabelsByKey('Disclosure'),
                    minResizeWidth: 1,
                    show: true,
                    alwaysShow: true,
                    enableHiding : false,
                }, {
                    accessorKey: 'start',
                    id: 'start',
                    header: constants.getManageDuplicatesLabelsByKey('Start'),
                    minResizeWidth: 1,
                    show: true,
                    alwaysShow: true,
                    enableHiding : false,
                },
            ],
        },
        {
            title: constants.getManageDuplicatesLabelsByKey('Index Attributes'),
            list: [{
                accessorKey: 'subject',
                id: 'subject',
                header: constants.getManageDuplicatesLabelsByKey('Subject'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'to',
                id: 'to',
                header: constants.getManageDuplicatesLabelsByKey('To'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'from',
                id: 'from',
                header: constants.getManageDuplicatesLabelsByKey('From'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'date',
                id: 'docDate',
                header: constants.getManageDuplicatesLabelsByKey('Date'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'classification',
                id: 'classification',
                header: constants.getManageDuplicatesLabelsByKey('Classification'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'language',
                id: 'language',
                header: constants.getManageDuplicatesLabelsByKey('Language'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'toOrg',
                id: 'toOrg',
                header: constants.getManageDuplicatesLabelsByKey('To Org.'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'fromOrg',
                id: 'fromOrg',
                header: constants.getManageDuplicatesLabelsByKey('From Org.'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'docType',
                id: 'docType',
                header: constants.getManageDuplicatesLabelsByKey('Document Type'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'referenceNumber',
                id: 'referenceNumber',
                header: constants.getManageDuplicatesLabelsByKey('Reference Number'),
                minResizeWidth: 1,
                show: true,
            },{
                accessorKey: 'field1',
                id: 'field1',
                header: constants.getManageDuplicatesLabelsByKey('Field 1'),
                minResizeWidth: 1,
                show: true,
            },
            ...customFields,
            ],
        },
        {
            title: constants.getManageDuplicatesLabelsByKey('Document Attributes'),
            list: [{
                accessorKey: 'originalName',
                id: 'originalName',
                header: constants.getManageDuplicatesLabelsByKey('Filename'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'author',
                id: 'author',
                header: constants.getManageDuplicatesLabelsByKey('Author'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'pageCount',
                id: 'pages',
                header: constants.getManageDuplicatesLabelsByKey('Pages'),
                minResizeWidth: 1,
                show: true,
            }, {
                accessorKey: 'fileSize',
                id: 'fileSize',
                header: constants.getManageDuplicatesLabelsByKey('File Size'),
                minResizeWidth: 1,
                show: true,
            },
            ],
        },

    ];
};

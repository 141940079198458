import {
    degrees,
    SCALE_STEP,
    scaleDirection,
    FULL_WIDTH_NAME,
    PAGE_WIDTH_NAME,
    FULL_PAGE_NAME,
    MARGIN_HIDE_SCROLL,
    MARGIN_PDF,
    FULL_WIDTH_VAL,
    FULL_PAGE_VAL,
    PAGE_WIDTH_VAL,
} from '../constants';
import { IClientSize } from '../containers/redactor/redactor.model';

export const rotate = (degree: number, prevDegree: number): number => {
    // available rotate 0 || 90 || 180 || 270
    let currentDegree = prevDegree;

    if (currentDegree === degrees.ZERO && degree === degrees.MINUS_QUARTER) {
        currentDegree = degrees.FULL;
    }

    if (currentDegree === degrees.THREE_QUARTERS && degree === degrees.QUARTER) {
        currentDegree = degrees.MINUS_QUARTER;
    }

    if (degree === degrees.HALF) {
        switch (currentDegree) {
            case degrees.ZERO:
                currentDegree = degrees.ZERO;
                break;
            case degrees.QUARTER:
                currentDegree = degrees.QUARTER;
                break;
            case degrees.HALF:
                currentDegree = degrees.MINUS_HALF;
                break;
            case degrees.THREE_QUARTERS:
                currentDegree = degrees.MINUS_QUARTER;
                break;
            default:
                break;
        }
    }

    return currentDegree + degree;
};

export const zoom = (direction: string, scale: number): number => {
    if (direction === scaleDirection.UP) {
        return scale + SCALE_STEP;
    }

    return scale - SCALE_STEP < 0 ? 0 : scale - SCALE_STEP;
};

export const size = (
    prevSize: string,
    clientSize: IClientSize,
    realPdfWidth: number,
    realPdfHeight: number,
    isRotated: boolean): {pdfSize: number | null , pdfScale: number} => {
    if (prevSize === FULL_WIDTH_NAME) {
        return {
            pdfSize: FULL_WIDTH_VAL,
            pdfScale: realPdfWidth ? realPdfHeight / realPdfWidth : realPdfHeight / 100,
        };
    } else if (prevSize === PAGE_WIDTH_NAME) {
        return getPageWidthSize(isRotated? realPdfHeight : realPdfWidth);
    } else if (prevSize === FULL_PAGE_NAME) {
        let scale=1;

        if (realPdfWidth > realPdfHeight && realPdfHeight < clientSize.clientHeight) {
            scale = (clientSize.clientWidth - MARGIN_HIDE_SCROLL) /
                ((isRotated ? realPdfHeight : realPdfWidth) + MARGIN_PDF);
        } else {
            scale = (clientSize.clientHeight - MARGIN_HIDE_SCROLL) /
                ((isRotated ? realPdfWidth : realPdfHeight || 100) + MARGIN_PDF);
        }

        return {
            pdfSize: FULL_PAGE_VAL,
            pdfScale: scale,
        };
    }

    const prevSizeFormatted = Number(prevSize.replace(/%/g,''));
    const pdfScale = prevSizeFormatted / 100;

    return {
        pdfSize: prevSizeFormatted,
        pdfScale,
    };
};

export const getPageWidthSize = (realPdfWidth: number): {pdfSize: number | null, pdfScale: number} => {
    const right = JSON.parse(localStorage.getItem('rightPanel'));
    const left = JSON.parse(localStorage.getItem('leftPanel'));
    const windowWidth = (window as any).innerWidth;
    let redactorWidth = 0;

    if (right && left) {
        redactorWidth = windowWidth - ((right as any).barWidth + (left as any).barWidth) - 60;
    }

    return {
        pdfSize: PAGE_WIDTH_VAL,
        pdfScale: redactorWidth / realPdfWidth,
    };
};

import React, { useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Scrollbars } from 'rc-scrollbars';
import { MenuItem, FormControlLabel, Checkbox, Divider, Button } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { ISortableContainerProps, ISortableItemProps } from './settingsTable.model';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getItem, getSessionItem } from '../../../utils/localStorage.util';
import { MODIFIED_LABELS, REDACTION_LANG } from '../../../redux/reducers/localStorage/constant';
import { changeLang, commonLabels, getCommonLabelsByKey } from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';

const SortableItem: React.FC<ISortableItemProps> = ({ item, handleClickCheckbox, index, handleDrop }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'sortable-item',
        item: { id: item.id, index },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'sortable-item',
        drop: (draggedItem: { index: number }) => {
            if (draggedItem.index !== index) {
                handleDrop(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const style = {
        opacity: isDragging ? 0.5 : 1,
    };

    return (
        <MenuItem ref={(node) => drag(drop(node))} style={style}>
            <FormControlLabel
                control={<Checkbox checked={item.show} onChange={() => handleClickCheckbox(item.id, item.show)} />}
                label={item.header}
            />
        </MenuItem>
    );
};

const SortableList: React.FC<ISortableContainerProps> = ({ items, handleClickCheckbox, resetColumns, handleDrop, columnOrder, handleShowAll, handleHideAll }) => {
    const sortedItems = columnOrder.map(orderId => items.find(item => item.id === orderId));

    const redactionLang = getSessionItem(REDACTION_LANG);
    const langRule = changeLang(redactionLang);

    return (
        <DndProvider backend={HTML5Backend}>
            <Scrollbars autoHeight autoHeightMax={400}>
                {sortedItems.map((item, index) => (
                    <SortableItem
                        key={item.id}
                        index={index}
                        item={item}
                        handleClickCheckbox={handleClickCheckbox}
                        handleDrop={handleDrop}
                    />
                ))}
            </Scrollbars>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <Button onClick={handleHideAll} startIcon={<VisibilityOffIcon />}>
                    {commonLabels.hideAllLabel[langRule]}
                </Button>
                <Button onClick={resetColumns} startIcon={<AutorenewIcon />}>
                    {commonLabels.resetLabel[langRule]}
                </Button>
                <Button onClick={handleShowAll} startIcon={<VisibilityIcon />}>
                    {commonLabels.showAllLabel[langRule]}
                </Button>
            </div>
        </DndProvider>
    );
};

export default SortableList;
import * as React from 'react';
import { IProps } from './presetName.model';
import { DELETE } from '../../../../../constants/common.constants';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    CONFIRMATION_DIALOG_MODAL,
    DELETE_PRESET_MESSAGE,
    WARNING,
} from '../../../../../constants/messages.constants';

const PresetName = (props: IProps): JSX.Element => {
    const { handlePresetName, text, id, active, deletePermission, parentLabel, langRule } = props;

    const handleClick = (value: number): () => void => (): void => {
        handlePresetName(value);
    };

    const handleDelete = (selectedId: number): () => void => (): void => {
        props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: `Delete-${selectedId}`,
            title: parentLabel && parentLabel.warningLabel[langRule],
            message: <div>{parentLabel && `${parentLabel.deletePreset[langRule]} ${parentLabel.pressOkContinue[langRule]}`}</div>,
            confirm: (): () => void => (): void => {
                props.deletePackagePreset(selectedId);
                props.handleShowTabs(false);
            },
        });

    };

    return (
        <div className='preset-item-wrapper' onClick={handleClick(id)}>
            <div className={`preset-item ${active}`}>{text}</div>
            {
                deletePermission && (
                    <IconButton
                        aria-label={DELETE}
                        className='delete-preset'
                        onClick={handleDelete(id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                )
            }
        </div>
    );
};

export default PresetName;

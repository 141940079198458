import * as React from 'react';
import { IModalArticleStampsSelectProps } from './modalArticle.model';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';

export const ModalArticleStampsSelect = (
  { label, value, options, onChangeHandler, fullWidth }: IModalArticleStampsSelectProps): JSX.Element => (
  <FormControl variant='outlined' fullWidth={fullWidth}>
    <InputLabel>{label}</InputLabel>
    <Select
      defaultValue=""
      value={value}
      onChange={onChangeHandler}
      input={
        <OutlinedInput
          label={100}
        />
      }
    >
      {
        options.map((option: string): JSX.Element => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))
      }
    </Select>
  </FormControl>
);

import * as React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import './modalIndexKeywordManagement.styles.scss';
import { Scrollbars } from 'rc-scrollbars';
import { connect } from 'react-redux';
import { IState as StoreState } from '../../../redux/store';
import { getIndexMetadataDescriptionsData } from '../../../redux/selectors/indexMetadataDescriptions';
import {
    fetchIndexMetadataDescriptions,
    clearIndexMetadataDescriptions,
    addIndexMetadataDescriptions,
    removeIndexMetadataDescriptions,
    editIndexMetadataDescriptions,
    saveIndexMetadataDescriptions,
    replaceIndexMetadataDescriptions,
    replaceStringMetadataDescriptions,
    exportStringMetadataDescriptions,
} from '../../../redux/actions/indexMetadataDescriptions';
import { ReplaceIndex } from './replaceIndex/ReplaceIndex';
import { IndexDescription } from './indexDescription/IndexDescription';
import { StringDescription } from './stringDescription/StringDescription';
import {
    IKeywordManagementDispatchToProps,
    IKeywordManagementProps,
    IKeywordManagementState, IKeywordManagementStateToProps,
    IReplaceData,
} from './modalIndexKeywordManagement.model';
import {
    IDescription,
    IDescriptionItem,
    ILookup,
} from '../../../redux/reducers/indexMetadataDescriptions/indexMetadataDescriptions.model';
import { ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { IState } from '../../../redux/store';
import { uniq } from 'lodash';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import resourceBundle from '../../localization/localizationData';
import { initialLabel } from '../../../constants/localization.constants';

export enum FieldTypes {
    INDEX = 'Index',
    STRING = 'String',
}

export const getIndexKeywordLabelsByKey = (key: string): string => {
    switch(key) {
        case 'INDEX_KEYWORD_MANAGEMENT_MODAL_DESCRIPTION':
            return 'descriptionLabel';
        case 'INDEX_KEYWORD_MANAGEMENT_MODAL_DESCRIPTION_FR':
            return 'descriptionFrLabel';
        case 'INDEX_KEYWORD_MANAGEMENT_MODAL_OCCURENCES':
            return 'occurancesLabel';
        case 'INDEX_KEYWORD_MANAGEMENT_MODAL_REPLACE':
            return 'replaceLabel';
        case 'COMMON_LABEL_DELETE':
            return 'deleteLabel';
        case 'COMMON_LABEL_ADD_NEW':
            return 'addNewLabel';
        case 'INDEX_KEYWORD_MANAGEMENT_MODAL_REFRESH':
            return 'refreshLabel';
        case 'COMMON_LABEL_EXPORT':
            return 'exportLabel';
        case 'COMMON_LABEL_BACK':
            return 'backLabel';
        case 'COMMON_LABEL_SAVE':
            return 'saveLabel';
        case 'INDEX_KEYWORD_MANAGEMENT_MODAL_CHOOSE_REPLACE':
            return 'chooseReplaceLabel';
        default: return '';
    }
};

export class ModalIndexKeywordManagement extends React.Component <IKeywordManagementProps, IKeywordManagementState> {

    constructor(props: IKeywordManagementProps) {
        super(props);

        this.state = {
            selectedTab: 0,
            isReplaceOpened: false,
            replaceId: null,
            replaceValueId: null,
        };
    }

    public componentDidMount(): void {
        this.props.fetchMetadataDescriptions();
    }

    public componentWillUnmount(): void {
        this.props.clearMetadataDescriptions();
    }

    public render(): JSX.Element {
        const {
            metadataDescriptions,
            permissions: {
                ADD: addPermission,
                DELETE: deletePermission,
                EDIT: editPermission,
            },
            modifiedLabels,
            redactionLang,
        } = this.props;
        const langRule = redactionLang ? redactionLang === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';
        const { selectedTab, isReplaceOpened, replaceId, replaceValueId } = this.state;
        const allOptions = metadataDescriptions.length
            ? metadataDescriptions[selectedTab].data.map((item: IDescriptionItem) => {
                    return {
                        value: ((item.value) && (item.value as ILookup).id)
                            ? String((item.value as ILookup).id)
                            : (item.value as string),
                        label: ((item.value) && (item.value as ILookup).description)
                            ? redactionLang ? redactionLang === 'fr' ? (item.value as ILookup).description2
                            ? (item.value as ILookup).description2 : (item.value as ILookup).description
                            :(item.value as ILookup).description : (item.value as ILookup).description
                            : (item.value as string),
                    };
                })
            : [];
        const options = allOptions.filter((item: ISelectOptions, i: number) => i !== replaceId);
        const labels = {
            descriptionLabel: initialLabel,
            descriptionFrLabel: initialLabel,
            occurancesLabel: initialLabel,
            replaceLabel: initialLabel,
            deleteLabel: initialLabel,
            addNewLabel: initialLabel,
            refreshLabel: initialLabel,
            exportLabel: initialLabel,
            saveLabel: initialLabel,
            backLabel: initialLabel,
            chooseReplaceLabel: initialLabel,
        };

        resourceBundle.map( (resource: any) => {
            if (getIndexKeywordLabelsByKey(resource.resourceKey)) {
                labels[getIndexKeywordLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map( (resource: any) => {
            if (getIndexKeywordLabelsByKey(resource.resourceKey)) {
                labels[getIndexKeywordLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        return (
            !isReplaceOpened
                ? (
                    <div className='modal-index-keyword'>
                        <div className='modal-index-keyword_wrapper'>
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={this.tabsHandleChange}
                                indicatorColor='primary'
                                textColor='primary'
                                variant='scrollable'
                                ScrollButtonComponent={this.renderScrollNavButtons}
                                className='modal-index-keyword_tabs'
                            >
                                {
                                    metadataDescriptions
                                        .map((tab: IDescription, i: number): JSX.Element =>
                                            <Tab
                                                key={i}
                                                label={ redactionLang ? redactionLang === 'fr' ? tab.description_fr
                                                    ? tab.description_fr : tab.description : tab.description
                                                    : tab.description}
                                            />)
                                }
                            </Tabs>
                            <div className='modal-index-keyword_body'>
                                <Scrollbars>
                                    {
                                        metadataDescriptions[selectedTab]
                                        && metadataDescriptions[selectedTab].dataType === FieldTypes.INDEX
                                        && <IndexDescription
                                            selectedTab={selectedTab}
                                            descriptions={metadataDescriptions[selectedTab].data}
                                            handleChange={this.onChange}
                                            handleReplace={this.openReplace}
                                            handleRemove={this.removeItem}
                                            handleAdd={this.addItem}
                                            editPermission={editPermission}
                                            addPermission={addPermission}
                                            deletePermission={deletePermission}
                                            labels={labels}
                                            language={redactionLang}
                                        />
                                    }
                                    {
                                        metadataDescriptions[selectedTab]
                                        && metadataDescriptions[selectedTab].dataType === FieldTypes.STRING
                                        && <StringDescription
                                            descriptions={metadataDescriptions[selectedTab].data}
                                            disableReplace={options.length <= 1}
                                            editPermission={editPermission}
                                            handleReplace={this.openReplace}
                                            labels={labels}
                                            langRule={langRule}
                                        />
                                    }
                                </Scrollbars>
                            </div>
                        </div>
                        <div className='modal-index-keyword_bottom'>
                            <Button
                                variant='outlined'
                                className='modal-window__buttons outlined'
                                onClick={this.props.fetchMetadataDescriptions}
                            >
                                {labels.refreshLabel[langRule]}
                            </Button>
                            <Button
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    className='primary'
                                    onClick={this.handleExport}
                            >
                               {labels.exportLabel[langRule]}
                            </Button>
                            <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                disabled={this.isSaveDisabled()}
                                onClick={this.props.saveIndexMetadataDescriptions}
                            >
                                {labels.saveLabel[langRule]}
                            </Button>
                        </div>
                    </div>
                )
            : (
                    <ReplaceIndex
                        options={options}
                        currentValue={String(replaceValueId)}
                        handleSelect={(value: number | string): void => this.setState({replaceValueId: value})}
                        handleClose={this.closeReplace}
                        handleSave={this.saveReplace}
                        labels={labels}
                        langRule={langRule}
                    />
                )
        );
    }

    private renderScrollNavButtons = (props: {
        direction: string;
        onClick: () => any;
        disabled: boolean;
    }): JSX.Element => {
        const { direction, onClick, disabled } = props;
    
        if (!disabled && direction === 'left') {
            return (
                <IconButton className='scroll-nav-button' onClick={onClick}>
                    <ChevronLeft />
                </IconButton>
            );
        } else if (!disabled && direction === 'right') {
            return (
                <IconButton className='scroll-nav-button' onClick={onClick}>
                    <ChevronRight />
                </IconButton>
            );
        }
    
        return <IconButton disabled={true} className='scroll-nav-button' />;
    }
    

    private isSaveDisabled = (): boolean => {
        const editableDescriptions = this.props.metadataDescriptions
            .filter((item: IDescription) => item.dataType  === FieldTypes.INDEX);

        const allFieldsDescriptions = editableDescriptions.reduce((acc: IDescriptionItem[], item: IDescription) => {
            return [
                ...acc,
                ...item.data.map((field: IDescriptionItem) => (field.value as ILookup).description),
            ];
        }, []);

        const hasDuplicates = editableDescriptions
            .map((item: IDescription) =>
                item.data.map((data: IDescriptionItem) => (data.value as ILookup).description))
            .some((item: string[]) => uniq(item).length !== item.length);

        return hasDuplicates ||
            allFieldsDescriptions.some((item: IDescriptionItem) => !item) ||
            !this.props.permissions.EDIT;
    }

    private handleExport = (): void => {
        const { selectedTab } = this.state;
        const { metadataDescriptions } = this.props;
        const selectedMetadata = metadataDescriptions[selectedTab];

        this.props.exportStringMetadataDescriptions(selectedMetadata.id);
    }

    private tabsHandleChange = (event: React.SyntheticEvent, selectedTab: number): void => {
        this.setState({selectedTab});
    }

    private addItem = (): void => {
        this.props.addIndexMetadataDescriptions(this.props.metadataDescriptions[this.state.selectedTab].id);
    }

    private removeItem = (descriptionId: number, dataIndex: number, id: number): void => {
        this.props.removeIndexMetadataDescriptions(descriptionId, dataIndex, id);
    }

    private onChange = (value: string, descriptionId: number, dataIndex: number, param: string): void => {
        this.props.editIndexMetadataDescriptions(value, descriptionId, dataIndex, param);
    }

    private openReplace = (dataIndex: number): void => {
        this.setState({isReplaceOpened: true, replaceId: dataIndex});
    }

    private closeReplace = (): void => {
        this.setState({isReplaceOpened: false, replaceId: null, replaceValueId: null});
    }

    private saveReplace = (): void => {
        const { metadataDescriptions } = this.props;
        const {replaceValueId} = this.state;
        const selectedTab = metadataDescriptions[this.state.selectedTab];
        const selectedField = selectedTab.data[this.state.replaceId];

        if (typeof selectedField.value === 'string') {
            this.props.replaceStringMetadataDescriptions(selectedTab.id, {
                fromReplace: selectedField.value,
                toReplace: String(this.state.replaceValueId),
            });
        } else {
            const toReplace = selectedTab.data
                .find((item: IDescriptionItem) => (item.value as ILookup).id === Number(replaceValueId));

            this.props.replaceIndexMetadataDescriptions(selectedTab.id, {
                fromReplace: selectedField.value,
                toReplace: toReplace.value,
            });
        }

        this.closeReplace();
    }
}

const mapStateToProps = (state: StoreState): IKeywordManagementStateToProps => ({
    metadataDescriptions: getIndexMetadataDescriptionsData(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<IState,IKeywordManagementDispatchToProps, AnyAction>): IKeywordManagementDispatchToProps =>
        ({
            fetchMetadataDescriptions: (): void => {
                dispatch(fetchIndexMetadataDescriptions());
            },
            clearMetadataDescriptions: (): void => {
                dispatch(clearIndexMetadataDescriptions());
            },
            addIndexMetadataDescriptions: (selectedTabId: number): void => {
                dispatch(addIndexMetadataDescriptions(selectedTabId));
            },
            removeIndexMetadataDescriptions: (descriptionId: number, dataIndex: number, id: number): void => {
                dispatch(removeIndexMetadataDescriptions(descriptionId, dataIndex, id));
            },
            editIndexMetadataDescriptions: (value: string, descriptionId: number, dataIndex: number, param: string)
                : void => {
                dispatch(editIndexMetadataDescriptions(value,descriptionId, dataIndex, param));
            },
            saveIndexMetadataDescriptions: (): void => {
                dispatch(saveIndexMetadataDescriptions());
            },
            replaceIndexMetadataDescriptions: (metadataId: number, replaceData: IReplaceData): void => {
                dispatch(replaceIndexMetadataDescriptions(replaceData));
            },
            replaceStringMetadataDescriptions: (metadataId: number, replaceData: IReplaceData): void => {
                dispatch(replaceStringMetadataDescriptions(metadataId, replaceData));
            },
            exportStringMetadataDescriptions: (
                metadataId: number,
            ): void => {
                dispatch(exportStringMetadataDescriptions(metadataId));
            },
        });

export default connect(mapStateToProps, mapDispatchToProps)(ModalIndexKeywordManagement);

import { useEffect, useRef } from 'react';

// this helper function can accept any type of data
export const usePrevious = (value: any): any=> {
    const ref = useRef();

    useEffect(() => {
        // TS doesn't recognize ref value and equates it to undefined
        // @ts-ignore
        ref.current = value;
    });

    return ref.current;
};

import { AnyAction } from 'redux';
import { IPageIndexState } from './pageIndex.model';
import {
    FETCH_PAGE_INDEX_FAIL,
    FETCH_PAGE_INDEX_PENDING,
    FETCH_PAGE_INDEX_SUCCESS,
    SET_PAGE_INDEX_VALUE,
    CLEAR_PAGE_INDEX,
} from './constant';
import { IIndex } from '../indexMetadata/indexMetadata.model';

export const initialState: IPageIndexState = {
    pending: false,
    error: null,
    pageIndex: [],
};
export const pageIndexReducer =
    (state: IPageIndexState = initialState, { type, payload }: AnyAction): IPageIndexState => {
    switch (type) {
        case FETCH_PAGE_INDEX_PENDING:
            return {
                ...state,
                pending: true,
            };
        case FETCH_PAGE_INDEX_FAIL:
            return {
                ...state,
                pending: false,
                error: payload,
            };
        case FETCH_PAGE_INDEX_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                pageIndex: payload,
            };
        case SET_PAGE_INDEX_VALUE:
            return {
                ...state,
                pending: false,
                error: null,
                pageIndex: state.pageIndex.map((index: IIndex) => {
                    return index.id === payload.id ? {
                        ...index,
                        value: payload.value,
                    } : index;
                }),
            };
        case CLEAR_PAGE_INDEX:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default pageIndexReducer;

import { v4 as uuidv4 } from 'uuid';
import { IState } from '../store';
import { IAnnotation } from '../reducers/pageList/pageList.model';
import { IKonvaRectangle } from '../../containers/shapesLayer/shapesLayer.model';
import { END_POINT, START_POINT } from '../../constants/annotationTypes.constants';
import { DEFAULT_OPACITY } from '../../constants/annotation.constants';
import { ANNOTATION } from '../../constants/common.constants';
import { createSelector } from 'reselect';
import { reductionState } from './redactor';
import { IRedactorState } from '../reducers/redactor/redactor.model';
import { IAnnotationState } from '../../redux/reducers/annotation/annotation.model';

export const getAnnotationLayouts = (state: IState): IAnnotation[] => state.annotation.annotations;
export const getAnnotationState = (state: IState): IAnnotationState => state.annotation;

export const getLoadingAnnotation = (state: IState): boolean => state.annotation.loadingAnnotations;

export const getAutoCorrectSever =  createSelector(
    [getAnnotationState],
    (annotation: IAnnotationState): boolean => annotation.autoCorrectSever,
);

export const getAnnotationKonvaData = createSelector(
    [getAnnotationLayouts, reductionState],
    (
        annotation: IAnnotation[],
        { scale }: IRedactorState,
    ): IKonvaRectangle[] => {
        return annotation && annotation.length
            ? annotation.map((item: IAnnotation): IKonvaRectangle => {
                return {
                    width: (item.coordinate[0][END_POINT].x - item.coordinate[0][START_POINT].x) * scale,
                    height: (item.coordinate[0][END_POINT].y - item.coordinate[0][START_POINT].y) * scale,
                    coordinate: {
                        [START_POINT]: {
                            x: item.coordinate[0][START_POINT].x * scale,
                            y: item.coordinate[0][START_POINT].y * scale,
                        },
                        [END_POINT]: {
                            x: item.coordinate[0][END_POINT].x * scale,
                            y: item.coordinate[0][END_POINT].y * scale,
                        },
                    },
                    color: item.color,
                    opacity: item.opacity ? Number(item.opacity) : DEFAULT_OPACITY,
                    name: item.id ? `${ANNOTATION}${item.id}` : `${ANNOTATION}${uuidv4()}`,
                    id: item.id,
                    annotationTypeId: item.annotationTypeId,
                    isSever: item.isSever,
                    isBorderOnly: item.isBorderOnly,
                    stroke: !!item.stroke ? item.stroke : '',
                    strokeWidth: !!item.strokeWidth ? item.strokeWidth : 0,
                    linkedArticles: !!item.linkedArticles ? item.linkedArticles : [],
                };
            })
            : [];
    },
);

export const getAddAnnotaionPending = createSelector(
    [getAnnotationState],
    (annotation: IAnnotationState): boolean => annotation.loadingAnnotations,
);

export const getLoadingLinkedArticles = createSelector(
    [getAnnotationState],
    (annotation: IAnnotationState): boolean => annotation.loadingLinkedArticles,
);

export const getRecentlySnappedAnnotationId = createSelector(
    [getAnnotationState],
    (annotation: IAnnotationState): number => annotation.recentSnappedAnnotationId,
);

import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './spinner.styles.scss';

interface IProps {
    active: boolean;
    size?: number;
}

const SPINNER_SIZE = 50;
export const spinnerActive = (size: number): JSX.Element => (
    <div className='spinner'>
        <CircularProgress size={size} />
    </div>
);
export const spinnerInActive = <div />;
/* tslint:disable: variable-name */
export const Spinner = ({ active, size = SPINNER_SIZE }: IProps): JSX.Element => (
    active ? spinnerActive(size) : spinnerInActive
);
/* tslint:enable */

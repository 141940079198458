import * as React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { Scrollbars } from 'rc-scrollbars';
import { handleCloseModalWindow } from '../../../redux/actions/modal';
import { setSingleSelectedShape } from '../../../redux/actions/redactor';
import { IState as StoreState } from '../../../redux/store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { getDocument, getOverlayedShapesList } from '../../../redux/selectors/redactor';
import './modalOverlayedShapes.styles.scss';
import { Spinner } from '../../../components/spinner/Spinner';
import { RadioMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { IRadio } from '../../../components/materialUiForms/marerialUiForms.model';
import {
    IOverlayedShapesDispatchProps,
    IOverlayedShapesProps,
    IOverlayedShapesState,
    IOverlayedShapesStateProps,
} from './modalOverlayedShapes.model';
import { IAnnotation } from '../../../redux/reducers/pageList/pageList.model';
import { IStamp } from '../../../redux/reducers/stamps/stamps.model';
import { IS_SELECT_OVERLAYED } from '../../../constants/modal.constants';
import { OVERLAYED_SHAPES } from '../../../constants/contextmenu/context.menu.constants';
import { EMPTY_OVERLAYED_OBJECTS_MESSAGE, OVERLAYED_SHAPES_ERROR_MESSAGE } from '../../../constants/messages.constants';
import { CANCEL, OK } from '../../../constants/common.constants';

class ModalOverlayedShapes extends React.Component<IOverlayedShapesProps, IOverlayedShapesState> {

    public state: IOverlayedShapesState = {
        selectedValue: '',
        overlayedSeverAndAnotationList: [],
    };

    public componentDidMount(): void {
        this.setState({
            overlayedSeverAndAnotationList : [
                ...this.props.overlayedShapes.overlayedAnnotations,
                ...this.props.overlayedShapes.overlayedStamps,
            ],
        });
    }

    public componentDidUpdate(prevProps: IOverlayedShapesProps): void {
        if(prevProps.overlayedShapes.getOverlayedShapesPending !==
            this.props.overlayedShapes.getOverlayedShapesPending) {
            this.setState({
                overlayedSeverAndAnotationList : [
                    ...this.props.overlayedShapes.overlayedAnnotations,
                    ...this.props.overlayedShapes.overlayedStamps,
                ],
            });
        }
    }

    public render(): JSX.Element {

        const severAndAnotationList = this.state.overlayedSeverAndAnotationList.map(this.transformToRadio);
        const { selectedValue } = this.state;
        const { overlayedShapes } = this.props;

        return (
            <div className='overlayed_shapes'>
                {overlayedShapes && overlayedShapes.getOverlayedShapesPending &&
                    <div className='overlayed_shapes-height'>
                        <div className='overlayed_shapes-spinner'><Spinner active={true} /></div>
                    </div>
                }
                {overlayedShapes && !overlayedShapes.getOverlayedShapesPending &&
                    <div className='overlayed_shapes-wrapper'>
                        {overlayedShapes.hasError ?
                            <div className='overlayed_shapes-title'>
                                {OVERLAYED_SHAPES_ERROR_MESSAGE}
                            </div> :
                            (
                                <>
                                {severAndAnotationList && !!severAndAnotationList.length ?
                                    <div className='overlayed_shapes-height'>
                                        <Scrollbars>
                                            {
                                                <RadioMaterialBuilder
                                                    value={selectedValue}
                                                    handleRadioChange={this.handleSelectedValue}
                                                    paramName={''}
                                                    ariaLabel={''}
                                                    row={false}
                                                    radioList={severAndAnotationList}
                                                />
                                            }
                                        </Scrollbars>
                                    </div> : (
                                        <div className='overlayed_shapes-title'>
                                            {EMPTY_OVERLAYED_OBJECTS_MESSAGE}
                                        </div>
                                    )
                                }
                                </>
                            )
                        }
                        <div className='form-footer__action'>
                            <Button
                                size='small'
                                variant='outlined'
                                className='modal-window__buttons outlined'
                                onClick={(): void => this.props.handleCloseModalWindow(OVERLAYED_SHAPES)}
                            >
                                {severAndAnotationList && !!severAndAnotationList.length ? CANCEL : OK}
                            </Button>
                            {severAndAnotationList && !!severAndAnotationList.length &&
                                <Button
                                    size='small'
                                    variant='contained'
                                    color='primary'
                                    className='modal-window__buttons primary'
                                    disabled={(!selectedValue)}
                                    onClick={this.save}
                                >
                                    {OK}
                                </Button>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }

    private save = (): void => {
        if(this.state.selectedValue) {
            const value = this.state.overlayedSeverAndAnotationList.find(
                (item: (IStamp | IAnnotation)) => item.id === Number(this.state.selectedValue));

            value[IS_SELECT_OVERLAYED] = true;
            this.props.handleSetSingleSelectedShape(value, this.props.data.scale);
        }

        this.props.handleCloseModalWindow(OVERLAYED_SHAPES);
    }

    private handleSelectedValue = (param: string, selectedValue: string): void => {
        this.setState({ selectedValue });
    }

    private transformToRadio =({text, id}: (IStamp | IAnnotation)): IRadio => {
        return {
            label: text,
            value: String(id),
        };
    }
}

const mapStateToProps = (state: StoreState): IOverlayedShapesStateProps => ({
    overlayedShapes: getOverlayedShapesList(state),
    data: getDocument(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IOverlayedShapesDispatchProps, AnyAction>,
): IOverlayedShapesDispatchProps => ({
    handleCloseModalWindow: (id: string): void => {
        dispatch(handleCloseModalWindow(id));
    },
    handleSetSingleSelectedShape: (shape: IStamp | IAnnotation, scaleParam: number): void => {
        dispatch(setSingleSelectedShape(shape, scaleParam));
    },
});

export default connect( mapStateToProps, mapDispatchToProps)(ModalOverlayedShapes);

import * as React from 'react';
import { Link } from 'react-router-dom';
import { getRedactionDocumentId, getRedactionFolderRsn } from '../../redux/selectors/initialization';
import { useSelector, useDispatch } from '../../../node_modules/react-redux';
import './localization.scss';
import { CheckboxMaterialBuilder, RadioMaterialBuilder, SelectMaterialBuilder } from '../../components/materialUiForms/materialUiFormBuilder';
import { useEffect, useState } from 'react';
import Table from '../../components/table/Table';
import FormLabel from '@mui/material/FormLabel';
import { Button, Collapse, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import { getAuditLogPermission } from '../../redux/selectors/initialization';
import Grid from '@mui/material/Grid';
import { FOLDER_RSN, FOLDER_RSN_PARAM, SEARCH } from '../../constants/advancedSearch.constants';
import { CLEAR } from '../../constants/globalSearch.constants';
import { b64EncodeUnicode } from '../../utils/encode.util';
import {
    CANCEL,
    FILTER_OPTIONS,
    FILTER_LABEL,
    LOCALIZATION_TITLE,
    RESOURCE_TYPE_PARAM,
    RESOURCE_BUNDLE_LABEL,
    SAVE,
    getTableColumns,
    RESOURCE_TYPE_LABEL,
    RESOURCE_TYPE_OPTIONS,
    SYSTEM_MESSAGES_LABEL,
    getMessagesTableColumns,
    MESSAGES_FILTER_OPTIONS,
    LOCALIZATION_TYPE_OPTIONS,
    SCREEN_LABELS_PARAM,
    SYSTEM_MESSAGES_PARAM,
    RESOURCE_KEY_PARAM,
    RESOURCE_VALUE1_PARAM,
    RESOURCE_VALUE1_LABEL,
    RESOURCE_VALUE2_PARAM,
    RESOURCE_VALUE2_LABEL,
    MESSAGE_KEY_PARAM,
    MESSAGE_KEY_LABEL,
    MESSAGE_TEXT_PARAM,
    MESSAGE_TEXT_LABEL,
    MESSAGE_TEXT2_PARAM,
    MESSAGE_TEXT2_LABEL,
    MESSAGE_COMMENTS_PARAM,
    MESSAGE_COMMENTS_LABEL,
    RESOURCE_KEY_LABEL,
    SYSTEM_MESSAGE_MAX_CHAR,
    SCREEN_LABEL_MAX_CHAR,
    LOCALIZATION_MIN_CHAR,
    EDIT_SYSTEM_MESSAGE_MODAL,
} from '../../constants/localization.constants';
import resourceBundle from './localizationData';
import {
    getImportSystemMessagesFlag,
    getLocalizationOriginalResult,
    getLocalizationSearchResult,
    getModifiedLabels,
    getModifiedLabelsFlag,
    getSearchResultsPending,
} from '../../redux/selectors/localization';
import {
    modifySearchResult,
    searchSystemMessages,
    selectSystemMessageForEdit,
    setSearchResult,
    setSearchResultsPending,
    updateScreenLabels,
} from '../../redux/actions/localization';
import { OK, SSO_LID } from '../../constants/common.constants';
import { ILabel, ISearchData } from 'redux/reducers/localization/localization.model';
import { getReductionLid } from '../../redux/selectors/localStorage';
import { Spinner } from '../../components/spinner/Spinner';
import { openModalWindow } from '../../redux/actions/modal';
import { CHANGES_MAY_NOT_BE_SAVED_MESSAGE, CONFIRMATION_DIALOG_MODAL, WARNING } from '../../constants/messages.constants';
import { IModalProps } from '../../redux/reducers/modal/modal.model';

const DEFAULT_LOADING_DELAY = 100;

export const Localization = (): JSX.Element => {
    const folderRsn = useSelector(getRedactionFolderRsn);
    const redactionLID = useSelector(getReductionLid);
    const searchResultList =  useSelector(getLocalizationSearchResult);
    const originalResultList =  useSelector(getLocalizationOriginalResult);
    const modifiedLabels = useSelector(getModifiedLabels);
    const modifiedLabelsFlag = useSelector(getModifiedLabelsFlag);
    const redactionDocumentId = useSelector(getRedactionDocumentId);
    const importSystemMessagesFlag = useSelector(getImportSystemMessagesFlag);
    const resultsPendingFlag = useSelector(getSearchResultsPending);
    const [filters, handleFilters] = useState(false);
    const [filterValue, setFilter] = useState('null');
    const [localizationType, setLocalizationType] = useState(SCREEN_LABELS_PARAM);
    const [resourceType, setResourceType] = useState(null);
    const [searchValue, handleChangeSearchValue] = useState('');
    const [filterOptions, setFilterOptions] = useState(FILTER_OPTIONS);
    const [searchFieldLabel, setSearchFieldLabel] = useState(RESOURCE_BUNDLE_LABEL);
    const [resultTableFlag, setResultTableFlag] = useState(false);

    const dispatch = useDispatch();
    const setResult = (result: ILabel[]): void => dispatch(setSearchResult(result));

    const modifyResult = (result: ILabel): void => dispatch(modifySearchResult(result));
    const updateLabels = (data: ILabel[], results: ILabel[]): void => dispatch(updateScreenLabels(data, results));
    const handleOpenModalWindow = (modalType: string, modalProps: IModalProps): void =>
        dispatch(openModalWindow(modalType, modalProps));
    const postSearch = (data: ISearchData): void => dispatch(searchSystemMessages(data));
    const selectSystemMessage = (data: ILabel): void => dispatch(selectSystemMessageForEdit(data));
    const setSearchPending = (): void => dispatch(setSearchResultsPending());

    const prepareDataForSearch = (): ILabel[] => {
        const searchString = searchValue.toLowerCase();

        if (filters && resourceType) {
            const results1 = modifiedLabels.filter((resource: ILabel) => resource.resourceType === resourceType);
            const results2 = resourceBundle.filter((resource: ILabel) => resource.resourceType === resourceType)
                .filter((resource: ILabel) =>
                    !results1.find((resource1: ILabel) => resource1.resourceKey === resource.resourceKey ));
            const result = [...results1, ...results2];

            return result;
        } else {
            if (filters && filterValue && filterValue !== 'null') {
                const results1 = modifiedLabels.filter((resource: ILabel) =>
                    resource[filterValue].toLowerCase().includes(searchString));
                const results2 = resourceBundle.filter((resource: ILabel) =>
                    resource[filterValue].toLowerCase().includes(searchString)).filter((resource: ILabel) =>
                        !results1.find((resource1: ILabel) => resource1.resourceKey === resource.resourceKey ));
                const result = [...results1, ...results2];

                return result;
            } else {
                const results1 = modifiedLabels.filter((resource: ILabel) => {
                    return resource[RESOURCE_TYPE_PARAM].toLowerCase().includes(searchString) ||
                        resource[RESOURCE_KEY_PARAM].toLowerCase().includes(searchString) ||
                        resource[RESOURCE_VALUE1_PARAM].toLowerCase().includes(searchString) ||
                        resource[RESOURCE_VALUE2_PARAM].toLowerCase().includes(searchString);
                });
                const results2 = resourceBundle.filter((resource: ILabel) => {
                        return resource[RESOURCE_TYPE_PARAM].toLowerCase().includes(searchString) ||
                            resource[RESOURCE_KEY_PARAM].toLowerCase().includes(searchString) ||
                            resource[RESOURCE_VALUE1_PARAM].toLowerCase().includes(searchString) ||
                            resource[RESOURCE_VALUE2_PARAM].toLowerCase().includes(searchString);
                    }).filter((resource: ILabel) =>
                        !results1.find((resource1: ILabel) => resource1.resourceKey === resource.resourceKey ));
                const result = [...results1, ...results2];

                return result;
            }
        }
    };

    const handleSave = (): void => {
        const modifiedData = searchResultList.filter((result: ILabel, index: number ) =>
                !(result.resourceValue === originalResultList[index].resourceValue &&
                result.resourceValue2 === originalResultList[index].resourceValue2));

        updateLabels(modifiedData, searchResultList);
    };

    const prepareDataForSearchMessages = (): ISearchData => {
        const appliedFilters = filters ? filterValue === 'null' ? null : filterValue : null;

        return {
            searchString: searchValue,
            appliedFilters,
        };
    };

    const handleSearch = (): void => {
        if(localizationType === SCREEN_LABELS_PARAM) {
            setSearchPending();

            const searchDto = prepareDataForSearch();

            setResult(searchDto);
        } else {
            const searchDto = prepareDataForSearchMessages();

            postSearch(searchDto);
        }
    };

    const handleClear = (): void => {
        handleChangeSearchValue('');
        setFilter('null');
        setResourceType(null);
        handleFilters(false);
        setResult([]);
        setSearchFieldLabel(RESOURCE_BUNDLE_LABEL);
    };

    const handleFilterchange = (name: string, value: string): void => {
        setFilter(value);
        setResourceType(null);

        switch(value) {
            case RESOURCE_KEY_PARAM:
                setSearchFieldLabel(RESOURCE_KEY_LABEL);
                break;
            case RESOURCE_VALUE1_PARAM:
                setSearchFieldLabel(RESOURCE_VALUE1_LABEL);
                break;
            case RESOURCE_VALUE2_PARAM:
                setSearchFieldLabel(RESOURCE_VALUE2_LABEL);
                break;
            case MESSAGE_KEY_PARAM:
                setSearchFieldLabel(MESSAGE_KEY_LABEL);
                break;
            case MESSAGE_TEXT_PARAM:
                setSearchFieldLabel(MESSAGE_TEXT_LABEL);
                break;
            case MESSAGE_TEXT2_PARAM:
                setSearchFieldLabel(MESSAGE_TEXT2_LABEL);
                break;
            case MESSAGE_COMMENTS_PARAM:
                setSearchFieldLabel(MESSAGE_COMMENTS_LABEL);
                break;
            default: setSearchFieldLabel(RESOURCE_BUNDLE_LABEL);
        }
    };

    const handleLocalizationTypeChange = (name: string, value: string): void => {
        setLocalizationType(value);
        setFilter('null');
        setSearchFieldLabel(RESOURCE_BUNDLE_LABEL);
        setResourceType(null);
        setResult([]);
        handleChangeSearchValue('');
        setResultTableFlag(true);
        setFilterOptions(value === SYSTEM_MESSAGES_PARAM ? MESSAGES_FILTER_OPTIONS : FILTER_OPTIONS);
        setTableColumns(value === SYSTEM_MESSAGES_PARAM ? getMessagesTableColumns(handleEdit)
            : getTableColumns(handleResultChange));
        setTimeout(() => {
            setResultTableFlag(false);
        }, DEFAULT_LOADING_DELAY);
    };

    const handleResourceTypeChange = (param: string, val: string): void => {
        setFilter('null');
        setResourceType(val);
        setSearchFieldLabel(RESOURCE_BUNDLE_LABEL);
    };

    const isSearchDisabled = (): boolean => {
        return !((searchValue && searchValue.length >= LOCALIZATION_MIN_CHAR  || filters && resourceType))
            || (localizationType === SYSTEM_MESSAGES_PARAM ? false : modifiedLabelsFlag);
    };

    const isSaveDisabled = (cancel: boolean = false): boolean => {
        let errorFlags = [];

        if (searchResultList.length) {
            if(localizationType === SCREEN_LABELS_PARAM) {
                const saveFlags = searchResultList.map((result: ILabel, index: number ) => {
                    errorFlags = [...errorFlags,
                        (result.resourceValue.length < LOCALIZATION_MIN_CHAR
                            || result.resourceValue.length > SCREEN_LABEL_MAX_CHAR)
                        || (result.resourceValue2.length < LOCALIZATION_MIN_CHAR
                            || result.resourceValue2.length > SCREEN_LABEL_MAX_CHAR),
                    ];

                    return (result.resourceValue === originalResultList[index].resourceValue
                        && result.resourceValue2 === originalResultList[index].resourceValue2);
                });
                const disableFlag = saveFlags.find((flag: boolean) => !flag);
                const errorFlag = errorFlags.find((flag: boolean) => flag);

                if(cancel) {
                    return disableFlag === undefined ? true : disableFlag;
                } else {
                    return disableFlag === undefined ? true : disableFlag || errorFlag || modifiedLabelsFlag;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    const handleCancel = (): void => {
        const destination = folderRsn ? redactionDocumentId ? `/${b64EncodeUnicode(folderRsn)}` : ''
            : `/${redactionLID && '?'+SSO_LID+ '=' + redactionLID}`;

        if(!isSaveDisabled(true)) {
            handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: WARNING,
                okButtonTitle: OK,
                cancelButtonTitle: CANCEL,
                message: CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
                confirm: (): () => void => (): void => {
                    if(destination) {
                        window.open(destination, '_self');
                    }
                },
            });
        } else {
            if(destination) {
                window.open(destination, '_self');
            }
        }
    };

    const handleResultChange = (key: ILabel, value: string, param: string): void => {
        modifyResult({...key, [param]: value});
    };

    const handleEdit = (data: ILabel): void => {
        selectSystemMessage(data);
        handleOpenModalWindow(EDIT_SYSTEM_MESSAGE_MODAL, {id: EDIT_SYSTEM_MESSAGE_MODAL});
    };

    const [tableColumns, setTableColumns] = useState(getTableColumns(handleResultChange));

    return (
        <div className='localization'>
            <div className='localization_header'>
                <h2>{LOCALIZATION_TITLE}</h2>
                {
                    folderRsn && redactionDocumentId ?
                        <Button
                            size='small'
                            variant='outlined'
                            className='buttons-style outlined cancel_button'
                            onClick={handleCancel}
                        >
                            Back Home
                        </Button>
                        : folderRsn ?
                            <Button
                                size='small'
                                variant='outlined'
                                className='cancel_button buttons-style outlined '
                                disabled={true}
                            >
                                Back Home
                            </Button>
                            : <Button
                                size='small'
                                variant='outlined'
                                className='cancel_button buttons-style outlined'
                                onClick={handleCancel}
                            >
                                Back Home
                            </Button>
                }
            </div>
            <div className='localization_form'>
                <div className='localization_scrollWrap'>
                        <div className='localization_formInner'>
                            <div className='localization-filter'>
                                <div className='resource-filter'>
                                    <RadioMaterialBuilder
                                            value={localizationType}
                                            handleRadioChange={handleLocalizationTypeChange}
                                            paramName={'localizationType'}
                                            ariaLabel={'Localization Type'}
                                            radioList={LOCALIZATION_TYPE_OPTIONS}
                                    />
                                </div>
                            </div>
                            <Grid container={true} spacing={4}>
                                <Grid item={true} xs={3}>
                                    <div className='filter-tab'>
                                        <CheckboxMaterialBuilder
                                            type={'switch'}
                                            checked={filters}
                                            label={FILTER_LABEL}
                                            handleCheckboxChange={
                                                (param: string, checked: boolean): void => {
                                                    handleFilters(checked);
                                                    setFilter('null');
                                                    setResourceType(null);
                                                    setSearchFieldLabel(RESOURCE_BUNDLE_LABEL);
                                                }
                                            }
                                            paramName={FILTER_LABEL}
                                        />
                                    </div>
                                    <div className='resource-bundle-label'>
                                        <FormLabel><b> Search Resource </b></FormLabel>
                                        <p>The name of the resource to search.</p>
                                    </div>
                                </Grid>
                                <Grid item={true} xs={9}>
                                    <div className='filter-tab'>
                                        <Collapse in={filters}>
                                            <div className='localization-filter'>
                                            <div className='resource-filter'>
                                                <RadioMaterialBuilder
                                                        value={filterValue}
                                                        handleRadioChange={handleFilterchange}
                                                        paramName={'filter'}
                                                        ariaLabel={'Filters'}
                                                        radioList={filterOptions}
                                                        disabled={!filters}
                                                />
                                            </div>
                                            { localizationType === SCREEN_LABELS_PARAM &&
                                                <div className='resource-type-filter'>
                                                    <SelectMaterialBuilder
                                                        value={resourceType}
                                                        label={RESOURCE_TYPE_LABEL}
                                                        paramName={RESOURCE_TYPE_LABEL}
                                                        handleSelectChange={handleResourceTypeChange}
                                                        options={RESOURCE_TYPE_OPTIONS}
                                                    />
                                                </div>
                                            }
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div>
                                        <TextField
                                            value={searchValue}
                                            label={searchFieldLabel}
                                            fullWidth={true}
                                            type='text'
                                            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                                if(e.target.value.length > (localizationType === SCREEN_LABELS_PARAM
                                                    ? SCREEN_LABEL_MAX_CHAR : SYSTEM_MESSAGE_MAX_CHAR)) {
                                                    handleChangeSearchValue(e.target.value.substring(0,
                                                        (localizationType === SCREEN_LABELS_PARAM
                                                            ? SCREEN_LABEL_MAX_CHAR : SYSTEM_MESSAGE_MAX_CHAR)));
                                                } else {
                                                    handleChangeSearchValue(e.target.value);
                                                }
                                            }}
                                            variant='outlined'
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                </div>
                <div className={'localization_formFooter'}>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        className='buttons-style primary'
                        onClick={handleSearch}
                        disabled={isSearchDisabled()}
                    >
                        {SEARCH}
                    </Button>
                    <Button
                        size='small'
                        variant='outlined'
                        onClick={handleClear}
                        className='buttons-style outlined'
                    >
                        {CLEAR}
                    </Button>
                </div>
            </div>
            <div className='localization_result'>
                <div className='localization_result_table'>
                    { resultTableFlag ? <Spinner active={true} />
                        : <Table
                                title={'Search Result'}
                                columns={tableColumns}
                                data={searchResultList}
                                loading={resultsPendingFlag}
                        />}
                </div>
                <div className={'localization_formFooter'}>
                    { localizationType === SCREEN_LABELS_PARAM &&
                        <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            className='buttons-style primary'
                            disabled={isSaveDisabled()}
                            onClick={handleSave}
                        >
                            {SAVE}
                        </Button>
                    }
                    {
                        folderRsn && redactionDocumentId ?
                        <Button
                            size='small'
                            variant='outlined'
                            className='buttons-style outlined cancel_button'
                            onClick={handleCancel}
                        >
                            {CANCEL}
                        </Button>
                    : folderRsn ?
                        <Button
                            size='small'
                            variant='outlined'
                            className='cancel_button buttons-style outlined '
                            disabled={true}
                        >
                            {CANCEL}
                        </Button>
                        : <Button
                            size='small'
                            variant='outlined'
                            className='cancel_button buttons-style outlined'
                            onClick={handleCancel}
                        >
                            {CANCEL}
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
};

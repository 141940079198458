import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    IWatermarksAssociateState,
    IWatermarkRequest,
    IWatermarkType,
    IWatermarkTypeAssociate,
} from '../reducers/modalAssociateWatermarks/modalAssociateWatermarks.model';
import { IError } from '../common.model';
import { getRedactionRequestType } from './initialization';

export const getWatermarksSettings = (state: IState): IWatermarksAssociateState => state.modalWatermarksAssociate;

export const getWatermarksSettingsList = createSelector(
    [getWatermarksSettings],
    (modalWatermarksAssociate: IWatermarksAssociateState): IWatermarkRequest[] =>
    modalWatermarksAssociate.watermarksSettings,
);
export const getWatermarkTypesList = createSelector(
    [getWatermarksSettings],
    (modalWatermarksAssociate: IWatermarksAssociateState): IWatermarkType[] => modalWatermarksAssociate.watermarkTypes,
);
export const getRequestTypesWithWatermarkTypes = createSelector(
    [getWatermarksSettings],
    (modalWatermarksAssociate: IWatermarksAssociateState): IWatermarkTypeAssociate[] =>
    modalWatermarksAssociate.requestTypesWithWatermarkTypes,
);
export const getWatermarksSettingsPending = createSelector(
    [getWatermarksSettings],
    (modalWatermarksAssociate: IWatermarksAssociateState): boolean => modalWatermarksAssociate.watermarksPending,
);

export const getWatermarksSettingsError = createSelector(
    [getWatermarksSettings],
    (modalWatermarksAssociate: IWatermarksAssociateState): IError => modalWatermarksAssociate.error,
);

export const hasCurrentReductionDefaultWatermark = createSelector(
    [getRequestTypesWithWatermarkTypes, getRedactionRequestType],
    (
        requestTypesWithWatermarkTypes: IWatermarkTypeAssociate[],
        requestType: string,
    ): boolean => {
        const currentAssociatedRequest = requestTypesWithWatermarkTypes.filter(
            (request: IWatermarkTypeAssociate) => request.requestType === requestType);

        return currentAssociatedRequest.length &&
            currentAssociatedRequest[0] &&
            !!currentAssociatedRequest[0].defaultWatermarkTypeId;
    },
);

import {
    GET_INFO_CONTROLLER_PENDING,
    GET_INFO_CONTROLLER_SUCCESS,
    GET_INFO_CONTROLLER_FAILURE,
} from './constants';
import { IVersionController } from './infoController.model';
import { AnyAction } from 'redux';
import { VERSION } from '../../../version';

const initialState: IVersionController = {
    FEVersion: VERSION,
    BEVersion: '',
    DBVersion: '',
    versionPending: false,
    error: null,
};

const infoControllerReducer = (state: IVersionController = initialState,
                               {type, payload}: AnyAction): IVersionController => {
    switch (type) {
        case GET_INFO_CONTROLLER_PENDING:
            return {
                ...state,
                versionPending: true,
                error: null,
            };
        case GET_INFO_CONTROLLER_SUCCESS:
            return{
                ...state,
                BEVersion: payload.buildVersion,
                DBVersion: payload.dbVersion,
                versionPending: false,
            };
        case GET_INFO_CONTROLLER_FAILURE:
            return{
                ...state,
                error: payload,
                versionPending: false,
            };
        default:
            return state;
    }
};

export default infoControllerReducer;

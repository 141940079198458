import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import {
    GET_PAGES_REVIEW_SUMMARY_REPORT_PENDING,
    GET_PAGES_REVIEW_SUMMARY_REPORT_SUCCESS,
    GET_PAGES_REVIEW_SUMMARY_REPORT_FAILURE,
} from '../reducers/modalPageReviewSummary/constants';
import { IError } from '../common.model';
import { fileSaver } from '../../utils/fileSaver';
import { REQUESTER } from '../../constants/requesterReport';
import { addError } from './errorHandling';
import { handleCloseAllModalWindows } from './modal';

export const fetchPageReviewSummaryReportPending = (): AnyAction => action(GET_PAGES_REVIEW_SUMMARY_REPORT_PENDING);
export const fetchPagesReviewSummaryReportSuccess = ( dataReviewSummary: any): AnyAction =>
    action(GET_PAGES_REVIEW_SUMMARY_REPORT_SUCCESS, dataReviewSummary);
export const fetchPagesReviewSummaryReportFailure = (error: IError): AnyAction =>
    action(GET_PAGES_REVIEW_SUMMARY_REPORT_FAILURE, error);

export const fetchPagesReviewSummaryByStackId = (
    redactionDocumentId: number,
    stackId: number,
    reportLang: string
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchPageReviewSummaryReportPending());

        try {
            const data = stackId ? {stackId} : null;
            const dataPagesReviewSummaryByStackId = await api.reportController.getPagesReviewSummary(
                redactionDocumentId,
                data,
                reportLang
            );

            dispatch(fetchPagesReviewSummaryReportSuccess(dataPagesReviewSummaryByStackId));
            dispatch(handleCloseAllModalWindows());
            fileSaver(dataPagesReviewSummaryByStackId, REQUESTER);
        } catch (error) {
            dispatch(addError(error));
            dispatch(fetchPagesReviewSummaryReportFailure(error));
        }
    };

import { IState } from '../store';
import { createSelector } from 'reselect';
import { IModal, IModalStore, IModalProps } from '../reducers/modal/modal.model';
import { SEARCH_REDACTION_DOCUMENTS } from '../../constants/searchDocuments.contstants';
import { EXPORT_ID } from '../../constants/common.constants';
import { PACKAGE_PRESETS } from '../../constants/modal.constants';

export const getModalState = (state: IState): IModalStore => state.modal;

export const getModalList = createSelector(
    [getModalState],
    (popup: IModalStore): IModal[] => popup.modal,
);

export const getModalType = createSelector(
    [getModalState],
    (popup: IModalStore): boolean => popup.modal.some((value: IModal): boolean => {
        if(value.modalProps.id === SEARCH_REDACTION_DOCUMENTS) {
            return value.modalProps.title ? true : false;
        }

        if(value.modalProps.id === EXPORT_ID) {
            return value.modalProps.id ? true : false;
        }

    }),
);

export const getIsActivePackagePresetModal = createSelector(
    [getModalState],
    (popup: IModalStore): boolean => popup.modal.some((value: IModal): boolean => {
        if(value.modalProps.id === PACKAGE_PRESETS) {
            return value.modalProps.id ? true : false;
        }

    }),
);

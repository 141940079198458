import * as React from 'react';
import classnames from 'classnames';
import { IAnnotationControlsProps } from './control.model';
import { IAnnotationType } from '../../redux/reducers/annotationTypes/annotationTypes.model';
import { SelectComponent } from '../select/Select';
import { SvgIcon } from '../svgIcons/svgIcons';
import { ACTIVE_ITEM_CLASS, AUTO_CORRECT_SEVER, AUTO_CORRECT_SEVER_ELEMENT, AUTO_CORRECT_SEVER_TOOL, ITEM_CLASS } from '../../constants';
import {
    SEVER_ELEMENT,
    HIGHLIGHT_ELEMENT,
    SEVER_TEXT,
    HIGHLIGHT_TEXT,
} from '../../constants/annotationTypes.constants';

export const AnnotationTypesControls = (
    {
        activeControl,
        severTypes,
        highlightTypes,
        buttonToggle,
        pdfSrc,
        setSelectedAnnotations,
        selectedAnnotationType,
        handleChangeHighLight,
        handleChangeSever,
        openHighlight,
        openSever,
        handleCloseSelects,
        highlightPermission,
        severPermission,
        setAutoCorrectSever,
        autoCorrectSever,
    }: IAnnotationControlsProps,
): JSX.Element => {
    const autoCorrectSeverF = autoCorrectSever ? { color: '#13CB18' } : null;
    const autoCorrectSeverSvg = { ...(autoCorrectSever && { borderColor: '#13CB18' }) };

    const handleChange = (selectedId: number): void => {
        const selected = [...severTypes, ...highlightTypes].find((item: IAnnotationType): boolean => {
            return item.id === selectedId;
        });

        if (selected.isActive) {
            setSelectedAnnotations(selected);
        }
    };

    const getClassName = (controlType: string): string => (classnames({
        [ACTIVE_ITEM_CLASS]: activeControl === controlType,
        [ITEM_CLASS]: activeControl !== controlType,
        disabled: controlType === SEVER_ELEMENT ? !severPermission : !highlightPermission,
    }));

    return (
        <React.Fragment>
            <div
                key={AUTO_CORRECT_SEVER_TOOL}
                className={getClassName(AUTO_CORRECT_SEVER_ELEMENT)}
                onClick={(): void => setAutoCorrectSever(!autoCorrectSever)}
            >
                <div className='icon icon-auto-stamp'>
                    <div className='svg' style={autoCorrectSeverSvg}>
                        <SvgIcon iconname={'AutoCorrectSeverIcon'} width={18} height={16} />
                    </div>
                    <div className='text' style={autoCorrectSeverF}>{AUTO_CORRECT_SEVER}</div>
                </div>

            </div>
            {
                [severTypes, highlightTypes].map((types: IAnnotationType[], i: number): JSX.Element => {
                    const isSever = (i === 0);
                    const element = isSever ? SEVER_ELEMENT : HIGHLIGHT_ELEMENT;
                    const disableSelect = isSever ? !severPermission : !highlightPermission;

                    const activeStamp = types.find((type: IAnnotationType): boolean =>
                        selectedAnnotationType && type.id === selectedAnnotationType.id,
                    );

                    const activeSvg = activeStamp ? { borderColor: selectedAnnotationType.color } : null;
                    const activeText = activeStamp ? { color: selectedAnnotationType.color } : null;

                    return (
                        <div
                            key={i}
                            className={getClassName(element)}
                            onClick={(): void => buttonToggle(element)}
                        >
                            <div className='position'>
                                <div className='svg sever-dropdown' style={activeSvg}>
                                    <SelectComponent
                                        handleCloseSelects={handleCloseSelects}
                                        open={isSever ? openSever : openHighlight}
                                        handleChange={isSever ? handleChangeSever : handleChangeHighLight}
                                        text={isSever ? SEVER_TEXT : HIGHLIGHT_TEXT}
                                        icon={isSever ? <SvgIcon iconname={'SeverIcon'} width={18} height={16} /> :
                                            <SvgIcon iconname={'HighlightIcon'} width={18} height={16} />}

                                        value={selectedAnnotationType ? `${selectedAnnotationType.id}` : ''}
                                        handleItemChange={(selectedId: number): void => {
                                            handleChange(selectedId);
                                            handleCloseSelects();
                                        }}
                                        items={types}
                                        disabledCondition={!pdfSrc || disableSelect}
                                        placementOrient={'top'}
                                    />
                                </div>
                                <div className='text' style={activeText}>
                                    {isSever ? SEVER_TEXT : HIGHLIGHT_TEXT}
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </React.Fragment>
    );
};

import {
    GET_PEOPLE_TYPE_BY_DISCLOSURE_PENDING,
    GET_PEOPLE_TYPE_BY_DISCLOSURE_SUCCESS,
    GET_PEOPLE_TYPE_BY_DISCLOSURE_FAILURE,
    GET_SEARCH_PEOPLE_RECORDS_PENDING,
    GET_SEARCH_PEOPLE_RECORDS_SUCCESS,
    GET_SEARCH_PEOPLE_RECORDS_FAILURE,
    GET_ASSIGNED_PEOPLE_RECORDS_PENDING,
    GET_ASSIGNED_PEOPLE_RECORDS_SUCCESS,
    GET_ASSIGNED_PEOPLE_RECORDS_FAILURE,
    CLEAR_SEARCH_RESULT,
} from './constants';
import { IDisclosureContactsState } from './disclosureContacts.model';
import { AnyAction } from 'redux';

const initialState: IDisclosureContactsState = {
    peopleTypeByDisclosure: [],
    searchPeopleRecords: [],
    assignedContacts: [],
    peopleTypeByDisclosurePending: false,
    searchPeopleRecordsPending: false,
    assignedContactsPending: false,
    error: null,
};

const disclosureContactsReducer = (
    state: IDisclosureContactsState = initialState,
    {type, payload}: AnyAction,
): IDisclosureContactsState => {
    switch (type) {
        case GET_PEOPLE_TYPE_BY_DISCLOSURE_PENDING:
            return {
                ...state,
                peopleTypeByDisclosurePending: true,
                error: null,
            };
        case GET_PEOPLE_TYPE_BY_DISCLOSURE_SUCCESS:
            return{
                ...state,
                peopleTypeByDisclosure: payload,
                peopleTypeByDisclosurePending: false,
            };
        case GET_PEOPLE_TYPE_BY_DISCLOSURE_FAILURE:
            return{
                ...state,
                error: payload,
                peopleTypeByDisclosurePending: false,
            };
        case GET_SEARCH_PEOPLE_RECORDS_PENDING:
            return {
                ...state,
                searchPeopleRecordsPending: true,
                error: null,
            };
        case GET_SEARCH_PEOPLE_RECORDS_SUCCESS:
            return{
                ...state,
                searchPeopleRecords: payload,
                searchPeopleRecordsPending: false,
            };
        case GET_SEARCH_PEOPLE_RECORDS_FAILURE:
            return{
                ...state,
                error: payload,
                searchPeopleRecordsPending: false,
            };
        case CLEAR_SEARCH_RESULT:
            return initialState;
        case GET_ASSIGNED_PEOPLE_RECORDS_PENDING:
            return {
                ...state,
                assignedContactsPending: true,
                error: null,
            };
        case GET_ASSIGNED_PEOPLE_RECORDS_SUCCESS:
            return{
                ...state,
                assignedContacts: payload,
                assignedContactsPending: false,
            };
        case GET_ASSIGNED_PEOPLE_RECORDS_FAILURE:
            return{
                ...state,
                searchPeopleRecordsPending: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default disclosureContactsReducer;

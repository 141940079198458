import * as React from 'react';
import { useEffect, useState } from 'react';
import { RadioMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormLabel } from '@mui/material';
import * as constants from '../../../constants/modalUserPreference.constants';
import { IProps } from './modalUserPreference.model';
import { stampsState } from '../../../redux/selectors/stamps';
import { handleCloseModalWindow } from '../../../redux/actions/modal';
import { updateStampAlingment } from '../../../redux/actions/stamps';
import './modalUserPreference.scss';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import resourceBundle from '../../localization/localizationData';
import { initialLabel } from '../../../constants/localization.constants';

export const ModalUserPreference = (props: IProps): JSX.Element => {

    const dispatch = useDispatch();

    const stamps = useSelector(stampsState);
    const modifiedLabels = useSelector(getModifiedLabels);
    const redactionLang = useSelector(getRedactionLanguage);
    const [stampAlignment, setAlign] = useState('');
    const langRule = redactionLang ? redactionLang === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';

    useEffect(() => {
        setAlign(stamps[constants.USER_PREFERENCE_PARAM]);
    }, [stamps[constants.USER_PREFERENCE_PARAM]]);

    const closeUserPreferenceModal = (): void => {
        dispatch(handleCloseModalWindow(constants.USER_PREFERENCE_MODAL));
    };
    const setStampAlignment = (align: string): void => {
        dispatch(updateStampAlingment(align));
    };
    const handleSelectedValue = (param: string, value: string): void => {
        setAlign(value);
    };
    const save = (): void => {
        setStampAlignment(stampAlignment);
        closeUserPreferenceModal();
    };

    const labels = {
        autoAlignLabel: initialLabel,
        leftAlignLabel: initialLabel,
        noneLabel: initialLabel,
        rightAlignLabel: initialLabel,
        okLabel: initialLabel,
        cancelLabel: initialLabel,
    };

    resourceBundle.map((resource: any) => {
        switch(resource.resourceKey) {
            case 'USER_PREFERENCES_MODAL_ARTICLE_STAMP_AUTO_ALIGN':
                labels.autoAlignLabel = resource;
                break;
            case 'USER_PREFERENCES_MODAL_LEFT_ALIGN':
                labels.leftAlignLabel = resource;
                break;
            case 'USER_PREFERENCES_MODAL_RIGHT_ALIGN':
                labels.rightAlignLabel = resource;
                break;
            case 'USER_PREFERENCES_MODAL_NONE':
                labels.noneLabel = resource;
                break;
            case 'COMMON_LABEL_CANCEL':
                labels.cancelLabel = resource;
                break;
            case 'COMMON_LABEL_OK':
                labels.okLabel = resource;
                break;
        }

        return resource;
    });

    modifiedLabels.map((resource: any) => {
        switch(resource.resourceKey) {
            case 'USER_PREFERENCES_MODAL_ARTICLE_STAMP_AUTO_ALIGN':
                labels.autoAlignLabel = resource;
                break;
            case 'USER_PREFERENCES_MODAL_LEFT_ALIGN':
                labels.leftAlignLabel = resource;
                break;
            case 'USER_PREFERENCES_MODAL_RIGHT_ALIGN':
                labels.rightAlignLabel = resource;
                break;
            case 'USER_PREFERENCES_MODAL_NONE':
                labels.noneLabel = resource;
                break;
            case 'COMMON_LABEL_CANCEL':
                labels.cancelLabel = resource;
                break;
            case 'COMMON_LABEL_OK':
                labels.okLabel = resource;
                break;
        }

        return resource;
    });

    const userAlignmentPreference = [
        { value: constants.LEFT_ALIGN_PARAM, label: labels.leftAlignLabel[langRule] },
        { value: constants.RIGHT_ALIGN_PARAM, label: labels.rightAlignLabel[langRule] },
        { value: constants.NONE_ALIGN_PARAM, label: labels.noneLabel[langRule] },
    ];

    return (
        <div className='modal-user-preference'>
            <div className='radio-wrapper'>
                <FormLabel>{labels.autoAlignLabel[langRule]}:</FormLabel>
                <RadioMaterialBuilder
                    value={stampAlignment}
                    handleRadioChange={handleSelectedValue}
                    paramName={constants.USER_PREFERENCE_PARAM}
                    ariaLabel={constants.USER_PREFERENCE_TITLE}
                    radioList={userAlignmentPreference}
                    disabled={false}
                />
            </div>
            <div className='modal-btn-wrap'>
                <Button
                    size='small'
                    variant='outlined'
                    className='outlined'
                    onClick={closeUserPreferenceModal}
                >
                    {labels.cancelLabel[langRule]}
                </Button>
                <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    className='primary'
                    disabled={false}
                    onClick={save}
                >
                    {labels.okLabel[langRule]}
                </Button>
            </div>
        </div>
    );

};

import {
    GET_STAMP_PENDING,
    GET_STAMP_SUCCESS,
    GET_STAMP_FAILURE,
    GET_ARTICLE_STAMP_PENDING,
    GET_ARTICLE_STAMP_SUCCESS,
    GET_ARTICLE_STAMP_FAILURE,
    POST_STAMP_PENDING,
    POST_STAMP_SUCCESS,
    POST_STAMP_FAILURE,
    PUT_STAMP_PENDING,
    PUT_STAMP_SUCCESS,
    PUT_STAMP_FAILURE,
    DELETE_STAMP_PENDING,
    DELETE_STAMP_SUCCESS,
    DELETE_STAMP_FAILURE,
    SET_SELECTED_CUSTOM_STAMPS,
    SET_SELECTED_ARTICLE_STAMPS,
    CHANGE_LOCAL_STAMPS,
    SET_SINGLE_RECENTLY_USED_STAMP,
    SET_INTERACTIVE_MODE,
} from './constants';
import { ILayoutRedactorTypes, IStampType } from './layoutRedactorTypes.model';
import { AnyAction } from 'redux';

const initialState: ILayoutRedactorTypes = {
    stampsType: [],
    localStampsType: [],
    stampPending: false,
    stampUpdatingPending: false,
    selectedCustomStamps: [],
    selectedArticleStamps: [],
    error: null,
    articleStampType: [],
    articleStampPending: false,
    recentlyUsedStamp: [],
    interactMode: false,
};
// TODO: to rename it to StampTypes
const popupReducer = (state: ILayoutRedactorTypes = initialState, {type, payload}: AnyAction): ILayoutRedactorTypes => {
    switch (type) {
        case GET_STAMP_PENDING:
            return {
                ...state,
                stampPending: true,
            };
        case GET_STAMP_SUCCESS:
            return{
                ...state,
                stampsType: payload,
                localStampsType: payload,
                stampPending: false,
            };
        case GET_STAMP_FAILURE:
            return{
                ...state,
                error: payload,
                stampPending: false,
            };
        case GET_ARTICLE_STAMP_PENDING:
            return{
                ...state,
                articleStampPending: true,
            };
        case GET_ARTICLE_STAMP_SUCCESS:
            return{
                ...state,
                articleStampType: payload,
                articleStampPending: false,
            };
        case GET_ARTICLE_STAMP_FAILURE:
            return{
                ...state,
                error: payload,
                articleStampPending: false,
            };
        case SET_SELECTED_ARTICLE_STAMPS:
            return {
                ...state,
                selectedArticleStamps: [...payload],
            };
        case SET_SINGLE_RECENTLY_USED_STAMP:
            return {
                ...state,
                recentlyUsedStamp: payload,
            };
        case POST_STAMP_PENDING:
        case PUT_STAMP_PENDING:
        case DELETE_STAMP_PENDING:
            return {
                ...state,
                stampUpdatingPending: true,
            };
        case POST_STAMP_SUCCESS:
            return{
                ...state,
                stampsType: [...state.stampsType, ...payload],
                localStampsType: [...state.stampsType, ...payload],
                stampUpdatingPending: false,
            };
        case POST_STAMP_FAILURE:
        case PUT_STAMP_FAILURE:
        case DELETE_STAMP_FAILURE:
            return{
                ...state,
                error: payload,
                stampUpdatingPending: false,
            };
        case PUT_STAMP_SUCCESS:
            return{
                ...state,
                stampsType: state.stampsType.map((stamp: IStampType): IStampType => {
                    const updatedElements = payload.filter((updatedStamp: IStampType): boolean =>
                        stamp.id === updatedStamp.id);

                    return updatedElements.length ? updatedElements[0] : stamp;
                }),
                stampUpdatingPending: false,
            };
        case DELETE_STAMP_SUCCESS:
            return{
                ...state,
                stampsType: [...state.stampsType.filter((stamp: IStampType): boolean => stamp.id !== payload)],
                localStampsType: state.localStampsType.filter(
                    (stamp: IStampType): boolean => stamp.id !== payload,
                    ),
                stampUpdatingPending: false,
            };
        case SET_SELECTED_CUSTOM_STAMPS:
            return {
                ...state,
                selectedCustomStamps: [...payload],
            };
        case CHANGE_LOCAL_STAMPS:
            return {
                ...state,
                localStampsType: payload,
            };
        case SET_INTERACTIVE_MODE:
            return {
                ...state,
                interactMode: payload,
            };
        default:
            return state;
    }
};

export default popupReducer;

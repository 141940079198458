import * as React from 'react';
import { useState, ChangeEvent } from 'react';
import Radio from '@mui/material/Radio';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { IFolder, IYearFolder } from '../../../redux/reducers/amandaContent/amandaContent.model';
import { IRequestSubFoldersParams } from './ModalRequestSecurity.model';
import { PRIMARY } from '../../../constants/theme.constants';

export const RequestSubfolder = (
    {
        subFolders,
        fetchRequestSecurityGroupByFolder,
        availableSecurities,
        fetchAvailableSecurities,
        selectedFolderType,
        setSelectedFolderType,
        selectedCustomFolderRsn,
        setSelectedCustomFolderRsn,
    }: IRequestSubFoldersParams,
): JSX.Element => {
    const [selectedYears, setSelectedYears] = useState([]);

    const toggleShowSelectedYear = (e: React.SyntheticEvent, year: string): void => {
        e.persist();
        setSelectedYears(selectedYears.includes(year) ? selectedYears.filter(
            (selectedYear: string): boolean => selectedYear !== year) :
            [...selectedYears, year],
        );
    };

    const handleChangeSelectedFolderNumber = (e: ChangeEvent<HTMLInputElement>): void => {
        setSelectedFolderType('');
        setSelectedCustomFolderRsn(Number(e.target.value));
        fetchRequestSecurityGroupByFolder({ folderRsn: Number(e.target.value) });

        if(!availableSecurities) {
            fetchAvailableSecurities({ folderType: selectedFolderType });
        }
    };

    return (
        <React.Fragment>
            {subFolders.map((subFolder: IYearFolder): JSX.Element => (
                <ul key={subFolder.folderYear} className='modal_request_security_folders_row_year'>
                    <li>
                        {subFolder.folderYear}
                        <KeyboardArrowDown
                            onClick={(e: React.SyntheticEvent): void =>
                                toggleShowSelectedYear(e, subFolder.folderYear)}
                        />
                        {selectedYears.includes(subFolder.folderYear ) && <ul>
                            {
                                subFolder.folders.map((folder: IFolder): JSX.Element => (
                                    <li key={folder.customFolderNumber}>
                                        <Radio
                                            color={PRIMARY}
                                            onChange={handleChangeSelectedFolderNumber}
                                            checked={selectedCustomFolderRsn=== folder.folderRsn}
                                            value={folder.folderRsn}
                                        />
                                        {folder.customFolderNumber}
                                    </li>
                                ))
                            }
                        </ul>}
                    </li>
                </ul>
                ))}
        </React.Fragment>
    );
};

import * as React from 'react';
import { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import {
    CheckboxMaterialBuilder,
    RadioMaterialBuilder,
    SelectMaterialBuilder,
} from '../../../../components/materialUiForms/materialUiFormBuilder';
import {
    CREATE_PACKAGE_AS_SINGLE_FILE_PARAM,
    INCLUDE_PAGES_WITH_CONTACT_DISCLOSURES_PARAM,
    NON_RELEASABLE_PAGES_PARAM,
    INCLUDE_PAGINATED_PAGES_ONLY_PARAM, ORIGINALS_ONLY_PARAM, ERROR_WATERMARKS, INCLUDE_NOTES_PARAM,
} from '../../../../constants/export.contstants';
import { CREATE_PACKAGE_AS_SINGLE_FILE } from '../../../../constants/export.contstants';
import { RenderColorPickerInput } from '../../../../components/materialUiForms/materialUiForms';
import * as constants from '../../../../constants/export.contstants';
import './contentOptions.style.scss';
import TextField from '@mui/material/TextField';
import { PRESET_NAME_PARAM, PRESET_NAME, NO_UNIQUE_ERROR, PRESET_NAME_2, PRESET_NAME_2_PARAM, MAX_LENGTH_ERROR } from '../../../../constants/packagePresets.contants';
import { usePrevious } from '../../../../hooks/usePrevious';
import { IContentOptionChangeProps } from './contentOption.model';
import { IAnnotationType } from '../../../../redux/reducers/annotationTypes/annotationTypes.model';
import { useSelector } from 'react-redux';
import { getShowArticleStamps } from '../../../../redux/selectors/modalWindowExport';
import { getExportOptionsSelector } from '../../../../redux/selectors/modalPackagePresets';
import { getIsActivePackagePresetModal } from '../../../../redux/selectors/modal';
import { getModifiedLabels } from '../../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../../redux/selectors/localStorage';
import { initialLabel } from '../../../../constants/localization.constants';
import resourceBundle from '../../../localization/localizationData';
import { changeLang } from '../../../../redux/actions/localization';

export const getContentOptionLabelsByKey = (key: string): string => {
    switch(key) {
        case 'EXPORT_CONTENT_OPTIONS_EXPORT_AS':
            return 'exportAsLabel';
        case 'EXPORT_CONTENT_OPTIONS_ORIGINALS_ONLY':
            return 'originalsOnlyLabel';
        case 'EXPORT_CONTENT_OPTIONS_PRESET_SETTINGS':
            return 'presetsParamLabel';
        case 'EXPORT_CONTENT_OPTIONS_SELECT_PRESET':
            return 'selectPresetLabel';
        case 'EXPORT_CONTENT_OPTIONS_DOCUMENT_ORDER':
            return 'documentOrderLabel';
        case 'EXPORT_CONTENT_OPTIONS_ORDER_BY_PAGINATION':
            return 'byPaginationLabel';
        case 'EXPORT_CONTENT_OPTIONS_ORDER_BY_DOC_NAME':
            return 'byDocNameLabel';
        case 'EXPORT_CONTENT_OPTIONS_ORDER_BY_DOC_DATE':
            return 'byDocDateLabel';
        case 'EXPORT_CONTENT_OPTIONS_SEVER_COLOR':
            return 'severColorLabel';
        case 'EXPORT_CONTENT_OPTIONS_SEVER_COLOR_ORIGINAL':
            return 'severOriginalLabel';
        case 'EXPORT_CONTENT_OPTIONS_SEVER_COLOR_CUSTOM':
            return 'severCustomLabel';
        case 'EXPORT_CONTENT_OPTIONS_ARTICLE_COLOR':
            return 'articleColorLabel';
        case 'EXPORT_CONTENT_OPTIONS_PRESET_NAME':
            return 'presetNameLabel';
        case 'EXPORT_CONTENT_OPTIONS_PRESET_NAME_FR':
            return 'presetNameFrLabel';
        case 'EXPORT_CONTENT_OPTIONS_NON_RELEASABLE_PAGES':
            return 'nonReleasableLabel';
        case 'EXPORT_CONTENT_OPTIONS_EXCLUDE':
            return 'excludeLabel';
        case 'EXPORT_CONTENT_OPTIONS_INCLUDE':
            return 'includeLabel';
        case 'EXPORT_CONTENT_OPTIONS_AS_PAGE_OUT_NOTICE':
            return 'asPageOutNoticeLabel';
        case 'EXPORT_CONTENT_OPTIONS_LANGUAGE_DEFAULT':
            return 'langDefaultLabel';
        case 'EXPORT_CONTENT_OPTIONS_BILINGUAL':
            return 'bilingualLabel';
        case 'EXPORT_CONTENT_OPTIONS_LABEL_DOC_BY':
            return 'labelDocByLabel';
        case 'EXPORT_CONTENT_OPTIONS_DOCUMENT_NAME':
            return 'docNameLabel';
        case 'EXPORT_CONTENT_OPTIONS_FILENAME':
            return 'fileNameLabel';
        case 'EXPORT_CONTENT_OPTIONS_CREATE_AS_SINGLE_FILE':
            return 'createSingleFileLabel';
        case 'EXPORT_CONTENT_OPTIONS_SHOW_ARTICLE_STAMPS':
            return 'showArticlesLabel';
        case 'EXPORT_CONTENT_OPTIONS_SHOW_USER_DEFINED_STAMPS':
            return 'showUserDefinedLabel';
        case 'EXPORT_CONTENT_OPTIONS_INCLUDE_NOTES':
            return 'includeNotesLabel';
        case 'EXPORT_CONTENT_OPTIONS_INCLUDE_PAGINATED_PAGES_ONLY':
            return 'includePaginatedPagesLabel';
        case 'EXPORT_CONTENT_OPTIONS_SHOW_ARTICLE_LINKS':
            return 'showArticleLinksLabel';
        case 'EXPORT_CONTENT_OPTIONS_INCLUDE_CONTACT_DISCLOSURE_PAGES':
            return 'includeContactDisclosureLabel';
        case 'EXPORT_CONTENT_OPTIONS_SHOW_WATERMARKS':
            return 'showWaterMarksLabel';
        case 'EXPORT_CONTENT_OPTIONS_WATERMARK_LOCATION':
            return 'waterMarksLocationLabel';
        case 'EXPORT_CONTENT_OPTIONS_LANGUAGE':
            return 'languageLabel';
        case 'COMMON_LABEL_POSITION_MIDDLE_LEFT':
            return 'middleLeftLabel';
        case 'COMMON_LABEL_POSITION_MIDDLE_CENTER':
            return 'middleCenterLabel';
        case 'COMMON_LABEL_POSITION_MIDDLE_RIGHT':
            return 'middleRightLabel';
        case 'COMMON_LABEL_POSITION_BOTTOM_LEFT':
            return 'bottomLeftLabel';
        case 'COMMON_LABEL_POSITION_BOTTOM_CENTER':
            return 'bottomCenterLabel';
        case 'COMMON_LABEL_POSITION_BOTTOM_RIGHT':
            return 'bottomRightLabel';
        case 'COMMON_LABEL_POSITION_TOP_LEFT':
            return 'topLeftLabel';
        case 'COMMON_LABEL_POSITION_TOP_CENTER':
            return 'topCenterLabel';
        case 'COMMON_LABEL_POSITION_TOP_RIGHT':
            return 'topRightLabel';
        case 'EXPORT_CONTENT_OPTIONS_NO_WATERMARK_ERROR':
            return 'noWatermarkError';
        default: return '';
    }
};

const ContentOptions = (
    {
        handleOptionsChange,
        contentOptionsData,
        isPackagePresetsTab,
        selectedPresetId,
        existingPresetsName,
        presetNames,
        hasDefaultWatermark,
        hasEditPermission = true,
        annotationControlsIdList,
        severList,
    }: IContentOptionChangeProps): JSX.Element => {
    const [nameState, useName] = useState(contentOptionsData[PRESET_NAME_PARAM]);
    const [nameError, setNameError] = useState('');
    const [name2State, useName2] = useState(contentOptionsData[PRESET_NAME_2_PARAM]);
    const [name2Error, setName2Error] = useState('');
    const [isPickerOpened, useOpenPicker] = useState(false);

    const isShowArticleActive = useSelector(getShowArticleStamps);
    const presetExportOption = useSelector(getExportOptionsSelector);
    const isPackagePresetsActive = useSelector(getIsActivePackagePresetModal);
    const modifiedLabels = useSelector(getModifiedLabels);
    const redactionLang = useSelector(getRedactionLanguage);

    const prevId = usePrevious(selectedPresetId);
    const prevOptions = usePrevious(contentOptionsData);

    const langRule = changeLang(redactionLang);
    const labels = {
        presetNameLabel: initialLabel,
        presetNameFrLabel: initialLabel,
        presetsParamLabel: initialLabel,
        selectPresetLabel: initialLabel,
        documentOrderLabel: initialLabel,
        byPaginationLabel: initialLabel,
        byDocNameLabel: initialLabel,
        byDocDateLabel: initialLabel,
        exportAsLabel: initialLabel,
        originalsOnlyLabel: initialLabel,
        createSingleFileLabel: initialLabel,
        severColorLabel: initialLabel,
        severOriginalLabel: initialLabel,
        severCustomLabel: initialLabel,
        articleColorLabel: initialLabel,
        nonReleasableLabel: initialLabel,
        excludeLabel: initialLabel,
        includeLabel: initialLabel,
        asPageOutNoticeLabel: initialLabel,
        languageLabel: initialLabel,
        langDefaultLabel: initialLabel,
        bilingualLabel: initialLabel,
        labelDocByLabel: initialLabel,
        docNameLabel: initialLabel,
        fileNameLabel: initialLabel,
        showArticlesLabel: initialLabel,
        showUserDefinedLabel: initialLabel,
        includeNotesLabel: initialLabel,
        includePaginatedPagesLabel: initialLabel,
        showArticleLinksLabel: initialLabel,
        includeContactDisclosureLabel: initialLabel,
        showWaterMarksLabel: initialLabel,
        waterMarksLocationLabel: initialLabel,
        middleLeftLabel: initialLabel,
        middleCenterLabel: initialLabel,
        middleRightLabel: initialLabel,
        bottomLeftLabel: initialLabel,
        bottomCenterLabel: initialLabel,
        bottomRightLabel: initialLabel,
        topLeftLabel: initialLabel,
        topCenterLabel: initialLabel,
        topRightLabel: initialLabel,
        noWatermarkError: initialLabel,
    };

    resourceBundle.map((resource: any) => {
        if (getContentOptionLabelsByKey(resource.resourceKey)) {
            labels[getContentOptionLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getContentOptionLabelsByKey(resource.resourceKey)) {
            labels[getContentOptionLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    useEffect(() => {
        if (prevId !== selectedPresetId) {
            useName(contentOptionsData[PRESET_NAME_PARAM]);
            useName2(contentOptionsData[PRESET_NAME_2_PARAM]);
            setError(PRESET_NAME_PARAM, contentOptionsData[PRESET_NAME_PARAM]);
            setError(PRESET_NAME_2_PARAM, contentOptionsData[PRESET_NAME_2_PARAM]);
        }

        if (prevOptions !== contentOptionsData && !contentOptionsData[PRESET_NAME_PARAM]) {
            useName(contentOptionsData[PRESET_NAME_PARAM]);
        }
    });

    const isSeverShown = !!annotationControlsIdList.length &&
        severList.some((sever: IAnnotationType) => annotationControlsIdList.includes(sever.id));

    const setError = (param: string, name: string): void => {
        param === PRESET_NAME_PARAM
            ? setNameError(!!name && existingPresetsName.includes(name.toLocaleLowerCase()) && NO_UNIQUE_ERROR || '')
            : setName2Error(!!name && name.length > 255 && MAX_LENGTH_ERROR || '');
    };

    const handleChange = (name: string, value: boolean | string): void => {
        const propsToCallUpdate = [
            ORIGINALS_ONLY_PARAM,
            NON_RELEASABLE_PAGES_PARAM,
            INCLUDE_PAGINATED_PAGES_ONLY_PARAM,
            INCLUDE_PAGES_WITH_CONTACT_DISCLOSURES_PARAM,
            INCLUDE_NOTES_PARAM,
        ];

        const shouldUpdateDocList = propsToCallUpdate.some((item: string) => item === name);

        if(name === constants.SHOW_ARTICLE_STAMPS_PARAM && !value) {
            handleOptionsChange({
                [constants.SHOW_ARTICLE_STAMPS_PARAM]: value,
                [constants.ARTICLE_COLOR_PARAM]: '#000000',
                [constants.INCLUDE_SEVER_TO_ARTICLES_LINKING_LINES_PARAM]:  false,
            }, shouldUpdateDocList);
        } else {
            handleOptionsChange({ [name]: value }, shouldUpdateDocList);
        }
    };

    const handleChangeName = (param: string,value: string): void => {
        param === PRESET_NAME_PARAM ? useName(value) : useName2(value);
        handleChange(param, value);
        setError(param, value);
    };

    const handleBlurName = (param: string): void => {
        handleChange(param, param === PRESET_NAME_PARAM ? nameState: name2State);
    };

    const handleChangeColorType = (param: string, value: string): void => {
        const isColorOriginal = value === constants.ORIGINAL_SEVER_COLOR_PARAM;
        const isColorCustom = value === constants.CUSTOM_SEVER_COLOR_PARAM;

        handleChange(param, value);

        if (isColorCustom) {
            useOpenPicker(true);
        } else if (isColorOriginal) {
            useOpenPicker(false);
        }

        if (param === constants.SEVER_COLOR_PARAM) {
            handleChange(constants.SEVER_COLOR_PARAM, isColorOriginal ? null : value);
        }
    };

    const handleOpenColorPicker = (openPicker: boolean, color: string): void => {
        if (openPicker) {
            handleChange(constants.SEVER_COLOR_TYPE_PARAM, constants.CUSTOM_SEVER_COLOR_PARAM);
            handleChange(constants.SEVER_COLOR_PARAM, color);
        }
    };

    const isDisableShowArticlesLine = (): boolean => {
        if (isPackagePresetsActive) {
            return !presetExportOption.showArticleStamps;
        } else {
            return !isShowArticleActive;
        }

    };

    const renderPackagePresetsName = (): JSX.Element => {
        return (
            <div className='select-group preset-name-tab'>
                <div className='preset-name-group'>
                    <TextField
                        type='text'
                        multiline={false}
                        rows='4'
                        label={labels.presetNameLabel[langRule]}
                        variant='outlined'
                        value={nameState || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            handleChangeName( PRESET_NAME_PARAM,(e.target as HTMLInputElement).value)
                        }
                        onBlur={(): void => handleBlurName(PRESET_NAME_PARAM)}
                        margin='dense'
                        fullWidth={true}
                        error={!!nameError}
                        helperText={nameError}
                        disabled={!hasEditPermission}
                        className='preset-name'
                    />
                    <TextField
                        type='text'
                        multiline={false}
                        rows='4'
                        label={labels.presetNameFrLabel[langRule]}
                        variant='outlined'
                        value={name2State || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            handleChangeName( PRESET_NAME_2_PARAM, (e.target as HTMLInputElement).value)
                        }
                        onBlur={(): void => handleBlurName(PRESET_NAME_2_PARAM)}
                        margin='dense'
                        fullWidth={true}
                        error={!!name2Error}
                        helperText={name2Error}
                        disabled={!hasEditPermission}
                        className='preset-name'
                    />
                </div>
            </div>
        );
    };

    const renderModalExportSelects = (): JSX.Element => {
        if (!isPackagePresetsTab) {
            return (
                <div className='content-option-tab__select'>
                    <span className='content-option-tab-subtitle'>{labels.presetsParamLabel[langRule]}</span>
                    <div className='select-group'>
                        <SelectMaterialBuilder
                            value={contentOptionsData[constants.PRESETS_PARAM] || ''}
                            label={labels.selectPresetLabel[langRule]}
                            paramName={constants.PRESETS_PARAM}
                            handleSelectChange={handleChange}
                            options={presetNames}
                            disabled={!hasEditPermission}
                        />
                        <SelectMaterialBuilder
                            value={contentOptionsData[constants.DOCUMENT_ORDER_PARAM]}
                            label={labels.documentOrderLabel[langRule]}
                            paramName={constants.DOCUMENT_ORDER_PARAM}
                            handleSelectChange={handleChange}
                            options={constants.documentOrderOptions(labels, langRule)}
                            disabled={!hasEditPermission}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className='select-group'>
                    <SelectMaterialBuilder
                        value={contentOptionsData[constants.DOCUMENT_ORDER_PARAM]}
                        label={labels.documentOrderLabel[langRule]}
                        paramName={constants.DOCUMENT_ORDER_PARAM}
                        handleSelectChange={handleChange}
                        options={constants.documentOrderOptions(labels, langRule)}
                        disabled={!hasEditPermission}
                    />
                </div>
            );
        }
    };

    const renderContent = (originalsOnly: boolean): JSX.Element => {
        if (originalsOnly) {
            return (
                <React.Fragment>
                    <div className='content-option-tab__switch'>
                        <span className='content-option-tab-subtitle'>{labels.exportAsLabel[langRule]}</span>
                        {isPackagePresetsTab && renderPackagePresetsName()}
                        <div className='originals-switch-offset'>
                            <CheckboxMaterialBuilder
                                type={constants.CHECKBOX_TYPE_SWITCH}
                                label={labels.originalsOnlyLabel[langRule]}
                                checked={contentOptionsData[constants.ORIGINALS_ONLY_PARAM]}
                                handleCheckboxChange={handleChange}
                                paramName={constants.ORIGINALS_ONLY_PARAM}
                                disabled={!hasEditPermission}
                            />
                        </div>
                    </div>
                    <div className='select-group'>
                        <SelectMaterialBuilder
                            value={contentOptionsData[constants.DOCUMENT_ORDER_PARAM]}
                            label={labels.documentOrderLabel[langRule]}
                            paramName={constants.DOCUMENT_ORDER_PARAM}
                            handleSelectChange={handleChange}
                            options={constants.documentOrderOptions(labels, langRule)}
                            disabled={!hasEditPermission}
                        />
                    </div>
                    <CheckboxMaterialBuilder
                        checked={contentOptionsData[constants.CREATE_PACKAGE_AS_SINGLE_FILE_PARAM]}
                        key={CREATE_PACKAGE_AS_SINGLE_FILE_PARAM}
                        handleCheckboxChange={handleChange}
                        label={labels.createSingleFileLabel[langRule]}
                        paramName={CREATE_PACKAGE_AS_SINGLE_FILE_PARAM}
                        disabled={!hasEditPermission}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className='content-option-tab__switch'>
                        <span className='content-option-tab-subtitle'>{labels.exportAsLabel[langRule]}</span>
                        {isPackagePresetsTab && renderPackagePresetsName()}
                        <div className='originals-switch-offset'>
                            <CheckboxMaterialBuilder
                                type={constants.CHECKBOX_TYPE_SWITCH}
                                label={labels.originalsOnlyLabel[langRule]}
                                checked={contentOptionsData[constants.ORIGINALS_ONLY_PARAM]}
                                handleCheckboxChange={handleChange}
                                paramName={constants.ORIGINALS_ONLY_PARAM}
                                disabled={!hasEditPermission}
                            />
                        </div>
                    </div>
                    {renderModalExportSelects()}
                    <div className='content-option-tab__color-picker'>
                        <span className='color-picker-label'>{labels.severColorLabel[langRule]}:</span>
                        <RadioMaterialBuilder
                            value={contentOptionsData[constants.SEVER_COLOR_TYPE_PARAM]}
                            handleRadioChange={handleChangeColorType}
                            paramName={constants.SEVER_COLOR_TYPE_PARAM}
                            ariaLabel={constants.ORIGINAL_SEVER_COLOR_LABEL}
                            radioList={constants.colorOptions(labels, langRule)}
                            disabled={!hasEditPermission}
                        />
                        <RenderColorPickerInput
                            color={contentOptionsData[constants.SEVER_COLOR_PARAM]}
                            handleChange={(color: string): void =>
                                handleChangeColorType(constants.SEVER_COLOR_PARAM, color)}
                            handleClick={handleOpenColorPicker}
                            error=''
                            openColorPicker={isPickerOpened}
                            isDisabled={!hasEditPermission}
                        />
                        <span className='color-picker-label'>{labels.articleColorLabel[langRule]}:</span>
                        <RenderColorPickerInput
                            color={contentOptionsData[constants.ARTICLE_COLOR_PARAM]}
                            handleChange={(color: string): void =>
                                handleChangeColorType(constants.ARTICLE_COLOR_PARAM, color)}
                            error=''
                            isDisabled={isDisableShowArticlesLine()}
                        />
                    </div>
                    <div className='radio-wrapper'>
                        <FormLabel>{labels.nonReleasableLabel[langRule]}: </FormLabel>
                        <RadioMaterialBuilder
                            value={contentOptionsData[constants.NON_RELEASABLE_PAGES_PARAM]}
                            handleRadioChange={handleChange}
                            paramName={constants.NON_RELEASABLE_PAGES_PARAM}
                            ariaLabel={constants.NON_RELEASABLE_PAGES}
                            radioList={constants.nonReleasablePageOptions(labels, langRule)}
                            disabled={!hasEditPermission}
                        />
                    </div>
                    { !isPackagePresetsTab &&
                        <div className='radio-wrapper'>
                            <FormLabel>{labels.languageLabel[langRule]}: </FormLabel>
                            <RadioMaterialBuilder
                                value={contentOptionsData[constants.LANGUAGE_PARAM]}
                                handleRadioChange={handleChange}
                                paramName={constants.LANGUAGE_PARAM}
                                ariaLabel={constants.LANGUAGE}
                                radioList={constants.languageOptions(labels, langRule)}
                                disabled={!hasEditPermission}
                            />
                        </div>
                    }
                    <div className='radio-wrapper'>
                        <FormLabel>{labels.labelDocByLabel[langRule]}:</FormLabel>
                        <RadioMaterialBuilder
                            value={contentOptionsData[constants.LABEL_DOCUMENT_BY_PARAM]}
                            handleRadioChange={handleChange}
                            paramName={constants.LABEL_DOCUMENT_BY_PARAM}
                            ariaLabel={constants.LABEL_DOCUMENT_BY}
                            radioList={constants.labelDocumentBy(labels, langRule)}
                            disabled={!hasEditPermission}
                        />
                    </div>
                    <div className='content-option-tab__checkbox'>
                        <FormGroup>
                            {
                                constants.firstListOfCheckbox(labels, langRule).map(
                                    (list: { label: string; param: string; }): JSX.Element => (
                                        <CheckboxMaterialBuilder
                                            checked={contentOptionsData[list.param]}
                                            key={list.param}
                                            handleCheckboxChange={handleChange}
                                            label={list.label}
                                            paramName={list.param}
                                            disabled={!hasEditPermission}
                                        />
                                    ))
                            }
                        </FormGroup>
                        <FormGroup>
                            {
                                constants.secondListOfCheckbox(labels, langRule).map(
                                    (list: { label: string; param: string; }): JSX.Element => (
                                            <CheckboxMaterialBuilder
                                                checked={contentOptionsData[list.param]}
                                                key={list.param}
                                                handleCheckboxChange={handleChange}
                                                label={list.label}
                                                paramName={list.param}
                                                disabled={
                                                    list.label === labels.showArticleLinksLabel[langRule]
                                                        ? isDisableShowArticlesLine() : !hasEditPermission
                                                }
                                            />
                                    ))
                            }
                        </FormGroup>
                    </div>
                    <div className='select-builder'>
                        <div className='checkbox-switch-offset'>
                            <CheckboxMaterialBuilder
                                type={constants.CHECKBOX_TYPE_SWITCH}
                                label={labels.showWaterMarksLabel[langRule]}
                                checked={contentOptionsData[constants.SHOW_WATERMARKS_PARAM]}
                                handleCheckboxChange={handleChange}
                                paramName={constants.SHOW_WATERMARKS_PARAM}
                                disabled={!hasEditPermission || isSeverShown}
                            />
                        </div>
                        <div className='select-group'>
                            <SelectMaterialBuilder
                                value={contentOptionsData[constants.WATERMARK_LOCATION_PARAM]}
                                label={labels.waterMarksLocationLabel[langRule]}
                                paramName={constants.WATERMARK_LOCATION_PARAM}
                                handleSelectChange={handleChange}
                                options={constants.watermarkLocation(labels, langRule)}
                                disabled={
                                    !contentOptionsData[constants.SHOW_WATERMARKS_PARAM] ||
                                    !hasEditPermission || isSeverShown
                                }
                                error={contentOptionsData[constants.SHOW_WATERMARKS_PARAM] && !hasDefaultWatermark}
                                errorText={labels.noWatermarkError[langRule]}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    return (
        <div className='content-option-tab'>
            {renderContent(contentOptionsData.originalsOnly)}
        </div>
    );
};

export default ContentOptions;

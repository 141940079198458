import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AuditLog } from './containers/auditLog/AuditLog';
import { MainContainer } from './containers/mainContainer/MainContainer';
import MainPanels from './containers/mainPanels/MainPanels';
import { DuplicatePanels } from './containers/duplicatePanels/DupilcatePanels';
import { Localization } from './containers/localization/Localization';

export const MainPanelsContainer = (props: RouteComponentProps): JSX.Element => (
    <MainContainer location={props.location} routeParams={props.match}>
        <MainPanels/>
    </MainContainer>
);

export const DuplicatePanelsContainer = (props: RouteComponentProps): JSX.Element => (
    <MainContainer location={props.location} routeParams={props.match}>
        <DuplicatePanels/>
    </MainContainer>
);

export const AuditPanelsContainer = (props: RouteComponentProps): JSX.Element => (
    <MainContainer location={props.location} routeParams={props.match}>
        <AuditLog/>
    </MainContainer>
);

export const LocalizationPanelsContainer = (props: RouteComponentProps): JSX.Element => (
    <MainContainer location={props.location} routeParams={props.match}>
        <Localization/>
    </MainContainer>
);

import { AnyAction } from 'redux';
import {
    IDescription,
    IDescriptionItem,
    IIndexMetadataDescriptionsState,
    ILookup,
} from './indexMetadataDescriptions.model';
import {
    FETCH_INDEX_METADATA_DESCRIPTIONS_FAIL,
    FETCH_INDEX_METADATA_DESCRIPTIONS_PENDING,
    FETCH_INDEX_METADATA_DESCRIPTIONS_SUCCESS,
    CLEAR_INDEX_METADATA_DESCRIPTIONS,
    ADD_INDEX_METADATA_DESCRIPTIONS,
    REMOVE_INDEX_METADATA_DESCRIPTIONS,
    EDIT_INDEX_METADATA_DESCRIPTIONS,
    EXPORT_INDEX_METADATA_SUCCESS,
    EXPORT_INDEX_METADATA_FAIL,
    EXPORT_INDEX_METADATA_PENDING,
} from './constant';

export const initialState: IIndexMetadataDescriptionsState = {
    metadataDescriptions: [],
    exportedMetadataSuccess: false,
    exportMetadataPending: false,
    pending: false,
    error: null,
};

const createEmptyIndex = (item: IDescription, id: number): IDescription => {
    return {
        ...item,
        data: [
            ...item.data,
            {
                occurrences: 0,
                value: {
                    id: 0,
                    metadataId: id,
                    description: '',
                    description2: '',
                },
            },
        ],
    };
};

export const indexMetadataDescriptionsReducer =
    (state: IIndexMetadataDescriptionsState = initialState, { type, payload }: AnyAction)
        : IIndexMetadataDescriptionsState => {
    switch (type) {
        case FETCH_INDEX_METADATA_DESCRIPTIONS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case FETCH_INDEX_METADATA_DESCRIPTIONS_FAIL:
            return {
                ...state,
                pending: false,
                error: payload,
            };
        case FETCH_INDEX_METADATA_DESCRIPTIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                metadataDescriptions: payload,
            };
        case CLEAR_INDEX_METADATA_DESCRIPTIONS:
            return {
                ...state,
                pending: false,
                error: null,
                metadataDescriptions: [],
            };
        case ADD_INDEX_METADATA_DESCRIPTIONS:
            return {
                ...state,
                pending: false,
                error: null,
                metadataDescriptions: state.metadataDescriptions.map((item: IDescription) =>
                    item.id === payload
                        ? createEmptyIndex(item, payload)
                        : item,
                ),
            };
        case REMOVE_INDEX_METADATA_DESCRIPTIONS:
            return {
                ...state,
                pending: false,
                error: null,
                metadataDescriptions: state.metadataDescriptions.map((item: IDescription) =>
                    item.id === payload.descriptionId
                        ? {
                            ...item,
                            data: item.data
                                .filter((field: IDescriptionItem, i: number): boolean =>
                                    i !== payload.dataIndex),
                        }
                        : item,
                ),
            };
        case EDIT_INDEX_METADATA_DESCRIPTIONS:
            return {
                ...state,
                pending: false,
                error: null,
                metadataDescriptions: state.metadataDescriptions.map((item: IDescription) =>
                    item.id === payload.descriptionId
                        ? {
                            ...item,
                            data: item.data.map((field: IDescriptionItem, i: number): IDescriptionItem => {
                                return i === payload.dataIndex
                                    ? {...field, value: {...(field.value as ILookup), [payload.param]: payload.value}}
                                    : field;
                            }),
                        }
                        : item,
                ),
            };
        case EXPORT_INDEX_METADATA_SUCCESS:
            return {
                ...state,
                exportedMetadataSuccess: true,
                exportMetadataPending: false,

            };
        case EXPORT_INDEX_METADATA_PENDING:
            return {
                ...state,
                exportMetadataPending: true,
                error: null,
            };
        case EXPORT_INDEX_METADATA_FAIL:
            return {
                ...state,
                error: payload,
                exportMetadataPending: false,
            };
        default:
            return state;
    }
};

export default indexMetadataDescriptionsReducer;

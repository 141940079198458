export const ADD_ANNOTATION_PENDING = 'reduction/ADD_ANNOTATION_PENDING';
export const ADD_ANNOTATION_SUCCESS = 'reduction/ADD_ANNOTATION_SUCCESS';
export const ADD_ANNOTATION_FAILURE = 'reduction/ADD_ANNOTATION_FAILURE';

export const CHANGE_ANNOTATION_COLOR = 'reduction/CHANGE_ANNOTATION_COLOR';
export const CHANGE_ANNOTATION_TEXT = 'reduction/CHANGE_ANNOTATION_TEXT';

export const DELETE_ANNOTATION_PENDING = 'reduction/DELETE_ANNOTATION_PENDING';
export const DELETE_ANNOTATION_SUCCESS = 'reduction/DELETE_ANNOTATION_SUCCESS';
export const DELETE_ANNOTATION_FAILURE = 'reduction/DELETE_ANNOTATION_FAILURE';

export const UPDATE_ANNOTATION_PENDING = 'reduction/UPDATE_ANNOTATION_PENDING';
export const UPDATE_ANNOTATION_SUCCESS = 'reduction/UPDATE_ANNOTATION_SUCCESS';
export const UPDATE_ANNOTATION_FAILURE = 'reduction/UPDATE_ANNOTATION_FAILURE';

export const GET_CURRENT_PAGE_ANNOTATIONS_PENDING = 'reduction/GET_CURRENT_PAGE_ANNOTATIONS_PENDING';
export const GET_CURRENT_PAGE_ANNOTATIONS_SUCCESS = 'reduction/GET_CURRENT_PAGE_ANNOTATIONS_SUCCESS';
export const GET_CURRENT_PAGE_ANNOTATIONS_FAILURE = 'reduction/GET_CURRENT_PAGE_ANNOTATIONS_FAILURE';

export const REMOVE_ANNOTATIONS_PER_PAGE = 'reduction/REMOVE_ANNOTATIONS_PER_PAGE';

export const ADD_ANNOTATIONS_TO_DOCUMENTS_PENDING = 'reduction/ADD_ANNOTATIONS_TO_DOCUMENTS_PENDING';
export const ADD_ANNOTATIONS_TO_DOCUMENTS_SUCCESS = 'reduction/ADD_ANNOTATIONS_TO_DOCUMENTS_SUCCESS';
export const ADD_ANNOTATIONS_TO_DOCUMENTS_FAILURE = 'reduction/ADD_ANNOTATIONS_TO_DOCUMENTS_FAILURE';

export const SET_LINKED_ARTICLES_PENDING = 'redaction/SET_LINKED_ARTICLES_PENDING';
export const SET_LINKED_ARTICLES_SUCCESS = 'redaction/SET_LINKED_ARTICLES_SUCCESS';
export const SET_LINKED_ARTICLES_FAILURE = 'redaction/SET_LINKED_ARTICLES_FAILURE';
export const HIGHLIGHT_LINKED_SEVERS = 'redaction/HIGHLIGHT_LINKED_SEVERS';

export const SET_AUTO_CORRECT_SEVER = 'redaction/SET_AUTO_CORRECT_SEVER';
export const SNAP_ANNOTATION_PENDING ='redaction/SNAP_ANNOTATION_PENDING';
export const SET_RECENT_AUTOCORRECTED_ANNOTATION_ID = 'redaction/SET_RECENT_AUTOCORRECTED_ANNOTATION_ID';

export const UNDO_SNAP_ANNOTATION_PENDING = 'redaction/UNDO_SNAP_ANNOTATION_PENDING';
export const UNDO_SNAP_ANNOTATION_SUCCESS = 'redaction/UNDO_SNAP_ANNOTATION_SUCCESS';
export const UNDO_SNAP_ANNOTATION_FAILURE = 'redaction/UNDO_SNAP_ANNOTATION_FAILURE';

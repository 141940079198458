import { createSelector } from 'reselect';
import {
    IGlobalSettingsState,
    IDisclosureSettings,
    IExemptionSettings, IRequestTypes,
} from '../reducers/modalGlobalSettings/globalSettings.model';
import { get } from 'lodash';
import { IState } from '../store';
import { getRedactionRequestType } from './initialization';
import {
  IBasicSettings,
} from '../../containers/modalWindowContents/modalGlobalSettings/basicSettings/BasicSettingsTab.model';

export const getGlobalSettings = (state: IState): IGlobalSettingsState => state.modalGlobalSettings;
export const getGlobalSettingsLoading = createSelector(
    [getGlobalSettings],
    (globalSettingsModal: IGlobalSettingsState): boolean => globalSettingsModal.globalSettingsPending,
);

export const getUpdateGlobalSettingsLoading = createSelector(
    [getGlobalSettings],
    (globalSettingsModal: IGlobalSettingsState): boolean => globalSettingsModal.updateGlobalSettingsPending,
);

export const getDisclosureSettings = createSelector(
    [getGlobalSettings],
    (globalSettingsModal: IGlobalSettingsState): IDisclosureSettings => globalSettingsModal.disclosureSettings,
);

export const getRequestTypesWithDisclosureTypes = createSelector(
    [getGlobalSettings],
    (globalSettingsModal: IGlobalSettingsState): IRequestTypes[] =>
                globalSettingsModal.disclosureSettings.requestTypesWithDisclosureTypes,
);

export const getBasicSettings = createSelector(
    [getGlobalSettings],
    (globalSettingsModal: IGlobalSettingsState): IBasicSettings => globalSettingsModal.basicSettings,
);

export const getExemptionSettings = createSelector(
    [getGlobalSettings],
    (globalSettingsModal: IGlobalSettingsState): IExemptionSettings => globalSettingsModal.exemptionSettings,
);

export const getClearPrefixAndSuffix = createSelector(
    [getExemptionSettings],
    (exemptionSettings: IExemptionSettings): IExemptionSettings => {
        const prefix = get(exemptionSettings, 'prefix', '');
        const suffix = get(exemptionSettings, 'suffix', '');

        return {
            prefix,
            suffix,
        };
    },
);

export const hasCurrentReductionDefaultDisclosure = createSelector(
    [getRequestTypesWithDisclosureTypes, getRedactionRequestType],
    (
        requestTypesWithDisclosureTypes: IRequestTypes[],
        requestType: string,
    ): boolean => {

        const currentAssociatedRequest = requestTypesWithDisclosureTypes.filter(
            (request: IRequestTypes) => request.requestType === requestType);

        return !!currentAssociatedRequest.length &&
            !!currentAssociatedRequest[0].defaultDisclosureTypeId;
    },
);

import * as React from 'react';
import { getSessionItem, getItem } from '../../utils/localStorage.util';
import { commonLabels, getCommonLabelsByKey } from '../../redux/actions/localization';
import { REDACTION_LANG, MODIFIED_LABELS, MODE_CONTRIBUTE, MODE_CONSULT } from '../../redux/reducers/localStorage/constant';
import resourceBundle from '../../containers/localization/localizationData';
import { MaterialReactTable, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import { IStackOptions } from 'redux/reducers/stackList/stackList.model';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import SettingsTable from './SettingsTable/SettingsTable';
import './table.styles.scss';
import { useContextMenu } from 'react-contexify';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { Pagination } from './Pagination/Pagination';
import { Spinner } from '../../components/spinner/Spinner';
import { useEffect, useRef, useState } from 'react';
import { IHeader } from 'containers/leftTopBar/leftTopBar.model';
import { SortingRule } from 'containers/leftBottomBar/LeftBottomBar';
import { PageData } from 'redux/reducers/common/common.model';

interface ResizibleSortableTableProps {
    title?: string;
    allowSettings?: boolean;
    stackSelector?: boolean;
    stackList?: IStackOptions[];
    chosenStack?: number;
    handleChange?: (event: SelectChangeEvent<number>, child: React.ReactNode) => void;
    isDraggable?: boolean;
    mode?: string;
    columns: any[];
    data: any[];
    pageSize?: number;
    page?: number;
    handleDragEnd?: (result: any) => boolean;
    changeColumns?: (columns: any[]) => void;
    modifyColumns?: (columns: any[], userId: string) => void;
    userId?: string;
    resetColumnHeader?: (userId: string) => void;
    resetedPageListColumnLoading?: boolean;
    userPreferenceTable?: boolean;
    storeSortSession?: boolean;
    handleSortSession?: (param: string, checked: string, pageName: string) => void;
    sortParams?: any;
    pageName?: string;
    dynamicColumns?: any[];
    isPDFLoading?: boolean;
    contextMenuId?: any;
    selectedIds?: any;
    pageMenuStartPoint?: any;
    mouseDown?: any;
    onMouseDown?: any;
    loading?: any;
    showPagination?: any;
    pagesCount?: any;
    defaultPageSize?: any;
    sortable?: boolean;
    serverPagination?: boolean;
    useDefaultTable?: boolean;
    enableShowHideColumn?: boolean;
    isSearch?: boolean;
    defaultColumnSorted?: SortingRule[];
    getTrProps?: (row: any) => void;
    onFetchData?: (state: any) => void;
    onPageChange?: (page: number) => void;
    sortChange?: (sortState: MRT_SortingState) => void;
}
type ColumnSizingState = Record<string, number>;
type ColumnOrderState = string[];
type VisibilityState = Record<string, boolean>;
const ResizibleSortableTable: React.FC<ResizibleSortableTableProps> = ({
    title,
    allowSettings,
    stackSelector = false,
    stackList,
    chosenStack,
    handleChange,
    isDraggable,
    contextMenuId,
    pageMenuStartPoint,
    resetedPageListColumnLoading,
    selectedIds,
    mode,
    columns,
    data: initialData,
    changeColumns,
    handleDragEnd,
    sortChange,
    userId,
    showPagination,
    resetColumnHeader,
    onFetchData,
    onPageChange,
    defaultPageSize,
    pageSize,
    page,
    pagesCount,
    useDefaultTable,
    serverPagination,
    userPreferenceTable,
    defaultColumnSorted,
    dynamicColumns,
    enableShowHideColumn = false,
    modifyColumns,
    sortable,
    getTrProps,
    loading,
    isSearch,
    storeSortSession,
    sortParams,
    handleSortSession,
    pageName
}) => {
    const [data, setData] = useState(initialData);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
    const [sortingState, setSortingState] = useState<MRT_SortingState>(defaultColumnSorted || []);
    const [columnsort, setColumnsort] = useState([]);
    const [defaultSorted, setDefaultSorted] = useState<MRT_SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

    const handleColumnFiltersChange = (
        updaterOrValue: MRT_ColumnFiltersState | ((old: MRT_ColumnFiltersState) => MRT_ColumnFiltersState)
    ) => {
        const newFilters: MRT_ColumnFiltersState =
            typeof updaterOrValue === 'function'
                ? updaterOrValue(columnFilters)
                : { ...columnFilters, ...updaterOrValue };
        setColumnFilters(newFilters);
    };

    const applyFilters = (data: any[], filters: MRT_ColumnFiltersState) => {
        return data.filter(row => {
            return filters.every(filter => {
                const value = row[filter.id];
                if (value == null) return false;
                if (typeof value === 'string') {
                    return value.toLowerCase().includes((filter.value as string).toLowerCase());
                }
                if (typeof value === 'number') {
                    return value === Number(filter.value);
                }
                if (typeof value === 'boolean') {
                    return value === (filter.value === 'true');
                }
                return value == filter.value;
            });
        });
    };

    const [tablePage, setTablePage] = useState(0);
    defaultPageSize = defaultPageSize ? defaultPageSize : 100;
    const [tablepageSize, setTablePageSize] = useState(defaultPageSize);

    useEffect(() => {
        setDefaultSortOrder(columns);
        resetPagination();
    }, [columns]);
    const resetPagination = (): void => {
        if (data.length === 0 && tablePage != 0) {
            setTablePage(0);
        }
    };

    const setDefaultSortOrder = (column: any[]): void => {
        const sortedColumns = setSortOrder(column);
        setColumnsort(sortedColumns);
        setDefaultSorted(sortedColumns);
    };

    const sortDataLocally = (data: any[], sorting: MRT_SortingState) => {
        return data.sort((a, b) => {
            for (const sort of sorting) {
                const { id, desc } = sort;
                const fieldPath = id.split('.');
                const getValue = (obj: any, path: string[]) => {
                    return path.reduce((acc, key) => acc && acc[key], obj);
                };
                const valueA = fieldPath.length > 1 ? getValue(a, fieldPath) : a[id];
                const valueB = fieldPath.length > 1 ? getValue(b, fieldPath) : b[id];
                const normalizedValueA = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
                const normalizedValueB = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;
                if (normalizedValueA < normalizedValueB) return desc ? 1 : -1;
                if (normalizedValueA > normalizedValueB) return desc ? -1 : 1;
            }
            return 0;
        });
    };

    const sortMethod = (newSorted: MRT_SortingState): void => {
        let id = '';
        let value = '';
        if (columns && columns.length) {
            columns = columns.map((columnHeader: IHeader): IHeader => {
                if (newSorted.length && columnHeader.id === newSorted[0].id && newSorted[0].desc) {
                    columnHeader.sortOrder = "desc";
                    id = columnHeader.id;
                    value = 'desc'
                } else if (newSorted.length && columnHeader.id === newSorted[0].id && !newSorted[0].desc) {
                    columnHeader.sortOrder = "asc";
                    id = columnHeader.id;
                    value = 'asc'
                } else {
                    columnHeader.sortOrder = null;
                }
                return columnHeader;
            });
            if (handleSortSession) {
                handleSortSession(id, value, pageName);
            }
        }
    }

    if (sortParams) {
        const sortOrder: SortingRule[] = [];
        const pageData: PageData = sortParams;
        if (columns && columns.length) {
            columns = columns.map((columnHeader: IHeader): IHeader => {
                if (columnHeader.id === pageData.columnName) {
                    columnHeader.sortOrder = pageData.sortOrder;
                    sortOrder.push({
                        id: columnHeader.id,
                        desc: pageData.sortOrder === 'desc',
                    });
                }
                return columnHeader;
            });
        }
        sortDataLocally(data, sortOrder);
    } else {
        const sortOrder: SortingRule[] = [];
        if (columns && columns.length) {
            columns.map((columnHeader: IHeader): IHeader => {
                if (columnHeader.sortOrder) {
                    sortOrder.push({
                        id: columnHeader.id,
                        desc: columnHeader.sortOrder === 'desc',
                    });
                }
                return columnHeader;
            });
        }
        sortDataLocally(data, sortOrder);
    }

    useEffect(() => {
        setData(initialData);
    }, [initialData]);
    const initialColumnSizing: ColumnSizingState = columns.reduce((acc, column) => {
        if (column.id) {
            acc[column.id] = column.width ? column.width : 80;
        }
        return acc;
    }, {} as ColumnSizingState);
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(initialColumnSizing);
    const initialColumnOrder = columns.sort((a, b) => a.displayOrder - b.displayOrder).map(column => column.id);
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(initialColumnOrder);
    let initialOrderRef = useRef(initialColumnOrder);
    const visibilityState: VisibilityState = columns.reduce((acc, column) => {
        acc[column.id] = column.show;
        return acc;
    }, {} as Record<string, boolean>);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(visibilityState);

    const handleSortingChange = (updaterOrValue: MRT_SortingState | ((old: MRT_SortingState) => MRT_SortingState)) => {
        const newSorting: MRT_SortingState = typeof updaterOrValue === 'function'
            ? (updaterOrValue as (old: MRT_SortingState) => MRT_SortingState)(sortingState)
            : updaterOrValue;
        setSortingState(newSorting);
        sortChange && sortChange(newSorting);
    };

    const redactionLang = getSessionItem(REDACTION_LANG);
    const modifiedLabels = getItem(MODIFIED_LABELS);

    resourceBundle.forEach((resource) => {
        if (getCommonLabelsByKey(resource.resourceKey)) {
            commonLabels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }
    });

    if (modifiedLabels && modifiedLabels.length) {
        modifiedLabels.forEach((resource) => {
            if (getCommonLabelsByKey(resource.resourceKey)) {
                commonLabels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
        });
    }

    const handleRowClick = (row: any) => {
        setSelectedRowId(row.original.id);
        getTrProps && getTrProps(row);
    };

    const handleTablePageChange = (newPage) => {
        setTablePage(newPage);
    };

    const onChangeDefaultSortOrder = (updaterOrValue: MRT_SortingState | ((old: MRT_SortingState) => MRT_SortingState)) => {
        const newSorting: MRT_SortingState = typeof updaterOrValue === 'function'
            ? (updaterOrValue as (old: MRT_SortingState) => MRT_SortingState)(sortingState)
            : updaterOrValue;
        if (newSorting.length > 0) {
            sortMethod(newSorting);
            if (!newSorting.length) {
                setDefaultSorted([]);
            }
            const sortedColumns = setSortOrder(columns);
            setDefaultSorted(sortedColumns);
            const sortedData = sortDataLocally(data, newSorting);
            setData(sortedData);
            setTablePage(0);
            if (changeColumns) {
                changeColumns(columns);
            }
            if (modifyColumns) {
                modifyColumns(columns, userId);
            }
        }
    };

    const setSortOrder = (columns: any[]): SortingRule[] => {
        const columnsSorted: SortingRule[] = [];
        if (columns && columns.length) {
            columns.map((columnHeader: IHeader) => {
                if (columnHeader.sortOrder === "desc") {
                    return columnsSorted.push({
                        id: columnHeader.id,
                        desc: true,
                    });
                } else if (columnHeader.sortOrder === "asc") {
                    return columnsSorted.push({
                        id: columnHeader.id,
                        desc: false,
                    });
                }
            });
        }
        return columnsSorted;
    }

    const handleColumnOrderChange = (newOrder: string[]) => {
        if (JSON.stringify(newOrder) === JSON.stringify(initialOrderRef.current)) {
            resetColumnHeader(userId);
        } else {
            const createKeyValuePairs = newOrder.reduce((acc, columnName, index) => {
                acc[columnName] = index + 1;
                return acc;
            }, {} as Record<string, number>);
            setColumnOrder(newOrder);
            columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
                data.displayOrder = createKeyValuePairs[data.id];
                return data;
            }
            )
            columns = columns.sort((a, b) => a.displayOrder - b.displayOrder);
            initialOrderRef.current = newOrder;
            if (modifyColumns) {
                modifyColumns(columns, userId);
            }
        }
    };

    const handleColumnSizingChange = (updater: (oldState: ColumnSizingState) => ColumnSizingState) => {
        setColumnSizing((prevState) => {
            const newState = updater(prevState);
            Object.entries(newState).forEach(([columnId, width]) => {
                columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
                    if (data.accessorKey == columnId) {
                        data.width = width;
                    }
                    return data;
                }
                )
            });
            if (modifyColumns) {
                modifyColumns(columns, userId);
            }
            return newState;
        });
    };

    const resetTableHeader = () => {
        resetColumnHeader(userId);
    }

    const handleShowAll = () => {
        columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
            if (data) {
                data.show = true;
            }
            return data;
        }
        )
        const visibilityStateNew = columns.reduce((acc, column) => {
            acc[column.id] = column.show;
            return acc;
        }, {} as Record<string, boolean>);
        setColumnVisibility(visibilityStateNew);
        if (modifyColumns) {
            modifyColumns(columns, userId);
        }
    }

    const handleHideAll = () => {
        columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
            if (data) {
                data.show = false;
            }
            return data;
        }
        )
        const visibilityStateNew = columns.reduce((acc, column) => {
            acc[column.id] = column.show;
            return acc;
        }, {} as Record<string, boolean>);
        setColumnVisibility(visibilityStateNew);
        if (modifyColumns) {
            modifyColumns(columns, userId);
        }
    }

    const handleColumnVisibilityChange = (updater: (oldState: VisibilityState) => VisibilityState) => {
        setColumnVisibility((prevState) => {
            const newState = updater(prevState);
            Object.entries(newState).forEach(([columnId, visibility]) => {
                columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
                    if (data.accessorKey == columnId) {
                        data.show = visibility;
                    }
                    return data;
                }
                )
            });
            if (modifyColumns) {
                modifyColumns(columns, userId);
            }
            return newState;
        });
    };

    const handleClickCheckbox = (columnName: string, previousState: boolean) => {
        columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
            if (data.accessorKey == columnName) {
                data.show = !previousState;
            }
            return data;
        }
        )
        const visibilityStateNew = columns.reduce((acc, column) => {
            acc[column.id] = column.show;
            return acc;
        }, {} as Record<string, boolean>);
        setColumnVisibility(visibilityStateNew);
        if (modifyColumns) {
            modifyColumns(columns, userId);
        }
    }

    const handleDrop = (dragIndex: number, hoverIndex: number) => {
        const updatedOrder = [...columnOrder];
        const [removed] = updatedOrder.splice(dragIndex, 1);
        updatedOrder.splice(hoverIndex, 0, removed);
        setColumnOrder(updatedOrder);
        const updatedColumns = updatedOrder.map(orderId => columns.find(col => col.id === orderId));
        if (modifyColumns) {
            modifyColumns(updatedColumns, userId);
        }

    };

    const filteredData = applyFilters(data, columnFilters);
    const tablepagesCount = filteredData.length;
    const dataToShow = filteredData.slice(tablePage * tablepageSize, (tablePage + 1) * tablepageSize);

    const CustomPagination = (props: any) => {
        if (!showPagination) {
            return null;
        }
        return (
            <Pagination
                {...props}
                page={tablePage}
                canNext={tablePage < (Math.ceil(tablepagesCount / defaultPageSize) - 1)}
                canPrevious={tablePage > 0}
                pageSize={tablepageSize}
                dataLength={filteredData.length}
                onPageChange={handleTablePageChange}
                pages={Math.ceil(tablepagesCount / defaultPageSize)}
            />
        );
    };

    const table = useMaterialReactTable({
        columns,
        data: dataToShow,
        enableStickyHeader: true,
        enableColumnActions: false,
        enableGlobalFilter: false,
        enableColumnResizing: true,
        enableColumnDragging: false,
        enableHiding: false,
        enableColumnOrdering: false,
        enableDensityToggle: enableShowHideColumn,
        enableColumnFilters: enableShowHideColumn,
        enableSorting: sortable,
        manualFiltering: true,
        enableFilterMatchHighlighting: true,
        manualSorting: true,
        sortDescFirst: false,
        manualPagination: true,
        enablePagination: false,
        enableFullScreenToggle: false,
        muiTableContainerProps: { sx: { height: '100%' } },
        muiTablePaperProps: { sx: { display: 'flex', flexDirection: 'column', height: '100%' } },
        rowCount: tablepagesCount,
        pageCount: Math.ceil(tablepagesCount / tablepageSize),
        columnResizeMode: 'onEnd',
        layoutMode: 'grid',
        state: {
            sorting: (!serverPagination || useDefaultTable) ? defaultSorted : sortingState,
            columnSizing: columnSizing,
            columnOrder,
            columnVisibility: columnVisibility,
            columnFilters: columnFilters
        },
        onColumnSizingChange: handleColumnSizingChange,
        onColumnOrderChange: handleColumnOrderChange,
        onColumnVisibilityChange: handleColumnVisibilityChange,
        onColumnFiltersChange: handleColumnFiltersChange,
        onSortingChange: (!serverPagination || useDefaultTable) ? (userPreferenceTable && onChangeDefaultSortOrder) : handleSortingChange,
        localization: redactionLang == 'en' ? MRT_Localization_EN : MRT_Localization_FR,
        defaultColumn: {
            minSize: 20,
            maxSize: 9001,
            size: 80,
        },
        muiFilterTextFieldProps: {
            sx: {
                minWidth: "80px",
            },
        },
        muiTableHeadCellProps: (props) => {
            const sortDir = props.column.getIsSorted();
            if (typeof sortDir === 'string') {
                return {
                    sx: {
                        backgroundColor: "#f2f2f2",
                        color: "#8492a6",
                        boxShadow: sortDir == 'asc' ? 'inset 0 3px 0 0 rgba(0, 0, 0, 0.6);' : 'inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);',
                        "& .MuiBadge-root": {
                            display: "none !important",
                        },
                    },
                };
            }
            return {
                sx: {
                    backgroundColor: "#f2f2f2",
                    color: "#8492a6",
                    "& .MuiBadge-root": {
                        display: "none !important",
                    },
                }
            }
        },
        muiTableBodyCellProps: ({ row }) => ({
            sx: {
                fontWeight: selectedIds != null && selectedIds.includes(row.original.id) ? 'bold' : '',
            }
        }),
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => handleRowClick(row),
            sx: {
                backgroundColor: selectedIds != null && selectedIds.includes(row.original.id) ? '#e0e0e0' : 'inherit',
                cursor: isDraggable ? 'grab' : 'default',
            },
            onContextMenu: (event) => {
                event.preventDefault();
                if (!isSearch) {
                    handleRowClick(row);
                    handleContextMenu(event);
                }
            },
            draggable: isDraggable,
            onDragStart: (event) => {
                event.dataTransfer.setData('text/plain', JSON.stringify(row.original));
            },
            onDragOver: (event) => {
                event.preventDefault();
            },
            onDrop: (event) => {
                event.preventDefault();
                const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'));
                const droppedIndex = data.findIndex(item => item.id === droppedItem.id);
                const targetIndex = data.findIndex(item => item.id === row.original.id);
                const dragData = { id: droppedItem.id, pageNumber: targetIndex, prevPageNumber: droppedIndex };
                if (droppedIndex !== -1 && targetIndex !== -1 && !handleDragEnd(dragData)) {
                    const [removedItem] = data.splice(droppedIndex, 1);
                    data.splice(targetIndex, 0, removedItem);
                    setData([...data]);
                }
            },
        }),
        muiTableFooterProps: () => {
            return {
                sx: {
                    outline: 'none',
                }
            }
        },
        muiTopToolbarProps: () => {
            if (!title) {
                return {
                    sx: {
                        display: 'none',
                    },
                };
            }
            return {
                sx: {
                    "& .MuiSvgIcon-root": {
                        fontSize: "1.2rem !important"
                    },
                }
            }
        },
        muiBottomToolbarProps: () => {
            if (!showPagination) {
                return {
                    sx: {
                        display: 'none',
                    },
                };
            }
        },
        renderBottomToolbarCustomActions: () => (
            <CustomPagination
                page={page}
                pageSize={pageSize}
                dataLength={pagesCount}
                onPageChange={handleTablePageChange}
            />
        ),
        renderTopToolbarCustomActions: () => (
            <div className="react_table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
                <div className="left-section" style={{ display: 'flex', alignItems: 'center' }}>
                    <h4>{title}</h4>
                    {stackSelector && (
                        <Select
                            disabled={false}
                            value={chosenStack}
                            onChange={handleChange}
                            className="react_table-select"
                            style={{ marginLeft: '10px', width: 'auto' }}
                        >
                            {!isContribute && (
                                <MenuItem value={0} className="react_table-stack-item">
                                    {initialText}
                                </MenuItem>
                            )}
                            {stackList.map((option: IStackOptions) => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id}
                                    className="react_table-stack-item"
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </div>
                <div className="right-section" style={{ display: 'flex', alignItems: 'center', marginBottom: 'auto' }}>
                    {allowSettings && (
                        <SettingsTable
                            columns={columns.sort((a, b) => a.displayOrder - b.displayOrder)}
                            handleClickCheckbox={handleClickCheckbox}
                            resetColumns={resetTableHeader}
                            handleDrop={handleDrop}
                            columnOrder={columnOrder}
                            handleShowAll={handleShowAll}
                            handleHideAll={handleHideAll}
                        />
                    )}
                </div>
            </div>
        )

    });

    const handleContextMenu = (event: React.MouseEvent): void => {
        const { show } = useContextMenu({ id: contextMenuId });
        let x = event.clientX;
        let y = event.clientY;

        if (event.clientX === 0 && event.clientY === 0 && pageMenuStartPoint) {
            x = pageMenuStartPoint.x;
            y = pageMenuStartPoint.y;
        }

        show(event, {
            position: { x, y },
            props: null,
        });
    }

    const isContribute = mode === MODE_CONTRIBUTE;
    const isConsult = mode === MODE_CONSULT;
    const initialText = isConsult ? 'Consulted Documents' : 'All Documents';

    return (
        loading ? <Spinner active={true} /> :
            <div className="react_table">
                <div className="material-react-table">
                    <MaterialReactTable
                        table={table}
                    />
                </div>
            </div>
    );
};

export default ResizibleSortableTable;
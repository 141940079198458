import { createSelector } from 'reselect';
import { isEmpty, get, find } from 'lodash';
import { IState } from '../store';
import { IDocument, IPages, PageListState } from 'redux/reducers/pageList/pageList.model';
import { IPageMetadataInfo } from '../reducers/pageList/pageList.model';
import { getDisclosureTypesAccordance, getAllDisclosureTypesList } from './disclosureTypes';
import { IDisclosureTypeState } from '../reducers/disclosureTypes/disclosureType.model';
import {
    IAdditionalArticleStamp,
    IArticleStamp,
    IValidExemptionDtoList,
} from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { YES, NO } from '../../constants/common.constants';
import { IDefaultIndex } from '../reducers/indexMetadata/indexMetadata.model';
import { IDisclosureOptions } from '../reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { getClearPrefixAndSuffix } from './modalGlobalSettings';
import { IExemptionSettings } from '../reducers/modalGlobalSettings/globalSettings.model';
import { ITableSort } from '../../containers/leftBottomBar/leftBottomBar.model';
import { getDocumentList } from './documentList';
import { IFile } from '../reducers/documentList/documentList.model';
import { setValue } from './documentList';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { IComment } from '../../containers/comment/comment.model';

export const getState = (state: IState): IState => state;
export const getPageListState = (state: IState): PageListState => state.pageList;
export const getCurrentDocument = (state: IState): IDocument => state.pageList.currentDocument;
export const getCurrentPage = (state: IState): IPages => state.pageList.currentPage;
export const getCommentLoadingFlag = (state: IState): boolean => state.pageList.commentLoadingFlag;
export const getPageListLoading = (state: IState): boolean => state.pageList.loading;
export const getPageListUpdateDisclosurePending = (state: IState): boolean => state.pageList.loadingUpdateDisclosure;
export const getPageCurrentDocLoading = (state: IState): boolean => state.pageList.loadingCurrentDoc;
export const getSelectedDocuments = (state: IState): number[] => state.pageList.selectedDocuments;
export const getSelectedPages = (state: IState): number[] => state.pageList.selectedPages;
export const getLoadingPageOrdering = (state: IState): boolean => state.pageList.loadingPageOrdering;
export const getPagesDisclosureLoading = (state: IState): number[] => state.pageList.pagesDisclosureLoading;

// pageList pagination/sorting
export const getCurrentPageNumber = (state: IState): number => state.pageList.currentPageNumber;
export const getCurrentPageSort = (state: IState): ITableSort => state.pageList.currentPageSort;

// TODO need to add return type
export const getPageInformation = createSelector(
    [getCurrentDocument, getCurrentPage],
    (document: IDocument, page: IPages) => document && page && !isEmpty(page) && {
        documentId: document.id,
        pageId: page.id,
        layers: page.layers,
        rotation: page.rotation || 0,
    },
);

export const currentDocIdFromContexMenu = (state: IState): number => state.contextMenu.contextMenuProps.currentDocId;
export const getPagesCount = createSelector(
    [getCurrentDocument, getDocumentList, currentDocIdFromContexMenu],
    (document: IDocument, files: IFile[], documentId: number): number => {
        const docId = document && document.id ? document.id : documentId;

        return files.find((file: IFile) => file.id === docId).pageCount;
    },
);

export const layoutReductorTypes = (state: IState): IArticleStamp[] => state.layoutRedactorTypes.articleStampType;
export const layoutReductorAdditionalTypes =
    (state: IState): IAdditionalArticleStamp[] => state.pageList.exemptionsCurrentDocument;

export const transformExemptionIdsToArticles = (exemptionIds: number[], articleStampType: IArticleStamp[]): string =>
    articleStampType.reduce((acc: string[], item: IArticleStamp): string[] => {
        const exemptionLabels = item.validExemptionDtoList
            .filter((validExemption: IValidExemptionDtoList): boolean =>
                exemptionIds && exemptionIds.includes(validExemption.exemptionCode))
            .map((validExemption: IValidExemptionDtoList): string => validExemption.exemptionLabel);

        return [...acc, ...exemptionLabels];
    }, []).join(', ');

export const transformExemptionIdsToAdditionalArticlesWithPrefixSuffix =
    (
        exemptionIds: number[],
        articleStampType: IAdditionalArticleStamp[],
        { prefix, suffix }: IExemptionSettings,
    ): string =>
        exemptionIds.reduce((articleTitles: string, exemptionId: number) => {
            const oneArticleStampType =
                articleStampType.find(({ exemptionCode }: { exemptionCode: number }) => exemptionCode === exemptionId);
            let articleStringList = articleTitles;

            if (oneArticleStampType) {
                articleStringList +=
                    `${articleTitles.length === 0 ? '' : ','} ${prefix}${oneArticleStampType.text}${suffix}`;
            }

            return articleStringList;
        }, '');

export const getPageMetadataInfo = createSelector(
    [getCurrentDocument, getAllDisclosureTypesList, layoutReductorAdditionalTypes,
        getClearPrefixAndSuffix, getPagesDisclosureLoading],
    (
        document: IDocument,
        allDisclosureTypes: IDisclosureOptions[],
        articleStampType: IAdditionalArticleStamp[],
        prefixAndSuffix: IExemptionSettings,
        pagesDisclosureLoading: number[],
    ): IPageMetadataInfo[] => {
        return document && document.pages && document.pages.length && document.pages.map((page: IPages) => {
            const getIndexValue = (param: IDefaultIndex): string => !!param && param.value ? param.value : '';

            const fullSetOfDuplicates = {};

            allDisclosureTypes.forEach((item: IDisclosureOptions) => {
                fullSetOfDuplicates[item.id] = item.name;
            });

            return {
                id: page.id,
                page: page.actualPageNumber,
                ocr: page.isOcred ? YES : NO,
                disclosureType:
                    setValue(pagesDisclosureLoading.includes(page.id), fullSetOfDuplicates[page.disclosureTypeId])
                    || fullSetOfDuplicates[document.defaultDisclosureTypeId],
                pagination: `${page.paginationIndex}`.padStart(8, '0'),
                article: transformExemptionIdsToAdditionalArticlesWithPrefixSuffix(
                    page.exemptionIds,
                    articleStampType || [],
                    prefixAndSuffix,
                ),
                rotation: page.rotation || 0,
                to: getIndexValue(page.defaultIndexMetadata.to),
                toOrg: getIndexValue(page.defaultIndexMetadata.toOrg),
                from: getIndexValue(page.defaultIndexMetadata.from),
                fromOrg: getIndexValue(page.defaultIndexMetadata.fromOrg),
                subject: getIndexValue(page.defaultIndexMetadata.subject),
                documentDate: page.documentDate || '',
            };
        });
    },
);

export const getSelectedPage = createSelector(
    [getCurrentPage],
    (page: IPages) => page && page.id,
);

export const getSelectedPagesNames = createSelector(
    [getSelectedPages, getPageMetadataInfo],
    (pagesIds: number[], pageMetadataInfo: IPageMetadataInfo[]): IPageMetadataInfo[] =>
        pagesIds.map((pageId: number): IPageMetadataInfo => find(pageMetadataInfo, { id: pageId })),
);

export const getCurrentDocumentOriginalName = createSelector(
    [getCurrentDocument],
    (currentDocument: IDocument): string => currentDocument ?
        currentDocument.originalName ? currentDocument.originalName : '' : '',
);

export const getPageListColumnHeaderLoading = createSelector(
    [getPageListState],
    (pageListState: PageListState): boolean => pageListState.pageListColumnHeaderPending,
);

export const getPageListColumnHeader = createSelector(
    [getPageListState],
    (pageState: PageListState): IHeader[] => {
        const pageListHeader = pageState.pageListColumnHeader.map((header: IHeader): IHeader => ({
            ...header,
            accessorKey: header.accessor,
            header: header.Header,
        }));
        return pageListHeader;
    }
);
    

export const getResetedPageListColumnHeaderLoading = createSelector(
    [getPageListState],
    (pageListState: PageListState): boolean => pageListState.resetPageListColumnHeaderPending,
);

export const getSelectedDocumentName = createSelector(
    [getState, getSelectedDocuments],
    (state: IState, selectedDocuments: number[]): string => {
        return state.documentList && state.documentList.files && selectedDocuments &&
            !!selectedDocuments.length && state.documentList.files.filter((files: IFile) =>
                files.id === selectedDocuments[0])[0].originalName;
    },
);

export const getHasLinkedArticles = createSelector(
    [getPageListState],
    (pageListState: PageListState): boolean => pageListState.hasLinkedArticles,
);

export const getFirstPageId = createSelector(
    [getPageListState],
    (pageListState: PageListState): number => pageListState.firstPageId,
);

export  const getCurrentPageComments = createSelector(
    [getPageListState],
    (pageListState: PageListState): IComment[] => {
        return pageListState.currentPageComments;
    },
);

export const getCurrentFirstPageId = createSelector(
    [getPageListState],
    (pageListState: PageListState): number => {
        return pageListState.currentFirstPageId;
    },
);
export const getCurrentLastPageId = createSelector(
    [getPageListState],
    (pageListState: PageListState): number => {
        return pageListState.currentLastPageId;
    },
);

import { createSelector } from 'reselect';
import { getSearchState } from './globalSearch';
import { getAnnotationState } from './annotation';
import { IReduxStateSearch } from '../../containers/globalSearch/globalSearch.model';
import { IAnnotationState } from '../../redux/reducers/annotation/annotation.model';

export const getCursorLoading = createSelector(
    [getSearchState, getAnnotationState],
    (search: IReduxStateSearch, annotation: IAnnotationState): boolean => {
        return search.applySearchPending || annotation.snapAnnotationPending;
    },
);

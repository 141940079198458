import { AnyAction } from 'redux';
import { IScannerState } from './scanner.model';
import { SET_SCANNER_SETTINGS } from './constant';
import { host } from '../../../index';

export const initialState: IScannerState = {
    isScannerAvailable: false,
    webTwainConfig: '',
    webTwainInstall: '',
};

const scannerReducer = (state: IScannerState = initialState, { type, payload }: AnyAction): IScannerState => {
    if (type === SET_SCANNER_SETTINGS) {
            return {
                ...state,
                ...payload,
                webTwainConfig: `${host}${payload.webTwainConfig}`,
                webTwainInstall: `${host}${payload.webTwainInstall}`,
            };
    } else {
        return state;
      }
};

export default scannerReducer;

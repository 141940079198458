import {
    GET_AUDIT_ACTIONS_PENDING,
    GET_AUDIT_ACTIONS_SUCCESS,
    GET_AUDIT_ACTIONS_FAILURE,
    GET_AUDIT_LOGS_PENDING,
    GET_AUDIT_LOGS_SUCCESS,
    GET_AUDIT_LOGS_FAILURE, GET_AUDIT_LOGS_CLEAR, MODIFY_AUDIT_LOG_COLUMN_HEADERS, SET_AUDIT_LOG_COLUMN_LOADING_FLAG,
} from './constants';

import { AnyAction } from 'redux';
import { IAuditLog } from './auditLog.model';
import { tableColumns } from '../../../constants/auditLog.contstants';

const initialState: IAuditLog = {
    auditActionsList: [],
    auditActionsPending: false,
    auditLogsList: [],
    auditLogsPending: false,
    error: null,
    auditLogColumnHeaders: tableColumns,
    resetColumnPending: false,
};

const auditLog = (state: IAuditLog = initialState, {type, payload}: AnyAction): IAuditLog => {
    switch (type) {
        case GET_AUDIT_ACTIONS_PENDING:
            return {
                ...state,
                auditActionsPending: true,
                error: null,
            };
        case GET_AUDIT_ACTIONS_SUCCESS:
            return{
                ...state,
                auditActionsList: payload,
                auditActionsPending: false,
            };
        case GET_AUDIT_ACTIONS_FAILURE:
            return{
                ...state,
                error: payload,
                auditActionsPending: false,
            };
        case GET_AUDIT_LOGS_PENDING:
            return {
                ...state,
                auditLogsPending: true,
                error: null,
            };
        case GET_AUDIT_LOGS_SUCCESS:
            return{
                ...state,
                auditLogsList: payload,
                auditLogsPending: false,
            };
        case GET_AUDIT_LOGS_FAILURE:
            return{
                ...state,
                error: payload,
                auditLogsPending: false,
            };
        case GET_AUDIT_LOGS_CLEAR:
            return{
                ...state,
                error: payload,
                auditLogsPending: false,
                auditLogsList: [],
            };
        case MODIFY_AUDIT_LOG_COLUMN_HEADERS:
            return{
                ...state,
                auditLogColumnHeaders: payload,
            };
        case SET_AUDIT_LOG_COLUMN_LOADING_FLAG:
            return{
                ...state,
                resetColumnPending: payload,
            };
        default:
            return state;
    }
};

export default auditLog;

import { createSelector } from 'reselect';
import { IState } from '../store';
import { cloneDeep } from 'lodash';
import {
    HeaderMainMenus,
    headerMenu,
    IMenu, INDEX_NOTES_REPORTS_ACTION,
    OFFICER_REPORTS_ACTION,
    REPORTS_ACTION,
} from '../../constants/headerMenu.constants';
import { REDACTION_ADMIN } from '../../constants/common.constants';
import { permissionValues, ADMIN } from '../../constants/permissions';
import { getRedactionDocumentId, getReductionMetaDataState, getUserPermissionsLoading } from './initialization';
import { getDocument } from './redactor';
import { getReportingOfficerPending, getReportingIndexNotesPending } from './reports';
import { IRedactorDocument } from '../reducers/redactor/redactor.model';
import { IInitializationState } from '../reducers/initialization/initialization.model';
import { getAvailableMenuByPermissions } from '../../utils/permissions.utils';

const { DENY_ACCESS } = permissionValues;

export const getHeaderActiveMenu = (state: IState): string  => state.header.activeMenu;

const changeActionHeader = (headerMenuAction: IMenu[], callback: (submenu: IMenu) => IMenu): IMenu[] =>
    headerMenu.map((menu: IMenu): IMenu => {
        if(menu.id === HeaderMainMenus.ACTION) {
            const subMenus = {
                submenus: menu.submenus.map((submenu: IMenu): IMenu =>
                    submenu.id === REPORTS_ACTION ? callback(submenu) : submenu,
                ),
            };

            return {...menu, ...subMenus};
        } else {
            return menu;
        }
    });

export const getHeaderMenu = createSelector(
    [
        getRedactionDocumentId,
        getDocument,
        getReportingOfficerPending,
        getReportingIndexNotesPending,
        getReductionMetaDataState,
        getUserPermissionsLoading,
    ],
    (
        redactionDocumentId: number,
        document: IRedactorDocument,
        loadingReport: boolean,
        loadingIndexReport: boolean,
        {
            userSecurityRole,
            userIsAdmin,
            userPermissions,
        }: IInitializationState,
        userPermissionsLoading: boolean,
    ): IMenu[] => {

        const disabled = { enable: false };
        let customMenu = cloneDeep(headerMenu);

        if (!redactionDocumentId) {
            customMenu = headerMenu.map((menu: IMenu): IMenu =>
                menu.id === HeaderMainMenus.FILE || menu.id === HeaderMainMenus.ADMIN
                || menu.id === HeaderMainMenus.HELP  ?
                    menu :
                    { ...menu, ...disabled },
            );

            return customMenu.map((menuItem: IMenu): IMenu => {
                if(menuItem.id === ADMIN) {

                    return {
                        ...menuItem,
                        submenus: menuItem.submenus.map((subMenu: IMenu): IMenu => ({
                            ...subMenu,
                            enable: !userPermissionsLoading && userPermissions.includes(subMenu.id),
                        })),
                        enable: !userPermissionsLoading && !!userPermissions.find((permission: string): boolean =>
                            permission.includes(REDACTION_ADMIN)),
                    };
                }

                return menuItem;
            });
        }

        if (loadingReport) {
            customMenu = changeActionHeader(headerMenu, changeReportActionHeader);
        }

        if (loadingIndexReport) {
            customMenu  = changeActionHeader(headerMenu, changeReportIndexNotesActionHeader);
        }

        if (!document.pdfSrc) {
            customMenu = headerMenu.map((menu: IMenu): IMenu =>
                menu.id !== HeaderMainMenus.PAGE?
                    menu :
                    { ...menu, ...disabled },
            );
        }

        const availableMenu = userIsAdmin && userSecurityRole === DENY_ACCESS ?
            customMenu.filter((menuItem: IMenu): boolean => menuItem.id === ADMIN) :
            customMenu;

        const readyPermissionsList = userPermissionsLoading ? [] : userPermissions;

        return getAvailableMenuByPermissions(
            userIsAdmin ? availableMenu : availableMenu.filter((menuItem: IMenu): boolean =>
                menuItem.id !== 'Admin'),
            readyPermissionsList,
        );
    },
);

const changeReportActionHeader = (submenu: IMenu): IMenu => {
    const subSubMenus = {
        submenus: submenu.submenus.map((subSubmenu: IMenu): IMenu =>
            subSubmenu.id !== OFFICER_REPORTS_ACTION ?
                subSubmenu :
                {...subSubmenu, enable: false },
        ),
    };

    return {...submenu, ...subSubMenus};
};

const changeReportIndexNotesActionHeader = (submenu: IMenu): IMenu => {
    const subSubMenus = {
        submenus: submenu.submenus.map((subSubmenu: IMenu): IMenu =>
            subSubmenu.id !== INDEX_NOTES_REPORTS_ACTION ?
                subSubmenu :
                {...subSubmenu, enable: false },
        ),
    };

    return {...submenu, ...subSubMenus};
};

import * as React from 'react';
import { ChangeEvent } from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from '../../../../node_modules/react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './modalSystemMessageEdit.scss';
import {
    CANCEL,
    LOCALIZATION_MIN_CHAR,
    MESSAGE_KEY_PARAM,
    MESSAGE_TEXT2_PARAM,
    MESSAGE_TEXT_PARAM,
    SAVE,
    SYSTEM_MESSAGE_MAX_CHAR,
    SYSTEM_MESSAGE_MAX_CHAR_ERROR,
    SYSTEM_MESSAGE_MIN_CHAR_ERROR,
} from '../../../constants/localization.constants';
import { getSelectedSystemMessage } from '../../../redux/selectors/localization';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { CHANGES_MAY_NOT_BE_SAVED_MESSAGE, CONFIRMATION_DIALOG_MODAL, WARNING } from '../../../constants/messages.constants';
import { OK } from '../../../constants/common.constants';
import { ILabel } from '../../../redux/reducers/localization/localization.model';
import { updateSystemMessages } from '../../../redux/actions/localization';

const INP_ERRORS = {
    messageText: { error: false, errorMessage: ''},
    messageText2: { error: false, errorMessage: ''},
};

const ModalSystemMessageEdit = (props: any): JSX.Element => {
    const selectedMessage = useSelector(getSelectedSystemMessage);
    const [messageText, setMessageText] = useState(selectedMessage && selectedMessage.messageText || '');
    const [messageText2, setMessageText2] = useState(selectedMessage && selectedMessage.messageText2 || '');
    const [inputErrors, setInputErrors] = useState(INP_ERRORS);
    const [openFlag, setOpenFlag] = useState(true);

    const dispatch = useDispatch();
    const closeAllModalWindows = (): void => dispatch(handleCloseAllModalWindows());
    const handleOpenModalWindow = (modalType: string, modalProps: IModalProps): void =>
        dispatch(openModalWindow(modalType, modalProps));
    const closeModalWindow = (id: string): void => dispatch(handleCloseModalWindow(id));
    const editMessage = (modifiedMessage: ILabel): void => dispatch(updateSystemMessages(modifiedMessage));

    const isSaveDisabled = (error: boolean = false): boolean => {
        if(openFlag) {
            props.handleDetectChanges(false);
            setOpenFlag(false);
        }

        const flag = selectedMessage.messageText === messageText
            && selectedMessage.messageText2 === messageText2;
        const errorFlag = (messageText.length > SYSTEM_MESSAGE_MAX_CHAR || messageText.length < LOCALIZATION_MIN_CHAR)
            || (messageText2.length > SYSTEM_MESSAGE_MAX_CHAR || messageText2.length < LOCALIZATION_MIN_CHAR);

        if(error) {
            return flag || errorFlag;
        } else {
            return flag;
        }
    };

    const handleCancel = (): void => {
        if(!isSaveDisabled()) {
            handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: WARNING,
                okButtonTitle: OK,
                cancelButtonTitle: CANCEL,
                message: CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
                confirm: (): () => void => (): void => {
                    closeAllModalWindows();
                    props.handleDetectChanges(false);
                },
                reject: (): () => void => (): void => {
                    closeModalWindow(CONFIRMATION_DIALOG_MODAL);
                    props.handleDetectChanges(false);
                },
            });
        } else {
            closeAllModalWindows();
            props.handleDetectChanges(false);
        }
    };

    const handleError = (param: string, value: string): void => {
        if(value.length > SYSTEM_MESSAGE_MAX_CHAR) {
            setInputErrors({...inputErrors, [param]:{error: true, errorMessage: SYSTEM_MESSAGE_MAX_CHAR_ERROR}});
        } else if(value.length < LOCALIZATION_MIN_CHAR) {
            setInputErrors({...inputErrors, [param]:{error: true, errorMessage: SYSTEM_MESSAGE_MIN_CHAR_ERROR}});
        } else {
            setInputErrors({...inputErrors, [param]:{error: false, errorMessage: ''}});
        }
    };

    const handleSave = (): void => {
        editMessage({messageKey: selectedMessage.messageKey, messageText, messageText2});
        props.handleDetectChanges(false);
    };

    const handleChanges = (param: string, value: string): void => {
        if(param === 'messageText') {
            if( selectedMessage.messageText === value && selectedMessage.messageText2 === messageText2 ) {
                props.handleDetectChanges(false);
            } else {
                props.handleDetectChanges(true);
            }
        } else {
            if( selectedMessage.messageText2 === value && selectedMessage.messageText === messageText ) {
                props.handleDetectChanges(false);
            } else {
                props.handleDetectChanges(true);
            }
        }
    };

    return (
        <div className='system-message-edit'>
            { selectedMessage &&
                <div className='system-message-edit_header'>
                    <div className='system-message-edit_row'>
                        <div className='system-message-edit_item-large'>
                            <div className='message-key-tab'>
                                <div><b> Message Key : </b></div>
                                <div className='message-key-text'>{selectedMessage.messageKey}</div>
                            </div>
                        </div>
                    </div>
                    <div className='system-message-edit_row'>
                        <div className='system-message-edit_item-large'>
                            <TextField
                                value={messageText}
                                label='Message Text'
                                fullWidth={true}
                                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                    setMessageText(e.target.value);
                                    handleError(MESSAGE_TEXT_PARAM, e.target.value);
                                    handleChanges('messageText', e.target.value);
                                }}
                                type='text'
                                variant='outlined'
                                multiline={true}
                                minRows={3}
                                error={inputErrors.messageText.error}
                                helperText={inputErrors.messageText.errorMessage}
                            />
                        </div>
                    </div>
                    <div className='system-message-edit_row'>
                        <div className='system-message-edit_item-large'>
                            <TextField
                                value={messageText2}
                                label='Message Text(French)'
                                fullWidth={true}
                                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                    setMessageText2(e.target.value);
                                    handleError(MESSAGE_TEXT2_PARAM, e.target.value);
                                    handleChanges('messageText2', e.target.value);
                                }}
                                type='text'
                                variant='outlined'
                                multiline={true}
                                minRows={3}
                                error={inputErrors.messageText2.error}
                                helperText={inputErrors.messageText2.errorMessage}
                            />
                        </div>
                    </div>
                </div>
            }
            <div className='system-message-edit_footer'>
                <Button
                    variant='outlined'
                    size='small'
                    className='button_indent-left'
                    onClick={handleCancel}
                >
                    {CANCEL}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    className='primary button_indent-left'
                    disabled={isSaveDisabled(true)}
                    onClick={(): void => {
                        handleSave();
                        closeAllModalWindows();
                    }}
                >
                    {SAVE}
                </Button>
            </div>
        </div >
    );
};

export default ModalSystemMessageEdit;

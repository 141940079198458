import * as React from 'react';
import './settingsTable.styles.scss';
import {
    ISettingsTableProps,
    ISettingsTableState,
} from './settingsTable.model';
import SortableListGroup from './SettingsTableList';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { IHeader } from '../../../../constants/leftSidebar.config';
import { IDuplicateTableHeader } from '../table.model';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Tooltip } from '@mui/material';
import { getSessionItem } from '../../../../utils/localStorage.util';
import {REDACTION_LANG } from '../../../../redux/reducers/localStorage/constant';

export class SettingsTable<D extends object> extends React.Component<ISettingsTableProps<D>, ISettingsTableState> {
    constructor(props: ISettingsTableProps<D>) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }
    public render(): JSX.Element {
        const {anchorEl} = this.state;
        const open = !!anchorEl;
        const redactionLang = getSessionItem(REDACTION_LANG);
        return (
            <div className='duplicate_table'>
                <Tooltip title={redactionLang === 'en' ? "Show/Hide columns" : "Afficher/Masquer les colonnes"}>
                    <IconButton onClick={this.handleOpenMenu}>
                        <ViewColumnIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    id='fade-menu'
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    PopoverClasses={{ paper: 'duplicate_table_list' }}
                >
                    <SortableListGroup
                        columnsGroup={this.props.columnsGroup}
                        resetColumns={this.props.resetColumns}
                        handleClickCheckbox={this.handleClickCheckbox}
                        handleShowAll={this.props.handleShowAll}
                        handleHideAll={this.props.handleHideAll}
                    />
                </Menu>
            </div>
        );
    }
    private handleOpenMenu = (event: React.BaseSyntheticEvent): void => {
        this.setState({ anchorEl: event.currentTarget });
    }

    private handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    private handleClickCheckbox = (idMenu: string): void => {
        const { columnsGroup } = this.props;
        const newColumnsGroup = columnsGroup.map((group: IDuplicateTableHeader) => {
            const list = group.list.map((item: IHeader) => {
                return item.id === idMenu ? {
                    ...item,
                    show: !item.show,
                } : item;
            });
    
            return {
                ...group,
                list,
            };
        });
        this.props.setColumnsGroup(newColumnsGroup);
    }
}
import { action } from 'typesafe-actions';
import { AnyAction, Dispatch } from 'redux';
import api from '../../api/reductionApi';
import { IError } from '../common.model';
import { IAnnotation, ICoordinate, IDisclosureReset } from '../reducers/pageList/pageList.model';
import {
    IDocumentAnnotations,
    IDocumentAnnotationUpdate,
    IPageActionParams,
    IPageAnnotation,
} from '../reducers/annotation/annotation.model';
import {
    GET_CURRENT_PAGE_ANNOTATIONS_PENDING,
    GET_CURRENT_PAGE_ANNOTATIONS_SUCCESS,
    GET_CURRENT_PAGE_ANNOTATIONS_FAILURE,
    ADD_ANNOTATION_FAILURE,
    ADD_ANNOTATION_SUCCESS,
    ADD_ANNOTATION_PENDING,
    DELETE_ANNOTATION_PENDING,
    DELETE_ANNOTATION_SUCCESS,
    DELETE_ANNOTATION_FAILURE,
    UPDATE_ANNOTATION_PENDING,
    UPDATE_ANNOTATION_SUCCESS,
    UPDATE_ANNOTATION_FAILURE,
    REMOVE_ANNOTATIONS_PER_PAGE,
    ADD_ANNOTATIONS_TO_DOCUMENTS_FAILURE,
    ADD_ANNOTATIONS_TO_DOCUMENTS_SUCCESS,
    ADD_ANNOTATIONS_TO_DOCUMENTS_PENDING,
    SET_LINKED_ARTICLES_PENDING,
    SET_LINKED_ARTICLES_FAILURE,
    SET_LINKED_ARTICLES_SUCCESS,
    HIGHLIGHT_LINKED_SEVERS,
    SET_AUTO_CORRECT_SEVER,
    UNDO_SNAP_ANNOTATION_FAILURE,
    UNDO_SNAP_ANNOTATION_PENDING,
    UNDO_SNAP_ANNOTATION_SUCCESS,
    SET_RECENT_AUTOCORRECTED_ANNOTATION_ID,
    SNAP_ANNOTATION_PENDING,
} from '../reducers/annotation/constants';
import { IState } from '../store';
import { intersectionBy } from 'lodash';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from './modal';
import { cleanSelectedShapes, clearLinkedAnnotationIdAndArticleId, setSingleSelectedShape } from './redactor';
import { IAnnotationType, IAnnotationUpdate } from '../reducers/annotationTypes/annotationTypes.model';
import { setDocumentDisclosureType, setPageDisclosureType } from './documentList';
import { UPDATE_DISCLOSURES } from '../reducers/pageList/constant';
import { applySearchPending } from './globalSearch';
import { refreshResultDisclosureForDocument } from './disclosureTypes';
import { START_POINT, END_POINT } from '../../constants/annotationTypes.constants';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { addError } from './errorHandling';
import { deleteStamps, getPageStamps, highlightLinkedArticles } from './stamps';
import { IStamp } from 'redux/reducers/stamps/stamps.model';
import { changePageExemptionSuccess } from './pageList';
import { VIEW_SEVER_INFO } from '../../constants/contextmenu/context.menu.constants';
import { ISearchResponseData } from 'containers/globalSearch/globalSearch.model';
import { CONFIRMATION_DIALOG_MODAL, YES_BUTTON, NO_BUTTON, UNDO_SEVER, UNDO_SEVER_MESSAGE } from '../../constants/messages.constants';

export const getCurrentPageAnnotationsPending = (): AnyAction => action(GET_CURRENT_PAGE_ANNOTATIONS_PENDING);
export const getCurrentPageAnnotationsSuccess = (file: IAnnotation[]): AnyAction => action(
    GET_CURRENT_PAGE_ANNOTATIONS_SUCCESS,
    file,
);
export const getCurrentPageAnnotationsFailure = (error: IError): AnyAction => action(
    GET_CURRENT_PAGE_ANNOTATIONS_FAILURE,
    error,
);
export const addAnnotationPending = (data: IAnnotation[]): AnyAction => action(ADD_ANNOTATION_PENDING, data);
export const addAnnotationSuccess = (data: IAnnotation[], isUpdate: boolean = false): AnyAction =>
    action(ADD_ANNOTATION_SUCCESS, { data, isUpdate });
export const addAnnotationFailure = (error: IError): AnyAction => action(ADD_ANNOTATION_FAILURE, error);
export const deleteAnnotationPending = (): AnyAction => action(DELETE_ANNOTATION_PENDING);
export const deleteAnnotationSuccess = (data: number[]): AnyAction => action(DELETE_ANNOTATION_SUCCESS, data);
export const deleteAnnotationFailure = (error: IError): AnyAction => action(DELETE_ANNOTATION_FAILURE, error);
export const updateAnnotationPending = (): AnyAction => action(UPDATE_ANNOTATION_PENDING);
export const updateAnnotationSuccess = (annotation: IAnnotation[]): AnyAction =>
    action(UPDATE_ANNOTATION_SUCCESS, annotation);
export const updateAnnotationFailure = (error: IError): AnyAction => action(UPDATE_ANNOTATION_FAILURE, error);
export const removeAnnotationsPerPage = ({ severs, highlights }: IDisclosureReset): AnyAction =>
    action(REMOVE_ANNOTATIONS_PER_PAGE, { severs, highlights });
export const addAnnotationsToDocumentPending = (data: IAnnotation[]): AnyAction =>
    action(ADD_ANNOTATIONS_TO_DOCUMENTS_PENDING, data);
export const addAnnotationsToDocumentSuccess = (data: IAnnotation[]): AnyAction =>
    action(ADD_ANNOTATIONS_TO_DOCUMENTS_SUCCESS, { data });
export const addAnnotationsToDocumentFailure = (error: IError): AnyAction =>
    action(ADD_ANNOTATIONS_TO_DOCUMENTS_FAILURE, error);
export const updateDisclosures = (disclosures: IDocumentAnnotationUpdate): AnyAction =>
    action(UPDATE_DISCLOSURES, disclosures);

export const setLinkedArticlesPending = (): AnyAction => action(SET_LINKED_ARTICLES_PENDING);
export const setLinkedArticlesSuccess = (data: IAnnotation[]): AnyAction => action(SET_LINKED_ARTICLES_SUCCESS, data);
export const setLinkedArticlesFailure = (error: IError): AnyAction => action(SET_LINKED_ARTICLES_FAILURE, error);

export const highlightLinkedSever = (linkedArticlesId: number): AnyAction =>
    action(HIGHLIGHT_LINKED_SEVERS, linkedArticlesId);

export const setAutoCorrectSever = (autoCorrectSever: boolean): AnyAction =>
    action(SET_AUTO_CORRECT_SEVER, autoCorrectSever);
export const setRecentUndoSnapAnnotation = (id: number): AnyAction =>
    action(SET_RECENT_AUTOCORRECTED_ANNOTATION_ID, id);
export const undoSnapAnnotationPending = (): AnyAction => action(UNDO_SNAP_ANNOTATION_PENDING);
export const undoSnapAnnotationSuccess = (data: IAnnotation[]): AnyAction => action(UNDO_SNAP_ANNOTATION_SUCCESS, data);
export const undoSnapAnnotationFailure = (error: IError): AnyAction => action(UNDO_SNAP_ANNOTATION_FAILURE, error);

export const snapAnnotationPending = (flag: boolean): AnyAction => action(SNAP_ANNOTATION_PENDING, flag);

// TODO investigate how to remove any from Dispatch type
export const addAnnotation = (
    params: IPageActionParams,
    data: IAnnotation[],
    actionCall?: () => AnyAction,
    isSearch?: boolean,
    isFromPaste: boolean = false,
): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> => async (
    dispatch: Dispatch<any>, getState: () => IState,
    ): Promise<void> => {
        const {
            redactor: {
                scale,
            },
            annotation: {
                autoCorrectSever,
            },
        } = getState();
        const scaledData = data.map((shape: IAnnotation) => {
            return {
                ...shape,
                coordinate: shape.coordinate.map((coordinate: ICoordinate) => {
                    return {
                        [START_POINT]: {
                            x: coordinate[START_POINT].x / scale,
                            y: coordinate[START_POINT].y / scale,
                        },
                        [END_POINT]: {
                            x: coordinate[END_POINT].x / scale,
                            y: coordinate[END_POINT].y / scale,
                        },
                    };
                }),
            };
        });
        const annotationData = isSearch ? scaledData : data;

        dispatch(addAnnotationPending(annotationData));

        if(autoCorrectSever && !isFromPaste) {
            dispatch(snapAnnotationPending(true));
        }

        try {
            const response = await api.annotation.addRedactionAnnotation(
                    { ...params }, annotationData, !isSearch && !isFromPaste && autoCorrectSever);

            dispatch(addAnnotationSuccess(response.annotations));
            dispatch(applySearchPending(false));

            if (actionCall) {
                dispatch(actionCall());
            }

            if (autoCorrectSever && response.annotations[0].isSever && response.annotations[0]['preCoordinate']) {
                dispatch(setRecentUndoSnapAnnotation(response.annotations[0].id));
            }

            dispatch(snapAnnotationPending(false));
            dispatch(setPageDisclosureType(params.pageId, response.pageDisclosureTypeId));
            dispatch(setDocumentDisclosureType(params.documentId, response.documentDisclosureType));

        } catch (error) {
            dispatch(addAnnotationFailure(error));
            dispatch(snapAnnotationPending(false));
        }
};

export const removeAnnotation = (
    {
        redactionDocumentId,
        documentId,
        pageId,
        layerId,
    }: IPageActionParams,
    data: number[],
    isDeleteStamps?: boolean,
    selectedAnnotationShapeslinkedArticlesId?: number[],
): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) =>
        Promise<void> => async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState):
        Promise<void> => {
        const {
            pageList: {
                selectedDocuments: [selectedDocuments],
                selectedPages: [selectedPages],
                currentPage,
            },
            redactor: {
                selectedSingleShape,
            },
        } = getState();
        const stampData = {
            redactionDocumentId,
            documentId: selectedDocuments,
            pageId: selectedPages,
        };

        dispatch(deleteAnnotationPending());
        try {

            if (isDeleteStamps) {

                if (selectedSingleShape
                    && selectedSingleShape.linkedArticles && !!selectedSingleShape.linkedArticles.length) {
                    await dispatch(deleteStamps(stampData, selectedSingleShape.linkedArticles));
                } else if (!!selectedAnnotationShapeslinkedArticlesId.length) {
                    await dispatch(deleteStamps(stampData, selectedAnnotationShapeslinkedArticlesId));
                }

            }

            await api.annotation.deleteRedactionAnnotation(
                redactionDocumentId,
                documentId,
                pageId,
                layerId,
                data,
                isDeleteStamps,
            );
            dispatch(deleteAnnotationSuccess(data));
            dispatch(refreshResultDisclosureForDocument(documentId));
            dispatch(getAllAnnotationsPerPage(
                redactionDocumentId,
                documentId,
                pageId,
                currentPage.layers[0].id,
            ));

            if (isDeleteStamps) {
                dispatch(getPageStamps(stampData));
            }

        } catch (error) {
            dispatch(deleteAnnotationFailure(error));
        }
};

export const updateAnnotation = (
    {
        redactionDocumentId,
        documentId,
        pageId,
        layerId,
    }: IPageActionParams,
    data: IAnnotation[],
    fromTransformer: boolean = false,
    fromChangeAnnotationsTypeTo: boolean = false,
    isSaveAndClose?: boolean): (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            annotation: {
                autoCorrectSever,
                annotations,
                recentSnappedAnnotationId,
            },
        } = getState();
        let snapAnnotation = false;

        dispatch(updateAnnotationPending());

        if(autoCorrectSever) {
            dispatch(snapAnnotationPending(true));
        }

        if(fromTransformer) {
            const snappedAnnotation = annotations &&
                annotations.filter((annotation: IAnnotation) =>annotation.id === data[0].id );

            snapAnnotation = snappedAnnotation && snappedAnnotation.length && snappedAnnotation[0].isSever;

        } else if(fromChangeAnnotationsTypeTo) {
            snapAnnotation = fromChangeAnnotationsTypeTo;
        }

        try {
            const annotation = await api.annotation.updateRedactionAnnotation(
                redactionDocumentId,
                documentId,
                pageId,
                layerId,
                data,
                snapAnnotation && autoCorrectSever ? autoCorrectSever : false,
            );

            if (autoCorrectSever && annotation[0]['preCoordinate'] && fromTransformer) {
                dispatch(cleanSelectedShapes());
                dispatch(deleteAnnotationSuccess([annotation[0].id]));
                dispatch(addAnnotationSuccess(annotation, true));
                dispatch(setRecentUndoSnapAnnotation(annotation[0].id));
                dispatch(highlightLinkedArticles([]));
            } else {
                dispatch(updateAnnotationSuccess(annotation));

            }

            if (isSaveAndClose) {
                dispatch(handleCloseModalWindow(VIEW_SEVER_INFO));
            }

            if(autoCorrectSever) {
                dispatch(snapAnnotationPending(false));
            }

        } catch (error) {
            dispatch(updateAnnotationFailure(error));
            dispatch(applySearchPending(false));
            dispatch(snapAnnotationPending(false));
        }
};

export const getAllAnnotationsPerPage = (
    redactionDocumentId: number,
    documentId: number,
    pageId: number,
    layerId: number,
): (dispatch: Dispatch) => Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(getCurrentPageAnnotationsPending());
    try {
        const response = await api.annotation.getAllAnnotationsPerPage(
            redactionDocumentId,
            documentId,
            pageId,
            layerId,
        );

        dispatch(getCurrentPageAnnotationsSuccess(response));
    } catch (error) {
        dispatch(getCurrentPageAnnotationsFailure(error));
    }
};

export const changeAnnotationTypeTo = (
    id: number,
    isSeverLinked?: boolean,
    isChangeToSever?: boolean,
): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> => async (
    dispatch: Dispatch<any>,
    getState: () => IState,
    ): Promise<void> => {
        const {
            redactor: {
                selectedAnnotationShapes,
                selectedSingleShape,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
            annotation: {
                annotations,
            },
            annotationTypes: {
                annotationType,
            },
            pageList: {
                currentDocument: { id: documentId },
                currentPage: {
                    id: pageId,
                    layers: [{ id: layerId }],
                },
            },
        } = getState();
        const pageParams = {
            redactionDocumentId,
            documentId,
            pageId,
            layerId,
        };
        const annotationArray = selectedAnnotationShapes.length
            ? selectedAnnotationShapes
            : [selectedSingleShape];
        const selectedAnnotations = intersectionBy(annotations, annotationArray, 'id');
        const selectedType = annotationType.find((item: IAnnotationType) => item.id === id);
        const updatedAnnotations = selectedAnnotations.map((item: IAnnotation) => {
            return {
                ...item,
                annotationTypeId: selectedType.id,
                color: selectedType.color,
                isSever: selectedType.isSever,
                name: selectedType.name,
                ...(isSeverLinked && { linkedArticles: [] }),
            };
        });

        dispatch(handleCloseAllModalWindows());
        await dispatch(updateAnnotation(pageParams, updatedAnnotations, false, isChangeToSever));
        await dispatch(cleanSelectedShapes());
        dispatch(highlightLinkedArticles([]));
};

export const addAnnotationsToDocument = (
    params: IPageActionParams,
    data: IDocumentAnnotations[],
    isLinkedArticles?: boolean,
    isAddAnnotationFromSearch: boolean = false,
): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> => async (
    dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                userId,
            },
            globalSearch: {
                searchData,
            },
            annotation: {
                autoCorrectSever,
            },
        } = getState();
        const { pageId, documentId, redactionDocumentId } = params;
        const currentDocument = data.find((document: IDocumentAnnotations): boolean =>
            document.documentId === documentId,
        );
        const currentPageAnnotations = currentDocument && currentDocument.pageAnnotations &&
            currentDocument.pageAnnotations.length && currentDocument.pageAnnotations.find(
                (page: IPageAnnotation): boolean => page.pageId === pageId);

        dispatch(addAnnotationsToDocumentPending(currentPageAnnotations ? currentPageAnnotations.annotationDto : []));

        if(autoCorrectSever) {
            dispatch(snapAnnotationPending(true));
        }

        try {
            const response = await api.annotation.addRedactionAnnotationsToDocuments(
                redactionDocumentId,
                data,
                userId,
                !isAddAnnotationFromSearch ? autoCorrectSever : false);
            const documentExpandedAnnotations = response.find((document: IDocumentAnnotationUpdate): boolean =>
                document.documentId === documentId);

            const currentPageExpandedAnnotations = documentExpandedAnnotations &&
                documentExpandedAnnotations.expandedAnnotations.find((expanded: IAnnotationUpdate): boolean =>
                    expanded.pageId === pageId);

            if (response && response.length) {

                const articleStamp = [];

                if (isLinkedArticles) {
                    if (data[0].pageAnnotations[0].annotationDto[0]['stampDtoList'].length) {
                        data[0].pageAnnotations[0].annotationDto[0]['stampDtoList'].map((stamp: IStamp) => {
                            articleStamp.push(stamp.stampTypeId);
                        });
                    }
                }

                response.map((document: IDocumentAnnotationUpdate) => {
                    if (document.documentId === (currentDocument && currentDocument.documentId)) {
                        document.expandedAnnotations.map((page: IAnnotationUpdate) => {
                            dispatch(setPageDisclosureType(page.pageId, page.pageDisclosureTypeId));
                            dispatch(setDocumentDisclosureType(document.documentId, page.documentDisclosureType));

                            if (isLinkedArticles) {
                                dispatch(changePageExemptionSuccess(page.pageId, articleStamp));
                            }
                        });
                    } else {
                        searchData.map((searchResponse: ISearchResponseData) => {
                            if (document.documentId === searchResponse.documentId) {
                                document.expandedAnnotations.map((page: IAnnotationUpdate) => {
                                    dispatch(setPageDisclosureType(page.pageId, page.pageDisclosureTypeId));
                                    dispatch(setDocumentDisclosureType(
                                        document.documentId,
                                        page.documentDisclosureType,
                                    ));
                                });
                            }
                        });
                    }
                });
            }

            dispatch(applySearchPending(false));
            dispatch(addAnnotationsToDocumentSuccess(currentPageExpandedAnnotations ?
                [...currentPageExpandedAnnotations.annotations] : []));

            if (isLinkedArticles && params.documentId && params.pageId) {
                dispatch(getPageStamps(params, currentPageExpandedAnnotations ?
                    [...currentPageExpandedAnnotations.annotations] : [], isAddAnnotationFromSearch));
            }

        } catch (error) {
            dispatch(applySearchPending(false));
            dispatch(snapAnnotationPending(false));
            dispatch(addAnnotationsToDocumentFailure(error));
        }
};

export const updateLinkedArticles = ():
    (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> => async (
        dispatch: Dispatch<any>,
        getState: () => IState,
    ): Promise<void> => {
        const {
            annotation: {
                annotations,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument: { id: documentId },
                currentPage: {
                    id: pageId,
                    layers: [{ id: layerId }],
                },
            },
            redactor: {
                linkedAnnotaionIdAndStampsId: {
                    annotaionId,
                    articlesId,
                },
            },
        } = getState();
        const annotationLinkedIds = annotations.filter((annotation: IAnnotation): IAnnotation => {
            return annotation.id === annotaionId && annotation;
        });
        const linkedArticles = annotationLinkedIds && !!annotationLinkedIds.length ?
            annotationLinkedIds[0].linkedArticles : [];

        dispatch(setLinkedArticlesPending());
        try {
            const response = await api.annotation.updateLinkedArticles(
                redactionDocumentId,
                documentId,
                pageId,
                layerId,
                annotaionId,
                [...(linkedArticles ? linkedArticles : []), ...articlesId],
            );

            dispatch(setLinkedArticlesSuccess(response));
            dispatch(clearLinkedAnnotationIdAndArticleId());
        } catch (error) {
            dispatch(addError(error));
            dispatch(clearLinkedAnnotationIdAndArticleId());
        }
};

export const undoShapes = ():
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> => async (
        dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        const {
            annotation: {
                recentSnappedAnnotationId,
            },
            redactor: {
                selectedSingleShape,
            },
        } = getState();

        if (selectedSingleShape && selectedSingleShape.isSever && selectedSingleShape['preCoordinate'] &&
            selectedSingleShape['preCoordinate'].length) {

            dispatch(undoSnapAnnotation(selectedSingleShape.id));

        }
};

const undoSnapAnnotation = (id: number):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> => async (
        dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument: { id: documentId },
                currentPage: {
                    id: pageId,
                    layers: [{ id: layerId }],
                },
            },
            redactor: {
                selectedSingleShape,
                scale,
            },
        } = getState();

        dispatch(undoSnapAnnotationPending());
        try {
            const response = await api.annotation.undoSnapTextAnnotation(
                redactionDocumentId,
                documentId,
                pageId,
                layerId,
                id,
            );

            dispatch(undoSnapAnnotationSuccess(response));
            dispatch(setSingleSelectedShape({
                ...response[0],
                stroke: selectedSingleShape && selectedSingleShape.stroke ? selectedSingleShape.stroke : '',
                strokeWidth: selectedSingleShape && selectedSingleShape.strokeWidth ?
                    selectedSingleShape.strokeWidth : 0,
         }, scale));
        } catch (error) {
            dispatch(addError(error));
            dispatch(snapAnnotationPending(false));
        }
};

import * as React from 'react';
import DynamicForm from './DynamicForm';
import { IDynamicFormWrapper, IDynamicFormWrapperState } from './modalLayerTypes.model';

class DynamicFormContainer extends React.Component<IDynamicFormWrapper, IDynamicFormWrapperState> {
    public state: IDynamicFormWrapperState = {
        activeField: '',
    };

    public render(): JSX.Element {
        const {
            layerItems,
            formFields,
            onSubmit,
            onDelete,
            defaultData,
            uniqueField,
            handleDetectChanges,
            onCloseModal,
            isPristine,
            permissions,
            redactionLang,
            modifiedLabels,            
        } = this.props;

        return (
            <DynamicForm
                layerItems={layerItems}
                formFields={formFields}
                isPristine={isPristine}
                onSubmit={onSubmit}
                onDelete={onDelete}
                handleDetectChanges={handleDetectChanges}
                defaultData={defaultData}
                uniqueField={uniqueField}
                activeField={this.state.activeField}
                setActiveField={this.setActiveField}
                onCloseModal={onCloseModal}
                permissions={permissions}
                redactionLang={redactionLang}
                modifiedLabels={modifiedLabels}
            />
        );
    }

    private setActiveField = (activeField: string): void => {
        this.setState({ activeField });
    }
}

export default DynamicFormContainer;

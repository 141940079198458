import * as React from 'react';
import classnames from 'classnames';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SketchPicker } from 'react-color';
import { DEFAULT_ITEM_COLOR } from '../../constants/annotationTypes.constants';
import {
    IFromWrapperProps,
    IFromWrapperState,
    IColor,
    IMaterialUIField,
    ISelectOptions,
} from './marerialUiForms.model';
import './materialIUiForms.styles.scss';

export const emptySelectOption: ISelectOptions[] = [
    {
        value: '',
        label: '',
    },
];

export const renderTextField = ({ input, label, meta: { touched, error }, ...custom }:
                                    IMaterialUIField): React.ReactNode => (
    <div className='text-field'>
        <TextField
            error={touched && !!error}
            label={label}
            {...input}
            {...custom}
            helperText={touched && error}
        />
    </div>
);

export const renderCheckbox = ({ input: { checked, onChange }, label, meta: { touched }, ...custom }:
                                   IMaterialUIField): React.ReactNode => (
    <FormControlLabel
        control={
            <Checkbox
                color='primary'
                checked={Boolean(checked)}
                onChange={onChange}
            />}
        label={label}
        {...custom}
    />
);

export const renderSelectField = ({ input, label, meta: { touched, error }, options, ...custom }:
                                      IMaterialUIField): React.ReactNode => (
    <TextField
        select={true}
        label={label}
        {...input}
        {...custom}
    >
        {
            options.map((option: ISelectOptions): JSX.Element => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))
        }
    </TextField>
);

export class RenderColorPickerInput extends React.Component<IFromWrapperProps, IFromWrapperState> {
    constructor(props: IFromWrapperProps) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: this.props.color || DEFAULT_ITEM_COLOR,
            isDirty: false,
        };
    }

    public componentDidUpdate(prevProps: IFromWrapperProps, prevState: IFromWrapperState): void {
        if (prevProps.openColorPicker !== this.props.openColorPicker) {
            this.setState({ displayColorPicker: this.props.openColorPicker });
        }

        if (this.props.color && prevProps.color !== this.props.color) {
            this.setState({ color: this.props.color });
        }
    }

    public render(): JSX.Element {
        const { isDisabled, error } = this.props;
        const { displayColorPicker } = this.state;

        const pickerClass = classnames('swatch', {
            'swatch-error': error,
            'swatch-disabled': isDisabled,
        });

        return (
            <div className='color-picker'>
                <div className='color-picker__wrapper'>
                    <div className={pickerClass} onClick={this.handleClick}>
                        <div
                            className='color'
                            style={{ background: `${this.state.color}` }}
                        />
                    </div>
                    <div className='error-message'>{this.state.isDirty && this.props.error}</div>
                </div>
                {
                    displayColorPicker ? (

                        <div className='wrapper'>
                            <div className='popover'>
                                <div className='cover' onClick={this.handleClose} />
                                <SketchPicker
                                    onChange={(pickedColor: IColor): void => {
                                        this.props.handleChange(pickedColor.hex, displayColorPicker);
                                        this.handleChangeColor(pickedColor.hex);
                                    }}
                                    color={this.state.color}
                                />
                            </div>
                        </div>
                    ) : null
                }
            </div>
        );
    }

    private handleClick = (): void => {
        const { isDisabled, handleClick } = this.props;

        if (!isDisabled) {
            this.setState({ displayColorPicker: !this.state.displayColorPicker }, () => {
                if (handleClick) {
                    handleClick(this.state.displayColorPicker, this.state.color);
                }
            });
        }
    }

    private handleClose = (): void => this.setState({ displayColorPicker: false });

    private handleChangeColor = (color: string): void => {
        this.setState({ color, isDirty: true });
    }
}

// accept props from material UI, redux-form and custom handleChange in the same time that's why any
export const renderColorPicker = ({ input, meta: { touched, error }, disabled }: any): JSX.Element => (
    <RenderColorPickerInput
        color={input.value}
        handleChange={(color: string): void => input.onChange(color)}
        error={error}
        isDisabled={disabled}
    />
);

import * as React from 'react';
import { Rectangle } from './Rectangle';
import { LabelComponent } from './Label';
import { IShapesGroupProps } from './shapesLayer.model';
import { END_POINT, START_POINT } from '../../constants/annotationTypes.constants';
import { GROUP_ID } from '../../constants/common.constants';
import { degrees } from '../../constants';

/* tslint:disable:no-string-literal */
export const ShapesGroup = <T extends object>(
    {
        moveLayerToTop,
        selectedShape,
        group,
        container,
        handleDraggable,
        isDraggable,
        pageRotation,
    }: IShapesGroupProps<T>,
): JSX.Element => {
    if(moveLayerToTop && group.length && selectedShape) {
        const selectedObject = group.find((item: T) => item[GROUP_ID] === selectedShape[GROUP_ID]);
        const index = group.indexOf(selectedObject);

        group.splice(index, 1);
        group.push(selectedObject);
    }

    return (
        <React.Fragment>
            {
                group.map((rect: T): JSX.Element => {
                    const props: any = {
                        ...(rect as object),
                        key: rect['name'],
                        value: rect['annotationTypeId'],
                        x1: rect['coordinate'][START_POINT].x,
                        y1: rect['coordinate'][START_POINT].y,
                        x2: rect['coordinate'][END_POINT].x,
                        y2: rect['coordinate'][END_POINT].y,
                        dragHandler: handleDraggable,
                        container,
                        isDraggable,
                        ... (rect['fontSize'] && { font: rect['fontSize'] }),
                    };

                    if(rect['rotate']) {
                        props.rotate = rect['rotate'];

                        if (props.rotate === degrees.MINUS_QUARTER) {
                            props.rotate = -degrees.MINUS_QUARTER;
                        }

                        if (props.rotate === degrees.THREE_QUARTERS) {
                            props.rotate = degrees.MINUS_QUARTER;
                        }
                    }

                    return <React.Fragment key={rect['name']}>
                        {rect['annotationTypeId']
                            ? <Rectangle {...props} isBorderOnly={!!rect['isBorderOnly']} pageRotation={pageRotation} />
                            : <LabelComponent {...props} pageRotation={pageRotation} />
                        }
                    </React.Fragment>;
                })
            }
        </React.Fragment>
    );
};
/* tslint:enable:no-string-literal */

import * as React from 'react';
import PeopleSearch from './PeopleSearch';
import './searchTab.styles.scss';
import { IProps } from './searchTab.model';
import ResizibleSortableTable from '../../../../components/table/Table';
import {
    SearchResultHeaders,
} from '../../../../constants/selectContacts.contstants';
import {
    IDisclosureContacts,
    IPeopleRecords,
    IPeopleSearchRecordsTable,
} from '../../../../redux/reducers/disclosureContacts/disclosureContacts.model';
import Button from '@mui/material/Button';
import { changeLang } from '../../../../redux/actions/localization';
import { langFrench } from '../../../../constants/localization.constants';
import { IStateSearchTab } from '../modalSelectContacts.model';
import { PageData } from 'redux/reducers/common/common.model';

export class SearchTab extends React.Component<IProps, IStateSearchTab> {

    constructor(props: IProps) {
        super(props);
        this.state = {
           sortParams : null
        };
    }

    private handleSortTable = (param: string, val: string, pageName: string): void => {
        const pageData: PageData = {
            pageName: pageName,
            columnName: param,
            sortOrder: val,
        };
       this.setState({
        sortParams : pageData
       })
    }
    public render(): JSX.Element {
        const {
            searchPeopleRecords,
            searchPeopleRecordsLoading,
            peopleTypeByDisclosure,
            handleAssignContact,
            labels,
            language,
        } = this.props;
        const langRule = changeLang(language);
        const peopleTypes = peopleTypeByDisclosure
            .reduce((obj: { [key: string]: string }, item: IDisclosureContacts) => {
                obj[item.peopleCode] = language ? language === langFrench ? item.peopleDesc2 ? item.peopleDesc2
                    : item.peopleDesc : item.peopleDesc : item.peopleDesc;

                return obj;
            }, {});

        const searchPeople = searchPeopleRecordsLoading ?
            [] :
            searchPeopleRecords.map((item: IPeopleRecords): IPeopleSearchRecordsTable => {
                return {
                    ...item,
                    peopleCode: peopleTypes[item.peopleCode],
                    action: (
                            <Button
                                color='primary'
                                onClick={(): void => handleAssignContact(item)}
                                size='small'
                            >
                                {labels.assignLabel[langRule]}
                            </Button>
                    ),
                };
            });

        return (
            <div className='search-tab'>
                <PeopleSearch
                    peopleTypeByDisclosure={peopleTypeByDisclosure}
                    handleSave={this.props.onSearch}
                    labels={labels}
                    language={language}
                />
                <h3 className='text-search'>
                    {labels.searchResultsTitleLabel[langRule]}
                </h3>
                <ResizibleSortableTable
                    data={searchPeople}
                    sortParams={this.state.sortParams ? this.state.sortParams : null}
                    pageName={'SelectContacts'}
                    handleSortSession={this.handleSortTable}
                    userPreferenceTable={true}
                    columns={SearchResultHeaders(labels, langRule)}
                />
            </div>
        );
    }

}

export default SearchTab;
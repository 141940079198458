import * as React from 'react';
import { useEffect, useState } from 'react';
import { RadioMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { FormLabel } from '@mui/material';
import './modalReportLangSelection.scss';
import { ENGLISH_PARAM, getReportLanguageModalLabels, reportLangSelection, REPORT_LANG_PARAM, REPORT_LANG_SELECTION_TITLE, SELECT_LANG_TITLE } from '../../../constants/modalReportLangSelection.constants';
import { setItem } from '../../../utils/localStorage.util';

export const ModalReportLangSelection = (): JSX.Element => {
    const [reportLang, setReportLang] = useState(ENGLISH_PARAM);

    useEffect(() => {
        setItem("reportLang", reportLang);
    },[]);
    
    const handleSelectedValue = (param: string, value: string): void => {
        setReportLang(value);
        setItem("reportLang", value);
    };
    return (
        <div className='modal-report-lang'>
            <div className='radio-wrapper'>
                <FormLabel>{getReportLanguageModalLabels(SELECT_LANG_TITLE)}:</FormLabel>
                <RadioMaterialBuilder
                    value={reportLang}
                    handleRadioChange={handleSelectedValue}
                    paramName={REPORT_LANG_PARAM}
                    ariaLabel={REPORT_LANG_SELECTION_TITLE}
                    radioList={reportLangSelection}
                    disabled={false}
                />
            </div>
        </div>
    );
};


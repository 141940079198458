export const SET = 'SET';
export const DELETE = 'DELETE';
export const ADD = 'ADD';
export const EDIT = 'EDIT';
export const OK = 'Ok';
export const EXPORT = 'Export';
export const SAVE = 'Save';
export const SAVE_AND_CLOSE = 'Save & Close';
export const EDIT_LABEL = 'Edit';
export const CONFIRM = 'Confirm';
export const CLOSE = 'Close';
export const CANCEL = 'Cancel';
export const ASSIGN = 'Assign';
export const COMPARE = 'Compare';
export const ADD_ID = 0;
export const PX = 'px';
export const DEFAULT_CURSOR = 'default';
export const PREV_PAGE = 'Previous Page';
export const NEXT_PAGE = 'Next Page';
export const SETTINGS = 'Settings';
export const CROSSHAIR_CURSOR = 'crosshair';
export const POINTER_CURSOR = 'pointer';
export const STAMP = 'stamp';
export const TIMESTAMP = 'Timestamp';
export const ANNOTATION = 'annotation';
export const FILE_2GB_SIZE = 2147483648;
export const regexFiles = new RegExp(
    '(.*?)\.(pdf|jpeg|jpg|tiff|tif|bmp|gif|csv|ppt|pptx|xls|xlsx|txt|docx|doc|vsdx|vsd|msg|png|rtf)$',
);
export const regexImages = new RegExp(
    '(.*?)\.(jpeg|jpg|png)$',
);
export const regexPdfFile = new RegExp(
    '(.*?)\.([pP][dD][fF])$',
);
export const regexExtensions = new RegExp(
    '^\.(pdf|jpeg|jpg|tiff|tif|bmp|gif|csv|ppt|pptx|xls|xlsx|txt|docx|doc|vsdx|vsd|msg|png|rtf)$',
);
export const TRANSPARENT = 'transparent';
export const ID = 'id';
export const PAGE = 'page';
export const PAGES = 'pages';
export const NAME = 'name';
export const TEXT = 'text';
export const WATERMARK_TEXT_LABEL = 'Watermark Text';
export const IMAGE = 'image';
export const IMAGE_PATH = 'imagePath';
export const NOT_APPLICABLE = 'N/A';
export const YES = 'Yes';
export const NO = 'No';
export const APPLY = 'Apply';
export const ENTER_BUTTON_KEYCODE = 13;
export const ACTIVE_PAGE_PATH = '.sidebar-bottom-wr div.draggable.active';

export const FOLDER_RSN = 'folderRsn';
export const FOLDER_RSN_PARAM = 'FolderRSN';
export const SSO_LID = 'AmandaSsoLid';
export const SSO_PARAM = 'AmandaSsoParm';
export const LANG_PARAM = 'lang';
export const MODE_PARAM = 'mode';

export const NO_OFFICER_SELECTED = 'No officer selected';

export const KEYWORD_INDEX = 'Keyword Index';

export const MAX_NOTE_VALUE_LENGTH = 1000;

export const REDACTION_ADMIN = 'REDACTION_ADMIN';

export const permissionsProperty = [
    ADD,
    EDIT,
    DELETE,
];

export const DISCLOSURE_ID = 'disclosure';
export const STACK_ID = 'stack';
export const PAGINATION_ID = 'pagination';
export const DOCUMENTS_DETAILS_ID = 'documentDetails';
export const UNDO_DISCLOSURE_ID = 'undoDisclosure';
export const REPLACE_DISCLOSURE_ID = 'replaceDisclosure';
export const SET_DISCLOSURE_ID = 'setDisclosure';
export const DELETE_PAGE_ID = 'deletePage';
export const EXPORT_ID = 'export';
export const DUPLICATE_REFERENCE_ID = 'duplicateReference';
export const DELETE_DOCUMENT_ID = 'documentDelete';
export const EDIT_DOCUMENT_ORIGINAL_NAME = 'editDocumentOriginalName';
export const SET_PAGINATION_ID = 'setPagination';
export const EDIT_PAGINATION_ID = 'editPagination';
export const UNDO_PAGINATION_ID = 'undoPagination';
export const ADD_REMOVE_FROM_STACK_ID = 'addRemoveFromStack';
export const STACK_MANAGEMENT_ID = 'stackManagement';
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_DATE_FORMAT_FULL = 'YYYY-MM-DD HH:mm:ss';
export const DATAPICKER_DATE_FORMAT = 'MM/DD/YYYY';

export const NOTES_QUERY_PARAM = 'notes';
export const LOADING_MESSAGE = 'Loading...';
export const COMMENTS_QUERY_PARAM = 'comments';
export const RECOMMENDATIONS_QUERY_PARAM = 'recommendations';
export const EXACT_MATCH = 'Exact Match';
export const EXACT_MATCH_PARAM = 'exactMatch';
export const NO_ROWS_FOUND = 'No rows found';

export const STACK_MANDATORY_ALERT = 'Assign to Stack is Empty. '

export const DESC = 'desc';
export const ASC = 'asc';

export const GROUP_ID = 'id';

export const SORT_KEYS = {
    page: 'actualPageNumber',
    pagination: 'paginationIndex',
    ocr: 'isOcred',
    disclosureType: 'disclosure',
    subject: 'subject',
    to: 'to',
    toOrg: 'toOrg',
    from: 'from',
    fromOrg: 'fromOrg',
    article: 'article',
    documentDate: 'documentDate',
};

export const DOCUMENT_NAME = 'Document Name';
export const DOCUMENT_DESCRIPTION = 'Description';
export const SIZE = 'Size';
export const PAGES = 'Pages';
export const CONVERSION_OPTIONS = 'Conversion Options';
export const ENABLE_OCR = 'Apply OCR';
export const AUTO_DESKEW = 'Auto Deskew';
export const REDACTION_OPTIONS = 'Redaction Options';
export const ASSIGN_TO_DISCLOSURE = 'Assign to Disclosure';
export const ASSIGN_TO_STACK = 'Assign to Stack';
export const ADD_FILES_AS_ATTACHMENT = 'Add Files as Attachments';
export const INCLUDE_ANNOTATIONS = 'Include annotations';
export const INCLUDE_DISCLOSURES = 'Include disclosures';
export const IMPORT = 'Import';

export const INCLUDE_ANNOTATIONS_PARAM = 'includeAnnotations';
export const STACK_ID = 'stackId';
export const INCLUDE_DISCLOSURES_PARAM = 'includeDisclosures';
export const ASSIGN_TO_DISCLOSURE_ID = 'assignToDisclosureTypeId';
export const OCR_PARAM = 'ocrRequired';
export const DESKEW_PARAM = 'autoDeskew';
export const FROM_SCANNER = 'fromScanner';

import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { isEqual } from 'lodash';
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form';
import { FormApi, FormState } from 'final-form';
import { Scrollbars } from 'rc-scrollbars';
import { Button, LinearProgress, List, ListItem, ListItemText, TextField } from '@mui/material';
import { VIEW_SEVER_INFO } from '../../../constants/contextmenu/context.menu.constants';
import { SEVER_TEXT, RECOMMENDATIONS, STAMP_ASSOCIATIONS } from '../../../constants/modal.constants';
import { CLOSE, SAVE, SAVE_AND_CLOSE } from '../../../constants/common.constants';
import { MAX_LENGTH } from '../../../constants/validate.constants';
import {
        CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
        CONFIRMATION_DIALOG_MODAL,
        NO_LINKED_STAMPS_MESSAGE, WARNING,
    } from '../../../constants/messages.constants';
import { validate } from '../../../utils/validate.util';
import { IState as StoreState } from '../../../redux/store';
import {
    ISeverInfoDispatchProps,
    ISeverInfoProps,
    ISeverInfoState,
    ISeverInfoStateProps } from './modalSeverInfo.model';
import { IAnnotation } from '../../../redux/reducers/pageList/pageList.model';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { IPageActionParams } from '../../../redux/reducers/annotation/annotation.model';
import { IStamp } from '../../../redux/reducers/stamps/stamps.model';
import { getSingleSelectedShape } from '../../../redux/selectors/redactor';
import { getAnnotationLayouts, getLoadingAnnotation } from '../../../redux/selectors/annotation';
import { handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import { updateAnnotation } from '../../../redux/actions/annotations';
import { getPageInformation } from '../../../redux/selectors/pageList';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import { getCurrentPageStamps } from '../../../redux/selectors/stamps';
import './modalSeveInfo.styles.scss';
import { IMaterialUIField } from 'components/materialUiForms/marerialUiForms.model';

const validateFields = [
    {
        field: 'severedText',
        [MAX_LENGTH]: 8000,
    },
    {
        field: 'recommendText',
        [MAX_LENGTH]: 4000,
    },
];

class ModalSeverInfo extends React.Component<ISeverInfoProps, ISeverInfoState> {

    public state: ISeverInfoState = {
        selectedAnnotation: null,
        isSaveAndClose: false,
    };

    public componentDidMount(): void {
        const { pageAnnotations, singleSelectedShape } = this.props;

        pageAnnotations.map((annotation: IAnnotation) => {
            if(annotation.id === singleSelectedShape.id) {
                this.setState({
                    selectedAnnotation: singleSelectedShape,
                });
            }
        });
    }

    public componentWillUnmount(): void {
        this.props.handleDetectChanges(false);
    }

    public renderTextField = ({ input, label, meta: { touched, error }, ...custom }:
        IMaterialUIField): React.ReactNode => (
        <div className='text-field'>
            <TextField
                error={touched && !!error}
                label={label}
                {...input}
                {...custom}
                helperText={touched && error}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        height: '175px',
                    },
                }}
            />
        </div>
    );

    public render(): JSX.Element {

        const { selectedAnnotation } = this.state;
        const {
            handleDetectChanges,
            annotationPending,
        } = this.props;

        return(
            <Form
                onSubmit={this.handleSave}
                initialValues={selectedAnnotation}
                validate={(values: any): { [param: string]: boolean } => validate(values, validateFields)}
                render={<T extends object>({
                                               handleSubmit,
                                               pristine,
                                               submitting,
                                               invalid,
                                               form,
                                           }: FormRenderProps & FormState<T>): JSX.Element => (
                    <form
                        className='stack-modal'
                        onSubmit={handleSubmit}
                    >
                        <div className='stack-modal-body' style={{height:'475px', width:'900px'}}>
                            <div className='stack-list-wrap'>
                                <div className='stack-header'>
                                    <label>{STAMP_ASSOCIATIONS}</label>
                                </div>
                                <Scrollbars>
                                    <List
                                        component='nav'
                                    >
                                        {
                                            this.getLinkedStampsName().length ?
                                                this.getLinkedStampsName().map((stamp: string, index: number) => (
                                                    <ListItem
                                                        key={index}
                                                        button={false}
                                                    >
                                                        <ListItemText
                                                            title={stamp}
                                                            className='text-stack'
                                                            primary={stamp}
                                                        />
                                                    </ListItem>
                                                )) : (
                                                    <div className='stack-empty'>{NO_LINKED_STAMPS_MESSAGE}</div>
                                                )
                                        }
                                    </List>
                                </Scrollbars>
                            </div>
                            <div className='stack-tabs-wrap'>
                                <div>
                                    {SEVER_TEXT}
                                    <Field
                                        render={this.renderTextField}
                                        name='severedText'
                                        multiline={true}
                                        rows={6}
                                        variant='outlined'
                                        fullWidth={true}
                                    />
                                    {RECOMMENDATIONS}
                                    <Field
                                        render={this.renderTextField}
                                        name='recommendText'
                                        multiline={true}
                                        rows={6}
                                        variant='outlined'
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='stack-modal-footer'>
                            <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                disabled={submitting || invalid || pristine}
                                type='submit'
                                className='primary'
                            >
                                {SAVE}
                            </Button>
                            <span>
                                <Button
                                    variant='outlined'
                                    onClick={(): void => this.handleCloseModal(form)}
                                    className='modal-window__buttons outlined'
                                    size='small'
                                >
                                    {CLOSE}
                                </Button>
                            </span>
                            <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                disabled={submitting || invalid || pristine}
                                type='submit'
                                onClick={(): void => this.handleSaveAndClose()}
                                className='primary'
                            >
                                {SAVE_AND_CLOSE}
                            </Button>
                        </div>
                        <div className='stack-modal-loader'>
                            {annotationPending && <LinearProgress />}
                        </div>
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={(): void => handleDetectChanges(this.isSeverFormChanged(form))}
                        />
                    </form>
                )}
            />
        );
    }

    private getLinkedStampsName(): string[] {
        const { selectedAnnotation } = this.state;
        const { pageStamps } = this.props;
        const tempLinkedStamps = [];

        if(selectedAnnotation && selectedAnnotation.linkedArticles && selectedAnnotation.linkedArticles.length) {
            selectedAnnotation.linkedArticles.map((value: number) => {
                if(pageStamps && pageStamps.length) {
                    tempLinkedStamps.push((pageStamps.find((stamp: IStamp) => stamp.id === value)).text);
                }
            });
        }

        return tempLinkedStamps;
    }

    private isSeverFormChanged(form: FormApi): boolean {
        return !isEqual(form.getState().values, this.state.selectedAnnotation);
    }

    private handleSave = (form: IAnnotation): void => {
        const { pageInformation, redactionDocumentId } = this.props;
        const actionParams = {
            redactionDocumentId,
            documentId: pageInformation.documentId,
            pageId: pageInformation.pageId,
            layerId: pageInformation.layers[0].id,
        };
        let formValue = {};

        if (form && !form.severedText) {
            formValue = {
                ...form,
                severedText: '',
                severedTextEdited: true,
            };

            this.setState({
                selectedAnnotation: formValue,
            });
        }  else {
            formValue = {
                ...form,
                severedTextEdited: true,
            };
            this.setState({
                selectedAnnotation: formValue,
            });
        }

        if(this.state.isSaveAndClose) {
            this.props.handleUpdateAnnotations(actionParams, [formValue], true);
        } else {
            this.props.handleUpdateAnnotations(actionParams, [formValue]);
        }
    }

    private handleSaveAndClose = (): void => {
        this.setState({
            isSaveAndClose: true,
        });
    }

    private handleCloseModal = (form: FormApi): void => {
        if (this.isSeverFormChanged(form)) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: WARNING,
                message: CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
                confirm: (): () => void => (): void => {
                    this.props.handleCloseModalWindow(VIEW_SEVER_INFO);
                    this.props.handleDetectChanges(false);
                },
            });
        } else {
            this.props.handleCloseModalWindow(VIEW_SEVER_INFO);
        }
    }
}

const mapStateToProps = (state: StoreState): ISeverInfoStateProps => ({
    singleSelectedShape: getSingleSelectedShape(state),
    pageAnnotations: getAnnotationLayouts(state),
    pageInformation: getPageInformation(state),
    redactionDocumentId: getRedactionDocumentId(state),
    pageStamps: getCurrentPageStamps(state),
    annotationPending: getLoadingAnnotation(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, ISeverInfoDispatchProps, AnyAction>,
): ISeverInfoDispatchProps => ({
    handleCloseModalWindow: (id: string): void => {
        dispatch(handleCloseModalWindow(id));
    },
    handleUpdateAnnotations: (props: IPageActionParams, shapes: IAnnotation[], isSaveAndClose?: boolean): void => {
        dispatch(updateAnnotation(props, shapes, isSaveAndClose));
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
});

export default connect( mapStateToProps, mapDispatchToProps)(ModalSeverInfo);

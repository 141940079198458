export const GET_SEARCH_REDACTION_DOCUMENTS_PENDING = 'reduction/GET_SEARCH_REDACTION_DOCUMENTS_PENDING';
export const GET_SEARCH_REDACTION_DOCUMENTS_SUCCESS = 'reduction/GET_SEARCH_REDACTION_DOCUMENTS_SUCCESS';
export const GET_SEARCH_REDACTION_DOCUMENTS_FAILURE = 'reduction/GET_SEARCH_REDACTION_DOCUMENTS_FAILURE';

export const GET_FOI_FOLDER_TYPES_PENDING = 'reduction/GET_FOI_FOLDER_TYPES_PENDING';
export const GET_FOI_FOLDER_TYPES_SUCCESS = 'reduction/GET_FOI_FOLDER_TYPES_SUCCESS';
export const GET_FOI_FOLDER_TYPES_FAILURE = 'reduction/GET_FOI_FOLDER_TYPES_FAILURE';

export const GET_IMPORT_REDACTION_DOCUMENT_PENDING = 'reduction/GET_IMPORT_REDACTION_DOCUMENT_PENDING';
export const GET_IMPORT_REDACTION_DOCUMENT_SUCCESS = 'reduction/GET_IMPORT_REDACTION_DOCUMENT_SUCCESS';
export const GET_IMPORT_REDACTION_DOCUMENT_FAILURE = 'reduction/GET_IMPORT_REDACTION_DOCUMENT_FAILURE';

export const GET_AMANDA_EDMS_ADAPTORS_PENDING = 'reduction/GET_AMANDA_EDMS_ADAPTORS_PENDING';
export const GET_AMANDA_EDMS_ADAPTORS_SUCCESS = 'reduction/GET_AMANDA_EDMS_ADAPTORS_SUCCESS';
export const GET_AMANDA_EDMS_ADAPTORS_FAILURE = 'reduction/GET_AMANDA_EDMS_ADAPTORS_FAILURE';

export const GET_AMANDA_EDMS_FILES_PENDING = 'reduction/GET_AMANDA_EDMS_FILES_PENDING';
export const GET_AMANDA_EDMS_FILES_SUCCESS = 'reduction/GET_AMANDA_EDMS_FILES_SUCCESS';
export const GET_AMANDA_EDMS_FILES_FAILURE = 'reduction/GET_AMANDA_EDMS_FILES_FAILURE';

export const GET_AMANDA_ATTACHMENTS_PENDING = 'reduction/GET_AMANDA_ATTACHMENTS_PENDING';
export const GET_AMANDA_ATTACHMENTS_SUCCESS = 'reduction/GET_AMANDA_ATTACHMENTS_SUCCESS';
export const GET_AMANDA_ATTACHMENTS_FAILURE = 'reduction/GET_AMANDA_ATTACHMENTS_FAILURE';

export const CLEAR_SEARCH_REDACTION_DOCUMENTS = 'reduction/CLEAR_SEARCH_REDACTION_DOCUMENTS';

import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Scrollbars } from 'rc-scrollbars';

import '../modalPackagePresets.style.scss';
import PresetName from './presetName/PresetName';
import { ADD, EXISTING_PRESET } from '../../../../constants/packagePresets.contants';
import { IExistingPreset, IExportPreset } from '../modalPackagePresets.model';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { usePrevious } from '../../../../hooks/usePrevious';
import { changeLang } from '../../../../redux/actions/localization';

// this function can get different types
export const getCheckedAnnotations = (annotations: any[]): any[] => {
    return annotations.reduce((acc: any[], current: any): number[] => {
        if (current.showHighlight || current.showText) {
            return [...acc, current.annotationTypeId];
        }

        return acc;
    }, []);
};

const ExistingPresets = (props: IExistingPreset): JSX.Element => {
    const {
        presets,
        setSelectedPreset,
        setSelectedPresetId,
        selectedPresetId,
        handleShowTabs,
        addPermission,
        deletePermission,
        parentLabel,
        language,
    } = props;
    const [idState, useId] = useState(null);
    const prevPresets = usePrevious(presets);
    const prevSelectedId = usePrevious(selectedPresetId);

    const langRule = changeLang(language);

    useEffect(() => {
        if (prevPresets !== presets && presets.length === 0) {
            props.setDefaultPreset();
        }

        if (prevSelectedId !== selectedPresetId) {
            useId(selectedPresetId);
        }
    });

    const handlePresetName = (id: number): void => {

        const selectedPreset = presets.filter((preset: IExportPreset): boolean => {
            return preset.id === id;
        });

        const { annotationControlOptions: { highlightDisplayOptions, severTransparencyOptions } } = selectedPreset[0];

        useId(selectedPreset[0].id);

        const highlightIds = getCheckedAnnotations(highlightDisplayOptions);
        const severIds = getCheckedAnnotations(severTransparencyOptions);

        setSelectedPresetId(id);
        setSelectedPreset({
            exportOptions: selectedPreset[0].exportOptions,
            paginationOptions: selectedPreset[0].paginationOptions,
            annotationControlOptions: [...highlightIds, ...severIds],
        });
        handleShowTabs(true);
    };

    const handleAddPreset = (): void => {
        handleShowTabs(true);
        props.setDefaultPreset();
    };

    const renderPresetName = (): JSX.Element[] => {
        return presets.map((preset: IExportPreset): JSX.Element => {
            return (
                <PresetName
                    active={idState === preset.id ? 'selectedPreset' : ''}
                    key={preset.exportOptions.name}
                    text={language ? language === 'fr' ? preset.exportOptions.name2 ? preset.exportOptions.name2
                        : preset.exportOptions.name : preset.exportOptions.name :preset.exportOptions.name}
                    id={preset.id}
                    deletePermission={deletePermission}
                    deletePackagePreset={props.deletePackagePreset}
                    openModalWindow={props.openModalWindow}
                    handlePresetName={handlePresetName}
                    handleShowTabs={props.handleShowTabs}
                    parentLabel={parentLabel}
                    langRule={langRule}
                />);
        });
    };

    return (
        <div className='existing-presets'>
            <Typography className='presets-heading' variant='h6'>
                {parentLabel && parentLabel.existingPresetsLabel[langRule]}
            </Typography>
            {
                addPermission && (
                    <div className='control-preset-button'>
                        <Fab
                            variant='extended'
                            size='medium'
                            color='primary'
                            aria-label='Add'
                            onClick={handleAddPreset}
                            className='disclosure-add'
                        >
                            <AddIcon fontSize={'small'} />
                            {parentLabel && parentLabel.addNewLabel[langRule]}
                        </Fab>
                    </div>
                )
            }
            <div className='preset-list'>
                <Scrollbars>
                    {renderPresetName()}
                </Scrollbars>
            </div>
        </div>
    );
};

export default ExistingPresets;

import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Table from '../../components/table/Table';
import {
    getDocumentList,
    getDocumentListMetadata,
    getDocumentListLoading,
    getDocumnetListColumnHeader,
    getResetedDocumentListColumnHeaderLoading,
    getStackId,
} from '../../redux/selectors/documentList';
import { createRedactionDocument } from '../../redux/actions/initialization/initialization';
import { getRedactionDocumentId, getUserMetaData, getRedactionFolderRsn } from '../../redux/selectors/initialization';
import { getCurrentPage, getPageListLoading, getSelectedDocuments } from '../../redux/selectors/pageList';
import {
    saveStackId,
    fetchDocumentList,
    fetchStackDocumentsList,
    setDocumentListTableColumns,
    modifyDocumentListColumnHeader,
    getResetedDocumentListColumnHeader,
} from '../../redux/actions/documentList';
import {
    fetchDocumentMetadata,
    setDocumentsRangeToSelectedList,
    setDocumentToSelectedList,
} from '../../redux/actions/pageList';
import { clearOptionsState } from '../../redux/actions/redactor';
import './leftTopBar.styles.scss';
import {
    ILeftTopBarDispatchProps,
    ILeftTopBarProps,
    ILeftTopBarState,
    ILeftTopBarStateProps,
} from './leftTopBar.model';
import { Spinner } from '../../components/spinner/Spinner';
import { openContextMenu, closeContextMenu } from '../../redux/actions/contextMenu';
import { DOCUMENT_LIST_CONTEXT_MENU } from '../../constants/contextmenu/context.menu.constants';
import { DOCUMENT_LIST_LABEL } from '../../constants/leftSidebar.config';
import { IState } from '../../redux/store';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IModalIdsData } from '../../redux/reducers/modal/modal.model';
import { Row } from 'react-table';
import { ITrProps, MyRowData } from '../../components/table/table.model';
import { getStackList } from '../../redux/selectors/stackList';
import { fetchStackList } from '../../redux/actions/stackList';
import { IFile } from '../../redux/reducers/documentList/documentList.model';
import { isContextMenu } from '../../utils/event.utils';
import { getDocumentLoading } from '../../redux/selectors/redactor';
import { IHeader } from './leftTopBar.model';
import { getOriginalSearchData } from '../../redux/selectors/globalSearch';
import { ICurrentSearchElement, ISearchResponseData } from '../../containers/globalSearch/globalSearch.model';
import { setCurrentSearchElement, setPreviousSearchElement } from '../../redux/actions/globalSearch';
import { getRedactionLanguage, getRedactionMode } from '../../redux/selectors/localStorage';
import { getModifiedLabels } from '../../redux/selectors/localization';
import { changeLang } from '../../redux/actions/localization';
import { initialLabel } from '../../constants/localization.constants';
import resourceBundle from '../../containers/localization/localizationData';
import { SelectChangeEvent } from '@mui/material';

const labels = {
    documentListTitle: initialLabel,
    headerName: initialLabel,
    headerFileName: initialLabel,
    headerDisclosure: initialLabel,
    headerOcr: initialLabel,
    headerPages: initialLabel,
    headerStacks: initialLabel,
    headerStart: initialLabel,
    headerEnd: initialLabel,
    headerDocDate: initialLabel,
    headerPreReleaseDate: initialLabel,
    headerReleaseDate: initialLabel,
    headerTo: initialLabel,
    headerToOrg: initialLabel,
    headerFrom: initialLabel,
    headerFromOrg: initialLabel,
    headerSubject: initialLabel,
};

const getDocumentLabelByKey = (key: string): string => {
    switch (key) {
        case 'DOCUMENT_LIST_COLUMN_HEADER_NAME_TITLE':
            return 'documentListTitle';
        case 'DOCUMENT_LIST_COLUMN_HEADER_NAME':
            return 'headerName';
        case 'DOCUMENT_LIST_COLUMN_HEADER_FILE_NAME':
            return 'headerFileName';
        case 'DOCUMENT_LIST_COLUMN_HEADER_DISCLOSURE':
            return 'headerDisclosure';
        case 'DOCUMENT_LIST_COLUMN_HEADER_OCR':
            return 'headerOcr';
        case 'DOCUMENT_LIST_COLUMN_HEADER_PAGES':
            return 'headerPages';
        case 'DOCUMENT_LIST_COLUMN_HEADER_STACKS':
            return 'headerStacks';
        case 'DOCUMENT_LIST_COLUMN_HEADER_START':
            return 'headerStart';
        case 'DOCUMENT_LIST_COLUMN_HEADER_END':
            return 'headerEnd';
        case 'DOCUMENT_LIST_COLUMN_HEADER_DOCUMENT_DATE':
            return 'headerDocDate';
        case 'DOCUMENT_LIST_COLUMN_HEADER_PRE_RELEASE_DATE':
            return 'headerPreReleaseDate';
        case 'DOCUMENT_LIST_COLUMN_HEADER_RELEASE_DATE':
            return 'headerReleaseDate';
        case 'PAGE_LIST_COLUMN_HEADER_TO':
            return 'headerTo';
        case 'PAGE_LIST_COLUMN_HEADER_TO_ORG':
            return 'headerToOrg';
        case 'PAGE_LIST_COLUMN_HEADER_FROM':
            return 'headerFrom';
        case 'PAGE_LIST_COLUMN_HEADER_FROM_ORG':
            return 'headerFromOrg';
        case 'DOCUMENT_LIST_COLUMN_HEADER_SUBJECT':
            return 'headerSubject';
        default: return '';
    }
}

class LeftTopBar extends React.Component<ILeftTopBarProps, ILeftTopBarState> {
    constructor(props: ILeftTopBarProps) {
        super(props);
        this.state = {
            collapsedTopBlock: false,
            collapsedBottomBlock: false,
            chosenStack: 0,
            page:0,
            redactionDocumentId :null
        };
    }
    public componentDidUpdate(prevProps: ILeftTopBarProps): void {
        const { mode, stackList, redactionDocumentId } = this.props;
        if (prevProps.stackList !== this.props.stackList && this.props.stackList.length &&
            mode === "Contribute") {
            const stackId = stackList[0].id;
            if (this.state.chosenStack !== this.props.stackId) {
                this.setState({ chosenStack: stackId });
                this.props.fetchStackDocumentsList(redactionDocumentId, stackId);
                this.props.saveStackId(stackId);
                this.setState({ redactionDocumentId: redactionDocumentId });
            }
        }

        if (this.state.chosenStack !== this.props.stackId && this.props.stackId !== null) {

            this.setState({ chosenStack: this.props.stackId });
        }

        const { redactionLang, modifiedLabels, documentListColumnHeader } = this.props;
        const langRule = changeLang(redactionLang);
        const documentListLabels = (key: string): string => {
            switch (key) {
                case 'name':
                    return labels.headerName[langRule];
                case 'fileName':
                    return labels.headerFileName[langRule];
                case 'disclosureType':
                    return labels.headerDisclosure[langRule];
                case 'ocr':
                    return labels.headerOcr[langRule];
                case 'pages':
                    return labels.headerPages[langRule];
                case 'stacks':
                    return labels.headerStacks[langRule];
                case 'start':
                    return labels.headerStart[langRule];
                case 'end':
                    return labels.headerEnd[langRule];
                case 'documentDate':
                    return labels.headerDocDate[langRule];
                case 'preReleaseDate':
                    return labels.headerPreReleaseDate[langRule];
                case 'releaseDate':
                    return labels.headerReleaseDate[langRule];
                case 'to':
                    return labels.headerTo[langRule];
                case 'toOrg':
                    return labels.headerToOrg[langRule];
                case 'from':
                    return labels.headerFrom[langRule];
                case 'fromOrg':
                    return labels.headerFromOrg[langRule];
                case 'subject':
                    return labels.headerSubject[langRule];
                default: return '';
            }
        }
        documentListColumnHeader.map((columnHeader) => {
            columnHeader.Header = documentListLabels(columnHeader.id);
            columnHeader.enableResizing = true;
        });
        resourceBundle.map((resource: any) => {
            if (getDocumentLabelByKey(resource.resourceKey)) {
                labels[getDocumentLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getDocumentLabelByKey(resource.resourceKey)) {
                labels[getDocumentLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
    }

   


    public render(): JSX.Element {
        const { files, currentDocuments, loadingDocumentList,
            isPDFLoading, documentListColumnHeader, setDocumentListTableColumn,
            modifyDocumentListTableColumnHeader, userMetaData, resetedDocumentListColumnLoading,
            redactionLang, getResetedDocumentListColumnHeader, fetchStackDocumentsList , stackId , redactionDocumentId} = this.props;
        const langRule = changeLang(redactionLang);
        const paginatedData = files;

        return (
            <div>
                {resetedDocumentListColumnLoading || loadingDocumentList ? (
                    <Spinner active={true} />
                ) : (
                    <div className='top'>
                        <Table
                            isPDFLoading={isPDFLoading}
                            columns={documentListColumnHeader}
                            data={paginatedData}                        
                            getTrProps={(rowInfo: any): ITrProps => this.getTrProps(rowInfo)}
                            contextMenuId={DOCUMENT_LIST_CONTEXT_MENU}
                            selectedIds={currentDocuments}
                            showPagination={files.length > 100}
                            defaultPageSize={100}
                            pagesCount={files.length}
                            page={this.state.page}
                            onFetchData={(page: number): void => this.setState({page})}
                            allowSettings={true}
                            stackSelector={true}
                            enableShowHideColumn={true}
                            stackList={this.props.stackList}
                            chosenStack={this.state.chosenStack}
                            handleChange={this.handleChange}
                            mode={this.props.mode}
                            title={labels.documentListTitle[langRule]}
                            changeColumns={setDocumentListTableColumn}
                            userPreferenceTable={true}
                            modifyColumns={modifyDocumentListTableColumnHeader}
                            userId={userMetaData.userId}
                            resetColumnHeader={this.handleReset}
                        />
                    </div>
                )}
            </div>
        );
    }

    public getTrProps(rowInfo: Row): ITrProps {
        this.onContextMenu(DOCUMENT_LIST_CONTEXT_MENU, get(rowInfo, 'original.id', null));
        const { currentDocuments, originalSearchData,
            updateCurrentSearchElement, setPrevsSearchElement } = this.props;
        const id = get(rowInfo, 'original.id', null);
        this.onDocumentSelect(id, false,false);
        if (currentDocuments.length && currentDocuments.includes(id)) {
            this.onContextMenu(DOCUMENT_LIST_CONTEXT_MENU, currentDocuments);
        } else {
            this.onContextMenu(DOCUMENT_LIST_CONTEXT_MENU, [id]);
        }
        return {
            id,
            rowInfo,
            onMouseDown: (e: React.MouseEvent<HTMLOptionElement, MouseEvent>): void => {
                const isContext = isContextMenu(e);

                if (!isContext || isContext && !currentDocuments.includes(id)) {
                    this.onDocumentSelect(id, e.ctrlKey, e.shiftKey);

                    if (!currentDocuments.length && originalSearchData && originalSearchData.length) {
                        const selectedDoc = originalSearchData.find(
                            (value: ISearchResponseData) => value.documentId === id);

                        if (selectedDoc && selectedDoc.pages[0].page.actualPageNumber === 1) {

                            const currentSearchElement = {
                                index: 0,
                                coordinates: selectedDoc.pages[0].coordinates[0],
                            };

                            updateCurrentSearchElement(currentSearchElement);
                            setPrevsSearchElement({
                                ...currentSearchElement,
                                documentName: selectedDoc.documentName,
                                documentId: selectedDoc.documentId,
                                pageId: selectedDoc.pages[0].page.id,
                                actualPageNumber: 1,
                            });

                        }

                    }

                }
            },
        };
    }

    private onDocumentSelect(id: number, isMultiple: boolean = false, isRange: boolean = false): void {
        if (!isMultiple && !isRange && !this.props.currentDocuments.includes(id)) {
            this.props.clearOptionsState();
            this.props.fetchDocumentMetadata(this.props.redactionDocumentId, id);
        }

        const { fullFilesList, currentDocuments } = this.props;

        if (isRange && currentDocuments.length) {
            this.props.onSetDocumentsRangeToSelectedList(id, fullFilesList, currentDocuments);
        } else {
            this.props.onSetDocumentToSelectedList(id, isMultiple);
        }
    }

    private onContextMenu(type: string, ids: number[]): void {
        this.props.onContextMenu(type, {
            documentIds: ids,
            currentDocId: ids[0],
        });
    }
    private handleReset = (userId: string): void  => {
        const { redactionDocumentId , stackId, getResetedDocumentListColumnHeader, folderRSN, createDocument} = this.props;
            getResetedDocumentListColumnHeader(userId);
            if (stackId && stackId !== 0) {
                this.props.fetchStackDocumentsList(redactionDocumentId, stackId);
            } else {
                createDocument(String(folderRSN));
            }
    }

    private handleChange = (e: SelectChangeEvent<number>, child: React.ReactNode): void => {
        const { redactionDocumentId } = this.props;
        const stackId: any = e.target.value;

        this.setState({ chosenStack: stackId }, () => {
            if (stackId !== 0) {
                this.props.fetchStackDocumentsList(redactionDocumentId, stackId);
            } else {
                this.props.fetchDocumentList(redactionDocumentId);
                this.props.saveStackId(stackId);
            }
        });
    }
}

const mapStateToProps = (state: IState): ILeftTopBarStateProps => ({
    files: getDocumentListMetadata(state),
    fullFilesList: getDocumentList(state),
    loadingDocumentList: getDocumentListLoading(state),
    loadingPageList: getPageListLoading(state),
    currentDocuments: getSelectedDocuments(state),
    selectedPage: getCurrentPage(state),
    redactionDocumentId: getRedactionDocumentId(state),
    stackList: getStackList(state),
    isPDFLoading: getDocumentLoading(state),
    documentListColumnHeader: getDocumnetListColumnHeader(state),
    userMetaData: getUserMetaData(state),
    resetedDocumentListColumnLoading: getResetedDocumentListColumnHeaderLoading(state),
    stackId: getStackId(state),
    originalSearchData: getOriginalSearchData(state),
    mode: getRedactionMode(state),
    redactionLang: getRedactionLanguage(state),
    modifiedLabels: getModifiedLabels(state),
    folderRSN : getRedactionFolderRsn(state)
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<IState, ILeftTopBarDispatchProps, AnyAction>): ILeftTopBarDispatchProps => ({
        fetchDocumentList: (redactionDocumentId: number): void => {
            dispatch(fetchDocumentList(redactionDocumentId));
        },
        fetchDocumentMetadata: (redactionDocumentId: number, id: number): void => {
            dispatch(fetchDocumentMetadata(redactionDocumentId, id));
        },
        clearOptionsState: (): void => {
            dispatch(clearOptionsState());
        },
        onContextMenu: (type: string, data: IModalIdsData): void => {
            dispatch(openContextMenu(type, data));
        },
        closeContextMenu: (): void => {
            dispatch(closeContextMenu());
        },
        onSetDocumentToSelectedList: (id: number, isMultiple: boolean = false): void => {
            dispatch(setDocumentToSelectedList(id, isMultiple));
        },
        onSetDocumentsRangeToSelectedList: (id: number, files: IFile[], selectedDocuments: number[]): void => {
            dispatch(setDocumentsRangeToSelectedList(id, files, selectedDocuments));
        },
        fetchStackList: (redactionDocumentId: number): void => {
            dispatch(fetchStackList(redactionDocumentId));
        },
        fetchStackDocumentsList: (redactionDocumentId: number, id: number): void => {
            dispatch(fetchStackDocumentsList(redactionDocumentId, id));
        },
        saveStackId: (id: number): void => {
            dispatch(saveStackId(id));
        },
        setDocumentListTableColumn: (columns: IHeader[]): void => {
            dispatch(setDocumentListTableColumns(columns));
        },
        modifyDocumentListTableColumnHeader: (columns: IHeader[], userId: string): void => {
            dispatch(modifyDocumentListColumnHeader(columns, userId));
        },
        getResetedDocumentListColumnHeader: (userId: string): void => {
            dispatch(getResetedDocumentListColumnHeader(userId));
        },
        updateCurrentSearchElement: (element: ICurrentSearchElement): void => {
            dispatch(setCurrentSearchElement(element));
        },
        setPrevsSearchElement: (element: ICurrentSearchElement): void => {
            dispatch(setPreviousSearchElement(element));
        },
        createDocument: (folderRSN: string): void => {
            dispatch(createRedactionDocument(folderRSN));
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(LeftTopBar);
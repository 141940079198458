import { IState } from '../store';
import { createSelector } from 'reselect';
import { IModalEncryptionState, IEncryptionType } from 'redux/reducers/modalEncryption/modalEncryptionKeys.model';

export const encryptionKeys = (state: IState): IModalEncryptionState => state.modalWindowEncryptionKeys;

export const getEncryptionKeys = createSelector(
    [ encryptionKeys ],
    (layout: IModalEncryptionState): IEncryptionType[] => layout.encryptionKeysType,
);

export const getEncryptionLoading = createSelector(
    [ encryptionKeys ],
    (layout: IModalEncryptionState): boolean => layout.encryptionTypePending,
);
export const getEncryptionOptions = createSelector(
    [ encryptionKeys ],
    (layout: IModalEncryptionState): IEncryptionType=> layout.encryptionOptions,
);

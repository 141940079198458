import { AnyAction } from 'redux';
import {
    GET_DOCUMENT_TASKS_PENDING,
    GET_DOCUMENT_TASKS_SUCCESS,
    GET_DOCUMENT_TASKS_FAILURE,
    DELETE_DOCUMENT_TASKS_PENDING,
    DELETE_DOCUMENT_TASKS_SUCCESS,
    DELETE_DOCUMENT_TASKS_FAILURE,
    UPDATE_DOCUMENT_TASKS_PENDING,
    UPDATE_DOCUMENT_TASKS_SUCCESS,
    UPDATE_DOCUMENT_TASKS_FAILURE,
} from './constants';
import { ITaskList, ITaskListReducer } from './taskQueue.model';

export const initialState: ITaskListReducer = {
    taskList: null,
    lastProcessedDocuments: null,
    totalPages: 0,
    currentPage: 0,
    totalTaskCount: 0,
    showFinishedTasks: false,
    loading: false,
    loadedDocumentsTask: false,
    loadingDelete: false,
    error: null,
};

const stampsReducer = (state: ITaskListReducer = initialState, {type, payload}: AnyAction): ITaskListReducer => {
    switch (type) {
        case GET_DOCUMENT_TASKS_PENDING:
            return {
                ...state,
                loading: true,
                loadedDocumentsTask: false,
                error: null,
            };
        case GET_DOCUMENT_TASKS_SUCCESS:
            return {
                ...state,
                taskList: payload.taskList,
                lastProcessedDocuments: payload.lastProcessedDocuments,
                totalPages: payload.totalPages,
                currentPage: payload.currentPage,
                totalTaskCount: payload.totalTaskCount,
                showFinishedTasks: payload.showFinishedTasks,
                loading: false,
                loadedDocumentsTask: true,
                error: null,
            };
        case GET_DOCUMENT_TASKS_FAILURE:
            return {
                ...state,
                loading: false,
                loadedDocumentsTask: true,
                error: payload,
            };
        case DELETE_DOCUMENT_TASKS_PENDING:
        case UPDATE_DOCUMENT_TASKS_PENDING:
            return {
                ...state,
                loadingDelete: true,
                error: null,
            };
        case DELETE_DOCUMENT_TASKS_SUCCESS:
            return {
                ...state,
                taskList: state.taskList.filter((task: ITaskList): boolean => task.id !== payload),
                totalTaskCount: state.totalTaskCount - 1,
                loadingDelete: false,
                error: null,
            };
        case DELETE_DOCUMENT_TASKS_FAILURE:
        case UPDATE_DOCUMENT_TASKS_FAILURE:

            return {
                ...state,
                loadingDelete: false,
                error: payload,
            };
        case UPDATE_DOCUMENT_TASKS_SUCCESS:
            return {
                ...state,
                taskList: state.taskList.map((task: ITaskList): ITaskList =>
                    task.id === payload.id ? {
                        ...task,
                        ...payload.status,
                    } : task),
                loadingDelete: false,
                error: null,
            };
        default:
            return state;
    }
};

export default stampsReducer;

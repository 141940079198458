import {
    FETCH_DOCUMENT_PENDING,
    FETCH_DOCUMENT_SUCCESS,
    FETCH_DOCUMENT_FAILURE,
    PAGE_SIZE,
    PAGE_SCALE,
    PAGE_ROTATE_PENDING,
    PAGE_ROTATE_SUCCESS,
    PAGE_ROTATE_FAILURE,
    SET_PAGE_ROTATE,
    DOCUMENT_PAGE_NUMBER,
    CLEAR_OPTIONS_STATE,
    CLEAR_PAGE_STATE,
    ADD_SELECTED_SHAPES,
    CLEAR_SELECTED_SHAPES,
    COPY_SELECTED_SHAPES,
    GET_CLIENT_SIZE,
    PAST_SELECTED_SHAPES,
    ADD_SINGLE_SELECTED_SHAPE,
    GET_OVERLAYED_SHAPES_PENDING,
    GET_OVERLAYED_SHAPES_SUCCESS,
    GET_OVERLAYED_SHAPES_FAILURE,
    SET_LINKED_STAMPS_ID,
    SET_LINKED_ANNOTATION_ID,
    SET_LINKED_ARTICLE_FLAG,
    CLEAR_LINKED_ANNOTATION_AND_STAMP_ID,
    SET_CONTEXT_MENU_COORDINATES,
} from './constants';
import { SET_PDF_WIDTH, SET_PDF_HEIGHT, SET_PDF_OFFSET_WIDTH } from './constants';
import { AnyAction } from 'redux';
import { IRedactorState } from './redactor.model';
import { CLEAR_CURRENT_DOCUMENT_STATE } from '../pageList/constant';
import { PAGE_WIDTH_VAL } from '../../../constants';
import { IStamp } from '../stamps/stamps.model';
import { first } from 'lodash';

export const initialState: IRedactorState = {
    pdfSrc: null,
    selectedPage: 1,
    rotate: 0,
    textLayer: false,
    renderAnnotations: false,
    scale: 1,
    size: PAGE_WIDTH_VAL,
    loading: false,
    loaded: false,
    redactorLoading: false,
    error: null,
    layerLoading: false,
    pdfWidth: null,
    pdfHeight: null,
    pdfOffsetWidth: null,
    selectedAnnotationShapes: [],
    selectedStampShapes: [],
    selectedSingleShape: null,
    buffer: {},
    clientSize: null,
    overlayedShapes: {
        overlayedAnnotations: [],
        overlayedStamps: [],
        getOverlayedShapesPending: false,
        hasError: false,
    },
    linkedAnnotaionIdAndStampsId: {
        linkedArticlesFlag: false,
        annotaionId: null,
        articlesId: null,
    },
    contextMenuStartPoint: null,
};

const redactorReducer = (state: IRedactorState = initialState, { type, payload }: AnyAction): IRedactorState => {
    switch (type) {
        case FETCH_DOCUMENT_PENDING:
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        case FETCH_DOCUMENT_SUCCESS:
            return {
                ...state,
                pdfSrc: payload,
                loading: false,
                loaded: true,
            };
        case FETCH_DOCUMENT_FAILURE:
            return {
                ...state,
                pdfSrc: payload,
                loading: false,
                loaded: false,
            };
        case PAGE_SIZE:
            return {
                ...state,
                size: payload,

            };
        case SET_PDF_WIDTH:
            return {
                ...state,
                pdfWidth: payload,

            };
        case SET_PDF_HEIGHT:
            return {
                ...state,
                pdfHeight: payload,

            };
        case SET_PDF_OFFSET_WIDTH:
            return {
                ...state,
                pdfOffsetWidth: payload,

            };
        case PAGE_SCALE:
            return {
                ...state,
                scale: payload,

            };
        case PAGE_ROTATE_PENDING:
            return {
                ...state,
                redactorLoading: true,
                error: null,
            };
        case PAGE_ROTATE_SUCCESS:
            return {
                ...state,
                rotate: payload,
                redactorLoading: false,
            };
        case PAGE_ROTATE_FAILURE:
            return {
                ...state,
                redactorLoading: false,
                error: payload.error,
            };
        case SET_PAGE_ROTATE:
            return {
                ...state,
                rotate: payload || 0,
            };
        case DOCUMENT_PAGE_NUMBER:
            return {
                ...state,
                selectedPage: payload,
            };
        case CLEAR_OPTIONS_STATE:
            return {
                ...state,
                rotate: 0,
            };
        case CLEAR_PAGE_STATE:
            return {
                ...state,
                selectedPage: 1,
                rotate: 0,
                scale: 1,
                size: null,
            };
        case CLEAR_CURRENT_DOCUMENT_STATE:
            return {
                ...state,
                pdfSrc: null,
            };
        case ADD_SELECTED_SHAPES:
            return {
                ...state,
                selectedAnnotationShapes: payload.annotations,
                selectedStampShapes: payload.stamps,
                selectedSingleShape: null,
                buffer: {
                    ...state.buffer,
                    scale: payload.scale,
                },
            };
        case ADD_SINGLE_SELECTED_SHAPE:
            return {
                ...state,
                selectedSingleShape: payload.shape,
                selectedStampShapes: [],
                selectedAnnotationShapes: [],
                buffer: {
                    ...state.buffer,
                    scale: payload.scale,
                },

            };
        case CLEAR_SELECTED_SHAPES:
            return {
                ...state,
                selectedAnnotationShapes: [],
                selectedStampShapes: [],
                selectedSingleShape: null,
            };
        case COPY_SELECTED_SHAPES:
            const { selectedSingleShape, selectedAnnotationShapes, selectedStampShapes } = state;
            const singleSelectedIsAnnotation = selectedSingleShape && selectedSingleShape.annotationTypeId;

            const annotationShapes = selectedSingleShape && singleSelectedIsAnnotation ?
                [...selectedAnnotationShapes, selectedSingleShape] : selectedAnnotationShapes;
            const stamps = selectedSingleShape && !singleSelectedIsAnnotation ?
                [...selectedStampShapes, selectedSingleShape] : selectedStampShapes;

            const stampShapes = stamps.map((item: IStamp) => {
                const oldStamp = payload.find((stamp: IStamp) => stamp.id === item.id);

                return oldStamp
                    ? { ...item, coordinate: first(oldStamp.coordinate) as any}
                    : item;
            });

            return {
                ...state,
                buffer: {
                    ...state.buffer,
                    annotationShapes,
                    stampShapes,
                },
            };
        case GET_CLIENT_SIZE:
            return {
                ...state,
                clientSize: payload,
            };
        case PAST_SELECTED_SHAPES:
        case GET_OVERLAYED_SHAPES_PENDING:
            return {
                ...state,
                overlayedShapes: {
                    ...state.overlayedShapes,
                    getOverlayedShapesPending: true,
                    hasError: false,
                },
            };
        case GET_OVERLAYED_SHAPES_SUCCESS:
            return {
                ...state,
                overlayedShapes: {
                    ...state.overlayedShapes,
                    overlayedAnnotations: payload.annotations,
                    overlayedStamps: payload.stamps,
                    getOverlayedShapesPending: false,
                    hasError: false,
                },
            };
        case GET_OVERLAYED_SHAPES_FAILURE:
            return {
                ...state,
                overlayedShapes: {
                    ...state.overlayedShapes,
                    getOverlayedShapesPending: false,
                    hasError: true,
                },
            };
        case SET_LINKED_ARTICLE_FLAG:
            return {
                ...state,
                linkedAnnotaionIdAndStampsId: {
                    ...state.linkedAnnotaionIdAndStampsId,
                    linkedArticlesFlag: payload,
                },
            };
        case SET_LINKED_ANNOTATION_ID:
            return {
                ...state,
                linkedAnnotaionIdAndStampsId: {
                    ...state.linkedAnnotaionIdAndStampsId,
                    annotaionId: payload,
                },
            };
        case SET_LINKED_STAMPS_ID:
            return {
                ...state,
                linkedAnnotaionIdAndStampsId: {
                    ...state.linkedAnnotaionIdAndStampsId,
                    articlesId: payload,
                },
            };
        case CLEAR_LINKED_ANNOTATION_AND_STAMP_ID:
            return {
                ...state,
                linkedAnnotaionIdAndStampsId: {
                    linkedArticlesFlag: false,
                    annotaionId: null,
                    articlesId: null,
                },
            };
        case SET_CONTEXT_MENU_COORDINATES:
            return {
                ...state,
                contextMenuStartPoint: payload,
            };
        default:
            return state;
    }
};

export default redactorReducer;

import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { get } from 'lodash';
import {
    SET_SEARCH_PARAM,
    SEARCH_VALUE_SUCCESS,
    SEARCH_VALUE_PENDING,
    SEARCH_VALUE_FAILURE,
    SET_CURRENT_SEARCH_ELEMENT,
    SET_SEARCH_CURRENT_PAGE_ID,
    CLEAR_SEARCH_ACTION,
    APPLY_SEARCH_PENDING,
    SET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING,
    SET_GLOBAL_SEARCH_COLUMN_HEADER_LIST_SUCCESS,
    SET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE,
    MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_PENDING,
    MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS,
    MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE,
    SET_GLOBAL_SEARCH_TABLE_COLUMNS,
    RESET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING,
    RESET_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS,
    RESET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE,
    RESTRICT_ON_FETCH_DATA_FOR_PAGE_LIST,
    SET_ELEMENT_SEARCH_DIRECTION,
    SET_PREVIOUS_SEARCH_ELEMENT,
} from '../reducers/globalSearch/constant';
import {
    IPostSearchValue,
    ISearchParamChange,
    ICurrentSearchElement,
    ISearchResponseData, ISearchPage,
} from 'containers/globalSearch/globalSearch.model';
import { IError } from '../common.model';
import api from '../../api/reductionApi';
import { getPageSearchData } from '../../utils/globalSearch.util';
import { IState } from '../store';
import { setPageToSelectedList, fetchDocumentMetadata } from './pageList';
import { addError } from './errorHandling';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { IPages } from '../reducers/pageList/pageList.model';
import { getPageNumberByPageId, getPageResource } from './redactor';

export const setSearchParams = (param: ISearchParamChange): AnyAction => action(SET_SEARCH_PARAM, param);
export const setSearchCurrentPageId = (id: number): AnyAction => action(SET_SEARCH_CURRENT_PAGE_ID, id);
export const clearSearchTable = (): AnyAction => action(CLEAR_SEARCH_ACTION);

export const searchValueSuccess = (data: ISearchResponseData[]): AnyAction => action(SEARCH_VALUE_SUCCESS, data);
export const searchValuePending = (): AnyAction => action(SEARCH_VALUE_PENDING);
export const searchValueFailure = (error: IError): AnyAction => action(SEARCH_VALUE_FAILURE, error);
export const applySearchPending = (pending: boolean): AnyAction => action(APPLY_SEARCH_PENDING, pending);

export const setCurrentSearchElement = (element: ICurrentSearchElement): AnyAction =>
    action(SET_CURRENT_SEARCH_ELEMENT, element);
export const setElementSearchDirection = (direction: string): AnyAction =>
    action(SET_ELEMENT_SEARCH_DIRECTION, direction);
export const setPreviousSearchElement = (element: ICurrentSearchElement): AnyAction =>
    action(SET_PREVIOUS_SEARCH_ELEMENT, element);

export const setGlobalSearchColumnHeaderPending = (): AnyAction =>
    action(SET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING);
export const setGlobalSearchColumnHeaderSuccess = (globalSearchColumnsHeaders: IHeader[]): AnyAction =>
    action(SET_GLOBAL_SEARCH_COLUMN_HEADER_LIST_SUCCESS, globalSearchColumnsHeaders);
export const setGlobalSearchColumnHeaderFailure = (error: IError): AnyAction =>
    action(SET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE, error);

export const modifyGlobalSearchColumnHeaderPending = (): AnyAction =>
    action(MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_PENDING);
export const modifyGlobalSearchColumnHeaderSuccess = (columns: IHeader[]): AnyAction =>
    action(MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS, columns);
export const modifyGlobalSearchColumnHeaderFailure = (error: IError): AnyAction =>
    action(MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE, error);

export const setGlobalSearchTableColumns = (columns: IHeader[]): AnyAction =>
    action(SET_GLOBAL_SEARCH_TABLE_COLUMNS, columns);

export const resetGlobalSearchColumnHeaderPending = (): AnyAction =>
    action(RESET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING);
export const resetGlobalSearchColumnHeaderSuccess = (globalSearchColumnsHeaders: IHeader[]): AnyAction =>
    action(RESET_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS, globalSearchColumnsHeaders);
export const resetGlobalSearchColumnHeaderFailure = (error: IError): AnyAction =>
    action(RESET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE, error);

export const restrictOnFetchDataForPageList = (restrictFetchData: boolean): AnyAction =>
    action(RESTRICT_ON_FETCH_DATA_FOR_PAGE_LIST, restrictFetchData);

export const postSearchValue =
    (redactionDocId: number, searchValue: IPostSearchValue):
        (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
        async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
            dispatch(searchValuePending());

            try {
                const {
                    pageList: {
                        currentDocument,
                    },
                    globalSearch: {
                        currentPageId,
                    },
                } = getState();
                const response = await api.searchController.postSearchQuery(searchValue, redactionDocId);
                const responseDocName = get(response, '[0].documentName', '');
                const responsePage = get(response, '[0].pages[0].page', null);
                const responseDocId = get(response, '[0].documentId', 0);
                let isPageExists = false;

                response.forEach((item: ISearchResponseData) => {
                    isPageExists = item.pages.some((page: ISearchPage) => page.page.id === currentPageId);
                });

                const docName = currentDocument ? currentDocument.name : responseDocName;
                const pageId = (!currentPageId || !isPageExists) && responsePage ? responsePage.id : currentPageId;
                const isPageInCurrentPagination = currentDocument &&
                    currentDocument.pages.find((page: IPages) => page.id === pageId);
                const isCurrentDocument = currentDocument && currentDocument.id === responseDocId;

                dispatch(setPageToSelectedList(pageId, false));

                if (isCurrentDocument) {
                    if (isPageInCurrentPagination) {
                        dispatch(getPageResource(
                            redactionDocId, responseDocId, isPageInCurrentPagination.id,
                            isPageInCurrentPagination.rotation, isPageInCurrentPagination.layers[0].id));
                    } else {
                        dispatch(getPageNumberByPageId({
                            redactionDocumentId: redactionDocId,
                            documentId: responseDocId,
                            selectedPageId: responsePage.actualPageNumber,
                            getAnnotationStamps: true,
                        }));
                    }
                } else if (response.length) {
                    await dispatch(fetchDocumentMetadata(redactionDocId, responseDocId, null, false, true));
                    dispatch(getPageNumberByPageId({
                        redactionDocumentId: redactionDocId,
                        documentId: responseDocId,
                        selectedPageId: responsePage.actualPageNumber,
                        getAnnotationStamps: true,
                    }));
                }

                dispatch(setSearchParams({ searchQuery: searchValue.searchQuery }));
                dispatch(searchValueSuccess(response));

                if (pageId) {
                    const searchData = getPageSearchData(response, docName, pageId);

                    dispatch(setSearchCurrentPageId(pageId));
                    dispatch(setCurrentSearchElement(searchData ? {
                        index: 0,
                        coordinates: searchData.coordinates[0],
                    } : null));
                    dispatch(setPreviousSearchElement(searchData ? {
                        index: 0,
                        coordinates: searchData.coordinates[0],
                        documentName: docName,
                        documentId: responseDocId,
                        actualPageNumber: responsePage.actualPageNumber,
                        pageId,
                    } : null));
                }
            } catch (error) {
                dispatch(searchValueFailure(error));
                dispatch(addError(error));
            }
        };

export const getGlobalSearchColumnHeader = (lid: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(setGlobalSearchColumnHeaderPending());
        try {
            const response = await api.searchController.getGlobalSearchListColumnHeader(lid);

            dispatch(setGlobalSearchColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const modifyGlobalSearchColumnHeader = (globalSearchColumnHeader: IHeader[], userId: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        const columns = globalSearchColumnHeader.map((columnsHeader: IHeader, index: number) => {
            return {
                ...columnsHeader,
                displayOrder: index + 1,
            };
        });

        dispatch(modifyGlobalSearchColumnHeaderPending());
        try {
            const response = await api.searchController.modifyGlobalSearchColumnHeader(columns, userId);
            dispatch(modifyGlobalSearchColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };
export const getResetedGlobalSearchColumnHeader = (userId: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(resetGlobalSearchColumnHeaderPending());
        try {
            const response = await api.searchController.resetGlobalSearchColumnHeader(userId);

            dispatch(resetGlobalSearchColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const getSearchDataFromNextDoc = (
    redactionDocumentId: number,
    selectedDoc: number,
    actualPageNumber: number,
): (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        await dispatch(fetchDocumentMetadata(redactionDocumentId, selectedDoc, null, false, true));
        dispatch(getPageNumberByPageId({
            redactionDocumentId,
            documentId: selectedDoc,
            selectedPageId: actualPageNumber,
            getAnnotationStamps: true,
        }));
    };

export const restrictOnFetchDataPageList = (restrictFetchData: boolean): (dispatch: Dispatch) => void =>
    (dispatch: Dispatch): void => {
        dispatch(restrictOnFetchDataForPageList(restrictFetchData));
    };

import {
    GET_PEOPLE_TYPE_BY_DISCLOSURE_PENDING,
    GET_PEOPLE_TYPE_BY_DISCLOSURE_SUCCESS,
    GET_PEOPLE_TYPE_BY_DISCLOSURE_FAILURE,
    GET_SEARCH_PEOPLE_RECORDS_PENDING,
    GET_SEARCH_PEOPLE_RECORDS_SUCCESS,
    GET_SEARCH_PEOPLE_RECORDS_FAILURE,
    GET_ASSIGNED_PEOPLE_RECORDS_PENDING,
    GET_ASSIGNED_PEOPLE_RECORDS_SUCCESS,
    GET_ASSIGNED_PEOPLE_RECORDS_FAILURE,
    CLEAR_SEARCH_RESULT,
} from '../reducers/disclosureContacts/constants';
import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import {
    IDisclosureContacts,
    IPeopleRecords,
    IAssignedContacts, IAssignedContactsByDocIdsResponse, IAssignedContactsByDocId,
} from '../reducers/disclosureContacts/disclosureContacts.model';
import { IError } from '../common.model';
import api from '../../api/reductionApi';
import { ISearchParam } from '../../containers/modalWindowContents/modalSelectContacts/searchTab/searchTab.model';

export const fetchPeopleTypeByDisclosurePending = (): AnyAction => action(GET_PEOPLE_TYPE_BY_DISCLOSURE_PENDING);
export const fetchPeopleTypeByDisclosureSuccess = (contactsTypes: IDisclosureContacts[]): AnyAction =>
    action(GET_PEOPLE_TYPE_BY_DISCLOSURE_SUCCESS, contactsTypes);
export const fetchPeopleTypeByDisclosureFailure = (error: IError): AnyAction =>
    action(GET_PEOPLE_TYPE_BY_DISCLOSURE_FAILURE, error);
export const fetchSearchPeopleRecordsPending = (): AnyAction => action(GET_SEARCH_PEOPLE_RECORDS_PENDING);
export const fetchSearchPeopleRecordsSuccess = (peopleRecords: IPeopleRecords[]): AnyAction =>
    action(GET_SEARCH_PEOPLE_RECORDS_SUCCESS, peopleRecords);
export const fetchSearchPeopleRecordsFailure = (error: IError): AnyAction =>
    action(GET_SEARCH_PEOPLE_RECORDS_FAILURE, error);
export const fetchAssignedPeopleRecordsPending = (): AnyAction => action(GET_ASSIGNED_PEOPLE_RECORDS_PENDING);
export const fetchAssignedPeopleRecordsSuccess = (peopleRecords: IAssignedContacts[]): AnyAction =>
    action(GET_ASSIGNED_PEOPLE_RECORDS_SUCCESS, peopleRecords);
export const fetchAssignedPeopleRecordsFailure = (error: IError): AnyAction =>
    action(GET_ASSIGNED_PEOPLE_RECORDS_FAILURE, error);

export const fetchPeopleTypeByDisclosure = (
    redactionDocumentId: number,
    disclosureId: number,
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchPeopleTypeByDisclosurePending());

        try {
            const response = await api.amandaRequests.getPeopleTypesByDisclosureType(redactionDocumentId, disclosureId);

            dispatch(fetchPeopleTypeByDisclosureSuccess(response));
        } catch (error) {
            dispatch(fetchPeopleTypeByDisclosureFailure(error));
        }
    };

export const fetchSearchPeopleRecords = (
    redactionDocumentId: number,
    disclosureId: number,
    data: ISearchParam,
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchSearchPeopleRecordsPending());

        try {
            const response = await api.amandaRequests.searchPeopleRecords(disclosureId, data);
            const validData = response
                .map((item: IPeopleRecords) => item.nameFirst ? item : {...item, nameFirst: ''});

            dispatch(fetchSearchPeopleRecordsSuccess(validData));
        } catch (error) {
            dispatch(fetchSearchPeopleRecordsFailure(error));
        }
    };

export const fetchAssignedPeopleRecordsByPageId = (
    redactionDocumentId: number,
    pageIds: number[],
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchAssignedPeopleRecordsPending());

        try {
            const queryData = { pageIds: pageIds.join(',') };
            const response = await api.amandaRequests.getAssignedContacts(redactionDocumentId, queryData);

            dispatch(fetchAssignedPeopleRecordsSuccess(response));
        } catch (error) {
            dispatch(fetchAssignedPeopleRecordsFailure(error));
        }
    };

export const fetchAssignedPeopleRecordsByDocumentIds = (
    redactionDocumentId: number,
    documentIds: number[],
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchAssignedPeopleRecordsPending());

        try {
            const response = await api.consultee.getDocumentsConsultees(redactionDocumentId, documentIds);

            const data = response.reduce((
                acc: IAssignedContacts[], item: IAssignedContactsByDocIdsResponse,
            ) => {
                const pages = item.pageConsultees.map((assignedContactsPage: IAssignedContactsByDocId) => ({
                    pageId: assignedContactsPage.pageId,
                    people: assignedContactsPage.consultees,
                }));

                return [...acc, ...pages];
            }, []);

            dispatch(fetchAssignedPeopleRecordsSuccess(data));
        } catch (error) {
            dispatch(fetchAssignedPeopleRecordsFailure(error));
        }
    };

export const clearSearchResult = (): AnyAction => action(CLEAR_SEARCH_RESULT);

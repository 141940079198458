import { AnyAction } from 'redux';
import { IAnnotationState } from './annotation.model';
import { IAnnotation } from '../pageList/pageList.model';
import {
    ADD_ANNOTATION_PENDING,
    ADD_ANNOTATION_SUCCESS,
    ADD_ANNOTATION_FAILURE,
    DELETE_ANNOTATION_FAILURE,
    DELETE_ANNOTATION_SUCCESS,
    DELETE_ANNOTATION_PENDING,
    UPDATE_ANNOTATION_FAILURE,
    UPDATE_ANNOTATION_SUCCESS,
    UPDATE_ANNOTATION_PENDING,
    GET_CURRENT_PAGE_ANNOTATIONS_FAILURE,
    GET_CURRENT_PAGE_ANNOTATIONS_PENDING,
    GET_CURRENT_PAGE_ANNOTATIONS_SUCCESS,
    REMOVE_ANNOTATIONS_PER_PAGE,
    CHANGE_ANNOTATION_TEXT,
    CHANGE_ANNOTATION_COLOR,
    ADD_ANNOTATIONS_TO_DOCUMENTS_FAILURE,
    ADD_ANNOTATIONS_TO_DOCUMENTS_PENDING,
    ADD_ANNOTATIONS_TO_DOCUMENTS_SUCCESS,
    SET_LINKED_ARTICLES_SUCCESS,
    SET_LINKED_ARTICLES_PENDING,
    SET_LINKED_ARTICLES_FAILURE,
    HIGHLIGHT_LINKED_SEVERS,
    SET_AUTO_CORRECT_SEVER,
    SET_RECENT_AUTOCORRECTED_ANNOTATION_ID,
    UNDO_SNAP_ANNOTATION_SUCCESS,
    UNDO_SNAP_ANNOTATION_PENDING,
    SNAP_ANNOTATION_PENDING,
} from './constants';
import { CLEAR_LINKED_ARTICLES, CLEAR_LINKED_ARTICLES_FOR_ALL_ANNOTATIONS } from '../stamps/constants';

export const initialState: IAnnotationState = {
    annotations: [],
    loadingAnnotations: false,
    loadingLinkedArticles: false,
    error: null,
    autoCorrectSever: false,
    recentSnappedAnnotationId: null,
    snapAnnotationPending: false,
};

const annotationReducer = (state: IAnnotationState = initialState, { type, payload }: AnyAction): IAnnotationState => {
    switch (type) {
        case GET_CURRENT_PAGE_ANNOTATIONS_PENDING:
        case DELETE_ANNOTATION_PENDING:
        case UPDATE_ANNOTATION_PENDING:
            return {
                ...state,
                loadingAnnotations: true,
                error: null,
            };
        case GET_CURRENT_PAGE_ANNOTATIONS_SUCCESS:
            return {
                ...state,
                annotations: payload,
                loadingAnnotations: false,
            };
        case ADD_ANNOTATION_PENDING:
        case ADD_ANNOTATIONS_TO_DOCUMENTS_PENDING:
            return {
                ...state,
                annotations: [...state.annotations, ...payload],
                loadingAnnotations: true,
                error: null,
            };
        case ADD_ANNOTATION_SUCCESS:
        case ADD_ANNOTATIONS_TO_DOCUMENTS_SUCCESS:
            return {
                ...state,
                annotations: [...state.annotations.filter((annotation: IAnnotation): boolean => {

                    if(payload.isUpdate) {
                        return !payload.data.find((elem: IAnnotation) => elem.id === annotation.id);
                    }

                    return !!annotation.id;
                }), ...payload.data],
                loadingAnnotations: false,
            };
        case DELETE_ANNOTATION_SUCCESS:
            return {
                ...state,
                loadingAnnotations: false,
                annotations: state.annotations.filter((annotation: IAnnotation) =>
                    !payload.includes(annotation.id),
                ),
            };
        case CHANGE_ANNOTATION_COLOR: {
            return {
                ...state,
                annotations: state.annotations.map((elem: IAnnotation) => elem.id === payload.id ?
                    {
                        ...elem,
                        color: payload.color,
                    }
                    : elem),
            };
        }
        case CHANGE_ANNOTATION_TEXT: {
            return {
                ...state,
                annotations: state.annotations.map((elem: IAnnotation) => elem.id === payload.id ?
                    {
                        ...elem,
                        annotation: payload.text,
                    }
                    : elem),
            };
        }
        case UPDATE_ANNOTATION_SUCCESS:
            return {
                ...state,
                annotations: state.annotations.map((stateAnnotation: IAnnotation) => {
                    const updated = payload.find((annotation: IAnnotation): boolean =>
                        annotation.id === stateAnnotation.id);

                    return updated || stateAnnotation;
                }),
                loadingAnnotations: false,
                error: null,
            };
        case REMOVE_ANNOTATIONS_PER_PAGE:
            return {
                ...state,
                annotations: state.annotations.filter((annotation: IAnnotation): boolean => {
                    if (payload.severs !== payload.highlights) {
                        return annotation.isSever !== payload.severs;
                    }

                    return !(payload.severs && payload.highlights);
                }),
            };
        case ADD_ANNOTATION_FAILURE:
        case DELETE_ANNOTATION_FAILURE:
        case UPDATE_ANNOTATION_FAILURE:
        case GET_CURRENT_PAGE_ANNOTATIONS_FAILURE:
        case ADD_ANNOTATIONS_TO_DOCUMENTS_FAILURE:
            return {
                ...state,
                loadingAnnotations: false,
                error: payload,
            };
        case SET_LINKED_ARTICLES_PENDING:
            return {
                ...state,
                loadingLinkedArticles: true,
            };
        case SET_LINKED_ARTICLES_SUCCESS:
            return {
                ...state,
                loadingLinkedArticles: false,
                annotations: state.annotations.map((annotaion: IAnnotation): IAnnotation => {
                    const linkedAnnotation = payload.id === annotaion.id;

                    return linkedAnnotation ? {
                        ...annotaion,
                        linkedArticles: [...(annotaion.hasOwnProperty('linkedArticles') ?
                            annotaion.linkedArticles && [] : []),
                        ...payload.linkedArticles],
                    } : annotaion;
                }),
            };
        case SET_LINKED_ARTICLES_FAILURE:
            return {
                ...state,
                loadingLinkedArticles: false,
            };
        case HIGHLIGHT_LINKED_SEVERS:
            return {
                ...state,
                annotations: state.annotations.map((annotation: IAnnotation): IAnnotation => {
                    const linkedSever = annotation
                        && !!annotation.linkedArticles
                        && annotation.linkedArticles.find((linkedArticleId: number) => payload === linkedArticleId);

                    return linkedSever
                        ? { ...annotation, stroke: '#00ffff', strokeWidth: 2 }
                        : { ...annotation, stroke: '', strokeWidth: 0 };
                }),
            };
        case CLEAR_LINKED_ARTICLES:
            return {
                ...state,
                annotations: state.annotations.map((annotaion: IAnnotation): IAnnotation => {
                    if(annotaion.linkedArticles && annotaion.linkedArticles.length) {
                        if(annotaion.linkedArticles.includes(payload)) {
                            return {
                                ...annotaion,
                                linkedArticles: annotaion.linkedArticles.filter((stamp: number) => stamp !== payload),
                            };
                        } else {
                            return {
                                ...annotaion,
                            };
                        }
                    } else {
                        return { ...annotaion };
                    }
                }),
            };
        case CLEAR_LINKED_ARTICLES_FOR_ALL_ANNOTATIONS:
            return {
                ...state,
                annotations: state.annotations.map((annotaion: IAnnotation): IAnnotation => {
                    if(annotaion.linkedArticles && annotaion.linkedArticles.length) {
                        return {
                            ...annotaion,
                            linkedArticles: [],
                        };
                    } else {
                        return { ...annotaion };
                    }
                }),
            };
        case SET_AUTO_CORRECT_SEVER:
            return {
                ...state,
                autoCorrectSever: payload,
            };
        case SET_RECENT_AUTOCORRECTED_ANNOTATION_ID:
            return {
                ...state,
                recentSnappedAnnotationId: payload,
            };
        case UNDO_SNAP_ANNOTATION_PENDING:
            return {
                ...state,
                loadingAnnotations: true,
                snapAnnotationPending: true,
                error: null,
            };
        case UNDO_SNAP_ANNOTATION_SUCCESS:
            return {
                ...state,
                annotations: state.annotations.map((stateAnnotation: IAnnotation) => {
                    const updated = payload.find((annotation: IAnnotation): boolean =>
                        annotation.id === stateAnnotation.id);

                    return updated || stateAnnotation;
                }),
                loadingAnnotations: false,
                snapAnnotationPending: false,
                error: null,
            };
        case SNAP_ANNOTATION_PENDING:
            return {
                ...state,
                snapAnnotationPending: payload,
            };
        default:
            return state;
    }
};

export default annotationReducer;

import * as React from 'react';
import './localFiles.styles.scss';
import {
    CONVERSION_OPTIONS,
    ENABLE_OCR,
    AUTO_DESKEW,
    REDACTION_OPTIONS,
    ASSIGN_TO_DISCLOSURE,
    ASSIGN_TO_STACK,
    ADD_FILES_AS_ATTACHMENT,
    INCLUDE_ANNOTATIONS,
    INCLUDE_DISCLOSURES,
    IMPORT,
    ASSIGN_TO_DISCLOSURE_ID,
    INCLUDE_ANNOTATIONS_PARAM,
    INCLUDE_DISCLOSURES_PARAM,
    STACK_ID,
} from './constants';

import Table from '../../../../components/table/Table';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { IProps, ILocalFilesState } from './localFiles.model';
import { IState } from '../../../../redux/store';
import { IFile } from '../modalWindowImport.model';
import { SelectMaterialBuilder } from '../../../../components/materialUiForms/materialUiFormBuilder';
import LinearProgress from '@mui/material/LinearProgress';
import { connect } from 'react-redux';
import { getStackList } from '../../../../redux/selectors/stackList';
import { ISelectOptions } from '../../../../components/materialUiForms/marerialUiForms.model';
import { IStackOptions } from '../../../../redux/reducers/stackList/stackList.model';
import { emptySelectOption } from '../../../../components/materialUiForms/materialUiForms';
import { getRedactionMode } from '../../../../redux/selectors/localStorage';
import { MODE_CONTRIBUTE } from '../../../../redux/reducers/localStorage/constant';

const className = {
    DOC_NAME_TABLE: 'docName-table',
    DOC_NAME_TABLE_HEADER: 'docName-table-header',
    DOC_NAME_TABLE_HEADER_EMPTY: 'docName-table-header-empty',
    DOC_NAME_TABLE_HEADER_NAME: 'docName-table-header-name',
    DOC_NAME_TABLE_HEADER_SIZE: 'docName-table-header-size',
    DOC_NAME_TABLE_BODY: 'docName-table-body',
    DOC_NAME_TABLE_ROW: 'docName-table-row',
    DOC_NAME_TABLE_ROW_NAME: 'docName-table-row-name',
    DOC_NAME_TABLE_ROW_SIZE: 'docName-table-row-size',

    MAIN_TEXT: 'text-main',
    FORM: 'form',
    SELECTS: 'native-selects',
    CHECKBOX: 'check-box-styles',
    VERTICAL_LINE: 'vertical-line',
    OPTIONS_CONVERSION: 'options-conversion',
    CHECKBOX_TEXT: 'checkBox-text',
    OPTIONS_REDACTION: 'options-redaction',
    DOC_NAME: 'docName',
    WIDTH_LINE: 'width-line',
    WIDTH_OPTIONS_CARDS: 'width-options',
};

const documentsChecked = (data: IFile[]): boolean => data.every((el: IFile) => !el.isChecked);

class LocalFiles extends React.Component<IProps, ILocalFilesState> {
    public render(): JSX.Element {
        const {
            isDocuments,
            columns,
            isFormInvalid,
            isLoading,
            data,
            additionalParams,
            handleAdditionalParam,
            handleSortTable,
            handleImport,
            sortParams,
            isAutoDeskewVisible,
            isInsertPage,
            stackList,
            labels,
            langRule,
            mode,
            pageName
        } = this.props;
        const createOptions = (options: IStackOptions[]): ISelectOptions[] => {
            const emptyStack =  { label: '', value: '' };
            const formattedOptions = options.map((type: IStackOptions): ISelectOptions =>
                ({ label: type.name, value: `${type.id.toString()}` }));

            if (mode === MODE_CONTRIBUTE) {
                return [...formattedOptions]
            } else {
                return [
                    emptyStack,
                    ...formattedOptions,
                ];
            }
        };

        return (
            <div className='add-cards'>
                <div className='container'>
                    <div className={className.DOC_NAME}>
                        <Table
                            data={data}
                            columns={columns}
                            userPreferenceTable ={true}
                            storeSortSession = {true}
                            handleSortSession={handleSortTable}
                            sortParams={sortParams}
                            pageName={pageName}
                        />
                    </div>
                    <div className={className.WIDTH_LINE}>
                        <hr className={className.VERTICAL_LINE} />
                    </div>
                    <div className={className.WIDTH_OPTIONS_CARDS}>
                        <div className='options'>
                            <div className={className.OPTIONS_CONVERSION}>
                                <div className={className.MAIN_TEXT}>{labels.conversionOptionsLabel[langRule]}</div>
                                <div className={className.CHECKBOX_TEXT}>
                                    <Checkbox
                                        color='primary'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>,
                                                   checked: boolean): void =>
                                            handleAdditionalParam('ocrRequired', checked)}
                                    />
                                    <div className='text-doc'>{labels.applyOCRlabel[langRule]}</div>
                                </div>
                                {
                                    isAutoDeskewVisible &&
                                    (<div className='deskew checkBox-text'>
                                        <Checkbox
                                            id='autoDeskew'
                                            color='primary'
                                            disabled={!additionalParams.ocrRequired}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>,
                                                       checked: boolean): void =>
                                                handleAdditionalParam('autoDeskew', checked)}
                                        />
                                        <div className='text-doc'>{labels.autoDeskewLabel[langRule]}</div>
                                    </div>)
                                }
                            </div>
                            <form>
                                <div className={className.OPTIONS_REDACTION}>
                                    <div className={className.MAIN_TEXT}>{labels.redactionOptionsLabel[langRule]}</div>
                                    <div className={className.FORM}>
                                        {
                                            !isInsertPage &&
                                            <SelectMaterialBuilder
                                              value={additionalParams.stackId.toString()}
                                              paramName={STACK_ID}
                                              label={labels.assignToStackLabel[langRule]}
                                              options={createOptions(stackList)}
                                              handleSelectChange={(label: string, value: string): void =>
                                                  handleAdditionalParam(STACK_ID,
                                                      Number(value))}
                                              variant='standard'
                                            />
                                        }

                                    </div>
                                    <div className='check-box'>
                                        {/*need for the future*/}
                                        {/*<div className={className.CHECKBOX}>*/}
                                            {/*<Checkbox*/}
                                                {/*color='primary'*/}
                                            {/*/>*/}
                                            {/*<div>{ADD_FILES_AS_ATTACHMENT}</div>*/}
                                        {/*</div>*/}
                                        {
                                            isDocuments &&
                                            <React.Fragment>
                                              <div className={className.CHECKBOX}>
                                                <Checkbox
                                                  color='primary'
                                                  onChange={(event: React.ChangeEvent<HTMLInputElement>,
                                                             checked: boolean): void =>
                                                      handleAdditionalParam(INCLUDE_ANNOTATIONS_PARAM,
                                                          checked)
                                                  }
                                                />
                                                <div>{labels.includeAnnotationsLabel[langRule]}</div>
                                              </div>
                                              <div className={className.CHECKBOX}>
                                                <Checkbox
                                                  color='primary'
                                                  onChange={(event: React.ChangeEvent<HTMLInputElement>,
                                                             checked: boolean): void =>
                                                      handleAdditionalParam(INCLUDE_DISCLOSURES_PARAM,
                                                          checked)}
                                                />
                                                <div>{labels.includeDisclosureLabel[langRule]}</div>
                                              </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='button'>
                    <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        className='button-import primary'
                        onClick={handleImport}
                        disabled={documentsChecked(data) || isFormInvalid || isLoading}
                    >
                        {labels.importLabel[langRule]}
                    </Button>
                </div>
                <div className='linear-progress'>
                    {
                        isLoading
                        && <LinearProgress />
                    }
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state: IState): ILocalFilesState => ({
    stackList: getStackList(state),
    mode: getRedactionMode(state)
});

export default connect(
    mapStateToProps,
)(LocalFiles);
export const FETCH_INDEX_METADATA_DESCRIPTIONS_PENDING = 'redaction/FETCH_INDEX_METADATA_DESCRIPTIONS_PENDING';
export const FETCH_INDEX_METADATA_DESCRIPTIONS_FAIL = 'redaction/FETCH_INDEX_METADATA_DESCRIPTIONS_FAIL';
export const FETCH_INDEX_METADATA_DESCRIPTIONS_SUCCESS = 'redaction/FETCH_INDEX_METADATA_DESCRIPTIONS_SUCCESS';
export const CLEAR_INDEX_METADATA_DESCRIPTIONS = 'redaction/CLEAR_INDEX_METADATA_DESCRIPTIONS';
export const ADD_INDEX_METADATA_DESCRIPTIONS = 'redaction/ADD_INDEX_METADATA_DESCRIPTIONS';
export const REMOVE_INDEX_METADATA_DESCRIPTIONS = 'redaction/REMOVE_INDEX_METADATA_DESCRIPTIONS';
export const EDIT_INDEX_METADATA_DESCRIPTIONS = 'redaction/EDIT_INDEX_METADATA_DESCRIPTIONS';
export const EXPORT_INDEX_METADATA_SUCCESS = 'redaction/EXPORT_INDEX_METADATA_SUCCESS';
export const EXPORT_INDEX_METADATA_PENDING = 'redaction/EXPORT_INDEX_METADATA_PENDING';
export const EXPORT_INDEX_METADATA_FAIL = 'redaction/EXPORT_INDEX_METADATA_FAIL';

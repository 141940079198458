import * as React from 'react';
import './modalSelectContacts.styles.scss';
import Button from '@mui/material/Button';
import {
    SEARCH,
} from '../../../constants/selectContacts.contstants';
import SearchTab from './searchTab/SearchTab';
import { uniqBy, isEqual } from 'lodash';
import {
    fetchPeopleTypeByDisclosure,
    fetchSearchPeopleRecords,
    fetchAssignedPeopleRecordsByPageId,
    clearSearchResult, fetchAssignedPeopleRecordsByDocumentIds,
} from '../../../redux/actions/modalDisclosureContacts';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import {
    getModalDisclosureContactsLoading,
    getSearchPeopleRecordsLoading,
    getSearchPeopleRecords,
    getPeopleTypeByDisclosure,
    getAssignedPeopleRecords, getAssignedPeopleRecordsPending,
} from '../../../redux/selectors/modalDisclosureContacts';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import { connect } from 'react-redux';
import {
    IModalSelectContactsDispatchProps,
    IProps,
    IState,
    IModalSelectContactsStateProps,
} from './modalSelectContacts.model';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { IState as StoreState } from '../../../redux/store';
import { ISearchParam } from './searchTab/searchTab.model';
import AssignedContacts from './assignedContacts/AssignedContacts';
import {
    IAssignedContacts, IAssignedContactsDto,
    IPeopleRecords,
} from '../../../redux/reducers/disclosureContacts/disclosureContacts.model';
import { changePageDisclosureWithAdditionalData } from '../../../redux/actions/pageList';
import { IModalSpecificProps } from '../../../redux/reducers/modal/modal.model';
import { replaceDocumentsDisclosureWithAdditionalData } from '../../../redux/actions/disclosureTypes';
import { Spinner } from '../../../components/spinner/Spinner';
import { initialLabel } from '../../../constants/localization.constants';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../localization/localizationData';
import { getConsulteesLabelsByKey } from '../../IndexMetadataConsultees/IndexMetadataConsultees';

export class ModalSelectContacts extends React.Component<IProps, IState> {
    public static getDerivedStateFromProps(nextProps: any, prevState: IState): IState {
        if(nextProps.assignedPeopleRecords &&
            !isEqual(nextProps.assignedPeopleRecords,prevState.initialAssignedContacts)) {
            return {
                assignedContacts: nextProps.assignedPeopleRecords,
                initialAssignedContacts: nextProps.assignedPeopleRecords,
                saveInProgress: nextProps.saveInProgress,
            };
        }

        return null;
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            assignedContacts: [],
            initialAssignedContacts: [],
            saveInProgress: false,
        };
    }

    public componentWillUnmount(): void {
        this.props.clearSearchResult();
    }

    public componentDidMount(): void {
        const {
            redactionDocumentId,
            modalSpecificProps: {disclosureTypeId, pageIds, documentId},
        } = this.props;

        this.props.fetchContactsTypesByDisclosure(redactionDocumentId, disclosureTypeId);
        !pageIds ?
            this.props.fetchAssignedPeopleRecordsByDocumentIds(redactionDocumentId, documentId) :
            this.props.fetchAssignedPeopleRecordsByPageId(redactionDocumentId, pageIds);
    }

    public render(): JSX.Element {
        const {
            handleCloseModalWindow,
            peopleTypeByDisclosure,
            searchPeopleRecords,
            searchPeopleRecordsLoading,
            assignedPeopleRecordsPending,
            redactionLanguage,
            modifiedLabels,
        } = this.props;
        const { assignedContacts, saveInProgress } = this.state;
        const langRule = changeLang(redactionLanguage);
        const labels = {
            cancelLabel: initialLabel,
            saveLabel: initialLabel,
            assignLabel: initialLabel,
            searchResultsTitleLabel: initialLabel,
            organisationNameHeaderLabel: initialLabel,
            firstNameHeaderLabel: initialLabel,
            lastNameHeaderLabel: initialLabel,
            removeLabel: initialLabel,
            peopleTypeHeaderLabel: initialLabel,
            actionHeaderLabel: initialLabel,
            pesopleRSNHeaderLabel: initialLabel,
            resetLabel: initialLabel,
            searchLabel: initialLabel,
            removeAllLabel: initialLabel,
            assignedContactsTitleLabel: initialLabel,
            noPersonaddedLabel: initialLabel,
            loadingLabel: initialLabel,
            emptyContactsLabel: initialLabel,
        };
        const uniqAssignedPeopleRecords = uniqBy(assignedContacts
            .reduce((acc: IPeopleRecords[], item: IAssignedContacts): IPeopleRecords[] => {
                return [...acc, ...item.people].sort((a: IPeopleRecords, b: IPeopleRecords) =>
                    (a.nameFirst || '').localeCompare(b.nameFirst || ''));
            }, []), 'peopleRsn')
        ;

        resourceBundle.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        return (
            <div className='select-contacts'>
                <div className='cards'>
                    <Spinner active={saveInProgress} />
                    <div className='left-side'>
                        <SearchTab
                            title={SEARCH}
                            isValid={true}
                            peopleTypeByDisclosure={uniqBy(peopleTypeByDisclosure, 'peopleDesc')}
                            searchPeopleRecords={searchPeopleRecords}
                            searchPeopleRecordsLoading={searchPeopleRecordsLoading}
                            onSearch={(data: ISearchParam): void => this.onSearch(data)}
                            handleAssignContact={
                                (contact: IPeopleRecords): void => this.handleAssignContact(contact)}
                            labels={labels}
                            language={redactionLanguage}
                        />
                    </div>
                    <div className='right-side'>
                        <AssignedContacts
                            removeAssignedContact={(id: number): void => this.handleRemoveAssignedContact(id)}
                            removeAllAssignedContact={this.handleRemoveAllAssignedContact}
                            assignContacts={uniqAssignedPeopleRecords}
                            assignedPeopleRecordsPending={assignedPeopleRecordsPending}
                            labels={labels}
                            langRule={langRule}
                        />
                    </div>
                </div>
                <div className='button'>
                    <Button
                        size='large'
                        variant='outlined'
                        className='outlined'
                        onClick={handleCloseModalWindow}
                    >
                        {labels.cancelLabel[langRule]}
                    </Button>
                    <Button
                        size='large'
                        variant='contained'
                        color='primary'
                        className='buttons-save primary'
                        onClick={(): void => this.getShortAssignedContact()}
                        disabled={!uniqAssignedPeopleRecords.length || saveInProgress}
                    >
                        {labels.saveLabel[langRule]}
                    </Button>
                </div>
            </div>
        );
    }

    private handleAssignContact = (contact: IPeopleRecords): void => {
        const filteredAssignedConcats = this.removeAssignedContact(contact.peopleRsn);
        const assignedContacts = filteredAssignedConcats.map((item: IAssignedContacts): IAssignedContacts => {
            const people = [...item.people, contact];

            return {
                pageId: item.pageId,
                people,
            };
        });

        this.setState({
            assignedContacts,
        });
    }

    private removeAssignedContact = (id: number): IAssignedContacts[] => {
        const { assignedContacts } = this.state;

        return assignedContacts.map((item: IAssignedContacts): IAssignedContacts => {
            const people = item.people.filter((i: IPeopleRecords) => i.peopleRsn !== id);

            return {
                pageId: item.pageId,
                people,
            };
        });
    }

    private handleRemoveAssignedContact = (id: number): void => {
        const newAssignedConcats = this.removeAssignedContact(id);

        this.setState({
            assignedContacts: newAssignedConcats,
        });
    }

    private handleRemoveAllAssignedContact = (): void => {
        const { assignedContacts } = this.state;
        const emptyAssignedConcats= assignedContacts.map((item: IAssignedContacts): IAssignedContacts => {
            return {
                pageId: item.pageId,
                people: [],
            };
        });

        this.setState({
            assignedContacts: emptyAssignedConcats,
        });
    }

    private getShortAssignedContact = (): void => {
        const { assignedContacts } = this.state;
        const { modalSpecificProps } = this.props;

        this.setState({saveInProgress: true});

        const contacts = assignedContacts.map((item: IAssignedContacts): IAssignedContactsDto => {
            const contactIds = item.people.map(({peopleRsn}: IPeopleRecords) => peopleRsn);

            return {
                pageId: item.pageId,
                contactIds,
            };
        });

        if (modalSpecificProps.needReplace) {  // document level
            const { fromId, disclosureTypeId, exemptionIds } = modalSpecificProps;

            this.props.replaceDocumentsDisclosureWithAdditionalData(fromId, disclosureTypeId, contacts, exemptionIds);

            return;
        }

        this.props.changePageDisclosureWithAdditionalData(// document and page level
                this.props.modalSpecificProps.selectedStamps || [],
                {
                    ...this.props.modalSpecificProps,
                    documentId: this.props.modalSpecificProps.documentId,
                },
                contacts,
            );
    }

    private onSearch(data: ISearchParam): void {
        const {redactionDocumentId, modalSpecificProps: {disclosureTypeId}} = this.props;

        if (data.organizationName) {
            data.organizationName = (data.organizationName as any).value;
        }

        this.props.fetchSearchPeopleRecords(redactionDocumentId, disclosureTypeId, data);
    }
}

const mapStateToProps = (state: StoreState): IModalSelectContactsStateProps => ({
    loading: getModalDisclosureContactsLoading(state),
    peopleTypeByDisclosure: getPeopleTypeByDisclosure(state),
    redactionDocumentId: getRedactionDocumentId(state),
    searchPeopleRecords: getSearchPeopleRecords(state),
    searchPeopleRecordsLoading: getSearchPeopleRecordsLoading(state),
    assignedPeopleRecords: getAssignedPeopleRecords(state),
    assignedPeopleRecordsPending: getAssignedPeopleRecordsPending(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLanguage: getRedactionLanguage(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IModalSelectContactsDispatchProps, AnyAction>,
): IModalSelectContactsDispatchProps => ({
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    fetchContactsTypesByDisclosure: (redactionDocumentId: number, disclosureId: number): void => {
        dispatch(fetchPeopleTypeByDisclosure(redactionDocumentId, disclosureId));
    },
    fetchSearchPeopleRecords: (redactionDocumentId: number, disclosureId: number, data: any): void => {
        dispatch(fetchSearchPeopleRecords(redactionDocumentId, disclosureId, data));
    },
    fetchAssignedPeopleRecordsByPageId: (redactionDocumentId: number, documentIds: number[]): void => {
        dispatch(fetchAssignedPeopleRecordsByPageId(redactionDocumentId, documentIds));
    },
    fetchAssignedPeopleRecordsByDocumentIds: (redactionDocumentId: number, documentIds: number[]): void => {
        dispatch(fetchAssignedPeopleRecordsByDocumentIds(redactionDocumentId, documentIds));
    },
    changePageDisclosureWithAdditionalData:
        (selectedStamps: number[], modalSpecificProps: IModalSpecificProps, contacts: IAssignedContactsDto[]): void => {
            dispatch(changePageDisclosureWithAdditionalData(selectedStamps, modalSpecificProps, contacts));
        },
    clearSearchResult: (): void => {
        dispatch(clearSearchResult());
    },
    replaceDocumentsDisclosureWithAdditionalData: (
        fromId: number, toId: number, pageContacts: IAssignedContactsDto[], exemptionIds: number[]): void => {
        dispatch(replaceDocumentsDisclosureWithAdditionalData(fromId, toId, pageContacts, exemptionIds));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectContacts);

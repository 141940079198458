import * as React from 'react';
import { useSelector } from '../../../../node_modules/react-redux';
import { getModalDuplicatesRefData, getModalDuplicatesRefPending } from '../../../redux/selectors/duplicates';
import { Spinner } from '../../../components/spinner/Spinner';
import { Scrollbars } from 'rc-scrollbars';
import Table from '../../../components/table/Table';
import * as constants from '../../../constants/duplicates.constants';

import './ModalDuplicateReferences.scss';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { changeLang } from '../../../redux/actions/localization';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../../containers/localization/localizationData';
import { IHeader } from '../../../constants/leftSidebar.config';

const getDuplicateReferencesLabelsByKey = (key: string): string => {
    switch (key) {
        case 'DUPLICATE_REFENRENCES_MODAL_DOCUMENT_DISCLOSURE':
            return 'documentDisclosure';
        case 'DUPLICATE_REFENRENCES_MODAL_PAGE_DISCLOSURE':
            return 'pageDisclosure';
        case 'DUPLICATE_REFENRENCES_MODAL_PAGES':
            return 'pagesLabel';
        case 'DUPLICATE_REFENRENCES_MODAL_DOCUMENT':
            return 'documentLabel';
        case 'DUPLICATE_REFENRENCES_MODAL_PAGE_NUMBER':
            return 'pageNumberLabel';
        default: return '';
    }
};
const labels = {
    documentDisclosure: initialLabel,
    pageDisclosure: initialLabel,
    documentLabel: initialLabel,
    pageNumberLabel: initialLabel,
    pagesLabel: initialLabel,
};

const ModalDuplicateReferences = (): JSX.Element => {
    const duplicateRefData = useSelector(getModalDuplicatesRefData);
    const duplicateRefLoading = useSelector(getModalDuplicatesRefPending);
    const redactionLang = useSelector(getRedactionLanguage);
    const modifiedLabels = useSelector(getModifiedLabels);
    const langRule = changeLang(redactionLang);

    const DUPLICATE_REFERENCES_HEADERS: IHeader[] = [
        {
            accessorKey: 'documentName',
            id: 'documentName',
            header: labels.documentLabel[langRule],
            minResizeWidth: 1,
            show: true,
        }, {
            accessorKey: 'actualPageNumber',
            id: 'actualPageNumber',
            header: labels.pageNumberLabel[langRule],
            minResizeWidth: 1,
            show: true,
        },
    ];

    resourceBundle.map((resource: any) => {
        if (getDuplicateReferencesLabelsByKey(resource.resourceKey)) {
            labels[getDuplicateReferencesLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getDuplicateReferencesLabelsByKey(resource.resourceKey)) {
            labels[getDuplicateReferencesLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    if (duplicateRefLoading) {
        return <Spinner active={duplicateRefLoading} />;
    } else {
        const {
            documentDisclosureName,
            pageDisclosureName,
            documentName,
            duplicateDocuments,
            masterDocuments,
            pageCount,
        } = duplicateRefData;

        const data = masterDocuments && masterDocuments.length ? masterDocuments : duplicateDocuments;

        return (
            <Scrollbars autoHide={true}>
                <div className='modal-references'>
                    <div className='duplicate-info'>
                        <span className='doc-name'>{documentName}</span>
                        <span className='doc-pages'>{`(${pageCount} ${labels.pagesLabel[langRule]})`}</span>
                    </div>
                    <div className='duplicate-info'>
                        <span>{labels.documentDisclosure[langRule]}</span>
                        <span>{documentDisclosureName}</span>
                    </div>
                    <div className='duplicate-info'>
                        <span>{labels.pageDisclosure[langRule]}</span>
                        <span>{pageDisclosureName}</span>
                    </div>
                    <Table
                        data={data}
                        columns={DUPLICATE_REFERENCES_HEADERS}
                    />
                </div>
            </Scrollbars>
        );
    }
};

export default ModalDuplicateReferences;

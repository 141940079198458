import {
    GET_REQUEST_RESULTS_PENDING,
    GET_REQUEST_RESULTS_SUCCESS,
    GET_REQUEST_RESULTS_FAILURE,
    CLEAR_SEARCH_RESULT,
    GET_OFFICERS_PENDING,
    GET_OFFICERS_SUCCESS,
    GET_OFFICERS_FAILURE,
} from './constants';
import { IRequestSearchState } from './requestSearch.model';
import { AnyAction } from 'redux';

const initialState: IRequestSearchState = {
    requestResultSearch: [],
    officers: [],
    officersPending: false,
    requestResultSearchPending: false,
    error: null,
};

const requestSearch = (
    state: IRequestSearchState = initialState,
    {type, payload}: AnyAction,
): IRequestSearchState => {
    switch (type) {
        case GET_REQUEST_RESULTS_PENDING:
            return {
                ...state,
                requestResultSearchPending: true,
                error: null,
            };
        case GET_REQUEST_RESULTS_SUCCESS:
            return{
                ...state,
                requestResultSearch: payload,
                requestResultSearchPending: false,
            };
        case GET_REQUEST_RESULTS_FAILURE:
            return{
                ...state,
                error: payload,
                requestResultSearchPending: false,
            };
        case GET_OFFICERS_PENDING:
            return {
                ...state,
                officersPending: true,
                error: null,
            };
        case GET_OFFICERS_SUCCESS:
            return{
                ...state,
                officers: payload,
                officersPending: false,
            };
        case GET_OFFICERS_FAILURE:
            return{
                ...state,
                error: payload,
                officersPending: false,
            };
        case CLEAR_SEARCH_RESULT:
            return {
                ...state,
                requestResultSearch: [],
            };
        default:
            return state;
    }
};

export default requestSearch;

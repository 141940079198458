import {
    OPEN_CONTEXTMENU,
    CLOSE_CONTEXTMENU,
    SET_REDACTOR_CONTEXT_MENU,
} from './constants';
import { AnyAction } from 'redux';
import { IContextMenu, IContextMenuStore } from './contextMenu.model';
import { contextMenuList } from '../../../constants/contextmenu/context.menu.config';
import { REDACTOR_CONTEXT_MENU } from '../../../constants/contextmenu/context.menu.constants';

const initialState: IContextMenuStore = {
    contextMenuType: '',
    contextMenuProps: {},
    contextMenuList,
};

const contextMenuReducer = (state: IContextMenuStore = initialState, {type, payload}: AnyAction): IContextMenuStore => {
    switch (type) {
        case OPEN_CONTEXTMENU:
            return {
                ...state,
                contextMenuType: payload.contextType,
                contextMenuProps: payload.contextProps,
            };
        case CLOSE_CONTEXTMENU:
            return {
                ...state,
                contextMenuType: '',
                contextMenuProps: {},
            };
        case SET_REDACTOR_CONTEXT_MENU:
            return {
                ...state,
                contextMenuType: '',
                contextMenuProps: {},
                contextMenuList: state.contextMenuList.map((menu: IContextMenu) => {
                    return menu.id === REDACTOR_CONTEXT_MENU ?
                        {
                            ...menu,
                            menu: [...payload],
                        }
                        : menu;
                }),
            };
        default:
            return state;
    }
};

export default contextMenuReducer;

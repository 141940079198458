import * as React from 'react';
import { connect } from 'react-redux';
import SimpleTabs from '../../../../components/simpleTabs/SimpleTabs';
import * as constants from '../../../../constants/export.contstants';
import {
    ANNOTATION_CONTROL,
    CONSULT,
    CONTENT_OPTIONS,
    EXPORT_DESTINATION,
    PAGINATION_OPTIONS,
    PAGINATION_TYPE_OFF_PARAM,
    PRESETS_PARAM, SELECT_PRESET,
} from '../../../../constants/export.contstants';
import ContentOptions from '../contentOption/ContentOption';
import { IContentOptionChange } from '../contentOption/contentOption.model';
import AnnotationControlTab from '../annotationControl/AnnotationControlTab';
import ExportDestination, { IDestinationOptionChange } from '../exportDestination/ExportDestination';
import PaginationOptions, { IPaginationOptionChange } from '../paginationOptions/PaginationOptions';
import Consult from '../consult/Consult';

import {
    getAnnotationControlsForm,
    getExportContentOptionsForm,
    getExportDestinationOptionsForm,
    getExportDocumentsLoading,
    getExportDocumentsSelector,
    getExportPaginationOptionsForm,
} from '../../../../redux/selectors/modalWindowExport';
import { getHighlightTypesList, getSeverTypesList } from '../../../../redux/selectors/annotationTypes';
import {
    handleExportDestinationOptions,
    handleOptions,
    handlePaginationOptions,
    removeAnnotationOption,
    setAnnotationOption,
    setExportFilters,
    updateExportFilters,
    setExportOptionsFromPreset, clearPresetOptions,
} from '../../../../redux/actions/modalWindowExport';
import {
    getFolderAttachmentTypes,
    getFolderProcesses,
    getFolderProcessesLoading,
} from '../../../../redux/selectors/amandaContent';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { IActionProps, IProps, IState, IStateProps } from './modalWindoeExportTabs.model';
import { IState as StoreState } from '../../../../redux/store';
import { getRedactionDocumentId } from '../../../../redux/selectors/initialization';
import { IContentOptions } from '../../../../redux/reducers/modalWindowExport/modalWindowExport.model';
import { IExportFilters, ISelectedPagesData } from '../modalWindowExport.model';
import { getPrinters } from '../../../../redux/selectors/printer';
import { fetchPrinters } from '../../../../redux/actions/printer';
import { getLastUsedPrinter, getRedactionLanguage } from '../../../../redux/selectors/localStorage';
import { IPrinter } from '../../../../redux/reducers/printer/printer.model';
import { getPackagePresets } from '../../../../redux/selectors/modalPackagePresets';
import { fetchAllPresets } from '../../../../redux/actions/modalPackagePresets';
import { PRESET_NAME_2_PARAM, PRESET_NAME_PARAM } from '../../../../constants/packagePresets.contants';
import { IExportPreset } from '../../modalPackagePresets/modalPackagePresets.model';
import { ISelectOptions } from '../../../../components/materialUiForms/marerialUiForms.model';
import {
    hasCurrentReductionDefaultWatermark,
} from '../../../../redux/selectors/modalAssociateWatermark';
import { fetchWatermarksSettings } from '../../../../redux/actions/modalAssociateWatermarkToRequestType';
import { changeLang } from '../../../../redux/actions/localization';
import { isEqual } from 'lodash';

class ModalWindowExportTabs extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { presetNames: [] };
    }

    public componentDidMount(): void {
        this.props.fetchPrinters();
        this.props.fetchAllPresets();
        this.props.fetchAllWatermarksSettings();
    }

    public componentDidUpdate(prevProps: IProps): void {
        const {
            exportDestinationOptions,
            paginationOptions,
            lastUsedPrinter,
            printers,
            presets,
            contentOptionsData,
            hasDefaultWatermark,
        } = this.props;
        const isPrinter = exportDestinationOptions.exportDestinationOption === constants.PRINTER_PARAM;
        const isPrinterSelected = exportDestinationOptions[constants.PRINTER_CODE];
        const isTraySelected = exportDestinationOptions[constants.PRINTER_TRAY_PARAM];
        const isPrinterValid = !!isPrinter && !!isPrinterSelected && !!isTraySelected;
        const isInvalid = contentOptionsData.originalsOnly ? false :
            exportDestinationOptions[constants.EXPORT_DESTINATION_PARAM] === constants.FOLDER_PROCESS_PARAM &&
            !exportDestinationOptions[constants.FOLDER_PARAM] || !isPrinterValid;
        const isInvalidPagination =
            paginationOptions[constants.PAGINATION_MODE_PARAM] === constants.PAGINATION_TYPE_ADVANCED_PARAM &&
            (!!paginationOptions[constants.STARTING_NUMBER_PARAM] && !paginationOptions[constants.INCREMENT_BY_PARAM] ||
                !!paginationOptions[constants.INCREMENT_BY_PARAM]
                && !paginationOptions[constants.STARTING_NUMBER_PARAM]);
        const isProcessAttachment = exportDestinationOptions.exportDestinationOption === constants.FOLDER_PROCESS_PARAM;
        const isProcessAttachmentValid = !!exportDestinationOptions[constants.FOLDER_PARAM];

        const isWatermarkValid = contentOptionsData[constants.SHOW_WATERMARKS_PARAM] && !hasDefaultWatermark;

        if (!isEqual(prevProps.exportDestinationOptions, exportDestinationOptions) ||
            !isEqual(prevProps.paginationOptions, paginationOptions)
            || !isEqual(prevProps.contentOptionsData, contentOptionsData)) {
            this.props.onChangeValidationStatus(!isInvalid || !isInvalidPagination && !isWatermarkValid);
        }

        if (isPrinter && !isPrinterValid) {
            this.props.onChangeValidationStatus(isPrinterValid);
        }

        if (isProcessAttachment && !isProcessAttachmentValid) {
            this.props.onChangeValidationStatus(isProcessAttachmentValid);
        }

        if (lastUsedPrinter && isPrinter && !isPrinterSelected) {
            const printer = printers.find((availablePrinter: IPrinter): boolean => {
                return availablePrinter.printerCode === Number(lastUsedPrinter.printerCode);
            });

            if (printer) {
                this.handleDestinationOptionsChange({
                    [constants.PRINTER_CODE]: lastUsedPrinter.printerCode,
                    [constants.PRINTER_TRAY_PARAM]: lastUsedPrinter.trayCode,
                });
            }
        }

        if (presets.length !== 0 && prevProps.presets !== presets) {
            this.formatPresets();
        }

        if (prevProps.contentOptionsData[PRESETS_PARAM] !== this.props.contentOptionsData[PRESETS_PARAM]) {
            const filtered = this.props.presets.filter((preset: IExportPreset) => {
                return preset.id === Number(this.props.contentOptionsData[PRESETS_PARAM]);
            });

            if (this.props.contentOptionsData[PRESETS_PARAM] === SELECT_PRESET) {
                this.props.clearPresetOptions();
            } else {
                this.props.setExportOptionsFromPreset(filtered);
            }

            this.props.updateExportFilterParams();
        }
    }

    public render(): JSX.Element {
        const {
            contentOptionsData: { includePagesWithContact, originalsOnly },
            exportDocumentsList,
            exportDocumentsLoading,
            folderProcesses,
            folderProcessesLoading,
            setExportFiltersParams,
            updateExportFilterParams,
            hasDefaultWatermark,
            folderAttachmentTypes,
            redactionLang,
            parentLabels,
        } = this.props;
        const { presetNames } = this.state;
        const langRule = changeLang(redactionLang);
        const defaultPreset = [{
            value: SELECT_PRESET,
            label: parentLabels.selectPresetLabel[langRule],
        }];

        return (
            <div className='options'>
                <SimpleTabs>
                    <ContentOptions
                        isValid={true}
                        hasDefaultWatermark={hasDefaultWatermark}
                        title={parentLabels.contentOptionsLabel[langRule]}
                        presetNames={[...defaultPreset, ...presetNames]}
                        contentOptionsData={this.props.contentOptionsData}
                        handleOptionsChange={(data: IContentOptionChange, shouldUpdateDocList: boolean): void =>
                            this.handleOptionsChange(data, shouldUpdateDocList)}
                        annotationControlsIdList={this.props.annotationControls}
                        severList={this.props.severList}
                    />
                    <AnnotationControlTab
                        title={parentLabels.annotationControlLabel[langRule]}
                        isValid={true}
                        disabled={originalsOnly}
                        highLightList={this.props.highLightList}
                        severList={this.props.severList}
                        annotationControlsIdList={this.props.annotationControls}
                        handleChangeAnnotationControl={
                            (id: number, isChecked: boolean): void => this.handleChangeAnnotationControl(id, isChecked)
                        }
                    />
                    <PaginationOptions
                        title={parentLabels.paginationOptionsLabel[langRule]}
                        isValid={true}
                        paginationOptions={this.props.paginationOptions}
                        handlePaginationOptionsChange={this.handlePaginationOptionsChange}
                        isExportModal={true}
                    />
                    {
                        includePagesWithContact && (
                            <Consult
                                title={parentLabels.consultTitleLabel[langRule]}
                                isValid={true}
                                consultData={exportDocumentsList}
                                isLoading={exportDocumentsLoading}
                                updateExportFilters={updateExportFilterParams}
                                setExportFilters={setExportFiltersParams}
                                parentLabel={parentLabels}
                                language={redactionLang}
                            />
                        )
                    }
                    <ExportDestination
                        title={parentLabels.exportDestinationLabel[langRule]}
                        isValid={true}
                        folderProcesses={folderProcesses}
                        folderProcessesLoading={folderProcessesLoading}
                        exportDestinationOptions={this.props.exportDestinationOptions}
                        handleDestinationOptionsChange={this.handleDestinationOptionsChange}
                        printers={this.props.printers}
                        folderAttachmentTypes={folderAttachmentTypes}
                    />
                </SimpleTabs>
            </div>
        );
    }

    private formatPresets = (): void => {
        const { presets, redactionLang } = this.props;

        const formattedPreset = presets.map((preset: IExportPreset): ISelectOptions => {
            return {
                value: preset.id.toString(),
                label: redactionLang ? redactionLang === 'fr' ? preset.exportOptions[PRESET_NAME_2_PARAM]
                    ? preset.exportOptions[PRESET_NAME_2_PARAM] : preset.exportOptions[PRESET_NAME_PARAM]
                    : preset.exportOptions[PRESET_NAME_PARAM]: preset.exportOptions[PRESET_NAME_PARAM],
            };
        });

        this.setState({ presetNames: formattedPreset });
    }

    private handleOptionsChange = (data: IContentOptionChange, shouldUpdateDocList: boolean): void => {
        const {
            handleOptionsChange,
            contentOptionsData,
            selectedPagesData,
            updateExportFilterParams,
            handlePaginationOptionsChange,
            changeForm,
        } = this.props;

        handleOptionsChange(data, contentOptionsData, selectedPagesData, shouldUpdateDocList);

        if (shouldUpdateDocList) {
            updateExportFilterParams();

            if (changeForm) {
                changeForm();
            }
        }

        if (data.originalsOnly) {
            handlePaginationOptionsChange({ paginationMode: PAGINATION_TYPE_OFF_PARAM });
        }
    }

    private handleChangeAnnotationControl = (id: number, isChecked: boolean): void => {
        const { changeForm } = this.props;

        if (isChecked) {
            this.props.setAnnotationOption(id);

            if (changeForm) {
                changeForm();
            }
        } else {
            this.props.removeAnnotationOption(id);
        }
    }

    private handlePaginationOptionsChange = (data: any): void => {
        const { changeForm } = this.props;

        this.props.handlePaginationOptionsChange(data);

        if (changeForm) {
            changeForm();
        }
    }

    private handleDestinationOptionsChange = (data: IDestinationOptionChange): void => {
        const { changeForm } = this.props;

        this.props.handleExportDestinationOptions(data);

        if (changeForm) {
            changeForm();
        }
    }

}

const mapStateToProps = (state: StoreState): IStateProps => ({
    highLightList: getHighlightTypesList(state),
    severList: getSeverTypesList(state),
    contentOptionsData: getExportContentOptionsForm(state),
    annotationControls: getAnnotationControlsForm(state),
    paginationOptions: getExportPaginationOptionsForm(state),
    exportDestinationOptions: getExportDestinationOptionsForm(state),
    redactionDocumentId: getRedactionDocumentId(state),
    exportDocumentsList: getExportDocumentsSelector(state),
    exportDocumentsLoading: getExportDocumentsLoading(state),
    folderProcesses: getFolderProcesses(state),
    folderProcessesLoading: getFolderProcessesLoading(state),
    printers: getPrinters(state),
    lastUsedPrinter: getLastUsedPrinter(state),
    presets: getPackagePresets(state),
    hasDefaultWatermark: hasCurrentReductionDefaultWatermark(state),
    folderAttachmentTypes: getFolderAttachmentTypes(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, IActionProps, AnyAction>): IActionProps =>
    ({
        clearPresetOptions: (): void => {
            dispatch(clearPresetOptions());
        },
        setExportOptionsFromPreset: (data: IExportPreset[]): void => {
            dispatch(setExportOptionsFromPreset(data));
        },
        fetchAllPresets: (): void => {
            dispatch(fetchAllPresets());
        },
        handlePaginationOptionsChange: (data: IPaginationOptionChange): void => {
            dispatch(handlePaginationOptions(data));
        },
        handleOptionsChange: (
            data: IContentOptionChange,
            contentOptionsData: IContentOptions,
            selectedPagesData: ISelectedPagesData,
            shouldUpdateDocList: boolean,
        ): void => {
            dispatch(handleOptions(data, contentOptionsData, selectedPagesData, shouldUpdateDocList));
        },
        setAnnotationOption: (id: number): void => {
            dispatch(setAnnotationOption(id));
        },
        removeAnnotationOption: (id: number): void => {
            dispatch(removeAnnotationOption(id));
        },
        handleExportDestinationOptions: (data: IDestinationOptionChange): void => {
            dispatch(handleExportDestinationOptions(data));
        },
        updateExportFilterParams: (): void => {
            dispatch(updateExportFilters());
        },
        setExportFiltersParams: (filters: IExportFilters): void => {
            dispatch(setExportFilters(filters));
        },
        fetchPrinters: (): void => {
            dispatch(fetchPrinters());
        },
        fetchAllWatermarksSettings: (): void => {
            dispatch(fetchWatermarksSettings());
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindowExportTabs);

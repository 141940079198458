import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { CHECKBOX_TYPE_DEFAULT } from '../../constants/export.contstants';
import {
    IRadio,
    ICheckboxMaterialBuilder,
    IRadioMaterialBuilder,
    ISelectMaterialBuilder,
    ISelectOptions,
} from './marerialUiForms.model';

export const CheckboxMaterialBuilder = (
    {
        type = CHECKBOX_TYPE_DEFAULT,
        checked,
        handleCheckboxChange,
        label,
        paramName,
        color = 'primary',
        disabled = false,
    }: ICheckboxMaterialBuilder): JSX.Element => {
    const CustomCheckbox = type === CHECKBOX_TYPE_DEFAULT ? Checkbox : Switch;

    return (
        <FormControlLabel
            control={
                <CustomCheckbox
                    checked={!!checked}
                    value={''}
                    onClick={(e: React.MouseEvent<Element>): void => {
                        e.stopPropagation();
                        handleCheckboxChange(paramName, (e.target as HTMLInputElement).checked);
                    }}
                    name={paramName}
                    color={color}
                    disabled={disabled}
                />
            }
            label={label && <span onClick={(e: React.MouseEvent<Element>): void => e.stopPropagation()}>{label}</span>}
        />
    );
};

export const RadioMaterialBuilder = (
    {
        value,
        handleRadioChange,
        paramName,
        radioList,
        ariaLabel,
        row = true,
        disabled = false,
    }: IRadioMaterialBuilder): JSX.Element => (
    <RadioGroup
        row={row}
        aria-label={ariaLabel}
        name={paramName}
        value={value}
        onChange={(e: React.ChangeEvent<Element>): void =>
            handleRadioChange(paramName, (e.target as HTMLInputElement).value)}
    >
        {
            radioList.map((radio: IRadio): JSX.Element => (
                <FormControlLabel
                    key={radio.value}
                    value={radio.value}
                    control={<Radio color={radio.color || 'primary'} />}
                    label={radio.label}
                    disabled={disabled}
                />
            ))
        }
    </RadioGroup>
);

export const SelectMaterialBuilder = (
    {
        value,
        paramName,
        label,
        options,
        handleSelectChange,
        fullWidth = true,
        disabled = false,
        variant = 'outlined',
        error = false,
        errorText,
    }: ISelectMaterialBuilder): JSX.Element => (
    <TextField
        select={true}
        label={label}
        value={value || ''}
        onChange={(e: React.ChangeEvent<Element>): void =>
            handleSelectChange(paramName, (e.target as HTMLInputElement).value)}
        margin='normal'
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled}
        error={error}
        helperText={error && <div className='error-watermarks'>{errorText}</div>}
    >
        {
            options.map((option: ISelectOptions): JSX.Element => (
                <MenuItem key={option.value} value={option.value} className='item-li'>
                    <span title={option.label} className='text-disc'>{option.label}</span>
                </MenuItem>
            ))
        }
    </TextField>
);

export const GET_REQUEST_TYPE_PENDING = 'reduction/GET_REQUEST_TYPE_PENDING';
export const GET_REQUEST_TYPE_SUCCESS = 'reduction/GET_REQUEST_TYPE_SUCCESS';
export const GET_REQUEST_TYPE_FAILURE = 'reduction/GET_REQUEST_TYPE_FAILURE';

export const GET_CONTACT_TYPE_PENDING = 'reduction/GET_CONTACT_TYPE_PENDING';
export const GET_CONTACT_TYPE_SUCCESS = 'reduction/GET_CONTACT_TYPE_SUCCESS';
export const GET_CONTACT_TYPE_FAILURE = 'reduction/GET_CONTACT_TYPE_FAILURE';

export const GET_REQUEST_FOLDERS_PENDING = 'reduction/GET_REQUEST_FOLDERS_PENDING';
export const GET_REQUEST_FOLDERS_SUCCESS = 'reduction/GET_REQUEST_FOLDERS_SUCCESS';
export const GET_REQUEST_FOLDERS_FAILURE = 'reduction/GET_REQUEST_FOLDERS_FAILURE';

export const GET_REQUEST_SECURITY_PENDING = 'reduction/GET_REQUEST_SECURITY_PENDING';
export const GET_REQUEST_SECURITY_SUCCESS = 'reduction/GET_REQUEST_SECURITY_SUCCESS';
export const GET_REQUEST_SECURITY_FAILURE = 'reduction/GET_REQUEST_SECURITY_FAILURE';

export const GET_FOLDER_PROCESSES_PENDING = 'reduction/GET_FOLDER_PROCESSES_PENDING';
export const GET_FOLDER_PROCESSES_SUCCESS = 'reduction/GET_FOLDER_PROCESSES_SUCCESS';
export const GET_FOLDER_PROCESSES_FAILURE = 'reduction/GET_FOLDER_PROCESSES_FAILURE';

export const GET_AMANDA_USERS_PENDING = 'reduction/GET_AMANDA_USERS_PENDING';
export const GET_AMANDA_USERS_SUCCESS = 'reduction/GET_AMANDA_USERS_SUCCESS';
export const GET_AMANDA_USERS_FAILURE = 'reduction/GET_AMANDA_USERS_FAILURE';

export const SET_SELECTED_REQUEST_TYPE = 'reduction/SET_SELECTED_REQUEST_TYPE ';

export const GET_AVAILABLE_SECURITY_PENDING = 'reduction/GET_AVAILABLE_SECURITY_PENDING';
export const GET_AVAILABLE_SECURITY_SUCCESS = 'reduction/GET_AVAILABLE_SECURITY_SUCCESS';
export const GET_AVAILABLE_SECURITY_FAILURE = 'reduction/GET_AVAILABLE_SECURITY_FAILURE';

export const UPDATE_REQUEST_SECURITY_PENDING = 'reduction/UPDATE_REQUEST_SECURITY_PENDING';
export const UPDATE_REQUEST_SECURITY_SUCCESS = 'reduction/UPDATE_REQUEST_SECURITY_SUCCESS';
export const UPDATE_REQUEST_SECURITY_FAILURE = 'reduction/UPDATE_REQUEST_SECURITY_FAILURE';

export const GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING = 'reduction/GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING';
export const GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS = 'reduction/GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS';
export const GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE = 'reduction/GET_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE';

export const UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING =
    'reduction/UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_PENDING';
export const UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS =
    'reduction/UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_SUCCESS';
export const UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE =
    'reduction/UPDATE_REQUEST_SECURITY_GROUPS_BY_FOLDER_FAILURE';

export const SET_REQUEST_SECURITIES = 'reduction/SET_REQUEST_SECURITIES';
export const SET_REQUEST_SECURITY_GROUPS_BY_FOLDER = 'reduction/SET_REQUEST_SECURITY_GROUPS_BY_FOLDER';
export const CLEAN_REQUEST_SECURITIES = 'reduction/CLEAN_REQUEST_SECURITIES';

export const GET_FOLDER_ATTACHMENT_TYPES_PENDING = 'redaction/GET_FOLDER_ATTACHMENT_TYPES_PENDING';
export const GET_FOLDER_ATTACHMENT_TYPES_SUCCESS = 'redaction/GET_FOLDER_ATTACHMENT_TYPES_SUCCESS';
export const GET_FOLDER_ATTACHMENT_TYPES_FAILURE = 'redaction/GET_FOLDER_ATTACHMENT_TYPES_FAILURE';

export const GET_PROCESS_ATTACHMENT_TYPES_PENDING = 'redaction/GET_PROCESS_ATTACHMENT_TYPES_PENDING';
export const GET_PROCESS_ATTACHMENT_TYPES_SUCCESS = 'redaction/GET_PROCESS_ATTACHMENT_TYPES_SUCCESS';
export const GET_PROCESS_ATTACHMENT_TYPES_FAILURE = 'redaction/GET_PROCESS_ATTACHMENT_TYPES_FAILURE';

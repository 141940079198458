import * as React from 'react';
import { useState, useEffect } from 'react';
import { first } from 'lodash';
import classnames from 'classnames';
import { Scrollbars } from 'rc-scrollbars';
import { Document, Page } from 'react-pdf';
import { Search, ZoomOutMap, Close } from '@mui/icons-material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IDuplicateDocument } from '../../redux/reducers/duplicates/duplicates.model';
import { IClientSize, ILoadedPage } from '../redactor/redactor.model';
import { ISizeSelectOptionsObject } from '../../components/controls/control.model';
import { sizeSelectOptions } from '../../constants';
import { setPageParamsOnLoaded } from '../../utils/redactor.utils';
import { size as pdfSizeUtil } from '../../utils/pdfControl.util';
import { PAGE_WIDTH_NAME } from '../../constants';
import { GoToPage } from '../../components/navigation/Navigation';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Tooltip from '@mui/material/Tooltip';
import { IDuplicateReferences } from './duplicate.model';
import './duplicatePanels.styles.scss';
import { IRedactorDocument } from '../../redux/reducers/redactor/redactor.model';
import * as constants from '../../constants/duplicates.constants';
import { MenuItem } from '@mui/material';

export const DUPLICATES_REDACTOR_DEFAULT_CLASS = 'duplicates-body_redactor';
export const DUPLICATES_REDACTOR_FULL_SIZE_CLASS = `${DUPLICATES_REDACTOR_DEFAULT_CLASS}_full-size`;

export interface IDuplicateRedactorProps {
    document: IDuplicateDocument;
    redactorDocument: IRedactorDocument;
    setWidth: (width: number) => void;
    setHeight: (height: number) => void;
    handleClose: () => void;
    setDuplicateScale: (documentId: number, pageId: number, scale: number) => void;
    setDuplicateClientSize: (documentId: number, pageId: number, clientSize: IClientSize) => void;
    handleGoToDocumentPage: (documentId: number, page: string) => void;
    fetchDuplicateRefData: (documentId: number, pageId: number, actualPageNumber: number) => void;
    duplicateReferences: IDuplicateReferences[];
}

export const DuplicateRedactor = (
    {
        document,
        redactorDocument,
        setHeight,
        setWidth,
        handleClose,
        setDuplicateScale,
        setDuplicateClientSize,
        handleGoToDocumentPage,
        duplicateReferences,
        fetchDuplicateRefData,
    }: IDuplicateRedactorProps,
): JSX.Element => {
    const [isRedactorFullSize, useIsRedactorFullSize] = useState(false);
    const [isZoomActive, useIsZoomActive] = useState(false);
    const [duplicateSize, useDuplicateSize] = useState(PAGE_WIDTH_NAME);
    const resizingWindow: React.RefObject<HTMLDivElement> = React.createRef();
    const scrollbars: React.RefObject<Scrollbars> = React.createRef();
    const documentComponent: React.RefObject<HTMLDivElement> = React.createRef();
    const documentPage: React.RefObject<HTMLDivElement> = React.createRef();

    useEffect((): void => {
        const isScrollExist = scrollbars && scrollbars.current;

        if (isScrollExist) {
            const { clientHeight, clientWidth } = scrollbars.current.getValues();

            setDuplicateClientSize(
                document.documentId,
                document.currentPageId,
                { clientHeight, clientWidth },
            );
        }

    }, []);

    const changeDuplicateScale = (e: SelectChangeEvent<string>): void => {
        // e.persist();

        const { clientSize, documentId, currentPageId } = document;
        const { pdfHeight, pdfWidth } = redactorDocument;
        /* tslint:disable:no-string-literal */
        const filteredOptions = sizeSelectOptions
            .filter((item: ISizeSelectOptionsObject) =>
                item.val ===  e.target['value']);
        /* tslint:enable:no-string-literal */
        const size: string = first(filteredOptions).name;
        const { pdfScale } = pdfSizeUtil(size, clientSize, pdfWidth, pdfHeight, false);

        useDuplicateSize(size);
        setDuplicateScale(documentId, currentPageId, pdfScale);
    };

    const handleReferenceModal = (): void => {
        fetchDuplicateRefData(document.documentId, document.currentPageId, document.currentPageNumber);
    };

    const showDuplicateIcon = (documentId: number, currentPageNumber: number): JSX.Element => {
        const currentDuplicate = duplicateReferences.find(
            (ref: IDuplicateReferences): boolean => ref.documentId === documentId,
        );

        const isMasterPage = currentDuplicate && currentDuplicate.masterPageNumbers
            && currentDuplicate.masterPageNumbers.some((page: number): boolean => page === currentPageNumber);

        const isDuplicatePage = currentDuplicate && currentDuplicate.duplicatePageNumbers.some(
            (page: number): boolean => page === currentPageNumber,
        );

        if (isMasterPage) {
            return (
                <span className='master-duplicate-icon' onClick={handleReferenceModal}>
                    <img alt='master' src='/icons/master.png' />
                </span>
            );
        } else if (isDuplicatePage) {
            return (
                <span className='master-duplicate-icon' onClick={handleReferenceModal}>
                    <img alt='duplicate' src='/icons/duplicate.png' />
                </span>
            );
        }
    };

    const backDisabled = document.currentPageNumber === 1;
    const forwardDisabled = document.currentPageNumber === document.pagesAmount;

    return (
        <div
            className={classnames(
                DUPLICATES_REDACTOR_DEFAULT_CLASS,
                { [DUPLICATES_REDACTOR_FULL_SIZE_CLASS]: isRedactorFullSize },
            )}
            ref={resizingWindow}
        >
            <div className='duplicates-body_redactor_controls'>
                <div>{document.documentName}</div>
                <div className='redactor-control-icons'>
                    {showDuplicateIcon(document.documentId, document.currentPageNumber)}
                    <span className='duplicates-body_redactor_controls_zoom'>
                        {!isZoomActive && <Search onClick={(): void => useIsZoomActive(!isZoomActive)} />}
                        {isZoomActive && (
                            <Select
                                value={duplicateSize}
                                onChange={changeDuplicateScale}
                                renderValue={(): string =>
                                    `${constants.getManageDuplicatesLabelsByKey(duplicateSize)}` !== ''
                                        ? `${constants.getManageDuplicatesLabelsByKey(duplicateSize)}`
                                        : duplicateSize
                                }
                            >
                                {sizeSelectOptions.map((option: ISizeSelectOptionsObject) => (
                                    <MenuItem key={option.val} value={option.val} className='zoom-option'>
                                        {`${option.label}` !== 'undefined' ? `${option.label}` : `${option.name} `}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}

                    </span>
                    {!isRedactorFullSize && <ZoomOutMap onClick={(): void => useIsRedactorFullSize(true)} />}
                    <Close
                        onClick={(): void => isRedactorFullSize
                            ? useIsRedactorFullSize(false)
                            : handleClose()}
                    />
                </div>
            </div>
            <Scrollbars ref={scrollbars}>
                <div className='duplicates-body_redactor_wrapper' ref={documentComponent}>
                    <Document file={document.pdfSrc}>
                        <Page
                            ref={documentPage}
                            pageNumber={1}
                            onLoadSuccess={(page: ILoadedPage): void =>
                                setPageParamsOnLoaded(page, setWidth, setHeight)}
                            width={document.pdfWidth * document.scale}
                            height={document.pdfHeight * document.scale}
                            scale={document.scale}
                        />
                    </Document>
                </div>
                <div className='navigation'>
                    <Tooltip title='Go to First Page' placement='top'>
                        <span>
                            <IconButton
                                className={`icon ${!backDisabled && isRedactorFullSize ? 'icon-white' : ''}`}
                                disabled={backDisabled}
                                aria-label='First Page'
                                onClick={(): void => handleGoToDocumentPage(document.documentId, GoToPage.FIRST)}
                            >
                                <FirstPageIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title='Go to Previous Page' placement='top'>
                        <span>
                            <IconButton
                                className={`icon ${!backDisabled && isRedactorFullSize ? 'icon-white' : ''}`}
                                disabled={backDisabled}
                                aria-label='Previous Page'
                                onClick={(): void => handleGoToDocumentPage(document.documentId, GoToPage.PREV)}
                            >
                                <KeyboardArrowLeft />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title='Go to Next Page' placement='top'>
                        <span>
                            <IconButton
                                className={`icon ${!forwardDisabled && isRedactorFullSize ? 'icon-white' : ''}`}
                                disabled={forwardDisabled}
                                aria-label='Next Page'
                                onClick={(): void => handleGoToDocumentPage(document.documentId, GoToPage.NEXT)}
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title='Go to Last Page' placement='top'>
                        <span>
                            <IconButton
                                className={`icon ${!forwardDisabled && isRedactorFullSize ? 'icon-white' : ''}`}
                                disabled={forwardDisabled}
                                aria-label='Last Page'
                                onClick={(): void => handleGoToDocumentPage(document.documentId, GoToPage.LAST)}
                            >
                                <LastPageIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </Scrollbars>
        </div>
    );
};

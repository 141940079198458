import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import { IMaterialCheckboxTableRowProps } from '../materialCheckboxTable.model';

const MaterialCheckboxTableRow = (props: IMaterialCheckboxTableRowProps): JSX.Element => {
    const { rowKeysList, row } = props;

    return (
        <React.Fragment>
            {
                rowKeysList.map((arrItem: any, index: number) => {
                    return <TableCell key={index} align='left'>
                        {rowKeysList[index] === 'name1' ? row[rowKeysList[index]] ? row[rowKeysList[index]]
                            : row['name'] : rowKeysList[index] === 'departmentFr' ? row[rowKeysList[index]]
                            ? row[rowKeysList[index]] : row['department'] : row[rowKeysList[index]]}
                    </TableCell>;
                })
            }
        </React.Fragment>
    );
};

export default MaterialCheckboxTableRow;

export const isContextMenu = (event: any): boolean => {
    let isContext = false;

    if ('which' in event) {
        isContext = event.which === 3;
    } else if ('button' in event) {
        isContext = event.button === 2;
    }

    return isContext;
};

export const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
});

export const mouseUpEvent = new MouseEvent('mouseup', {
    view: window,
    bubbles: true,
    cancelable: true,
});

export const contextMenuEvent = new MouseEvent('contextmenu', {
    view: window,
    bubbles: true,
    cancelable: true,
});

import * as React from 'react';
import './BasicTab.style.scss';
import Checkbox from '@mui/material/Checkbox';
import { Field, FormSpy } from 'react-final-form';
import { renderCheckbox, renderTextField } from '../../../../components/materialUiForms/materialUiForms';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { IProps, IState, IStateProps } from './basicTab.model';
import FormLabel from '@mui/material/FormLabel';
import { getRequestTypesList, getRequestTypesListValues } from '../../../../redux/selectors/amandaContent';
import { IState as StoreState } from '../../../../redux/store';
import { IRequestType } from '../../../../redux/reducers/amandaContent/amandaContent.model';
import * as constants from '../../../../constants/disclosure.contstants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { FormApi, FormState } from 'final-form';
import { getDisclosureSettings } from '../../../../redux/selectors/modalGlobalSettings';
import { IRequestTypes } from '../../../../redux/reducers/modalGlobalSettings/globalSettings.model';
import { changeLang } from '../../../../redux/actions/localization';
import { langFrench } from '../../../../constants/localization.constants';

export class BasicTab extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isSelectAll: props.form.getState().values[constants.REQUEST_TYPE].length === props.requestTypesList.length,
        };
    }

    public componentDidUpdate(prevProps: IProps): void {
        const prevDuplicateRef = prevProps.formState.values[constants.IS_DUPLICATE_REF_REQUIRED];
        const nextDuplicateRef = this.props.formState.values[constants.IS_DUPLICATE_REF_REQUIRED];
        const prevReleaseState = prevProps.formState.values[constants.IS_RELEASABLE_STATE];
        const nextReleaseState = this.props.formState.values[constants.IS_RELEASABLE_STATE];

        if (prevReleaseState && nextDuplicateRef) {
            this.props.form.change(constants.IS_RELEASABLE_STATE, false);
        } else if (prevDuplicateRef && nextReleaseState) {
            this.props.form.change(constants.IS_DUPLICATE_REF_REQUIRED, false);
        }
    }

    public render(): JSX.Element {
        const { requestTypesList, form, hasEditPermissions, labels, language } = this.props;
        const { isSelectAll } = this.state;
        const isRequestTypeError = !form.getState().values[constants.REQUEST_TYPE].length;
        const langRule = changeLang(language);

        return (
            <div>
                <Field
                    render={renderCheckbox}
                    type='checkbox'
                    name={constants.IS_ACTIVE}
                    label={labels.activeLabel[langRule]}
                    disabled={!hasEditPermissions}
                />
                <Field
                    render={renderTextField}
                    name={constants.NAME}
                    label={labels.nameLabel[langRule]}
                    margin='normal'
                    required={true}
                    variant='outlined'
                    fullWidth={true}
                    disabled={!hasEditPermissions}
                />
                <Field
                    render={renderTextField}
                    name={constants.NAME_1}
                    label={labels.nameFrenchLabel[langRule]}
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    disabled={!hasEditPermissions}
                />
                <Field
                    render={renderTextField}
                    name={constants.DESCRIPTION}
                    multiline={true}
                    minRows='2'
                    label={labels.descriptionLabel[langRule]}
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    disabled={!hasEditPermissions}
                />
                <Field
                    render={renderTextField}
                    name={constants.DESCRIPTION_1}
                    multiline={true}
                    minRows='2'
                    label={labels.descriptionFrenchLabel[langRule]}
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    disabled={!hasEditPermissions}
                />
                <div className='basicTab-label'>
                    <FormLabel error={isRequestTypeError}>
                        {`${labels.requestTypeLabel[langRule]}:*`}
                        {
                            isRequestTypeError &&
                            <span className='error-message'> {labels.atleastOneRequiredLabel[langRule]} </span>
                        }
                    </FormLabel>
                </div>
                <div className='basicTab-checkbox-selectAll'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color='primary'
                                checked={isSelectAll}
                                disabled={this.disableSelectAll() || !hasEditPermissions}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                    this.handleChangeSelectAll(e, form)
                                }
                            />
                        }
                        label={labels.selectAllLabel[langRule]}
                    />
                </div>
                <FormSpy
                    subscription={{ values: true, dirty: true }}
                    onChange={<T extends object>(formState: FormState<T>): void => this.onChange(formState)}
                />
                <Divider />
                <div className='basicTab-checkbox-list'>
                    {
                        requestTypesList.map((item: IRequestType): JSX.Element => (
                            <div key={item.folderType}>
                                <Field
                                    render={renderCheckbox}
                                    type='checkbox'
                                    disabled={this.disableChange(item) || !hasEditPermissions}
                                    name={constants.REQUEST_TYPE}
                                    label={language ? language === langFrench ? item.folderDesc2 ? item.folderDesc2
                                        : item.folderDesc : item.folderDesc : item.folderDesc}
                                    value={item.folderType}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    private get requiredFields(): string[] {
        return this.props.disclosureGlobal.requestTypesWithDisclosureTypes
            ? this.props.disclosureGlobal.requestTypesWithDisclosureTypes
                .filter((field: IRequestTypes) => field.defaultDisclosureTypeId === this.props.disclosureId)
                .map((field: IRequestTypes) => field.requestType)
            : [];
    }

    private disableSelectAll = (): boolean => {
        return !!this.requiredFields.length
            && this.props.form.getState().values[constants.REQUEST_TYPE].length === this.props.requestTypesList.length;
    }

    private disableChange = (item: IRequestType): boolean => {
        return this.requiredFields.includes(item.folderType);
    }

    private handleChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>, form: FormApi): void => {
        const { requestTypesListValues } = this.props;
        const checked = e.target.checked;

        this.setState({
            isSelectAll: checked,
        });

        form.batch(() => {
            form.change(constants.REQUEST_TYPE, !checked ? [] : sortBy(requestTypesListValues));
        });
    }

    private onChange = <T extends object>(formState: FormState<T>): void => {
        const { values } = formState;
        const { requestTypesList } = this.props;

        this.setState({
            isSelectAll: values[constants.REQUEST_TYPE].length === requestTypesList.length,
        });
    }
}

const mapStateToProps = (state: StoreState): IStateProps => ({
    requestTypesList: getRequestTypesList(state),
    requestTypesListValues: getRequestTypesListValues(state),
    disclosureGlobal: getDisclosureSettings(state),
});

export default connect(mapStateToProps)(BasicTab);

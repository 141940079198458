import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import { TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    RadioMaterialBuilder,
    SelectMaterialBuilder,
} from '../../../../components/materialUiForms/materialUiFormBuilder';
import { Spinner } from '../../../../components/spinner/Spinner';
import * as constants from '../../../../constants/export.contstants';
import {
    IExportDestinationOptions,
} from '../../../../redux/reducers/modalWindowExport/modalWindowExport.model';
import { IAttachmentTypes, IFolderProcesses } from '../../../../redux/reducers/amandaContent/amandaContent.model';
import { IPrinter, IPrinterTray } from '../../../../redux/reducers/printer/printer.model';
import { ISelectOptions } from '../../../../components/materialUiForms/marerialUiForms.model';
import {
    getAttachmentTypeCode,
    getCreateSinglePackageAs,
    getExportAttachmentDescription,
    getExportAttachmentDetail,
    getExportFileName,
    getExportFileNameErrorMessage,
} from '../../../../redux/selectors/modalWindowExport';
import {
    handleAttachmentTypeOptions,
    setExportFileNameErrorMessage } from '../../../../redux/actions/modalWindowExport';
import { getRedactionDocumentId } from '../../../../redux/selectors/initialization';
import { getProcessAttachmentTypes, getProcessAttachmentTypesLoading } from '../../../../redux/selectors/amandaContent';
import { fetchProcessAttachmentTypes } from '../../../../redux/actions/amandaContent';
import { emptySelectOption } from '../../../../components/materialUiForms/materialUiForms';
import { getRedactionLanguage } from '../../../../redux/selectors/localStorage';
import { getModifiedLabels } from '../../../../redux/selectors/localization';
import { initialLabel } from '../../../../constants/localization.constants';
import resourceBundle from '../../../localization/localizationData';
import { changeLang } from '../../../../redux/actions/localization';

export interface IDestinationOptionChange {
    [name: string]: string;
}

export interface IAttachmentOptionChange {
    [name: string]: string;
}

const getExportDestinationLabelsByKey = (key: string): string => {
    switch(key) {
        case 'MODAL_EXPORT_DESTINATION_TAB_DESTINATION_LABEL':
            return 'exportDestinationLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_FILE':
            return 'fileLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_FOLDER_ATTACHMENT':
            return 'folderAttachmentLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_PROCESS_ATTACHMENT':
            return 'processAttachmentLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_PRINTER':
            return 'printerLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_EXPORT_FILENAME':
            return 'exportFilenameLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_ATTACHMENT_TYPE':
            return 'attachmentTypeLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_ATTACHEMENT_DESCRIPTION':
            return 'attachmentDescriptionLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_ATTACHMENT_DETAIL':
            return 'attachmentDetailLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_ATTACH_TO_PROCESS':
            return 'attachToProcessLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_SELECT_PRINTER':
            return 'selectPrinterLabel';
        case 'MODAL_EDIT_FILENAME_INVALID_FILE_NAME_ERROR':
            return 'invalidFilenameLabel';
        case 'MODAL_EDIT_FILENAME_FILE_NAME_EXCEED_ERROR':
            return 'fileExceedLengthLabel';
        case 'MODAL_EXPORT_DESTINATION_TAB_SELECT_TRAY':
            return 'selectTrayLabel';
        default: return '';
    }
};

const ExportDestination = ({
    exportDestinationOptions,
    handleDestinationOptionsChange,
    folderProcesses,
    folderProcessesLoading,
    printers,
    folderAttachmentTypes,
}: {
    handleDestinationOptionsChange: (data: IDestinationOptionChange) => void;
    exportDestinationOptions: IExportDestinationOptions;
    title?: string;
    isValid: boolean;
    folderProcesses: IFolderProcesses[];
    folderProcessesLoading: boolean;
    printers: IPrinter[];
    folderAttachmentTypes: IAttachmentTypes[];
}): JSX.Element => {

    const isProcess = exportDestinationOptions[constants.EXPORT_DESTINATION_PARAM] === constants.FOLDER_PROCESS_PARAM;
    const isPrinter = exportDestinationOptions[constants.EXPORT_DESTINATION_PARAM] === constants.PRINTER_PARAM;
    const isTray = exportDestinationOptions[constants.EXPORT_DESTINATION_PARAM] === constants.PRINTER_PARAM
        && exportDestinationOptions[constants.PRINTER_CODE];
    const isRequestFolder = exportDestinationOptions[constants.EXPORT_DESTINATION_PARAM] ===
        constants.REQUEST_FOLDER_PARAM;
    const isFile = exportDestinationOptions[constants.EXPORT_DESTINATION_PARAM] === constants.FILE_PARAM;
    const [getProcessAttachmentTypeOptionWithEmpty, setProcessAttachmentTypeOptions] =
        useState([...emptySelectOption]);

    const createPacakageAsSingleFile = useSelector(getCreateSinglePackageAs);
    const exportFilenameErrorMessage = useSelector(getExportFileNameErrorMessage);
    const exportFilename = useSelector(getExportFileName);
    const redactionDocumentId = useSelector(getRedactionDocumentId);
    const processAttachmentTypesLoading = useSelector(getProcessAttachmentTypesLoading);
    const processAttachmentTypes = useSelector(getProcessAttachmentTypes);
    const attachmentTypeCode = useSelector(getAttachmentTypeCode);
    const attachmentDescription = useSelector(getExportAttachmentDescription);
    const attachmentDetail = useSelector(getExportAttachmentDetail);
    const modifiedLabels = useSelector(getModifiedLabels);
    const redactionLang = useSelector(getRedactionLanguage);
    const langRule = changeLang(redactionLang);
    const labels = {
        exportDestinationLabel: initialLabel,
        fileLabel: initialLabel,
        folderAttachmentLabel: initialLabel,
        processAttachmentLabel: initialLabel,
        printerLabel: initialLabel,
        exportFilenameLabel: initialLabel,
        attachmentTypeLabel: initialLabel,
        attachmentDescriptionLabel: initialLabel,
        attachmentDetailLabel: initialLabel,
        selectPrinterLabel: initialLabel,
        attachToProcessLabel: initialLabel,
        invalidFilenameLabel: initialLabel,
        fileExceedLengthLabel: initialLabel,
        selectTrayLabel: initialLabel,
    };

    resourceBundle.map((resource: any) => {
        if (getExportDestinationLabelsByKey(resource.resourceKey)) {
            labels[getExportDestinationLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getExportDestinationLabelsByKey(resource.resourceKey)) {
            labels[getExportDestinationLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    const dispatch = useDispatch();
    const onChangeExportFileNameValidation = (errorMessage: string): void => {
        dispatch(setExportFileNameErrorMessage(errorMessage));
    };
    const handleFetchProcessAttachmentTypes = (redactionDocId: number, processRsn: number): void => {
        dispatch(fetchProcessAttachmentTypes(redactionDocId, processRsn));
    };
    const handleAttachmentTypeOption = (data: IAttachmentOptionChange): void => {
        dispatch(handleAttachmentTypeOptions(data));
    };

    useEffect(() => {

        if (!createPacakageAsSingleFile && (isProcess || isRequestFolder)) {
            resetoreExportFileName();
        }

    }, [createPacakageAsSingleFile]);

    useEffect(() => {
        if(processAttachmentTypes && processAttachmentTypes.length) {
            setProcessAttachmentTypeOptions([...emptySelectOption,
                ...getAttachmentTypeOptions(processAttachmentTypes)]);
        }
    }, [processAttachmentTypes]);

    const getAttachmentTypeOptions = (attachmentTypes: IAttachmentTypes[]): ISelectOptions[] => {
        return attachmentTypes.map((attachmentType: IAttachmentTypes) => ({
                value: attachmentType.attachmentCode,
                label: redactionLang ? redactionLang === 'fr' ? attachmentType.attachmentDesc2
                    ? attachmentType.attachmentDesc2 : attachmentType.attachmentDesc : attachmentType.attachmentDesc
                    : attachmentType.attachmentDesc,
            }));

    };

    const handleChange = (param: string, value: string): void => {
        if(param === constants.FOLDER_ATTACHMENT_TYPE_CODE_PARAM ||
            param === constants.PROCESS_ATTACHMENT_TYPE_CODE_PARAM) {
            handleAttachmentTypeOption({ [param]: value });

            return;
        }

        handleDestinationOptionsChange({ [param]: value });

        if (param === constants.PRINTER_CODE) {
            handleDestinationOptionsChange({ [constants.PRINTER_TRAY_PARAM]: '' });
        }

        if (param === constants.FOLDER_PARAM) {
            handleDestinationOptionsChange({ [constants.PRINTER_TRAY_PARAM]: '' });
            handleDestinationOptionsChange({ [constants.PRINTER_CODE]: '' });
            handleFetchProcessAttachmentTypes(redactionDocumentId, +value);
        }

        if (!(param === constants.FOLDER_PARAM)) {
            resetoreExportFileName();
            resetAttachmentInfo();
        }
    };

    const resetoreExportFileName = (): void => {
        handleDestinationOptionsChange({ [constants.EXPORT_FILE_NAME_PARAM]: '' });
        onChangeExportFileNameValidation('');
    };

    const resetAttachmentInfo = (): void => {
        handleDestinationOptionsChange({ [constants.ATTACHMENT_DESCRIPTION]: '' });
        handleDestinationOptionsChange({ [constants.ATTACHMENT_DETAIL]: '' });
    };

    const handleExportFileNameChange = (param: string, value: string): void => {
        handleDestinationOptionsChange({ [param]: value });
    };

    const handleExportAttachmentInfoChange = (param: string, value: string): void => {
        if(param === constants.ATTACHMENT_DESCRIPTION) {
            if(value.length > 64) {
                handleDestinationOptionsChange({ [param]: value.slice(0,64) });
            } else {
                handleDestinationOptionsChange({ [param]: value });
            }
        }

        if(param === constants.ATTACHMENT_DETAIL) {
            if(value.length > 4000) {
                handleDestinationOptionsChange({ [param]: value.slice(0,4000) });
            } else {
                handleDestinationOptionsChange({ [param]: value });
            }
        }
    };

    const getFolderProcessOptions = folderProcesses.map((folderProcess: IFolderProcesses) => ({
        value: `${folderProcess.processRSN}`,
        label: redactionLang ? redactionLang === 'fr' ? folderProcess.description_fr
            ? folderProcess.description_fr : folderProcess.description : folderProcess.description
            : folderProcess.description,
    }));

    const getPrinterOptions = printers
        .filter((printer: IPrinter) => printer.printerTrays.length)
        .map((printer: IPrinter) => ({
            value: `${printer.printerCode}`,
            label: printer.printerDesc,
        }));

    const getTrayOptions = (selectedPrinter: string): ISelectOptions[] => {
        return printers
            .find((printer: IPrinter): boolean => printer.printerCode === Number(selectedPrinter))
            .printerTrays
            .map((tray: IPrinterTray) => {
                return {
                    value: `${tray.trayCode}`,
                    label: tray.trayName,
                };
            });
    };

    const getFolderAttachmentTypeOptions = folderAttachmentTypes && getAttachmentTypeOptions(folderAttachmentTypes);

    const validation = (value: string): void => {

        if (value && value.match(constants.FILE_NAME_REGEX)) {
            onChangeExportFileNameValidation(labels.invalidFilenameLabel[langRule]);
        } else if (value &&
            !value.match(constants.FILE_NAME_REGEX) && value.length > 251) {
            onChangeExportFileNameValidation(labels.fileExceedLengthLabel[langRule]);
        } else if (value) {
            onChangeExportFileNameValidation('');
        }

        if (!value) {
            onChangeExportFileNameValidation('');
        }
    };

    return (
        <div className='pagination-tab tab'>
            <div className='radio-wrapper'>
                <FormLabel>{labels.exportDestinationLabel[langRule]}: </FormLabel>
                <RadioMaterialBuilder
                    value={exportDestinationOptions[constants.EXPORT_DESTINATION_PARAM]}
                    handleRadioChange={handleChange}
                    paramName={constants.EXPORT_DESTINATION_PARAM}
                    ariaLabel={constants.EXPORT_DESTINATION_PARAM}
                    radioList={constants.exportDestinationOptions(labels, langRule)}
                />
            </div>
            {
                isProcess &&
                <>
                    {
                        createPacakageAsSingleFile &&
                        <>
                            <TextField
                                disabled={!createPacakageAsSingleFile}
                                type={'string'}
                                name={constants.EXPORT_FILE_NAME_PARAM}
                                value={exportFilename}
                                variant='outlined'
                                label={labels.exportFilenameLabel[langRule]}
                                className='row_field border-bottom-solid attachment-detail'
                                onChange={(e: React.ChangeEvent<Element>): void => {
                                    handleExportFileNameChange(constants.EXPORT_FILE_NAME_PARAM,
                                        (e.target as HTMLInputElement).value);
                                    validation((e.target as HTMLInputElement).value);
                                }}
                                error={!!exportFilenameErrorMessage && !!exportFilename}
                                autoComplete={'off'}
                            />
                            <div className='row error_block'>
                                {
                                    exportFilenameErrorMessage &&
                                    exportFilename && (
                                        <Typography
                                            variant='caption'
                                            color='secondary'
                                        >
                                            {exportFilenameErrorMessage}
                                        </Typography>
                                    )
                                }
                            </div>
                        </>
                    }
                    <div className='select-group process-select'>
                        <SelectMaterialBuilder
                            value={exportDestinationOptions[constants.FOLDER_PARAM]}
                            disabled={!getFolderProcessOptions.length}
                            label={labels.attachToProcessLabel[langRule]}
                            paramName={constants.FOLDER_PARAM}
                            handleSelectChange={handleChange}
                            options={getFolderProcessOptions}
                        />
                        <Spinner active={processAttachmentTypesLoading} size={25} />
                        <SelectMaterialBuilder
                            value={attachmentTypeCode[constants.PROCESS_ATTACHMENT_TYPE_CODE_PARAM]}
                            disabled={!(getProcessAttachmentTypeOptionWithEmpty &&
                                getProcessAttachmentTypeOptionWithEmpty.length)}
                            label={labels.attachmentTypeLabel[langRule]}
                            paramName={constants.PROCESS_ATTACHMENT_TYPE_CODE_PARAM}
                            handleSelectChange={handleChange}
                            options={getProcessAttachmentTypeOptionWithEmpty}
                        />
                        <Spinner active={folderProcessesLoading} size={25} />
                    </div>
                    <div>
                        <TextField
                            type={'string'}
                            name={'Attachment Description'}
                            value={attachmentDescription}
                            variant='outlined'
                            label={labels.attachmentDescriptionLabel[langRule]}
                            className='row_field border-bottom-solid attachment-detail'
                            onChange={(e: React.ChangeEvent<Element>): void => {
                                handleExportAttachmentInfoChange(constants.ATTACHMENT_DESCRIPTION,
                                    (e.target as HTMLInputElement).value);
                            }}
                            autoComplete={'off'}
                        />
                    </div>
                    <div>
                        <TextField
                            type={'string'}
                            name={'Attachment Detail'}
                            value={attachmentDetail}
                            variant='outlined'
                            label={labels.attachmentDetailLabel[langRule]}
                            className='row_field border-bottom-solid attachment-detail desc'
                            onChange={(e: React.ChangeEvent<Element>): void => {
                                handleExportAttachmentInfoChange(constants.ATTACHMENT_DETAIL,
                                    (e.target as HTMLInputElement).value);
                            }}
                            autoComplete={'off'}
                            multiline={true}
                            minRows={'3'}
                        />
                    </div>
                </>
            }
            {
                isPrinter &&
                <div className='select-group'>
                    <SelectMaterialBuilder
                        value={exportDestinationOptions[constants.PRINTER_CODE]}
                        disabled={!getPrinterOptions.length}
                        label={labels.selectPrinterLabel[langRule]}
                        paramName={constants.PRINTER_CODE}
                        handleSelectChange={handleChange}
                        options={getPrinterOptions}
                    />
                </div>
            }
            {
                isTray &&
                <div className='select-group'>
                    <SelectMaterialBuilder
                        value={exportDestinationOptions[constants.PRINTER_TRAY_PARAM]}
                        disabled={!getTrayOptions(exportDestinationOptions[constants.PRINTER_CODE]).length}
                        label={labels.selectTrayLabel[langRule]}
                        paramName={constants.PRINTER_TRAY_PARAM}
                        handleSelectChange={handleChange}
                        options={getTrayOptions(exportDestinationOptions[constants.PRINTER_CODE])}
                    />
                </div>
            }
            {
                isRequestFolder &&
                <>
                    {
                    createPacakageAsSingleFile &&
                        <>

                            <TextField
                                disabled={!createPacakageAsSingleFile}
                                type={'string'}
                                name={constants.EXPORT_FILE_NAME_PARAM}
                                value={exportFilename}
                                variant='outlined'
                                label={labels.exportFilenameLabel[langRule]}
                                className='row_field border-bottom-solid attachment-detail'
                                onChange={(e: React.ChangeEvent<Element>): void => {
                                    handleExportFileNameChange(constants.EXPORT_FILE_NAME_PARAM,
                                        (e.target as HTMLInputElement).value);
                                    validation((e.target as HTMLInputElement).value);
                                }}
                                autoComplete={'off'}
                                error={!!exportFilenameErrorMessage && !!exportFilename}
                            />
                            <div className='row error_block'>
                                {
                                    exportFilenameErrorMessage &&
                                    exportFilename && (
                                        <Typography
                                            variant='caption'
                                            color='secondary'
                                        >
                                            {exportFilenameErrorMessage}
                                        </Typography>
                                    )
                                }
                            </div>
                        </>
                    }
                    <div className='select-group attachment-select'>
                        <SelectMaterialBuilder
                            value={attachmentTypeCode[constants.FOLDER_ATTACHMENT_TYPE_CODE_PARAM]}
                            disabled={!getFolderAttachmentTypeOptions.length}
                            label={labels.attachmentTypeLabel[langRule]}
                            paramName={constants.FOLDER_ATTACHMENT_TYPE_CODE_PARAM}
                            handleSelectChange={handleChange}
                            options={[...emptySelectOption, ...getFolderAttachmentTypeOptions]}
                        />
                    </div>
                    <div>
                        <TextField
                            type={'string'}
                            name={'Attachment Description'}
                            value={attachmentDescription}
                            variant='outlined'
                            label={labels.attachmentDescriptionLabel[langRule]}
                            className='row_field border-bottom-solid attachment-detail'
                            onChange={(e: React.ChangeEvent<Element>): void => {
                                handleExportAttachmentInfoChange(constants.ATTACHMENT_DESCRIPTION,
                                    (e.target as HTMLInputElement).value);
                            }}
                            autoComplete={'off'}
                        />
                    </div>
                    <div>
                        <TextField
                            type={'string'}
                            name={'Attachment Detail'}
                            value={attachmentDetail}
                            variant='outlined'
                            label={labels.attachmentDetailLabel[langRule]}
                            className='row_field border-bottom-solid attachment-detail desc'
                            onChange={(e: React.ChangeEvent<Element>): void => {
                                handleExportAttachmentInfoChange(constants.ATTACHMENT_DETAIL,
                                    (e.target as HTMLInputElement).value);
                            }}
                            autoComplete={'off'}
                            multiline={true}
                            minRows={'3'}
                        />
                    </div>
                </>
            }

            {
                isFile &&
                <>
                    <TextField
                        disabled={false}
                        type={'string'}
                        name={constants.EXPORT_FILE_NAME_PARAM}
                        value={exportFilename}
                        variant='outlined'
                        label={labels.exportFilenameLabel[langRule]}
                        className='row_field border-bottom-solid attachment-detail'
                        onChange={(e: React.ChangeEvent<Element>): void => {
                            handleExportFileNameChange(constants.EXPORT_FILE_NAME_PARAM,
                                (e.target as HTMLInputElement).value);
                            validation((e.target as HTMLInputElement).value);
                        }}
                        autoComplete={'off'}
                        error={!!exportFilenameErrorMessage && !!exportFilename}
                    />
                    <div className='row error_block'>
                        {
                            exportFilenameErrorMessage &&
                            exportFilename && (
                                <Typography
                                    variant='caption'
                                    color='secondary'
                                >
                                    {exportFilenameErrorMessage}
                                </Typography>
                            )
                        }
                    </div>
                </>
            }
        </div>
    );
};

export default ExportDestination;

import { action } from 'typesafe-actions';
import { AnyAction, Dispatch } from 'redux';
import { IState } from '../store';
import {
    GET_PAGE_METADATA_PENDING,
    GET_PAGE_METADATA_SUCCESS,
    GET_PAGE_METADATA_FAILURE,
    ADD_PAGE_METADATA_SUCCESS,
    UPDATE_PAGE_METADATA_PENDING,
    ADD_PAGE_METADATA_PENDING,
    ADD_PAGE_METADATA_FAILURE,
    UPDATE_PAGE_METADATA_SUCCESS,
    UPDATE_PAGE_METADATA_FAILURE,
    GET_PAGE_CONSULTEES_PENDING,
    GET_PAGE_CONSULTEES_SUCCESS,
    GET_PAGE_CONSULTEES_FAILURE,
    CLEAR_PAGE_CONSULTEES,
    DELETE_PAGE_CONSULTEES_PENDING,
    DELETE_PAGE_CONSULTEES_SUCCESS,
    DELETE_PAGE_CONSULTEES_FAILURE,
    CLEAR_PAGE_METADATA,
} from '../reducers/pageMetadata/constants';
import {
    NOTES_QUERY_PARAM,
    COMMENTS_QUERY_PARAM,
    RECOMMENDATIONS_QUERY_PARAM,
} from '../../constants/common.constants';
import { addError } from './errorHandling';
import { IError } from '../common.model';
import api from '../../api/reductionApi';
import { IConsulteesPages, IPageMetadataType } from '../reducers/pageMetadata/notes.model';
import { get } from 'lodash';

export const getPageConsulteesPending = (): AnyAction => action(GET_PAGE_CONSULTEES_PENDING);
export const getPageConsulteesSuccess =
    (data: IConsulteesPages): AnyAction => action(GET_PAGE_CONSULTEES_SUCCESS, data);
export const getPageConsulteesFailure = (error: IError): AnyAction => action(GET_PAGE_CONSULTEES_FAILURE, error);
export const clearPageConsultees = (): AnyAction => action(CLEAR_PAGE_CONSULTEES);

export const deletePageConsulteesPending = (): AnyAction => action(DELETE_PAGE_CONSULTEES_PENDING);
export const deletePageConsulteesSuccess = (pageId: number, consulteeId: number): AnyAction =>
    action(DELETE_PAGE_CONSULTEES_SUCCESS, {pageId, consulteeId});
export const deletePageConsulteesFailure = (error: IError): AnyAction => action(DELETE_PAGE_CONSULTEES_FAILURE, error);

export const addPageMetaDataPending = (): AnyAction => action(ADD_PAGE_METADATA_PENDING);
export const addPageMetaDataSuccess = (data: IPageMetadataType): AnyAction => action(ADD_PAGE_METADATA_SUCCESS, data);
export const addPageMetaDataFailure = (error: IError): AnyAction => action(ADD_PAGE_METADATA_FAILURE, error);
export const clearPageMetaData = (): AnyAction => action(CLEAR_PAGE_METADATA);

export const updatePageMetaDataPending = (): AnyAction => action(UPDATE_PAGE_METADATA_PENDING);
export const updatePageMetaDataSuccess = (data: IPageMetadataType): AnyAction =>
    action(UPDATE_PAGE_METADATA_SUCCESS, data);
export const updatePageMetaDataFailure = (error: IError): AnyAction => action(UPDATE_PAGE_METADATA_FAILURE, error);

export const getPageMetaDataPending = (): AnyAction => action(GET_PAGE_METADATA_PENDING);
export const getPageMetaDataSuccess = (data: {
    notes: IPageMetadataType;
    comments: IPageMetadataType;
    recommendations: IPageMetadataType;
}): AnyAction => action(GET_PAGE_METADATA_SUCCESS, data);
export const getPageMetaDataFailure = (error: IError): AnyAction => action(GET_PAGE_METADATA_FAILURE, error);

export const getPageMetaData = (
    dataType?: string,
): (dispatch: Dispatch, getState: () => IState) =>
    void => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: { redactionDocumentId },
        } = getState();

        dispatch(getPageMetaDataPending());

        const pageId = get(getState().pageList.currentPage, 'id');
        const documentId = get(getState().pageList.currentDocument, 'id');

        if (!pageId || !documentId) {
            return;
        }

        try {
            const response = await api.pageMetadataController.getPageMetadata(
                redactionDocumentId,
                documentId,
                pageId,
                dataType,
            );
            const getMetadataType = (type: string): IPageMetadataType =>
                response.find((data: IPageMetadataType): boolean => data.dataType === type);

            dispatch(getPageMetaDataSuccess({
                notes: getMetadataType(NOTES_QUERY_PARAM),
                comments: getMetadataType(COMMENTS_QUERY_PARAM),
                recommendations: getMetadataType(RECOMMENDATIONS_QUERY_PARAM),
            }));
        } catch (error) {
            dispatch(getPageMetaDataFailure(error));
            dispatch(addError(error));
        }
    };

export const addPageMetaData = (
    data: IPageMetadataType,
): (dispatch: Dispatch, getState: () => IState) =>
    void => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: { redactionDocumentId },
            pageList: {
                currentDocument: {
                    id: documentId,
                },
                currentPage: {
                    id: pageId,
                },
            },
        } = getState();

        dispatch(addPageMetaDataPending());

        try {
            const response = await api.pageMetadataController.addPageMetadata(
                redactionDocumentId,
                documentId,
                pageId,
                data,
            );

            dispatch(addPageMetaDataSuccess(response));
        } catch (error) {
            dispatch(addPageMetaDataFailure(error));
            dispatch(addError(error));
        }
    };

export const updatePageMetaData = (
    data: IPageMetadataType,
): (dispatch: Dispatch, getState: () => IState) =>
    void => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: { redactionDocumentId },
            pageList: {
                currentDocument: {
                    id: documentId,
                },
                currentPage: {
                    id: pageId,
                },
            },
        } = getState();

        dispatch(updatePageMetaDataPending());

        try {
            const response = await api.pageMetadataController.updatePageMetadata(
                redactionDocumentId,
                documentId,
                pageId,
                data,
            );

            dispatch(updatePageMetaDataSuccess(response));
        } catch (error) {
            dispatch(updatePageMetaDataFailure(error));
            dispatch(addError(error));
        }
    };

export const getPageConsultee = (
    redactionDocId: number,
    documentId: number,
    pageId: number,
): (dispatch: Dispatch) => void => async (dispatch: Dispatch): Promise<void> => {

    dispatch(getPageConsulteesPending());

    try {
        const response = await api.consultee.getPageConsultee(redactionDocId, documentId, pageId);

        dispatch(getPageConsulteesSuccess({id: pageId, consultees: response}));
    } catch (error) {
        dispatch(getPageConsulteesFailure(error));
        dispatch(addError(error));
    }
};

export const deletePageConsultee = (
    redactionDocId: number,
    documentId: number,
    pageId: number,
    consulteeId: number,
): (dispatch: Dispatch) => void => async (dispatch: Dispatch): Promise<void> => {

    dispatch(deletePageConsulteesPending());

    try {
        await api.consultee.deletePageConsultee(redactionDocId, documentId, pageId, consulteeId);

        dispatch(deletePageConsulteesSuccess(pageId, consulteeId));
    } catch (error) {
        dispatch(deletePageConsulteesFailure(error));
        dispatch(addError(error));
    }
};

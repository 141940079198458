import * as React from 'react';
import { useSelector } from '../../../node_modules/react-redux';
import QueueManagementContainer from '../queueManagment/QueueManagementContainer';
import GlobalSearch from '../globalSearch/GlobalSearch';
import AdvancedSearch from '../advancedSearch/AdvancedSearch';
import { getRightTopBarPermission } from '../../redux/selectors/initialization';
import { QUEUE_MANAGEMENT, SEARCH, ADVANCED_SEARCH } from '../../constants/taskQueue.constants';
import SimpleTabs from '../../components/simpleTabs/SimpleTabs';
import './rightTopBar.styles.scss';
import { getPanelSizes, getRedactionLanguage } from '../../redux/selectors/localStorage';
import { getModifiedLabels } from '../../redux/selectors/localization';
import { ILabel } from '../../redux/reducers/localization/localization.model';
import resourceBundle from '../localization/localizationData';

export const RightTopBar = (): JSX.Element => {
    const permissions = useSelector(getRightTopBarPermission);
    const panelSizes = useSelector(getPanelSizes);
    const width = panelSizes.rightPanel.barWidth;
    const redactionLang = useSelector(getRedactionLanguage);
    const modifiedLabels = useSelector(getModifiedLabels);
    let docTitle;
    let advancedSearchTitle;
    let simpleSearchTitle;

    resourceBundle.map((resource: ILabel) => {
        if(resource.resourceKey === 'DOCUMENTQUEUE_TITLE') {
            docTitle = resource;
        } else if (resource.resourceKey === 'ADVANCEDSEARCH_TITLE') {
            advancedSearchTitle = resource;
        } else if (resource.resourceKey === 'SIMPLESEARCH_TITLE') {
            simpleSearchTitle = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: ILabel) => {
        if(resource.resourceKey === 'DOCUMENTQUEUE_TITLE') {
            docTitle = resource;
        } else if (resource.resourceKey === 'ADVANCEDSEARCH_TITLE') {
            advancedSearchTitle = resource;
        } else if (resource.resourceKey === 'SIMPLESEARCH_TITLE') {
            simpleSearchTitle = resource;
        }

        return resource;
    });

    return (
        <div className='details'>
            { redactionLang && <SimpleTabs
                defaultIndex={0}
                widthTabs={width}
            >
                <QueueManagementContainer
                    title={redactionLang === 'en' ? docTitle.resourceValue : docTitle.resourceValue2}
                    isValid={true}
                    disabled={false}
                />
                <GlobalSearch
                    title={ simpleSearchTitle[redactionLang ? redactionLang === 'fr' ? 'resourceValue2'
                        : 'resourceValue' :'resourceValue']}
                    isValid={true}
                    disabled={!permissions.search}
                />
                <AdvancedSearch
                    title={redactionLang === 'en'
                        ? advancedSearchTitle.resourceValue
                        : advancedSearchTitle.resourceValue2}
                    isValid={true}
                    disabled={!permissions.search}
                />
            </SimpleTabs>}
        </div>
    );
};

export default RightTopBar;

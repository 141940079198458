import { AnyAction, Dispatch } from 'redux';
import api from '../../api/reductionApi';
import { action } from 'typesafe-actions';
import { SET_SCANNER_SETTINGS } from '../reducers/scanner/constant';

const setScannerSettings = (data: any): AnyAction => action(SET_SCANNER_SETTINGS, data);

export const fetchScannerSettings = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            const response = await api.scanner.getScannerSettings();

            dispatch(setScannerSettings(response));
        } catch (error) {
        //  TODO add error handler
        }
    };

import {
    GET_STACK_LIST_PENDING,
    GET_STACK_LIST_SUCCESS,
    GET_STACK_LIST_FAILURE,
    DELETE_STACK_PENDING,
    DELETE_STACK_SUCCESS,
    DELETE_STACK_FAILURE,
    POST_STACK_PENDING,
    POST_STACK_SUCCESS,
    POST_STACK_FAILURE,
    PUT_STACK_PENDING,
    PUT_STACK_SUCCESS,
    PUT_STACK_FAILURE,
    CHANGE_OPTIONS_STACK,
    CLEAR_OPTIONS_STACK,
    SET_OPTIONS_STACK,
    GET_STACK_PROPERTY_LIST_PENDING,
    GET_STACK_PROPERTY_LIST_SUCCESS,
    GET_STACK_PROPERTY_LIST_FAILURE,
    DELETE_STACK_PROPERTY_PENDING,
    DELETE_STACK_PROPERTY_SUCCESS,
    DELETE_STACK_PROPERTY_FAILURE,
    POST_STACK_PROPERTY_PENDING,
    POST_STACK_PROPERTY_SUCCESS,
    POST_STACK_PROPERTY_FAILURE,
    PUT_STACK_PROPERTY_PENDING,
    PUT_STACK_PROPERTY_SUCCESS,
    PUT_STACK_PROPERTY_FAILURE,
    CHANGE_OPTIONS_STACK_PROPERTY,
    SET_OPTIONS_STACK_PROPERTY,
    UPDATE_STACK_DOCUMENT_RELATION_PENDING,
    UPDATE_STACK_DOCUMENT_RELATION_SUCCESS,
    UPDATE_STACK_DOCUMENT_RELATION_FAILURE,
    CLEAR_OPTIONS_STACK_PROPERTY,
} from './constants';
import { IStackOptions, IStackListState, IStackPropertyOptions } from './stackList.model';
import { AnyAction } from 'redux';
import { NAME, DESCRIPTION, STACK_PROPERTY_ID } from '../../../constants/stack.contstants';
import { ID } from '../../../constants/common.constants';

export const initialStackOptions: IStackOptions =  {
    [ID]: null,
    [NAME]: '',
    [DESCRIPTION]: '',
    [STACK_PROPERTY_ID]: null,
};
export const initialStackPropertyOptions: IStackPropertyOptions =  {
    [ID]: null,
    [NAME]: '',
    [DESCRIPTION]: '',
};
const initialState: IStackListState = {
    stackList: [],
    stackListPending: false,
    stackListUpdatePending: false,
    error: null,
    stackOptions: initialStackOptions,
    stackPropertyList: [],
    stackPropertyListPending: false,
    stackPropertyListUpdatePending: false,
    stackPropertyOptions: initialStackPropertyOptions,
    updateStackDocumentPending: false,
};

const sortByName = <T extends object>(arr: T[]): T[] => {
    return arr.sort((a: T, b: T) => a[NAME].localeCompare(b[NAME]));
};

const stackListReducer = (
    state: IStackListState = initialState,
    {type, payload}: AnyAction,
): IStackListState => {
    switch (type) {
        case GET_STACK_LIST_PENDING:
            return {
                ...state,
                stackListPending: true,
                error: null,
            };
        case GET_STACK_LIST_SUCCESS:
            return {
                ...state,
                stackList: sortByName([...payload]),
                stackListPending: false,
            };
        case GET_STACK_LIST_FAILURE:
            return{
                ...state,
                error: payload,
                stackListPending: false,
            };
        case POST_STACK_PENDING:
        case PUT_STACK_PENDING:
        case DELETE_STACK_PENDING:
            return {
                ...state,
                stackListUpdatePending: true,
                error: null,
            };
        case POST_STACK_SUCCESS:
            return{
                ...state,
                stackList: sortByName([...state.stackList, payload]),
                stackListUpdatePending: false,
                error: null,
            };
        case POST_STACK_FAILURE:
        case DELETE_STACK_FAILURE:
        case PUT_STACK_FAILURE:
            return{
                ...state,
                error: payload,
                stackListUpdatePending: false,
            };
        case PUT_STACK_SUCCESS:
            return{
                ...state,
                stackList: sortByName(state.stackList.map((item: IStackOptions) =>
                    item.id !== payload.id ? item : payload,
                )),
                stackListUpdatePending: false,
            };
        case DELETE_STACK_SUCCESS:
            return{
                ...state,
                stackList: sortByName([...state.stackList.filter(
                    ( disclosure: IStackOptions): boolean =>  disclosure.id !== payload,
                )]),
                stackListUpdatePending: false,
            };
        case SET_OPTIONS_STACK:
            return {
                ...state,
                stackOptions: {...payload},
            };
        case CHANGE_OPTIONS_STACK:
            return {
                ...state,
                stackOptions: {
                    ...state.stackOptions,
                    ...payload,
                },
            };
        case CLEAR_OPTIONS_STACK:
            return {
                ...state,
                stackOptions: initialStackOptions,
            };
        case CLEAR_OPTIONS_STACK_PROPERTY:
            return {
                ...state,
                stackPropertyOptions: initialStackPropertyOptions,
            };
        case GET_STACK_PROPERTY_LIST_PENDING:
            return {
                ...state,
                stackPropertyListPending: true,
                error: null,
            };
        case GET_STACK_PROPERTY_LIST_SUCCESS:
            return {
                ...state,
                stackPropertyList: sortByName([...payload]),
                stackPropertyListPending: false,
            };
        case GET_STACK_PROPERTY_LIST_FAILURE:
            return{
                ...state,
                error: payload,
                stackPropertyListPending: false,
            };
        case POST_STACK_PROPERTY_PENDING:
        case PUT_STACK_PROPERTY_PENDING:
        case DELETE_STACK_PROPERTY_PENDING:
            return {
                ...state,
                stackPropertyListUpdatePending: true,
                error: null,
            };
        case POST_STACK_PROPERTY_SUCCESS:
            return{
                ...state,
                stackPropertyList: sortByName([...state.stackPropertyList, payload]),
                stackPropertyListUpdatePending: false,
                error: null,
            };
        case POST_STACK_PROPERTY_FAILURE:
        case DELETE_STACK_PROPERTY_FAILURE:
        case PUT_STACK_PROPERTY_FAILURE:
            return{
                ...state,
                error: payload,
                stackPropertyListUpdatePending: false,
            };
        case PUT_STACK_PROPERTY_SUCCESS:
            return{
                ...state,
                stackPropertyList: sortByName(state.stackPropertyList.map((item: IStackPropertyOptions) =>
                    item.id !== payload.id ? item : payload,
                )),
                stackPropertyListUpdatePending: false,
            };
        case DELETE_STACK_PROPERTY_SUCCESS:
            return{
                ...state,
                stackPropertyList: sortByName([...state.stackPropertyList.filter(
                    ( stackProperty: IStackPropertyOptions): boolean =>  stackProperty.id !== payload,
                )]),
                stackPropertyListUpdatePending: false,
            };
        case SET_OPTIONS_STACK_PROPERTY:
            return {
                ...state,
                stackPropertyOptions: {...payload},
            };
        case CHANGE_OPTIONS_STACK_PROPERTY:
            return {
                ...state,
                stackPropertyOptions: {
                    ...state.stackPropertyOptions,
                    ...payload,
                },
            };
        case UPDATE_STACK_DOCUMENT_RELATION_PENDING:
            return {
                ...state,
                updateStackDocumentPending: true,
            };
        case UPDATE_STACK_DOCUMENT_RELATION_SUCCESS:
        case UPDATE_STACK_DOCUMENT_RELATION_FAILURE:
            return {
                ...state,
                updateStackDocumentPending: false,
            };

        default:
            return state;
    }
};

export default stackListReducer;

import * as React from 'react';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import Table from '../../components/table/ServerSidePagingTable';
import {
    ILeftBottomBarProps,
    ILeftBottomBarState,
    ILeftBottomBarStateProps,
    ILeftBottomBarDispatchProps,
    ILeftBottomBarThunkDispatch,
    IUpdatedPages, ITableSort,
} from './leftBottomBar.model';
import {
    getCurrentDocument,
    getCurrentPage,
    getPageListLoading,
    getSelectedPages,
    getPageMetadataInfo,
    getLoadingPageOrdering, getCurrentPageSort,
    getCurrentPageNumber, getPageListColumnHeader,
    getResetedPageListColumnHeaderLoading,
} from '../../redux/selectors/pageList';
import {
    changeDocumentOrder,
    setPagesRangeToSelectedList,
    setPageToSelectedList,
    getExemptionCurrentDocument,
    fetchPaginatedPages, setPageNumber,
    setSortParam, setPageListTableColumns,
    modifyPageListColumnHeader, getResetedPageListColumnHeader, setFirstPage,
} from '../../redux/actions/pageList';
import { getPageResource, clearOptionsState } from '../../redux/actions/redactor';
import { closeContextMenu, openContextMenu } from '../../redux/actions/contextMenu';
import { PAGE_LIST_CONTEXT_MENU } from '../../constants/contextmenu/context.menu.constants';
import { PAGE_LIST_LABEL } from '../../constants/leftSidebar.config';
import { Spinner } from '../../components/spinner/Spinner';
import { getRedactionDocumentId, getUserMetaData } from '../../redux/selectors/initialization';
import { IState } from '../../redux/store';
import { IModalIdsData } from '../../redux/reducers/modal/modal.model';
import { Row, Column, Cell } from 'react-table';
import { IDroppedData, ITrProps, MyRowData } from '../../components/table/table.model';
import { IPages } from '../../redux/reducers/pageList/pageList.model';
import { setCurrentSearchElement, setElementSearchDirection } from '../../redux/actions/globalSearch';
import { ICurrentSearchElement } from '../globalSearch/globalSearch.model';
import { clearPageConsultees, getPageConsultee } from '../../redux/actions/pageMetadata';
import { getContextMenuStartPoint, getDocumentLoading } from '../../redux/selectors/redactor';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { DESC, ASC, NOT_APPLICABLE, PAGE } from '../../constants/common.constants';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { openModalWindow } from '../../redux/actions/modal';
import {
    ALERT_DIALOG_MODAL, DRAGGING_NONE_SELECTED_PAGE_WARNING,
    DRAGGING_PAGE_IN_PRESENT_BLOCK_PAGE_WARNING, SORTING_NOT_APPLICABLE_WARNING, WARNING,
} from '../../constants/messages.constants';
import { getAdvanceSearchedSelectedDocumentDetails } from '../../redux/selectors/advanceSearch';
import { getRestrictFlagForPageListFetchData, getSearchElementDirection } from '../../redux/selectors/globalSearch';
import { getRedactionLanguage, getRedactionMode } from '../../redux/selectors/localStorage';
import { MODE_CONSULT } from '../../redux/reducers/localStorage/constant';
import { getModifiedLabels } from '../../redux/selectors/localization';
import { initialLabel } from '../../constants/localization.constants';
import { changeLang } from '../../redux/actions/localization';
import resourceBundle from '../../containers/localization/localizationData';

export const DEFAULT_PAGE_SIZE = 50;
export interface SortingRule {
    id: string;
    desc: boolean;
}
export const setDefaultSortOrder = (columns: IHeader[]): SortingRule => {
    const columnsSorted: SortingRule[] = [];

    if (columns && columns.length) {
        columns.map((columnHeader: IHeader) => {
            if (columnHeader.sortOrder === DESC) {
                return columnsSorted.push({
                    id: columnHeader.accessor,
                    desc: true,
                });
            } else if (columnHeader.sortOrder === ASC) {
                return columnsSorted.push({
                    id: columnHeader.accessor,
                    desc: false,
                });
            }
        });
    }

    return columnsSorted[0];
};

const labels = {
    pageListTitleLabel: initialLabel,
    headerPage: initialLabel,
    headerPagination: initialLabel,
    headerOcr: initialLabel,
    headerDisclosure: initialLabel,
    headerArticle: initialLabel,
    headerDocDate: initialLabel,
    headerSubject: initialLabel,
    headerTo: initialLabel,
    headerToOrg: initialLabel,
    headerFrom: initialLabel,
    headerFromOrg: initialLabel,
};

const getPageLabelByKey = (key: string): string => {
    switch (key) {
        case 'PAGE_LIST_TITLE':
            return 'pageListTitleLabel';
        case 'PAGE_LIST_COLUMN_HEADER_PAGE':
            return 'headerPage';
        case 'PAGE_LIST_COLUMN_HEADER_PAGINATION':
            return 'headerPagination';
        case 'PAGE_LIST_COLUMN_HEADER_OCR':
            return 'headerOcr';
        case 'PAGE_LIST_COLUMN_HEADER_DISCLOSURE':
            return 'headerDisclosure';
        case 'PAGE_LIST_COLUMN_HEADER_ARTICLE':
            return 'headerArticle';
        case 'PAGE_LIST_COLUMN_HEADER_DOC_DATE':
            return 'headerDocDate';
        case 'PAGE_LIST_COLUMN_HEADER_SUBJECT':
            return 'headerSubject';
        case 'PAGE_LIST_COLUMN_HEADER_TO':
            return 'headerTo';
        case 'PAGE_LIST_COLUMN_HEADER_TO_ORG':
            return 'headerToOrg';
        case 'PAGE_LIST_COLUMN_HEADER_FROM':
            return 'headerFrom';
        case 'PAGE_LIST_COLUMN_HEADER_FROM_ORG':
            return 'headerFromOrg';
        default: return '';
    }
}

export const changeDefaultSortOrder = (newSorted: SortingRule[], columns: IHeader[]): IHeader[] => {
    if (columns && columns.length) {
        columns.map((columnHeader: IHeader): void => {
            if (newSorted.length && columnHeader.accessor === newSorted[0].id && newSorted[0].desc) {
                columnHeader.sortOrder = DESC;
            } else if (newSorted.length && columnHeader.accessor === newSorted[0].id && !newSorted[0].desc) {
                columnHeader.sortOrder = ASC;
            } else {
                columnHeader.sortOrder = null;
            }
        });
    }

    return columns;
};

class LeftBottomBar extends React.Component<ILeftBottomBarProps, ILeftBottomBarState> {
    constructor(props: ILeftBottomBarProps) {
        super(props);
    }

    public componentDidUpdate(prevProps: ILeftBottomBarProps): void {
        const { pageMetadataInfo, selectedPage, currentDocument } = this.props;

        if (!isEqual(prevProps.currentDocument, currentDocument) && currentDocument && currentDocument.pages) {

            const exemptionsIds = currentDocument.pages.length &&
                currentDocument.pages.reduce((ids: number[], page: IPages) =>
                    ([...ids, ...page.exemptionIds]), []);

            this.props.getExemptionCurrentDocument(exemptionsIds);

        }

        if (prevProps.selectedPage && selectedPage && prevProps.selectedPage.id !== selectedPage.id) {
            this.setPaginationPage(pageMetadataInfo.length > DEFAULT_PAGE_SIZE);
        }

        if (prevProps.currentDocument && currentDocument && prevProps.currentDocument.id !== currentDocument.id) {
            this.props.setPageNumber(0);
        }

        const { redactionLang, modifiedLabels, pageListColumnHeader } = this.props;
        const langRule = changeLang(redactionLang);
        const pageListLabels = (key: string): string => {
            switch (key) {
                case 'page':
                    return labels.headerPage[langRule];
                case 'pagination':
                    return labels.headerPagination[langRule];
                case 'ocr':
                    return labels.headerOcr[langRule];
                case 'disclosureType':
                    return labels.headerDisclosure[langRule];
                case 'article':
                    return labels.headerArticle[langRule];
                case 'documentDate':
                    return labels.headerDocDate[langRule];
                case 'subject':
                    return labels.headerSubject[langRule];
                case 'to':
                    return labels.headerTo[langRule];
                case 'toOrg':
                    return labels.headerToOrg[langRule];
                case 'from':
                    return labels.headerFrom[langRule];
                case 'fromOrg':
                    return labels.headerFromOrg[langRule];
                default: return '';
            }
        }
        pageListColumnHeader.map((columnHeader) => {
            columnHeader.Header = pageListLabels(columnHeader.id);
        });

        resourceBundle.map((resource: any) => {
            if (getPageLabelByKey(resource.resourceKey)) {
                labels[getPageLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getPageLabelByKey(resource.resourceKey)) {
                labels[getPageLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

    }

    public render(): JSX.Element {
        const {
            currentDocument,
            selectedPage,
            pageMetadataInfo,
            selectedMultiplePages,
            pageNumber,
            pageSort,
            isPDFLoading,
            advanceSearchedSelectedDocument,
            restrictOnFetchData,
            contextMenuStartPoint,
            mode,
            redactionLang,
            modifiedLabels
        } = this.props;

        const pageCount = currentDocument && (currentDocument as any).pageCount || 0;
        const sortedData = pageMetadataInfo ? pageMetadataInfo : [];
        const minTableRow = sortedData.length > DEFAULT_PAGE_SIZE ? DEFAULT_PAGE_SIZE : sortedData.length;
        const langRule = changeLang(redactionLang);
        resourceBundle.map((resource: any) => {
            if (getPageLabelByKey(resource.resourceKey)) {
                labels[getPageLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        modifiedLabels.map((resource: any) => {
            if (getPageLabelByKey(resource.resourceKey)) {
                labels[getPageLabelByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        const onFetchdata = (page: any): void => {

            if (pageNumber !== page && !advanceSearchedSelectedDocument && !restrictOnFetchData) {
                this.props.fetchPages(page);
            }

        };

        const onSortChange = (newSort: ITableSort[]): void => {
            this.props.setPageListTableColumns(this.props.pageListColumnHeader);
            setPageNumber(0);
            this.props.setSortParam(newSort[0]);
            this.props.modifyPageListTableColumnHeader(
            changeDefaultSortOrder(newSort, this.props.pageListColumnHeader), this.props.userMetaData.userId);
            this.props.fetchPages(0);
        };
        const mouseDownHandler = this.selectPageNumber.bind(this);

        if (currentDocument && selectedPage) {
            return (
                <div className='sidebar-bottom-wr'>
                    {
                        this.props.resetedPageListColumnLoading ?
                            <Spinner active={true} /> :
                            !this.props.loadingPageList ?
                                <Table
                                    isPDFLoading={isPDFLoading}
                                    page={this.props.pageNumber}
                                    data={sortedData}
                                    columns={this.props.pageListColumnHeader}
                                    onPageChange={(page: number): void => this.changePageNumber(page)}
                                    getTrProps={(rowInfo: any): ITrProps => this.getTrProps(rowInfo)}
                                    contextMenuId={PAGE_LIST_CONTEXT_MENU}
                                    selectedIds={selectedMultiplePages}
                                    showPagination={pageCount > DEFAULT_PAGE_SIZE}
                                    allowSettings={true}
                                    title={labels.pageListTitleLabel[langRule]}
                                    isDraggable={mode === MODE_CONSULT ? false : true}
                                    mode={mode}
                                    handleDragEnd={this.handleDragEnd}
                                    loading={this.props.loadingPageOrdering}
                                    serverPagination={true}
                                    pageSize={DEFAULT_PAGE_SIZE}
                                    defaultPageSize={DEFAULT_PAGE_SIZE}
                                    onFetchData={onFetchdata}
                                    pagesCount={pageCount}
                                    sortChange={onSortChange}
                                    mouseDown={mouseDownHandler}
                                    dynamicColumns={this.props.pageListColumnHeader}
                                    changeColumns={this.props.setPageListTableColumns}
                                    userPreferenceTable={true}
                                    enableShowHideColumn={true}
                                    modifyColumns={this.props.modifyPageListTableColumnHeader}
                                    userId={this.props.userMetaData.userId}
                                    resetColumnHeader={this.props.getResetedPageListColumnHeader}
                                    defaultColumnSorted={pageSort ? [pageSort] : []}
                                    pageMenuStartPoint={contextMenuStartPoint}
                                />
                                :
                                <Spinner active={true} />

                    }
                </div>
            );
        }

        return null;
    }

    private recalculatePageNumber = (data: IDroppedData, pages: IPages[], selectedMultiplePages: number[]): number => {
        const { pageNumber, prevPageNumber } = data;
        const startRange = pages.filter((page: IPages) => page.actualPageNumber <= pageNumber);
        const countOfSelectedPages = startRange.filter(
            (page: IPages) => selectedMultiplePages.includes(page.id),
        ).length;

        if (pageNumber <= prevPageNumber) {
            return pageNumber + 1 - countOfSelectedPages;
        } else {
            return pageNumber + 2 - countOfSelectedPages;
        }
    }

    private calculatePagePosition = (pagePosition: number): number => {
        const { pageNumber, currentDocument: { pageCount }, pageSort } = this.props;

        if (pageSort && pageSort.desc) {
            return pageCount + 1 - (pageNumber > 0 ? (pageNumber * DEFAULT_PAGE_SIZE + pagePosition) : pagePosition);
        } else {
            return pageNumber > 0 ? (pageNumber * DEFAULT_PAGE_SIZE + pagePosition) : pagePosition;
        }
    }

    private getDataList = (selectedMultiplePages: number[], pages: IPages[], data: IDroppedData): IUpdatedPages[] => {
        const startPageNumber = this.recalculatePageNumber(data, pages, selectedMultiplePages);

        return pages.filter((page: IPages) => selectedMultiplePages.includes(page.id))
            .map((page: IPages, idx: number): IUpdatedPages => ({
                pageNumber: this.calculatePagePosition(startPageNumber + idx),
                pageId: page.id,
            }),
            );
    }

    private handleDragEnd = (data: IDroppedData): boolean => {
        const { redactionDocumentId,
            currentDocument: { id, pages, pageCount },
            selectedMultiplePages,
            pageNumber,
            pageSort } = this.props;
        const pagePosition = data.pageNumber + 1;
        let isError = false;

        if (!pageSort || (pageSort && pageSort.id === PAGE)) {
            const isSelectedeveryPageInBlock = pages.filter((page: IPages) =>
                selectedMultiplePages.includes(page.id)).map((page: IPages) => page.id);

            if (!selectedMultiplePages.includes(data.id)) {
                isError = true;
                this.props.openModalWindow(ALERT_DIALOG_MODAL, {
                    id: NOT_APPLICABLE,
                    title: WARNING,
                    message: DRAGGING_NONE_SELECTED_PAGE_WARNING,
                });
            } else if (selectedMultiplePages && isSelectedeveryPageInBlock &&
                selectedMultiplePages.length && isSelectedeveryPageInBlock.length &&
                selectedMultiplePages.length !== isSelectedeveryPageInBlock.length) {
                    isError = true;
                this.props.openModalWindow(ALERT_DIALOG_MODAL, {
                    id: NOT_APPLICABLE,
                    title: WARNING,
                    message: DRAGGING_PAGE_IN_PRESENT_BLOCK_PAGE_WARNING,
                });
            } else if (selectedMultiplePages.includes(data.id) && pages.length !== selectedMultiplePages.length) {
                const movedPages = selectedMultiplePages.length > 1 ?
                    this.getDataList(selectedMultiplePages, pages, data) : [{
                        pageNumber: this.calculatePagePosition(pagePosition),
                        pageId: data.id,
                    }];

                this.props.changeDocumentOrder(redactionDocumentId, id, movedPages, pageNumber);
            }

        } else {
            isError = true;
            this.props.openModalWindow(ALERT_DIALOG_MODAL, {
                id: NOT_APPLICABLE,
                title: WARNING,
                message: SORTING_NOT_APPLICABLE_WARNING,
            });
        }
        return isError;

    }

    private changePageNumber = (page: number): void => {
        if (this.props.restrictOnFetchData) {
            this.props.setPageNumber(page);
            this.props.fetchPages(page);
        } else {
            this.props.setPageNumber(page);
        }
    }

    private setPaginationPage = (showPagination: boolean): void => {
        const { currentDocument, selectedPage } = this.props;

        if (!showPagination) {
            return null;
        }

        const pageIndex = currentDocument.pages.findIndex((item: IPages): boolean => item.id === selectedPage.id);

        this.props.setPageNumber(Math.floor(pageIndex / 100));
    }

    private getTrProps(rowInfo: any): ITrProps {
        const { selectedMultiplePages } = this.props;
        const id = get(rowInfo, 'original.id', null);
        this.selectPageNumber(id, false, rowInfo.original.rotation, false, false);
        if (selectedMultiplePages.length && selectedMultiplePages.includes(id)) {
            this.onContextMenu(PAGE_LIST_CONTEXT_MENU, selectedMultiplePages);
        } else {
            this.onContextMenu(PAGE_LIST_CONTEXT_MENU, [id]);
        }

        return {
            id,
            rowInfo,
            onContextMenu: (): void => {
                if (selectedMultiplePages.length && selectedMultiplePages.includes(id)) {
                    this.onContextMenu(PAGE_LIST_CONTEXT_MENU, selectedMultiplePages);
                } else {
                    this.onContextMenu(PAGE_LIST_CONTEXT_MENU, [id]);
                }
            },
        };
    }

    private selectPageNumber(
        pageId: number, isMultiple: boolean = false, rotation: number,
        isRange: boolean = false, isContext: boolean): void {
        const { selectedMultiplePages, currentDocument: { id: documentId, pages } } = this.props;
        const { redactionDocumentId } = this.props;

        if (this.props.currentSearchElementDirection) {
            this.props.setCurrentSearchElementDirection(null);
        }

        if (isContext && selectedMultiplePages.includes(pageId)) {
            return;
        }

        if (!isMultiple && !isRange && selectedMultiplePages && selectedMultiplePages[0] !== pageId) {
            this.props.getPageResource(this.props.redactionDocumentId, documentId, pageId, rotation);
            this.props.clearOptionsState();
            this.props.clearPageConsultees();
            this.props.setFirstPage(null);
        }

        if (isRange && selectedMultiplePages) {
            this.props.onSetPagesRangeToSelectedList(pageId, pages, selectedMultiplePages);
        } else {
            this.props.setPageToSelectedList(pageId, isMultiple);
            this.props.setCurrentSearchElement(null);
        }

        this.props.getPageConsultee(redactionDocumentId, documentId, pageId);
    }

    private onContextMenu(type: string, ids: number[]): void {
        const { id: documentId } = this.props.currentDocument;

        this.props.onContextMenu(type, {
            pageIds: ids,
            documentIds: [documentId],
            currentDocId: documentId,
        });
    }
}

const mapStateToProps = (state: IState): ILeftBottomBarStateProps => ({
    currentDocument: getCurrentDocument(state),
    selectedPage: getCurrentPage(state),
    selectedMultiplePages: getSelectedPages(state),
    loadingPageList: getPageListLoading(state),
    pageMetadataInfo: getPageMetadataInfo(state),
    redactionDocumentId: getRedactionDocumentId(state),
    loadingPageOrdering: getLoadingPageOrdering(state),
    pageNumber: getCurrentPageNumber(state),
    pageSort: getCurrentPageSort(state),
    isPDFLoading: getDocumentLoading(state),
    pageListColumnHeader: getPageListColumnHeader(state),
    userMetaData: getUserMetaData(state),
    resetedPageListColumnLoading: getResetedPageListColumnHeaderLoading(state),
    advanceSearchedSelectedDocument: getAdvanceSearchedSelectedDocumentDetails(state),
    restrictOnFetchData: getRestrictFlagForPageListFetchData(state),
    currentSearchElementDirection: getSearchElementDirection(state),
    contextMenuStartPoint: getContextMenuStartPoint(state),
    mode: getRedactionMode(state),
    redactionLang: getRedactionLanguage(state),
    modifiedLabels: getModifiedLabels(state),
});

const mapDispatchToProps = (dispatch: ILeftBottomBarThunkDispatch): ILeftBottomBarDispatchProps => ({
    getPageResource: (redactionDocumentId: number, id: number, pageNumber: number, rotation?: number): void => {
        dispatch(getPageResource(redactionDocumentId, id, pageNumber, rotation));
    },
    changeDocumentOrder: (
        redactionDocumentId: number,
        documentId: number,
        pageList: IUpdatedPages[],
        pageNumber: number,
    ): void => {
        dispatch(changeDocumentOrder(redactionDocumentId, documentId, pageList, pageNumber));
    },
    clearOptionsState: (): void => {
        dispatch(clearOptionsState());
    },
    setCurrentSearchElement: (element: ICurrentSearchElement): void => {
        dispatch(setCurrentSearchElement(element));
    },
    setPageToSelectedList: (id: number, isMultiple: boolean = false): void => {
        dispatch(setPageToSelectedList(id, isMultiple));
    },
    getExemptionCurrentDocument: (ids: number[]): void => {
        dispatch(getExemptionCurrentDocument(ids));
    },
    onContextMenu: (type: string, data: IModalIdsData): void => {
        dispatch(openContextMenu(type, data));
    },
    closeContextMenu: (): void => {
        dispatch(closeContextMenu());
    },
    onSetPagesRangeToSelectedList: (id: number, pages: IPages[], selectedPagesIds: number[]): void => {
        dispatch(setPagesRangeToSelectedList(id, pages, selectedPagesIds));
    },
    getPageConsultee: (redactionDocId: number, documentId: number, pageId: number): void => {
        dispatch(getPageConsultee(redactionDocId, documentId, pageId));
    },
    clearPageConsultees: (): void => {
        dispatch(clearPageConsultees());
    },
    fetchPages: (pageNumber: number): void => {
        dispatch(fetchPaginatedPages(pageNumber));
    },
    setPageNumber: (pageNumber: number): void => {
        dispatch(setPageNumber(pageNumber));
    },
    setSortParam: (sortParam: ITableSort): void => {
        dispatch(setSortParam(sortParam));
    },
    setPageListTableColumns: (columns: IHeader[]): void => {
        dispatch(setPageListTableColumns(columns));
    },
    modifyPageListTableColumnHeader: (columns: IHeader[], userId: string): void => {
        dispatch(modifyPageListColumnHeader(columns, userId));
    },
    getResetedPageListColumnHeader: (userId: string): void => {
        dispatch(getResetedPageListColumnHeader(userId));
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
    setFirstPage: (firstPageId: number): void => {
        dispatch(setFirstPage(firstPageId));
    },
    setCurrentSearchElementDirection: (direction: string): void => {
        dispatch(setElementSearchDirection(direction));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LeftBottomBar);

import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    IDisclosureOptions,
    IModalWindowDisclosureState,
} from '../reducers/modalWindowDisclosure/modalWindowDisclosure.model';

export const getDisclosureModal = (state: IState): IModalWindowDisclosureState => state.modalWindowDisclosure;

export const getDisclosureOptions = createSelector(
    [getDisclosureModal],
    (modalWindowDisclosure: IModalWindowDisclosureState): IDisclosureOptions => modalWindowDisclosure.disclosureOptions,
);

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import { DELETE } from '../../../../constants/common.constants';
import { Scrollbars } from 'rc-scrollbars';
import { IStackOptions } from '../../../../redux/reducers/stackList/stackList.model';

interface IStackList {
    list: IStackOptions[];
    activeId: number;
    handleClickStack: (item: IStackOptions) => void;
    handleDeleteStack: (id: number) => void;
}

const StackList = ({
   list,
   activeId,
   handleDeleteStack,
   handleClickStack,
}: IStackList): JSX.Element => {
    return (
        <Scrollbars>
            <List
                component='nav'
            >
                {
                    list
                        .map((item: IStackOptions) =>
                            (<ListItem
                                key={item.id}
                                divider={true}
                                button={true}
                                selected={activeId === item.id}
                                onClick={(): void => handleClickStack(item)}
                            >
                                <ListItemText
                                    title={item.name}
                                    className='text-stack'
                                    primary={item.name}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label={DELETE}
                                        onClick={(): void => handleDeleteStack(item.id)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>))
                }
            </List>
        </Scrollbars>
    );
};

export default StackList;

import * as React from 'react';
import { useEffect,useState } from 'react';
import { useSelector, useDispatch } from '../../../../node_modules/react-redux';
import Grid from '@mui/material/Grid';
import { SelectMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { RequestsFoldersList } from './RequestsFoldersList';
import { AvailableSecuritiesList } from './AvailableSecuritiesList';
import { SecuritiesList } from './SecuritiesList';
import {
    fetchRequestTypeList,
    fetchRequestFolders,
    setSelectedRequestType,
    fetchRequestSecurities,
    fetchAvailableSecurity,
    fetchRequestSecurityGroupsByFolder,
    setRequestSecurities,
    setRequestSecurityGroupsByFolder,
    updateRequestSecurity,
    updateRequestSecurityGroupsByFolder,
    cleanRequestSecurities,
} from '../../../redux/actions/amandaContent';
import {
    getRequestTypesList,
    getRequestFolders,
    getSelectedRequestType,
    getRequestSecurities,
    getAvailableSecurities,
    getRequestSecurityGroupsByFolder,
} from '../../../redux/selectors/amandaContent';
import {
    IRequestSecurities,
    IRequestSecurityParams,
    IRequestFolderSecurityParams,
    ISecurityGroupByFolderRsn,
} from '../../../redux/reducers/amandaContent/amandaContent.model';
import { getModifiedRequestTypes } from '../../../utils/requestSecurity.utils';
import './modalRequestSecurity.style.scss';
import Button from '@mui/material/Button';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';

export const initialSelectedAvailableSecurity = {
    folderType: '',
    folderRsn: 0,
    selectedRowLevelSecurityDtos: [],
    selectedValidUserDtos: [],
};

const getRequestsecurityLabelsByKey = (key: string): string => {
    switch (key) {
        case 'ADMIN_HEADER_REQUEST_SECURITY':
            return 'headerLabel';
        case 'REQUESTSECURITY_MODAL_REQUEST_TYPE':
            return 'searchRequestLabel';
        case 'REQUESTSECURITY_LABEL_APPLY':
             return 'applyLabel'
        case 'REQUESTSECURITY_LABEL_CLOSE':
                return 'closeLabel'     
        default:  return '';
    }
}

export const ModalRequestSecurity = (): JSX.Element => {
    const dispatch = useDispatch();
    const handleFetchRequestTypes = (): void => dispatch(fetchRequestTypeList());
    const handleFetchRequestFolders = (params: IRequestSecurityParams): void =>
        dispatch(fetchRequestFolders(params));
    const handleSetSelectedRequestType = (requestType: string): void =>
        dispatch(setSelectedRequestType(requestType));
    const handleFetchAvailableSecurities = (params: IRequestSecurityParams): void =>
        dispatch(fetchAvailableSecurity(params));
    const handleFetchRequestSecurities = (params: IRequestSecurityParams): void =>
        dispatch(fetchRequestSecurities(params));
    const handleFetchRequestSecurityGroupsByFolder = (params: IRequestFolderSecurityParams): void =>
        dispatch(fetchRequestSecurityGroupsByFolder(params));
    const handleSetRequestSecurities = (data: IRequestSecurities): void =>
        dispatch(setRequestSecurities(data));
    const handleSetRequestSecurityGroupsByFolder = (data: ISecurityGroupByFolderRsn): void =>
        dispatch(setRequestSecurityGroupsByFolder(data));
    const handleCloseModal = (): void => {
        dispatch(handleCloseAllModalWindows());
    };
    const handleApply = (): void => {
        if (requestSecurities.folderType) {
            dispatch(updateRequestSecurity({folderType: requestSecurities.folderType} , requestSecurities));
        } else if (requestSecurities.folderRsn) {
            dispatch(updateRequestSecurityGroupsByFolder(
                {folderRsn: requestSecurities.folderRsn}, requestSecurities));
        }

    };
    const requestTypes = useSelector(getRequestTypesList);
    const requestFolders = useSelector(getRequestFolders);
    const selectedRequestType = useSelector(getSelectedRequestType);
    const requestSecurities = useSelector(getRequestSecurities);
    const availableSecurities = useSelector(getAvailableSecurities);
    const securityGroupsByFolder = useSelector(getRequestSecurityGroupsByFolder);
    const [selectedAvailableSecurities, setSelectedAvailableSecurities] = useState(initialSelectedAvailableSecurity);

    useEffect(() => {handleFetchRequestTypes();}, []);

    const handleSelectChange = (param: string , value: string): void => {
        handleSetSelectedRequestType(value);
        handleFetchRequestFolders({ folderType: value });
    };

    const labels = {
        headerLabel: initialLabel,
        searchRequestLabel: initialLabel,
        applyLabel: initialLabel,
        closeLabel: initialLabel,
    };

    useEffect(() => {
        return (): void => {
            dispatch(cleanRequestSecurities());
        };
    }, []);
    const redactionLang =useSelector(getRedactionLanguage);
    const modifiedLabels = useSelector(getModifiedLabels)
    const langRule = changeLang(redactionLang);
    resourceBundle.map((resource: any) => {
        if (getRequestsecurityLabelsByKey(resource.resourceKey)) {
            labels[getRequestsecurityLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getRequestsecurityLabelsByKey(resource.resourceKey)) {
            labels[getRequestsecurityLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    return (
        <div className='modal_request_security_wrap'>
        <Grid container={true} spacing={4} className='modal_request_security'>
            <Grid item={true} xs={4} className='modal_request_security_types_column'>
                <SelectMaterialBuilder
                    value={selectedRequestType || ''}
                    label= {labels.searchRequestLabel[langRule]}
                    paramName='folderType'
                    handleSelectChange={handleSelectChange}
                    options={getModifiedRequestTypes(requestTypes, redactionLang)}
                />
                <RequestsFoldersList
                    fetchAvailableSecurities={handleFetchAvailableSecurities}
                    fetchRequestSecurities={handleFetchRequestSecurities}
                    fetchRequestSecurityGroupByFolder={handleFetchRequestSecurityGroupsByFolder}
                    folders={requestFolders}
                    availableSecurities={availableSecurities}
                    setSelectedAvailableSecurities={setSelectedAvailableSecurities}
                />
            </Grid>
            <Grid item={true} xs={8} className='modal_request_security_securities_column'>
                <AvailableSecuritiesList
                    availableSecurities={availableSecurities}
                    requestSecurities={requestSecurities}
                    selectedAvailableSecurities={selectedAvailableSecurities}
                    setSelectedAvailableSecurities={setSelectedAvailableSecurities}
                />
                {(securityGroupsByFolder || requestSecurities) && <SecuritiesList
                    securities={securityGroupsByFolder ? {} : requestSecurities}
                    securityGroupsByFolder={securityGroupsByFolder ||  {}}
                    selectedAvailableSecurities={selectedAvailableSecurities}
                    setSelectedAvailableSecurities={setSelectedAvailableSecurities}
                    setRequestSecurities={handleSetRequestSecurities}
                    setRequestSecurityGroupsByFolder={handleSetRequestSecurityGroupsByFolder}
                    requestSecurities={requestSecurities}
                />}
            </Grid>
        </Grid>
        <div className='modal_request_security_buttons'>
            <Button
                variant='outlined'
                size='small'
                color='primary'
                className='modal_request_security_buttons_item'
                onClick={handleCloseModal}
            >
                {labels.closeLabel[langRule]}
            </Button>
            <Button
                variant='contained'
                size='small'
                color='primary'
                className='primary modal_request_security_buttons_item'
                onClick={handleApply}
            >
                {labels.applyLabel[langRule]}
            </Button>
        </div>
        </div>
    );
};

import {
    OPEN_MODAL_WINDOW,
    CLOSE_MODAL_WINDOW,
    CLOSE_ALL_MODAL_WINDOWS, CLOSE_2_LAST_MODAL_WINDOWS,
} from '../reducers/modal/constants';
import { action } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { IModalProps } from '../reducers/modal/modal.model';

export const openModalWindow = (modalType: string, modalProps: IModalProps ): AnyAction =>
    action(OPEN_MODAL_WINDOW, { modalType, modalProps });
export const handleCloseModalWindow = (id: string): AnyAction =>
    action(CLOSE_MODAL_WINDOW, { id });
export const handleCloseAllModalWindows = (): AnyAction =>
    action(CLOSE_ALL_MODAL_WINDOWS);
export const handleClose2LastModalWindows = (): AnyAction =>
    action(CLOSE_2_LAST_MODAL_WINDOWS);

import * as React from 'react';
import Typography from '@mui/material/Typography';
import { TextField, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import './modalEditFilename.scss';
import {
    getSelectedDocuments, getSelectedDocumentName,
} from '../../../redux/selectors/pageList';
import { FILE_NAME_PARAM, FILE_NAME_REGEX, INVALID_FILE_NAME, FILE_NAME_EXCEED_MAX_LENGTH } from '../../../constants/export.contstants';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import {
    EDIT_FILE_NAME_NO_DOCUMENT_MESSAGE,
    EDIT_FILE_NAME_MULTI_DOCUMENT_WARNING_MESSAGE,
    EDIT_FILE_NAME_MODAL,
} from '../../../constants/messages.constants';
import { CONFIRM } from '../../../constants/common.constants';
import { handleCloseModalWindow } from '../../../redux/actions/modal';
import { editDocumentFileName } from '../../../redux/actions/pageList';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { getModalType } from '../../../redux/selectors/modal';
import { changeDocumentOriginalNameInExportDocumentList } from '../../../redux/actions/modalWindowExport';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';

export const getEditFilenameLabelsByKey = (key: string): string => {
    switch(key) {
        case 'MODAL_EDIT_FILENAME_CONFIRM':
            return 'confirmLabel';
        case 'MODAL_EDIT_FILENAME_FIELD_FILENAME':
            return 'fileNameLabel';
        case 'MODAL_EDIT_FILENAME_INVALID_FILE_NAME_ERROR':
            return 'invalidFilenameLabel';
        case 'MODAL_EDIT_FILENAME_FILE_NAME_EXCEED_ERROR':
            return 'fileNameExceedLabel';
        case 'MODAL_EDIT_FILENAME_MULTI_DOC_WARNING':
            return 'multiDocWarningLabel';
        case 'COMMON_LABEL_NO_DOCUMENT_WARNING':
            return 'noDocumentWarningLabel';
        default: return '';
    }
};

export const EditFilenameDialog = (props: IModalProps): JSX.Element => {

    const dispatch = useDispatch();
    const selectedDocumentOriginalName = useSelector(getSelectedDocumentName);
    const selectedDocuments = useSelector(getSelectedDocuments);
    const isExportModalActive = useSelector(getModalType);
    const [fileName, setFileName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const modifiedLabels = useSelector(getModifiedLabels);
    const redactionLang = useSelector(getRedactionLanguage);
    const langRule = changeLang(redactionLang);
    const labels = {
        confirmLabel: initialLabel,
        fileNameLabel: initialLabel,
        invalidFilenameLabel: initialLabel,
        fileNameExceedLabel: initialLabel,
        multiDocWarningLabel: initialLabel,
        noDocumentWarningLabel: initialLabel,
    };

    resourceBundle.map((resource: any) => {
        if(getEditFilenameLabelsByKey(resource.resourceKey)) {
            labels[getEditFilenameLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if(getEditFilenameLabelsByKey(resource.resourceKey)) {
            labels[getEditFilenameLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    useEffect(()=> {
        if(isExportModalActive) {
            if(props.modalSpecificProps && props.modalSpecificProps.originalFileName &&
                (props.modalSpecificProps.originalFileName.length > 251)) {
                setErrorMessage(labels.fileNameExceedLabel[langRule]);
            }
        }

        if(isExportModalActive) {
            setFileName(props.modalSpecificProps.originalFileName);
        } else {
            setFileName(selectedDocumentOriginalName);
        }
    },[]);

    const validation = (value: string): void => {
        const maximumLength = isExportModalActive ? 251 : 255;

        if (value  && value.match(FILE_NAME_REGEX)) {
            setErrorMessage(labels.invalidFilenameLabel[langRule]);
        } else if(value && !value.match(FILE_NAME_REGEX) && value.length > maximumLength) {
            setErrorMessage(labels.fileNameExceedLabel[langRule]);
        } else {
            setErrorMessage('');
        }

    };

    const handleSubmit = (): void => {
        if(isExportModalActive) {
            dispatch(changeDocumentOriginalNameInExportDocumentList(fileName, props.modalSpecificProps.id));
        } else {
            dispatch(editDocumentFileName(fileName));
        }

        dispatch(handleCloseModalWindow(EDIT_FILE_NAME_MODAL));

    };

    return (
        <div className='edit-filename-modal'>
            {
               isExportModalActive || selectedDocuments.length ?
               isExportModalActive || selectedDocuments.length === 1 ?
                        <>
                            <div className='modal-content'>
                                <TextField
                                    disabled={false}
                                    type={'string'}
                                    name={FILE_NAME_PARAM}
                                    value={fileName}
                                    variant='outlined'
                                    className='row_field'
                                    fullWidth={true}
                                    label={labels.fileNameLabel[langRule]}
                                    onChange={(e: React.ChangeEvent<Element>): void => {
                                        setFileName((e.target as HTMLInputElement).value);
                                        validation((e.target as HTMLInputElement).value);
                                    }}
                                    error={!!errorMessage && !!fileName}
                                    autoComplete={'off'}
                                    autoFocus={true}
                                />
                                <div>
                                    {
                                        errorMessage && fileName && (
                                            <Typography
                                                variant='caption'
                                                color='secondary'
                                            >
                                                {errorMessage}
                                            </Typography>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='confirm-button'>
                                <Button
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    onClick={handleSubmit}
                                    className='primary'
                                    disabled={!!errorMessage
                                        && !!fileName
                                        || (!isExportModalActive && selectedDocumentOriginalName === fileName)
                                        || isExportModalActive && props.modalSpecificProps.originalFileName === fileName
                                    }
                                >
                                    {labels.confirmLabel[langRule]}
                                </Button>
                            </div>
                        </>
                        : <ConfirmationDialog message={labels.multiDocWarningLabel[langRule]} />
                    : <ConfirmationDialog message={labels.noDocumentWarningLabel[langRule]} />
            }
        </div>
    );
};

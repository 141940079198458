import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './polyfills/index';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import { SnackbarProvider, withSnackbar } from 'notistack';
import './styles/index.scss';
import axios from 'axios';
import BaseApi from './api/baseApi';

// Use <any> because connect(...) return component with incorrect types
const MyApp = withSnackbar<any>(App);

export let host = '';
export let reductionApi: BaseApi;
export let maxDuplicatesCount: number;

const loadConfig = (filePath: string): void => {
    let result = null;
    const http = new XMLHttpRequest();

    http.open('GET', filePath, false);
    http.send();

    if (http.status === 200) {
        result = JSON.parse(http.responseText)[process.env.NODE_ENV];
    }

    const instance = axios.create({
        baseURL: result.originUrl,
        responseType: 'json',
    });

    host = result.host;
    reductionApi = new BaseApi(instance);
    maxDuplicatesCount = result.maxDuplicatesCount;

    const container = document.getElementById('app');
    const root = createRoot(container);
    root.render(
        (
            <Provider store={store}>
                <SnackbarProvider maxSnack={3}>
                    <MyApp />
                </SnackbarProvider>
            </Provider>
        ),
    );
};

if (process.env.NODE_ENV !== 'test') {
    loadConfig(`${window.location.origin}/config.json`);
}



import {
    GET_SEARCH_REDACTION_DOCUMENTS_PENDING,
    GET_SEARCH_REDACTION_DOCUMENTS_SUCCESS,
    GET_SEARCH_REDACTION_DOCUMENTS_FAILURE,
    GET_FOI_FOLDER_TYPES_PENDING,
    GET_FOI_FOLDER_TYPES_SUCCESS,
    GET_FOI_FOLDER_TYPES_FAILURE,
    GET_IMPORT_REDACTION_DOCUMENT_PENDING,
    GET_IMPORT_REDACTION_DOCUMENT_SUCCESS,
    GET_IMPORT_REDACTION_DOCUMENT_FAILURE,
    CLEAR_SEARCH_REDACTION_DOCUMENTS,
    GET_AMANDA_ATTACHMENTS_PENDING,
    GET_AMANDA_ATTACHMENTS_SUCCESS,
    GET_AMANDA_ATTACHMENTS_FAILURE,
    GET_AMANDA_EDMS_FILES_PENDING,
    GET_AMANDA_EDMS_FILES_SUCCESS,
    GET_AMANDA_EDMS_FILES_FAILURE,
    GET_AMANDA_EDMS_ADAPTORS_SUCCESS,
    GET_AMANDA_EDMS_ADAPTORS_FAILURE,
    GET_AMANDA_EDMS_ADAPTORS_PENDING,
} from '../reducers/modalSearchDocuments/constants';
import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { IError } from '../common.model';
import api from '../../api/reductionApi';
import {
    IEdmsFileDto,
    ISearchRedactionDocumentsParams,
} from '../../containers/modalWindowContents/modalSearchDocument/modalSearchDocument.model';
import {
    IFoiFolder,
    ISearchDocuments,
    ISearchDocumentsResponse,
} from '../reducers/modalSearchDocuments/searchDocuments.model';
import { handleCloseModalWindow } from './modal';
import { SEARCH_REDACTION_DOCUMENTS } from '../../constants/searchDocuments.contstants';
import { addError } from './errorHandling';
import {
    IAmandaAttachmentResponse, IEdmsFile, IFile, IValidEdms,
} from '../../containers/modalWindowContents/modalWindowImport/modalWindowImport.model';

export const fetchSearchReductionDocumentsPending = (): AnyAction => action(GET_SEARCH_REDACTION_DOCUMENTS_PENDING);
export const fetchSearchReductionDocumentsSuccess = (peopleRecords: ISearchDocuments[]): AnyAction =>
    action(GET_SEARCH_REDACTION_DOCUMENTS_SUCCESS, peopleRecords);
export const fetchSearchReductionDocumentsFailure = (error: IError): AnyAction =>
    action(GET_SEARCH_REDACTION_DOCUMENTS_FAILURE, error);

export const fetchFoiFolderTypesPending = (): AnyAction => action(GET_FOI_FOLDER_TYPES_PENDING);
export const fetchFoiFolderTypesSuccess = (foiFolders: IFoiFolder[]): AnyAction =>
    action(GET_FOI_FOLDER_TYPES_SUCCESS, foiFolders);
export const fetchFoiFolderTypesFailure = (error: IError): AnyAction =>
    action(GET_FOI_FOLDER_TYPES_FAILURE, error);

export const fetchReductionDocumentPending = (): AnyAction => action(GET_IMPORT_REDACTION_DOCUMENT_PENDING);
export const fetchReductionDocumentSuccess = (response: ISearchDocumentsResponse): AnyAction =>
    action(GET_IMPORT_REDACTION_DOCUMENT_SUCCESS, response);
export const fetchReductionDocumentFailure = (error: IError): AnyAction =>
    action(GET_IMPORT_REDACTION_DOCUMENT_FAILURE, error);

export const fetchAmandaEdmsAdaptorPending = (): AnyAction => action(GET_AMANDA_EDMS_ADAPTORS_PENDING);
export const fetchAmandaEdmsAdaptorSuccess = (processes: IValidEdms[]): AnyAction =>
    action(GET_AMANDA_EDMS_ADAPTORS_SUCCESS, processes);
export const fetchAmandaEdmsAdaptorFailure = (error: IError): AnyAction =>
    action(GET_AMANDA_EDMS_ADAPTORS_FAILURE, error);

export const fetchEdmsFilesPending = (): AnyAction =>
    action(GET_AMANDA_EDMS_FILES_PENDING);
export const fetchEdmsFilesSuccess = (response: IEdmsFile[]): AnyAction =>
    action(GET_AMANDA_EDMS_FILES_SUCCESS, response);
export const fetchEdmsFilesFailure = (error: IError): AnyAction =>
    action(GET_AMANDA_EDMS_FILES_FAILURE, error);

export const fetchAmandaAttachmentsPending = (): AnyAction => action(GET_AMANDA_ATTACHMENTS_PENDING);
export const fetchAmandaAttachmentsSuccess = (response: IAmandaAttachmentResponse[]): AnyAction =>
    action(GET_AMANDA_ATTACHMENTS_SUCCESS, response);
export const fetchAmandaAttachmentsFailure = (error: IError): AnyAction =>
    action(GET_AMANDA_ATTACHMENTS_FAILURE, error);

export const fetchSearchReductionDocuments = (
    data: ISearchRedactionDocumentsParams,
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchSearchReductionDocumentsPending());

        try {
            const response = await api.requestController.getSearchRequestsFolders(data);

            dispatch(fetchSearchReductionDocumentsSuccess(response));
        } catch (error) {
            dispatch(fetchSearchReductionDocumentsFailure(error));
            dispatch(addError(error));
        }
    };

export const fetchAmandaEdmsAdaptors = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchAmandaEdmsAdaptorPending());

        try {
            const response = await api.edmsController.getEdmsAdaptors();

            dispatch(fetchAmandaEdmsAdaptorSuccess(response));
        } catch (error) {
            dispatch(fetchAmandaEdmsAdaptorFailure(error));
        }
    };

export const fetchEdmsFiles = (inputURLData: string, edmsAdaptor: string): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchEdmsFilesPending());

        try {
            const response = await api.edmsController.getEdmsFiles(inputURLData, edmsAdaptor);
            const edmsFiles: IEdmsFile[] = response.map((edmsFileDto: IEdmsFileDto, index) => ({
                id: index,
                name: edmsFileDto.name,
                size: edmsFileDto.size,
                isChecked: edmsFileDto.checked,
            }));
            dispatch(fetchEdmsFilesSuccess(edmsFiles));
            if (edmsFiles.length) {
                dispatch(handleCloseModalWindow('importFromEDMS'))
            }
        } catch (error) {
            dispatch(fetchEdmsFilesFailure(error));
            dispatch(addError(error));
        }
    };

export const fetchReductionDocument = (
    folderRsn: number,
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchReductionDocumentPending());

        try {
            const response = await api.requestController.getImportRedactionDocuments(folderRsn);

            dispatch(fetchReductionDocumentSuccess(response));

            if  (response.documents.length) {
                dispatch(handleCloseModalWindow(SEARCH_REDACTION_DOCUMENTS));
            }

        } catch (error) {
            dispatch(fetchReductionDocumentFailure(error));
            dispatch(addError(error));
        }
    };

export const fetchAmandaAttachments = (
    folderRsn: number,
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchAmandaAttachmentsPending());

        try {
            const response = await api.requestController.getImportAmandaAttachments(folderRsn);

            dispatch(fetchAmandaAttachmentsSuccess(response));

            if (response.length) {
            dispatch(handleCloseModalWindow(SEARCH_REDACTION_DOCUMENTS));
            }

        } catch (error) {
            dispatch(fetchAmandaAttachmentsFailure(error));
            dispatch(addError(error));
        }
    };

export const fetchFoiFolderTypes = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchFoiFolderTypesPending());

        try {
            const response = await api.requestController.getFoiRequestTypes();

            dispatch(fetchFoiFolderTypesSuccess(response));
        } catch (error) {
            dispatch(fetchFoiFolderTypesFailure(error));
            dispatch(addError(error));
        }
    };

export const clearSearchReductionDocuments = (): AnyAction => action(CLEAR_SEARCH_REDACTION_DOCUMENTS);

import {
    GET_ALL_DISCLOSURE_TYPE_PENDING,
    GET_ALL_DISCLOSURE_TYPE_SUCCESS,
    GET_ALL_DISCLOSURE_TYPE_FAILURE,
    DELETE_DISCLOSURE_TYPE_SUCCESS,
    DELETE_DISCLOSURE_TYPE_PENDING,
    DELETE_DISCLOSURE_TYPE_FAILURE,
    POST_DISCLOSURE_TYPE_PENDING,
    POST_DISCLOSURE_TYPE_SUCCESS,
    POST_DISCLOSURE_TYPE_FAILURE,
    PUT_DISCLOSURE_TYPE_PENDING,
    PUT_DISCLOSURE_TYPE_SUCCESS,
    PUT_DISCLOSURE_TYPE_FAILURE,
    GET_CURRENT_DISCLOSURE_TYPE_PENDING,
    GET_CURRENT_DISCLOSURE_TYPE_SUCCESS,
    GET_CURRENT_DISCLOSURE_TYPE_FAILURE,
    GET_DUPLICATED_DISCLOSURE_TYPE_FAILURE,
    GET_DUPLICATED_DISCLOSURE_TYPE_SUCCESS,
    GET_DUPLICATED_DISCLOSURE_TYPE_PENDING,
} from './constants';
import { IDisclosureTypeState } from './disclosureType.model';
import { AnyAction } from 'redux';
import { IDisclosureOptions } from '../modalWindowDisclosure/modalWindowDisclosure.model';
import { NAME } from '../../../constants/disclosure.contstants';

const initialState: IDisclosureTypeState = {
    disclosureType: [],
    disclosureTypePending: false,
    currentDisclosureTypes: [],
    currentDisclosureTypesPending: false,
    duplicatedDisclosureTypes: [],
    duplicatedDisclosureTypesPending: false,
    disclosureTypeUpdatePending: false,
    error: null,
};

const sortByName = (arr: IDisclosureOptions[]): IDisclosureOptions[] => {
    return arr.sort((a: IDisclosureOptions, b: IDisclosureOptions) => a[NAME].localeCompare(b[NAME]));
};

const disclosureReducer = (
    state: IDisclosureTypeState = initialState,
    {type, payload}: AnyAction,
): IDisclosureTypeState => {
    switch (type) {
        case GET_ALL_DISCLOSURE_TYPE_PENDING:
            return {
                ...state,
                disclosureTypePending: true,
                error: null,
            };
        case GET_ALL_DISCLOSURE_TYPE_SUCCESS:
            return {
                ...state,
                disclosureType: sortByName([...payload]),
                disclosureTypePending: false,
            };
        case GET_ALL_DISCLOSURE_TYPE_FAILURE:
            return{
                ...state,
                error: payload,
                disclosureTypePending: false,
            };
        case GET_CURRENT_DISCLOSURE_TYPE_PENDING:
            return {
                ...state,
                currentDisclosureTypesPending: true,
                error: null,
            };
        case GET_CURRENT_DISCLOSURE_TYPE_SUCCESS:
            return {
                ...state,
                currentDisclosureTypes: sortByName([...payload]),
                currentDisclosureTypesPending: false,
            };
        case GET_CURRENT_DISCLOSURE_TYPE_FAILURE:
            return{
                ...state,
                error: payload,
                currentDisclosureTypesPending: false,
            };
        case GET_DUPLICATED_DISCLOSURE_TYPE_PENDING:
            return {
                ...state,
                duplicatedDisclosureTypesPending: true,
                error: null,
            };
        case GET_DUPLICATED_DISCLOSURE_TYPE_SUCCESS:
            return {
                ...state,
                duplicatedDisclosureTypes: sortByName([...payload]),
                duplicatedDisclosureTypesPending: false,
            };
        case GET_DUPLICATED_DISCLOSURE_TYPE_FAILURE:
            return{
                ...state,
                error: payload,
                duplicatedDisclosureTypesPending: false,
            };
        case POST_DISCLOSURE_TYPE_PENDING:
        case PUT_DISCLOSURE_TYPE_PENDING:
        case DELETE_DISCLOSURE_TYPE_PENDING:
            return {
                ...state,
                disclosureTypeUpdatePending: true,
                error: null,
            };
        case POST_DISCLOSURE_TYPE_SUCCESS:
            return{
                ...state,
                disclosureType: sortByName([...state.disclosureType, payload]),
                disclosureTypeUpdatePending: false,
                error: null,
            };
        case POST_DISCLOSURE_TYPE_FAILURE:
        case DELETE_DISCLOSURE_TYPE_FAILURE:
        case PUT_DISCLOSURE_TYPE_FAILURE:
            return{
                ...state,
                error: payload,
                disclosureTypeUpdatePending: false,
            };
        case PUT_DISCLOSURE_TYPE_SUCCESS:
            return{
                ...state,
                disclosureType: sortByName(state.disclosureType.map((item: IDisclosureOptions) =>
                    item.id !== payload.id ? item : payload,
                )),
                disclosureTypeUpdatePending: false,
            };
        case DELETE_DISCLOSURE_TYPE_SUCCESS:
            return{
                ...state,
                disclosureType: sortByName([...state.disclosureType.filter(
                    ( disclosure: IDisclosureOptions): boolean =>  disclosure.id !== payload,
                )]),
                disclosureTypeUpdatePending: false,
            };

        default:
            return state;
    }
};

export default disclosureReducer;

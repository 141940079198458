export const SEARCH_LABEL = 'Search page text';
export const SEARCH_ALL_DOCS_CHECKBOX_LABEL = 'Search All Documents under the current stack';
export const SEARCH_RESULTS_LABEL = 'Search results';
export const SEARCH_QUERY = 'searchQuery';
export const CLEAR = 'Clear';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const SEVER_PARAM = 'sever';
export const SEVER_ALL = 'Sever All';
export const SEVER_ALL_PARAM = 'severAll';
export const HIGHLIGHT_PARAM = 'highlight';
export const HIGHLIGHT_ALL = 'Highlight All';
export const HIGHLIGHT_ALL_PARAM = 'highlightAll';
export const GO_PREV_PAGE_RESULT = 'Go to previous page result';
export const GO_NEXT_PAGE_RESULT = 'Go to next page result';
export const DOCS_UNDER_CURRENT_STACK_PARAM = 'docsUnderCurrentStack';
export const SELECT_DOCUMENT_TEXT_ERROR = 'Select which document to search or enable ‘Search All Documents under the current stack’.';
export const EMPTY_SEARCH_ERROR = 'Please enter document text to search for';
export const SEARCH_NAVIGATION_STEP = 1;

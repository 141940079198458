import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import {
    GET_INDEX_NOTES_PENDING,
    GET_INDEX_NOTES_SUCCESS,
    GET_INDEX_NOTES_FAILURE,
    POST_INDEX_NOTES_PENDING,
    POST_INDEX_NOTES_SUCCESS,
    POST_INDEX_NOTES_FAILURE,
} from '../reducers/reportsIndexNotes/constants';
import {
    INDEX_NOTES_ALL_DOCUMENTS,
    INDEX_NOTES,
} from '../../constants/indexNotes.constants';
import { action } from 'typesafe-actions';
import { fileSaver } from '../../utils/fileSaver';
import { IError } from '../common.model';
import { IModalIdsData } from '../reducers/modal/modal.model';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IState } from '../store';
import { handleCloseModalWindow, openModalWindow } from './modal';
import { ALERT_DIALOG_MODAL, WARNING } from '../../constants/messages.constants';
import { IFile } from '../reducers/documentList/documentList.model';
import { initialLabel } from '../../constants/localization.constants';
import resourceBundle from '../../containers/localization/localizationData';
import { changeLang } from './localization';
import { getReportLanguageModalLabels, REPORT_LANG_SELECTION_MODAL, REPORT_LANG_SELECTION_TITLE } from '../../constants/modalReportLangSelection.constants';
import { getItem } from '../../utils/localStorage.util';

export const fetchIndexNotesPending = (): AnyAction => action(GET_INDEX_NOTES_PENDING);
export const fetchIndexNotesSuccess = (): AnyAction => action(GET_INDEX_NOTES_SUCCESS);
export const fetchIndexNotesFailure = (error: IError): AnyAction => action(GET_INDEX_NOTES_FAILURE, error);

export const postIndexNotesPending = (): AnyAction => action(POST_INDEX_NOTES_PENDING);
export const postIndexNotesSuccess = (): AnyAction => action(POST_INDEX_NOTES_SUCCESS);
export const postIndexNotesFailure = (error: IError): AnyAction => action(POST_INDEX_NOTES_FAILURE, error);

export const fetchReportIndexNotes = ({documentIds, redactionDocumentId }: IModalIdsData):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) =>
        void => (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): void => {
            dispatch(openModalWindow(REPORT_LANG_SELECTION_MODAL, {
                id: REPORT_LANG_SELECTION_MODAL,
                title: getReportLanguageModalLabels(REPORT_LANG_SELECTION_TITLE),
                okButtonTitle: getReportLanguageModalLabels('Ok'),
                cancelButtonTitle: getReportLanguageModalLabels('Cancel'),
                confirm: (): () => void => (): void => {
                    const reportLang = getItem("reportLang");
                    const { documentList: { stackId, files }, localization: { modifiedLabels }, localStorage: { language } } = getState();
                    let warningLabel = initialLabel;

                    resourceBundle.map((resource: any) => {
                        if (resource.resourceKey === 'COMMON_LABEL_WARNING') {
                            warningLabel = resource;
                        }

                        return;
                    });
                    (modifiedLabels && modifiedLabels.length) ? modifiedLabels.map((resource: any) => {
                        if (resource.resourceKey === 'COMMON_LABEL_WARNING') {
                            warningLabel = resource;
                        }

                        return;
                    }) : '';

                    if (redactionDocumentId && !documentIds.length && !stackId) {
                        dispatch(fetchReportIndexNotesAllDocuments(redactionDocumentId, warningLabel[changeLang(language)], reportLang));
                    } else {
                        const documentIdsReports = stackId ? files.map((file: IFile): number => file.id) : documentIds;

                        dispatch(fetchReportIndexNotesById(redactionDocumentId, documentIdsReports,
                            warningLabel[changeLang(language)], reportLang));
                    }
                },
                reject: (): () => void => (): void => {
                    dispatch(handleCloseModalWindow(REPORT_LANG_SELECTION_MODAL))
                },
            }));

        };

export const fetchReportIndexNotesAllDocuments = (redactionDocumentId: number, warningLabel: string, reportLang: string)
    : (dispatch: Dispatch) => Promise<void> => async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchIndexNotesPending());
    try {
            const dataReportIndexNotes = await api.reportController.getReportIndexNotes(redactionDocumentId, reportLang);

        dispatch(fetchIndexNotesSuccess());
        fileSaver(dataReportIndexNotes, INDEX_NOTES_ALL_DOCUMENTS);
    } catch (error) {
        dispatch(fetchIndexNotesFailure(error));
        dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
            id: ALERT_DIALOG_MODAL,
            title: warningLabel,
            message: error.message,
            confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
        }));
    }
};

export const fetchReportIndexNotesById = (
    redactionDocumentId: number,
    documentIds: number[],
    warningLabel: string,
    reportLang: string
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(postIndexNotesPending());
        try {
            const dataRequesterReport = await api.reportController.postReportIndexNotes(
                redactionDocumentId,
                documentIds,
                reportLang,
            );

            dispatch(postIndexNotesSuccess());
            fileSaver(dataRequesterReport, INDEX_NOTES);
        } catch (error) {
            dispatch(postIndexNotesFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: warningLabel,
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

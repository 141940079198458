import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { AnyObject, FormApi, FormState } from 'final-form';
import { get, isEqual } from 'lodash';
import * as React from 'react';
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form';
import { connect, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import {
    renderCheckbox, renderTextField
} from '../../../components/materialUiForms/materialUiForms';
import {
    ID
} from '../../../constants/common.constants';
import { initialLabel } from '../../../constants/localization.constants';
import {
    ALERT_DIALOG_MODAL,
    CONFIRMATION_DIALOG_MODAL
} from '../../../constants/messages.constants';
import resourceBundle from '../../../containers/localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';
import { handleCloseAllModalWindows, openModalWindow } from '../../../redux/actions/modal';
import { deleteEncryptKey, fetchEncryptionKeys, postEncryption, putEncryption, setEncryptionOptions } from '../../../redux/actions/modalEncryption';
import { ILabel } from '../../../redux/reducers/localization/localization.model';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { IEncryptionType } from '../../../redux/reducers/modalEncryption/modalEncryptionKeys.model';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import {
    getEncryptionKeys, getEncryptionLoading, getEncryptionOptions
} from '../../../redux/selectors/modalEncryption';
import { IState } from '../../../redux/store';
import { validate } from '../../../utils/validate.util';
import EncryptionKeysList from './EncryptionKeysList';
import {
    emptyEncryption, EMPTY_ENCRYPT_ID, ENCRYPTION_METHOD, IS_ACTIVE, MUST_BE_ACTIVE, NAME, validateFields
} from './modalEncryption.constants';
import {
    IEncryptionDispatchProps, IEncryptionProps, IEncryptionState, IEncryptionStateProps
} from './modalEncryption.model';
import './modalEncryption.style.scss';

const classNames = {
    disclosureModal: 'disclosure-modal',
    disclosureModalFooter: 'disclosure-modal-footer',
    disclosureModalBody: 'disclosure-modal-body',
    stackTabsWrap: 'stack-tabs-wrap',
    disclosureListWrap: 'disclosure-list-wrap',
    disclosureTabsWrap: 'disclosure-tabs-wrap',
    disclosureLoader: 'disclosure-modal-loader',
};

const getEncriptionLabelsByKey = (key: string): string => {
    switch (key) {
        case 'COMMON_LABEL_ADD_NEW':
            return 'addNewLabel'; 
        case  'COMMON_LABEL_SELECT_ENCRYPTION': 
            return 'selectEncryptionLabel';
        case 'ENCRYPTION_MODAL_KEYSTORE':
            return 'keystoreLabel'; 
        case  'ENCRYPTION_MODAL_KEYSTORE_VALUE': 
            return 'keystoreValueLabel';
        case   'ENCRYPTION_MODAL_ACTIVE':
            return 'activeLabel'
        case  'ENCRYPTION_MODAL_NAME':
            return 'nameLabel'
        case   'ENCRYPTION_MODAL_CANCEL':
            return 'cancelLabel'
        case  'ENCRYPTION_MODAL_SAVE':
            return 'saveLabel'
        case 'ENCRYPTION_MODAL_METHOD':
            return 'methodLabel'
        case 'COMMON_LABEL_MUST_BE_UNIQUE':
            return 'mustBeUniqueLabel';
        case 'COMMON_LABEL_AT_LEAST_ONE_OF_LIST':
            return 'atLeastOneOfListLabel';
        case 'COMMON_LABEL_FIELD_IS_MANDATORY':
            return 'mandatoryFieldLabel';    
        case 'COMMON_LABEL_CHANGES_NOT_SAVED':
            return 'changesFieldLabel';
        case 'COMMON_LABEL_WARNING':
            return 'warningFieldLabel';
        case  'ENCRYPTION_MODAL_MUST_BE_ACTIVE':
            return 'mustBeActiveLabel';
        case 'COMMON_LABEL_MAX_LENGTH':
            return 'maxLengthLabel';
        default:  return '';
    }
}

const labels = {
    addNewLabel: initialLabel,
    selectEncryptionLabel: initialLabel,
    keystoreLabel: initialLabel,
    keystoreValueLabel: initialLabel,
    activeLabel: initialLabel,
    nameLabel: initialLabel,
    cancelLabel: initialLabel,
    saveLabel: initialLabel,
    methodLabel:initialLabel,
    mustBeUniqueLabel:initialLabel,
    atLeastOneOfListLabel:initialLabel,
    mandatoryFieldLabel:initialLabel,
    changesFieldLabel:initialLabel,
    warningFieldLabel:initialLabel,
    mustBeActiveLabel:initialLabel,
    maxLengthLabel:initialLabel,
 };

class ModalEncryption extends React.Component<IEncryptionProps, IEncryptionState> {
    public state: IEncryptionState  = {
        activeCheckbox: true,
        activeEncrypyionId: null,
    };

    public componentDidMount(): void {
        this.props.fetchEncryptionKeys();
    }

    public getUniqueNamesOfEncryption = (): string[] => {
        const { encryptionKeysList, currentEncryption } = this.props;

        return encryptionKeysList.map((item: IEncryptionType) => {
            return currentEncryption.id === item.id ? '' : item[NAME];
        });
    }

    public onChange = ({ values }: { values: IEncryptionType}): void => {
        const { encryptionKeysList } = this.props;
        const langRule = changeLang(this.props.redactionLang)
        const activeCheckboxes = encryptionKeysList
            .map((encryption: IEncryptionType): IEncryptionType => encryption.id === values.id ? values : encryption)
            .filter((encryption: IEncryptionType): boolean => !encryption.isActive);

        if (encryptionKeysList.length && activeCheckboxes.length === encryptionKeysList.length) {
            this.props.setEncryptionOptions({ ...values, [IS_ACTIVE]: true});
            this.props.openModalWindow(ALERT_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningFieldLabel[langRule],
                message: labels.mustBeActiveLabel[langRule],
            });
        }
    }

    public render(): JSX.Element {
        const { handleCloseModalWindow, encryptionKeysList, currentEncryption,redactionLang, modifiedLabels } = this.props;
        const activeId = currentEncryption[ID];
        const uniqueFields = { name: this.getUniqueNamesOfEncryption() };
        
         
         const langRule = changeLang(redactionLang);
         resourceBundle.map((resource: any) => {
             if (getEncriptionLabelsByKey(resource.resourceKey)) {
                 labels[getEncriptionLabelsByKey(resource.resourceKey)] = resource;
             }
     
             return resource;
         });
         
         modifiedLabels.map((resource: any) => {
            if (getEncriptionLabelsByKey(resource.resourceKey)) {
                labels[getEncriptionLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
         
        return (
            <Form
                onSubmit={this.handleSave}
                initialValues={currentEncryption}
                validate={(values: AnyObject): { [param: string]: boolean } =>
                    validate(values, validateFields, uniqueFields,labels,langRule)}
                render={({
                   handleSubmit,
                   pristine,
                   submitting,
                   invalid,
                   dirty,
                   form,
                }: FormRenderProps): JSX.Element => (
                    <form
                        className={classNames.disclosureModal}
                        onSubmit={handleSubmit}
                    >
                        <div className={classNames.disclosureModalBody}>
                            <div className={classNames.disclosureListWrap}>
                                <Fab
                                    variant='extended'
                                    size='medium'
                                    color='primary'
                                    aria-label='Add'
                                    className='disclosure-add'
                                    onClick={(): void => this.handleAddNew(form)}
                                >
                                    <AddIcon fontSize={'small'}/>
                                    {labels.addNewLabel[langRule]}
                                </Fab>
                                <EncryptionKeysList
                                    list={encryptionKeysList}
                                    activeId={activeId}
                                    handleClickEncryption={(item: IEncryptionType): void =>
                                        this.handleClickEncryption(item, form)}
                                    handleDeleteEncryption={(item: IEncryptionType): void =>
                                        this.handleDeleteEncryption(item)}
                                />
                            </div>
                            <div className={classNames.disclosureTabsWrap}>
                                { currentEncryption[ID] !== null ?
                                    <div className={'encryption-form-container'}>
                                        <div className='row'>
                                            <Field
                                                render={renderCheckbox}
                                                name={IS_ACTIVE}
                                                type='checkbox'
                                                label= {labels.activeLabel[langRule]}
                                            />
                                        </div>
                                        <Field
                                            render={renderTextField}
                                            name={NAME}
                                            multiline={true}
                                            required={true}
                                            rows='2'
                                            label={labels.nameLabel[langRule]}
                                            margin='normal'
                                            variant='outlined'
                                            fullWidth={true}
                                        />
                                        <div className='row'>
                                            <div className='row_title'>
                                                <Typography variant='body1' gutterBottom={true}>
                                                    {labels.methodLabel[langRule]}
                                                </Typography>
                                            </div>
                                            <div className='text'>
                                                <Typography variant='body2' gutterBottom={true}>
                                                    {ENCRYPTION_METHOD}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='row_title'>
                                                <Typography variant='body1' gutterBottom={true}>
                                                    {labels.keystoreLabel[langRule]}
                                                </Typography>
                                            </div>
                                            <div className='text'>
                                                <Typography variant='body2' gutterBottom={true}>
                                                    {labels.keystoreValueLabel[langRule]}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='encryption-empty'>{labels.selectEncryptionLabel[langRule]}</div>
                                }
                            </div>
                        </div>
                        <div className={classNames.disclosureModalFooter}>
                            <Button
                                variant='outlined'
                                className='modal-window__buttons outlined'
                                size='small'
                                onClick={handleCloseModalWindow}
                            >
                                {labels.cancelLabel[langRule]}
                            </Button>
                            <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                className='primary'
                                onClick={(): Promise<object | undefined> => handleSubmit()}
                                disabled={submitting || invalid || pristine}
                            >
                                {labels.saveLabel[langRule]}
                            </Button>
                        </div>
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={(formState: FormState<any>): void => this.onChange(formState)}
                        />
                    </form>
                )}
            />
        );
    }

    private handleClickEncryption = (item: IEncryptionType, form: FormApi): void => {
        const prevItemId = this.props.currentEncryption[ID];
        const currItemId = item.id;

        if (prevItemId === currItemId) {
            return;
        }

        this.handleEncryptionAction(form, item);
    }

    private handleAddNew = (form: FormApi): void => {
        this.handleEncryptionAction(form, emptyEncryption);
    }

    private handleEncryptionAction = (form: FormApi, item: IEncryptionType): void => {
        if (this.isEncryptionOptionsChanged(form)) {
            this.openNotSavedModalWindow(item);
        } else {
            this.props.setEncryptionOptions(item);
        }
    }

    private openNotSavedModalWindow = (item: IEncryptionType): void => {
        const langRule = changeLang(this.props.redactionLang)
        this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: 'Not-saved',
            title: labels.warningFieldLabel[langRule],
            message: labels.changesFieldLabel[langRule],
            confirm: (): () => void => (): void => this.props.setEncryptionOptions(item),
        });
    }

    private handleDeleteEncryption = (item: IEncryptionType): void => {
        this.props.deleteEncryptKey(item.id);
    }

    private handleSave = (values: AnyObject): void => {
        const { currentEncryption } = this.props;
        const currentId = currentEncryption[ID];
        const encryptionOptions = {
            ...!!currentId && { [ID]: currentId },
            [NAME]: values[NAME],
            [IS_ACTIVE]: values[IS_ACTIVE],
        };

        if (currentId) {
            this.props.putEncryption(currentId, encryptionOptions);
        } else {
            this.props.postEncryption(encryptionOptions);
        }
    }

    private isEncryptionOptionsChanged(form: FormApi): boolean {
        const { currentEncryption, encryptionKeysList } = this.props;
        const currentId = currentEncryption[ID];

        if (currentId === null) { // not selected stack
            return false;
        }

        const formValues = form.getState().values;

        const encryption = {
            ...formValues,
            [ID]: currentId.toString(),
            [NAME]: get(formValues, NAME, ''),
            [IS_ACTIVE]: get(formValues, IS_ACTIVE, ''),
        };

        if (currentId === EMPTY_ENCRYPT_ID) {
            return !isEqual( { ...emptyEncryption, id: emptyEncryption.id.toString()}, encryption);
        }

        const initialEncryptOptions = encryptionKeysList
            .find((i: IEncryptionType) => currentId === i.id);
        const initialInfo = {
            ...initialEncryptOptions,
            [ID]: `${get(initialEncryptOptions, [ID], '')}`,
            [NAME]: get(initialEncryptOptions, [NAME], ''),
            [IS_ACTIVE]: get(initialEncryptOptions, [IS_ACTIVE], ''),
        };

        return !isEqual(initialInfo, encryption);
    }
}

const mapStateToProps = (state: IState): IEncryptionStateProps => ({
    encryptionKeysList: getEncryptionKeys(state),
    encryptionLoading: getEncryptionLoading(state),
    currentEncryption: getEncryptionOptions(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang:getRedactionLanguage(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IState, IEncryptionDispatchProps, AnyAction>): IEncryptionDispatchProps => ({
    setEncryptionOptions: (data: IEncryptionType): void => {
        dispatch(setEncryptionOptions(data));
    },
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
    fetchEncryptionKeys: (): void => {
        dispatch(fetchEncryptionKeys());
    },
    putEncryption: (redactionDocumentId: number, data: IEncryptionType): void => {
        dispatch(putEncryption(redactionDocumentId, data));
    },
    deleteEncryptKey: (id: number): void => {
        dispatch(deleteEncryptKey(id));
    },
    postEncryption: (data: IEncryptionType): void => {
        dispatch(postEncryption(data));
    },
});

export default connect( mapStateToProps, mapDispatchToProps ) (ModalEncryption);

const WIDTH_OFFSET = 10;

export const uppercaseFirstLetter = (arg: string): string => {
    return arg.charAt(0).toUpperCase() + arg.slice(1);
};

export const getTextWidth = (txt: string, fontSize: number, fontFamily: string): number => {
    const element = document.createElement('canvas');
    const context = element.getContext('2d');

    context.font = `${fontSize}px ${fontFamily}`;

    return context.measureText(txt).width;
};

export const getTextParams = (txt: string, fontSize: number, fontFamily: string, maxWidth: number): {
    textWidth: number,
    fontSize: number,
} => {
    const contentWidth = getTextWidth(txt, fontSize, fontFamily);

    if (contentWidth > maxWidth - WIDTH_OFFSET) {
        return getTextParams(txt, fontSize - 1, fontFamily, maxWidth);
    }

    return {
        textWidth: contentWidth,
        fontSize,
    };
};

export const getFontSizeByWidth = (
    txt: string,
    fontSize: number,
    fontFamily: string,
    containerWidth: number,
    maxWidth: number,
): number => {
    const textWidth = getTextWidth(txt, fontSize, fontFamily) + 10;

    if (textWidth - containerWidth > WIDTH_OFFSET && fontSize > 0) {
        return getFontSizeByWidth(txt, fontSize - 1, fontFamily, containerWidth, maxWidth);
    }

    if (containerWidth - textWidth > WIDTH_OFFSET) {
        return getFontSizeByWidth(txt, fontSize + 1, fontFamily, containerWidth, maxWidth);
    }

    return fontSize;
};

export const FETCH_DOCUMENT_PENDING = 'reduction/FETCH_DOCUMENT_PENDING';
export const FETCH_DOCUMENT_SUCCESS = 'reduction/FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAILURE = 'reduction/FETCH_DOCUMENT_FAILURE';

export const PAGE_ROTATE_PENDING = 'reduction/PAGE_ROTATE_PENDING';
export const PAGE_ROTATE_SUCCESS = 'reduction/PAGE_ROTATE_SUCCESS';
export const PAGE_ROTATE_FAILURE = 'reduction/PAGE_ROTATE_FAILURE';

export const SET_PAGE_ROTATE = 'reduction/SET_PAGE_ROTATE';

export const PAGE_SCALE = 'reduction/PAGE_ZOOM';
export const PAGE_SIZE = 'reduction/PAGE_SIZE';
export const SET_PDF_WIDTH = 'reduction/SET_PDF_WIDTH';
export const SET_PDF_HEIGHT = 'reduction/SET_PDF_HEIGHT';
export const SET_PDF_OFFSET_WIDTH = 'reduction/SET_PDF_OFFSET_WIDTH';

export const DOCUMENT_PAGE_NUMBER = 'reduction/DOCUMENT_PAGE_NUMBER';

export const CLEAR_OPTIONS_STATE = 'reduction/CLEAR_OPTIONS_STATE';
export const CLEAR_PAGE_STATE = 'reduction/CLEAR_PAGE_STATE';

export const ADD_SELECTED_SHAPES = 'reduction/ADD_SELECTED_SHAPES';
export const ADD_SINGLE_SELECTED_SHAPE = 'reduction/ADD_SINGLE_SELECTED_SHAPE';
export const CLEAR_SELECTED_SHAPES = 'redaction/CLEAR_SELECTED_SHAPES';
export const COPY_SELECTED_SHAPES = 'redaction/COPY_SELECTED_SHAPES';
export const PAST_SELECTED_SHAPES = 'redaction/PAST_SELECTED_SHAPES';

export const GET_CLIENT_SIZE = 'reduction/GET_CLIENT_SIZE';

export const GET_OVERLAYED_SHAPES_PENDING = 'reduction/GET_OVERLAYED_SHAPES_PENDING';
export const GET_OVERLAYED_SHAPES_SUCCESS = 'reduction/GET_OVERLAYED_SHAPES_SUCCESS';
export const GET_OVERLAYED_SHAPES_FAILURE = 'reduction/GET_OVERLAYED_SHAPES_FAILURE';

export const SET_LINKED_ARTICLE_FLAG = 'redaction/SET_LINKED_ARTICLE_FLAG';
export const SET_LINKED_ANNOTATION_ID = 'redaction/SET_LINKED_ANNOTATION_ID';
export const SET_LINKED_STAMPS_ID = 'redaction/SET_LINKED_STAMPS_ID';
export const CLEAR_LINKED_ANNOTATION_AND_STAMP_ID = 'redaction/CLEAR_LINKED_ANNOTATION_AND_STAMP_ID';

export const GET_PAGE_NUMBER_BY_PAGEID_PENDING = 'redaction/GET_PAGE_NUMBER_BY_PAGEID_PENDING';
export const GET_PAGE_NUMBER_BY_PAGEID_SUCCESS = 'redaction/GET_PAGE_NUMBER_BY_PAGEID_SUCCESS';
export const GET_PAGE_NUMBER_BY_PAGEID_FAILURE = 'redaction/GET_PAGE_NUMBER_BY_PAGEID_FAILURE';

export const SET_CONTEXT_MENU_COORDINATES = 'redaction/SET_CONTEXT_MENU_COORDINATES';

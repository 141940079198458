import { ITableColumn } from '../components/table/table.model';
import {
    DOCUMENT_DESCRIPTION,
    DOCUMENT_NAME,
    PAGES,
    SIZE,
} from '../containers/modalWindowContents/modalWindowImport/localFiles/constants';
import Checkbox from '@mui/material/Checkbox';
import PageInput from '../containers/modalWindowContents/modalWindowImport/pageInput/PageInput';
import * as React from 'react';
import { compact } from 'lodash';
import { Tooltip } from '@mui/material';

export const IMPORT_SOURCE = 'Import Source:';
export const REDACTION_DOCUMENTS = 'redactionDocuments';
export const AMANDA_ATTACHMENTS_LIST = 'amandaAttachmentsList';
export const INVALID_FILE_ERROR = 'Invalid file extension';
export const FILES = 'files';
export const EDMS_FILE_LIST = 'edmsFiles';

export const getFileListHeaders = (
    handleCheckItem: (id: string, type: string) => void,
    handleChangePage: (val: number, param: string, id: string, type: string) => void,
    isInsertPage: boolean,
    handleCheckAllItem: (checked: boolean, type: string) => void,
    labels: any,
    langRule: string,
): ITableColumn[] => {
    const columns = [
        {
            accessor: 'action',
            id: 'action',
            size: 10,
            Header:  (rowInfo: any): JSX.Element => {
                const isChecked: boolean = rowInfo.table.options.data.every((columnsData: any): boolean => {
                    return columnsData.isChecked;
                });

                return (
                    <Tooltip
                        title={isChecked ? labels.deselectAllLabel[langRule] : labels.selectAllLabel[langRule]}
                        placement={'top'}
                    >
                        <div>
                            <Checkbox
                                color='primary'
                                checked={isChecked}
                                onChange={(): void =>
                                    handleCheckAllItem(!isChecked, FILES)}
                            />
                        </div>
                    </Tooltip>
                );
            },
            minResizeWidth: 1,
            width: 60,
            show: true,
            Cell: (cellInfo: any): JSX.Element => (
                <Checkbox
                    color='primary'
                    checked={cellInfo.cell.row.original.isChecked}
                    onChange={(): void =>
                        handleCheckItem(cellInfo.cell.row.original.id, FILES)}
                />
            ),
            sortable: false,
        },
        {
            accessorKey: 'file.name',
            id: 'file.name',
            header: labels.docNameHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
        },
        isInsertPage ?
            {
                accessorKey: 'totalPages',
                id: 'totalPages',
                header: labels.pagesHeaderLabel[langRule],
                width: 60,
                minResizeWidth: 1,
                show: true,
            } :
            {
                accessorKey: 'file.size',
                id: 'file.size',
                header: labels.sizeHeaderLabel[langRule],
                minResizeWidth: 1,
                width: 100,
                show: true,
            },
        isInsertPage && {
            accessorKey: 'from',
            id: 'from',
            width: 180,
            header: labels.startPageHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
            Cell: (cellInfo: any): JSX.Element => (
                <PageInput
                    initialValue={cellInfo.cell.row.original.from}
                    max={cellInfo.cell.row.original.totalPages}
                    id={cellInfo.cell.row.original.id}
                    param={'from'}
                    type={FILES}
                    setPagination={handleChangePage}
                    isDisabled={false}
                />
            ),
        },
        isInsertPage && {
            accessorKey: 'to',
            id: 'to',
            width: 180,
            header: labels.endPageHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
            Cell: (cellInfo: any): JSX.Element => (
                <PageInput
                    initialValue={cellInfo.cell.row.original.to}
                    max={cellInfo.cell.row.original.totalPages}
                    id={cellInfo.cell.row.original.id}
                    param={'to'}
                    type={FILES}
                    setPagination={handleChangePage}
                    isDisabled={false}
                    pageCountError={cellInfo.cell.row.original.pageCountError}
                />
            ),
        },
    ];

    return compact(columns);
};


export const getEDMSFileListHeaders = (
    handleCheckItem: (id: string, type: string) => void,
    handleChangePage: (val: number, param: string, id: string, type: string) => void,
    isInsertPage: boolean,
    handleCheckAllItem: (checked: boolean, type: string) => void,
    labels: any,
    langRule: string,
): ITableColumn[] => {
    const columns = [
        {
            accessor: 'action',
            id: 'action',
            size: 10,
            Header:  (rowInfo: any): JSX.Element => {
                const isChecked: boolean = rowInfo.table.options.data.every((columnsData: any): boolean => {

                    return columnsData.isChecked;
                });

                return (
                    <Tooltip
                        title={isChecked ? labels.deselectAllLabel[langRule] : labels.selectAllLabel[langRule]}
                        placement={'top'}
                    >
                        <div>
                            <Checkbox
                                color='primary'
                                checked={isChecked}
                                onChange={(): void =>
                                    handleCheckAllItem(!isChecked, EDMS_FILE_LIST)}
                            />
                        </div>
                    </Tooltip>
                );
            },
            minResizeWidth: 1,
            width: 60,
            show: true,
            Cell: (cellInfo: any): JSX.Element => {
                return (
                    <Checkbox
                        color='primary'
                        checked={cellInfo.cell.row.original.isChecked}
                        onChange={(): void =>
                            handleCheckItem(cellInfo.cell.row.original.id, EDMS_FILE_LIST)}
                    />
                );
            },
            sortable: false,
        },
        {
            accessorKey: 'name',
            id: 'name',
            header: labels.docNameHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
        },
        isInsertPage ?
            {
                accessorKey: 'totalPages',
                id: 'totalPages',
                header: labels.pagesHeaderLabel[langRule],
                width: 60,
                minResizeWidth: 1,
                show: true,
            } :
            {
                accessorKey: 'size',
                id: 'size',
                header: labels.sizeHeaderLabel[langRule],
                minResizeWidth: 1,
                width: 100,
                show: true,
            },
        isInsertPage && {
            accessorKey: 'from',
            id: 'from',
            width: 180,
            header: labels.startPageHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
            Cell: (cellInfo: any): JSX.Element => (
                <PageInput
                    initialValue={cellInfo.cell.row.original.from}
                    max={cellInfo.cell.row.original.totalPages}
                    id={cellInfo.cell.row.original.id}
                    param={'from'}
                    type={EDMS_FILE_LIST}
                    setPagination={handleChangePage}
                    isDisabled={false}
                />
            ),
        },
        isInsertPage && {
            accessorKey: 'to',
            id: 'to',
            width: 180,
            header: labels.endPageHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
            Cell: (cellInfo: any): JSX.Element => (
                <PageInput
                    initialValue={cellInfo.cell.row.original.to}
                    max={cellInfo.cell.row.original.totalPages}
                    id={cellInfo.cell.row.original.id}
                    param={'to'}
                    type={EDMS_FILE_LIST}
                    setPagination={handleChangePage}
                    isDisabled={false}
                    pageCountError={cellInfo.cell.row.original.pageCountError}
                />
            ),
        },
    ];

    return compact(columns);
};

export const getDocumentListHeaders = (
    handleCheckItem: (id: number, type: string) => void,
    handleChangePage: (val: number, param: string, id: string, type: string) => void,
    isInsertPage: boolean,
    handleCheckAllItem: (checked: boolean, type: string) => void,
    labels: any,
    langRule: string,
): ITableColumn[] => {
    const columns = [
        {
            accessor: 'action',
            id: 'action',
            size: 10,
            Header:  (rowInfo: any): JSX.Element => {
                const isChecked: boolean = rowInfo.table.options.data.every((columnsData: any): boolean => {

                    return columnsData.isChecked;
                });

                return (
                    <Tooltip
                        title={isChecked ? labels.deselectAllLabel[langRule] : labels.selectAllLabel[langRule]}
                        placement={'top'}
                    >
                        <div>
                            <Checkbox
                                color='primary'
                                checked={isChecked}
                                onChange={(): void =>
                                    handleCheckAllItem(!isChecked, REDACTION_DOCUMENTS)}
                            />
                        </div>
                    </Tooltip>
                );
            },
            minResizeWidth: 1,
            width: 60,
            show: true,
            Cell: (cellInfo: any): JSX.Element => (
                <Checkbox
                    color='primary'
                    checked={cellInfo.cell.row.original.isChecked}
                    onChange={(): void =>
                        handleCheckItem(cellInfo.cell.row.original.id, REDACTION_DOCUMENTS)}
                />
            ),
            sortable: false,
        },
        {
            accessorKey: 'name',
            id: 'name',
            header: labels.docNameHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
        },
        {
            accessorKey: 'totalPages',
            id: 'totalPages',
            header: labels.pagesHeaderLabel[langRule],
            minResizeWidth: 1,
            width: 70,
            show: true,
        },
        isInsertPage && {
            accessorKey: 'from',
            id: 'from',
            width: 180,
            header: labels.startPageHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
            Cell: (cellInfo: any): JSX.Element => (
                <PageInput
                    initialValue={cellInfo.cell.row.original.from}
                    max={cellInfo.cell.row.original.totalPages}
                    id={cellInfo.cell.row.original.id}
                    param={'from'}
                    type={REDACTION_DOCUMENTS}
                    setPagination={handleChangePage}
                    isDisabled={false}
                />
            ),
        },
        isInsertPage && {
            accessorKey: 'to',
            id: 'to',
            width: 180,
            header: labels.endPageHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
            Cell: (cellInfo: any): JSX.Element => (
                <PageInput
                    initialValue={cellInfo.cell.row.original.to}
                    max={cellInfo.cell.row.original.totalPages}
                    id={cellInfo.cell.row.original.id}
                    param={'to'}
                    type={REDACTION_DOCUMENTS}
                    setPagination={handleChangePage}
                    isDisabled={false}
                    pageCountError={cellInfo.cell.row.original.pageCountError}
                />
            ),
        },
    ];

    return compact(columns);
};

export const getAmandaAttachmentHeaders = (
    handleCheckItem: (id: number, type: string) => void,
    handleCheckAllItem: (checked: boolean, type: string) => void,
    labels: any,
    langRule: string,
): ITableColumn[] => {
    const columns = [
        {
            accessor: 'action',
            id: 'action',
            size: 10,
            Header: (rowInfo: any): JSX.Element => {
                let isValidExtension: boolean;
                const isChecked: boolean = rowInfo.table.options.data.every((columnsData: any): boolean => {
                    isValidExtension = columnsData.isValidExtension;

                    return columnsData.isValidExtension && columnsData.isChecked;
                });

                return (
                    <Tooltip
                        title={isChecked ? labels.deselectAllLabel[langRule] : labels.selectAllLabel[langRule]}
                        placement={'top'}
                    >
                        <div>
                            <Checkbox
                                color='primary'
                                checked={isValidExtension && isChecked}
                                disabled={!isValidExtension}
                                onChange={(): void =>
                                    handleCheckAllItem(!isChecked, AMANDA_ATTACHMENTS_LIST)}
                            />
                        </div>
                    </Tooltip>
                );
            },
            minResizeWidth: 1,
            width: 60,
            show: true,
            Cell: (cellInfo: any): JSX.Element => {
                return (
                    <Tooltip
                        title={`${cellInfo.cell.row.original.isValidExtension ? '' : labels.fileExtensionErrorLabel[langRule]}`}
                    >
                        <div>
                            <Checkbox
                                color='primary'
                                checked={cellInfo.cell.row.original.isValidExtension && cellInfo.cell.row.original.isChecked}
                                disabled={!cellInfo.cell.row.original.isValidExtension}
                                onChange={(): void =>
                                    handleCheckItem(cellInfo.cell.row.original.attachmentRsn, AMANDA_ATTACHMENTS_LIST)}
                            />
                        </div>
                    </Tooltip>
                );
            },
            sortable: false,
        },
        {
            accessorKey: 'attachmentFileAlias',
            id: 'attachmentFileAlias',
            header: labels.docNameHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
        },
        {
            accessorKey: 'attachmentFileSuffix',
            id: 'attachmentFileSuffix',
            header: labels.docTypeHeaderLabel[langRule],
            width: 60,
            minResizeWidth: 1,
            show: true,
        },
        {
            accessorKey: 'attachmentDesc',
            id: 'attachmentDesc',
            header: labels.docDescriptionHeaderLabel[langRule],
            minResizeWidth: 1,
            show: true,
        },
    ];

    return compact(columns);
};

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import { DELETE } from '../../../../constants/common.constants';
import { Scrollbars } from 'rc-scrollbars';
import { IDisclosureOptions } from '../../../../redux/reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { langFrench } from '../../../../constants/localization.constants';

interface IDisclosureList {
    list: IDisclosureOptions[];
    activeId: number;
    deletePermission: boolean;
    handleClickDisclosure: (item: IDisclosureOptions) => void;
    handleDeleteDisclosure: (id: number) => void;
    language: string;
}

const DisclosureList = ({
    list,
    activeId,
    handleDeleteDisclosure,
    handleClickDisclosure,
    deletePermission,
    language,
}: IDisclosureList): JSX.Element => {
    return (
        <Scrollbars>
            <List
                component='nav'
            >
                {
                    list
                        .map((item: IDisclosureOptions) =>
                            (<ListItem
                                key={item.id}
                                divider={true}
                                button={true}
                                className={!item.isActive ? 'non-active' :''}
                                selected={activeId === item.id}
                                onClick={(): void => handleClickDisclosure(item)}
                            >
                                <ListItemIcon className='list-icon'>
                                    <CheckCircle color={item.isActive ? 'primary' : 'disabled'} fontSize='small'/>
                                </ListItemIcon>
                                    <ListItemText
                                        title={language ? language === langFrench ? item.name1 ? item.name1
                                            : item.name : item.name : item.name}
                                        className='text-disclosure'
                                        primary={language ? language === langFrench ? item.name1 ? item.name1
                                            : item.name : item.name : item.name}
                                    />
                                {
                                    deletePermission && (
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                aria-label={DELETE}
                                                onClick={(): void => handleDeleteDisclosure(item.id)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    )
                                }
                            </ListItem>))
                }
            </List>
        </Scrollbars>
    );
};

export default DisclosureList;

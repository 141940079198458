import { createSelector } from 'reselect';
import { IState } from '../store';
import { IPackagePresetState } from 'containers/modalWindowContents/modalPackagePresets/modalPackagePresets.model';
import { IExportPreset } from '../../containers/modalWindowContents/modalPackagePresets/modalPackagePresets.model';
import { PRESET_NAME_PARAM } from '../../constants/packagePresets.contants';
import { IContentOptions, IPaginationOptions } from '../reducers/modalWindowExport/modalWindowExport.model';

const getModalPackagePresets = (state: IState): IPackagePresetState => state.modalPackagePresets;

export const getExportOptionsSelector = createSelector(
    [getModalPackagePresets],
    (modalPackagePresets: IPackagePresetState): IContentOptions => modalPackagePresets.exportOptions,
);

export const getPresetPaginationOptionsSelector = createSelector(
    [getModalPackagePresets],
    (modalPackagePresets: IPackagePresetState): IPaginationOptions => modalPackagePresets.paginationOptions,
);

export const getPresetAnnotationOptionsSelector = createSelector(
    [getModalPackagePresets],
    (modalPackagePresets: IPackagePresetState): number[] => modalPackagePresets.annotationPresetIdList,
);

export const getPackagePresets = createSelector(
    [getModalPackagePresets],
    (modalPackagePresets: IPackagePresetState): IExportPreset[] => modalPackagePresets.presets,
);

export const getSelectedPresetId = createSelector(
    [getModalPackagePresets],
    (modalPackagePresets: IPackagePresetState): number | null => modalPackagePresets.selectedPresetId,
);

export const getPresetsName = createSelector(
    [getSelectedPresetId, getPackagePresets],
    (selectedPresetId: number, presets: IExportPreset[]): string[] => {
        const existingPresets = selectedPresetId ?
            presets.filter((preset: IExportPreset) => preset.id !== selectedPresetId):
            presets;

        return existingPresets.map(
            (preset: IExportPreset): string => preset.exportOptions[PRESET_NAME_PARAM].toLocaleLowerCase(),
        );
    },
);

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './simpleTable.styles.scss';
import { IProps, IKeysArray } from './simpleTable.model';

export const SimpleTable = ({ rowHeaders, rowsData, header }: IProps): JSX.Element => {
    return (
        <Paper className='simple-table'>
            {header && <h2>{header}</h2>}
            <Table>
                <TableHead>
                    <TableRow>
                        {rowHeaders.map((row: string) => (
                            <TableCell key={row}>{row}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsData.map((row: IKeysArray) => (
                        <TableRow key={row.key}>
                            {Object.keys(row).map((key: string) => (
                                <TableCell key={key}>{row[key]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import { IState } from '../store';
import { action } from 'typesafe-actions';
import {
    GET_ENCRYPTION_KEYS_PENDING,
    GET_ENCRYPTION_KEYS_SUCCESS,
    GET_ENCRYPTION_KEYS_FAIL,
    SET_OPTIONS_ENCRYPTION,
    PUT_ENCRYPTION_FAILURE,
    PUT_ENCRYPTION_PENDING,
    PUT_ENCRYPTION_SUCCESS,
    DELETE_ENCRYPTION_KEY_PENDING,
    DELETE_ENCRYPTION_KEY_SUCCESS,
    DELETE_ENCRYPTION_KEY_FAILURE,
    POST_ENCRYPTION_PENDING,
    POST_ENCRYPTION_SUCCESS,
    POST_ENCRYPTION_FAILURE,
} from '../reducers/modalEncryption/constant';

import {
    IEncryptionType,
} from '../reducers/modalEncryption/modalEncryptionKeys.model';

import { IError } from '../common.model';
import { handleCloseModalWindow, openModalWindow } from './modal';
import { ALERT_DIALOG_MODAL } from '../../constants/messages.constants';
import { changeLang, commonLabels, getCommonLabelsByKey } from './localization';
import resourceBundle from '../../containers/localization/localizationData';

const labels = commonLabels;
   
export const fetchEncryptionKeysPending = (): AnyAction => action(GET_ENCRYPTION_KEYS_PENDING);
export const fetchEncryptionKeysSuccess = (dataList: IEncryptionType[]): AnyAction =>
    action(GET_ENCRYPTION_KEYS_SUCCESS, dataList);
export const fetchEncryptionKeysFailure = (error: IError): AnyAction => action(GET_ENCRYPTION_KEYS_FAIL, error);
export const setEncryptionOptions = (data: IEncryptionType): AnyAction => action(SET_OPTIONS_ENCRYPTION, data);

export const putEncryptionPending = (): AnyAction =>
    action(PUT_ENCRYPTION_PENDING);
export const putEncryptionSuccess = (data: IEncryptionType): AnyAction =>
    action(PUT_ENCRYPTION_SUCCESS, data);
export const putEncryptionFailure = (error: IError): AnyAction =>
    action(PUT_ENCRYPTION_FAILURE, error);

export const postEncryptionPending = (): AnyAction =>
    action(POST_ENCRYPTION_PENDING);
export const postEncryptionSuccess = (data: IEncryptionType): AnyAction =>
    action(POST_ENCRYPTION_SUCCESS, data);
export const postEncryptionFailure = (error: IError): AnyAction =>
    action(POST_ENCRYPTION_FAILURE, error);

export const deleteEncryptionKeyPending = (): AnyAction => action(DELETE_ENCRYPTION_KEY_PENDING);
export const deleteEncryptionKeySuccess = (id: number): AnyAction => action(DELETE_ENCRYPTION_KEY_SUCCESS, id);
export const deleteEncryptionKeyFailure = (error: IError): AnyAction => action(DELETE_ENCRYPTION_KEY_FAILURE, error);

export const fetchEncryptionKeys = ():
    (dispatch: Dispatch) => Promise<void> =>

    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchEncryptionKeysPending());

        try {
            const response = await api.encryptionController.getEncryptionKeys();

            dispatch(fetchEncryptionKeysSuccess(response));
        } catch (error) {
            dispatch(fetchEncryptionKeysFailure(error));
        }
    };

export const postEncryption = (data: IEncryptionType): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(postEncryptionPending());
        try {
            const response = await api.encryptionController.postEncryption(data);

            dispatch(postEncryptionSuccess(response));
            dispatch(setEncryptionOptions(response));
        } catch (error) {
            dispatch(postEncryptionFailure(error));
        }
    };

export const putEncryption = (id: number, data: IEncryptionType): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(putEncryptionPending());
        try {
            const response = await api.encryptionController.putEncryption(id, data);

            dispatch(putEncryptionSuccess(response));
            dispatch(setEncryptionOptions(response));
        } catch (error) {
            dispatch(putEncryptionFailure(error));
        }
    };

export const deleteEncryptKey = (id: number): (dispatch: Dispatch, getState : () => IState) => Promise<void> =>
    async (dispatch: Dispatch, getState : () => IState): Promise<void> => {
        const {
            localStorage:{ language},
             localization:{modifiedLabels}       ,
        } = getState();
        const langRule = changeLang(language);
        resourceBundle.map((resource: any) => {
            if (getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getCommonLabelsByKey(resource.resourceKey)) {
                labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        dispatch(deleteEncryptionKeyPending());
        try {
            await api.encryptionController.deleteEncrypt(id);
            dispatch(deleteEncryptionKeySuccess(id));
        } catch (error) {
            dispatch(deleteEncryptionKeyFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title:labels.warningLabel[langRule],
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

import { IVersionController } from '../reducers/infoController/infoController.model';
import { IState } from '../store';
import { createSelector } from 'reselect';

export const getInfoController = (state: IState): IVersionController => state.version;

export const getFEVersion = createSelector(
    [getInfoController],
    (version: IVersionController): string => version.FEVersion,
);

export const getBEVersion = createSelector(
    [getInfoController],
    (version: IVersionController): string => version.BEVersion,
);

export const getDBVersion = createSelector(
    [getInfoController],
    (version: IVersionController): string => version.DBVersion,
);

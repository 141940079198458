import { IContextMenu } from '../../redux/reducers/contextMenu/contextMenu.model';
import {
    DOCUMENT_LIST_CONTEXT_MENU,
    PAGE_LIST_CONTEXT_MENU,
    REDACTOR_CONTEXT_MENU,
    CHANGE_PAGE_DISCLOSURE_LIST_MENU,
} from './context.menu.constants';
import { DELETE_DOCUMENT_TITLE, DELETE_PAGE_TITLE, EDIT_FILE_NAME_TITLE, EDIT_FILE_NAME_MODAL } from '../messages.constants';
import { confirmDeletePage } from '../../redux/actions/pageList';
import {
    confirmDeleteDocument,
    editPaginationDocuments,
    setPaginationDocument,
    undoPagination,
} from '../../redux/actions/documentList';
import { STACK_MANAGMENT_MODAL } from '../stack.contstants';
import { addToStackDocuments } from '../../redux/actions/stackList';
import {
    DISCLOSURE_ID,
    UNDO_DISCLOSURE_ID,
    DELETE_PAGE_ID,
    EXPORT_ID,
    STACK_ID,
    PAGINATION_ID,
    DUPLICATE_REFERENCE_ID,
    DELETE_DOCUMENT_ID,
    REPLACE_DISCLOSURE_ID,
    SET_DISCLOSURE_ID,
    SET_PAGINATION_ID,
    EDIT_PAGINATION_ID,
    UNDO_PAGINATION_ID,
    ADD_REMOVE_FROM_STACK_ID,
    STACK_MANAGEMENT_ID,
    DOCUMENTS_DETAILS_ID,
    EDIT_DOCUMENT_ORIGINAL_NAME,
} from '../common.constants';
import { DUPLICATE_REFERENCES, REPLACE_DISCLOSURE } from '../modal.constants';
import { openDuplicateReferences } from '../../redux/actions/duplicates';
import { initialLabel } from '../../constants/localization.constants';
import { changeLang } from '../../redux/actions/localization';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getItem, getSessionItem } from '../../utils/localStorage.util';
import resourceBundle from '../../containers/localization/localizationData';
import { MODIFIED_LABELS, REDACTION_LANG } from '../../redux/reducers/localStorage/constant';
import { getModalLabelsByKey } from '../../containers/header/Header';

export const getRightClickMenuLabelsByKey = (key: string): string => {
    switch (key) {
        case 'PAGE_LIST_DISCLOSURE_SET':
            return 'changePageDisclosureListMenu';
        case 'COMMON_LABEL_LOADING':
            return 'loading';
        case 'PAGE_LIST_DISCLOSURE_UNDO':
            return 'undoDisclosure';
        case 'PAGE_LIST_DELETE_PAGE':
            return 'deletePage';
        case 'PAGE_LIST_EXPORT':
            return 'export';
        case 'PAGE_LIST_DUPLICATE_REFERENCES':
            return 'duplicateReference';
        case 'DOCUMENT_LIST_DOCUMENT_DETAIL':
            return 'documentDetails';
        case 'DOCUMENT_LIST_DELETE_DOCUMENT':
            return 'documentDelete';
        case 'DOCUMENT_LIST_EDIT_FILENAME':
            return 'editDocumentOriginalName';
        case 'DOCUMENT_LIST_STACK':
            return 'stack';
        case 'DOCUMENT_LIST_PAGINATION':
            return 'pagination';
        case 'DOCUMENT_LIST_DISCLOSURE':
            return 'disclosure';
        case 'DOCUMENT_LIST_STACK_ADD_REMOVE':
            return 'addRemoveFromStack';
        case 'DOCUMENT_LIST_STACK_MANAGEMENT':
            return 'stackManagement';
        case 'DOCUMENT_LIST_PAGINATION_SET':
            return 'setPagination';
        case 'DOCUMENT_LIST_PAGINATION_EDIT':
            return 'editPagination';
        case 'DOCUMENT_LIST_PAGINATION_UNDO':
            return 'undoPagination';
        case 'DOCUMENT_LIST_DISCLOSURE_SET':
            return 'setDisclosure';
        case 'DOCUMENT_LIST_DISCLOSURE_REPLACE':
            return 'replaceDisclosure';
        case 'DOCUMENT_LIST_DISCLOSURE_UNDO':
            return 'undoDisclosure';
        default: return '';
    }
};
export const rightClickMenuLabels = {
    changePageDisclosureListMenu: initialLabel,
    loading: initialLabel,
    undoDisclosure: initialLabel,
    deletePage: initialLabel,
    export: initialLabel,
    duplicateReference: initialLabel,
    documentDetails: initialLabel,
    documentDelete: initialLabel,
    editDocumentOriginalName: initialLabel,
    stack: initialLabel,
    pagination: initialLabel,
    disclosure: initialLabel,
    addRemoveFromStack: initialLabel,
    stackManagement: initialLabel,
    setPagination: initialLabel,
    editPagination: initialLabel,
    undoPagination: initialLabel,
    setDisclosure: initialLabel,
    replaceDisclosure: initialLabel,
};

export const getRightClickModalLabelsByKey = (key: string): string => {
    const redactionLang = getSessionItem(REDACTION_LANG);
    const modifiedLabels = getItem(MODIFIED_LABELS);
    const label = {
        editFilenameModalTitle: initialLabel,
        stackManagementModalTitle: initialLabel,
        replaceDisclosureModalTitle: initialLabel,
        undoDisclosureModalTitle: initialLabel,
        modalExportLabel: initialLabel,
        duplicateReferencesModalTitle: initialLabel,
    };
    const langRule = changeLang(redactionLang);

    resourceBundle.map((resource: any) => {
        if (getModalLabelsByKey(resource.resourceKey)) {
            label[getModalLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    (modifiedLabels && modifiedLabels.length) ? modifiedLabels.map((resource: any) => {
        if (getModalLabelsByKey(resource.resourceKey)) {
            label[getModalLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    }) : '';

    switch(key) {
        case EDIT_FILE_NAME_MODAL:
            return label.editFilenameModalTitle[langRule];
        case STACK_MANAGMENT_MODAL:
            return label.stackManagementModalTitle[langRule];
        case REPLACE_DISCLOSURE:
            return label.replaceDisclosureModalTitle[langRule];
        case 'undoDisclosure':
            return label.undoDisclosureModalTitle[langRule];
        case 'export':
             return label.modalExportLabel[langRule];
        case DUPLICATE_REFERENCES:
            return label.duplicateReferencesModalTitle[langRule];
        default:
            return '';
    }
}

export const contextMenuList: IContextMenu[] = [
    {
        id: PAGE_LIST_CONTEXT_MENU,
        menu: [
            {
                id: CHANGE_PAGE_DISCLOSURE_LIST_MENU,
                label: 'Set Disclosure',
                menu: [
                    {
                        id: 'loading',
                        label: 'Loading...',
                        disabled: false,
                    },
                ],
                disabled: false,
            },
            {
                id: UNDO_DISCLOSURE_ID,
                label: 'Undo Disclosure',
                modal: 'undoDisclosure',
                disabled: false,
            }, {
                id: DELETE_PAGE_ID,
                label: DELETE_PAGE_TITLE,
                action: confirmDeletePage,
                disabled: false,
            }, {
                id: EXPORT_ID,
                label: 'Export',
                modal: 'export',
                disabled: false,
            }, {
                id: DUPLICATE_REFERENCE_ID,
                label: 'Duplicate References',
                action: openDuplicateReferences,
                disabled: false,
            }],
    },
    {
        id: DOCUMENT_LIST_CONTEXT_MENU,
        menu: [
            {
                id: DOCUMENTS_DETAILS_ID,
                label: 'Document Detail',
                disabled: true,
            }, {
                id: DELETE_DOCUMENT_ID,
                label: DELETE_DOCUMENT_TITLE,
                action: confirmDeleteDocument,
                disabled: false,
            }, {
                id: EDIT_DOCUMENT_ORIGINAL_NAME,
                label: EDIT_FILE_NAME_TITLE,
                modal: EDIT_FILE_NAME_MODAL,
                disabled: false,
            }, {
                id: STACK_ID,
                label: 'Stack',
                disabled: false,
                menu: [{
                    id: ADD_REMOVE_FROM_STACK_ID,
                    label: 'Add/Remove from Stack',
                    disabled: false,
                    action: addToStackDocuments,
                }, {
                    id: STACK_MANAGEMENT_ID,
                    label: 'Stack Management',
                    disabled: false,
                    modal: STACK_MANAGMENT_MODAL,
                }],
            }, {
                id: PAGINATION_ID,
                label: 'Pagination',
                disabled: false,
                menu: [{
                    id: SET_PAGINATION_ID,
                    label: 'Set Pagination',
                    disabled: false,
                    action: setPaginationDocument,
                }, {
                    id: EDIT_PAGINATION_ID,
                    label: 'Edit Pagination',
                    disabled: false,
                    action: editPaginationDocuments,
                }, {
                    id: UNDO_PAGINATION_ID,
                    label: 'Undo Pagination',
                    disabled: false,
                    action: undoPagination,
                }],
            }, {
                id: DISCLOSURE_ID,
                label: 'Disclosure',
                disabled: false,
                menu: [
                    {
                        id: SET_DISCLOSURE_ID,
                        label: 'Set Disclosure',
                        disabled: false,
                        menu: [
                            {
                                id: 'loading',
                                label: 'Loading...',
                                disabled: false,
                            },
                        ],
                    },
                    {
                        id: REPLACE_DISCLOSURE_ID,
                        label: 'Replace Disclosure',
                        disabled: false,
                        modal: REPLACE_DISCLOSURE,
                    }, {
                        id: UNDO_DISCLOSURE_ID,
                        label: 'Undo Disclosure',
                        modal: 'undoDisclosure',
                        disabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: REDACTOR_CONTEXT_MENU,
        menu: [],
    },
];

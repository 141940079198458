import * as React from 'react';
import { connect } from 'react-redux';
import SimpleTabs from '../../../../components/simpleTabs/SimpleTabs';
import ContentOptions from '../../modalWindowExport/contentOption/ContentOption';
import { IContentOptionChange } from '../../modalWindowExport/contentOption/contentOption.model';
import AnnotationControlTab from '../../modalWindowExport/annotationControl/AnnotationControlTab';
import PaginationOptions,
{ IPaginationOptionChange } from '../../modalWindowExport/paginationOptions/PaginationOptions';
import { IState as StoreState } from '../../../../redux/store';
import { IActionProps, ICommonProps } from './modalPackagePresetsTabs.model';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import {
    createPackageAnnotationList,
    removeAnnotationOptionFromList,
    setPackageExportOptions,
    setPackagePaginationOptions,
} from '../../../../redux/actions/modalPackagePresets';
import { ANNOTATION_CONTROL, ORIGINALS_ONLY_PARAM, PAGINATION_OPTIONS } from '../../../../constants/export.contstants';
import { EXPORT_OPTIONS } from '../../../../constants/packagePresets.contants';

import '../modalPackagePresets.style.scss';

const ModalPackagePresetsTabs = (props: ICommonProps): JSX.Element => {

    const handleOptionsChange = (data: IContentOptionChange): void => {
        props.setPackageExportOptions(data);
        props.activateSaveBtn();
    };

    const handleChangeAnnotationControl = (id: number, isChecked: boolean): void => {
        if (isChecked) {
            props.createPackageAnnotationList(id);
        } else {
            props.removeAnnotationOptionFromList(id);
        }

        props.activateSaveBtn();
    };

    const handlePaginationOptionsChange = (data: IPaginationOptionChange): void => {
        props.setPackagePaginationOptions(data);
        props.activateSaveBtn();
    };

    const {parentLabel, langRule} = props;

    return (
        <div className='package-presets-options'>
            <SimpleTabs>
                <ContentOptions
                    title={parentLabel.exportOptionsTitle[langRule]}
                    isValid={true}
                    contentOptionsData={props.exportOptions}
                    handleOptionsChange={handleOptionsChange}
                    isPackagePresetsTab={true}
                    selectedPresetId={props.selectedPresetId}
                    existingPresetsName={props.existingPresetsName}
                    hasEditPermission={props.hasEditPermission}
                    annotationControlsIdList={props.annotationPresetIdList}
                    severList={props.severList}
                    hasDefaultWatermark={props.hasDefaultWatermark}
                />
                <PaginationOptions
                    title={parentLabel.paginationOptionsTitle[langRule]}
                    isValid={true}
                    paginationOptions={props.paginationOptions}
                    handlePaginationOptionsChange={handlePaginationOptionsChange}
                    selectedPresetId={props.selectedPresetId}
                    hasEditPermission={props.hasEditPermission}
                />
                <AnnotationControlTab
                    title={parentLabel.annotationControlTitle[langRule]}
                    isValid={true}
                    disabled={props.exportOptions[ORIGINALS_ONLY_PARAM]}
                    highLightList={props.highLightList}
                    severList={props.severList}
                    annotationControlsIdList={props.annotationPresetIdList}
                    handleChangeAnnotationControl={handleChangeAnnotationControl}
                    hasEditPermission={props.hasEditPermission}
                />
            </SimpleTabs>
        </div>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, IActionProps, AnyAction>): IActionProps =>
    ({
        setPackageExportOptions: (data: IContentOptionChange): void => {
            dispatch(setPackageExportOptions(data));
        },
        setPackagePaginationOptions: (data: IPaginationOptionChange): void => {
            dispatch(setPackagePaginationOptions(data));
        },
        createPackageAnnotationList: (id: number): void => {
            dispatch(createPackageAnnotationList(id));
        },
        removeAnnotationOptionFromList: (id: number): void => {
            dispatch(removeAnnotationOptionFromList(id));
        },
    });

export default connect(null, mapDispatchToProps)(ModalPackagePresetsTabs);

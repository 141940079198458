import * as React from 'react';
import { Field } from 'react-final-form';
import {
    renderCheckbox,
    renderTextField,
} from '../../../../components/materialUiForms/materialUiForms';
import { IProps, IState } from './releaseTab.model';
import * as constants from '../../../../constants/disclosure.contstants';
import './releaseTab.styles.scss';
import FormLabel from '@mui/material/FormLabel';

export class ReleaseTab extends React.Component<IProps, IState> {
    public render(): JSX.Element {
        const { labels, langRule } = this.props;

        return (
            <React.Fragment>
                <div>
                    <Field
                        render={renderCheckbox}
                        type='checkbox'
                        name={constants.IS_IN_PAGINATION}
                        label={labels.includeInPaginationLabel[langRule]}
                        disabled={!this.props.hasEditPermissions}
                    />
                </div>
                <div>
                    <Field
                        render={renderCheckbox}
                        type='checkbox'
                        name={constants.IS_DUPLICATE_REF_REQUIRED}
                        label={labels.duplicateReferenceLabel[langRule]}
                        disabled={!this.props.hasEditPermissions}
                    />
                </div>
                <div>
                    <Field
                        render={renderCheckbox}
                        type='checkbox'
                        name={constants.IS_RELEASABLE_STATE}
                        label={labels.releasableLabel[langRule]}
                        disabled={!this.props.hasEditPermissions}
                    />
                </div>
                <div>
                    <Field
                        render={renderCheckbox}
                        type='checkbox'
                        name={constants.IS_EXEMPTION_REQUIRED}
                        label={labels.exemptionRequiredLabel[langRule]}
                        disabled={!this.props.hasEditPermissions}
                    />
                </div>

                <Field
                    render={renderTextField}
                    name={constants.PAGE_OUT_NOTICE}
                    label={labels.pageOutNoticeLabel[langRule]}
                    multiline={true}
                    minRows='3'
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    disabled={!this.props.hasEditPermissions}
                />
                <div className='count-page'>
                    <FormLabel>
                    {labels.countPageAsLabel[langRule]}:
                    </FormLabel>
                    <div className='count-checkBox'>
                        <div>
                            <Field
                                render={renderCheckbox}
                                type='checkbox'
                                name={constants.IS_DUPLICATE}
                                label={labels.countDuplicateLabel[langRule]}
                                disabled={!this.props.hasEditPermissions}
                            />
                        </div>
                        <div>
                            <Field
                                render={renderCheckbox}
                                type='checkbox'
                                name={constants.IS_NOT_RELEVANT}
                                label={labels.notRelevantLabel[langRule]}
                                disabled={!this.props.hasEditPermissions}
                            />
                        </div>
                        <div>
                            <Field
                                render={renderCheckbox}
                                type='checkbox'
                                name={constants.IS_RECEIVED}
                                label={labels.receivedLabel[langRule]}
                                disabled={!this.props.hasEditPermissions}
                            />
                        </div>
                        <div>
                            <Field
                                render={renderCheckbox}
                                type='checkbox'
                                name={constants.IS_RELEASED}
                                label={labels.releasedLabel[langRule]}
                                disabled={!this.props.hasEditPermissions}
                            />
                        </div>
                        <div>
                            <Field
                                render={renderCheckbox}
                                type='checkbox'
                                name={constants.IS_REVIEWED}
                                label={labels.reviewedLabel[langRule]}
                                disabled={!this.props.hasEditPermissions}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ReleaseTab;

import * as React from 'react';
import Button from '@mui/material/Button';
import { IStringDescription } from './stringDescription.model';
import { IDescriptionItem } from '../../../../redux/reducers/indexMetadataDescriptions/indexMetadataDescriptions.model';

export const StringDescription =
    ({ descriptions, handleReplace, editPermission, disableReplace, labels, langRule }: IStringDescription)
        : JSX.Element => (
        <div className='modal-index-keyword_body_container'>
            <div className='modal-index-keyword_body_container_header'>
                <div className='modal-index-keyword-description'>{labels.descriptionLabel[langRule]}</div>
                <div className='modal-index-keyword-occurrences'>{labels.occurancesLabel[langRule]}</div>
                <div className='modal-index-keyword-empty'/>
            </div>
            {
                descriptions.map((item: IDescriptionItem, i: number) => {
                    return (
                        <div className='modal-index-keyword_body_container_item' key={i}>
                            <div title={String(item.value)} className='modal-index-keyword-description'>
                                {item.value as React.ReactNode}
                            </div>
                            <div className='modal-index-keyword-occurrences2'>{item.occurrences}</div>
                            <Button
                                className='modal-index-keyword-empty-button'
                                variant='contained'
                                color='primary'
                                size='small'
                                disabled={!editPermission || disableReplace}
                                onClick={(): void => handleReplace(i)}
                            >
                                {labels.replaceLabel[langRule]}
                            </Button>
                        </div>
                    );
                })
            }
        </div>
    );

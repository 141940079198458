import * as React from 'react';
import classnames from 'classnames';
import { IStampControlsProps } from './control.model';
import {
    STAMP_ELEMENT,
    CUSTOM_STAMP_ELEMENT,
    ACTIVE_ITEM_CLASS,
    ITEM_CLASS,
    ARTICLE_STAMPS,
    CUSTOM_STAMPS,
    RECENTLY_USED,
    AUTO_STAMP,
    AUTO_STAMP_ELEMENT,
} from '../../constants';
import { STAMP } from '../../constants/common.constants';
import { ARTICLE_STAMPS_MODAL, CUSTOM_STAMPS_MODAL } from '../../containers/modalWindowContents';
import { SvgIcon } from '../svgIcons/svgIcons';
import { SelectMaterialBuilder } from '../materialUiForms/materialUiFormBuilder';
import { getRecentlyUsedStamps, getRecentlyUsedStampsOptions } from '../../redux/selectors/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setRecentlyUsed, setSelectedArticleStamps } from '../../redux/actions/layoutRedactorTypes';
import { ElementType, setActiveToolStyles } from './Controls';
import { getRecentlySingleUsedStamp } from '../../redux/selectors/layoutRedactorTypes';
import { getUserPermissionsLoading } from '../../redux/selectors/initialization';
import { getAutoStamp } from '../../redux/selectors/stamps';
import { setAutoStamp } from '../../redux/actions/stamps';

export const STAMP_TEXT = 'Article stamp tool';
export const USER_DEFINED_STAMP_TEXT = 'User Defined Stamp';
export const AUTO_STAMP_TEXT = 'Auto Stamp tool';

const StampControls = (
    {
        activeItem,
        pdfSrc,
        openModalWindow,
        exemptionsPermission,
        customStampsPermission,
        articleStamps,
        selectedAnnotationType,
        selectedCustomStamps,
        autoStampPermission,
    }: IStampControlsProps,
): JSX.Element => {
    const dispatch = useDispatch();
    const [recentlyUsedValue, handleChangeRecentlyUsed] = useState(null);
    const recentlyUsedOptions = useSelector(getRecentlyUsedStampsOptions);
    const recentlyUsedStamps = useSelector(getRecentlyUsedStamps);
    const recentlySingleUsedStamp = useSelector(getRecentlySingleUsedStamp);
    const userPermissionsLoading = useSelector(getUserPermissionsLoading); // need for hide unfiltered list
    const autoStamp = useSelector(getAutoStamp);

    const autoStampSvg = autoStamp ? { borderColor: '#13CB18' } : null;
    const autoStampText = autoStamp ? { color: '#13CB18' } : null;

    const openModal = (type: string): void => {
        const active = type === ARTICLE_STAMPS_MODAL ? exemptionsPermission : customStampsPermission;

        if (pdfSrc && active) {
            openModalWindow(type, { id: STAMP });
        }
    };

    const getClassName = (controlType: string): string => (classnames({
        [ACTIVE_ITEM_CLASS]: activeItem === controlType,
        [ITEM_CLASS]: activeItem !== controlType,
        disabled: controlType === AUTO_STAMP_ELEMENT
            ? !autoStampPermission
            : controlType === STAMP_ELEMENT ? !exemptionsPermission : !customStampsPermission,
    }));

    const activeStamp = !selectedAnnotationType && articleStamps.length && !recentlySingleUsedStamp.length;
    const activeUserStamp = !selectedAnnotationType && selectedCustomStamps.length;
    const activeRecentlyUsed = !selectedAnnotationType && recentlySingleUsedStamp.length;

    const handleSelectRecentlyUsed = (value: string): void => {
        handleChangeRecentlyUsed(value);
        dispatch(setSelectedArticleStamps(recentlyUsedStamps[Number(value)]));
        dispatch(setRecentlyUsed(recentlyUsedStamps[Number(value)]));
    };

    const handleAutoStamp = (value: boolean): void => {
        dispatch(setAutoStamp(value));
    };

    return (
        <React.Fragment>
            <div
                key={USER_DEFINED_STAMP_TEXT}
                className={getClassName(CUSTOM_STAMP_ELEMENT)}
                onClick={(): void => openModal(CUSTOM_STAMPS_MODAL)}
            >
                <div className='icon'>
                    <div className={setActiveToolStyles(ElementType.SVG, !!activeUserStamp)}>
                        <SvgIcon iconname={'CustomStampIcon'} width={18} height={16} />
                    </div>
                    <div className={setActiveToolStyles(ElementType.TEXT, !!activeUserStamp)}>{CUSTOM_STAMPS}</div>
                </div>

            </div>
            <div
                key={STAMP_TEXT}
                className={getClassName(STAMP_ELEMENT)}
                onClick={(): void => openModal(ARTICLE_STAMPS_MODAL)}
            >
                <div className='icon'>
                    <div className={setActiveToolStyles(ElementType.SVG, !!activeStamp)}>
                        <SvgIcon iconname={'ArticleStampIcon'} width={18} height={16} />
                    </div>
                    <div className={setActiveToolStyles(ElementType.TEXT, !!activeStamp)}>{ARTICLE_STAMPS}</div>
                </div>

            </div>
            <div
                key={AUTO_STAMP_TEXT}
                className={getClassName(AUTO_STAMP_ELEMENT)}
                onClick={(): void => handleAutoStamp(!autoStamp)}
            >
                <div className='icon icon-auto-stamp'>
                    <div className='svg' style={autoStampSvg}>
                        <SvgIcon iconname={'AutoStampIcon'} width={18} height={16} />
                    </div>
                    <div className='text' style={autoStampText}>{AUTO_STAMP}</div>
                </div>

            </div>
            <div className={`recentlyUsedSelect ${activeRecentlyUsed ? 'active-border' : ''}`}>
                <SelectMaterialBuilder
                    value={!!activeRecentlyUsed ? recentlyUsedValue
                        : recentlySingleUsedStamp.length
                            ? (recentlyUsedStamps.indexOf(recentlySingleUsedStamp)).toString()
                            : selectedCustomStamps.length
                            ? recentlyUsedValue :'0'}
                    paramName={RECENTLY_USED}
                    label={''}
                    options={userPermissionsLoading ? [] : recentlyUsedOptions}
                    disabled={!recentlyUsedOptions.length || !exemptionsPermission}
                    handleSelectChange={(label: string, value: string): void => {
                        handleSelectRecentlyUsed(value);
                    }}
                />
                <div className={setActiveToolStyles(ElementType.TEXT, !!activeRecentlyUsed)}>{RECENTLY_USED}</div>
            </div>
        </React.Fragment>);
};

export default StampControls;

import * as React from 'react';
import { isEqual, isNull } from 'lodash';
import { Scrollbars } from 'rc-scrollbars';
import { AutoSizer } from 'react-virtualized';
import { fetchWatermarksSettings } from '../../../redux/actions/modalAssociateWatermarkToRequestType';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { IState as StoreState } from '../../../redux/store';
import { AnyAction } from 'redux';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { connect } from 'react-redux';
import { SelectMaterialBuilder } from './../../../components/materialUiForms/materialUiFormBuilder';
import { ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import Button from '@mui/material/Button';
import {
    getWatermarksSettingsList,
    getWatermarkTypesList,
    getRequestTypesWithWatermarkTypes,
    getWatermarksSettingsPending,
    getWatermarksSettingsError,
} from '../../../redux/selectors/modalAssociateWatermark';
import { putSelectedWatermarkType } from '../../../redux/actions/modalAssociateWatermarkToRequestType';
import { IDispatchProps, IStateProps, IProps, IWatermarkState } from './ModalAssociateWatermark.model';
import {
    IWatermarkType,
    IWatermarkRequest,
    IWatermarkTypeAssociate,
} from '../../../redux/reducers/modalAssociateWatermarks/modalAssociateWatermarks.model';
import './ModalAssociateWatermark.style.scss';
import { initialLabel } from '../../../constants/localization.constants';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { getModifiedLabels } from '../../../redux/selectors/localization';

const getAssoiciateWaterMarkLabelsByKey = (key: string): string => {
    switch(key) {
        case 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_REQUEST_TYPE':
            return 'requestLabel';
        case 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_WATERMARK':
            return 'waterMarkLabel';
        case 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_CANCEL':
            return 'cancelLabel';
        case 'ASSOCIATE_WATERMARK_TO_REQUEST_MODAL_OK':
            return 'okLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_TYPE':
            return 'typeWaterMarkLabel';
        case 'WATERMARK_MANAGEMENT_MODAL_NAME':
            return 'nameLabel';
        default: return '';
    }
};
const className = {
    BODY_CHILD_DESC: 'body-children-description',
    BODY_CHILD_TEXT: 'body-children-text',
    TAB_HEAD: 'table-header',
};

class ModalAssociateWatermark  extends React.Component<IProps, IWatermarkState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            requestTypesWithWatermarkTypes: props.requestTypesWithWatermarkTypes,
        };
    }
    public componentDidMount(): void {
        this.props.fetchAllWatermarksSettings();
    }
    public componentDidUpdate(prevProps: IProps): void {
        if (!isEqual(prevProps.requestTypesWithWatermarkTypes, this.props.requestTypesWithWatermarkTypes)) {
            this.setState({ requestTypesWithWatermarkTypes: this.props.requestTypesWithWatermarkTypes });
        }
    }
    public render(): JSX.Element {
        const { requestTypesWithWatermarkTypes } = this.state;
        const { watermarkTypes, handleCloseModalWindow,redactionLang,
            modifiedLabels,} = this.props;

        const emptyOption = {
            id: null,
            name: '',
            requestType: [],
            stampDate: '',
            text: '',
        };
        const watermarkTypesList = [emptyOption, ... watermarkTypes];
        const createOptions = (options: IWatermarkType[]): ISelectOptions[] =>
        options.map((type: IWatermarkType): ISelectOptions => ({ label: type.name, value: isNull(type.id) ?
        null : `${type.id.toString()}`}));
        const labels = {
            requestLabel: initialLabel,
            waterMarkLabel: initialLabel,
            cancelLabel: initialLabel,
            okLabel: initialLabel,

            typeWaterMarkLabel: initialLabel,
            nameLabel: initialLabel,
            clearLabel: initialLabel,
            selectWaterLabel: initialLabel,
            imageLabel: initialLabel,
            textLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);
        resourceBundle.map((resource: any) => {
            if (getAssoiciateWaterMarkLabelsByKey(resource.resourceKey)) {
                labels[getAssoiciateWaterMarkLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getAssoiciateWaterMarkLabelsByKey(resource.resourceKey)) {
                labels[getAssoiciateWaterMarkLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        return (
            <div className='modal_watermark_assotiate'>
            <AutoSizer>
                {({ width, height }: { width: number; height: number; }): JSX.Element  => (
                    <div className='full-width indents' style={{ width, height }}>
                        <div className='table'>
                            <div className={className.TAB_HEAD}>
                                <h5> {labels.requestLabel[langRule]}</h5>
                                <h5>{labels.waterMarkLabel[langRule]}</h5>
                            </div>
                            <Scrollbars>
                                <div className='modal-scroll'>
                                {
                                        requestTypesWithWatermarkTypes.map(
                                            (setting: IWatermarkTypeAssociate) => (
                                            <div className='tab-body' key={setting.requestType}>
                                            {
                                                <div
                                                    className={className.BODY_CHILD_DESC}
                                                >
                                                   {setting.requestDescription}
                                                </div>
                                            }
                                                <div className='select-box'>
                                                {
                                                <SelectMaterialBuilder
                                                    key={setting.requestType}
                                                    value={`${setting.defaultWatermarkTypeId}`}
                                                    paramName={''}
                                                    label={''}
                                                    options={
                                                        createOptions(
                                                            watermarkTypesList,
                                                        )
                                                    }
                                                    handleSelectChange={(label: string, value: string): void =>
                                                        this.handleChange(value,
                                                                          setting.requestType)}
                                                /> }
                                            </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Scrollbars>
                        </div>
                        <div className='form-article-stamps__action'>
                            <Button
                                size='small'
                                variant='outlined'
                                className='buttons-style outlined'
                                onClick={handleCloseModalWindow}
                            >
                                {labels.cancelLabel[langRule]}
                            </Button>
                            <Button
                                size='small'
                                variant='contained'
                                color='primary'
                                onClick={this.onSubmit}
                                className='buttons-style primary'
                            >
                                {labels.okLabel[langRule]}
                            </Button>
                            </div>
                    </div>
                    )}
                </AutoSizer>
               </div>
        );
    }
    private handleChange = (val: string, requestType: string): void => {
        const requestTypesWithWatermarkTypes = this.updateDisclosureSettings(this.state.requestTypesWithWatermarkTypes,
            requestType,
            val);

        this.setState((prevState: IWatermarkState): IWatermarkState => ({
            ...prevState,
            requestTypesWithWatermarkTypes,
        }));
    }
    private onSubmit = (): void => {
        this.props.putWatermarkType(this.state.requestTypesWithWatermarkTypes);
        this.props.handleCloseModalWindow();
    }

    private updateDisclosureSettings = (
        requestTypesWithWatermarkTypes: IWatermarkTypeAssociate[],
        requestType: string,
        value: string,
    ): IWatermarkTypeAssociate[] =>
       requestTypesWithWatermarkTypes.map((type: IWatermarkTypeAssociate): IWatermarkTypeAssociate =>
        type.requestType === requestType ? {
                ...type,
                defaultWatermarkTypeId: isNull(value) ? null : Number(value),
            } : type)
    }

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IDispatchProps , AnyAction>,
): IDispatchProps => ({
    fetchAllWatermarksSettings: (): void => {
        dispatch(fetchWatermarksSettings());
    },
    putWatermarkType: (data: IWatermarkTypeAssociate[]): void => {
        dispatch(putSelectedWatermarkType(data));
    },
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
});
const mapStateToProps = (state: StoreState): IStateProps => ({
    watermarksSettings: getWatermarksSettingsList(state),
    watermarkTypes: getWatermarkTypesList(state),
    requestTypesWithWatermarkTypes: getRequestTypesWithWatermarkTypes(state),
    pending: getWatermarksSettingsPending(state),
    error: getWatermarksSettingsError(state),
    redactionLang: getRedactionLanguage(state),
    modifiedLabels: getModifiedLabels(state),
});

export const ModalAssociateWatermarks = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalAssociateWatermark);

export const START_POINT =  'start-point';
export const END_POINT = 'end-point';
export const DEFAULT_ITEM_COLOR = '#000000';
export const SEVER_TEXT = 'sever';
export const HIGHLIGHT_TEXT = 'highlight';
export const SEVER_TEXT_LABEL = 'Sever';
export const HIGHLIGHT_TEXT_LABEL = 'Highlight';
export const SEVER_ELEMENT = 'sever_element';
export const HIGHLIGHT_ELEMENT = 'highlight_element';
export const ANNOTATION_TYPE_ID = 'annotationTypeId';
export const STAMP_TYPE_ID = 'stampTypeId';
export const ANNOTATIONS = 'annotations';
export const STAMPS = 'stamps';
export const COORDINATE = 'coordinate';

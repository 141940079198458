import { createSelector } from 'reselect';
import { IState } from '../store';
import { IAuditAction, IAuditActionState, IAuditHeader, IAuditLog, IAuditSearchResponse } from '../reducers/auditLog/auditLog.model';
import moment = require('moment');
import { MOMENT_DATE_FORMAT_FULL } from '../../constants/common.constants';

export const getAuditLog = (state: IState): IAuditLog => state.auditLog;

export const getAuditActionsPending = createSelector(
    [getAuditLog],
    (auditLog: IAuditLog): boolean => auditLog.auditActionsPending);

export const getAuditActions = createSelector(
    [getAuditLog],
    (auditLog: IAuditLog): IAuditAction[] => auditLog.auditActionsList);

export const getAuditActionsOptions = createSelector(
    [getAuditActions],
    (auditLog: IAuditAction[]): IAuditActionState[] => {
        return auditLog.map((auditAction: IAuditAction) => {
            const list = auditAction.auditActions.map((label: string) => ({
                label,
                isChecked: false,
            }));

            return ({
                label: auditAction.auditActionType,
                isChecked: false,
                list,
            });
        });
    });

export const getAuditLogsPending = createSelector(
    [getAuditLog],
    (auditLog: IAuditLog): boolean => auditLog.auditLogsPending);

export const getAuditLogs = createSelector(
    [getAuditLog],
    (auditLog: IAuditLog): IAuditSearchResponse[] => auditLog.auditLogsList
        .map((logItem: IAuditSearchResponse) => ({
            ...logItem,
            stampDate: moment(logItem.stampDate).format(MOMENT_DATE_FORMAT_FULL),
        })));

export const getAuditLogHeaders = createSelector(
    [getAuditLog],
    (auditLog: IAuditLog): IAuditHeader[] => auditLog.auditLogColumnHeaders);

export const getResetColumnPendingFlag = createSelector(
    [getAuditLog],
    (auditLog: IAuditLog): boolean => auditLog.resetColumnPending);

import * as React from 'react';
import { findIndex, get, curry, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import Search from '@mui/icons-material/Search';
import Table from '../../components/table/Table';
import ArrowBack from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Button, TextField, Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { CheckboxMaterialBuilder, SelectMaterialBuilder } from '../../components/materialUiForms/materialUiFormBuilder';
import {
    SEARCH_ALL_DOCS_CHECKBOX_LABEL,
    SEARCH_LABEL,
    SEARCH_RESULTS_LABEL,
    CLEAR,
    PREVIOUS,
    NEXT,
    SEVER_ALL,
    HIGHLIGHT_ALL,
    GO_PREV_PAGE_RESULT,
    GO_NEXT_PAGE_RESULT,
    DOCS_UNDER_CURRENT_STACK_PARAM,
    SEVER_PARAM, SEVER_ALL_PARAM,
    HIGHLIGHT_PARAM,
    HIGHLIGHT_ALL_PARAM,
    SELECT_DOCUMENT_TEXT_ERROR,
    EMPTY_SEARCH_ERROR,
    SEARCH_NAVIGATION_STEP, SEARCH_QUERY,
} from '../../constants/globalSearch.constants';
import { ENTER_BUTTON_KEYCODE, NO, OK, YES } from '../../constants/common.constants';
import {
    SEVER_TEXT,
    HIGHLIGHT_TEXT,
    END_POINT,
    START_POINT,
} from '../../constants/annotationTypes.constants';
import { MIN_SHAPE_SIZE } from '../shapesLayer/Transformer';
import { getAnnotationTypesList } from '../../redux/selectors/annotationTypes';
import {
    getCurrentPageId, getOriginalSearchData, getSearchPending,
    getResetedGlobalSearchColumnHeaderLoading, getSearchElementDirection,
    getPreviousSearchElement,
    getGlobalSearchColumnHeaderLoading,
} from '../../redux/selectors/globalSearch';
import {
    setSearchCurrentPageId, clearSearchTable,
    modifyGlobalSearchColumnHeader, setGlobalSearchTableColumns,
    getResetedGlobalSearchColumnHeader,
    getSearchDataFromNextDoc,
    restrictOnFetchDataPageList,
    setElementSearchDirection,
    setPreviousSearchElement,
} from '../../redux/actions/globalSearch';
import { getSearchParams, getSearchData, getSearchCurrentElement } from '../../redux/selectors/globalSearch';
import {
    postSearchValue,
    setSearchParams,
    setCurrentSearchElement,
    applySearchPending,
} from '../../redux/actions/globalSearch';
import { getCurrentDocument, getCurrentPage } from '../../redux/selectors/pageList';
import { addAnnotation, addAnnotationsToDocument } from '../../redux/actions/annotations';
import { getRedactionDocumentId, getUserMetaData } from '../../redux/selectors/initialization';
import { getStackId, getDocumentList } from '../../redux/selectors/documentList';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { IState as StoreState, IState } from '../../redux/store';
import { ISelectOptions } from 'components/materialUiForms/marerialUiForms.model';
import { IAnnotation, ICoordinate, IPages, IPoint } from 'redux/reducers/pageList/pageList.model';
import {
    IDocumentAnnotations,
    IPageActionParams,
    IPageAnnotation,
} from '../../redux/reducers/annotation/annotation.model';
import {
    IActionSearchProps, ICommonSearchProps, IPostSearchValue,
    ISearchParamChange,
    ISearchProps,
    ISearchState, ISearchTableData, ICurrentIds,
    ICurrentSearchElement, ISearchResponseData, ISearchPage, ILinkStampOnSearch,
} from './globalSearch.model';
import { Row } from 'react-table';
import { ITrProps, MyRowData } from '../../components/table/table.model';
import { setDocumentToSelectedList, setPageToSelectedList } from '../../redux/actions/pageList';
import { IFile } from '../../redux/reducers/documentList/documentList.model';
import { getPageSearchData } from '../../utils/globalSearch.util';
import './globalSearch.style.scss';
import { getGlobalSearchTableColumnHeader } from '../../redux/selectors/globalSearch';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { Spinner } from '../../components/spinner/Spinner';
import { IStamp } from '../../redux/reducers/stamps/stamps.model';
import { getAutoStamp } from '../../redux/selectors/stamps';
import { getRecentlySingleUsedStamp } from '../../redux/selectors/layoutRedactorTypes';
import { getRecentlyUsedExemptions, getRedactionLanguage } from '../../redux/selectors/localStorage';
import { IStampType } from '../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { DEFAULT_FONT, PADDING_OFFSET, STAMP_FONT_FAMILY, STAMP_LINE_HEIGHT } from '../../constants/annotation.constants';
import { getTextParams } from '../../utils/string.utils';
import { getClearPrefixAndSuffix } from '../../redux/selectors/modalGlobalSettings';
import { getDocument } from '../../redux/selectors/redactor';
import { degrees } from '../../constants';
import { recalculateShape } from '../../utils/konva.utils';
import { getPageNumberByPageId, getPageResource, setLinkedArticlesFlag } from '../../redux/actions/redactor';
import { handleCloseModalWindow, openModalWindow } from '../../redux/actions/modal';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import {
    CONFIRMATION_DIALOG_MODAL,
    NO_MATCHING_RESULTS,
    NO_MATCHING_RESULT_TO_APPLY, NO_MATCHING_RESULT_TO_APPLY_ALL,
    NO_NEXT_SEARCH_RESULT, NO_PREVIOUS_SEARCH_RESULT, SELECTED_DOC_NOT_IN_CURRENT_STACK,
} from '../../constants/messages.constants';
import { fetchDocumentList, saveStackId } from '../../redux/actions/documentList';
import { getModifiedLabels } from '../../redux/selectors/localization';
import resourceBundle from '../localization/localizationData';
import { initialLabel } from '../../constants/localization.constants';
class GlobalSearch extends React.Component<ICommonSearchProps, ISearchState> {
    constructor(props: ICommonSearchProps) {
        super(props);

        this.state = {
            inputValue: this.props.searchParams[SEARCH_QUERY],
            emptyDocErrorState: null,
            emptyInputErrorState: null,
            selectedAnnotation: null,
            selectedSever: null,
            sortedData: [],
            page: 0,
        };
    }

    public componentDidUpdate(prevProps: ICommonSearchProps): void {
        const {
            currentPageId,
        } = this.props;

        if (!prevProps.currentPageId && prevProps.currentPageId !== currentPageId && this.getCurrentIds(true)) {
            this.props.updateCurrentSearchElement(null);
        }

        if(prevProps.currentPageId !== this.props.currentPageId) {
            this.setPageNumber();
        }
    }

    public render(): JSX.Element {
        const { searchParams, searchData, currentDocument,
            currentPageId, searchPending, globalSearchTableColumnHeader, isAutoStampActive,
            setCurrentSearchElementDirection, currentSearchElementDirection,
            modifiedLabels, redactionLang } = this.props;
        const { inputValue, emptyDocErrorState, emptyInputErrorState } = this.state;
        const labels = {
            searchFieldLabel: initialLabel,
            allDocsUnderStackLabel: initialLabel,
            previousLabel: initialLabel,
            nextLabel: initialLabel,
            severTypeLabel: initialLabel,
            highlightTypeLabel: initialLabel,
            applyOnceLabel: initialLabel,
            applyAllLabel: initialLabel,
            clearLabel: initialLabel,
            resultsTitleLabel: initialLabel,
            goToPreviousLabel: initialLabel,
            goToNextLabel: initialLabel,
            noMatchResultsTitleLabel: initialLabel,
            noMatchResultsApplyLabel: initialLabel,
            okLabel: initialLabel,
        };
        const paginatedData = searchData;

        resourceBundle.map( (resource: any) => {
            switch(resource.resourceKey) {
                case 'SIMPLESEARCH_SEARCH_TEXT':
                    labels.searchFieldLabel = resource;
                    break;
                case 'SIMPLESEARCH_ALL_DOCUMENTS_CURRENT_STACK':
                    labels.allDocsUnderStackLabel = resource;
                    break;
                case 'SIMPLESEARCH_PREVIOUS':
                    labels.previousLabel = resource;
                    break;
                case 'SIMPLESEARCH_NEXT':
                    labels.nextLabel = resource;
                    break;
                case 'SIMPLESEARCH_SEVER_TYPE':
                    labels.severTypeLabel = resource;
                    break;
                case 'SIMPLESEARCH_HIGHLIGHT_TYPE':
                    labels.highlightTypeLabel = resource;
                    break;
                case 'SIMPLESEARCH_APPLY_ONCE':
                    labels.applyOnceLabel = resource;
                    break;
                case 'SIMPLESEARCH_APPLY_ALL':
                    labels.applyAllLabel = resource;
                    break;
                case 'COMMON_LABEL_CLEAR':
                    labels.clearLabel = resource;
                    break;
                case 'SIMPLESEARCH_RESULT_TITLE':
                    labels.resultsTitleLabel = resource;
                    break;
                case 'COMMONSEARCH_GO_TO_PREVIOUS':
                    labels.goToPreviousLabel = resource;
                    break;
                case 'COMMONSEARCH_GO_TO_NEXT':
                    labels.goToNextLabel = resource;
                    break;
                case 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE':
                    labels.noMatchResultsTitleLabel = resource;
                    break;
                case 'SIMPLESEARCH_NO_MATCH_RESULTS_TO_APPLY':
                    labels.noMatchResultsApplyLabel = resource;
                    break;
                case 'COMMON_LABEL_OK':
                    labels.okLabel = resource;
                    break;

            }

            return resource;
        });

        modifiedLabels.map( (resource: any) => {
            switch(resource.resourceKey) {
                case 'SIMPLESEARCH_SEARCH_TEXT':
                    labels.searchFieldLabel = resource;
                    break;
                case 'SIMPLESEARCH_ALL_DOCUMENTS_CURRENT_STACK':
                    labels.allDocsUnderStackLabel = resource;
                    break;
                case 'SIMPLESEARCH_PREVIOUS':
                    labels.previousLabel = resource;
                    break;
                case 'SIMPLESEARCH_NEXT':
                    labels.nextLabel = resource;
                    break;
                case 'SIMPLESEARCH_SEVER_TYPE':
                    labels.severTypeLabel = resource;
                    break;
                case 'SIMPLESEARCH_HIGHLIGHT_TYPE':
                    labels.highlightTypeLabel = resource;
                    break;
                case 'SIMPLESEARCH_APPLY_ONCE':
                    labels.applyOnceLabel = resource;
                    break;
                case 'SIMPLESEARCH_APPLY_ALL':
                    labels.applyAllLabel = resource;
                    break;
                case 'COMMON_LABEL_CLEAR':
                    labels.clearLabel = resource;
                    break;
                case 'SIMPLESEARCH_RESULT_TITLE':
                    labels.resultsTitleLabel = resource;
                    break;
                case 'COMMONSEARCH_GO_TO_PREVIOUS':
                    labels.goToPreviousLabel = resource;
                    break;
                case 'COMMONSEARCH_GO_TO_NEXT':
                    labels.goToNextLabel = resource;
                    break;
                case 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE':
                    labels.noMatchResultsTitleLabel = resource;
                    break;
                case 'SIMPLESEARCH_NO_MATCH_RESULTS_TO_APPLY':
                    labels.noMatchResultsApplyLabel = resource;
                    break;
                case 'COMMON_LABEL_OK':
                    labels.okLabel = resource;
                    break;

            }

            return resource;
        });

        const emptyDocError = emptyDocErrorState
            && !(currentDocument || searchParams[DOCS_UNDER_CURRENT_STACK_PARAM])
            && !!inputValue;
        const emptyInputError = emptyInputErrorState && !inputValue;
        const error = emptyDocError || emptyInputError;
        const langRule = redactionLang ? redactionLang === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';

        return (
            <div className='global-search'>
                <div className='search-input-wrapper'>
                    <TextField
                        multiline={false}
                        minRows='4'
                        label={labels.searchFieldLabel[langRule]}
                        variant='outlined'
                        value={inputValue}
                        className='search-input'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            this.handleSearch((e.target as HTMLInputElement).value)
                        }
                        onKeyDown={this.submitSearchOnEnter}
                        fullWidth={true}
                        error={error}
                    />
                    {this.renderError(emptyDocError, emptyInputError)}
                    <IconButton
                        aria-label='Search'
                        className='search-input-icon'
                        onClick={this.submitSearch}
                    >
                        <Search />
                    </IconButton>
                    <div className={'linear-progress'}>
                        {searchPending && <LinearProgress />}
                    </div>
                </div>
                <CheckboxMaterialBuilder
                    label={labels.allDocsUnderStackLabel[langRule]}
                    checked={searchParams[DOCS_UNDER_CURRENT_STACK_PARAM]}
                    handleCheckboxChange={this.handleChange}
                    paramName={DOCS_UNDER_CURRENT_STACK_PARAM}
                />

                <div className='search-options'>
                    <div className='search-options-buttons'>
                        <Button
                            variant='contained'
                            onClick={(): void => this.resetSearchCoordinates(PREVIOUS)}
                            disabled={this.props.searchPending}
                        >
                            {labels.previousLabel[langRule]}
                        </Button>
                        <Button
                            variant='contained'
                            onClick={(): void => this.resetSearchCoordinates(NEXT)}
                            disabled={this.props.searchPending}
                        >
                            {labels.nextLabel[langRule]}
                        </Button>
                    </div>
                    <div className='search-options-severs'>
                        <SelectMaterialBuilder
                            value={searchParams[SEVER_PARAM] ? searchParams[SEVER_PARAM].id.toString() : ''}
                            label={labels.severTypeLabel[langRule]}
                            paramName={SEVER_PARAM}
                            handleSelectChange={curry(this.handleChangeAnnotationType)(true)}
                            options={this.formatAnnotations(true)}
                        />
                        <div className='search-options-controls'>
                            <Button
                                variant='contained'
                                disabled={!this.state.selectedSever}
                                onClick={(): void => {

                                    if (searchData && searchData.length) {
                                        this.props.setLinkedArticleFlag(isAutoStampActive);
                                        this.addAnnotations(SEVER_TEXT);
                                    } else {
                                        this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                                            id: CONFIRMATION_DIALOG_MODAL,
                                            title: labels.noMatchResultsTitleLabel[langRule],
                                            okButtonTitle: labels.okLabel[langRule],
                                            message: labels.noMatchResultsApplyLabel[langRule],
                                            confirm: (): () => void => (): void => {
                                                handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                                                return;
                                            },
                                        });
                                    }

                                }}
                            >
                                {labels.applyOnceLabel[langRule]}
                            </Button>
                            <Button
                                variant='contained'
                                disabled={!this.state.selectedSever}
                                onClick={(): void => {

                                    if (searchData && searchData.length) {
                                        this.addAnnotations(SEVER_ALL);
                                    } else {
                                        this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                                            id: CONFIRMATION_DIALOG_MODAL,
                                            title: labels.noMatchResultsTitleLabel[langRule],
                                            okButtonTitle: labels.okLabel[langRule],
                                            message: labels.noMatchResultsApplyLabel[langRule],
                                            confirm: (): () => void => (): void => {
                                                handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                                                return;
                                            },
                                        });
                                    }
                                }}
                            >
                                {labels.applyAllLabel[langRule]}
                            </Button>
                        </div>
                    </div>
                    <div className='search-options-highlights'>
                        <SelectMaterialBuilder
                            value={searchParams[HIGHLIGHT_PARAM] ? searchParams[HIGHLIGHT_PARAM].id.toString() : ''}
                            label={labels.highlightTypeLabel[langRule]}
                            paramName={HIGHLIGHT_PARAM}
                            handleSelectChange={curry(this.handleChangeAnnotationType)(false)}
                            options={this.formatAnnotations(false)}
                        />
                        <div className='search-options-controls'>
                            <Button
                                variant='contained'
                                disabled={!this.state.selectedAnnotation}
                                onClick={(): void => {

                                    if (searchData && searchData.length) {
                                        this.addAnnotations(HIGHLIGHT_TEXT);
                                    } else {
                                        this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                                            id: CONFIRMATION_DIALOG_MODAL,
                                            title: labels.noMatchResultsTitleLabel[langRule],
                                            okButtonTitle: labels.okLabel[langRule],
                                            message: labels.noMatchResultsApplyLabel[langRule],
                                            confirm: (): () => void => (): void => {
                                                handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                                                return;
                                            },
                                        });
                                    }
                                }}
                            >
                                {labels.applyOnceLabel[langRule]}
                            </Button>
                            <Button
                                variant='contained'
                                disabled={!this.state.selectedAnnotation}
                                onClick={(): void => {

                                    if (searchData && searchData.length) {
                                        this.addAnnotations(HIGHLIGHT_ALL);
                                    } else {
                                        this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                                            id: CONFIRMATION_DIALOG_MODAL,
                                            title: labels.noMatchResultsTitleLabel[langRule],
                                            okButtonTitle: labels.okLabel[langRule],
                                            message: labels.noMatchResultsApplyLabel[langRule],
                                            confirm: (): () => void => (): void => {
                                                handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                                                return;
                                            },
                                        });
                                    }
                                }}
                            >
                                {labels.applyAllLabel[langRule]}
                            </Button>
                        </div>
                    </div>
                </div>
                <Button
                    variant='contained'
                    className='clear-button'
                    onClick={this.handleClear}
                >
                    {labels.clearLabel[langRule]}
                </Button>
                <Tooltip title={labels.goToPreviousLabel[langRule]} placement='top'>
                    <IconButton
                        aria-label='Back'
                        onClick={(): void => {
                        if (currentSearchElementDirection) {
                            setCurrentSearchElementDirection(null);
                        }

                        this.navigateResultPage(false, true);}}
                        disabled={this.props.searchPending}
                    >
                        <ArrowBack />
                    </IconButton>
                </Tooltip>
                <Tooltip title={labels.goToNextLabel[langRule]} placement='top'>
                    <IconButton
                        aria-label='Forward'
                        onClick={(): void => {
                        if (currentSearchElementDirection) {
                            setCurrentSearchElementDirection(null);
                        }

                        this.navigateResultPage(true, true);}}
                        disabled={this.props.searchPending}
                    >
                        <ArrowForward />
                    </IconButton>
                </Tooltip>
                {this.props.resetedGlobalSearchColumnLoading || this.props.globalSearchColumnLoading ?
                    <Spinner active={true} /> :
                    <Table
                        data={paginatedData}
                        selectedIds={[currentPageId]}
                        allowSettings={true}
                        pagesCount={searchData.length}
                        defaultPageSize={100}
                        title={labels.resultsTitleLabel[langRule]}
                        columns={globalSearchTableColumnHeader}
                        showPagination={searchData.length > 100}
                        dynamicColumns={globalSearchTableColumnHeader}
                        changeColumns={this.props.setGlobalSearchTableColumns}
                        getTrProps={(rowInfo: any): ITrProps => this.getTrProps(rowInfo)}
                        userPreferenceTable={true}
                        enableShowHideColumn={true}
                        modifyColumns={this.props.modifyGlobalSearchColumnHeader}
                        userId={this.props.userMetaData.userId}
                        resetColumnHeader={this.props.getResetedGlobalSearchColumnHeader}
                        page={this.state.page}
                        isSearch={true}
                        onFetchData={(page: number): void => this.setState({page})}
                        onPageChange={(page: number): void => this.setState({page})}
                    />
                }
            </div>
        );
    }

    private renderError = (emptyDocError: boolean, emptyInputError: boolean): JSX.Element => {
        let selectDocError = initialLabel;
        let emptySearchError = initialLabel;
        const langRule = this.props.redactionLang ? this.props.redactionLang === 'fr'
            ? 'resourceValue2' : 'resourceValue' : 'resourceValue';

        resourceBundle.map((resource: any) => {
            if(resource.resourceKey === 'SIMPLESEARCH_SELECT_DOCUMENT_ERROR') {
                selectDocError = resource;
            } else if(resource.resourceKey === 'SIMPLESEARCH_EMPTY_SEARCH_ERROR') {
                emptySearchError = resource;
            }

            return resource;
        });

        this.props.modifiedLabels.map((resource: any) => {
            if(resource.resourceKey === 'SIMPLESEARCH_SELECT_DOCUMENT_ERROR') {
                selectDocError = resource;
            } else if(resource.resourceKey === 'SIMPLESEARCH_EMPTY_SEARCH_ERROR') {
                emptySearchError = resource;
            }

            return resource;
        });

        if (emptyDocError) {
            return <span className='error-message'>{selectDocError[langRule]}</span>;
        } else if (emptyInputError) {
            return <span className='error-message'>{emptySearchError[langRule]}</span>;
        } else {
            return null;
        }

    }

    private handleClear = (): void => {
        this.props.clearSearchTableData();
        this.props.updateCurrentSearchElement(null);
        this.props.setPrevsSearchElement(null);

        if(this.state.sortedData.length) {
            this.setState({sortedData: []});

        }

        if (this.props.currentSearchElementDirection) {
            this.props.setCurrentSearchElementDirection(null);

        }

    }

    private getCurrentDocId = (pageIndex: number): number => {
        const { searchData, documentList } = this.props;

        const document = documentList.filter((doc: IFile) => {
            return doc.name === searchData[pageIndex].documentName;
        })[0];

        return document ? document.id : searchData[pageIndex].documentId;
    }

    private getPageIndex = (pageIndex: number, forward: boolean): number => {
        const { searchData, currentPageId } = this.props;
        const indexOfCurrentPage = findIndex(
            searchData,
            (item: ISearchTableData): boolean => item.id === currentPageId,
        );

        if (indexOfCurrentPage === -1) {
            return 0;
        }

        const currentPageIndex = forward ? pageIndex + 1 : pageIndex - 1;

        if (currentPageIndex > searchData.length - 1) {
            return 0;
        } else if (currentPageIndex < 0) {
            return searchData.length - 1;
        }

        return currentPageIndex;
    }

    private getCurrentIds = (init: boolean = false, forward?: boolean): ICurrentIds => {
        const { searchData, currentPageId } = this.props;

        let indexOfCurrentPage = findIndex(
            searchData,
            (item: ISearchTableData): boolean => item.id === currentPageId,
        );

        if (indexOfCurrentPage === -1) {
            indexOfCurrentPage = 0;
        }

        if (init && searchData[indexOfCurrentPage]) {
            return {
                indexOfCurrentPage,
                initialPageId: searchData[indexOfCurrentPage].id,
                currentSelectedDoc: this.getCurrentDocId(indexOfCurrentPage),
            };
        } else if (searchData[indexOfCurrentPage]) {
            const pageIndex = this.getPageIndex(indexOfCurrentPage, forward);

            const pageId = searchData[pageIndex].id;
            const actualPageNumber = searchData[pageIndex].page;
            const docName = searchData[pageIndex].documentName;

            return {
                currentSelectedDoc: this.getCurrentDocId(pageIndex),
                indexOfCurrentPage: pageIndex,
                pageId,
                actualPageNumber,
                docName,
            };
        }
    }

    private navigateResultPage = (forward: boolean, fromArrow: boolean = false): void => {
        const {
            searchData,
        } = this.props;

        if (searchData.length) {
            const { currentSelectedDoc, pageId, actualPageNumber, docName } = this.getCurrentIds(false, forward);

            this.goToSelectedPage(currentSelectedDoc, pageId, actualPageNumber, docName, -1, forward, fromArrow);
        }

        return;

    }

    private getTrProps = (rowInfo: Row): ITrProps => {
        this.handleRowClick(rowInfo);
        return {
            id: parseInt(rowInfo.id, 10),
            onClick: (): void => this.handleRowClick(rowInfo),
        };
    }

    private handleRowClick = (rowInfo: any): void => {
        const {
            documentList,
            searchData,
            setCurrentSearchElementDirection,
            currentSearchElementDirection,
            previousSearchElement,
            setPrevsSearchElement,
        } = this.props;

        if(currentSearchElementDirection) {
            setCurrentSearchElementDirection(null);
        }

        const { original: { id, page, documentId, documentName } } = rowInfo;

        if(previousSearchElement && id !== this.props.currentPageId) {
            setPrevsSearchElement(null);
        }

        const indexOfCurrentPage = findIndex(
            searchData,
            (item: ISearchTableData): boolean => item.id === id,
        );

        const selected = documentList.filter((doc: IFile) => {
            return doc.name === searchData[indexOfCurrentPage].documentName;
        })[0];
        const selectedDoc = selected ? selected.id : documentId;

        this.goToSelectedPage(selectedDoc, id, page, documentName, 0);

    }

    private getSearchedPages = (
        selectedDoc: number,
        id: number,
        actualPageNumber: number,
        selectedDocName?: string, searchElementIndex?: number): void => {
        const {
            getPageResources,
            redactionDocumentId,
            setCurrentPageIdParam,
            onSetPageToSelectedList,
            onSetDocumentToSelectedList,
            fetchPageNumberByPageId,
            fetchSearchDataFromNextDoc,
            currentDocument,
            currentSearchElement,
            originalSearchData,
            updateCurrentSearchElement,
            restrictOnFetchDataForPageList,
            setPrevsSearchElement,
        } = this.props;
        const isPageInCurrentPagination = currentDocument && currentDocument.pages &&
            currentDocument.pages && currentDocument.pages.find((page: IPages) => page.id === id);
        const isCurrentDocument = currentDocument && currentDocument.id === selectedDoc;

        if (isCurrentDocument) {
            if (isPageInCurrentPagination) {
                getPageResources(redactionDocumentId, currentDocument.id, id, isPageInCurrentPagination.rotation,
                    isPageInCurrentPagination.layers[0].id);

            } else {
                fetchPageNumberByPageId(redactionDocumentId, selectedDoc, actualPageNumber, true);
            }
        } else {
            fetchSearchDataFromNextDoc(redactionDocumentId, selectedDoc, actualPageNumber);
        }

        setCurrentPageIdParam(id);
        onSetPageToSelectedList(id);
        onSetDocumentToSelectedList(selectedDoc);

        if(this.state.sortedData && this.state.sortedData.length) {
            this.setPageNumber(id);
        }

        if (!currentSearchElement && searchElementIndex >= 0) {

            const pageSearch = getPageSearchData(originalSearchData, selectedDocName, id);

            if (get(pageSearch, 'coordinates')) {

                updateCurrentSearchElement({
                    index: searchElementIndex,
                    coordinates: pageSearch.coordinates[searchElementIndex],
                });
                setPrevsSearchElement({
                    index: searchElementIndex,
                    coordinates: pageSearch.coordinates[searchElementIndex],
                    documentName: selectedDocName,
                    pageId: id,
                    documentId: selectedDoc,
                    actualPageNumber,
                });
                restrictOnFetchDataForPageList(true);

            }
        }
    }
    private goToSelectedPage = (
        selectedDoc: number, id: number,
        actualPageNumber: number,
        selectedDocName?: string,
        searchElementIndex?: number, forward?: boolean, fromArrow?: boolean): void => {
        const {
            documentList,
            originalSearchData,
            handleOpenModalWindow,
            setStackId,
            fetchDocumentsList,
            redactionDocumentId,
            currentSearchElement,
            updateCurrentSearchElement,
            currentDocument,
            currentPage,
            restrictOnFetchDataForPageList,
            setPrevsSearchElement,
            modifiedLabels,
            redactionLang,
        } = this.props;
        let noMatchResult = initialLabel;
        let selectedDocNotInCurrentStack = initialLabel;
        let yesLabel = initialLabel;
        let noLabel = initialLabel;
        const langRule = redactionLang ? redactionLang === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';

        resourceBundle.map((resource: any) => {
            if(resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE') {
                noMatchResult = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_DOCUMENT_NOT_IN_CURRENT_STACK') {
                selectedDocNotInCurrentStack = resource;
            } else if(resource.resourceKey === 'COMMON_LABEL_YES') {
                yesLabel = resource;
            } else if(resource.resourceKey === 'COMMON_LABEL_NO') {
                noLabel = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE') {
                noMatchResult = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_DOCUMENT_NOT_IN_CURRENT_STACK') {
                selectedDocNotInCurrentStack = resource;
            } else if(resource.resourceKey === 'COMMON_LABEL_YES') {
                yesLabel = resource;
            } else if(resource.resourceKey === 'COMMON_LABEL_NO') {
                noLabel = resource;
            }

            return resource;
        });

        const isDocExist = documentList.filter((docListValue: IFile) => originalSearchData.some(
            (searchValue: ISearchResponseData) => docListValue.id === searchValue.documentId));
        const isSelectedDocExistInDocList = isDocExist && isDocExist.length &&
            isDocExist.filter((docListValue: IFile) => docListValue.id === selectedDoc);

        if ((isDocExist && !isDocExist.length) ||
            (isDocExist && isSelectedDocExistInDocList && !isSelectedDocExistInDocList.length)) {
            handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: noMatchResult[langRule],
                okButtonTitle: yesLabel[langRule],
                cancelButtonTitle: noLabel[langRule],
                message: selectedDocNotInCurrentStack[langRule],
                confirm: (): () => void => (): void => {

                    fetchDocumentsList(redactionDocumentId);
                    setStackId(0);
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    if(searchElementIndex >= 0) {
                        this.getSearchedPages(selectedDoc, id, actualPageNumber,
                            selectedDocName, searchElementIndex);

                        return;
                    } else if(searchElementIndex === -1) {
                        this.getSearchedPages(selectedDoc, id, actualPageNumber,
                            selectedDocName, searchElementIndex);

                        const pageSearch = getPageSearchData(originalSearchData, selectedDocName, id);

                        if (get(pageSearch, 'coordinates')) {

                            updateCurrentSearchElement({
                                index: forward || fromArrow ? 0 : pageSearch.coordinates.length - 1,
                                coordinates: forward || fromArrow ? pageSearch.coordinates[0] :
                                    pageSearch.coordinates[pageSearch.coordinates.length - 1],
                            });
                            setPrevsSearchElement({
                                index: forward || fromArrow ? 0 : pageSearch.coordinates.length - 1,
                                coordinates: forward || fromArrow ? pageSearch.coordinates[0] :
                                    pageSearch.coordinates[pageSearch.coordinates.length - 1],
                                documentName: selectedDocName,
                                pageId: id,
                                actualPageNumber,
                                documentId: selectedDoc,
                            });
                            restrictOnFetchDataForPageList(true);
                        }

                        return;
                    }
                },
                reject: (): () => void => (): void => {
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    return;
                },

            });

            return;
        } else if(searchElementIndex === -1 && !currentSearchElement && !currentDocument && !currentPage &&
            isSelectedDocExistInDocList && isSelectedDocExistInDocList.length && !fromArrow) {

            const pageSearch = getPageSearchData(originalSearchData, selectedDocName, id);
            const index = forward || fromArrow ? 0 : pageSearch.coordinates.length - 1;

            this.getSearchedPages(selectedDoc, id, actualPageNumber, selectedDocName, index);

            return;
        }

        this.getSearchedPages(selectedDoc, id, actualPageNumber, selectedDocName, 0);

    }
    private handleSearch = (inputValue: string): void => {
        const { currentDocument, searchParams } = this.props;
        const emptyDocErrorState = !(currentDocument || searchParams[DOCS_UNDER_CURRENT_STACK_PARAM]);

        this.setState({ inputValue, emptyDocErrorState, emptyInputErrorState: false });
    }

    private submitSearch = (): void => {
        const { inputValue } = this.state;
        const { currentDocument, searchParams, redactionDocumentId, stackId,
            setCurrentSearchElementDirection, currentSearchElementDirection, setPrevsSearchElement } = this.props;

        if(currentDocument) {
            if (currentSearchElementDirection) {
                setCurrentSearchElementDirection(null);
            }

            setPrevsSearchElement(null);

            if(this.state.sortedData.length) {
                this.setState({sortedData: [], page: 0});

            }
        }

        if (inputValue) {
            this.props.postSearchValue(redactionDocumentId, {
                docId: currentDocument ? currentDocument.id : undefined,
                searchInStack: searchParams[DOCS_UNDER_CURRENT_STACK_PARAM],
                searchQuery: inputValue,
                stackId: stackId ? stackId : undefined,
            });

        } else {
            this.setState({ emptyInputErrorState: true });
        }
    }

    private submitSearchOnEnter = (event: React.KeyboardEvent): void => {
        if (event.keyCode === ENTER_BUTTON_KEYCODE) {
            this.submitSearch();
        }
    }

    private getCoordinatesWithLack = (start: IPoint, end: IPoint): ICoordinate => {
        const width = end.x - start.x;
        const height = end.y - start.y;
        const xLack = width < MIN_SHAPE_SIZE ? Math.round((MIN_SHAPE_SIZE - width) / 2) : 0;
        const yLack = height < MIN_SHAPE_SIZE ? Math.round((MIN_SHAPE_SIZE - height) / 2) : 0;

        return {
            [END_POINT]: {
                x: end.x + xLack,
                y: end.y + yLack,
            },
            [START_POINT]: {
                x: start.x - xLack,
                y: start.y - yLack,
            },
        };
    }

    private getSearchDocumentAnnotations = (
        redactionDocumentId: number,
        documentId: number,
        annotation: IAnnotation,
        isSever?: boolean,
    ): IDocumentAnnotations => {
        const { originalSearchData, isAutoStampActive, data } = this.props;
        const currentDocumentSearchData = originalSearchData.find((searchData: ISearchResponseData): boolean =>
            searchData.documentId === documentId);

        return {
            documentId,
            pageAnnotations: currentDocumentSearchData && currentDocumentSearchData.pages &&
                currentDocumentSearchData.pages.map((page: ISearchPage): IPageAnnotation => {
                    const documentCoordinates = page.coordinates.map((coordinate: ICoordinate): ICoordinate => {

                        return this.getCoordinatesWithLack(coordinate[START_POINT], coordinate[END_POINT]);
                    });

                    const annotationDto = documentCoordinates.map((coordinates: ICoordinate): ILinkStampOnSearch => {
                        let stampDtoList = [];

                        if (isAutoStampActive && isSever) {
                            const newCoordinates = {
                                'start-point': {
                                    x: coordinates[START_POINT].x * data.scale,
                                    y: coordinates[START_POINT].y * data.scale,
                                },
                                'end-point': {
                                    x: coordinates[END_POINT].x * data.scale,
                                    y: coordinates[END_POINT].y * data.scale,
                                },

                            };

                            if (page.page.rotation === degrees.ZERO) {
                                stampDtoList = this.handleStamps(
                                    redactionDocumentId,
                                    documentId,
                                    page.page,
                                    newCoordinates[START_POINT].x,
                                    newCoordinates[START_POINT].y,
                                    true,
                                );
                            }

                            if (page.page.rotation === degrees.MINUS_QUARTER) {
                                stampDtoList = this.handleStamps(
                                    redactionDocumentId,
                                    documentId,
                                    page.page,
                                    newCoordinates[START_POINT].x,
                                    newCoordinates[END_POINT].y,
                                    true,
                                );
                            }

                            if (page.page.rotation === degrees.HALF) {
                                stampDtoList = this.handleStamps(
                                    redactionDocumentId,
                                    documentId,
                                    page.page,
                                    newCoordinates[END_POINT].x,
                                    newCoordinates[END_POINT].y,
                                    true,
                                );
                            }

                            if (page.page.rotation === degrees.THREE_QUARTERS) {
                                stampDtoList = this.handleStamps(
                                    redactionDocumentId,
                                    documentId,
                                    page.page,
                                    newCoordinates[END_POINT].x,
                                    newCoordinates[START_POINT].y,
                                    true,
                                );
                            }
                        }

                        return ({
                            annotationTypeId: annotation.id,
                            color: annotation.color,
                            coordinate: [coordinates],
                            text: annotation.text,
                            stampDtoList,
                        });

                    });

                    return {
                        annotationDto,
                        layerId: page.page.layers[0].id,
                        pageId: page.page.id,
                    };
                }),
        };
    }

    private calculateValidHorizontalCoordinates(
        x: number,
        y: number,
        textWidth: number,
        textHeight: number,
        idx: number,
        data: any,
    ): ICoordinate[] {
        const {
            prefixAndSuffix,
            data: {
                scale,
            },
            lastUsedExemption,
            recentlySingleUsedStamp,
        } = this.props;
        const height = data.pdfHeight * scale;
        const width = data.pdfWidth * scale;
        const isRecentlyUsedStamps = !isEmpty(recentlySingleUsedStamp);
        const allStampsCount = isRecentlyUsedStamps ? recentlySingleUsedStamp.length : lastUsedExemption.length;

        if (data.pageRotation === degrees.ZERO) {
            const limitCondition = y + textHeight * allStampsCount > height;
            const newY = limitCondition ? y - ((y + textHeight * allStampsCount) - height) : y;
            const endPointX = x + textWidth > width ? width : x + textWidth;
            const startPointX = x + textWidth > width ? endPointX - textWidth : x;

            return [
                {
                    [START_POINT]: {
                        x: startPointX,
                        y: newY + (textHeight * idx),
                    },
                    [END_POINT]: {
                        x: endPointX,
                        y: newY + (textHeight * (idx + 1)),
                    },
                },
            ];
        }

        if (data.pageRotation === degrees.MINUS_QUARTER) {
            const limitCondition = x + textHeight * allStampsCount > width;
            const newX = limitCondition ? x - ((x + textHeight * allStampsCount) - width) : x;
            const startPointY = y - textWidth < 0 ? textWidth : y;

            return [
                {
                    [START_POINT]: {
                        x: idx === 0 ? newX : newX + (textHeight * idx),
                        y: startPointY,
                    },
                    [END_POINT]: {
                        x: idx === 0 ? newX + textWidth : x + (textHeight * idx) + textWidth,
                        y: startPointY + textHeight,
                    },
                },
            ];
        }

        if (data.pageRotation === degrees.HALF) {
            const limitCondition = y - textHeight * allStampsCount < 0;
            const newY = limitCondition ? y - (y - textHeight * allStampsCount) : y;
            const endPointX = x - textWidth < 0 ? textWidth * 2 : x + textWidth;
            const startPointX = x - textWidth < 0 ? textWidth : x;

            return [
                {
                    [START_POINT]: {
                        x: startPointX,
                        y: newY + (textHeight * (-idx)),
                    },
                    [END_POINT]: {
                        x: endPointX,
                        y: newY + (textHeight * (-idx + 1)),
                    },
                },
            ];
        }

        if (data.pageRotation === degrees.THREE_QUARTERS) {
            const limitCondition = x - textHeight * allStampsCount < 0;
            const newX = limitCondition ? x - (x - textHeight * allStampsCount) : x;
            const startPointY = y + textWidth > height ? height - textWidth : y;

            return [
                {
                    [START_POINT]: {
                        x: idx === 0 ? newX : newX + (textHeight * (-idx)),
                        y: startPointY,
                    },
                    [END_POINT]: {
                        x: idx === 0 ? newX + textWidth : newX + (textHeight * (-idx)) + textWidth,
                        y: startPointY + textHeight,
                    },
                },
            ];
        }
    }

    private parseStamps(
        stampTypes: any,
        isExemption: boolean,
        redactionDocumentId: number,
        documentId: number,
        page: any,
        x: number,
        y: number,
        isApplyAll: boolean,
    ): IStamp[] {
        const {
            prefixAndSuffix,
            data: {
                scale,
            },
            currentPage,
        } = this.props;
        let data;

        if (isApplyAll) {
            data = {
                pageRotation: page.rotation,
                pdfHeight: page.height,
                pdfWidth: page.width,
            };
        } else {
            data = {
                pageRotation: this.props.data.rotate,
                pdfHeight: this.props.data.pdfHeight,
                pdfWidth: this.props.data.pdfWidth,
            };
        }

        const height = data.pdfHeight * scale;
        const width = data.pdfWidth * scale;
        const actionParams = {
            redactionDocumentId,
            documentId,
            pageId: get(page, 'id'),
            layerId: get(page, 'layers[0].id'),
        };

        return stampTypes.map((stamp: IStampType, i: number): IStamp => {
            const { prefix, suffix } = prefixAndSuffix;
            const text = isExemption ? `${prefix}${stamp.exemptionLabel}${suffix}` : stamp.text;
            const { textWidth, fontSize } = getTextParams(text, DEFAULT_FONT, STAMP_FONT_FAMILY, width);
            const textHeight = fontSize * STAMP_LINE_HEIGHT < MIN_SHAPE_SIZE ?
                MIN_SHAPE_SIZE :
                fontSize * STAMP_LINE_HEIGHT;
            const newX = x;
            const newY = y;

            return {
                coordinate: this.calculateValidHorizontalCoordinates(newX, newY, (textWidth + PADDING_OFFSET) * scale,
                    textHeight * scale, i, data),
                isExemption,
                lastModified: new Date().toISOString(),
                stampTypeId: isExemption ? stamp.exemptionCode : stamp.id,
                text: isExemption ? stamp.exemptionLabel : stamp.text,
                pageId: actionParams.pageId,
                fontSize: fontSize * scale,
                // TODO 2 better to rename rotation to page rotation
                rotation: data.pageRotation,
            };
        });
    }
    private handleStamps(
        redactionDocumentId: number,
        documentId: number,
        currentPage: any,
        x: number,
        y: number,
        isApplyAll: boolean = false,
    ): IStamp[] {

        const {
            recentlySingleUsedStamp,
            lastUsedExemption,
            data,
        } = this.props;

        const actionParams = {
            redactionDocumentId,
            documentId,
            pageId: get(currentPage, 'id'),
            layerId: get(currentPage, 'layers[0].id'),
        };

        const isRecentlyUsedExemption = !isEmpty(lastUsedExemption);
        const isRecentlyUsedStamps = !isEmpty(recentlySingleUsedStamp);
        const someChecked = isRecentlyUsedExemption || isRecentlyUsedStamps;

        if (someChecked) {
            return this.parseStamps(
                isRecentlyUsedStamps ? recentlySingleUsedStamp : lastUsedExemption,
                isRecentlyUsedStamps ? isRecentlyUsedStamps : isRecentlyUsedExemption,
                redactionDocumentId,
                documentId,
                currentPage,
                x,
                y,
                isApplyAll,
            ).map((stamp: IStamp): IStamp => recalculateShape(stamp, data.scale));
        }
    }

    private addAnnotations = (param: any): void => {
        const {
            redactionDocumentId,
            currentDocument,
            currentPage,
            currentSearchElement,
            originalSearchData,
            searchParams,
            isAutoStampActive,
            data: { rotate, scale },
            redactionLang,
            modifiedLabels,
        } = this.props;
        const actionParams = {
            redactionDocumentId,
            documentId: get(currentDocument, 'id'),
            pageId: get(currentPage, 'id'),
            layerId: get(currentPage, 'layers[0].id'),
        };
        const isSever = param === SEVER_TEXT || param === SEVER_ALL
            ? searchParams.sever && searchParams.sever.isSever
            || searchParams.severAll && searchParams.severAll.isSever
            : null;

        const annotationById = param === SEVER_TEXT || param === SEVER_ALL
            ? this.state.selectedSever
            : this.state.selectedAnnotation;
        let noMatchResultApply = initialLabel;
        let noMatchResultApplyAll = initialLabel;
        let noMatchResult = initialLabel;
        let okLabel = initialLabel;
        const langRule = redactionLang ? redactionLang === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';

        resourceBundle.map((resource: any) => {
            if(resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE') {
                noMatchResult = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TO_APPLY') {
                noMatchResultApply = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TO_APPLY_ALL') {
                noMatchResultApplyAll = resource;
            } else if (resource.resourceKey === 'COMMON_LABEL_OK') {
                okLabel = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE') {
                noMatchResult = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TO_APPLY') {
                noMatchResultApply = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TO_APPLY_ALL') {
                noMatchResultApplyAll = resource;
            } else if (resource.resourceKey === 'COMMON_LABEL_OK') {
                okLabel = resource;
            }

            return resource;
        });

        if (currentSearchElement && (param === SEVER_TEXT || param === HIGHLIGHT_TEXT)) {

            this.props.applySearchPending(true);

            const start = currentSearchElement.coordinates[START_POINT];
            const end = currentSearchElement.coordinates[END_POINT];
            const coordinate = [this.getCoordinatesWithLack(start, end)];

            const newAnnotation = {
                annotationTypeId: annotationById.id,
                color: annotationById.color,
                coordinate,
                lastModified: new Date().toISOString(),
                opacity: annotationById.opacity,
                text: annotationById.description,
                isBorderOnly: !!annotationById.isBorderOnly,
            };

            if (isAutoStampActive && isSever) {
                let stampDtoList = [];

                if (rotate === degrees.ZERO) {
                    stampDtoList = this.handleStamps(
                        redactionDocumentId,
                        get(currentDocument, 'id'),
                        currentPage,
                        coordinate[0][START_POINT].x,
                        coordinate[0][START_POINT].y,
                    );
                }

                if (rotate === degrees.MINUS_QUARTER) {
                    stampDtoList = this.handleStamps(
                        redactionDocumentId,
                        get(currentDocument, 'id'),
                        currentPage,
                        coordinate[0][START_POINT].x,
                        coordinate[0][END_POINT].y,
                    );
                }

                if (rotate === degrees.HALF) {
                    stampDtoList = this.handleStamps(
                        redactionDocumentId,
                        get(currentDocument, 'id'),
                        currentPage,
                        coordinate[0][END_POINT].x,
                        coordinate[0][END_POINT].y,
                    );
                }

                if (rotate === degrees.THREE_QUARTERS) {
                    stampDtoList = this.handleStamps(
                        redactionDocumentId,
                        get(currentDocument, 'id'),
                        currentPage,
                        coordinate[0][END_POINT].x,
                        coordinate[0][START_POINT].y,
                    );
                }

                const annotation = {
                    documentId: actionParams.documentId,
                    pageAnnotations: [{
                        annotationDto: [{
                            annotationTypeId: annotationById.id,
                            color: annotationById.color,
                            coordinate: [{
                                [START_POINT]: {
                                    x: coordinate[0][START_POINT].x / scale,
                                    y: coordinate[0][START_POINT].y / scale,
                                },
                                [END_POINT]: {
                                    x: coordinate[0][END_POINT].x / scale,
                                    y: coordinate[0][END_POINT].y / scale,
                                },
                            }],
                            lastModified: new Date().toISOString(),
                            text: annotationById.description,
                            stampDtoList,
                        }],
                        layerId: actionParams.layerId,
                        pageId: actionParams.pageId,
                    }],
                };

                this.props.addAnnotationsToDocument(actionParams, [annotation], true);
            } else {
                this.props.addAnnotations(actionParams, [newAnnotation], null, true);

            }

        } else if (this.props.searchData && this.props.searchData.length &&
            (param === SEVER_ALL || param === HIGHLIGHT_ALL)) {

            const data = originalSearchData.length > 1 ?
                originalSearchData.map((searchResponseData: ISearchResponseData): IDocumentAnnotations => {
                    return this.getSearchDocumentAnnotations(
                        searchResponseData.redactionDocumentId, searchResponseData.documentId, annotationById,
                        isSever);
                }) :
                [this.getSearchDocumentAnnotations(
                    redactionDocumentId,
                    originalSearchData[0].documentId,
                    annotationById, isSever,
                )];

            this.props.addAnnotationsToDocument(actionParams, data, isSever && isAutoStampActive);
            this.props.applySearchPending(true);

        } else if (!currentSearchElement && (param === SEVER_TEXT || param === HIGHLIGHT_TEXT)) {
            this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: noMatchResult[langRule],
                okButtonTitle: okLabel[langRule],
                message: noMatchResultApply[langRule],
                confirm: (): () => void => (): void => {
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    return;
                },
            });
        } else if (!currentSearchElement && (param === SEVER_ALL || param === HIGHLIGHT_ALL)) {
            this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: noMatchResult[langRule],
                okButtonTitle: okLabel[langRule],
                message: noMatchResultApplyAll[langRule],
                confirm: (): () => void => (): void => {
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                    return;
                },
            });
        }
    }

    private handleChangeAnnotationType = (isSever: boolean, param: string, value: string): void => {
        const { annotationTypesList } = this.props;

        const annotationById = annotationTypesList.find(
            (item: IAnnotation): boolean => item.id === Number(value));

        if (isSever) {
            this.setState({ selectedSever: annotationById });
        } else {
            this.setState({ selectedAnnotation: annotationById });
        }

        this.props.setSearchParams({ [param]: annotationById });
    }

    private handleChange = (param: string, value: boolean | string): void => {
        this.props.setSearchParams({ [param]: value });
    }

    private formatAnnotations = (isSever: boolean): ISelectOptions[] => {
        const emptyAnnotation = { id: 0, isActive: true, name: '', isSever };
        const annotationOptions = [emptyAnnotation, ...this.props.annotationTypesList];

        return annotationOptions
            .filter((item: IAnnotation): boolean => item.isActive)
            .filter((item: IAnnotation): boolean => item.isSever === isSever)
            .map((item: IAnnotation): ISelectOptions => {
                return {
                    value: item.id.toString(),
                    label: this.props.redactionLang ? this.props.redactionLang === 'fr'
                    ? item.name2 ? item.name2 : item.name : item.name : item.name,
                };
            });

    }

    private resetSearchCoordinates = (direction: string): void => {
        const { currentDocument, currentPage, currentSearchElement, originalSearchData,
            setCurrentSearchElementDirection, previousSearchElement } = this.props;
        const isPrevious = direction === PREVIOUS;
        const pageSearchData = currentDocument ? getPageSearchData(
            originalSearchData,
            currentDocument.name,
            currentPage.id,
        ) : null;

        if (this.checkIsCurrentElementLast(isPrevious)) {
            return;

        }

        if (currentSearchElement) {
            setCurrentSearchElementDirection(isPrevious ? PREVIOUS : NEXT);

            const index = isPrevious ? currentSearchElement.index - SEARCH_NAVIGATION_STEP :
                currentSearchElement.index + SEARCH_NAVIGATION_STEP;

            if(pageSearchData && !pageSearchData.coordinates.length) {
                return;
            }

            if (!pageSearchData.coordinates[index]) {
                this.navigateResultPage(!isPrevious);

                return;
            }

            this.props.updateCurrentSearchElement({
                index,
                coordinates: pageSearchData.coordinates[index],
            });
            this.props.setPrevsSearchElement({
                index,
                coordinates: pageSearchData.coordinates[index],
                ...(currentDocument && currentPage && {
                    documentName: currentDocument.name,
                    documentId: currentDocument.id,
                    pageId: currentPage.id,
                    actualPageNumber: currentPage.actualPageNumber,
                }),
            });

        } else if (!currentSearchElement && originalSearchData && originalSearchData.length) {
            setCurrentSearchElementDirection(isPrevious ? PREVIOUS : NEXT);

            const pageData = getPageSearchData(
                originalSearchData,
                previousSearchElement.documentName,
                previousSearchElement.pageId,
            );
            const index = isPrevious ? previousSearchElement.index - SEARCH_NAVIGATION_STEP :
            previousSearchElement.index + SEARCH_NAVIGATION_STEP;

            if (pageData.coordinates.length && !pageData.coordinates[index]) {
                this.navigateResultPage(!isPrevious);

                return;
            }

            this.goToSelectedPage(
                previousSearchElement.documentId,
                previousSearchElement.pageId,
                previousSearchElement.actualPageNumber,
                previousSearchElement.documentName,
                index);

        }
    }
    private setPageNumber = (pageId: number = this.props.currentPageId): void => {

        if(this.props.searchData.length < 100) {
            this.setState({page: 0});

            return;

        }

        if(this.calculatePageSetIndex(pageId) > 0) {
            this.setState({page: this.calculatePageSetIndex(pageId)});

            return;
        }

        this.setState({page: 0});
    }

    private calculatePageSetIndex = (pageId: number): number => {

        const currentPageIdIndex = this.state.sortedData.length &&
            this.state.sortedData.findIndex((data: IPages) =>
                data.id === pageId);

        return Math.floor(currentPageIdIndex / 100);
    }

    private checkIsCurrentElementLast = (isPrevious: boolean): boolean => {
        const {
            originalSearchData, previousSearchElement, modifiedLabels, redactionLang,
        } = this.props;
        let isLastOrFirstDoc;
        let isLastOrFirstPage;
        let isLastOrFirstElement;
        let noNextResult = initialLabel;
        let noPreviousResult = initialLabel;
        let noMatchResult = initialLabel;
        let okLabel = initialLabel;
        const langRule = redactionLang ? redactionLang === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';

        resourceBundle.map((resource: any) => {
            if(resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE') {
                noMatchResult = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_NO_PREVIOUS_RESULT') {
                noPreviousResult = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_NO_NEXT_RESULT') {
                noNextResult = resource;
            } else if (resource.resourceKey === 'COMMON_LABEL_OK') {
                okLabel = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(resource.resourceKey === 'SIMPLESEARCH_NO_MATCH_RESULTS_TITLE') {
                noMatchResult = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_NO_PREVIOUS_RESULT') {
                noPreviousResult = resource;
            } else if (resource.resourceKey === 'SIMPLESEARCH_NO_NEXT_RESULT') {
                noNextResult = resource;
            } else if (resource.resourceKey === 'COMMON_LABEL_OK') {
                okLabel = resource;
            }

            return resource;
        });

        if(!isPrevious) {
            const lastDoc = originalSearchData[originalSearchData.length-1];
            const lastPageIndex = lastDoc.pages.length-1;

            isLastOrFirstDoc = lastDoc.documentId === previousSearchElement.documentId;
            isLastOrFirstPage = isLastOrFirstDoc &&
                lastDoc.pages[lastPageIndex].page.id === previousSearchElement.pageId;
            isLastOrFirstElement = isLastOrFirstPage &&
                lastDoc.pages[lastPageIndex].coordinates.length -1 === previousSearchElement.index;

        } else if(isPrevious) {
            isLastOrFirstDoc = originalSearchData[0].documentId === previousSearchElement.documentId;
            isLastOrFirstPage = isLastOrFirstDoc &&
                originalSearchData[0].pages[0].page.id === previousSearchElement.pageId;
            isLastOrFirstElement = isLastOrFirstPage && 0 === previousSearchElement.index;

        }

        if(isLastOrFirstElement) {
            this.props.handleOpenModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: CONFIRMATION_DIALOG_MODAL,
                title: noMatchResult[langRule],
                okButtonTitle: okLabel[langRule],
                message: isPrevious ? noPreviousResult[langRule] : noNextResult[langRule],
                confirm: (): () => void => (): void => {
                    handleCloseModalWindow(CONFIRMATION_DIALOG_MODAL);

                },
            });

            return true;
        }

        return false;
    }
}

const mapStateToProps = (state: StoreState): ISearchProps => ({
    annotationTypesList: getAnnotationTypesList(state),
    searchParams: getSearchParams(state),
    searchPending: getSearchPending(state),
    redactionDocumentId: getRedactionDocumentId(state),
    currentDocument: getCurrentDocument(state),
    currentPageId: getCurrentPageId(state),
    documentList: getDocumentList(state),
    originalSearchData: getOriginalSearchData(state),
    searchData: getSearchData(state),
    stackId: getStackId(state),
    currentSearchElement: getSearchCurrentElement(state),
    currentPage: getCurrentPage(state),
    globalSearchTableColumnHeader: getGlobalSearchTableColumnHeader(state),
    userMetaData: getUserMetaData(state),
    resetedGlobalSearchColumnLoading: getResetedGlobalSearchColumnHeaderLoading(state),
    isAutoStampActive: getAutoStamp(state),
    recentlySingleUsedStamp: getRecentlySingleUsedStamp(state),
    lastUsedExemption: getRecentlyUsedExemptions(state),
    prefixAndSuffix: getClearPrefixAndSuffix(state),
    data: getDocument(state),
    currentSearchElementDirection: getSearchElementDirection(state),
    previousSearchElement: getPreviousSearchElement(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
    globalSearchColumnLoading: getGlobalSearchColumnHeaderLoading(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IState, IActionSearchProps, AnyAction>): IActionSearchProps => ({
    setSearchParams: (param: ISearchParamChange): void => {
        dispatch(setSearchParams(param));
    },
    postSearchValue: (docId: number, value: IPostSearchValue): void => {
        dispatch(postSearchValue(docId, value));
    },
    updateCurrentSearchElement: (element: ICurrentSearchElement): void => {
        dispatch(setCurrentSearchElement(element));
    },
    addAnnotations: (
        params: IPageActionParams,
        annotations: IAnnotation[],
        actionCall: () => AnyAction,
        isSearch: boolean,
    ): void => {
        dispatch(addAnnotation(params, annotations, actionCall, isSearch));
    },
    addAnnotationsToDocument: (params: IPageActionParams, data: IDocumentAnnotations[], isLinkedArticles?: boolean)
        : void => {
        dispatch(addAnnotationsToDocument(params, data, isLinkedArticles, true));
    },
    setCurrentPageIdParam: (id: number): void => {
        dispatch(setSearchCurrentPageId(id));
    },
    onSetDocumentToSelectedList: (id: number, isMultiple: boolean = false): void => {
        dispatch(setDocumentToSelectedList(id, isMultiple));
    },
    onSetPageToSelectedList: (id: number, isMultiple: boolean = false): void => {
        dispatch(setPageToSelectedList(id, isMultiple));
    },
    getPageResources: (redactionDocumentId: number, id: number, pageId: number, rotation: number, layerId: number)
        : void => {
        dispatch(getPageResource(redactionDocumentId, id, pageId, rotation, layerId));
    },
    clearSearchTableData: (): void => {
        dispatch(clearSearchTable());
    },
    applySearchPending: (pending: boolean): void => {
        dispatch(applySearchPending(pending));
    },
    setGlobalSearchTableColumns: (columns: IHeader[]): void => {
        dispatch(setGlobalSearchTableColumns(columns));
    },
    modifyGlobalSearchColumnHeader: (columns: IHeader[], userId: string): void => {
        dispatch(modifyGlobalSearchColumnHeader(columns, userId));
    },
    getResetedGlobalSearchColumnHeader: (userId: string): void => {
        dispatch(getResetedGlobalSearchColumnHeader(userId));
    },
    setLinkedArticleFlag: (isLinkedArticleFlag: boolean): void => {
        dispatch(setLinkedArticlesFlag(isLinkedArticleFlag));
    },
    fetchPageNumberByPageId: (
        redactionDocumentId: number, documentId: number, selectedPageId: number,
        getAnnotationStamps: boolean = false): void => {
        dispatch(getPageNumberByPageId({ redactionDocumentId, documentId, selectedPageId, getAnnotationStamps }));
    },
    fetchSearchDataFromNextDoc: (redactionDocumentId: number, selectedDoc: number, actualPageNumber: number): void => {
        dispatch(getSearchDataFromNextDoc(redactionDocumentId, selectedDoc, actualPageNumber));
    },
    handleOpenModalWindow: (modalType: string, modalProps: IModalProps): void => {
        dispatch(openModalWindow(modalType, modalProps));
    },
    setStackId: (id: number): void => {
        dispatch(saveStackId(id));
    },
    fetchDocumentsList: (redactionDocumentId: number): void => {
        dispatch(fetchDocumentList(redactionDocumentId));
    },
    restrictOnFetchDataForPageList: (restricFlag: boolean): void => {
        dispatch(restrictOnFetchDataPageList(restricFlag));
    },
    setCurrentSearchElementDirection: (direction: string): void => {
        dispatch(setElementSearchDirection(direction));
    },
    setPrevsSearchElement: (element: ICurrentSearchElement): void => {
        dispatch(setPreviousSearchElement(element));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);

import * as React from 'react';
import { IPageInputProps } from './pageInput.model';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { PAGINATION_BIGGER, PAGINATION_LESS, PAGINATION_INTEGER } from './constants';
import './../modalWindowImport.styles.scss';
import { useSelector } from 'react-redux';
import { getModifiedLabels } from '../../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../../redux/selectors/localStorage';
import { changeLang } from '../../../../redux/actions/localization';
import { initialLabel } from '../../../../constants/localization.constants';
import resourceBundle from '../../../localization/localizationData';
import { getImportLabelsByKey } from '../ModalWindowImport';

const PageInput = (
    {
        initialValue,
        max,
        id,
        param,
        type,
        setPagination,
        isDisabled = false,
        pageCountError = null,
    }: IPageInputProps,
    ): JSX.Element => {
        const [value, setValue] = useState(initialValue);
        const isError = value < 1 || value > max || !Number.isInteger(value) || !!pageCountError;
        const modifiedLabels = useSelector(getModifiedLabels);
        const redactionLanguage = useSelector(getRedactionLanguage);
        const langRule = changeLang(redactionLanguage);
        const labels = {
            totalPagesLabel: initialLabel,
            equalOrLargerLabel: initialLabel,
            equalOrLessLabel: initialLabel,
            mustIntegerLabel: initialLabel,
            startPageHeaderLabel: initialLabel,
            endPageHeaderLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);

        const helperText = (): string | null => {
            if(!!pageCountError) {
                return `${pageCountError}`;
            }

            if (value < 1) {
                return `${labels.equalOrLargerLabel[langRule]} ${param === 'to' ? labels.startPageHeaderLabel[langRule]
                    : '1'}`;
            } else if (value > max) {
                return `${labels.equalOrLessLabel[langRule]} ${param === 'from' ? labels.endPageHeaderLabel[langRule]
                    : labels.totalPagesLabel[langRule]}`;
            }

            if (!Number.isInteger(value)) {
                return `${labels.mustIntegerLabel[langRule]}`;
            }

            return null;
        };
        const onBlur = (): void => {
            setPagination(value, param, id, type);
        };

        return (
            <React.Fragment>
                {
                    max === 1 || max === null ? max :
                    <div className='error-msg' >
                        <TextField
                            className='form-body-field'
                            onChange={(e: React.ChangeEvent<Element>): void =>
                                setValue(Number((e.target as HTMLInputElement).value))}
                            onBlur={(): void => onBlur()}
                            value={value}
                            fullWidth={true}
                            type='number'
                            variant='outlined'
                            error={isError}
                            helperText={helperText()}
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    max,
                                },
                            }}
                            disabled={isDisabled}
                        />
                    </div>
                }
            </React.Fragment>
        );
    }
;

export default PageInput;

import resourceBundle from "../containers/localization/localizationData";
import { changeLang } from "../redux/actions/localization";
import { MODIFIED_LABELS, REDACTION_LANG } from "../redux/reducers/localStorage/constant";
import { getItem, getSessionItem } from "../utils/localStorage.util";
import { initialLabel } from "./localization.constants";

export const  REPORT_LANG_SELECTION_MODAL = 'reportLangSelection';
export const REPORT_LANG_SELECTION_TITLE = 'Report Language Selection';
export const SELECT_LANG_TITLE = 'Select language for report';
export const ENGLISH = 'English';
export const BILINGUAL = 'Bilingual';

export const ENGLISH_PARAM = 'en';
export const BILINGUAL_PARAM = 'fr';
export const REPORT_LANG_PARAM = 'reportValue';

export const reportLangSelection = [
    { value: ENGLISH_PARAM, label: ENGLISH },
    { value: BILINGUAL_PARAM, label: BILINGUAL },
];

const getReportLanguageKey = (key: string): string => {
    switch (key) {
        case 'REPORT_LANGUAGE_SELECTION_TITLE':
            return 'reportLangTitle';
        case 'REPORT_LANGUAGE_SELECTION_SELECT_LABEL':
            return 'selectLang';
        case 'REPORT_LANGUAGE_SELECTION_CANCEL':
            return 'cancelLabel';
        case 'REPORT_LANGUAGE_SELECTION_OK':
            return 'okLabel';
        default : return '';
    }
}

export const getReportLanguageModalLabels = (key: string): string => {
    const redactionLang = getSessionItem(REDACTION_LANG);
    const modifiedLabels = getItem(MODIFIED_LABELS);
    const label = {
        reportLangTitle: initialLabel,
        selectLang: initialLabel,
        cancelLabel: initialLabel,
        okLabel: initialLabel,
    };
    const langRule = changeLang(redactionLang);

    resourceBundle.map((resource: any) => {
        if (getReportLanguageKey(resource.resourceKey)) {
            label[getReportLanguageKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    (modifiedLabels && modifiedLabels.length) ? modifiedLabels.map((resource: any) => {
        if (getReportLanguageKey(resource.resourceKey)) {
            label[getReportLanguageKey(resource.resourceKey)] = resource;
        }

        return resource;
    }) : '';

    switch (key) {
        case REPORT_LANG_SELECTION_TITLE:
            return label.reportLangTitle[langRule];
        case SELECT_LANG_TITLE:
            return label.selectLang[langRule];
        case 'Cancel':
            return label.cancelLabel[langRule];
        case 'Ok':
            return label.okLabel[langRule];
        default : return '';
    }
}
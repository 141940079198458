import { createSelector } from 'reselect';
import { IState } from '../store';
import { IError, ISuccessNotification } from '../common.model';
import { INotificationState } from '../reducers/notificationHandling/reducer';

export const getNotification = (state: IState): INotificationState => state.notifications;

export const getAnyError = createSelector(
    [getNotification],
    (notifications: INotificationState): IError[] => notifications.error,
);

export const getAnyNotification = createSelector(
    [getNotification],
    (notifications: INotificationState): ISuccessNotification[] => notifications.successNotifications,
);

import { IHeader } from './leftSidebar.config';

export const DISPLAY_FINISHED = 'Display finished tasks';
export const METADATA_ERROR = 'The number of symbols exceeds 1000. The text will be truncated upon export.';

export const QueueListHeaders = (
    handelLanguageChange: (param: string) => string,
    ): IHeader[] => [
    {
        accessorKey: 'name',
        id: 'name',
        header: handelLanguageChange('Filename'),
        minResizeWidth: 1,
        show: true,
    }, {
        accessorKey: 'status',
        id: 'status',
        header: handelLanguageChange('Status'),
        minResizeWidth: 1,
        show: true,
    }, {
        accessorKey: 'details',
        id: 'details',
        header: handelLanguageChange('Details'),
        minResizeWidth: 1,
        show: true,
    }, {
        accessorKey: 'action',
        id: 'action',
        header: '',
        minResizeWidth: 1,
        width: 10,
        size:10,
        show: true,
    },
];

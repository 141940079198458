if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        // Used any. Because element of Array can be any value. And return any element or undefined
        value(predicate: any): any {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            const o = Object(this);
            const len = o.length;

            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            const thisArg = arguments[1];
            let k = 0;

            while (k < len) {
                const kValue = o[k];

                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }

                k++;
            }

            return undefined;
        },
    });
}

import * as React from 'react';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import './modalWindow.styles.scss';
import { IBaseModalWindow } from './modalWindow.model';
import { AUDIT_LOG_ERASE_CONFIRM, CANCEL_BUTTON, EXPORT_ABORT } from '../../constants/messages.constants';
import { getItem, getSessionItem } from '../../utils/localStorage.util';
import { MODIFIED_LABELS, REDACTION_LANG } from '../../redux/reducers/localStorage/constant';
import { changeLang, commonLabels, getCommonLabelsByKey } from '../../redux/actions/localization';
import resourceBundle from '../../containers/localization/localizationData';
import { getAuditLogLabelsByKey } from '../../constants/auditLog.contstants';

const BaseModalWindow = ({
    SpecificModal,
    id,
    modalTitle,
    okButton,
    cancelButton,
    onAccept,
    onCancel,
    childModalProps,
    isDisabledDrag,
    noControls,
 }: IBaseModalWindow): JSX.Element => {
    let modalClassName = classnames('modal-window', {
        'is-auto-height': isDisabledDrag,
    }, modalTitle === EXPORT_ABORT ? 'exportabort' : '');
    const redactionLang = getSessionItem(REDACTION_LANG);
    const modifiedLabels = getItem(MODIFIED_LABELS);
    const labels = commonLabels;
    const langRule = changeLang(redactionLang);
    const presetTitles = new Set(['Package Presets', 'Global Settings', 'Request Security', 'Release Management',
        'Préréglages de package', 'Paramètres globaux', 'Demander la sécurité', 'Gestion des versions']);
    const userTitles = new Set(['Manage User Defined Stamps', 'References', 'Gérer les tampons définis par l\'utilisateur',
        'Les références']);
    if (presetTitles.has(modalTitle)) {
        modalClassName += ' preset';
    } else if (userTitles.has(modalTitle)) {
        modalClassName += ' user';
    }
    resourceBundle.map((resource: any) => {
        if (getCommonLabelsByKey(resource.resourceKey)) {
            labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }
        return resource;
    });
    (modifiedLabels && modifiedLabels.length) ? modifiedLabels.map((resource: any) => {
        if (getCommonLabelsByKey(resource.resourceKey)) {
            labels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }
        return resource;
    }) : '';

    return (
        <div
            className={modalClassName}
        >
            <div
                className={classnames('modal-window-header', {
                    'cursor-move': !isDisabledDrag,
                })}
            >
                <p className='modal-window-header-p'>{modalTitle}</p>
                {!noControls &&
                    <Button
                        variant='contained'
                        onClick={(): void => onCancel(id)}
                        className='button-close-icon'
                    >
                        <CloseIcon className='close-icon' />
                    </Button>
                }
            </div>
            <div className='modal-window-children'>
                <SpecificModal {...childModalProps} />
            </div>
            {(okButton || cancelButton) && !noControls && (
                <div className='modal-window-buttons-ok-cancel'>
                    { modalTitle === getAuditLogLabelsByKey(AUDIT_LOG_ERASE_CONFIRM) &&
                        <div className='button-cancel'>
                            <Button
                                variant='outlined'
                                onClick={(): void => onCancel(id, false)}
                                className='modal-window__buttons outlined'
                                size='small'
                            >
                              {labels.cancelLabel[langRule]}
                            </Button>
                        </div>
                    }
                    <div className='button-cancel'>
                        {cancelButton && (
                            <Button
                                variant='outlined'
                                onClick={(): void => onCancel(id, true)}
                                className='modal-window__buttons outlined'
                                size='small'
                            >
                                {labels.cancelLabel[langRule]}
                            </Button>
                        )}
                    </div>
                    <div className='button-ok'>
                        {okButton && (
                            <Button
                                variant='contained'
                                onClick={(): void => onAccept()}
                                className='modal-window__buttons primary'
                                size='small'
                                color='primary'
                            >
                                {labels.okLabel[langRule]}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BaseModalWindow;

if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        enumerable: false,
        value(obj: any): boolean {
            const newArr = this.filter((el: any): boolean => el === obj);

            return newArr.length > 0;
        },
    });
}

if (!String.prototype.includes) {
    String.prototype.includes = function(search: string, start: any): boolean {
        let startCopy = start;

        if (typeof startCopy !== 'number') {
            startCopy = 0;
        }

        if (startCopy + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, startCopy) !== -1;
        }
    };
}

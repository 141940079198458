import { SizeSelectOptions } from './components/controls/control.model';
import { IDegrees, IScaleDirection } from './containers/redactor/redactor.model';
import { ISelectOptions } from './components/materialUiForms/marerialUiForms.model';
import * as constants from './constants/duplicates.constants';

export const EXPORT_PDF_TYPE = 'application/pdf;charset=utf-8';
export const EXPORT_PDF_NAME = 'exportDocumentPages.pdf';
export const EXPORT_PDF_KEYWORD_OCCURENCY = 'exportKeywordOccurency.pdf';
export const EXPORT_ZIP_TYPE = 'application/zip';
export const EXPORT_ZIP_NAME = 'exportDocumentPages.zip';
export const EXPORT_CSV_TYPE = 'text/csv;encoding:utf-8';
export const EXPORT_CSV_NAME = 'exportLog.csv';

export const SCALE_STEP: number = 0.05;
export const MIN_SCALE: number = 0.1;

export const FULL_WIDTH_NAME: string = 'Actual Page Size';
export const PAGE_WIDTH_NAME: string = 'Page Width';
export const FULL_PAGE_NAME: string = 'Full Page';

export const FULL_WIDTH_VAL: number = 1;
export const PAGE_WIDTH_VAL: number = 2;
export const FULL_PAGE_VAL: number = 3;

export const SIDE_SPACE: number = 15;
export const HALF_DIVIDER: number = 2;

export const MARGIN_HIDE_SCROLL: number = 17;
export const MARGIN_PDF: number = 52;

export const degrees: IDegrees = {
    ZERO: 0,
    QUARTER: 270,
    HALF: 180,
    THREE_QUARTERS: 270,
    FULL: 360,
    MINUS_QUARTER: 90,
    MINUS_HALF: 180,
};

export const scaleDirection: IScaleDirection = {
    UP: 'Up',
    DOWN: 'Down',
};

export const LOCAL_FILE = 'Local file';
export const REDACTION_DOCUMENT = 'Redaction Document(s)';
export const SCANNER = 'Import From Scanner';
export const AMMANDA_ATTACHMENT = 'AMANDA Attachment(s)';
export const EDMS_FILES = 'Add Attachments from Edms';

export const sizeSelectOptions: SizeSelectOptions = [
    {
        val: FULL_WIDTH_VAL,
        name: FULL_WIDTH_NAME,
        label: constants.getManageDuplicatesLabelsByKey(FULL_WIDTH_NAME),
    },
    {
        val: PAGE_WIDTH_VAL,
        name: PAGE_WIDTH_NAME,
        label: constants.getManageDuplicatesLabelsByKey(PAGE_WIDTH_NAME),
    },
    {
        val: FULL_PAGE_VAL,
        name: FULL_PAGE_NAME,
        label: constants.getManageDuplicatesLabelsByKey(FULL_PAGE_NAME),
    },
    {
        val: 25,
        name: '25%',
    },
    {
        val: 50,
        name: '50%',
    },
    {
        val: 100,
        name: '100%',
    },
    {
        val: 200,
        name: '200%',
    },
    {
        val: 400,
        name: '400%',
    },
    {
        val: 800,
        name: '800%',
    },
];

export const MOUSE_DOWN_EVENT = 'mousedown';
export const MOUSE_UP_EVENT = 'mouseup';
export const MOUSE_MOVE_EVENT = 'mousemove';

export const STAMP_ELEMENT = 'stamp_element';
export const AUTO_STAMP_ELEMENT = 'auto_stamp_element';
export const CUSTOM_STAMP_ELEMENT = 'custom_stamp_element';
export const ITEM_CLASS = 'controls_item';
export const ACTIVE_ITEM_CLASS = 'controls_item-active';
export const ARTICLE_STAMPS = 'Article';
export const AUTO_STAMP = 'Auto';
export const AUTO_STAMP_PARAM = 'autoStamp';
export const RECENTLY_USED = 'Recently Used';
export const CUSTOM_STAMPS = 'User';
export const LEFT = 'Left';
export const RIGHT = 'Right';
export const AUTO_CORRECT_SEVER = 'Snap';
export const AUTO_CORRECT_SEVER_PARAM = 'autoCorrectSever';
export const AUTO_CORRECT_SEVER_ELEMENT = 'auto_correct_sever_element';
export const AUTO_CORRECT_SEVER_TOOL = 'Auto Correct Sever tool';

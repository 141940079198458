import * as React from 'react';
import Controls from '../../components/controls/Controls';
import Redactor from '../redactor/Redactor';
import { useSetOpenSelectComponent } from '../../hooks/useSetOpenSelectComponent';
import './main.styles.scss';
import { setFocusOnRedactor } from '../../redux/actions/redactor';

const Main = (): JSX.Element => {
    const {
        handleCloseSelects,
        openHighlight,
        openSever,
        handleChangeSever,
        handleChangeHighLight,
    } = useSetOpenSelectComponent();

    setFocusOnRedactor(true);

    return (
        <div tabIndex={-1} className='main'>
            <Controls
                handleCloseSelects={handleCloseSelects}
                openSever={openSever}
                handleChangeHighLight={handleChangeHighLight}
                handleChangeSever={handleChangeSever}
                openHighlight={openHighlight}
            />
            <Redactor handleCloseSelects={handleCloseSelects} />
        </div>
    );
};

export default Main;

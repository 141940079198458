import {
    RESET_ERROR_MESSAGE,
    ADD_ERROR,
    RESET_SUCCESS_MESSAGE,
    ADD_SUCCESS_MESSAGE,
} from '../reducers/notificationHandling/constants';
import { action } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { IError, ISuccessNotification } from '../common.model';

export const addError = (error: IError): AnyAction => action(ADD_ERROR, error);
export const removeError = (id: string): AnyAction => action(RESET_ERROR_MESSAGE, id);

export const addSuccessNotification = (message: ISuccessNotification): AnyAction =>
    action(ADD_SUCCESS_MESSAGE, message);
export const removeSuccessNotification = (id: string): AnyAction => action(RESET_SUCCESS_MESSAGE, id);

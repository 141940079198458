import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import MaterialCheckboxTableRow from './materialCheckboxTableRow';
import { IMaterialCheckboxTableBodyProps } from '../materialCheckboxTable.model';

const MaterialCheckboxTableBody = (props: IMaterialCheckboxTableBodyProps): JSX.Element => {
    const { rows, handleRowClick, handleSelected, model } = props;

    return (
        <React.Fragment>
            {
                rows.map((row: any): JSX.Element => {
                    const isSelected = handleSelected(row[props.id]);

                    return (
                        <TableRow
                            hover={true}
                            onClick={(): void => handleRowClick(row[props.id])}
                            role='checkbox'
                            aria-checked={isSelected}
                            key={row[props.id]}
                            selected={isSelected}
                        >
                            <TableCell padding='checkbox'>
                                <Checkbox color='primary' checked={isSelected} />
                            </TableCell>
                            <MaterialCheckboxTableRow row={row} rowKeysList={model} />
                        </TableRow>
                    );
                })
            }
        </React.Fragment>
    );
};

export default MaterialCheckboxTableBody;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import classnames from 'classnames';
import * as constants from '../../../../constants/export.contstants';
import { RadioMaterialBuilder } from '../../../../components/materialUiForms/materialUiFormBuilder';
import { ISelectOptions } from '../../../../components/materialUiForms/marerialUiForms.model';
import { IAnnotationType } from '../../../../redux/reducers/annotationTypes/annotationTypes.model';
import './annotationControl.scss';
import { useSelector } from 'react-redux';
import { getModifiedLabels } from '../../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../../redux/selectors/localStorage';
import { initialLabel } from '../../../../constants/localization.constants';
import resourceBundle from '../../../localization/localizationData';
import { changeLang } from '../../../../redux/actions/localization';

const getAnnotationsLabelsByKey = (key: string): string => {
    switch(key) {
        case 'MODAL_EXPORT_ANNOTATION_TAB_SEVER_TRANSPARENCY':
            return 'severTransparencyLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_HIGHLIGHT_DISPLAY':
            return 'highLightDisplayLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_SEVER_NAME':
            return 'severNameLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_HIGHLIGHT_NAME':
            return 'highlightNameLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_HIDE_OR_SHOW_TEXT':
            return 'showOrHideLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_HIDE_TEXT':
            return 'hideTextLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_SHOW_TEXT':
            return 'showTextLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_OFF_OR_SHOW':
            return 'showOrOffLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_OFF':
            return 'offLabel';
        case 'MODAL_EXPORT_ANNOTATION_TAB_SHOW':
            return 'showLabel';
        default: return '';
    }
};

const AnnotationBlock = ({
     list,
     title,
     labelText,
     header,
     handleChangeAnnotationControl,
     radioList,
     annotationControlsIdList,
     hasEditPermission,
     language,
}: {
    list: IAnnotationType[];
    title: string;
    labelText: string;
    header: string[];
    annotationControlsIdList: number[];
    radioList: ISelectOptions[];
    hasEditPermission: boolean;
    handleChangeAnnotationControl: (id: number, isChecked: boolean) => void;
    language: string;
}): JSX.Element => {
    const listClass = (idx: number): string => classnames('annotation-control-tab__wrapper-listitem', { odd: idx % 2 });
    const isShownRadio = (isShown: boolean): string => isShown ? constants.SHOW_PARAM : constants.HIDE_PARAM;

    return (
        <div className={'annotation-control-tab__wrapper'}>
            <div className={'annotation-control-tab__wrapper-header'}>
                <Typography variant='h6'>{title}</Typography>
            </div>
            <div className={'annotation-control-tab__wrapper-content'}>
                <List dense={true}>
                    <ListItem className='annotation-control-tab__wrapper-listitem header-listitem'>
                        {
                            header.map((headerItem: string): JSX.Element => (
                                <ListItemText primary={headerItem} key={headerItem} />
                            ))
                        }
                    </ListItem>
                    <div className='annotation-control-tab__container'>
                        {
                            list.map((listItem: IAnnotationType, idx: number): JSX.Element => (
                                <ListItem key={listItem.name} className={listClass(idx)}>
                                    <ListItemText
                                        primary={ language ? language === 'fr' ? listItem.name2
                                            ? listItem.name2 : listItem.name : listItem.name : listItem.name}
                                    />
                                    <RadioMaterialBuilder
                                        value={isShownRadio(annotationControlsIdList.includes(listItem.id))}
                                        handleRadioChange={(param: string, value: string): void =>
                                            handleChangeAnnotationControl(
                                                listItem.id,
                                                value === constants.SHOW_PARAM,
                                            )}
                                        paramName={title}
                                        ariaLabel={title}
                                        radioList={radioList}
                                        disabled={!hasEditPermission}
                                    />
                                </ListItem>
                            ))
                        }
                    </div>
                </List>
            </div>
        </div>
    );
};

const AnnotationControlTab = ({
    highLightList,
    severList,
    handleChangeAnnotationControl,
    annotationControlsIdList,
    hasEditPermission = true,
}: {
    highLightList: IAnnotationType[];
    severList: IAnnotationType[];
    annotationControlsIdList: number[];
    handleChangeAnnotationControl: (id: number, isChecked: boolean) => void;
    title?: string;
    isValid?: boolean;
    disabled?: boolean;
    hasEditPermission?: boolean;
}): JSX.Element => {
    const modifiedLabels = useSelector(getModifiedLabels);
    const redactionLang = useSelector(getRedactionLanguage);
    const langRule = changeLang(redactionLang);
    const labels = {
        severTransparencyLabel: initialLabel,
        severNameLabel: initialLabel,
        highLightDisplayLabel: initialLabel,
        highlightNameLabel: initialLabel,
        showLabel: initialLabel,
        offLabel: initialLabel,
        hideTextLabel: initialLabel,
        showTextLabel: initialLabel,
        showOrHideLabel: initialLabel,
        showOrOffLabel: initialLabel,
    };

    resourceBundle.map((resource: any) => {
        if (getAnnotationsLabelsByKey(resource.resourceKey)) {
            labels[getAnnotationsLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getAnnotationsLabelsByKey(resource.resourceKey)) {
            labels[getAnnotationsLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    return (
    <div className='annotation-control-tab tab'>
        <AnnotationBlock
            list={severList}
            header={[labels.severNameLabel[langRule], labels.showOrHideLabel[langRule]]}
            title={labels.severTransparencyLabel[langRule]}
            labelText={labels.showTextLabel[langRule]}
            annotationControlsIdList={annotationControlsIdList}
            radioList={constants.severShowHideOptions(labels, langRule)}
            hasEditPermission={hasEditPermission}
            handleChangeAnnotationControl={
                (id: number, isChecked: boolean): void => handleChangeAnnotationControl(id, isChecked)
            }
            language={redactionLang}
        />
        <AnnotationBlock
            list={highLightList}
            header={[labels.highlightNameLabel[langRule], labels.showOrOffLabel[langRule]]}
            title={labels.highLightDisplayLabel[langRule]}
            labelText={labels.showLabel[langRule]}
            annotationControlsIdList={annotationControlsIdList}
            radioList={constants.hightLightShowHideOptions(labels, langRule)}
            hasEditPermission={hasEditPermission}
            handleChangeAnnotationControl={
                (id: number, isChecked: boolean): void => handleChangeAnnotationControl(id, isChecked)
            }
            language={redactionLang}
        />
    </div>
    );
};

export default AnnotationControlTab;

import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    IRequestType,
    IContactType,
    IAmandaContentState,
    IRequestFolder,
    IRequestSecurities,
    IAvailableSecurity,
    ISecurityGroupByFolderRsn,
    IFolderProcesses,
    IUser,
    IAttachmentTypes,
} from '../reducers/amandaContent/amandaContent.model';
import { ISelectOptions } from '../../components/materialUiForms/marerialUiForms.model';
import { uniqBy } from 'lodash';

export const getAmandaContent = (state: IState): IAmandaContentState => state.amandaContent;

export const getRequestTypeLoading = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): boolean => amandaContent.requestTypePending,
);

export const getRequestTypesList = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IRequestType[] => amandaContent.requestType,
);

export const getRequestTypesListValues = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): string[] => {
        return amandaContent.requestType.map((i: IRequestType) => i.folderType);
    },
);

export const getContactTypeLoading = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): boolean => amandaContent.contactsTypesPending,
);

export const getContactTypesList = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IContactType[] => uniqBy(amandaContent.contactsTypes, 'peopleDesc'),
);

export const getRequestFolders = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IRequestFolder[] => amandaContent.requestFolders,
);

export const  getRequestSecurities = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IRequestSecurities => amandaContent.requestSecurities,
);

export const getSelectedRequestType = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): string => amandaContent.selectedRequestType,
);

export const getAvailableSecurities = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IAvailableSecurity => amandaContent.availableSecurity,
);

export const getRequestSecurityGroupsByFolder = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): ISecurityGroupByFolderRsn =>
        amandaContent.requestSecurityGroupsByFolder,
);

export const  getFolderProcesses = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IFolderProcesses[] => amandaContent.folderProcesses,
);

export const getFolderProcessesLoading = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): boolean => amandaContent.folderProcessesLoading,
);

export const  getAmandaUsers = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IUser[] => amandaContent.amandaUsers,
);

export const getAmandaUsersPending = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): boolean => amandaContent.amandaUsersPending,
);

export const getFolderAttachmentTypes = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IAttachmentTypes[] => amandaContent.folderAttachmentTypes,
);

export const getFolderAttachmentTypesLoading = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): boolean => amandaContent.folderAttachmentTypesLoading,
);

export const getProcessAttachmentTypes = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): IAttachmentTypes[] => amandaContent.processAttachmentTypes,
);

export const getProcessAttachmentTypesLoading = createSelector(
    [getAmandaContent],
    (amandaContent: IAmandaContentState): boolean => amandaContent.processAttachmentTypesLoading,
);

import { IRequestType } from '../redux/reducers/amandaContent/amandaContent.model';
import { ISelectOptions } from '../components/materialUiForms/marerialUiForms.model';
import {
    ROW_LEVEL_SECURITY_CODE,
    USER_ID,
} from '../containers/modalWindowContents/modalRequestSecurity/ModalRequestSecurityConstants';

export const getModifiedRequestTypes = (folders: IRequestType[], redactionLang: string): ISelectOptions[] => {
    const all = { folderType: 'All', folderDesc: 'All' };
    const allFolders = [all, ...folders];

    return allFolders.map((folder: IRequestType): ISelectOptions => ({
        value: folder.folderType,

        label: (redactionLang ==='fr' && folder.folderDesc2) ?folder.folderDesc2:folder.folderDesc,
    }));
};

export const isExistValidUser = <G extends object>(users: G[], wanted: G): G =>
    users.find((user: G): boolean => user[USER_ID] === wanted[USER_ID]);

export const isExistRowLevelSecurity = <G extends object>(securities: G[], wanted: G): G =>
    securities.find((security: G): boolean =>
        security[ROW_LEVEL_SECURITY_CODE] === wanted[ROW_LEVEL_SECURITY_CODE]);

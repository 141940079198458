import { IState } from '../store';
import { createSelector } from 'reselect';
import { IModalWindowImportState } from '../reducers/modalWindowImport/modalWindowImport.model';

export const getImportForm = (state: IState): IModalWindowImportState => state.modalWindowImport;

export const getImportFilesLoading = createSelector(
    [getImportForm],
    (modalWindowImport: IModalWindowImportState): boolean => modalWindowImport.importFilesPending,
);

export const getImportDocumentsLoading = createSelector(
    [getImportForm],
    (modalWindowImport: IModalWindowImportState): boolean => modalWindowImport.importDocumentPending,
);

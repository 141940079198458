import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import {
    LOAD_GLOBAL_SETTINGS_PENDING,
    LOAD_GLOBAL_SETTINGS_SUCCESS,
    LOAD_GLOBAL_SETTINGS_FAILURE,
    UPDATE_GLOBAL_SETTINGS_PENDING,
    UPDATE_GLOBAL_SETTINGS_SUCCESS,
    UPDATE_GLOBAL_SETTINGS_FAILURE,
} from '../reducers/modalGlobalSettings/constants';
import {
    IGlobalSettings,
    IDisclosureSettingsUpdate,
    IRequestTypeDefaultDisclosureList,
} from '../reducers/modalGlobalSettings/globalSettings.model';
import { addError } from './errorHandling';
import { IError } from '../common.model';

export const fetchGlobalSettingsPending = (): AnyAction => action(LOAD_GLOBAL_SETTINGS_PENDING);
export const fetchGlobalSettingsSuccess = (globalSettings: IGlobalSettings): AnyAction =>
    action(LOAD_GLOBAL_SETTINGS_SUCCESS, globalSettings);
export const fetchGlobalSettingsFailure = (error: IError): AnyAction => action(LOAD_GLOBAL_SETTINGS_FAILURE, error);

export const updateGlobalSettingsPending = (): AnyAction => action(UPDATE_GLOBAL_SETTINGS_PENDING);
export const updateGlobalSettingsSuccess = (globalSettings: IDisclosureSettingsUpdate): AnyAction =>
    action(UPDATE_GLOBAL_SETTINGS_SUCCESS, globalSettings);
export const updateGlobalSettingsFailure = (error: IError): AnyAction => action(UPDATE_GLOBAL_SETTINGS_FAILURE, error);

export const fetchGlobalSettings = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchGlobalSettingsPending());
        try {
            const response = await api.globalSettings.getGlobalSettings();

            dispatch(fetchGlobalSettingsSuccess(response));
        } catch (error) {
            dispatch(fetchGlobalSettingsFailure(error));
        }
    };

export const updateGlobalSettings = (data: IDisclosureSettingsUpdate): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(updateGlobalSettingsPending());

        try {
            const response = await api.globalSettings.updateGlobalSettings(data);

            dispatch(updateGlobalSettingsSuccess(response));
        } catch (error) {
            dispatch(updateGlobalSettingsFailure(error));
            dispatch(addError(error));
        }
    };

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import { Scrollbars } from 'rc-scrollbars';
import { IEncryptionType } from '../../../redux/reducers/modalEncryption/modalEncryptionKeys.model';
import { DELETE } from '../../../constants/common.constants';

interface IEncryptionKeysList {
    list: IEncryptionType[];
    activeId: number;
    handleClickEncryption: (item: IEncryptionType) => void;
    handleDeleteEncryption: (item: IEncryptionType) => void;
}

const EncryptionKeysList =
    ({
         list,
         activeId,
         handleClickEncryption,
         handleDeleteEncryption,
     }: IEncryptionKeysList): JSX.Element => {
    return (
        <Scrollbars>
            <List
                component='nav'
            >
                {
                    list
                        .map((item: IEncryptionType) =>
                            (<ListItem
                                key={item.id}
                                divider={true}
                                button={true}
                                selected={activeId === item.id}
                                onClick={(): void => handleClickEncryption(item)}
                            >
                                <ListItemIcon className='list-icon'>
                                <CheckCircle color={'primary'} fontSize='small'/>
                                </ListItemIcon>
                                <ListItemText
                                    title={item.name}
                                    className='text-disclosure'
                                    primary={item.name}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label={DELETE}
                                        disabled={item.isActive}
                                        onClick={(): void => handleDeleteEncryption(item)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>))
                }
            </List>
        </Scrollbars>
    );
};

export default EncryptionKeysList;

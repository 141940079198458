import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import {
    GET_WATERMARKS_SUCCESS,
    GET_WATERMARKS_PENDING,
    GET_WATERMARKS_FAILURE,
    POST_WATERMARK_SUCCESS,
    POST_WATERMARK_FAILURE,
    POST_WATERMARK_PENDING,
    PUT_WATERMARK_SUCCESS,
    PUT_WATERMARK_PENDING,
    PUT_WATERMARK_FAILURE,
    DELETE_WATERMARK_PENDING,
    DELETE_WATERMARK_FAILURE,
    DELETE_WATERMARK_SUCCESS,
    SET_OPTIONS_WATERMARK,
    CHANGE_OPTIONS_WATERMARK,
    CLEAR_OPTIONS_WATERMARK,
    GET_WATERMARK_IMAGE_FAILURE,
    GET_WATERMARK_IMAGE_PENDING,
} from '../reducers/modalWatermarks/constants';
import {
    IWatermark,
} from '../reducers/modalWatermarks/watermarksManagement.model';
import { IError } from '../common.model';
import { handleCloseModalWindow, openModalWindow } from './modal';
import { ALERT_DIALOG_MODAL, WARNING } from '../../constants/messages.constants';
import { BASE64 } from '../../constants/watermark.contstants';
import { addError } from './errorHandling';
import { getImagePath } from '../../utils/watermarks.util';

export const fetchWatermarksPending = (): AnyAction => action(GET_WATERMARKS_PENDING);
export const fetchWatermarksSuccess = (watermarks: IWatermark[]): AnyAction =>
    action(GET_WATERMARKS_SUCCESS, watermarks);
export const fetchWatermarksFailure = (error: IError): AnyAction => action(GET_WATERMARKS_FAILURE, error);

export const postWatermarkPending = (): AnyAction => action(POST_WATERMARK_PENDING);
export const postWatermarkSuccess = (watermark: IWatermark): AnyAction => action(POST_WATERMARK_SUCCESS, watermark);
export const postWatermarkFailure = (error: IError): AnyAction => action(POST_WATERMARK_FAILURE, error);

export const updateWatermarkPending = (): AnyAction => action(PUT_WATERMARK_PENDING);
export const updateWatermarkSuccess = (watermark: IWatermark): AnyAction => action(PUT_WATERMARK_SUCCESS, watermark);
export const updateWatermarkFailure = (error: IError): AnyAction => action(PUT_WATERMARK_FAILURE, error);

export const removeWatermarkPending = (): AnyAction => action(DELETE_WATERMARK_PENDING);
export const removeWatermarkSuccess = (watermark: IWatermark): AnyAction => action(DELETE_WATERMARK_SUCCESS, watermark);
export const removeWatermarkFailure = (error: IError): AnyAction => action(DELETE_WATERMARK_FAILURE, error);

export const setWatermarkOptions = (data: IWatermark): AnyAction => action(SET_OPTIONS_WATERMARK, data);
export const changeWatermarkOptions = (data: IWatermark): AnyAction => action(CHANGE_OPTIONS_WATERMARK, data);
export const clearWatermarkOptions = (): AnyAction => action(CLEAR_OPTIONS_WATERMARK);

export const getWatermarkImagePending = (): AnyAction => action(GET_WATERMARK_IMAGE_PENDING);
export const getWatermarkImageFailure = (error: IError): AnyAction => action(GET_WATERMARK_IMAGE_FAILURE, error);

export const fetchWatermarks = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchWatermarksPending());

        try {
            const response = await api.watermarksController.getAllWatermarks();
            const watermarks = response.map((watermark: IWatermark): IWatermark => ({
                ...watermark,
                imagePath: null,
            }));

            dispatch(fetchWatermarksSuccess(watermarks));
        } catch (error) {
            dispatch(fetchWatermarksFailure(error));
            dispatch(addError(error));
        }
    };

export const fetchWaterMarkOptions = (data: IWatermark): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(getWatermarkImagePending());

        try {
            const image = await api.watermarksController.getWaterMarkImage(data.id);

            dispatch(setWatermarkOptions({ ...data, imagePath: getImagePath(image) }));
        } catch  (error) {
            dispatch(getWatermarkImageFailure(error));
            dispatch(addError(error));
        }
    };

export const postWatermark = (watermark: IWatermark, file: File): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(postWatermarkPending());

        try {
            const response =  await api.watermarksController.postWatermark(watermark, file);

            delete response.imagePath;

            const image = await api.watermarksController.getWaterMarkImage(response.id);

            dispatch(postWatermarkSuccess(response));
            dispatch(setWatermarkOptions({ ...response, imagePath: getImagePath(image) }));
        } catch (error) {
            dispatch(postWatermarkFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: WARNING,
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

export const updateWatermark = (watermark: IWatermark, file: File): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(updateWatermarkPending());

        try {
            const response =  await api.watermarksController.putWatermark(watermark, file);

            dispatch(updateWatermarkSuccess(response));

            const image = await api.watermarksController.getWaterMarkImage(watermark.id);

            dispatch(setWatermarkOptions({ ...response, imagePath: getImagePath(image) }));
        } catch (error) {
            dispatch(updateWatermarkFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: WARNING,
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

export const removeWatermark = (
    watermark: IWatermark,
    needsClearState: boolean,
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(removeWatermarkPending());

        try {
            await api.watermarksController.deleteWatermark(watermark.id);

            if (needsClearState) {
                dispatch(clearWatermarkOptions());
            }

            dispatch(removeWatermarkSuccess(watermark));
        } catch (error) {
            dispatch(removeWatermarkFailure(error));
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: WARNING,
                message: error.message,
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        }
    };

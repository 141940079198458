import { AnyAction } from 'redux';
import {
    SET_OPTIONS_DISCLOSURE,
    CHANGE_OPTIONS_DISCLOSURE,
    CLEAR_OPTIONS_DISCLOSURE,
} from './constant';
import * as constants from '../../../constants/disclosure.contstants';
import { IModalWindowDisclosureState } from './modalWindowDisclosure.model';

const initialState: IModalWindowDisclosureState = {
    disclosureOptions: {
        [constants.BORDER_COLOR ]: '',
        [constants.DESCRIPTION ]: '',
        [constants.ID ]: null,
        [constants.IS_ACTIVE ]: false,
        [constants.IS_DUPLICATE_REF_REQUIRED ]: false,
        [constants.CONTACTS ]: [],
        [constants.IS_EXEMPTION_REQUIRED ]: false,
        [constants.IS_IN_PAGINATION ]: false,
        [constants.NAME ]: '',
        [constants.PAGE_OUT_NOTICE ]: '',
        [constants.IS_RELEASABLE_STATE ]: true,
        [constants.REQUEST_TYPE ]: [],
        [constants.NAME_1]: '',
        [constants.DESCRIPTION_1]: '',
    },
};

const modalWindowDisclosureReducer = (
    state: IModalWindowDisclosureState = initialState,
    {type, payload}: AnyAction,
): IModalWindowDisclosureState => {
  switch (type) {
      case SET_OPTIONS_DISCLOSURE:
          return {
              ...state,
              disclosureOptions: {...payload},
          };
      case CHANGE_OPTIONS_DISCLOSURE:
          return {
              ...state,
              disclosureOptions: {
                  ...state.disclosureOptions,
                  ...payload,
              },
          };
      case CLEAR_OPTIONS_DISCLOSURE:
          return initialState;
      default:
          return state;
  }
};

export default modalWindowDisclosureReducer;

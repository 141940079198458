import { AnyAction } from 'redux';
import { IStamp, IStampsState } from './stamps.model';
import {
    GET_CURRENT_PAGE_STAMPS_FAILURE,
    GET_CURRENT_PAGE_STAMPS_PENDING,
    GET_CURRENT_PAGE_STAMPS_SUCCESS,
    UPDATE_STAMP_FAILURE,
    UPDATE_STAMP_PENDING,
    UPDATE_STAMP_SUCCESS,
    ADD_STAMPS_FAILURE,
    ADD_STAMPS_PENDING,
    ADD_STAMPS_SUCCESS,
    DELETE_STAMPS_FAILURE,
    DELETE_STAMPS_PENDING,
    DELETE_STAMPS_SUCCESS,
    REMOVE_STAMPS_PER_PAGE,
    GET_STAMP_ALIGN_SUCCESS,
    UPDATE_STAMP_ALIGN_SUCCESS,
    SET_AUTO_STAMP,
    HIGHLIGHT_LINKED_ARTICLES,
} from './constants';
import { USER_PREFERENCE_PARAM } from '../../../constants/modalUserPreference.constants';

export const initialState: IStampsState = {
    stamps: [],
    [USER_PREFERENCE_PARAM]: '',
    autoStamp: false,
    loadingStamps: false,
    error: null,
};

const stampsReducer = (state: IStampsState = initialState, { type, payload }: AnyAction): IStampsState => {
    switch (type) {
        case GET_CURRENT_PAGE_STAMPS_PENDING:
        case UPDATE_STAMP_PENDING:
        case DELETE_STAMPS_PENDING:

            return {
                ...state,
                loadingStamps: true,
                error: null,
            };
        case GET_CURRENT_PAGE_STAMPS_SUCCESS:
            return {
                ...state,
                stamps: payload,
                loadingStamps: false,
                error: null,
            };
        case REMOVE_STAMPS_PER_PAGE:
            const { exemptions, userDefinedStamps } = payload;

            if (exemptions && userDefinedStamps) {
                return {
                    ...state,
                    stamps: [],
                };
            }

            if (exemptions || userDefinedStamps) {
                return {
                    ...state,
                    stamps: state.stamps.filter((stamp: IStamp): boolean => stamp.isExemption !== exemptions),
                };
            }

            return {
                ...state,
            };
        case UPDATE_STAMP_SUCCESS:
            return {
                ...state,
                stamps: state.stamps.map((stateStamp: IStamp) => {
                    const updated = payload.find((stamp: IStamp): boolean => stamp.id === stateStamp.id);

                    return updated || stateStamp;
                }),
                loadingStamps: false,
            };
        case ADD_STAMPS_PENDING:
            return {
                ...state,
                stamps: [...state.stamps, ...payload],
                loadingStamps: true,
                error: null,
            };
        case ADD_STAMPS_SUCCESS:
            return {
                ...state,
                loadingStamps: false,
                stamps: [
                    ...state.stamps.filter((stamp: IStamp): boolean => (
                        !!stamp.id
                    )),
                    ...payload,
                ],
            };
        case DELETE_STAMPS_SUCCESS:
            return {
                ...state,
                loadingStamps: false,
                stamps: state.stamps.filter((stamp: IStamp): boolean =>
                    !payload.includes(stamp.id),
                ),
            };
        case GET_CURRENT_PAGE_STAMPS_FAILURE:
        case ADD_STAMPS_FAILURE:
        case UPDATE_STAMP_FAILURE:
        case DELETE_STAMPS_FAILURE:
            return {
                ...state,
                loadingStamps: false,
                error: payload,
            };
        case GET_STAMP_ALIGN_SUCCESS:
            return {
                ...state,
                stampAlignment: payload,
            };
        case UPDATE_STAMP_ALIGN_SUCCESS:
            return {
                ...state,
                stampAlignment: payload,
            };
        case SET_AUTO_STAMP:
            return {
                ...state,
                autoStamp: payload,
            };
        case HIGHLIGHT_LINKED_ARTICLES:
            return {
                ...state,
                loadingStamps: false,
                stamps: state.stamps.map((stamp: IStamp): IStamp => {
                    const linkedStamp = payload && payload.find((id: number) => id === stamp.id);

                    return linkedStamp ? {...stamp, stroke: '#00ffff'} : {...stamp, stroke: ''};

                  }),
            };
        default:
            return state;
    }
};

export default stampsReducer;

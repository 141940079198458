import { createSelector } from 'reselect';
import { IState } from '../store';
import { IOfficerReportTypes } from '../reducers/reportsOfficer/reportsOffice.model';
import { IIndexNotes } from '../reducers/reportsIndexNotes/reportsIndexNotes.model.';

export const getReportingOfficer = (state: IState): IOfficerReportTypes => state.reportsOfficer;

export const getReportingOfficerPending = createSelector(
    [getReportingOfficer],
    (reportOfficer: IOfficerReportTypes): boolean => reportOfficer.officerReportPending);

export const getReportingIndexNotes = (state: IState): IIndexNotes => state.indexNotesReports;

export const getReportingIndexNotesPending = createSelector(
    [getReportingIndexNotes],
    (indexNotesReports: IIndexNotes): boolean => indexNotesReports.indexNotesReportPending);

import * as React from 'react';
import { RowWithCheckbox } from './RowWithCheckBox';
import { IAvailableSecurityRowProps } from './ModalRequestSecurity.model';
import { IValidRowLevelSecurity } from '../../../redux/reducers/amandaContent/amandaContent.model';
import { isExistRowLevelSecurity } from '../../../utils/requestSecurity.utils';
import { SELECTED_ROW_LEVEL_SECURITY_DTOS } from './ModalRequestSecurityConstants';
import './modalRequestSecurity.style.scss';

export const AvailableSecurityRow = (
    {
        security,
        isGroup,
        securityChildrenToShow,
        setSecurityChildrenToShow,
        selectedAvailableSecurities,
        setSelectedAvailableSecurities,
    }: IAvailableSecurityRowProps,
): JSX.Element => (
    <React.Fragment>
            {
                securityChildrenToShow.includes(security.rowLevelSecurityCode) &&
                <li className='modal_request_security_available_securities_list_row'>
                    <RowWithCheckbox
                        showCheckbox={true}
                        isGroup={isGroup}
                        security={security}
                        checked={!!isExistRowLevelSecurity(
                            selectedAvailableSecurities[SELECTED_ROW_LEVEL_SECURITY_DTOS],
                            security,
                        )}
                        handleChange={(): void => setSelectedAvailableSecurities(security)}
                        securityChildrenToShow={securityChildrenToShow}
                        setSecurityChildrenToShow={setSecurityChildrenToShow}
                    />
                    {
                        securityChildrenToShow.includes(security.rowLevelSecurityCode) && <ul>
                        { security.validRowLevelSecurityChildren.map(
                            (child: IValidRowLevelSecurity): JSX.Element => (
                                <AvailableSecurityRow
                                    securityChildrenToShow={securityChildrenToShow}
                                    setSecurityChildrenToShow={setSecurityChildrenToShow}
                                    isGroup={isGroup}
                                    security={child}
                                    key={child.rowLevelSecurityCode}
                                    setSelectedAvailableSecurities={setSelectedAvailableSecurities}
                                    selectedAvailableSecurities={selectedAvailableSecurities}
                                />
                            ))}
                        </ul>
                    }
                </li>
            }
    </React.Fragment>
);

import * as React from 'react';
import './settingsTable.styles.scss';
import {
    ISortableContainerProps,
    ISortableItemProps,
} from './settingsTable.model';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Scrollbars } from 'rc-scrollbars';
import Divider from '@mui/material/Divider';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { IDuplicateTableHeader } from '../table.model';
import { IHeader } from '../../../../constants/leftSidebar.config';
import * as constants from '../../../../constants/duplicates.constants';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';

const SortableItem = (({item, handleClickCheckbox}: ISortableItemProps): JSX.Element => {
    return (
        <MenuItem classes={{root: 'duplicate_table_list-item'}}>
            <FormControlLabel
                onChange={(): void => handleClickCheckbox(item.id)}
                control={
                    <Checkbox
                        disabled={item.alwaysShow}
                        color='primary'
                        checked={item.show}
                    />}
                label={item.header}
            />
        </MenuItem>
    );
});

const SortableListGroup = (({
    columnsGroup,
    handleClickCheckbox,
    resetColumns,
    handleShowAll,
    handleHideAll
}: ISortableContainerProps): JSX.Element => {
    return (
        <React.Fragment>
            <div className={'duplicate_table_group-wrapper'}>
                {
                    columnsGroup.map((group: IDuplicateTableHeader) => (
                        <div
                            key={group.title}
                            className={'duplicate_table_group'}
                        >
                            <div className={'duplicate_table_title'}>{group.title}</div>
                            <div>
                                <Scrollbars
                                    autoHeightMax={400}
                                    autoHeight={true}
                                >
                                    {group.list.map((item: IHeader) => (
                                        <SortableItem
                                            key={`item-${item.id}`}
                                            item={item}
                                            handleClickCheckbox={handleClickCheckbox}
                                        />
                                    ))}
                                </Scrollbars>
                            </div>
                        </div>
                    ))
                }
            </div>
            <Divider/>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                <Button className="button-spacing" onClick={handleHideAll} startIcon={<VisibilityOffIcon />}>
                    {constants.getManageDuplicatesLabelsByKey('Hide All')}
                </Button>
                <Button className="button-spacing" onClick={resetColumns} startIcon={<AutorenewIcon />}>
                    {constants.getManageDuplicatesLabelsByKey('Reset')}
                </Button>
                <Button className="button-spacing" onClick={handleShowAll} startIcon={<VisibilityIcon />}>
                    {constants.getManageDuplicatesLabelsByKey('Show All')}
                </Button>
            </div>
        </React.Fragment>
    );
});

export default SortableListGroup;

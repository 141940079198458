import { decode } from 'base-64';
import { createSelector } from 'reselect';
import { IState } from '../store';
import { IPanelSizes, IDuplicatesPanelSizes } from 'redux/reducers/localStorage/localStorage.model';
import { ILastUsedPrinter, LocalStorageState } from '../reducers/localStorage/localStorage.model';
import { IValidExemptionDtoList } from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { ISelectOptions } from '../../components/materialUiForms/marerialUiForms.model';

export const getLocalStorageState = (state: IState): LocalStorageState => state.localStorage;

export const getPanelSizes = createSelector(
    [ getLocalStorageState ],
    (localStorage: LocalStorageState): IPanelSizes => localStorage.panelSizes,
);

export const getLastUsedPrinter = createSelector(
    [ getLocalStorageState ],
    (localStorage: LocalStorageState): ILastUsedPrinter => localStorage.lastUsedPrinter,
);

export const getRecentlyUsedStamps = createSelector(
    [ getLocalStorageState ],
    (localStorage: LocalStorageState): IValidExemptionDtoList[][] => localStorage.lastUsedStamps,
);

export const getRecentlyUsedStampsOptions = createSelector(
    [ getLocalStorageState ],
    (localStorage: LocalStorageState): ISelectOptions[] => {
        const usedStamps = localStorage.lastUsedStamps;

        return  usedStamps.map((arrStamps: IValidExemptionDtoList[], i: number) => {
            const label = arrStamps.reduce((acc: string, stamp: IValidExemptionDtoList, index: number) => {
                return `${acc}${index === 0 ? '' : ','} ${stamp.exemptionLabel}`;
            }, '');

            return ({
                label,
                value: `${i}`,
            });
        });
    },
);

export const getDuplicatePanelSizes = createSelector(
    [ getLocalStorageState ],
    (localStorage: LocalStorageState): IDuplicatesPanelSizes => localStorage.duplicatePanelSizes,
);

export const getReductionLid = createSelector(
    [ getLocalStorageState ],
    (localStorage: LocalStorageState): string => localStorage.lid,
);

export const getReductionAuthHeader = createSelector(
    [ getLocalStorageState ],
    (localStorage: LocalStorageState): string => localStorage.auth,
);

export const getRecentlyUsedExemptions = createSelector(
    [getLocalStorageState],
    (localStorageState: LocalStorageState): IValidExemptionDtoList[] => localStorageState.lastUsedExemptions,
);

export const getRedactionLanguage = createSelector(
    [getLocalStorageState],
    (localStorageState: LocalStorageState): string => localStorageState.language,
);

export const getRedactionMode = createSelector(
    [ getLocalStorageState ],
    (localStorage: LocalStorageState): string => localStorage.mode,
);

import {
    FETCH_INDEX_METADATA_PENDING,
    FETCH_INDEX_METADATA_FAIL,
    FETCH_INDEX_METADATA_SUCCESS,
} from '../reducers/indexMetadata/constant';
import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../../api/reductionApi';
import { addError } from './errorHandling';
import { IIndexMetadata } from '../reducers/indexMetadata/indexMetadata.model';
import { IError } from '../common.model';

export const fetchIndexMetadataPending = (): AnyAction => action(FETCH_INDEX_METADATA_PENDING);
export const fetchIndexMetadataSuccess = (indexMetadata: IIndexMetadata[]): AnyAction =>
    action(FETCH_INDEX_METADATA_SUCCESS, indexMetadata);
export const fetchIndexMetadataFailure = (error: IError): AnyAction => action(FETCH_INDEX_METADATA_FAIL, error);

export const fetchIndexMetadata = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchIndexMetadataPending());

        try {
            const response = await api.indexMetadata.getIndexMetadata();

            dispatch(fetchIndexMetadataSuccess(response));
        } catch (error) {
            dispatch(fetchIndexMetadataFailure(error));
            dispatch(addError(error));
        }
    };

import {
    CLASSIFICATION_PARAM, DOCUMENT_NAME_PARAM,
    DOCUMENT_TYPE_PARAM,
    FOLDER_RSN_PARAM,
    LANGUAGE_PARAM, NOTES_PARAM, PAGINATION_NUM_PARAM,
    RECEIVING_INDIVIDUAL_PARAM,
    RECEIVING_ORGANIZATION_PARAM,
    REFERENCE_NUM_PARAM,
    SEARCH_REQUEST_TYPE_PARAM,
    SEARCH_SCOPE_PARAM,
    SENDING_INDIVIDUAL_PARAM,
    SENDING_ORGANIZATION_PARAM,
    SUBJECT_PARAM,
    DOC_DATE_FROM_PARAM,
    DOC_DATE_TO_PARAM,
    REQUEST_NUMBER_PARAM,
    SPECIFIC_REQUEST_PARAM,
    CURRENT_SCOPE_PARAM,
    ACT,
} from '../../../constants/advancedSearch.constants';

import { AnyAction } from 'redux';
import { IAdvanceSearchReducer } from './advanceSearch.model';
import {
    SET_ADVANCE_SEARCH_PARAM,
    CLEAR_ADVANCE_SEARCH_PARAM,
    UPDATE_ADVANCE_SEARCH_ERRORS,
    ADVANCE_SEARCH_VALUE_SUCCESS,
    ADVANCE_SEARCH_VALUE_FAILURE,
    ADVANCE_SEARCH_VALUE_PENDING,
    SET_ADVANCE_SEARCH_TABLE_COLUMNS,
    SET_ACTS_LIST,
    SET_EXEMPTIONS_LIST,
    CLEAR_ADVANCE_SEARCH_RESULT,
    SET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING,
    SET_ADVANCE_SEARCH_COLUMN_HEADER_LIST_SUCCESS,
    SET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE,
    MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE,
    MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_PENDING,
    MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS,
    RESET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE,
    RESET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING,
    RESET_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS,
    SET_SELECTED_ADVANCE_SEARCHED_DOCUMENT,
} from './constant';
import { FILE_NAME_PARAM } from '../../../constants/export.contstants';

const initialAdvanceSearchParams = {
    [SEARCH_SCOPE_PARAM]: CURRENT_SCOPE_PARAM,
    [LANGUAGE_PARAM]: null,
    [REFERENCE_NUM_PARAM]: null,
    [CLASSIFICATION_PARAM]: null,
    [DOCUMENT_TYPE_PARAM]: null,
    [RECEIVING_ORGANIZATION_PARAM]: null,
    [SENDING_ORGANIZATION_PARAM]: null,
    [RECEIVING_INDIVIDUAL_PARAM]: null,
    [SENDING_INDIVIDUAL_PARAM]: null,
    [NOTES_PARAM]: null,
    [SUBJECT_PARAM]: null,
    [DOC_DATE_FROM_PARAM]: null,
    [DOC_DATE_TO_PARAM]: null,
    [DOCUMENT_NAME_PARAM]: null,
    [FILE_NAME_PARAM]: null,
    [PAGINATION_NUM_PARAM]: null,
    [SPECIFIC_REQUEST_PARAM]: {
        [FOLDER_RSN_PARAM]: null,
        [SEARCH_REQUEST_TYPE_PARAM]: null,
        [REQUEST_NUMBER_PARAM]: null,
    },
    [ACT]: null,
};
const initialState = {
    advanceSearchParams: initialAdvanceSearchParams,
    errorsList: [],
    error: null,
    advanceSearchResult: [],
    advanceSearchPending: false,
    actList: [],
    exemptionsList: [],
    advanceSearchColumnHeaderPending: false,
    advanceSearchColumnHeader: [],
    modifyAdvanceSearchTableColumnLoading: false,
    resetAdvanceSearchColumnHeaderPending: false,
    selectedAdvanceSearchedDocument: null,
};

const advanceSearchReducer = (state: IAdvanceSearchReducer = initialState, { type, payload }: AnyAction):
    IAdvanceSearchReducer => {
    switch (type) {
        case SET_ADVANCE_SEARCH_PARAM:
            return {
                ...state,
                advanceSearchParams: {
                    ...state.advanceSearchParams,
                    ...payload,
                },
            };
        case CLEAR_ADVANCE_SEARCH_PARAM:
            return {
                ...state,
                advanceSearchParams: {
                    ...initialAdvanceSearchParams,
                },
                errorsList: [],
                advanceSearchResult: [],
            };
        case UPDATE_ADVANCE_SEARCH_ERRORS:
            return {
                ...state,
                errorsList: [...payload],
                advanceSearchResult: [],
            };
        case ADVANCE_SEARCH_VALUE_PENDING:
            return {
                ...state,
                advanceSearchPending: true,
                advanceSearchResult: [],
                error: null,
            };
        case ADVANCE_SEARCH_VALUE_SUCCESS:
            return {
                ...state,
                advanceSearchPending: false,
                advanceSearchResult: payload,
                error: null,
            };
        case ADVANCE_SEARCH_VALUE_FAILURE:
            return {
                ...state,
                advanceSearchPending: false,
                error: payload,
            };
        case SET_ADVANCE_SEARCH_TABLE_COLUMNS: {
            return {
                ...state,
                advanceSearchColumnHeader: payload,
            };
        }
        case SET_ACTS_LIST: {
            return {
                ...state,
                actList: payload,
            };
        }
        case SET_EXEMPTIONS_LIST: {
            return {
                ...state,
                exemptionsList: payload,
            };
        }
        case SET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING:
            return {
                ...state,
                advanceSearchColumnHeaderPending: true,
            };
        case SET_ADVANCE_SEARCH_COLUMN_HEADER_LIST_SUCCESS:
            return {
                ...state,
                advanceSearchColumnHeader: payload,
                advanceSearchColumnHeaderPending: false,
            };
        case SET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                advanceSearchColumnHeader: [],
                advanceSearchColumnHeaderPending: false,
                error: payload,
            };
        case MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_PENDING:
            return {
                ...state,
                modifyAdvanceSearchTableColumnLoading: true,
            };
        case MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS:
            return {
                ...state,
                advanceSearchColumnHeader: payload,
                resetAdvanceSearchColumnHeaderPending: false,
            };
        case MODIFY_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE:
        case CLEAR_ADVANCE_SEARCH_RESULT: {
            return {
                ...state,
                advanceSearchResult: [],
            };
        }
        case RESET_ADVANCE_SEARCH_COLUMN_HEADER_PENDING:
            return {
                ...state,
                resetAdvanceSearchColumnHeaderPending: true,
            };
        case RESET_ADVANCE_SEARCH_COLUMN_HEADER_SUCCESS:
            return {
                ...state,
                advanceSearchColumnHeader: payload,
                resetAdvanceSearchColumnHeaderPending: false,
            };
        case RESET_ADVANCE_SEARCH_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                resetAdvanceSearchColumnHeaderPending: false,
                error: payload,
            };
        case SET_SELECTED_ADVANCE_SEARCHED_DOCUMENT:
            return {
                ...state,
                selectedAdvanceSearchedDocument: payload,
            };
        default:
            return state;
    }
};

export default advanceSearchReducer;

import {
    GET_INDEX_NOTES_PENDING,
    GET_INDEX_NOTES_SUCCESS,
    GET_INDEX_NOTES_FAILURE,
    POST_INDEX_NOTES_PENDING,
    POST_INDEX_NOTES_SUCCESS,
    POST_INDEX_NOTES_FAILURE,
} from './constants';

import { IIndexNotes } from './reportsIndexNotes.model.';
import { AnyAction } from 'redux';

const initialState: IIndexNotes = {
    indexNotesReportPending: false,
    error: null,
};

const indexNotesReports = (state: IIndexNotes = initialState, {type, payload}: AnyAction): IIndexNotes => {
    switch (type) {
        case GET_INDEX_NOTES_PENDING:
        case POST_INDEX_NOTES_PENDING:
            return {
                ...state,
                indexNotesReportPending: true,
                error: null,
            };
        case GET_INDEX_NOTES_SUCCESS:
        case POST_INDEX_NOTES_SUCCESS:
            return{
                ...state,
                indexNotesReportPending: false,
            };
        case GET_INDEX_NOTES_FAILURE:
        case POST_INDEX_NOTES_FAILURE:
            return{
                ...state,
                error: payload,
                indexNotesReportPending: false,
            };
        default:
            return state;
    }
};

export default indexNotesReports;

import * as React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { addMasterDocument } from '../../../redux/actions/modalAssignDuplicates';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import {
    IAssignDuplicateDispatch,
    IAssignDuplicateProps,
    IAssignDuplicateState,
    IAssignDuplicateStateToProps,
    IMasterDocumentDto,
} from './modalAssignDuplicate.model';
import { Scrollbars } from 'rc-scrollbars';
import './modalAssignDuplicate.style.scss';
import { IState as StoreState } from '../../../redux/store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { ASSIGN, CANCEL, ID, NAME } from '../../../constants/common.constants';
import { fetchDuplicatedDisclosureTypeList } from '../../../redux/actions/disclosureTypes';
import {
    getDuplicatedDisclosureTypes,
    getDuplicatedDisclosureTypesLoading,
} from '../../../redux/selectors/disclosureTypes';
import {
    CheckboxMaterialBuilder,
    SelectMaterialBuilder,
} from '../../../components/materialUiForms/materialUiFormBuilder';
import { DISCLOSURE } from '../../../constants/disclosure.contstants';
import { ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import { getDuplicatesResources } from '../../../redux/selectors/duplicates';
import {
    DOCUMENT_ID_PARAM,
    DOCUMENT_NAME_PARAM,
    DUPLICATES_LABEL,
    MASTER_DOCUMENT_LABEL,
    PAGE_AMOUNT_LABEL,
} from '../../../constants/duplicates.constants';
import TextField from '@mui/material/TextField';
import { IDuplicateDocument } from '../../../redux/reducers/duplicates/duplicates.model';
import { InputAdornment } from '@mui/material';
import { Spinner } from '../../../components/spinner/Spinner';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { getAssignDuplicatesPending } from '../../../redux/selectors/modalAssignDuplicates';
import * as constants from '../../../constants/duplicates.constants';

class ModalAssignDuplicate extends React.Component<IAssignDuplicateProps, IAssignDuplicateState> {
    public state: IAssignDuplicateState = {
        [DISCLOSURE]: null,
        duplicatesIds: [],
        masterId: null,
        pageAmount: undefined,
    };

    public componentDidMount(): void {
        this.props.fetchDuplicatedDisclosureTypeList();
    }

    public render(): JSX.Element {
        const {
            duplicatesIds,
            masterId,
            pageAmount,
        } = this.state;
        const {
            duplicatedDisclosureTypesLoading,
            duplicatedDisclosureTypes,
            duplicatesResources,
            assignDuplicatesPending,
        } = this.props;

        const createSelectOptionsByParam = (paramId: string, paramName: string, options: any[]): ISelectOptions[] =>
            options.map((type: any): ISelectOptions => ({value: `${type[paramId]}`, label: type[paramName]}));
        const getSelectValues = (val: number | null): string => val ? `${val}` : '';
        const duplicatesResourcesWithoutMaster = duplicatesResources.filter((item: IDuplicateDocument) =>
            item[DOCUMENT_ID_PARAM] !== masterId);

        return (
            <div className='assign-modal'>
                <div className='assign-modal_form'>
                    <div className='select-box'>
                        <SelectMaterialBuilder
                            value={getSelectValues(this.state.masterId)}
                            paramName={MASTER_DOCUMENT_LABEL}
                            label={constants.getManageDuplicatesLabelsByKey(MASTER_DOCUMENT_LABEL)}
                            options={createSelectOptionsByParam(
                                DOCUMENT_ID_PARAM, DOCUMENT_NAME_PARAM, duplicatesResources,
                            )}
                            handleSelectChange={
                                (
                                    label: string,
                                    value: string,
                                ): void => this.handleSelectMasterDocument(Number(value))}
                        />
                    </div>
                    <div className='basicTab-label'>
                        <FormLabel>
                        {constants.getManageDuplicatesLabelsByKey('Assigned Duplicates')}:
                        {
                            !duplicatesIds.length &&
                            <span className='error-message'>{constants.getManageDuplicatesLabelsByKey(constants.ATLEAST_ONE_REQUIRED)} </span>
                        }
                        </FormLabel>
                    </div>
                    <div className='assign-modal_duplicates-list-wrap'>
                        <Scrollbars>
                            <div className='assign-modal_duplicates-list'>
                                {
                                    duplicatesResourcesWithoutMaster.map((item: IDuplicateDocument): JSX.Element => (
                                        <div key={item[DOCUMENT_ID_PARAM]}>
                                            <CheckboxMaterialBuilder
                                                checked={duplicatesIds.includes(item[DOCUMENT_ID_PARAM])}
                                                handleCheckboxChange={
                                                    (param: string, checked: boolean): void =>
                                                        this.handleChangeDuplicate(
                                                            item[DOCUMENT_ID_PARAM],
                                                            checked,
                                                        )
                                                }
                                                label={item[DOCUMENT_NAME_PARAM]}
                                                paramName={`${item[DOCUMENT_ID_PARAM]}`}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </Scrollbars>
                    </div>
                    <TextField
                        value={pageAmount ? pageAmount : ''}
                        label={constants.getManageDuplicatesLabelsByKey(PAGE_AMOUNT_LABEL)}
                        fullWidth={true}
                        type='number'
                        inputProps={{
                            min: 1,
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                            this.handlePageAmount(e.target.value);
                        }}
                        error={pageAmount > this.getMaxValPageAmount() || pageAmount < 1}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                {duplicatesIds.length ? `Max ${this.getMaxValPageAmount()}` : ''}
                            </InputAdornment>,
                        }}
                        variant='outlined'
                    />
                    {
                        duplicatedDisclosureTypesLoading ?
                            <Spinner active={true}/> :
                            <div className='select-box'>
                                <SelectMaterialBuilder
                                    value={getSelectValues(this.state[DISCLOSURE])}
                                    paramName={DISCLOSURE}
                                    label={constants.getManageDuplicatesLabelsByKey('Assigned Disclosure')}
                                    options={createSelectOptionsByParam(ID, NAME, duplicatedDisclosureTypes)}
                                    handleSelectChange={
                                        (
                                            label: string,
                                            value: string,
                                        ): void => this.setState({[DISCLOSURE]: Number(value)})}
                                />
                            </div>
                    }
                </div>
                <div className='form-footer__action'>
                    <Button
                        size='small'
                        variant='outlined'
                        className='modal-window__buttons outlined'
                        onClick={this.props.handleCloseModalWindow}
                    >
                        {constants.getManageDuplicatesLabelsByKey('Assigned Cancel')}
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        className='modal-window__buttons primary'
                        disabled={!this.isEnableForm() || assignDuplicatesPending}
                        onClick={(): void => this.onSave()}
                    >
                        {constants.getManageDuplicatesLabelsByKey(ASSIGN)}
                    </Button>
                </div>
                <div className='linear-progress'>
                    {assignDuplicatesPending && <LinearProgress />}
                </div>
            </div>
        );
    }

    private handleSelectMasterDocument = (value: number): void => {
        this.setState({
            masterId: Number(value),
            pageAmount: undefined,
            duplicatesIds: [],
        });
    }

    private getMaxValPageAmount = (): number => {
        const {duplicatesResources} = this.props;
        const {duplicatesIds, masterId} = this.state;
        const resources = duplicatesResources.filter((duplicate: IDuplicateDocument) =>
            [...duplicatesIds, masterId].includes(duplicate[DOCUMENT_ID_PARAM]));

        return resources.reduce((minVal: number, currentDoc: IDuplicateDocument) => {
            const availablePages = currentDoc.pagesAmount - (currentDoc.currentPageNumber - 1);

            if (!minVal) {
                return availablePages;
            }

            return availablePages < minVal ? availablePages : minVal;
        }, null);
    }

    private handlePageAmount = (value: string): void => {
        this.setState({pageAmount: value ? Number(value) : null});
        // this.setState({pageAmount: Number(value) > 1 ? Number(value) : 1});
    }

    private handleChangeDuplicate = (id: number, isChecked: boolean): void => {
        const {duplicatesIds} = this.state;
        const filteredResources = duplicatesIds.filter((itemId: number) => itemId !== id);
        const newDuplicatesIds = isChecked ? [...duplicatesIds, id] : filteredResources;

        // recalc page available

        this.setState({duplicatesIds: newDuplicatesIds});
    }

    private isEnableForm = (): boolean => {
        const {
            duplicatesIds,
            masterId,
            pageAmount,
        } = this.state;

        return !!this.state[DISCLOSURE] &&
            !!duplicatesIds.length &&
            !!masterId &&
            pageAmount >= 1 &&
            pageAmount <= this.getMaxValPageAmount();
    }

    private onSave(): void {
        const {
            duplicatesIds,
            masterId,
            pageAmount,
        } = this.state;
        const {duplicatesResources, redactionDocumentId} = this.props;
        const filteredDuplicatePages = duplicatesResources.filter((duplicate: IDuplicateDocument) =>
            duplicatesIds.includes(duplicate[DOCUMENT_ID_PARAM]));
        const duplicatePages = filteredDuplicatePages.map((duplicate: IDuplicateDocument) => ({
            documentId: duplicate[DOCUMENT_ID_PARAM],
            actualPageNumber: duplicate.currentPageNumber,
            redactionDocumentId,
        }));
        const actualPageNumber = duplicatesResources.find((duplicate: IDuplicateDocument) =>
            duplicate[DOCUMENT_ID_PARAM] === masterId).currentPageNumber;
        const data = {
            disclosureTypeId: this.state[DISCLOSURE],
            documentId: masterId,
            actualPageNumber,
            duplicatePages,
            pageAmount,
        };

        this.props.addMasterDocument(this.props.redactionDocumentId, data);
    }
}

const mapStateToProps = (state: StoreState): IAssignDuplicateStateToProps => ({
    redactionDocumentId: getRedactionDocumentId(state),
    duplicatedDisclosureTypesLoading: getDuplicatedDisclosureTypesLoading(state),
    assignDuplicatesPending: getAssignDuplicatesPending(state),
    duplicatedDisclosureTypes: getDuplicatedDisclosureTypes(state),
    duplicatesResources: getDuplicatesResources(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IAssignDuplicateDispatch, AnyAction>,
): IAssignDuplicateDispatch => ({
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    addMasterDocument: (redactionDocumentId: number, data: IMasterDocumentDto): void => {
        dispatch(addMasterDocument(redactionDocumentId, data));
    },
    fetchDuplicatedDisclosureTypeList: (): void => {
        dispatch(fetchDuplicatedDisclosureTypeList());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignDuplicate);

import { createSelector } from 'reselect';
import modalNames, { IModalPermissionElement } from '../../constants/modal.constants';
import { permissionsProperty } from '../../constants/common.constants';
import {
    REDACTION_DOCUMENT_DELETE,
    REDACTION_PAGE_DELETE,
    REDACTION_DOCUMENT_PAGINATION_UNDO,
    REDACTION_DOCUMENT_PAGINATION_EDIT,
    REDACTION_DOCUMENT_PAGINATION_SET,
    REDACTION_DOCUMENT_STACK_MANAGEMENT,
    REDACTION_DOCUMENT_STACK_ADD_REMOVE,
    REDACTION_COMMON_SET_DISCLOSURE,
    REDACTION_COMMON_REPLACE_DISCLOSURE,
    REDACTION_COMMON_UNDO_DISCLOSURE,
    REDACTION_ACTION_EXPORT_PRINT_PACKAGE,
    REDACTION_COMMON_APPLY_CUSTOM_STAMPS,
    REDACTION_COMMON_APPLY_EXEMPTION_STAMPS,
    REDACTION_COMMON_APPLY_HIGHLIGHTS,
    REDACTION_COMMON_APPLY_SEVERS,
    REDACTION_COMMON_CONSULT_TAB_VIEW,
    REDACTION_COMMON_NOTES_TAB_VIEW,
    REDACTION_COMMON_INDEX_VIEW,
    REDACTION_COMMON_INDEX_EDIT,
    REDACTION_COMMON_NOTES_TAB_EDIT,
    REDACTION_ACTION_DOCUMENT_SEARCH,
    REDACTION_ACTION_IMPORT_DOCUMENT_FOLDER_PROCESS,
    REDACTION_ACTION_IMPORT_DOCUMENT_LOCAL_FILE_SYSTEM,
    REDACTION_ACTION_IMPORT_DOCUMENT_REDACTION,
    REDACTION_ACTION_IMPORT_DOCUMENT_SCANNER,
    REDACTION_ADMIN_AUDIT_LOGS_EXPORT_RESULTS,
    REDACTION_COMMON_RECCOMENDATIONS_TAB_EDIT,
    REDACTION_COMMON_RECCOMENDATIONS_TAB_VIEW,
    REDACTION_ADMIN_DOCUMENT_INDEX_VIEW,
    REDACTION_PAGE_ROTATE,
    REDACTION_DOCUMENT_EDIT_FILE_NAME,
    REDACTION_COMMON_APPLY_AUTO_STAMPS,
    REDACTION_ADMIN_ERASE_LOGS,
    REDACTION_ACTION_ADD_ATTACHMENT_FROM_EDMS,
} from '../../constants/permissions';
import { IState } from '../store';
import {
    IInitializationState,
    IUserMetaData,
    IModalElementPermissions,
    IModalPermissions,
    IMainModulesPermissions,
} from '../reducers/initialization/initialization.model';
import { permissionValues } from '../../constants/permissions';
import { LocalStorageState } from 'redux/reducers/localStorage/localStorage.model';
import { MODE_CONTRIBUTE, MODE_FULL } from '../../redux/reducers/localStorage/constant';

const { DENY_ACCESS } = permissionValues;
export const getReductionLocalStorageState = (state:IState): LocalStorageState => state.localStorage;

export const getReductionMetaDataState = (state: IState): IInitializationState => state.reductionMetaData;

export const getRedactionDocumentId = createSelector(
    [getReductionMetaDataState],
    (metaData: IInitializationState): null | number => metaData.redactionDocumentId,
);

export const getActCode = createSelector(
    [getReductionMetaDataState],
    (metaData: IInitializationState): number => metaData.actCode,
);

export const getRedactionRequestType = createSelector(
    [getReductionMetaDataState],
    (metaData: IInitializationState): null | string => metaData.requestType,
);

export const getRedactionFolderRsn = createSelector(
    [getReductionMetaDataState],
    (metaData: IInitializationState): null | number => metaData.folderRsn,
);

export const getInitialDataLoaded = createSelector(
    [getReductionMetaDataState],
    (metaData: IInitializationState): boolean => metaData.redactionDocumentLoaded,
);

export const getInitialDataLoading = createSelector(
    [getReductionMetaDataState],
    (metaData: IInitializationState): boolean => metaData.redactionDocumentLoading,
);

export const getUserPermissionsLoading = createSelector(
    [getReductionMetaDataState],
    (metaData: IInitializationState): boolean => metaData.userPermissionsLoading || metaData.redactionEntryDataLoading,
);

export const getUserMetaData = createSelector(
    [getReductionMetaDataState],
    ({ userSecurityRole, userId, userIsAdmin }: IInitializationState): IUserMetaData => ({
        userSecurityRole, userId, userIsAdmin,
    }),
);

export const getUserPermissions = createSelector(
    [getReductionMetaDataState],
    ({ userPermissions }: IInitializationState): string[] => userPermissions,
);

export const getRedactionRequestNumber = createSelector(
    [getReductionMetaDataState],
    (metaData: IInitializationState): string => metaData.requestNumber,
);

export const formatPermissions = (
    permissions: string[],
    permissionsPropertyList: string[],
    modalPermissions: IModalPermissionElement,
): IModalElementPermissions =>
    permissionsPropertyList.reduce((result: IModalElementPermissions, property: string): IModalElementPermissions => ({
        ...result,
        [property]: permissions.includes(modalPermissions[property]),
    }), {});

export const getModalWindowPermissions = createSelector(
    [getReductionMetaDataState],
    ({ userPermissions }: IInitializationState): IModalPermissions =>
        Object.keys(modalNames).reduce((
            result: IModalPermissions,
            name: string,
        ): IModalPermissions => ({
            ...result,
            [name]: formatPermissions(userPermissions, permissionsProperty, modalNames[name]),
        }), {}));

export const getMainModulesPermissions = createSelector(
    [getReductionMetaDataState],
    ({
         userSecurityRole,
         userId,
         userIsAdmin,
     }: IInitializationState): IMainModulesPermissions => ({
        isDenyAccess: userSecurityRole === DENY_ACCESS,
        showHeader: userSecurityRole === DENY_ACCESS && userIsAdmin || userSecurityRole !== DENY_ACCESS,
    }),
);

export const getDocumentListPermissions = createSelector(
    [getReductionMetaDataState],
    ({ userPermissions }: IInitializationState): IModalElementPermissions => ({
        deleteDocumentPermission: userPermissions.includes(REDACTION_DOCUMENT_DELETE),
        setDisclosurePermission: userPermissions.includes(REDACTION_COMMON_SET_DISCLOSURE),
        replaceDisclosurePermission: userPermissions.includes(REDACTION_COMMON_REPLACE_DISCLOSURE),
        undoDisclosurePermission: userPermissions.includes(REDACTION_COMMON_UNDO_DISCLOSURE),
        undoPaginationPermission: userPermissions.includes(REDACTION_DOCUMENT_PAGINATION_UNDO),
        editPaginationPermission: userPermissions.includes(REDACTION_DOCUMENT_PAGINATION_EDIT),
        setPaginationPermission: userPermissions.includes(REDACTION_DOCUMENT_PAGINATION_SET),
        addRemoveStackPermission: userPermissions.includes(REDACTION_DOCUMENT_STACK_ADD_REMOVE),
        managementStackPermission: userPermissions.includes(REDACTION_DOCUMENT_STACK_MANAGEMENT),
        editFileNamePermission: userPermissions.includes(REDACTION_DOCUMENT_EDIT_FILE_NAME),
    }));

export const getPageListPermissions = createSelector(
    [getReductionMetaDataState],
    ({ userPermissions }: IInitializationState): IModalElementPermissions => ({
        deletePagePermission: userPermissions.includes(REDACTION_PAGE_DELETE),
        setDisclosurePermission: userPermissions.includes(REDACTION_COMMON_SET_DISCLOSURE),
        undoDisclosurePermission: userPermissions.includes(REDACTION_COMMON_UNDO_DISCLOSURE),
        exportPagePermission: userPermissions.includes(REDACTION_ACTION_EXPORT_PRINT_PACKAGE),
        rotatePagePermission: userPermissions.includes(REDACTION_PAGE_ROTATE),
    }));

export const getReadOnlySecurityRole = createSelector(
    [getReductionMetaDataState],
    ({ userSecurityRole }: IInitializationState): IModalElementPermissions => ({
        readOnlyRole: userSecurityRole === permissionValues.READ_ONLY_ALL ||
        userSecurityRole === permissionValues.READ_ONLY_RELEASABLE,
    }));

export const getControlsPermissions = createSelector(
    [getReductionMetaDataState],
    ({ userPermissions }: IInitializationState): IModalElementPermissions => ({
        severPermission: userPermissions.includes(REDACTION_COMMON_APPLY_SEVERS),
        highlightPermission: userPermissions.includes(REDACTION_COMMON_APPLY_HIGHLIGHTS),
        exemptionsPermission: userPermissions.includes(REDACTION_COMMON_APPLY_EXEMPTION_STAMPS),
        customStampsPermission: userPermissions.includes(REDACTION_COMMON_APPLY_CUSTOM_STAMPS),
        autoStampPermission: userPermissions.includes(REDACTION_COMMON_APPLY_AUTO_STAMPS),
    }));

export const getRightBottomTabsPermissions = createSelector(
    [getReductionMetaDataState, getReadOnlySecurityRole, getReductionLocalStorageState],
    ({ userPermissions }: IInitializationState, { readOnlyRole }:
        { readOnlyRole: boolean }, { mode }: LocalStorageState): IModalElementPermissions => ({
        consultTubPermission: userPermissions.includes(REDACTION_COMMON_CONSULT_TAB_VIEW),
        notesTabPermissionView: userPermissions.includes(REDACTION_COMMON_NOTES_TAB_VIEW),
        notesTabPermissionEdit: userPermissions.includes(REDACTION_COMMON_NOTES_TAB_EDIT) && mode === MODE_FULL ? !readOnlyRole : true,
        indexPermissionView: userPermissions.includes(REDACTION_COMMON_INDEX_VIEW),
        indexPermissionEdit: userPermissions.includes(REDACTION_COMMON_INDEX_EDIT) && mode === MODE_FULL ? !readOnlyRole : true,
        recommendationsTabPermissionView: userPermissions.includes(REDACTION_COMMON_RECCOMENDATIONS_TAB_VIEW),
        recommendationsTabPermissionEdit: userPermissions.includes(REDACTION_COMMON_RECCOMENDATIONS_TAB_EDIT) 
         && mode === MODE_FULL ? !readOnlyRole : true,
        indexDocumentPermissionView: userPermissions.includes(REDACTION_ADMIN_DOCUMENT_INDEX_VIEW),
    }));

export const getImportPermissions = createSelector(
    [getReductionMetaDataState, getReductionLocalStorageState],
    ({ userPermissions }: IInitializationState, { mode }: LocalStorageState): IModalElementPermissions => ({
        amandaAttachmnentsImport: userPermissions.includes(REDACTION_ACTION_IMPORT_DOCUMENT_FOLDER_PROCESS),
        localFilesImport: userPermissions.includes(REDACTION_ACTION_IMPORT_DOCUMENT_LOCAL_FILE_SYSTEM),
        redactionDocumentsImports: userPermissions.includes(REDACTION_ACTION_IMPORT_DOCUMENT_REDACTION) && mode !== MODE_CONTRIBUTE,
        scannerImports: userPermissions.includes(REDACTION_ACTION_IMPORT_DOCUMENT_SCANNER),
        edmsAttachmentsImport: userPermissions.includes(REDACTION_ACTION_ADD_ATTACHMENT_FROM_EDMS),
    }));

export const getRightTopBarPermission = createSelector(
    [getReductionMetaDataState],
    ({ userPermissions }: IInitializationState): IModalElementPermissions => ({
        search: userPermissions.includes(REDACTION_ACTION_DOCUMENT_SEARCH),
    }));

export const getAuditLogPermission = createSelector(
    [getReductionMetaDataState],
    ({ userPermissions }: IInitializationState): IModalElementPermissions => ({
        exportButton: userPermissions.includes(REDACTION_ADMIN_AUDIT_LOGS_EXPORT_RESULTS),
        eraseButton: userPermissions.includes(REDACTION_ADMIN_ERASE_LOGS),
    }));

import { minBy, maxBy } from 'lodash';
import { ICoordinate } from '../redux/reducers/pageList/pageList.model';
import { END_POINT, START_POINT } from '../constants/annotationTypes.constants';
import { IStartCoordinate, ILoadedPage } from '../containers/redactor/redactor.model';
import { get } from 'lodash';
import { degrees } from '../constants';

export const MIN_OFFSET = 10;

interface IEdgeShapesCoordinates {
    startXShape: ICoordinate;
    startYShape: ICoordinate;
    endXShape: ICoordinate;
    endYShape: ICoordinate;
}

export const getEdgeShapes = (coordinates: ICoordinate[]): IEdgeShapesCoordinates => ({
    startXShape: minBy(coordinates, (points: ICoordinate): number => points[START_POINT].x),
    startYShape: minBy(coordinates, (points: ICoordinate): number => points[START_POINT].y),
    endXShape: maxBy(coordinates, (points: ICoordinate): number => points[END_POINT].x),
    endYShape: maxBy(coordinates, (points: ICoordinate): number => points[END_POINT].y),
});

export const formatGroupCoordinates = (
    coordinates: ICoordinate[],
    pdfWidth: number,
    pdfHeight: number,
    startPoint: IStartCoordinate,
    rotate: number,
): ICoordinate[] => {
    const {
        startXShape,
        startYShape,
        endXShape,
        endYShape,
    } = getEdgeShapes(coordinates);
    let startCoordinate;

    if (rotate === degrees.ZERO) {
        startCoordinate = {
            x: startPoint ? startPoint.x : get(startXShape, `start-point.x`) + MIN_OFFSET,
            y: startPoint ? startPoint.y : get(startYShape, 'start-point.y') + MIN_OFFSET,
        };
    }

    if (rotate === degrees.HALF) {
        startCoordinate = {
            x: startPoint ? pdfWidth - startPoint.x : get(startXShape, `start-point.x`) - MIN_OFFSET,
            y: startPoint ? pdfHeight - startPoint.y : get(startYShape, 'start-point.y') - MIN_OFFSET,
        };
    }

    if (rotate === degrees.MINUS_QUARTER) {
        const height  = get(startXShape, `end-point.y`) - get(startXShape, `start-point.y`);

        startCoordinate = {
            x: startPoint ? startPoint.y : get(startXShape, `start-point.x`) + MIN_OFFSET,
            y: startPoint ? (pdfHeight - startPoint.x - height) : get(startXShape, `start-point.y`) - MIN_OFFSET,
        };
    }

    if (rotate === degrees.THREE_QUARTERS) {
        startCoordinate = {
            x: startPoint ? pdfWidth - startPoint.y : get(startXShape, `start-point.x`) - MIN_OFFSET,
            y: startPoint ? startPoint.x: get(startXShape, 'start-point.y') + MIN_OFFSET,
        };
    }

    const lastShapeXPoint = startCoordinate.x + (get(endXShape,'end-point.x') -  get(startXShape, 'start-point.x'));
    const lastShapeYPoint = startCoordinate.y + (get(endYShape, 'end-point.y') - get(startYShape, 'start-point.y'));
    const elementWidth = get(startXShape, `end-point.y`) - get(startXShape, `start-point.y`);
    const elementHeight  = get(startXShape, `end-point.y`) - get(startXShape, `start-point.y`);

    let startX;
    let startY;

    // right side
    // check if last shape is not out of container
    if (lastShapeXPoint > pdfWidth ) {
        startX = startCoordinate.x - (lastShapeXPoint - pdfWidth) - MIN_OFFSET;
    } else if (lastShapeXPoint < elementWidth ) {
        startX = MIN_OFFSET;
    } else {
        startX = startCoordinate.x;
    }

    // bottom side
    // check if last shape is not out of container
    if (lastShapeYPoint > pdfHeight) {
        startY = startCoordinate.y - (lastShapeYPoint - pdfHeight) - MIN_OFFSET;
    } else if (lastShapeYPoint < elementHeight ) {
        startY = MIN_OFFSET;
    } else {
        startY = startCoordinate.y;
    }

    return formatCustomCoordinates(coordinates, { x: startX, y: startY }, {
        startXShape,
        startYShape,
        endXShape,
        endYShape,
    });

};

export const formatCustomCoordinates = (
    coordinates: ICoordinate[],
    startPoint: IStartCoordinate,
    groupBlockCoordinates: IEdgeShapesCoordinates,
): ICoordinate[] => {

    const {
        startXShape,
        startYShape,
    } = groupBlockCoordinates;

    return coordinates.map((coordinate: ICoordinate): ICoordinate => {
        const width = (coordinate[END_POINT].x - coordinate[START_POINT].x);
        const height = (coordinate[END_POINT].y - coordinate[START_POINT].y);
        const isStartPointByX = coordinate[START_POINT].x === startXShape[START_POINT].x;
        const isStartPointByY = coordinate[START_POINT].y === startYShape[START_POINT].y;
        const distanceFromFirstShapeByX = coordinate[START_POINT].x - startXShape[START_POINT].x;
        const distanceFromFirstShapeByY = coordinate[START_POINT].y - startYShape[START_POINT].y;

        return {
            [START_POINT]: {
                x: isStartPointByX ? startPoint.x : startPoint.x + distanceFromFirstShapeByX,
                y: isStartPointByY ? startPoint.y : startPoint.y + distanceFromFirstShapeByY,
            },
            [END_POINT]: {
                x: isStartPointByX ? startPoint.x + width : startPoint.x + distanceFromFirstShapeByX + width,
                y: isStartPointByY ? startPoint.y + height : startPoint.y + distanceFromFirstShapeByY + height,
            },
        };
    });
};

export const setPageParamsOnLoaded = (
    { view, rotate }: ILoadedPage,
    setWidth: (width: number) => void,
    setHeight: (height: number) => void,
): void => {
    // rotate it's inner pdf param, which shows landscape(90) and partition (0) mode
    if (!rotate) {
        setWidth(view[2]);
        setHeight(view[3]);
    } else {
        setWidth(view[3]);
        setHeight(view[2]);
    }
};

export const SET_PANEL_SIZE = 'SET_PANEL_SIZE';
export const RECENTLY_USED_STAMPS_KEY = 'recentlyUsedStamps_';
export const RECENTLY_USED_EXEMPTION_KEY = 'recentlyUsedExemptions_';
export const DEFAULT_SIDEBAR_WIDTH = 337;
export const MIN_PDF_PANEL_SIZE = 200;
export const MIN_RIGHT_PANEL_SIZE = 260;
export const MIN_LEFT_PANEL_SIZE = 200;
export const LOW_WINDOW_WIDTH = 1280;
export const LOW_WINDOW_HEIGHT = 1024;
export const LAST_USED_PRINTER_KEY = 'lastUsedPrinter';

export const INITIAL_BAR = {
    isCollapsed: false,
    barWidth: DEFAULT_SIDEBAR_WIDTH,
};

export const INITIAL_LAST_USED_PRINTER =  {
    printerCode: '',
    trayCode: '',
};

export const SET_ANNOTATION_COLOR = 'SET_ANNOTATION_COLOR';
export const DELETE_ANNOTATION_COLOR = 'DELETE_ANNOTATION_COLOR';
export const LEFT_PANEL = 'leftPanel';
export const RIGHT_PANEL = 'rightPanel';
export const TOP_LEFT_PANEL = 'topLeftPanel';
export const TOP_RIGHT_PANEL = 'topRightPanel';
export const ROTATED_PAGES = 'rotatedPages';
export const SET_LAST_USED_PRINTER = 'localstorage/SET_LAST_USED_PRINTER';
export const SET_RECENTLY_USED_STAMPS = 'reduction/SET_RECENTLY_USED_STAMPS';
export const SET_RECENTLY_USED_EXEMPTIONS = 'redaction/SET_RECENTLY_USED_EXEMPTIONS';
export const DUPLICATES_BOTTOM_PANEL = 'duplicatesBottomPanel';
export const SET_DUPLICATES_BOTTOM_PANEL_SIZE = 'localstorage/SET_DUPLICATES_BOTTOM_PANEL_SIZE';
export const SET_REDUCTION_LID = 'localstorage/SET_REDUCTION_LID';
export const REDUCTION_LID = 'redactionLID';
export const AUTH = 'auth';
export const SET_REDUCTION_AUTH = 'localStorage/SET_AUTH';
export const REDACTION_LANG = 'redactionLang';
export const SET_REDACTION_LANGUAGE = 'localstorage/SET_REDACTION_LANGUAGE';
export const REDACTION_MODE = 'redactionMode';
export const MODIFIED_LABELS = 'modifiedLabels';
export const SET_REDACTION_MODE = 'localstorage/SET_REDACTION_MODE';
export const MODE_FULL = 'Full';
export const MODE_CONTRIBUTE = 'Contribute';
export const MODE_CONSULT = 'Consult';


import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2962ff',
        },
        secondary: {
            main: '#de3a3a',
        },
    },

    components: {
        MuiButton: {
            // Name of the styleSheet
            styleOverrides: {
                root: {
                    // Name of the rule
                    borderRadius: 2,
                    border: 0,
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    lineHeight: 1.13,
                },
                sizeSmall: {
                    height: 32,
                    fontSize: 14,
                    paddingLeft: 14,
                    paddingRight: 14,
                },
                sizeLarge: {
                    height: 40,
                    fontSize: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                },
                containedPrimary: {
                    '&$disabled': {
                        backgroundColor: '#b2cffb',
                        color: '#fff',
                    },
                    'boxShadow': 'none',
                },
                outlined: {
                    'boxShadow': 'none',
                    'color': '#1f2d3d',
                    'border': 'solid 1px gray',
                    '&$disabled': {
                        backgroundColor: '#ffffff',
                        color: '#93a1b6',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                marginNormal: {
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: 40,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    marginTop: 10,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    'paddingLeft': 3,
                    'paddingRight': 3,
                    'marginLeft': -3,
                    'lineHeight': 1.4,
                    'background': 'white',
                    '&.Mui-focused': {
                        lineHeight: 1,
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginLeft: -3,
                        marginTop: 10,
                        background: 'white',
                    },
                },
                filled: {
                    lineHeight: 1,
                    paddingLeft: 3,
                    paddingRight: 3,
                    marginLeft: -3,
                    marginTop: 10,
                    background: 'white',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    lineHeight: 3.7,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    'marginTop': 10,
                    'marginBottom': 14,
                    'MuiSvgIcon': {
                        right: 20,
                    },
                    '&$error': {
                        borderColor: '#f44336'
                    },
                    '&:hover &.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e0e6ed',
                    },
                    '&.Mui-focused &.MuiOutlinedInput-notchedOutline': {
                        borderWidth: 3,
                    },
                },
                input: {
                    paddingTop: 18.5,
                },
                multiline: {
                    height: 75,
                },
                notchedOutline: {
                    borderRadius: 0,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&$error': {
                        fontSize: 10,
                    },
                },
                contained: {
                    marginTop: 2,
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    padding: 6,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: -8,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    opacity: 1,
                    backgroundColor: '#c0ccda',
                },
            },
        },
    },
});

export default theme;

import {
    GET_SEARCH_REDACTION_DOCUMENTS_PENDING,
    GET_SEARCH_REDACTION_DOCUMENTS_FAILURE,
    GET_SEARCH_REDACTION_DOCUMENTS_SUCCESS,
    GET_FOI_FOLDER_TYPES_PENDING,
    GET_FOI_FOLDER_TYPES_SUCCESS,
    GET_FOI_FOLDER_TYPES_FAILURE,
    CLEAR_SEARCH_REDACTION_DOCUMENTS,
    GET_IMPORT_REDACTION_DOCUMENT_PENDING,
    GET_IMPORT_REDACTION_DOCUMENT_SUCCESS,
    GET_IMPORT_REDACTION_DOCUMENT_FAILURE,
    GET_AMANDA_ATTACHMENTS_FAILURE,
    GET_AMANDA_ATTACHMENTS_PENDING,
    GET_AMANDA_ATTACHMENTS_SUCCESS,
    GET_AMANDA_EDMS_FILES_PENDING,
    GET_AMANDA_EDMS_FILES_SUCCESS,
    GET_AMANDA_EDMS_FILES_FAILURE,
    GET_AMANDA_EDMS_ADAPTORS_SUCCESS,
    GET_AMANDA_EDMS_ADAPTORS_FAILURE,
    GET_AMANDA_EDMS_ADAPTORS_PENDING,
} from './constants';
import { IModalSearchDocuments } from './searchDocuments.model';
import { AnyAction } from 'redux';

const initialState: IModalSearchDocuments = {
    selectedDocumentsPending: false,
    selectedDocuments: [],
    amandaAttachmentsPending: false,
    amandaAttachments: [],
    selectedDocumentsRedactionId: null,
    searchDocuments: [],
    searchDocumentsPending: false,
    foiFolders: [],
    foiFolderPending: false,
    edmsAdaptorsPending: false,
    edmsAdaptors: [],
    edmsFiles: [],
    edmsFilesPending: false,
    error: null,
};

const disclosureContactsReducer = (
    state: IModalSearchDocuments = initialState,
    { type, payload }: AnyAction,
): IModalSearchDocuments => {
    switch (type) {
        case GET_SEARCH_REDACTION_DOCUMENTS_PENDING:
            return {
                ...state,
                searchDocumentsPending: true,
                error: null,
            };
        case GET_SEARCH_REDACTION_DOCUMENTS_SUCCESS:
            return {
                ...state,
                searchDocuments: payload,
                searchDocumentsPending: false,
            };
        case GET_SEARCH_REDACTION_DOCUMENTS_FAILURE:
            return {
                ...state,
                error: payload,
                searchDocumentsPending: false,
            };
        case CLEAR_SEARCH_REDACTION_DOCUMENTS:
            return initialState;

        case GET_FOI_FOLDER_TYPES_PENDING:
            return {
                ...state,
                foiFolderPending: true,
                error: null,
            };
        case GET_FOI_FOLDER_TYPES_SUCCESS:
            return{
                ...state,
                foiFolderPending: false,
                foiFolders: payload,
            };
        case GET_FOI_FOLDER_TYPES_FAILURE:
            return{
                ...state,
                foiFolderPending: false,
                error: payload,
            };

        case GET_IMPORT_REDACTION_DOCUMENT_PENDING:
            return {
                ...state,
                selectedDocumentsPending: true,
                error: null,
            };
        case GET_IMPORT_REDACTION_DOCUMENT_SUCCESS:
            return{
                ...state,
                selectedDocumentsPending: false,
                selectedDocuments: payload.documents,
                selectedDocumentsRedactionId: payload.id,
            };
        case GET_IMPORT_REDACTION_DOCUMENT_FAILURE:
            return{
                ...state,
                selectedDocumentsPending: false,
                error: payload,
            };

        case GET_AMANDA_EDMS_ADAPTORS_PENDING:
            return {
                ...state,
                edmsAdaptorsPending: true,
                error: null,
            };
        case GET_AMANDA_EDMS_ADAPTORS_SUCCESS:
            return {
                ...state,
                edmsAdaptorsPending: false,
                edmsAdaptors: payload,
            };

        case GET_AMANDA_EDMS_ADAPTORS_FAILURE:
            return {
                ...state,
                edmsAdaptorsPending: false,
                error: payload,
            };

        case GET_AMANDA_EDMS_FILES_PENDING:
            return {
                ...state,
                edmsFilesPending: true,
                error: null,
            };
        case GET_AMANDA_EDMS_FILES_SUCCESS:
            return {
                ...state,
                edmsFiles: payload,
                edmsFilesPending: false,
            };
        case GET_AMANDA_EDMS_FILES_FAILURE:
            return {
                ...state,
                edmsFilesPending: false,
                error: payload,
            };

        case GET_AMANDA_ATTACHMENTS_PENDING:
            return {
                ...state,
                amandaAttachmentsPending: true,
                error: null,
            };
        case GET_AMANDA_ATTACHMENTS_SUCCESS:
            return{
                ...state,
                amandaAttachmentsPending: false,
                amandaAttachments: payload,
            };
        case GET_AMANDA_ATTACHMENTS_FAILURE:
            return{
                ...state,
                amandaAttachmentsPending: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default disclosureContactsReducer;

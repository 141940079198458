import { AnyAction } from 'redux';
import { IDocumentIndexState } from './documentIndex.model';
import {
    FETCH_DOCUMENT_INDEX_PENDING,
    FETCH_DOCUMENT_INDEX_SUCCESS,
    FETCH_DOCUMENT_INDEX_FAIL,
    SET_DOCUMENT_INDEX_VALUE,
    CLEAR_DOCUMENT_INDEX,
} from './constant';
import { IIndex } from '../indexMetadata/indexMetadata.model';

export const initialState: IDocumentIndexState = {
    pending: false,
    error: null,
    documentIndex: [],
};
export const documentIndexReducer =
    (state: IDocumentIndexState = initialState, { type, payload }: AnyAction): IDocumentIndexState => {
    switch (type) {
        case FETCH_DOCUMENT_INDEX_PENDING:
            return {
                ...state,
                pending: true,
            };
        case FETCH_DOCUMENT_INDEX_FAIL:
            return {
                ...state,
                pending: false,
                error: payload,
            };
        case FETCH_DOCUMENT_INDEX_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                documentIndex: payload,
            };
        case SET_DOCUMENT_INDEX_VALUE:
            return {
                ...state,
                pending: false,
                error: null,
                documentIndex: state.documentIndex.map((index: IIndex) => {
                    return index.id === payload.id ? {
                        ...index,
                        value: payload.value,
                    } : index;
                }),
            };
        case CLEAR_DOCUMENT_INDEX:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default documentIndexReducer;

import * as React from 'react';
import { IShapeProps } from './shapesLayer.model';
import { Vector2d } from 'konva/types/types';
import { Text, Label, Tag } from 'react-konva';
import { dragBoundHandler } from '../../utils/konva.utils';
import { STAMP_LINE_HEIGHT, STAMP_FONT_FAMILY } from '../../constants/annotation.constants';

export const LabelComponent = ({
    width,
    height,
    x1,
    y1,
    x2,
    y2,
    value,
    color,
    name,
    dragHandler,
    text,
    isDraggable,
    container,
    font,
   // rotate it's shape rotate, not page rotate
    rotate,
    pageRotation,
    stroke,
}: IShapeProps): JSX.Element => {
    return (
    <Label
        key={x1 + y1 + x2 + y2}
        x={x1}
        y={y1}
        name={name}
        draggable={isDraggable}
        onDragEnd={dragHandler}
        rotation={rotate}
        dragBoundFunc={(pos: Vector2d): Vector2d =>
            dragBoundHandler(container, width, height, pos, pageRotation, !!rotate && Math.abs(rotate) === 90, rotate)}
    >
        <Tag
            stroke={!!stroke ? stroke : '#000'}
            dash={[2,2]}
            fill={color}
            strokeEnabled={true}
        />
        <Text
            x={x1}
            y={y1}
            name={name}
            value={value}
            width={width}
            height={height}
            text={text}
            fill={'#000'}
            fontSize={font}
            align='center'
            verticalAlign='middle'
            fontFamily={STAMP_FONT_FAMILY}
            lineHeight={STAMP_LINE_HEIGHT}
        />
    </Label>
);
        };

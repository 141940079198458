export const GET_DOCUMENT_TASKS_PENDING = 'reduction/GET_DOCUMENT_TASKS_PENDING';
export const GET_DOCUMENT_TASKS_SUCCESS = 'reduction/GET_DOCUMENT_TASKS_SUCCESS';
export const GET_DOCUMENT_TASKS_FAILURE = 'reduction/GET_DOCUMENT_TASKS_FAILURE';

export const DELETE_DOCUMENT_TASKS_PENDING = 'reduction/DELETE_DOCUMENT_TASKS_PENDING';
export const DELETE_DOCUMENT_TASKS_SUCCESS = 'reduction/DELETE_DOCUMENT_TASKS_SUCCESS';
export const DELETE_DOCUMENT_TASKS_FAILURE = 'reduction/DELETE_DOCUMENT_TASKS_FAILURE';

export const UPDATE_DOCUMENT_TASKS_PENDING = 'reduction/UPDATE_DOCUMENT_TASKS_PENDING';
export const UPDATE_DOCUMENT_TASKS_SUCCESS = 'reduction/UPDATE_DOCUMENT_TASKS_SUCCESS';
export const UPDATE_DOCUMENT_TASKS_FAILURE = 'reduction/UPDATE_DOCUMENT_TASKS_FAILURE';

import { action } from 'typesafe-actions';
import {
    CHANGE_PAGE_ORDER_FAILURE,
    CHANGE_PAGE_ORDER_PENDING,
    CHANGE_PAGE_ORDER_SUCCESS,
    CLEAR_CURRENT_DOCUMENT_STATE,
    DELETE_PAGE_FAILURE,
    DELETE_PAGE_PENDING,
    DELETE_PAGE_SUCCESS,
    UNDO_PAGINATION_PAGE,
    GET_CURRENT_DOCUMENT_FAILURE,
    GET_CURRENT_DOCUMENT_PENDING,
    GET_CURRENT_DOCUMENT_SUCCESS,
    GET_CURRENT_PAGE_SUCCESS,
    SET_DOCUMENT_TO_SELECT_LIST,
    SET_PAGE_TO_SELECT_LIST,
    UNDO_PAGE_DISCLOSURE_FAILURE,
    UNDO_PAGE_DISCLOSURE_PENDING,
    UNDO_PAGE_DISCLOSURE_SUCCESS,
    GET_PAGE_IN_DOCUMENT_PENDING,
    GET_PAGE_IN_DOCUMENT_SUCCESS,
    GET_PAGE_IN_DOCUMENT_FAILURE,
    CHANGE_PAGE_EXEMPTIONS_SUCCESS,
    REMOVE_PAGE_EXEMPTIONS_SUCCESS,
    SET_ROTATE_IN_DOCUMENT_LIST,
    UPDATE_PAGINATION,
    OCR_PAGES_PENDING,
    OCR_PAGES_SUCCESS,
    OCR_PAGES_FAILURE,
    SET_ALL_DOCUMENTS_TO_SELECT_LIST,
    SET_ALL_PAGES_TO_SELECT_LIST,
    CLEAR_CURRENT_PAGE_STATE,
    GET_EXEMPTION_CURRENT_DOCUMENT_SUCCESS,
    GET_EXEMPTION_CURRENT_DOCUMENT_PENDING,
    GET_EXEMPTION_CURRENT_DOCUMENT_FAILURE,
    SET_PAGE_LIST_PENDING,
    SET_PAGE_LIST_FAILURE,
    SET_PAGE_LIST_SUCCESS, SET_SORT_PARAM, SET_PAGE_NUMBER,
    SET_PAGE_INITIAL_STATE,
    CHANGE_PAGES_DISCLOSURE_PENDING,
    SET_PAGE_LIST_COLUMN_HEADER_PENDING,
    SET_PAGE_LIST_COLUMN_HEADER_LIST_SUCCESS,
    SET_PAGE_LIST_COLUMN_HEADER_FAILURE,
    SET_PAGE_LIST_TABLE_COLUMN,
    MODIFY_PAGE_LIST_COLUMN_HEADER_PENDING,
    MODIFY_PAGE_LIST_COLUMN_HEADER_SUCCESS,
    MODIFY_PAGE_LIST_COLUMN_HEADER_FAILURE,
    RESET_PAGE_LIST_COLUMN_HEADER_FAILURE,
    RESET_PAGE_LIST_COLUMN_HEADER_SUCCESS,
    RESET_PAGE_LIST_COLUMN_HEADER_PENDING,
    EDIT_DOCUMENT_FILE_NAME_PENDING,
    EDIT_DOCUMENT_FILE_NAME_SUCCESS,
    EDIT_DOCUMENT_FILE_NAME_FAILURE,
    UPDATE_CURRENT_DOCUMENT_EDITED_FILE_NAME,
    HAS_LINKED_ARTICLES_FAILURE,
    HAS_LINKED_ARTICLES_PENDING,
    HAS_LINKED_ARTICLES_SUCCESS,
    APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_PENDING,
    APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_SUCCESS,
    APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_FAILURE,
    SET_FIRST_PAGE,
    GET_CURRENT_PAGE_COMMENTS_FAILURE,
    GET_CURRENT_PAGE_COMMENTS_PENDING,
    GET_CURRENT_PAGE_COMMENTS_SUCCESS,
    SET_PAGE_COUNT_AFTER_DELETION,
    GET_CURRENT_PAGE_HYPER_LINKS_PENDING,
    GET_CURRENT_PAGE_HYPER_LINKS_FAILURE,
    GET_CURRENT_PAGE_HYPER_LINKS_SUCCESS,
    SET_SELECTED_COMMENT,
    SET_COMMENT_LOADING_FLAG,
    SET_GOTO_PAGE_PENDING,
    SET_CURRENT_FIRST_PAGE_ID,
    SET_CURRENT_LAST_PAGE_ID,
} from '../reducers/pageList/constant';
import {
    ALERT_DIALOG_MODAL,
    PAGINATION_RECOMMENDED_TITLE,
    PAGINATION_RECOMMENDED_MESSAGE,
    SEVERAL_PAGES_SELECTED_WARNING,
    CONFIRMATION_DIALOG_MODAL,
    DELETE_DOCUMENT_PER_PAGE_MESSAGE,
    DELETE_PAGE_MESSAGE,
    DELETE_PAGE_TITLE,
    DISCLOSURE_WARNING,
    CHANGE_DISCLOSURE_WARNING,
    WARNING, AUTO_DESKEW_PAGE_MESSAGE, AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL,
    NO_BUTTON, YES_BUTTON, AUTO_ALIGN_ARTICLE_STAMPS_PAGE_ALERT_MESSAGE,
    DELETE_COMMENT_TITLE, DELETE_COMMENT_MESSAGE, SORTING_NOT_APPLICABLE_ON_MOVE_WARNING,
} from '../../constants/messages.constants';
import api from '../../api/reductionApi';
import {
    cleanSelectedShapes, clearOptionsState,
    getPageNumberByPageId,
    getPageResource, setLinkedAnnotationId, setLinkedArticlesFlag,
} from './redactor';
import { highlightLinkedSever } from './annotations';
import { clearLinkedArticlesForAllAnnotations, getPageStamps, highlightLinkedArticles, removeStampsPerPage } from './stamps';
import { removeAnnotationsPerPage } from './annotations';
import {
    deleteDocument,
    updateDocumentPageCount,
    setPaginationForExectDocuments,
    updatePaginationDocument,
    updateInfoDocumentInDocumentList,
    fetchDocumentList,
    fetchStackDocumentsList,
} from './documentList';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from './modal';
import { addError } from './errorHandling';
import { IModalIdsData, IModalSpecificProps } from '../reducers/modal/modal.model';
import { AnyAction, Dispatch } from 'redux';
import {
    IDocument,
    IHyperLink,
    IPages,
    IUndoDisclosure,
    IUndoDocDisclosure } from '../reducers/pageList/pageList.model';
import { IState, ThunkResult } from '../store';
import { IUpdatedPages, IHeader } from '../../containers/leftTopBar/leftTopBar.model';
import { IError } from '../common.model';
import { IDisclosureOptions } from '../reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import { NOT_APPLICABLE, PAGE, SORT_KEYS, STAMP } from '../../constants/common.constants';
import { ARTICLE_STAMPS_MODAL, SELECT_CONSTANT_MODAL } from '../../containers/modalWindowContents';
import { IAssignedContactsDto } from '../reducers/disclosureContacts/disclosureContacts.model';
import { IFile, IOcrDocPage } from '../reducers/documentList/documentList.model';
import { GoToPage as GoToPageEnum } from '../../components/navigation/Navigation';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { restartDocumentTasksQueue } from './taskQueue';
import { fetchDocumentIndexPending, fetchDocumentIndexFailure, fetchDocumentIndexSuccess } from './documentIndex';
import { mergeIndexArrayWithMetadata } from '../../utils/indexMetadata';
import { applySearchPending, setSearchCurrentPageId } from './globalSearch';
import { findIndex, sortBy } from 'lodash';
import { getPageConsultee, clearPageConsultees } from './pageMetadata';
import { DOCUMENT_LIST_CONTEXT_MENU, PAGE_LIST_CONTEXT_MENU } from '../../constants/contextmenu/context.menu.constants';
import { IAdditionalArticleStamp } from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { changeDocumentDisclosurePending, changeDocumentDisclosureComplete } from './documentList';
import { setDefaultSortOrder, SortingRule } from '../../containers/leftBottomBar/LeftBottomBar';
import { IComment } from '../../containers/comment/comment.model';
import { changeLang } from './localization';
import resourceBundle from '../../containers/localization/localizationData';
import { initialLabel } from '../../constants/localization.constants';
import { getConsulteesLabelsByKey } from '../../containers/IndexMetadataConsultees/IndexMetadataConsultees';

export enum MovePage {
    DOWN = 'down',
    UP = 'up',
}

export const changePagesDisclosurePending = (pageIds: number[]): AnyAction =>
    action(CHANGE_PAGES_DISCLOSURE_PENDING, pageIds);
export const setPageInitialState = (): AnyAction => action(SET_PAGE_INITIAL_STATE);
export const getCurrentDocumentPending = (): AnyAction => action(GET_CURRENT_DOCUMENT_PENDING);
export const getCurrentDocumentSuccess = (file: IDocument): AnyAction => action(GET_CURRENT_DOCUMENT_SUCCESS, file);
export const getCurrentDocumentFailure = (): AnyAction => action(GET_CURRENT_DOCUMENT_FAILURE);
export const getPageInDocumentPending = (): AnyAction => action(GET_PAGE_IN_DOCUMENT_PENDING);
export const getPageInDocumentSuccess = (page: IPages): AnyAction => action(GET_PAGE_IN_DOCUMENT_SUCCESS, page);
export const getPageInDocumentFailure = (): AnyAction => action(GET_PAGE_IN_DOCUMENT_FAILURE);
export const getCurrentPageSuccess = (id: number): AnyAction => action(GET_CURRENT_PAGE_SUCCESS, id);
export const changePageOrderPending = (): AnyAction => action(CHANGE_PAGE_ORDER_PENDING);
export const changePageOrderSuccess = (pages: IPages[], currentPage: IPages): AnyAction =>
    action(CHANGE_PAGE_ORDER_SUCCESS, { pages, currentPage });
export const changePageOrderFailure = (): AnyAction => action(CHANGE_PAGE_ORDER_FAILURE);
export const setDocumentsRangeToSelected = (ids: number[]): AnyAction => action(SET_ALL_DOCUMENTS_TO_SELECT_LIST, ids);

export const updatePaginationPageList = (file: IDocument): AnyAction => action(UPDATE_PAGINATION, file);

export const deletePagePending = (): AnyAction => action(DELETE_PAGE_PENDING);
export const deletePageSuccess = (ids: number[]): AnyAction => action(DELETE_PAGE_SUCCESS, ids);
export const deletePageFailure = (): AnyAction => action(DELETE_PAGE_FAILURE);

export const undoPageDisclosurePending = (): AnyAction => action(UNDO_PAGE_DISCLOSURE_PENDING);
export const undoPageDisclosureSuccess = (): AnyAction =>
    action(UNDO_PAGE_DISCLOSURE_SUCCESS);
export const undoPageDisclosureFailure = (error: IError): AnyAction => action(UNDO_PAGE_DISCLOSURE_FAILURE, error);

export const undoPaginationPage = (): AnyAction => action(UNDO_PAGINATION_PAGE);

export const setPageListPending = (): AnyAction => action(SET_PAGE_LIST_PENDING);
export const setPageListSuccess = (pages: IPages[]): AnyAction => action(SET_PAGE_LIST_SUCCESS, pages);
export const setPageListFailure = (): AnyAction => action(SET_PAGE_LIST_FAILURE);

export const clearCurrentDocumentState = (): AnyAction => action(CLEAR_CURRENT_DOCUMENT_STATE);
export const clearCurrentPageState = (): AnyAction => action(CLEAR_CURRENT_PAGE_STATE);

export const getExemptionCurrentDocumentPending = (): AnyAction => action(GET_EXEMPTION_CURRENT_DOCUMENT_PENDING);
export const getExemptionCurrentDocumentSuccess = (exemptions: IAdditionalArticleStamp[]): AnyAction =>
    action(GET_EXEMPTION_CURRENT_DOCUMENT_SUCCESS, exemptions);
export const getExemptionCurrentDocumentFailure = (): AnyAction => action(GET_EXEMPTION_CURRENT_DOCUMENT_FAILURE);

export const setDocumentToSelectedList = (id: number, isMultiple: boolean): AnyAction =>
    action(SET_DOCUMENT_TO_SELECT_LIST, { id, isMultiple });

export const setDocumentsRangeToSelectedList = (id: number, files: IFile[], selectedDocumentsIds: number[]):
    (dispatch: Dispatch) => void => (dispatch: Dispatch): void => {
        const lastSelected = selectedDocumentsIds[selectedDocumentsIds.length - 1];

        const [start, end]: number[] = files.reduce((res: number[], file: IFile, i: number) => {
            return file.id === lastSelected || file.id === id ? [...res, i] : res;
        }, []);

        const selectedIds = files.slice(start, end + 1).map((file: IFile) => file.id);

        dispatch(setDocumentsRangeToSelected(selectedIds));
    };

export const setPageToSelectedList = (id: number, isMultiple: boolean): AnyAction =>
    action(SET_PAGE_TO_SELECT_LIST, { id, isMultiple });
export const setPagesRangeToSelected = (ids: number[]): AnyAction => action(SET_ALL_PAGES_TO_SELECT_LIST, ids);

export const changePageExemptionSuccess = (id: number, stampsIds: number[]): AnyAction =>
    action(CHANGE_PAGE_EXEMPTIONS_SUCCESS, { stampsIds, id });

export const removePageExemptionSuccess = (id: number, stampsIds: number[]): AnyAction =>
    action(REMOVE_PAGE_EXEMPTIONS_SUCCESS, { stampsIds, id });

export const setPageNumber = (pageNumber: number): AnyAction => action(SET_PAGE_NUMBER, pageNumber);
export const setSortParam = (sortParam: SortingRule): AnyAction => action(SET_SORT_PARAM, sortParam);

export const ocrPagesPending = (pageIds: number[]): AnyAction => action(OCR_PAGES_PENDING, pageIds);
export const ocrPagesSuccess = (): AnyAction => action(OCR_PAGES_SUCCESS);
export const ocrPagesFailure = (): AnyAction => action(OCR_PAGES_FAILURE);

export const setPageRotateInDocumentInfo = (id: number, rotation: number): AnyAction =>
    action(SET_ROTATE_IN_DOCUMENT_LIST, { id, rotation });

export const setPageListColumnHeaderPending = (): AnyAction =>
    action(SET_PAGE_LIST_COLUMN_HEADER_PENDING);
export const setPageListColumnHeaderSuccess = (pageListColumnsHeaders: IHeader[]): AnyAction =>
    action(SET_PAGE_LIST_COLUMN_HEADER_LIST_SUCCESS, pageListColumnsHeaders);
export const setPageListColumnHeaderFailure = (error: IError): AnyAction =>
    action(SET_PAGE_LIST_COLUMN_HEADER_FAILURE, error);

export const modifyPageListColumnHeaderPending = (): AnyAction =>
    action(MODIFY_PAGE_LIST_COLUMN_HEADER_PENDING);
export const modifyPageListColumnHeaderSuccess = (pageListColumnsHeaders: IHeader[]): AnyAction =>
    action(MODIFY_PAGE_LIST_COLUMN_HEADER_SUCCESS, pageListColumnsHeaders);
export const modifyPageListColumnHeaderFailure = (error: IError): AnyAction =>
    action(MODIFY_PAGE_LIST_COLUMN_HEADER_FAILURE, error);

export const setPageListTableColumns = (columns: IHeader[]): AnyAction => {
    return action(SET_PAGE_LIST_TABLE_COLUMN, columns);
};

export const resetPageListColumnHeaderPending = (): AnyAction =>
    action(RESET_PAGE_LIST_COLUMN_HEADER_PENDING);
export const resetPageListColumnHeaderSuccess = (pageListColumnsHeaders: IHeader[]): AnyAction =>
    action(RESET_PAGE_LIST_COLUMN_HEADER_SUCCESS, pageListColumnsHeaders);
export const resetPageListColumnHeaderFailure = (error: IError): AnyAction =>
    action(RESET_PAGE_LIST_COLUMN_HEADER_FAILURE, error);

export const editFileNamePending = (): AnyAction => action(EDIT_DOCUMENT_FILE_NAME_PENDING);
export const editFileNameSuccess = (): AnyAction =>
    action(EDIT_DOCUMENT_FILE_NAME_SUCCESS);
export const editFileNameFailure = (): AnyAction => action(EDIT_DOCUMENT_FILE_NAME_FAILURE);
export const updateCurrentDocumentEditedFileName = (fielName: string): AnyAction =>
    action(UPDATE_CURRENT_DOCUMENT_EDITED_FILE_NAME, fielName);

export const hasLinkedArticlesPending = (): AnyAction => action(HAS_LINKED_ARTICLES_PENDING);
export const hasLinkedArticlesSuccess = (hasLinkedArticle: boolean): AnyAction =>
    action(HAS_LINKED_ARTICLES_SUCCESS, hasLinkedArticle);
export const hasLinkedArticlesFailure = (): AnyAction => action(HAS_LINKED_ARTICLES_FAILURE);
export const applyPageAutoAlignArticleStampsPending = (): AnyAction =>
    action(APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_PENDING);
export const applyPageAutoAlignArticleStampsSuccess = (): AnyAction =>
    action(APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_SUCCESS);
export const applyPageAutoAlignArticleStampsFailure = (error: IError): AnyAction =>
    action(APPLY_PAGE_AUTO_ALIGN_ARTICLE_STAMPS_FAILURE, error);

export const setFirstPage = (firstPageId: number): AnyAction =>
    action(SET_FIRST_PAGE, firstPageId);

export const getCurrentPageCommentsPending = (): AnyAction => action(GET_CURRENT_PAGE_COMMENTS_PENDING);
export const getCurrentPageCommentsSuccess = (pdfComments: IComment): AnyAction =>
    action(GET_CURRENT_PAGE_COMMENTS_SUCCESS, pdfComments);
export const getCurrentPageCommentsFailure = (): AnyAction => action(GET_CURRENT_PAGE_COMMENTS_FAILURE);

export const getCurrentPageHyperLinkPending = (): AnyAction => action(GET_CURRENT_PAGE_HYPER_LINKS_PENDING);
export const getCurrentPageHyperLinkSuccess = (hyperLinks: IHyperLink): AnyAction =>
    action(GET_CURRENT_PAGE_HYPER_LINKS_SUCCESS, hyperLinks);
export const getCurrentPageHyperLinkFailure = (): AnyAction => action(GET_CURRENT_PAGE_HYPER_LINKS_FAILURE);

export const setPageCountAfterDeletion = (deletedPageCounts: number): AnyAction =>
    action(SET_PAGE_COUNT_AFTER_DELETION, deletedPageCounts);

export const setSelectedCommentInCurrentPage = (comment: null | IComment): AnyAction =>
action(SET_SELECTED_COMMENT, comment);

export const setGotoPagePendingFlag = ( flag: boolean ): AnyAction =>
    action(SET_GOTO_PAGE_PENDING, flag);

export const setCurrentFirstOrLastPage = ( type: string, id: number ): AnyAction =>
    action(type, id);

export const fetchDocumentMetadata = (
    redactionDocumentId: number,
    documentId: number,
    pageId?: number,
    searchUpdate?: boolean,
    fromSearch?: boolean,
): any =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        dispatch(getCurrentDocumentPending());
        dispatch(fetchDocumentIndexPending());

        try {
            const response = await api.redactionDocuments.getDocumentMetadata(redactionDocumentId, documentId);

            dispatch(getCurrentDocumentSuccess(response));

            if (!fromSearch) {
                const { pages: [{ id: firstPageId, layers, rotation }] } = response;
                const neededPageId = pageId || firstPageId;
                const selectedPage = response.pages.filter((page: IPages): boolean => page.id === pageId);
                const rotate = pageId ?
                    (selectedPage && selectedPage.length && selectedPage[0].rotation) || 0 :
                    rotation;

                if (!pageId) {
                    dispatch(setFirstPage(firstPageId));
                } else {
                    dispatch(setFirstPage(null));
                }

                searchUpdate ? dispatch(getPageResource(redactionDocumentId, documentId, neededPageId, rotate))
                    : dispatch(getPageResource(redactionDocumentId, documentId, neededPageId, rotate, layers[0].id));

                dispatch(fetchPaginatedPages(0));
                dispatch(getPageConsultee(redactionDocumentId, documentId, neededPageId));
            }

            dispatch(updateInfoDocumentInDocumentList(response));
        } catch (error) {
            dispatch(getCurrentDocumentFailure());
            dispatch(addError(error));
        }

        try {
            const response = await api.documentIndex.getDocumentIndex(redactionDocumentId, documentId);
            const {
                indexMetadata: {
                    metadata,
                },
            } = getState();

            dispatch(fetchDocumentIndexSuccess(mergeIndexArrayWithMetadata(response, metadata)));
        } catch (error) {
            dispatch(fetchDocumentIndexFailure(error));
            dispatch(addError(error));
        }
    };

export const setPagesOrder = (
    redactionDocumentId: number,
    documentId: number,
    data: IUpdatedPages,
): any =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            pageList: { currentPage, currentDocument, currentPageSort },
            documentList: { files },
        } = getState();
        const currentFile = files.find((file: IFile) => file.id === documentId);

        if(data.pageNumber < 1  || data.pageNumber > currentDocument.pageCount) {
            dispatch(setGotoPagePendingFlag(false));

            return;
        }

        dispatch(changePageOrderPending());

        const currentPageNumber =
            Math.floor((currentPageSort.desc ? currentDocument.pageCount - data.pageNumber : data.pageNumber-1)/50);
        const sort = currentPageSort && currentPageSort.id
            ? `&sort=${SORT_KEYS[currentPageSort.id]},${currentPageSort.desc ? 'desc' : 'asc'}`
            : '';

        try {
            const responsePages = await api.pages.movePagesInDocument(redactionDocumentId, documentId, [data]);
            const fetchedPages =
                await api.pages.fetchPaginatedPages(
                    redactionDocumentId, currentDocument.id, currentPageNumber, sort);
            const currentPageToSet = fetchedPages.find((page: IPages) =>
            page.id === currentPage.id);

            dispatch(changePageOrderSuccess(fetchedPages, currentPageToSet));
            dispatch(updateInfoDocumentInDocumentList({ ...currentFile, pages: responsePages }));
            dispatch(setSearchCurrentPageId(currentPage.id));
            dispatch(fetchPaginatedPages(currentPageNumber));
            dispatch(setGotoPagePendingFlag(false));
        } catch (error) {
            dispatch(changePageOrderFailure());
            dispatch(addError(error));
        }
    };

export const selectAllPages = (): (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const { pageList: { currentDocument: { pages } } } = getState();
        const pageIds = pages.map((page: IPages) => page.id);

        dispatch(setPagesRangeToSelected(pageIds));
    };

export const setPagesRangeToSelectedList = (id: number, pages: IPages[], selectedPagesIds: number[]):
    (dispatch: Dispatch) => void => (dispatch: Dispatch): void => {
        const lastSelected = selectedPagesIds[selectedPagesIds.length - 1];

        const [start, end]: number[] = pages.reduce((res: number[], page: IPages, i: number) => {
            return page.id === lastSelected || page.id === id ? [...res, i] : res;
        }, []);

        const selectedIds = pages.slice(start, end + 1).map((page: IPages) => page.id);

        dispatch(setPagesRangeToSelected(selectedIds));
    };

export const changeDocumentOrder = (
    redactionDocumentId: number,
    documentId: number,
    pageList: IUpdatedPages[],
    pageNumber: number,
):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { pageList: { currentPage }, documentList: { files } } = getState();
        const currentFile = files.find((file: IFile) => file.id === documentId);

        dispatch(changePageOrderPending());

        try {
            const response = await api.pages.movePages(redactionDocumentId, documentId, pageList, pageNumber);
            const pages = [...response].sort((a: IPages, b: IPages) => a.actualPageNumber - b.actualPageNumber);

            dispatch(changePageOrderSuccess(
                pages,
                currentPage));
            dispatch(updateInfoDocumentInDocumentList({ ...currentFile, pages }));
            dispatch(fetchPaginatedPages(pageNumber));
        } catch (error) {
            dispatch(changePageOrderFailure());
            dispatch(addError(error));
        }
    };

export const confirmDeletePage = ({ documentIds, pageIds, currentDocId }: IModalIdsData):
    (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {

        const {
            pageList: { currentDocument: { pages,pageCount } },
            reductionMetaData: { redactionDocumentId },
            localStorage: {language},
            localization: { modifiedLabels}
        } = getState();
        const langRule = changeLang(language);
        const getdeleteLabelsByKey = (key: string): string => {
            switch (key) {
                case 'PAGE_LIST_DELETE_PAGE_MESSAGE':
                    return 'deletePageMessage';
                case 'PAGE_LIST_DELETE_PAGE':
                    return 'deletePageTitle';
                case 'DOCUMENT_LIST_DELETE_MESSAGE':
                    return 'deleteDocumentMessage'
        }  
    }      
        const labels = {
            deletePageMessage: initialLabel,
            deletePageTitle: initialLabel,
            deleteDocumentMessage: initialLabel,
        }
        resourceBundle.map((resource: any) => {
            if (getdeleteLabelsByKey(resource.resourceKey)) {
                labels[getdeleteLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getdeleteLabelsByKey(resource.resourceKey)) {
                labels[getdeleteLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        const resultCountOfPages = pageCount-pageIds.length;
        const deletedAndPaginatedPages = pages.filter(
            (page: IPages) => pageIds.includes(page.id) && !!page.paginationIndex,
        );
        const message = resultCountOfPages !== 0 ? labels.deletePageMessage[langRule] : labels.deleteDocumentMessage[langRule];

        dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: DELETE_PAGE_TITLE,
            title: labels.deletePageTitle[langRule],
            message,
            confirm: (): (dispatch: Dispatch, getState: () => IState) => void =>
            resultCountOfPages !== 0 ?
                    deletePage(redactionDocumentId, currentDocId, pageIds, !!deletedAndPaginatedPages.length) :
                    deleteDocument(documentIds),
        }));
    };

export const changeDisclosure = ({ pageIds, documentIds, redactionDocumentId, disclosureTypeId }: IModalIdsData):
    (dispatch: Dispatch<any>, getState: () => IState) => void =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const {
            documentList: { files },
            disclosureTypes: { disclosureType },
            contextMenu: { contextMenuType },
            localStorage: { language },
            localization: { modifiedLabels },
        } = getState();
        const langRule = changeLang(language);
        const labels = { consultessModalTitleLabel: initialLabel,
            changeDisclosureWarning:initialLabel,
            warningLabel:initialLabel };

        resourceBundle.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        try {
            const currentDisclosureObject = disclosureType
                .find((disclosure: IDisclosureOptions): boolean => disclosure.id === disclosureTypeId);

            if (currentDisclosureObject && pageIds) { // page level
                const { contacts } = currentDisclosureObject;
                const isContactsRequired = !!contacts && !!contacts.length;

                if (isContactsRequired && !currentDisclosureObject.isExemptionRequired) {
                    dispatch(openModalWindow(SELECT_CONSTANT_MODAL, {
                        id: SELECT_CONSTANT_MODAL,
                        title: labels.consultessModalTitleLabel[langRule],
                        modalSpecificProps: {
                            redactionDocumentId,
                            documentId: documentIds,
                            disclosureTypeId,
                            pageIds,
                        },
                    }));

                    return;
                }

                if (currentDisclosureObject.isExemptionRequired) {
                    const articleModalSpecificData = {
                        redactionDocumentId,
                        documentId: documentIds,
                        disclosureTypeId,
                        pageIds,
                    };

                    dispatch(openModalWindow(ARTICLE_STAMPS_MODAL, {
                        id: STAMP,
                        modalSpecificProps: {
                            setRequiredExemptions: true,
                            articleModalSpecificData,
                            isContactsRequired,
                        },
                    }));

                    return;
                }

                // simple disclosure for pages without exemption and contacts
                const pageDisclosureDto = {
                    disclosureTypeId,
                    pageIds,
                    exemptionIds: [],
                };

                dispatch(changePagesDisclosurePending(pageIds));

                const response = await api.pages.updatePageDisclosure(
                    redactionDocumentId,
                    documentIds[0],
                    pageDisclosureDto,
                );

                dispatch(fetchPaginatedPagesSilent());
                dispatch(updateInfoDocumentInDocumentList(response));
                dispatch(changeDocumentDisclosureComplete(documentIds));
            }

            if (!pageIds || contextMenuType === DOCUMENT_LIST_CONTEXT_MENU) { // document level
                const { contacts } = currentDisclosureObject;
                const isContactsRequired = !!contacts && !!contacts.length;

                if (!checkIfPagesWithDefaultDisclosuresExist(files, documentIds)) {
                    dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                        id: 'Not-saved',
                        title: labels.warningLabel[langRule],
                        message: labels.changeDisclosureWarning[langRule],
                    }));

                    return;
                }

                if (isContactsRequired && !currentDisclosureObject.isExemptionRequired) {
                    dispatch(openModalWindow(SELECT_CONSTANT_MODAL, {
                        id: SELECT_CONSTANT_MODAL,
                        title: labels.consultessModalTitleLabel[langRule],
                        modalSpecificProps: {
                            redactionDocumentId,
                            documentId: documentIds,
                            disclosureTypeId,
                            pageIds,
                        },
                    }));

                    return;
                }

                if (currentDisclosureObject.isExemptionRequired) {
                    const articleModalSpecificData = {
                        redactionDocumentId,
                        documentId: documentIds,
                        disclosureTypeId,
                        pageIds,
                    };

                    dispatch(openModalWindow(ARTICLE_STAMPS_MODAL, {
                        id: STAMP,
                        modalSpecificProps: {
                            setRequiredExemptions: true,
                            articleModalSpecificData,
                            isContactsRequired,
                        },
                    }));

                    return;
                }

                dispatch(changePageDisclosureWithAdditionalData(
                    [],
                    {
                        redactionDocumentId,
                        disclosureTypeId,
                        pageIds,
                        documentId: documentIds,
                    },
                    []));
            }
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const changePageDisclosureWithAdditionalData =
    (selectedStamps: number[], articleModalSpecificData: IModalSpecificProps, pageContacts: IAssignedContactsDto[]):
        (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => void =>
        async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
            try {
                const {
                    redactionDocumentId,
                    disclosureTypeId,
                    pageIds,
                    documentId,
                } = articleModalSpecificData;
                const {
                    documentList: { files },
                    pageList: {
                        currentDocument,
                        currentPage,
                    },
                    contextMenu: { contextMenuType },
                    localStorage: { language },
                    localization: { modifiedLabels },
                } = getState();

                const langRule = changeLang(language);
                const labels = { 
                    changeDisclosureWarning:initialLabel,
                    warningLabel:initialLabel };
        
                resourceBundle.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });
                modifiedLabels.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });

                dispatch(changeDocumentDisclosurePending(documentId));

                if (currentDocument) {
                    dispatch(changePagesDisclosurePending(
                        currentDocument.pages.map((item: IPages) => item.id)));
                }

                if (!pageIds || contextMenuType === DOCUMENT_LIST_CONTEXT_MENU) {
                    // document level
                    if (checkIfPagesWithDefaultDisclosuresExist(files, documentId)) {
                        const documentDisclosureDto = {
                            documentIds: documentId,
                            pageContacts,
                            exemptionIds: [
                                ...selectedStamps,
                            ],
                        };

                        const responseDocumentDisclosure = await api.disclosureController.updateDocumentDisclosure(
                            redactionDocumentId,
                            disclosureTypeId,
                            documentDisclosureDto,
                        );

                        responseDocumentDisclosure.forEach((doc: IDocument) => {
                            dispatch(updateInfoDocumentInDocumentList(doc));
                        });
                        dispatch(fetchPaginatedPagesSilent());
                        dispatch(handleCloseAllModalWindows());
                        dispatch(changeDocumentDisclosureComplete(documentId));
                    } else {
                        dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                            id: 'Not-saved',
                            title: labels.warningLabel[langRule],
                            message: labels.changeDisclosureWarning[langRule],
                        }));
                    }
                } else {
                    const pageDisclosureDto = {
                        disclosureTypeId,
                        exemptionIds: [
                            ...selectedStamps,
                        ],
                        pageIds: [
                            ...pageIds,
                        ],
                        pageContacts,
                    };

                    dispatch(changePagesDisclosurePending(pageIds));

                    const response = await api.pages.updatePageDisclosure(
                        redactionDocumentId,
                        documentId,
                        pageDisclosureDto,
                    );

                    dispatch(fetchPaginatedPagesSilent());
                    dispatch(updateInfoDocumentInDocumentList(response));
                    dispatch(handleCloseAllModalWindows());

                    if (pageIds.includes(currentPage.id)) {
                        dispatch(getPageConsultee(redactionDocumentId, currentDocument.id, currentPage.id));
                    }
                }
            } catch (error) {
                dispatch(addError(error));
            }
        };

export const deletePage = (
    redactionDocumentId: number,
    documentId: number,
    pageIds: number[],
    wasSomePaginated?: boolean,
): (
        dispatch: Dispatch,
        getState: () => IState,
    ) => void => async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        dispatch(deletePagePending());

        const { documentList: { files },
                pageList : {currentPageNumber},
                localStorage: { language },
                localization: { modifiedLabels }, } = getState();

                const langRule = changeLang(language);
                const labels = {
                    paginationLabel:initialLabel,
                    paginationMessageLabel:initialLabel
                 };
        
                resourceBundle.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });
                modifiedLabels.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });
        const pageCount = files.find((file: IFile) => file.id === documentId).pageCount - pageIds.length;

        try {
            await api.pages.deletePageFromDocument(redactionDocumentId, documentId, pageIds);

            dispatch(deletePageSuccess(pageIds));
            dispatch(updateDocumentPageCount(documentId, pageCount));

            if (wasSomePaginated) {
                dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                    id: PAGINATION_RECOMMENDED_TITLE,
                    title: labels.paginationLabel[langRule],
                    message: labels.paginationMessageLabel[langRule],
                    confirm: (): ThunkResult<Promise<void>> =>
                        setPaginationForExectDocuments(redactionDocumentId, [documentId]),
                    reject: (): ThunkResult<Promise<void>> =>
                        updatePaginationDocument(redactionDocumentId, documentId),
                }));
            }

            dispatch(fetchPaginatedPages(currentPageNumber >= pageCount/50 ? (pageCount/50) - 1 : currentPageNumber));
            dispatch(goToPage('next', true));
            dispatch(setPageCountAfterDeletion(pageIds.length));

        } catch (error) {
            dispatch(deletePageFailure());
            dispatch(addError(error));
        }
    };

export const changePageNumber = (documentId: number, pageId: number):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        const {
            reductionMetaData: { redactionDocumentId },
            pageList: { currentDocument: { pages } },
        } = getState();
        const selectedPage = pages.find((page: IPages): boolean => page.id === pageId);

        dispatch(clearOptionsState());
        dispatch(getPageResource(redactionDocumentId, documentId, pageId,
            selectedPage.rotation, selectedPage.layers[0].id));
    };

export const goToPage = (act: string, fromDelete: boolean = false):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { redactor: { pdfSrc } } = getState();

        if (!pdfSrc) {
            return;
        }

        const {
            pageList: {
                currentDocument: { id: documentId, pages, pageCount },
                currentPage: { id: pageId, actualPageNumber },
                currentPageNumber: pageNumber,
            },
            reductionMetaData: { redactionDocumentId },
        } = getState();

        let orderedPageIds = pages.map((page: IPages) => page.id);
        let idxCurrentPage = orderedPageIds.indexOf(pageId);

        if (idxCurrentPage === -1 && !fromDelete) {
            dispatch(setGotoPagePendingFlag(true));
            await dispatch(getPageNumberByPageId({
                redactionDocumentId,
                documentId,
                selectedPageId: actualPageNumber,
                getAnnotationStamps: true,
                goToPage: true,
            }));

            const {
                pageList: {
                    currentDocument: { pages: currentDocumentPages, pageCount: currentPageCount },
                    currentPageNumber,
                },
            } = getState();

            orderedPageIds = currentDocumentPages.map((page: IPages) => page.id);
            idxCurrentPage = orderedPageIds.indexOf(pageId);

            const lastPageSetNumber = Math.floor(currentPageCount / 50);

            if (act === GoToPageEnum.PREV && idxCurrentPage === 0 && currentPageNumber !== 0) {
                await dispatch(fetchPaginatedPages(currentPageNumber - 1));

                const {
                    pageList: { currentDocument: { pages: documentPages } },
                } = getState();

                dispatch(changePageNumber(documentId, documentPages[documentPages.length - 1].id));

                return;
            } else if (act === GoToPageEnum.NEXT && idxCurrentPage === 49 && currentPageNumber !== lastPageSetNumber) {
                await dispatch(fetchPaginatedPages(currentPageNumber + 1));

                const {
                    pageList: { currentDocument: { pages: documentPages } },
                } = getState();

                dispatch(changePageNumber(documentId, documentPages[0].id));

                return;
            }

            const handler = {
                [GoToPageEnum.NEXT]: orderedPageIds[idxCurrentPage] === orderedPageIds[orderedPageIds.length - 1]
                    ? -1
                    : orderedPageIds[idxCurrentPage + 1],
                [GoToPageEnum.PREV]: orderedPageIds[idxCurrentPage] === orderedPageIds[0]
                    ? -1
                    : orderedPageIds[idxCurrentPage - 1],
                [GoToPageEnum.FIRST]: orderedPageIds[0],
                [GoToPageEnum.LAST]: orderedPageIds[orderedPageIds.length - 1],
            };

            if(handler[act] === -1) {
                dispatch(setGotoPagePendingFlag(false));

                return;
            } else {
                dispatch(changePageNumber(documentId, handler[act]));

                return;
            }
        } else {
            const lastPageSetNumber = Math.floor(pageCount/50);

            if (act === GoToPageEnum.PREV && idxCurrentPage === 0 && pageNumber !== 0) {
                await dispatch(fetchPaginatedPages(pageNumber - 1));

                const {
                    pageList: { currentDocument: { pages: documentPages } },
                } = getState();

                dispatch(changePageNumber(documentId, documentPages[documentPages.length - 1].id));

                return;
            } else if (act === GoToPageEnum.NEXT && idxCurrentPage === 49 && pageNumber !== lastPageSetNumber) {
                await dispatch(fetchPaginatedPages(pageNumber + 1));

                const {
                    pageList: { currentDocument: { pages: documentPages } },
                } = getState();

                dispatch(changePageNumber(documentId, documentPages[0].id));

                return;

            }

            const handler = {
                [GoToPageEnum.NEXT]: orderedPageIds[idxCurrentPage] === orderedPageIds[orderedPageIds.length - 1]
                    ? -1
                    : orderedPageIds[idxCurrentPage + 1],
                [GoToPageEnum.PREV]: orderedPageIds[idxCurrentPage] === orderedPageIds[0]
                    ? -1
                    : orderedPageIds[idxCurrentPage - 1],
                [GoToPageEnum.FIRST]: orderedPageIds[0],
                [GoToPageEnum.LAST]: orderedPageIds[orderedPageIds.length - 1],
            };

            if(handler[act] === -1) {

                return;
            } else {
                dispatch(changePageNumber(documentId, handler[act]));
            }
        }
    };

const createMoveObj = (
        pages: IPages[],
        pageId: number,
        idx: number,
        currentPageNumber: number,
        totalPages: number,
        descSort: boolean ): IUpdatedPages => {
    let pageNumber = idx + 1 + (50 * currentPageNumber);

    if (descSort) {
        pageNumber = totalPages - pageNumber + 1;
    }

    return {
        pageId,
        pageNumber,
    };
};

export const movePages = (act: string): (
    dispatch: ThunkDispatch<IState, null, AnyAction>,
    getState: () => IState,
) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        const {
            pageList: {
                currentDocument,
                currentPage,
                selectedPages,
                currentPageSort,
                currentPageNumber,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
            localStorage: { language },
            localization: { modifiedLabels }, } = getState();

            const langRule = changeLang(language);
            const labels = {
                    severalPagesSelectedWarning:initialLabel,
                    warningLabel:initialLabel,
                    sortingNotApplicableOnMoveLabel:initialLabel,
                 };
        
                resourceBundle.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });
                modifiedLabels.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });

        if (!currentDocument) {
            return;
        }

        const { id: documentId, pages, pageCount } = currentDocument;

        if (selectedPages.length > 1) {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: labels.warningLabel[langRule],
                message: labels.severalPagesSelectedWarning[langRule],
                confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
            }));
        } else if(currentPageSort && currentPageSort.id === PAGE) {
            const orderedPageIds = pages.map((page: IPages) => page.id);
            const idxCurrentPage = orderedPageIds.indexOf(currentPage.id);

            if (idxCurrentPage === -1) {
                dispatch(setGotoPagePendingFlag(true));
                await dispatch(getPageNumberByPageId({
                    redactionDocumentId,
                    documentId,
                    selectedPageId: currentPage.actualPageNumber,
                    getAnnotationStamps: true,
                    goToPage: true,
                }));

                const {
                    pageList: {
                        currentDocument: { pages: currentDocumentPages, pageCount: currentPageCount },
                        currentPageNumber: activeCurrentPageNumber,
                    },
                } = getState();
                const currentPageId = currentPage.id;
                const currentPageIdx = findIndex(currentDocumentPages, currentPage);
                const handler = {
                    [MovePage.DOWN]: createMoveObj(
                        currentDocumentPages,
                        currentPageId,
                        currentPageIdx + 1,
                        activeCurrentPageNumber,
                        currentPageCount,
                        currentPageSort.desc),
                    [MovePage.UP]: createMoveObj(
                        currentDocumentPages,
                        currentPageId,
                        currentPageIdx - 1,
                        activeCurrentPageNumber,
                        currentPageCount,
                        currentPageSort.desc),
                };

                dispatch(setPagesOrder(redactionDocumentId, documentId, handler[act]));
            } else {
                const currentPageId = currentPage.id;
                const currentPageIdx = findIndex(pages, currentPage);
                const handler = {
                    [MovePage.DOWN]:
                        createMoveObj(
                            pages,
                            currentPageId,
                            currentPageIdx + 1,
                            currentPageNumber,
                            pageCount,
                            currentPageSort.desc),
                    [MovePage.UP]:
                        createMoveObj(
                            pages,
                            currentPageId,
                            currentPageIdx - 1,
                            currentPageNumber,
                            pageCount,
                            currentPageSort.desc),
                };

                dispatch(setPagesOrder(redactionDocumentId, documentId, handler[act]));
            }
        } else {
            dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                id: NOT_APPLICABLE,
                title: labels.warningLabel[langRule],
                message: labels.sortingNotApplicableOnMoveLabel[langRule],
            }));
        }
    };

export const reverseOrder = (): (
    dispatch: ThunkDispatch<IState, null, AnyAction>,
    getState: () => IState,
) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            pageList: {
                currentDocument,
                currentPageNumber,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
        } = getState();

        if (!currentDocument) {
            return;
        }

        const { id: documentId } = currentDocument;

        dispatch(changePageOrderPending());
        try {
            await api.pages.reversePages(redactionDocumentId, documentId);

            dispatch(fetchPaginatedPages(currentPageNumber));
        } catch (error) {
            dispatch(changePageOrderFailure());
            dispatch(addError(error));
        }
    };

export const undoPageDisclosure =
    (redactionDocumentId: number, documentId: number, data: IUndoDisclosure, isDeleteStamp: boolean):
        ThunkResult<Promise<void>> => async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
            const {
                pageList: {
                    currentDocument,
                    currentPage,
                },
            } = getState();

            dispatch(undoPageDisclosurePending());

            try {
                const response = await api.disclosureController.resetPageDisclosure(
                    redactionDocumentId,
                    documentId,
                    data,
                    isDeleteStamp,
                );
                const pageId = currentPage ? currentPage.id : null;
                const isCurrentPage = isDeleteStamp && data.pageIds.some((id: number) => id === pageId);

                if (isDeleteStamp && isCurrentPage) {
                    await dispatch(getPageStamps(
                        { redactionDocumentId, documentId: currentDocument.id, pageId: currentPage.id },
                    ));
                }

                if (data.disclosureReset.severs || data.disclosureReset.exemptions) {
                    dispatch(cleanSelectedShapes());
                    dispatch(setLinkedAnnotationId(null));
                    dispatch(setLinkedArticlesFlag(false));
                    dispatch(highlightLinkedArticles([]));
                    dispatch(highlightLinkedSever(null));
                }

                if(data.disclosureReset.exemptions && !data.disclosureReset.severs ) {
                    dispatch(clearLinkedArticlesForAllAnnotations());
                }

                const { disclosureReset } = data;

                if (data.disclosureReset.contacts) {
                    dispatch(clearPageConsultees());
                }

                dispatch(removeStampsPerPage(disclosureReset));
                dispatch(undoPageDisclosureSuccess());
                dispatch(fetchPaginatedPagesSilent());
                dispatch(updateInfoDocumentInDocumentList(response));
                dispatch(removeAnnotationsPerPage(disclosureReset));
                dispatch(handleCloseModalWindow('undoDisclosure'));
            } catch (error) {
                dispatch(addError(error));
                dispatch(undoPageDisclosureFailure(error));
            }
        };

export const undoDocumentDisclosure = (redactionDocumentId: number, data: IUndoDocDisclosure, isDeleteStamp?: boolean):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            pageList: {
                currentDocument,
                currentPage,
            },
        } = getState();

        dispatch(undoPageDisclosurePending());

        try {
            const response = await api.disclosureController.resetDocDisclosure(
                redactionDocumentId,
                data,
                isDeleteStamp,
            );
            const documentId = currentDocument ? currentDocument.id : null;
            const isCurrentDocument = isDeleteStamp && data.documentIds.some((id: number) => id === documentId);

            if (isDeleteStamp && isCurrentDocument) {
                await dispatch(getPageStamps(
                    { redactionDocumentId, documentId: currentDocument.id, pageId: currentPage.id },
                ));
            }

            if (data.disclosureReset.severs || data.disclosureReset.exemptions) {
                dispatch(cleanSelectedShapes());
                dispatch(setLinkedAnnotationId(null));
                dispatch(setLinkedArticlesFlag(false));
                dispatch(highlightLinkedArticles([]));
                dispatch(highlightLinkedSever(null));
            }

            if(data.disclosureReset.exemptions && !data.disclosureReset.severs ) {
                dispatch(clearLinkedArticlesForAllAnnotations());
            }

            const { disclosureReset } = data;

            if (data.disclosureReset.contacts) {
                dispatch(clearPageConsultees());
            }

            dispatch(removeStampsPerPage(disclosureReset));
            response.forEach((file: IDocument) => {
                dispatch(updateInfoDocumentInDocumentList(file));
            });
            dispatch(fetchPaginatedPagesSilent());
            dispatch(removeAnnotationsPerPage(disclosureReset));
            dispatch(undoPageDisclosureSuccess());
            dispatch(handleCloseModalWindow('undoDisclosure'));
        } catch (error) {
            dispatch(addError(error));
            dispatch(undoPageDisclosureFailure(error));
        }
    };

export const showAlertMessage = (): (dispatch: Dispatch, getState : () => IState) => void => (dispatch: Dispatch , getState : () => IState): void => {

    const {
        localStorage: { language },
        localization: { modifiedLabels }, 
    } = getState();
    const langRule = changeLang(language);
    const labels = {
                    disclosureWarningLabel:initialLabel,
                    warningLabel:initialLabel };
        
    resourceBundle.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }
        
             return resource;
            });
    modifiedLabels.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }
        
             return resource;
            });
    dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
        id: WARNING,
        title: labels.warningLabel[langRule],
        message: labels.disclosureWarningLabel[langRule],
        confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
    }));
};

export const isDisclosureTypeDefaultOnPage = (
    currentDocument: IDocument,
    documentId: number,
    pageId: number,
): boolean => {
    if (currentDocument.id === documentId) {
        const currentPage = currentDocument.pages.filter((page: IPages): boolean => page.id === pageId);

        if (currentPage.length && currentPage[0].disclosureTypeId) {
            return currentPage[0].disclosureTypeId === currentDocument.defaultDisclosureTypeId;
        }
    }
};

export const updatePageMetaDataInDocument = (
    redactionDocumentId: number,
    documentId: number,
    pageId: number,
): ThunkResult<Promise<void>> => async (dispatch: Dispatch): Promise<void> => {

    dispatch(getPageInDocumentPending());

    try {
        const updatedPage = await api.pages.getPageInDocument(
            redactionDocumentId,
            documentId,
            pageId,
        );

        dispatch(getPageInDocumentSuccess(updatedPage));
    } catch (e) {
        dispatch(getPageInDocumentFailure());
    }
};

export const ocrExistingPages = ({ redactionDocumentId, currentDocId, pageIds }: IModalIdsData):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        const ocrDocumentsData = [{
            documentId: currentDocId,
            pageIds,
            autoDeskew: false,
        }];

        dispatch(ocrPagesPending(pageIds));

        try {
            await api.ocrExistingDocumentController.ocrExistingDocuments(redactionDocumentId, ocrDocumentsData);
            dispatch(ocrPagesSuccess());

        } catch (error) {
            dispatch(ocrPagesFailure());
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

export const checkPagesAndAutoDeskewCurrentPages = ({ redactionDocumentId, currentDocId, pageIds }: IModalIdsData):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            pageList: {
                currentDocument: {
                    pages,
                },
            },
            localStorage: { language },
            localization: { modifiedLabels },
        } = getState();
         const langRule = changeLang(language);
                const labels = {
                    autoDeskewPageLabel:initialLabel, };
                resourceBundle.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });
                modifiedLabels.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });
        const hasNotOcredPage = pages.some((page: IPages) =>
            !page.isOcred && pageIds.includes(page.id));
        const ocrDocumentsData = [{
            documentId: currentDocId,
            pageIds,
            autoDeskew: true,
        }];

        hasNotOcredPage ?
            dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: AUTO_DESKEW_PAGE_MESSAGE,
                title: '',
                message: labels.autoDeskewPageLabel[langRule],
                confirm: (): (dispatch: Dispatch, getState: () => IState) => void =>
                    autoDeskewCurrentPages(redactionDocumentId, ocrDocumentsData, pageIds),
            })) :
            dispatch(autoDeskewCurrentPages(redactionDocumentId, ocrDocumentsData, pageIds));
    };

export const autoDeskewCurrentPages = (redactionDocumentId: number, ocrDocumentsData: IOcrDocPage[], pageIds: number[]):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(ocrPagesPending(pageIds));

        try {
            await api.ocrExistingDocumentController.ocrExistingDocuments(redactionDocumentId, ocrDocumentsData);
            dispatch(ocrPagesSuccess());

        } catch (error) {
            dispatch(ocrPagesFailure());
            dispatch(addError(error));
        }

        dispatch(restartDocumentTasksQueue(redactionDocumentId));
    };

const checkIfPagesWithDefaultDisclosuresExist = (files: IFile[], documentIds: number[]): boolean => {
    if (!Array.isArray(documentIds)) {
        documentIds = [documentIds];
    }
    const currentDocuments = files.filter((file: IFile) => documentIds.includes(file.id));

    return currentDocuments.every((document: IFile) => document.isDefaultDisclosureExist);
};

export const getExemptionCurrentDocument = (ids: number[]): (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        const { pageList: { gotoPagePending } } = getState();

        dispatch(getExemptionCurrentDocumentPending());

        try {
            const exemptions = await api.amandaExemptions.getArticleStampTypesByExemptionsId(ids);

            dispatch(getExemptionCurrentDocumentSuccess(exemptions));

            if (!gotoPagePending) {
                dispatch(fetchPaginatedPagesSilent());
            }

        } catch (e) {
            dispatch(getExemptionCurrentDocumentFailure());
        }
    };
export const fetchPaginatedPages = (pageNumber: number):
    (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument,
                currentPageSort,
                currentPage,
            },
        } = getState();

        const sort = currentPageSort &&
            currentPageSort.id ? `&sort=${SORT_KEYS[currentPageSort.id]},${currentPageSort.desc ? 'desc' : 'asc'}` : '';

        dispatch(setPageListPending());
        try {
            const response =
                await api.pages.fetchPaginatedPages(redactionDocumentId, currentDocument.id, pageNumber, sort);

            dispatch(setPageListSuccess(response));
            dispatch(setPageNumber(pageNumber));

            if(currentPage && currentPage.id) {
                const pageId = currentPage.id;
                const firstPageSet =
                    await api.pages.fetchPaginatedPages(redactionDocumentId, currentDocument.id, 0, sort);
                const firstPageCheck = firstPageSet.findIndex((page: IPages): boolean => page.id === pageId) === 0;

                dispatch(setCurrentFirstOrLastPage(SET_CURRENT_FIRST_PAGE_ID, firstPageCheck
                    ? pageId : null));

                const lastPageNumber = Math.floor(currentDocument.pageCount/50);
                const lastPageSet =
                    await api.pages.fetchPaginatedPages(
                        redactionDocumentId, currentDocument.id, lastPageNumber, sort);

                dispatch(setCurrentFirstOrLastPage(SET_CURRENT_LAST_PAGE_ID,
                    lastPageSet.findIndex((page: IPages): boolean => page.id === pageId) ===
                        lastPageSet.length - 1 ? pageId : null));
            }
        } catch (e) {
            dispatch(setPageListFailure());
        }
    };

export const fetchPaginatedPagesSilent = ():
    (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument,
                currentPageNumber,
                currentPageSort,
                currentPage,
            },
        } = getState();

        if (!currentDocument) {
            return;
        }

        const sort = currentPageSort && currentPageSort.id
            ? `&sort=${SORT_KEYS[currentPageSort.id]},${currentPageSort.desc ? 'desc' : 'asc'}`
            : '';

        dispatch(setPageListPending());
        try {
            const response =
                await api.pages.fetchPaginatedPages(redactionDocumentId, currentDocument.id, currentPageNumber, sort);

            dispatch(setPageListSuccess(response));

            if(currentPage && currentPage.id) {
                const pageId = currentPage.id;
                const firstPageSet =
                    await api.pages.fetchPaginatedPages(redactionDocumentId, currentDocument.id, 0, sort);
                const firstPageCheck = firstPageSet.findIndex((page: IPages): boolean => page.id === pageId) === 0;

                dispatch(setCurrentFirstOrLastPage(SET_CURRENT_FIRST_PAGE_ID, firstPageCheck? pageId : null));

                const lastPageNumber = Math.floor(currentDocument.pageCount/50);
                const lastPageSet =
                    await api.pages.fetchPaginatedPages(
                        redactionDocumentId, currentDocument.id, lastPageNumber, sort);

                dispatch(setCurrentFirstOrLastPage(SET_CURRENT_LAST_PAGE_ID,
                    lastPageSet.findIndex((page: IPages): boolean => page.id === pageId) ===
                        lastPageSet.length - 1 ? pageId : null));
            }
        } catch (e) {
            dispatch(setPageListFailure());
        }
    };

export const getPageListColumnHeader = (lid: string): (dispatch:
    ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(setPageListColumnHeaderPending());
        try {
            const response = await api.pages.getPageListColumnHeader(lid);

            dispatch(setPageListColumnHeaderSuccess(response));
            dispatch(setSortParam(setDefaultSortOrder(response)));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const modifyPageListColumnHeader = (pageListColumnHeader: IHeader[], userId: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(modifyPageListColumnHeaderPending());

        const columns = pageListColumnHeader.map((columnsHeader: IHeader, index: number) => {
            return {
                ...columnsHeader,
                displayOrder: index + 1,
            };
        });

        try {
            const response = await api.pages.modifyPageListColumnHeader(columns, userId);
            dispatch(modifyPageListColumnHeaderSuccess(response));
        } catch (error) {
            dispatch(modifyPageListColumnHeaderFailure(error))
            dispatch(addError(error));
        }
    };
export const getResetedPageListColumnHeader = (userId: string):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        dispatch(resetPageListColumnHeaderPending());
        dispatch(setSortParam({ id: 'page', desc: false }));
        dispatch(setPageNumber(0));
        dispatch(fetchPaginatedPages(0));
        try {
            const response = await api.pages.resetPageListColumnHeader(userId);

            dispatch(resetPageListColumnHeaderSuccess(response));
            dispatch(setSortParam(null));
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const editDocumentFileName = (fileName: string): (dispatch: Dispatch<any>, getState: () => IState) => void =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {

        const {
            documentList: {
                files,
                stackId,
            },
            reductionMetaData: {
                redactionDocumentId,
                folderRsn,
            },
            pageList: {
                currentDocument,
                selectedDocuments,
            },
        } = getState();
        const selectedDocumentId = files.find((file: IFile) => file.id === selectedDocuments[0]).id;
        const isEditedCurrentDocumentNameOrSelectedDocumentName = currentDocument
            && currentDocument.id === selectedDocumentId;
        const documentId = isEditedCurrentDocumentNameOrSelectedDocumentName
            ? currentDocument.id : selectedDocumentId;

        dispatch(editFileNamePending());
        try {
            await api.redactionDocuments.editFileName(fileName, redactionDocumentId, documentId);

            if (isEditedCurrentDocumentNameOrSelectedDocumentName) {
                dispatch(updateCurrentDocumentEditedFileName(fileName));
            } else {
                dispatch(editFileNameSuccess());
            }

            if (stackId) {
                dispatch(fetchStackDocumentsList(redactionDocumentId, stackId));
            } else {
                dispatch(fetchDocumentList(redactionDocumentId));
            }

        } catch (error) {
            dispatch(addError(error));
            dispatch(editFileNameFailure());
        }
    };

export const hasLinkedArticles = (): (dispatch: Dispatch<any>, getState: () => IState) => void =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const {
            contextMenu: {
                contextMenuType,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                selectedDocuments,
                selectedPages,
                currentDocument,
            },

        } = getState();
        const data = contextMenuType === DOCUMENT_LIST_CONTEXT_MENU
            ? { documentIds: [...selectedDocuments], pageIds: [] }
            : contextMenuType === PAGE_LIST_CONTEXT_MENU
                ? { documentIds: [currentDocument.id], pageIds: [...selectedPages] }
                : { documentIds: [], pageIds: [] };

        dispatch(hasLinkedArticlesPending());
        try {
            const response = await api.disclosureController.hasLinkedArticles(redactionDocumentId, data);

            dispatch(hasLinkedArticlesSuccess(response));
        } catch (error) {
            dispatch(addError(error));
        }

    };
export const openPageAutoAlignArticleStampsConfirmModal = ():
    (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {

        const {
            localStorage: { language },
            localization: { modifiedLabels },
        } = getState();
        const langRule = changeLang(language);
        const labels = {
                    warningLabel:initialLabel,
                    autoAlignArticleStampsLabel:initialLabel };
        
                resourceBundle.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });
                modifiedLabels.map((resource: any) => {
                    if(getConsulteesLabelsByKey(resource.resourceKey)) {
                        labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
                    }
        
                    return resource;
                });
        dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL,
            title: labels.warningLabel[langRule],
            message: labels.autoAlignArticleStampsLabel[langRule],
            okButtonTitle: YES_BUTTON,
            cancelButtonTitle: NO_BUTTON,
            confirm: (): () => void => (): void => {
                dispatch(applyPageAutoAlignArticleStamps());
                handleCloseModalWindow(AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL);
            },
            reject: (): AnyAction => handleCloseModalWindow(AUTO_ALIGN_ARTICLE_STAMPS_ALERT_MODAL),
        }));
    };

export const applyPageAutoAlignArticleStamps = ():
    (dispatch: Dispatch, getState: () => IState) => void =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                userId,
                redactionDocumentId,
            },
            pageList: {
                currentDocument,
                currentPage,
                selectedPages,
            },
        } = getState();
        const isCurrentPageIncluded = currentPage && selectedPages.includes(currentPage.id);

        dispatch(applyPageAutoAlignArticleStampsPending());
        try {
            dispatch(applySearchPending(true));

            await api.pages.autoAlignPageStamps(redactionDocumentId, currentDocument.id, userId, selectedPages);
            dispatch(applyPageAutoAlignArticleStampsSuccess());

            if (isCurrentPageIncluded) {
                dispatch(getPageStamps({
                    redactionDocumentId,
                    documentId: currentDocument.id, pageId: currentPage.id,
                }));

            }

            dispatch(applySearchPending(false));

        } catch (error) {
            dispatch(applySearchPending(false));
            dispatch(addError(error));
        }
    };

export const getCurrentPageComments = ():
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument,
                currentPage,
            },
            globalSearch: {
                currentPageId,
            },
        } = getState();

        if( currentDocument && currentPage && (currentPage.id || currentPageId)) {
            try {
                dispatch(getCurrentPageCommentsPending());

                const response = await api.pages.getCurrentPageComments(redactionDocumentId,
                    currentDocument.id, (currentPage && currentPage.id ? currentPage.id : currentPageId));

                dispatch(getCurrentPageCommentsSuccess(response));
            } catch (error) {
                dispatch(addError(error));
                dispatch(getCurrentPageCommentsFailure());
            }
        }
    };

export const getCurrentPageHyperLinks = ():
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument,
                currentPage,
            },
            globalSearch: {
                currentPageId,
            },
        } = getState();

        if( currentDocument && currentPage && (currentPage.id || currentPageId)) {
            try {
                dispatch(getCurrentPageHyperLinkPending());

                const response = await api.pages.getCurrentPageHyperLinks(redactionDocumentId,
                    currentDocument.id, (currentPage && currentPage.id ? currentPage.id : currentPageId));

                dispatch(getCurrentPageHyperLinkSuccess(response));
            } catch (error) {
                dispatch(addError(error));
            }
        }
    };

export const confirmDeleteComment = (comment: IComment):
    (dispatch: Dispatch, getState: () => IState) => void =>
    (dispatch: Dispatch, getState: () => IState): void => {
        const {
            reductionMetaData: {
                redactionDocumentId,
            },
            pageList: {
                currentDocument,
                currentPage,
            },
            redactor:{
                rotate,
            },
            localStorage: { language },
            localization: { modifiedLabels },
        } = getState();
        const langRule = changeLang(language);
        const labels = {
            deleteCommentTitleLabel:initialLabel,
            deleteCommentMessageLabel:initialLabel };

        resourceBundle.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getConsulteesLabelsByKey(resource.resourceKey)) {
                labels[getConsulteesLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        dispatch(openModalWindow(CONFIRMATION_DIALOG_MODAL, {
            id: DELETE_COMMENT_TITLE,
            title: labels.deleteCommentTitleLabel[langRule],
            message: labels.deleteCommentMessageLabel[langRule],
            confirm: (): (dispatch: Dispatch, getState: () => IState) => void  =>
                    deletePageComment(
                    redactionDocumentId,
                    currentDocument.id,
                    currentPage.id,
                    comment,
                    currentPage.selectedComment,
                    rotate),
        }));
};

export const deletePageComment = (
    redactionDocumentId: number,
    documentId: number,
    pageId: number,
    comment: IComment,
    selectedComment: null | IComment,
    rotation: number):
    (dispatch: ThunkDispatch<IState, null, AnyAction>) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>): Promise<void> => {
        if(selectedComment) {
            dispatch(setSelectedCommentInCurrentPage(null));
        }

        try {
            dispatch(setCommentLoadingFlag(true));

            const response = await api.pages.deletePageComment (redactionDocumentId,
                documentId, pageId, comment);

            if(response) {
                dispatch(getPageResource(redactionDocumentId, documentId, pageId, rotation));
            }
        } catch (error) {
            dispatch(addError(error));
        }
    };

export const setCommentLoadingFlag = (flag: boolean): AnyAction =>
    action(SET_COMMENT_LOADING_FLAG, flag);

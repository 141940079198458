import { IState } from '../store';
import { createSelector } from 'reselect';
import { IAnnotationType, IAnnotationTypesState } from 'redux/reducers/annotationTypes/annotationTypes.model';

export const getAnnotationTypes = (state: IState): IAnnotationTypesState => state.annotationTypes;

export const getAnnotationTypesLoading = createSelector(
    [getAnnotationTypes],
    (annotation: IAnnotationTypesState): boolean => annotation.annotationTypePending,
);

export const getAnnotationTypesUpdatingLoading = createSelector(
    [getAnnotationTypes],
    (annotation: IAnnotationTypesState): boolean => annotation.annotationTypeUpdatePending,
);

export const getAnnotationTypesList = createSelector(
    [getAnnotationTypes],
    (annotation: IAnnotationTypesState): IAnnotationType[] => annotation.annotationType,
);

export const getLocalAnnotationTypesList = createSelector(
    [getAnnotationTypes],
    (annotation: IAnnotationTypesState): IAnnotationType[] => annotation.localAnnotationType,
);

export const getHighlightTypesList = createSelector(
    [getAnnotationTypes],
    (annotations: IAnnotationTypesState): IAnnotationType[] =>
        annotations.annotationType.filter((annotation: IAnnotationType): boolean => !annotation.isSever),
);

export const getSeverTypesList = createSelector(
    [getAnnotationTypes],
    (annotations: IAnnotationTypesState): IAnnotationType[] =>
        annotations.annotationType.filter((annotation: IAnnotationType): boolean => annotation.isSever),
);

export const getActiveHighlightTypesList = createSelector(
    [getAnnotationTypes],
    (annotations: IAnnotationTypesState): IAnnotationType[] =>
        annotations.annotationType.filter((annotation: IAnnotationType): boolean => {
            return !annotation.isSever && annotation.isActive;
        }),
);

export const getActiveSeverTypesList = createSelector(
    [getAnnotationTypes],
    (annotations: IAnnotationTypesState): IAnnotationType[] =>
         annotations.annotationType.filter((annotation: IAnnotationType): boolean => {
        return annotation.isSever && annotation.isActive;
    }),
);

export const getSelectedAnnotationType = (state: IState): IAnnotationType => state.annotationTypes.selectedAnnotation;

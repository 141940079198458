export const SET_ALL_DOCUMENT_PAGES_TO_EXPORT = 'reduction/SET_ALL_DOCUMENT_PAGES_TO_EXPORT';
export const CHANGE_DOCUMENT_IN_EXPORT_LIST = 'reduction/CHANGE_DOCUMENT_IN_EXPORT_LIST';
export const SELECT_ALL_OR_DESELECT_ALL_DOCUMENTS_IN_EXPORT_LIST = 'redaction/SELECT_ALL_OR_DESELECT_ALL_DOCUMENTS_IN_EXPORT_LIST';
export const CHANGE_PAGES_IN_EXPORT_LIST = 'reduction/CHANGE_PAGES_IN_EXPORT_LIST';

export const SET_ANNOTATION_TYPE = 'reduction/SET_ANNOTATION_TYPE';
export const REMOVE_ANNOTATION_TYPE = 'reduction/REMOVE_ANNOTATION_TYPE';

export const SET_EXPORT_OPTIONS_FROM_PRESET = 'reduction/SET_EXPORT_OPTIONS_FROM_PRESET';

export const CHANGE_OPTIONS = 'reduction/CHANGE_OPTIONS';
export const CHANGE_PAGINATION_OPTIONS = 'reduction/CHANGE_PAGINATION_OPTIONS';
export const CHANGE_EXPORT_DESTINATION_OPTIONS = 'reduction/CHANGE_EXPORT_DESTINATION_OPTIONS';

export const CLEAR_EXPORT_STATE = 'reduction/CLEAR_EXPORT_STATE';
export const CLEAR_PRESET_OPTIONS = 'reduction/CLEAR_PRESET_OPTIONS';

export const GET_EXPORT_DOCUMENTS_PENDING = 'reduction/GET_EXPORT_DOCUMENTS_PENDING';
export const GET_EXPORT_DOCUMENTS_SUCCESS = 'reduction/GET_EXPORT_DOCUMENTS_SUCCESS';
export const GET_EXPORT_DOCUMENTS_FAILURE = 'reduction/GET_EXPORT_DOCUMENTS_FAILURE';

export const POST_EXPORT_DOCUMENT_PENDING = 'reduction/POST_EXPORT_DOCUMENT_PENDING';
export const POST_EXPORT_DOCUMENT_SUCCESS = 'reduction/POST_EXPORT_DOCUMENT_SUCCESS';
export const POST_EXPORT_DOCUMENT_FAILURE = 'reduction/POST_EXPORT_DOCUMENT_FAILURE';

export const SET_CONSULT_FILTERS = 'reduction/SET_CONSULT_FILTERS';

export const SET_PAGES_FOR_SINGLE_FILE = 'reduction/SET_PAGES_FOR_SINGLE_FILE';

export const SELECT_EXPORT_STACK = 'reduction/SELECT_EXPORT_STACK';

export const EXPORT_STACK_DOCS_PENDING = 'reduction/EXPORT_STACK_DOCS_PENDING';
export const EXPORT_STACK_DOCS_SUCCESS = 'reduction/EXPORT_STACK_DOCS_SUCCESS';
export const EXPORT_STACK_DOCS_FAILURE = 'reduction/EXPORT_STACK_DOCS_FAILURE';

export const CHANGE_DOCUMENT_ORIGINAL_NAME_IN_EXPORT_LIST = 'redaction/CHANGE_DOCUMENT_ORIGINAL_NAME_IN_EXPORT_LIST';

export const SET_EXPORT_FILE_NAME_ERROR_FLAG = 'redaction/SET_EXPORT_FILE_NAME_ERROR_FLAG';

export const CHANGE_ATTACHMENT_TYPE_OPTIONS = 'redaction/CHANGE_ATTACHMENT_TYPE_OPTIONS';

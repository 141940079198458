import * as React from 'react';
import Button from '@mui/material/Button';
import LocalFiles from './localFiles/LocalFiles';
import FileImportErrors from './fileImportErrors/FileImportErrors';
import {
    IFile,
    IModalWindowImportState,
    IModalWindowImportDispatchProps,
    IModalWindowImportModelProps,
    IAdditionalImportParam,
    IModalWindowImportStateProps,
    IDocuments,
    IInsertPageOptionsFromRedaction,
    IInsertPageOptionsFromRedactionForOneDocument,
    IInsertPageOptionsFromFileForOneDocument,
    IInsertPageOptionsFromFile,
    IAmandaAttachmentResponse,
    IUploadAttachmentDto,
    IAmandaAttachmentState,
    IEdmsFile,
} from './modalWindowImport.model';
import {
    LOCAL_FILE,
    SCANNER,
    REDACTION_DOCUMENT,
    AMMANDA_ATTACHMENT,
    EDMS_FILES,
} from '../../../constants';
import { AutoSizer } from 'react-virtualized';
import { PDFDocument, PDFPage } from 'pdf-lib';
import { connect } from 'react-redux';
import { handleCloseModalWindow, openModalWindow, handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { FILE_2GB_SIZE, OK, regexFiles, regexPdfFile, STACK_MANDATORY_ALERT } from '../../../constants/common.constants';
import { CONFIRMATION_DIALOG_MODAL, ALERT_DIALOG_MODAL, WARNING } from '../../../constants/messages.constants';
import {
    IMPORT_ERROR_TITLE_MESSAGE,
    UNSUPPORTED_FILE_MESSAGE,
    BIG_FILE_MESSAGE,
    ERROR_MESSAGE_NOT_DEFAULT_DISCLOSURE,
} from './fileImportErrors/constants';
import { IModalIdsData, IModalProps } from '../../../redux/reducers/modal/modal.model';
import {
    DEFAULT_MEDIUM_WIDTH,
    DEFAULT_HEIGHT_SMALL,
    MIN_WIDTH_LARGE,
    MIN_HEIGHT_SMALL,
    DEFAULT_HEIGHT_LARGE,
    MIN_HEIGHT_LARGE,
} from '../index';
import { IState } from '../../../redux/store';
import { getScanner } from '../../../redux/selectors/scanner';
import './modalWindowImport.styles.scss';
import Scanner from './scanner/scanner';
import { SEARCH_FOLDER_ATTACHMENTS, SEARCH_REDACTION_DOCUMENTS } from '../../../constants/searchDocuments.contstants';
import {
    getAmandaAttachments,
    getSearchRedactionDocuments,
    getSelectedDocumentsRedactionId,
    getAmandaAttachmentsPending,
    getSearchRedactionDocumentsPending,
    getEDMSFiles,
} from '../../../redux/selectors/modalSearchDocument';
import {
    getSortParams
} from '../../../redux/selectors/commonSelector';
import { IBasicDocuments, IExportBasicPages } from '../../../redux/reducers/modalWindowExport/modalWindowExport.model';
import { clearSearchReductionDocuments } from '../../../redux/actions/modalSearchDocuments';
import { changeDisclosure } from '../../../redux/actions/pageList';
import { IEdmsFileDto, IUploadRedactionDocumentsDto } from '../modalSearchDocument/modalSearchDocument.model';
import { getRedactionDocumentId, getImportPermissions } from '../../../redux/selectors/initialization';
import {
    IMPORT_SOURCE,
    getFileListHeaders,
    getDocumentListHeaders,
    REDACTION_DOCUMENTS,
    FILES,
    AMANDA_ATTACHMENTS_LIST,
    getAmandaAttachmentHeaders,
    getEDMSFileListHeaders,
} from '../../../constants/import.contstants';
import { getCurrentDisclosureTypes } from '../../../redux/selectors/disclosureTypes';
import { IDisclosureOptions } from '../../../redux/reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import {
    ASSIGN_TO_DISCLOSURE_ID,
    INCLUDE_ANNOTATIONS_PARAM,
    INCLUDE_DISCLOSURES_PARAM,
    OCR_PARAM,
    DESKEW_PARAM,
    STACK_ID,
    FROM_SCANNER,
} from './localFiles/constants';
import { v4 as uuidv4 } from 'uuid';
import { SelectMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { getCurrentDocument, getSelectedPages } from '../../../redux/selectors/pageList';
import {
    insertFiles,
    insertRedactionDocuments,
    fetchFiles,
    fetchRedactionDocuments,
    importDocumentFromAttachments,
    fetchEDMSFiles,
} from '../../../redux/actions/modalWindowImport';
import { getImportDocumentsLoading } from '../../../redux/selectors/modalWindowImport';
import { emptySelectOption } from '../../../components/materialUiForms/materialUiForms';
import { LinearProgress } from '@mui/material';
import { hasCurrentReductionDefaultDisclosure } from '../../../redux/selectors/modalGlobalSettings';
import { ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import { fetchAllDisclosureTypeList, fetchCurrentDisclosureTypeList } from '../../../redux/actions/disclosureTypes';
import {
    NO_AMANDA_ATTACHMENT_FOUND, NO_ATTACHMENT_FOUND_MESSAGE,
    NO_ATTACHMENT_FOUND_TITLE, NO_REDACTION_DOCUMENT_FOUND, NO_DOCUMENT_FOUND_MESSAGE,
} from '../../../containers/modalWindowContents/modalWindowImport/fileImportErrors/constants';
import { IError } from '../../../redux/common.model';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage, getRedactionMode } from '../../../redux/selectors/localStorage';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';
import { MODE_CONTRIBUTE } from '../../../redux/reducers/localStorage/constant';
import { IMPORT_FROM_EDMS } from '../modalImportFromEDMS/ModalImportFromEDMS';
import { PageData } from '../../../redux/reducers/common/common.model';

const resizeImportToWidth = 900;
const resizeImportToHeight = 600;
const resizeMinW = 500;
const resizeMinH = 600;
export const getImportLabelsByKey = (key: string): string => {
    switch(key) {
        case 'MODAL_IMPORT_SELECT_SOURCE':
            return 'importSourceLabel';
        case 'MODAL_IMPORT_SELECT_LOCAL_FILE':
            return 'localFileLabel';
        case 'MODAL_IMPORT_SELECT_FROM_EDMS':
            return 'edmsLabel';
        case 'MODAL_IMPORT_SELECT_REDACTION_DOCUMENTS':
            return 'redactionDocumentLabel';
        case 'MODAL_IMPORT_SELECT_AMANDA_ATTACHMENTS':
            return 'amandaAttachmentLabel';
        case 'MODAL_IMPORT_SELECT_FROM_SCANNER':
            return 'scannerLabel';
        case 'COMMON_LABEL_NEXT':
            return 'nextLabel';
        case 'COMMON_LABEL_CLEAR':
            return 'clearLabel';
        case 'MODAL_IMPORT_SEARCH_FOLDER_ATTACHMENTS_TITLE':
            return 'folderAttachmentLabel';
        case 'MODAL_IMPORT_SEARCH_REDACTION_DOCUMENTS_TITLE':
            return 'redactionDocumentsLabel';
        case 'MODAL_IMPORT_EDMS_TITLE':
            return 'edmsTitle';
        case 'MODAL_IMPORT_HAVE_NO_DEFAULT_DISCLOSURE':
            return 'noDefaultDisclosureLabel';
        case 'COMMON_LABEL_DESELECT_ALL':
            return 'deselectAllLabel';
        case 'COMMON_LABEL_SELECT_ALL':
            return 'selectAllLabel';
        case 'MODAL_IMPORT_DOCUMENT_NAME_HEADER':
            return 'docNameHeaderLabel';
        case 'MODAL_IMPORT_PAGES_HEADER':
            return 'pagesHeaderLabel';
        case 'MODAL_IMPORT_SIZE_HEADER':
            return 'sizeHeaderLabel';
        case 'MODAL_IMPORT_START_PAGE_HEADER':
            return 'startPageHeaderLabel';
        case 'MODAL_IMPORT_END_PAGE_HEADER':
            return 'endPageHeaderLabel';
        case 'MODAL_IMPORT_INVALID_FILE_EXTENSION_ERROR':
            return 'fileExtensionErrorLabel';
        case 'MODAL_IMPORT_DOCUMENT_TYPE_HEADER':
            return 'docTypeHeaderLabel';
        case 'COMMON_LABEL_DESCRIPTION':
            return 'docDescriptionHeaderLabel';
        case 'COMMON_LABEL_APPLY_OCR':
            return 'applyOCRlabel';
        case 'COMMON_LABEL_AUTO_DESKEW':
            return 'autoDeskewLabel';
        case 'MODAL_IMPORT_CONVERSION_OPTIONS':
            return 'conversionOptionsLabel';
        case 'MODAL_IMPORT_REDACTION_OPTIONS':
            return 'redactionOptionsLabel';
        case 'MODAL_IMPORT_ASSIGN_TO_STACK':
            return 'assignToStackLabel';
        case 'MODAL_IMPORT_INCLUDE_ANNOTATIONS':
            return 'includeAnnotationsLabel';
        case 'MODAL_IMPORT_INCLUDE_DISCLOSURES':
            return 'includeDisclosureLabel';
        case 'COMMON_LABEL_IMPORT':
            return 'importLabel';
        case 'MODAL_IMPORT_PAGE_COUNT_ERROR':
            return 'pageCountErrorLabel';
        case 'MODAL_IMPORT_UNSUPPORTED_FILE_MESSAGE':
            return 'unsupportedFileLabel';
        case 'MODAL_IMPORT_BIG_FILE_MESSAGE':
            return 'bigFileErrorLabel';
        case 'MODAL_IMPORT_NO_ATTACHMENT_FOUND':
            return 'noAttachmentErrorLabel';
        case 'MODAL_IMPORT_NO_DOCUMENT_FOUND':
            return 'noDocumentErrorLabel';
        case 'COMMON_LABEL_AUTO':
            return 'alertLabel';
        case 'COMMON_LABEL_OK':
            return 'okLabel';
        case 'COMMON_LABEL_WARNING':
            return 'warningLabel';
        case 'MODAL_IMPORT_TOTAL_PAGES':
            return 'totalPagesLabel';
        case 'COMMON_LABEL_EQUAL_OR_LARGER':
            return 'equalOrLargerLabel';
        case 'COMMON_LABEL_EQUAL_OR_LESS':
            return 'equalOrLessLabel';
        case 'COMMON_LABEL_MUST_BE_INTEGER':
            return 'mustIntegerLabel';
        case 'MODAL_IMPORT_SELECT_SCANNER':
            return 'selectScannerLabel';
        case 'MODAL_IMPORT_SCANNER_RESOLUTION':
            return 'resolutionLabel';
        case 'MODAL_IMPORT_SCANNER_COLOR_DEPTH':
            return 'colorDepthLabel';
        case 'MODAL_IMPORT_SCANNER_COLOR_Black_AND_WHITE':
            return 'balckAndWhiteLabel';
        case 'MODAL_IMPORT_SCANNER_COLOR_GRAY':
            return 'colorGrayLabel';
        case 'MODAL_IMPORT_SCANNER_COLOR':
            return 'scannerColorLabel';
        case 'MODAL_IMPORT_SCANNER_MODE':
            return 'scannerModeLabel';
        case 'MODAL_IMPORT_SCANNER_MODE_MULTI_PAGE':
            return 'multiPageLabel';
        case 'MODAL_IMPORT_SCANNER_MODE_BATCH_MULTI':
            return 'multiBatchLabel';
        case 'COMMON_LABEL_SCAN':
            return 'scanLabel';
        case 'MODAL_IMPORT_SCANNER_CUSTOM_SETTINGS':
            return 'customSettingsLabel';
        case 'MODAL_IMPORT_SCANNER_DUPLEX':
            return 'duplexLabel';
        case 'MODAL_IMPORT_SCANNER_AUTOMATIC_FEEDER':
            return 'autoFeederLabel';
        default: return '';
    }
};

class ModalWindowImport extends React.Component<IModalWindowImportModelProps, IModalWindowImportState> {
    public static getDerivedStateFromProps(
        nextProps: IModalWindowImportModelProps,
        prevState: IModalWindowImportState,
    ): Partial<IModalWindowImportState> {
        if (prevState[REDACTION_DOCUMENTS].length !== nextProps[REDACTION_DOCUMENTS].length) {
            return {
                [REDACTION_DOCUMENTS]: nextProps[REDACTION_DOCUMENTS].map((doc: IBasicDocuments) => (
                    {
                        ...doc,
                        isChecked: true,
                        from: 1,
                        to: doc.pageCount,
                        totalPages: doc.pageCount,
                    }
                )),
            };
        } else if (prevState[AMANDA_ATTACHMENTS_LIST].length !== nextProps[AMANDA_ATTACHMENTS_LIST].length) {
            return {
                [AMANDA_ATTACHMENTS_LIST]: nextProps[AMANDA_ATTACHMENTS_LIST]
                    .map((attachment: IAmandaAttachmentResponse) => ({
                        ...attachment,
                        isChecked: attachment.isValidExtension,
                        id: attachment.attachmentRsn,
                    })),
            };
        }

        return null;
    }

    private readonly input: React.RefObject<HTMLInputElement>;

    constructor(props: IModalWindowImportModelProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            typeOfImport: '',
            isFormInvalid: false,
            sortParams : null,
            [FILES]: [],
            edmsFiles: [],
            scannerActive: false,
            [REDACTION_DOCUMENTS]: props.redactionDocuments.map((doc: IBasicDocuments) => (
                {
                    ...doc,
                    isChecked: true,
                }
            )),
            [AMANDA_ATTACHMENTS_LIST]: [],
            additionalParams: {
                [ASSIGN_TO_DISCLOSURE_ID]: '',
                [INCLUDE_ANNOTATIONS_PARAM]: false,
                [INCLUDE_DISCLOSURES_PARAM]: false,
                [OCR_PARAM]: false,
                [DESKEW_PARAM]: false,
                [STACK_ID]: 0,
            },
        };
    }

    public componentWillUnmount(): void {
        this.props.clearSearchReductionDocuments();
    }

    public componentDidMount(): void {
        this.props.fetchAllDisclosureTypeList();
        this.props.fetchCurrentDisclosureTypeList(this.props.redactionDocumentId);
    }

    public componentDidUpdate(prevsProps: IModalWindowImportModelProps): void {
        const langRule = changeLang(this.props.redactionLanguage);
        const labels = {
            noAttachmentErrorLabel: initialLabel,
            noDocumentErrorLabel: initialLabel,
            alertLabel: initialLabel,
            okLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.props.modifiedLabels.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        if (!this.state.amandaAttachmentsList.length && prevsProps.amandaAttachmentsListLoading) {
            this.props.openModalWindow(ALERT_DIALOG_MODAL,
                {
                    id:NO_AMANDA_ATTACHMENT_FOUND,
                    title: labels.alertLabel[langRule],
                    message:labels.noDocumentErrorLabel[langRule],
                    okButtonTitle: labels.okLabel[langRule],
                });
        } else if (!this.state.redactionDocuments.length && prevsProps.redactionDocumentListLoading) {
            this.props.openModalWindow(ALERT_DIALOG_MODAL,
                {
                    id:NO_REDACTION_DOCUMENT_FOUND,
                    title: labels.alertLabel[langRule],
                    message: labels.noDocumentErrorLabel[langRule],
                    okButtonTitle: labels.okLabel[langRule],
                });
        }
        if (prevsProps.edmsFiles !== this.props.edmsFiles) {
            this.setState({ edmsFiles: this.props.edmsFiles });
        }
    }

    public render(): JSX.Element {
        const {isInsertPage} = this.props.modalSpecificProps;
        const {calcPagesProgress} = this.state;
        const {modifiedLabels, redactionLanguage} = this.props;
        const labels = {
            importSourceLabel: initialLabel,
            localFileLabel: initialLabel,
            edmsLabel: initialLabel,
            redactionDocumentLabel: initialLabel,
            amandaAttachmentLabel: initialLabel,
            scannerLabel: initialLabel,
            nextLabel: initialLabel,
            clearLabel: initialLabel,
            folderAttachmentLabel: initialLabel,
            edmsTitle: initialLabel,
            redactionDocumentsLabel: initialLabel,
            noDefaultDisclosureLabel: initialLabel,
            deselectAllLabel: initialLabel,
            selectAllLabel:initialLabel,
            docNameHeaderLabel: initialLabel,
            pagesHeaderLabel: initialLabel,
            sizeHeaderLabel:initialLabel,
            startPageHeaderLabel: initialLabel,
            endPageHeaderLabel: initialLabel,
            fileExtensionErrorLabel: initialLabel,
            docTypeHeaderLabel: initialLabel,
            docDescriptionHeaderLabel: initialLabel,
            applyOCRlabel: initialLabel,
            autoDeskewLabel: initialLabel,
            conversionOptionsLabel: initialLabel,
            redactionOptionsLabel: initialLabel,
            assignToStackLabel: initialLabel,
            includeAnnotationsLabel: initialLabel,
            includeDisclosureLabel: initialLabel,
            importLabel: initialLabel,
            unsupportedFileLabel: initialLabel,
            bigFileErrorLabel: initialLabel,
            selectScannerLabel: initialLabel,
            resolutionLabel: initialLabel,
            colorDepthLabel: initialLabel,
            balckAndWhiteLabel: initialLabel,
            colorGrayLabel: initialLabel,
            scannerColorLabel: initialLabel,
            scannerModeLabel: initialLabel,
            multiPageLabel: initialLabel,
            multiBatchLabel: initialLabel,
            scanLabel: initialLabel,
            customSettingsLabel: initialLabel,
            duplexLabel: initialLabel,
            autoFeederLabel: initialLabel,
        };
        const langRule = changeLang(redactionLanguage);

        resourceBundle.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        const importSelectOptions = [
            {
                label: labels.localFileLabel[langRule],
                value: LOCAL_FILE,
            },
            {
                label: labels.redactionDocumentLabel[langRule],
                value: REDACTION_DOCUMENT,
            },
            {
                label: labels.edmsLabel[langRule],
                value: EDMS_FILES,
            },
        ];
        let options = this.props.scanner.isScannerAvailable

            ? [
                ...importSelectOptions,
                {
                    value: SCANNER,
                    label: labels.scannerLabel[langRule],
                },
            ]
            : importSelectOptions;

        if(!isInsertPage) {
            options = [...options, { label: labels.amandaAttachmentLabel[langRule], value: AMMANDA_ATTACHMENT}];
        }

        const getDisclosureOptions = this.props.disclosureTypesList.map((disclosure: IDisclosureOptions) => ({
            value: `${disclosure.id}`,
            label: disclosure.name,
        }));
        const getFullDisclosureOptions = [
            ...emptySelectOption,
            ...getDisclosureOptions,
        ];
        const edmsFileListHeaders = getEDMSFileListHeaders(this.handleCheck,
            this.handleChangePagination,
            isInsertPage,
            this.handleCheckAll,
            labels,
            langRule);
        const fileListHeaders = getFileListHeaders(this.handleCheck,
            this.handleChangePagination,
            isInsertPage,
            this.handleCheckAll,
            labels,
            langRule);
        const documentListHeaders = getDocumentListHeaders(
            this.handleCheck,this.handleChangePagination, isInsertPage,this.handleCheckAll,labels,langRule);
        const noImportPermissions = !this.props.permissions.amandaAttachmnentsImport &&
            !this.props.permissions.localFilesImport &&
            (!this.props.scanner.isScannerAvailable || !this.props.permissions.scannerImports) &&
            !this.props.permissions.redactionDocumentsImports &&
            !this.props.permissions.edmsAttachmentsImport;
          
        return (
            <AutoSizer>
                {({width, height}: { width: number; height: number; }): JSX.Element => (
                    <div className='modal-window-import' style={{width, height}}>
                        <div className='modal-window-import-title-selects-button'>
                            <div className='modal-window-import-select'>
                                <SelectMaterialBuilder
                                    value={this.state.typeOfImport}
                                    paramName={'name'}
                                    label={`${labels.importSourceLabel[langRule]}:`}
                                    options={this.filterOptionsByPermission(options)}
                                    handleSelectChange={this.handleChange}
                                    variant='standard'
                                    disabled={!this.props.hasDefaultDisclosure || noImportPermissions}
                                />
                            </div>
                            <div className='modal-window-import-button'>
                                <div className='modal-window-import-button-div-but-nex'>
                                    <Button
                                        className='outlined'
                                        variant='outlined'
                                        size='small'
                                        onClick={(): void => this.handleNextClick(labels)}
                                        disabled={!this.props.hasDefaultDisclosure || noImportPermissions}
                                    >
                                        {labels.nextLabel[langRule]}
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        className='outlined padding-0'
                                        variant='outlined'
                                        size='small'
                                        onClick={this.handleClearClick}
                                        disabled={!this.props.hasDefaultDisclosure || noImportPermissions}
                                    >
                                        {labels.clearLabel[langRule]}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {
                            !this.props.hasDefaultDisclosure &&
                                <div className='error-message-import padding-left'>
                                    {labels.noDefaultDisclosureLabel[langRule]}
                                </div>
                        }
                        <div className='hidden'>
                            <input
                                multiple={true}
                                type='file'
                                id='file'
                                ref={this.input}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => this.openFile(e)}
                            />
                        </div>
                        {
                            !!calcPagesProgress &&
                            <LinearProgress
                              variant='determinate'
                              value={calcPagesProgress}
                            />
                        }
                        {
                            !!this.state.files.length &&
                            // Component for local & scanner
                            <LocalFiles
                              isDocuments={false}
                              isLoading={false}
                              isFormInvalid={this.state.isFormInvalid}
                              data={this.state.files}
                              columns={fileListHeaders}
                              disclosureTypesList={getFullDisclosureOptions}
                              additionalParams={this.state.additionalParams}
                              handleImport={this.handleSubmitImportFiles}
                              handleAdditionalParam={this.handleAdditionalParam}
                              isAutoDeskewVisible={true}
                              isInsertPage={isInsertPage}
                              labels={labels}
                              langRule={langRule}
                              handleSortTable = {this.handleSortTable}
                              pageName ={'LocalFile'}
                              sortParams={this.state.sortParams ? this.state.sortParams : null}
                            />
                        }
                        {
                            !!this.state.edmsFiles.length &&
                            // Component for Edms Files
                            <LocalFiles
                              isDocuments={false}
                              isLoading={false}
                              isFormInvalid={this.state.isFormInvalid}
                              data={this.state.edmsFiles}
                              columns={edmsFileListHeaders}
                              disclosureTypesList={getFullDisclosureOptions}
                              additionalParams={this.state.additionalParams}
                              handleImport={this.handleSubmitImportEdmsFiles}
                              handleAdditionalParam={this.handleAdditionalParam}
                              isAutoDeskewVisible={true}
                              isInsertPage={isInsertPage}
                              labels={labels}
                              langRule={langRule}
                              handleSortTable = {this.handleSortTable}
                              pageName ={'EDMSDocuments'}
                              sortParams={this.state.sortParams ? this.state.sortParams : null}
                            />
                        }
                        {
                            !!this.state.redactionDocuments.length &&
                            // Component for redactionDocuments
                            <LocalFiles
                              isDocuments={true}
                              isLoading={this.props.isDocumentsUploading}
                              isFormInvalid={this.state.isFormInvalid}
                              data={this.state.redactionDocuments}
                              columns={documentListHeaders}
                              disclosureTypesList={getFullDisclosureOptions}
                              additionalParams={this.state.additionalParams}
                              handleImport={this.handleSubmitImportDocuments}
                              handleAdditionalParam={this.handleAdditionalParam}
                              handleSortTable = {this.handleSortTable}
                              pageName ={'redactionDocuments'}
                              sortParams={this.state.sortParams ? this.state.sortParams : null}
                              isAutoDeskewVisible={false}
                              isInsertPage={isInsertPage}
                              labels={labels}
                              langRule={langRule}
                            />
                        }
                        {
                            !!this.state[AMANDA_ATTACHMENTS_LIST].length &&
                            // Component for AMANDA Attachments
                            <LocalFiles
                              isDocuments={false}
                              isLoading={false}
                              isFormInvalid={this.state.isFormInvalid}
                              data={this.state[AMANDA_ATTACHMENTS_LIST]}
                              columns={getAmandaAttachmentHeaders(
                                this.handleCheck,this.handleCheckAll,labels,langRule)}
                              disclosureTypesList={getFullDisclosureOptions}
                              additionalParams={this.state.additionalParams}
                              handleImport={this.handleSubmitImportAmandaAttachments}
                              handleAdditionalParam={this.handleAdditionalParam}
                              isAutoDeskewVisible={false}
                              isInsertPage={false}
                              labels={labels}
                              langRule={langRule}
                              handleSortTable = {this.handleSortTable}
                              pageName ={'AMANDAAttachments'}
                              sortParams={this.state.sortParams ? this.state.sortParams : null}
                            />
                        }
                        {
                            this.state.scannerActive && !this.state.files.length &&
                            <Scanner
                              openFile={this.openFile}
                              configURL={this.props.scanner.webTwainConfig}
                              initialURL={this.props.scanner.webTwainInstall}
                              labels={labels}
                              langRule={langRule}
                            />
                        }
                    </div>
                )}
            </AutoSizer>
        );
    }

    private filterOptionsByPermission = (options: ISelectOptions[]): ISelectOptions[] =>
        options.filter((option: ISelectOptions) => {
            const {
                amandaAttachmnentsImport,
                localFilesImport,
                scannerImports,
                redactionDocumentsImports,
                edmsAttachmentsImport,
            } = this.props.permissions;

            return option.value === AMMANDA_ATTACHMENT && amandaAttachmnentsImport ||
                option.value === LOCAL_FILE && localFilesImport ||
                option.value === SCANNER && scannerImports ||
                option.value === REDACTION_DOCUMENT && redactionDocumentsImports ||
                option.value === EDMS_FILES && edmsAttachmentsImport;
        })

    private handleNextClick = (labels: any): void => {
        const {typeOfImport} = this.state;
        const langRule = changeLang(this.props.redactionLanguage);

        if (typeOfImport === LOCAL_FILE) {
            this.setState({scannerActive: false, files:[]});
            this.input.current.click();
            this.props.clearSearchReductionDocuments();
        } else if (typeOfImport === EDMS_FILES) {
            this.setState({
                scannerActive: false,
                files: [],
            });
            this.props.clearSearchReductionDocuments();
            this.props.handleResize(resizeImportToWidth, resizeImportToHeight, resizeMinW, resizeMinH);
            this.props.openModalWindow(IMPORT_FROM_EDMS, {
                id: IMPORT_FROM_EDMS,
                title: labels.edmsTitle[langRule],
            });
        } else if (typeOfImport === REDACTION_DOCUMENT) {
            this.setState({
                scannerActive: false,
                files:[],
            });
            this.props.clearSearchReductionDocuments();
            this.props.handleResize(resizeImportToWidth, resizeImportToHeight, resizeMinW, resizeMinH);
            this.props.openModalWindow(SEARCH_REDACTION_DOCUMENTS, {
                id: SEARCH_REDACTION_DOCUMENTS,
                title: labels.redactionDocumentsLabel[langRule],
            });
        } else if (typeOfImport === AMMANDA_ATTACHMENT) {
            this.setState({
                scannerActive: false,
                files:[],
            });
            this.props.clearSearchReductionDocuments();
            this.props.handleResize(resizeImportToWidth, resizeImportToHeight, resizeMinW, resizeMinH);
            this.props.openModalWindow(SEARCH_REDACTION_DOCUMENTS, {
                id: SEARCH_REDACTION_DOCUMENTS,
                title: labels.folderAttachmentLabel[langRule],
            });
        } else if (typeOfImport === SCANNER) {
            this.setState({
                scannerActive: true,
                files:[],
            });
            this.props.clearSearchReductionDocuments();
            this.props.handleResize(DEFAULT_MEDIUM_WIDTH, DEFAULT_HEIGHT_LARGE, MIN_WIDTH_LARGE, MIN_HEIGHT_LARGE);
        }
    }

    private handleClearClick = (): void => {
        this.setState({
            [FILES]: [],
            [AMANDA_ATTACHMENTS_LIST]: [],
            scannerActive: false,
        });
        this.input.current.value = '';
        this.props.handleResize(DEFAULT_MEDIUM_WIDTH, DEFAULT_HEIGHT_SMALL, MIN_WIDTH_LARGE, MIN_HEIGHT_SMALL);
        this.props.clearSearchReductionDocuments();
    }

    private handleChange = (param: string, val: string): void => {
        this.setState({typeOfImport: val});
    }

    private handleChangePagination = (val: number, param: string, id: string, type: string): void => {
        const langRule = changeLang(this.props.redactionLanguage);
        const labels = {
            pageCountErrorLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.props.modifiedLabels.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.setState((prevState: IModalWindowImportState) => {
            const calcValidForms = new Array(this.state[type].length).fill(false);
            const newFiles = this.state[type].map((item: IFile|IDocuments, index: number) => {
                const newFile = item.id === id ? {
                    ...item,
                    [param]: val,
                } : item;

                if(
                    item.isChecked &&
                    (
                        newFile.from < 1 || newFile.to < 1 ||
                        newFile.to > newFile.totalPages || newFile.from > newFile.totalPages ||
                        !Number.isInteger(newFile.to) ||
                        !Number.isInteger(newFile.from) ||
                        newFile.from > newFile.to
                    ) &&
                    item.totalPages
                ) {
                    calcValidForms[index] = true;

                    if (newFile.from > newFile.to  && newFile.from <= newFile.totalPages) {
                        newFile.pageCountError = labels.pageCountErrorLabel[langRule];
                    } else {
                        newFile.pageCountError = '';
                    }
                } else if(newFile.from <= newFile.to) {
                    newFile.pageCountError = '';
                }

                return newFile;
            });
            const calcValidForm = calcValidForms.every((flag: boolean) => !flag);

            return {
                ...prevState,
                [type]: newFiles,
                isFormInvalid: !calcValidForm,
            };
        });
    }

    private openFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const newFiles: FileList = event.target.files;
        const filteredFiles = [];
        const titlesOfBigFiles = [];
        const titlesOfUnSupportedFiles = [];
        let biggestFileSize = 0;
        const langRule = changeLang(this.props.redactionLanguage);
        const labels = {
            unsupportedFileLabel: initialLabel,
            bigFileErrorLabel: initialLabel,
            warningLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.props.modifiedLabels.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        Array.prototype.slice.call(newFiles).map((file: File) => {
            if (file.size <= FILE_2GB_SIZE) {
                if (regexFiles.test(file.name.toLowerCase())) {
                    filteredFiles.push({
                        id: uuidv4(),
                        file,
                        isChecked: true,
                    });

                    if(file.size > biggestFileSize) {
                        biggestFileSize = file.size;
                    }
                } else {
                    titlesOfUnSupportedFiles.push(file.name);
                }
            } else {
                titlesOfBigFiles.push(file.name);
            }
        });

        if (titlesOfUnSupportedFiles.length || titlesOfBigFiles.length) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: IMPORT_ERROR_TITLE_MESSAGE,
                title: labels.warningLabel[langRule],
                message: FileImportErrors([{
                    title: labels.unsupportedFileLabel[langRule],
                    filesNames: titlesOfUnSupportedFiles,
                }, {
                    title: labels.bigFileErrorLabel[langRule],
                    filesNames: titlesOfBigFiles,
                }]),
            });
        }

        const anAsyncFunction = async (file: IFile): Promise<IFile> => {
            return new Promise((resolve: (val: IFile) => void): void => {
                const size = file.file.size;
                const reader = new FileReader();
                let rest = size;
                const CHUNK_SIZE = 1024 * 10000;
                const STEP_PROGRESS = 5;
                const HUNDRED = 100;
                let pages = 0;

                if (!regexPdfFile.test(file.file.name.toLowerCase())) {
                    resolve({
                        ...file,
                        from: null,
                        to: null,
                        totalPages: null,
                    });
                }

                const seek = (): void => {
                    const start = rest - CHUNK_SIZE;
                    const slice = file.file.slice(start, rest);
                    const calcPagesProgress = Math.ceil(HUNDRED - HUNDRED * rest / size);

                    rest -= CHUNK_SIZE;
                    reader.readAsText(slice);

                    if(
                        biggestFileSize === file.file.size &&
                        this.state.calcPagesProgress - calcPagesProgress < -STEP_PROGRESS
                    ) {
                        this.setState({
                            calcPagesProgress,
                        });
                    }
                };
                const getNumPages = async (doc: File): Promise<PDFPage[]> => {
                    const arrayBuffer = await readFile(doc);

                    if(regexPdfFile.test(doc.name.toLowerCase())) {
                        const pdf = await PDFDocument.load(arrayBuffer);
                        const totalPages = pdf.getPages();

                        return totalPages;
                    }

                    return;

                };
                const readFile = (doc: Blob): Promise<string | ArrayBuffer> => {

                    return new Promise((
                        resolved: (value: string | ArrayBuffer | PromiseLike<string | ArrayBuffer>) => void,
                        reject: (reason?: any) => void): void => {

                        const fileReader = new FileReader();

                        fileReader.onload = (): void => resolved(fileReader.result);
                        fileReader.onerror = (error: ProgressEvent): void => reject(error);

                        return fileReader.readAsArrayBuffer(doc);
                    });
                };

                reader.onloadend = (): void => {

                    getNumPages(file.file).then((value: PDFPage[]) => {
                        const pagesCount = value && value.length ? value.length : 0;

                        if (pagesCount) {
                            pages = pagesCount;
                        }

                        if (rest > 0) {
                            seek();
                        } else {
                            resolve({
                                ...file,
                                from: 1,
                                to: pages,
                                totalPages: pages,
                            });

                        }
                    });

                };
                seek();

            });
        };

        const getFilesWithPages = async (filesZ: IFile[]): Promise<IFile[]> => {
            return await Promise.all(filesZ.map(async (file: IFile): Promise<IFile> => await anAsyncFunction(file)));
        };

        this.setState({
           calcPagesProgress: 1,
        });

        const files = await getFilesWithPages(filteredFiles);

        biggestFileSize = 0;

        this.setState((prevState: IModalWindowImportState): IModalWindowImportState => {
            if (!prevState.files.length && filteredFiles.length) {
                this.props.handleResize(resizeImportToWidth, resizeImportToHeight, resizeMinW, resizeMinH);
            }

            return {
                ...prevState,
                files: [...prevState.files, ...files],
                calcPagesProgress: null,
            };
        });
    }

    private handleCheck = (id: number|string, type: string): void => {
        const langRule = changeLang(this.props.redactionLanguage);
        const labels = {
            pageCountErrorLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.props.modifiedLabels.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.setState((prevState: IModalWindowImportState) => {
            const calcValidForms = new Array(prevState[type].length).fill(false);
            const pagesError = labels.pageCountErrorLabel[langRule];
            const newFiles = prevState[type].map((item: IFile|IDocuments, index: number) => {
                if(
                    item.id !== id && item.isChecked &&
                    (
                        item.from < 1 || item.to < 1 ||
                        item.to > item.totalPages || item.from > item.totalPages ||
                        !Number.isInteger(item.to) ||
                        !Number.isInteger(item.from) ||
                        item.from > item.to
                    ) &&
                    item.totalPages
                ) {
                    calcValidForms[index] = true;
                }

                if (item.id === id) {
                    calcValidForms[index] = item.isChecked ? false :
                    (
                        item.from < 1 || item.to < 1 ||
                        item.to > item.totalPages || item.from > item.totalPages ||
                        (item.to && !Number.isInteger(item.to)) ||
                        (item.from && !Number.isInteger(item.from)) ||
                        item.from > item.to
                    );

                    return {
                        ...item,
                        pageCountError: item.isChecked ? '' : item.from > item.to && item.from <= item.totalPages ?
                         pagesError : item.pageCountError,
                        isChecked: !item.isChecked,
                    };
                }

                return item;
            });

            const invalidFlag = calcValidForms.every((flag: boolean) => !flag);

            return {
                ...prevState,
                [type]: newFiles,
                isFormInvalid: !invalidFlag,
            };
        });
    }
    private handleCheckAll = (isChecked: boolean, type: string): void => {
        const langRule = changeLang(this.props.redactionLanguage);
        const labels = {
            pageCountErrorLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.props.modifiedLabels.map((resource: any) => {
            if (getImportLabelsByKey(resource.resourceKey)) {
                labels[getImportLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.setState((prevState: IModalWindowImportState) => {
            const calcValidForms = new Array(prevState[type].length).fill(false);
            const pagesError = labels.pageCountErrorLabel[langRule];
            const newFiles = prevState[type].map((item: IFile|IDocuments, index: number) => {
                if( isChecked &&
                    (
                        item.from < 1 || item.to < 1 ||
                        item.to > item.totalPages || item.from > item.totalPages ||
                        (item.to && !Number.isInteger(item.to)) ||
                        (item.from && !Number.isInteger(item.from)) ||
                        item.from > item.to
                    ) &&
                    item.totalPages
                ) {
                    calcValidForms[index] = true;
                }

                return {
                    ...item,
                    pageCountError: !isChecked ? '' : item.from > item.to && item.from <= item.totalPages ?
                        pagesError : item.pageCountError,
                    isChecked,
                };
            });

            const invalidFlag = calcValidForms.every((flag: boolean) => !flag);

            return {
                ...prevState,
                [type]: newFiles,
                isFormInvalid: !invalidFlag,
            };
        });
    }
    private handleAdditionalParam = (param: string, val: string | boolean): void => {
        this.setState((prevState: IModalWindowImportState): IModalWindowImportState => ({
            ...prevState,
            additionalParams: {
                ...prevState.additionalParams,
                [param]: val,
            },
        }));
    }

    private handleSortTable = (param: string, val: string , pageName : string): void => {
        const pageData: PageData = {
            pageName: pageName,
            columnName: param,
            sortOrder: val,        
        };
        this.setState({
            sortParams : pageData
        });
    }

    private handleSubmitImportFiles = (): void => {
        const {
            redactionDocumentId,
            modalSpecificProps: { isInsertPage },
            currentDocument,
            selectedPages,
            redactionMode
        } = this.props;
       if(!isInsertPage && this.state.additionalParams.stackId === 0 && redactionMode === MODE_CONTRIBUTE){
        this.props.openModalWindow(ALERT_DIALOG_MODAL, {
            id: ALERT_DIALOG_MODAL,
            title: "Mandatory Alert!!",
            okButtonTitle: OK,
            message: STACK_MANDATORY_ALERT,
        });
        return;
       }
        const checkedFiles = this.state.files.filter((file: IFile): boolean =>
            file.isChecked).map((file: IFile): File => file.file);
        const pages = this.state.files.filter((file: IFile): boolean =>
            file.isChecked).map((file: IFile, index: number): IInsertPageOptionsFromFileForOneDocument => (
            {
                index,
                startPage: file.from ? file.from : null,
                endPage: file.to ? file.to : null,
            }
        ));
        const {additionalParams} = this.state;
        const insertPageOptions = {
            pages,
            ...(!isInsertPage && { [STACK_ID]: additionalParams.stackId }),
            [OCR_PARAM]: additionalParams[OCR_PARAM],
            [DESKEW_PARAM]: additionalParams[DESKEW_PARAM],
            [ASSIGN_TO_DISCLOSURE_ID]: null,
            [FROM_SCANNER]: this.state.scannerActive,
        };

        const additionalParamsWithScanner = {...additionalParams, [FROM_SCANNER]: this.state.scannerActive};

        isInsertPage ?
        this.props.insertFiles(
            redactionDocumentId,
            currentDocument.id,
            selectedPages[0],
            checkedFiles,
            insertPageOptions,
            Number(this.state.additionalParams[ASSIGN_TO_DISCLOSURE_ID]),
        ):

        this.props.uploadFiles(checkedFiles, additionalParamsWithScanner, redactionDocumentId);

        if ( !isInsertPage || (!additionalParams[ASSIGN_TO_DISCLOSURE_ID]) ) {
            this.props.handleCloseAllModalWindows();
        }
    }

    private handleSubmitImportEdmsFiles = (): void => {
        const {
            redactionDocumentId,
            modalSpecificProps: { isInsertPage },
            currentDocument,
            selectedPages,
            redactionMode
        } = this.props;
        if (!isInsertPage && this.state.additionalParams.stackId === 0 && redactionMode === MODE_CONTRIBUTE) {
            this.props.openModalWindow(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                title: "Mandatory Alert!!",
                okButtonTitle: OK,
                message: STACK_MANDATORY_ALERT,
            });
            return;
        }
        const checkedEdmsFiles = this.state.edmsFiles.map((edmsFile: IEdmsFile): IEdmsFileDto => ({
            name: edmsFile.name,
            size: edmsFile.size,
            checked: edmsFile.isChecked,
        }));
        const { additionalParams } = this.state;

        const additionalParamsWithScanner = { ...additionalParams, [FROM_SCANNER]: false };

        this.props.uploadEdmsFiles(checkedEdmsFiles, additionalParamsWithScanner, redactionDocumentId);

        if (!isInsertPage || (!additionalParams[ASSIGN_TO_DISCLOSURE_ID])) {
            this.props.handleCloseAllModalWindows();
        }
    }

    private handleSubmitImportDocuments = (): void => {
        const {
            redactionDocumentId,
            modalSpecificProps: { isInsertPage },
            currentDocument,
            selectedPages,
            selectedDocumentsRedactionId,
        } = this.props;
        const {additionalParams} = this.state;
        const documentIds = this.state.redactionDocuments.filter((doc: IDocuments): boolean =>
            doc.isChecked).map((doc: IDocuments): number => doc.id);
        const disclosureId = additionalParams.assignToDisclosureTypeId === ''
            ? null : Number(additionalParams.assignToDisclosureTypeId);
        const data = {
            ...(!isInsertPage && { [STACK_ID]: additionalParams.stackId }),
            [ASSIGN_TO_DISCLOSURE_ID]: disclosureId,
            [INCLUDE_ANNOTATIONS_PARAM]: additionalParams.includeAnnotations,
            [INCLUDE_DISCLOSURES_PARAM]: additionalParams.includeDisclosures,
            redactionDocumentId: selectedDocumentsRedactionId,
            documentIds,
        };
        const documents = this.state.redactionDocuments.filter((doc: IDocuments): boolean =>
            doc.isChecked).map((doc: IDocuments): IInsertPageOptionsFromRedactionForOneDocument => {

            return {
                redactionDocumentId: selectedDocumentsRedactionId,
                documentId: doc.id,
                startPage: doc.from,
                endPage: doc.to,
            };
        });

        const insertPageOptions = {
            ...(!isInsertPage && { [STACK_ID]: additionalParams.stackId }),
            [OCR_PARAM]: additionalParams.ocrRequired,
            [DESKEW_PARAM]: additionalParams.autoDeskew,
            [ASSIGN_TO_DISCLOSURE_ID]: disclosureId,
            [INCLUDE_ANNOTATIONS_PARAM]: additionalParams.includeAnnotations,
            [INCLUDE_DISCLOSURES_PARAM]: additionalParams.includeDisclosures,
            documents,
        };

        if(isInsertPage) {
            this.props.insertRedactionDocuments(
                redactionDocumentId,
                currentDocument.id,
                selectedPages[0],
                insertPageOptions,
            );
        } else {
            this.props.uploadRedactionDocuments(
                redactionDocumentId,
                additionalParams.ocrRequired,
                additionalParams.autoDeskew,
                additionalParams.stackId,
                data,
            );
            this.props.handleCloseAllModalWindows();
        }
    }

    private handleSubmitImportAmandaAttachments = (): void => {
        const {redactionDocumentId} = this.props;
        const {additionalParams} = this.state;
        const attachmentIds = this.state[AMANDA_ATTACHMENTS_LIST].reduce((
            acc: number[], attachment: IAmandaAttachmentState,
        ): number[] => {
            return attachment.isChecked && attachment.isValidExtension ? [...acc, attachment.attachmentRsn] : acc;
        }, []);
        const disclosureId = additionalParams.assignToDisclosureTypeId === ''
            ? null : Number(additionalParams.assignToDisclosureTypeId);
        const data = {
            [ASSIGN_TO_DISCLOSURE_ID]: disclosureId,
            attachmentIds,
        };

        this.props.importDocumentFromAttachments(
            redactionDocumentId,
            additionalParams.ocrRequired,
            additionalParams.autoDeskew,
            additionalParams.stackId,
            data,
        );
    }
}

const mapStateToProps = (state: IState): IModalWindowImportStateProps => ({
    scanner: getScanner(state),
    redactionDocuments: getSearchRedactionDocuments(state),
    selectedDocumentsRedactionId: getSelectedDocumentsRedactionId(state),
    redactionDocumentId: getRedactionDocumentId(state),
    disclosureTypesList: getCurrentDisclosureTypes(state),
    currentDocument: getCurrentDocument(state),
    selectedPages: getSelectedPages(state),
    isDocumentsUploading: getImportDocumentsLoading(state),
    hasDefaultDisclosure: hasCurrentReductionDefaultDisclosure(state),
    permissions: getImportPermissions(state),
    [AMANDA_ATTACHMENTS_LIST]: getAmandaAttachments(state),
    amandaAttachmentsListLoading: getAmandaAttachmentsPending(state),
    redactionDocumentListLoading: getSearchRedactionDocumentsPending(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLanguage: getRedactionLanguage(state),
    redactionMode: getRedactionMode(state),
    edmsFiles: getEDMSFiles(state)
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<IState, IModalWindowImportDispatchProps, AnyAction>): IModalWindowImportDispatchProps => ({
        uploadFiles: (files: File[], param: IAdditionalImportParam, redactionDocumentId: number): void => {
            dispatch(fetchFiles(files, param, redactionDocumentId));
        },
        uploadEdmsFiles: (edmsFiles: IEdmsFileDto[], param: IAdditionalImportParam, redactionDocumentId: number): void => {
            dispatch(fetchEDMSFiles(edmsFiles, param, redactionDocumentId));
        },
        uploadRedactionDocuments: (
            redactionDocumentId: number,
            ocrRequired: boolean,
            autoDeskew: boolean,
            stackId: number,
            data: IUploadRedactionDocumentsDto,
        ): void => {
            dispatch(fetchRedactionDocuments(redactionDocumentId, ocrRequired, autoDeskew, stackId, data ));
        },
        importDocumentFromAttachments: (
            redactionDocumentId: number,
            ocrRequired: boolean,
            autoDeskew: boolean,
            stackId: number,
            data: IUploadAttachmentDto,
        ): void => {
            dispatch(importDocumentFromAttachments(redactionDocumentId, ocrRequired, autoDeskew, stackId, data ));
        },
        insertFiles: (
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            files: File[],
            insertPageOptions: IInsertPageOptionsFromFile,
            assignToDisclosureTypeId: number,
        ): void => {
            dispatch(insertFiles(
                redactionDocumentId,
                documentId,
                pageId,
                files,
                insertPageOptions,
                assignToDisclosureTypeId));
        },
        insertRedactionDocuments: (
            redactionDocumentId: number,
            documentId: number,
            pageId: number,
            insertPageOptions: IInsertPageOptionsFromRedaction,
        ): void => {
            dispatch(insertRedactionDocuments(
                redactionDocumentId,
                documentId,
                pageId,
                insertPageOptions));
        },
        openModalWindow: (data: string, message: IModalProps): void => {
            dispatch(openModalWindow(data, message));
        },
        handleCloseModalWindow: (id: string): void => {
            dispatch(handleCloseModalWindow(id));
        },
        handleCloseAllModalWindows: (): void => {
            dispatch(handleCloseAllModalWindows());
        },
        clearSearchReductionDocuments: (): void => {
            dispatch(clearSearchReductionDocuments());
        },
        // ToDo: we really need changePageDisclosure here?
        changePageDisclosure: ({pageIds, documentIds, redactionDocumentId, disclosureTypeId}: IModalIdsData): void => {
            dispatch(changeDisclosure({pageIds, documentIds, redactionDocumentId, disclosureTypeId}));
        },
        fetchAllDisclosureTypeList: (): void => {
            dispatch(fetchAllDisclosureTypeList());
        },
        fetchCurrentDisclosureTypeList: (redactionDocumentId: number): void => {
            dispatch(fetchCurrentDisclosureTypeList(redactionDocumentId));
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindowImport);

export const SET_SEARCH_RESULTS_SUCCESS = 'reduction/SET_SEARCH_RESULTS_SUCCESS';
export const MODIFY_SEARCH_RESULT = 'reduction/MODIFY_SEARCH_RESULT';
export const SET_ORIGINAL_SEARCH_RESULT = 'reduction/SET_ORIGINAL_SEARCH_RESULT';
export const SET_SEARCH_RESULTS_PENDING = 'reduction/SET_SEARCH_RESULTS_PENDING';
export const SET_SEARCH_RESULTS_FAILURE = 'reduction/SET_SEARCH_RESULTS_FAILURE';

export const SET_MODIFIED_LABELS_PENDING = 'reduction/GET_MODIFIED_LABELS_PENDING';
export const SET_MODIFIED_LABELS_SUCCESS = 'reduction/GET_MODIFIED_LABELS_SUCCESS';
export const SET_MODIFIED_LABELS_FAILURE = 'reduction/GET_MODIFIED_LABELS_FAILURE';

export const UPDATE_SYSTEM_MESSAGES_PENDING = 'reduction/UPDATE_SYSTEM_MESSAGES_PENDING';
export const UPDATE_SYSTEM_MESSAGES_SUCCESS = 'reduction/UPDATE_SYSTEM_MESSAGES_SUCCESS';
export const UPDATE_SYSTEM_MESSAGES_FAILURE = 'reduction/UPDATE_SYSTEM_MESSAGES_FAILURE';

export const SET_AUTH_FLAG = 'reduction/SET_AUTH_FLAG';

export const SET_SELECTED_SYSTEM_MESSAGE = 'reduction/SET_SELECTED_SYSTEM_MESSAGE';

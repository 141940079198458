export const getQueryStringParams = (query: string): { [param: string]: string } => query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: { [param: string]: string }, param: string) => {
                const [key, value] = param.split('=');

                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';

                return params;
            }, {},
        )
    : {};

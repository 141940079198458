import {
    GET_WATERMARKS_SUCCESS,
    GET_WATERMARKS_PENDING,
    GET_WATERMARKS_FAILURE,
    POST_WATERMARK_SUCCESS,
    POST_WATERMARK_FAILURE,
    POST_WATERMARK_PENDING,
    DELETE_WATERMARK_FAILURE,
    DELETE_WATERMARK_SUCCESS,
    DELETE_WATERMARK_PENDING,
    SET_OPTIONS_WATERMARK,
    CLEAR_OPTIONS_WATERMARK,
    CHANGE_OPTIONS_WATERMARK,
    PUT_WATERMARK_PENDING,
    PUT_WATERMARK_SUCCESS, PUT_WATERMARK_FAILURE,
    GET_WATERMARK_IMAGE_FAILURE,
    GET_WATERMARK_IMAGE_PENDING,
} from './constants';

import { IWatermarksManagementState, IWatermark } from './watermarksManagement.model';
import { AnyAction } from 'redux';
import { sortByName } from '../../../utils/watermarks.util';

export const initialWatermarkOptions: IWatermark =  {
    id: null,
    name: '',
    text: '',
    requestType: [],
};

const initialState: IWatermarksManagementState = {
    watermarksList: [],
    currentWatermarkOptions: initialWatermarkOptions,
    watermarksPending: false,
    error: null,
};

const requesterReportTypes = (state: IWatermarksManagementState = initialState,
                              {type, payload}: AnyAction): IWatermarksManagementState => {
    switch (type) {
        case GET_WATERMARKS_FAILURE:
        case POST_WATERMARK_FAILURE:
        case PUT_WATERMARK_FAILURE:
        case DELETE_WATERMARK_FAILURE:
        case GET_WATERMARK_IMAGE_FAILURE:
            return {
                ...state,
                error: payload,
                watermarksPending: false,
            };

        case GET_WATERMARKS_PENDING:
        case POST_WATERMARK_PENDING:
        case PUT_WATERMARK_PENDING:
        case DELETE_WATERMARK_PENDING:
        case GET_WATERMARK_IMAGE_PENDING:
            return {
                ...state,
                watermarksPending: true,
                error: null,
            };

        case GET_WATERMARKS_SUCCESS:
            return {
                ...state,
                watermarksList: sortByName([...payload]),
                watermarksPending: false,
            };
        case POST_WATERMARK_SUCCESS:
            return {
                ...state,
                watermarksList:  sortByName([...state.watermarksList, payload]),
                watermarksPending: false,
            };
        case PUT_WATERMARK_SUCCESS:
            return {
                ...state,
                watermarksList:  sortByName(state.watermarksList.map((item: IWatermark) =>
                    item.id !== payload.id ? item : payload)),
                watermarksPending: false,
            };
        case DELETE_WATERMARK_SUCCESS:
            return {
                ...state,
                watermarksList: sortByName([...state.watermarksList.filter(
                    (watermark: IWatermark) => watermark.id !== payload.id,
                )]),
                watermarksPending: false,
            };

        case SET_OPTIONS_WATERMARK:
            return {
                ...state,
                currentWatermarkOptions: {...payload},
                watermarksPending: false,
            };
        case CHANGE_OPTIONS_WATERMARK:
            return {
                ...state,
                currentWatermarkOptions: {
                    ...state.currentWatermarkOptions,
                    ...payload,
                },
            };
        case CLEAR_OPTIONS_WATERMARK:
            return {
                ...state,
                currentWatermarkOptions: initialWatermarkOptions,
            };
        default:
            return state;
    }
};

export default requesterReportTypes;

import { ThunkDispatch } from 'redux-thunk/es/types';
import { action } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { IState } from '../store';
import { IModalIdsData } from '../reducers/modal/modal.model';
import { IContextData } from '../reducers/contextMenu/contextMenu.model';
import { addError } from './errorHandling';
import {
    OPEN_CONTEXTMENU,
    CLOSE_CONTEXTMENU,
    SET_REDACTOR_CONTEXT_MENU,
} from '../reducers/contextMenu/constants';
import {
    REDACTION_COMMON_VIEW_SEVER_INFO,
    REDACTION_COMMON_CONVERT_HIGHLIGHT_TO_SEVER,
} from '../../constants/permissions';

export const openContextMenu = (contextType: string, contextProps: IModalIdsData): AnyAction => {
    return action(OPEN_CONTEXTMENU, { contextType, contextProps });
};
export const closeContextMenu = (): AnyAction => {
    return action(CLOSE_CONTEXTMENU);
};

export const setRedactorContextMenuItems = (items: IContextData[]): AnyAction =>
    action(SET_REDACTOR_CONTEXT_MENU, items);

export const setRedactorContextMenu = (items: IContextData[]): (
    dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const { reductionMetaData: { userPermissions } } = getState();
        const viewSeverInfoPermission = userPermissions.includes(REDACTION_COMMON_VIEW_SEVER_INFO);
        const convertHighlightToSeverPermission = userPermissions.includes(REDACTION_COMMON_CONVERT_HIGHLIGHT_TO_SEVER);

        try {

            const itemsWithCheckedPermissions = items.map((item: IContextData): IContextData => {
                const noSeverInfo = item.id === REDACTION_COMMON_VIEW_SEVER_INFO && !viewSeverInfoPermission;
                const noChangeAnnotationType = item.id === REDACTION_COMMON_CONVERT_HIGHLIGHT_TO_SEVER &&
                        !convertHighlightToSeverPermission;

                return {
                    ...item,
                    disabled: noSeverInfo || noChangeAnnotationType || item.disabled,
                };
            });

            dispatch(setRedactorContextMenuItems(itemsWithCheckedPermissions));
        } catch (error) {
            dispatch(addError(error));
        }
    };

import { useState } from 'react';

export interface IUseSetOpenSelectComponentProps {
    handleChangeSever: () => void;
    handleChangeHighLight: () => void;
    handleCloseSelects: () => void;
    openSever: boolean;
    openHighlight: boolean;
}

export const useSetOpenSelectComponent = (): IUseSetOpenSelectComponentProps => {
    const [openSever, setOpenSever] = useState(false);
    const [openHighlight, setOpenHighlight] = useState(false);

    const handleCloseSelects = (): void => {
        setOpenSever(false);
        setOpenHighlight(false);
    };

    const handleChangeSever = (): void => {
        handleCloseSelects();
        setOpenSever(!openSever);
    };

    const handleChangeHighLight = (): void => {
        handleCloseSelects();
        setOpenHighlight(!openHighlight);
    };

    return {
        handleChangeSever,
        handleCloseSelects,
        handleChangeHighLight,
        openSever,
        openHighlight,
    };
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import { SelectMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { Button, Fab, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Scrollbars } from 'rc-scrollbars';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import './modalImportFromEDMS.scss';
import { ISelectOptions } from 'components/materialUiForms/marerialUiForms.model';
import { useSelector, useDispatch } from '../../../../node_modules/react-redux';
import { fetchAmandaEdmsAdaptors, fetchEdmsFiles } from '../../../redux/actions/modalSearchDocuments';
import { DELETE, OK } from '../../../constants/common.constants';
import { IValidEdms } from '../modalWindowImport/modalWindowImport.model';
import { getAmandaEDMSAdaptors } from '../../../redux/selectors/modalSearchDocument';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../../containers/localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';
import { ALERT_DIALOG_MODAL } from '../../../constants/messages.constants';
import { handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';

export const IMPORT_FROM_EDMS = 'importFromEDMS';

export const edmsLabels = {
    edmsTitle: initialLabel,
    selectEdmsAdaptor: initialLabel,
    inputUrl: initialLabel,
    addlabel: initialLabel,
    cancelLabel: initialLabel,
    oklabel: initialLabel,
    edmsMandatoryTitle: initialLabel,
    edmsUrlMandatory: initialLabel,
    edmsAdaptorMandatory: initialLabel,
};

export const getEdmsLabelsByKey = (key: string): string => {
    switch (key) {
        case 'MODAL_IMPORT_EDMS_TITLE':
            return 'edmsTitle';
        case 'MODAL_IMPORT_EDMS_SELECT_ADAPTOR':
            return 'selectEdmsAdaptor';
        case 'MODAL_IMPORT_EDMS_INPUT_URL_LABEL':
            return 'inputUrl';
        case 'MODAL_IMPORT_EDMS_ADD_LABEL':
            return 'addlabel';
        case 'MODAL_IMPORT_EDMS_CANCEL_LABEL':
            return 'cancelLabel';
        case 'MODAL_IMPORT_EDMS_OK_LABEL':
            return 'oklabel';
        case 'MODAL_IMPORT_EDMS_MANDATORY_ALERT_TITLE':
            return 'edmsMandatoryTitle';
        case 'MODAL_IMPORT_EDMS_URL_ALERT_MESSAGE':
            return 'edmsUrlMandatory';
        case 'MODAL_IMPORT_EDMS_ADAPTOR_SELECTION_ALERT_MESSAGE':
            return 'edmsAdaptorMandatory';
        default: return '';
    }
}

export const ModalImportFromEDMS = (): JSX.Element => {
    const [edmsAdaptor, setEdmsAdaptor] = useState('');
    const [fields, setFields] = useState<string[]>(['']);
    const redactionLang = useSelector(getRedactionLanguage);
    const modifiedLabels = useSelector(getModifiedLabels);
    const langRule = changeLang(redactionLang);

    const dispatch = useDispatch();
    const getEdmsAdaptors = (): void => dispatch(fetchAmandaEdmsAdaptors());
    const handleAddField = () => {
        setFields([...fields, '']);
    };
    const edmsAdaptors = useSelector(getAmandaEDMSAdaptors);
    const edmsAdaptorsOptions: ISelectOptions[] = edmsAdaptors.filter((edmsAdpt: IValidEdms) =>
        (edmsAdpt.adaptorType === 'Custom')).map((edmsAdpt: IValidEdms): ISelectOptions => ({
            label: (edmsAdpt.adaptorDesc === null || edmsAdpt.adaptorDesc.trim() === '') ?
                '[' + edmsAdpt.adaptorName + ']' : edmsAdpt.adaptorDesc,
            value: edmsAdpt.adaptorName,
        }));

    useEffect(() => {
        getEdmsAdaptors();
    }, []);

    const handleChange = (param: string, val: string): void => {
        setEdmsAdaptor(val);
    }

    const handleRemoveInput = (index: number) => {
        if (fields.length === 1) {
            return;
        }
        const updatedValues = [...fields];
        updatedValues.splice(index, 1);
        setFields(updatedValues);
    };

    const addEdmsAttachments = (): void => {
        const nonEmptyFields = fields.filter((field) => field.trim() !== '');
        if (edmsAdaptor.trim() === '' || (nonEmptyFields.length === 0 || (fields.length === 1 && fields[0].trim() === ''))) {
            if (edmsAdaptor.trim() !== '') {
                dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                    id: ALERT_DIALOG_MODAL,
                    title: edmsLabels.edmsMandatoryTitle[langRule],
                    okButtonTitle: edmsLabels.oklabel[langRule],
                    message: edmsLabels.edmsUrlMandatory[langRule],
                }));
                return;
            }
            if (edmsAdaptor.trim() === '') {
                dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
                    id: ALERT_DIALOG_MODAL,
                    title: edmsLabels.edmsMandatoryTitle[langRule],
                    okButtonTitle: edmsLabels.oklabel[langRule],
                    message: edmsLabels.edmsAdaptorMandatory[langRule],
                }));
                return;
            }
        }

        const edmsProps = {
            isEdms: true,
            fields,
            edmsAdaptor
        }
        const urlData = edmsProps.fields.filter((field) => field.trim() !== '').join(',');
        dispatch(fetchEdmsFiles(urlData, edmsProps.edmsAdaptor));
    }
    resourceBundle.map((resource: any) => {
        if (getEdmsLabelsByKey(resource.resourceKey)) {
            edmsLabels[getEdmsLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getEdmsLabelsByKey(resource.resourceKey)) {
            edmsLabels[getEdmsLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    return (
        <div className='modal-edms' >
            <div className='form-container_content'>
                <Scrollbars>
                    <div className='form-row'>
                        <label style={{ marginTop: '18px' }}>{edmsLabels.selectEdmsAdaptor[langRule]} :</label>
                        <SelectMaterialBuilder
                            className='select-class'
                            label=''
                            paramName='edmsAdaptor'
                            value={edmsAdaptor}
                            handleSelectChange={handleChange}
                            options={edmsAdaptorsOptions}
                        />
                    </div>
                        {fields.map((field, index) => (
                            <div className='modal-edms' key={index} style={{ display: 'flex', padding: '0px' }}>
                                <input
                                    style={{ display: 'block', width: '100%', height: '40px', marginTop: '5px', marginBottom: '5px' }}
                                    value={field}
                                    placeholder={edmsLabels.inputUrl[langRule]}
                                    onChange={(e) => {
                                        const newFields = [...fields];
                                        newFields[index] = e.target.value;
                                        setFields(newFields);
                                    }}
                                />
                                <IconButton
                                    aria-label={DELETE}
                                    onClick={() => handleRemoveInput(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        ))}
                    <Fab
                        variant='extended'
                        size='medium'
                        color='primary'
                        aria-label='Add'
                        onClick={handleAddField}
                        className='fab-addIcon'
                    >
                        <AddIcon fontSize={'small'} />
                    </Fab>

                </Scrollbars>
            </div >
            <Divider />
            <div className='footer-actions' >
                <Button
                    variant='outlined'
                    onClick={(): void => dispatch(handleCloseModalWindow(IMPORT_FROM_EDMS))}
                    className='modal-window__buttons outlined'
                    size='small'
                >
                    {edmsLabels.cancelLabel[langRule]}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    type='submit'
                    onClick={addEdmsAttachments}
                >
                    {edmsLabels.addlabel[langRule]}
                </Button>
            </div>
        </div>
    );
};


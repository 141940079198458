import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import LeftTopBar from '../leftTopBar/LeftTopBar';
import LeftBottomBar from '../leftBottomBar/LeftBottomBar';
import RightTopBar from '../rightTopBar/RightTopBar';
import ResizablePanels from './../../components/resizablePanels/ResizablePanels';
import { getPanelSizes } from '../../redux/selectors/localStorage';
import { setPanelSize, setRecentlyUsedExemptions, setRecentlyUsedStampsOnReferesh } from '../../redux/actions/localStorage';
import { IPanelSizes } from './../../components/resizablePanels/resizablePanels.model';
import { IState } from '../../redux/store';
import Main from '../../containers/main/Main';
import RightBottomBar from '../../containers/rightBottomBar/rightBottomBar';
import './mainPanels.styles.scss';
import { getRedactionRequestNumber } from '../../redux/selectors/initialization';
import { IValidExemptionDtoList } from '../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { RECENTLY_USED_EXEMPTION_KEY, RECENTLY_USED_STAMPS_KEY } from '../../redux/reducers/localStorage/constant';
import { getItem } from '../../utils/localStorage.util';

interface IStateProps {
    panelSizes: IPanelSizes;
    requestNumber: string;
}

interface IDispatchProps {
    setPanel: (data: IPanelSizes) => void;
    setRecentlyUsedStampOnReferesh: (requestNumber: string, lastUsedStamps: IValidExemptionDtoList[]) => void;
    setRecentlyUsedExemption: (requestNumber: string, lastUsedExemption: IValidExemptionDtoList[]) => void;
}

type IProps = IStateProps & IDispatchProps;

class MainPanels extends React.Component<IProps> {
    public componentDidMount(): void {
        this.props.setPanel(this.props.panelSizes);
    }

    public componentDidUpdate(prevsProps: IStateProps): void {

        const {
            requestNumber,
            setRecentlyUsedExemption,
            setRecentlyUsedStampOnReferesh,
        } = this.props;

        if(prevsProps.requestNumber !== requestNumber && !!requestNumber) {
            const lastUsedExemption = getItem(RECENTLY_USED_EXEMPTION_KEY + requestNumber);
            const lastUsedStamps = getItem(RECENTLY_USED_STAMPS_KEY + requestNumber);

            if (lastUsedExemption && !!lastUsedExemption.length) {
                setRecentlyUsedExemption(requestNumber, lastUsedExemption);
            }

            if (lastUsedStamps && !!lastUsedStamps.length) {
                setRecentlyUsedStampOnReferesh(requestNumber, lastUsedStamps);
            }

            if(!prevsProps.requestNumber) {
                if(!lastUsedStamps && !lastUsedExemption) {
                    setRecentlyUsedStampOnReferesh(requestNumber, []);
                    setRecentlyUsedExemption(requestNumber, []);
                }

            }
        }
    }
    public render(): JSX.Element {
        return (
            <div className='page-body'>
                <ResizablePanels
                    panelSizes={this.props.panelSizes}
                    setPanel={(panel: IPanelSizes): void => this.props.setPanel(panel)}
                >
                    {{
                        leftTop: <LeftTopBar />,
                        leftBottom: <LeftBottomBar />,
                        center: <Main />,
                        rightTop: <RightTopBar />,
                        rightBottom: <RightBottomBar />,
                    }}
                </ResizablePanels>
            </div>
        );
    }
}

const mapStateToProps = (state: IState): IStateProps => ({
    panelSizes: getPanelSizes(state),
    requestNumber: getRedactionRequestNumber(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
    setPanel: (panel: IPanelSizes): void => {
        dispatch(setPanelSize(panel));
    },
    setRecentlyUsedStampOnReferesh: (requestNumber: string, lastUsedStamps: IValidExemptionDtoList[]): void => {
        dispatch(setRecentlyUsedStampsOnReferesh(requestNumber, lastUsedStamps));
    },
    setRecentlyUsedExemption:  (requestNumber: string, lastUsedExemption: IValidExemptionDtoList[]): void => {
        dispatch(setRecentlyUsedExemptions(requestNumber, lastUsedExemption));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPanels);

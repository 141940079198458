import * as React from 'react';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import { IStatePagination, IProps } from './pagination.model';
import { InputHTMLAttributes } from 'react';
import { ENTER_BUTTON_KEYCODE, NEXT_PAGE, PREV_PAGE } from '../../../constants/common.constants';
import './pagination.styles.scss';

export class Pagination extends React.Component<IProps, IStatePagination> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            page: props.page,
        };
    }

    public render(): JSX.Element {
        const {
            page,
            dataLength,
            pageSize,
            canPrevious,
            canNext,
        } = this.props;

        const getPages = (): string => {
            const startPage = page * pageSize + 1;
            const rowsPerPage = startPage + pageSize;
            const endPage = rowsPerPage < dataLength ? rowsPerPage - 1 : dataLength;

            return `${startPage}-${endPage} of ${dataLength}`;
        };

        return (
            <div className='pagination_table'>
                <div>
                    {getPages()}
                </div>
                <div className='pagination_table-paginator'>
                    <IconButton
                        aria-label={PREV_PAGE}
                        onClick={(): void => {
                            this.changePage(page - 1);
                        }}
                        disabled={!canPrevious}
                    >
                        <KeyboardArrowLeft/>
                    </IconButton>
                    <div className='pagination_table-paginator'>
                            <input
                                type='text'
                                className='pagination_table-input'
                                {...this.getPageJumpProperties()}
                            />
                        <div className='pagination_table-paginator'>
                            of {this.props.pages}
                        </div>
                    </div>
                    <IconButton
                        aria-label={NEXT_PAGE}
                        onClick={(): void => {
                            this.changePage(page + 1);
                        }}
                        disabled={!canNext}
                    >

                        <KeyboardArrowRight/>
                    </IconButton>
                </div>
            </div>
        );
    }

    private getSafePage(num: any): number {
        const page = Number.isNaN(num) ? this.props.page : num;

        return Math.min(Math.max(page, 0), this.props.pages - 1);
    }

    private applyPage(): void {
        const page = this.state.page;

        this.changePage(page === '' ? this.props.page : page);
    }

    private changePage(num: number | string): void {
        const page = this.getSafePage(num);

        this.setState({page});

        if (this.props.page !== page) {
            this.props.onPageChange(page);
        }
    }

    private getPageJumpProperties = (): InputHTMLAttributes<HTMLInputElement> =>
        (
            {
                onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>): void => {
                    if (event.which === ENTER_BUTTON_KEYCODE || event.keyCode === ENTER_BUTTON_KEYCODE) {
                        this.applyPage();
                    }
                },
                onBlur: (): void => this.applyPage(),
                value: this.state.page === '' ? '' : Number(this.state.page) + 1,
                onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
                    const val = e.target.value;
                    const page = Number(val) - 1;

                    if (val === '') {
                        return this.setState({page: val});
                    }

                    this.setState({page: this.getSafePage(page)});
                },
            }
        )
}

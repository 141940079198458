import { IState } from '../store';
import { createSelector } from 'reselect';
import { IStackListState, IStackOptions, IStackPropertyOptions } from 'redux/reducers/stackList/stackList.model';

export const getStack = (state: IState): IStackListState => state.stackList;

export const getStackListLoading = createSelector(
    [getStack],
    (stackList: IStackListState): boolean => stackList.stackListPending,
);

export const getStackListUpdating = createSelector(
    [getStack],
    (stackList: IStackListState): boolean => stackList.stackListUpdatePending,
);

export const getStackList = createSelector(
    [getStack],
    (stackList: IStackListState): IStackOptions[] => stackList.stackList,
);

export const getStackOptions = createSelector(
    [getStack],
    (stackList: IStackListState): IStackOptions => stackList.stackOptions,
);

export const getStackPropertyListLoading = createSelector(
    [getStack],
    (stackList: IStackListState): boolean => stackList.stackPropertyListPending,
);

export const getStackPropertyListUpdating = createSelector(
    [getStack],
    (stackList: IStackListState): boolean => stackList.stackPropertyListUpdatePending,
);

export const getStackPropertyList = createSelector(
    [getStack],
    (stackList: IStackListState): IStackPropertyOptions[] => stackList.stackPropertyList,
);

export const getStackPropertyOptions = createSelector(
    [getStack],
    (stackList: IStackListState): IStackPropertyOptions => stackList.stackPropertyOptions,
);

export const getUpdateStackDocumentPending = createSelector(
    [getStack],
    (stackList: IStackListState): boolean => stackList.updateStackDocumentPending,
);

import * as React from 'react';
import { IBox, ITransformerProps } from './shapesLayer.model';
import { Transformer } from 'react-konva';
import { degrees } from '../../constants';

export const MIN_SHAPE_SIZE = 1;

export class TransformerComponent extends React.Component<ITransformerProps> {

    private transformer: any;

    constructor(props: ITransformerProps) {
        super(props);
        this.transformer = React.createRef();
    }

    public componentDidMount(): void {
        this.checkNode();
    }

    public componentDidUpdate(): void {
        this.checkNode();
    }

    public render(): JSX.Element {
        return (
            <Transformer
                boundBoxFunc={this.boundHandler}
                onTransformEnd={this.props.transformerHandler}
                ref={(node: any): void => {
                    this.transformer = node;
                }}
                resizeEnabled={this.props.isResizable}
                rotateEnabled={this.props.isRotatable}
            />
        );
    }

    private boundHandler = (oldBox: IBox, newBox: IBox): IBox => {
        const { width, height } = newBox;
        const { container, scale, pageRotation} = this.props;
        const minSize = width < MIN_SHAPE_SIZE || height < MIN_SHAPE_SIZE;
        let widthLimit = width > container.width - newBox.x || newBox.x < 0;
        let heightLimit = height > container.height - newBox.y || newBox.y < 0;
        if(pageRotation === degrees.THREE_QUARTERS) {
            if(oldBox.x < 0) {
                newBox.x = 0;
            }
            heightLimit = parseInt(newBox.y.toFixed(3), 10) > (container.height) || (parseInt(newBox.y.toFixed(3), 10) - parseInt(newBox.width.toFixed(3), 10)) < 0;
            widthLimit = (parseInt(newBox.x.toFixed(3), 10) + parseInt(newBox.height.toFixed(3), 10)) / scale > (container.width / scale ) || parseInt(newBox.x.toFixed(3), 10) < 0;
        } else if(pageRotation === degrees.MINUS_QUARTER) {
            if(oldBox.y < 0) {
                newBox.y = 0;
            }
            heightLimit = (parseInt(newBox.y.toFixed(3), 10) + parseInt(newBox.width.toFixed(3), 10)) / scale > (container.height / scale ) || parseInt(newBox.y.toFixed(3), 10) < 0;
            widthLimit = parseInt(newBox.x.toFixed(3), 10) / scale > (container.width / scale ) || (parseInt(newBox.x.toFixed(3), 10) - parseInt(newBox.height.toFixed(3), 10)) < 0;
        } else if(pageRotation === degrees.MINUS_HALF) {
            heightLimit = parseInt(newBox.y.toFixed(3), 10) > (container.height) || (parseInt(newBox.y.toFixed(3), 10) - parseInt(newBox.height.toFixed(3), 10)) < 0;
            widthLimit = parseInt(newBox.x.toFixed(3), 10) / scale > (container.width / scale ) || (parseInt(newBox.x.toFixed(3), 10) - parseInt(newBox.width.toFixed(3), 10)) < 0;
        }

        if (minSize || widthLimit || heightLimit ) {
            return oldBox;
        }
        return newBox;
    }

    private checkNode = (): void => {
        // here we need to manually attach or detach Transformer node
        const stage = this.transformer.getStage();
        const { selectedShapeName } = this.props;

        if (selectedShapeName) {
            const selectedNode = stage.findOne('.' + selectedShapeName);

            // do nothing if selected node is already attached
            if (selectedNode === this.transformer.node()) {
                return;
            }

            if (selectedNode) {
                // attach to another node
                this.transformer.attachTo(selectedNode);
            } else {
                // remove transformer
                this.transformer.detach();
            }

            this.transformer.getLayer().batchDraw();
        }
    }
}
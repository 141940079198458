import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from '../../../../node_modules/react-redux';
import { useSelector } from 'react-redux';
import { SelectMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import { INDEX_METADATA } from '../../../constants/modal.constants';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CANCEL, DATAPICKER_DATE_FORMAT, SAVE } from '../../../constants/common.constants';
import './modalReleaseDate.scss';
import { Button, TextField } from '@mui/material';
import { RELEASE_DATE_PARAM, PRE_RELEASE_DATE_PARAM } from '../../../constants/releaseDate.constants';
import { CLEAR } from '../../../constants/globalSearch.constants';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { handleCloseModalWindow } from '../../../redux/actions/modal';
import { updateReleaseDate, deletePrereleaseDate, deleteReleaseDate } from '../../../redux/actions/documentList';
import { IUpdateModalReleaseData } from './modalReleaseData.model';
import { RELEASE_MANAGEMENT_MODAL } from '../../../constants/messages.constants';
import { initialLabel } from '../../../constants/localization.constants';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import resourceBundle from '../../localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';
import dayjs = require('dayjs');

export const getReleaseDateModalLabelsByKey = (key: string): string => {
    switch (key) {
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'COMMON_LABEL_SAVE':
            return 'saveLabel';
        case 'COMMON_LABEL_SET':
            return 'setLabel';
        case 'COMMON_LABEL_CLEAR':
            return 'clearLabel';
        case 'MODAL_RELEASE_MANAGEMENT_SELECTED_DOCS_TO':
            return 'allSelectedDocsToLabel';
        case 'MODAL_RELEASE_MANAGEMENT_ALL_SELECTED_DOCS':
            return 'allSelectedDocsLabel';
        case 'MODAL_RELEASE_MANAGEMENT_PRE_RELEASE_DATE':
            return 'preReleaseDateLabel';
        case 'MODAL_RELEASE_MANAGEMENT_RELEASE_DATE':
            return 'releaseDateLabel';
        case 'MODAL_AUTODESKEW_DOCUMENT_MESSAGE':
            return 'autoDeskewDocMessageLabel';
        default: return '';
    }
};

const ModalReleaseDate = (props: IModalProps): JSX.Element => {
    const { documentIds, redactionDocumentId, setRelease } = props.modalSpecificProps;

    const dispatch = useDispatch();
    const handleCloseModal = (id: string): void => dispatch(handleCloseModalWindow(id));
    const handleSubmitUpdate = (redactionDocId: number, data: IUpdateModalReleaseData): void =>
        dispatch(updateReleaseDate(redactionDocId, data));
    const handleDeletePreRelease = (redactionDocId: number, data: number[]): void =>
        dispatch(deletePrereleaseDate(redactionDocId, data));
    const handleDeleteRelease = (redactionDocId: number, data: number[]): void =>
        dispatch(deleteReleaseDate(redactionDocId, data));
    const [dateType, useDateType] = useState('');
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [dateValue, useDateValue] = useState(null);
    const [isDateValid, useDateValidate] = useState(true);
    const modifiedLabels = useSelector(getModifiedLabels);
    const redactionLanguage = useSelector(getRedactionLanguage);
    const labels = {
        cancelLabel: initialLabel,
        saveLabel: initialLabel,
        setLabel: initialLabel,
        clearLabel: initialLabel,
        allSelectedDocsToLabel: initialLabel,
        allSelectedDocsLabel: initialLabel,
        preReleaseDateLabel: initialLabel,
        releaseDateLabel: initialLabel,
    };
    const langRule = changeLang(redactionLanguage);
    const INVALID_DATE = "Invalid Date Format";
    let validDate: boolean;

    resourceBundle.map((resource: any) => {
        if (getReleaseDateModalLabelsByKey(resource.resourceKey)) {
            labels[getReleaseDateModalLabelsByKey(resource.resourceKey)] = resource;
        }
        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getReleaseDateModalLabelsByKey(resource.resourceKey)) {
            labels[getReleaseDateModalLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    const handleChange = (date): void => {
        if (date) {
            validDate = dayjs(date.$d).isValid();
            useDateValidate(validDate);
            if (!validDate) {
                setDateErrorMessage(INVALID_DATE);
            }
            else {
                setDateErrorMessage('');
            }
        }

        useDateValue(date
            ? dayjs(date)
            : null);
    };

    const handleSubmit = (): void => {
        if (setRelease) {
            const releaseDto = {
                documentIds,
                [RELEASE_DATE_PARAM]: dateType === RELEASE_DATE_PARAM ? dateValue : null,
                [PRE_RELEASE_DATE_PARAM]: dateType === PRE_RELEASE_DATE_PARAM ? dateValue : null,
            };

            handleSubmitUpdate(redactionDocumentId, releaseDto);
        } else if (!setRelease && dateType === RELEASE_DATE_PARAM) {
            handleDeleteRelease(redactionDocumentId, documentIds);
        } else {
            handleDeletePreRelease(redactionDocumentId, documentIds);
        }
    };

    const isDisabled = setRelease ? !dateType || !dateValue || !isDateValid : !dateType;
    const SELECT_RELEASE_DATE_OPTIONS = [
        {
            value: PRE_RELEASE_DATE_PARAM,
            label: labels.preReleaseDateLabel[langRule],
        },
        {
            value: RELEASE_DATE_PARAM,
            label: labels.releaseDateLabel[langRule],
        },
    ];

    const renderModalContent = (): JSX.Element => {
        if (setRelease) {
            return (
                <React.Fragment>
                    <span>{labels.setLabel[langRule]}</span>
                    <div className='date-type-select'>
                        <SelectMaterialBuilder
                            value={dateType}
                            paramName={INDEX_METADATA}
                            label={null}
                            handleSelectChange={(label: string, value: string): void => useDateType(value)}
                            options={SELECT_RELEASE_DATE_OPTIONS}
                        />
                    </div>
                    <span>{labels.allSelectedDocsToLabel[langRule]}</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={dateValue}
                            onChange={handleChange}
                            slotProps={{
                                textField: {
                                    helperText: (dateValue!=null && !isDateValid) ? dateErrorMessage : '',
                                    FormHelperTextProps: {
                                        style: { marginTop: !isDateValid ? '-15px' : 'none'}
                                    }
                                },
                            }}
                            format={DATAPICKER_DATE_FORMAT}
                        />
                    </LocalizationProvider>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <span>{labels.clearLabel[langRule]}</span>
                    <div className='date-type-select'>
                        <SelectMaterialBuilder
                            value={dateType}
                            paramName={INDEX_METADATA}
                            label={null}
                            handleSelectChange={(label: string, value: string): void => useDateType(value)}
                            options={SELECT_RELEASE_DATE_OPTIONS}
                        />
                    </div>
                    <span>{labels.allSelectedDocsLabel[langRule]}</span>
                </React.Fragment>
            );
        }
    };

    return (
        <div className='release-date-modal'>
            <div className='modal-content'>
                {renderModalContent()}
            </div>
            <div className='control-buttons'>
                <Button
                    variant='outlined'
                    onClick={(): void => handleCloseModal(RELEASE_MANAGEMENT_MODAL)}
                    className='modal-window__buttons outlined'
                    size='small'
                >
                    {labels.cancelLabel[langRule]}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={handleSubmit}
                    className='primary'
                    disabled={isDisabled}
                >
                    {labels.saveLabel[langRule]}
                </Button>
            </div>
        </div>
    );
};

export default ModalReleaseDate;

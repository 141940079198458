export const DISCLOSURE_TITLE_MODAL = 'Disclosure Management';
export const DISCLOSURE_MODAL = 'disclosureManagement';
export const BASIC_SETTINGS = 'Basic Settings';
export const RELEASE_SETTINGS = 'Release Settings';
export const CONTACT_SETTINGS = 'Contact Settings';

export const MIXED = 'MIXED';

export const DISCLOSURE = 'Disclosure';
export const BORDER_COLOR = 'borderColor';
export const CONTACTS = 'contacts';
export const DESCRIPTION = 'description';
export const ID = 'id';
export const IS_ACTIVE = 'isActive';
export const IS_DUPLICATE_REF_REQUIRED = 'isDuplicateRefRequired';
export const IS_EXEMPTION_REQUIRED = 'isExemptionRequired';
export const IS_IN_PAGINATION = 'isInPagination';
export const NAME = 'name';
export const NAME_1 = 'name1';
export const DESCRIPTION_1 = 'description1';
export const PAGE_OUT_NOTICE = 'pageOutNotice';
export const IS_RELEASABLE_STATE = 'isReleasableState';
export const REQUEST_TYPE = 'requestType';
export const REQUEST_TYPES_LIST = 'requestTypesList';

export const DUPLICATE = 'duplicate';
export const NOT_RELEVANT = 'notRelevant';
export const RECEIVED = 'received';
export const RELEASED = 'released';
export const REVIEWED = 'reviewed';

export const IS_DUPLICATE = 'IS_DUPLICATE';
export const IS_NOT_RELEVANT = 'IS_NOT_RELEVANT';
export const IS_RECEIVED = 'IS_RECEIVED';
export const IS_RELEASED = 'IS_RELEASED';
export const IS_REVIEWED = 'IS_REVIEWED';

export const disclosureCountPage = {
    [DUPLICATE]: IS_DUPLICATE,
    [NOT_RELEVANT]: IS_NOT_RELEVANT,
    [RECEIVED]: IS_RECEIVED,
    [RELEASED]: IS_RELEASED,
    [REVIEWED]: IS_REVIEWED,
};

export const NEW_DISCLOSURE_ID = 0;

export const tabs = [
    {
        title: BASIC_SETTINGS,
        isValid: true,
    },
    {
        title: RELEASE_SETTINGS,
        isValid: true,
    },
    {
        title: CONTACT_SETTINGS,
        isValid: true,
    },
];

export const emptyDisclosure = {
    borderColor: '',
    description: '',
    id: 0,
    isActive: false,
    isDuplicateRefRequired: false,
    isExemptionRequired: false,
    isInPagination: false,
    name: '',
    pageOutNotice: '',
    isReleasableState: true,
    requestType: [],
    contacts: [],
};

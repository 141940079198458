import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { IIndexDescription } from './indexDescription.model';
import {
    IDescriptionItem,
    ILookup,
} from '../../../../redux/reducers/indexMetadataDescriptions/indexMetadataDescriptions.model';

export class IndexDescription extends React.Component<IIndexDescription, null> {
    public render(): JSX.Element {
        const {
            selectedTab,
            descriptions,
            handleChange,
            handleReplace,
            handleRemove,
            handleAdd,
            editPermission,
            deletePermission,
            addPermission,
            labels,
            language,
        } = this.props;
        const langRule = language ? language === 'fr' ? 'resourceValue2' : 'resourceValue' : 'resourceValue';

        return (
            <div className='modal-index-keyword_body_container' key={selectedTab}>
                <div className='modal-index-keyword_body_container_header'>
                    <div className='modal-index-keyword-description1'>{labels.descriptionLabel[langRule]}</div>
                    <div className='modal-index-keyword-description1'>{labels.descriptionFrLabel[langRule]}</div>
                    <div className='modal-index-keyword-occurrences'>{labels.occurancesLabel[langRule]}</div>
                    <div className='modal-index-keyword-empty'/>
                    <div className='modal-index-keyword-empty'/>
                </div>
                {
                    descriptions.map((item: IDescriptionItem, i: number) => {
                        return (
                            <div className='modal-index-keyword_body_container_item' key={i}>
                                <TextField
                                    className='modal-index-keyword-description1'
                                    variant='outlined'
                                    value={(item.value as ILookup).description}
                                    error={this.isValidValue(descriptions, (item.value as ILookup).description)}
                                    inputProps={{ maxLength: 255 }}
                                    placeholder={labels.descriptionLabel[langRule]}
                                    autoFocus={!((item.value as ILookup).id)}
                                    disabled={!editPermission}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChange(e.target.value, (item.value as ILookup).metadataId, i, 'description')}
                                />
                                <TextField
                                    className='modal-index-keyword-description2'
                                    variant='outlined'
                                    value={(item.value as ILookup).description2}
                                    inputProps={{ maxLength: 255 }}
                                    placeholder={labels.descriptionFrLabel[langRule]}
                                    disabled={!editPermission}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChange(e.target.value, (item.value as ILookup).metadataId, i, 'description2')}
                                />
                                <div className='modal-index-keyword-occurrences1'>{item.occurrences}</div>
                                <Button
                                    className='outlined modal-index-keyword-empty-button'
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    disabled={!editPermission}
                                    onClick={(): void => handleReplace(i)}
                                >
                                    {labels.replaceLabel[langRule]}
                                </Button>
                                {
                                    deletePermission && (
                                        <Button
                                            className='modal-index-keyword-empty-button'
                                            disabled={!!item.occurrences}
                                            style={{ 'backgroundColor':'#e0e0e0', fontWeight: 500, color: !!item.occurrences ? 'grey' : 'black'}}
                                            variant='contained'
                                            size='small'
                                            onClick={(): void => handleRemove((item.value as ILookup).metadataId, i,
                                                (item.value as ILookup).id)}
                                        >
                                            {labels.deleteLabel[langRule]}
                                        </Button>
                                    )
                                }
                            </div>
                        );
                    })
                }
                {
                    addPermission && (
                        <Fab
                            variant='extended'
                            size='medium'
                            color='primary'
                            aria-label='Add'
                            className='modal-index-keyword_add'
                            onClick={handleAdd}
                        >
                            <AddIcon fontSize={'small'} />
                            {labels.addNewLabel[langRule]}
                        </Fab>
                    )
                }
            </div>
        );
    }

    private isValidValue = (descriptions: IDescriptionItem[], value: string): boolean => {
        if (!value) {
            return true;
        }

        const values = descriptions
            .map((item: IDescriptionItem) => (item.value as ILookup).description)
            .filter((item: string) => item === value);

        return values.length > 1 && values.includes(value);
    }
}

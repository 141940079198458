import * as React from 'react';
import './replaceDisclosure.scss';
import { SelectMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IState as StoreState } from '../../../redux/store';
import { getAllDisclosureTypesList, getCurrentDisclosureTypes } from '../../../redux/selectors/disclosureTypes';
import { first, uniq, flatten } from 'lodash';
import { getSelectedDocuments } from '../../../redux/selectors/pageList';
import { ISelectOptions } from '../../../components/materialUiForms/marerialUiForms.model';
import {
    IDisclosureOptions,
    IGetDocumentsDisclosureOption,
} from '../../../redux/reducers/modalWindowDisclosure/modalWindowDisclosure.model';
import {
    IReplaceDisclosureDispatchToProps,
    IReplaceDisclosureProps,
    IReplaceDisclosureState,
    IReplaceDisclosureStateToProps,
} from './replaceDisclosure.model';
import { handleCloseAllModalWindows } from '../../../redux/actions/modal';
import { replaceDocumentsDisclosure } from '../../../redux/actions/disclosureTypes';
import api from '../../../api/reductionApi';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import { ID } from '../../../constants/disclosure.contstants';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';
import { initialLabel } from '../../../constants/localization.constants';

const getReplaceDisclosureLabelsByKey = (key: string): string => {
    switch (key) {
        case 'REPLACE_DISCLOSURE_MODAL_FROM':
            return 'fromLabel';
        case 'REPLACE_DISCLOSURE_MODAL_TO':
            return 'toLabel';
        case 'REPLACE_DISCLOSURE_MODAL_CANCEL':
            return 'cancelLabel';
        case 'REPLACE_DISCLOSURE_MODAL_REPLACE':
            return 'replaceLabel';
        default: return '';
    }
};
const labels = {
    fromLabel: initialLabel,
    toLabel: initialLabel,
    cancelLabel: initialLabel,
    replaceLabel: initialLabel,
};

const createOptions = (item: IDisclosureOptions): ISelectOptions => {
    return {
        label: item.name,
        value: String(item.id),
    };
};

class ReplaceDisclosureModal extends React.Component<IReplaceDisclosureProps, IReplaceDisclosureState> {

    public state: IReplaceDisclosureState = {
        selectedFromId: null,
        selectedToId: null,
        allAvailableDisclosuresOptions: [],
        appliedDisclosuresOptions: [],
        replaceDisabled: false,
    };

    public async componentDidMount(): Promise<void> {
        const {
            allDisclosureTypesList,
            selectedDocuments,
            allCurrentDisclosureTypes,
            redactionDocumentId,
        } = this.props;

        const response = await api.disclosureTypes.getDocumentsDisclosureTypes(redactionDocumentId, selectedDocuments);

        const allAppliedDisclosureIds = response.reduce((
            acc: number[],
            item: IGetDocumentsDisclosureOption,
        ) => [...acc, ...item.disclosureType.map((diclosure: IDisclosureOptions) => diclosure[ID]) ], []);

        const uniqAppliedDisclosureIds = uniq(allAppliedDisclosureIds);
        const appliedDisclosures= allDisclosureTypesList
            .filter((item: IDisclosureOptions) => uniqAppliedDisclosureIds.includes(item.id));
        const appliedDisclosuresOptions = appliedDisclosures.map(createOptions);

        if(appliedDisclosures.length) {
            this.setState({selectedFromId: first(appliedDisclosures).id});
        }

        this.setState({
            allAvailableDisclosuresOptions: allCurrentDisclosureTypes.map(createOptions),
            appliedDisclosuresOptions,
        });
    }

    public render(): JSX.Element {
        const { closeModal, replaceDisclosure, redactionLang, modifiedLabels } = this.props;
        const {
            selectedFromId,
            selectedToId,
            appliedDisclosuresOptions,
            allAvailableDisclosuresOptions,
            replaceDisabled,
        } = this.state;
        const langRule = changeLang(redactionLang);
        resourceBundle.map((resource: any) => {
            if (getReplaceDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getReplaceDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getReplaceDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getReplaceDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        const allDisclosuresWithoutSelected = allAvailableDisclosuresOptions
            .filter((item: ISelectOptions) => item.value !== String(selectedFromId));

        return (
            <div className='replace_disclosure'>
                <div className='replace_disclosure_selects'>
                    <SelectMaterialBuilder
                        value={selectedFromId}
                        paramName={'from'}
                        label={labels.fromLabel[langRule]}
                        options={appliedDisclosuresOptions}
                        handleSelectChange={(param: string, val: string): void => {
                            this.setState({selectedFromId: val, selectedToId: null});
                        }}
                        variant='standard'
                    />
                    <ArrowForward className='replace_disclosure_arrow'/>
                    <SelectMaterialBuilder
                        value={selectedToId}
                        paramName={'from'}
                        label={labels.toLabel[langRule]}
                        options={allDisclosuresWithoutSelected}
                        handleSelectChange={(param: string, val: string): void => {
                            this.setState({selectedToId: val});
                        }}
                        variant='standard'
                    />
                </div>
                <div className='replace_disclosure_buttons'>
                    <Button
                        variant='outlined'
                        className='outlined right_indent'
                        onClick={closeModal}
                        size='small'
                    >
                        {labels.cancelLabel[langRule]}
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        disabled={!selectedToId || replaceDisabled}
                        onClick={(): void => {
                            this.setState({replaceDisabled: true});
                            replaceDisclosure(Number(selectedFromId), Number(selectedToId));
                        }}
                        className='primary'
                    >
                         {labels.replaceLabel[langRule]}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): IReplaceDisclosureStateToProps => ({
    allDisclosureTypesList: getAllDisclosureTypesList(state),
    allCurrentDisclosureTypes: getCurrentDisclosureTypes(state),
    selectedDocuments: getSelectedDocuments(state),
    redactionDocumentId: getRedactionDocumentId(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, IReplaceDisclosureDispatchToProps, AnyAction>):
    IReplaceDisclosureDispatchToProps => ({
    closeModal: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    replaceDisclosure: (fromId: number, toId: number): void => {
        dispatch(replaceDocumentsDisclosure(fromId, toId));
    },
});

export const ReplaceDisclosure = connect(mapStateToProps, mapDispatchToProps)(ReplaceDisclosureModal);

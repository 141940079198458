import * as constant from '../constants/validate.constants';

export const isUnique = (textList: string[], value: string): boolean =>
    textList
        .map((text: string): string => text.toLowerCase())
        .includes(value.toLowerCase());

export const validate = (
    values: any, requiredFields: any, uniqueFields?: { [param: string]: string[]}, labels?: any, langRule?: string,
    ): any =>
    requiredFields.reduce((result: any, current: any) => {
        if (
            current[constant.UNIQUE] &&
            values &&
            values[current.field] &&
            isUnique(uniqueFields[current.field], values[current.field])
        ) {
            return {
                ...result,
                [current.field]: labels && labels.mustBeUniqueLabel && labels.mustBeUniqueLabel[langRule]
                    || constant.MUST_BE_UNIQUE,
            };
        }

        if (current[constant.AT_LEAST_ONE_OF] && values && !values[current.field].length) {
            return {
                ...result,
                [current.field]: labels && labels.atLeastOneOfListLabel && labels.atLeastOneOfListLabel[langRule]
                    || constant.AT_LEAST_ONE_OF_LIST,
            };
        }

        if (current[constant.REQUIRED] && values && !values[current.field]) {
            return {
                ...result,
                [current.field]: labels && labels.mandatoryFieldLabel && labels.mandatoryFieldLabel[langRule]
                    || constant.FIELD_IS_MANDATORY_TO_FILL_IN,
            };
        }

        if (
            current[constant.MIN_LENGTH] &&
            values &&
            values[current.field] &&
            values[current.field].length < current[constant.MIN_LENGTH]
        ) {
            return {
                ...result,
                [current.field]: `${labels && labels.minLengthLabel && labels.minLengthLabel[langRule]
                    || constant.MIN_LENGTH_LABEL} ${current[constant.MIN_LENGTH]}`,
            };
        }

        if (
            current[constant.MAX_LENGTH] &&
            values &&
            values[current.field] &&
            values[current.field].length > current[constant.MAX_LENGTH]
        ) {
            return {
                ...result,
                [current.field]: `${labels && labels.maxLengthLabel && labels.maxLengthLabel[langRule]
                    || constant.MAX_LENGTH_LABEL} ${current[constant.MAX_LENGTH]}`,
            };
        }

        return result;
    }, {});

export const validateArrayType = (
    values: any[],
    requiredFields: any,
    uniqueFields?: { [param: string]: string[]},
    labels?: any, 
    langRule?: string,
    ): any =>
    values.map((value: any, idx: number): any => {
        const uniqueValues = uniqueFields &&
            Object.keys(uniqueFields).reduce((result: { [param: string]: string[]}, current: string): any => {
                const uniqValues = uniqueFields[current]
                    .filter((val: string, i: number): boolean => i !== idx && !!val);

                return { ...result, [current]: uniqValues };
            }, {});

        return validate(value, requiredFields, uniqueValues, labels, langRule);
    });

export const mustBeNumber = (value: number): string | null => {
    return (isNaN(value) ? 'Must be a number' : null);
};

export const minValue = (min: number): (value: number) => string | null => (value: number): string | null =>
    value < min ? `Should be greater than ${min}` : null;

export const maxValue = (max: number): (value: number) => string | null => (value: number): string | null =>
    value <= max ? null : `Should be less than ${max}`;

export const composeValidators = (validators: any[], value: any): string | null =>
   validators.reduce((error: any, validator: any) => error || validator(value), null);

export const outOfRangeError = (documentName: string, startPage: number, totalPage: number): string =>
    `Out of range PDF page number entered for document ${documentName}.\
    Please enter page numbers between [${startPage} - ${totalPage}] in the Documents list filter.`;

import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../../../api/reductionApi';
import {
    GET_REDACTION_DOCUMENT_METADATA_PENDING,
    GET_REDACTION_DOCUMENT_METADATA_SUCCESS,
    GET_REDACTION_DOCUMENT_METADATA_FAILURE,
    GET_USER_PERMISSIONS_FAILURE,
    GET_USER_PERMISSIONS_PENDING,
    GET_USER_PERMISSIONS_SUCCESS,
    GET_REDACTION_AUTH_FAILURE,
    GET_REDACTION_AUTH_PENDING,
    GET_AMANDA_PERMISSIONS_SUCCESS,
    GET_AMANDA_PERMISSIONS_PENDING,
    GET_ENTRY_DATA_PENDING,
    GET_ENTRY_DATA_SUCCESS,
    GET_ENTRY_DATA_FAILURE,
} from '../../reducers/initialization/constants';
import { IError } from '../../common.model';
import {
    fetchDocumentListFailure,
    fetchDocumentListPending,
    fetchDocumentListSuccess,
    getDocumentListColumnHeader,
} from '../documentList';
import { fetchAllDisclosureTypeList, fetchCurrentDisclosureListSuccess } from '../disclosureTypes';
import { fetchIndexMetadataSuccess } from '../indexMetadata';
import { fetchAnnotationListSuccess } from '../annotationTypes';
import { fetchStackListSuccess } from '../stackList';
import { fetchArticleStampListSuccess, fetchStampListSuccess } from '../layoutRedactorTypes';
import { fetchGlobalSettingsSuccess } from '../modalGlobalSettings';
import {
    filterCurrentRecentlyUsedStamps,
    setRecentlyUsedExemptions,
    setRecentlyUsedStampsOnReferesh,
    setReductionAuth,
} from '../localStorage';
import { IGetReductionMetadataProps, IUserPermissions } from '../../reducers/initialization/initialization.model';
import { IState } from '../../store';
import { addError } from '../errorHandling';
import { X_AUTH_LOWER_CASE } from '../../../constants/api-headers.constants';
import { fetchFoiFolderTypes } from '../modalSearchDocuments';
import { fetchOfficers } from '../modalRequestSearch';
import { AxiosError } from 'axios';
import { IResponseHeaders } from '../../../api/baseApi';
import { openModalWindow } from '../modal';
import {
    ALERT_DIALOG_MODAL,
    WARNING,
} from '../../../constants/messages.constants';
import { b64DecodeUnicode } from '../../../utils/encode.util';
import { getAdvanceSearchColumnHeader, setSelectedAdvanceSearchDocumentDetails } from '../advanceSearch';
import { getGlobalSearchColumnHeader } from '../globalSearch';
import { getPageListColumnHeader } from '../pageList';
import { MODE_CONTRIBUTE, RECENTLY_USED_EXEMPTION_KEY, RECENTLY_USED_STAMPS_KEY } from '../../../redux/reducers/localStorage/constant';
import { getItem } from '../../../utils/localStorage.util';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { getModifiedScreenLabels, setAuthFlag } from '../localization';

export const expiredSessionAlert = {
    id: 'expiredSession',
    noControls: true,
    title: WARNING,
    message: 'Session is expired. Please close the window and restart Redaction.',
};

export const getReductionMetadataPending = (): AnyAction => action(GET_REDACTION_DOCUMENT_METADATA_PENDING);
export const getReductionMetadataSuccess = (metaDataProps: IGetReductionMetadataProps): AnyAction =>
    action(GET_REDACTION_DOCUMENT_METADATA_SUCCESS, metaDataProps);
export const getReductionMetadataFailure = (error: IError): AnyAction =>
    action(GET_REDACTION_DOCUMENT_METADATA_FAILURE, error);
export const getUserPermissionsPending = (): AnyAction => action(GET_USER_PERMISSIONS_PENDING);
export const getUserPermissionsSuccess = (data: IUserPermissions): AnyAction =>
    action(GET_USER_PERMISSIONS_SUCCESS, data);
export const getUserPermissionsFailure = (error: IError): AnyAction =>
    action(GET_USER_PERMISSIONS_FAILURE, error);
export const getRedactionAuthPending = (): AnyAction => action(GET_REDACTION_AUTH_PENDING);
export const getRedactionAuthFailure = (error: IError): AnyAction =>
    action(GET_REDACTION_AUTH_FAILURE, error);
export const getAdminPermissionsPending = (): AnyAction => action(GET_AMANDA_PERMISSIONS_PENDING);
export const getAdminPermissionsSuccess = (data: IUserPermissions): AnyAction =>
    action(GET_AMANDA_PERMISSIONS_SUCCESS, data);
export const getAdminPermissionsFailure = (erorr: IError): AnyAction =>
    action(GET_AMANDA_PERMISSIONS_SUCCESS, erorr);
export const getEntryReductionDataPending = (): AnyAction => action(GET_ENTRY_DATA_PENDING);
export const getEntryReductionDataSuccess = (): AnyAction => action(GET_ENTRY_DATA_SUCCESS);
export const getEntryReductionDataFailure = (error: IError): AnyAction =>
    action(GET_ENTRY_DATA_FAILURE, error);

export const getUserPermissions = (): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        const { reductionMetaData: { redactionDocumentId } } = getState();

        dispatch(getUserPermissionsPending());

        try {
            const data = await api.permissionController.getUserPermissions(redactionDocumentId);

            dispatch(getUserPermissionsSuccess(data));
        } catch (error) {
            dispatch(getReductionMetadataFailure(error));
        }
    };

export const getRedactionAuth =
    (lid: string, lang: string, isFirstLogin: boolean = false, mode: string): Promise<IResponseHeaders> => {
    return new Promise<IResponseHeaders>(async (
        resolve: (response: IResponseHeaders) => void,
        reject: (error: AxiosError) => void,
    ): Promise<void> => {
        try {
            const { headers } = await api.loginController.loginRequest(lid, isFirstLogin, lang, mode);

            resolve(headers);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const createRedactionDocument = (folderRsn: string): (dispatch: Dispatch<any>) => Promise<void> =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        dispatch(getReductionMetadataPending());
        dispatch(fetchDocumentListPending());

        try {
            const {
                data: {
                    requestType,
                    documents,
                    folderId,
                    id,
                    requestNumber,
                    actCode,
                },
                headers,
            } = await api.redactionDocumentController.createRedactionDocument(folderRsn);

            if (headers[X_AUTH_LOWER_CASE]) {
                dispatch(setReductionAuth(headers[X_AUTH_LOWER_CASE]));
                dispatch(setAuthFlag(true));
            }

            dispatch(getReductionMetadataSuccess({
                requestType, folderId: id,
                folderRsn: folderId, requestNumber, actCode,
            }));
            dispatch(getUserPermissions());
            dispatch(fetchDocumentListSuccess(documents));
            dispatch(getEntryReductionData(id, requestNumber));
            dispatch(fetchAllDisclosureTypeList());

        } catch (error) {
            dispatch(getReductionMetadataFailure(error));
            dispatch(fetchDocumentListFailure());
        }
    };

export const createRedactionDocumentFirstLogin = (
    folderRsn: string,
): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        try {
            const {
                localStorage: {
                    lid,
                    language,
                    mode
                },
                documentList: {
                    documentListColumnHeader,
                },
                pageList: {
                    pageListColumnHeader,
                },
                advanceSearch: {
                    advanceSearchColumnHeader,
                },
                globalSearch: {
                    globalSearchColumnHeader,
                },
                localization: {
                    modifiedLabels,
                },
            } = getState();

            getRedactionAuth(lid, language, false, mode).then((headers: IResponseHeaders): void => {

                dispatch(setReductionAuth(headers[X_AUTH_LOWER_CASE]));
                dispatch(setAuthFlag(true));

                if (!documentListColumnHeader.length) {
                    dispatch(getDocumentListColumnHeader(lid));
                }

                if (!modifiedLabels.length) {
                    dispatch(getModifiedScreenLabels());
                }

                if (!pageListColumnHeader.length) {
                    dispatch(getPageListColumnHeader(lid));
                }

                if (!globalSearchColumnHeader.length) {
                    dispatch(getGlobalSearchColumnHeader(lid));
                }

                if (!advanceSearchColumnHeader.length) {
                    dispatch(getAdvanceSearchColumnHeader(lid));
                }

                const rsn = isFinite(Number(folderRsn)) ? folderRsn : b64DecodeUnicode(folderRsn);

                dispatch(createRedactionDocument(rsn));
            },
            ).catch(() => {
                dispatch(openModalWindow(ALERT_DIALOG_MODAL, expiredSessionAlert));
            });

        } catch (error) {
            dispatch(addError(error));
        }
    };

export const getEntryReductionData = (
    reductionDocumentId: number,
    requestNumber: string,
): (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
        const {
            advanceSearch: {
                selectedAdvanceSearchedDocument,
            },
            localStorage: {
                mode
            }
        } = getState();

        dispatch(getEntryReductionDataPending());

        try {
            const {
                disclosureTypes,
                indexMetadata,
                annotationTypes,
                stacks,
                exemptions,
                stampTypes,
                requestTypesWithDisclosureTypes,
                exemptionSettings,
            } = await api.loginController.loginEntryRequest(reductionDocumentId);

            const globalSettings = {
                disclosureSettings: {
                    requestTypesWithDisclosureTypes:
                        requestTypesWithDisclosureTypes ? [requestTypesWithDisclosureTypes] : [],
                },
                exemptionSettings,
            };

            dispatch(fetchCurrentDisclosureListSuccess(disclosureTypes));
            dispatch(fetchIndexMetadataSuccess(indexMetadata));
            dispatch(fetchAnnotationListSuccess(annotationTypes));
            if(mode === MODE_CONTRIBUTE && stacks.length === 0){
                dispatch(openModalWindow(ALERT_DIALOG_MODAL,
                {
                    id: 'noStackInContributeMode',
                    noControls: true,
                    title: WARNING,
                    message: 'Please contact your administrator, Collaboration Portal users must be assigned to a Team to contribute documents inside Redaction.',
                }));
                return;
            }
            dispatch(fetchStackListSuccess(stacks));
            dispatch(fetchArticleStampListSuccess(exemptions));
            dispatch(fetchStampListSuccess(stampTypes || []));
            dispatch(fetchGlobalSettingsSuccess(globalSettings));
            dispatch(getEntryReductionDataSuccess());
            dispatch(filterCurrentRecentlyUsedStamps(requestNumber, exemptions));

            const lastUsedExemption = getItem(RECENTLY_USED_EXEMPTION_KEY + requestNumber);
            const lastUsedStamps = getItem(RECENTLY_USED_STAMPS_KEY + requestNumber);

            if (lastUsedExemption && !!lastUsedExemption.length) {
                dispatch(setRecentlyUsedExemptions(requestNumber, lastUsedExemption));
            }

            if (lastUsedStamps && !!lastUsedStamps.length) {
                dispatch(setRecentlyUsedStampsOnReferesh(requestNumber, lastUsedStamps));
            }

        } catch (error) {
            dispatch(getReductionMetadataFailure(error));
            dispatch(getEntryReductionDataFailure(error));
        }

        if(selectedAdvanceSearchedDocument) {
            dispatch(setSelectedAdvanceSearchDocumentDetails(selectedAdvanceSearchedDocument));

        }
    };

export const getRequestSearchFolders = (): (dispatch: Dispatch<any>, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch<any>, getState: () => IState): Promise<void> => {
        dispatch(getRedactionAuthPending());

        try {

            const {
                localStorage: {
                    lid,
                    language,
                    mode
                },
                reductionMetaData: {
                    folderRsn,
                },
                reductionMetaData: {
                    userPermissions,
                },
            } = getState();

            if (folderRsn) {
                dispatch(fetchFoiFolderTypes());
                dispatch(fetchOfficers());

                if (!userPermissions.length) {
                    dispatch(getAdminPermissions(lid));
                }
            } else {
                getRedactionAuth(lid, language, true, mode).then((headers: IResponseHeaders): void => {
                    dispatch(setReductionAuth(headers[X_AUTH_LOWER_CASE]));
                    dispatch(setAuthFlag(true));
                    dispatch(getModifiedScreenLabels());
                    dispatch(getDocumentListColumnHeader(lid));
                    dispatch(getAdvanceSearchColumnHeader(lid));
                    dispatch(getGlobalSearchColumnHeader(lid));
                    dispatch(getPageListColumnHeader(lid));
                    dispatch(fetchFoiFolderTypes());
                    dispatch(fetchOfficers());

                    if (!userPermissions.length) {
                        dispatch(getAdminPermissions(lid));
                    }

                });
            }

        } catch (error) {
            dispatch(getRedactionAuthFailure(error));
            dispatch(addError(error));
        }
    };

export const getAdminPermissions = (lid: string): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(getAdminPermissionsPending());

        try {
            const permissions = await api.permissionController.getAdminPermissions(lid);

            dispatch(getUserPermissionsSuccess(permissions));
        } catch (error) {
            dispatch(getAdminPermissionsFailure(error));
        }
    };

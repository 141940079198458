import * as React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import DocumentList from './documentList/DocumentList';
import ModalWindowExportTabs from './modalWindowExportTabs/ModalWindowExportTabs';
import {
    clearExportState,
    postExportDocument,
    getExportDocuments,
} from '../../../redux/actions/modalWindowExport';
import { fetchFolderAttachmentTypes, fetchFolderProcesses } from '../../../redux/actions/amandaContent';
import {
    getRedactionDocumentId,
    getRedactionFolderRsn,
    getUserMetaData,
} from '../../../redux/selectors/initialization';
import { getHighlightTypesList, getSeverTypesList } from '../../../redux/selectors/annotationTypes';
import {
    IProps,
    IState,
    IExportStateProps,
    IExportDispatchProps,
    ISelectedPagesData,
} from './modalWindowExport.model';
import { getExportDocumentList, getExportLoading, getExportFileNameErrorMessage } from '../../../redux/selectors/modalWindowExport';
import { IAnnotationType } from '../../../redux/reducers/annotationTypes/annotationTypes.model';
import { IState as StoreState } from '../../../redux/store';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import './modalWindowExport.style.scss';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { handleCloseAllModalWindows, openModalWindow } from '../../../redux/actions/modal';
import { initialLabel } from '../../../constants/localization.constants';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import resourceBundle from '../../localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';

export const getExportTabLabelsByKey = (key: string): string => {
    switch(key) {
        case 'EXPORT_CONTENT_OPTIONS_TITLE':
            return 'contentOptionsLabel';
        case 'MODAL_EXPORT_ANNOTATION_CONTROL_TITLE':
            return 'annotationControlLabel';
        case 'MODAL_EXPORT_PAGINATION_CONTROL_TITLE':
            return 'paginationOptionsLabel';
        case 'MODAL_EXPORT_CONSULTEES_TITLE':
            return 'consultTitleLabel';
        case 'MODAL_EXPORT_EXPORT_DESTINATION_TITLE':
            return 'exportDestinationLabel';
        case 'EXPORT_CONTENT_OPTIONS_SELECT_PRESET':
            return 'selectPresetLabel';
        case 'MODAL_EXPORT_DOCUMENTS_LIST_TITLE':
            return 'documentListTitleLabel';
        case 'MODAL_EXPORT_SELECT_ALL_DOCUMENTS':
            return 'allDocumentLabel';
        case 'MODAL_EXPORT_FILENAME_FIELD':
            return 'fileNameLabel';
        case 'MODAL_EXPORT_PAGES_FIELD':
            return 'pagesLabel';
        case 'MODAL_EXPORT_OUT_OF_RANGE_ERROR_PART_ONE':
            return 'outOfRangeErrorOneLabel';
        case 'MODAL_EXPORT_OUT_OF_RANGE_ERROR_PART_TWO':
            return 'outOfRangeErrorTwoLabel';
        case 'MODAL_EXPORT_OUT_OF_RANGE_ERROR_PART_THREE':
            return 'outOfRangeErrorThreeLabel';
        case 'MODAL_EXPORT_NO_PAGES_TO_EXPORT_ERROR':
            return 'noPagesToExportErrorLabel';
        case 'MODAL_EXPORT_ERROR_FORMAT':
            return 'errorFormatLabel';
        case 'MODAL_EXPORT_FILE_LENGTH_EXCEED':
            return 'fileLengthExceedLabel';
        case 'MODAL_EDIT_FILENAME_TITLE':
            return 'editFilenameModalTitle';
        case 'MODAL_EXPORT_SELECT_ALL':
            return 'selectAllLabel';
        case 'MODAL_EXPORT_DESELECT_ALL':
            return 'deselectAllLabel';
        case 'COMMON_LABEL_EXPORT':
            return 'exportLabel';
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'MODAL_EXPORT_CONSULTEE_TAB_DISCLOSURE_TO_EXPORT':
            return 'consulteeTabDisclosureLabel';
        case 'MODAL_EXPORT_CONSULTEE_TAB_DISCLOSURES':
            return 'disclosuresLabel';
        case 'MODAL_EXPORT_CONSULTEE_TAB_LAST_NAME':
            return 'lastNameLabel';
        case 'MODAL_EXPORT_CONSULTEE_TAB_FIRST_NAME':
            return 'firstNameLabel';
        case 'MODAL_EXPORT_CONSULTEE_TAB_ORGANIZATION_NAME':
            return 'organizationNameLabel';
        case 'MODAL_EXPORT_CONSULTEE_TAB_DEPARTMENT':
            return 'departmentLabel';
        case 'MODAL_EXPORT_CONSULTEE_TAB_USER_TEAM':
            return 'userTeamLabel';
        case 'MODAL_EXPORT_CONSULTEE_TAB_PEOPLE_RSN':
            return 'peopleRSNLabel';
        default: return '';
    }
};

class ModalWindowExport extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isFormValid: true,
            isTabsDataValid: true,
            isFormChanged: false,
        };
    }

    public componentDidMount(): void {
        const { pageIds, currentDocId, redactionDocumentId, folderRsn } = this.props;

        this.props.getExportDocuments(redactionDocumentId, { currentDocId, pageIds });
        this.props.fetchFolderProcesses(redactionDocumentId);
        this.props.fetchFolderAttachmentTypes(redactionDocumentId, folderRsn);
    }

    public shouldComponentUpdate(prevProps: IProps, prevState: IState): boolean {
        if (
            prevState.isFormValid !== this.state.isFormValid ||
            prevState.isTabsDataValid !== this.state.isTabsDataValid ||
            prevProps.exportLoading !== this.props.exportLoading ||
            prevProps.exportDocumentsList !== this.props.exportDocumentsList ||
            prevProps.exportFileNameErrorMessage !== this.props.exportFileNameErrorMessage
        ) {
            return true;
        }

        return false;
    }

    public componentWillUnmount(): void {
        this.props.clearExportState();
    }

    public render(): JSX.Element {
        const { pageIds, currentDocId, exportLoading, exportDocumentsList, exportFileNameErrorMessage,
            modifiedLabels, redactionLang} = this.props;
        const { isFormValid, isTabsDataValid } = this.state;
        const disableExport = exportDocumentsList.length === 0;
        const labels = {
            contentOptionsLabel: initialLabel,
            annotationControlLabel: initialLabel,
            consultTitleLabel: initialLabel,
            paginationOptionsLabel: initialLabel,
            exportDestinationLabel: initialLabel,
            selectPresetLabel: initialLabel,
            documentListTitleLabel: initialLabel,
            allDocumentLabel: initialLabel,
            fileNameLabel: initialLabel,
            pagesLabel: initialLabel,
            outOfRangeErrorOneLabel: initialLabel,
            outOfRangeErrorTwoLabel: initialLabel,
            outOfRangeErrorThreeLabel: initialLabel,
            noPagesToExportErrorLabel: initialLabel,
            errorFormatLabel: initialLabel,
            fileLengthExceedLabel: initialLabel,
            editFilenameModalTitle: initialLabel,
            selectAllLabel: initialLabel,
            deselectAllLabel: initialLabel,
            cancelLabel: initialLabel,
            exportLabel: initialLabel,
            consulteeTabDisclosureLabel: initialLabel,
            disclosuresLabel: initialLabel,
            lastNameLabel: initialLabel,
            firstNameLabel: initialLabel,
            organizationNameLabel: initialLabel,
            departmentLabel: initialLabel,
            userTeamLabel: initialLabel,
            peopleRSNLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getExportTabLabelsByKey(resource.resourceKey)) {
                labels[getExportTabLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getExportTabLabelsByKey(resource.resourceKey)) {
                labels[getExportTabLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        return (
            <div className={'export-modal'}>
                <form className={'modal-window-export-form'}>
                    <DocumentList
                        onChangeValidationStatus={this.handleValidationStatusChange}
                        parentLabels={labels}
                        langRule={langRule}
                    />
                    <ModalWindowExportTabs
                        changeForm={this.changeForm}
                        selectedPagesData={{ currentDocId, pageIds }}
                        onChangeValidationStatus={(isValid: boolean): void =>
                            this.setValidationStatus('isTabsDataValid', isValid)
                        }
                        parentLabels={labels}
                    />
                </form>
                <div className='modal-buttons-footer'>
                        <Button
                            variant='outlined'
                            onClick={this.handleCancel}
                            className='modal-window__buttons outlined'
                            size='small'
                        >
                            {labels.cancelLabel[langRule]}
                        </Button>

                        <span>
                            <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                disabled={
                                            disableExport ||
                                            !isFormValid ||
                                            !isTabsDataValid ||
                                            !!exportFileNameErrorMessage
                                        }
                                className='primary'
                                onClick={this.handleExport}
                            >
                                {labels.exportLabel[langRule]}
                            </Button>
                        </span>
                </div>
                <div className='linear-progress'>
                    {exportLoading && <LinearProgress />}
                </div>
            </div>
        );
    }

    private handleValidationStatusChange = (isValid: boolean): void => {
        this.setState({ isFormValid: isValid });
    }

    private setValidationStatus = (param: string, isValid: boolean): void => {
        this.setState({ [param]: isValid });
    }

    private handleExport = (): void => {
        const { highLightList, severList, redactionDocumentId } = this.props;

        this.props.exportDocument(redactionDocumentId, highLightList, severList);
    }

    private changeForm = (): void => {
        this.setState({isFormChanged: true});
    }

    private handleCancel = (): void => {
           this.props.handleCloseAllModalWindows();
       }
    }

const mapStateToProps = (state: StoreState): IExportStateProps => ({
    redactionDocumentId: getRedactionDocumentId(state),
    highLightList: getHighlightTypesList(state),
    severList: getSeverTypesList(state),
    exportLoading: getExportLoading(state),
    exportDocumentsList: getExportDocumentList(state),
    exportFileNameErrorMessage: getExportFileNameErrorMessage(state),
    userMetaData: getUserMetaData(state),
    folderRsn: getRedactionFolderRsn(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, IExportDispatchProps, AnyAction>,
): IExportDispatchProps => ({
    getExportDocuments: (redactionDocumentId: number, data: ISelectedPagesData): void => {
        dispatch(getExportDocuments(redactionDocumentId, data));
    },
    clearExportState: (): void => {
        dispatch(clearExportState());
    },
    exportDocument: (
        redactionDocumentId: number,
        highLightList: IAnnotationType[],
        severList: IAnnotationType[],
    ): void => {
        dispatch(postExportDocument(redactionDocumentId, highLightList, severList));
    },
    fetchFolderProcesses: (redactionDocumentId: number): void => {
        dispatch(fetchFolderProcesses(redactionDocumentId));
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
    handleCloseAllModalWindows: (): void => {
        dispatch(handleCloseAllModalWindows());
    },
    fetchFolderAttachmentTypes: (redactionDocumentId: number, folderRsn: number): void => {
        dispatch(fetchFolderAttachmentTypes(redactionDocumentId, folderRsn));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindowExport);

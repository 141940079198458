import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from '../../../node_modules/react-redux';
import { isEqual } from 'lodash';
import QueueManagement from './QueueManagement';
import {
    fetchDocumentTasksQueue,
    deleteDocumentTasksQueue,
    updateDocumentTasksQueue,
} from '../../redux/actions/taskQueue';
import { updateDocumentList, updateDocumentsList } from '../../redux/actions/documentList';
import {
    getTaskQueueTableList,
    getLastProcessedDocumentsList,
    getTaskQueueMetaData,
    getTaskQueueLoading,
    getTaskQueueLoaded,
} from '../../redux/selectors/taskQueue';
import { getRedactionDocumentId } from '../../redux/selectors/initialization';
import { DEFAULT_PAGE_SIZE } from '../../constants/taskQueue.constants';
import { ITaskList, IPaginationParams } from '../../redux/reducers/taskQueue/taskQueue.model';
import { ISimpleTabProps } from '../../components/simpleTabs/simpleTabs.model';
import './queueManagement.styles.scss';
import { getCurrentPageComments, getCurrentPageHyperLinks } from '../../redux/actions/pageList';

const QueueManagementContainer = (props: ISimpleTabProps): JSX.Element => {
    const taskQueueList = useSelector(getTaskQueueTableList);
    const lastProcessedDocuments = useSelector(getLastProcessedDocumentsList);
    const {
        totalPages,
        currentPage,
        totalTaskCount,
        showFinishedTasks,
    } = useSelector(getTaskQueueMetaData);
    const redactionDocumentId = useSelector(getRedactionDocumentId);
    const loading = useSelector(getTaskQueueLoading);
    const loadedTaskList = useSelector(getTaskQueueLoaded);

    const dispatch = useDispatch();
    const deleteTasksQueue = (redactionId: number, id: number): void => {
        dispatch(deleteDocumentTasksQueue(redactionId, id));
    };
    const fetchDocumentTasksQueueList = (
        redactionId: number,
        params?: IPaginationParams,
        needPending?: boolean,
    ): void => {
        dispatch(fetchDocumentTasksQueue(redactionId, params, needPending));
    };
    const updateTasksQueue = (redactionId: number, id: number, status: string): void => {
        dispatch(updateDocumentTasksQueue(redactionId, id, status));
    };
    const updateDocuments = (redactionId: number, id: number): void => {
        dispatch(updateDocumentList(redactionId, id));
        dispatch(getCurrentPageComments());
        dispatch(getCurrentPageHyperLinks());
    };
    const updateExistDocumentsList = (redactionId: number): void => dispatch(updateDocumentsList(redactionId));

    useEffect(() => {
        if (redactionDocumentId) {
            const params = {
                page: currentPage,
                size: DEFAULT_PAGE_SIZE,
                showFinishedTasks,
            };

            fetchDocumentTasksQueueList(redactionDocumentId, params);
        }
    }, [redactionDocumentId]);

    return (
        <QueueManagement
            taskQueueList={taskQueueList}
            lastProcessedDocuments={lastProcessedDocuments}
            totalPages={totalPages}
            currentPage={currentPage}
            totalTaskCount={totalTaskCount}
            showFinishedTasks={showFinishedTasks}
            redactionDocumentId={redactionDocumentId}
            loading={loading}
            loadedTaskList={loadedTaskList}
            fetchDocumentTasksQueueList={fetchDocumentTasksQueueList}
            deleteTasksQueue={deleteTasksQueue}
            updateTasksQueue={updateTasksQueue}
            updateDocuments={updateDocuments}
            updateDocumentsList={updateExistDocumentsList}
        />
    );
};

export default QueueManagementContainer;

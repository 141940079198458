import * as React from 'react';
import './modalSearchDocument.styles.scss';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import { isNil, omitBy } from 'lodash';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { IState as StoreState } from '../../../redux/store';
import SearchDocument from './SearchDocument';
import ResizibleSortableTable from '../../../components/table/Table';
import {
    getAmandaAttachmentsPending,
    getFoiFolderPending, getFoiFolders,
    getSearchDocuments,
    getSearchDocumentsLoading,
    getSearchRedactionDocuments,
    getSearchRedactionDocumentsPending,
} from '../../../redux/selectors/modalSearchDocument';
import {
    IModalSearchDocumentsDispatchProps,
    IModalSearchDocumentsStateProps,
    ISearchRedactionDocumentsParams,
    IProps,
    IState,
} from './modalSearchDocument.model';
import { getRedactionRequestNumber } from '../../../redux/selectors/initialization';
import {
    clearSearchReductionDocuments, fetchAmandaAttachments,
    fetchFoiFolderTypes, fetchReductionDocument,
    fetchSearchReductionDocuments,
} from '../../../redux/actions/modalSearchDocuments';
import { handleCloseModalWindow } from '../../../redux/actions/modal';
import {
    EMPTY_REDACTION_DOCUMENTS_MESSAGE,
    SEARCH_FOLDER_ATTACHMENTS,
    SEARCH_REDACTION_DOCUMENTS,
    SearchDocumentsResultHeaders,
} from '../../../constants/searchDocuments.contstants';
import { Spinner } from '../../../components/spinner/Spinner';
import {
    ISearchDocuments,
    ISearchDocumentsTable,
} from '../../../redux/reducers/modalSearchDocuments/searchDocuments.model';
import LinearProgress from '@mui/material/LinearProgress';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { changeLang } from '../../../redux/actions/localization';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { PageData } from '../../../redux/reducers/common/common.model';

export const getSearchDocLabelsbyKey = (key: string): string => {
    switch(key) {
        case 'MODAL_IMPORT_OFFICER_ASSIGNED_HEADER':
            return 'officerAssignedLabel';
        case 'MODAL_IMPORT_REQUEST_SUMMARY_HEADER':
            return 'reqSummaryHeaderLabel';
        case 'MODAL_IMPORT_SEARCH_RESULTS':
            return 'searchResultsLabel';
        case 'MODAL_IMPORT_REQUEST_NUMBER':
            return 'requestNumberLabel';
        case 'MODAL_IMPORT_FOLDER_TYPE':
            return 'folderTypeLabel';
        case 'COMMON_LABEL_EXACT_MATCH':
            return 'exactMatchLabel';
        case 'COMMON_LABEL_RESET':
            return 'resetlabel';
        case 'COMMON_LABEL_SEARCH':
            return 'searchLabel';
        case 'COMMON_LABEL_SELECT':
            return 'selectLabel';
        case 'MODAL_IMPORT_SEARCH_FOLDER_ATTACHMENTS_TITLE':
            return 'folderAttachmentLabel';
        case 'COMMON_LABEL_NO_RESULTS':
            return 'noResultsLabel';
        case 'COMMON_LABEL_CANCEL':
            return'cancelLabel';
        default: return '';
    }
};

export class ModalSearchDocument extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            assignedDocumentId: null,
            page: 0,
            sortParams :  null,
        };
    }

    public componentDidMount(): void {
        this.props.fetchFoiFolderTypes();
    }

    private handleSortTable = (param: string, val: string , pageName : string): void => {
        const pageData: PageData = {
            pageName: pageName,
            columnName: param,
            sortOrder: val,        
        };
        this.setState({
            sortParams : pageData
        })
    }

    public render(): JSX.Element {
        const {
            foiFolderPending,
            foiFolders,
            searchDocumentsPending=false,
            searchDocuments,
            selectedDocumentsPending,
            amandaAttachmentsPending,
            modifiedLabels,
            redactionLang,
        } = this.props;
        const langRule = changeLang(redactionLang);
        const labels = {
            cancelLabel: initialLabel,
            officerAssignedLabel: initialLabel,
            reqSummaryHeaderLabel: initialLabel,
            searchResultsLabel: initialLabel,
            requestNumberLabel: initialLabel,
            folderTypeLabel: initialLabel,
            exactMatchLabel: initialLabel,
            resetlabel: initialLabel,
            searchLabel: initialLabel,
            selectLabel: initialLabel,
            noResultsLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if (getSearchDocLabelsbyKey(resource.resourceKey)) {
                labels[getSearchDocLabelsbyKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getSearchDocLabelsbyKey(resource.resourceKey)) {
                labels[getSearchDocLabelsbyKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        const searchData = searchDocumentsPending ?
            [] :
            searchDocuments.map((item: ISearchDocuments): ISearchDocumentsTable => {
                return {
                    ...item,
                   
                    action: (
                        <Radio
                            checked={this.state.assignedDocumentId === item.folder.folderRsn}
                            onChange={(e: React.BaseSyntheticEvent): void => this.handleAssignDocument(e.target.value)}
                            value={item.folder.folderRsn}
                        />
                    ),
                };
            });

            const searchModified = searchData.map(doc => ({
                ...doc,
                customFolderNumber: doc.folder.customFolderNumber
            }));
            const paginatedData = searchModified;
        return (
            <React.Fragment>
                {foiFolderPending ?
                    <Spinner active={true}/> :
                    <div className='select-document'>
                        <div className='select-document_inner'>
                            <SearchDocument
                                loading={searchDocumentsPending}
                                foiFolders={foiFolders}
                                onSearch={this.onSearch}
                                requestNumber={this.props.requestNumber}
                                labels={labels}
                                language={redactionLang}
                            />
                            <ResizibleSortableTable
                                data={paginatedData}
                                showPagination={!!searchData.length}
                                handleSortSession = {this.handleSortTable}
                                pageName ={'redactionDocuments'}
                                userPreferenceTable={true}
                                columns={SearchDocumentsResultHeaders(labels, langRule)}
                                title={labels.searchResultsLabel[langRule]}
                                pagesCount={searchData.length}
                                defaultPageSize={100}
                                page={this.state.page}
                                onFetchData={(page: number): void => this.setState({page})}
                                sortParams={ this.state.sortParams ? this.state.sortParams  :  null}
                            />
                        </div>
                        <div className='button'>
                            <Button
                                size='large'
                                variant='outlined'
                                className='outlined'
                                onClick={this.handleClose}
                            >
                                {labels.cancelLabel[langRule]}
                            </Button>
                            <Button
                                size='large'
                                variant='contained'
                                color='primary'
                                className='buttons-save primary'
                                onClick={this.handleSelectDocument}
                                disabled={
                                    !this.state.assignedDocumentId
                                    || selectedDocumentsPending
                                    || amandaAttachmentsPending
                                }
                            >
                                {labels.selectLabel[langRule]}
                            </Button>
                        </div>
                        <div className='linear-progress'>
                            {
                                (searchDocumentsPending || selectedDocumentsPending || amandaAttachmentsPending)
                                && <LinearProgress/>
                            }
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

    private handleClose = (): void => {
        this.props.clearSearchReductionDocuments();
        this.props.handleCloseModalWindow();
    }

    private handleSelectDocument = (): void => {
        const {assignedDocumentId} = this.state;
        const langRule = changeLang(this.props.redactionLang);
        const labels = { folderAttachmentLabel: initialLabel };

        resourceBundle.map((resource: any) => {
            if (getSearchDocLabelsbyKey(resource.resourceKey)) {
                labels[getSearchDocLabelsbyKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.props.modifiedLabels.map((resource: any) => {
            if (getSearchDocLabelsbyKey(resource.resourceKey)) {
                labels[getSearchDocLabelsbyKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        this.props.title === labels.folderAttachmentLabel[langRule] ?
            this.props.fetchAmandaAttachments(assignedDocumentId) :
            this.props.fetchReductionDocument(assignedDocumentId);
    }

    private handleAssignDocument = (id: string): void => {
        this.setState({
            assignedDocumentId: Number(id),
        });
    }

    private onSearch = (data: ISearchRedactionDocumentsParams): void => {
        const params = omitBy(data, isNil);

        this.props.fetchSearchReductionDocuments(params);
    }
}

const mapStateToProps = (state: StoreState): IModalSearchDocumentsStateProps => ({
    searchDocumentsPending: getSearchDocumentsLoading(state),
    searchDocuments: getSearchDocuments(state),
    foiFolderPending: getFoiFolderPending(state),
    foiFolders: getFoiFolders(state),
    selectedDocumentsPending: getSearchRedactionDocumentsPending(state),
    selectedDocuments: getSearchRedactionDocuments(state),
    amandaAttachmentsPending: getAmandaAttachmentsPending(state),
    requestNumber: getRedactionRequestNumber(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<IProps, IModalSearchDocumentsDispatchProps, AnyAction>,
): IModalSearchDocumentsDispatchProps => ({
    handleCloseModalWindow: (): void => {
        dispatch(handleCloseModalWindow(SEARCH_REDACTION_DOCUMENTS));
    },
    fetchSearchReductionDocuments: (data: ISearchRedactionDocumentsParams): void => {
        dispatch(fetchSearchReductionDocuments(data));
    },
    fetchReductionDocument: (folderRsn: number): void => {
        dispatch(fetchReductionDocument(folderRsn));
    },
    fetchAmandaAttachments: (folderRsn: number): void => {
        dispatch(fetchAmandaAttachments(folderRsn));
    },
    fetchFoiFolderTypes: (): void => {
        dispatch(fetchFoiFolderTypes());
    },
    clearSearchReductionDocuments: (): void => {
        dispatch(clearSearchReductionDocuments());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSearchDocument);
import * as React from 'react';
import { hotKeys, ZOOM_IN_HOT_KEY, ZOOM_OUT_HOT_KEY } from '../../../hotKeys';
import { SimpleTable } from '../../../components/simpleTable/SimpleTable';
import './keyboardShortcuts.scss';

export const headers = ['Keys', 'Description'];

const renameKeys = {
  [ZOOM_IN_HOT_KEY]: 'alt+( + )',
  [ZOOM_OUT_HOT_KEY]: 'alt+( - )',
};
let rows: { key: string, description: string }[] = [];

if (hotKeys) {
  rows = Object.keys(hotKeys).map((key: string) => {
    return {
      key: renameKeys[hotKeys[key]] ? renameKeys[hotKeys[key]] : hotKeys[key],
      description: key,
    };
  });
}

export { rows };



export const keyboardShortcuts = (): JSX.Element => {
  return (
    <div className='keyboard-shortcuts-table-wrapper'>
      <SimpleTable rowsData={rows} rowHeaders={headers} />
    </div>
  );
};

import * as React from 'react';
import { compact } from 'lodash';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import './simpleTabs.styles.scss';
import { IProps, ITab } from './simpleTabs.model';
import { Scrollbars } from 'rc-scrollbars';
import { useState } from 'react';
import { QUEUE_MANAGEMENT, SEARCH, ADVANCED_SEARCH } from '../../constants/taskQueue.constants';

const SimpleTabs = (
    {
        children,
        defaultIndex,
        widthTabs,
    }: IProps,
): JSX.Element => {
    const [tabIndex, tabsHandleChange] = useState(defaultIndex || 0);
    const tabs = compact(children);

    return (
        <div className='simple-tabs'>
            <Tabs
                value={tabIndex}
                onChange={(event: React.SyntheticEvent, tabInd: number): void => tabsHandleChange(tabInd)}
                indicatorColor='primary'
                textColor='primary'
                variant='scrollable'
                style={{ width: widthTabs ? widthTabs : '100%' }}
            >
                {tabs.reduce((acc: JSX.Element[], item: ITab, i: number) =>
                    item.props.hidden ? [...acc] : [
                        ...acc,
                        <Tab
                            key={i}
                            label={item.props.title}
                            disabled={!!item.props.disabled}
                            classes={{ root: item.props.isValid ? null : 'simple-tabs-error' }}
                        />,
                    ], [])}
            </Tabs>
            <div className='simple-tabs-content'>
            <Scrollbars>
        {
            tabs.reduce((acc: JSX.Element[], item: ITab, i: number) =>
                item.props.hidden ? [...acc] : [
                    ...acc,
                    <div
                        className={`simple-tabs-inner ${i !== tabIndex ? 'hidden' : ''}`}
                        key={i}
                    >
                        {
                            item.props.title === QUEUE_MANAGEMENT ||
                            item.props.title === SEARCH ||
                            item.props.title === ADVANCED_SEARCH ?
                                i === tabIndex ? item as React.ReactNode : null
                                : item as React.ReactNode
                        }
                    </div>,
                ], [])
        }
    </Scrollbars>
    </div>
        </div>);
};

export default SimpleTabs;

import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { IPropsCheckboxContainer } from './advancedSearch.model';

const className = {
    COLOR_CHECKBOX: 'color-checkbox',
};

const CheckboxContainer = ({type, element, label, checked, handleCheckbox}: IPropsCheckboxContainer ): JSX.Element => {

    return (
        <div
            className='item'
        >
            <div className='checkBox-name'>
                <Checkbox
                    checked={checked}
                    onChange={(): void => {
                        handleCheckbox(type,
                            element);
                    }}
                    name={`${element}`}
                    className={className.COLOR_CHECKBOX}
                />
                <div className='name'>
                    {label}</div>
            </div>
        </div>
    );
};

export default CheckboxContainer;

import { AnyAction } from 'redux';
import { IHeaderState } from './header.model';
import { SET_HEADER_MENU, CLEAR_HEADER_MENU } from './constant';

export const initialState: IHeaderState = {
    activeMenu: '',
};

const scannerReducer = (state: IHeaderState = initialState, { type, payload }: AnyAction): IHeaderState => {
    switch (type) {
        case SET_HEADER_MENU:
            return {
                ...state,
                activeMenu: payload,
            };
        case CLEAR_HEADER_MENU:
            return {
                ...state,
                activeMenu: '',
            };
        default:
            return state;
    }
};

export default scannerReducer;

import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';

import { handleCloseAllModalWindows, openModalWindow } from '../../../redux/actions/modal';
import { fetchRequesterReport } from '../../../redux/actions/requesterReport';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';

import { IState as StoreState } from '../../../redux/store';

import {
    IState,
    IProps,
    IRequesterReportStateProps,
    IRequesterReportDispatchProps,
    ICheckBox,
    IRequesterReportDispatch,
} from './modalWindowRequester.model';
import './modalWindowRequester.styles.scss';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import {
    SHOW_DATE_TIME,
    REPORT_ON_SELECTED_iTEM_ONLY,
} from '../../../constants/requesterReport';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { initialLabel, langFrench } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { getReportsLabelsByKey } from '../modalPageReviewSummary/ModalPageReviewSummary';
import { changeLang } from '../../../redux/actions/localization';
import { IModalProps } from 'redux/reducers/modal/modal.model';
import { getReportLanguageModalLabels, REPORT_LANG_SELECTION_MODAL, REPORT_LANG_SELECTION_TITLE } from '../../../constants/modalReportLangSelection.constants';
import { getItem } from '../../../utils/localStorage.util';

class ModalWindowRequester extends React.Component<IProps, IState> {
    public state: IState = {
        requesterParams: {
            showDate: false,
            onlySelectedItem: false,
        },
    };

    public render(): JSX.Element {
        const { handleCloseModalWindow, modifiedLabels, redactionLang } = this.props;
        const langRule = changeLang(redactionLang);
        const labels = {
            generateLabel: initialLabel,
            showDateTimeLabel: initialLabel,
            reportSelectedItemsLabel: initialLabel,
            cancelLabel: initialLabel,
        };

        resourceBundle.map((resource: any) => {
            if (getReportsLabelsByKey(resource.resourceKey)) {
                labels[getReportsLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getReportsLabelsByKey(resource.resourceKey)) {
                labels[getReportsLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        return (
            <div className='requester'>
                <div className='check-box'>
                    <div className='box'>
                        <Checkbox
                            color='primary'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
                                this.handleCheckedParam('showDate', checked)}
                        />
                        <div>{labels.showDateTimeLabel[langRule]}</div>
                    </div>
                    <div className='box'>
                        <Checkbox
                            color='primary'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
                                this.handleCheckedParam('onlySelectedItem', checked)}
                        />
                        <div>{labels.reportSelectedItemsLabel[langRule]}</div>
                    </div>
                </div>
                <div className='button'>
                    <Button
                        size='small'
                        variant='outlined'
                        onClick={handleCloseModalWindow}
                        className='modal-window__buttons outlined cancel'
                    >
                        {labels.cancelLabel[langRule]}
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        className='buttons-save primary'
                        onClick={this.onSelect}
                    >
                        {labels.generateLabel[langRule]}
                    </Button>
                </div>
            </div>
        );
    }
    private handleCheckedParam = (param: string, requesterParams: boolean): void => {
        this.setState((prevState: IState): IState => ({
            ...prevState,
            requesterParams: {
                ...prevState.requesterParams,
                [param]: requesterParams,
            },
        }));
    }

    private onSelect = (): void => {
        this.props.handleOpenModalWindow(REPORT_LANG_SELECTION_MODAL, {
            id: REPORT_LANG_SELECTION_MODAL,
            title: getReportLanguageModalLabels(REPORT_LANG_SELECTION_TITLE),
            okButtonTitle: getReportLanguageModalLabels('Ok'),
            cancelButtonTitle: getReportLanguageModalLabels('Cancel'),
            confirm: (): () => void => (): void => {
                const reportLang = getItem("reportLang");
                const { requesterParams } = this.state;
                const { modalSpecificProps } = this.props;
                this.props.fetchRequesterReport(
                    requesterParams,
                    this.props.reductionDocumentId,
                    modalSpecificProps.documentIds,
                    reportLang
                );
                this.props.handleCloseModalWindow();
            },
            reject: (): () => void => (): void => {
                this.props.handleCloseModalWindow();
            },
        });
    }
}

const mapStateToProps = (state: StoreState): IRequesterReportStateProps => ({
    reductionDocumentId: getRedactionDocumentId(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps =
    (dispatch: ThunkDispatch<StoreState, IRequesterReportDispatchProps, AnyAction>): IRequesterReportDispatch => ({
        handleCloseModalWindow: (): void => {
            dispatch(handleCloseAllModalWindows());
        },
        fetchRequesterReport: (param: ICheckBox, reductionDocumentId: number, documentIds: number[], reportLang: string): void => {
            dispatch(fetchRequesterReport(param, reductionDocumentId, documentIds, reportLang));
        },
        handleOpenModalWindow: (modalType: string, modalProps: IModalProps): void => {
            dispatch(openModalWindow(modalType, modalProps));
        }
    });

export default connect( mapStateToProps, mapDispatchToProps) (ModalWindowRequester);

export const  USER_PREFERENCE_MODAL = 'userPreference';
export const USER_PREFERENCE_TITLE = 'User Preferences';
export const ARTICLE_STAMP_AUTO_ALIGN_TITLE = 'Article Stamp Auto Align';
export const LEFT_ALIGN = 'Left Align';
export const RIGHT_ALIGN = 'Right Align';
export const NONE_ALIGN = 'None';

export const LEFT_ALIGN_PARAM = 'leftAlign';
export const RIGHT_ALIGN_PARAM = 'rightAlign';
export const NONE_ALIGN_PARAM = 'none';
export const USER_PREFERENCE_PARAM = 'stampAlignment';

export const userAlignmentPreference = [
    { value: LEFT_ALIGN_PARAM, label: LEFT_ALIGN },
    { value: RIGHT_ALIGN_PARAM, label: RIGHT_ALIGN },
    { value: NONE_ALIGN_PARAM, label: NONE_ALIGN },
];

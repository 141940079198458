import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from '../../../node_modules/react-redux';
import { usePrevious } from '../../hooks/usePrevious';
import Konva from 'konva';
import { isEmpty, get } from 'lodash';
import { Layer, Stage, Rect } from 'react-konva';
import {
    getAnnotationKonvaData, getAnnotationLayouts, getAutoCorrectSever,
    getLoadingLinkedArticles, getRecentlySnappedAnnotationId,
} from '../../redux/selectors/annotation';
import { getStampsKonvaData, getCurrentPageStamps, getAutoStamp, getAutoAlignArticleStampsUserPreference } from '../../redux/selectors/stamps';
import {
    getSelectedCustomStamps,
    getSelectedAnnotationShapes,
    getSelectedStampShapes,
    getSingleSelectedShape,
    getlinkedAnnotaionIdAndStampsId,
} from '../../redux/selectors/redactor';
import { setCurrentSearchElement, setPreviousSearchElement } from '../../redux/actions/globalSearch';
import { getCurrentDocument, getPageListState, getCurrentPage } from '../../redux/selectors/pageList';
import { getSearchCurrentElement, getOriginalSearchData, getSearchElementDirection } from '../../redux/selectors/globalSearch';
import {
    setSelectedShapes,
    cleanSelectedShapes,
    setSingleSelectedShape,
    setLinkedArticlesFlag,
    setLinkedAnnotationId,
    setOverlayedShapes,
} from '../../redux/actions/redactor';
import {
    updateAnnotation, addAnnotation, highlightLinkedSever, updateLinkedArticles, addAnnotationsToDocument,
} from '../../redux/actions/annotations';
import { getRecentlySingleUsedStamp, getSelectedArticleStamps, getInteractiveMode } from '../../redux/selectors/layoutRedactorTypes';
import { getSelectedAnnotationType } from '../../redux/selectors/annotationTypes';
import { updateStamp, addStamps, highlightLinkedArticles } from '../../redux/actions/stamps';
import { IKonvaRectangle, IProps, ISelectedShapeData } from './shapesLayer.model';
import { ShapeComponent } from './ShapeComponent';
import { Shapes } from '../../constants/konva.constants';
import { IStamp } from '../../redux/reducers/stamps/stamps.model';
import { ICoordinate, IAnnotation, IPages, IHyperLink } from '../../redux/reducers/pageList/pageList.model';
import { IDocumentAnnotations, IPageActionParams } from '../../redux/reducers/annotation/annotation.model';
import { IStampType } from '../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { ICurrentSearchElement } from '../globalSearch/globalSearch.model';
import { getWantedObjectByProp, deleteObjectById, deleteSubarrayFromArray } from '../../utils/array.utils';
import { recalculateShape } from '../../utils/konva.utils';
import './shapesLayer.styles.scss';
import { END_POINT, START_POINT } from '../../constants/annotationTypes.constants';
import {
    DEFAULT_CURSOR,
    CROSSHAIR_CURSOR,
    ANNOTATION,
    STAMP,
    ID,
    NAME,
    TRANSPARENT,
    POINTER_CURSOR,
} from '../../constants/common.constants';
import {
    DEFAULT_DRAW_RECTANGLE,
    DEFAULT_COORDINATES,
    DEFAULT_OPACITY,
    DEFAULT_STROKE_WIDTH,
    SEARCH_HIGHLIGHT_COLOR,
    SEARCH_HIGHLIGHT_OPACITY,
    DEFAULT_FONT,
    STAMP_LINE_HEIGHT,
    STAMP_FONT_FAMILY,
    PADDING_OFFSET,
    MIN_SHAPE_SIZE,
} from '../../constants/annotation.constants';
import { isContextMenu } from '../../utils/event.utils';
import { getFontSizeByWidth, getTextParams } from '../../utils/string.utils';
import { getPageSearchData } from '../../utils/globalSearch.util';
import { degrees } from '../../constants';
import { getClearPrefixAndSuffix } from '../../redux/selectors/modalGlobalSettings';
import { getRecentlyUsedExemptions } from '../../redux/selectors/localStorage';
import { openModalWindow } from '../../redux/actions/modal';
import { IModalProps } from '../../redux/reducers/modal/modal.model';
import { ALERT_DIALOG_MODAL, MANUAL_LINKING_MULTIPLE_SEVERS_TO_ARTICLES, WARNING } from '../../constants/messages.constants';
import { LEFT_ALIGN_PARAM, NONE_ALIGN, NONE_ALIGN_PARAM, RIGHT_ALIGN_PARAM } from '../../constants/modalUserPreference.constants';
import { NEXT } from '../../constants/globalSearch.constants';

const TRANSFORMER_CLASS = 'Transformer';

interface IAnnotationDto {
    annotationTypeId?: number;
    color?: string;
    coordinate?: ICoordinate[];
    text?: string;
    stampDtoList?: IStamp[];
}

export const ShapesLayer = <T extends object, G extends object>(
    {
        children,
        width,
        height,
        onContextMenu,
        actionParams,
        scale,
        handleCloseSelects,
        pageRotation,
    }: IProps<T, G>,
): JSX.Element => {
    const [selectedShape, setSelectedShape] = useState(null);
    const [showResize, setShowResize] = useState(false);
    const [isDrawActive, setIsDrawActive] = useState(false);
    const [coordinates, setCoordinates] = useState(DEFAULT_COORDINATES);
    const [hyperLinks, setHyperLinks] = useState([]);
    const [newRect, setNewRect] = useState(DEFAULT_DRAW_RECTANGLE);
    const [commentHighlightRect, setCommentHighlight] = useState(DEFAULT_DRAW_RECTANGLE);
    const [hyperLinkHighlightRect, setHyperlinkHighlight] = useState(DEFAULT_DRAW_RECTANGLE);
    const [cursor, setCursor] = useState(DEFAULT_CURSOR);
    const [searchHighlight, setSearchHighlight] = useState(null);
    const [hyperLink, setHyperLink] = useState(null);
    let currentSearchElement = useSelector(getSearchCurrentElement);
    const originalSearchData = useSelector(getOriginalSearchData);
    const annotations = useSelector(getAnnotationKonvaData);
    const prefixAndSuffix = useSelector(getClearPrefixAndSuffix);
    const stamps = useSelector(getStampsKonvaData);
    const stampsLayouts = useSelector(getCurrentPageStamps);
    const annotationLayouts = useSelector(getAnnotationLayouts);
    const selectedAnnotationShapes = useSelector(getSelectedAnnotationShapes);
    const selectedStampShapes = useSelector(getSelectedStampShapes)
        .map((item: IStamp) => ({ ...item, fontSize: item.fontSize * scale }));
    const selectedAnnotationType = useSelector(getSelectedAnnotationType);
    const selectedArticleStamps = useSelector(getSelectedArticleStamps);
    const selectedCustomStamps = useSelector(getSelectedCustomStamps);
    const singleSelectedShape = useSelector(getSingleSelectedShape);
    const currentDocument = useSelector(getCurrentDocument);
    const lastUsedExemption = useSelector(getRecentlyUsedExemptions);
    const isSeverType = useSelector(getSelectedAnnotationType);
    const isAutoStampActive = useSelector(getAutoStamp);
    const recentlySingleUsedStamp = useSelector(getRecentlySingleUsedStamp);
    const linkedAnnotaionAndStampId = useSelector(getlinkedAnnotaionIdAndStampsId);
    const autoAlignArticleStampPreference = useSelector(getAutoAlignArticleStampsUserPreference);
    const loadingLinkedArticles = useSelector(getLoadingLinkedArticles);
    const pageList = useSelector(getPageListState);
    const currentSearchElementDirection = useSelector(getSearchElementDirection);
    const isAutoCorrectSeverActive = useSelector(getAutoCorrectSever);
    const recentlySnappedAnnotationId = useSelector(getRecentlySnappedAnnotationId);
    const interactMode = useSelector(getInteractiveMode);
    const currentPage = useSelector(getCurrentPage);

    const dispatch = useDispatch();
    const handleUpdateAnnotations = (
        props: IPageActionParams,
        shapes: IAnnotation[],
        fromTransformer?: boolean): void => dispatch(updateAnnotation(props, shapes, fromTransformer));
    const updateCurrentSearchElement = (element: ICurrentSearchElement): void =>
        dispatch(setCurrentSearchElement(element));
    const handleUpdateStamps = (props: IPageActionParams, shapes: IStamp[]): void =>
        dispatch(updateStamp(props, shapes));
    const handleSetSelectedShapes = (shapes: G[], scaleParam: number): void =>
        dispatch(setSelectedShapes(shapes, scaleParam));
    const handleSetSingleSelectedShape = (shape: G, scaleParam: number): void =>
        dispatch(setSingleSelectedShape(shape, scaleParam));
    const handleCleanSelectedShapes = (): void => dispatch(cleanSelectedShapes());
    const handleAddAnnotation = (params: IPageActionParams, shapes: IAnnotation[]): void =>
        dispatch(addAnnotation(params, shapes));
    const handleAddStamps = (params: IPageActionParams, shapes: IStamp[]): void =>
        dispatch(addStamps(params, shapes));
    const setHighlightForLinkedArticles = (linkedArticlesId: number[]): void =>
        dispatch(highlightLinkedArticles(linkedArticlesId));
    const setLinkedArticleFlag = (linkedArticleFlag: boolean): void =>
        dispatch(setLinkedArticlesFlag(linkedArticleFlag));
    const setHighlightLinkedSever = (linkedArticlesId: number): void =>
        dispatch(highlightLinkedSever(linkedArticlesId));
    const setAnnotationId = (annotaionId: number): void => dispatch(setLinkedAnnotationId(annotaionId));
    const updateLinkedArticle = (): void => dispatch(updateLinkedArticles());
    const addAnnotationToDocuments = (
        params: IPageActionParams, data: IDocumentAnnotations[], isLinkedArticles?: boolean): void =>
        dispatch(addAnnotationsToDocument(params, data, isLinkedArticles));
    const prevCurrentPageId = usePrevious(actionParams.pageId);
    const handleOverlayedShape = (params: IPageActionParams, data: ISelectedShapeData): void =>
        dispatch(setOverlayedShapes(params, data));
    const openAlertModal = (modalType: string, modalProps: IModalProps): void =>
        dispatch(openModalWindow(modalType, modalProps));
    const updatePreviousSearchElement = (element: ICurrentSearchElement): void =>
        dispatch(setPreviousSearchElement(element));
    const previousSnappedAnnotation = usePrevious(recentlySnappedAnnotationId);

    useEffect(() => {
        const isNewPage = (prevCurrentPageId === undefined) || (prevCurrentPageId && prevCurrentPageId !== actionParams.pageId);
        const pageSearch = currentDocument ? getPageSearchData(originalSearchData,
            currentDocument.name,
            actionParams.pageId) : null;

        if (!currentSearchElement && !pageSearch) {
            setSearchHighlight(null);
        } else if (currentSearchElement || pageSearch && pageSearch.coordinates) {
            if (isNewPage) {
                if (get(pageSearch, 'coordinates')) {
                    let index = 0;
                    let pageCoordinates = pageSearch.coordinates[0];

                    if (currentSearchElementDirection) {
                        index = currentSearchElementDirection === NEXT ? 0 :
                            pageSearch.coordinates.length - 1;
                        pageCoordinates = currentSearchElementDirection === NEXT ? pageSearch.coordinates[0] :
                            pageSearch.coordinates[pageSearch.coordinates.length - 1];

                    }
                    currentSearchElement = {
                        index,
                        coordinates: pageCoordinates,
                    };
                    updateCurrentSearchElement(currentSearchElement);
                    updatePreviousSearchElement({
                        ...currentSearchElement,
                        documentName: currentDocument.name,
                        documentId: currentDocument.id,
                        pageId: actionParams.pageId,
                        actualPageNumber: pageList.currentPage.actualPageNumber,
                    });
                } else {
                    updateCurrentSearchElement(null);
                }
            }
            if (currentSearchElement) {
                setNewCoordinates(currentSearchElement);
            }
        }

    }, [currentSearchElement, actionParams.pageId]);

    useEffect(() => {
        if (singleSelectedShape && selectedShape && singleSelectedShape !== selectedShape
            && !!singleSelectedShape.isSelectOverlayed) {
            const selected = !!singleSelectedShape.annotationTypeId ?
                annotations.find((item: IAnnotation) => item.id === Number(singleSelectedShape.id)) :
                stamps.find((item: IStamp) => item.id === Number(singleSelectedShape.id));
            if (selected) {
                setSelectedShape(selected);
            }
        }
    }, [singleSelectedShape]);

    useEffect(() => {
        if (linkedAnnotaionAndStampId.linkedArticlesFlag
            && linkedAnnotaionAndStampId.annotaionId
            && linkedAnnotaionAndStampId.articlesId && !!linkedAnnotaionAndStampId.articlesId.length
            && !loadingLinkedArticles) {
            updateLinkedArticle();
        }

    }, [linkedAnnotaionAndStampId]);

    useEffect(() => {
        const snappedAnnotation = annotations && annotations.length &&
            annotations.filter((annotation: IAnnotation) =>
                annotation.isSever && annotation.id === recentlySnappedAnnotationId);

        if (isAutoCorrectSeverActive && isAutoStampActive && snappedAnnotation && stamps &&
            snappedAnnotation.length && stamps.length && typeof previousSnappedAnnotation !== 'undefined' &&
            previousSnappedAnnotation !== recentlySnappedAnnotationId &&
            autoAlignArticleStampPreference === NONE_ALIGN_PARAM) {
            let newCoordinates = null;

            snappedAnnotation.forEach((annotation: IKonvaRectangle) => {

                stamps.forEach((stamp: IKonvaRectangle) => {
                    if (annotation.linkedArticles.includes(stamp.id)) {
                        if (!newCoordinates) {
                            if (pageRotation === degrees.ZERO) {
                                newCoordinates = {
                                    [START_POINT]: {
                                        x: annotation.coordinate[START_POINT].x,
                                        y: annotation.coordinate[START_POINT].y,
                                    },
                                    [END_POINT]: {
                                        x: annotation.coordinate[START_POINT].x + stamp.width,
                                        y: annotation.coordinate[START_POINT].y + stamp.height,
                                    },
                                };
                            } else if (pageRotation === degrees.MINUS_QUARTER) {
                                newCoordinates = {
                                    [START_POINT]: {
                                        x: annotation.coordinate[START_POINT].x,
                                        y: annotation.coordinate[START_POINT].y + (
                                            annotation.coordinate[END_POINT].y - annotation.coordinate[START_POINT].y),
                                    },
                                    [END_POINT]: {
                                        x: annotation.coordinate[START_POINT].x + stamp.width,
                                        y: annotation.coordinate[START_POINT].y + stamp.height + (
                                            annotation.coordinate[END_POINT].y - annotation.coordinate[START_POINT].y),
                                    },
                                };
                            } else if (pageRotation === degrees.HALF) {
                                newCoordinates = {
                                    [START_POINT]: {
                                        x: annotation.coordinate[END_POINT].x,
                                        y: annotation.coordinate[END_POINT].y,
                                    },
                                    [END_POINT]: {
                                        x: annotation.coordinate[END_POINT].x + stamp.width,
                                        y: annotation.coordinate[END_POINT].y + stamp.height,
                                    },
                                };
                            } else if (pageRotation === degrees.THREE_QUARTERS) {
                                newCoordinates = {
                                    [START_POINT]: {
                                        x: annotation.coordinate[START_POINT].x + (
                                            annotation.coordinate[END_POINT].x - annotation.coordinate[START_POINT].x),
                                        y: annotation.coordinate[START_POINT].y,
                                    },
                                    [END_POINT]: {
                                        x: annotation.coordinate[START_POINT].x + stamp.width + (
                                            annotation.coordinate[END_POINT].x - annotation.coordinate[START_POINT].x),
                                        y: annotation.coordinate[START_POINT].y + stamp.height,
                                    },
                                };
                            }

                            updateShape(newCoordinates, stamp);
                        } else {
                            if (pageRotation === degrees.ZERO) {
                                newCoordinates = {
                                    [START_POINT]: {
                                        x: newCoordinates[START_POINT].x,
                                        y: newCoordinates[END_POINT].y,
                                    },
                                    [END_POINT]: {
                                        x: newCoordinates[START_POINT].x + stamp.width,
                                        y: newCoordinates[END_POINT].y + stamp.height,
                                    },
                                };
                            } else if (pageRotation === degrees.MINUS_QUARTER) {
                                newCoordinates = {
                                    [START_POINT]: {
                                        x: newCoordinates[START_POINT].x +
                                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y),
                                        y: newCoordinates[START_POINT].y,
                                    },
                                    [END_POINT]: {
                                        x: newCoordinates[START_POINT].x + stamp.width +
                                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y),
                                        y: newCoordinates[START_POINT].y + stamp.height,
                                    },
                                };
                            } else if (pageRotation === degrees.HALF) {
                                newCoordinates = {
                                    [START_POINT]: {
                                        x: newCoordinates[START_POINT].x,
                                        y: newCoordinates[START_POINT].y -
                                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y),
                                    },
                                    [END_POINT]: {
                                        x: newCoordinates[START_POINT].x + stamp.width,
                                        y: newCoordinates[START_POINT].y + stamp.height -
                                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y),
                                    },
                                };
                            } else if (pageRotation === degrees.THREE_QUARTERS) {
                                newCoordinates = {
                                    [START_POINT]: {
                                        x: newCoordinates[START_POINT].x -
                                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y),
                                        y: newCoordinates[END_POINT].y -
                                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y),
                                    },
                                    [END_POINT]: {
                                        x: newCoordinates[START_POINT].x + stamp.width -
                                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y),
                                        y: newCoordinates[END_POINT].y + stamp.height -
                                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y),
                                    },
                                };
                            }

                            updateShape(newCoordinates, stamp);
                        }
                    }
                });
            });

        }
    }, [recentlySnappedAnnotationId]);

    useEffect(() => {
        if (currentPage && currentPage.selectedComment) {
            const selectedComment = currentPage.selectedComment;
            const newCoordinates = {
                [START_POINT]: {
                    x: selectedComment.coordinate[0][START_POINT].x * scale,
                    y: selectedComment.coordinate[0][START_POINT].y * scale,
                },
                [END_POINT]: {
                    x: selectedComment.coordinate[0][END_POINT].x * scale,
                    y: selectedComment.coordinate[0][END_POINT].y * scale,
                },
            };

            const highLightComment = {
                fill: TRANSPARENT,
                stroke: '#2962ff',
                height: (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y + 1),
                width: (newCoordinates[END_POINT].x - newCoordinates[START_POINT].x + 1),
                opacity: 1,
                strokeWidth: 3,
                x: (newCoordinates[START_POINT].x),
                y: (newCoordinates[START_POINT].y),
            };

            setCommentHighlight(highLightComment);
        } else {
            if (commentHighlightRect !== DEFAULT_DRAW_RECTANGLE) {
                setCommentHighlight(DEFAULT_DRAW_RECTANGLE);
            }
        }
    }, [currentPage]);

    useEffect(() => {
        let addadjustments;

        if (pageRotation === degrees.ZERO) {
            addadjustments = 1.3 * scale;
        } else if (pageRotation === degrees.HALF) {
            addadjustments = 1.8 * scale;
        } else if (pageRotation === degrees.THREE_QUARTERS) {
            addadjustments = 1 * scale;
        }

        const links = pageList && pageList.currentPageHyperLinks ?
            pageList.currentPageHyperLinks.map((link: IHyperLink) => {
                const newCoordinates = getCoordinatesToHighlight(link.coordination);

                return {
                    fill: '#4f91d3',
                    height: 1 * scale,
                    width: (newCoordinates[END_POINT].x - newCoordinates[START_POINT].x + 1 * scale),
                    opacity: 1,
                    strokeWidth: DEFAULT_STROKE_WIDTH,
                    x: newCoordinates[START_POINT].x,
                    y: pageRotation === degrees.MINUS_QUARTER ? newCoordinates[START_POINT].y - 2 * scale :
                        (newCoordinates[START_POINT].y +
                            (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y - addadjustments)),
                };
            }) : [];

        setHyperLinks(links);
    }, [pageList.currentPageHyperLinks, scale]);

    if (!singleSelectedShape && selectedShape) {
        setSelectedShape(null);
    }

    const setNewCoordinates = (curSearchElement: ICurrentSearchElement): void => {
        const start = curSearchElement.coordinates[START_POINT];
        const end = curSearchElement.coordinates[END_POINT];

        setSearchHighlight({
            x: start.x,
            y: start.y,
            width: (end.x - start.x),
            height: (end.y - start.y),
            fill: SEARCH_HIGHLIGHT_COLOR,
            opacity: SEARCH_HIGHLIGHT_OPACITY,
        });
    };

    const handleContext = (e: Konva.KonvaEventObject<PointerEvent>): void => {
        const { target, evt } = e;
        const elementType = target.className;
        const startPoint = { x: evt.offsetX, y: evt.offsetY };

        if (selectedShape) {
            const selectedShapeData = {
                typeId: !!selectedShape.annotationTypeId ? selectedShape.annotationTypeId : selectedShape.stampTypeId,
                id: selectedShape.id,
                isAnnotation: !!selectedShape.annotationTypeId,
                layerId: actionParams.layers[0].id,
            };
            const params = {
                redactionDocumentId: actionParams.redactionDocumentId,
                documentId: actionParams.documentId,
                pageId: actionParams.pageId,
            };

            handleOverlayedShape(params, selectedShapeData);
        }

        // TODO opening contextmenu by resize box attrs name is (area)_anchor.
        if (elementType === 'Rect') {
            const isSever = annotations.find((item: IKonvaRectangle) =>
                item.name === e.target.attrs.name).isSever;

            onContextMenu(isSever ? Shapes.Sever : Shapes.Highlight, startPoint);

        } else if (elementType === Shapes.Text) {
            onContextMenu(Shapes.Text, startPoint);
        } else {
            onContextMenu(Shapes.Stage, startPoint);
        }

    };

    const applyShape = (e: Konva.KonvaEventObject<DragEvent>): void => {
        const { target, evt } = e;
        const { name, value } = e.target.attrs;
        const shapes = value ? annotations : stamps;
        let selectedShape: any;
        let singleSelectedShape: any;
        if (name) {
            selectedShape = target.attrs.name ? getWantedObjectByProp(shapes, NAME, name) : null;
            singleSelectedShape = useSelector(getSingleSelectedShape);
        }
        const selectedStamps = !isEmpty(selectedArticleStamps) || !isEmpty(selectedCustomStamps);
        const isExemption = !isEmpty(selectedArticleStamps);
        const isMultipleAnnotationSelected = selectedAnnotationShapes.some((annotation: IAnnotation) =>
            annotation.isSever,
        );
        handleCloseSelects();

        if (singleSelectedShape && singleSelectedShape.isSever
            && !(selectedCustomStamps.length || selectedCustomStamps.isExemption) && target.attrs.name) {
            setAnnotationId(singleSelectedShape.id);
            setLinkedArticleFlag(true);
        } else if ((singleSelectedShape && !singleSelectedShape.isSever
            && linkedAnnotaionAndStampId.linkedArticlesFlag
            && linkedAnnotaionAndStampId.annotaionId) ||
            (selectedCustomStamps.length || selectedCustomStamps.isExemption)) {
            setAnnotationId(null);
            setLinkedArticleFlag(false);
        }

        if (target.parent && target.parent.className === TRANSFORMER_CLASS) {
            return;

        }

        // remove transformer
        if (showResize && !target.attrs.name && ((singleSelectedShape && !selectedStamps)
            || (singleSelectedShape && selectedAnnotationType))) {
            handleCleanSelectedShapes();
            setAnnotationId(null);
            setLinkedArticleFlag(false);
            removeTransformer();

            return;

        }

        if (showResize && !target.attrs.name && (singleSelectedShape && singleSelectedShape.isSever)
            && selectedStamps && !selectedAnnotationType) {
            handleCleanSelectedShapes();
            handleStamps(evt.offsetX, evt.offsetY);
            removeTransformer();
            return;
        }

        if (target.attrs.name) {
            const isBelongToGroup = getWantedObjectByProp(
                [...selectedStampShapes, ...selectedAnnotationShapes, ...(selectedShape || [])],
                NAME,
                target.attrs.name,
            );

            if (!isBelongToGroup && !e.evt.ctrlKey) {
                handleCleanSelectedShapes();
            }

            return;
        } else if (!target.attrs.name) {
            handleCleanSelectedShapes();

            if (singleSelectedShape) {
                removeTransformer();
            }
        } else if (!selectedAnnotationType && !selectedStamps || newRect.width || showResize) {
            return;
        }

        if (selectedAnnotationType) {
            startDrawRectangle(evt.offsetX, evt.offsetY);
        } else if (isExemption && isMultipleAnnotationSelected) {
            openAlertModal(ALERT_DIALOG_MODAL, {
                id: ALERT_DIALOG_MODAL,
                message: MANUAL_LINKING_MULTIPLE_SEVERS_TO_ARTICLES,
                title: WARNING,
            });
        } else if (selectedCustomStamps && !isContextMenu(evt) && !singleSelectedShape) {
            handleStamps(evt.offsetX, evt.offsetY);
        }

        handleHyperLinks(e);
    };

    const handleStageMouseDown = (e: Konva.KonvaEventObject<any>): void => {
        const { name, value } = e.target.attrs;
        const shapes = value ? annotations : stamps;
        const isGroup = !!(selectedAnnotationShapes.length || selectedStampShapes.length);

        // remove transformer
        if (showResize && !name) {
            removeTransformer();
            return;
        }

        if (name) {
            setHyperLink(null);
            setCursor(DEFAULT_CURSOR);

            const selectedName = name.includes(STAMP) ? name.replace(STAMP, '') : name.replace(ANNOTATION, '');
            const selectedId = Number(selectedName);
            const shape = getWantedObjectByProp(shapes, ID, Number(selectedName));
            const restrictSeverSelection = shape && shape.isSever ? loadingLinkedArticles : false;

            // do nothing if shape is already selected
            if (selectedShape && selectedName === selectedShape) {
                return;

            } else if (selectedId && e.evt.ctrlKey) {

                if (singleSelectedShape
                    && singleSelectedShape.isSever
                    && linkedAnnotaionAndStampId.linkedArticlesFlag
                    && linkedAnnotaionAndStampId.annotaionId) {

                    handleCleanSelectedShapes();
                    setLinkedArticleFlag(false);
                    setAnnotationId(null);
                }

                const notSelectedShapesRange = value ? selectedStampShapes : selectedAnnotationShapes;
                const isAlreadyChecked = getWantedObjectByProp(
                    value ? selectedAnnotationShapes : selectedStampShapes,
                    ID,
                    selectedId,
                );
                // TODO: fix any
                const rez: any[] = isAlreadyChecked ? [...notSelectedShapesRange,
                ...deleteObjectById(value ? selectedAnnotationShapes : selectedStampShapes, selectedId),
                ] : [...selectedStampShapes, ...selectedAnnotationShapes, shape];

                const resWithOldFontSize: any[] = rez.map((item: IStamp) => {
                    const oldStamps = stampsLayouts.find((stamp: IStamp) => stamp.id === item.id);

                    return oldStamps ? { ...item, fontSize: oldStamps.fontSize } : item;
                });

                handleSetSelectedShapes(resWithOldFontSize, scale);
                setSelectedShape(null);
                removeTransformer();

            } else if (selectedId && !isGroup && !restrictSeverSelection) {
                const singleSelected = getWantedObjectByProp(
                    shape && shape.name.includes(ANNOTATION) ? annotationLayouts : stampsLayouts,
                    ID,
                    shape.id,
                );

                handleSetSingleSelectedShape(singleSelected, scale);

                if (singleSelected && singleSelected.isSever) {
                    setHighlightLinkedSever(null);
                    setHighlightForLinkedArticles(singleSelected.linkedArticles);
                } else if (singleSelected && singleSelected.isExemption) {
                    setHighlightForLinkedArticles([]);
                    setHighlightLinkedSever(singleSelected.id);
                    setAnnotationId(null);
                    setLinkedArticleFlag(false);
                } else if (singleSelected && !singleSelected.isSever) {
                    removeTransformer();
                }

                setSelectedShape(shape);
                setShowResize(true);
            }
        }

    };

    const updateShape = (coordinate: ICoordinate, shape: IKonvaRectangle, fromTransformer: boolean = false): void => {
        const actionProps = {
            redactionDocumentId: actionParams.redactionDocumentId,
            documentId: actionParams.documentId,
            pageId: actionParams.pageId,
            layerId: actionParams.layers[0].id,
        };

        const data = {
            coordinate: [coordinate],
            lastModified: new Date().toISOString(),
            id: shape.id,
        };

        shape.annotationTypeId ? handleUpdateAnnotations(
            actionProps,
            [{ ...data, annotationTypeId: shape.annotationTypeId }].map((annotation: IAnnotation) =>
                recalculateShape(annotation, scale)), fromTransformer) : handleUpdateStamps(
                    actionParams,
                    [{
                        ...data,
                        stampTypeId: shape.stampTypeId,
                        isExemption: shape.isExemption,
                        pageId: shape.pageId,
                        id: shape.id,
                        lastModified: new Date().toISOString(),
                        text: shape.text,
                        fontSize: getFontSizeByWidth(
                            shape.text, shape.fontSize,
                            STAMP_FONT_FAMILY,
                            coordinate[END_POINT].x - coordinate[START_POINT].x,
                            width,
                        ),
                        rotation: shape.rotate,
                    }].map((stamp: IStamp) => recalculateShape(stamp, scale)),
                );
    };

    const handleDraggable = (e: Konva.KonvaEventObject<DragEvent>): void => {
        const { x, y } = e.target.attrs;

        if (selectedShape) {
            const coordinate = {
                [START_POINT]: { x, y },
                [END_POINT]: {
                    x: x + selectedShape.width,
                    y: y + selectedShape.height,
                },
            };
            const singleSelected = getWantedObjectByProp(
                selectedShape.name.includes(ANNOTATION) ? annotationLayouts : stampsLayouts,
                ID,
                selectedShape.id,
            );

            updateShape(coordinate, selectedShape, true);
            handleSetSingleSelectedShape({ ...singleSelected, coordinate }, scale);
            setSelectedShape({ ...selectedShape, coordinate });
        }
    };

    const startDrawRectangle = (x: number, y: number): void => {
        const allSelectedShapes = [...selectedStampShapes, ...selectedAnnotationShapes];

        if (!allSelectedShapes.length) {
            setCursor(CROSSHAIR_CURSOR);
            setIsDrawActive(true);
            setCoordinates({
                [START_POINT]: { x, y },
                [END_POINT]: { x, y },
            });
        }
    };

    const calculateValidHorizontalCoordinates = (
        x: number,
        y: number,
        textWidth: number,
        textHeight: number,
        idx: number,
    ): ICoordinate[] => {
        const isExemption = !isEmpty(selectedArticleStamps);
        const allStampsCount = isExemption ? selectedArticleStamps.length : selectedCustomStamps.length;

        if (pageRotation === degrees.ZERO) {
            const limitCondition = y + textHeight * allStampsCount > height;
            const newY = limitCondition ? y - ((y + textHeight * allStampsCount) - height) : y;
            const endPointX = x + textWidth > width ? width : x + textWidth;
            const startPointX = x + textWidth > width ? endPointX - textWidth : x;

            return [
                {
                    [START_POINT]: {
                        x: startPointX,
                        y: newY + (textHeight * idx),
                    },
                    [END_POINT]: {
                        x: endPointX,
                        y: newY + (textHeight * (idx + 1)),
                    },
                },
            ];
        }

        if (pageRotation === degrees.MINUS_QUARTER) {
            const limitCondition = x + textHeight * allStampsCount > width;
            const newX = limitCondition ? x - ((x + textHeight * allStampsCount) - width) : x;
            const startPointY = y - textWidth < 0 ? textWidth : y;

            return [
                {
                    [START_POINT]: {
                        x: idx === 0 ? newX : newX + (textHeight * idx),
                        y: startPointY,
                    },
                    [END_POINT]: {
                        x: idx === 0 ? newX + textWidth : x + (textHeight * idx) + textWidth,
                        y: startPointY + textHeight,
                    },
                },
            ];
        }

        if (pageRotation === degrees.HALF) {
            const limitCondition = y - textHeight * allStampsCount < 0;
            const newY = limitCondition ? y - (y - textHeight * allStampsCount) : y;
            const endPointX = x - textWidth < 0 ? textWidth * 2 : x + textWidth;
            const startPointX = x - textWidth < 0 ? textWidth : x;

            return [
                {
                    [START_POINT]: {
                        x: startPointX,
                        y: newY + (textHeight * (-idx)),
                    },
                    [END_POINT]: {
                        x: endPointX,
                        y: newY + (textHeight * (-idx + 1)),
                    },
                },
            ];
        }

        if (pageRotation === degrees.THREE_QUARTERS) {
            const limitCondition = x - textHeight * allStampsCount < 0;
            const newX = limitCondition ? x - (x - textHeight * allStampsCount) : x;
            const startPointY = y + textWidth > height ? height - textWidth : y;

            return [
                {
                    [START_POINT]: {
                        x: idx === 0 ? newX : newX + (textHeight * (-idx)),
                        y: startPointY,
                    },
                    [END_POINT]: {
                        x: idx === 0 ? newX + textWidth : newX + (textHeight * (-idx)) + textWidth,
                        y: startPointY + textHeight,
                    },
                },
            ];
        }
    };

    const parseStamps = (
        stampTypes: any,
        isExemption: boolean,
        x: number,
        y: number,
    ): IStamp[] => (stampTypes.map((stamp: IStampType, i: number): IStamp => {
        const { prefix, suffix } = prefixAndSuffix;
        const text = isExemption ? `${prefix}${stamp.exemptionLabel}${suffix}` : stamp.text;
        const { textWidth, fontSize } = getTextParams(text, DEFAULT_FONT, STAMP_FONT_FAMILY, width);
        const textHeight = fontSize * STAMP_LINE_HEIGHT < MIN_SHAPE_SIZE ?
            MIN_SHAPE_SIZE :
            fontSize * STAMP_LINE_HEIGHT;
        let newX;
        let newY;

        if (pageRotation === degrees.ZERO) {
            newX = x;
            newY = y;
        }

        if (pageRotation === degrees.MINUS_QUARTER) {
            newX = y;
            newY = height - x;
        }

        if (pageRotation === degrees.HALF) {
            newX = width - x;
            newY = height - y;
        }

        if (pageRotation === degrees.THREE_QUARTERS) {
            newX = width - y;
            newY = x;
        }

        return {
            coordinate: calculateValidHorizontalCoordinates(newX, newY, (textWidth + PADDING_OFFSET) * scale,
                textHeight * scale, i),
            isExemption,
            lastModified: new Date().toISOString(),
            stampTypeId: isExemption ? stamp.exemptionCode : stamp.id,
            text: isExemption ? stamp.exemptionLabel : stamp.text,
            pageId: actionParams.pageId,
            fontSize: fontSize * scale,
            // TODO 2 better to rename rotation to page rotation
            rotation: pageRotation,
        };
    }));

    const handleStamps = (x: number, y: number): void => {
        // TODO check this x y coords
        const isExemption = !isEmpty(selectedArticleStamps);
        const isSomeChecked = !isEmpty(selectedCustomStamps) || isExemption;

        if (isSomeChecked) {
            handleAddStamps(
                actionParams,
                parseStamps(
                    isExemption ? selectedArticleStamps : selectedCustomStamps,
                    isExemption,
                    x,
                    y,
                ).map((stamp: IStamp): IStamp => recalculateShape(stamp, scale)),
            );
        }

    };

    const getCoordinatesToHighlight = (coordinate: ICoordinate): ICoordinate => {
        const actualPageWidth = width / scale;
        const actualPageheight = height / scale;

        if (pageRotation === degrees.ZERO) {
            return {
                [START_POINT]: {
                    x: coordinate[START_POINT].x * scale,
                    y: coordinate[START_POINT].y * scale,
                },
                [END_POINT]: {
                    x: coordinate[END_POINT].x * scale,
                    y: coordinate[END_POINT].y * scale,
                },
            };
        } else if (pageRotation === degrees.MINUS_QUARTER) {
            return {
                [START_POINT]: {
                    x: coordinate[END_POINT].y * scale,
                    y: (actualPageheight - coordinate[START_POINT].x) * scale,
                },
                [END_POINT]: {
                    x: coordinate[START_POINT].y * scale,
                    y: (actualPageheight - coordinate[END_POINT].x) * scale,
                },
            };
        } else if (pageRotation === degrees.HALF) {
            return {
                [START_POINT]: {
                    x: (actualPageWidth - coordinate[END_POINT].x) * scale,
                    y: (actualPageheight - coordinate[END_POINT].y) * scale,
                },
                [END_POINT]: {
                    x: (actualPageWidth - coordinate[START_POINT].x) * scale,
                    y: (actualPageheight - coordinate[START_POINT].y) * scale,
                },
            };
        } else if (pageRotation === degrees.THREE_QUARTERS) {
            return {
                [START_POINT]: {
                    x: (actualPageWidth - coordinate[END_POINT].y) * scale,
                    y: coordinate[START_POINT].x * scale,
                },
                [END_POINT]: {
                    x: (actualPageWidth - coordinate[START_POINT].y) * scale,
                    y: coordinate[END_POINT].x * scale,
                },
            };
        }
    };

    const handleHyperLinks = (e: Konva.KonvaEventObject<DragEvent>): boolean => {
        const allSelectedShapes = [...selectedStampShapes, ...selectedAnnotationShapes];
        let isHyperLinksAvailable = false;

        if (pageList && pageList.currentPageHyperLinks && !isDrawActive &&
            !selectedAnnotationType && !allSelectedShapes.length && !singleSelectedShape &&
            !selectedArticleStamps.length && !selectedCustomStamps.length) {
            isHyperLinksAvailable = Boolean(pageList.currentPageHyperLinks.find((links: IHyperLink): boolean => {
                if ((e.evt.offsetX / scale) >= links.coordination[START_POINT].x &&
                    (e.evt.offsetX / scale) <= links.coordination[END_POINT].x &&
                    (e.evt.offsetY / scale) >= links.coordination[START_POINT].y &&
                    (e.evt.offsetY / scale) <= links.coordination[END_POINT].y) {
                    const newCoordinates = getCoordinatesToHighlight(links.coordination);
                    const highLightHyperLink = {
                        fill: '#f0ee5f',
                        height: (newCoordinates[END_POINT].y - newCoordinates[START_POINT].y + 1),
                        width: (newCoordinates[END_POINT].x - newCoordinates[START_POINT].x + 1),
                        opacity: DEFAULT_OPACITY,
                        strokeWidth: DEFAULT_STROKE_WIDTH,
                        x: newCoordinates[START_POINT].x,
                        y: (newCoordinates[START_POINT].y),
                    };

                    setHyperLink(links.hyperlink);
                    setHyperlinkHighlight(highLightHyperLink);

                    return true;
                }

                return false;
            }));
        }

        return isHyperLinksAvailable;
    };

    const moveHandler = (e: Konva.KonvaEventObject<DragEvent>): void => {
        const allSelectedShapes = [...selectedStampShapes, ...selectedAnnotationShapes];
        const endPoint = {
            x: e.evt.offsetX,
            y: e.evt.offsetY,
        };

        if (handleHyperLinks(e) && cursor !== POINTER_CURSOR) {
            setCursor(POINTER_CURSOR);
        }

        if (!handleHyperLinks(e) && hyperLink) {
            setHyperLink(null);
            setHyperlinkHighlight(DEFAULT_DRAW_RECTANGLE);
        }

        if (interactMode && cursor !== POINTER_CURSOR) {
            setCursor(POINTER_CURSOR);
        }

        if (singleSelectedShape && handleHyperLinks(e) && cursor === POINTER_CURSOR && !interactMode) {
            setHyperLink(null);
            setHyperlinkHighlight(DEFAULT_DRAW_RECTANGLE);
            setCursor(DEFAULT_CURSOR);
        }

        const startPoint = { ...coordinates[START_POINT] };

        if (!isDrawActive || !selectedAnnotationType || allSelectedShapes.length) {
            setNewRect(DEFAULT_DRAW_RECTANGLE);

            if (!handleHyperLinks(e) && !interactMode) {
                setCursor(DEFAULT_CURSOR);
            }

            return;
        }

        if (pageRotation === degrees.ZERO) {
            if (endPoint.x < startPoint.x) {
                startPoint.x = endPoint.x;
                startPoint.y = endPoint.y;
                endPoint.x = coordinates[START_POINT].x;
                endPoint.y = coordinates[START_POINT].y;

                setCursor(DEFAULT_CURSOR);
                setIsDrawActive(false);
            }

            setCoordinates({
                [START_POINT]: startPoint,
                [END_POINT]: endPoint,
            });

            const newRectProps = {
                x: startPoint.x,
                y: startPoint.y,
                width: endPoint.x - startPoint.x,
                height: endPoint.y - startPoint.y,
                strokeWidth: DEFAULT_STROKE_WIDTH,
                fill: selectedAnnotationType.isBorderOnly ? TRANSPARENT : selectedAnnotationType.color,
                opacity: selectedAnnotationType.opacity ? Number(selectedAnnotationType.opacity) : DEFAULT_OPACITY,
            };

            /* tslint:disable:no-string-literal */
            if (selectedAnnotationType.isBorderOnly) {
                newRectProps['stroke'] = `${selectedAnnotationType.color}`;
            }

            setNewRect(newRectProps);
        }

        if (pageRotation === degrees.MINUS_QUARTER) {
            [startPoint.x, startPoint.y] = [startPoint.y, height - startPoint.x];
            [endPoint.x, endPoint.y] = [endPoint.y, height - endPoint.x];

            if (endPoint.x < startPoint.x) {
                setCursor(DEFAULT_CURSOR);
                setIsDrawActive(false);
            }

            const newRectProps = {
                x: startPoint.x,
                y: startPoint.y,
                height: endPoint.x - startPoint.x,
                width: startPoint.y - endPoint.y,
                strokeWidth: DEFAULT_STROKE_WIDTH,
                fill: selectedAnnotationType.isBorderOnly ? TRANSPARENT : selectedAnnotationType.color,
                opacity: selectedAnnotationType.opacity ? Number(selectedAnnotationType.opacity) : DEFAULT_OPACITY,
            };

            /* tslint:disable:no-string-literal */
            if (selectedAnnotationType.isBorderOnly) {
                newRectProps['stroke'] = `${selectedAnnotationType.color}`;
            }

            setNewRect(newRectProps);
        }

        if (pageRotation === degrees.HALF) {
            [startPoint.x, startPoint.y] = [width - startPoint.x, height - startPoint.y];
            [endPoint.x, endPoint.y] = [width - endPoint.x, height - endPoint.y];

            if (endPoint.y > startPoint.y || endPoint.x > startPoint.x) {
                setCursor(DEFAULT_CURSOR);
                setIsDrawActive(false);
            }

            const newRectProps = {
                x: startPoint.x,
                y: startPoint.y,
                width: startPoint.x - endPoint.x,
                height: startPoint.y - endPoint.y,
                strokeWidth: DEFAULT_STROKE_WIDTH,
                fill: selectedAnnotationType.isBorderOnly ? TRANSPARENT : selectedAnnotationType.color,
                opacity: selectedAnnotationType.opacity ? Number(selectedAnnotationType.opacity) : DEFAULT_OPACITY,
            };

            /* tslint:disable:no-string-literal */
            if (selectedAnnotationType.isBorderOnly) {
                newRectProps['stroke'] = `${selectedAnnotationType.color}`;
            }

            setNewRect(newRectProps);
        }

        if (pageRotation === degrees.THREE_QUARTERS) {
            [startPoint.x, startPoint.y] = [width - startPoint.y, startPoint.x];
            [endPoint.x, endPoint.y] = [width - endPoint.y, endPoint.x];

            if (endPoint.x > startPoint.x) {
                setCursor(DEFAULT_CURSOR);
                setIsDrawActive(false);
            }

            const newRectProps = {
                x: startPoint.x,
                y: startPoint.y,
                height: endPoint.x - startPoint.x,
                width: startPoint.y - endPoint.y,
                strokeWidth: DEFAULT_STROKE_WIDTH,
                fill: selectedAnnotationType.isBorderOnly ? TRANSPARENT : selectedAnnotationType.color,
                opacity: selectedAnnotationType.opacity ? Number(selectedAnnotationType.opacity) : DEFAULT_OPACITY,
            };

            /* tslint:disable:no-string-literal */
            if (selectedAnnotationType.isBorderOnly) {
                newRectProps['stroke'] = `${selectedAnnotationType.color}`;
            }

            setNewRect(newRectProps);
        }
    };

    const linkArticleAndSever = (
        annotation: IAnnotation,
        isRecentlyUsedStamps: boolean,
        isRecentlyUsedExemption: boolean,
        x: number,
        y: number,
    ): IAnnotationDto[] => {

        return [recalculateShape({
            annotationTypeId: annotation.annotationTypeId,
            color: annotation.color,
            coordinate: annotation.coordinate,
            text: annotation.text,
            stampDtoList: parseStamps(
                isRecentlyUsedStamps ? recentlySingleUsedStamp : lastUsedExemption,
                isRecentlyUsedStamps ?
                    isRecentlyUsedStamps : isRecentlyUsedExemption,
                x,
                y,
            ).map((stamp: IStamp): IStamp => recalculateShape(stamp, scale)),
        }, scale)];

    };

    const autoAlignArticleStamps = (params: IPageActionParams, annotation: any, x: number, y: number)
        : IDocumentAnnotations => {
        const isRecentlyUsedExemption = !isEmpty(lastUsedExemption);
        const isRecentlyUsedStamps = !isEmpty(recentlySingleUsedStamp);
        const annotationDto = linkArticleAndSever(annotation, isRecentlyUsedStamps, isRecentlyUsedExemption, x, y);
        const data: IDocumentAnnotations = {
            documentId: params.documentId,
            pageAnnotations: [{ annotationDto, layerId: params.layerId, pageId: params.pageId }],
        };

        return data;
    };

    const stopDrawRectangle = (): void => {
        const isRecentlyUsedExemption = !isEmpty(lastUsedExemption);
        const isRecentlyUsedStamps = !isEmpty(recentlySingleUsedStamp);
        const isSomeChecked = isRecentlyUsedExemption || isRecentlyUsedStamps;

        if (newRect.width && selectedAnnotationType) {
            const params = {
                redactionDocumentId: actionParams.redactionDocumentId,
                documentId: actionParams.documentId,
                pageId: actionParams.pageId,
                layerId: actionParams.layers[0].id,
            };

            if (pageRotation === degrees.ZERO) {
                const annotation = {
                    annotationTypeId: selectedAnnotationType.id,
                    color: selectedAnnotationType.color,
                    coordinate: [coordinates],
                    lastModified: new Date().toISOString(),
                    opacity: selectedAnnotationType.opacity,
                    text: selectedAnnotationType.description,
                    isBorderOnly: !!selectedAnnotationType.isBorderOnly,

                };

                if (isSeverType && isSeverType.isSever && isAutoStampActive && isSomeChecked) {
                    let x: number;
                    let y: number;

                    if (coordinates[START_POINT].y > coordinates[END_POINT].y) {

                        if (autoAlignArticleStampPreference === NONE_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === LEFT_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === RIGHT_ALIGN_PARAM) {
                            x = coordinates[START_POINT].x, y = coordinates[END_POINT].y;

                        }
                    } else {

                        if (autoAlignArticleStampPreference === NONE_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === LEFT_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === RIGHT_ALIGN_PARAM) {
                            x = coordinates[START_POINT].x, y = coordinates[START_POINT].y;

                        }
                    }

                    addAnnotationToDocuments(params, [autoAlignArticleStamps(params, annotation, x, y)], true);

                } else {
                    handleAddAnnotation(params, [recalculateShape(annotation, scale)]);
                }

            }

            if (pageRotation === degrees.MINUS_QUARTER) {
                const annotation = {
                    annotationTypeId: selectedAnnotationType.id,
                    color: selectedAnnotationType.color,
                    coordinate: [{
                        [START_POINT]: { x: newRect.x, y: newRect.y },
                        [END_POINT]: { x: newRect.x + newRect.height, y: newRect.y - newRect.width },
                    }],
                    lastModified: new Date().toISOString(),
                    opacity: selectedAnnotationType.opacity,
                    text: selectedAnnotationType.description,
                    isBorderOnly: !!selectedAnnotationType.isBorderOnly,

                };

                if (isSeverType && isSeverType.isSever && isAutoStampActive && isSomeChecked) {
                    if (annotation.coordinate[0][END_POINT].y > annotation.coordinate[0][START_POINT].y) {
                        let x: number;
                        let y: number;

                        if ((autoAlignArticleStampPreference === NONE_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === LEFT_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === RIGHT_ALIGN_PARAM)) {
                            x = coordinates[START_POINT].x + newRect.width, y = coordinates[START_POINT].y;

                        }

                        addAnnotationToDocuments(params, [autoAlignArticleStamps(params, annotation, x, y)], true);

                    } else if (annotation.coordinate[0][START_POINT].x < annotation.coordinate[0][END_POINT].x) {
                        let x: number;
                        let y: number;

                        if (autoAlignArticleStampPreference === NONE_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === LEFT_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === RIGHT_ALIGN_PARAM) {
                            x = coordinates[START_POINT].x, y = coordinates[START_POINT].y;

                        }

                        addAnnotationToDocuments(params, [autoAlignArticleStamps(params, annotation, x, y)], true);
                    }
                } else {
                    handleAddAnnotation(params, [recalculateShape(annotation, scale)]);
                }

            }

            if (pageRotation === degrees.HALF) {
                const annotation = {
                    annotationTypeId: selectedAnnotationType.id,
                    color: selectedAnnotationType.color,
                    coordinate: [{
                        [END_POINT]: { x: newRect.x, y: newRect.y },
                        [START_POINT]: { x: newRect.x - newRect.width, y: newRect.y - newRect.height },
                    }],
                    lastModified: new Date().toISOString(),
                    opacity: selectedAnnotationType.opacity,
                    text: selectedAnnotationType.description,
                    isBorderOnly: !!selectedAnnotationType.isBorderOnly,

                };

                if (isSeverType && isSeverType.isSever && isAutoStampActive && isSomeChecked) {
                    if (annotation.coordinate[0][START_POINT].x < annotation.coordinate[0][END_POINT].x
                        && annotation.coordinate[0][START_POINT].y < annotation.coordinate[0][END_POINT].y) {
                        let x: number;
                        let y: number;

                        if (autoAlignArticleStampPreference === NONE_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === LEFT_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === RIGHT_ALIGN_PARAM) {
                            x = coordinates[START_POINT].x, y = coordinates[END_POINT].y;

                        }

                        addAnnotationToDocuments(params, [autoAlignArticleStamps(params, annotation, x, y)], true);

                    }
                } else {
                    handleAddAnnotation(params, [recalculateShape(annotation, scale)]);
                }
            }

            if (pageRotation === degrees.THREE_QUARTERS) {
                const annotation = {
                    annotationTypeId: selectedAnnotationType.id,
                    color: selectedAnnotationType.color,
                    coordinate: [{
                        [START_POINT]: { x: newRect.x + newRect.height, y: newRect.y - newRect.width },
                        [END_POINT]: { x: newRect.x, y: newRect.y },
                    }],
                    lastModified: new Date().toISOString(),
                    opacity: selectedAnnotationType.opacity,
                    text: selectedAnnotationType.description,
                    isBorderOnly: !!selectedAnnotationType.isBorderOnly,

                };

                if (isSeverType && isSeverType.isSever && isAutoStampActive && isSomeChecked) {
                    if (annotation.coordinate[0][START_POINT].x < annotation.coordinate[0][END_POINT].x
                        && annotation.coordinate[0][START_POINT].y < annotation.coordinate[0][END_POINT].y) {
                        let x: number;
                        let y: number;

                        if ((autoAlignArticleStampPreference === NONE_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === LEFT_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === RIGHT_ALIGN_PARAM)) {
                            x = coordinates[START_POINT].x - newRect.width, y = coordinates[END_POINT].y;

                        }

                        addAnnotationToDocuments(params, [autoAlignArticleStamps(params, annotation, x, y)], true);

                    } else if (annotation.coordinate[0][START_POINT].x < annotation.coordinate[0][END_POINT].x
                        && annotation.coordinate[0][START_POINT].y > annotation.coordinate[0][END_POINT].y) {
                        let x: number;
                        let y: number;

                        if (autoAlignArticleStampPreference === NONE_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === LEFT_ALIGN_PARAM ||
                            autoAlignArticleStampPreference === RIGHT_ALIGN_PARAM) {
                            x = coordinates[START_POINT].x, y = coordinates[END_POINT].y;

                        }

                        addAnnotationToDocuments(params, [autoAlignArticleStamps(params, annotation, x, y)], true);
                    }
                } else {
                    handleAddAnnotation(params, [recalculateShape(annotation, scale)]);
                }

            }

            setNewRect(DEFAULT_DRAW_RECTANGLE);
            setIsDrawActive(false);
            setCursor(DEFAULT_CURSOR);

        } else if (hyperLink) {
            window.open(hyperLink, '_blank');
        }
    };

    const getOffset = (): any => {
        if (!pageRotation) {
            return { x: 0, y: 0 };
        }

        if (pageRotation === degrees.MINUS_QUARTER) {
            return { x: 0, y: height };
        }

        if (pageRotation === degrees.THREE_QUARTERS) {
            return { x: width, y: 0 };
        }

        if (pageRotation === degrees.HALF) {
            return { x: width, y: height };
        }
    };

    const removeTransformer = (): void => {
        setShowResize(false);

        const isAnnotaionHighlighted = annotations && !!annotations.length
            && annotations.filter((annotation: IAnnotation) => annotation.stroke !== '',
            );
        const isStampHightLighted = stamps && !!stamps.length && stamps.filter((stamp: IStamp) =>
            stamp.stroke !== '',
        );

        if (isAnnotaionHighlighted && !!isAnnotaionHighlighted.length) {
            setHighlightLinkedSever(null);

        } else if (isStampHightLighted && isStampHightLighted.length) {
            setHighlightForLinkedArticles([]);

        }

        return;
    };

    const pageRotationForNewRect = pageRotation === degrees.MINUS_QUARTER ? -degrees.MINUS_QUARTER : pageRotation;
    const rotatedWidth = pageRotation === degrees.ZERO || pageRotation === degrees.HALF ? width : height;
    const rotatedHeight = pageRotation === degrees.ZERO || pageRotation === degrees.HALF ? height : width;

    return (
        <React.Fragment>
            {children}
            <div className='annotation_wrapper' style={{ cursor }}>
                <Stage
                    onContextMenu={handleContext}
                    width={rotatedWidth}
                    height={rotatedHeight}
                    onMouseDown={applyShape}
                    onMouseMove={moveHandler}
                    onMouseUp={stopDrawRectangle}
                >
                    <Layer onMouseDown={handleStageMouseDown} rotation={pageRotation} offset={getOffset()}>
                        <ShapeComponent
                            moveLayerToTop={singleSelectedShape && !!singleSelectedShape.isSelectOverlayed}
                            pageRotation={pageRotation}
                            annotations={deleteSubarrayFromArray(annotations, selectedAnnotationShapes) as object[]}
                            stamps={deleteSubarrayFromArray(stamps, selectedStampShapes) as object[]}
                            selectedAnnotations={selectedAnnotationShapes}
                            selectedStamps={selectedStampShapes}
                            container={{ width: rotatedWidth, height: rotatedHeight }}
                            handleDraggable={handleDraggable}
                            showTransform={showResize}
                            selectedShape={selectedShape}
                            updateShape={updateShape}
                            cleanSelectedShapes={handleCleanSelectedShapes}
                            scale={scale}
                        />
                        {isDrawActive && <Rect {...newRect} rotation={pageRotationForNewRect} />}
                        {searchHighlight && <Rect {...searchHighlight} />}
                        {commentHighlightRect && <Rect {...commentHighlightRect} />}
                        {hyperLinkHighlightRect && <Rect {...hyperLinkHighlightRect} />}
                        {hyperLinks && hyperLinks.map((link: any, index: number) => <Rect key={index} {...link} />)}
                    </Layer>
                </Stage>
            </div>
        </React.Fragment>
    );
};
/* tslint:enable:no-string-literal */

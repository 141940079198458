export interface IHeader {
    id: string;
    accessor?: string;
    accessorKey?: string;
    header?: string;
    Header?: string;
    minResizeWidth: number;
    show: boolean;
    alwaysShow?: boolean;
    width?: number;
    size?: number;
    sortOrder?:string;
    enableHiding ?: false
}

export const DOCUMENT_LIST_LABEL = 'Document List';

export const PAGE_LIST_LABEL = 'Page List';

export const sortKeys = {
    page: 'actualPageNumber',
    pagination: 'paginationIndex',
    ocr: 'isOcred',
    disclosureType: 'disclosure',
    subject: 'subject',
    to: 'to',
    toOrg: 'toOrg',
    from: 'from',
    fromOrg: 'fromOrg',
    article: 'article',
    documentDate: 'documentDate',
};
import * as React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'rc-scrollbars';
import { IRequestSecuritiesListParams } from './ModalRequestSecurity.model';
import { IValidRowLevelSecurity, IValidUser } from '../../../redux/reducers/amandaContent/amandaContent.model';
import { AvailableSecurityRow } from './AvailableSecurityRow ';
import { RowWithCheckbox } from './RowWithCheckBox';
import { isExistRowLevelSecurity, isExistValidUser } from '../../../utils/requestSecurity.utils';
import { getFilteredArrayByObjectProp } from '../../../utils/array.utils';
import {
    SELECTED_ROW_LEVEL_SECURITY_DTOS,
    SELECTED_VALID_USER_DTOS,
    ROW_LEVEL_SECURITY_CODE,
    USER_ID,
    VALID_ROW_LEVEL_SECURITY,
    VALID_USER,
} from './ModalRequestSecurityConstants';
import './modalRequestSecurity.style.scss';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';
import { initialLabel } from '../../../constants/localization.constants';
import { useSelector } from 'react-redux';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';

const getRequestLabelsByKey = (key: string): string => {
    switch (key) {
        case 'REQUESTSECURITY_MODAL_NAME':
            return 'nameLabel';     
        default:  return '';
    }
}

export const AvailableSecuritiesList = (
    {
        availableSecurities,
        requestSecurities,
        selectedAvailableSecurities,
        setSelectedAvailableSecurities,
    }: IRequestSecuritiesListParams,
): JSX.Element => {
    const [securityChildrenToShow, setSecurityChildrenToShow] = useState([]);

    const handleSetSelectedAvailableSecurities = <G extends object>(security: G): void => {
        const securities = security[ROW_LEVEL_SECURITY_CODE] ?
            selectedAvailableSecurities[SELECTED_ROW_LEVEL_SECURITY_DTOS] :
            selectedAvailableSecurities[SELECTED_VALID_USER_DTOS];
        const property = security[ROW_LEVEL_SECURITY_CODE] ? ROW_LEVEL_SECURITY_CODE : USER_ID;
        const isExist = property === USER_ID ? isExistValidUser(securities, security) :
            isExistRowLevelSecurity(securities, security);

        if (property === USER_ID) {
            setSelectedAvailableSecurities({
                ...selectedAvailableSecurities,
                [SELECTED_VALID_USER_DTOS]: isExist ? getFilteredArrayByObjectProp(
                    securities,
                    security,
                    USER_ID,
                ) : [...selectedAvailableSecurities[SELECTED_VALID_USER_DTOS], security],
            });
        } else {
            setSelectedAvailableSecurities({
                ...selectedAvailableSecurities,
                [SELECTED_ROW_LEVEL_SECURITY_DTOS]: isExist ? getFilteredArrayByObjectProp(
                    securities,
                    security,
                    ROW_LEVEL_SECURITY_CODE,
                ) : [...selectedAvailableSecurities[SELECTED_ROW_LEVEL_SECURITY_DTOS], security],
            });
        }
    };

    const showCheckBoxForRowLevel = (code: number): boolean => {
        return requestSecurities && requestSecurities[SELECTED_ROW_LEVEL_SECURITY_DTOS]
            ? !requestSecurities[SELECTED_ROW_LEVEL_SECURITY_DTOS].find(
            <T extends object>(
                security: T,
            ): boolean =>
                security[VALID_ROW_LEVEL_SECURITY] && security[VALID_ROW_LEVEL_SECURITY].rowLevelSecurityCode === code,
        ) : true;
    };

    const labels = {
        nameLabel: initialLabel,
     };
 
     const redactionLang =useSelector(getRedactionLanguage);
     const modifiedLabels=useSelector(getModifiedLabels);
     const langRule = changeLang(redactionLang);
     resourceBundle.map((resource: any) => {
         if (getRequestLabelsByKey(resource.resourceKey)) {
             labels[getRequestLabelsByKey(resource.resourceKey)] = resource;
         }
 
         return resource;
     });
     modifiedLabels.map((resource: any) => {
        if (getRequestLabelsByKey(resource.resourceKey)) {
            labels[getRequestLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    const showCheckboxForValidUser = (id: string): boolean => {
        return requestSecurities && requestSecurities[SELECTED_VALID_USER_DTOS]
            ? !requestSecurities[SELECTED_VALID_USER_DTOS].find(
            <T extends object>(security: T): boolean =>
                security[VALID_USER].userId === id,
        ) : true;
    };

    return (
        <div>
            <Typography className='heading' variant='h6'>
             {labels.nameLabel[langRule]}
            </Typography>
            <div className='modal_request_security_available_securities_list'>
                <AutoSizer>
                    {({width, height}: { width: number; height: number; }): JSX.Element => (
                        <div style={{width, height}}>
                            <Scrollbars>
                                { availableSecurities && <ul>
                                    {
                                        availableSecurities['availableRowLevelSecurities'].map((
                                            levelSecurity: IValidRowLevelSecurity,
                                        ): JSX.Element => (
                                            <li
                                                key={`rowSecurity_${levelSecurity.rowLevelSecurityCode}`}
                                                className='modal_request_security_available_securities_list_row'
                                            >
                                                <RowWithCheckbox
                                                    showCheckbox={showCheckBoxForRowLevel(
                                                        levelSecurity.rowLevelSecurityCode,
                                                    )}
                                                    isGroup={true}
                                                    security={levelSecurity}
                                                    checked={!!isExistRowLevelSecurity(
                                                        selectedAvailableSecurities[SELECTED_ROW_LEVEL_SECURITY_DTOS],
                                                        levelSecurity,
                                                    )}
                                                    handleChange={(): void =>
                                                        handleSetSelectedAvailableSecurities(levelSecurity)
                                                    }
                                                    securityChildrenToShow={securityChildrenToShow}
                                                    setSecurityChildrenToShow={setSecurityChildrenToShow}
                                                />
                                               <ul>
                                                    {
                                                        levelSecurity.validRowLevelSecurityChildren.map(
                                                        (child: IValidRowLevelSecurity): JSX.Element => (
                                                            <AvailableSecurityRow
                                                                securityChildrenToShow={securityChildrenToShow}
                                                                setSecurityChildrenToShow={setSecurityChildrenToShow}
                                                                isGroup={true}
                                                                security={child}
                                                                key={`valid_row_security_${child.rowLevelSecurityCode}`}
                                                                setSelectedAvailableSecurities={
                                                                    handleSetSelectedAvailableSecurities
                                                                }
                                                                selectedAvailableSecurities={
                                                                    selectedAvailableSecurities
                                                                }
                                                            />
                                                        ))
                                                    }
                                               </ul>
                                            </li>
                                        ))
                                    }
                                    {
                                        availableSecurities['availableValidUsers'].map((
                                            user: IValidUser,
                                        ): JSX.Element => (
                                            <li
                                                key={`valid_user_${user.userId}`}
                                                className='modal_request_security_available_securities_list_row'
                                            >
                                                <RowWithCheckbox
                                                    showCheckbox={showCheckboxForValidUser(user.userId)}
                                                    isGroup={false}
                                                    security={user}
                                                    checked={!!isExistValidUser(
                                                        selectedAvailableSecurities[SELECTED_VALID_USER_DTOS],
                                                        user,
                                                    )}
                                                    handleChange={(): void =>
                                                        handleSetSelectedAvailableSecurities(user)
                                                    }
                                                    securityChildrenToShow={securityChildrenToShow}
                                                    setSecurityChildrenToShow={setSecurityChildrenToShow}
                                                />
                                            </li>
                                        ))
                                    }
                                </ul>}
                            </Scrollbars>
                        </div>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
};

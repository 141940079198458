import * as React from 'react';
import { ITableColumn } from '../components/table/table.model';

export const SEARCH = 'Search';
export const ASSIGN = 'ASSIGN';
export const REMOVE = 'REMOVE';
export const CONTACTS_IN_THE_REQUEST = 'Contacts in the request';
export const EMPTY_CONTACTS_LIST_MESSAGE = 'Your search did not return any results. Please review the search criteria.';
export const EMPTY_ASSIGNED_CONTACTS_MESSAGE = 'No persons have been added yet.';

export const SearchResultHeaders = (labels: any, langRule: string): ITableColumn[] => [
    {
        id: 'action',
        accessorKey: 'action',
        accessor: 'action',
        header: labels.actionHeaderLabel[langRule],
        sortOrder : null,
        minResizeWidth: 1,
    }, {
        id: 'nameFirst',
        accessorKey: 'nameFirst',
        accessor: 'nameFirst',
        header: labels.firstNameHeaderLabel[langRule],
        sortOrder : null,
        minResizeWidth: 1,
    }, {
        id: 'nameLast',
        accessorKey: 'nameLast',
        accessor: 'nameLast',
        header: labels.lastNameHeaderLabel[langRule],
        sortOrder : null,
        minResizeWidth: 1,
    }, {
        id: 'peopleRsn',
        accessorKey: 'peopleRsn',
        accessor: 'peopleRsn',
        header: labels.pesopleRSNHeaderLabel[langRule],
        sortOrder : null,
        minResizeWidth: 1,
    }, {
        id: 'organizationName',
        accessorKey: 'organizationName',
        accessor: 'organizationName',
        header: labels.organisationNameHeaderLabel[langRule],
        sortOrder : null,
        minResizeWidth: 1,
    }, {
        id: 'peopleCode',
        accessorKey: 'peopleCode',
        accessor: 'peopleCode',
        header: labels.peopleTypeHeaderLabel[langRule],
        sortOrder : null,
        minResizeWidth: 1,
    },
];
export const AssignedContactHeaders = (labels: any, langRule: string): ITableColumn[] => [
    {
        id: 'nameFirst',
        accessorKey: 'nameFirst',
        accessor: 'nameFirst',
        header: labels.firstNameHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder : null,
    }, {
        id: 'nameLast',
        accessorKey: 'nameLast',
        accessor: 'nameLast',
        header: labels.lastNameHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder : null,
    }, {
        id: 'peopleRsn',
        accessorKey: 'peopleRsn',
        accessor: 'peopleRsn',
        header: labels.pesopleRSNHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder : null,
    },{
        id: 'action',
        accessorKey: 'action',
        accessor: 'action',
        header: labels.actionHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder : null,
    },
];

export const EmptySearchParams = {
    nameFirst: '',
    nameLast: '',
    peopleCode: '',
    organizationName: '',
    userTeam: '',
    userDepartment: '',
    userLocation: '',
};

export const ConsalteesResult = (labels: any, langRule: string): ITableColumn[] => [
    {
        id: 'nameFirst',
        accessorKey: 'nameFirst',
        accessor: 'nameFirst',
        header: labels.firstNameHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder : null,
    }, {
        id: 'nameLast',
        accessorKey: 'nameLast',
        accessor: 'nameLast',
        header: labels.lastNameHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder : null,
    }, {
        id: 'peopleDesc',
        accessorKey: 'peopleDesc',
        accessor: 'peopleDesc',
        header: labels.peopleTypeHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder : null,
    }, {
        id: 'action',
        accessorKey: 'action',
        accessor: 'action',
        header: labels.actionHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder : null,
    },
];
import api from '../../api/reductionApi';
import { AnyAction, Dispatch } from 'redux';
import {
    GET_REQUESTER_REPORT_PENDING,
    GET_REQUESTER_REPORT_SUCCESS,
    GET_REQUESTER_REPORT_FAILURE,
    POST_REQUESTER_REPORT_PENDING,
    POST_REQUESTER_REPORT_SUCCESS,
    POST_REQUESTER_REPORT_FAILURE,
} from '../reducers/modalRequesterReport/constants';
import { action } from 'typesafe-actions';
import {
    IRequesterReport,
} from '../reducers/modalRequesterReport/requesterReport.model';
import { IError } from '../common.model';
import { fileSaver } from '../../utils/fileSaver';
import {
    REQUESTER_ALL_DOCUMENTS,
    REQUESTER,
} from '../../constants/requesterReport';

export const fetchRequesterReportPending = (): AnyAction => action(GET_REQUESTER_REPORT_PENDING);
export const fetchRequesterReportSuccess = (dataList: IRequesterReport[]): AnyAction =>
    action(GET_REQUESTER_REPORT_SUCCESS, dataList);
export const fetchRequesterReportFailure = (error: IError): AnyAction => action(GET_REQUESTER_REPORT_FAILURE, error);

export const postRequesterReportPending = (): AnyAction => action(POST_REQUESTER_REPORT_PENDING);
export const postRequesterReportSuccess = (dataList: any): AnyAction =>
    action(POST_REQUESTER_REPORT_SUCCESS, dataList);
export const postRequesterReportFailure = (error: IError): AnyAction => action(POST_REQUESTER_REPORT_FAILURE, error);

// TODO investigate how to remove any from Dispatch type
export const fetchRequesterReport = (
    param: any,
    redactionDocumentId: number,
    documentIds: number[],
    reportLang: string
): (dispatch: Dispatch<any>) => void =>
    (dispatch: Dispatch<any>): void  => {

        if (param && !param.onlySelectedItem) {
            dispatch(fetchRequesterReportAllDocuments(param, redactionDocumentId, reportLang ));
        } else {
            dispatch(fetchRequesterReportById(param, redactionDocumentId, documentIds, reportLang ));
        }
};

export const fetchRequesterReportAllDocuments = (param: IRequesterReport[], redactionDocumentId: number, reportLang: string):
    (dispatch: Dispatch) => Promise<void> => async (dispatch: Dispatch): Promise<void> => {

        dispatch(fetchRequesterReportPending());

        try {
            const dataRequesterReport = await api.reportController.
            getRequesterReport(redactionDocumentId, reportLang, param);

            dispatch(fetchRequesterReportSuccess(dataRequesterReport));

            fileSaver(dataRequesterReport, REQUESTER_ALL_DOCUMENTS);

        } catch (error) {
            dispatch(fetchRequesterReportFailure(error));
        }
    };

export const fetchRequesterReportById = (
    param: IRequesterReport[],
    redactionDocumentId: number,
    documentIds: number[],
    reportLang: string,
): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(postRequesterReportPending());

        try {

            const dataRequesterReport = await api.reportController.postRequesterReport(
                redactionDocumentId,
                reportLang,
                param,
                documentIds,
            );

            dispatch(postRequesterReportSuccess(dataRequesterReport));

            fileSaver(dataRequesterReport, REQUESTER);

        } catch (error) {
            dispatch(postRequesterReportFailure(error));
        }
    };

export const SAVE_NOTE = 'reduction/SAVE_NOTE';
export const CANCEL_NOTE = 'reduction/CANCEL_NOTE';

export const GET_PAGE_METADATA_PENDING = 'reduction/GET_PAGE_METADATA_PENDING';
export const GET_PAGE_METADATA_SUCCESS = 'reduction/GET_PAGE_METADATA_SUCCESS';
export const GET_PAGE_METADATA_FAILURE = 'reduction/GET_PAGE_METADATA_FAILURE';
export const ADD_PAGE_METADATA_PENDING = 'reduction/ADD_PAGE_METADATA_PENDING';
export const ADD_PAGE_METADATA_SUCCESS = 'reduction/ADD_PAGE_METADATA_SUCCESS';
export const ADD_PAGE_METADATA_FAILURE = 'reduction/ADD_PAGE_METADATA_FAILURE';
export const UPDATE_PAGE_METADATA_PENDING = 'reduction/UPDATE_PAGE_METADATA_PENDING';
export const UPDATE_PAGE_METADATA_SUCCESS = 'reduction/UPDATE_PAGE_METADATA_SUCCESS';
export const UPDATE_PAGE_METADATA_FAILURE = 'reduction/UPDATE_PAGE_METADATA_FAILURE';

export const GET_PAGE_CONSULTEES_PENDING = 'reduction/GET_PAGE_CONSULTEES_PENDING';
export const GET_PAGE_CONSULTEES_SUCCESS = 'reduction/GET_PAGE_CONSULTEES_SUCCESS';
export const GET_PAGE_CONSULTEES_FAILURE = 'reduction/GET_PAGE_CONSULTEES_FAILURE';
export const CLEAR_PAGE_CONSULTEES = 'reduction/CLEAR_PAGE_CONSULTEES';

export const DELETE_PAGE_CONSULTEES_PENDING = 'reduction/DELETE_PAGE_CONSULTEES_PENDING';
export const DELETE_PAGE_CONSULTEES_SUCCESS = 'reduction/DELETE_PAGE_CONSULTEES_SUCCESS';
export const DELETE_PAGE_CONSULTEES_FAILURE = 'reduction/DELETE_PAGE_CONSULTEES_FAILURE';

export const CLEAR_PAGE_METADATA = 'redaction/CLEAR_PAGE_METADATA';

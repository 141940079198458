import resourceBundle from "../containers/localization/localizationData";
import { changeLang } from "../redux/actions/localization";
import { MODIFIED_LABELS, REDACTION_LANG } from "../redux/reducers/localStorage/constant";
import { getItem, getSessionItem } from "../utils/localStorage.util";
import { initialLabel } from "./localization.constants";
import { SEARCH } from '../constants/advancedSearch.constants';
import { ASSIGN, CANCEL, COMPARE } from "./common.constants";
import { LabelSharp } from "@mui/icons-material";
import { FULL_WIDTH_NAME, PAGE_WIDTH_NAME, FULL_PAGE_NAME } from "../constants";

export const SELECT_ONE = 'Select at least one:';
export const ASSIGNMENT = 'Assignment';
export const REFERENCES = 'References';
export const PAGE_NAVIGATION = 'Page Navigation';
export const DOCUMENT_SET_NAVIGATION = 'Document Set Navigation';
export const BACK_HOME = 'Back Home';
export const DOCUMENT_DISCLOSURE = 'Document Disclosure:';
export const PAGE_DISCLOSURE = 'Page Disclosure:';

export const ASSIGNED_DUPLICATES_MODAL = 'assignDuplicates';
export const ASSIGNED_DUPLICATES_TITLE_MODAL = 'Assign Duplicates';

export const DUPLICATES_LABEL = 'Duplicates';
export const MASTER_DOCUMENT_LABEL = 'Master Document';
export const PAGE_AMOUNT_LABEL = 'Page Amount';
export const DOCUMENT_ID_PARAM = 'documentId';
export const DOCUMENT_NAME_PARAM = 'documentName';
export const ATLEAST_ONE_REQUIRED = 'at least one required';

export const ALERT_MESSAGE = 'You can select only one page to see duplicate references!';

const getManageDuplicatesByKey = (key: string): string => {
    switch (key) {
        case 'MANAGE_DUPLICATES_SCREEN_COMPARE':
            return 'compareLabel';
        case 'MANAGE_DUPLICATES_SCREEN_SUBJECT':
            return 'subjectLabel';
        case 'MANAGE_DUPLICATES_SCREEN_TO':
            return 'toLabel';
        case 'MANAGE_DUPLICATES_SCREEN_FROM':
            return 'fromLabel';
        case 'MANAGE_DUPLICATES_SCREEN_DATE':
            return 'dateLabel';
        case 'MANAGE_DUPLICATES_SCREEN_CLASSIFICATION':
            return 'classificationLabel';
        case 'MANAGE_DUPLICATES_SCREEN_LANGUAGE':
            return 'languageLabel';
        case 'MANAGE_DUPLICATES_SCREEN_TO_ORGANIZATION':
            return 'toOrganizationLabel';
        case 'MANAGE_DUPLICATES_SCREEN_FROM_ORGANIZATION':
            return 'fromOrganizationLabel';
        case 'MANAGE_DUPLICATES_SCREEN_DOCUMENTTYPE':
            return 'documentTypeLabel';
        case 'MANAGE_DUPLICATES_SCREEN_REFERENCE_NUMBER':
            return 'referenceNumberLabel';
        case 'MANAGE_DUPLICATES_SCREEN_FILENAME':
            return 'filenameLabel';
        case 'MANAGE_DUPLICATES_SCREEN_FIELD1':
            return 'field1Label';
        case 'MANAGE_DUPLICATES_SCREEN_CANCEL':
            return 'cancelLabel';
        case 'MANAGE_DUPLICATES_SCREEN_SEARCH':
            return 'searchLabel';
        case 'MANAGE_DUPLICATES_SCREEN_ASSIGNMENT':
            return 'assignmentLabel';
        case 'MANAGE_DUPLICATES_SCREEN_REFERENCES':
            return 'referencesLabel';
        case 'MANAGE_DUPLICATES_SCREEN_SELECT_ATLEAST_ONE':
            return 'selectAtleastOneLabel';
        case 'MANAGE_DUPLICATES_SCREEN_PAGE_NAVIGATION':
            return 'pageNavigationLabel';
        case 'MANAGE_DUPLICATES_SCREEN_DOCUMENT_SET_NAVIGATION':
            return 'documentSetNavigationLabel';
        case 'MANAGE_DUPLICATES_SCREEN_GENERAL_ATTRIBUTES':
            return 'generalAttributesLabel';
        case 'MANAGE_DUPLICATES_SCREEN_DOCUMENT_NAME':
            return 'documentNameLabel';
        case 'MANAGE_DUPLICATES_SCREEN_DISCLOSURE':
            return 'disclosureLabel';
        case 'MANAGE_DUPLICATES_SCREEN_START':
            return 'startLabel';
        case 'MANAGE_DUPLICATES_SCREEN_INDEX_ATTRIBUTES':
            return 'indexAttributesLabel';
        case 'MANAGE_DUPLICATES_SCREEN_TO_ORG':
            return 'toOrgLabel';
        case 'MANAGE_DUPLICATES_SCREEN_FROM_ORG':
            return 'fromOrgLabel';
        case 'MANAGE_DUPLICATES_SCREEN_DOCUMENT_ATTRIBUTES':
            return 'documentAttributesLabel';
        case 'MANAGE_DUPLICATES_SCREEN_AUTHOR':
            return 'authorLabel';
        case 'MANAGE_DUPLICATES_SCREEN_PAGES':
            return 'pagesLabel';
        case 'MANAGE_DUPLICATES_SCREEN_FILE_SIZE':
            return 'fileSizeLabel';
        case 'MANAGE_DUPLICATES_SCREEN_DUPLICATES_TITLE':
            return 'duplicatesTitleLabel';
        case 'MANAGE_DUPLICATES_SCREEN_RESET':
            return 'resetLabel';
        case 'MANAGE_DUPLICATES_SCREEN_SHOW_ALL':
            return 'showAllLabel';
        case 'MANAGE_DUPLICATES_SCREEN_HIDE_ALL':
            return 'hideAllLabel';
        case 'MANAGE_DUPLICATES_SCREEN_BACK_HOME_LABEL':
            return 'backHomeLabel';
        case 'MANAGE_DUPLICATES_SCREEN_PAGE_WIDTH':
            return 'pageWidthLabel';
        case 'MANAGE_DUPLICATES_SCREEN_ACTUAL_PAGE_SIZE':
            return 'actualPageSizeLabel';
        case 'MANAGE_DUPLICATES_SCREEN_FULL_PAGE':
            return 'fullPageLabel';
        case 'ASSIGNED_DUPLICATES_MODAL_TITLE':
            return 'assignedDuplicatesLabel';
        case 'ASSIGNED_DUPLICATES_MASTER_DOCUMENT':
            return 'assignedDuplicatesMasterDocument';
        case 'ASSIGNED_DUPLICATES_DUPLICATE_FIELD':
            return 'assignedDuplicatesDuplicateFieldLabel';
        case 'ASSIGNED_DUPLICATES_PAGE_AMOUNT_FIELD':
            return 'assignedDuplicatesPageAmountLabel';
        case 'ASSIGNED_DUPLICATES_DISCLOSURE':
            return 'assignedDuplicatesDisclosure';
        case 'ASSIGNED_DUPLICATES_CANCEL':
            return 'assignedDuplicatesCancelLabel';
        case 'ASSIGNED_DUPLICATES_ASSIGN':
            return 'assignedDuplicatesAssignLabel';
        case 'ASSIGNED_DUPLICATES_ATLEAST_ONE_REUIRED':
            return 'atleastOnerequiredLabel';
        case 'MANAGE_DUPLICATES_ALERT_MESSAGE':
            return 'duplicatesAlertMessage';
        case 'REFERENCE_MODAL_TITLE':
            return 'referenceModalTitlelabel';
        case 'REFERENCE_MODAL_MASTER_DOCUMENT':
            return 'referenceModalMasterDocument';
        case 'REFERENCE_MODAL_MASTER_DUPLICATES':
            return 'referenceModalDuplicates';
        case 'REFERENCE_MODAL_PAGE':
            return 'referenceModalPage';
        case 'REFERENCE_MODAL_DISCLOSURE':
            return 'referenceModalDisclosure';
        case 'REFERENCE_MODAL_DOCUMENT':
            return 'referenceModalDocument';
        case 'REFERENCE_MODAL_DOCUMENT_DISCLOSURE':
            return 'referenceModalDocumentDisclosure';
        case 'REFERENCE_MODAL_PAGE_DISCLOSURE':
            return 'referenceModalPageDisclosure';
        case 'REFERENCE_MODAL_CLOSE':
            return 'referenceModalClose';
        case 'REFERENCE_MODAL_DELETEALL':
            return 'referenceModalDeleteAll';
        default: return '';
    }
}

export const getManageDuplicatesLabelsByKey = (key: string): string => {
    const redactionLang = getSessionItem(REDACTION_LANG);
    const modifiedLabels = getItem(MODIFIED_LABELS);
    const label = {
        compareLabel: initialLabel,
        selectAtleastOneLabel: initialLabel,
        subjectLabel: initialLabel,
        toLabel: initialLabel,
        fromLabel: initialLabel,
        dateLabel: initialLabel,
        classificationLabel: initialLabel,
        languageLabel: initialLabel,
        toOrganizationLabel: initialLabel,
        fromOrganizationLabel: initialLabel,
        documentTypeLabel: initialLabel,
        referenceNumberLabel: initialLabel,
        filenameLabel: initialLabel,
        field1Label: initialLabel,
        cancelLabel: initialLabel,
        searchLabel: initialLabel,
        assignmentLabel: initialLabel,
        referencesLabel: initialLabel,
        pageNavigationLabel: initialLabel,
        documentSetNavigationLabel: initialLabel,
        generalAttributesLabel: initialLabel,
        documentNameLabel: initialLabel,
        disclosureLabel: initialLabel,
        startLabel: initialLabel,
        indexAttributesLabel: initialLabel,
        toOrgLabel: initialLabel,
        fromOrgLabel: initialLabel,
        documentAttributesLabel: initialLabel,
        authorLabel: initialLabel,
        pagesLabel: initialLabel,
        fileSizeLabel: initialLabel,
        duplicatesTitleLabel: initialLabel,
        resetLabel: initialLabel,
        showAllLabel: initialLabel,
        hideAllLabel: initialLabel,
        backHomeLabel: initialLabel,
        pageWidthLabel: initialLabel,
        actualPageSizeLabel: initialLabel,
        fullPageLabel: initialLabel,
        assignedDuplicatesLabel: initialLabel,
        assignedDuplicatesMasterDocument: initialLabel,
        assignedDuplicatesDuplicateFieldLabel: initialLabel,
        assignedDuplicatesPageAmountLabel: initialLabel,
        assignedDuplicatesDisclosure: initialLabel,
        assignedDuplicatesCancelLabel: initialLabel,
        assignedDuplicatesAssignLabel: initialLabel,
        atleastOnerequiredLabel: initialLabel,
        duplicatesAlertMessage: initialLabel,
        referenceModalTitlelabel: initialLabel,
        referenceModalMasterDocument: initialLabel,
        referenceModalDuplicates: initialLabel,
        referenceModalPage: initialLabel,
        referenceModalDisclosure: initialLabel,
        referenceModalDocument: initialLabel,
        referenceModalDocumentDisclosure: initialLabel,
        referenceModalPageDisclosure: initialLabel,
        referenceModalClose: initialLabel,
        referenceModalDeleteAll: initialLabel,
    };
    const langRule = changeLang(redactionLang);

    resourceBundle.map((resource: any) => {
        if (getManageDuplicatesByKey(resource.resourceKey)) {
            label[getManageDuplicatesByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

    (modifiedLabels && modifiedLabels.length) ? modifiedLabels.map((resource: any) => {
        if (getManageDuplicatesByKey(resource.resourceKey)) {
            label[getManageDuplicatesByKey(resource.resourceKey)] = resource;
        }

        return resource;
    }) : '';

    switch (key) {
        case COMPARE:
            return label.compareLabel[langRule];
        case SELECT_ONE:
            return label.selectAtleastOneLabel[langRule];
        case 'Subject':
            return label.subjectLabel[langRule];
        case 'To':
            return label.toLabel[langRule];
        case 'From':
            return label.fromLabel[langRule];
        case 'Date':
            return label.dateLabel[langRule];
        case 'Classification':
            return label.classificationLabel[langRule];
        case 'Language':
            return label.languageLabel[langRule];
        case 'To Organization':
            return label.toOrganizationLabel[langRule];
        case 'From Organization':
            return label.fromOrganizationLabel[langRule];
        case 'Document Type':
            return label.documentTypeLabel[langRule];
        case 'Reference Number':
            return label.referenceNumberLabel[langRule];
        case 'Filename':
            return label.filenameLabel[langRule];
        case 'Field 1':
            return label.field1Label[langRule];
        case CANCEL:
            return label.cancelLabel[langRule];
        case SEARCH:
            return label.searchLabel[langRule];
        case ASSIGNMENT:
            return label.assignmentLabel[langRule];
        case REFERENCES:
            return label.referencesLabel[langRule];
        case PAGE_NAVIGATION:
            return label.pageNavigationLabel[langRule];
        case DOCUMENT_SET_NAVIGATION:
            return label.documentSetNavigationLabel[langRule];
        case 'General Attributes':
            return label.generalAttributesLabel[langRule];
        case 'Document Name':
            return label.documentNameLabel[langRule];
        case 'Disclosure':
            return label.disclosureLabel[langRule];
        case 'Start':
            return label.startLabel[langRule];
        case 'Index Attributes':
            return label.indexAttributesLabel[langRule];
        case 'To Org.':
            return label.toOrgLabel[langRule];
        case 'From Org.':
            return label.fromOrgLabel[langRule];
        case 'Document Attributes':
            return label.documentAttributesLabel[langRule];
        case 'Author':
            return label.authorLabel[langRule];
        case 'Pages':
            return label.pagesLabel[langRule];
        case 'File Size':
            return label.fileSizeLabel[langRule];
        case 'Duplicates':
            return label.duplicatesTitleLabel[langRule];
        case 'Reset':
            return label.resetLabel[langRule];
        case 'Show All':
            return label.showAllLabel[langRule];
        case 'Hide All':
            return label.hideAllLabel[langRule];
        case BACK_HOME:
            return label.backHomeLabel[langRule];
        case FULL_WIDTH_NAME:
            return label.actualPageSizeLabel[langRule];
        case PAGE_WIDTH_NAME:
            return label.pageWidthLabel[langRule];
        case FULL_PAGE_NAME:
            return label.fullPageLabel[langRule];
        case ASSIGNED_DUPLICATES_TITLE_MODAL:
            return label.assignedDuplicatesLabel[langRule];
        case MASTER_DOCUMENT_LABEL:
            return label.assignedDuplicatesMasterDocument[langRule];
        case 'Assigned Duplicates':
            return label.assignedDuplicatesDuplicateFieldLabel[langRule];
        case PAGE_AMOUNT_LABEL:
            return label.assignedDuplicatesPageAmountLabel[langRule];
        case 'Assigned Disclosure':
            return label.assignedDuplicatesDisclosure[langRule];
        case 'Assigned Cancel':
            return label.assignedDuplicatesCancelLabel[langRule];
        case ASSIGN:
            return label.assignedDuplicatesAssignLabel[langRule];
        case ATLEAST_ONE_REQUIRED:
            return label.atleastOnerequiredLabel[langRule];
        case ALERT_MESSAGE:
            return label.duplicatesAlertMessage[langRule];
        case 'References Modal':
            return label.referenceModalTitlelabel[langRule];
        case 'References Modal Master Document':
            return label.referenceModalMasterDocument[langRule];
        case 'References Duplicates':
            return label.referenceModalDuplicates[langRule];
        case 'Page #':
            return label.referenceModalPage[langRule];
        case 'Reference Modal Disclosure':
            return label.referenceModalDisclosure[langRule];
        case 'Reference Modal Document':
            return label.referenceModalDocument[langRule];
        case 'Document Disclosure':
            return label.referenceModalDocumentDisclosure[langRule];
        case 'Page Disclosure':
            return label.referenceModalPageDisclosure[langRule];
        case 'Reference Modal Close':
            return label.referenceModalClose[langRule];
        case 'Reference Modal DeleteAll':
            return label.referenceModalDeleteAll[langRule];
        default:
            return '';
    }
}

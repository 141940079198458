import * as React from 'react';
import { isEqual, sortBy } from 'lodash';
import { Spinner } from '../../../../components/spinner/Spinner';
import DisclosureSettingsForm from './DisclosureSettingsForm';
import { IProps, IState } from './disclosureSettingsTab.model';
import {
    IRequestTypes,
} from '../../../../redux/reducers/modalGlobalSettings/globalSettings.model';
import './disclosureSettings.style.scss';
import { initialLabel } from '../../../../constants/localization.constants';
import resourceBundle from '../../../../containers/localization/localizationData';
import { changeLang } from '../../../../redux/actions/localization';

const getDisclosureLabelsByKey = (key: string): string => {
    switch (key) {
        case 'GLOBALSETTINGS_FOLDER_TYPE':
            return 'folderTypeLabel';
        case 'GLOBALSETTINGS_DEFAULT_DISCLOSURE':
            return 'defaultdisclosureLabel';
        case 'GLOBALSETTINGS_SEVER_APPLIED':
             return 'severAppliedLabel'
        case 'GLOBALSETTINGS_HIGHLIGHT_APPLIED':
             return 'highlightLabel'     
        case 'GLOBALSETTINGS_EXEMPTION_APPLIED':
             return 'exemptionLabel'
        case 'GLOBALSETTINGS_USERSTAMPAPPLIED_APPLIED':
             return 'userStampAppliedLabel'
        default:  return '';
    }
}

const labels = {
    folderTypeLabel: initialLabel,
    defaultdisclosureLabel: initialLabel,
    severAppliedLabel: initialLabel,
    highlightLabel: initialLabel,
    exemptionLabel: initialLabel,
    userStampAppliedLabel:initialLabel,
};

export const formFields = [
    {
        field: 'folderType',
        label: 'Folder Type',
        type: null,
    }, {
        field: 'defaultDisclosureTypeId',
        label: 'Default Disclosure',
        type: 'select',
    }, {
        field: 'severDisclosureTypeId',
        label: 'Sever Applied',
        type: 'select',
    }, {
        field: 'highlightDisclosureTypeId',
        label: 'Highlight Applied',
        type: 'select',
    }, {
        field: 'exemptionDisclosureTypeId',
        label: 'Exemption Applied',
        type: 'select',
    }, {
        field: 'stampDisclosureTypeId',
        label: 'User Stamp Applied',
        type: 'select',
    },
];

export class DisclosureSettingsTab extends React.Component<IProps, IState> {

    public static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
        const settings = nextProps.disclosureSettings.requestTypesWithDisclosureTypes;

        if(settings && settings.length !== prevState.settings.length) {
            return ({
                settings,
            });
        }

        return null;
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            settings: this.props.disclosureSettings &&
                this.props.disclosureSettings.requestTypesWithDisclosureTypes ||
                [],
        };
    }

    public componentDidUpdate(prevProps: IProps): void {
        if (this.props.disclosureSettings.requestTypesWithDisclosureTypes &&
            prevProps.disclosureSettings.requestTypesWithDisclosureTypes &&
            this.props.disclosureSettings.requestTypesWithDisclosureTypes[0].disclosureTypes.length
            !== prevProps.disclosureSettings.requestTypesWithDisclosureTypes[0].disclosureTypes.length) {
            this.setState({settings: this.props.disclosureSettings.requestTypesWithDisclosureTypes});
        }
    }

    public render(): JSX.Element {
        const { settings } = this.state;
        const sortedSettings = sortBy(settings, 'requestTypeDescription');
        const { redactionLang,modifiedLabels } = this.props;
        const langRule = changeLang(redactionLang);
        resourceBundle.map((resource: any) => {
            if (getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getDisclosureLabelsByKey(resource.resourceKey)) {
                labels[getDisclosureLabelsByKey(resource.resourceKey)] = resource;
            }
    
            return resource;
        });
        const formFields1 = [
            {
                field: 'folderType',
                label: labels.folderTypeLabel[langRule],
                type: null,
            }, {
                field: 'defaultDisclosureTypeId',
                label: labels.defaultdisclosureLabel[langRule],
                type: 'select',
            }, {
                field: 'severDisclosureTypeId',
                label: labels.severAppliedLabel[langRule],
                type: 'select',
            }, {
                field: 'highlightDisclosureTypeId',
                label: labels.highlightLabel[langRule],
                type: 'select',
            }, {
                field: 'exemptionDisclosureTypeId',
                label: labels.exemptionLabel[langRule],
                type: 'select',
            }, {
                field: 'stampDisclosureTypeId',
                label: labels.userStampAppliedLabel[langRule],
                type: 'select',
            },
        ];
        return (
            <div className='disclosure-settings-container_tab'>
                {
                    this.props.loadingSettings ?
                        <Spinner active={true}/> :
                        <DisclosureSettingsForm
                            formFields={formFields1}
                            disclosureSettings={sortedSettings}
                            hasEditPermission={this.props.editPermission}
                            handleSelectChange={
                                (type: string, requestType: string, value: string): void =>
                                    this.handleSelectChange(type, requestType, value)}
                        />
                }
            </div>
        );
    }

    private updateDisclosureSettings = (
        settings: IRequestTypes[],
        type: string,
        requestType: string,
        value: string,
    ): IRequestTypes[] =>
        settings.map((disclosure: IRequestTypes): IRequestTypes =>
            disclosure.requestType === requestType ? {
                ...disclosure,
                [type]: Number(value),
            } : disclosure)

    private handleSelectChange(type: string, requestType: string, value: string): void {
        const settings = this.updateDisclosureSettings(this.state.settings, type, requestType, value);

        this.setState((prevState: IState): IState => ({
            ...prevState,
            settings,
        }));

        this.props.form.batch(() => {
            this.props.form.change('disclosureSettings',  {requestTypesWithDisclosureTypes: settings});
        });
    }

}

if (!String.prototype.trim) {
    String.prototype.trim = function(): string {
        return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    };
}

if (!String.prototype.trimRight) {
    const trimBeginRegexp = /^\s\s*/;

    String.prototype.trimRight = function(): string {
        return String(this).replace(trimBeginRegexp, '');
    };
}

if (!String.prototype.trimLeft) {
    const trimEndRegexp = /\s\s*$/;

    String.prototype.trimLeft = function(): string {
        return String(this).replace(trimEndRegexp, '');
    };
}

import { get } from 'lodash';
import {
    SET_SEARCH_PARAM,
    SEARCH_VALUE_SUCCESS,
    SEARCH_VALUE_PENDING,
    SEARCH_VALUE_FAILURE, SET_SEARCH_CURRENT_PAGE_ID, CLEAR_SEARCH_ACTION,
    SET_CURRENT_SEARCH_ELEMENT,
    APPLY_SEARCH_PENDING,
    MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE,
    SET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING,
    SET_GLOBAL_SEARCH_COLUMN_HEADER_LIST_SUCCESS,
    SET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE,
    SET_GLOBAL_SEARCH_TABLE_COLUMNS,
    MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_PENDING,
    MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS,
    RESET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING,
    RESET_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS,
    RESET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE,
    RESTRICT_ON_FETCH_DATA_FOR_PAGE_LIST,
    SET_ELEMENT_SEARCH_DIRECTION,
    SET_PREVIOUS_SEARCH_ELEMENT,
} from './constant';
import {
    DOCS_UNDER_CURRENT_STACK_PARAM,
    SEVER_PARAM,
    SEVER_ALL_PARAM,
    HIGHLIGHT_ALL_PARAM,
    HIGHLIGHT_PARAM, SEARCH_QUERY,
} from '../../../constants/globalSearch.constants';
import { IReduxStateSearch } from 'containers/globalSearch/globalSearch.model';
import { AnyAction } from 'redux';
import { ID, PAGE, PAGES } from '../../../constants/common.constants';

const initialSearchParams = {
    [DOCS_UNDER_CURRENT_STACK_PARAM]: false,
    [SEVER_PARAM]: null,
    [SEVER_ALL_PARAM]: null,
    [HIGHLIGHT_PARAM]: null,
    [HIGHLIGHT_ALL_PARAM]: null,
    [SEARCH_QUERY]: '',
};

const initialState = {
    searchParams: initialSearchParams,
    searchPending: false,
    error: null,
    searchData: [],
    currentSearchElement: null,
    currentPageId: null,
    applySearchPending: false,
    globalSearchColumnHeaderPending: false,
    globalSearchColumnHeader: [],
    modifyGlobalSearchTableColumnLoading: false,
    resetGlobalSearchColumnHeaderPending: false,
    restrictFlagForPageList: false,
    elementSearchDirection: null,
    previousSearchElement: null,
};

const globalSearchReducer = (state: IReduxStateSearch = initialState, { type, payload }: AnyAction):
    IReduxStateSearch => {
    switch (type) {
        case SEARCH_VALUE_PENDING:
            return {
                ...state,
                searchPending: true,
                error: null,
            };
        case SEARCH_VALUE_SUCCESS:
            const firstSelectedPage = get(payload[0], [PAGES, '0', PAGE, ID], null);

            return {
                ...state,
                searchData: payload,
                currentPageId: firstSelectedPage,
                searchPending: false,
                error: null,
            };
        case SEARCH_VALUE_FAILURE:
            return {
                ...state,
                searchPending: false,
                error: payload,
            };
        case SET_SEARCH_CURRENT_PAGE_ID:
            return {
                ...state,
                currentPageId: payload,
            };
        case SET_SEARCH_PARAM:
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    ...payload,
                },
            };
        case SET_CURRENT_SEARCH_ELEMENT:
            return {
                ...state,
                currentSearchElement: payload,
            };
        case CLEAR_SEARCH_ACTION:
            return {
                ...state,
                searchData: [],
                currentPageId: null,
            };
        case APPLY_SEARCH_PENDING:
            return {
                ...state,
                applySearchPending: payload,
            };
        case SET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING:
            return {
                ...state,
                globalSearchColumnHeaderPending: true,
            };
        case SET_GLOBAL_SEARCH_COLUMN_HEADER_LIST_SUCCESS:
            return {
                ...state,
                globalSearchColumnHeader: payload,
                globalSearchColumnHeaderPending: false,
            };
        case SET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                globalSearchColumnHeader: [],
                globalSearchColumnHeaderPending: false,
                error: payload,
            };
        case MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_PENDING:
            return {
                ...state,
                modifyGlobalSearchTableColumnLoading: true,
            };
        case SET_GLOBAL_SEARCH_TABLE_COLUMNS:
            return {
                ...state,
                globalSearchColumnHeader: payload,
            };
        case MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS:
            return {
                ...state,
                globalSearchColumnHeader: payload,
                resetGlobalSearchColumnHeaderPending: false,
            };
        case MODIFY_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE:
            case RESET_GLOBAL_SEARCH_COLUMN_HEADER_PENDING:
            return {
                ...state,
                resetGlobalSearchColumnHeaderPending: true,
            };
        case RESET_GLOBAL_SEARCH_COLUMN_HEADER_SUCCESS:
            return {
                ...state,
                globalSearchColumnHeader: payload,
                resetGlobalSearchColumnHeaderPending: false,
            };
        case RESET_GLOBAL_SEARCH_COLUMN_HEADER_FAILURE:
            return {
                ...state,
                resetGlobalSearchColumnHeaderPending: false,
                error: payload,
            };
        case RESTRICT_ON_FETCH_DATA_FOR_PAGE_LIST:
            return {
                ...state,
                restrictFlagForPageList: payload,
            };
        case SET_ELEMENT_SEARCH_DIRECTION:
            return {
                ...state,
                elementSearchDirection: payload,
            };
        case SET_PREVIOUS_SEARCH_ELEMENT:
            return {
                ...state,
                previousSearchElement: payload,
            };
        default:
            return state;
    }
};

export default globalSearchReducer;

import * as React from 'react';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Restore from '@mui/icons-material/Restore';
import Description from '@mui/icons-material/Description';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import { CheckboxMaterialBuilder } from '../../../../components/materialUiForms/materialUiFormBuilder';
import { convertStringRangeToArray, rangeFormat } from '../../../../utils/array.utils';
import { NO_PAGES, ERROR_FORMAT, FILE_LENGTH_EXCEED } from '../../../../constants/export.contstants';
import { outOfRangeError } from '../../../../utils/validate.util';
import { IProps, IState } from './documentField.model';
import './documentField.styles.scss';
import { IExportPages } from '../../../../redux/reducers/modalWindowExport/modalWindowExport.model';
import { EDIT_FILE_NAME_MODAL } from '../../../../constants/messages.constants';
import { isEqual } from 'lodash';

const NUMBER_RANGE = /^(\d+[,-]{1})*\d+$/;
const MAX_FILENAME_LENGTH = 251;

const classNames = {
    documentField: 'document-field-container',
    iconButton: 'icon-button',
    icon: 'icon',
    iconDescription: 'icon icon_description',
    iconRotate: 'icon icon_rotate',
    textField: 'text-field',
    textFieldError: 'text-field text-field_error',
};

class DocumentField extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            pageNumberRange: rangeFormat(this.props.pages),
            originalName: this.props.document.originalFileName,
            isMultiline: false,
            errorMessage: '',
            fileNameExceed: '',
        };
    }

    public componentDidMount(): void {
        this.validateFilename(this.props.document.originalFileName);
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState): void {
        if (!isEqual(prevProps.pages, this.props.pages)) {
            this.setState({ pageNumberRange: rangeFormat(this.props.pages) },
            () => {this.validation(this.props.pages);});
        }

        if (prevProps.document.originalFileName !== this.props.document.originalFileName) {
            this.setState({ originalName: this.props.document.originalFileName });
            this.validateFilename(this.props.document.originalFileName);
        }
    }

    public validateFilename = (filename: string): void => {
        if(filename.length > MAX_FILENAME_LENGTH) {
            this.setState({ fileNameExceed: this.props.parentLabel.fileLengthExceedLabel[this.props.langRule]});
            this.props.onChangeValidationStatus(false);
        } else {
            this.props.onChangeValidationStatus(true && !this.state.errorMessage);
            this.setState({ fileNameExceed: ''});
        }
    }

    public validation = (numberRange?: number[]): void => {
        let errorMessage;
        const {parentLabel, langRule} =  this.props;
        const availableRange = this.props.document.pages.map((page: IExportPages): number => page.pageNumber);

        if (!this.state.pageNumberRange) {
            errorMessage = parentLabel.noPagesToExportErrorLabel[langRule];
        } else if (numberRange && this.isInNotRange(numberRange, availableRange)) {
            const { name } = this.props.document;
            const maxRange = Math.max.apply(null, availableRange);
            const minRange = Math.min.apply(null, availableRange);

            errorMessage = `${parentLabel.outOfRangeErrorOneLabel[langRule]} ${name}.
                ${parentLabel.outOfRangeErrorTwoLabel[langRule]} [${minRange}-${maxRange}]
                ${parentLabel.outOfRangeErrorThreeLabel[langRule]}`;
        } else if (this.state.pageNumberRange && !(NUMBER_RANGE.test(this.state.pageNumberRange.replace(/ /g, '')))) {
            errorMessage = parentLabel.errorFormatLabel[langRule];
        } else {
            errorMessage = '';
        }

        this.setState({
            errorMessage,
        }, () => {
            this.props.onChangeValidationStatus(!errorMessage &&
                this.state.originalName.length <= MAX_FILENAME_LENGTH);

            if (!errorMessage) {
                this.props.inputChangeHandler(this.props.document.id, numberRange);
            }
        });
    }

    public isInNotRange = (value: number[], availableRange: number[]): boolean =>
        value.some((num: number): boolean => !availableRange.includes(num))

    public openEditFilenameModal = (): void => {
        this.props.openModalWindow(
            EDIT_FILE_NAME_MODAL,
            {
                id: EDIT_FILE_NAME_MODAL,
                title: this.props.parentLabel.editFilenameModalTitle[this.props.langRule],
                modalSpecificProps: {
                    originalFileName: this.state.originalName,
                    id: this.props.document.id,
                },
            },
        );
    }
    public stopPropagation = (e: React.MouseEvent<Element>): void => e.stopPropagation();

    public render(): JSX.Element {
        const { document, parentLabel, langRule } = this.props;
        const { isMultiline, errorMessage } = this.state;
        const documentFieldClass = classnames(classNames.documentField, {
            error: errorMessage && this.props.document.isChecked,
        });

        return (
            <Accordion className={documentFieldClass} defaultExpanded={true}>
                <AccordionSummary className='document-field panel-summary' expandIcon={<ExpandMoreIcon />} >
                    <div onClick={this.stopPropagation} className='document-field_wrapper'>
                        <CheckboxMaterialBuilder
                            checked={document.isChecked}
                            handleCheckboxChange={
                                (param: string, checked: boolean): void => this.onChange(param, checked)}
                            label={document.name}
                            paramName={`${document.id}`}
                        />
                    </div>
                    <div onClick={this.stopPropagation} className='filename-field_wrapper'>
                        <TextField
                            minRows='6'
                            name={'originalName'}
                            label={parentLabel.fileNameLabel[langRule]}
                            variant='outlined'
                            className={classNames.textField}
                            onClick={this.openEditFilenameModal}
                            value={this.state.originalName}
                            fullWidth={true}
                            disabled={false}
                            autoComplete={'off'}
                            error={!!this.state.fileNameExceed}
                        />
                    </div>
                </AccordionSummary>
                <AccordionDetails className='document-field__detail'>
                    <div className='row row-top'>
                        <div className='row-top__input'>
                            <Description className={classNames.iconDescription} />
                            <div className={isMultiline ? 'page-top-padding' : ''}>
                                <TextField
                                    multiline={isMultiline}
                                    minRows='4'
                                    label={parentLabel.pagesLabel[langRule]}
                                    name={'pageNumberRange'}
                                    variant='outlined'
                                    className={classNames.textField}
                                    value={this.state.pageNumberRange}
                                    onChange={(e: React.ChangeEvent<Element>): void =>
                                        this.onInputChange((e.target as HTMLInputElement))}
                                    onBlur={this.inputHandler}
                                    fullWidth={true}
                                    error={!!this.state.errorMessage}
                                />
                            </div>
                        </div>
                        <div className='row-top__actions'>
                            <Button className={classNames.iconButton} onClick={(): void => this.toggleUnfold()}>
                                {
                                    this.state.isMultiline ? (
                                        <UnfoldLess className={classNames.icon} color='disabled' />
                                    ) : (
                                            <UnfoldMore className={classNames.icon} color='disabled' />
                                        )
                                }
                            </Button>
                            <Button className={classNames.iconButton} onClick={(): void => this.restorePagesHandler()}>
                                <Restore className={classNames.icon} color='disabled' />
                            </Button>
                        </div>
                    </div>
                    <div className='row error_block'>
                        {
                            this.state.errorMessage && (
                                <Typography
                                    variant='caption'
                                    color='secondary'
                                >
                                    {this.state.errorMessage}
                                </Typography>
                            )
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }

    private onChange = (param: string, checked: boolean): void => {
        this.props.onChangeCheckbox(checked);
    }

    private onInputChange = (input: HTMLInputElement): void => {
        this.setState({
            [input.name]: input.value,
        });
    }

    private restorePagesHandler = (): void => {
        this.setState({
            ...this.state,
            pageNumberRange: rangeFormat(this.props.initialPages),
            errorMessage: '',
        });
        this.props.onChangeValidationStatus(this.state.originalName.length <= MAX_FILENAME_LENGTH && true);
        this.props.inputChangeHandler(this.props.document.id, this.props.initialPages);
    }

    private inputHandler = (): void => {
        const stringToArrayOfPageNumbers = convertStringRangeToArray(this.state.pageNumberRange);

        this.validation(stringToArrayOfPageNumbers);
    }

    private toggleUnfold = (): void => {
        this.setState({
            isMultiline: !this.state.isMultiline,
        });
    }
}

export default DocumentField;

import * as React from 'react';
import { useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'rc-scrollbars';
import { IRequestsFoldersListParams } from './ModalRequestSecurity.model';
import { IRequestFolder } from '../../../redux/reducers/amandaContent/amandaContent.model';
import { RequestSubfolder } from './RequestsSubFolder';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import { PRIMARY } from '../../../constants/theme.constants';
import './modalRequestSecurity.style.scss';
import { initialLabel } from '../../../constants/localization.constants';
import { changeLang} from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';
import { useSelector } from 'react-redux';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';

const getRequestLabelsByKey = (key: string): string => {
    switch (key) {
        case 'REQUESTSECURITY_LABEL_REQUEST':
            return 'requestLabel';     
        default:  return '';
    }
}

export const RequestsFoldersList = (
    {
        folders,
        fetchAvailableSecurities,
        fetchRequestSecurityGroupByFolder,
        availableSecurities,
        fetchRequestSecurities,
        setSelectedAvailableSecurities,
    }: IRequestsFoldersListParams,
    
): JSX.Element => {
    const [selectedFolderType, setSelectedFolderType] = useState('');
    const [selectedCustomFolderRsn, setSelectedCustomFolderRsn] = useState(null);
    const [showFoldersByYear, setShowFoldersByYear] = useState([]);
    

    const handleChangeSelectedRequest = (folderType: string): void => {
        setSelectedCustomFolderRsn(null);
        setSelectedFolderType(folderType);
        fetchRequestSecurities({ folderType });

        if (!availableSecurities) {
            fetchAvailableSecurities({ folderType });
        }

        setSelectedAvailableSecurities({
            ...availableSecurities,
            selectedRowLevelSecurityDtos: [],
            selectedValidUserDtos: [],
        });
    };

    const labels = {
       requestLabel: initialLabel,
    };
    const redactionLanguage =useSelector(getRedactionLanguage);
    const modifiedLabels = useSelector(getModifiedLabels);
    const langRule = changeLang(redactionLanguage);
    resourceBundle.map((resource: any) => {
        if (getRequestLabelsByKey(resource.resourceKey)) {
            labels[getRequestLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getRequestLabelsByKey(resource.resourceKey)) {
            labels[getRequestLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    const toggleShowFoldersByYear = (e: React.SyntheticEvent, folderType: string): void => {
        e.persist();
        setShowFoldersByYear(showFoldersByYear.includes(folderType) ? showFoldersByYear.filter(
            (toShowFolderType: string): boolean =>
                toShowFolderType !== folderType,
            ) : [...showFoldersByYear, folderType],
        );
    };

    return (
        <React.Fragment>
            <Typography className='heading' variant='h6'>
            {labels.requestLabel[langRule]}
            </Typography>
            <div className='modal_request_security_folders'>
            <AutoSizer>
                {({ width, height }: { width: number; height: number; }): JSX.Element  => (
                    <div style={{ width, height }}>
                        <Scrollbars>
                                {
                                    folders.map((folder: IRequestFolder): JSX.Element => (
                                            <ul
                                                key={folder.validFolder.folderType}
                                                className='modal_request_security_folders_row'
                                            >
                                                <li>
                                                    <Radio
                                                        color={PRIMARY}
                                                        onChange={(): void =>
                                                            handleChangeSelectedRequest(folder.validFolder.folderType)
                                                        }
                                                        checked={selectedFolderType === folder.validFolder.folderType}
                                                        value={`${folder.validFolder.folderType}`}
                                                    />
                                                    {folder.foldersByYear.length ? <KeyboardArrowDown
                                                        onClick={(e: React.SyntheticEvent): void =>
                                                            toggleShowFoldersByYear(e, folder.validFolder.folderType)}
                                                    /> : null}

                                                    {(redactionLanguage==='fr' && folder.validFolder.folderDesc2)?
                                                    folder.validFolder.folderDesc2:folder.validFolder.folderDesc}
                                                    {showFoldersByYear.includes(folder.validFolder.folderType) && (
                                                        <RequestSubfolder
                                                            subFolders={folder.foldersByYear}
                                                            fetchRequestSecurityGroupByFolder={
                                                                fetchRequestSecurityGroupByFolder
                                                            }
                                                            availableSecurities={availableSecurities}
                                                            fetchAvailableSecurities={fetchAvailableSecurities}
                                                            selectedFolderType={selectedFolderType}
                                                            setSelectedFolderType={setSelectedFolderType}
                                                            selectedCustomFolderRsn={selectedCustomFolderRsn}
                                                            setSelectedCustomFolderRsn={setSelectedCustomFolderRsn}
                                                        /> )}
                                                </li>
                                            </ul>
                                    ))
                                }
                        </Scrollbars>
                    </div>
                )}
            </AutoSizer>
            </div>
        </React.Fragment>
    );
};

import * as React from 'react';
import Button from '@mui/material/Button';
import { SelectMaterialBuilder } from '../../../../components/materialUiForms/materialUiFormBuilder';
import { IReplaceIndex } from './replaceIndex.model';

export const ReplaceIndex = ({ options, currentValue, handleSelect, handleClose, handleSave, labels, langRule }
    : IReplaceIndex): JSX.Element => (
    <div className='modal-index-keyword'>
        <div className='modal-index-keyword_replace'>
            <div className='modal-index-keyword_replace_title'>
                <div>{labels.chooseReplaceLabel[langRule]}</div>
            </div>
            <SelectMaterialBuilder
                value={currentValue}
                label={labels.replaceLabel[langRule]}
                paramName={'name'}
                options={options}
                variant='standard'
                handleSelectChange={(e: string, value: string): void => handleSelect(value)}
            />
        </div>
        <div className='modal-index-keyword_bottom'>
            <Button
                variant='contained'
                size='small'
                onClick={handleClose}
            >
                {labels.backLabel[langRule]}
            </Button>
            <Button
                variant='contained'
                size='small'
                color='primary'
                disabled={currentValue === 'null' || !currentValue}
                onClick={handleSave}
            >
                {labels.saveLabel[langRule]}
            </Button>
        </div>
    </div>
);

import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    ITableState,
    PageData
} from '../reducers/common/common.model';

export const getCommonSortData = (state: IState): ITableState => {
    return state.tableReducer;
};
export const getSortParams = createSelector(
    [getCommonSortData],
    (tableState: ITableState): PageData[] => {
        return tableState.sortParams
    }
);
import * as React from 'react';
import { flattenDeep, uniq } from 'lodash';
import { connect } from 'react-redux';
import { DOCUMENT_STACK_MODAL, NAME } from '../../../constants/stack.contstants';
import { CANCEL, CLOSE, ID, OK, SAVE } from '../../../constants/common.constants';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { IDispatchProps, IProps, IStackCheckbox, IStateProps, IState } from './modalDocumentStack.model';
import './modalDocumentStack.style.scss';
import { IState as StoreState } from '../../../redux/store';
import { handleCloseModalWindow } from '../../../redux/actions/modal';
import { IStackDocumentsDto, IStackOptions } from '../../../redux/reducers/stackList/stackList.model';
import { getStackList, getUpdateStackDocumentPending } from '../../../redux/selectors/stackList';
import { getRedactionDocumentId } from '../../../redux/selectors/initialization';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getSelectedDocuments } from '../../../redux/selectors/pageList';
import { getDocumentList, getStackId } from '../../../redux/selectors/documentList';
import { IFile } from '../../../redux/reducers/documentList/documentList.model';
import { updateStackDocumentRelation } from '../../../redux/actions/stackList';
import { LinearProgress } from '@mui/material';
import { Scrollbars } from 'rc-scrollbars';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { initialLabel } from '../../../constants/localization.constants';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../localization/localizationData';

const classNames = {
    stackDocumentModal: 'stack-document-modal',
    stackModalFooter: 'stack-document-modal-footer',
    stackModalBody: 'stack-document-modal-body',
    stackLoader: 'stack-document-modal-loader',
};

export const getStackLabelsByKey = (key: string): string => {
    switch(key) {
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'COMMON_LABEL_OK':
            return 'okLabel';
        case 'COMMON_LABEL_CLOSE':
            return 'closeLabel';
        case 'COMMON_LABEL_SAVE':
            return 'saveLabel';
        case 'MODAL_STACK_TITLE':
            return 'stackTitleLabel';
        case 'MODAL_STACK_SELECT_DOCUMENT_WARNING':
            return 'selectDocWarningLabel';
        case 'MODAL_STACK_PROPERTIES_TITLE':
            return 'stackPropertiesTitle';
        case 'COMMON_LABEL_ADD_NEW':
            return 'addNewLabel';
        case 'COMMON_LABEL_NAME':
            return 'nameLabel';
        case 'COMMON_LABEL_DESCRIPTION':
            return 'descriptionLabel';
        case 'MODAL_STACK_MANAGEMENT_STACK_PROPERTY':
            return 'stackPropertyLabel';
        case 'MODAL_STACK_MANAGEMENT_SELECT_STACK':
            return 'selectStackLabel';
        case 'COMMON_LABEL_MUST_BE_UNIQUE':
            return 'mustBeUniqueLabel';
        case 'COMMON_LABEL_AT_LEAST_ONE_OF_LIST':
            return 'atLeastOneOfListLabel';
        case 'COMMON_LABEL_FIELD_IS_MANDATORY':
            return 'mandatoryFieldLabel';
        case 'COMMON_LABEL_MAX_LENGTH':
            return 'maxLengthLabel';
        case 'COMMON_LABEL_MIN_LENGTH':
            return 'minLengthLabel';
        case 'MODAL_STACK_PROPERTIES_SELECT_PROPERTY':
            return 'selectPropertyLabel';
        case 'COMMON_LABEL_WARNING':
            return 'warningLabel';
        case 'COMMON_LABEL_CHANGES_NOT_SAVED':
            return 'changesNotSavedLabel';
        case 'MODAL_STACK_MANAGEMENT_DELETE_STACK':
            return 'deleteStackLabel';
        case 'MODAL_STACK_PROPERTIES_DELETE_PROPERTY':
            return 'deleteStackPropertyLabel';
        case 'MODAL_RELEASE_MANAGEMENT_TITLE':
            return 'releaseManagementTitleLabel';
        default: return '';
    }
};

export class ModalStackManagementComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            stackCheckboxList: [],
        };
    }

    public componentDidMount(): void {
        const {
            stackList,
            selectedDocuments,
            documentList,
        } = this.props;
        const allCurrentStacksIds = documentList
            .filter((file: IFile) => selectedDocuments.includes(file.id) && file.stacks)
            .map((file: IFile) => file.stacks.map((stack: IStackOptions) => stack[ID]));
        const uniqCurrentStacksIds = uniq(flattenDeep(allCurrentStacksIds));
        const stackCheckboxList = stackList.map((stack: IStackOptions) => ({
            ...stack,
            active: uniqCurrentStacksIds.includes(stack[ID]),
        }));

        this.setState({ stackCheckboxList });
    }

    public render(): JSX.Element {
        const { stackCheckboxList } = this.state;
        const { loading, modifiedLabels, redactionLang } = this.props;
        const labels = {
            cancelLabel: initialLabel,
            okLabel: initialLabel,
            closeLabel: initialLabel,
            saveLabel: initialLabel,
        };
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if(getStackLabelsByKey(resource.resourceKey)) {
                labels[getStackLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        return (
            <div className={classNames.stackDocumentModal}>
                <Scrollbars>
                    <div className={classNames.stackModalBody}>
                        {
                            stackCheckboxList.map((stack: IStackCheckbox): JSX.Element => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            checked={stack.active}
                                            onChange={(): void => this.handleCheckStack(stack[ID])}
                                        />}
                                    label={stack[NAME]}
                                    key={stack[ID]}
                                />
                            ))
                        }
                    </div>
                </Scrollbars>
                <div className={classNames.stackModalFooter}>
                    <Tooltip title={labels.closeLabel[langRule]} placement='top'>
                        <Button
                            variant='outlined'
                            onClick={(): void => this.props.handleCloseModalWindow(DOCUMENT_STACK_MODAL)}
                            className='modal-window__buttons outlined'
                            size='small'
                        >
                            {labels.cancelLabel[langRule]}
                        </Button>
                    </Tooltip>
                    <Tooltip title={labels.saveLabel[langRule]} placement='top'>
                                <span>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        onClick={this.handleSave}
                                        className='primary'
                                    >
                                        {labels.okLabel[langRule]}
                                    </Button>
                                </span>
                    </Tooltip>
                </div>
                <div className={classNames.stackLoader}>
                    {loading && <LinearProgress />}
                </div>
            </div>
        );
    }

    private handleSave = (): void => {
        const { redactionDocumentId, selectedDocuments } = this.props;
        const { stackCheckboxList } = this.state;
        const stackIds = stackCheckboxList
            .filter((stack: IStackCheckbox) => stack.active)
            .map((stack: IStackCheckbox) => stack[ID]);
        const stackDocumentDto = {
            documentIds: selectedDocuments,
            stackIds,
        };

        this.props.updateStackDocumentRelation(redactionDocumentId, stackDocumentDto);
    }

    private handleCheckStack = (id: number): void => {
        const { stackCheckboxList } = this.state;
        const newStackCheckboxList = stackCheckboxList.map((stack: IStackCheckbox) => {
            if (stack[ID] === id) {
                stack.active = !stack.active;
            }

            return stack;
        });

        this.setState({ stackCheckboxList: newStackCheckboxList });
    }
}

const mapStateToProps = (state: StoreState): IStateProps => ({
    stackId: getStackId(state),
    stackList: getStackList(state),
    loading: getUpdateStackDocumentPending(state),
    redactionDocumentId: getRedactionDocumentId(state),
    selectedDocuments: getSelectedDocuments(state),
    documentList: getDocumentList(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, IDispatchProps, AnyAction>): IDispatchProps => ({
    updateStackDocumentRelation: (redactionDocumentId: number, stackDto: IStackDocumentsDto): void => {
        dispatch(updateStackDocumentRelation(redactionDocumentId, stackDto));
    },
    handleCloseModalWindow: (id: string): void => {
        dispatch(handleCloseModalWindow(id));
    },
});

export const ModalDocumentStack = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalStackManagementComponent);

import * as React from 'react';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { IState } from '../store';
import {
    IReduxStateSearch,
    ISearchParams,
    ICurrentSearchElement,
    ISearchResponseData, ISearchTableData, IMetadataSearchData,
} from '../../containers/globalSearch/globalSearch.model';
import { getDisclosureTypesAccordance } from './disclosureTypes';
import { IDisclosureTypeState } from '../reducers/disclosureTypes/disclosureType.model';
import { layoutReductorTypes, transformExemptionIdsToArticles } from './pageList';
import { IArticleStamp } from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { IPages } from '../reducers/pageList/pageList.model';
import { END_POINT, START_POINT } from '../../constants/annotationTypes.constants';
import { reductionState } from './redactor';
import { IRedactorState } from '../reducers/redactor/redactor.model';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';

export const getSearchState = (state: IState): IReduxStateSearch => state.globalSearch;

export const getSearchParams = createSelector(
    [getSearchState],
    (searchState: IReduxStateSearch): ISearchParams => searchState.searchParams,
);

export const getCurrentPageId = createSelector(
    [getSearchState],
    (searchState: IReduxStateSearch): number => searchState.currentPageId,
);

export const getSearchPending = createSelector(
    [getSearchState],
    (searchState: IReduxStateSearch): boolean => searchState.searchPending,
);

export const getOriginalSearchData = createSelector(
    [getSearchState],
    (searchState: IReduxStateSearch): ISearchResponseData[] => searchState.searchData,
);

export const getSearchData = createSelector(
    [getSearchState, getDisclosureTypesAccordance, layoutReductorTypes],
    (
        searchState: IReduxStateSearch,
        disclosureAccordance: Partial<IDisclosureTypeState>,
        articleStampType: IArticleStamp[],
    ): ISearchTableData[] => {
        return searchState.searchData.reduce(
            (acc: ISearchTableData[], current: ISearchResponseData): ISearchTableData[] => {
                const pages = current.pages.map(({ page }: { page: IPages }): ISearchTableData => {
                    const metadata: IMetadataSearchData = {
                        subject: page.defaultIndexMetadata.subject ? page.defaultIndexMetadata.subject.value : '',
                        to: page.defaultIndexMetadata.to ? page.defaultIndexMetadata.to.value : '',
                        from: page.defaultIndexMetadata.from ? page.defaultIndexMetadata.from.value : '',
                        date: page.defaultIndexMetadata.date ? page.defaultIndexMetadata.date.value : '',
                        toOrg: page.defaultIndexMetadata.toOrg ? page.defaultIndexMetadata.toOrg.value : '',
                        fromOrg: page.defaultIndexMetadata.fromOrg ? page.defaultIndexMetadata.fromOrg.value : '',
                    };

                    return {
                        id: page.id,
                        request: current.request,
                        documentName: current.documentName,
                        documentId: current.documentId,
                        page: page.actualPageNumber,
                        disclosure: get(
                            disclosureAccordance,
                            page.disclosureTypeId,
                            '',
                        ),
                        articles: transformExemptionIdsToArticles(page.exemptionIds, articleStampType),
                        ...metadata,
                    };
                });

                return [...acc, ...pages];
            }, []);
    },
);

export const getSearchCurrentElement = createSelector(
    [getSearchState, reductionState],
    (
        searchState: IReduxStateSearch,
        { scale }: IRedactorState,
    ): ICurrentSearchElement => {
        const currElement = searchState.currentSearchElement;

        return searchElementCalc(currElement, scale);

    },
);

export const getPreviousSearchElement = createSelector(
    [getSearchState, reductionState],
    (
        searchState: IReduxStateSearch,
        { scale }: IRedactorState,
    ): ICurrentSearchElement => {
        const prevsElement = searchState.previousSearchElement;

        if(!prevsElement) {
            return;
        }

        return {
            index: prevsElement.index,
            coordinates: prevsElement.coordinates,
            ...( prevsElement.documentName && prevsElement.pageId && {
                documentName: prevsElement.documentName,
                pageId: prevsElement.pageId,
                actualPageNumber: prevsElement.actualPageNumber,
                documentId: prevsElement.documentId,
            }),
        };

    },
);

export const getGlobalSearchColumnHeaderLoading = createSelector(
    [getSearchState],
    (searchState: IReduxStateSearch): boolean => searchState.globalSearchColumnHeaderPending,
);

export const getGlobalSearchTableColumnHeader = createSelector (
    [getSearchState],
    (searchState: IReduxStateSearch): IHeader[] => {
        const searchListHeader = searchState.globalSearchColumnHeader.map((header: IHeader): IHeader => ({
            ...header,
            accessorKey: header.accessor,
            header: header.Header,
        }));
        return searchListHeader;
    }
);

export const getResetedGlobalSearchColumnHeaderLoading = createSelector (
    [getSearchState],
    (searchState: IReduxStateSearch): boolean => searchState.resetGlobalSearchColumnHeaderPending,
);

export const getRestrictFlagForPageListFetchData = createSelector(
    [getSearchState],
    (searchState: IReduxStateSearch): boolean => searchState.restrictFlagForPageList,
);

export const getSearchElementDirection = createSelector(
    [getSearchState],
    (searchState: IReduxStateSearch): string => searchState.elementSearchDirection,
);

const searchElementCalc = (element: ICurrentSearchElement, scale: number): ICurrentSearchElement => {
    if (element) {
        return {
            index: element.index,
            width: (element.coordinates[END_POINT].x - element.coordinates[START_POINT].x) * scale,
            height: (element.coordinates[END_POINT].y - element.coordinates[START_POINT].y) * scale,
            coordinates: {
                [START_POINT]: {
                    x: element.coordinates[START_POINT].x * scale,
                    y: element.coordinates[START_POINT].y * scale,
                },
                [END_POINT]: {
                    x: element.coordinates[END_POINT].x * scale,
                    y: element.coordinates[END_POINT].y * scale,
                },
            },
        };
    }
};

import * as React from 'react';
import { getSessionItem, getItem } from '../../utils/localStorage.util';
import { changeLang, commonLabels, getCommonLabelsByKey } from '../../redux/actions/localization';
import { REDACTION_LANG, MODIFIED_LABELS, MODE_CONTRIBUTE, MODE_CONSULT } from '../../redux/reducers/localStorage/constant';
import resourceBundle from '../../containers/localization/localizationData';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import { IStackOptions } from 'redux/reducers/stackList/stackList.model';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import './table.styles.scss';
import { useContextMenu } from 'react-contexify';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { Pagination } from './Pagination/Pagination';
import { Spinner } from '../spinner/Spinner';
import { useEffect, useRef, useState } from 'react';
import { IHeader } from 'containers/leftTopBar/leftTopBar.model';
import { SortingRule } from 'containers/leftBottomBar/LeftBottomBar';
import SettingsTable from './SettingsTable/SettingsTable';

interface ResizibleSortableTableProps {
    title?: string;
    allowSettings?: boolean;
    stackSelector?: boolean;
    stackList?: IStackOptions[];
    chosenStack?: number;
    handleChange?: (event: SelectChangeEvent<number>, child: React.ReactNode) => void;
    isDraggable?: boolean;
    mode?: string;
    columns: any[];
    data: any[];
    pageSize?: number;
    page?: number;
    handleDragEnd?: (result: any) => boolean;
    changeColumns?: (columns: any[]) => void;
    modifyColumns?: (columns: any[], userId: string) => void;
    userId?: string;
    resetColumnHeader?: (userId: string) => void;
    resetedPageListColumnLoading?: boolean;
    userPreferenceTable?: boolean;
    storeSortSession?:boolean;
    handleSortSession? : (param: string, checked: string , pageName : string ) => void;
    sortParams ?: any;
    pageName?:string;
    dynamicColumns?: any[];
    isPDFLoading?: boolean;
    contextMenuId?: any;
    selectedIds?: any;
    pageMenuStartPoint?: any;
    mouseDown?: any;
    onMouseDown?: any;
    loading?: any;
    showPagination?: any;
    pagesCount?: any;
    defaultPageSize?: any;
    sortable?: boolean;
    serverPagination?: boolean;
    useDefaultTable?: boolean;
    enableShowHideColumn?: boolean;
    defaultColumnSorted?: SortingRule[];
    getTrProps?: (row: any) => void;
    onFetchData?: (state: any) => void;
    onPageChange?: (page: number) => void;
    sortChange?: (sortState: MRT_SortingState) => void;
}
type ColumnSizingState = Record<string, number>;
type ColumnOrderState = string[];
type VisibilityState = Record<string, boolean>;
const ServerSidePagingTableTable: React.FC<ResizibleSortableTableProps> = ({
    title,
    allowSettings,
    stackSelector = false,
    stackList,
    chosenStack,
    handleChange,
    isDraggable,
    contextMenuId,
    pageMenuStartPoint,
    resetedPageListColumnLoading,
    selectedIds,
    mode,
    columns,
    data: initialData,
    changeColumns,
    handleDragEnd,
    sortChange,
    userId,
    showPagination,
    resetColumnHeader,
    onFetchData,
    onPageChange,
    defaultPageSize,
    pageSize,
    page,
    pagesCount,
    useDefaultTable,
    serverPagination,
    userPreferenceTable,
    defaultColumnSorted,
    enableShowHideColumn=false,
    modifyColumns,
    sortable,
    getTrProps,
    loading,
}) => {
    const [data, setData] = useState(initialData);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
    const [sortingState, setSortingState] = useState<MRT_SortingState>(defaultColumnSorted || []);
    const [defaultSorted, setDefaultSorted] = useState<MRT_SortingState>([]);
    defaultPageSize = defaultPageSize ? defaultPageSize : 100;
    const [tablepageSize, setTablePageSize] = useState(defaultPageSize);
    
    useEffect(() => {
        setDefaultSortOrder(columns);
    }, [columns]);

    const setDefaultSortOrder = (column: any[]): void => {
        const sortedColumns = setSortOrder(column);
        setDefaultSorted(sortedColumns);
    };

    useEffect(() => {
        setData(initialData);
    }, [initialData]);
      const initialColumnSizing: ColumnSizingState = columns.reduce((acc, column) => {
        if (column.id) {
            acc[column.id] = column.width ? column.width : 80;
        }
        return acc;
    }, {} as ColumnSizingState);
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(initialColumnSizing);
    const initialColumnOrder = columns.sort((a, b) => a.displayOrder - b.displayOrder).map(column => column.id);
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(initialColumnOrder);
    let initialOrderRef = useRef(initialColumnOrder);
    const visibilityState: VisibilityState = columns.reduce((acc, column) => {
        acc[column.id] = column.show;
        return acc;
    }, {} as Record<string, boolean>);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(visibilityState);

    const handleSortingChange = (updaterOrValue: MRT_SortingState | ((old: MRT_SortingState) => MRT_SortingState)) => {
        const newSorting: MRT_SortingState = typeof updaterOrValue === 'function'
            ? (updaterOrValue as (old: MRT_SortingState) => MRT_SortingState)(sortingState)
            : updaterOrValue;
        setSortingState(newSorting);
        sortChange && sortChange(newSorting);
    };

    const redactionLang = getSessionItem(REDACTION_LANG);
    const modifiedLabels = getItem(MODIFIED_LABELS);
    const langRule = changeLang(redactionLang);

    resourceBundle.forEach((resource) => {
        if (getCommonLabelsByKey(resource.resourceKey)) {
            commonLabels[getCommonLabelsByKey(resource.resourceKey)] = resource;
        }
    });

    if (modifiedLabels && modifiedLabels.length) {
        modifiedLabels.forEach((resource) => {
            if (getCommonLabelsByKey(resource.resourceKey)) {
                commonLabels[getCommonLabelsByKey(resource.resourceKey)] = resource;
            }
        });
    }

    const handleRowClick = (row: any) => {
        setSelectedRowId(row.original.id);
        getTrProps && getTrProps(row);
    };

    const tablepagesCount = pagesCount;

    const onChangeDefaultSortOrder = (updaterOrValue: MRT_SortingState | ((old: MRT_SortingState) => MRT_SortingState)) => {
        const newSorting: MRT_SortingState = typeof updaterOrValue === 'function'
            ? (updaterOrValue as (old: MRT_SortingState) => MRT_SortingState)(sortingState)
            : updaterOrValue;
        if (newSorting.length > 0) {
            sortChange(newSorting)
            if (changeColumns) {
                changeColumns(columns);
            }
            if (modifyColumns) {
                modifyColumns(columns, userId);
            }
        }
    };

    const setSortOrder = (columns: any[]): SortingRule[] => {
        const columnsSorted: SortingRule[] = [];
        if(columns && columns.length) {
            columns.map((columnHeader: IHeader) => {
                if(columnHeader.sortOrder === "desc") {
                    return columnsSorted.push({
                        id: columnHeader.id,
                        desc: true,
                    });
                } else if(columnHeader.sortOrder === "asc") {
                    return columnsSorted.push({
                        id: columnHeader.id,
                        desc: false,
                    });
                } 
             });
        }
        return columnsSorted;
    }

    const handlePageChange = (pageNumber : number)=> {
        onPageChange(pageNumber);
        onFetchData(pageNumber);
    }

    const handleColumnOrderChange = (newOrder: string[]) => {
        if (JSON.stringify(newOrder) === JSON.stringify(initialOrderRef.current)) {
            resetColumnHeader(userId);
        } else {
            const createKeyValuePairs = newOrder.reduce((acc, columnName, index) => {
                acc[columnName] = index + 1;
                return acc;
            }, {} as Record<string, number>);
            setColumnOrder(newOrder);
            columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
                data.displayOrder = createKeyValuePairs[data.id];
                return data;
            }
            )
            columns = columns.sort((a, b) => a.displayOrder - b.displayOrder);
            initialOrderRef.current = newOrder;
            if (modifyColumns) {
                modifyColumns(columns, userId);
            }
        }
    };

    const resetTableHeader = () =>{
        resetColumnHeader(userId);
    }

    const handleShowAll = () => {
        columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
            if (data) {
                data.show = true;
            }
            return data;
        }
        )
        const visibilityStateNew = columns.reduce((acc, column) => {
            acc[column.id] = column.show;
            return acc;
        }, {} as Record<string, boolean>);
        setColumnVisibility(visibilityStateNew);
        if (modifyColumns) {
            modifyColumns(columns, userId);
        }
    }

    const handleHideAll = () => {
        columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
            if (data) {
                data.show = false;
            }
            return data;
        }
        )
        const visibilityStateNew = columns.reduce((acc, column) => {
            acc[column.id] = column.show;
            return acc;
        }, {} as Record<string, boolean>);
        setColumnVisibility(visibilityStateNew);
        if (modifyColumns) {
            modifyColumns(columns, userId);
        }
    }

    const handleClickCheckbox = (columnName: string, previousState: boolean) => {
        columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
            if (data.accessorKey == columnName) {
                data.show = !previousState;
            }
            return data;
        }
        )
        const visibilityStateNew = columns.reduce((acc, column) => {
            acc[column.id] = column.show;
            return acc;
        }, {} as Record<string, boolean>);
        setColumnVisibility(visibilityStateNew);
        if (modifyColumns) {
            modifyColumns(columns, userId);
        }
    }

    const handleDrop = (dragIndex: number, hoverIndex: number) => {
        const updatedOrder = [...columnOrder];
        const [removed] = updatedOrder.splice(dragIndex, 1);
        updatedOrder.splice(hoverIndex, 0, removed);
        setColumnOrder(updatedOrder);
        const updatedColumns = updatedOrder.map(orderId => columns.find(col => col.id === orderId));
        if (modifyColumns) {
            modifyColumns(updatedColumns, userId);
        }
        
    };


    const handleColumnSizingChange = (updater: (oldState: ColumnSizingState) => ColumnSizingState) => {
        setColumnSizing((prevState) => {
            const newState = updater(prevState);
            Object.entries(newState).forEach(([columnId, width]) => {
                columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
                    if (data.accessorKey == columnId) {
                        data.width = width;
                    }
                    return data;
                }
                )
            });
            if (modifyColumns) {
                modifyColumns(columns, userId);
            }
            return newState;
        });
    };
    const handleColumnVisibilityChange = (updater: (oldState: VisibilityState) => VisibilityState) => {        
        setColumnVisibility((prevState) => {
            const newState = updater(prevState);
            Object.entries(newState).forEach(([columnId, visibility]) => {
                columns = Object.entries(columns).map(([_, data]: [string, IHeader]) => {
                    if (data.accessorKey == columnId) {
                        data.show = visibility;
                    }
                    return data;
                }
                )
            });
            if (modifyColumns) {
                modifyColumns(columns, userId);
        }
        return newState;
        });
    };
  
    const CustomPagination = (props: any) => {
        if (!showPagination) {
            return null;
        }
        return (
            <Pagination
                {...props}
                page={page}
                canNext={page < (Math.ceil(tablepagesCount / defaultPageSize)-1)}
                canPrevious={page > 0}
                pageSize={tablepageSize}
                dataLength={tablepagesCount}
                pages={Math.ceil(tablepagesCount / defaultPageSize)}
                onPageChange={handlePageChange}
            />
        );
    };

    const table = useMaterialReactTable({
        columns,
        data : data,
        enableStickyHeader: true,
        enableColumnActions: false,
        enableGlobalFilter: false,
        enableColumnResizing: true,
        enableColumnDragging: false,
        enableHiding: false,
        enableColumnOrdering: false,
        enableDensityToggle: enableShowHideColumn,
        enableColumnFilters: enableShowHideColumn,
        enableSorting: sortable,
        manualSorting:true,
        sortDescFirst: false,
        manualPagination: true,
        enableSortingRemoval: false,
        muiTableContainerProps:{sx:{height:'100%'}},
        muiTablePaperProps: { sx: { display: 'flex', flexDirection: 'column', height: '100%' } },
        pageCount: pagesCount,
        rowCount : pagesCount,
        columnResizeMode: 'onEnd',
        state:{
            sorting: (!serverPagination || useDefaultTable) ? defaultSorted : sortingState,
            columnSizing : columnSizing,
            columnOrder,
            columnVisibility : columnVisibility,
            isLoading: loading,
        },
        onColumnSizingChange:handleColumnSizingChange,
        onColumnOrderChange:  handleColumnOrderChange,
        onColumnVisibilityChange:handleColumnVisibilityChange,
        onSortingChange: (!serverPagination || useDefaultTable) ?  (userPreferenceTable && onChangeDefaultSortOrder) : handleSortingChange,
        localization: redactionLang == 'en' ? MRT_Localization_EN : MRT_Localization_FR,
        muiFilterTextFieldProps: {
            sx: {
                minWidth: "80px",
            },
        },
        muiTableHeadCellProps: (props) => {
            const sortDir = props.column.getIsSorted();
            if (typeof sortDir === 'string') {
                return {
                    sx: {
                        backgroundColor: "#f2f2f2",
                        color: "#8492a6",
                        boxShadow: sortDir == 'asc' ? 'inset 0 3px 0 0 rgba(0, 0, 0, 0.6);' : 'inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);',
                        "& .MuiBadge-root": {
                            display: "none !important",
                        },
                    },
                };
            }
            return {
                sx: {
                    backgroundColor: "#f2f2f2",
                    color: "#8492a6",
                    "& .MuiBadge-root": {
                        display: "none !important",
                    },
                }
            }
        },
        muiTableBodyCellProps: ({ row }) => ({
            sx: {
                fontWeight: selectedIds != null && selectedIds.includes(row.original.id) ? 'bold' : '',
            }
        }),
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => handleRowClick(row),
            sx: {
                backgroundColor: selectedIds !=null && selectedIds.includes(row.original.id) ? '#e0e0e0' : 'inherit',
                cursor: isDraggable ? 'grab' : 'default',
            },
            onContextMenu: (event) => {
                event.preventDefault();
                handleRowClick(row);
                handleContextMenu(event);
            },
            draggable: isDraggable,
            onDragStart: (event) => {
                event.dataTransfer.setData('text/plain', JSON.stringify(row.original));
            },
            onDragOver: (event) => {
                event.preventDefault();
            },
            onDrop: (event) => {
                event.preventDefault();
                const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'));
                const droppedIndex = data.findIndex(item => item.id === droppedItem.id);
                const targetIndex = data.findIndex(item => item.id === row.original.id);
                const dragData = { id: droppedItem.id, pageNumber: targetIndex, prevPageNumber: droppedIndex };
                if (droppedIndex !== -1 && targetIndex !== -1 && !handleDragEnd(dragData)) {
                    const [removedItem] = data.splice(droppedIndex, 1);
                    data.splice(targetIndex, 0, removedItem);
                    setData([...data]);
                }
            },
        }),
        muiTableFooterProps:() => {
            return {
                sx: {
                    outline: 'none',
                }
            }
        },
        muiTopToolbarProps:() => {
            if (!title) {
                return {
                    sx: {
                        display: 'none',
                    },
                };
            }
            return {
                sx: {
                    "& .MuiSvgIcon-root": {
                        fontSize: "1.2rem !important"
                    },
                }
            }
        },
        muiBottomToolbarProps:() => {
            if (!showPagination) {
                return {
                    sx: {
                        display: 'none',
                    },
                };
            }
        },
        renderBottomToolbarCustomActions:() => (
            <CustomPagination
            page={page}
            pageSize={pageSize}
            dataLength={pagesCount}
            onPageChange={onPageChange}
        />
        ),
        renderTopToolbarCustomActions: () => (
            <div className="react_table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
            <div className="left-section" style={{ display: 'flex', alignItems: 'center' }}>
                <h4>{title}</h4>
                {stackSelector && (
                    <Select
                        disabled={false}
                        value={chosenStack}
                        onChange={handleChange}
                        className="react_table-select"
                        style={{ marginLeft: '10px' }}
                    >
                        {!isContribute && (
                            <MenuItem value={0} className="react_table-stack-item">
                                {initialText}
                            </MenuItem>
                        )}
                        {stackList.map((option: IStackOptions) => (
                            <MenuItem
                                key={option.id}
                                value={option.id}
                                className="react_table-stack-item"
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </div>
            <div className="right-section" style={{ display: 'flex', alignItems: 'center', marginBottom:'auto' }}>
                {allowSettings && (
                    <SettingsTable
                        columns={columns.sort((a, b) => a.displayOrder - b.displayOrder)}
                        handleClickCheckbox={handleClickCheckbox}
                        resetColumns={resetTableHeader}
                        handleDrop={handleDrop}
                        columnOrder={columnOrder}
                        handleShowAll={handleShowAll}
                        handleHideAll={handleHideAll}
                    />
                )}
                {/* Add default table icons here if any */}
            </div>
        </div>
        ),
        defaultColumn: {
            minSize: 20,
            maxSize: 9001,
            size: 80,
        },
        layoutMode: 'grid',
        enablePagination: false,
        enableFullScreenToggle: false,
    });

    const handleContextMenu = (event: React.MouseEvent): void => {
        const { show } = useContextMenu({ id: contextMenuId });
        let x = event.clientX;
        let y = event.clientY;

        if (event.clientX === 0 && event.clientY === 0 && pageMenuStartPoint) {
            x = pageMenuStartPoint.x;
            y = pageMenuStartPoint.y;
        }

        show(event, {
            position: { x, y },
            props: null,
        });
    }

    const isContribute = mode === MODE_CONTRIBUTE;
    const isConsult = mode === MODE_CONSULT;
    const initialText = isConsult ? 'Consulted Documents' : 'All Documents';

    return (
        // loading ? <Spinner active={true} /> :
            <div className="react_table">
                <div className="material-react-table">
                    <MaterialReactTable
                        table={table}
 
                    />
                </div>
            </div>
    );
};

export default ServerSidePagingTableTable;
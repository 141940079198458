import { ILabel, ILocalizationState } from 'redux/reducers/localization/localization.model';
import { createSelector } from 'reselect';
import { IState } from '../store';

export const getLocalization = (state: IState): ILocalizationState => state.localization;

export const getLocalizationSearchResult = createSelector(
    [getLocalization],
    (localization: ILocalizationState): ILabel[] => localization.searchResult);

export const getLocalizationOriginalResult = createSelector(
    [getLocalization],
    (localization: ILocalizationState): ILabel[] => localization.originalSearchResult);

export const getModifiedLabels = createSelector(
    [getLocalization],
    (localization: ILocalizationState): ILabel[] => localization.modifiedLabels);

export const getModifiedLabelsFlag = createSelector(
    [getLocalization],
    (localization: ILocalizationState): boolean => localization.modifiedLabelsPending);

export const getSearchResultsPending = createSelector(
    [getLocalization],
    (localization: ILocalizationState): boolean => localization.searchResultPending);

export const getImportSystemMessagesFlag = createSelector(
    [getLocalization],
    (localization: ILocalizationState): boolean => localization.importSystemMessagesFlag);

export const getSelectedSystemMessage = createSelector(
    [getLocalization],
    (localization: ILocalizationState): ILabel => localization.selectedSystemMessage);

export const getAuthFlag = createSelector(
    [getLocalization],
    (localization: ILocalizationState): boolean => localization.authFlag);

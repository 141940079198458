import { REDACTION, VIEW } from '../constants/permissions';
import { IMenu, showAnywayHeaderMenus } from '../constants/headerMenu.constants';

export const getAvailableMenuByPermissions = (menu: IMenu[], permissions: string[]): IMenu[] => {
    const checkPermissions = permissions.concat(showAnywayHeaderMenus);

    return menu.map((menuItem: IMenu): IMenu => {
        const item = `${REDACTION}_${menuItem.id.toUpperCase()}`;
        const itemWithView = `${item}_${VIEW}`;
        const includesMenuItem = menuItem.enable
            && (checkPermissions.includes(item) || checkPermissions.includes(itemWithView));

        return includesMenuItem ? {
            ...menuItem,
            enable: includesMenuItem,
            submenus: checkAvailablePermissions(menuItem.submenus, checkPermissions),
        } : {
            ...menuItem,
            enable: false,
        };
    });
};

export const checkAvailablePermissions = (submenus: IMenu[], checkPermissions: string[]): IMenu[] => submenus.map(
    (submenuItem: IMenu): IMenu => ({
        ...submenuItem,
        enable: checkPermissions.includes(submenuItem.id),
        ...(!!submenuItem.submenus && { submenus: checkAvailablePermissions(submenuItem.submenus, checkPermissions) }),
    }));

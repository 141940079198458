import * as React from 'react';
import Button from '@mui/material/Button';
import { Scrollbars } from 'rc-scrollbars';
import { AutoSizer } from 'react-virtualized';
import { IFormProps } from './ModalStamps.model';
import { IStampType } from '../../../redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { CheckboxMaterialBuilder } from '../../../components/materialUiForms/materialUiFormBuilder';
import './ModalCustomStampsForm.styles.scss';

const className = {
    BODY_CHILD_DESC: 'body-children-description',
    BODY_CHILD_TEXT: 'body-children-text',
    TAB_HEAD: 'table-header',
};

const ModalCustomStampsForm = ({
    onSubmit,
    customStampsList,
    handleChange,
    selectedStamps,
    handleCloseModalWindow,
}: IFormProps): JSX.Element => (
    <div className='custom-stamp-wrapper'>
        <AutoSizer>
            {({ width, height }: { width: number; height: number; }): JSX.Element  => (
                <div className='full-width indents' style={{ width, height }}>
                    <div className='table'>
                        <div className={className.TAB_HEAD}>
                            <h5>Text </h5>
                            <h5>Description </h5>
                        </div>
                        <Scrollbars>
                            <div className='modal-scroll'>
                                {
                                    customStampsList.map((stamp: IStampType, i: number) => (
                                        <div className='tab-body' key={i}>
                                            <div
                                                className={className.BODY_CHILD_TEXT}
                                                key='selectedStampText'
                                            >
                                                <CheckboxMaterialBuilder
                                                    checked={!!selectedStamps.find(
                                                        (selectedStamp: IStampType): boolean =>
                                                            (
                                                                selectedStamp.id === stamp.id
                                                            ),
                                                    )}
                                                    handleCheckboxChange={
                                                        (param: string, checked: boolean): void =>
                                                            handleChange(param, checked)
                                                    }
                                                    label={stamp.text}
                                                    paramName={`${stamp.id}`}
                                                />
                                            </div>
                                            <div
                                                className={className.BODY_CHILD_DESC}
                                                key='selectedStampDescription'
                                            >
                                                {stamp.description}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Scrollbars>
                    </div>
                    {/*TODO need refactoring*/}
                    <div className='form-footer__action'>
                        <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={onSubmit}
                            className='modal-window__buttons primary'
                        >
                            Ok
                        </Button>
                        <Button
                            size='small'
                            variant='outlined'
                            onClick={handleCloseModalWindow}
                            className='modal-window__buttons outlined'
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            )}
        </AutoSizer>
    </div>
);

export default ModalCustomStampsForm;

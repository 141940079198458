import { IState } from '../store';
import { createSelector } from 'reselect';
import { IStamp, IStampsState } from '../reducers/stamps/stamps.model';
import { IRedactorState } from '../reducers/redactor/redactor.model';
import { reductionState } from './redactor';
import { IKonvaRectangle } from '../../containers/shapesLayer/shapesLayer.model';
import { END_POINT, START_POINT } from '../../constants/annotationTypes.constants';
import { TRANSPARENT, STAMP } from '../../constants/common.constants';
import { getClearPrefixAndSuffix } from './modalGlobalSettings';
import { IExemptionSettings } from '../reducers/modalGlobalSettings/globalSettings.model';

export const stampsState =  (state: IState): IStampsState  => state.stamps;

export const getCurrentPageStamps = createSelector(
    [ stampsState ],
    (stamps: IStampsState): IStamp[] => stamps.stamps,
);

export const getStampsKonvaData = createSelector(
    [stampsState, reductionState, getClearPrefixAndSuffix],
    (
        stamps: IStampsState,
        { scale }: IRedactorState,
        { prefix, suffix }: IExemptionSettings,
    ): IKonvaRectangle[] =>  stamps.stamps.map(
        (stamp: IStamp, idx: number): IKonvaRectangle => ({
            id: stamp.id,
            width: (stamp.coordinate[0][END_POINT].x - stamp.coordinate[0][START_POINT].x) * scale,
            height: (stamp.coordinate[0][END_POINT].y - stamp.coordinate[0][START_POINT].y) * scale,
            coordinate: {
                [START_POINT]: {
                    x: stamp.coordinate[0][START_POINT].x * scale,
                    y: stamp.coordinate[0][START_POINT].y * scale,
                },
                [END_POINT]: {
                    x: stamp.coordinate[0][END_POINT].x * scale,
                    y: stamp.coordinate[0][END_POINT].y * scale,
                },
            },
            color: TRANSPARENT,
            opacity: 1,
            name: `${STAMP}${stamp.id ? stamp.id : (stamp.text + idx)}`,
            stampTypeId: stamp.stampTypeId,
            text: `${stamp.isExemption ? prefix : ''}${stamp.text}${stamp.isExemption ? suffix : ''}`,
            pageId: stamp.pageId,
            isExemption: stamp.isExemption,
            exemptionCode: stamp.exemptionCode,
            fontSize: stamp.fontSize * scale,
            rotate: stamp.rotation,
            stroke: !!stamp.stroke ? stamp.stroke : '',
        }),
      ),
);

export const getAutoStamp = createSelector(
    [ stampsState ],
    (stamps: IStampsState): boolean => stamps.autoStamp,
);

export const getAutoAlignArticleStampsUserPreference = createSelector(
    [ stampsState ],
    (stamps: IStampsState): string => stamps.stampAlignment,
);

import { IIndex, IIndexMetadata } from '../redux/reducers/indexMetadata/indexMetadata.model';
import { omit, concat, uniqBy, sortBy  } from 'lodash';

export const mergeIndexArrayWithMetadata = (array: IIndex[], metadata: IIndexMetadata[]): IIndex[] => {
    const emptyMetadata = metadata.map((meta: IIndexMetadata, i: number): IIndex => {
        return {
            id: -(i + 1),
            metadataId: meta.id,
            value: null,
        };
    });

    return sortBy(
        uniqBy(
            concat(array, emptyMetadata),
            'metadataId',
        ),
        'metadataId',
    );
};

export const prepareIndexDataToSave = (data: IIndex[]): IIndex[] => {
    return data
        .map((item: IIndex): IIndex => {
            return typeof item.value === 'object' && item.value !== null
                ? {
                    ...item,
                    value: item.value.id,
                }
                : item;
        })
        .map((item: IIndex) => item.id < 0 ? omit(item, 'id') : item)
        .filter((item: IIndex): boolean => !!item.value);
};

import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { addError } from './errorHandling';
import api from '../../api/reductionApi';
import {
    GET_WATERMARKS_SETTINGS_SUCCESS,
    GET_WATERMARKS_SETTINGS_PENDING,
    GET_WATERMARKS_SETTINGS_FAILURE,
    PUT_WATERMARK_TYPE_SUCCESS,
    PUT_WATERMARK_TYPE_FAILURE,
    PUT_WATERMARK_TYPE_PENDING,
} from '../reducers/modalAssociateWatermarks/constants';
import {
    IWatermarkRequest,
    IWatermarkTypeAssociate,
} from '../reducers/modalAssociateWatermarks/modalAssociateWatermarks.model';
import { IWatermarkState,
} from '../../containers/modalWindowContents/modalAssociateWatermark/ModalAssociateWatermark.model';
import { IError } from '../common.model';

export const putWatermarkTypePending = (): AnyAction =>
 action(PUT_WATERMARK_TYPE_PENDING);

export const putWatermarkTypeSuccess = (data: IWatermarkTypeAssociate[]): AnyAction =>
    action(PUT_WATERMARK_TYPE_SUCCESS, data);
export const putWatermarkTypeFailure = (error: IError): AnyAction =>
    action(PUT_WATERMARK_TYPE_FAILURE, error);
export const fetchWatermarksSettingsPending = (): AnyAction =>
    action(GET_WATERMARKS_SETTINGS_PENDING);
export const fetchWatermarksSettingsSuccess = (watermarksSettings: IWatermarkRequest[]): AnyAction =>
    action(GET_WATERMARKS_SETTINGS_SUCCESS, watermarksSettings);
export const fetchWatermarksSettingsFailure = (error: IError): AnyAction =>
    action(GET_WATERMARKS_SETTINGS_FAILURE, error);
export const fetchWatermarksSettings = (): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchWatermarksSettingsPending());

        try {
            const response = await api.watermarksTypeAssociateController.getWatermarkTypeAssociateSettings();

            dispatch(fetchWatermarksSettingsSuccess(response));
        } catch (error) {
            dispatch(fetchWatermarksSettingsFailure(error));
            dispatch(addError(error));
        }
    };
export const putSelectedWatermarkType = (data: IWatermarkTypeAssociate[]): (dispatch: Dispatch) => Promise<void> =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(putWatermarkTypePending());

        try {
            const response = await api.watermarksTypeAssociateController.updateWatermarkTypeAssociateSettings(data);

            dispatch(putWatermarkTypeSuccess(response));
        } catch (error) {
            dispatch(putWatermarkTypeFailure(error));
            dispatch(addError(error));
        }
    };

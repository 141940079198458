import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { connect } from 'react-redux';
import { isEqual, get } from 'lodash';
import {
    BASIC_SETTINGS,
    DISCLOSURE_SETTINGS,
    EXEMPTION_SETTINGS,
    PREFIX,
    SUFFIX,
} from '../../../constants/globalSettings.constants';
import SimpleTabs from '../../../components/simpleTabs/SimpleTabs';
import { fetchGlobalSettings, updateGlobalSettings } from '../../../redux/actions/modalGlobalSettings';
import { IProps, IDispatchProps, IStateProps } from './globalSettings.modal';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import { DisclosureSettingsTab } from './disclosureSettings/DisclosureSettingsTab';
import { ExemptionSettingsTab } from './exemptionSettings/ExemptionSettingsTab';
import { IDisclosureSettingsUpdate } from '../../../redux/reducers/modalGlobalSettings/globalSettings.model';
import { handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import { IState as StoreState } from '../../../redux/store';
import {
    getDisclosureSettings,
    getExemptionSettings,
    getGlobalSettingsLoading,
    getUpdateGlobalSettingsLoading,
    getBasicSettings,
} from '../../../redux/selectors/modalGlobalSettings';
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import { validate } from '../../../utils/validate.util';
import { AnyObject, FormApi, FormState } from 'final-form';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { BasicSettingsTab } from './basicSettings/BasicSettingsTab';
import { SAVE, CLOSE, CANCEL } from '../../../constants/common.constants';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import {
    CHANGES_MAY_NOT_BE_SAVED_MESSAGE,
    CONFIRMATION_DIALOG_MODAL,
    WARNING,
} from '../../../constants/messages.constants';
import { MAX_LENGTH } from '../../../constants/validate.constants';
import './globalSettingsTab.style.scss';
import { GLOBAL_SETTINGS } from '../../../constants/modal.constants';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { stat } from 'fs';
import { changeLang } from '../../../redux/actions/localization';
import resourceBundle from '../../../containers/localization/localizationData';
import { initialLabel } from '../../../constants/localization.constants';

const getGlobalLabelsByKey = (key: string): string => {
    switch (key) {
        case 'BASIC_SETTINGS_LABEL':
            return 'basicLabel';
        case 'DISCLOSURE_SETTINGS_LABEL':
            return 'disclosureLabel';
        case 'EXEMPTION_SETTINGS_LABEL':
             return 'exemptionLabel'
        case 'GLOBALSETTINGS_CANCEL':
             return 'cancelLabel'     
        case 'GLOBALSETTINGS_SAVE':
             return 'saveLabel'
        case 'COMMON_LABEL_MAX_LENGTH':
             return 'maxLengthLabel'
        case 'COMMON_LABEL_WARNING':
             return 'warningLabel'
        case 'COMMON_LABEL_CHANGES_NOT_SAVED':
             return 'changesLabel'
        default:  return '';
    }
}

const labels = {
    basicLabel: initialLabel,
    disclosureLabel: initialLabel,
    exemptionLabel: initialLabel,
    cancelLabel: initialLabel,
    saveLabel: initialLabel,
    maxLengthLabel: initialLabel,
    warningLabel: initialLabel,
    changesLabel: initialLabel,
};

const validateFields = [
    {
        field: PREFIX,
        [MAX_LENGTH]: 5,
    }, {
        field: SUFFIX,
        [MAX_LENGTH]: 5,
    },
];

const classNames = {
    globalSettingsModal: 'global-settings-modal',
    globalSettingsModalFooter: 'global-settings-modal-footer',
    globalSettingsModalBody: 'global-settings-modal-body',
    globalSettingsLoader: 'global-settings-modal-loader',
};

export class GlobalSettingsModal extends React.Component<IProps> {
    public componentDidMount(): void {
        this.props.fetchGlobalSettings();
    }

    public componentWillUnmount(): void {
        this.props.handleDetectChanges(false);
    }

    public render(): JSX.Element {
        const {
            loadingSettings,
            updateSettings,
            disclosureSettings,
            basicSettings,
            exemptionSettings,
            handleDetectChanges,
            permissions: {
                EDIT: editPermission,
            },
            redactionLang,
            modifiedLabels,
        } = this.props;
        const initialValues = {
            disclosureSettings,
            ...exemptionSettings,
        };

    const langRule = changeLang(redactionLang);
    resourceBundle.map((resource: any) => {
        if (getGlobalLabelsByKey(resource.resourceKey)) {
            labels[getGlobalLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });
    modifiedLabels.map((resource: any) => {
        if (getGlobalLabelsByKey(resource.resourceKey)) {
            labels[getGlobalLabelsByKey(resource.resourceKey)] = resource;
        }

        return resource;
    });

        return (
            <Form
                onSubmit={this.handleSave}
                initialValues={initialValues}
                validate={(values: any): { [param: string]: boolean } => validate(values, validateFields,{}, labels,langRule)}
                render={({
                     handleSubmit,
                     invalid,
                     form,
                }: FormRenderProps): JSX.Element => (
                    <form
                        className={classNames.globalSettingsModal}
                        onSubmit={handleSubmit}
                    >
                        <div className={classNames.globalSettingsModalBody}>
                            <SimpleTabs defaultIndex={1}>
                                <BasicSettingsTab
                                    title={labels.basicLabel[langRule]}
                                    isValid={true}
                                    loadingSettings={loadingSettings}
                                    basicSettings={basicSettings}
                                    disabled={false}
                                    form={form}
                                    editPermission={editPermission}
                                    modifiedLabels={modifiedLabels}
                                    redactionLang={redactionLang}
                                />
                                <DisclosureSettingsTab
                                    title={labels.disclosureLabel[langRule]}
                                    isValid={true}
                                    loadingSettings={loadingSettings}
                                    disclosureSettings={disclosureSettings}
                                    form={form}
                                    editPermission={editPermission}
                                    modifiedLabels={modifiedLabels}
                                    redactionLang={redactionLang}
                                />
                                <ExemptionSettingsTab
                                    title={labels.exemptionLabel[langRule]}
                                    isValid={true}
                                    loadingSettings={loadingSettings}
                                    modifiedLabels={modifiedLabels}
                                    redactionLang={redactionLang}
                                    disabled={false}
                                />
                            </SimpleTabs>
                        </div>
                        <div className={classNames.globalSettingsModalFooter}>
                            <Tooltip title={labels.cancelLabel[langRule]} placement='top'>
                                <Button
                                    variant='outlined'
                                    onClick={(): void => this.handleCloseModal(form)}
                                    className='modal-window__buttons outlined'
                                    size='small'
                                >
                                    {labels.cancelLabel[langRule]}
                                </Button>
                            </Tooltip>
                            <Tooltip title={labels.saveLabel[langRule]} placement='top'>
                                <span>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        disabled={updateSettings || invalid || !editPermission}
                                        onClick={(): Promise<object | undefined> => handleSubmit()}
                                        className='primary'
                                    >
                                        {labels.saveLabel[langRule]}
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div className={classNames.globalSettingsLoader}> {updateSettings && <LinearProgress/>} </div>
                        <FormSpy
                            subscription={{values: true, dirty: true }}
                            onChange={<K extends object>(props: FormState<K>): void => {
                                handleDetectChanges(this.isGlobalSettingsChanged(form));
                            }}
                        />
                    </form>
                )}
            />
        );
    }

    private isGlobalSettingsChanged(form: FormApi): boolean {
        const formValues = form.getState().values;
        const { disclosureSettings, exemptionSettings} = this.props;
        const defaultExemptionSettings = { [PREFIX]: '', [SUFFIX]: '' };
        const initialValues = {
            disclosureSettings,
            ...exemptionSettings,
        };
        const currentFormValues = {
            ...defaultExemptionSettings,
            ...formValues,
        };

        return !isEqual(initialValues, currentFormValues);
    }

    private handleCloseModal = (form: FormApi): void => {
        const langRule = changeLang(this.props.redactionLang);
        if (this.isGlobalSettingsChanged(form)) {
            this.props.openModalWindow(CONFIRMATION_DIALOG_MODAL, {
                id: 'Not-saved',
                title: labels.warningLabel[langRule],
                message: labels.changesLabel[langRule],
                confirm: (): () => void => (): void => this.props.closeModal(GLOBAL_SETTINGS),
            });
        } else {
            this.props.closeModal(GLOBAL_SETTINGS);
        }
    }

    private handleSave = (values: AnyObject): void => {
        const data = {
            disclosureSettings: values.disclosureSettings,
            exemptionSettings: {
                prefix: get(values, PREFIX, ''),
                suffix: get(values, SUFFIX, ''),
            },
            basicSettingsDto: values.basicSettingsDto,
        };

        this.props.updateGlobalSettings(data);
    }

}

const mapStateToProps = (state: StoreState): IStateProps => ({
    loadingSettings: getGlobalSettingsLoading(state),
    updateSettings: getUpdateGlobalSettingsLoading(state),
    basicSettings: getBasicSettings(state),
    disclosureSettings: getDisclosureSettings(state),
    exemptionSettings: getExemptionSettings(state),
    redactionLang: getRedactionLanguage(state),
    modifiedLabels: getModifiedLabels(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IProps, IDispatchProps, AnyAction>): IDispatchProps => ({
    fetchGlobalSettings: (): void => {
        dispatch(fetchGlobalSettings());
    },
    openModalWindow: (data: string, message: IModalProps): void => {
        dispatch(openModalWindow(data, message));
    },
    updateGlobalSettings: (data: IDisclosureSettingsUpdate): void => {
        dispatch(updateGlobalSettings(data));
    },
    closeModal: (id: string): void => {
        dispatch(handleCloseModalWindow(id));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSettingsModal);

import { regexExtensions } from '../constants/common.constants';
import { uniq, isEqual } from 'lodash';

export const getUniqSelectedItems = (id: number, isMultiple: number, prevDocs: number[]): number[] => {
    const nextDocs = isMultiple ? uniq([...prevDocs, id]) : [id];

    const uniqueDocs = prevDocs.filter((docId: number): boolean => {
        return docId !== id;
    });

    return prevDocs.length > 1 && isEqual(nextDocs, prevDocs) ? uniqueDocs : nextDocs;
};

export const removeExtension = (filename: string): string => {
    const lastDotPosition = filename.lastIndexOf('.');
    const extensionName = filename.substring(lastDotPosition, filename.length).toLowerCase();

    if (lastDotPosition === -1) {
        return filename;
    } else if(regexExtensions.test(extensionName)) {
        return filename.substring(0, lastDotPosition);
    } else {
        return filename;
    }

};

export const dateSorting = (a: string | Date | number, b: string | Date | number, desc: boolean): number => {
    let key1 = (a === null || a === undefined || a === '') ? a : new Date(a);
    let key2 = (b === null || b === undefined || b === '') ? b : new Date(b);

    if (!desc && (!key1 || !key2)) {
        !key1 ? key1 = new Date().setFullYear(5000) : key2 = new Date().setFullYear(5000);
    } else if (desc && (!key1 || !b)) {
        !key1 ? key1 = new Date().setFullYear(1) : key2 = new Date().setFullYear(1);
    }

    if (key1 === key2) {
        return 0;
    }

    return key1 < key2 ? -1 : 1;
};

import { IState } from '../store';
import { createSelector } from 'reselect';
import { ILayoutRedactorTypes, IStampType } from 'redux/reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { IBuffer, ILinkedAnnotaionIdAndStampsId, IRedactorDocument, IRedactorState } from '../reducers/redactor/redactor.model';
import { getPageCurrentDocLoading } from './pageList';
import { IArticleStamp } from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';

export const reductionState = (state: IState): IRedactorState => state.redactor;
export const layoutReductorTypes = (state: IState): ILayoutRedactorTypes => state.layoutRedactorTypes;
export const getBuffer = (state: IState): IBuffer => state.redactor.buffer;

export const getDocument = createSelector(
    [ reductionState ],
    (redactor: IRedactorState): IRedactorDocument => ({
        pdfSrc: redactor.pdfSrc,
        rotate: redactor.rotate,
        selectedPage: redactor.selectedPage,
        textLayer: redactor.textLayer,
        renderAnnotations: redactor.renderAnnotations,
        scale: redactor.scale,
        size: redactor.size,
        pdfWidth: redactor.pdfWidth,
        pdfHeight: redactor.pdfHeight,
        pdfOffsetWidth: redactor.pdfOffsetWidth,
    }),
);

export const getDocumentLoading = createSelector(
    [ reductionState, getPageCurrentDocLoading ],
    (redactor: IRedactorState, loadingDocument: boolean): boolean =>
        redactor.loading || redactor.redactorLoading || loadingDocument,
);

export const getStampsList = createSelector(
    [ layoutReductorTypes ],
    (layout: ILayoutRedactorTypes): IStampType[] => layout.stampsType,
);

export const getLocalStampsList = createSelector(
    [ layoutReductorTypes ],
    (layout: ILayoutRedactorTypes): IStampType[] => layout.localStampsType,
);

export const getStampsListLoading = createSelector(
    [ layoutReductorTypes ],
    (layout: ILayoutRedactorTypes): boolean => layout.stampPending,
);

export const getUpdateStampsListLoading = createSelector(
    [ layoutReductorTypes ],
    (layout: ILayoutRedactorTypes): boolean => layout.stampUpdatingPending,
);

export const getActiveStampsList = createSelector(
    [ layoutReductorTypes ],
    (layout: ILayoutRedactorTypes) =>
        layout.stampsType.filter((stamp: IStampType): boolean => stamp.isActive),
);

export const getSelectedCustomStamps = createSelector(
    [ layoutReductorTypes ],
    (layout: ILayoutRedactorTypes): IStampType[] => layout.selectedCustomStamps,
);

export const getArticleStamps = createSelector(
    [ layoutReductorTypes ],
    (layout: ILayoutRedactorTypes): IArticleStamp[] => layout.articleStampType,
);

export const getSelectedShapes = createSelector(
    [reductionState],
    (redactor: IRedactorState) => [...redactor.selectedStampShapes, ...redactor.selectedAnnotationShapes],
);

export const getSelectedAnnotationShapes = createSelector(
    [reductionState],
    (redactor: IRedactorState) => redactor.selectedAnnotationShapes,
);

export const getSelectedStampShapes = createSelector(
    [reductionState],
    (redactor: IRedactorState) => redactor.selectedStampShapes,
);

export const clientSize = createSelector(
    [reductionState],
    (redactor: IRedactorState) => redactor.clientSize,
);

export const getSingleSelectedShape = createSelector(
    [reductionState],
    (redactor: IRedactorState) => redactor.selectedSingleShape,
);

export const getOverlayedShapesList = createSelector(
    [reductionState],
    (redactor: IRedactorState) => redactor.overlayedShapes,
);

export const getlinkedAnnotaionIdAndStampsId = createSelector(
    [reductionState],
    (redactor: IRedactorState): ILinkedAnnotaionIdAndStampsId => redactor.linkedAnnotaionIdAndStampsId,
);

export const getCurrentDocumentPageLoading = createSelector(
    [reductionState],
    (redactor: IRedactorState) => redactor.loading,
);

export const getCurrentDocumentPageLoaded = createSelector(
    [reductionState],
    (redactor: IRedactorState) => redactor.loaded,
);

export const getDocumentScale = createSelector(
    [getDocument],
    (document: IRedactorDocument): number => document.scale,
);

export const getContextMenuStartPoint = createSelector(
    [reductionState],
    (redactor: IRedactorState) => redactor.contextMenuStartPoint,
);

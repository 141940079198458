import { AnyAction } from 'redux';
import {
    UPLOAD_FILES_PENDING,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILURE,
    UPLOAD_DOCUMENT_PENDING,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_REDACTION_DOCUMENTS_PENDING,
    UPLOAD_REDACTION_DOCUMENTS_SUCCESS,
    UPLOAD_REDACTION_DOCUMENTS_FAILURE,
} from './constant';
import {    IModalWindowImportState } from './modalWindowImport.model';

const initialState: IModalWindowImportState = {
    importFilesPending: false,
    importDocumentPending: false,
    uploadLoading: false,
    error: null,
};

const modalWindowExportReducer = (state: IModalWindowImportState = initialState,
                                  { type, payload }: AnyAction): IModalWindowImportState => {
    switch (type) {
        case UPLOAD_FILES_PENDING:
            return {
                ...state,
                importFilesPending: true,
                error: null,
            };
        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                importFilesPending: false,
            };
        case UPLOAD_FILES_FAILURE:
            return {
                ...state,
                error: payload,
                importFilesPending: false,
            };
        case UPLOAD_REDACTION_DOCUMENTS_PENDING:
            return {
                ...state,
                importDocumentPending: true,
                error: null,
            };
        case UPLOAD_REDACTION_DOCUMENTS_SUCCESS:
            return {
                ...state,
                importDocumentPending: false,
            };
        case UPLOAD_REDACTION_DOCUMENTS_FAILURE:
            return {
                ...state,
                error: payload,
                importDocumentPending: false,
            };
        case UPLOAD_DOCUMENT_PENDING:
            return {
                ...state,
                uploadLoading: true,
            };
        case UPLOAD_DOCUMENT_SUCCESS:
        case UPLOAD_DOCUMENT_FAILURE:
            return {
                ...state,
                uploadLoading: false,
            };

        default:
            return state;
    }
};

export default modalWindowExportReducer;

import * as React from 'react';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'rc-scrollbars';
import Typography from '@mui/material/Typography';
import { SelectMaterialBuilder } from '../../../../components/materialUiForms/materialUiFormBuilder';
import { ISelectOptions } from '../../../../components/materialUiForms/marerialUiForms.model';
import { IRequestTypes, IDisclosureTypes } from '../../../../redux/reducers/modalGlobalSettings/globalSettings.model';
import { IDisclosureFormFields, IDisclosureSettingsForm } from './disclosureSettingsTab.model';
import { useSelector } from 'react-redux';
import { getRedactionLanguage } from '../../../../redux/selectors/localStorage';

const DisclosureSettingsForm = ({
    disclosureSettings,
    formFields,
    handleSelectChange,
    hasEditPermission,

}: IDisclosureSettingsForm): JSX.Element => {
    const redactionLang = useSelector(getRedactionLanguage)
    const createOptions = (options: IDisclosureTypes[]): ISelectOptions[] =>
        options.map((type: IDisclosureTypes): ISelectOptions => ({ value: `${type.id}`, label: 
        (redactionLang ==='fr' && type.name1) ?type.name1:type.name}));
    return (
        <div className='disclosure-settings-container'>
            <AutoSizer>
                {({ width, height }: { width: number; height: number; }): JSX.Element  => (
                    <div style={{ width, height }}>
                        <div className={'disclosure-settings-container_header'}>
                            {
                                formFields.map((field: IDisclosureFormFields): JSX.Element => (
                                    <div className={field.className} key={field.field}>{field.label}</div>
                                ))
                            }
                        </div>
                        <div className='form-container_content'>
                            <Scrollbars>
                                {
                                    disclosureSettings &&
                                    disclosureSettings.map((disclosure: IRequestTypes): JSX.Element => (
                                        <div
                                            className={'disclosure-settings-container_content'}
                                            key={disclosure.requestType}
                                        >
                                            {
                                                formFields.map((field: IDisclosureFormFields): JSX.Element => {
                                                    const defaultValue = `${disclosure[field.field]}` || '';

                                                    return (
                                                        <div className={field.className} key={field.field}>
                                                            {
                                                                field.type ? (
                                                                    <div className='select-box'>
                                                                        <SelectMaterialBuilder
                                                                            value={defaultValue}
                                                                            paramName={disclosure.requestType}
                                                                            disabled={!hasEditPermission}
                                                                            label={''}
                                                                            options={
                                                                                createOptions(
                                                                                    disclosure.disclosureTypes,
                                                                                )
                                                                            }
                                                                            handleSelectChange={
                                                                                (
                                                                                    requestType: string,
                                                                                    value: string,
                                                                                ): void =>
                                                                                handleSelectChange(
                                                                                    field.field,
                                                                                    requestType,
                                                                                    value,
                                                                                )}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className='ellipsis'>
                                                                        <Typography variant='body1' gutterBottom={true}>
                                                                            {disclosure.requestTypeDescription}
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    ))
                                }
                            </Scrollbars>
                        </div>
                    </div>
                )}
            </AutoSizer>
        </div>
    );
};

export default DisclosureSettingsForm;

import {
    SET_MODIFIED_LABELS_FAILURE,
    SET_MODIFIED_LABELS_PENDING,
    SET_MODIFIED_LABELS_SUCCESS,
    MODIFY_SEARCH_RESULT,
    SET_SEARCH_RESULTS_SUCCESS,
    SET_ORIGINAL_SEARCH_RESULT,
    SET_SEARCH_RESULTS_PENDING,
    SET_SEARCH_RESULTS_FAILURE,
    SET_SELECTED_SYSTEM_MESSAGE,
    UPDATE_SYSTEM_MESSAGES_PENDING,
    UPDATE_SYSTEM_MESSAGES_SUCCESS,
    UPDATE_SYSTEM_MESSAGES_FAILURE,
    SET_AUTH_FLAG,
} from './constants';
import { AnyAction } from 'redux';
import { ILocalizationState, ILabel } from './localization.model';
import { setItem } from '../../../utils/localStorage.util';
import { MODIFIED_LABELS } from '../localStorage/constant';

const initialState: ILocalizationState = {
    searchResult: [],
    originalSearchResult: [],
    modifiedLabels: [],
    modifiedLabelsPending: false,
    searchResultPending: false,
    importSystemMessagesFlag: false,
    selectedSystemMessage: null,
    authFlag: false,
};

const localization = (state: ILocalizationState = initialState, {type, payload}: AnyAction): ILocalizationState => {
    switch (type) {
        case SET_SEARCH_RESULTS_SUCCESS:
            return{
                ...state,
                searchResult: payload,
                originalSearchResult: payload,
                searchResultPending: false,
            };
        case SET_SEARCH_RESULTS_PENDING:
            return{
                ...state,
                searchResultPending: true,
            };
        case SET_SEARCH_RESULTS_FAILURE:
            return{
                ...state,
                searchResult: [],
                originalSearchResult: [],
                searchResultPending: false,
            };
        case SET_MODIFIED_LABELS_PENDING:
            return{
                ...state,
                modifiedLabelsPending: true,
            };
        case SET_MODIFIED_LABELS_SUCCESS:
            setItem(MODIFIED_LABELS, payload);
            return{
                ...state,
                modifiedLabelsPending: false,
                modifiedLabels: payload,
            };
        case SET_MODIFIED_LABELS_FAILURE:
            return{
                ...state,
                modifiedLabels: [],
                modifiedLabelsPending: false,
            };
        case MODIFY_SEARCH_RESULT:
            return{
                ...state,
                searchResult: state.searchResult.map((result: ILabel) =>
                    result.resourceKey === payload.resourceKey ? payload: result),
            };
        case SET_ORIGINAL_SEARCH_RESULT:
            return{
                ...state,
                originalSearchResult: state.searchResult,
            };
        case SET_SELECTED_SYSTEM_MESSAGE:
            return {
                ...state,
                selectedSystemMessage: payload,
            };
        case UPDATE_SYSTEM_MESSAGES_PENDING:
            return {
                ...state,
                searchResultPending: true,
            };
        case UPDATE_SYSTEM_MESSAGES_SUCCESS:
            return {
                ...state,
                searchResult: state.searchResult.map((result: ILabel) =>
                    result.messageKey === payload.messageKey ? payload: result),
                originalSearchResult: state.originalSearchResult.map((result: ILabel) =>
                    result.messageKey === payload.messageKey ? payload: result),
                searchResultPending: false,
            };
        case UPDATE_SYSTEM_MESSAGES_FAILURE:
            return {
                ...state,
                searchResultPending: false,
            };
        case SET_AUTH_FLAG:
            return {
                ...state,
                authFlag: payload,
            };
        default:
            return state;
    }
};

export default localization;

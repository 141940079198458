import { IState } from '../store';
import { createSelector } from 'reselect';
import {
    IModalWindowExportState,
    IContentOptions,
    IExportDoc, IPaginationOptions, IExportDestinationOptions, IExportData, IAttachmentTypeCode,
} from '../reducers/modalWindowExport/modalWindowExport.model';

export const getExportForm = (state: IState): IModalWindowExportState => state.modalWindowExport;

export const getExportDocumentList = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): IExportDoc[] => modalWindowExport.documentsToExport,
);

export const getExportDocumentsSelector = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): IExportData => modalWindowExport.exportDocumentsList,
);

export const getExportLoading = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): boolean => modalWindowExport.exportDocumentPending,
);

export const getExportedSuccess = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): boolean => modalWindowExport.exportedSuccess,
);

export const getExportDocumentsLoading = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): boolean => modalWindowExport.getExportDocumentsPending,
);

export const getInitialExportDocumentList = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): IExportDoc[] => modalWindowExport.initialDocumentsToExport,
);

export const getExportContentOptionsForm = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): IContentOptions => modalWindowExport.contentOptions,
);

export const getExportPaginationOptionsForm = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): IPaginationOptions => modalWindowExport.paginationOptions,
);

export const getExportDestinationOptionsForm = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): IExportDestinationOptions =>
        modalWindowExport.exportDestinationOptions,
);

export const getAnnotationControlsForm = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): number[] => modalWindowExport.annotationControlsId,
);

export const getExportStackId = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): string => modalWindowExport.currentExportStackId,
);

export const getCreateSinglePackageAs = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): boolean  => modalWindowExport.contentOptions.packageAsASingleFile,
);

export const getExportFileNameErrorMessage = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): string  => modalWindowExport.exportFileNameErrorMessage,
);

export const getExportFileName = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): string  => modalWindowExport.exportDestinationOptions.exportFileName,
);

export const getShowArticleStamps = createSelector(
    [getExportContentOptionsForm],
    (contenOption: IContentOptions): boolean  => contenOption.showArticleStamps,
);

export const getAttachmentTypeCode = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): IAttachmentTypeCode  => modalWindowExport.attachmentTypeCode,
);

export const getExportAttachmentDescription = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): string  =>
        modalWindowExport.exportDestinationOptions.attachmentDescription,
);

export const getExportAttachmentDetail = createSelector(
    [getExportForm],
    (modalWindowExport: IModalWindowExportState): string  =>
        modalWindowExport.exportDestinationOptions.attachmentDetail,
);

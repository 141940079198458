import * as React from 'react';
import { ITableColumn } from '../components/table/table.model';

export const SEARCH_REDACTION_DOCUMENTS = 'Search Redaction Documents';
export const SEARCH_FOLDER_ATTACHMENTS = 'Search Folder Attachments';
export const EMPTY_REDACTION_DOCUMENTS_MESSAGE =
    'Your search did not return any results. Please review the search criteria.';

export const FOLDER_TYPE_LABEL = 'Folder Type';
export const REQUEST_NUMBER_LABEL = 'Request Number';
export const REQUEST_YEAR_LABEL = 'Request Year';

export const FOLDER_TYPE = 'folderType';
export const REQUEST_NUMBER = 'requestNumber';
export const REQUEST_YEAR = 'requestYear';

export const SearchDocumentsResultHeaders = (labels: any, langRule: string): ITableColumn[] => [
    {
        id: 'action',
        accessorKey: 'action',
        accessor: 'action',
        header: '',
        minResizeWidth: 1,
        width: 40,
        size:10,
        sortOrder: null
    }, {
        id: 'customFolderNumber',
        accessorKey: 'customFolderNumber',
        accessor: 'customFolderNumber',
        header: labels.requestNumberLabel[langRule],
        minResizeWidth: 1,
        sortOrder: null
    }, {
        id: 'officerAssigned',
        accessorKey: 'officerAssigned',
        accessor: 'officerAssigned',
        header: labels.officerAssignedLabel[langRule],
        minResizeWidth: 1,
        sortOrder: null
    }, {
        id: 'requestSummary',
        accessorKey: 'requestSummary',
        accessor: 'requestSummary',
        header: labels.reqSummaryHeaderLabel[langRule],
        minResizeWidth: 1,
        sortOrder: null
    },
];
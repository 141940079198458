export const DOCUMENT_STACK_TITLE_MODAL = 'Document Stack';
export const DOCUMENT_STACK_MODAL = 'documentsStack';
export const DOCUMENT_STACK_WARNING = 'You cannot perform this action. Please select at least one document.';
export const STACK_MANAGMENT_TITLE_MODAL = 'Stack Management';
export const STACK_MANAGMENT_MODAL = 'stackManagement';
export const STACK_PROPERTIES_TITLE_MODAL = 'Stack Properties';
export const STACK_PROPERTIES_MODAL = 'stackProperties';

export const DESCRIPTION = 'description';
export const STACK_PROPERTY_ID = 'stackPropertyId';
export const STACK_PROPERTY_LABEL = 'Stack Property';
export const NAME = 'name';

export const EMPTY_STACK_ID = 0;

export const emptyStack = {
    id: EMPTY_STACK_ID,
    name: '',
    description: '',
};

export const emptyStackProperty = {
    id: EMPTY_STACK_ID,
    name: '',
    description: '',
};

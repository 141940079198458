import { AnyAction } from 'redux';
import { LocalStorageState } from './localStorage.model';
import {
    SET_PANEL_SIZE,
    INITIAL_BAR,
    DEFAULT_SIDEBAR_WIDTH,
    SET_ANNOTATION_COLOR,
    DELETE_ANNOTATION_COLOR,
    LEFT_PANEL,
    RIGHT_PANEL,
    TOP_LEFT_PANEL,
    TOP_RIGHT_PANEL,
    SET_LAST_USED_PRINTER,
    LAST_USED_PRINTER_KEY,
    INITIAL_LAST_USED_PRINTER,
    SET_DUPLICATES_BOTTOM_PANEL_SIZE,
    DUPLICATES_BOTTOM_PANEL,
    SET_REDUCTION_LID,
    REDUCTION_LID,
    AUTH,
    SET_REDUCTION_AUTH,
    SET_RECENTLY_USED_STAMPS,
    RECENTLY_USED_STAMPS_KEY,
    SET_RECENTLY_USED_EXEMPTIONS,
    RECENTLY_USED_EXEMPTION_KEY,
    REDACTION_LANG,
    SET_REDACTION_LANGUAGE,
    REDACTION_MODE,
    SET_REDACTION_MODE,
} from './constant';
import { ANNOTATION_COLOR } from '../annotationTypes/constants';
import { getItem, setItem, clearItemValue, getSessionItem, setSessionItem } from '../../../utils/localStorage.util';

const left = getItem(LEFT_PANEL);
const right = getItem(RIGHT_PANEL);
const topLeft = getItem(TOP_LEFT_PANEL);
const topRight = getItem(TOP_RIGHT_PANEL);
const duplicatesBottomPanel = getItem(DUPLICATES_BOTTOM_PANEL);
const annotationTypeColor = getItem(ANNOTATION_COLOR);
const lastUsedPrinter = JSON.parse(getItem(LAST_USED_PRINTER_KEY));
const lid = getItem(REDUCTION_LID);
const auth = getItem(AUTH);
const language = getSessionItem(REDACTION_LANG);
const mode = getItem(REDACTION_MODE);

export const initialState: LocalStorageState = {
    panelSizes: {
        leftPanel: left ? left : INITIAL_BAR,
        rightPanel: right ? right : INITIAL_BAR,
        topLeftPanel: topLeft ? topLeft : INITIAL_BAR,
        topRightPanel: topRight ? topRight : INITIAL_BAR,
    },
    duplicatePanelSizes: {
      bottom: duplicatesBottomPanel || DEFAULT_SIDEBAR_WIDTH,
    },
    annotations: {
        color: annotationTypeColor,
    },
    lastUsedPrinter: lastUsedPrinter ? lastUsedPrinter : INITIAL_LAST_USED_PRINTER,
    lastUsedStamps: [],
    lid,
    auth,
    lastUsedExemptions: [],
    language,
    mode,
};

const localStorageReducer = (state: LocalStorageState = initialState,
                             {type, payload}: AnyAction): LocalStorageState => {
    switch (type) {
        case SET_PANEL_SIZE:
            return {
                ...state,
                panelSizes: {
                    ...state.panelSizes,
                    ...payload,
                },
            };
        case SET_DUPLICATES_BOTTOM_PANEL_SIZE:
            return {
                ...state,
                duplicatePanelSizes: {
                    ...state.duplicatePanelSizes,
                    bottom: payload,
                },
            };
        case SET_ANNOTATION_COLOR:
            setItem(ANNOTATION_COLOR, payload);

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    color: payload,
                },
            };
        case DELETE_ANNOTATION_COLOR:
            setItem(ANNOTATION_COLOR, '');

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    color: '',
                },
            };
        case SET_LAST_USED_PRINTER:
            setItem(LAST_USED_PRINTER_KEY, JSON.stringify(payload));

            return {
                ...state,
                lastUsedPrinter: payload,
            };
        case SET_RECENTLY_USED_STAMPS:
            setItem(RECENTLY_USED_STAMPS_KEY + payload.requestNumber, payload.recentlyUsedStamp);

            return {
                ...state,
                lastUsedStamps: payload.recentlyUsedStamp,
            };
        case SET_REDUCTION_LID:
            setItem(REDUCTION_LID, payload);

            if(lid !== payload) {
                clearItemValue(RECENTLY_USED_EXEMPTION_KEY);
                clearItemValue(RECENTLY_USED_STAMPS_KEY);
            }

            return {
                ...state,
                lid: payload,
            };
        case SET_REDACTION_LANGUAGE:
            setSessionItem(REDACTION_LANG, payload);

            return {
                ...state,
                language: payload,
            };
        case SET_REDACTION_MODE:
                setItem(REDACTION_MODE, payload);

            return {
                ...state,
                mode: payload,
            };
        case SET_REDUCTION_AUTH:
            setItem(AUTH, null);
            setItem(AUTH, payload);

            return {
                ...state,
                auth: payload,
            };
        case SET_RECENTLY_USED_EXEMPTIONS:
            setItem(RECENTLY_USED_EXEMPTION_KEY + payload.requestNumber, payload.exemptions);

            return {
                ...state,
                lastUsedExemptions: payload.exemptions,
            };
        default:
            return state;
    }
};

export default localStorageReducer;

import {
    GET_PAGE_METADATA_PENDING,
    GET_PAGE_METADATA_SUCCESS,
    GET_PAGE_METADATA_FAILURE,
    ADD_PAGE_METADATA_SUCCESS,
    ADD_PAGE_METADATA_PENDING,
    ADD_PAGE_METADATA_FAILURE,
    UPDATE_PAGE_METADATA_PENDING,
    UPDATE_PAGE_METADATA_SUCCESS,
    UPDATE_PAGE_METADATA_FAILURE,
    GET_PAGE_CONSULTEES_FAILURE,
    GET_PAGE_CONSULTEES_PENDING,
    GET_PAGE_CONSULTEES_SUCCESS,
    CLEAR_PAGE_CONSULTEES,
    DELETE_PAGE_CONSULTEES_SUCCESS,
    CLEAR_PAGE_METADATA,
} from './constants';
import { IConsultees, IConsulteesPages, IPageMetadataState, IPageMetadataType } from './notes.model';
import { AnyAction } from 'redux';
import { uniqBy } from 'lodash';

const initialState: IPageMetadataState = {
    notes: null,
    comments: null,
    recommendations: null,
    pageMetadataPending: false,
    error: null,
    pagesConsultees: [],
    pageConsulteesPending: false,
};

const pageMetadata = (state: IPageMetadataState = initialState, { type, payload }: AnyAction): IPageMetadataState => {
    switch (type) {
        case ADD_PAGE_METADATA_PENDING:
        case GET_PAGE_METADATA_PENDING:
        case UPDATE_PAGE_METADATA_PENDING:
            return {
                ...state,
                pageMetadataPending: true,
            };
        case UPDATE_PAGE_METADATA_SUCCESS:
        case ADD_PAGE_METADATA_SUCCESS:
            return payload ? {
                ...state,
                pageMetadataPending: false,
                error: null,
                [payload.dataType]: payload,
            }: {
                ...state,
                pageMetadataPending: false,
                error: null,
                notes: null,
                recommendations: null,
                comments: null,
            };
        case GET_PAGE_METADATA_SUCCESS:
            const { notes, comments, recommendations } = payload;

            return {
                ...state,
                pageMetadataPending: false,
                notes,
                comments,
                recommendations,
                error: null,
            };
        case ADD_PAGE_METADATA_FAILURE:
        case GET_PAGE_METADATA_FAILURE:
        case UPDATE_PAGE_METADATA_FAILURE:
            return {
                ...state,
                pageMetadataPending: false,
                error: payload,
            };
        case GET_PAGE_CONSULTEES_PENDING:
            return {
                ...state,
                pageConsulteesPending: true,
            };
        case GET_PAGE_CONSULTEES_SUCCESS:
            return {
                ...state,
                pageConsulteesPending: false,
                pagesConsultees: state.pagesConsultees.length
                    ? uniqBy([payload, ...state.pagesConsultees], 'id')
                    : [payload],
                error: null,
            };
        case GET_PAGE_CONSULTEES_FAILURE:
            return {
                ...state,
                pageConsulteesPending: false,
                error: payload,
            };
        case CLEAR_PAGE_CONSULTEES:
            return {
                ...state,
                pagesConsultees: [],
            };
        case DELETE_PAGE_CONSULTEES_SUCCESS:
            return {
                ...state,
                pagesConsultees: state.pagesConsultees.map((pageConsultee: IConsulteesPages) => {
                    return pageConsultee.id !== payload.pageId
                        ? pageConsultee
                        : {
                            ...pageConsultee,
                            consultees: pageConsultee.consultees
                                .filter((item: IConsultees) => item.peopleRsn !== payload.consulteeId),
                        };
                }),
            };
        case CLEAR_PAGE_METADATA:
            return {
                ...state,
                pageMetadataPending: false,
                error: null,
                notes: null,
                recommendations: null,
                comments: null,
            };
        default:
            return state;
    }
};

export default pageMetadata;

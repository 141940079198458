export const REQUIRED = 'isRequired';
export const UNIQUE = 'isUniqueText';
export const AT_LEAST_ONE_OF = 'isAtLeastOneOf';
export const MAX_LENGTH = 'maxLength';
export const MIN_LENGTH = 'minLength';

export const MUST_BE_UNIQUE = 'Must be unique';
export const AT_LEAST_ONE_OF_LIST = 'At least one of list';
export const FIELD_IS_MANDATORY_TO_FILL_IN = 'Field is mandatory to fill in';
export const MAX_LENGTH_LABEL = 'Max length';
export const MIN_LENGTH_LABEL = 'Min length';
export const ANY_ONE_FIELD_REQUIRED = '*Please select any one of the following fields for search : FolderRSN, RequestType, RequestNumber.';

import { action } from 'typesafe-actions';
import { AnyAction, Dispatch } from 'redux';
import api from '../../api/reductionApi';
import { IState } from '../store';
import { IError } from '../common.model';
import {
    IDuplicatesCurrentGroup,
    IDuplicatesSearchIds,
    IDuplicateDocument,
    IDuplicateMetadata, IReferenceDuplicate, IReferenceMaster, IReferencePage, IReferenceManagement,
} from '../reducers/duplicates/duplicates.model';
import {
    GET_DUPLICATES_CURRENT_GROUP_FAILURE,
    GET_DUPLICATES_CURRENT_GROUP_SUCCESS,
    GET_DUPLICATES_CURRENT_GROUP_PENDING,
    GET_DUPLICATES_RESOURCE_FAILURE,
    GET_DUPLICATES_RESOURCE_SUCCESS,
    GET_DUPLICATES_RESOURCE_PENDING,
    SET_DUPLICATES_MAX_COUNT,
    CLOSE_SELECTED_DUPLICATE,
    CLEAR_DUPLICATE_GROUP,
    UPDATE_DUPLICATE_RESOURCE,
    UPDATE_ALLOW_PAGES_NAVIGATION,
    SET_DUPLICATE_SCALE,
    SET_DUPLICATE_CLIENT_SIZE,
    SET_DUPLICATE_SEARCH_PENDING,
    CLEAR_DUPLICATES_RESOURCE,
    SET_MASTER_DOCUMENTS,
    SET_REFERENCE_PAGES,
    SET_REFERENCE_DUPLICATES,
    CLEAN_REFERENCE_MANAGEMENT_DATA,
    GET_DUPLICATE_REFERENCES,
    GET_MODAL_DUPLICATE_REF_DATA_PENDING,
    GET_MODAL_DUPLICATE_REF_DATA_SUCCESS,
    GET_MODAL_DUPLICATE_REF_DATA_FAILURE, CLEAR_DUPLICATE_REFERENCES,
} from '../reducers/duplicates/constants';
import {
    ALERT_DIALOG_MODAL,
    WARNING,
} from '../../constants/messages.constants';
import { handleCloseAllModalWindows, handleCloseModalWindow, openModalWindow } from './modal';
import { IClientSize } from '../../containers/redactor/redactor.model';
import { GoToPage } from '../../components/navigation/Navigation';
import { DUPLICATE_REFERENCES } from '../../constants/modal.constants';
import { IDuplicateReferences, IDuplicatesRefData } from '../../containers/duplicatePanels/duplicate.model';
import { IModalSpecificProps } from '../reducers/modal/modal.model';
import { ALERT_MESSAGE } from '../../constants/duplicates.constants';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { addError } from './errorHandling';
import { fill } from 'lodash';
import { MAX_PAGE_ITEMS } from '../../containers/modalWindowContents/modalReference/Reference';
import { IPages } from '../reducers/pageList/pageList.model';

export const fetchDuplicatesCurrentGroupPending = (): AnyAction => action(GET_DUPLICATES_CURRENT_GROUP_PENDING);
export const fetchDuplicatesCurrentGroupSuccess = (data: IDuplicatesCurrentGroup, isCriteriaSearch: boolean):
    AnyAction => action(GET_DUPLICATES_CURRENT_GROUP_SUCCESS, { data, isCriteriaSearch });
export const fetchDuplicatesCurrentGroupFailure = (error: IError): AnyAction =>
    action(GET_DUPLICATES_CURRENT_GROUP_FAILURE, error);
export const fetchDuplicatesResourcePending = (): AnyAction => action(GET_DUPLICATES_RESOURCE_PENDING);
export const fetchDuplicatesResourceSuccess = (data: IDuplicateDocument): AnyAction =>
    action(GET_DUPLICATES_RESOURCE_SUCCESS, data);
export const fetchDuplicatesResourceFailure = (error: IError): AnyAction =>
    action(GET_DUPLICATES_RESOURCE_FAILURE, error);
export const updateAllowPagesNavigation = (back: boolean, forward: boolean): AnyAction =>
    action(UPDATE_ALLOW_PAGES_NAVIGATION, { back, forward });

export const clearDuplicateGroup = (): AnyAction => action(CLEAR_DUPLICATE_GROUP);
export const clearDuplicatesResource = (): AnyAction => action(CLEAR_DUPLICATES_RESOURCE);
export const setSearchPending = (searchPending: boolean): AnyAction =>
    action(SET_DUPLICATE_SEARCH_PENDING, searchPending);

export const getDuplicateReferences = (data: IDuplicateReferences): AnyAction =>
    action(GET_DUPLICATE_REFERENCES, data);
export const clearDuplicateReferences = (): AnyAction => action(CLEAR_DUPLICATE_REFERENCES);

export const getModalDuplicateRefDataPending = (): AnyAction =>
    action(GET_MODAL_DUPLICATE_REF_DATA_PENDING);
export const getModalDuplicateRefDataSuccess = (data: IDuplicatesRefData): AnyAction =>
    action(GET_MODAL_DUPLICATE_REF_DATA_SUCCESS, data);
export const getModalDuplicateRefDataFailure = (error: IError): AnyAction =>
    action(GET_MODAL_DUPLICATE_REF_DATA_FAILURE, error);

export const fetchDuplicatesCurrentGroup = (ids: IDuplicatesSearchIds, pageNumber?: number):
    (dispatch: Dispatch, getState: () => IState) => Promise<void> => async (
    dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const { reductionMetaData: { redactionDocumentId } } = getState();

    dispatch(fetchDuplicatesCurrentGroupPending());

    try {
        const response = await api.duplicatesController.searchDuplicates(redactionDocumentId, ids, pageNumber);

        dispatch(fetchDuplicatesCurrentGroupSuccess(response, true));
    } catch (error) {
        dispatch(fetchDuplicatesCurrentGroupFailure(error));
    }
};

export const fetchDuplicatesResource = (
    documentId: number,
    pageId: number,
    duplicatesResources: IDuplicateDocument[],
): (dispatch: Dispatch, getState: () => IState) => Promise<void> => async (
    dispatch: Dispatch, getState: () => IState,
): Promise<void> => {
    const {
        reductionMetaData: { redactionDocumentId },
        duplicatesReducer: { duplicatesCurrentGroup, maxDuplicatesCount },
    } = getState();
    const documentMetaData = duplicatesCurrentGroup.duplicatesGroup.find((metaData: IDuplicateMetadata): boolean =>
        metaData.documentId === documentId);

    if (duplicatesResources && duplicatesResources.length === maxDuplicatesCount) {
        dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
            id: ALERT_DIALOG_MODAL,
            title: WARNING,
            message: `You can view maximum ${maxDuplicatesCount}
            documents at the same time. Please, close some of the selected documents.`,
            confirm: (): AnyAction => handleCloseAllModalWindows(),
        }));

        return;
    }

    dispatch(fetchDuplicatesResourcePending());

    try {
        const pdfSrc = await api.pages.getPageResource(redactionDocumentId, documentId, pageId);

        dispatch(fetchDuplicatesResourceSuccess({
            pdfSrc,
            currentPageId: pageId,
            scale: 1,
            size: documentMetaData.fileSize,
            pagesAmount: documentMetaData.pageCount,
            documentId,
            documentName: documentMetaData.documentName,
            currentPageNumber: documentMetaData.start || 1,
        }));

        const { duplicatesReducer: { duplicatesResources: selectedPages } } = getState();

        const back = selectedPages.some((item: IDuplicateDocument) => item.currentPageNumber !== 1);
        const forward = selectedPages
            .some((item: IDuplicateDocument) => item.currentPageNumber !== item.pagesAmount);

        dispatch(updateAllowPagesNavigation(back, forward));
    } catch (error) {
        dispatch(fetchDuplicatesResourceFailure(error));
    }
};

export const setMaxDuplicatesCount = (count: number): AnyAction => action(SET_DUPLICATES_MAX_COUNT, count);
export const closeSelectedDuplicate = (index: number): AnyAction => action(CLOSE_SELECTED_DUPLICATE, index);
export const setDuplicateScale = (documentId: number, pageId: number, scale: number): AnyAction =>
    action(SET_DUPLICATE_SCALE, { documentId, pageId, scale });
export const setDuplicateClientSize = (documentId: number, pageId: number, clientSize: IClientSize): AnyAction =>
    action(SET_DUPLICATE_CLIENT_SIZE, { documentId, pageId, clientSize });

export const handleDuplicatesSearch = (pageNumber?: number):
    (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            reductionMetaData: { redactionDocumentId },
        } = getState();

        dispatch(setSearchPending(true));
        try {
            const response = await api.duplicatesController.search(redactionDocumentId, pageNumber);

            dispatch(fetchDuplicatesCurrentGroupSuccess(response, false));
        } catch (error) {
            dispatch(fetchDuplicatesCurrentGroupFailure(error));
        } finally {
            dispatch(setSearchPending(false));
        }
    };

export const updateDuplicateResource =
    (documentId: number, currentPageId: number, currentPageNumber: number, pdfSrc: string): AnyAction =>
        action(UPDATE_DUPLICATE_RESOURCE, { documentId, currentPageId, currentPageNumber, pdfSrc });

export const goToPageDuplicates = (page: string): (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const {
            duplicatesReducer: {
                duplicatesResources,
            },
            reductionMetaData: {
                redactionDocumentId,
            },
        } = getState();

        try {
            if (page === GoToPage.NEXT) {
                duplicatesResources.forEach(async (item: IDuplicateDocument) => {
                    if (item.currentPageNumber !== item.pagesAmount) {
                        const nextPage = item.currentPageNumber + 1;
                        const response = await api.pages
                            .getPageResourceByActualNumber(redactionDocumentId, item.documentId, nextPage);

                        dispatch(updateDuplicateResource(item.documentId, item.currentPageId, nextPage, response));
                    }
                });
            }

            if (page === GoToPage.PREV) {
                duplicatesResources.forEach(async (item: IDuplicateDocument) => {
                    if (item.currentPageNumber !== 1) {
                        const prevPage = item.currentPageNumber - 1;
                        const response = await api.pages
                            .getPageResourceByActualNumber(redactionDocumentId, item.documentId, prevPage);

                        dispatch(updateDuplicateResource(item.documentId, item.currentPageId, prevPage, response));
                    }
                });
            }

            if (page === GoToPage.FIRST) {
                duplicatesResources.forEach(async (item: IDuplicateDocument) => {
                    if (item.currentPageNumber !== 1) {
                        const response = await api.pages
                            .getPageResourceByActualNumber(redactionDocumentId, item.documentId, 1);

                        dispatch(updateDuplicateResource(item.documentId, item.currentPageId, 1, response));
                    }
                });
            }

            if (page === GoToPage.LAST) {
                duplicatesResources.forEach(async (item: IDuplicateDocument) => {
                    if (item.currentPageNumber !== item.pagesAmount) {
                        const response = await api.pages
                            .getPageResourceByActualNumber(redactionDocumentId, item.documentId, item.pagesAmount);

                        dispatch(updateDuplicateResource(
                            item.documentId,
                            item.currentPageId,
                            item.pagesAmount,
                            response,
                        ));
                    }
                });
            }

        } catch (error) {
            dispatch(fetchDuplicatesResourceFailure(error));
        }
    };

export const goToDocumentPage = (documentId: number, page: string): (dispatch: Dispatch, getState: () => IState) =>
    Promise<void> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const {
        duplicatesReducer: {
            duplicatesResources,
        },
        reductionMetaData: {
            redactionDocumentId,
        },
    } = getState();
    const currentDocument = duplicatesResources
        .find((item: IDuplicateDocument) => item.documentId === documentId);

    try {
        if (page === GoToPage.NEXT) {
            if (currentDocument.currentPageNumber !== currentDocument.pagesAmount) {
                const nextPage = currentDocument.currentPageNumber + 1;
                const response = await api.pages
                    .getPageResourceByActualNumber(redactionDocumentId, currentDocument.documentId, nextPage);

                dispatch(updateDuplicateResource(currentDocument.documentId, currentDocument.currentPageId,
                    nextPage, response));
            }
        }

        if (page === GoToPage.PREV) {
            if (currentDocument.currentPageNumber !== 1) {
                const prevPage = currentDocument.currentPageNumber - 1;
                const response = await api.pages
                    .getPageResourceByActualNumber(redactionDocumentId, currentDocument.documentId, prevPage);

                dispatch(updateDuplicateResource(currentDocument.documentId,
                    currentDocument.currentPageId, prevPage, response));
            }
        }

        if (page === GoToPage.FIRST) {
            if (currentDocument.currentPageNumber !== 1) {
                const response = await api.pages
                    .getPageResourceByActualNumber(redactionDocumentId, currentDocument.documentId, 1);

                dispatch(updateDuplicateResource(currentDocument.documentId, currentDocument.currentPageId, 1,
                    response));
            }
        }

        if (page === GoToPage.LAST) {
            if (currentDocument.currentPageNumber !== currentDocument.pagesAmount) {
                const response = await api.pages
                    .getPageResourceByActualNumber(redactionDocumentId, currentDocument.documentId,
                        currentDocument.pagesAmount);

                dispatch(updateDuplicateResource(currentDocument.documentId, currentDocument.currentPageId,
                    currentDocument.pagesAmount, response));
            }
        }

    } catch (error) {
        dispatch(fetchDuplicatesResourceFailure(error));
    }
};

export const openDuplicateReferences = (params: IModalSpecificProps): (
    dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState,
) => void => (
    dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState,
): void => {
    const { currentDocId, pageIds } = params;
    const { pageList: { currentDocument: {pages}} } = getState();
    const actualPageNumber = pages.find(({id}: IPages) => id === pageIds[0]).actualPageNumber;

    if (pageIds.length > 1) {
        dispatch(openModalWindow(ALERT_DIALOG_MODAL, {
            id: ALERT_DIALOG_MODAL,
            title: WARNING,
            message: ALERT_MESSAGE,
            confirm: (): AnyAction => handleCloseModalWindow(ALERT_DIALOG_MODAL),
        }));
    } else {
        dispatch(fetchPageDuplicateReferences(currentDocId, pageIds[0], actualPageNumber));
    }
};

export const fetchDuplicateReferences = (documentId: number): (dispatch: Dispatch, getState: () => IState) =>
    Promise<void> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const { reductionMetaData: { redactionDocumentId } } = getState();

    const response = await api.duplicateReferenceController.getDuplicateReferences(redactionDocumentId, documentId);

    dispatch(getDuplicateReferences(response));
};

export const fetchPageDuplicateReferences = (documentId: number, pageId: number, actualPageNumber: number):
    (dispatch: Dispatch, getState: () => IState) => Promise<void> =>
    async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
        const { reductionMetaData: { redactionDocumentId } } = getState();

        dispatch(getModalDuplicateRefDataPending());

        dispatch(openModalWindow(DUPLICATE_REFERENCES, {
            id: DUPLICATE_REFERENCES,
        }));

        const response = await api.duplicateReferenceController
            .getPageDuplicateReferences(redactionDocumentId, documentId, pageId, actualPageNumber);

        try {
            dispatch(getModalDuplicateRefDataSuccess(response));
        } catch (error) {
            dispatch(getModalDuplicateRefDataFailure(error));
        }
    };

export const setMasterDocuments = (masterDocuments: IReferenceMaster[]): AnyAction =>
    action(SET_MASTER_DOCUMENTS, masterDocuments);
export const setReferencePages = (pages: IReferencePage[]): AnyAction => action(SET_REFERENCE_PAGES, pages);

export const fetchDocuments = (): (dispatch: Dispatch, getState: () => IState) =>
    Promise<void> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const {
        reductionMetaData: { redactionDocumentId },
    } = getState();

    try {
        const response = await api.masterDocumentController.getMasterDocuments(redactionDocumentId);

        dispatch(setMasterDocuments(response));
    } catch (error) {
        dispatch(addError(error));
    }
};

export const fetchReferencePagesById = (id: number): (dispatch: Dispatch, getState: () => IState) =>
    Promise<void> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const {
        reductionMetaData: { redactionDocumentId },
    } = getState();

    try {
        const response = await api.masterPageController.getMasterPages(redactionDocumentId, id);
        const emptyPage = {
            id: null,
            masterDocumentId: null,
            pageDisclosureTypeName: 'Loading...',
            actualPageNumber: null,
            pageId: null,
            duplicatePages: [],
        };
        const restPages = fill(Array(response.totalElements - response.masterPageList.length), emptyPage);

        dispatch(setReferencePages([...response.masterPageList, ...restPages]));
    } catch (error) {
        dispatch(addError(error));
    }
};

export const fetchReferencePageNumber = (masterDocumentId: number, pageNumber: number):
    (dispatch: Dispatch, getState: () => IState) =>
        Promise<void> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const {
        reductionMetaData: {
            redactionDocumentId,
        },
        duplicatesReducer: {
            referenceManagement: {
                pages,
            },
        },
    } = getState();

    try {
        const itemLAlreadyExists = pages[MAX_PAGE_ITEMS * pageNumber].id;

        if (!itemLAlreadyExists) {
            const { masterPageList } = await api.masterPageController
                .getMasterPages(redactionDocumentId, masterDocumentId, pageNumber);
            const updatedPages = [
                ...pages.slice(0, MAX_PAGE_ITEMS * pageNumber),
                ...masterPageList,
                ...pages.slice(MAX_PAGE_ITEMS * (pageNumber + 1)),
            ];

            dispatch(setReferencePages(updatedPages));
        }
    } catch (error) {
        dispatch(addError(error));
    }
};

export const setReferenceDuplicates = (duplicates: IReferenceDuplicate[]): AnyAction =>
    action(SET_REFERENCE_DUPLICATES, duplicates);
export const cleanReferenceManagementData = (): AnyAction => action(CLEAN_REFERENCE_MANAGEMENT_DATA);

export const removeDuplicateReference = (masterDocumentId: number, masterPageId: number, duplicateId: number):
    (dispatch: Dispatch, getState: () => IState) =>
        Promise<void> => async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const {
        reductionMetaData: {
            redactionDocumentId,
        },
        duplicatesReducer: {
            referenceManagement: {
                duplicates,
                pages,
            },
        },
    } = getState();

    try {
        await api.duplicatesController
            .removeDuplicate(redactionDocumentId, masterDocumentId, masterPageId, duplicateId);

        const filteredDuplicates = duplicates.filter((item: IReferenceDuplicate) => item.id !== duplicateId);
        const filteredPages = pages.map((item: IReferencePage) => {
            return item.id !== masterPageId
                ? item
                : {
                    ...item,
                    duplicatePages: item.duplicatePages
                        .filter((duplicate: IReferenceDuplicate) => duplicate.id !== duplicateId),
                };
        });

        dispatch(setReferencePages(filteredPages));
        dispatch(setReferenceDuplicates(filteredDuplicates));
    } catch (error) {
        dispatch(addError(error));
    }
};

export const removePageReference = (masterDocumentId: number, masterPageId: number):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
    const {
        reductionMetaData: {
            redactionDocumentId,
        },
        duplicatesReducer: {
            referenceManagement: {
                pages,
            },
        },
    } = getState();

    try {
        await api.masterPageController.removeMasterPage(redactionDocumentId, masterDocumentId, masterPageId);

        const filteredPages = pages.filter((item: IReferencePage) => item.id !== masterPageId);

        dispatch(setReferencePages(filteredPages));

        if (!filteredPages.length) {
            dispatch(removeMasterReference(masterDocumentId));
        }
    } catch (error) {
        dispatch(addError(error));
    }
};

export const removeMasterReference = (masterDocumentId: number):
    (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState) => Promise<void> =>
    async (dispatch: ThunkDispatch<IState, null, AnyAction>, getState: () => IState): Promise<void> => {
    const {
        reductionMetaData: {
            redactionDocumentId,
        },
        duplicatesReducer: {
            referenceManagement: {
                masterDocuments,
            },
            duplicateReferences,
        },
    } = getState();

    try {
        await api.masterDocumentController.removeMasterDocument(redactionDocumentId, masterDocumentId);

        const filteredDocuments = masterDocuments.filter((item: IReferenceMaster) => item.id !== masterDocumentId);

        dispatch(setMasterDocuments(filteredDocuments));
        dispatch(clearDuplicateReferences());
        duplicateReferences.forEach((duplicate: IDuplicateReferences) => {
            dispatch(fetchDuplicateReferences(duplicate.documentId));
        });
    } catch (error) {
        dispatch(addError(error));
    }
};

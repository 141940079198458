export const ADD_PACKAGE_PRESET_SUCCESS = 'reduction/ADD_PACKAGE_PRESET_SUCCESS';
export const ADD_PACKAGE_PRESET_FAILURE = 'reduction/ADD_PACKAGE_PRESET_FAILURE';
export const GET_ALL_PACKAGE_PRESETS_SUCCESS = 'reduction/GET_ALL_PACKAGE_PRESETS_SUCCESS';
export const GET_ALL_PACKAGE_PRESETS_FAILURE = 'reduction/GET_ALL_PACKAGE_PRESETS_FAILURE';
export const DELETE_PACKAGE_PRESET_SUCCESS = 'reduction/DELETE_PACKAGE_PRESET_SUCCESS';
export const DELETE_PACKAGE_PRESET_FAILURE = 'reduction/DELETE_PACKAGE_PRESET_FAILURE';
export const SET_PRESET_EXPORT_OPTIONS = 'reduction/SET_PRESET_EXPORT_OPTIONS';
export const SET_PRESET_PAGINATION_OPTIONS = 'reduction/SET_PRESET_PAGINATION_OPTIONS';
export const SET_PACKAGE_ANNOTATION_TYPE = 'reduction/SET_PACKAGE_ANNOTATION_TYPE';
export const REMOVE_PACKAGE_ANNOTATION_TYPE = 'reduction/REMOVE_PACKAGE_ANNOTATION_TYPE';
export const SET_SELECTED_PRESET = 'reduction/SET_SELECTED_PRESET';
export const SET_DEFAULT_PRESET = 'reduction/SET_DEFAULT_PRESET';
export const SET_SELECTED_PRESET_ID = 'reduction/SET_SELECTED_PRESET_ID';
export const EDIT_PACKAGE_PRESET_SUCCESS = 'reduction/EDIT_PACKAGE_PRESET_SUCCESS';
export const EDIT_PACKAGE_PRESET_FAILURE = 'reduction/EDIT_PACKAGE_PRESET_FAILURE';

export const GET_STACK_LIST_PENDING = 'reduction/GET_STACK_LIST_PENDING';
export const GET_STACK_LIST_SUCCESS = 'reduction/GET_STACK_LIST_SUCCESS';
export const GET_STACK_LIST_FAILURE = 'reduction/GET_STACK_LIST_FAILURE';

export const DELETE_STACK_PENDING = 'reduction/DELETE_STACK_PENDING';
export const DELETE_STACK_SUCCESS = 'reduction/DELETE_STACK_SUCCESS';
export const DELETE_STACK_FAILURE = 'reduction/DELETE_STACK_FAILURE';

export const POST_STACK_PENDING = 'reduction/POST_STACK_PENDING';
export const POST_STACK_SUCCESS = 'reduction/POST_STACK_SUCCESS';
export const POST_STACK_FAILURE = 'reduction/POST_STACK_FAILURE';

export const PUT_STACK_PENDING = 'reduction/PUT_STACK_PENDING';
export const PUT_STACK_SUCCESS = 'reduction/PUT_STACK_SUCCESS';
export const PUT_STACK_FAILURE = 'reduction/PUT_STACK_FAILURE';

export const CHANGE_OPTIONS_STACK = 'reduction/CHANGE_OPTIONS_STACK';
export const SET_OPTIONS_STACK = 'reduction/SET_OPTIONS_STACK';
export const CLEAR_OPTIONS_STACK = 'reduction/CLEAR_OPTIONS_STACK';

export const GET_STACK_PROPERTY_LIST_PENDING = 'reduction/GET_STACK_PROPERTY_LIST_PENDING';
export const GET_STACK_PROPERTY_LIST_SUCCESS = 'reduction/GET_STACK_PROPERTY_LIST_SUCCESS';
export const GET_STACK_PROPERTY_LIST_FAILURE = 'reduction/GET_STACK_PROPERTY_LIST_FAILURE';

export const DELETE_STACK_PROPERTY_PENDING = 'reduction/DELETE_STACK_PROPERTY_PENDING';
export const DELETE_STACK_PROPERTY_SUCCESS = 'reduction/DELETE_STACK_PROPERTY_SUCCESS';
export const DELETE_STACK_PROPERTY_FAILURE = 'reduction/DELETE_STACK_PROPERTY_FAILURE';

export const POST_STACK_PROPERTY_PENDING = 'reduction/POST_STACK_PROPERTY_PENDING';
export const POST_STACK_PROPERTY_SUCCESS = 'reduction/POST_STACK_PROPERTY_SUCCESS';
export const POST_STACK_PROPERTY_FAILURE = 'reduction/POST_STACK_PROPERTY_FAILURE';

export const PUT_STACK_PROPERTY_PENDING = 'reduction/PUT_STACK_PROPERTY_PENDING';
export const PUT_STACK_PROPERTY_SUCCESS = 'reduction/PUT_STACK_PROPERTY_SUCCESS';
export const PUT_STACK_PROPERTY_FAILURE = 'reduction/PUT_STACK_PROPERTY_FAILURE';

export const UPDATE_STACK_DOCUMENT_RELATION_PENDING = 'reduction/UPDATE_STACK_DOCUMENT_RELATION_PENDING';
export const UPDATE_STACK_DOCUMENT_RELATION_SUCCESS = 'reduction/UPDATE_STACK_DOCUMENT_RELATION_SUCCESS';
export const UPDATE_STACK_DOCUMENT_RELATION_FAILURE = 'reduction/UPDATE_STACK_DOCUMENT_RELATION_FAILURE';

export const CHANGE_OPTIONS_STACK_PROPERTY = 'reduction/CHANGE_OPTIONS_STACK_PROPERTY';
export const SET_OPTIONS_STACK_PROPERTY = 'reduction/SET_OPTIONS_STACK_PROPERTY';
export const CLEAR_OPTIONS_STACK_PROPERTY = 'reduction/CLEAR_OPTIONS_STACK_PROPERTY';

export const GET_STAMP_PENDING = 'reduction/GET_STAMP_PENDING';
export const GET_STAMP_SUCCESS = 'reduction/GET_STAMP_SUCCESS';
export const GET_STAMP_FAILURE = 'reduction/GET_STAMP_FAILURE';

export const POST_STAMP_PENDING = 'reduction/POST_STAMP_PENDING';
export const POST_STAMP_SUCCESS = 'reduction/POST_STAMP_SUCCESS';
export const POST_STAMP_FAILURE = 'reduction/POST_STAMP_FAILURE';

export const PUT_STAMP_PENDING = 'reduction/PUT_STAMP_PENDING';
export const PUT_STAMP_SUCCESS = 'reduction/PUT_STAMP_SUCCESS';
export const PUT_STAMP_FAILURE = 'reduction/PUT_STAMP_FAILURE';

export const DELETE_STAMP_PENDING = 'reduction/DELETE_STAMP_PENDING';
export const DELETE_STAMP_SUCCESS = 'reduction/DELETE_STAMP_SUCCESS';
export const DELETE_STAMP_FAILURE = 'reduction/DELETE_STAMP_FAILURE';

export const SET_SELECTED_CUSTOM_STAMPS = 'reduction/SET_SELECTED_CUSTOM_STAMPS';
export const SET_SELECTED_ARTICLE_STAMPS = 'reduction/SET_SELECTED_ARTICLE_STAMPS';

export const CHANGE_LOCAL_STAMPS = 'reduction/CHANGE_LOCAL_STAMPS';

export const GET_ANNOTATION_TYPE_PENDING = 'reduction/GET_ANNOTATION_TYPE_PENDING';
export const GET_ANNOTATION_TYPE_SUCCESS = 'reduction/GET_ANNOTATION_TYPE_SUCCESS';
export const GET_ANNOTATION_TYPE_FAILURE = 'reduction/GET_ANNOTATION_TYPE_FAILURE';

export const GET_ARTICLE_STAMP_PENDING = 'reduction/GET_ARTICLE_STAMP_PENDING';
export const GET_ARTICLE_STAMP_SUCCESS = 'reduction/GET_ARTICLE_STAMP_SUCCESS';
export const GET_ARTICLE_STAMP_FAILURE = 'reduction/GET_ARTICLE_STAMP_FAILURE';

export const SET_SINGLE_RECENTLY_USED_STAMP = 'reduction/SET_SINGLE_RECENTLY_USED_STAMP';

export const SET_INTERACTIVE_MODE = 'reduction/SET_INTERACTIVE_MODE';

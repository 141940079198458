import * as React from 'react';
import { Vector2d } from 'konva/types/types';
import { IShapeProps } from './shapesLayer.model';
import { Rect } from 'react-konva';
import { dragBoundHandler } from '../../utils/konva.utils';
import { TRANSPARENT } from '../../constants/common.constants';
import { DEFAULT_STROKE_WIDTH } from '../../constants/annotation.constants';

export const Rectangle = ({
    x1,
    y1,
    x2,
    y2,
    width,
    height,
    value,
    opacity,
    color,
    name,
    dragHandler,
    container,
    isDraggable,
    isBorderOnly,
    stroke,
    strokeWidth,
    // rotate it's shape rotate, not page rotate
    rotate,
                          pageRotation,
}: IShapeProps): JSX.Element => (
        <Rect
            key={x2 + y2 + x1 + y1}
            x={x1}
            y={y1}
            width={width}
            height={height}
            strokeWidth={!!strokeWidth ? strokeWidth : DEFAULT_STROKE_WIDTH}
            fill={isBorderOnly ? TRANSPARENT : color}
            name={name}
            opacity={isBorderOnly ? 1 : opacity}
            stroke={!!stroke ? stroke : isBorderOnly ? color : ''}
            draggable={isDraggable}
            onDragEnd={dragHandler}
            value={value}
            dragBoundFunc={(pos: Vector2d): Vector2d => dragBoundHandler(container, width, height, pos, pageRotation)}
        />
    );

import * as React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ModalPackagePresetsTabs from './modalPackagePresetsTabs/ModalPackagePresetsTabs';
import { ORIGINAL_SEVER_COLOR_PARAM, SAVE_TOOLTIP } from '../../../constants/export.contstants';

import './modalPackagePresets.style.scss';
import ExistingPresets from './existingPresets/ExistingPresets';
import { IState as StoreState } from '../../../redux/store';
import {
    IActionProps,
    IExportPreset,
    IPackagePresetState,
    IProps,
    ISelectedPreset,
    IState,
} from './modalPackagePresets.model';
import {
    getExportOptionsSelector,
    getPresetPaginationOptionsSelector,
    getPackagePresets,
    getPresetAnnotationOptionsSelector,
    getSelectedPresetId,
    getPresetsName,
} from '../../../redux/selectors/modalPackagePresets';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnyAction } from 'redux';
import {
    postPackagePreset,
    fetchAllPresets,
    setSelectedPreset,
    deletePackagePreset,
    setDefaultPreset,
    setSelectedPresetId,
    editPackagePreset,
} from '../../../redux/actions/modalPackagePresets';
import { getHighlightTypesList, getSeverTypesList } from '../../../redux/selectors/annotationTypes';
import { IContentOptions, IPaginationOptions } from '../../../redux/reducers/modalWindowExport/modalWindowExport.model';
import { IAnnotationType } from '../../../redux/reducers/annotationTypes/annotationTypes.model';
import { IModalProps } from '../../../redux/reducers/modal/modal.model';
import { handleCloseModalWindow, openModalWindow } from '../../../redux/actions/modal';
import { PACKAGE_PRESETS } from '../../../constants/modal.constants';
import { hasCurrentReductionDefaultWatermark } from '../../../redux/selectors/modalAssociateWatermark';
import { fetchWatermarksSettings } from '../../../redux/actions/modalAssociateWatermarkToRequestType';
import * as constants from '../../../constants/export.contstants';
import { getModifiedLabels } from '../../../redux/selectors/localization';
import { getRedactionLanguage } from '../../../redux/selectors/localStorage';
import { initialLabel } from '../../../constants/localization.constants';
import resourceBundle from '../../localization/localizationData';
import { changeLang } from '../../../redux/actions/localization';

const getPresetLabelsByKey = (key: string): string => {
    switch(key) {
        case 'MODAL_PACKAGE_PRESETS_EXISTING_PRESETS':
            return 'existingPresetsLabel';
        case 'COMMON_LABEL_ADD_NEW':
            return 'addNewLabel';
        case 'COMMON_LABEL_SAVE':
            return 'saveLabel';
        case 'COMMON_LABEL_CANCEL':
            return 'cancelLabel';
        case 'EXPORT_CONTENT_OPTIONS_SELECT_PRESET':
            return 'selectPresetLabel';
        case 'EXPORT_CONTENT_OPTIONS_EXPORT_OPTIONS_TITLE':
            return 'exportOptionsTitle';
        case 'MODAL_EXPORT_PAGINATION_CONTROL_TITLE':
            return 'paginationOptionsTitle';
        case 'MODAL_EXPORT_ANNOTATION_CONTROL_TITLE':
            return 'annotationControlTitle';
        case 'COMMON_LABEL_WARNING':
            return 'warningLabel';
        case 'COMMON_LABEL_PRESS_OK_TO_CONTINUE':
            return 'pressOkContinue';
        case 'MODAL_PACKAGE_PRESETS_DELETE_PRESET':
            return 'deletePreset';
        default: return '';
    }
};

class ModalPackagePresets extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showTabs: false,
            isChangedData: false,
        };
    }

    public componentDidMount(): void {
        this.props.fetchAllPresets();

        if(!this.props.hasDefaultWatermark) {
            this.props.fetchWatermarksSettings();
        }
    }

    public render(): JSX.Element {
        const { showTabs, isChangedData } = this.state;
        const {
            exportOptions: { name = '', name2 = '' },
            selectedPresetId,
            presets,
            hasDefaultWatermark,
            permissions: {
                ADD: addPermission,
                DELETE: deletePermission,
                EDIT: editPermission,
            },
            modifiedLabels,
            redactionLang,
        } = this.props;
        const labels = {
            existingPresetsLabel: initialLabel,
            addNewLabel: initialLabel,
            saveLabel: initialLabel,
            cancelLabel: initialLabel,
            selectPresetLabel: initialLabel,
            exportOptionsTitle: initialLabel,
            paginationOptionsTitle: initialLabel,
            annotationControlTitle: initialLabel,
            warningLabel: initialLabel,
            pressOkContinue: initialLabel,
            deletePreset: initialLabel,
        };
        const name2ErrorInPreset = name2.length > 255;
        const presetWithSameName = presets.find((preset: IExportPreset ) => (
            preset.exportOptions.name === name.toLowerCase()),
        );
        // tslint:disable-next-line:max-line-length
        const isDuplicateName = !!presetWithSameName && (!selectedPresetId || presetWithSameName.id !== selectedPresetId);
        const isWatermarkError = this.props.exportOptions[constants.SHOW_WATERMARKS_PARAM] &&
            !this.props.hasDefaultWatermark;
        const isSaveDisabled = !name || !isChangedData || isDuplicateName || isWatermarkError || name2ErrorInPreset;
        const hasEditPermission = editPermission || addPermission && !this.props.selectedPresetId;
        const langRule = changeLang(redactionLang);

        resourceBundle.map((resource: any) => {
            if (getPresetLabelsByKey(resource.resourceKey)) {
                labels[getPresetLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });
        modifiedLabels.map((resource: any) => {
            if (getPresetLabelsByKey(resource.resourceKey)) {
                labels[getPresetLabelsByKey(resource.resourceKey)] = resource;
            }

            return resource;
        });

        return (
            <div className='package-presets-modal'>
                <div className='package-presets-content'>
                    <ExistingPresets
                        deletePackagePreset={this.props.deletePackagePreset}
                        setSelectedPresetId={this.props.setSelectedPresetId}
                        setSelectedPreset={this.props.setSelectedPreset}
                        selectedPresetId={this.props.selectedPresetId}
                        setDefaultPreset={this.props.setDefaultPreset}
                        openModalWindow={this.props.openModalWindow}
                        handleShowTabs={this.handleShowTabs}
                        presets={this.props.presets}
                        addPermission={addPermission}
                        deletePermission={deletePermission}
                        parentLabel={labels}
                        language={redactionLang}
                    />
                    {
                        showTabs
                            ? <ModalPackagePresetsTabs
                                annotationPresetIdList={this.props.annotationPresetIdList}
                                paginationOptions={this.props.paginationOptions}
                                exportOptions={this.props.exportOptions}
                                highLightList={this.props.highLightList}
                                severList={this.props.severList}
                                selectedPresetId={this.props.selectedPresetId}
                                existingPresetsName={this.props.existingPresetsName}
                                activateSaveBtn={this.activateSaveBtn}
                                hasDefaultWatermark={hasDefaultWatermark}
                                hasEditPermission={hasEditPermission}
                                parentLabel={labels}
                                langRule={langRule}
                            />
                            : <div className='preset-default-view'>{labels.selectPresetLabel[langRule]}</div>
                    }

                </div>
                <div className='modal-buttons-footer'>
                    <Tooltip title='Close' placement='top'>
                        <Button
                            onClick={(): void => this.props.handleCloseModalWindow(PACKAGE_PRESETS)}
                            variant='outlined'
                            className='modal-window__buttons outlined'
                            size='small'
                        >
                            {labels.cancelLabel[langRule]}
                        </Button>
                    </Tooltip>
                    <Tooltip title={SAVE_TOOLTIP} placement='top'>
                        <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            className='primary'
                            onClick={this.handleSavePreset}
                            disabled={isSaveDisabled}
                        >
                            {labels.saveLabel[langRule]}
                        </Button>
                    </Tooltip>
                </div>
            </div>
        );
    }

    private handleShowTabs = (isDisabled: boolean): void => {
        this.setState({ showTabs: isDisabled, isChangedData: false });
    }

    private activateSaveBtn = (): void => {
        const{ isChangedData } = this.state;

        if (!isChangedData) { this.setState({ isChangedData: true }); }
    }

    private disableSaveBtn=(): void => {
        const{ isChangedData } = this.state;

        if (isChangedData) { this.setState({ isChangedData: false }); }
    }

    private handleSavePreset = (): void => {
        const {
            exportOptions,
            paginationOptions,
            annotationPresetIdList,
            highLightList,
            severList,
            postPackagePresetParams,
            selectedPresetId,
            editPackagePresetParams,
        } = this.props;

        const copiedExportOptions = {
            ...exportOptions,
            severColorOption: exportOptions.severColorType === ORIGINAL_SEVER_COLOR_PARAM
                ? null
                : exportOptions.severColorOption,
        };

        const { severColorType, ...restExportOptions } = copiedExportOptions;

        if (selectedPresetId) {
            editPackagePresetParams(
                selectedPresetId,
                restExportOptions,
                paginationOptions,
                annotationPresetIdList,
                highLightList,
                severList,
            );
        } else {
            postPackagePresetParams(
                restExportOptions,
                paginationOptions,
                annotationPresetIdList,
                highLightList,
                severList,
            );
        }
        this.disableSaveBtn();
    }
}

const mapStateToProps = (state: StoreState): IPackagePresetState => ({
    presets: getPackagePresets(state),
    exportOptions: getExportOptionsSelector(state),
    paginationOptions: getPresetPaginationOptionsSelector(state),
    annotationPresetIdList: getPresetAnnotationOptionsSelector(state),
    highLightList: getHighlightTypesList(state),
    severList: getSeverTypesList(state),
    selectedPresetId: getSelectedPresetId(state),
    existingPresetsName: getPresetsName(state),
    hasDefaultWatermark: hasCurrentReductionDefaultWatermark(state),
    modifiedLabels: getModifiedLabels(state),
    redactionLang: getRedactionLanguage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, IActionProps, AnyAction>): IActionProps =>
    ({

        editPackagePresetParams: (
            selectedPresetId: number,
            exportOptions: IContentOptions,
            paginationOptions: IPaginationOptions,
            annotationPresetIdList: number[],
            highLightList: IAnnotationType[],
            severList: IAnnotationType[]):
            void => {
            dispatch(editPackagePreset(
                selectedPresetId,
                exportOptions,
                paginationOptions,
                annotationPresetIdList,
                highLightList,
                severList,
            ));
        },
        setSelectedPresetId: (id: number): void => {
            dispatch(setSelectedPresetId(id));
        },
        setDefaultPreset: (): void => {
            dispatch(setDefaultPreset());
        },
        handleCloseModalWindow: (id: string): void => {
            dispatch(handleCloseModalWindow(id));
        },
        openModalWindow: (data: string, message: IModalProps): void => {
            dispatch(openModalWindow(data, message));
        },
        deletePackagePreset: (id: number): void => {
            dispatch(deletePackagePreset(id));
        },
        setSelectedPreset: (data: ISelectedPreset): void => {
            dispatch(setSelectedPreset(data));
        },
        fetchAllPresets: (): void => {
            dispatch(fetchAllPresets());
        },
        fetchWatermarksSettings: (): void => {
            dispatch(fetchWatermarksSettings());
        },
        postPackagePresetParams: (
            exportOptions: IContentOptions,
            paginationOptions: IPaginationOptions,
            annotationPresetIdList: number[],
            highLightList: IAnnotationType[],
            severList: IAnnotationType[]):
            void => {
            dispatch(postPackagePreset(
                exportOptions,
                paginationOptions,
                annotationPresetIdList,
                highLightList,
                severList,
            ));
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(ModalPackagePresets);
import * as React from 'react';
import { createSelector } from 'reselect';
import { IState } from '../store';
import { IAdvanceSearchParams } from '../../containers/advancedSearch/advancedSearch.model';
import {
    IAct,
    IAdvanceSearchReducer,
    IAdvanceSearchResponseData,
    IAdvanceSearchTableData,
    IMetadataAdvanceSearchData,
    ISelectedDocument,
} from '../reducers/advanceSearch/advanceSearch.model';
import { getDisclosureTypesAccordance } from './disclosureTypes';
import { layoutReductorTypes, transformExemptionIdsToArticles } from './pageList';
import { get } from 'lodash';
import { IDisclosureTypeState } from '../reducers/disclosureTypes/disclosureType.model';
import { IArticleStamp, IValidExemptionDtoList } from '../reducers/layoutRedactorTypes/layoutRedactorTypes.model';
import { IPages } from '../reducers/pageList/pageList.model';
import { Column } from 'react-table';
import { IHeader } from '../../containers/leftTopBar/leftTopBar.model';

export const getAdvanceSearchState = (state: IState): IAdvanceSearchReducer => state.advanceSearch;

export const getAdvanceSearchParams = createSelector(
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer): IAdvanceSearchParams => advanceSearchState.advanceSearchParams,
);

export const getAdvanceSearchErrors = createSelector(
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer): string[] => advanceSearchState.errorsList,
);

export const getAdvanceSearchData =  createSelector(
    [getAdvanceSearchState, getDisclosureTypesAccordance, layoutReductorTypes],
    (
        advanceSearchState: IAdvanceSearchReducer,
        disclosureAccordance: Partial<IDisclosureTypeState>,
        articleStampType: IArticleStamp[],
    ): IAdvanceSearchTableData[] => {
        return advanceSearchState.advanceSearchResult.reduce(
            (acc: IAdvanceSearchTableData[], current: IAdvanceSearchResponseData): IAdvanceSearchTableData[] => {
                const pages = current.pages.map(({ page }: { page: IPages }): IAdvanceSearchTableData => {
                    const metadata: IMetadataAdvanceSearchData = {
                        subject: page.defaultIndexMetadata.subject ? page.defaultIndexMetadata.subject.value : '',
                        to: page.defaultIndexMetadata.to ? page.defaultIndexMetadata.to.value : '',
                        from: page.defaultIndexMetadata.from ? page.defaultIndexMetadata.from.value : '',
                        date: page.defaultIndexMetadata.date ? page.defaultIndexMetadata.date.value : '',
                        toOrg: page.defaultIndexMetadata.toOrg ? page.defaultIndexMetadata.toOrg.value : '',
                        fromOrg: page.defaultIndexMetadata.fromOrg ? page.defaultIndexMetadata.fromOrg.value : '',
                    };

                    return {
                        id: page.id,
                        request: current.request,
                        documentName: current.documentName,
                        page: page.actualPageNumber,
                        disclosure: get(
                            disclosureAccordance,
                            page.disclosureTypeId,
                            '',
                        ),
                        articles: transformExemptionIdsToArticles(page.exemptionIds, articleStampType),
                        ...metadata,
                        redactionDocumentId: current.redactionDocumentId,
                        folderRSN: current.folderRSN,
                        documentId: current.documentId,
                        rotation: page.rotation,
                        fileName: current.fileName,
                    };
                });

                return [...acc, ...pages];
            }, []);
    },
);

export const getActList = createSelector (
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer): IAct[] => advanceSearchState.actList,
);

export const getExemptionsList= createSelector (
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer): IValidExemptionDtoList[] => advanceSearchState.exemptionsList,
);

export const getAdvanceSearchResultLoading = createSelector (
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer): boolean => advanceSearchState.advanceSearchPending,
);

export const getAdvanceSearchColumnHeaderLoading = createSelector(
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer): boolean => advanceSearchState.advanceSearchColumnHeaderPending,
);

export const getAdvanceSearchTableColumnHeader = createSelector (
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer): IHeader[] => {
        const advanceSearchHeader = advanceSearchState.advanceSearchColumnHeader.map((header: IHeader): IHeader => ({
            ...header,
            accessorKey: header.accessor,
            header: header.Header,
        }));
        return advanceSearchHeader;
    }
);

export const getResetedAdvanceSearchColumnHeaderLoading = createSelector(
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer): boolean => advanceSearchState.resetAdvanceSearchColumnHeaderPending,
);

export const getAdvanceSearchedSelectedDocumentDetails = createSelector(
    [getAdvanceSearchState],
    (advanceSearchState: IAdvanceSearchReducer):
        ISelectedDocument => advanceSearchState.selectedAdvanceSearchedDocument,
);
